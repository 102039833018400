import { useSelector } from 'react-redux';

import { selectSidebarOpenedKey } from 'modules/app/store/selectors';
import { SideBarStackItemKey } from 'modules/app/types';
import { isReactNative } from 'modules/reactNative/utils';

const sidebarKeysWithBottomBar: SideBarStackItemKey[] = ['bankCards', 'settings'];

const useShowRNBottomBarInSidebar = () => {
  const sidebarOpenedKey = useSelector(selectSidebarOpenedKey);

  return isReactNative && sidebarKeysWithBottomBar.includes(sidebarOpenedKey);
};

export default useShowRNBottomBarInSidebar;
