import clsx from 'clsx';
import { maxBy } from 'lodash';

import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useSideBar from 'modules/app/hooks/useSideBar';
import rentingCommonClasses from 'modules/cryptoRenting/constants/RentingCommon.module.scss';
import { selectTemplates } from 'modules/cryptoRenting/store/selectors';
import { TemplateName } from 'modules/cryptoRenting/types';
import { TemplateImage } from 'modules/cryptoRenting/views/CryptoRenting/components/TemplateImage';

import { Button } from 'components/ui';

import { TranslationKey, useTranslation } from 'libs/i18n';

import classes from './RentingFAQ.module.scss';

interface RentingFAQProps {
  index?: 1 | 2 | 3;
}

const RentingFAQ: FC<RentingFAQProps> = ({ index = 0 }) => {
  const sidebar = useSideBar();

  const translate = useTranslation();

  const rentingTemplates = useSelector(selectTemplates);

  const [cryptoMaxPercent, stableCoinsMaxPercent] = useMemo(() => {
    const cryptoTemplates = rentingTemplates.filter((t) => t.rentCurrencies.includes('BTC'));
    const stableTemplates = rentingTemplates.filter((t) => t.rentCurrencies.includes('USDT'));

    const cryptoPercent = maxBy(cryptoTemplates, 'percentRPY')?.percentRPY || 0;
    const stablePercent = maxBy(stableTemplates, 'percentRPY')?.percentRPY || 0;

    return [cryptoPercent, stablePercent];
  }, [rentingTemplates]);

  const config: {
    templateName: TemplateName;
    title: TranslationKey;
    description: TranslationKey;
    translateParams?: { [key: string]: number };
  }[] = useMemo(
    () => [
      {
        templateName: 'Baobab',
        title: 'RENTING_FAQ_TITLE',
        description: 'RENTING_FAQ_DESCRIPTION_ALTERNATIVE',
      },
      {
        templateName: 'Sequoia',
        title: 'RENTING_FAQ_RPY_TITLE',
        description: 'RENTING_FAQ_RPY_DESCRIPTION',
        translateParams: { cryptoMaxPercent, stableCoinsMaxPercent },
      },
      {
        templateName: 'Bonsai',
        title: 'RENTING_WITHDRAW_CURRENCY_FAQ_TITLE_ALTERNATIVE',
        description: 'RENTING_WITHDRAW_CURRENCY_FAQ_DESCRIPTION',
      },
      {
        templateName: 'Juniper',
        title: 'RENTING_FAQ_HOW_NEBEUS_USED_ASSETS_TITLE',
        description: 'RENTING_FAQ_HOW_NEBEUS_USED_ASSETS_DESCRIPTION',
      },
    ],
    [cryptoMaxPercent, stableCoinsMaxPercent],
  );

  const item = config[index];
  const nextItem = config[index + 1];
  const first = index === 0;
  const last = index === 3;

  const openNext = () => {
    if (nextItem) {
      sidebar.open('rentingFAQ', {
        id: `${index + 1}`,
        allowDuplicate: true,
        sideBarProps: {
          showBackButton: false,
          contentClassName: clsx(
            rentingCommonClasses.rentingSideBar,
            rentingCommonClasses['templateBg-' + nextItem.templateName],
          ),
        },
        props: {
          index: index + 1,
        },
      });
    }
  };
  return (
    <div className={classes.root}>
      <TemplateImage className="m-0-auto" size="xl" templateName={item.templateName} />
      <div className={classes.blurredCard}>
        <h4>{translate(item.title)}</h4>
        <p
          dangerouslySetInnerHTML={{ __html: translate(item.description, item.translateParams) }}
          className="mt-1-5"
        />
      </div>
      <div className="row aic gap-1-5 mt-3">
        <Button fullWidth onClick={sidebar.pop}>
          {translate(first ? 'CLOSE' : 'BACK')}
        </Button>
        <Button variant="lightGreen" fullWidth onClick={last ? sidebar.close : openNext}>
          {translate(last ? 'CLOSE' : 'NEXT')}
        </Button>
      </div>
    </div>
  );
};

export default RentingFAQ;
