import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectDigitalAccountWallets } from 'modules/accounts/store/selectors';
import { IbanAccountProviderId } from 'modules/digitalAccount/types';
import { PaymentOperationId } from 'modules/payment/types';
import { TransferReferenceCode } from 'modules/payment/views/components';
import {
  DepositByDetailsOperationId,
  PaymentDetailsCard,
} from 'modules/payment/views/components/PaymentDetailsCard';

import { ImportantCard } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { FiatCurrencyCode } from 'types';

import classes from './DepositByDetails.module.scss';

export interface DepositByDetailsProps {
  operationId: DepositByDetailsOperationId;
  isDigitalAccount: boolean;
  currencyCode: FiatCurrencyCode;
}

// const PaymentDetailsMessageWarning = () => {
//   const user = useSelector(selectUserProfile);
//   const translate = useTranslation();
//
//   const paymentMessage = `Rintral Trading SL, GB86DZNN04003107816480, Purpose of payment REF ${user?.publicId}`;
//   return user ? (
//     <div className={classes.referenceCodeCard}>
//       <div className="row aic mb-1">
//         <span className={classes.importantIcon}>!</span>
//         <span className={classes.importantDetailsLabel}>{translate('IMPORTANT')}:</span>
//       </div>
//       <div className={classes.referenceCodeInnerCard}>
//         <div className="column mr-2">
//           <span className="label">{translate('PAYMENT_DETAILS')}</span>
//           <span className={classes.referenceCode}>{paymentMessage}</span>
//         </div>
//         <CopyButton className="flex-shrink-0" value={paymentMessage} />
//       </div>
//       <div className="row aic jscb mt-2">
//         <p className={classes.importantLabel}>
//           {translate('PAYMENT_REFERENCE_CODE_INCLUDE_MESSAGE')}
//         </p>
//         <InfoIcon
//           className="flex-shrink-0"
//           title={translate('PAYMENT_DETAILS')}
//           description={translate('PAYMENT_REFERENCE_CODE_INCLUDE_MESSAGE_DESC')}
//         />
//       </div>
//     </div>
//   ) : null;
// };

const DepositByDetails: FC<DepositByDetailsProps> = ({ operationId, currencyCode }) => {
  const translate = useTranslation();

  const ibanWallets = useSelector(selectDigitalAccountWallets);

  const descriptionKey = useMemo(() => {
    switch (operationId) {
      case PaymentOperationId.depositFromSepaToTradingWallet: {
        return 'PAYMENT_DEPOSIT_SEPA_CRYPTO_DESC';
      }
      case PaymentOperationId.depositFromWireToTradingWallet: {
        if (currencyCode === 'USD') {
          return 'PAYMENT_DEPOSIT_USD_WIRE_NOTE';
        }
        return null;
      }
      case PaymentOperationId.depositFromSepaToMoneyWallet: {
        if (currencyCode === 'EUR') {
          return 'PAYMENT_DEPOSIT_SEPA_DIGITAL_DESC';
        }
        if (currencyCode === 'GBP') {
          return 'PAYMENT_DEPOSIT_WIRE_DIGITAL_DESC';
        }
      }
      // case PaymentOperationId.depositFromWireToMoneyWallet: {
      //   return null; // depositFromWireToMoneyWallet same as depositFromSepaToMoneyWallet
      // }
    }
    return null;
  }, [operationId, currencyCode]);

  const thirdPartyPaymentAllowed = useMemo(() => {
    const ibanWallet = ibanWallets.find((w) => w.currencyCode === 'EUR');

    return ibanWallet?.providerId === IbanAccountProviderId.quicko;
  }, [ibanWallets]);

  // const isUSDWireTransfer =
  //   operationId === PaymentOperationId.depositFromWireToTradingWallet && currencyCode === 'USD';

  return (
    <div className="column gap-3">
      {descriptionKey && <p className={classes.description}>{translate(descriptionKey)}</p>}

      {!thirdPartyPaymentAllowed && (
        <ImportantCard text={translate('PAYMENT_DEPOSIT_WIRE_SEPA_IMPORTANT_NOTICE')} />
      )}

      <PaymentDetailsCard operationId={operationId} currencyCode={currencyCode} />
      {(operationId === PaymentOperationId.depositFromWireToTradingWallet &&
        currencyCode === 'GBP') ||
      operationId === PaymentOperationId.depositFromSepaToTradingWallet ? (
        <TransferReferenceCode />
      ) : null}
      <p className={classes.importantLabel}>
        {translate(
          currencyCode === 'EUR'
            ? 'PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_2'
            : 'PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_1',
        )}
      </p>
      {/*{isUSDWireTransfer && <PaymentDetailsMessageWarning />}*/}
    </div>
  );
};

export default DepositByDetails;
