import { AnalyticEvents } from 'libs/analytic/events';
import analytic from 'libs/analytic/index';

const analyticEventByUrl: { [key: string]: AnalyticEvents } = {
  '/v1/loan/init': AnalyticEvents.loanCreate,
  '/v1/loan/collateral/add': AnalyticEvents.loadAddCollateral, // Params
  '/v1/loan/repayment/repay': AnalyticEvents.loanManualPayment, // Params
  '/v1/renting/open': AnalyticEvents.rentingCreate,
  '/v1/staking/stake': AnalyticEvents.stakingStake,
  '/v1/staking/unstake': AnalyticEvents.stakingUnstake,
  '/v1/exchange/execute': AnalyticEvents.exchangeProceed,
  '/v1/wallet/vault/funds/deposit': AnalyticEvents.vaultDeposit,
  '/v1/wallet/vault/funds/withdraw': AnalyticEvents.vaultWithdraw,

  '/v1/modulr/virtual-card/init': AnalyticEvents.bankCardCreate,
  '/v1/modulr/virtual-card/status/freeze': AnalyticEvents.bankCardFreeze, // Params
  '/v1/modulr/virtual-card/status/unfreeze': AnalyticEvents.bankCardUnfreeze, // Params
  '/v1/modulr/virtual-card/status/cancel': AnalyticEvents.bankCardTerminate, // Params

  '/v1/modulr/subscription/purchase': AnalyticEvents.digitalSubscriptionPurchase, // Params
  '/v1/modulr/subscription/cancel': AnalyticEvents.digitalSubscriptionCancel, // Params

  '/v1/wallet/trading/init': AnalyticEvents.accountsCreateTradingWallet,
  '/v1/modulr/customer': AnalyticEvents.accountsCreateDigitalWallet,
  '/v1/modulr/wallet/gbp': AnalyticEvents.accountsCreateDigitalWallet,

  '/v1/login': AnalyticEvents.authSignInOtpSent,
  '/v1/login/otp': AnalyticEvents.authSignInWithOtp,
  '/v1/login/verify': AnalyticEvents.authSignInWithTfa,
  '/v1/two-factor-auth/reset': AnalyticEvents.authEmergencyResetTFA,
  '/v1/register/confirm': AnalyticEvents.authSignUp,

  '/v1/transaction/crypto/outcome-transaction': AnalyticEvents.paymentSendToExternalCryptoWallet,
  '/v1/transaction/trading/from-money-to-trading': AnalyticEvents.paymentSendFromDigitalToCrypto,
  '/v1/transaction/trading/from-trading-to-money': AnalyticEvents.paymentSendFromCryptoToDigital,
  '/v1/transaction/trading/from-trading-to-trading': AnalyticEvents.paymentSendToNebeusUser,
  '/v1/transaction/modulr/outcome': AnalyticEvents.paymentSendFromDigitalToSepaOrWire,
  '/v1/transaction/iban/outcome': AnalyticEvents.paymentSendFromDigitalToSepaOrWire,
  '/v1/transaction/wire/withdrawal/init': AnalyticEvents.paymentSendFromTradingToSepaOrWire,
  '/v1/card/transaction/from-card-to-trading':
    AnalyticEvents.paymentDepositFromBankCardToTradingWallet,
  '/v1/card/transaction/from-trading-to-card':
    AnalyticEvents.paymentSendFromTradingWalletToBankCard,

  '/trade/create': AnalyticEvents.smartTraderCreate,

  '/v1/user-profile/default/values': AnalyticEvents.userChangeDefaultCurrencyOrLanguage,
  '/v1/two-factor-auth/verify': AnalyticEvents.userSetTFA,
  '/v1/two-factor-auth/disable': AnalyticEvents.userDisableTFA,
  '/v1/otp-code/by-email/set': AnalyticEvents.userSetOtpCodeByEmail,
  '/v1/otp-code/by-email/unset': AnalyticEvents.userUnsetOtpCodeByEmail,

  '/v1/crypto-card/order': AnalyticEvents.cryptoBankCardOrder,
  '/v1/crypto-card/order-virtual': AnalyticEvents.cryptoBankCardOrderVirtual,
  '/v1/crypto-card/pre-ordered-info/read': AnalyticEvents.cryptoBankCardReadOrderNotification,
  '/v1/crypto-card/activate': AnalyticEvents.cryptoBankCardActivate,
  '/v1/crypto-card/close': AnalyticEvents.cryptoBankCardClose,
  '/v1/crypto-card/freeze': AnalyticEvents.cryptoBankCardFreeze,
  '/v1/crypto-card/unfreeze': AnalyticEvents.cryptoBankCardUnfreeze,
  '/v1/crypto-card/trading-wallet/link': AnalyticEvents.cryptoBankCardLink,

  '/v1/crypto-card/onboarding/order-virtual':
    AnalyticEvents.cryptoBankCardOrderVirtualFromOnboarding,
  '/v1/crypto-card/onboarding/order-physical':
    AnalyticEvents.cryptoBankCardOrderPhysicalFromOnboarding,
  '/v1/otp-code/onboarding/phone/send': AnalyticEvents.onboardingPhoneOtpSent,
  '/v1/otp-code/onboarding/phone/verify': AnalyticEvents.onboardingPhoneOtpVerify,

  '/v1/invoice/create': AnalyticEvents.invoicingCreateInvoice,
};

// @POST /api/

const sensitiveFields = [
  'cardNumber',
  'card',
  'address1',
  'address2',
  'city',
  'firstName',
  'lastName',
  'clientDateOfBirth',
  'clientPhoneNumber',
  'cvcCode',
  'cvc',
  'login',
];
export const requestAnalytic = (url: string, success: boolean, payload?: string) => {
  const parsedUrl = url?.replaceAll(/\/(\d{1,10})\b/g, '');
  const eventName = analyticEventByUrl[parsedUrl];
  if (eventName) {
    let safePayload: { [key: string]: any } = {};
    if (payload) {
      try {
        safePayload = JSON.parse(payload);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error analytic: ', e);
      }
    }
    sensitiveFields.forEach((fieldName) => {
      delete safePayload[fieldName];
    });
    analytic.sendEvent(eventName, { source: 'Axios interceptor', success, safePayload });
  }
};
