import { FC } from 'react';

import { templateImageByType, templateTitleFullByType } from 'modules/loans/constants/config';
import { LoanItem } from 'modules/loans/types';

import { Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './LoanTypeAndId.module.scss';

interface LoanTypeAndIdProps {
  id: LoanItem['id'];
  type: LoanItem['templateType'];
}
const LoanTypeAndId: FC<LoanTypeAndIdProps> = ({ type, id }) => {
  const translate = useTranslation();

  return (
    <div className="row aic jcsb gap-2">
      <div className={classes.mark}>
        <Image name={templateImageByType[type]} path="loans" />
        <span>{translate(templateTitleFullByType[type])}</span>
      </div>
      <span className="label grey-200">
        {translate('ID')}: #{id}
      </span>
    </div>
  );
};

export default LoanTypeAndId;
