// import phonebookServicesMocked from 'modules/phonebook/services/mocked';
import { AxiosRequestConfig } from 'axios';

import {
  transformCounterparties,
  transformCounterparty,
} from 'modules/phonebook/services/transformers';
import { Counterparty, CounterpartyPaymentDetails, PaymentDetails } from 'modules/phonebook/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  CounterpartiesResponse,
  CounterpartyPaymentDetailsListResponse,
  CounterpartyRequest,
  CounterpartyResponse,
  GetCounterpartiesUsingQueryParams,
  GetPaymentDetailsListUsingQueryParams,
  IdsRequest,
  PaymentDetailsRequest,
  PaymentDetailsResponse,
} from 'libs/swagger/nebeusApiTypes';

// cli-import

const phonebookServices = {
  getCounterpartyList: (
    params: GetCounterpartiesUsingQueryParams,
    axiosConfig?: AxiosRequestConfig,
  ): Promise<{
    list: Counterparty[];
    pageNumber: number;
    pageSize: number;
  }> =>
    AxiosInstance.get<CounterpartiesResponse, CounterpartiesResponse>(
      '/v1/phonebook/counterparties',
      { params, ...axiosConfig },
    ).then(transformCounterparties),

  getCounterparty: (id: Counterparty['id']): Promise<Counterparty> =>
    AxiosInstance.get<CounterpartyResponse, CounterpartyResponse>(
      `/v1/phonebook/counterparty/${id}`,
    ).then(transformCounterparty),

  createCounterparty: (payload: CounterpartyRequest): Promise<Counterparty> =>
    AxiosInstance.post<CounterpartyResponse, CounterpartyResponse>(
      '/v1/phonebook/counterparty',
      payload,
    ).then(transformCounterparty),

  editCounterparty: (payload: Pick<Counterparty, 'name' | 'email' | 'id'>): Promise<Counterparty> =>
    AxiosInstance.put<CounterpartyResponse, CounterpartyResponse, CounterpartyRequest>(
      `/v1/phonebook/counterparty/${payload.id}`,
      payload,
    ).then(transformCounterparty),

  deleteCounterparty: (id: Counterparty['id']) =>
    AxiosInstance.delete(`/v1/phonebook/counterparty/${id}`),

  deleteCounterparties: (ids: Counterparty['id'][]) =>
    AxiosInstance.post<void, void, IdsRequest>(`/v1/phonebook/counterparties/delete`, { ids }),

  getCounterpartyPaymentsDetails: (id: Counterparty['id']): Promise<PaymentDetails[]> =>
    AxiosInstance.get<
      CounterpartyPaymentDetailsListResponse,
      CounterpartyPaymentDetailsListResponse
    >(`/v1/phonebook/counterparty/${id}/payment-details`).then((res) => res.paymentDetails),

  createCounterpartyPaymentDetails: ({
    counterpartyId,
    paymentDetails,
  }: {
    counterpartyId: Counterparty['id'];
    paymentDetails: PaymentDetailsRequest;
  }) =>
    AxiosInstance.post<PaymentDetailsResponse, PaymentDetailsResponse, PaymentDetailsRequest>(
      `/v1/phonebook/counterparty/${counterpartyId}/payment-details`,
      paymentDetails,
    ),

  editCounterpartyPaymentDetails: ({
    counterpartyId,
    paymentDetailsId,
    paymentDetails,
  }: {
    counterpartyId: Counterparty['id'];
    paymentDetailsId: PaymentDetails['id'];
    paymentDetails: PaymentDetailsRequest;
  }) =>
    AxiosInstance.put<PaymentDetailsResponse, PaymentDetailsResponse, PaymentDetailsRequest>(
      `/v1/phonebook/counterparty/${counterpartyId}/payment-details/${paymentDetailsId}`,
      paymentDetails,
    ),

  deleteCounterpartyPaymentDetails: ({
    counterpartyId,
    paymentDetailsId,
  }: {
    counterpartyId: Counterparty['id'];
    paymentDetailsId: PaymentDetails['id'];
  }) =>
    AxiosInstance.delete(
      `/v1/phonebook/counterparty/${counterpartyId}/payment-details/${paymentDetailsId}`,
    ),

  getPaymentDetailsList: (
    params: GetPaymentDetailsListUsingQueryParams,
  ): Promise<{
    list: CounterpartyPaymentDetails[];
    pageNumber: number;
    pageSize: number;
  }> => AxiosInstance.get('/v1/phonebook/payment-details', { params }),

  // cli-service
};

// export default phonebookServicesMocked;
export default phonebookServices;
