import { AppStore } from 'store';

export const selectTemplatesReducer = (state: AppStore) => state.loans.templates;
export const selectTemplates = (state: AppStore) => state.loans.templates.data;

export const selectActiveLoansItemsReducer = (state: AppStore) => state.loans.loansItemsActive;
export const selectHistoryLoansItemsReducer = (state: AppStore) => state.loans.loansItemsHistory;

export const selectStatisticReducer = (state: AppStore) => state.loans.statistic;
export const selectLoansNotificationsReducer = (state: AppStore) => state.loans.notifications;

export const selectLoanCalculatorSavedValues = (state: AppStore) =>
  state.loans.loanCalculatorSavedValues;
