import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { LoanItem } from 'modules/loans/types';
import LoanTypeAndId from 'modules/loans/views/components/LoanTypeAndId';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';

import { AmountLabel, Button, DottedLine } from 'components/ui';

import { useTranslation } from 'libs/i18n';

export interface LoanAutoPaymentFailedProps {
  loan: LoanItem;
}

const loanAmountFormatOptions = {
  withZeros: true,
};
const LoanAutoPaymentFailed: FC<LoanAutoPaymentFailedProps> = ({ loan }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const depositFunds = useCallback(() => {
    drawer.open(
      paymentDrawerTemplates.paymentProcess({
        isDeposit: true,
        currencyCode: loan.loanCurrency,
      }),
    );
  }, [drawer, loan]);

  return (
    <div className="column gap-3 jcsb flex-1">
      <div className="column gap-3 flexScrollable">
        <p
          className="grey-400"
          dangerouslySetInnerHTML={{
            __html: translate('LOANS_LOAN_AUTO_PAYMENT_FAILED_DESC'),
          }}
        />

        <div className="outlinedCard column gap-3">
          <LoanTypeAndId type={loan.templateType} id={loan.id} />
          <DottedLine />
          <div className="column gap-1">
            <span className="label">{translate('MINIMUM_DEPOSIT')}</span>
            <AmountLabel
              size="md"
              amount={loan.autoPaymentFailedDepositAmount || loan.nextPaymentAmount || 0}
              currencyCode={loan.loanCurrency}
              showCurrencySymbol
              formatOptions={loanAmountFormatOptions}
            />
          </div>
        </div>
      </div>

      <Button fullWidth onClick={depositFunds}>
        {translate('DEPOSIT')}
      </Button>
    </div>
  );
};

export default LoanAutoPaymentFailed;
