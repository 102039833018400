import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  selectStatisticReducer as selectRentingStatisticReducer,
  selectTemplatesReducer,
} from 'modules/cryptoRenting/store/selectors';
import {
  requestStatistic as requestRentingStatistic,
  requestTemplates,
} from 'modules/cryptoRenting/store/thunks';
import { AssetsOverview } from 'modules/cryptoRenting/views/CryptoRenting/components/AssetsOverview';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';
import { scrollViewSelector } from 'constants/viewConstants';

import { ButtonGroup, Loader, NavigationHeader } from 'components/ui';
import { ButtonGroupItem } from 'components/ui/ButtonGroup';

import useMobile from 'hooks/useMobile';
import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';

import classes from './CryptoRenting.module.scss';
import { RentingExplainersCard } from './components/RentingExplainersCard';
import { ActiveHistory } from './tabs/ActiveHistory';
import { New } from './tabs/New';

const buttonsIndexByTab = {
  new: 0,
  active: 1,
  history: 2,
};

type Tab = 'new' | 'active' | 'history';

const tabs = ['new', 'active', 'history'];

interface TabProps {
  active: boolean;
}

const componentsByTab: { [key in Tab]: FC<TabProps> } = {
  new: New,
  history: ActiveHistory,
  active: ActiveHistory,
};

const CryptoRenting = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const navigate = useNavigate();
  const { tab = 'new' } = useParams();

  const isMobile = useMobile();

  useEffect(() => {
    if (!tabs.includes(tab)) {
      navigate(routesByName.cryptoRenting('new'), { replace: true });
    }
    // eslint-disable-next-line
  }, [tab]);

  const {
    entityReducer: { data: statistic, meta: statisticMeta },
  } = useStoreEntity(selectRentingStatisticReducer, requestRentingStatistic);

  const templatesReducer = useSelector(selectTemplatesReducer);

  const initRenting = useCallback(() => {
    if (canRequest(templatesReducer.meta)) {
      dispatch(requestTemplates());
    }
  }, [templatesReducer.meta, dispatch]);

  useEffect(() => {
    initRenting();
    // eslint-disable-next-line
  }, []);

  const scrollDocumentToTopOnMobile = useCallback(() => {
    if (isMobile) {
      document.querySelector(scrollViewSelector)?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isMobile]);

  const buttons = useMemo<ButtonGroupItem[]>(
    () => [
      {
        id: '1',
        children: translate('RENTING_TAB_NEW'),
        onClick: () => {
          navigate(routesByName.cryptoRenting('new'));
          scrollDocumentToTopOnMobile();
        },
      },
      {
        id: '2',
        children: translate('RENTING_TAB_ACTIVE'),
        onClick: () => {
          navigate(routesByName.cryptoRenting('active'));
          scrollDocumentToTopOnMobile();
        },
      },
      {
        id: '3',
        children: translate('RENTING_TAB_HISTORY'),
        onClick: () => {
          navigate(routesByName.cryptoRenting('history'));
          scrollDocumentToTopOnMobile();
        },
      },
    ],
    [scrollDocumentToTopOnMobile, translate, navigate],
  );

  const TabComponent = useMemo(() => componentsByTab[tab as Tab], [tab]);

  const navHeaderInfoIcon = useMemo(
    () => ({
      title: translate('RENTING_FAQ_TITLE'),
      description: translate('RENTING_FAQ_DESCRIPTION'),
    }),
    [translate],
  );
  return (
    <>
      <NavigationHeader hideBackButton infoIcon={navHeaderInfoIcon}>
        {translate('CRYPTO_RENTING')}
      </NavigationHeader>
      <div className={classes.content}>
        {statisticMeta.loading && !statisticMeta.loaded ? (
          <div className={classes.loaderWrapper}>
            <Loader centered />
          </div>
        ) : statistic?.totalAssets ? (
          <AssetsOverview statistic={statistic} />
        ) : (
          <RentingExplainersCard />
        )}
      </div>
      <div className={classes.buttonsContainer}>
        <ButtonGroup
          activeButtonIndex={buttonsIndexByTab[tab as Tab]}
          buttons={buttons}
          className={classes.buttons}
          separated
        />
      </div>
      <div className={classes.tab}>
        {TabComponent && <TabComponent key={tab} active={tab === 'active'} />}
      </div>
    </>
  );
};

export default CryptoRenting;
