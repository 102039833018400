import { FC, useCallback, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { MIN_SPREAD_PERCENT } from 'modules/smartTrader/constants/config';
import { ConfigCard } from 'modules/smartTrader/views/SmartTraderConfigureTrade/components/ConfigCard';
import { ConfigChangeCard } from 'modules/smartTrader/views/SmartTraderConfigureTrade/components/ConfigChangeCard';
import { SmartTraderTitles } from 'modules/smartTrader/views/components/SmartTraderTitles';

import { AgreementText } from 'components/common';
import { SubmitButton } from 'components/form';
import { CurrencyChartCard } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import { useTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import { formatCurrencyWithLabel } from 'utils/currency';

export interface FormValues {
  takeProfitAmount: string;
  takeProfitPercent: string;
  stopLossAmount: string;
  stopLossPercent: string;
}
interface SmartTraderConfigureTradeFormProps extends FormRenderProps<FormValues> {
  primary: CurrencyAmount;
  secondary: CurrencyAmount;
}
const SmartTraderConfigureTradeForm: FC<SmartTraderConfigureTradeFormProps> = ({
  primary,
  secondary,
  values,
  handleSubmit,
}) => {
  const translate = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="column flex-1 gap-3">
      <div>
        <SmartTraderTitles
          title={translate('SMART_TRADER_CONFIGURE_TITLE')}
          subtitle={translate('SMART_TRADER_CONFIGURE_SUBTITLE')}
        />
        <div className="column gap-1-5 mt-3">
          <ConfigCard
            primary={primary}
            secondary={secondary}
            stopLossAmount={+values.stopLossAmount}
            takeProfitAmount={+values.takeProfitAmount}
          />
          <CurrencyChartCard variant="blurred" from={primary.currency} to={secondary.currency} />
          <ConfigChangeCard currencyCode={secondary.currency} secondaryAmount={+secondary.amount} />
        </div>
      </div>

      <div className="column gap-1-5">
        <SubmitButton variant="gold">{translate('CONTINUE')}</SubmitButton>
        <AgreementText light />
      </div>
    </form>
  );
};

export interface SmartTraderConfigureTradeProps {
  primary: CurrencyAmount;
  secondary: CurrencyAmount;
}

const initialValues: FormValues = {
  takeProfitAmount: '',
  takeProfitPercent: '',
  stopLossAmount: '',
  stopLossPercent: '',
};
const SmartTraderConfigureTrade: FC<SmartTraderConfigureTradeProps> = ({ primary, secondary }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const handleSubmit = useCallback(
    (values: FormValues) => {
      sidebar.open(
        ...sidebarTemplates.smartTraderSummaryConfirmation({
          primary,
          secondary,
          takeProfitAmount: values.takeProfitAmount,
          stopLossAmount: values.stopLossAmount,
        }),
      );
    },
    [primary, secondary, sidebar],
  );

  const minSpreadAmount = +secondary.amount * (MIN_SPREAD_PERCENT / 100);

  const schema = useMemo(
    () =>
      yup.object().shape({
        takeProfitAmount: yup
          .number()
          .transform((value) => (isNaN(value) ? 0 : value))
          .min(
            +secondary.amount + minSpreadAmount,
            translate('VALIDATION_MIN_AMOUNT', {
              minLabel: formatCurrencyWithLabel(
                +secondary.amount + minSpreadAmount,
                secondary.currency,
              ),
            }),
          )
          .requiredDefault(),
        stopLossAmount: yup
          .number()
          .transform((value) => (isNaN(value) ? 0 : value))
          .max(
            +secondary.amount - minSpreadAmount,
            translate('VALIDATION_MAX_AMOUNT', {
              maxLabel: formatCurrencyWithLabel(
                +secondary.amount - minSpreadAmount,
                secondary.currency,
              ),
            }),
          )
          .requiredDefault(),
      }),
    [minSpreadAmount, secondary, translate],
  );
  const validate = useMemo(() => {
    return makeValidate(schema);
  }, [schema]);

  return (
    <Form
      initialValues={initialValues}
      // @ts-ignore
      render={SmartTraderConfigureTradeForm}
      onSubmit={handleSubmit}
      primary={primary}
      secondary={secondary}
      validate={validate}
    />
  );
};
export default SmartTraderConfigureTrade;
