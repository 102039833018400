import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { appActions } from 'modules/app/store';
import { PopSideBarPayload, SideBarStackItem, SideBarStackItemKey } from 'modules/app/types';
import { verificationProtectedSidebars } from 'modules/app/views/Sidebar/config';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { selectIsUserVerified } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

const useDrawer = () => {
  const dispatch = useDispatch();

  const userVerified = useSelector(selectIsUserVerified);

  const checkVerification = useCallback(
    (key: SideBarStackItemKey) => {
      if (!userVerified && verificationProtectedSidebars.includes(key)) {
        dispatch(
          appActions.pushToSideBar({
            key: sidebarTemplates.verificationID()[0],
            ...sidebarTemplates.verificationID()[1],
          }),
        );

        return false;
      }
      return true;
    },
    [dispatch, userVerified],
  );

  const open = useCallback(
    (payload: Omit<SideBarStackItem, 'active'>) => {
      const canContinue = checkVerification(payload.key);
      if (!canContinue) {
        return;
      }
      dispatch(appActions.pushToSideBar(payload));
    },
    [checkVerification, dispatch],
  );

  const replace = useCallback(
    (payload: Omit<SideBarStackItem, 'active'>) => {
      const canContinue = checkVerification(payload.key);
      if (!canContinue) {
        return;
      }
      dispatch(appActions.replaceSideBar(payload));
    },
    [checkVerification, dispatch],
  );

  const pop = useCallback(
    (payload?: PopSideBarPayload) => {
      dispatch(appActions.popSideBar(payload));
    },
    [dispatch],
  );

  const close = useCallback(() => {
    dispatch(appActions.closeSideBar());
  }, [dispatch]);

  const replaceAll = useCallback(
    (payload: Omit<SideBarStackItem, 'active'>) => {
      const canContinue = checkVerification(payload.key);
      if (!canContinue) {
        return;
      }
      dispatch(appActions.replaceAllSideBar(payload));
    },
    [checkVerification, dispatch],
  );

  const drawer = useMemo(
    () => ({ open, pop, replace, replaceAll, close }),
    [open, pop, replace, replaceAll, close],
  );
  return drawer;
};

export default useDrawer;
