import { Suspense, lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';

import { selectIsAppInited } from 'modules/app/store/selectors';
import { initApp } from 'modules/app/store/thunks';
import AppControllers from 'modules/app/views/AppControllers';
import AppPublicConfig from 'modules/app/views/AppPublicConfig';
import AuthedApp from 'modules/app/views/AuthedApp';
import CrashApp from 'modules/app/views/CrashApp';
import MocksButton from 'modules/app/views/MocksButton';
import { selectIsUserAuthed } from 'modules/auth/store/selectors';
import Sign from 'modules/auth/views/Sign';
import Welcome from 'modules/auth/views/Welcome';
import { selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import appConfig from 'constants/appConfig';
import routesByName from 'constants/routesByName';
import unauthedRoutes from 'constants/unauthedRouteNames';

import { Loader, Toast } from 'components/ui';

import SentryRoutes from 'libs/sentry/SentryRoutes';

import { isCurrentDeviceMobileOrTablet } from 'utils/device';
import { manualLoadZohoScriptForReactNative } from 'utils/thirdPartyLibs';

// lazy import
const MocksConfigurator = lazy(() => import('modules/app/views/MocksConfigurator'));

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const appInited = useSelector(selectIsAppInited);
  const authed = useSelector(selectIsUserAuthed);
  const user = useSelector(selectUserProfile);

  useEffect(() => {
    manualLoadZohoScriptForReactNative(); // TODO: Fix
    dispatch(initApp());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // User without phone number redirects to onboarding
    if (appInited && authed && unauthedRoutes.includes(pathname)) {
      if (user?.hasPhoneNumber) {
        navigate(routesByName.dashboard, { replace: true });
      } else if (pathname === routesByName.signUp) {
        navigate(routesByName.signUp, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [appInited, authed, pathname]);

  return (
    <Suspense fallback={<Loader />}>
      {appInited ? (
        <SentryRoutes>
          <Route
            path={routesByName.welcome}
            element={
              isCurrentDeviceMobileOrTablet ? (
                <Welcome />
              ) : (
                <Navigate to={routesByName.signUp} replace />
              )
            }
          />
          <Route path={routesByName.signIn} element={<Sign />} />
          <Route path={routesByName.signUp} element={<Sign />} />
          <Route path={routesByName.crashApp} element={<CrashApp />} />
          <Route path={routesByName.publicConfig} element={<AppPublicConfig />} />
          {appConfig.mocksAllowed ? (
            <Route path={routesByName.mocksConfigurator} element={<MocksConfigurator />} />
          ) : null}
          <Route path="/*" element={<AuthedApp />} />
        </SentryRoutes>
      ) : (
        <div className="h100vh flex jcc aic">
          <Loader size="lg" />
        </div>
      )}
      <Toast />
      <AppControllers />
      <MocksButton />
    </Suspense>
  );
};

export default App;
