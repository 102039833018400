import { stakingActions } from '../store';
import { requestWallets } from 'modules/accounts/store/thunks';
import { generateRequestDataThunk } from 'store/generators/thunks';
import { generateRequestThunk } from 'store/generators/thunks';

import { GetStakingSingleStatisticUsingQueryParams } from 'libs/swagger/nebeusApiTypes';

import eventEmitter from 'utils/eventEmitter';

import stakingServices from '../services';
import { SingleStatistic, StakingItem, Statistic, Template } from '../types';

// cli-import

export const requestStakingTemplates = generateRequestDataThunk<Template[]>({
  updateState: stakingActions.updateTemplates,
  request: stakingServices.getTemplates,
  options: { showErrorToast: false },
});
export const requestStakingItems = generateRequestDataThunk<StakingItem[]>({
  updateState: stakingActions.updateStakingItems,
  request: stakingServices.getStakingItems,
  options: { showErrorToast: false },
});

export const requestStatistic = generateRequestDataThunk<Statistic>({
  updateState: stakingActions.updateStatistic,
  request: stakingServices.getStatistic,
  options: { showErrorToast: false },
});
export const requestStake = generateRequestThunk({
  request: stakingServices.stake,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestWallets());
      dispatch(requestStakingItems());
      dispatch(requestStatistic());
      eventEmitter.emit('refreshStakingHistory');
    },
  },
});
export const requestUnstake = generateRequestThunk({
  request: stakingServices.unstake,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestWallets());
      dispatch(requestStakingItems());
      dispatch(requestStatistic());
      eventEmitter.emit('refreshStakingHistory');
    },
  },
});

export const requestSingleStatistic = generateRequestThunk<
  GetStakingSingleStatisticUsingQueryParams,
  SingleStatistic
>({
  request: stakingServices.getSingleStatistic,
  options: { showErrorToast: false },
});
export const requestStakingHistory = generateRequestThunk({
  request: stakingServices.getHistory,
  options: { showErrorToast: false },
});
// cli-thunk
