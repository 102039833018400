import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import {
  selectBlogPostsDesktopReducer,
  selectBlogPostsMobileReducer,
} from 'modules/app/store/selectors';
import { requestBlogPostsDesktop, requestBlogPostsMobile } from 'modules/app/store/thunks';
import { BlogPost } from 'modules/app/types';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import { selectIsDigitalAccountAllowed } from 'modules/digitalAccount/store/selectors';
import { useDispatch } from 'store';

import { EntityView } from 'components/common';
import { CarouselControls } from 'components/ui';

import useTablet from 'hooks/useTablet';

import { useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';
import { openExternalLink } from 'utils/links';

import classes from './BlogCarousel.module.scss';

interface BlogCarouselProps {
  className?: string;
}

const useBlogCarousel = () => {
  const dispatch = useDispatch();

  const isTablet = useTablet();

  const posts = useSelector(
    isTablet ? selectBlogPostsMobileReducer : selectBlogPostsDesktopReducer,
  );

  const fetchPosts = useCallback(
    () => dispatch(isTablet ? requestBlogPostsMobile() : requestBlogPostsDesktop()),
    [dispatch, isTablet],
  );

  return { posts, fetchPosts, isTablet };
};
export const BlogCarousel: FC<BlogCarouselProps> = ({ className }) => {
  const navigate = useNavigate();
  const drawer = useDrawer();
  const translate = useTranslation();

  const { fetchPosts, posts, isTablet } = useBlogCarousel();

  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);

  useEffect(() => {
    if (canRequest(posts.meta)) {
      fetchPosts();
    }
    // eslint-disable-next-line
  }, [isTablet]);

  const handleClickPost = useCallback(
    (post: BlogPost) => {
      if (post.url) {
        if (post.url.endsWith('referral-program-guide')) {
          drawer.open(commonDrawerTemplates.referralLinksMarketingAd());
          return;
        }
        if (post.url.endsWith('choose-digital-subscription') && isDAAllowed) {
          drawer.open(ibanAccountDrawerTemplates.accountCreatingPrompt());
          return;
        }
        if (post.url.startsWith('https://app.nebeus.com/')) {
          const pathname = post.url.split('https://app.nebeus.com')[1];
          navigate(pathname);
          return;
        }
        openExternalLink(post.url);
      }
    },
    [drawer, navigate, isDAAllowed],
  );

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  return (
    <EntityView
      request={fetchPosts}
      reducer={posts}
      component={
        <div className={clsx(classes.root, className)}>
          <div className="row jcsb gap-2">
            <span className="label">{translate('NEBEUS_PRODUCTS')}</span>
            {swiper && (
              <CarouselControls
                slidePrev={() => swiper.slidePrev()}
                slideNext={() => swiper.slideNext()}
                canSlideNext
                canSlidePrev
              />
            )}
          </div>
          <div className={classes.swiperWrapper}>
            <div className={classes.decor} />
            <Swiper
              onSwiper={setSwiper}
              speed={1000}
              className={classes.slider}
              spaceBetween={16}
              centeredSlides
              slidesPerView={1}
              loop
              allowTouchMove
            >
              {posts.data.map((post) => (
                <SwiperSlide
                  onClick={() => handleClickPost(post)}
                  className={classes.imgContainer}
                  key={post.id}
                >
                  <img src={post.imageUrl} alt={post.imageUrl} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      }
    />
  );
};
