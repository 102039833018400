import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { ExchangeProps } from 'modules/exchange/views/Exchange';
import { ExchangeSuccessProps } from 'modules/exchange/views/ExchangeSuccess';

import { getTranslation } from 'libs/i18n';

const followedCurrencies = (): DrawerTemplateReturnType => ({
  key: 'followedCurrencies',
  sideBarProps: {
    swipeModal: true,
    swipeModalMaxHeight: true,
    showBackButton: false,
    withoutBottomPadding: true,
  },
});

const exchange = (props?: ExchangeProps): DrawerTemplateReturnType => ({
  key: 'exchange',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('EXCHANGE'),
  },
});

const exchangeSuccess = (props: ExchangeSuccessProps): DrawerTemplateReturnType => ({
  key: 'exchangeSuccess',
  props,
  sideBarProps: {
    swipeModal: true,
    showBackButton: false,
  },
});

const exchangeDrawerTemplates = {
  followedCurrencies,
  exchange,
  exchangeSuccess,
};

export default exchangeDrawerTemplates;
