import clsx from 'clsx';

import { FC } from 'react';

import { Icon } from 'components/ui';

import { voidFunc } from 'types';

import classes from './StackNavigation.module.scss';

export interface StackNavigationProps {
  className?: string;
  onBack?: voidFunc;
  onClose?: voidFunc;
  variant?: 'default' | 'light';
}

export const StackNavigation: FC<StackNavigationProps> = ({
  className,
  onBack,
  onClose,
  variant = 'default',
}) => {
  return (
    <div className={clsx(classes.root, className, classes['variant-' + variant], 'row aic')}>
      {onBack && (
        <Icon onClick={onBack} className={classes.backButton} name="arrowLeft" size="sm" />
      )}
      {onClose && (
        <Icon
          onClick={onClose}
          className={clsx(classes.closeIcon, onBack && classes.withBack)}
          name="cross"
          size={14}
        />
      )}
    </div>
  );
};
