import { createSelector } from '@reduxjs/toolkit';

import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';
import { VaultWallet } from 'modules/vault/types';
import { AppStore } from 'store';

import { getCurrencyLabelByCode, mainCryptoCodes } from 'utils/currency';

export const selectVaultWalletsMeta = (state: AppStore) => state.vault.vaultWallets.meta;
const selectVaultWalletsInternal = (state: AppStore) => state.vault.vaultWallets.data;

export const selectVaultWallets = createSelector(
  selectVaultWalletsInternal,
  selectUserDefaultCurrencyCode,
  (vaultWallets, defaultCurrencyCode) =>
    mainCryptoCodes.map(
      (vaultCurrency, index) =>
        vaultWallets.find((w) => w.currencyCode === vaultCurrency) ||
        ({
          id: index.toString(),
          amount: 0,
          currencyCode: vaultCurrency,
          amountInDefaultCurrency: 0,
          defaultCurrencyCode,
          currencyLabel: getCurrencyLabelByCode(vaultCurrency),
          exist: false,
        } as VaultWallet),
    ),
);

export const selectVaultWalletsReducer = createSelector(
  selectVaultWallets,
  selectVaultWalletsMeta,
  (wallets, meta) => ({ data: wallets, meta }),
);
