import { useSwiperSlide } from 'swiper/react';

import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import { StepHeader } from 'modules/auth/views/components/StepHeader';

import { PinCodeField, SubmitButton } from 'components/form';

import useWindowFocus from 'hooks/useWindowFocus';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import { getStringFromClipboard } from 'utils/common';
import { parseFixEmptyString } from 'utils/inputParsers';

import classes from './EnterOTP.module.scss';

interface ResendOtpProps {
  resendOtp: () => Promise<boolean>;
}

const ResendOtp: FC<ResendOtpProps> = ({ resendOtp }) => {
  const { isActive } = useSwiperSlide();
  const translate = useTranslation();

  const [seconds, setSeconds] = useState(0);

  const sendOtp = useCallback(async () => {
    const success = await resendOtp();
    if (success) {
      setSeconds(59);
    }
  }, [resendOtp]);

  useEffect(() => {
    if (isActive) {
      setSeconds(59);
    }
  }, [isActive]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (seconds > 0 && !intervalId) {
      intervalId = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [seconds]);
  return (
    <span className={classes.resendLabel}>
      {translate('CONFIRM_OTP_DIDNT_RECEIVE')}{' '}
      <span
        className={seconds === 0 ? classes.resendButton : undefined}
        onClick={seconds === 0 ? sendOtp : undefined}
      >
        {translate(seconds === 0 ? 'CONFIRM_OTP_RESEND' : 'CONFIRM_OTP_RESEND_IN_SECONDS', {
          seconds,
        })}
      </span>
    </span>
  );
};
interface FormValues {
  code: string;
}

const initialValues: FormValues = {
  code: '',
};

interface EnterOTPFormProps extends FormRenderProps<FormValues> {
  resendOtp: () => Promise<boolean>;
  title: string;
  subtitle: string;
  codeLength?: number;
}
const EnterOTPForm: FC<EnterOTPFormProps> = ({
  handleSubmit,
  form: { change: changeForm },
  resendOtp,
  title,
  subtitle,
  codeLength,
}) => {
  const translate = useTranslation();

  const { isActive } = useSwiperSlide();

  const pasteCodeFromClipboard = useCallback(async () => {
    if (!isActive) {
      return;
    }
    const code = await getStringFromClipboard();
    if (code.length === 6 && !isNaN(Number(code))) {
      changeForm('code', code);
    }
  }, [isActive, changeForm]);

  useWindowFocus(pasteCodeFromClipboard);

  return (
    <form className="authStepRoot" onSubmit={handleSubmit}>
      <div className="column gap-3">
        <StepHeader title={title} subtitle={subtitle} />

        <div className="column gap-2">
          <Field
            name="code"
            component={PinCodeField}
            onSubmit={handleSubmit}
            length={codeLength}
            parse={parseFixEmptyString}
            variant="creamy"
            canIntercept={isActive}
          />
          <ResendOtp resendOtp={resendOtp} />
        </div>
      </div>

      <SubmitButton fullWidth>{translate('CONTINUE')}</SubmitButton>
    </form>
  );
};

interface EnterOTPProps {
  onSubmit: (payload: FormValues) => Promise<object | undefined>;
  resendOtp: () => Promise<boolean>;
  title: string;
  subtitle: string;
  codeLength?: number;
}

export const EnterOTP = memo<EnterOTPProps>(
  ({ onSubmit, title, subtitle, resendOtp, codeLength = 6 }) => {
    const renderForm = useCallback(
      (formProps: FormRenderProps<FormValues>) => (
        <EnterOTPForm
          {...formProps}
          title={title}
          subtitle={subtitle}
          resendOtp={resendOtp}
          codeLength={codeLength}
        />
      ),
      [title, subtitle, resendOtp, codeLength],
    );

    const schema = useMemo(
      () =>
        yup.object().shape({
          code: yup
            .string()
            .required(getTranslation('VALIDATION_REQUIRED'))
            .length(codeLength, getTranslation('VALIDATION_LENGTH', { length: codeLength })),
        }),
      [codeLength],
    );

    const validate = useMemo(() => makeValidate(schema), [schema]);

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={renderForm}
        validate={validate}
      />
    );
  },
);
