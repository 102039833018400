import get from 'lodash/get';

import { Paths } from 'types/custom';

export const findByProperty = <ListItem>(
  list: ListItem[],
  propertyPath: Paths<ListItem>,
  value: any,
) => list.find((i) => get(i, propertyPath) === value);

export const getDeepKeys = (obj: { [index: string]: any }) => {
  let keys: string[] = [];
  for (const key in obj) {
    if (!Array.isArray(obj)) {
      keys.push(key);
    }
    if (typeof obj[key] === 'object') {
      const subkeys = getDeepKeys(obj[key]);
      keys = keys.concat(
        subkeys.map(function (subkey) {
          if (subkey.includes('.')) {
            const [index, name] = subkey.split('.');
            return `${key}[${index}].${name}`;
          }

          return key + '.' + subkey;
        }),
      );
    }
  }
  return keys;
};

export const transformArrayToMap = <E>(arr: E[], key: keyof E) =>
  arr.reduce((res, currValue) => ({ ...res, [currValue[key] as string]: currValue }), {});
