import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { useCallback, useMemo, useState } from 'react';

import { NavigationHeader } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './ExchangePage.module.scss';
import { Markets } from './components/Markets';
import { SmartTrader } from './components/SmartTrader';
import { WatchList } from './components/WatchList';

export enum ExchangePageTab {
  watchList,
  markets,
  smartTrader,
}

const ExchangePage = ({ initialTab = ExchangePageTab.markets }) => {
  const translate = useTranslation();

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState(initialTab);

  const buttons = useMemo(
    () => [
      {
        children: translate('WATCH_LIST'),
        onClick: () => {
          swiper?.slideTo(ExchangePageTab.watchList);
        },
      },
      {
        children: translate('EXCHANGE_MARKETS'),
        onClick: () => {
          swiper?.slideTo(ExchangePageTab.markets);
        },
      },
      {
        children: translate('SMART_TRADER'),
        onClick: () => {
          swiper?.slideTo(ExchangePageTab.smartTrader);
        },
      },
    ],
    [swiper, translate],
  );

  const handleSlideChange = useCallback((swiperInstance: SwiperClass) => {
    setActiveSlideIndex(swiperInstance.activeIndex);
  }, []);
  return (
    <div className="column gap-3">
      <NavigationHeader backButtonFilled={false}>{translate('EXCHANGE')}</NavigationHeader>
      <div className={classes.buttons}>
        {buttons.map((b, i) => (
          <div
            key={i}
            onClick={b.onClick}
            className={clsx(classes.button, i === activeSlideIndex && classes.active)}
          >
            <span>{b.children}</span>
          </div>
        ))}
      </div>
      <Swiper
        className={classes.slider}
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
        initialSlide={initialTab}
        spaceBetween={0}
        allowTouchMove={false}
        autoHeight
      >
        <SwiperSlide>
          <WatchList />
        </SwiperSlide>
        <SwiperSlide>
          <Markets />
        </SwiperSlide>
        <SwiperSlide>
          <SmartTrader />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ExchangePage;
