export const template = `
<!DOCTYPE html>
<html lang="it-IT">
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta charset="utf-8" />
    <title> </title>
    <style>
      body {
        font-family: SegoeUI, sans-serif;
        font-size: 12pt;
      }
      h3,
      p {
        margin: 0pt;
      }
      li,
      table {
        margin-top: 0pt;
        margin-bottom: 0pt;
      }
      h3 {
        margin-top: 5pt;
        margin-bottom: 5pt;
        page-break-inside: auto;
        page-break-after: auto;
        font-family: SegoeUI, sans-serif;
        font-size: 13.5pt;
        font-weight: bold;
        color: #000000;
      }
      .Footer {
        font-size: 12pt;
      }
      .ListParagraph {
        margin-left: 36pt;
        font-size: 12pt;
      }
      .NormalWeb {
        margin-top: 5pt;
        margin-bottom: 5pt;
        font-family: SegoeUI, sans-serif;
        font-size: 12pt;
      }
      span.Hyperlink {
        text-decoration: underline;
        color: #0563c1;
      }
      span.Strong {
        font-weight: bold;
      }
      span.Titolo3Carattere {
        font-family: SegoeUI, sans-serif;
        font-size: 13.5pt;
        font-weight: bold;
      }
      .awlist1 {
        list-style: none;
        counter-reset: awlistcounter3_1;
      }
      .awlist1 > li:before {
        content: '2.' counter(awlistcounter3_1);
        counter-increment: awlistcounter3_1;
      }
      @media (max-width: 900px) {
        img {
          max-width: 100%;
          height: auto;
        }

        .table-container {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }

        table {
          width: 100%;
          border-collapse: collapse;
        }

        td,
        th {
          padding: 8px;
          text-align: left;
          border: 1px solid #ddd;
        }
      }
    </style>
  </head>
  <body>
    <div>
      <p style="margin-bottom: 14pt; text-align: center; line-height: 150%; font-size: 14pt">
        <strong>LOAN AGREEMENT</strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>Loan Agreement</strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>Parties</strong>
      </p>
      <ol style="margin: 0pt; padding-left: 0pt">
        <li
          style="
            margin-top: 14pt;
            margin-left: 31.96pt;
            text-align: justify;
            line-height: 150%;
            padding-left: 3.74pt;
          "
        >
          <strong>Borrower:</strong> {userName}, residing at {userAddress}, (hereinafter referred to
          as the "Borrower").
        </li>
        <li
          style="
            margin-left: 31.96pt;
            margin-bottom: 14pt;
            text-align: justify;
            line-height: 150%;
            padding-left: 3.74pt;
          "
        >
          <strong>Lender:</strong> Rintral Trading SL, incorporated and registered in Spain with
          company number B66096686, whose registered office is at C/ de la Llacuna, 11, 08005 -
          Barcelona, Spain (hereinafter referred to as the "Lender").
        </li>
      </ol>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        The Borrower and the Lender shall hereinafter be jointly referred to as the "Parties".
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>1. </strong><strong><u>Loan Terms</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">&#xa0;</p>
      <table style="border: 0.75pt solid #000000; border-collapse: collapse">
        <tr>
          <td
            style="
              width: 85.45pt;
              border-right: 0.75pt solid #000000;
              border-bottom: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">Loan</p>
            <p style="text-align: justify; line-height: 150%">Amount</p>
            <p style="text-align: justify; line-height: 150%">&#xa0;</p>
          </td>
          <td
            style="
              width: 85.45pt;
              border-right: 0.75pt solid #000000;
              border-left: 0.75pt solid #000000;
              border-bottom: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">Interest rate,</p>
            <p style="text-align: justify; line-height: 150%">Year</p>
            <p style="text-align: justify; line-height: 150%">&#xa0;</p>
          </td>
          <td
            style="
              width: 85.5pt;
              border-right: 0.75pt solid #000000;
              border-left: 0.75pt solid #000000;
              border-bottom: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">Loan term,</p>
            <p style="text-align: justify; line-height: 150%">End day</p>
            <p style="text-align: justify; line-height: 150%">&#xa0;</p>
          </td>
          <td
            style="
              width: 85.5pt;
              border-right: 0.75pt solid #000000;
              border-left: 0.75pt solid #000000;
              border-bottom: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">Monthly payment</p>
          </td>
          <td
            style="
              width: 85.5pt;
              border-left: 0.75pt solid #000000;
              border-bottom: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">Collateral Amount</p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 85.45pt;
              border-top: 0.75pt solid #000000;
              border-right: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">&#xa0;{loanAmount} {loanCurrency}</p>
          </td>
          <td
            style="
              width: 85.45pt;
              border-top: 0.75pt solid #000000;
              border-right: 0.75pt solid #000000;
              border-left: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">{interestRate}</p>
          </td>
          <td
            style="
              width: 85.5pt;
              border-top: 0.75pt solid #000000;
              border-right: 0.75pt solid #000000;
              border-left: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">{termMonth} month(s), {endDate}</p>
          </td>
          <td
            style="
              width: 85.5pt;
              border-top: 0.75pt solid #000000;
              border-right: 0.75pt solid #000000;
              border-left: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">{monthlyPayment} {loanCurrency}</p>
          </td>
          <td
            style="
              width: 85.5pt;
              border-top: 0.75pt solid #000000;
              border-left: 0.75pt solid #000000;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p style="text-align: justify; line-height: 150%">
              {collateralAmount} {collateralCurrency}
            </p>
          </td>
        </tr>
      </table>
      <p style="text-align: justify; line-height: 150%">&#xa0;</p>
      <p style="text-align: justify; line-height: 150%">&#xa0;</p>
      <p style="text-align: justify; line-height: 150%">
        Calculated according to Bank of Spain Circular 5/2012 (BOE № 161 от 06.07.2012) and with law
        16/2011, of June 24, on consumer credit contracts.
      </p>
      <p style="text-align: justify; line-height: 150%">&#xa0;</p>
      <p style="text-align: justify; line-height: 150%">
        <strong>2. </strong><strong><u>Definitions</u></strong>
      </p>
      <ol class="awlist1" style="margin: 0pt; padding-left: 0pt">
        <li
          class="ListParagraph"
          style="
            margin-left: 18pt;
            text-indent: -18pt;
            text-align: justify;
            line-height: 150%;
            font-weight: bold;
          "
        >
          <span style="width: 2.63pt; font: 7pt SegoeUI, sans-serif; display: inline-block"
            >&#xa0; </span
          ><span style="font-weight: normal"
            >Loan Amount: The principal amount of {loanAmount} {loanCurrency}. Interest Rate:
            {interestRate}% per annum. Loan Term: {termMonth} months ending on {endDate}. Monthly
            Payment: {monthlyPayment} {loanCurrency}. Collateral Amount: {collateralAmount}.
            Guarantee Deposit: The Borrower shall deposit the same amount initially deposited as
            collateral under this loan agreement. "Event of Default": Any event or circumstance
            listed in this agreement. "Loan": The sum lent by the Lender to the Borrower under this
            agreement. "Repayment Date": Each of the dates set out in Appendix A. "Business Day": A
            Day other than a Saturday, Sunday, or public holiday in Spain when banks are open for
            business. "Loan Currency": The currency in which the loan was issued. "Calendar Day":
            Any day of the week including Saturday, Sunday, or public holiday in Spain. "Agreement":
            This loan agreement. "Security": Any charge, pledge, lien, assignment by way of
            security, or other security interest securing any obligation. "Security Period": The
            period starting on the date of this deed and ending when the Lender is satisfied that
            all liabilities have been paid and discharged. "Secured Liabilities": All obligations of
            the Borrower to the Lender under this agreement. "Customer's Account": Account opened by
            the Borrower with Nebeus. "Margin Call": Occurs when the value of the collateral drops
            below a set rate triggering a requirement for the Borrower to cover the shortfall.
            Security cushion Term of the Loan: period of months from the date the Loan is approved.
            An origination fee is a one-time fee a lender charges for processing a new loan
            application. It is a percentage ({originationFee}%) of the total loan amount and covers
            the costs of underwriting, processing, and approving the loan. The lender is authorized
            to deduct the fee from the Loan Principal Amount. Interpretation In this agreement, the
            following rules of interpretation shall apply: Clause, Schedule, and paragraph headings
            shall not affect the interpretation of this agreement. A reference to a person includes
            individuals, firms, companies, corporations, partnerships, unincorporated bodies of
            persons, governments, states, or agencies of a state and any association, trust, joint
            venture, or consortium whether or not having separate legal personality. Unless the
            context otherwise requires words in the singular include the plural and words in the
            plural include the singular. Similarly, a reference to one gender includes all genders.
            A reference to a party includes that party's successors, permitted assigns, and
            permitted transferees. This agreement shall be binding on and benefit the parties to
            this agreement and their respective personal representatives, successors, permitted
            assigns, and permitted transferees. A reference to a statute or statutory provision is a
            reference to it as amended, extended, or re-enacted from time to time, including all
            subordinate legislation made under that statute or statutory provision. A reference to a
            time of day is to London and Madrid time. A reference to writing or written includes fax
            and email. An obligation on a party not to do something includes an obligation not to
            allow that thing to be done. A reference to this agreement or any provision of it or to
            any other agreement or document referred to in any Finance Document is a reference to
            this agreement, that provision, or such other agreement or document as amended in each
            case other than in breach of the provisions of this agreement from time to time. Unless
            the context otherwise requires, a reference to a clause or Schedule is to a clause of or
            Schedule to this agreement and a reference to a paragraph is to a paragraph of the
            relevant Schedule. Any words following the terms including, include, in particular, for
            example, or any similar expression shall be construed as illustrative and shall not
            limit the sense of the words, description, definition, phrase, or term preceding those
            terms. A reference to a document in agreed form is to that document in the form agreed
            by the Lender and the Borrower and initiated by or on their behalf for identification. A
            reference to an amendment includes a novation, re-enactment, supplement, or variation,
            and amended shall be construed accordingly. A reference to assets includes present and
            future properties, undertakings, revenues, rights, and benefits of every description. A
            reference to an authorization includes approval, authorization, consent, exemption,
            filing, license, notarization, registration, and resolution. A reference to a certified
            copy of a document means a copy certified to be a true, complete, and up-to-date copy of
            the original document in writing and signed by a director or the secretary of the party
            delivering the document. A reference to "continuing" in relation to an Event of Default
            means an Event of Default that has not been remedied or waived. A reference to
            "determines" or "determined" means unless the contrary is indicated a determination made
            at the discretion of the person making it. A reference to a disposal of any asset,
            undertaking, or business includes a sale, lease, license, transfer, loan, or other
            disposal by a person of that asset, undertaking, or business whether by a voluntary or
            involuntary single transaction or series of transactions. A reference to a regulation
            includes any regulation, rule, official directive, request, or guideline whether or not
            having the force of law of any governmental, inter-governmental, or supranational body,
            agency, department, or regulatory, self-regulatory, or other authority or organization.
            Unless the context otherwise requires, any reference to European Union law that is
            directly applicable or directly effective in the UK at any time is a reference to it as
            it applies in England and Wales from time to time, including as retained, amended,
            extended, re-enacted, or otherwise given effect on or after 11 pm on 31 January
            2020.</span
          >
        </li>
        <li
          class="ListParagraph"
          style="
            margin-left: 18pt;
            text-indent: -18pt;
            text-align: justify;
            line-height: 150%;
            font-weight: bold;
          "
        >
          <span style="width: 2.63pt; font: 7pt SegoeUI, sans-serif; display: inline-block"
            >&#xa0; </span
          ><span style="font-weight: normal"
            >In this agreement, the following rules of interpretation shall apply:</span
          >
        </li>
      </ol>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        Clause, Schedule, and paragraph headings shall not affect the interpretation of this
        agreement. A reference to a person includes individuals, firms, companies, corporations,
        partnerships, unincorporated bodies of persons, governments, states or agencies of a state,
        and any association, trust, joint venture, or consortium, whether or not having separate
        legal personality.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        Unless the context otherwise requires, words in the singular include the plural, and words
        in the plural include the singular. Similarly, a reference to one gender includes all
        genders.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        A reference to a party includes that party's successors, permitted assigns, and permitted
        transferees. This agreement shall be binding on and benefit the parties to this agreement
        and their respective personal representatives, successors, permitted assigns, and permitted
        transferees.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        A reference to a statute or statutory provision is a reference to it as amended, extended,
        or re-enacted from time to time, including all subordinate legislation made under that
        statute or statutory provision.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        A reference to a time of day is to London and Madrid time. A reference to writing or written
        includes fax and email.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        An obligation on a party not to do something includes an obligation not to allow that thing
        to be done. A reference to this agreement, or any provision of it, or to any other agreement
        or document referred to in any Finance Document, is a reference to this agreement, that
        provision, or such other agreement or document as amended, in each case, other than in
        breach of the provisions of this agreement, from time to time.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        Unless the context otherwise requires, a reference to a clause or Schedule is to a clause
        of, or Schedule to, this agreement, and a reference to a paragraph is to a paragraph of the
        relevant Schedule. Any words following the terms including, include, in particular, for
        example, or any similar expression shall be construed as illustrative and shall not limit
        the sense of the words, description, definition, phrase, or term preceding those terms.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        A reference to a document in agreed form is to that document in the form agreed by the
        Lender and the Borrower and initiated by or on their behalf for identification. A reference
        to an amendment includes a novation, re-enactment, supplement, or variation, and amended
        shall be construed accordingly.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        A reference to assets includes present and future properties, undertakings, revenues,
        rights, and benefits of every description. A reference to an authorization includes
        approval, authorization, consent, exemption, filing, license, notarization, registration,
        and resolution.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        A reference to a certified copy of a document means a copy certified to be a true, complete,
        and up-to-date copy of the original document, in writing and signed by a director or the
        secretary of the party delivering the document. A reference to "continuing" in relation to
        an Event of Default means an Event of Default that has not been remedied or waived. A
        reference to "determines" or "determined" means, unless the contrary is indicated, a
        determination made at the discretion of the person making it.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        A reference to a disposal of any asset, undertaking, or business includes a sale, lease,
        license, transfer, loan, or other disposal by a person of that asset, undertaking, or
        business, whether by a voluntary or involuntary single transaction or series of
        transactions. A reference to a regulation includes any regulation, rule, official directive,
        request, or guideline, whether or not having the force of law, of any governmental,
        inter-governmental, or supranational body, agency, department, or regulatory,
        self-regulatory, or other authority or organization.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        Unless the context otherwise requires, any reference to European Union law that is directly
        applicable or directly effective in the UK at any time is a reference to it as it applies in
        England and Wales from time to time, including as retained, amended, extended, re-enacted,
        or otherwise given effect on or after 11 pm on 31 January 2020.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>3. Loan</u></strong>
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        The Lender agrees to loan the Borrower the amount of {loanAmount} {loanCurrency} (the "Loan
        Amount").
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        The Loan Amount will be disbursed to the Borrower's Bank Account. The disbursement amount
        will be reduced by the origination fee specified in Section 4.2.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>4. Interest Rate and Fees</u></strong>
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
          font-size: 13.5pt;
        "
      >
        <strong><span style="line-height: 150%; font-size: 12pt">4.1 Interest Rate</span></strong
        ><span style="line-height: 150%; font-size: 12pt"
          >: The Loan Amount will accrue interest at a rate of</span
        ><span class="apple-converted-space" style="line-height: 150%; font-size: 12pt">&#xa0;</span
        ><span class="Strong" style="line-height: 150%; font-size: 12pt"
          >{interestRate}% per annum</span
        ><span style="line-height: 150%; font-size: 12pt"
          >, calculated on the outstanding balance. The Borrower will be responsible for making
          monthly interest-only payments during the term of the loan. This rate applies exclusively
          to loans originated during the promotional period from</span
        ><span style="line-height: 150%; font-size: 12pt">&#xa0;</span
        ><strong><span style="line-height: 150%; font-size: 12pt">04/10/2024</span></strong
        ><span style="line-height: 150%; font-size: 12pt">&#xa0;</span
        ><span style="line-height: 150%; font-size: 12pt">to</span
        ><span style="line-height: 150%; font-size: 12pt">&#xa0;</span
        ><strong><span style="line-height: 150%; font-size: 12pt">31/10/2024.</span></strong
        ><span class="apple-converted-space" style="font-family: -webkit-standard">&#xa0;</span>
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong>4.2 Origination Fee</strong>: An origination fee of {originationFee}% of the Loan
        Amount will be deducted from the disbursement amount.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong>4.3 Early Repayment Fee</strong>: In the event of early repayment, an early
        repayment fee of {earlyRepaymentFeePercent}% of the outstanding balance will apply.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>5. Loan-to-value ratio (LTV) and Collateral</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>5.1 Loan-to-Value Ratio:</strong> The Loan Amount will be provided based on a
        Loan-to-Value (LTV) ratio of 90% of the collateral value.
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>5.2 Collateral:</strong> The Borrower shall provide USDT as collateral. The
        collateral amount must be equivalent to or greater than the LTV ratio specified.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>6. Term and Repayment</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>6.1 Term</strong>: The term of the loan shall be&#xa0;{termMonth}<strong>
          months</strong
        >&#xa0;from the Effective Date (the “Term”). During this period, the Borrower is required to
        make interest-only payments, as specified in Section <strong>4.1</strong>. The principal
        Loan Amount shall be repaid in full by the end of the Term. Early repayment before this
        period will incur an early repayment fee as outlined in Section <strong>4.3</strong>.
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>6.2 Repayment Schedule: </strong>The Borrower shall make monthly payments covering
        interest accrued at the rate of {interestRate}% on the outstanding Loan Amount. The full
        Loan Amount (principal) shall be repaid at the end of the Term on<span
          class="apple-converted-space"
          >&#xa0;</span
        ><span class="Strong" style="font-weight: normal">{endDate}</span
        ><strong>.</strong> Payments should be made to the Lender’s designated account.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>7. Default and Collateral Liquidation</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        If the Borrower fails to repay the Loan Amount, accrued interest, or any applicable fees per
        the terms of this Agreement, the Lender may declare a default. <br />In the event of
        default, the Lender has the right to liquidate the collateral to recover the outstanding
        balance of the loan, including accrued interest and fees.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>8. Assignment and Transfer</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>8.1</strong> The Borrower may not assign or transfer any of their rights or
        obligations under this Agreement.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>8.2</strong> The Lender reserves the right to assign its rights and obligations to
        any third party, notifying the Borrower of such assignment, as well as any other guarantee
        formalized on the occasion of this Agreement, communicating this assignment to the Borrower.
        All without prejudice to the provisions of Article 31 of Law 16/2011 of 24 June on Consumer
        Loan Agreements.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>9. Events of Default</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>9.1</strong> Events of Default include non-payment, bankruptcy, death or incapacity,
        unforeseen circumstances preventing compliance, and breach of any essential obligation under
        this Agreement.
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>9.2</strong> Upon an Event of Default, the Lender may terminate this Agreement or
        demand its compliance and claim damages.
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>9.3</strong> The Borrower will be notified of the Event of Default by registered
        letter, telegram, telex, or any other reliable manner.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>9.4</strong> Likewise, at the occurrence of the Event of Default provided for in
        sections “a” and “b” of clause 9.1., a portion of the Collateral Pledge is liquidated to
        cover the loan premium. The remaining crypto balance is then deposited back into the
        Borrower’s account in the form of the original crypto used.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>10. Account Statement</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        The Borrower is entitled to receive a statement of account in the form of a repayment chart
        free of charge upon request at any time during the term of this Agreement, particularly in
        the event of capital repayment.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>11. Covenants</u></strong>
      </p>
      <p
        class="NormalWeb"
        style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%"
      >
        <strong><span style="font-family: SegoeUI">11.1</span></strong
        ><span style="font-family: SegoeUI">
          The Borrower covenants with the Lender that, from the date of this Agreement until all its
          liabilities under this Agreement have been discharged, it will not create, or permit to
          subsist, any Security on or over the Collateral Pledge other than the Security created by
          this Agreement.</span
        >
      </p>
      <p
        class="NormalWeb"
        style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%"
      >
        <strong><span style="font-family: SegoeUI">11.2</span></strong
        ><span style="font-family: SegoeUI">
          The Borrower shall, on demand, pay to the Lender and discharge the Secured Liabilities
          when they become due.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>12. Guarantee Deposit</u></strong>
      </p>
      <p
        class="NormalWeb"
        style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%"
      >
        <strong><span style="font-family: SegoeUI">12.1</span></strong
        ><span style="font-family: SegoeUI">
          As a continuing security for the payment and discharge of the Secured Liabilities, the
          Borrower, with full title guarantee, charges to the Lender by way of first fixed charge
          all of its present and future right, title, benefit, and interest in and to the Collateral
          Pledge held in their customer account deposited on the Nebeus platform.</span
        >
      </p>
      <p
        class="NormalWeb"
        style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%"
      >
        <strong><span style="font-family: SegoeUI">12.2</span></strong
        ><span style="font-family: SegoeUI">
          The Borrower is the sole legal and beneficial owner of and has good, valid, and marketable
          title to the Collateral Pledge.</span
        >
      </p>
      <p
        class="NormalWeb"
        style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%"
      >
        <strong><span style="font-family: SegoeUI">12.3 </span></strong
        ><span style="font-family: SegoeUI"
          >The Collateral Pledge is free from any Security other than the Security created by this
          deed.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>13. Bankruptcy</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        Either:
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>13.1</strong> the Borrower stops or suspends payment of any of its debts or is
        unable to pay any of its debts as they fall due; or
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>13.2</strong> a petition for a bankruptcy order is presented or a bankruptcy order
        is made against the Borrower, or the Borrower makes an application for a bankruptcy order.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>14. Notices</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>18.1</strong> Any notice or communication given under this Agreement must be in
        writing and sent to the relevant party's address or email specified in this Agreement.
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>14.2</strong> Any notice or other communication to be given under this Agreement
        must be given to the relevant party at the relevant address stated at the start of this
        agreement or as otherwise specified by the relevant party in writing to the other party.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>15. Payment of account charges</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>15.1</strong> The Borrower shall promptly pay all charges and other outgoings in
        respect of the Collateral Pledge and, on demand, produce evidence of payment to the Lender.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>15.2</strong> Security becomes enforceable in the Event of Default.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>15.3</strong> The security constituted by this deed shall become immediately
        enforceable if an Event of Default occurs.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>16. Order of application of proceeds</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        All monies received or recovered by the Lender under this deed or in connection with the
        realization or enforcement of all or part of the Collateral Pledge constituted by this
        agreement, shall (subject to the claims of any person having prior rights and by way of
        variation of the LPA 1925) be applied in the following order of priority (but without
        prejudice to the Lender's right to recover any shortfall from the Borrower):
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>1. </strong>&#xa0;in or towards payment of all costs, liabilities, charges, and
        expenses incurred by or on behalf of the Lender (and any delegate, attorney, or agent
        appointed by it) under or in connection with this agreement;
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>2.</strong> in or towards payment of the Secured Liabilities in any order and manner
        that the Lender determines;
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>3.</strong> and in payment of the surplus (if any) to the Borrower or other person
        entitled to it.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>17 Rights and remedies</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>17.1</strong> The rights and remedies provided under this agreement are cumulative
        and are in addition to, and not exclusive of, any rights and remedies provided by law.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>18. Personal Data Processing</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>18.1</strong> By virtue of this Agreement, the Borrower is hereby informed of the
        fact that any personal data included therein shall be inserted in one or more personal data
        files of the Lender as defined in Law 3/2018, 5th December on the Protection of Personal
        Data and the guarantee of digital rights.
      </p>
      <p
        class="NormalWeb"
        style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%"
      >
        <strong><span style="font-family: SegoeUI">18.2 </span></strong
        ><span style="font-family: SegoeUI"
          >To comply with current legislation, the Borrower is hereby informed of their rights
          regarding personal data, including the right to access, rectify, cancel, or object to the
          processing of their personal data; the right to be forgotten; the right to data
          portability; and the right to limit data processing. These rights may be exercised if the
          personal data are incorrect or incomplete. The Borrower can exercise these rights by
          sending a signed request, including their full name, address, and a photocopy of their ID
          or any other valid identification document, to the following email address: </span
        ><a href="mailto:dpo@nebeus.com" style="text-decoration: none"
          ><span class="Hyperlink" style="font-family: SegoeUI"
            ><span
              class="__cf_email__"
              data-cfemail="
            91f5e1fed1fff4f3f4e4e2bff2fefc"
            ></span
            >dpo@nebeus.com</span
          ></a
        ><span style="font-family: SegoeUI">.</span>
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>19. Entire Agreement, Amendments and Invalidity</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>19.1</strong> This Agreement, along with any exhibits, appendices, schedules, and
        amendments hereto, encompasses the entire agreement of the parties, and supersedes all
        previous understandings and agreements between the Parties.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>19.2</strong> No modification or waiver of the rights conferred by this contract
        shall be valid unless made in writing and signed by the relevant parties.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>19.3</strong> If any provision (or part of a provision) of this deed is or becomes
        invalid, illegal, or unenforceable, it shall be deemed modified to the minimum extent
        necessary to make it valid, legal, and enforceable. If such modification is not possible,
        the relevant provision (or part of a provision) shall be deemed deleted. Any modification to
        or deletion of a provision (or part of a provision) under this clause shall not affect the
        legality, validity, and enforceability of the rest of this deed.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>20. Counterparts</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>20.1</strong> This deed may be executed in any number of counterparts, each of which
        when executed and delivered shall constitute a duplicate original, but all the counterparts
        shall together constitute one deed.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>20.2 </strong>Transmission of the executed signature page of a counterpart of this
        deed by fax or email (in PDF, JPEG, or other agreed format) shall take effect as delivery of
        an executed counterpart of this deed. If either method of delivery is adopted, without
        prejudice to the validity of the deed thus made, each party shall provide the others with
        the original of such counterpart as soon as reasonably possible thereafter.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>20.3</strong> No counterpart shall be effective until each party has executed and
        delivered at least one counterpart.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>21. Third party rights</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>21.1</strong> Except as expressly provided elsewhere in this deed, a person who is
        not a party to this agreement shall not have any rights under the Contracts (Rights of Third
        Parties) Act 1999 to enforce, or enjoy the benefit of, any term of this deed. This does not
        affect any right or remedy of a third party that exists or is available, apart from that
        Act.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>21.2</strong> The rights of the parties to rescind or agree to any amendment or
        waiver under this agreement are not subject to the consent of any other person.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>22. Delivery</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>22.1</strong> Any notice or other communication given to a party under or in
        connection with this deed shall be sent to:
      </p>
      <p style="text-align: justify; line-height: 150%">&#xa0;</p>
      <p style="text-align: justify; line-height: 150%">the Borrower at:</p>
      <p style="text-align: justify; line-height: 150%">&#xa0;</p>
      <p style="text-align: justify; line-height: 150%">Address: {userAddress}</p>
      <p style="text-align: justify; line-height: 150%">Email: {userEmail}</p>
      <p style="text-align: justify; line-height: 150%">Attention: {userName}</p>
      <p style="text-align: justify; line-height: 150%">&#xa0;</p>
      <p style="text-align: justify; line-height: 150%">(ii) the Lender at:</p>
      <p style="text-align: justify; line-height: 150%">&#xa0;</p>
      <p style="text-align: justify; line-height: 150%">Rintral Trading SL</p>
      <p style="text-align: justify; line-height: 150%">C/ de la Llacuna, 11, 08005 Barcelona</p>
      <p style="text-align: justify; line-height: 150%">
        Email:
        <a
          href="mailto:info@nebeus.com"
          class="__cf_email__"
          data-cfemail="84edeae2ebc4eae1e6e1f1f7aae7ebe9"
          >info@nebeus.com</a
        >
      </p>
      <p style="text-align: justify; line-height: 150%">Attention: Financial Department</p>
      <p style="text-align: justify; line-height: 150%">&#xa0;</p>
      <p style="text-align: justify; line-height: 150%">
        or to any other address or fax number/email as is notified in writing by one party to the
        other from time to time.
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>22.2 </strong>This<strong> </strong>clause does not apply to the service of any
        proceedings or other documents in any legal action or, where applicable, any arbitration or
        other method of dispute resolution.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>23. No adverse claims</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>23.1</strong> The Borrower has not received or acknowledged notice of any adverse
        claim by any person in respect of the Collateral Pledge or any interest in it.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>24. No adverse covenants</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        <strong>24.1</strong> There are no covenants, agreements, reservations, conditions,
        interests, rights, or other matters whatsoever that materially and adversely affect the
        Collateral Pledge.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>25. No breach of laws</u></strong>
      </p>
      <p style="text-align: justify; line-height: 150%">
        There is no breach of any law or regulation that materially and adversely affects the
        Collateral Pledge.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>26. Enforceable security</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        This agreement is signed as a deed and constitutes and will constitute the legal, valid,
        binding, and enforceable obligations of the Borrower, and is, and will continue to be,
        effective security overall and every part of the Secured Assets per its terms.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>27. Governing Law and Jurisdiction</u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>27.1</strong> This Agreement and any disputes arising out of it will be governed by
        and construed under the law of Spain.
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        <strong>27.2</strong> Concerning any legal action or proceedings arising out of or in
        connection with this Agreement, the Parties irrevocably submit to the exclusive jurisdiction
        of the Courts and Tribunals of Madrid, waiving their right to any other forum to which they
        may be entitled.
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-left: 18pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: 150%;
        "
      >
        <strong><u>28. Amendments.</u></strong
        ><strong><u>&#xa0; </u></strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        Amendments to this Loan Agreement (including the adding or updating of any Annex,
        appendices, annexures, or schedule) shall not be effective unless in writing and signed by
        authorized signatories on behalf of both parties.
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: right; line-height: 150%">
        <strong>&#xa0;</strong>
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: right; line-height: 150%">
        <strong>Signed by Borrower:</strong>
      </p>
      <div style="text-align: center">
        <hr style="width: 100%; height: 0.05pt; text-align: right" />
      </div>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: right; line-height: 150%">
        [ELECTRONIC SIGNATURE OF BORROWER]
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: right; line-height: 150%">
        &#xa0;
      </p>
      <p style="margin-top: 14pt; margin-bottom: 14pt; line-height: 150%">
        <strong>Signed by Lender:</strong>
      </p>
      <div style="text-align: center">
        <hr style="width: 100%; height: 0.05pt; text-align: right" />
      </div>
      <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify; line-height: 150%">
        [ELECTRONIC SIGNATURE OF LENDER]
      </p>
    </div>
  </body>
</html>
`;
