import { getTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

const schema = yup.object().shape({
  email: yup
    .string()
    .required(getTranslation('VALIDATION_REQUIRED'))
    .email(getTranslation('VALIDATION_EMAIL')),
});

export const validate = makeValidate(schema);
