import clsx from 'clsx';

import { FC, Fragment, ReactNode } from 'react';

import loaderIcon from 'assets/icons/loader.svg';
import loaderThin from 'assets/images/common/loaderThin.svg';

import { Size } from 'types';

import classes from './Loader.module.scss';

interface LoaderProps {
  size?: Size | number;
  className?: string;
  centered?: boolean;
  overlap?: boolean;
  active?: boolean;
  variant?: 'thin' | 'default';
}

interface OverlapWrapperProps {
  children: ReactNode;
  active?: boolean;
}
const OverlapWrapper: FC<OverlapWrapperProps> = ({ active, ...props }) => (
  <div className={clsx(classes.overlap, active && classes.active)} {...props} />
);

const sizeToNumber: { [key in Size]: number } = {
  xl: 48,
  lg: 36,
  md: 32,
  sm: 28,
  xs: 24,
};

export const Loader: FC<LoaderProps> = ({
  size: sizeFromProps = 'md',
  className,
  centered,
  overlap,
  active,
  variant = 'default',
}) => {
  const Wrapper = overlap ? OverlapWrapper : Fragment;

  const size = typeof sizeFromProps === 'number' ? sizeFromProps : sizeToNumber[sizeFromProps];

  const wrapperProps = overlap ? { active } : {};
  return (
    <Wrapper {...wrapperProps}>
      <img
        className={clsx(classes.root, className, centered && 'm-0-auto')}
        src={variant === 'thin' ? loaderThin : loaderIcon}
        alt="Loading"
        style={{ width: size, height: size }}
      />
    </Wrapper>
  );
};
