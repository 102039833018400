import clsx from 'clsx';

import { FC, useMemo } from 'react';

import { DigitalSubscription } from 'modules/digitalAccount/types';
import useRate from 'modules/exchange/hooks/useRate';

import { CurrencyIcon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrency, getCurrencySymbolByCode } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';

import { CurrencyCode } from 'types';

import classes from './SubscriptionCheckCard.module.scss';

interface SubscriptionCheckCardProps {
  subscription: DigitalSubscription;
  paymentCurrency?: CurrencyCode;
  className?: string;
  variant?: 'blurred' | 'default';
}

export const SubscriptionCheckCard: FC<SubscriptionCheckCardProps> = ({
  className,
  paymentCurrency: customPaymentCurrency,
  subscription,
  variant = 'default',
}) => {
  const translate = useTranslation();

  const paymentCurrency = customPaymentCurrency || subscription.payCurrencyCode;

  const rateToGBP = useRate('EUR', 'GBP');

  const pricesByCurrency = useMemo<{ [key in CurrencyCode]?: number }>(
    () => ({
      EUR: subscription.pricePerMonth,
      GBP: subscription.pricePerMonth * (rateToGBP || 1),
    }),
    [rateToGBP, subscription],
  );

  const config = useMemo(() => {
    let result: { label: string; value: string; currencyCode?: CurrencyCode }[] = [];

    if (subscription.isEnding) {
      result = [
        {
          label: translate('ACTIVE_UNTIL'),
          value: formatDDMMYY_HHMM(new Date(subscription.endDate!)),
        },
      ];
    } else {
      result = [
        {
          label: translate('SUBSCRIPTION'),
          value: translate(
            subscription.pricePerMonth ? 'SUBSCRIPTION_MONTHLY_AND_CANCEL_ANYTIME' : 'LIFETIME',
          ),
        },
        {
          label: translate('MONTHLY_PAYMENT'),
          value:
            subscription.pricePerMonth > 0
              ? translate('PRICE_PER_MONTH_SHORT', {
                  currency: getCurrencySymbolByCode(paymentCurrency),
                  amount: formatCurrency(pricesByCurrency[paymentCurrency], true, {
                    withZeros: true,
                  }),
                })
              : translate('FREE'),
        },
      ];

      if (subscription.pricePerMonth > 0) {
        result.push({
          label: translate('PAYMENT_ACCOUNT'),
          value: paymentCurrency,
          currencyCode: paymentCurrency,
        });
      }
    }

    return result;
  }, [paymentCurrency, pricesByCurrency, subscription, translate]);

  return (
    <div className={clsx(classes.root, className, classes['variant-' + variant], 'column gap-1')}>
      {config.map((item, index) => (
        <div key={index} className="row aic jcsb">
          <span className={classes.label}>{item.label}:</span>
          <div className="row aic gap-0-5 tar">
            {item.currencyCode && <CurrencyIcon size={16} code={item.currencyCode} />}
            <span className={classes.value}>{item.value}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
