import { union } from 'lodash';

import {
  RentingItem,
  RentingSchedule,
  RentingSchedulePayoutItem,
  Statistic,
  Template,
  TemplateName,
} from 'modules/cryptoRenting/types';
import { transformList } from 'store/generators/transformers';

import {
  DepositTemplateDto,
  RentingDto,
  RentingMonthlySchedule,
  RentingPayoutScheduleResponse,
  RentingStatisticResponse,
  TemplatesResponse,
} from 'libs/swagger/nebeusApiTypes';

import { CurrencyCode } from 'types';

const templatesOrderByName: { [key in TemplateName]: number } = {
  Baobab: 1,
  Sequoia: 2,
  Bonsai: 3,
  Juniper: 4,
};

const sortTemplates = (a: Template, b: Template) =>
  templatesOrderByName[a.name] - templatesOrderByName[b.name];

export const transformRentingTemplates = (response: TemplatesResponse): Template[] =>
  transformList<DepositTemplateDto, Template>(response.templates?.filter((t) => !t.staking) || [], [
    ['id'],
    ['title', 'name'],
    ['annualPercent', 'percentRPY'],
    // @ts-ignore
    ['rules', 'rentCurrencies', { custom: (rules) => rules?.map((r) => r.currencyCode) || [] }],
    ['payoutPairs', 'withdrawCurrencyPairs'],
    [
      'payoutPairs',
      'withdrawCurrencies',
      {
        custom: (pairs) =>
          pairs ? union(...Object.values(pairs as DepositTemplateDto['payoutPairs'])) : [],
      },
    ],
    ['lockUpPeriod'],
    ['minLength', 'minTermMonth'],
    ['maxLength', 'maxTermMonth'],
    ['rules'],
  ]).sort(sortTemplates);

export const transformRentingItems = (items: RentingDto[]) =>
  transformList<RentingDto, RentingItem>(items, [
    ['id'],
    ['templateId'],
    ['active'],
    ['startDate', 'dateStart'],
    ['endDate', 'dateEnd'],
    ['startAmount', 'rentAmount'],
    ['currency', 'rentCurrency'],
    ['termMonth'],
    ['interestRate', 'percentRPY'],
    ['payoutCurrency', 'withdrawCurrency'],
    ['monthRewardAmount', 'monthRewardAmount'],
    ['totalRewardAmount', 'totalPayout'],
  ]);

export const transformPayoutSchedule = (
  response: RentingPayoutScheduleResponse,
): RentingSchedule => ({
  renting: transformRentingItems([response.payoutSchedule.rentingAccount])[0],
  schedule: transformList<RentingMonthlySchedule, RentingSchedulePayoutItem>(
    response.payoutSchedule.monthlySchedule,
    [
      // @ts-ignore
      ['payoutDate', 'date', { custom: (date: string) => new Date(date) }],
      ['monthlyPayment', 'payoutAmount'],
      ['total', 'totalPayout'],
    ],
  ),
});

export const transformStatistic = (response: RentingStatisticResponse): Statistic => ({
  totalAssets: response.rentingStatistic.totalInvestment,
  assetsPer30Days: response.rentingStatistic.monthReward,
  assetsPer60Days: response.rentingStatistic.twoMonthReward,
  defaultCurrencyCode: response.rentingStatistic.defaultCurrency as CurrencyCode,

  nextRewardCurrency: response.rentingStatistic.nextRewardCurrency as CurrencyCode,
  nextRewardAssets: response.rentingStatistic.nextRewardAmount,
  nextRewardDate: response.rentingStatistic.nextRewardDate,
});
