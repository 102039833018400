import { useCallback, useRef } from 'react';

const useAbortController = () => {
  const controller = useRef<AbortController | null>(null);

  const abort = useCallback(() => {
    if (controller.current) {
      controller.current.abort();
    }
  }, []);

  const generate = useCallback(() => {
    controller.current = new AbortController();
    return controller.current.signal;
  }, []);

  return { abort, generate };
};

export default useAbortController;
