import { Placement } from '@floating-ui/core/src/types';
import clsx from 'clsx';

import {
  ChangeEventHandler,
  FocusEventHandler,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { Icon } from 'components/ui/Icon';
import { Select, SelectProps } from 'components/ui/Select';
import { TextInput, TextInputProps } from 'components/ui/TextInput';

import useFlag from 'hooks/useFlag';
import useMobile from 'hooks/useMobile';

import { useTranslation } from 'libs/i18n';

import classes from './AutoComplete.module.scss';

export interface AutoCompleteProps<T> extends Omit<TextInputProps, 'value' | 'onChange'> {
  value: T | null;
  onChange: (value: T | null) => void;
  options: T[];
  labelAccessor?: (value: T | null) => string;
  keyAccessor?: (value: T) => string;
  autocomplete?: boolean;
  placement?: Placement;
  selectProps?: Partial<SelectProps<T>>;
  withFilterOnMobile?: boolean;
  portalSelector?: string;
  selectSearchInputPlaceholder?: string;
}

export const AutoComplete = <T,>({
  value,
  onChange,
  options,
  labelAccessor,
  keyAccessor,
  className,
  autocomplete: autocompleteFromProps = true,
  error,
  onFocus,
  placement,
  selectProps,
  withFilterOnMobile,
  portalSelector,
  selectSearchInputPlaceholder,
  ...props
}: AutoCompleteProps<T>) => {
  const translate = useTranslation();

  const isMobile = useMobile();

  const autocomplete = isMobile ? false : autocompleteFromProps;

  const select = useFlag(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [filter, setFilter] = useState('');

  const handlePickFromSelect = useCallback(
    (value: T) => {
      setFilter('');
      onChange(value);
    },
    [onChange],
  );

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const filterString = event.target.value;

      const filterExactValue = options.find(
        (option) => (labelAccessor?.(option) || option) === filterString,
      );

      if (filterExactValue) {
        setFilter('');
        onChange(filterExactValue);
        select.off();
      } else {
        setFilter(filterString);
        if (value) {
          onChange(null);
        }
      }
    },
    [labelAccessor, value, options, onChange, select],
  );

  const filteredOptions = useMemo(() => {
    if (filter) {
      const lowerCasedFilter = filter.toLowerCase();
      return options.filter((option) => {
        const label = labelAccessor?.(option) || (option as string);
        return label.toLowerCase().includes(lowerCasedFilter);
      });
    }
    return options;
  }, [labelAccessor, options, filter]);

  const validValue = useMemo<string>(
    () => (labelAccessor ? labelAccessor(value) : typeof value === 'string' ? value : ''),
    [value, labelAccessor],
  );

  const inputError = useMemo(
    () =>
      error ||
      (options.length && !filteredOptions.length
        ? translate('VALIDATION_SELECT_NO_OPTIONS')
        : undefined),
    [translate, error, options, filteredOptions],
  );

  const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      select.on();
      onFocus?.(e);
    },
    [onFocus, select],
  );
  const handleClick = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (autocomplete) {
        select.on();
      } else {
        select.toggle();
      }
    },
    [select, autocomplete],
  );

  const portalNode = portalSelector ? document.querySelector(portalSelector) : null;

  const selectRenderedComponent = (
    <Select
      isOpen={select.state}
      onClose={select.off}
      onPick={handlePickFromSelect}
      options={filteredOptions}
      labelAccessor={labelAccessor}
      keyAccessor={keyAccessor}
      anchorEl={anchorEl}
      placement={placement}
      value={value}
      startAdornment={
        isMobile && withFilterOnMobile ? (
          <TextInput
            className="mb-2"
            value={filter}
            onChange={handleInputChange}
            placeholder={selectSearchInputPlaceholder || translate('PLACEHOLDER_YOUR_COUNTRY')}
            size="lg"
            startAdornment={
              <Icon name="search" size={16} className="ml-2 ml-1" color="kindaBlack" />
            }
          />
        ) : undefined
      }
      className={clsx(selectProps?.className, isMobile && withFilterOnMobile && classes.withFilter)}
      {...selectProps}
    />
  );
  return (
    <div className={className}>
      <TextInput
        // @ts-ignore
        forwardRef={setAnchorEl}
        onFocus={handleFocus}
        onInputContainerClick={handleClick}
        disabled={!autocomplete}
        value={filter || validValue}
        onChange={handleInputChange}
        inputContainerClassName={autocomplete ? '' : classes.inputContainer}
        endAdornment={
          <Icon
            name="triangleDownLg"
            className={clsx(classes.arrow, { [classes.active]: select.state }, 'mr-2')}
            color="kindaBlack"
            size={18}
          />
        }
        error={select.state ? undefined : inputError}
        {...props}
      />

      {portalNode ? createPortal(selectRenderedComponent, portalNode) : selectRenderedComponent}
    </div>
  );
};
