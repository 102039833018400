import { FC, MouseEventHandler, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import {
  selectActiveDigitalSubscription,
  selectAllEndingDigitalSubscription,
  selectSubscriptionMeta,
} from 'modules/digitalAccount/store/selectors';
import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionCard } from 'modules/digitalAccount/views/components/SubscriptionCard';
import { SubscriptionCheckCard } from 'modules/digitalAccount/views/components/SubscriptionCheckCard';
import { SubscriptionStateCard } from 'modules/digitalAccount/views/components/SubscriptionStateCard';

import { Button, Icon, Image, Loader, Mark } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './DigitalSubscriptionManagement.module.scss';

const GetSubscriptionCard = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const handleClick = useCallback(() => {
    drawer.open(ibanAccountDrawerTemplates.accountCreatingPrompt());
  }, [drawer]);

  return (
    <div onClick={handleClick} className={classes.getSubscriptionCard}>
      <div className={classes.content}>
        <h5>
          <span>Nebeus</span>
          <br />
          {translate('DIGITAL_ACCOUNT')}
        </h5>
        <div className={classes.blurredButton}>
          <Icon name="arrowRight" color="white" size="sm" />
        </div>
      </div>
      <Image className={classes.img} name="nebeusBankSubscriptions" path="digitalAccount" />
    </div>
  );
};

interface SubscriptionsCardsProps {
  active?: DigitalSubscription;
  ending: DigitalSubscription[];
}
const SubscriptionsCards: FC<SubscriptionsCardsProps> = ({ active, ending }) => {
  const drawer = useDrawer();
  const translate = useTranslation();

  const handleClickActiveSub = useCallback(() => {
    if (!active) {
      return;
    }
    if (active.isVerificationPending) {
      drawer.open(
        ibanAccountDrawerTemplates.digitalAccountSubscriptionVerificationPending({
          subscriptionType: active.type,
        }),
      );
      return;
    }
    if (!active.isDepositPending && !active.isVerificationPending && !active.isEnding) {
      drawer.open(
        ibanAccountDrawerTemplates.digitalAccountSubscriptionManagement({
          subscription: active,
        }),
      );
    }
  }, [drawer, active]);

  const chooseNewSubscription = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      drawer.open(ibanAccountDrawerTemplates.digitalAccountChooseSubscription());
    },
    [drawer],
  );

  return (
    <div className="column gap-2">
      {active && (
        <SubscriptionStateCard subscription={active} onClick={handleClickActiveSub}>
          {active.type === 'standard' && !ending.length ? (
            <Button
              onClick={chooseNewSubscription}
              className={classes.purchaseNewButton}
              fullWidth
              variant="gold"
            >
              {translate('PURCHASE_NEW_PLAN')}
              <Icon className="ml-1" name="arrowRight" size="sm" />
            </Button>
          ) : null}
        </SubscriptionStateCard>
      )}
      {ending.map((endingSub) => (
        <SubscriptionCard
          key={endingSub.id}
          subscription={endingSub}
          control={<Mark variant="blurredBlack">{translate('CANCELLED')}</Mark>}
        >
          <>
            <SubscriptionCheckCard
              className={classes.checkCard}
              subscription={endingSub}
              paymentCurrency={endingSub.payCurrencyCode}
            />
            <Button
              onClick={chooseNewSubscription}
              className={classes.purchaseNewButton}
              fullWidth
              variant="gold"
            >
              {translate('PURCHASE_NEW_PLAN')}
              <Icon className="ml-1" name="arrowRight" size="sm" />
            </Button>
          </>
        </SubscriptionCard>
      ))}
    </div>
  );
};

export const DigitalSubscriptionManagement = () => {
  const translate = useTranslation();

  const subscriptionsMeta = useSelector(selectSubscriptionMeta);

  const activeSub = useSelector(selectActiveDigitalSubscription);
  const endingSubs = useSelector(selectAllEndingDigitalSubscription);

  const userHasNotSub = !activeSub && !endingSubs.length;

  return (
    <div>
      <div className="aic jcsb row">
        <span className="label">{translate('DIGITAL_ACCOUNT')}</span>
        {!userHasNotSub && <Icon name="chevronRight" color="black40" size="xs" />}
      </div>
      <div className="mt-2">
        {subscriptionsMeta.loading ? (
          <Loader className="mt-10" centered />
        ) : userHasNotSub ? (
          <GetSubscriptionCard />
        ) : (
          <SubscriptionsCards active={activeSub} ending={endingSubs} />
        )}
      </div>
    </div>
  );
};
