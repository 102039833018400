import { format } from 'date-fns';

import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { selectMaintenanceData } from 'modules/app/store/selectors';
import { Maintenance } from 'modules/app/types';

import localStorageKeys from 'constants/localStorageKeys';

import { Icon } from 'components/ui';

import usePersistedState from 'hooks/usePersistedState';

import { useTranslation } from 'libs/i18n';

import classes from './MaintenanceScheduleCard.module.scss';

const dateFormatTemplate = 'cccc dd.MM.yyyy, hh:mm a';

interface MaintenanceScheduleCardUIProps {
  maintenanceData: Omit<Maintenance, 'dateTo' | 'dateFrom'> & {
    dateFrom: string;
    dateTo: string;
  };
}
const MaintenanceScheduleCardUI: FC<MaintenanceScheduleCardUIProps> = ({ maintenanceData }) => {
  const translate = useTranslation();

  const [dateFrom, dateTo] = [
    format(new Date(maintenanceData.dateFrom), dateFormatTemplate),
    format(new Date(maintenanceData.dateTo), dateFormatTemplate),
  ];

  const localStorageKey =
    localStorageKeys.maintenanceScheduleRead +
    `${maintenanceData.dateFrom}-${maintenanceData.dateTo}`;

  const [read, setRead] = usePersistedState(localStorageKey);

  const handleClickClose = () => {
    setRead(Date.now());
  };

  if (read) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Icon onClick={handleClickClose} name="cross" className={classes.close} />
      <span>{translate('MAINTENANCE_SCHEDULED_TITLE')}</span>
      <p>{translate('MAINTENANCE_SCHEDULED_SUBTITLE', { dateFrom, dateTo })}</p>
    </div>
  );
};
const MaintenanceScheduleCard = memo(() => {
  const maintenanceData = useSelector(selectMaintenanceData);

  if (maintenanceData.dateTo && maintenanceData.dateFrom) {
    // @ts-ignore
    return <MaintenanceScheduleCardUI maintenanceData={maintenanceData} />;
  }

  return null;
});

export default MaintenanceScheduleCard;
