import { useCallback, useEffect, useState } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { requestTFASetupKey } from 'modules/auth/store/thunks';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import { useDispatch } from 'store';

import { Button, CopyButton } from 'components/ui';
import { Loader } from 'components/ui/Loader';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';
import { AuthenticatorSecrets } from 'libs/swagger/nebeusApiTypes';

import classes from './SetupTFA.module.scss';

const SetupTFA = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const drawer = useDrawer();

  const [tfaSetup, setTfaSetup] = useState<AuthenticatorSecrets | null>(null);

  const loading = useFlag(false);

  const initTFA = useCallback(async () => {
    loading.on();
    const { success, data } = await dispatch(requestTFASetupKey());
    loading.off();
    if (success && data) {
      setTfaSetup(data);
    }
  }, [loading, dispatch]);

  const handleNext = useCallback(() => {
    if (!tfaSetup) {
      return;
    }
    drawer.replace(
      userDrawerTemplates.checkTFACode({
        variant: 'setup',
        resetCode: tfaSetup.resetKey,
      }),
    );
  }, [tfaSetup, drawer]);

  useEffect(() => {
    initTFA();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: translate('TFA_SETUP_SUBTITLE') }} />
      {loading.state ? (
        <Loader className="m-0-auto mt-10" />
      ) : (
        <>
          <div className="simpleCard mt-3 row aic jcsb">
            <div className="column">
              <span className="label">{translate('TFA_SETUP_KEY')}</span>
              <span className={classes.tfaKey}>{tfaSetup?.setUpKey}</span>
            </div>
            <CopyButton className="ml-2 flex-shrink-0" value={tfaSetup?.setUpKey} />
          </div>
          <div className={classes.creamyCard}>
            <div className={classes.qrWrapper}>
              <img src={tfaSetup?.qrCodeUrl} alt="TFA Qr" />
            </div>
            <span>{translate('TFA_SETUP_ALTERNATIVE')}</span>
          </div>
          <div className="row mt-3">
            <Button
              variant="darkGreenOutlined"
              className="mr-1-5"
              fullWidth
              onClick={() => drawer.pop()}
            >
              {translate('CANCEL')}
            </Button>
            <Button fullWidth onClick={handleNext}>
              {translate('NEXT')}
            </Button>
          </div>
        </>
      )}
    </>
  );
};
export default SetupTFA;
