import clsx from 'clsx';

import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useSideBar from 'modules/app/hooks/useSideBar';
import rentingCommonClasses from 'modules/cryptoRenting/constants/RentingCommon.module.scss';
import { TemplateImage } from 'modules/cryptoRenting/views/CryptoRenting/components/TemplateImage';
import { AppStore } from 'store';

import { AmountLabel, Button, Mark } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { CurrencyCode } from 'types';

import classes from './RentingSetupSuccess.module.scss';

interface RentingSetupSuccessProps {
  rent: { amount: string; currency: CurrencyCode };
  templateId: number;
}

const RentingSetupSuccess: FC<RentingSetupSuccessProps> = ({ rent, templateId }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const selectTemplate = useCallback(
    (state: AppStore) => state.cryptoRenting.templates.data.find((t) => t.id === templateId),
    [templateId],
  );
  const template = useSelector(selectTemplate);

  return template ? (
    <div className="pt-10">
      <div className="column aic">
        <div className="relative">
          <TemplateImage size="lg" templateName={template.name} />
          <Mark className={classes.mark} rounded variant="green">
            {translate('ACTIVE')}
          </Mark>
        </div>
        <div className="mt-3 column aic">
          <span
            className={clsx(
              classes.templateTitle,
              rentingCommonClasses[`templateLabel` + template.name],
            )}
          >
            {template.name}, {template.percentRPY}% {translate('RENTING_RPY')}
          </span>
          <AmountLabel
            className="mt-1-5"
            size="xl"
            amount={rent.amount}
            currencyCode={rent.currency}
            showCurrencyLabel
          />
        </div>
      </div>
      <div className={classes.blurredCard}>
        <h3>{translate('RENTING_SETUP_SUCCESS')}</h3>
        <p>{translate('RENTING_SETUP_SUCCESS_TEXT')}</p>
      </div>

      <Button onClick={sidebar.close} className="mt-3" fullWidth variant="lightGreen" showShadow>
        {translate('CLOSE')}
      </Button>
    </div>
  ) : null;
};

export default RentingSetupSuccess;
