import { useEffect } from 'react';

import { accountsActions } from 'modules/accounts/store';
import { AccountType } from 'modules/accounts/types';
import { LastTransactions } from 'modules/accounts/views/LastTransactions';
import { ReactNativeHeader } from 'modules/app/views/TradingAccountDashboard/components/ReactNativeHeader';
import { isReactNative } from 'modules/reactNative/utils';
import { useDispatch } from 'store';

import classes from './TradingAccountDashboard.module.scss';
import { Assets, BlogCarousel, LoanNotificationsCarousel } from './components';
import BankCards from './components/BankCards';

const TradingAccountDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountsActions.updateAccountType(AccountType.crypto));
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      {isReactNative && <ReactNativeHeader />}
      <LoanNotificationsCarousel />
      <Assets />
      <BlogCarousel />
      <BankCards />
      <LastTransactions accountType={AccountType.crypto} />
      {/*<FollowedCurrencies /> TODO: Remove in future */}
    </div>
  );
};

export default TradingAccountDashboard;
