import { FC } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';

import { Button, CopyButton } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './EmergencyResetCode.module.scss';

export interface EmergencyResetCodeProps {
  resetCode: string;
}

const EmergencyResetCode: FC<EmergencyResetCodeProps> = ({ resetCode }) => {
  const translate = useTranslation();

  const sideBar = useSideBar();

  return (
    <div>
      <p className="darkGrey">{translate('TFA_EMERGENCY_RESET_CODE_SUBTITLE')}</p>
      <div className="simpleCard mt-3 row aic jcsb">
        <div className="column">
          <span className="label">{translate('TFA_EMERGENCY_RESET_CODE_TITLE')}</span>
          <span className={classes.tfaKey}>{resetCode}</span>
        </div>
        <CopyButton className="ml-2 flex-shrink-0" value={resetCode} />
      </div>
      <Button fullWidth className="mt-3" onClick={sideBar.pop}>
        {translate('BACK')}
      </Button>
    </div>
  );
};

export default EmergencyResetCode;
