import { format } from 'date-fns';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectExistingWallets } from 'modules/accounts/store/selectors';
import { TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import {
  selectAllowedDebitCardPaymentCurrencyCodesReducer,
  selectBankCardConfigReducer,
} from 'modules/cryptoBankCard/store/selectors';
import {
  requestAllowedDebitCardPaymentCurrencyCodes,
  requestConfig,
  requestOrderDebitCard,
  requestOrderVirtualCard,
} from 'modules/cryptoBankCard/store/thunks';
import { DeliveryPlan } from 'modules/cryptoBankCard/types';
import { ShippingDetailsFormValues } from 'modules/cryptoBankCard/views/OrderBankCardShippingDetails';
import useGetRate from 'modules/exchange/hooks/useGetRate';
import useRate from 'modules/exchange/hooks/useRate';
import { useDispatch } from 'store';

import { AgreementText, BankCardOrder } from 'components/common';
import { CurrencyAmountField, SimpleCurrencyAmountField, SubmitButton } from 'components/form';
import { ImportantCard, Loader, SummaryCard } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';
import { SummaryCardProps } from 'components/ui/SummaryCard';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';
import {
  CryptoCardOrderPhysicalRequest,
  CryptoCardOrderVirtualRequest,
} from 'libs/swagger/nebeusApiTypes';
import yup, { makeValidate } from 'libs/yup';

import { findByProperty } from 'utils/arrayUtils';
import { formatCurrency, formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

export type OrderBankCardConfirmationProps =
  | {
      shippingValues: ShippingDetailsFormValues;
      deliveryPlan: DeliveryPlan;
      isVirtual: false;
      userData?: never;
    }
  | {
      isVirtual: boolean;
      shippingValues?: never;
      deliveryPlan?: never;
      userData: { phoneNumber: string; dateOfBirth: string };
    };

interface FormValues {
  currencyAmount: CurrencyAmount;
}

export interface OrderBankCardConfirmationFormProps extends FormRenderProps<FormValues> {
  totalPrice: number;
  paymentWallets: TradingWallet[];
  walletsLoading: boolean;
  userHasFundsToPay: boolean;
  allowedPaymentCurrencies: CurrencyCode[];
  walletWithEnoughFunds?: TradingWallet;
}
const OrderBankCardConfirmationForm: FC<OrderBankCardConfirmationFormProps> = ({
  handleSubmit,
  totalPrice,
  values,
  form,
  paymentWallets,
  walletsLoading,
  userHasFundsToPay,
  allowedPaymentCurrencies,
  walletWithEnoughFunds,
}) => {
  const translate = useTranslation();

  const rateToEur = useRate(values.currencyAmount.currency, 'EUR', { autoFetch: true });

  const totalCostsConfig = useMemo<SummaryCardProps['config']>(() => {
    const result = [
      {
        id: '1',
        label: translate('TOTAL_AMOUNT'),
        value: formatCurrencyWithSymbol(totalPrice, 'EUR'),
      },
    ];

    if (values.currencyAmount.currency !== 'EUR') {
      result.push(
        {
          id: '2',
          label: translate('TOTAL_AMOUNT_IN_CURRENCY', {
            currencyCode: values.currencyAmount.currency,
          }),
          value: formatCurrencyWithSymbol(totalPrice / rateToEur, values.currencyAmount.currency),
        },
        {
          id: '3',
          label: translate('EXCHANGE_RATE'),
          value: formatCurrencyWithSymbol(rateToEur, 'EUR'),
        },
      );
    }

    return result;
  }, [totalPrice, rateToEur, values.currencyAmount.currency, translate]);

  useEffect(() => {
    // If user has funds set to dropdown amount wallet amount, otherwise set to dropdown amount card price
    const wallet = findByProperty(paymentWallets, 'currencyCode', values.currencyAmount.currency);

    const amount = userHasFundsToPay ? wallet?.amount || 0 : totalPrice / rateToEur;

    form.change('currencyAmount', {
      amount: formatCurrency(amount, values.currencyAmount.currency, {
        withZeros: true,
      }),
      currency: values.currencyAmount.currency,
    });
    // eslint-disable-next-line
  }, [values.currencyAmount.currency, paymentWallets.length]);

  useEffect(() => {
    // Autofill with wallet with enough funds
    if (
      totalPrice > 0 &&
      walletWithEnoughFunds &&
      values.currencyAmount.currency === initialValues.currencyAmount.currency
    ) {
      form.change('currencyAmount', {
        amount: formatCurrency(walletWithEnoughFunds.amount, walletWithEnoughFunds.currencyCode, {
          withZeros: true,
        }),
        currency: walletWithEnoughFunds.currencyCode,
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(walletWithEnoughFunds)]);

  return (
    <form onSubmit={handleSubmit} className="column gap-6 flex-1 jcsb">
      <SummaryCard
        variant="outlined"
        config={totalCostsConfig}
        endAdornment={
          !totalPrice ? undefined : userHasFundsToPay ? (
            <Field
              name="currencyAmount"
              component={CurrencyAmountField}
              label={translate('SELECT_ACCOUNT_FOR_PAYMENT')}
              currenciesList={paymentWallets}
              disabled
              showErrorIfExist
              loading={walletsLoading}
            />
          ) : (
            <Field
              name="currencyAmount"
              component={SimpleCurrencyAmountField}
              label={translate('SELECT_ACCOUNT_FOR_PAYMENT')}
              currenciesList={allowedPaymentCurrencies}
              disabled
              loading={walletsLoading}
            />
          )
        }
      />
      <div className="column gap-1-5">
        <SubmitButton fullWidth variant="greyishGreen" />
        <AgreementText variant="cryptoBankCard" />
      </div>
    </form>
  );
};

const initialValues: FormValues = {
  currencyAmount: {
    amount: '',
    currency: 'EUR',
  },
};
const OrderBankCardConfirmation: FC<OrderBankCardConfirmationProps> = ({
  shippingValues,
  deliveryPlan,
  isVirtual,
  userData,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const { entityReducer: allowedDebitCardPaymentCurrencyCodes } = useStoreEntity(
    selectAllowedDebitCardPaymentCurrencyCodesReducer,
    requestAllowedDebitCardPaymentCurrencyCodes,
  );

  const existingTradingWallets = useSelector(selectExistingWallets);
  const paymentWallets = useMemo(
    () =>
      existingTradingWallets.filter((w) =>
        allowedDebitCardPaymentCurrencyCodes.data.includes(w.currencyCode),
      ),
    [allowedDebitCardPaymentCurrencyCodes, existingTradingWallets],
  );

  const { entityReducer: configReducer } = useStoreEntity(
    selectBankCardConfigReducer,
    requestConfig,
  );

  const releasePriceInEur = isVirtual
    ? configReducer.data?.virtualCardReleasePriceInEur || 0
    : configReducer.data?.cardReleasePriceInEur || 0;

  const deliveryPriceInEur = deliveryPlan?.priceInEur || 0;

  const totalPriceInEur = isVirtual ? releasePriceInEur : releasePriceInEur + deliveryPriceInEur;

  const minAmountToProcessOrderInEur =
    (isVirtual
      ? configReducer.data?.minAmountToProcessVirtualCardOrderInEur
      : configReducer.data?.minAmountToProcessCardOrderInEur) ?? 0;

  const getRate = useGetRate();

  const exchangeFromEurToCurrencyCode = useCallback(
    (amount: number, currencyCode: CurrencyCode) => amount / getRate(currencyCode, 'EUR'),
    [getRate],
  );

  const walletWithEnoughFunds = useMemo(
    () =>
      paymentWallets.find(
        (w) => w.amount >= exchangeFromEurToCurrencyCode(totalPriceInEur, w.currencyCode),
      ),
    [exchangeFromEurToCurrencyCode, totalPriceInEur, paymentWallets],
  );

  const userHasFundsToPay = totalPriceInEur === 0 || !!walletWithEnoughFunds;

  const needDepositForOrderProcessing = useMemo(() => {
    return (
      minAmountToProcessOrderInEur > 0 &&
      !paymentWallets.some(
        (w) =>
          w.amount >= exchangeFromEurToCurrencyCode(minAmountToProcessOrderInEur, w.currencyCode),
      )
    );
  }, [paymentWallets, exchangeFromEurToCurrencyCode, minAmountToProcessOrderInEur]);

  const summaryConfigDelivery = useMemo<SummaryCardProps['config']>(
    () =>
      !isVirtual && shippingValues
        ? [
            {
              id: '2',
              label: translate('COUNTRY'),
              value: shippingValues.shippingAddress.country!.name,
            },
            { id: '3', label: translate('CITY'), value: shippingValues.shippingAddress.city },
            {
              id: '4',
              label: translate('ADDRESS'),
              value:
                shippingValues.shippingAddress.address1 +
                (shippingValues.shippingAddress.address2
                  ? `, ${shippingValues.shippingAddress.address2}`
                  : ''),
            },
            {
              id: '5',
              label: translate('RECIPIENTS_NAME'),
              value: `${shippingValues.firstName} ${shippingValues.lastName}`,
            },
            {
              id: '7',
              label: translate('COST'),
              value: formatCurrencyWithSymbol(deliveryPlan.priceInEur, 'EUR'),
            },
          ]
        : [],
    [isVirtual, translate, deliveryPlan, shippingValues],
  );

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const paymentAmount = exchangeFromEurToCurrencyCode(
        totalPriceInEur,
        values.currencyAmount.currency,
      );
      if (isVirtual) {
        const [dd, mm, yyyy] = userData.dateOfBirth.split('.');
        const defaultJSDateString = [mm, dd, yyyy].join('/');
        const payload: CryptoCardOrderVirtualRequest = {
          clientDateOfBirth: format(new Date(defaultJSDateString), 'yyyy-MM-dd'),
          clientPhoneNumber: userData.phoneNumber.replaceAll('+', ''),
          paymentCurrencyCode: values.currencyAmount.currency,
          paidOrder: userHasFundsToPay,
          requestSource: 'IN_APP',
        };

        const { success } = await dispatch(requestOrderVirtualCard(payload));
        if (success) {
          drawer.replaceAll(
            cryptoBankCardDrawerTemplates.orderBankCardSuccess({
              isVirtual,
              userHasFundsToPay,
              needDepositForOrderProcessing,
              minAmountToProcessOrderInEur,
              paymentWalletCurrencyCode: values.currencyAmount.currency,
              paymentAmount,
            }),
          );
        }
      } else {
        if (shippingValues && values) {
          const [dd, mm, yyyy] = shippingValues.birthday.split('.');
          const defaultJSDateString = [mm, dd, yyyy].join('/');
          const payload: CryptoCardOrderPhysicalRequest = {
            firstName: shippingValues.firstName,
            lastName: shippingValues.lastName,
            countryIso2Code: shippingValues.shippingAddress.country!.code,
            city: shippingValues.shippingAddress.city,
            address1: shippingValues.shippingAddress.address1,
            address2: shippingValues.shippingAddress.address2,
            postCode: shippingValues.shippingAddress.postCode,
            paymentCurrencyCode: values.currencyAmount.currency,
            clientDateOfBirth: format(new Date(defaultJSDateString), 'yyyy-MM-dd'),
            clientPhoneNumber: shippingValues.phoneNumber.replaceAll('+', ''),
            deliveryTypeId: deliveryPlan.id,
            paidOrder: userHasFundsToPay,
            requestSource: 'IN_APP',
          };
          // @ts-ignore For analytic purpose
          payload['analyticField_deliveryName'] = deliveryPlan.name;

          const { success } = await dispatch(requestOrderDebitCard(payload));

          if (success) {
            drawer.replaceAll(
              cryptoBankCardDrawerTemplates.orderBankCardSuccess({
                isVirtual,
                userHasFundsToPay,
                needDepositForOrderProcessing,
                minAmountToProcessOrderInEur,
                paymentWalletCurrencyCode: values.currencyAmount.currency,
                paymentAmount,
              }),
            );
          }
        }
      }
    },
    [
      exchangeFromEurToCurrencyCode,
      totalPriceInEur,
      minAmountToProcessOrderInEur,
      needDepositForOrderProcessing,
      drawer,
      dispatch,
      shippingValues,
      deliveryPlan,
      isVirtual,
      userData,
      userHasFundsToPay,
    ],
  );

  const schema = useMemo(
    () =>
      !totalPriceInEur
        ? yup.object().optional()
        : userHasFundsToPay
        ? yup.object().shape({
            currencyAmount: yup.object().test({
              message: translate('VALIDATION_INSUFFICIENT_FUNDS'),
              test: (currencyAmount) => {
                const wallet = findByProperty(
                  existingTradingWallets,
                  'currencyCode',
                  currencyAmount.currency,
                );

                const totalPriceInWalletCurrency = exchangeFromEurToCurrencyCode(
                  totalPriceInEur,
                  currencyAmount.currency,
                );
                return (
                  (wallet?.amount || 0) >=
                  Number(formatCurrency(totalPriceInWalletCurrency, currencyAmount.currency))
                );
              },
            }),
          })
        : yup
            .object()
            .shape({ currencyAmount: yup.object().required(translate('VALIDATION_REQUIRED')) }),

    [
      exchangeFromEurToCurrencyCode,
      totalPriceInEur,
      translate,
      existingTradingWallets,
      userHasFundsToPay,
    ],
  );

  const validate = useMemo(() => makeValidate(schema), [schema]);

  const summaryConfigPayment: SummaryCardProps['config'] = useMemo(
    () => [
      {
        id: '1',
        label: translate('CARD_ISSUE'),
        value: releasePriceInEur
          ? formatCurrencyWithSymbol(releasePriceInEur, 'EUR')
          : translate('FREE'),
        loading: configReducer.meta.loading,
      },
    ],
    [translate, releasePriceInEur, configReducer.meta.loading],
  );

  return (
    <div className="flex-1 column gap-2 jcsb mt-1">
      <BankCardOrder isVirtual={isVirtual} />
      <SummaryCard variant="outlined" config={summaryConfigPayment} />
      {!isVirtual && (
        <SummaryCard
          variant="outlined"
          config={summaryConfigDelivery}
          startAdornment={
            <div className="row aic jcsb gap-2">
              <span className="label">{translate('DELIVERY')}</span>
              <span
                className="label cyanBlue pointer"
                onClick={() => {
                  drawer.pop();
                }}
              >
                {translate('EDIT')}
              </span>
            </div>
          }
        />
      )}

      {allowedDebitCardPaymentCurrencyCodes.meta.loading || configReducer.meta.loading ? (
        <div className="flex-1 jcc aic">
          <Loader centered />
        </div>
      ) : (
        <Form
          onSubmit={handleSubmit}
          // @ts-ignore
          render={OrderBankCardConfirmationForm}
          initialValues={initialValues}
          validate={validate}
          paymentWallets={paymentWallets}
          walletsLoading={allowedDebitCardPaymentCurrencyCodes.meta.loading}
          totalPrice={totalPriceInEur}
          configLoading={configReducer.meta.loading}
          userHasFundsToPay={userHasFundsToPay}
          allowedPaymentCurrencies={allowedDebitCardPaymentCurrencyCodes.data}
          walletWithEnoughFunds={walletWithEnoughFunds}
        />
      )}
      {needDepositForOrderProcessing && (
        <ImportantCard
          text={translate('CRYPTO_BANK_CARD_ISSUANCE_RULE', {
            minAmount: minAmountToProcessOrderInEur,
          })}
        />
      )}
    </div>
  );
};

export default OrderBankCardConfirmation;
