import clsx from 'clsx';

import { ChangeEventHandler, FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';

import { CurrencyIcon, Icon, NoResultLabel, TextInput } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './SelectCurrency.module.scss';

export interface SelectCurrencyProps {
  selectedCurrency?: CurrencyCode;
  currencies: CurrencyCode[];
  onPick: (code: CurrencyCode) => void;
}

// using in sidebar
const SelectCurrency: FC<SelectCurrencyProps> = ({ selectedCurrency, currencies, onPick }) => {
  const translate = useTranslation();

  const [filter, setFilter] = useState('');

  const currenciesWithLabel = useMemo(
    () => currencies.map((code) => ({ code, label: getCurrencyLabelByCode(code) })),
    [currencies],
  );

  const handlePick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      onPick(event.currentTarget.dataset.code as CurrencyCode);
    },
    [onPick],
  );

  const handleFilter = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setFilter(e.currentTarget.value);
  }, []);

  const filteredList = useMemo(() => {
    if (!filter) {
      return currenciesWithLabel;
    }
    const filterUppercase = filter.toUpperCase();

    return currenciesWithLabel.filter(
      (c) => c.code.includes(filterUppercase) || c.label.toUpperCase().includes(filterUppercase),
    );
  }, [currenciesWithLabel, filter]);

  return (
    <div className={classes.root}>
      <TextInput
        className={classes.input}
        value={filter}
        onChange={handleFilter}
        startAdornment={<Icon className={classes.searchIcon} size={18} name="search" />}
        placeholder={translate('PLACEHOLDER_SEARCH_CURRENCIES')}
        variant="creamyWhite"
        borderOnFocus={false}
      />
      <div className={classes.list}>
        {filteredList.map((currency) => (
          <div
            key={currency.code}
            className={clsx(
              classes.listItem,
              selectedCurrency === currency.code && classes.active,
              'row aic jcsb',
            )}
            data-code={currency.code}
            onClick={handlePick}
          >
            <div className="row">
              <CurrencyIcon code={currency.code} size={24} className={classes.currencyIcon} />
              <div className="column">
                <span className={classes.label}>{currency.label}</span>
                <span className={classes.code}>{currency.code}</span>
              </div>
            </div>
            {selectedCurrency === currency.code && (
              <Icon size={10} name="check" className={classes.checkIcon} />
            )}
          </div>
        ))}
        {!filteredList.length ? <NoResultLabel findString={filter} /> : null}
      </div>
    </div>
  );
};

export default SelectCurrency;
