import authServices from 'modules/auth/services';
import { SmartTrade } from 'modules/smartTrader/types';

import SmartTraderAxiosInstance from 'libs/axios/SmartTraderAxiosInstance';

import { CurrenciesDirection, CurrencyCode } from 'types';

// cli-import

interface GetAvailableDirectionApiResponse {
  pairs: CurrenciesDirection[];
}
interface GetTradesApiResponse {
  trades: SmartTrade[];
}

export type TradeHistoryOperationType =
  | 'TRADE_CREATED'
  | 'BUY_OPERATION'
  | 'SELL_OPERATION'
  | 'STOP_LOSS_TRIGGERED'
  | 'TAKE_PROFIT_TRIGGERED'
  | 'STOP_LOSS_TAKE_PROFIT_CHANGED';

interface GetTradeHistoryApiResponse {
  data: {
    id: string;
    tradeId: string;
    userId: string;
    operationType: TradeHistoryOperationType;
    operationAmount: number | null;
    date: string;
  }[];

  offset: number;
  limit: number;
  total: number;
}
const smartTraderServices = {
  attachFCMToken: (token: string) => {
    if (authServices.getAccessToken()) {
      return SmartTraderAxiosInstance.post('/user/attach-firebase-cloud-message-token-web', {
        token,
      });
    }
  },
  attachFCMTokenMobile: (token: string) => {
    if (authServices.getAccessToken()) {
      return SmartTraderAxiosInstance.post('/user/attach-firebase-cloud-message-token', {
        token,
      });
    }
  },
  detachFCMTokenMobile: () =>
    SmartTraderAxiosInstance.post('/user/detach-firebase-cloud-message-token'),
  detachFCMToken: () =>
    SmartTraderAxiosInstance.post('/user/detach-firebase-cloud-message-token-web'),
  getAvailableDirections: () =>
    SmartTraderAxiosInstance.get<
      GetAvailableDirectionApiResponse,
      GetAvailableDirectionApiResponse
    >('/rates/available-currency-pairs').then((response) => response.pairs),
  getTrades: () =>
    SmartTraderAxiosInstance.get<GetTradesApiResponse, GetTradesApiResponse>('/trade').then(
      (response) => response.trades,
    ),
  createTrade: (payload: {
    primaryCurrency: CurrencyCode;
    secondaryCurrency: CurrencyCode;
    primaryAmount: number;
    secondaryAmount: number;
    takeProfitAmount: number;
    stopLossAmount: number;
  }) => SmartTraderAxiosInstance.post('/trade/create', payload),

  getTransactionHistory: (payload: { id: string; offset: number; limit?: number }) =>
    SmartTraderAxiosInstance.post<GetTradeHistoryApiResponse, GetTradeHistoryApiResponse>(
      '/trade/history',
      { limit: 10, ...payload },
    ),
  commitExchangeOperation: (payload: {
    fromCurrency: CurrencyCode;
    toCurrency: CurrencyCode;
    fromAmount: number;
    toAmount: number;
    exchangeRate: number;
  }) => SmartTraderAxiosInstance.post('/trade/exchange-operation', payload),
  // cli-service
};

export default smartTraderServices;
