import clsx from 'clsx';
import get from 'lodash/get';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import { TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import rentingCommonClasses from 'modules/cryptoRenting/constants/RentingCommon.module.scss';
import { selectTemplates } from 'modules/cryptoRenting/store/selectors';
import { Template, TemplateName } from 'modules/cryptoRenting/types';
import { CurrenciesCarousel } from 'modules/cryptoRenting/views/CryptoRenting/components/CurrenciesCarousel';
import { TemplateHeader } from 'modules/cryptoRenting/views/CryptoRenting/components/TemplateHeader';
import RentingSetupPayoutCalculations from 'modules/cryptoRenting/views/RentingSetupPayoutCalculations';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import useRate from 'modules/exchange/hooks/useRate';
import { isReactNative } from 'modules/reactNative/utils';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';

import { AgreementText } from 'components/common';
import { CurrencyAmountField, SliderField, SubmitButton } from 'components/form';
import { Button, Icon, InfoIcon } from 'components/ui';
import { CurrencyPickerItem } from 'components/ui/CurrencyPicker';

import useVerificationCheck from 'hooks/useVerificationCheck';

import { useTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import {
  formatCurrency,
  formatCurrencyWithLabel,
  formatCurrencyWithSymbol,
  mainCryptoCodes,
  stableCoinsCodes,
} from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './RentingSetup.module.scss';

export interface RentingSetupProps {
  templateName: TemplateName;
  rentCurrency?: CurrencyCode;
}

interface FormValues {
  rent: { amount: string; currency: CurrencyCode };
  term: number;
  withdrawCurrency: CurrencyCode;
}

interface RentingSetupFormProps extends FormRenderProps<FormValues> {
  template: Template;
  wallets: TradingWallet[];
}

interface TemplateShortInfoProps {
  template: Template;
  rentCurrency: CurrencyCode;
}

const TemplateShortInfo: FC<TemplateShortInfoProps> = ({ template, rentCurrency }) => {
  const translate = useTranslation();

  const templateInfo = useMemo(() => {
    const currentRule = template.rules.find((r) => r.currencyCode === rentCurrency);
    return [
      {
        label: translate('RENTING_LOCKUP_PERIOD'),
        value: translate('DATE_MONTH_PLURAL', { count: template.minTermMonth }),
        answerModal: {
          title: translate('RENTING_LOCKUP_PERIOD_FAQ_TITLE'),
          description: translate('RENTING_LOCKUP_PERIOD_FAQ_DESCRIPTION_ALTERNATIVE'),
        },
      },
      {
        label: translate('RENTING_REWARD_PAYOUT'),
        value: translate('RENTING_PAYOUT_MONTHLY'),
        answerModal: {
          title: translate('RENTING_REWARD_PAYOUT_FAQ_TITLE'),
          description: translate('RENTING_REWARD_PAYOUT_FAQ_DESCRIPTION'),
        },
      },
      {
        label: translate('RENTING_MINIMUM_DEPOSIT'),
        value: formatCurrencyWithLabel(currentRule!.minAmount, currentRule!.currencyCode),
        answerModal: {
          title: translate('RENTING_MINIMUM_DEPOSIT_FAQ_TITLE'),
          description: translate('RENTING_MINIMUM_DEPOSIT_FAQ_DESCRIPTION'),
        },
      },
    ];
  }, [template, rentCurrency, translate]);

  return (
    <div className="outlinedCard p-1-5 mt-1-5 column gap-1">
      {templateInfo.map((item) => (
        <div key={item.label} className="row jcsb">
          <span className={classes.shortInfoLabel}>{item.label}</span>
          <div className="row aic">
            <span className={classes.shortInfoValue}>{item.value}</span>
            <InfoIcon className="ml-1" size="xs" {...item.answerModal} />
          </div>
        </div>
      ))}
    </div>
  );
};
const withdrawCurrenciesSortingPriority = [...mainCryptoCodes, ...stableCoinsCodes].reverse();

const RentingSetupForm: FC<RentingSetupFormProps> = ({
  handleSubmit,
  values,
  template,
  form,
  wallets,
  errors,
}) => {
  const translate = useTranslation();
  const sidebar = useSideBar();
  const drawer = useDrawer();

  const defaultCurrency = useSelector(selectUserDefaultCurrencyCode);

  const rateRentToDefCurrency = useRate(values.rent.currency, defaultCurrency, { autoFetch: true });

  const defaultCurrencyCalculatorComponent = useMemo(() => {
    return (
      <div className={classes.defaultCurrencyAmount}>
        <span>
          {translate('IN_CURRENCY')} {defaultCurrency}
        </span>{' '}
        <span>
          {formatCurrencyWithSymbol(rateRentToDefCurrency * +values.rent.amount, defaultCurrency)}
        </span>
      </div>
    );
  }, [values.rent.amount, defaultCurrency, rateRentToDefCurrency, translate]);

  const handleChangeWithdrawCurrency = useCallback(
    (code: CurrencyCode) => {
      form.change('withdrawCurrency', code);
    },
    [form],
  );

  const currentWithdrawCurrencies = useMemo(() => {
    const allowed = [...template.withdrawCurrencyPairs[values.rent.currency]];
    allowed.sort((a, b) => {
      if (a === values.withdrawCurrency) {
        return -1;
      }
      if (b === values.withdrawCurrency) {
        return 1;
      }

      return (
        withdrawCurrenciesSortingPriority.indexOf(b) - withdrawCurrenciesSortingPriority.indexOf(a)
      );
    });

    return allowed;
  }, [template.withdrawCurrencyPairs, values.withdrawCurrency, values.rent.currency]);

  const handleClickSearch = useCallback(() => {
    sidebar.open('selectCurrency', {
      sideBarProps: {
        swipeModal: true,
        contentClassName: clsx(
          rentingCommonClasses.rentingSideBar,
          rentingCommonClasses['templateBg-' + template.name],
          rentingCommonClasses.mask,
          isReactNative && rentingCommonClasses.maskNoBlur,
        ),
      },
      props: {
        selectedCurrency: values.withdrawCurrency,
        currencies: currentWithdrawCurrencies,
        onPick: (code: CurrencyCode) => {
          sidebar.pop();
          form.change('withdrawCurrency', code);
        },
      },
    });
  }, [sidebar, template, currentWithdrawCurrencies, values.withdrawCurrency, form]);

  const rentCurrenciesList = useMemo<CurrencyPickerItem[]>(
    () => wallets.filter((w) => template.rentCurrencies.includes(w.currencyCode)),
    [wallets, template],
  );

  useEffect(
    () => {
      form.change('withdrawCurrency', values.rent.currency);
    },
    // eslint-disable-next-line
    [values.rent.currency],
  );

  const isInsufficientFunds =
    get(errors, 'rent.amount') === translate('VALIDATION_INSUFFICIENT_FUNDS');

  const handleBuy = useCallback(() => {
    drawer.open(
      exchangeDrawerTemplates.exchange({
        from: { currency: defaultCurrency, amount: '' },
        to: { currency: values.rent.currency, amount: values.rent.amount },
      }),
    );
  }, [drawer, defaultCurrency, values.rent]);

  const handleDeposit = useCallback(() => {
    sidebar.open(
      ...sidebarTemplates.paymentProcess({ isDeposit: true, currencyCode: values.rent.currency }),
    );
  }, [sidebar, values.rent.currency]);

  return (
    <form onSubmit={handleSubmit}>
      <TemplateShortInfo template={template} rentCurrency={values.rent.currency} />
      <Field
        name="rent"
        component={CurrencyAmountField}
        className="mt-3"
        label={translate('PLACEHOLDER_ENTER_AMOUNT')}
        showBalance
        showMaxAmountButton
        underComponent={defaultCurrencyCalculatorComponent}
        currenciesList={rentCurrenciesList}
      />
      <Field
        name="term"
        component={SliderField}
        className="mt-4"
        suffix={' ' + translate('PLACEHOLDER_SUFFIX_MONTHS')}
        min={template.minTermMonth}
        max={template.maxTermMonth}
      />
      <div className="mt-3 row jcsb aic mb-1-5">
        <div className="row aic">
          <span className="label">{translate('RENTING_SELECT_REWARDS_CURRENCY')}</span>
          <InfoIcon
            size="xs"
            className={classes.rewardCurrencyInfoIcon}
            title={translate('RENTING_WITHDRAW_CURRENCY_FAQ_TITLE')}
            description={translate('RENTING_WITHDRAW_CURRENCY_FAQ_DESCRIPTION')}
          />
        </div>
        <button type="button" onClick={handleClickSearch} className={classes.searchCurrenciesBtn}>
          <Icon name="search" size="xs" />
          {translate('SEARCH')}
        </button>
      </div>
      <CurrenciesCarousel
        selectedCurrency={values.withdrawCurrency}
        currencies={currentWithdrawCurrencies}
        variant="creamy"
        onPick={handleChangeWithdrawCurrency}
      />
      <RentingSetupPayoutCalculations
        rentAmount={+values.rent.amount}
        rentCurrency={values.rent.currency}
        percent={template.percentRPY}
        withdrawCurrency={values.withdrawCurrency}
        defaultCurrency={defaultCurrency}
        termMonth={values.term}
      />
      {isInsufficientFunds ? (
        <div className="row mt-1-5 gap-1-5">
          <Button onClick={handleDeposit} fullWidth>
            {translate('DEPOSIT')}
          </Button>
          <Button onClick={handleBuy} fullWidth variant="pastelYellow">
            {translate('BUY')}
          </Button>
        </div>
      ) : (
        <SubmitButton showShadow className="mt-1-5" fullWidth>
          {translate('CONTINUE')}
        </SubmitButton>
      )}
      <AgreementText variant="renting-and-staking" className="mt-1-5" />
    </form>
  );
};

const RentingSetup: FC<RentingSetupProps> = ({ templateName, rentCurrency }) => {
  const sidebar = useSideBar();
  const translate = useTranslation();

  const templates = useSelector(selectTemplates);

  const template = useMemo(
    () => templates.find((t) => t.name === templateName),
    [templates, templateName],
  );

  const wallets = useSelector(selectWallets) as TradingWallet[];

  const initialValues = useMemo<FormValues>(() => {
    const currency = rentCurrency || template!.rentCurrencies[0];
    return {
      rent: { amount: '', currency },
      term: template!.minTermMonth,
      withdrawCurrency: template!.withdrawCurrencies[0],
    };
  }, [rentCurrency, template]);

  const checkVerification = useVerificationCheck();

  const handleSubmit = useCallback(
    (values: FormValues) => {
      if (!checkVerification()) {
        return;
      }
      sidebar.open('rentingSetupConfirm', {
        sideBarProps: {
          swipeModal: true,
          contentClassName: clsx(
            rentingCommonClasses.rentingSideBar,
            rentingCommonClasses['templateBg-' + template!.name],
          ),
        },
        props: {
          templateId: template!.id,
          ...values,
        },
      });
    },
    [template, checkVerification, sidebar],
  );

  const validate = useCallback(
    (values: FormValues) => {
      const wallet = wallets.find((w) => w.currencyCode === values.rent.currency);
      const templateRule = template!.rules.find((r) => r.currencyCode === values.rent.currency);
      const schema = yup.object().shape({
        rent: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .positive()
            .required(translate('VALIDATION_REQUIRED'))
            .min(
              templateRule!.minAmount,
              translate('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(templateRule!.minAmount, values.rent.currency),
              }),
            )
            .max(
              +formatCurrency(wallet?.amount || 0, false),
              translate('VALIDATION_INSUFFICIENT_FUNDS'),
            )
            .nullable(true),
        }),
      });
      return makeValidate(schema)(values);
    },
    [template, translate, wallets],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) =>
      template ? <RentingSetupForm {...formProps} template={template} wallets={wallets} /> : null,
    [wallets, template],
  );
  return (
    <div className={classes.root}>
      <TemplateHeader templateName={templateName} />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={renderForm}
        validate={validate}
      />
    </div>
  );
};

export default RentingSetup;
