import { useCallback, useEffect } from 'react';

import { requestVaultTransactions } from 'modules/vault/store/thunks';
import { useDispatch } from 'store';

import { TransactionHistory } from 'components/common';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import eventEmitter from 'utils/eventEmitter';

export const History = () => {
  const translate = useTranslation();
  const dispatch = useDispatch();

  const refresh = useFlag(false);

  const requestTransactions = useCallback(
    async (pageNumber: number, pageLimit: number) => {
      const { success, data } = await dispatch(
        requestVaultTransactions({ pageNumber, pageSize: pageLimit }),
      );

      if (success && data) {
        return data;
      }
      return null;
    },
    [dispatch],
  );

  useEffect(() => {
    const unsubscribe = eventEmitter.subscribe('refreshVaultHistory', refresh.on);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="outlinedCard column gap-3">
      <span className="label">{translate('HISTORY')}</span>
      <TransactionHistory
        scrollInside
        groupByDate
        requestTransactions={requestTransactions}
        pageSize={20}
        refresh={refresh}
      />
    </div>
  );
};
