import { FC, useCallback, useMemo } from 'react';

import { Invoice } from 'modules/invoicing/types';
import { SummaryAmount } from 'modules/invoicing/views/components/SummaryAmount';

import { SummaryCard, SummaryCardItem } from 'components/ui/SummaryCard';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import classes from './FullInvoiceView.module.scss';

export interface InvoiceStepConfigItem {
  label: string;
  id: 'issuer' | 'client' | 'details' | 'items' | 'discount' | 'notes' | 'paymentDetails';
  canEdit?: boolean;
  config: SummaryCardItem[];
}
export interface FullInvoiceViewProps {
  invoice: Invoice;
  onEdit?: (id: InvoiceStepConfigItem['id'], label: string) => void;
  showEmptyFields?: boolean;
}
const FullInvoiceView: FC<FullInvoiceViewProps> = ({ invoice, onEdit, showEmptyFields }) => {
  const translate = useTranslation();

  const paymentDetailsConfig = useMemo<SummaryCardItem[]>(() => {
    if (!invoice.payment) {
      return [];
    }

    switch (invoice.payment.type) {
      case 'nebeusCryptoAccount': {
        return [
          {
            id: 'paymentMethod',
            label: translate('PAYMENT_METHOD'),
            value: translate('PAYMENT_METHOD_NEBEUS_CRYPTO_ACCOUNT'),
          },
          {
            id: 'currencyCode',
            label: translate('PAYMENT_WALLET_CURRENCY'),

            value: invoice.payment.currencyCode,

            currencyCode: invoice.payment.currencyCode,
          },
          {
            id: 'network',
            label: translate('NETWORK'),

            value: invoice.payment.network || '-',
          },
          {
            id: 'address',
            label: translate('PAYMENT_WALLET_ADDRESS'),

            value: invoice.payment.address,
            valueClassName: classes.walletAddress,
          },
        ];
      }
      case 'nebeusIbanAccount': {
        if (invoice.payment.currencyCode === 'EUR') {
          return [
            {
              id: 'paymentMethod',
              label: translate('PAYMENT_METHOD'),
              value: translate('PAYMENT_METHOD_NEBEUS_IBAN_ACCOUNT'),
            },
            {
              id: 'iban',
              label: translate('PAYMENT_IBAN'),

              value: invoice.payment.iban,
            },
            {
              id: 'bic',
              label: translate('PAYMENT_BIC'),

              value: invoice.payment.bic,
            },
            {
              id: 'bankName',
              label: translate('BANK_NAME'),
              value: invoice.payment.bankName,
            },
            {
              id: 'beneficiary',
              label: translate('PAYMENT_BENEFICIARY'),
              value: invoice.payment.beneficiary,
            },
          ];
        }

        if (invoice.payment.currencyCode === 'GBP') {
          return [
            {
              id: 'paymentMethod',
              label: translate('PAYMENT_METHOD'),
              value: translate('PAYMENT_METHOD_NEBEUS_IBAN_ACCOUNT'),
            },
            {
              id: 'accountNumber',
              label: translate('PAYMENT_ACCOUNT_NUMBER'),
              value: invoice.payment.accountNumber,
            },
            {
              id: 'sortCode',
              label: translate('PAYMENT_SORT_CODE'),

              value: invoice.payment.sortCode,
            },
            {
              id: 'bankName',
              label: translate('BANK_NAME'),

              value: invoice.payment.bankName,
            },
            {
              id: 'beneficiary',
              label: translate('PAYMENT_BENEFICIARY'),
              value: invoice.payment.accountHolderName,
            },
          ];
        }
        return [];
      }

      case 'nebeusSepaOrWireTransfer': {
        if (invoice.payment.currencyCode === 'EUR') {
          return [
            {
              id: 'paymentMethod',
              label: translate('PAYMENT_METHOD'),
              value: translate('PAYMENT_METHOD_NEBEUS_CASH_ACCOUNT'),
            },
            {
              id: 'iban',
              label: translate('PAYMENT_IBAN'),

              value: invoice.payment.iban,
            },
            {
              id: 'bic',
              label: translate('PAYMENT_BIC'),

              value: invoice.payment.bic,
            },
            {
              id: 'beneficiary',
              label: translate('PAYMENT_BENEFICIARY'),

              value: invoice.payment.beneficiary,
            },
            {
              id: 'reference',
              label: translate('PAYMENT_REFERENCE'),

              value: invoice.payment.paymentReference,
            },
          ];
        }

        if (invoice.payment.currencyCode === 'GBP') {
          return [
            {
              id: 'paymentMethod',
              label: translate('PAYMENT_METHOD'),
              value: translate('PAYMENT_METHOD_NEBEUS_CASH_ACCOUNT'),
            },
            {
              id: 'accountNumber',
              label: translate('PAYMENT_ACCOUNT_NUMBER'),

              value: invoice.payment.accountNumber,
            },
            {
              id: 'sortCode',
              label: translate('PAYMENT_SORT_CODE'),

              value: invoice.payment.sortCode,
            },
            {
              id: 'beneficiary',
              label: translate('PAYMENT_BENEFICIARY'),

              value: invoice.payment.beneficiary,
            },
            {
              id: 'reference',
              label: translate('PAYMENT_REFERENCE'),

              value: invoice.payment.paymentReference,
            },
          ];
        }

        // if (invoice.payment.currencyCode === 'USD') {
        //   return [
        //     {
        //       id: 'paymentMethod',
        //       label: translate('PAYMENT_METHOD'),
        //       value: translate('PAYMENT_METHOD_NEBEUS_CASH_ACCOUNT'),
        //     },
        //     {
        //       id: 'iban',
        //       label: translate('PAYMENT_IBAN'),
        //
        //       value: invoice.payment.iban,
        //     },
        //     {
        //       id: 'swift',
        //       label: translate('PAYMENT_SWIFT_OR_BIC'),
        //
        //       value: invoice.payment.swift,
        //     },
        //     {
        //       id: 'beneficiary',
        //       label: translate('PAYMENT_BENEFICIARY'),
        //
        //       value: invoice.payment.beneficiary,
        //     },
        //     {
        //       id: 'reference',
        //       label: translate('PAYMENT_REFERENCE'),
        //
        //       value: invoice.payment.paymentReference,
        //     },
        //   ];
        // }
        return [];
      }
      default: {
        return [];
      }
    }
  }, [translate, invoice.payment]);

  const issuerConfig = useMemo(
    () =>
      [
        {
          id: 'fullName',
          label: translate('FULL_NAME'),
          value: invoice.issuer.name,
        },
        {
          id: 'country',
          label: translate('COUNTRY'),
          value: invoice.issuer.country,
        },
        {
          id: 'city',
          label: translate('CITY'),
          value: invoice.issuer.city,
        },
        {
          id: 'postCode',
          label: translate('POSTCODE'),
          value: invoice.issuer.postCode,
        },
        {
          id: 'address',
          label: translate('ADDRESS'),
          value: invoice.issuer.address,
        },
      ].filter((i) => !!i.value),
    [invoice.issuer, translate],
  );

  const clientConfig = useMemo(
    () => [
      {
        id: 'clientName',
        label: translate('INVOICE_COMPANY_OR_CLIENT_NAME'),
        value: invoice.client.name,
      },
      {
        id: 'country',
        label: translate('COUNTRY'),
        value: invoice.client.country,
      },
      {
        id: 'city',
        label: translate('CITY'),
        value: invoice.client.city,
      },
      {
        id: 'postCode',
        label: translate('POSTCODE'),
        value: invoice.client.postCode,
      },
      {
        id: 'address',
        label: translate('ADDRESS'),
        value: invoice.client.address,
      },
      {
        id: 'email',
        label: translate('EMAIL'),
        value: invoice.client.email,
      },
    ],
    [invoice.client, translate],
  );

  const invoiceDetailsConfig = useMemo(
    () => [
      {
        id: 'number',
        label: translate('INVOICE_NUMBER'),
        value: `#${invoice.invoiceDetails.number}`,
      },
      {
        id: 'date',
        label: translate('INVOICE_DATE'),
        value: invoice.invoiceDetails.fromDate,
      },
      {
        id: 'dueDate',
        label: translate('DUE_DATE'),
        value: invoice.invoiceDetails.toDate,
      },
      {
        id: 'currency',
        label: translate('INVOICE_CURRENCY'),
        value: invoice.invoiceDetails.currencyCode,
      },
      {
        id: 'reference',
        label: translate('INVOICE_REFERENCE'),
        value: invoice.invoiceDetails.reference || '-',
      },
    ],
    [translate, invoice.invoiceDetails],
  );

  const invoiceItemsConfig = useMemo(
    () =>
      invoice.invoiceServiceItems.reduce<SummaryCardItem[]>((prevValue, item, index) => {
        const currentConfig: SummaryCardItem[] = [
          {
            id: 'INVOICE_PRODUCT_OF_SERVICE_WITH_NUMBER' + index.toString(),
            label: translate('INVOICE_PRODUCT_OF_SERVICE_WITH_NUMBER', { count: index + 1 }),
            value: item.name,
          },
          {
            id: 'QUANTITY' + index.toString(),
            label: translate('QUANTITY'),
            value: item.quantity.toString(),
          },
          {
            id: 'AMOUNT' + index.toString(),
            label: translate('AMOUNT'),
            value: invoice.invoiceDetails.currencyCode
              ? formatCurrencyWithSymbol(item.amount, invoice.invoiceDetails.currencyCode)
              : '-',
          },
        ];
        if (index !== 0) {
          currentConfig.unshift({ delimiter: <div />, id: index.toString() });
        }

        return [...prevValue, ...currentConfig];
      }, [] as SummaryCardItem[]),
    [translate, invoice.invoiceDetails.currencyCode, invoice.invoiceServiceItems],
  );

  const discountConfig = useMemo(
    () => [
      {
        id: 'amount',
        label: translate('AMOUNT'),
        value: formatCurrencyWithSymbol(
          invoice.invoiceDiscount.amount || 0,
          invoice.invoiceDetails.currencyCode,
        ),
      },
      {
        id: 'reason',
        label: translate('REASON'),
        value: invoice.invoiceDiscount.reason || '-',
      },
    ],
    [invoice.invoiceDiscount, invoice.invoiceDetails.currencyCode, translate],
  );

  const invoiceNotesConfig = useMemo(
    () => [
      {
        id: 'notes',
        value: invoice.notes || '-',
      },
    ],
    [invoice.notes],
  );

  const config = useMemo<InvoiceStepConfigItem[]>(() => {
    const result: InvoiceStepConfigItem[] = [
      {
        id: 'issuer',
        label: translate('INVOICE_USER_DETAILS'),
        config: issuerConfig,
      },
      {
        id: 'client',
        canEdit: true,
        label: translate('INVOICES_CLIENT_DETAILS'),
        config: clientConfig,
      },
      {
        id: 'details',
        label: translate('INVOICE_DETAILS'),
        canEdit: true,
        config: invoiceDetailsConfig,
      },
      {
        id: 'items',
        label: translate('INVOICE_ITEMS'),
        canEdit: true,
        config: invoiceItemsConfig,
      },
      {
        id: 'discount',
        label: translate('INVOICE_DISCOUNT'),
        canEdit: true,
        config: discountConfig,
      },

      {
        id: 'paymentDetails',
        label: translate('PAYMENT_DETAILS'),
        canEdit: true,
        config: paymentDetailsConfig,
      },
    ];

    if (showEmptyFields) {
      result.splice(5, 0, {
        id: 'notes',
        label: translate('INVOICE_NOTES'),
        canEdit: true,
        config: invoiceNotesConfig,
      });
    }

    return result;
  }, [
    showEmptyFields,
    discountConfig,
    invoiceNotesConfig,
    clientConfig,
    issuerConfig,
    invoiceDetailsConfig,
    invoiceItemsConfig,
    translate,
    paymentDetailsConfig,
  ]);

  const handleEdit = useCallback(
    (id: InvoiceStepConfigItem['id'], label: string) => {
      onEdit?.(id, label);
    },
    [onEdit],
  );

  return (
    <div className="column gap-2">
      {config.map((item) => (
        <SummaryCard
          key={item.id}
          variant="outlined"
          config={item.config}
          startAdornment={
            <div className="row jcsb gap-2">
              <span className="label">{item.label}</span>
              {item.canEdit && onEdit ? (
                <span
                  onClick={() => {
                    handleEdit(item.id, item.label);
                  }}
                  className="label cyanBlue edit pointer"
                >
                  {translate('EDIT')}
                </span>
              ) : null}
            </div>
          }
        />
      ))}
      <SummaryAmount
        className={classes.total}
        startAdornment={<span className="label">{translate('TOTAL')}</span>}
        discountAmount={invoice.invoiceDiscount.amount || 0}
        serviceItems={invoice.invoiceServiceItems}
        currencyCode={invoice.invoiceDetails.currencyCode}
      />
    </div>
  );
};

export default FullInvoiceView;
