import clsx from 'clsx';

import { FC, useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { templateTitleFullByType } from 'modules/loans/constants/config';
import { LoanItem } from 'modules/loans/types';

import { AmountLabel, Button, CurrencyIcon, Icon, SummaryCard } from 'components/ui';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';

import classes from './AddCollateralSuccess.module.scss';

export interface AddCollateralSuccessProps {
  loan: LoanItem;
  amount: number;
}

const AddCollateralSuccess: FC<AddCollateralSuccessProps> = ({ loan, amount }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const summary = useMemo<SummaryCardItem[]>(
    () => [
      { label: translate('LOAN_ID'), value: `#${loan.id}` },
      {
        label: translate('LOAN_TYPE'),
        value: translate(templateTitleFullByType[loan.templateType]),
      },
      {
        label: translate('CHARGE_ACCOUNT'),
        value: translate('CURRENCY_ACCOUNT', { currencyCode: loan.collateralCurrency }),
      },
      {
        label: translate('CHARGE_AMOUNT'),
        value: formatCurrencyWithSymbol(amount, loan.collateralCurrency),
      },
      {
        label: translate('ACCOUNT_OPERATION_STATUS'),
        value: translate('PAYMENT_OPERATION_COMPLETED_SUCCESSFULLY'),
      },
    ],
    [translate, loan, amount],
  );

  return (
    <div className="column gap-4 flex-1 jcsb">
      <div className="flexScrollable gap-4">
        <p className="grey-400">{translate('LOANS_COLLATERAL_ADDED_DESC')}</p>

        <div className="creamyCard gap-2 column w100 aic">
          <CurrencyIcon
            code={loan.collateralCurrency}
            size={72}
            adornment={<Icon size={18} className={clsx(classes.plus)} name="plus" />}
          />
          <h6>{translate('LOAN_COLLATERAL')}</h6>
          <AmountLabel
            size="xl2"
            amount={amount}
            currencyCode={loan.collateralCurrency}
            showCurrencySymbol
            showPlusOrMinus
          />

          <div className="row aic gap-1">
            <Icon name="arrowDown" size={10} className={classes.downIcon} />
            <span className="label">{translate('LOANS_RISK_REDUCED')}</span>
          </div>
          <span className="label">{formatDDMMYY_HHMM(new Date())}</span>
        </div>
        <div className="column gap-2">
          <span className="label">{translate('ACCOUNTS_OPERATION_DETAILS')}</span>
          <SummaryCard variant="borderless" config={summary} />
        </div>
      </div>
      <Button fullWidth onClick={drawer.close}>
        {translate('CLOSE')}
      </Button>
    </div>
  );
};

export default AddCollateralSuccess;
