import { Placement } from '@floating-ui/core/src/types';
import {
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react-dom-interactions';

import { FC, ReactElement, useEffect, useMemo } from 'react';

import { SwipeModal, SwipeModalProps } from 'components/ui/SwipeModal';

import useMobile from 'hooks/useMobile';

import { voidFunc } from 'types';

interface FloatingProps {
  children: ReactElement | ReactElement[];
  isVisible: boolean;
  onClose: voidFunc;
  anchorEl: HTMLElement | null;
  flipEnabled?: boolean;
  placement?: Placement;
  sizeStyle?: { width?: string; minWidth?: string };
  offset?: number;
}

const Floating: FC<FloatingProps> = ({
  isVisible,
  onClose,
  anchorEl,
  children,
  placement = 'bottom-end',

  sizeStyle,
  flipEnabled = true,
  offset: offsetFromProps = 12,
}) => {
  const floatingMiddleware = useMemo(() => {
    const result = [
      offset({ mainAxis: offsetFromProps }),
      shift(),
      flipEnabled ? flip() : undefined,
      size({
        apply({ availableWidth, availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 12}px`,
            width: `${rects.reference.width}px`,
            ...sizeStyle,
          });
        },
      }),
    ];

    return result;
  }, [sizeStyle, offsetFromProps, flipEnabled]);

  const { x, y, reference, floating, strategy, context, update } = useFloating({
    open: isVisible,
    onOpenChange: onClose,
    placement,
    middleware: floatingMiddleware,
  });

  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    reference(anchorEl);
  }, [reference, anchorEl]);

  useEffect(() => {
    update();
    // eslint-disable-next-line
  }, [flipEnabled]);

  return isVisible ? (
    <div
      ref={floating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      {...getFloatingProps()}
    >
      {children}
    </div>
  ) : null;
};

interface FloatingElementProps extends FloatingProps {
  swipeModalOnMobile?: boolean;
  swipeModalProps?: Omit<SwipeModalProps, 'isVisible' | 'onClose' | 'children'>;
}
export const FloatingElement: FC<FloatingElementProps> = ({
  swipeModalOnMobile = true,
  swipeModalProps,
  children,
  ...props
}) => {
  const isMobile = useMobile();

  const useSwipeModal = swipeModalOnMobile && isMobile;

  const Component = useSwipeModal ? SwipeModal : Floating;

  const componentTargetProps = useSwipeModal ? swipeModalProps : {};

  return (
    <Component {...componentTargetProps} {...props}>
      {children}
    </Component>
  );
};
