import { FC, ReactElement } from 'react';

export interface CreateInvoiceStepProps {
  label: string;
  component: ReactElement;
  endAdornment?: ReactElement;
  labelEndAdornment?: ReactElement;
}
export const CreateInvoiceStep: FC<CreateInvoiceStepProps> = ({
  label,
  labelEndAdornment,
  component,
  endAdornment,
}) => {
  return (
    <div className="column gap-2 mt-1">
      <div className="row gap-2 jcsb">
        <span className="label">{label}</span>
        {labelEndAdornment}
      </div>
      {component}
      {endAdornment}
    </div>
  );
};
