import { FC, useCallback, useEffect } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';
import { selectsInvoicesSavedClientsReducer } from 'modules/invoicing/store/selectors';
import {
  requestEditSavedClient,
  requestRemoveSavedClient,
  requestSaveClient,
  requestSavedClients,
} from 'modules/invoicing/store/thunks';
import { InvoiceSavedClient } from 'modules/invoicing/types';
import { CreateInvoiceFormValues } from 'modules/invoicing/views/CreateInvoice';
import SavedEntityCard from 'modules/invoicing/views/CreateInvoice/components/SavedEntityCard';
import { useDispatch } from 'store';

import { EntityView } from 'components/common';
import { Button, Icon } from 'components/ui';

import useFlag from 'hooks/useFlag';
import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import { voidFunc } from 'types';

const LoadingView = () => {
  return (
    <>
      {new Array(10).fill(null).map((_, index) => (
        <SavedEntityCard key={index} entity="client" title={null} description={null} />
      ))}
    </>
  );
};

const RemoveClientButton: FC<{ clientId: InvoiceSavedClient['id'] }> = ({ clientId }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const loading = useFlag(false);

  const handleRemove = useCallback(async () => {
    loading.on();
    const { success } = await dispatch(requestRemoveSavedClient(clientId));
    if (success) {
      successToast(translate('INVOICE_CLIENT_REMOVED'));
    }
    loading.off();
    drawer.pop();
  }, [dispatch, translate, drawer, loading, clientId]);

  return (
    <Button loading={loading.state} onClick={handleRemove} variant="darkGreyOutlined">
      {translate('INVOICE_REMOVE_DETAILS')}
    </Button>
  );
};

const buildDescription = (client: InvoiceSavedClient) =>
  [client.country, client.city, client.postCode, client.address, client.address2, client.email]
    .filter((i) => !!i)
    .join(', ');
const ClientsList: FC<{
  loading: boolean;
  clients: InvoiceSavedClient[];
  variant: SavedClientsProps['variant'];
  onAction: (client: InvoiceSavedClient) => void;
}> = ({ clients, variant, onAction, loading }) => {
  const translate = useTranslation();

  return loading ? (
    <LoadingView />
  ) : (
    <>
      {clients.map((client) => (
        <SavedEntityCard
          key={client.id}
          entity="client"
          title={client.name}
          description={buildDescription(client)}
          endAdornment={
            <span
              onClick={() => {
                onAction(client);
              }}
              className="label cyanBlue pointer"
            >
              {translate(variant === 'pick' ? 'SELECT' : 'EDIT')}
            </span>
          }
        />
      ))}
    </>
  );
};

const NoClients: FC<{ addClient: voidFunc; loading: boolean }> = ({ addClient, loading }) => {
  const translate = useTranslation();

  return loading ? (
    <LoadingView />
  ) : (
    <div className="column aic flex-1 gap-3">
      <div className="column jcc flex-1 gap-6 tac">
        <Icon name="case2" size={72} />
        <h3>{translate('INVOICES_NO_CLIENTS')}</h3>
      </div>
      <Button
        fullWidth
        onClick={() => {
          // Cant pass event from onClick
          addClient();
        }}
      >
        {translate('INVOICES_ADD_YOUR_CLIENT_DETAILS')}
      </Button>
    </div>
  );
};

export type SavedClientsProps =
  | {
      variant: 'pick';
      onPick: (client: InvoiceSavedClient) => void;
    }
  | { variant: 'manage'; onPick?: never };
const SavedClients: FC<SavedClientsProps> = ({ variant, onPick }) => {
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const translate = useTranslation();

  const { entityReducer: clientsReducer, fetchEntity: fetchSavedClients } = useStoreEntity(
    selectsInvoicesSavedClientsReducer,
    requestSavedClients,
  );

  const addOrEditClient = useCallback(
    (client?: InvoiceSavedClient) => {
      drawer.open(
        invoicingDrawerTemplates.editInvoiceStep({
          stepId: 'client',
          stepName: translate('INVOICES_CLIENT_DETAILS'),
          topAdornment: client ? <RemoveClientButton clientId={client.id} /> : undefined,
          values: client
            ? {
                clientEmail: client.email,
                clientCity: client.city,
                clientCountry: client.country,
                clientAddress1: client.address,
                clientAddress2: client.address2,
                clientName: client.name,
                clientPostCode: client.postCode,
              }
            : {
                clientEmail: '',
                clientCity: '',
                clientCountry: '',
                clientAddress1: '',
                clientAddress2: '',
                clientName: '',
                clientPostCode: '',
              },
          onFinish: async (values: CreateInvoiceFormValues) => {
            const newClient = {
              email: values.clientEmail,
              postCode: values.clientPostCode,
              country: values.clientCountry,
              city: values.clientCity,
              name: values.clientName,
              address: values.clientAddress1,
              address2: values.clientAddress2,
            };
            if (client) {
              const { success } = await dispatch(
                requestEditSavedClient({ client: newClient, id: client.id }),
              );
              if (success) {
                drawer.pop();
              }
            } else {
              const { success } = await dispatch(requestSaveClient(newClient));

              if (success) {
                successToast(translate('INVOICE_CLIENT_ADDED'));

                drawer.pop();
              }
            }
          },
        }),
      );
    },
    [dispatch, drawer, translate],
  );

  const handleAction = useCallback(
    (client: InvoiceSavedClient) => {
      if (variant === 'pick') {
        onPick(client);
      } else if (variant === 'manage') {
        addOrEditClient(client);
      }
    },
    [onPick, addOrEditClient, variant],
  );

  useEffect(() => {
    if (variant === 'pick' && !onPick) {
      drawer.pop({ soft: false });
    }
    // eslint-disable-next-line
  }, [variant, onPick]);

  return (
    <div className="column flex-1 gap-2 mt-1">
      <EntityView
        reducer={clientsReducer}
        request={fetchSavedClients}
        component={
          <ClientsList
            clients={clientsReducer.data}
            loading={clientsReducer.meta.loading}
            variant={variant}
            onAction={handleAction}
          />
        }
        loaderComponent={<LoadingView />}
        noDataComponent={
          <NoClients loading={clientsReducer.meta.loading} addClient={addOrEditClient} />
        }
      />
    </div>
  );
};

export default SavedClients;
