import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectAllowedCountriesReducer } from 'modules/app/store/selectors';
import { requestAllowedCountries } from 'modules/app/store/thunks';
import { selectReferralLinksReducer, selectUserProfile } from 'modules/user/store/selectors';
import { requestGenerateReferralLink, requestReferralLinks } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import { UserAvatar } from 'components/common';
import { DetailsCard, Loader, Switch } from 'components/ui';
import { DetailsItem } from 'components/ui/DetailsCard';

import useFlag from 'hooks/useFlag';
import useStoreEntity from 'hooks/useStoreEntity';
import useVerificationCheck from 'hooks/useVerificationCheck';

import { useTranslation } from 'libs/i18n';

import { findByProperty } from 'utils/arrayUtils';

const Profile = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const userProfile = useSelector(selectUserProfile);

  const {
    entityReducer: { data: allowedCountries },
  } = useStoreEntity(selectAllowedCountriesReducer, requestAllowedCountries);

  const details = useMemo<DetailsItem[]>(
    () =>
      userProfile
        ? [
            {
              label: translate('FULL_NAME'),
              value: `${userProfile.firstName} ${userProfile.lastName}`,
              canCopy: false,
            },
            {
              label: translate('EMAIL'),
              value: userProfile.email,
              canCopy: false,
            },
            {
              label: translate('COUNTRY'),
              value: findByProperty(allowedCountries, 'code', userProfile.countryCode)?.name || '-',
              canCopy: false,
            },
          ]
        : [],
    [userProfile, allowedCountries, translate],
  );

  const { entityReducer: referralLinksReducer } = useStoreEntity(
    selectReferralLinksReducer,
    requestReferralLinks,
  );

  const referralLinkGenerating = useFlag(false);

  const checkVerification = useVerificationCheck();

  const generateReferralLink = useCallback(async () => {
    if (!checkVerification()) {
      return;
    }
    referralLinkGenerating.on();
    await dispatch(requestGenerateReferralLink());
    referralLinkGenerating.off();
  }, [dispatch, checkVerification, referralLinkGenerating]);

  const referralLinksDetails = useMemo<DetailsItem[]>(
    () =>
      referralLinksReducer.data.length
        ? referralLinksReducer.data.map((link) => ({
            value: link,
            canCopy: true,
            label: translate('REFERRAL_LINK'),
          }))
        : [
            {
              label: translate('REFERRAL_LINK'),
              value: translate('REFERRAL_LINK_GENERATE'),
              endAdornment: <Switch value={false} onChange={generateReferralLink} />,
              loading: referralLinkGenerating.state,
            },
          ],
    [referralLinksReducer.data, referralLinkGenerating.state, generateReferralLink, translate],
  );

  return userProfile ? (
    <div className="column gap-3 pt-1">
      <UserAvatar className="asc" size={100} />
      <DetailsCard details={details} />

      {referralLinksReducer.meta.loading && !referralLinksReducer.meta.loaded ? (
        <Loader centered />
      ) : (
        <DetailsCard details={referralLinksDetails} />
      )}
    </div>
  ) : null;
};

export default Profile;
