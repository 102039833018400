import { FC } from 'react';

import { LoanItem } from 'modules/loans/types';
import { LoanHeader } from 'modules/loans/views/components/LoanHeader';

import { Mark } from 'components/ui';

import { useTranslation } from 'libs/i18n';

export interface LoanRepaidProps {
  loan: LoanItem;
  amount: number;
  isFull: boolean;
}

const LoanRepaid: FC<LoanRepaidProps> = ({ isFull, loan, amount }) => {
  const translate = useTranslation();

  return (
    <div className="column gap-3">
      <p className="grey-400">
        {translate(isFull ? 'LOANS_REPAID_FULL_DESC' : 'LOANS_REPAID_PART_DESC')}
      </p>
      <div className="outlinedCard">
        <LoanHeader
          loan={loan}
          amount={amount}
          endAdornment={isFull && <Mark variant="green">{translate('LOANS_REPAID')}</Mark>}
        />
      </div>
    </div>
  );
};

export default LoanRepaid;
