import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { CheckTFACodeProps } from 'modules/auth/views/CheckTFACode';
import { EmergencyResetCodeProps } from 'modules/auth/views/EmergencyResetCode';
import { QuestionnaireProps } from 'modules/user/views/Questionnaire';
import { VerificationIDProps } from 'modules/user/views/Settings/components/Verification/components/VerificationID';
import { CloseAccountProps } from 'modules/user/views/Settings/components/closeAccountProcess/CloseAccount';

import { getTranslation } from 'libs/i18n';

const settings = (): DrawerTemplateReturnType => ({
  key: 'settings',
  sideBarProps: {
    navigationHeaderLabel: getTranslation('PROFILE'),
    navigationHeaderProps: {
      backButtonFilled: false,
    },
  },
});
const profile = (): DrawerTemplateReturnType => ({
  key: 'profile',
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('PROFILE_PERSONAL_DATA'),
    navigationHeaderProps: {
      backButtonFilled: false,
    },
  },
});
const verification = (): DrawerTemplateReturnType => ({
  key: 'verification',
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('VERIFICATION'),
  },
});
const verificationID = (props?: VerificationIDProps): DrawerTemplateReturnType => ({
  key: 'verificationID',
  props,
  sideBarProps: {
    disableClickOutside: props?.required,
    swipeModal: true,
    swipeModalBlocked: props?.required,
    navigationHeaderLabel: getTranslation('VERIFICATION_ID_VERIFICATION'),
    navigationHeaderProps: {
      hideBackButton: true,
    },
    showBackButton: !props?.required,
  },
});
const security = (): DrawerTemplateReturnType => ({
  key: 'profileSecurity',
  sideBarProps: { swipeModal: true, navigationHeaderLabel: getTranslation('SECURITY') },
});
const setupTFA = (): DrawerTemplateReturnType => ({
  key: 'setupTFA',
  sideBarProps: { swipeModal: true, navigationHeaderLabel: getTranslation('TFA_SETUP_TITLE') },
});
const checkTFACode = (props: CheckTFACodeProps): DrawerTemplateReturnType => ({
  key: 'checkTFACode',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation(
      props.variant === 'disable' ? 'TFA_DISABLE_TITLE' : 'TFA_SETUP_TITLE',
    ),
  },
});
const emergencyResetTFACode = (props: EmergencyResetCodeProps): DrawerTemplateReturnType => ({
  key: 'emergencyResetTFACode',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('TFA_EMERGENCY_RESET_CODE_TITLE'),
  },
});

const closeAccount = (props?: CloseAccountProps): DrawerTemplateReturnType => ({
  key: 'closeAccount',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('CLOSE_ACCOUNT_TITLE'),
  },
});
const closeAccountFinal = (): DrawerTemplateReturnType => ({
  key: 'closeAccount',
  allowDuplicate: true,
  id: 'closeAccountFinal',
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('CLOSE_ACCOUNT_FINAL_TITLE'),
  },
  props: { finalConfirmation: true },
});

const closeAccountCheckList = (): DrawerTemplateReturnType => ({
  key: 'closeAccountCheckList',
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('CLOSE_ACCOUNT_CHECK_LIST_TITLE'),
  },
});

const notifications = (): DrawerTemplateReturnType => ({
  key: 'notifications',
  sideBarProps: { isPlain: true },
});
const questionnaire = (props: QuestionnaireProps): DrawerTemplateReturnType => ({
  key: 'questionnaire',
  props,
  sideBarProps: {
    disableClickOutside: true,
    navigationHeaderLabel: getTranslation('USER_VERIFICATION_QUESTIONNAIRE'),
    showBackButton: false,
    navigationHeaderProps: {
      hideBackButton: true,
    },
  },
});
const customerQuestionnaire = (): DrawerTemplateReturnType => ({
  key: 'customerQuestionnaire',
  sideBarProps: {
    disableClickOutside: true,
    showBackButton: false,
  },
});

const userDrawerTemplates = {
  settings,
  profile,
  verification,
  verificationID,
  security,
  setupTFA,
  checkTFACode,
  emergencyResetTFACode,
  closeAccount,
  closeAccountFinal,
  closeAccountCheckList,
  notifications,
  questionnaire,
  customerQuestionnaire,
};

export default userDrawerTemplates;
