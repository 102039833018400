import { PayloadAction } from '@reduxjs/toolkit';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { AxiosError, AxiosRequestConfig } from 'axios';
import get from 'lodash/get';

import {
  DirectionData,
  IAPIInfinityListMeta,
  IAPIMeta,
  IApiInfinityListState,
  ListFilterReqPayload,
} from 'store/generators/types';
import { AppDispatch, AppStore } from 'store/index';

import ApiError from 'libs/axios/ApiError';
import { errorToast } from 'libs/toast';

import { CurrenciesDirection } from 'types';

type GenerateRequestDataThunk = <Entity, ReqPayload = void>({
  updateState,
  request,
}: {
  updateState: (payload: {
    data?: Entity;
    meta?: Partial<IAPIMeta>;
  }) => PayloadAction<{ data?: Entity; meta?: Partial<IAPIMeta> }>;
  request: (payload: ReqPayload) => Promise<Entity>;
  options?: {
    onSuccess?: (data: Entity, dispatch: AppDispatch) => MaybePromise<void>;
    preAction?: (dispatch: AppDispatch, reqPayload: ReqPayload) => void;
    onFail?: (error: Error, dispatch: AppDispatch) => MaybePromise<void>;
    showErrorToast?: boolean;
  };
}) => (
  reqPayload: ReqPayload,
) => (
  dispatch: AppDispatch,
) => Promise<
  { success: true; data: Entity; error: null } | { success: false; error: ApiError; data: null }
>;

export const generateRequestDataThunk: GenerateRequestDataThunk =
  ({ updateState, request, options = {} }) =>
  (reqPayload) =>
  // @ts-expect-error
  async (dispatch) => {
    const {
      onSuccess = undefined,
      onFail = undefined,
      preAction = undefined,
      showErrorToast = true,
    } = options;

    try {
      preAction?.(dispatch, reqPayload);

      dispatch(updateState({ meta: { loading: true } }));

      const data = await request(reqPayload);
      dispatch(
        updateState({
          data,
          meta: {
            loading: false,
            loaded: true,
            error: undefined,
            lastLoadedTime: Date.now(),
          },
        }),
      );
      await onSuccess?.(data, dispatch);

      return { success: true, data };
    } catch (error: unknown) {
      if (error instanceof ApiError && error.statusCode !== 401) {
        if (showErrorToast) {
          errorToast(error.message);
        }

        dispatch(
          updateState({
            meta: { loading: false, loaded: false, error: error.message },
          }),
        );
        await onFail?.(error, dispatch);
      }
      return { success: false, error };
    }
  };

type GenerateRequestThunk = <ReqPayload = void, Response = void>({
  request,
}: {
  request: (payload: ReqPayload, axiosConfig?: AxiosRequestConfig) => Promise<Response>;
  options?: {
    onSuccess?: (
      dispatch: AppDispatch,
      data: Response,
      reqPayload: ReqPayload,
    ) => MaybePromise<void>;
    preAction?: (dispatch: AppDispatch, reqPayload: ReqPayload) => void;
    onFail?: (dispatch: AppDispatch, error: Error | AxiosError) => void;
    showErrorToast?: boolean;
  };
}) => (
  reqPayload: ReqPayload,
  thunkOptions?: { axiosConfig?: AxiosRequestConfig; withoutFailedToast?: boolean },
) => (
  dispatch: AppDispatch,
) => Promise<
  { success: true; data: Response; error: null } | { success: false; error: ApiError; data: null }
>;

export const generateRequestThunk: GenerateRequestThunk =
  ({ request, options = {} }) =>
  (reqPayload, thunkOptions) =>
  // @ts-expect-error
  async (dispatch) => {
    const { onSuccess, onFail, preAction, showErrorToast = true } = options;

    try {
      preAction?.(dispatch, reqPayload);

      const data = await request(reqPayload, thunkOptions?.axiosConfig);

      await onSuccess?.(dispatch, data, reqPayload);

      return { success: true, data };
    } catch (e: unknown) {
      if (e instanceof ApiError && e.statusCode !== 401) {
        if (!thunkOptions?.withoutFailedToast && showErrorToast) {
          errorToast(e.message);
        }
        onFail?.(dispatch, e);
      }
      return { success: false, error: e };
    }
  };

type GenerateRequestDirectionThunk = <
  Entity extends DirectionData,
  ReqPayload extends DirectionData,
>({
  updateState,
  request,
}: {
  updateState: (payload: {
    direction: CurrenciesDirection;
    data?: Entity;
    meta?: Partial<IAPIMeta>;
  }) => PayloadAction<{ data?: Entity; meta?: Partial<IAPIMeta> }>;
  request: (payload: ReqPayload) => Promise<Entity>;
  options?: {
    onSuccess?: (data: Entity, dispatch: AppDispatch) => MaybePromise<void>;
    onFail?: (error: Error, dispatch: AppDispatch) => void;
    showErrorToast?: boolean;
  };
}) => (
  reqPayload: ReqPayload,
  thunkOptions?: { withoutFailedToast?: boolean },
) => (
  dispatch: AppDispatch,
) => Promise<
  { success: true; data: Entity; error: null } | { success: false; error: ApiError; data: null }
>;

export const generateRequestDirectionThunk: GenerateRequestDirectionThunk =
  ({ updateState, request, options = {} }) =>
  (reqPayload, thunkOptions) =>
  // @ts-expect-error
  async (dispatch) => {
    const { onSuccess = undefined, onFail = undefined, showErrorToast = true } = options;

    const direction: CurrenciesDirection = `${reqPayload.fromCurrency}/${reqPayload.toCurrency}`;

    try {
      dispatch(
        updateState({
          direction,
          meta: { loading: true, loaded: false },
        }),
      );

      const data = await request(reqPayload);
      dispatch(
        updateState({
          direction,
          data,
          meta: { loading: false, loaded: true, lastLoadedTime: Date.now() },
        }),
      );
      await onSuccess?.(data, dispatch);

      return { success: true, data };
    } catch (error: unknown) {
      if (error instanceof ApiError && error.statusCode !== 401) {
        if (!thunkOptions?.withoutFailedToast && showErrorToast) {
          errorToast(error.message);
        }

        dispatch(
          updateState({
            direction,
            meta: { loading: false, loaded: false, error: error.message },
          }),
        );
        onFail?.(error, dispatch);
      }
      return { success: false, error };
    }
  };

type GenerateInfinityListThunk = <Entity, ReqPayload = void>({
  request,
  updateState,
  reducerPath,
}: {
  request: (args: { payload?: ReqPayload; filter: ListFilterReqPayload }) => Promise<Entity[]>;
  updateState: (payload: {
    data?: Entity[];
    meta?: Partial<IAPIInfinityListMeta>;
  }) => PayloadAction<{ data?: Entity[]; meta?: Partial<IAPIInfinityListMeta> }>;
  reducerPath: string;
  options?: {
    showErrorToast?: boolean;
  };
}) => (
  reqPayload: ReqPayload,
  thunkOptions: {
    reset?: boolean;
    pageLimit: number;
  },
) => (dispatch: AppDispatch, getState: () => AppStore) => Promise<void>;

export const generateInfinityListThunk: GenerateInfinityListThunk =
  ({ request, updateState, reducerPath, options = {} }) =>
  (reqPayload, thunkOptions) =>
  async (dispatch, getState) => {
    const currentState: IApiInfinityListState<unknown> = get(getState(), reducerPath);

    const { showErrorToast = true } = options;

    try {
      if (!currentState.meta.hasMore && !thunkOptions.reset) {
        return;
      }
      const pageLimit = thunkOptions.pageLimit;

      dispatch(updateState({ meta: { loading: true, error: undefined } }));
      const data = await request({
        payload: reqPayload,
        filter: {
          pageLimit,
          pageNumber: thunkOptions.reset ? 0 : currentState.meta.pageNumber,
        },
      });
      dispatch(
        updateState({
          // @ts-ignore
          data: thunkOptions.reset ? data : [...currentState.data, ...data],
          meta: {
            loading: false,
            loaded: true,
            hasMore: data.length === pageLimit,
            pageNumber: thunkOptions.reset ? 1 : currentState.meta.pageNumber + 1,
            lastLoadedTime: Date.now(),
          },
        }),
      );
    } catch (e: unknown) {
      if (e instanceof ApiError && e.statusCode !== 401 && showErrorToast) {
        errorToast(e.message);
        dispatch(updateState({ meta: { error: e.message, loading: false, loaded: false } }));
      }
    }
  };
