import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { requestDigitalWallets, requestWallets } from 'modules/accounts/store/thunks';
import { selectIsDigitalAccountAllowed } from 'modules/digitalAccount/store/selectors';
import { useDispatch } from 'store';

import useWindowFocusState from 'hooks/useWindowFocusState';

const ONE_MINUTE_IN_MS = 1000 * 60;
const useDataRefresher = () => {
  const dispatch = useDispatch();

  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);

  const windowFocused = useWindowFocusState();

  const refreshData = useCallback(() => {
    dispatch(requestWallets());

    if (isDAAllowed) {
      dispatch(requestDigitalWallets());
    }
  }, [dispatch, isDAAllowed]);

  useEffect(() => {
    let intervalId: null | NodeJS.Timer = null;
    if (windowFocused) {
      intervalId = setInterval(refreshData, ONE_MINUTE_IN_MS);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [windowFocused, refreshData]);
};
export default useDataRefresher;
