import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectOperationCommissions } from 'modules/app/store/selectors';
import { CryptoNetworkId, PaymentOperationId } from 'modules/payment/types';

import { CurrencyCode } from 'types';

const useCommissionRule = (
  operationId: PaymentOperationId,
  currencyCode: CurrencyCode,
  cryptoNetwork?: CryptoNetworkId,
) => {
  const commissions = useSelector(selectOperationCommissions);

  const commissionRule = useMemo(
    () =>
      commissions.find((c) => {
        const firstCondition = c.operationId === operationId && c.currencyCode === currencyCode;

        if (cryptoNetwork) {
          return firstCondition && c.cryptoNetwork === cryptoNetwork;
        }
        return firstCondition;
      }),
    [operationId, currencyCode, cryptoNetwork, commissions],
  );

  return commissionRule;
};

export default useCommissionRule;
