import clsx from 'clsx';

import { FC, ReactNode, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import routesByName from 'constants/routesByName';

import { Icon } from 'components/ui/Icon';
import { InfoIcon, InfoIconProps } from 'components/ui/InfoIcon';

import { voidFunc } from 'types';

import classes from './NavigationHeader.module.scss';

export interface NavigationHeaderProps {
  className?: string;
  children: ReactNode;
  hideBackButton?: boolean;

  infoIcon?: InfoIconProps;

  onBack?: voidFunc | null;
  backButtonFilled?: boolean;
  endAdornment?: ReactNode;
}

export const NavigationHeader: FC<NavigationHeaderProps> = ({
  children,
  className,
  hideBackButton,
  infoIcon,
  onBack,
  backButtonFilled = false,
  endAdornment,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = useCallback(() => {
    if (onBack) {
      onBack();
      return;
    }
    if (onBack === null || hideBackButton) {
      return;
    }
    if (location.key === 'default') {
      navigate(routesByName.dashboard);
    } else {
      navigate(-1);
    }
  }, [hideBackButton, onBack, location?.key, navigate]);
  return (
    <div className={clsx(classes.root, className, 'row gap-1-5')}>
      <div className="row aic gap-1 pointer" onClick={handleBack}>
        {!hideBackButton && (
          <Icon
            size="sm"
            className={clsx(classes.icon, backButtonFilled && classes.filled)}
            name="chevronLeftSquared"
          />
        )}
        {typeof children === 'string' ? <h3>{children}</h3> : children}
      </div>
      {infoIcon ? <InfoIcon className="flex-shrink-0" {...infoIcon} /> : null}
      {endAdornment}
    </div>
  );
};
