import TransactionHistory from 'modules/accounts/views/components/TransactionHistory';

import { NavigationHeader } from 'components/ui';

import { useTranslation } from 'libs/i18n';

const AccountsTransactionsHistory = () => {
  const translate = useTranslation();
  return (
    <div>
      <NavigationHeader className="mb-4">
        {translate('ACCOUNT_TRANSACTIONS_HISTORY')}
      </NavigationHeader>
      <TransactionHistory />
    </div>
  );
};
export default AccountsTransactionsHistory;
