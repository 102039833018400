import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useSideBar from 'modules/app/hooks/useSideBar';
import { SmartTraderTitles } from 'modules/smartTrader/views/components/SmartTraderTitles';

import routesByName from 'constants/routesByName';

import { AgreementText } from 'components/common';
import { Button, Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { CurrencyCode } from 'types';

export interface SmartTraderActivatedProps {
  currencyCode: CurrencyCode;
}
const SmartTraderActivated: FC<SmartTraderActivatedProps> = ({ currencyCode }) => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const sidebar = useSideBar();

  const handleContinue = useCallback(() => {
    sidebar.close();
    navigate(routesByName.smartTradeDashboard(currencyCode));
  }, [sidebar, navigate, currencyCode]);

  return (
    <>
      <SmartTraderTitles
        title={translate('SMART_TRADER_ACTIVATED_TITLE')}
        subtitle={translate('SMART_TRADER_ACTIVATED_SUBTITLE')}
      />
      <Image name="smartTraderChart" path="smartTrader" />
      <div className="column gap-1-5">
        <Button variant="gold" onClick={handleContinue}>
          {translate('CONTINUE')}
        </Button>
        <AgreementText light />
      </div>
    </>
  );
};

export default SmartTraderActivated;
