import { DottedLine, Image } from 'components/ui';

import useMobile from 'hooks/useMobile';

import { useTranslation } from 'libs/i18n';

import classes from './VaultIntroduction.module.scss';

export const VaultIntroduction = () => {
  const translate = useTranslation();

  const isMobile = useMobile();

  return (
    <div className={classes.root}>
      <div className="row aic">
        <Image className={classes.img} name="vaultFlashKey" path="vault" />
        <h3>{translate('VAULT_INTRO_TITLE')}</h3>
      </div>
      <DottedLine />
      <p
        dangerouslySetInnerHTML={{
          __html: `${translate('VAULT_INTRO_TEXT_P1')}${
            isMobile ? '' : `<br/><br/>${translate('VAULT_INTRO_TEXT_P2')}`
          }`,
        }}
      />
      {isMobile ? (
        <>
          <Image className={classes.imgBetweenText} name="vaultFlashKey" path="vault" />
          <p
            dangerouslySetInnerHTML={{
              __html: translate('VAULT_INTRO_TEXT_P2'),
            }}
          />
        </>
      ) : null}
    </div>
  );
};
