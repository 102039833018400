import { requestWallets } from 'modules/accounts/store/thunks';
import exchangeServices from 'modules/exchange/services';
import { exchangeActions } from 'modules/exchange/store/index';
import { requestCommitExchangeOperation } from 'modules/smartTrader/store/thunks';
import { generateRequestDirectionThunk, generateRequestThunk } from 'store/generators/thunks';
import { generateRequestDataThunk } from 'store/generators/thunks';
import { IApiRequestState } from 'store/generators/types';

import {
  CalculateExchangeRequest,
  CalculateExchangeResponse,
  ExchangePairResponse,
  ExchangeRequest,
} from 'libs/swagger/nebeusApiTypes';

import { CurrenciesDirection, CurrencyCode } from 'types';

import {
  ExchangeRateData,
  ExchangeResponse,
  GetExchangeRateReqParams,
  GetRatesHistoryAggregatedApiResponse,
  GetRatesHistoryAggregatedReqParams,
  GetRatesHistoryParams,
} from '../types';
import { RatesHistory } from '../types';

// cli-import

export const requestExchangeRate = generateRequestDirectionThunk<
  ExchangePairResponse,
  GetExchangeRateReqParams
>({
  updateState: exchangeActions.updateRates,
  request: exchangeServices.getRate,
  options: { showErrorToast: false },
});

export const requestCalculateExchange = generateRequestThunk<
  CalculateExchangeRequest,
  CalculateExchangeResponse
>({
  request: exchangeServices.calculateExchange,
});
export const requestCalculateExchangeReversed = generateRequestThunk<
  CalculateExchangeRequest,
  CalculateExchangeResponse
>({
  request: exchangeServices.calculateExchangeReversed,
});

export const requestExchange = generateRequestThunk<ExchangeRequest, ExchangeResponse>({
  request: exchangeServices.exchange,
  options: {
    onSuccess: (dispatch, data) => {
      dispatch(requestWallets());
      dispatch(requestCommitExchangeOperation(data));
    },
  },
});

export const requestRatesHistory = generateRequestThunk<GetRatesHistoryParams, RatesHistory>({
  request: exchangeServices.getRatesHistory,
  options: {
    showErrorToast: false,
    onSuccess: (dispatch, data) => {
      dispatch(exchangeActions.updateRatesHistory(data));
    },
  },
});

export const requestRatesHistoryAggregated = generateRequestThunk<
  GetRatesHistoryAggregatedReqParams,
  GetRatesHistoryAggregatedApiResponse
>({
  request: exchangeServices.getRatesHistoryAggregated,
  options: {
    showErrorToast: false,
    onSuccess: (dispatch, data) => {
      dispatch(exchangeActions.updateAggregatedRatesHistory(data));
    },
  },
});

export const requestRatesBatched = generateRequestThunk({
  request: exchangeServices.getRateBatched,
  options: {
    showErrorToast: false,
    onSuccess: (dispatch, data) => {
      const batchedRates = data.pairs.reduce(
        (acc, value) => ({
          ...acc,
          [`${value.fromCurrency}/${value.toCurrency}`]: {
            data: value,
            meta: { loading: false, loaded: true },
          } as IApiRequestState<ExchangeRateData>,
        }),
        {},
      );
      dispatch(exchangeActions.updateRatesBatched(batchedRates));
    },
  },
});
export const requestAllowedDirections = generateRequestDataThunk<CurrenciesDirection[]>({
  updateState: exchangeActions.updateAllowedDirections,
  request: exchangeServices.getAllowedDirections,
  options: { showErrorToast: false },
});
export const requestFollowedCurrencies = generateRequestDataThunk<CurrencyCode[]>({
  updateState: exchangeActions.updateFollowedCurrencies,
  request: exchangeServices.getFollowedCurrencies,
});
export const requestFollowCurrency = generateRequestThunk({
  request: exchangeServices.followCurrency,
  options: {
    onSuccess: (dispatch, data) => {
      dispatch(exchangeActions.updateFollowedCurrencies({ data }));
    },
  },
});
export const requestUnfollowCurrency = generateRequestThunk({
  request: exchangeServices.unfollowCurrency,
  options: {
    onSuccess: (dispatch, data) => {
      dispatch(exchangeActions.updateFollowedCurrencies({ data }));
    },
  },
});
// cli-thunk
