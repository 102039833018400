import clsx from 'clsx';

import { ChangeEventHandler, FC, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

import { AllowedCountry } from 'modules/app/types';

import { CountryOption, Icon, Select, TextInput } from 'components/ui';
import { TextInputProps } from 'components/ui/TextInput';

import useFlag, { Flag } from 'hooks/useFlag';

import { parseNumbers } from 'utils/inputParsers';

import classes from './PhoneNumberInput.module.scss';

export interface PhoneNumberInputValue {
  country: AllowedCountry;
  number: string;
}

export interface PhoneNumberInputProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  value: PhoneNumberInputValue;
  onChange: (value: PhoneNumberInputValue) => void;
  allowedCountries: AllowedCountry[];
  selectPortalNode?: Element;
  hideSelect?: boolean;
}

interface PhoneNumberCodeProps {
  value: AllowedCountry;
  select: Flag;
  disabled?: boolean;
}

const PhoneNumberCode: FC<PhoneNumberCodeProps> = ({ value, select, disabled }) => {
  return (
    <div className="pl-1-5 row aic">
      <div className="row aic gap-1 pointer" onClick={disabled ? undefined : select.on}>
        <span className={classes.phoneCode}>{value.phoneCode}</span>
        {disabled ? null : (
          <Icon
            name="triangleDownLg"
            className={clsx(classes.arrow, select.state && classes.active)}
            color="kindaBlack"
            size={18}
          />
        )}
      </div>
      <div className={classes.delimiter} />
    </div>
  );
};

const labelAccessor = (country: AllowedCountry) => `${country.name} (${country.phoneCode})`;

const renderItem = (country: AllowedCountry, index: number, active: boolean) => (
  <CountryOption country={country} active={active} labelAccessor={labelAccessor} />
);
const keyAccessor = (country: AllowedCountry) => country.code;

export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  value,
  onChange,
  allowedCountries,
  selectPortalNode,
  hideSelect = false,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const select = useFlag(false);

  const handleChangeNumber = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onChange({ country: value.country, number: parseNumbers(e.currentTarget.value) });
    },
    [value.country, onChange],
  );
  const handleChangeCountry = useCallback(
    (country: AllowedCountry) => {
      onChange({ country, number: value.number });
    },
    [value.number, onChange],
  );

  const selectComponent = (
    <Select<AllowedCountry>
      anchorEl={anchorEl}
      isOpen={select.state}
      onClose={select.off}
      options={allowedCountries}
      onPick={handleChangeCountry}
      renderItem={renderItem}
      keyAccessor={keyAccessor}
      placement="bottom"
      flipEnabled={false}
    />
  );

  const finalSelectComponent = selectPortalNode
    ? createPortal(selectComponent, selectPortalNode)
    : selectComponent;

  return (
    <>
      <TextInput
        inputContainerRef={setAnchorEl}
        startAdornment={
          <PhoneNumberCode value={value.country} select={select} disabled={hideSelect} />
        }
        value={value.number}
        onChange={handleChangeNumber}
        autoComplete="tel-national"
        inputMode="numeric"
        {...props}
      />
      {hideSelect ? null : finalSelectComponent}
    </>
  );
};
