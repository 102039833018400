import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AppDispatch, AppStore, useDispatch } from 'store';
import { IApiRequestState } from 'store/generators/types';

import ApiError from 'libs/axios/ApiError';

import { canRequest } from 'utils/common';

const useStoreEntity = <T>(
  reducerSelector: (state: AppStore) => IApiRequestState<T>,
  requestEntityThunk: (
    reqPayload: void,
  ) => (
    dispatch: AppDispatch,
  ) => Promise<
    { success: true; data: T; error: null } | { success: false; error: ApiError; data: null }
  >,
  options?: { staleAllowed?: boolean },
) => {
  const dispatch = useDispatch();
  const entityReducer = useSelector(reducerSelector);

  const fetchEntity = useCallback(() => {
    dispatch(requestEntityThunk());
  }, [dispatch, requestEntityThunk]);

  useEffect(() => {
    if (options?.staleAllowed && entityReducer.meta.loaded) {
      return;
    }
    if (canRequest(entityReducer.meta)) {
      fetchEntity();
    }

    // eslint-disable-next-line
  }, []);

  return { fetchEntity, entityReducer };
};
export default useStoreEntity;
