import clsx from 'clsx';
import { Swiper as SwiperClass } from 'swiper/types';

import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useSideBar from 'modules/app/hooks/useSideBar';
import rentingCommonClasses from 'modules/cryptoRenting/constants/RentingCommon.module.scss';
import { selectTemplatesReducer } from 'modules/cryptoRenting/store/selectors';
import { Template } from 'modules/cryptoRenting/types';
import { CurrenciesCarousel } from 'modules/cryptoRenting/views/CryptoRenting/components/CurrenciesCarousel';
import { TemplateHeader } from 'modules/cryptoRenting/views/CryptoRenting/components/TemplateHeader';
import { isReactNative } from 'modules/reactNative/utils';

import { Button, Icon, Loader } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { sortCurrencies } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './New.module.scss';

interface TemplateCardProps {
  template: Template;
}

interface CurrenciesCarouselWithLabelProps {
  label: string;
  currencies: CurrencyCode[];
}
const CurrenciesCarouselWithLabel: FC<CurrenciesCarouselWithLabelProps> = ({
  label,
  currencies,
}) => {
  const [sliderPosition, setSliderPosition] = useState<'start' | 'end' | 'center'>('start');

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const canSlide = swiper?.allowSlideNext || swiper?.allowSlidePrev;

  const handleSlideChange = useCallback((swiperInstance: SwiperClass) => {
    setSliderPosition(
      swiperInstance.isBeginning ? 'start' : swiperInstance.isEnd ? 'end' : 'center',
    );
  }, []);

  const goNext = useCallback(() => {
    if (!swiper) {
      return;
    }
    swiper.slideTo(swiper.activeIndex + 5);
    setSliderPosition(swiper.isBeginning ? 'start' : swiper.isEnd ? 'end' : 'center');
  }, [swiper]);

  const goPrev = useCallback(() => {
    if (!swiper) {
      return;
    }
    swiper.slideTo(swiper.activeIndex - 5);
    setSliderPosition(swiper.isBeginning ? 'start' : swiper.isEnd ? 'end' : 'center');
  }, [swiper]);

  return (
    <div>
      <div className="flex gap-3 jcsb mb-1-5">
        <span className={classes.templateCardSmallLabel}>{label}</span>
        {canSlide ? (
          <div className="row aic gap-1">
            <Icon
              onClick={goPrev}
              className={clsx('pointer', sliderPosition === 'start' && 'disabled')}
              name="chevronLeftSquared"
              size={16}
              color="blue600"
            />
            <Icon
              onClick={goNext}
              className={clsx('pointer', sliderPosition === 'end' && 'disabled')}
              name="chevronRightSquared"
              size={16}
              color="blue600"
            />
          </div>
        ) : null}
      </div>
      <CurrenciesCarousel
        variant="white"
        currencies={currencies}
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
      />
    </div>
  );
};

const TemplateCard: FC<TemplateCardProps> = ({ template }) => {
  const translate = useTranslation();

  const sidebar = useSideBar();

  const handleLearnMore = useCallback(() => {
    sidebar.open('rentingSetup', {
      props: { templateName: template.name },
      sideBarProps: {
        swipeModal: true,
        contentClassName: clsx(
          rentingCommonClasses.rentingSideBar,
          rentingCommonClasses['templateBg-' + template.name],
          rentingCommonClasses.mask,
          isReactNative && rentingCommonClasses.maskNoBlur,
        ),
      },
    });
  }, [template, sidebar]);

  const rentCurrencies = useMemo(
    () => sortCurrencies(template.rentCurrencies, { last: ['USDT', 'USDC'] }),
    [template.rentCurrencies],
  );

  return (
    <div
      className={clsx(classes.templateCard, rentingCommonClasses[`templateBg-${template.name}`])}
    >
      <TemplateHeader templateName={template.name} />
      <div className="column gap-1-5">
        <CurrenciesCarouselWithLabel
          label={translate('RENTING_AVAILABLE_CURRENCIES', { count: rentCurrencies.length }) + ':'}
          currencies={rentCurrencies}
        />
        <CurrenciesCarouselWithLabel
          label={
            translate('RENTING_WITHDRAW_CURRENCIES', {
              count: template.withdrawCurrencies.length,
            }) + ':'
          }
          currencies={template.withdrawCurrencies}
        />
      </div>
      <Button onClick={handleLearnMore} fullWidth variant="whiteBlack">
        {translate('LEARN_MORE')}
      </Button>
    </div>
  );
};

export const New = () => {
  const templatesReducer = useSelector(selectTemplatesReducer);
  return (
    <>
      <div className={classes.root}>
        {templatesReducer.data.map((template) => (
          <TemplateCard key={template.id} template={template} />
        ))}
      </div>
      {templatesReducer.meta.loading && <Loader centered />}
    </>
  );
};
