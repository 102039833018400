import clsx from 'clsx';

import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIssuers, selectWallets } from 'modules/accounts/store/selectors';
import { requestCreateWallet } from 'modules/accounts/store/thunks';
import useDrawer from 'modules/app/hooks/useDrawer';
import { selectAllowedLinkingCurrenciesReducer } from 'modules/cryptoBankCard/store/selectors';
import {
  requestAllowedLinkingCurrencies,
  requestLinkTradingWalletToBankCard,
} from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard } from 'modules/cryptoBankCard/types';
import { useDispatch } from 'store';

import { Smooth } from 'components/common';
import {
  AmountLabel,
  Button,
  CurrencyIcon,
  DetailsDotSeparated,
  Icon,
  Image,
  Loader,
  NoResultLabel,
  WalletsFilters,
} from 'components/ui';
import { WalletsFiltersProps } from 'components/ui/WalletsFilters';

import useFlag from 'hooks/useFlag';
import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';
import { errorToast, successToast } from 'libs/toast';

import { findByProperty } from 'utils/arrayUtils';
import {
  findWallet,
  formatCurrency,
  formatCurrencyWithLabel,
  formatCurrencyWithSymbol,
  isFiat,
} from 'utils/currency';

import classes from './CryptoBankCardConnectAccount.module.scss';

export interface CryptoBankCardConnectAccountProps {
  card: CryptoBankCard;
}

enum AccountType {
  fiat = 'fiat',
  crypto = 'crypto',
}
const CryptoBankCardConnectAccount: FC<CryptoBankCardConnectAccountProps> = ({ card }) => {
  const drawer = useDrawer();
  const translate = useTranslation();
  const dispatch = useDispatch();

  const {
    entityReducer: { data: allowedCurrencies, meta: allowedCurrenciesMeta },
  } = useStoreEntity(selectAllowedLinkingCurrenciesReducer, requestAllowedLinkingCurrencies);

  const loading = useFlag(false);

  const wallets = useSelector(selectWallets);
  const issuers = useSelector(selectIssuers);

  const [activeCurrencyCode, setActiveCurrencyCode] = useState(card.currencyCode);

  const allowedWallets = useMemo(
    () => wallets.filter((w) => allowedCurrencies.includes(w.currencyCode)),
    [wallets, allowedCurrencies],
  );

  const handleSubmit = useCallback(async () => {
    if (!activeCurrencyCode || activeCurrencyCode === card.currencyCode) {
      return;
    }

    const walletForConnect = findByProperty(wallets, 'currencyCode', activeCurrencyCode);

    if (!walletForConnect) {
      return;
    }
    if (!walletForConnect.exist) {
      const issuer = findByProperty(issuers, 'currencyCode', activeCurrencyCode);

      if (!issuer) {
        errorToast(translate('ERROR_CANT_CREATE_WALLET_ISSUER_NOT_WOUND'));
        return;
      }
      loading.on();

      const { success: successWalletCreated } = await dispatch(
        requestCreateWallet({ issuerId: issuer.id }),
      );

      if (!successWalletCreated) {
        loading.off();

        // Error toast will be showed from request error
        return;
      }
    }

    loading.on();

    const { success } = await dispatch(
      requestLinkTradingWalletToBankCard({ bankCardId: card.id, currencyCode: activeCurrencyCode }),
    );
    if (success) {
      successToast(translate('CRYPTO_BANK_CARD_CONNECTED', { currencyCode: activeCurrencyCode }));
      drawer.pop();
    }
    loading.off();
  }, [card, wallets, issuers, drawer, translate, loading, dispatch, activeCurrencyCode]);

  const connectedWallet = useMemo(
    () => (card.currencyCode ? findWallet(allowedWallets, card.currencyCode) : null),
    [card, allowedWallets],
  );

  const hideZeroBalances = useFlag(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentAccountType, setCurrentAccountType] = useState<AccountType>(AccountType.fiat);

  const accountTypes = useMemo<WalletsFiltersProps<AccountType>['accountTypes']>(
    () => [
      { id: AccountType.fiat, label: translate('FIAT') },
      { id: AccountType.crypto, label: translate('CRYPTO') },
    ],
    [translate],
  );

  const filteredWallets = useMemo(
    () =>
      allowedWallets.filter((wallet) => {
        const walletIsFiat = isFiat(wallet.currencyCode);
        if (
          (currentAccountType === AccountType.crypto && walletIsFiat) ||
          (currentAccountType === AccountType.fiat && !walletIsFiat)
        ) {
          return false;
        }
        if (hideZeroBalances.state) {
          const walletFormattedBalance = +formatCurrency(wallet.amount, walletIsFiat, {
            round: 'down',
            separateThousands: false,
          });
          if (walletFormattedBalance === 0) {
            return false;
          }
        }
        if (searchQuery.length) {
          const searchQueryUpperCase = searchQuery.toUpperCase();

          return (
            wallet.currencyCode.includes(searchQueryUpperCase) ||
            wallet.currencyLabel.toUpperCase().includes(searchQueryUpperCase)
          );
        }
        return true;
      }),
    [currentAccountType, allowedWallets, hideZeroBalances.state, searchQuery],
  );

  const renderedWallets = useMemo(() => {
    return filteredWallets.map((wallet) => (
      <div
        key={wallet.currencyCode}
        className={classes.listItem}
        onClick={() => {
          setActiveCurrencyCode(wallet.currencyCode);
        }}
      >
        <div className="row aic gap-2">
          <CurrencyIcon code={wallet.currencyCode} size={40} />
          <div className="column gap-1">
            <span className={classes.boldLabel}>{wallet.currencyLabel}</span>
            <span className={classes.label}>{wallet.currencyCode}</span>
          </div>
        </div>
        <div className="row gap-2 aic">
          <div className="column gap-1 tar">
            <span className={classes.boldLabel}>
              {formatCurrencyWithLabel(wallet.amount, wallet.currencyCode, {
                round: 'down',
              })}
            </span>
            <span className={classes.label}>
              {formatCurrencyWithSymbol(
                wallet.amountInDefaultCurrency,
                wallet.defaultCurrencyCode,
                { round: 'down' },
              )}
            </span>
          </div>
          <div
            className={clsx(
              classes.radioButton,
              activeCurrencyCode === wallet.currencyCode && classes.active,
            )}
          >
            {activeCurrencyCode === wallet.currencyCode && <Icon name="check" size="xs" />}
          </div>
        </div>
      </div>
    ));
  }, [activeCurrencyCode, filteredWallets]);

  return (
    <div className={classes.root}>
      <div className="column gap-3 flex-1">
        <p className={classes.desc}>{translate('CRYPTO_BANK_CARD_CONNECT_ACCOUNT_SUBTITLE')}</p>

        {connectedWallet && (
          <div className={classes.connectedAccountCard}>
            <div className="row jcsb gap-2">
              <div className="column gap-1">
                <span className="label">{translate('CRYPTO_BANK_CARD_CONNECTED_BALANCE')}</span>
                <div className={classes.balanceLabels}>
                  <AmountLabel
                    className="flex-shrink-0"
                    amount={connectedWallet.amount}
                    currencyCode={connectedWallet.currencyCode}
                    showCurrencySymbol
                  />
                  <span className={classes.amountInDefaultCurrency}>
                    /{' '}
                    {formatCurrencyWithLabel(
                      connectedWallet.amountInDefaultCurrency,
                      connectedWallet.defaultCurrencyCode,
                    )}
                  </span>
                </div>
              </div>
              <Image
                name={card.isVirtual ? 'cryptoVirtualBankCard' : 'cryptoBankCard'}
                path="cryptoBankCard"
                className={classes.bankCardImg}
              />
            </div>
            <DetailsDotSeparated
              label={translate('CRYPTO_BANK_CARD_CURRENT_CARD_CURRENCY_ACCOUNT')}
              valueComponent={
                <div className="row aic gap-0-5">
                  <CurrencyIcon code={connectedWallet.currencyCode} size={16} />
                  <span className={classes.currencyAccountValue}>
                    {connectedWallet.currencyCode}
                  </span>
                </div>
              }
            />
          </div>
        )}

        <div className="column flex-1">
          <WalletsFilters<AccountType>
            accountTypes={accountTypes}
            currentAccountType={currentAccountType}
            changeCurrentAccountType={setCurrentAccountType}
            hideZeroAmountsFlag={hideZeroBalances}
            searchQuery={searchQuery}
            changeSearchQuery={setSearchQuery}
          />
          {allowedCurrenciesMeta.loading && !allowedCurrenciesMeta.loaded && (
            <Loader className="mt-3" centered />
          )}
          {searchQuery.length && !filteredWallets.length ? (
            <NoResultLabel className="mt-3" findString={searchQuery} />
          ) : null}
          <Smooth isVisible={!!activeCurrencyCode && activeCurrencyCode !== card.currencyCode}>
            <Button
              loading={loading.state}
              onClick={handleSubmit}
              fullWidth
              variant="greyishGreen"
              className="mt-3"
            >
              {translate('CRYPTO_BANK_CARD_CONNECT_ACCOUNT_CURRENCY', {
                currencyCode: activeCurrencyCode || 'BTC',
              })}
            </Button>
          </Smooth>
          <div className={classes.list}>{renderedWallets}</div>
        </div>
      </div>
    </div>
  );
};

export default CryptoBankCardConnectAccount;
