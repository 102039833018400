import { appActions } from 'modules/app/store';
import { logout } from 'modules/auth/store/externalThunks';
import store from 'store';

import routesByName from 'constants/routesByName';

import { Button } from 'components/ui';

import { getTranslation } from 'libs/i18n';

const handleLogout = () => {
  store.dispatch(logout());
  window.location.replace(window.location.origin + routesByName.signIn);
};
const handleDashboard = () => {
  try {
    store.dispatch(appActions.closeSideBar({ instantly: true }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  window.location.replace(window.location.origin + routesByName.dashboard);
};

const SentryErrorBoundary = ({ error }: { error: Error }) => {
  const translateError = error?.message?.startsWith("Failed to execute 'removeChild' on 'Node'");
  return (
    <div className="h100vh w100 row aic jcc">
      <div className="column gap-2 aic tac px-1">
        <div className="column gap-1">
          <h2>😔 {getTranslation('ERROR_BOUNDARY_TEXT')}</h2>
          {translateError && <p>{getTranslation('ERROR_BOUNDARY_TRANSLATION_ERROR_TEXT')}</p>}
        </div>
        <div className="row gap-2 jcc">
          <Button onClick={handleDashboard}>{getTranslation('DASHBOARD')}</Button>
          <Button onClick={handleLogout}>{getTranslation('PROFILE_LOGOUT')}</Button>
        </div>
      </div>
    </div>
  );
};

export default SentryErrorBoundary;
