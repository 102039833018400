import { FC, useMemo, useState } from 'react';

import { AllowedCountry } from 'modules/app/types';

import { CountryOption, Icon, NoResultLabel, TextInput } from 'components/ui';
import { CountryOptionProps } from 'components/ui/CountryOption';

import { useTranslation } from 'libs/i18n';

import classes from './CountryPickerMobile.module.scss';

interface CountryPickerMobileProps {
  allowedCountries: AllowedCountry[];
  onChange: (country: AllowedCountry) => void;
  value: AllowedCountry | null;
  countryOptionProps?: Partial<CountryOptionProps>;
  withFilter?: boolean;
}
export const CountryPickerMobile: FC<CountryPickerMobileProps> = ({
  allowedCountries,
  value,
  onChange,
  countryOptionProps,
  withFilter = true,
}) => {
  const translate = useTranslation();

  const [filter, setFilter] = useState('');

  const filteredCountries = useMemo(() => {
    if (filter) {
      const filterLower = filter.toLowerCase();

      return allowedCountries.filter(
        (c) =>
          c.name.toLowerCase().includes(filterLower) || c.code.toLowerCase().includes(filterLower),
      );
    }
    return allowedCountries;
  }, [allowedCountries, filter]);

  return (
    <div className="column gap-1 flex-1">
      {withFilter && (
        <TextInput
          value={filter}
          onChange={(e) => setFilter(e.currentTarget.value)}
          label={translate('COUNTRY')}
          placeholder={translate('SIGN_UP_COUNTRY_SEARCH')}
          startAdornment={<Icon name="search" className={classes.searchIcon} size={20} />}
          autoComplete="country-name"
        />
      )}

      <div className={classes.countries}>
        {filteredCountries.map((c) => (
          <CountryOption
            key={c.id}
            onClick={() => {
              onChange(c);
            }}
            country={c}
            active={c.id === value?.id}
            {...countryOptionProps}
          />
        ))}
        {!filteredCountries.length && filter.length ? <NoResultLabel findString={filter} /> : null}
      </div>
    </div>
  );
};
