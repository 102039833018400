import { useCallback, useRef } from 'react';

const pasteSpaceLengths = [4, 9, 14];

const useParseCardNumber = () => {
  const prevValue = useRef('');

  return useCallback((value: string) => {
    let result = value;

    const lastSymbolNumber = +result[result.length - 1];
    if (isNaN(lastSymbolNumber)) {
      result = result.slice(0, -1);
    }

    if (pasteSpaceLengths.includes(value.length)) {
      if (prevValue.current.length > value.length) {
        result = result.slice(0, -1);
      } else {
        result += ' ';
      }
    }

    if (value.length === 16 && !value.includes(' ')) {
      result = result.match(/.{1,4}/g)?.join(' ') || result;
    }
    prevValue.current = result;
    return result;
  }, []);
};

export default useParseCardNumber;
