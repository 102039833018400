import clsx from 'clsx';

import { FC } from 'react';

import { AllowedLanguage } from 'modules/user/constants/language';

import { AgreementText } from 'components/common';
import { Icon } from 'components/ui';

import { Flag } from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { openHelpCenter } from 'utils/thirdPartyLibs';

import classes from './SignTerms.module.scss';

interface SignTermsProps {
  langPicker: Flag;
  appLanguageShortCode: AllowedLanguage['shortCode'];
}
export const SignTerms: FC<SignTermsProps> = ({ langPicker, appLanguageShortCode }) => {
  const translate = useTranslation();

  return (
    <div className={classes.terms}>
      <AgreementText variant="signIn" />
      <div className={classes.mobileTools}>
        <span onClick={openHelpCenter}>{translate('HELP_CENTER')}</span>
        <div onClick={langPicker.on} className="row aic gap-0-5 pointer">
          <span>{appLanguageShortCode}</span>
          <Icon
            className={clsx(classes.langPickerIcon, langPicker.state && classes.active)}
            name="chevronRightSquaredThin"
            size="sm"
          />
        </div>
      </div>
      <span>
        {translate('NEBEUS_ALL_RIGHT_RESERVED', { currentYear: new Date().getFullYear() })}
      </span>
    </div>
  );
};
