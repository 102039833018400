import { FC, ReactElement } from 'react';

import { DigitalSubscriptionType } from 'modules/digitalAccount/types';

import { Image } from 'components/ui';

import classes from './SubscriptionLayout.module.scss';

interface SubscriptionLayoutProps {
  children: ReactElement | ReactElement[];
  subscriptionType: DigitalSubscriptionType;
}

const imgNameBySubType: { [key in DigitalSubscriptionType]: string } = {
  standard: 'nebeusBankLg',
  hodler: 'safeLg',
  whale: 'whaleLg',
};
export const SubscriptionLayout: FC<SubscriptionLayoutProps> = ({ children, subscriptionType }) => {
  return (
    <>
      <div className={classes.component}>{children}</div>

      <Image
        className={classes.image}
        name={imgNameBySubType[subscriptionType]}
        path="digitalAccount"
      />
    </>
  );
};
