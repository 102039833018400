import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import useOrderCryptoVirtualBankCard from 'modules/cryptoBankCard/hooks/useOrderCryptoVirtualBankCard';

import { Button, Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

export interface GoogleWalletOptionAdProps {
  cardExist: boolean;
}
const GoogleWalletOptionAd: FC<GoogleWalletOptionAdProps> = ({ cardExist }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const orderVirtualCard = useOrderCryptoVirtualBankCard();

  const handleAccept = useCallback(() => {
    if (cardExist) {
      drawer.open(commonDrawerTemplates.bankCards({ type: 'crypto' }));
    } else {
      orderVirtualCard.startProcess();
    }
  }, [cardExist, orderVirtualCard, drawer]);

  const handleReject = useCallback(() => {
    drawer.pop();
  }, [drawer]);

  return (
    <div className="column flex-1 jcsb gap-3">
      <div className="flexScrollable gap-3">
        <p className="grey-400">
          {translate(
            cardExist
              ? 'CARD_WITH_GOOGLE_WALLET_CARD_EXIST_AD_DESC'
              : 'CARD_WITH_GOOGLE_WALLET_CARD_ABSENT_AD_DESC',
          )}
        </p>
        <div className="column flex-1 jcc aic">
          <Image name="cryptoBankCardGPay" path="cryptoBankCard" style={{ maxWidth: '400px' }} />
        </div>
      </div>
      <div className="column gap-1-5">
        <Button loading={orderVirtualCard.loading} onClick={handleAccept}>
          {translate(cardExist ? 'UPGRADE' : 'BANK_CARD_GET')}
        </Button>
        <Button onClick={handleReject} variant="creamyBlack">
          {translate('CLOSE')}
        </Button>
      </div>
    </div>
  );
};

export default GoogleWalletOptionAd;
