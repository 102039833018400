import clsx from 'clsx';

import { FC, useCallback, useMemo } from 'react';

import { DatePicker, FloatingElement, Icon } from 'components/ui';
import { DatePickerProps, DatePickerValue } from 'components/ui/DatePicker';
import { TextInput, TextInputProps } from 'components/ui/TextInput';

import useAnchor from 'hooks/useAnchor';
import useFlag from 'hooks/useFlag';

import { formatDDMMYY } from 'utils/date';

import classes from './DatePickerInput.module.scss';

export interface DatePickerInputProps extends Omit<DatePickerProps, 'onCancel'> {
  inputProps: Omit<TextInputProps, 'value' | 'onChange' | 'disabled'>;
  className?: string;
}
export const DatePickerInput: FC<DatePickerInputProps> = ({
  value,
  onChange,
  variant = 'single',
  inputProps,
  className,
  ...props
}) => {
  const isVisible = useFlag(false);
  const [anchor, setAnchor] = useAnchor();

  const valueLabel = useMemo(() => {
    if (variant === 'single') {
      if (value.startDate) {
        return formatDDMMYY(value.startDate);
      }
    } else {
      if (value.startDate && value.endDate) {
        return `${formatDDMMYY(value.startDate)} - ${formatDDMMYY(value.endDate)}`;
      }
    }
  }, [value, variant]);

  const handleChange = useCallback(
    (value: DatePickerValue) => {
      onChange(value);
      isVisible.off();
    },
    [isVisible, onChange],
  );

  return (
    <div className={clsx(className, 'relative')}>
      <TextInput
        value={valueLabel}
        inputContainerClassName="pointer"
        onInputContainerClick={isVisible.toggle}
        disabled
        inputContainerRef={setAnchor}
        endAdornment={
          <Icon
            className={clsx(classes.arrow, isVisible.state && classes.active)}
            name="triangleDownLg"
            size="sm"
          />
        }
        {...inputProps}
      />
      <FloatingElement
        anchorEl={anchor}
        isVisible={isVisible.state}
        onClose={isVisible.off}
        swipeModalProps={{ variant: 'whiteNative' }}
        sizeStyle={{ width: '382px' }}
      >
        <DatePicker
          value={value}
          onChange={handleChange}
          variant={variant}
          onCancel={isVisible.off}
          {...props}
        />
      </FloatingElement>
    </div>
  );
};
