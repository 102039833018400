import clsx from 'clsx';

import { Suspense, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import { selectSideBarStack } from 'modules/app/store/selectors';
import { PopSideBarPayload, SideBarStackItem } from 'modules/app/types';
import useShowRNBottomBarInSidebar from 'modules/app/views/AuthedApp/components/ReactNativeBottomBar/useShowRNBottomBarInSidebar';
import { getComponentByKey } from 'modules/app/views/Sidebar/config';
import { isReactNative } from 'modules/reactNative/utils';

import { StackNavigation } from 'components/common';
import { Button, Icon, NavigationHeader, SwipeModal } from 'components/ui';

import useKeyboardButton from 'hooks/useKeyboardButton';
import useMobile from 'hooks/useMobile';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './Sidebar.module.scss';

interface SideBarItemProps {
  item: SideBarStackItem;
  index: number;
  popSideBar: (payload?: PopSideBarPayload) => void;
  closeSideBar: voidFunc;
  id?: string;
  isMobile: boolean;
  isOneInStack: boolean;
}

const TRANSITION_TIME_MS = 450;

const SideBarItem = memo<SideBarItemProps>(
  ({
    item: { active, props = {}, sideBarProps = {}, key },
    index,
    popSideBar,
    closeSideBar,
    id,
    isMobile,
    isOneInStack,
  }) => {
    const {
      showBackButton = true,
      navigationVariant = 'primary',
      secondaryNavigationProps = {},
      navigationHeaderLabel,
      navigationHeaderProps = {},
      wrapperClassName,
      swipeModal,
      swipeModalMaxHeight,
      swipeModalBlocked,
      backButtonVariant = 'darkGreyOutlined',
      backButtonClassName,
      backButtonAdditionalHandler,
      backButtonLabel,
      onHide,
      variant: sideBarVariant = 'white',
      withoutBottomPadding,
      isPlain = false,
    } = sideBarProps;

    const scrollRef = useRef<HTMLDivElement>(null);

    const translate = useTranslation();
    const showRNBottomBar = useShowRNBottomBarInSidebar();

    const [visible, setVisible] = useState(false);

    useEffect(() => {
      if (!active) {
        setVisible(false);
        setTimeout(() => {
          popSideBar({ soft: false, key, id });
          onHide?.();
        }, TRANSITION_TIME_MS);
      } else {
        setVisible(true);
      }

      // eslint-disable-next-line
    }, [active]);

    const showAsSwipeModal = isMobile && swipeModal;

    // useEffect(() => {
    //   if (!onTop && scrollRef.current?.offsetTop !== 0) {
    //     setTimeout(() => {
    //       scrollRef.current?.scrollTo({ top: 0 });
    //     }, TRANSITION_TIME_MS);
    //   }
    //   // eslint-disable-next-line
    // }, [onTop]);

    const Component = useMemo(() => getComponentByKey(key), [key]);

    const showBackButtonInternal =
      !isReactNative &&
      showBackButton &&
      (!navigationHeaderLabel || navigationHeaderProps.hideBackButton);

    return showAsSwipeModal ? (
      <SwipeModal
        variant={sideBarProps.variant || 'white'}
        onClose={popSideBar}
        isVisible={visible}
        index={index}
        className={clsx(
          sideBarProps.contentClassName,
          swipeModalMaxHeight && classes.swipeModalMaxHeight,
        )}
        canClose={!swipeModalBlocked}
        contentClassName={withoutBottomPadding ? 'pb-0' : undefined}
      >
        <>
          {navigationHeaderLabel && (
            <NavigationHeader
              hideBackButton
              className={clsx(
                classes.navigationHeaderSwipeModal,
                classes.navigationHeader,
                navigationHeaderProps.className,
              )}
              {...navigationHeaderProps}
            >
              {navigationHeaderLabel}
            </NavigationHeader>
          )}
          <Suspense>{Component && <Component {...props} />}</Suspense>
        </>
      </SwipeModal>
    ) : (
      <>
        <div
          ref={scrollRef}
          className={clsx(classes.root, sideBarProps.className, {
            [classes.visible]: visible,
            [classes.reactNative]: isReactNative,
          })}
          style={{ zIndex: 100 + index + 2 }}
        >
          {isPlain ? (
            <Component />
          ) : (
            <div
              className={clsx(
                classes.content,
                classes[sideBarVariant],
                sideBarProps.contentClassName,
                showRNBottomBar && classes.showRNBottomBar,
                withoutBottomPadding && 'pb-0',
                isReactNative && classes.reactNative,
              )}
            >
              {navigationVariant === 'secondary' ? (
                <StackNavigation
                  onBack={!isOneInStack ? popSideBar : undefined}
                  onClose={closeSideBar}
                  {...secondaryNavigationProps}
                  className={clsx(classes.stackNavigation, secondaryNavigationProps.className)}
                />
              ) : navigationHeaderLabel || showBackButtonInternal ? (
                <div className="p-2">
                  {showBackButtonInternal ? (
                    <Button
                      className={backButtonClassName || 'mb-2'}
                      fullWidth
                      onClick={() => {
                        backButtonAdditionalHandler?.();
                        popSideBar();
                      }}
                      variant={backButtonVariant}
                    >
                      <Icon
                        size="sm"
                        className="mr-1"
                        name={isOneInStack ? 'cross' : 'arrowLeft'}
                      />{' '}
                      <span className="textHeightFix">
                        {backButtonLabel || translate(isOneInStack ? 'CLOSE' : 'BACK')}
                      </span>
                    </Button>
                  ) : null}
                  {navigationHeaderLabel && (
                    <NavigationHeader
                      onBack={navigationHeaderProps.hideBackButton ? undefined : popSideBar}
                      backButtonFilled={false}
                      {...navigationHeaderProps}
                      className={clsx(classes.navigationHeader, navigationHeaderProps.className)}
                    >
                      {navigationHeaderLabel}
                    </NavigationHeader>
                  )}
                </div>
              ) : null}

              <div className={clsx('px-2 column flex-1', wrapperClassName)}>
                <Suspense>{Component && <Component {...props} />}</Suspense>
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
);

const SideBar = () => {
  const stack = useSelector(selectSideBarStack);

  const drawer = useDrawer();

  const handleKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
        if (isNotCombinedKey) {
          drawer.pop();
        }
      }
    },
    [drawer],
  );

  useKeyboardButton({ handler: handleKeyboard });

  const handleClickOutside = useCallback(() => {
    const currentSidebarItem = stack[stack.length - 1];
    if (currentSidebarItem?.sideBarProps?.disableClickOutside) {
      return;
    }
    if (currentSidebarItem?.sideBarProps?.closeOnClickOutside) {
      drawer.close();
    } else {
      drawer.pop();
    }
  }, [stack, drawer]);

  const isMobile = useMobile();

  const darkLayerVisible = stack.find((i) => i.active);

  const stackWithoutHiding = stack.filter((i) => i.active);
  return (
    <>
      {!isMobile && (
        <div
          onClick={handleClickOutside}
          className={clsx(classes.darkLayer, {
            [classes.visible]: darkLayerVisible,
          })}
        />
      )}
      {stack.map((stackItem, index) => (
        <SideBarItem
          popSideBar={drawer.pop}
          closeSideBar={drawer.close}
          key={`${stackItem.key}${stackItem.id}`} // if stackItemKey duplicate you must add id
          id={stackItem.id}
          item={stackItem}
          index={index}
          isMobile={isMobile}
          isOneInStack={stackWithoutHiding.length <= 1}
        />
      ))}
    </>
  );
};

export default SideBar;
