import { appActions } from '../store';
import authServices from 'modules/auth/services';
import { initAuthedUser } from 'modules/auth/store/thunks';
import { isReactNative } from 'modules/reactNative/utils';
import { AppDispatch } from 'store';
import { generateRequestDataThunk } from 'store/generators/thunks';
import { generateRequestThunk } from 'store/generators/thunks';

import { getTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import appServices from '../services';
import { AllowedCountry } from '../types';
import { BlogPost } from '../types';
import { OperationCommission } from '../types';

// cli-import

export const requestAllowedCountries = generateRequestDataThunk<AllowedCountry[]>({
  updateState: appActions.updateAllowedCountries,
  request: appServices.getAllowedCountries,
  options: { showErrorToast: false },
});

export const initApp = () => async (dispatch: AppDispatch) => {
  if (authServices.getAccessToken()) {
    if (isReactNative) {
      dispatch(appActions.closeSideBar({ instantly: true }));
    }
    await dispatch(initAuthedUser());
  }

  dispatch(appActions.updateInited(true));
};
export const requestSendOtpCode = generateRequestThunk({
  request: appServices.sendOtpCode,
  options: {
    showErrorToast: false,
    onSuccess: () => {
      successToast(getTranslation('CONFIRM_OTP_CODE_SENT'));
    },
  },
});
export const requestVerifyOtpCode = generateRequestThunk({
  request: appServices.verifyOtpCode,
  options: { showErrorToast: false },
});

export const requestBlogPostsDesktop = generateRequestDataThunk<BlogPost[]>({
  updateState: appActions.updateBlogpostDesktop,
  request: () => appServices.getBlogPosts('desktop'),
  options: { showErrorToast: false },
});
export const requestBlogPostsMobile = generateRequestDataThunk<BlogPost[]>({
  updateState: appActions.updateBlogpostMobile,
  request: () => appServices.getBlogPosts('apps'),
  options: { showErrorToast: false },
});
export const requestOperationsCommissions = generateRequestDataThunk<OperationCommission[]>({
  updateState: appActions.updateOperationsCommissions,
  request: appServices.getOperationsCommissions,
  options: { showErrorToast: false },
});
export const requestMaintenance = generateRequestThunk({
  request: appServices.getMaintenance,
  options: {
    showErrorToast: false,
    onSuccess: (dispatch, data) => {
      dispatch(appActions.updateMaintenance(data));
    },
  },
});

// cli-thunk
