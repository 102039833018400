export const template = `
<html lang="en">
  <head>
    <title>Mirror loan agreement</title>
    <style>
      body {
        margin: 0;
        padding: 24px;
        color: #2a2b2a;
        font-family: SegoeUI, sans-serif;
      }
      b {
        font-weight: 600;
      }
      p,
      span {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        padding: 0;
      }
      h1,
      h2,
      h3,
      h4 {
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
      h1 {
        font-size: 28px;
        line-height: 34px;
        margin: 24px 0;
      }
      h2 {
        font-size: 24px;
        line-height: 32px;
        margin: 16px 0;
      }
      h3 {
        font-size: 20px;
        line-height: 24px;
        margin: 12px 0;
      }
      h4 {
        font-size: 16px;
        line-height: 24px;
        margin: 12px 0 8px;
      }
      .ml-2 {
        margin-left: 16px;
      }
      .mt-2 {
        margin-top: 16px;
      }
      .mt-4 {
        margin-top: 32px;
      }
      table {
        border: 1px solid #000;
        border-collapse: collapse;
      }
      td {
        width: 100px;
        height: 30px;
        padding: 10px;
        font-size: 12px;
        border: 1px solid #000;

        color: #000;
      }
    </style>
  </head>
  <body>
    <h1 class="py-3">Loan Agreement</h1>

    <div>
      <h2>Parties</h2>

      <div class="ml-2">
        <b>Borrower:</b> {userName}, residing at {userAddress} (hereinafter referred to as the
        "Borrower").<br />
        <b>Lender:</b> Rintral Trading SL, incorporated and registered in Spain with company number
        B66096686, whose registered office is at C/ de la Llacuna, 11, 08005 - Barcelona, Spain
        (hereinafter referred to as the "Lender").
      </div>
    </div>

    <p class="mt-2">
      The Borrower and the Lender shall hereinafter be jointly referred to as the "Parties".
    </p>

    <h3>1. Loan terms</h3>
    <table>
      <tr>
        <td>Loan amount</td>
        <td>
          Interest rate,<br />
          Year
        </td>
        <td>
          Loan term,
          <br />
          End day
        </td>
        <td>Monthly payment</td>
        <td>Collateral Amount</td>
      </tr>
      <tr>
        <td>{loanAmount} {loanCurrency}</td>
        <td>{interestRate}%</td>
        <td>{termMonth} month(s), {endDate}</td>
        <td>{monthlyPayment} {loanCurrency}</td>
        <td>{collateralAmount} {collateralCurrency}</td>
      </tr>
    </table>
    <p class="mt-2">
      Calculated according to Bank of Spain Circular 5/2012 (BOE № 161 от 06.07.2012) and with law
      16/2011, of June 24, on consumer credit contracts.
    </p>

    <h3>2. Definitions</h3>
    <p>
      <b>Loan Amount:</b> The principal amount of {loanAmount} {loanCurrency}.<br />
      <b>Interest Rate:</b> {interestRate}% per annum.<br />
      <b>Loan Term:</b> {termMonth} months, ending on {endDate}.<br />
      <b>Monthly Payment:</b> {monthlyPayment} {loanCurrency}.<br />
      <b>Collateral Amount:</b>{collateralAmount} {collateralCurrency}.<br />
      <b>Guarantee Deposit:</b> The Borrower shall deposit the same amount of cryptocurrency
      initially deposited as collateral under this loan agreement.<br />
      <b>Event of Default:</b> Any event or circumstance listed in this agreement.<br />
      <b>Loan:</b> The sum lent by the Lender to the Borrower under this agreement.<br />
      <b>Repayment Date:</b> Each of the dates set out in Appendix A.<br />
      <b>Business Day:</b> A Day other than a Saturday, Sunday, or public holiday in Spain when
      banks are open for business.<br />
      <b>Loan Currency:</b> The currency in which the loan was issued.<br />
      <b>Calendar Day:</b> Any day of the week, including Saturday, Sunday, or public holiday in
      Spain.<br />
      <b>Agreement:</b> This mirror loan agreement.<br />
      <b>Security:</b> Any charge, pledge, lien, assignment by way of security or other security
      interest securing any obligation.<br />
      <b>Collateral Pledge:</b> Funds deposited by the Borrower in their Nebeus Hot Wallet as
      collateral.<br />
      <b>Security Period:</b> The period starting on the date of this deed and ending when the
      Lender is satisfied that all liabilities have been paid and discharged.<br />
      <b>Secured Liabilities:</b> All obligations of the Borrower to the Lender under this
      agreement.<br />
      <b>Cryptocurrency:</b> BTC (Bitcoin), ETH (Ethereum), LTC (Litecoin), BCH (Bitcoin Cash), XRP
      (Ripple), XLM (Stellar).<br />
      <b>Customer's Account:</b> Account opened by the Borrower with Nebeus with funds deposited in
      the Nebeus Hot Wallet.<br />
      <b>Margin Call:</b> Occurs when the value of the collateral drops below a set rate, triggering
      a requirement for the Borrower to cover the shortfall.<br />
    </p>

    <h4>2.1 Security cushion</h4>
    <p>
      <b>Term of the Loan: </b>period of months from the date the Loan is approved. <br />
      <b>An origination fee</b> is a one-time fee a lender charges for processing a new loan
      application. It is a percentage (2%) of the total loan amount and covers the costs of
      underwriting, processing, and approving the loan. The lender is authorized to deduct the fee
      from the Loan Principal Amount.
    </p>

    <h4>2.2 Interpretation</h4>
    <p>
      In this agreement, the following rules of interpretation shall apply:<br />
      Clause, Schedule, and paragraph headings shall not affect the interpretation of this
      agreement. A reference to a person includes individuals, firms, companies, corporations,
      partnerships, unincorporated bodies of persons, governments, states or agencies of a state,
      and any association, trust, joint venture, or consortium, whether or not having separate legal
      personality.<br />
      Unless the context otherwise requires, words in the singular include the plural, and words in
      the plural include the singular. Similarly, a reference to one gender includes all genders.<br />
      A reference to a party includes that party's successors, permitted assigns, and permitted
      transferees.<br />
      This agreement shall be binding on and benefit the parties to this agreement and their
      respective personal representatives, successors, permitted assigns, and permitted transferees.
      A reference to a statute or statutory provision is a reference to it as amended, extended, or
      re-enacted from time to time, including all subordinate legislation made under that statute or
      statutory provision.<br />
      A reference to a time of day is to London and Madrid time. A reference to writing or written
      includes fax and email.<br />
      An obligation on a party not to do something includes an obligation not to allow that thing to
      be done. A reference to this agreement, or any provision of it, or to any other agreement or
      document referred to in any Finance Document, is a reference to this agreement, that
      provision, or such other agreement or document as amended, in each case, other than in breach
      of the provisions of this agreement, from time to time.<br />
      Unless the context otherwise requires, a reference to a clause or Schedule is to a clause of,
      or Schedule to, this agreement, and a reference to a paragraph is to a paragraph of the
      relevant Schedule. Any words following the terms including, include, in particular, for
      example, or any similar expression shall be construed as illustrative and shall not limit the
      sense of the words, description, definition, phrase, or term preceding those terms.<br />
      A reference to a document in agreed form is to that document in the form agreed by the Lender
      and the Borrower and initiated by or on their behalf for identification. A reference to an
      amendment includes a novation, re-enactment, supplement, or variation, and amended shall be
      construed accordingly.<br />
      A reference to assets includes present and future properties, undertakings, revenues, rights,
      and benefits of every description. A reference to an authorization includes approval,
      authorization, consent, exemption, filing, license, notarization, registration, and
      resolution.<br />
      A reference to a certified copy of a document means a copy certified to be a true, complete,
      and up-to-date copy of the original document, in writing and signed by a director or the
      secretary of the party delivering the document. A reference to "continuing" in relation to an
      Event of Default means an Event of Default that has not been remedied or waived. A reference
      to "determines" or "determined" means, unless the contrary is indicated, a determination made
      at the discretion of the person making it.<br />
      A reference to a disposal of any asset, undertaking, or business includes a sale, lease,
      license, transfer, loan, or other disposal by a person of that asset, undertaking, or
      business, whether by a voluntary or involuntary single transaction or series of transactions.
      A reference to a regulation includes any regulation, rule, official directive, request, or
      guideline, whether or not having the force of law, of any governmental, inter-governmental, or
      supranational body, agency, department, or regulatory, self-regulatory, or other authority or
      organization.<br />
      Unless the context otherwise requires, any reference to European Union law that is directly
      applicable or directly effective in the UK at any time is a reference to it as it applies in
      England and Wales from time to time, including as retained, amended, extended, re-enacted, or
      otherwise given effect on or after 11 pm on 31 January 2020.
    </p>

    <h3>3. Loan</h3>
    <p>
      The total principal amount, equal to {totalRepaymentAmount} {loanCurrency}, is the
      corresponding market cost of Funds deposited by the Borrower in their Nebeus Hot Wallet as
      half part of the collateral + origination fee.
    </p>

    <h3>4. Purpose of Loan</h3>
    <div>
      <h4>4.1 Use of Loan Proceeds:</h4>
      <p>
        The Lender shall use all funds borrowed under this agreement exclusively to purchase an
        equivalent amount of cryptocurrency to that which was initially deposited by the Borrower as
        collateral and for payment of the origination fee. Such purchased cryptocurrency shall serve
        as a Guarantee Deposit
      </p>

      <h4>4.2 Origination Fee:</h4>
      <p>
        A one-time fee of {originationFee}% of the total loan amount will be charged for processing
        the loan application and deducted from the Loan Principal Amount.
      </p>
    </div>

    <h3>5. Right of Withdrawal</h3>
    <p>
      <b> 5.1</b> By the provisions of Royal Legislative Decree 1/2007, of 16 November, which
      approves the revised text of the General Law for the Defense of Consumers and Users and other
      complementary laws, and Law 16/2011, of 24 June, on consumer loan agreements, the Borrower has
      a period of fourteen (14) Calendar Days to withdraw from this Agreement without indicating
      reasons or incurring any penalties, starting from the date of signature.<br />

      <b>5.2</b> To exercise this right, the Borrower must notify the Lender in writing at C/ de la
      Llacuna, 11, 08005 - Barcelona, Spain, by email at
      <a href="mailto:info@nebeus.com">info@nebeus.com</a>, or by telephone at +34937377770.<br />
      <b>5.3 </b>The Borrower must repay the capital and accrued interest without delay and no later
      than 30 Calendar Days after sending the notice of withdrawal.
    </p>

    <h3>6. Repayment</h3>
    <p>
      <b>6.1</b> The Borrower will repay to the Lender the Loan together with accrued interest in
      full by repaying the amounts set out below in Appendix A on each Repayment Date, on that
      Repayment Date or, if earlier, following a demand by the Lender at any time after an Event of
      Default has occurred, together in each case with all accrued interest and other amounts
      outstanding under this agreement. Repayment Date and Amount are indicated in the section of
      loans in the personal account URL https://account.nebeus.com/app/#/loan/overview and Appendix
      A or Mobile application in section Loan.<br />
      <b>6.2</b> Six days before the monthly payment is due, the Borrower will receive an email
      reminder.<br />
      <b>6.3</b> One day before the monthly payment is due, the Borrower will receive a second email
      reminder.<br />
      <b>6.4</b> Monthly payments will be withdrawn automatically from the Customer's Account in the
      Loan Currency.<br />
    </p>

    <h3>7. Early repayment</h3>
    <p>
      <b>7.1</b> The Borrower may request, free of charge, a statement of account in the form of a
      repayment chart.<br />
      <b>7.2</b> By application of the provisions of Article 4 of Law 16/2011 of 24 June on Consumer
      Loan Agreements, the above terms shall not apply to loan agreements exceeding €75,000.<br />
      <b>7.3</b> For loans exceeding €75,000, a different and separate loan agreement will be
      created and signed with each Borrower individually. If such an agreement has not been created,
      early repayment is not allowed under this Agreement.<br />
    </p>

    <h3>8. Interest</h3>
    <p>
      <b>8.1</b> The Borrower shall pay nominal interest on the Loan at the rate of {interestRate}%
      per annum.<br />
      <b>8.2</b> Interest shall accrue daily and be payable monthly, in arrear, on the last Business
      Day of each month, according to the present deed.<br />
      <b>8.3</b> The formula for calculating accrued interest shall be as follows:<br /><br />
    </p>

    <p>
      Interest = P * r * n / 365<br />

      Where:<br />

      P = Principal amount of loan<br />

      r = interest rate<br />

      n = Term of loan in days<br />
    </p>

    <h3>9. Default Interest</h3>
    <p>
      <b>9.1</b> Overdue and unpaid Loan installments will accrue default interest from the due date
      until full repayment at a rate of 0.1% of the Loan premium daily for 10 calendar days. and
      without the need for a notice to be sent to the Borrower by the Lender<br />
      <b>9.2</b> If the Borrower fails to make a payment, they will receive late payment
      notification emails on the 3rd, 6th, and 9th day after the payment due date.
    </p>

    <h3>10. Annual Percentage Rate</h3>
    <p>
      <b>10.1</b> The Annual Percentage Rate is set at 10 %.<br />
      <b>10.2</b> Annual Percentage Rate ("APR") means the total cost of the Loan, expressed as an
      annual percentage of the amount of Loan granted. For information purposes, it should be noted
      that this APR is obtained by applying the formula contained in Law 16/2011 of 24 June on
      Consumer Loan Agreements (Annex I of that Law).<br />
      <b>10.3</b> The APR formula is also included in Annex VII of Bank of Spain Circular 5/2012 of
      27 June, to credit institutions and payment service providers on transparency of banking
      services and responsibility in the granting of loans.<br />
      <b>10.4</b> For the calculation of the APR, all costs are considered, including interest,
      commissions, taxes, and any other costs that the Borrower must pay in connection with the Loan
      Agreement and that are known to the Lender, except for notary fees.<br />
      <b>10.5</b> The calculation is made on the assumption that the Agreement will remain in force
      throughout its duration and that the Parties will fulfill their obligations under the agreed
      terms and conditions.
    </p>

    <h3>11. Costs and Taxes</h3>
    <p>
      <b>11.1</b> The Borrower will pay, or reimburse, all costs and liabilities incurred by the
      Lender concerning suing for or recovering the Loan.<br />
      <b>11.2</b> Any expenses, taxes, or liens arising from the execution and performance of this
      Agreement. or the fees accrued by notaries public, or from registrations of guarantees in any
      Registry, shall be borne by the Borrower.
    </p>

    <h3>12. Assignment and Transfer</h3>
    <p>
      <b>12.1</b> The Borrower may not assign or transfer any of their rights or obligations under
      this Agreement.<br />
      <b>12.2</b> The Lender reserves the right to assign its rights and obligations to any third
      party, notifying the Borrower of such assignment, as well as any other guarantee formalized on
      the occasion of this Agreement, communicating this assignment to the Borrower. All without
      prejudice to the provisions of Article 31 of Law 16/2011 of 24 June on Consumer Loan
      Agreements.
    </p>

    <h3>13. Events of Default</h3>
    <p>
      <b>13.1</b> Events of Default include non-payment, bankruptcy, death or incapacity, unforeseen
      circumstances preventing compliance, and breach of any essential obligation under this
      Agreement.<br />
      <b>13.2</b> Upon an Event of Default, the Lender may terminate this Agreement or demand its
      compliance and claim damages.<br />
      <b>13.3</b> The Borrower will be notified of the Event of Default by registered letter,
      telegram, telex, or any other reliable manner.<br />
      <b>13.4</b> Likewise, at the occurrence of the Event of Default provided for in sections "a"
      and "b" of clause 12.1., a portion of the Collateral Pledge is liquidated to cover the loan
      premium. The remaining crypto balance is then deposited back into the Borrower's account in
      the form of the original crypto used.
    </p>

    <h3>14. Margin Call</h3>
    <p>
      <b>14.1</b> The Borrower will be notified by email if the value of the collateral falls by
      20%, prompting an increase in the Collateral Pledge.<br />
      <b>14.2</b> If the value of the Security cushion falls by 80%, a margin call occurs, and the
      crypto used as collateral is automatically sold to cover the Loan
    </p>

    <h3>15. Account Statement</h3>
    <p>
      <b>15.1</b> The Borrower is entitled to receive a statement of account in the form of a
      repayment chart free of charge upon request at any time during the term of this Agreement,
      particularly in the event of capital repayment.
    </p>

    <h3>16. Covenants</h3>
    <p>
      <b>16.1</b> The Borrower covenants with the Lender that, from the date of this Agreement until
      all its liabilities under this Agreement have been discharged, it will not create, or permit
      to subsist, any Security on or over the Collateral Pledge other than the Security created by
      this Agreement.<br />
      <b>16.2</b> The Borrower shall, on demand, pay to the Lender and discharge the Secured
      Liabilities when they become due.
    </p>

    <h3>17. Guarantee Deposit</h3>
    <p>
      <b>17.1</b> As a continuing security for the payment and discharge of the Secured Liabilities,
      the Borrower, with full title guarantee, charges to the Lender by way of first fixed charge
      all of its present and future right, title, benefit, and interest in and to the Collateral
      Pledge held in their customer account deposited on the Nebeus platform.<br />
      <b>17.2</b> The Borrower is the sole legal and beneficial owner of and has good, valid, and
      marketable title to the Collateral Pledge.<br />
      <b>17.3</b> The Collateral Pledge is free from any Security other than the Security created by
      this deed.
    </p>

    <h3>18. Bankruptcy</h3>
    <p>
      Either:<br />

      <b>18.1</b> the Borrower stops or suspends payment of any of its debts or is unable to pay any
      of its debts as they fall due; or<br />
      <b>18.2</b> a petition for a bankruptcy order is presented or a bankruptcy order is made
      against the Borrower, or the Borrower makes an application for a bankruptcy order.
    </p>

    <h3>19. Notices</h3>
    <p>
      <b>19.1</b> Any notice or communication given under this Agreement must be in writing and sent
      to the relevant party's address or email specified in this Agreement.<br />
      <b>19.2</b> Any notice or other communication to be given under this Agreement must be given
      to the relevant party at the relevant address stated at the start of this agreement or as
      otherwise specified by the relevant party in writing to the other party.
    </p>

    <h3>20. Payment of account charges</h3>
    <p>
      <b>20.1</b> The Borrower shall promptly pay all charges and other outgoings in respect of the
      Collateral Pledge and, on demand, produce evidence of payment to the Lender.<br />
      <b>20.2</b> Security becomes enforceable in the Event of Default.<br />
      <b>20.3</b> The security constituted by this deed shall become immediately enforceable if an
      Event of Default occurs.
    </p>

    <h3>21. Order of application of proceeds</h3>
    <p>
      <b>21.1</b> All monies received or recovered by the Lender under this deed or in connection
      with the realization or enforcement of all or part of the Collateral Pledge constituted by
      this agreement, shall (subject to the claims of any person having prior rights and by way of
      variation of the LPA 1925) be applied in the following order of priority (but without
      prejudice to the Lender's right to recover any shortfall from the Borrower):<br />
      <b>1.</b> in or towards payment of all costs, liabilities, charges, and expenses incurred by
      or on behalf of the Lender (and any delegate, attorney, or agent appointed by it) under or in
      connection with this agreement;<br />
      <b>2.</b> in or towards payment of the Secured Liabilities in any order and manner that the
      Lender determines;<br />
      <b>3.</b> and in payment of the surplus (if any) to the Borrower or other person entitled to
      it.
    </p>

    <h3>22. Rights and remedies</h3>
    <p>
      <b>22.1</b> The rights and remedies provided under this agreement are cumulative and are in
      addition to, and not exclusive of, any rights and remedies provided by law.
    </p>

    <h3>23. Personal Data Processing</h3>
    <p>
      <b>23.1</b> By virtue of this Agreement, the Borrower is hereby informed of the fact that any
      personal data included therein shall be inserted in one or more personal data files of the
      Lender as defined in Law 3/2018, 5th December on the Protection of Personal Data and the
      guarantee of digital rights.<br />
      <b>23.2</b> To comply with current legislation, the Borrower is hereby informed of their
      rights regarding personal data, including the right to access, rectify, cancel, or object to
      the processing of their personal data; the right to be forgotten; the right to data
      portability; and the right to limit data processing. These rights may be exercised if the
      personal data are incorrect or incomplete. The Borrower can exercise these rights by sending a
      signed request, including their full name, address, and a photocopy of their ID or any other
      valid identification document, to the following email address:
      <a href="mailto:dpo@nebeus.com">dpo@nebeus.com</a>.
    </p>

    <h3>24. Entire agreement, Amendments and Invalidity</h3>
    <p>
      <b>24.1</b> This Agreement, along with any exhibits, appendices, schedules, and amendments
      hereto, encompasses the entire agreement of the parties, and supersedes all previous
      understandings and agreements between the Parties.<br />

      <b>24.2</b> No modification or waiver of the rights conferred by this contract shall be valid
      unless made in writing and signed by the relevant parties.<br />
      <b>24.3</b> If any provision (or part of a provision) of this deed is or becomes invalid,
      illegal, or unenforceable, it shall be deemed modified to the minimum extent necessary to make
      it valid, legal, and enforceable. If such modification is not possible, the relevant provision
      (or part of a provision) shall be deemed deleted. Any modification to or deletion of a
      provision (or part of a provision) under this clause shall not affect the legality, validity,
      and enforceability of the rest of this deed.
    </p>

    <h3>25. Counterparts</h3>
    <p>
      <b>25.1</b> This deed may be executed in any number of counterparts, each of which when
      executed and delivered shall constitute a duplicate original, but all the counterparts shall
      together constitute one deed.<br />
      <b>25.2</b> Transmission of the executed signature page of a counterpart of this deed by fax
      or email (in PDF, JPEG, or other agreed format) shall take effect as delivery of an executed
      counterpart of this deed. If either method of delivery is adopted, without prejudice to the
      validity of the deed thus made, each party shall provide the others with the original of such
      counterpart as soon as reasonably possible thereafter.<br />
      <b>25.3</b> No counterpart shall be effective until each party has executed and delivered at
      least one counterpart.
    </p>

    <h3>26. Third party rights</h3>
    <p>
      <b>26.1</b> Except as expressly provided elsewhere in this deed, a person who is not a party
      to this agreement shall not have any rights under the Contracts (Rights of Third Parties) Act
      1999 to enforce, or enjoy the benefit of, any term of this deed. This does not affect any
      right or remedy of a third party that exists or is available, apart from that Act.<br />
      <b>26.2</b> The rights of the parties to rescind or agree to any amendment or waiver under
      this agreement are not subject to the consent of any other person.
    </p>

    <h3>27. Delivery</h3>
    <p>
      <b>27.1</b> Any notice or other communication given to a party under or in connection with
      this deed shall be sent to::<br /><br />

      the Borrower at:<br /><br />
      Address: {userAddress} <br />
      Email: {userEmail}<br />
      Attention: {userName} <br /><br />

      (ii) the Lender at:<br /><br />

      Rintral Trading SL<br />
      C/ de la Llacuna, 11, 08005 Barcelona<br />
      Email: <a href="mailto:info@nebeus.com">info@nebeus.com</a><br />
      Attention: Financial Department
      <br />
      <br />

      or to any other address or fax number/email as is notified in writing by one party to the
      other from time to time.<br />

      <b>27.2</b> This clause does not apply to the service of any proceedings or other documents in
      any legal action or, where applicable, any arbitration or other method of dispute resolution.
    </p>

    <h3>28. No adverse claims</h3>
    <p>
      <b>28.1</b> The Borrower has not received or acknowledged notice of any adverse claim by any
      person in respect of the Collateral Pledge or any interest in it.
    </p>

    <h3>29. No adverse covenants</h3>
    <p>
      <b>29.1</b> There are no covenants, agreements, reservations, conditions, interests, rights,
      or other matters whatsoever that materially and adversely affect the Collateral Pledge.
    </p>

    <h3>30. No breach of laws</h3>
    <p>
      <b>30.1</b> There is no breach of any law or regulation that materially and adversely affects
      the Collateral Pledge.
    </p>

    <h3>31. Enforceable security</h3>
    <p>
      <b>31.1</b> This agreement is signed as a deed and constitutes and will constitute the legal,
      valid, binding, and enforceable obligations of the Borrower, and is, and will continue to be,
      effective security overall and every part of the Secured Assets per its terms.
    </p>

    <h3>32. Governing Law and Jurisdiction</h3>
    <p>
      <b>32.1</b> This Agreement and any disputes arising out of it will be governed by and
      construed under the law of Spain.<br />
      <b>32.2</b> Concerning any legal action or proceedings arising out of or in connection with
      this Agreement, the Parties irrevocably submit to the exclusive jurisdiction of the Courts and
      Tribunals of the domicile of the Borrower, waiving their right to any other forum to which
      they may be entitled.
    </p>

    <p class="mt-4">
      Signed by <b>{userName}</b>:<br />
      ....................<br />
      [ELECTRONIC SIGNATURE OF BORROWER]<br /><br /><br />

      Signed by Lender:<br />
      ....................<br />
      [ELECTRONIC SIGNATURE OF LENDER]
    </p>
  </body>
</html>


`;
