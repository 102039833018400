import { Field } from 'react-final-form';

import { TextInputField } from 'components/form';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup from 'libs/yup';

export interface ClientDetailsFormValues {
  clientName: string;
  clientCountry: string;
  clientCity: string;
  clientPostCode: string;
  clientAddress1: string;
  clientAddress2: string;
  clientEmail: string;
}

export const clientDetailsValidationSchema = yup.object().shape(
  {
    clientName: yup
      .string()
      .min(2, getTranslation('VALIDATION_MIN', { min: 2 }))
      .max(80, getTranslation('VALIDATION_MAX', { max: 80 }))
      .requiredDefault(),
    clientCountry: yup
      .string()
      .min(4, getTranslation('VALIDATION_MIN', { min: 4 }))
      .max(40, getTranslation('VALIDATION_MAX', { max: 40 }))
      .requiredDefault(),
    clientCity: yup
      .string()
      .min(2, getTranslation('VALIDATION_MIN', { min: 2 }))
      .max(60, getTranslation('VALIDATION_MAX', { max: 60 }))
      .requiredDefault(),
    clientPostCode: yup
      .string()
      .min(4, getTranslation('VALIDATION_MIN', { min: 4 }))
      .max(10, getTranslation('VALIDATION_MAX', { max: 10 }))
      .requiredDefault(),
    clientAddress1: yup
      .string()
      .min(8, getTranslation('VALIDATION_MIN', { min: 8 }))
      .max(100, getTranslation('VALIDATION_MAX', { max: 100 }))
      .requiredDefault(),
    clientAddress2: yup.string().when('clientAddress2', (val?: string) => {
      if (val && val.length > 0) {
        return yup.string().max(100, getTranslation('VALIDATION_MAX', { max: 100 }));
      }
      return yup.string().optional();
    }),
    clientEmail: yup.string().email(getTranslation('VALIDATION_EMAIL')).requiredDefault(),
  },
  [['clientAddress2', 'clientAddress2']],
);
export const ClientDetailsForm = () => {
  const translate = useTranslation();

  return (
    <div className="column gap-2">
      <Field
        name="clientName"
        placeholder={translate('INVOICE_COMPANY_OR_CLIENT_NAME')}
        component={TextInputField}
        autoComplete="organization"
      />
      <Field
        name="clientAddress1"
        placeholder={translate('PLACEHOLDER_ADDRESS_STREET_AND_NUMBER')}
        component={TextInputField}
        autoComplete="street-address"
      />
      <Field
        name="clientAddress2"
        placeholder={translate('PLACEHOLDER_ADDRESS_MORE_DETAILS')}
        component={TextInputField}
        autoComplete="address-line2"
      />
      <div className="row gap-2">
        <Field name="clientCity" placeholder={translate('CITY')} component={TextInputField} />
        <Field
          name="clientPostCode"
          placeholder={translate('POSTCODE')}
          component={TextInputField}
          autoComplete="postal-code"
        />
      </div>
      <Field
        name="clientCountry"
        placeholder={translate('COUNTRY')}
        component={TextInputField}
        autoComplete="countryName"
      />
      <Field
        name="clientEmail"
        placeholder={translate('EMAIL')}
        component={TextInputField}
        autoComplete="email"
      />
    </div>
  );
};
