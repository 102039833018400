import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import rentingDrawerTemplates from 'modules/cryptoRenting/constants/drawerTemplates';

import routesByName from 'constants/routesByName';

import { Button, DrawerHeader, Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './RentingHighestRewardsAd.module.scss';

const RentingHighestRewardsAd = () => {
  const drawer = useDrawer();
  const translate = useTranslation();
  const navigate = useNavigate();

  const handleClickStartRenting = useCallback(() => {
    drawer.replace(rentingDrawerTemplates.rentingSetup({ templateName: 'Baobab' }));
    navigate(routesByName.cryptoRenting('new'));
  }, [drawer, navigate]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className="column gap-1">
          <DrawerHeader title={translate('RENTING_HIGHEST_REWARDS_AD_TITLE')} />
          <p
            dangerouslySetInnerHTML={{ __html: translate('RENTING_HIGHEST_REWARDS_AD_SUBTITLE') }}
          />
        </div>
        <Image className={classes.img} name="rentingCoins" path="cryptoRenting" />
      </div>

      <Button onClick={handleClickStartRenting}>{translate('START_NOW')} →</Button>
    </div>
  );
};

export default RentingHighestRewardsAd;
