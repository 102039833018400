import { LoanTemplate } from 'modules/loans/types';

import { TranslationKey } from 'libs/i18n';

export const LOANS_REQUEST_LIMIT_PAGE = 6;
export const LOANS_MARGIN_CALL_DURATION_IN_DAYS = 3;

export const templateImageByType: { [key in LoanTemplate['type']]: string } = {
  QUICK: 'flexible',
  FLEXIBLE: 'flexible',
  BULLET: 'bullet',
  ONLY_INTEREST: 'interestOnly',
  MIRROR: 'mirror',
};
export const templateTitleByType: { [key in LoanTemplate['type']]: TranslationKey } = {
  QUICK: 'LOANS_TEMPLATE_QUICK_TITLE',
  FLEXIBLE: 'LOANS_TEMPLATE_FLEXIBLE_TITLE',
  BULLET: 'LOANS_TEMPLATE_BULLET_TITLE',
  ONLY_INTEREST: 'LOANS_TEMPLATE_ONLY_INTEREST_TITLE',
  MIRROR: 'LOANS_TEMPLATE_MIRROR_TITLE',
};
export const templateTitleFullByType: { [key in LoanTemplate['type']]: TranslationKey } = {
  QUICK: 'LOANS_TEMPLATE_QUICK_TITLE_FULL',
  FLEXIBLE: 'LOANS_TEMPLATE_FLEXIBLE_TITLE_FULL',
  BULLET: 'LOANS_TEMPLATE_BULLET_TITLE_FULL',
  ONLY_INTEREST: 'LOANS_TEMPLATE_ONLY_INTEREST_TITLE_FULL',
  MIRROR: 'LOANS_TEMPLATE_MIRROR_TITLE_FULL',
};
export const templateDescriptionByType: { [key in LoanTemplate['type']]: TranslationKey } = {
  QUICK: 'LOANS_TEMPLATE_FLEXIBLE_DESC',
  FLEXIBLE: 'LOANS_TEMPLATE_FLEXIBLE_DESC',
  BULLET: 'LOANS_TEMPLATE_BULLET_DESC',
  ONLY_INTEREST: 'LOANS_TEMPLATE_ONLY_INTEREST_DESC',
  MIRROR: 'LOANS_TEMPLATE_MIRROR_DESC',
};
export const templatesPriority: LoanTemplate['type'][] = [
  'MIRROR',
  'BULLET',
  'ONLY_INTEREST',
  'FLEXIBLE',
];
