import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export default function extendAxiosInstance(
  baseInstance: AxiosInstance,
  newConfig: AxiosRequestConfig,
) {
  const newInstance = axios.create({
    ...baseInstance.defaults,
    ...newConfig,
  });

  // @ts-expect-error
  baseInstance.interceptors.request.handlers.forEach((interceptor) => {
    // @ts-expect-error
    newInstance.interceptors.request.use(...Object.values(interceptor));
  });
  // @ts-expect-error
  baseInstance.interceptors.response.handlers.forEach((interceptor) => {
    // @ts-expect-error
    newInstance.interceptors.response.use(...Object.values(interceptor));
  });

  return newInstance;
}
