import { useSwiper } from 'swiper/react';

import { useEffect, useMemo, useState } from 'react';

const useSwiperSlideSizeObserver = () => {
  const swiper = useSwiper();

  const [node, setNode] = useState<HTMLElement | null>(null);

  const resizeObserver = useMemo(() => {
    return new ResizeObserver(() => {
      swiper.updateAutoHeight();
    });
  }, [swiper]);
  useEffect(() => {
    if (!node) {
      return;
    }

    resizeObserver.observe(node);

    return () => {
      resizeObserver.unobserve(node);
    };
  }, [node, resizeObserver]);

  return { setNode, swiper };
};

export default useSwiperSlideSizeObserver;
