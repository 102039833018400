import clsx from 'clsx';

import { useCallback } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import rentingCommonClasses from 'modules/cryptoRenting/constants/RentingCommon.module.scss';

import { Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './RentingExplainersCard.module.scss';

export const RentingExplainersCard = () => {
  const translate = useTranslation();
  const sidebar = useSideBar();
  const handleClickLearnMore = useCallback(() => {
    sidebar.open('rentingFAQ', {
      id: '0',
      sideBarProps: {
        showBackButton: false,
        contentClassName: clsx(
          rentingCommonClasses.rentingSideBar,
          rentingCommonClasses['templateBg-Baobab'],
        ),
      },
    });
  }, [sidebar]);
  return (
    <div className={classes.root}>
      <div className="column">
        <span className={classes.title}>{translate('RENTING_FAQ_TITLE')}</span>
        <span
          className={classes.subtitle}
          dangerouslySetInnerHTML={{ __html: translate('RENTING_FAQ_SUBTITLE') }}
        />
      </div>
      <button onClick={handleClickLearnMore} className={classes.learnMoreBtn}>
        {translate('LEARN_MORE')}
      </button>
      <Image className={classes.templatesImg} name="templatesGroup" path="cryptoRenting" />
    </div>
  );
};
