import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { accountsActions } from 'modules/accounts/store';
import { AccountType } from 'modules/accounts/types';
import { LastTransactions } from 'modules/accounts/views/LastTransactions';
import {
  selectIsDigitalAccountAllowed,
  selectSubscriptionMeta,
  selectUserDASubscriptionType,
} from 'modules/digitalAccount/store/selectors';
import { CurrentIbanDetails } from 'modules/digitalAccount/views/IbanDashboard/components/CurrentIbanDetails';
import SubscriptionsCarousel from 'modules/digitalAccount/views/SubscriptionsCarousel';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import classes from './IbanDashboard.module.scss';
import { Assets } from './components/Assets';

const IbanDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);

  const digitalSubscriptionsMeta = useSelector(selectSubscriptionMeta);
  const userHasSubscription = useSelector(selectUserDASubscriptionType);

  useEffect(() => {
    if (digitalSubscriptionsMeta.loaded && !userHasSubscription) {
      navigate(routesByName.dashboard);
    }
    // eslint-disable-next-line
  }, [digitalSubscriptionsMeta.loaded, userHasSubscription]);

  useEffect(() => {
    if (isDAAllowed) {
      dispatch(accountsActions.updateAccountType(AccountType.digital));
    }
    // eslint-disable-next-line
  }, [isDAAllowed]);

  if (!isDAAllowed) {
    return <Navigate to={routesByName.dashboard} replace />;
  }
  return (
    <div className={classes.root}>
      <Assets />
      <CurrentIbanDetails />
      <LastTransactions accountType={AccountType.digital} />
      <SubscriptionsCarousel />
    </div>
  );
};

export default IbanDashboard;
