import { selectPaymentOperationsLimitsReducer } from 'modules/payment/store/selectors';
import { requestOperationsLimits } from 'modules/payment/store/thunks';

import useStoreEntity from 'hooks/useStoreEntity';

const usePaymentInit = () => {
  useStoreEntity(selectPaymentOperationsLimitsReducer, requestOperationsLimits);

  return null;
};

export default usePaymentInit;
