import { phonebookActions } from 'modules/phonebook/store/index';
import { generateRequestThunk } from 'store/generators/thunks';

import eventEmitter, { eventEmitterEventNames } from 'utils/eventEmitter';

import phonebookServices from '../services';

// cli-import

export const requestCreateCounterparty = generateRequestThunk({
  request: phonebookServices.createCounterparty,
  options: {
    onSuccess: (dispatch, data) => {
      dispatch(phonebookActions.updateCounterpartyLibrary([data]));
      eventEmitter.emit(eventEmitterEventNames.phonebookCounterpartyCreated);
    },
  },
});
export const requestGetCounterpartyList = generateRequestThunk({
  request: phonebookServices.getCounterpartyList,
  options: {
    onSuccess: (dispatch, data) => {
      dispatch(phonebookActions.updateCounterpartyLibrary(data.list));
    },
  },
});

export const requestGetCounterparty = generateRequestThunk({
  request: phonebookServices.getCounterparty,
  options: {
    onSuccess: (dispatch, data) => {
      dispatch(phonebookActions.updateCounterpartyLibrary([data]));
    },
  },
});

export const requestGetPaymentDetailsList = generateRequestThunk({
  request: phonebookServices.getPaymentDetailsList,
  options: {
    onSuccess: (dispatch, data) => {
      dispatch(phonebookActions.updateCounterpartyWithPaymentDetailsLibraries(data.list));
    },
  },
});

export const requestEditCounterparty = generateRequestThunk({
  request: phonebookServices.editCounterparty,
  options: {
    onSuccess: (dispatch, data) => {
      dispatch(phonebookActions.updateCounterpartyLibrary([data]));
    },
  },
});
export const requestDeleteCounterparty = generateRequestThunk({
  request: phonebookServices.deleteCounterparty,
  options: {
    onSuccess: (dispatch, _, id) => {
      dispatch(phonebookActions.removeFromCounterpartyLibrary([id]));
    },
  },
});
export const requestDeleteCounterparties = generateRequestThunk({
  request: phonebookServices.deleteCounterparties,
  options: {
    onSuccess: (dispatch, _, ids) => {
      dispatch(phonebookActions.removeFromCounterpartyLibrary(ids));
    },
  },
});
export const requestGetCounterpartyPaymentsDetails = generateRequestThunk({
  request: phonebookServices.getCounterpartyPaymentsDetails,
  options: {
    onSuccess: (dispatch, data, counterpartyId) => {
      dispatch(
        phonebookActions.updatePaymentDetailsLibrary({ counterpartyId, paymentDetails: data }),
      );
    },
  },
});
export const requestCreateCounterpartyPaymentDetails = generateRequestThunk({
  request: phonebookServices.createCounterpartyPaymentDetails,
  options: {
    onSuccess: (dispatch, data, reqPayload) => {
      dispatch(
        phonebookActions.updatePaymentDetailsLibrary({
          counterpartyId: reqPayload.counterpartyId,
          paymentDetails: [data],
        }),
      );
      eventEmitter.emit(eventEmitterEventNames.phonebookCounterpartyPaymentDetailsUpdated);
    },
  },
});
export const requestEditCounterpartyPaymentDetails = generateRequestThunk({
  request: phonebookServices.editCounterpartyPaymentDetails,
  options: {
    onSuccess: (dispatch, data, reqPayload) => {
      dispatch(
        phonebookActions.updatePaymentDetailsLibrary({
          counterpartyId: reqPayload.counterpartyId,
          paymentDetails: [data],
        }),
      );
      eventEmitter.emit(eventEmitterEventNames.phonebookCounterpartyPaymentDetailsUpdated);
    },
  },
});
export const requestDeleteCounterpartyPaymentDetails = generateRequestThunk({
  request: phonebookServices.deleteCounterpartyPaymentDetails,
  options: {
    onSuccess: (dispatch, _, reqPayload) => {
      dispatch(phonebookActions.removeFromPaymentDetailsLibrary(reqPayload));
      eventEmitter.emit(eventEmitterEventNames.phonebookCounterpartyPaymentDetailsUpdated);
    },
  },
});
// cli-thunk
