import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react-dom-interactions';

import { FC, ReactElement, useLayoutEffect, useState } from 'react';

interface TooltipProps {
  children: ReactElement;
  anchorEl: HTMLDivElement | null;
}

export const Tooltip: FC<TooltipProps> = ({ children, anchorEl }) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom-start',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip(), shift()],
  });

  const hover = useHover(context, { move: false, delay: 200 });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([hover, focus, dismiss]);

  useLayoutEffect(() => {
    reference(anchorEl);
  }, [reference, anchorEl]);
  return (
    <FloatingPortal>
      {open && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: 999,
          }}
          {...getFloatingProps()}
        >
          {children}
        </div>
      )}
    </FloatingPortal>
  );
};
