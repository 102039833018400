import clsx from 'clsx';

import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { accountsActions } from 'modules/accounts/store';
import { selectActiveAccountType } from 'modules/accounts/store/selectors';
import { AccountType } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import {
  selectSubscriptionMeta,
  selectUserDASubscriptionType,
} from 'modules/digitalAccount/store/selectors';
import { useDispatch } from 'store';

import { ButtonGroup } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './AccountTypeChangeButton.module.scss';

interface AccountTypeChangeButtonProps {
  className?: string;
  onChange?: (type: AccountType) => void;
}

export const AccountTypeChangeButton: FC<AccountTypeChangeButtonProps> = ({
  onChange,
  className,
  ...props
}) => {
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const translate = useTranslation();

  const activeAccountType = useSelector(selectActiveAccountType);

  const digitalSubscriptionsMeta = useSelector(selectSubscriptionMeta);
  const userHasSubscription = useSelector(selectUserDASubscriptionType);

  const buttons = useMemo(
    () => [
      {
        id: '1',
        children: translate('DIGITAL_ACCOUNT'),
        disabled: !digitalSubscriptionsMeta.loaded,
        onClick: () => {
          if (userHasSubscription) {
            onChange?.(AccountType.digital);
            dispatch(accountsActions.updateAccountType(AccountType.digital));
          } else {
            drawer.open(ibanAccountDrawerTemplates.accountCreatingPrompt());
          }
        },
      },
      {
        id: '2',
        children: translate('CRYPTO_ACCOUNT'),
        onClick: () => {
          onChange?.(AccountType.crypto);
          dispatch(accountsActions.updateAccountType(AccountType.crypto));
        },
      },
    ],
    [digitalSubscriptionsMeta, userHasSubscription, drawer, dispatch, translate, onChange],
  );

  return (
    <div className={clsx(classes.root, className)}>
      <ButtonGroup
        className={classes.buttonGroup}
        activeButtonIndex={activeAccountType === AccountType.digital ? 0 : 1}
        buttons={buttons}
        {...props}
      />
    </div>
  );
};
