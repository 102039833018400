import { FC } from 'react';

import { AllowedCountry } from 'modules/app/types';

import { AutoComplete, AutoCompleteProps } from 'components/ui/AutoComplete';
import { CountryOption } from 'components/ui/CountryOption';

const countryLabelAccessor = (country: AllowedCountry | null) => country?.name || '';
const renderCountryItem = (country: AllowedCountry, _: number, active: boolean) => (
  <CountryOption country={country} active={active} />
);
const selectStaticProps = { renderItem: renderCountryItem };

export interface CountryPickerInputProps
  extends Omit<AutoCompleteProps<AllowedCountry>, 'options' | 'keyAccessor' | 'labelAccessor'> {
  allowedCountries: AllowedCountry[];
  value: AllowedCountry | null;
}

export const CountryPickerInput: FC<CountryPickerInputProps> = ({
  value,
  allowedCountries,
  selectProps,
  ...props
}) => {
  return (
    <AutoComplete
      value={value}
      options={allowedCountries}
      labelAccessor={countryLabelAccessor}
      keyAccessor={countryLabelAccessor}
      withFilterOnMobile
      selectProps={{ ...selectProps, ...selectStaticProps }}
      // @ts-ignore
      autoComplete="country-name"
      {...props}
    />
  );
};
