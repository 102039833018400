import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { transformArrayToMap } from 'utils/arrayUtils';

// cli-import
import {
  Counterparty,
  PaymentDetails,
  PhonebookState,
  SelectedRecipientForSendingFunds,
} from '../types';

const initialState: PhonebookState = {
  counterpartiesLibrary: {},
  paymentDetailsLibrary: {},
  selectedRecipientForSendingFunds: { counterpartyId: null, paymentDetailsId: null },
  // cli-state
};

const phonebookSlice = createSlice({
  name: 'phonebook',
  initialState,
  reducers: {
    updateSelectedRecipientForSendingFunds: (
      state: PhonebookState,
      { payload }: PayloadAction<Partial<SelectedRecipientForSendingFunds>>,
    ) => {
      state.selectedRecipientForSendingFunds = {
        ...state.selectedRecipientForSendingFunds,
        ...payload,
      };
    },
    updateCounterpartyLibrary: (
      state: PhonebookState,
      { payload }: PayloadAction<Counterparty[]>,
    ) => {
      state.counterpartiesLibrary = {
        ...state.counterpartiesLibrary,
        ...transformArrayToMap(payload, 'id'),
      };
    },
    removeFromCounterpartyLibrary: (
      state: PhonebookState,
      { payload }: PayloadAction<Counterparty['id'][]>,
    ) => {
      payload.forEach((id) => {
        delete state.counterpartiesLibrary[id];
      });
    },
    updatePaymentDetailsLibrary: (
      state: PhonebookState,
      {
        payload: { counterpartyId, paymentDetails },
      }: PayloadAction<{ counterpartyId: Counterparty['id']; paymentDetails: PaymentDetails[] }>,
    ) => {
      state.paymentDetailsLibrary = {
        ...state.paymentDetailsLibrary,
        [counterpartyId]: {
          ...state.paymentDetailsLibrary[counterpartyId],
          ...transformArrayToMap(paymentDetails, 'id'),
        },
      };
    },
    removeFromPaymentDetailsLibrary: (
      state: PhonebookState,
      {
        payload: { counterpartyId, paymentDetailsId },
      }: PayloadAction<{
        counterpartyId: Counterparty['id'];
        paymentDetailsId: PaymentDetails['id'];
      }>,
    ) => {
      delete state.paymentDetailsLibrary[counterpartyId][paymentDetailsId];
    },
    updateCounterpartyWithPaymentDetailsLibraries: (
      state: PhonebookState,
      {
        payload,
      }: PayloadAction<{ counterparty: Counterparty; paymentDetails: PaymentDetails[] }[]>,
    ) => {
      payload.forEach(({ counterparty, paymentDetails }) => {
        state.counterpartiesLibrary[counterparty.id] = counterparty;

        state.paymentDetailsLibrary = {
          ...state.paymentDetailsLibrary,
          [counterparty.id]: {
            ...state.paymentDetailsLibrary[counterparty.id],
            ...transformArrayToMap(paymentDetails, 'id'),
          },
        };
      });
    },
    // cli-reducer
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export default phonebookSlice.reducer;
export const phonebookActions = phonebookSlice.actions;
