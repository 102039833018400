import { Button } from 'components/ui';

const CrashApp = () => {
  return (
    <div>
      {/*@ts-ignore*/} {/*eslint-disable-next-line*/}
      <Button onClick={handlerDoesntExist}>Crash app</Button>
    </div>
  );
};

export default CrashApp;
