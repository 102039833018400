import { CryptoWallets } from './components/CryptoWallets';

// const BankCards = () => {
//   const translate = useTranslation();
//   const sidebar = useSideBar();
//   const dispatch = useDispatch();
//
//   const bankCardsReducer = useSelector(selectSavedBankCardsReducer);
//
//   useEffect(() => {
//     dispatch(requestSavedBankCards());
//     // eslint-disable-next-line
//   }, []);
//
//   const menu = useMemo<MenuListItem[]>(() => {
//     const result = bankCardsReducer.data.map(
//       (w): MenuListItem => ({
//         label: w.lastDigits,
//         description: w.issuerType === 'MC' ? 'MasterCard' : 'Visa',
//         endAdornment: <Icon name="dots" size="sm" color="black40" />,
//         startAdornment: (
//           <Image
//             className="mr-1-5"
//             path="payment"
//             name={w.issuerType === 'MC' ? 'masterCardCard' : 'visaCard'}
//             type="svg"
//           />
//         ),
//       }),
//     );
//
//     result.push({
//       icon: 'addBankCard',
//       label: translate('PAYMENT_SAVED_BANK_CARDS_ADD_CARD'),
//       endAdornment: <Icon name="plus" size="sm" color="black40" />,
//       handler: () =>
//         sidebar.open('paymentAddSavedBankCard', {
//           sideBarProps: { swipeModal: true, variant: 'creamy' },
//         }),
//     });
//     return result;
//   }, [translate, bankCardsReducer.data, sidebar]);
//
//   return (
//     <div>
//       <div className="row aic">
//         <h5>{translate('PROFILE_SAVED_BANK_CARDS_TITLE')}</h5>
//         {bankCardsReducer.meta.loading && <Loader className="ml-3" size="xs" />}
//       </div>
//       <MenuList className="mt-2" menu={menu} />
//     </div>
//   );
// };

const PaymentMethods = () => {
  return (
    <div className="column gap-4 pt-1">
      {/*TODO: [WBE] Saved bank cards*/}
      {/*<BankCards />*/}
      <CryptoWallets />
    </div>
  );
};

export default PaymentMethods;
