import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { failedTransactionStatuses } from 'modules/accounts/constants';
import { requestGetBankCardDepositTransactionById } from 'modules/accounts/store/thunks';
import useDrawer from 'modules/app/hooks/useDrawer';
import getDepositResultDrawerTemplate from 'modules/payment/views/DepositSendBankCard/utils/getDepositResultDrawerTemplate';
import { selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { getTranslation } from 'libs/i18n';

const useBankCardDepositTransactionDetails = () => {
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);

  const [params, setParams] = useSearchParams();

  const checkForTransactionId = useCallback(async () => {
    const transactionId = params.get('trId');

    if (transactionId) {
      const { data: transaction } = await dispatch(
        requestGetBankCardDepositTransactionById(transactionId),
      );

      if (transaction?.additionalInfo && 'commissionAmount' in transaction.additionalInfo) {
        const isSuccess = !failedTransactionStatuses.includes(transaction.status);

        const drawerTemplate = getDepositResultDrawerTemplate({
          amountWithCommission: transaction.amount - transaction.additionalInfo.commissionAmount,
          amount: transaction.amount,
          currencyCode: transaction.currencyCode,
          isSuccess,
          commissionAmount: transaction.additionalInfo.commissionAmount,
          userProfile: userProfile!,
          bankCardFullName: transaction.additionalInfo.bankCardOwnerFullName,
          bankCardNumber: '•••• •••• •••• ' + transaction.additionalInfo.bankCardNumberLastDigits,
          errorMessage: isSuccess ? undefined : getTranslation('PAYMENT_OPERATION_FAILED'),
        });
        drawer.open(drawerTemplate);
      }
      params.delete('trId');
      setParams(params);
    }
  }, [drawer, userProfile, dispatch, params, setParams]);

  useEffect(() => {
    checkForTransactionId();

    // eslint-disable-next-line
  }, []);
};

export default useBankCardDepositTransactionDetails;
