import { createSlice } from '@reduxjs/toolkit';

import { WithdrawalLimits } from 'modules/payment/services';
import { generateDefaultReducer, generateRequestDataReducer } from 'store/generators/reducers';
import { initialArrayApiRequestState } from 'store/generators/utils';

import {
  CurrencyNetworks,
  PaymentOperationLimit,
  PaymentState,
  SavedBankCard,
  SavedCryptoWallet,
} from '../types';

// cli-import

const initialState: PaymentState = {
  operationsLimits: initialArrayApiRequestState,
  savedCryptoWallets: initialArrayApiRequestState,
  savedBankCards: initialArrayApiRequestState,
  withdrawLimits: [],
  currenciesNetworks: initialArrayApiRequestState,
  // cli-state
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    updateOperationsLimits: generateRequestDataReducer<PaymentState, PaymentOperationLimit[]>({
      reducerPath: 'operationsLimits',
    }),
    updateSavedCryptoWallets: generateRequestDataReducer<PaymentState, SavedCryptoWallet[]>({
      reducerPath: 'savedCryptoWallets',
    }),
    updateSavedBankCards: generateRequestDataReducer<PaymentState, SavedBankCard[]>({
      reducerPath: 'savedBankCards',
    }),
    updateWithdrawLimits: generateDefaultReducer<PaymentState, WithdrawalLimits[]>({
      reducerPath: 'withdrawLimits',
      withPrev: true,
      uniqByKey: 'id',
    }),
    updateCurrenciesNetworks: generateRequestDataReducer<PaymentState, CurrencyNetworks[]>({
      reducerPath: 'currenciesNetworks',
    }),
    // cli-reducer
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export default paymentSlice.reducer;
export const paymentActions = paymentSlice.actions;
