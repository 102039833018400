import { FC } from 'react';

import { CryptoBankCardTransaction as ICryptoBankCardTransaction } from 'modules/cryptoBankCard/types';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY_HHMM_AMPM, formatHHMM } from 'utils/date';

import classes from './CryptoBankCardTransaction.module.scss';

interface CryptoBankCardTransactionProps {
  transaction: ICryptoBankCardTransaction;
  fullDate?: boolean;
}
const CryptoBankCardTransaction: FC<CryptoBankCardTransactionProps> = ({
  transaction,
  fullDate,
}) => {
  return (
    <div className={classes.root}>
      <div className="column gap-0-5">
        <span className={classes.boldLabel}>{transaction.description}</span>
        <span className={classes.label}>
          {fullDate
            ? formatDDMMYY_HHMM_AMPM(new Date(transaction.dateCreated))
            : formatHHMM(new Date(transaction.dateCreated))}
        </span>
      </div>
      <div className={classes.amounts}>
        <span className={classes.boldLabel}>
          {formatCurrencyWithSymbol(
            transaction.amountInMerchantCurrency,
            transaction.merchantCurrencyCode,
          )}
        </span>
        <span className={classes.label}>
          {formatCurrencyWithLabel(
            transaction.amountInLinkedWalletCurrency,
            transaction.currencyCode,
          )}
        </span>
      </div>
    </div>
  );
};

export default CryptoBankCardTransaction;
