import { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectDigitalAccountWallets } from 'modules/accounts/store/selectors';
import { DigitalWallet } from 'modules/accounts/types';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { requestCreateSubscription } from 'modules/digitalAccount/store/thunks';
import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionCheckCard } from 'modules/digitalAccount/views/components/SubscriptionCheckCard';
import { SubscriptionLayout } from 'modules/digitalAccount/views/components/SubscriptionLayout';
import { SubscriptionPriceCard } from 'modules/digitalAccount/views/components/SubscriptionPriceCard';
import { SubscriptionProcessTitle } from 'modules/digitalAccount/views/components/SubscriptionProcessTitle';
import useRate from 'modules/exchange/hooks/useRate';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { CurrencyAmountField, SubmitButton } from 'components/form';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import { useTranslation } from 'libs/i18n';

import { formatCurrency } from 'utils/currency';

import { CurrencyCode } from 'types';

interface GetSubscriptionConfirmFormProps extends FormRenderProps<FormValues> {
  digitalSubscription: DigitalSubscription;
  digitalWallets: DigitalWallet[];
  pricesByCurrency: { [key in CurrencyCode]?: number };
}

const GetSubscriptionConfirmForm: FC<GetSubscriptionConfirmFormProps> = ({
  digitalSubscription: subscription,
  values,
  digitalWallets,
  pricesByCurrency,
  form: { change },
  handleSubmit,
}) => {
  const translate = useTranslation();

  useEffect(() => {
    change('payment', {
      currency: values.payment.currency,
      amount: formatCurrency(pricesByCurrency[values.payment.currency], true, { withZeros: true }),
    });
    // eslint-disable-next-line
  }, [values.payment.currency, pricesByCurrency]);

  return (
    <form className="column flex-1 jcsb gap-3" onSubmit={handleSubmit}>
      <div>
        <SubscriptionProcessTitle subscriptionType={subscription.type} />
        <SubscriptionPriceCard subscription={subscription} variant="blurred" />
        <div className="whiteCard mt-3">
          <h5>{translate('CONFIRMATION')}</h5>
          <SubscriptionCheckCard
            subscription={subscription}
            className="mt-2"
            paymentCurrency={values.payment.currency}
          />
          {subscription.pricePerMonth ? (
            <Field
              name="payment"
              component={CurrencyAmountField}
              label={translate('DA_SELECT_ACCOUNT_FOR_SUB_PAYMENT') + ':'}
              className="mt-2"
              disabled
              currenciesList={digitalWallets}
              showErrorIfExist
            />
          ) : null}
        </div>
      </div>
      <div>
        <SubmitButton fullWidth variant="gold" showShadow>
          {translate('CONFIRM')}
        </SubmitButton>
        <AgreementText variant="modulR" light className="mt-1-5" />
      </div>
    </form>
  );
};

export interface GetSubscriptionConfirmProps {
  subscription: DigitalSubscription;
}
interface FormValues {
  payment: CurrencyAmount;
}

const GetSubscriptionConfirm: FC<GetSubscriptionConfirmProps> = ({ subscription }) => {
  const sidebar = useSideBar();
  const dispatch = useDispatch();
  const translate = useTranslation();

  const digitalWallets = useSelector(selectDigitalAccountWallets);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const { success, data } = await dispatch(
        requestCreateSubscription({
          id: subscription.id,
          paymentCurrency: values.payment.currency,
        }),
      );

      if (success) {
        sidebar.replaceAll(
          ...sidebarTemplates.digitalAccountGetSubscriptionSuccess({ subscription: data }),
        );
      }
    },
    [dispatch, sidebar, subscription],
  );

  const rateToGBP = useRate('EUR', 'GBP', { autoFetch: true });

  const pricesByCurrency = useMemo<{ [key in CurrencyCode]?: number }>(
    () => ({
      EUR: subscription.pricePerMonth,
      GBP: subscription.pricePerMonth * (rateToGBP || 1),
    }),
    [rateToGBP, subscription],
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      payment: {
        currency: 'EUR',
        amount: '',
      },
    }),
    [],
  );

  const validate = useCallback(
    (values: FormValues) => {
      const wallet = digitalWallets.find((w) => w.currencyCode === values.payment.currency);

      if (!wallet || wallet.amount < pricesByCurrency[values.payment.currency]!) {
        return {
          payment: {
            amount: translate('VALIDATION_INSUFFICIENT_FUNDS'),
          },
        };
      }
      return {};
    },
    [digitalWallets, pricesByCurrency, translate],
  );
  return (
    <SubscriptionLayout subscriptionType={subscription.type}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        // @ts-ignore
        render={GetSubscriptionConfirmForm}
        digitalSubscription={subscription}
        digitalWallets={digitalWallets}
        validate={validate}
        pricesByCurrency={pricesByCurrency}
      />
    </SubscriptionLayout>
  );
};

export default GetSubscriptionConfirm;
