import { GetRatesHistoryApiResponse, RatesHistory } from 'modules/exchange/types';

import { ExchangePairsResponse } from 'libs/swagger/nebeusApiTypes';

import { CurrenciesDirection } from 'types';

export const transformHistoryRatesResponse = (response: GetRatesHistoryApiResponse): RatesHistory =>
  Object.entries(response).reduce(
    (acc, [direction, body]) => ({
      ...acc,
      [direction]: { rates: body.rates, lastLoadedTime: Date.now() },
    }),
    {},
  );
export const transformExchangeAllowedDirections = (
  response: ExchangePairsResponse,
): CurrenciesDirection[] =>
  response.pairs
    .map((p) => `${p.fromCurrency}/${p.toCurrency}` as CurrenciesDirection)
    .concat(response.pairs.map((p) => `${p.toCurrency}/${p.fromCurrency}` as CurrenciesDirection));
