import { maxBy, minBy } from 'lodash';

import {
  LoanCollateralHistoryItem,
  LoanItem,
  LoanNotification,
  LoanNotificationType,
  LoanPaymentHistoryItem,
  LoanTemplate,
  OutstandingBalanceDetails,
  Statistic,
} from 'modules/loans/types';
import { transformList } from 'store/generators/transformers';

import {
  LoanCollateralPaymentDto,
  LoanCollateralPaymentResponseList,
  LoanDto,
  LoanNotificationResponseList,
  LoanOutstandingBalanceResponse,
  LoanPaymentDto,
  LoanPaymentResponseList,
  LoanStatisticResponse,
  LoanTemplateDto,
  LoanTemplateResponseList,
} from 'libs/swagger/nebeusApiTypes';

import { CurrencyCode, FiatCurrencyCode } from 'types';

const MCStatusByApiStatus: { [key: string]: LoanItem['marginCallStatus'] } = {
  ACTIVE: 'active',
  APPROACH: 'approach',
  NONE: 'none',
};

export const transformLoans = (loans: Required<LoanDto>[]): LoanItem[] =>
  transformList(loans, [
    ['id'],
    ['templateId'],
    ['loanAmount'],
    ['loanCurrencyCode', 'loanCurrency'],
    ['collateralAmount'],
    ['collateralCurrencyCode', 'collateralCurrency'],
    ['termMonth'],
    ['ltv'],
    ['interestRate'],
    ['nextPaymentAmount'],
    ['nextPaymentDate'],
    ['marginCallExchangeRate'],
    [
      'marginCallStatus',
      'marginCallStatus',
      // @ts-ignore
      { custom: (value: string) => MCStatusByApiStatus[value] },
    ],
    ['collateralMinDepositAmount'],
    ['marginCallStartDate'],
    ['active', 'isActive'],
    ['autoManagementMarginCall', 'autoManagementMC'],
    ['excessCollateralRelease'],
    ['onlyInterest'],
    ['zeroPayments'],
    ['fullRepaymentAllowed'],
    ['partialRepaymentAllowed'],
    ['restBalanceAmount'],
    ['dateStart'],
    ['dateEnd'],
    ['exchangeRate'],
    ['lastCollateralPayment', 'lastTransaction'],
    [
      'liquidationRiskPercent',
      'liquidationRiskPercent',
      // @ts-ignore
      { custom: (value: number) => +value.toFixed(2) },
    ],
    [
      'templateType',
      'templateType',
      // @ts-ignore
      { custom: (templateType) => loanTemplatesTypeMap[templateType] },
    ],

    ['autoPaymentFailedDepositAmount'],
    ['leftDaysBeforeLiquidation'],
  ]);

const loanTemplatesTypeMap: { [key in LoanTemplateDto['type']]: LoanTemplate['type'] } = {
  FLEXIBLE: 'FLEXIBLE',
  ONLY_INTEREST: 'ONLY_INTEREST',
  ZERO_PAYMENTS: 'BULLET',
  ONE_PLUS_ONE: 'MIRROR',
  QUICK: 'QUICK',
};
export const transformTemplates = (response: LoanTemplateResponseList): LoanTemplate[] =>
  transformList(response.loanTemplates, [
    ['id'],
    ['quick', 'isQuick'],
    // @ts-ignore
    ['type', 'type', { custom: (templateType) => loanTemplatesTypeMap[templateType] }],
    ['autoMarginCallEnabled', 'autoMCEnabled'],
    ['excessCollateralReleaseEnabled'],
    [
      'maxLengthDays',
      'maxTermMonth',
      // @ts-ignore
      { custom: (value: number) => Math.max(1, Math.round(value / 30)) },
    ],
    [
      'minLengthDays',
      'minTermMonth',
      // @ts-ignore
      { custom: (value: number) => Math.max(1, Math.round(value / 30)) },
    ],
    ['originationFeePercent'],
    ['earlyRepaymentPenaltyPercent'],
    [
      'loanCurrencyRules',
      'loanRules',
      {
        // @ts-ignore
        custom: (
          rules: { minLoanAmount: number; maxLoanAmount: number; currencyCode: CurrencyCode }[],
        ) =>
          rules?.reduce(
            (acc, value) => ({
              ...acc,
              [value.currencyCode]: {
                minAmount: value.minLoanAmount,
                maxAmount: value.maxLoanAmount,
              },
            }),
            {},
          ) || {},
      },
    ],
    [
      'loanCollateralRules',
      'collateralRules',
      {
        // @ts-ignore
        custom: (
          rules: {
            currencyCode: CurrencyCode;
            minCollateralAmount: number;
            maxCollateralAmount: number;
          }[],
        ) =>
          rules?.reduce(
            (acc, value) => ({
              ...acc,
              [value.currencyCode]: {
                minAmount: value.minCollateralAmount,
                maxAmount: value.maxCollateralAmount,
              },
            }),
            {},
          ) || {},
      },
    ],
    [
      'loanToValues',
      'ltvRange',
      {
        // @ts-ignore
        custom: (
          value: {
            ltvValue: number;
            percentAnnual: number;
            percentAnnualReplenishment: number;
            percentAnnualOverrateable: number;
            percentAnnualOverrateableReplenishment: number;
          }[],
        ) =>
          value?.reduce(
            (acc, currValue) => ({
              ...acc,
              [currValue.ltvValue]: {
                percent: currValue.percentAnnual,
                percentWithAutoMC: currValue.percentAnnualReplenishment,
                percentWithExcessCollateral: currValue.percentAnnualOverrateable,
                percentWithAutoMCAndExcessCollateral:
                  currValue.percentAnnualOverrateableReplenishment,
              },
            }),
            {},
          ) || {},
      },
    ],
    [
      'loanToValues',
      'minLtv',
      {
        // @ts-ignore
        custom: (
          value: {
            ltvValue: number;
            percentAnnual: number;
            percentAnnualReplenishment: number;
            percentAnnualOverrateable: number;
            percentAnnualOverrateableReplenishment: number;
          }[],
        ) => minBy(value, 'ltvValue')?.ltvValue,
      },
    ],
    [
      'loanToValues',
      'maxLtv',
      {
        // @ts-ignore
        custom: (
          value: {
            ltvValue: number;
            percentAnnual: number;
            percentAnnualReplenishment: number;
            percentAnnualOverrateable: number;
            percentAnnualOverrateableReplenishment: number;
          }[],
        ) => maxBy(value, 'ltvValue')?.ltvValue,
      },
    ],
  ]);

// Sorted By priority
export const allowedNotificationTypes = [
  LoanNotificationType.marginCall,
  LoanNotificationType.preMarginCall,
  LoanNotificationType.autoPaymentFailed,

  LoanNotificationType.marginCallAvoidedByAutoReplenish,
  LoanNotificationType.marginCallAvoidedByRateRaised,
  LoanNotificationType.autoPaymentSucceeded,
];

export const transformNotifications = (
  response: LoanNotificationResponseList,
): LoanNotification[] =>
  transformList(response.loanNotifications, [
    ['id'],
    // @ts-ignore
    ['loanInfo', 'loan', { custom: (loan: LoanDto) => transformLoans([loan])[0] }],
    ['type'],
  ]).filter(
    // @ts-ignore
    (n: LoanNotification) => allowedNotificationTypes.includes(n.type),
  );

export const transformStatistic = (response: LoanStatisticResponse): Statistic => ({
  totalAmount: response.statisticInfo.totalLoanAmount,
  defaultCurrencyCode: response.statisticInfo.defaultCurrencyCode as FiatCurrencyCode,
  nextPaymentDate: response.statisticInfo.nextPaymentDate,
  nextPaymentAmount: response.statisticInfo.nextPaymentAmount,
  nextPaymentCurrency: response.statisticInfo.nextPaymentCurrencyCode as CurrencyCode | null,
  loansNumber: response.statisticInfo.numberOfLoans,
});

const paymentStatusByApiStatus: {
  [key in LoanPaymentDto['paymentType']]: LoanPaymentHistoryItem['paymentType'];
} = {
  AUTO_PAYMENT: 'monthlyRepayment',
  LOAN_ISSUED: 'loanIssued',
  MANUAL_PAYMENT: 'manualPayment',
};

const collateralStatusByApiStatus: {
  [key in LoanCollateralPaymentDto['transactionType']]: LoanCollateralHistoryItem['transactionType'];
} = {
  AUTO_MARGIN_CALL_COLLATERAL: 'autoMC',
  EXCESS_COLLATERAL: 'excessRelease',
  MANUAL_COLLATERAL: 'addedManually',
  PAYOUT_COLLATERAL: 'payoutBack',
  COLLATERAL_PURCHASE_FROM_TRADER: 'purchaseCollateralForMirrorTemplate',
};

export const transformPaymentHistory = (
  response: LoanPaymentResponseList,
): LoanPaymentHistoryItem[] =>
  transformList(response.loanPayments, [
    ['paymentAmount'],
    ['paymentDate'],
    ['paymentCurrencyCode'],
    ['outstandingBalance'],
    ['paymentsLeft'],
    // @ts-ignore
    ['paymentType', 'paymentType', { custom: (value) => paymentStatusByApiStatus[value!] }],
    ['paymentStatus', 'isPaid', { custom: (value) => value === 'PAID' }],
  ]);
export const transformCollateralHistory = (
  response: LoanCollateralPaymentResponseList,
): LoanCollateralHistoryItem[] =>
  transformList(response.loanCollateralPayments, [
    ['paymentDate', 'date'],
    ['collateralAmount'],
    ['currencyCode'],
    ['transactionAmount'],
    ['walletAmount'],
    [
      'transactionType',
      'transactionType',
      {
        custom: (value) =>
          collateralStatusByApiStatus[value as LoanCollateralPaymentDto['transactionType']],
      },
    ],
  ]);
export const transformOutstandingBalance = (
  response: LoanOutstandingBalanceResponse,
): OutstandingBalanceDetails => ({
  loanBalanceAmount: response.outstandingBalanceInfo.loanBalanceAmount,
  interestRateBalanceAmount: response.outstandingBalanceInfo.interestBalanceAmount,
  earlyRepaymentFeeAmount: response.outstandingBalanceInfo.earlyRepaymentFeeAmount,
  fineForLateRepaymentAmount: response.outstandingBalanceInfo.penaltyBalanceAmount,
  totalOutstandingBalance: response.outstandingBalanceInfo.totalOutstandingBalance,
  currencyCode: response.outstandingBalanceInfo.currencyCode as CurrencyCode,
});
