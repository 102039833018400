import { FC } from 'react';

import {
  DepositByDetailsOperationId,
  PaymentDetailsCard,
} from 'modules/payment/views/components/PaymentDetailsCard';

import { FiatCurrencyCode } from 'types';

export interface AccountDetailsProps {
  operationId: DepositByDetailsOperationId;
  currencyCode: FiatCurrencyCode;
}

const AccountDetails: FC<AccountDetailsProps> = ({ operationId, currencyCode }) => {
  return (
    <PaymentDetailsCard className="mt-1" operationId={operationId} currencyCode={currencyCode} />
  );
};

export default AccountDetails;
