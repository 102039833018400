import { useEffect } from 'react';

const useKeyboardButton = ({ handler }: { handler: (event: KeyboardEvent) => void }) => {
  useEffect(() => {
    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [handler]);
};

export default useKeyboardButton;
