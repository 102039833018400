import { useSwiper } from 'swiper/react';

import { FC, useCallback } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import { requestResetTFA } from 'modules/auth/store/thunks';
import { StepHeader } from 'modules/auth/views/components/StepHeader';
import { useDispatch } from 'store';

import { SubmitButton, TextInputField } from 'components/form';

import { useTranslation } from 'libs/i18n';
import useSwiperInputFocus from 'libs/swiper/useSwiperInputFocus';
import { successToast } from 'libs/toast';

import { voidFunc } from 'types';

import { validate } from './validate';

interface FormValues {
  code: string;
}

const initialValues: FormValues = {
  code: '',
};

interface ResetTFAFormProps extends FormRenderProps<FormValues> {}

const ResetTFAForm: FC<ResetTFAFormProps> = ({ handleSubmit }) => {
  const translate = useTranslation();

  const { inputRef } = useSwiperInputFocus();

  return (
    <form onSubmit={handleSubmit} className="authStepRoot">
      <div className="column gap-3">
        <StepHeader
          title={translate('TFA_RESET_TITLE')}
          subtitle={translate('TFA_RESET_SUBTITLE')}
        />

        <Field
          name="code"
          inputRef={inputRef}
          component={TextInputField}
          placeholder={translate('TFA_EMERGENCY_RESET_CODE_PLACEHOLDER')}
        />
      </div>
      <SubmitButton fullWidth>{translate('NEXT')}</SubmitButton>
    </form>
  );
};

interface ResetTFAProps {
  email: string;
  onSuccess: voidFunc;
}
const ResetTFA: FC<ResetTFAProps> = ({ email, onSuccess }) => {
  const dispatch = useDispatch();

  const translate = useTranslation();

  const swiper = useSwiper();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const { success } = await dispatch(requestResetTFA({ resetKey: values.code, email }));
      if (success) {
        successToast(translate('TFA_EMERGENCY_RESET_SUCCESS'));
        swiper.slideTo(0);
        onSuccess();
      }
    },
    [swiper, onSuccess, translate, dispatch, email],
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={ResetTFAForm}
      validate={validate}
    />
  );
};

export default ResetTFA;
