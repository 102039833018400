import clsx from 'clsx';

import { FC } from 'react';

import { LoanCollateralHistoryItem } from 'modules/loans/types';

import { Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './LoanMovement.module.scss';

interface LoanMovementProps {
  currencyCode: CurrencyCode;
  transactionAmount: number;
  walletAmount: number;
  collateralAmount: number;
  transactionType: LoanCollateralHistoryItem['transactionType'];
}

const toWalletTypes: LoanCollateralHistoryItem['transactionType'][] = [
  'payoutBack',
  'excessRelease',
];
export const LoanMovement: FC<LoanMovementProps> = ({
  currencyCode,
  collateralAmount,
  walletAmount,
  transactionAmount,
  transactionType,
}) => {
  const translate = useTranslation();
  const addedToWallet = toWalletTypes.includes(transactionType);

  return (
    <div className={classes.root}>
      <div className="column gap-0-5">
        <span className={classes.label}>
          {currencyCode} {translate('ACCOUNT')}
        </span>
        <span className={clsx(classes.listItemValue, addedToWallet && classes.blue)}>
          {formatCurrencyWithSymbol(walletAmount, currencyCode)}
        </span>
      </div>
      <div className="column aic gap-0-5">
        <span className={classes.collateralTransactionLabel}>
          +{formatCurrencyWithSymbol(Math.abs(transactionAmount), currencyCode)}
        </span>
        <Icon
          className={classes.collateralArrow}
          size={24}
          name={addedToWallet ? 'arrowLeftSafe' : 'arrowRightSafe'}
        />
      </div>
      <div className="column tar gap-0-5">
        <span className={classes.label}>{translate('LOANS_COLLATERAL')}</span>
        <span className={clsx(classes.listItemValue, !addedToWallet && classes.blue)}>
          {formatCurrencyWithSymbol(collateralAmount, currencyCode)}
        </span>
      </div>
    </div>
  );
};
