import { requestWallets } from 'modules/accounts/store/thunks';
import { LOANS_REQUEST_LIMIT_PAGE } from 'modules/loans/constants/config';
import { loansActions } from 'modules/loans/store';
import { LoanItem, LoanTemplate } from 'modules/loans/types';
import { generateInfinityListThunk, generateRequestThunk } from 'store/generators/thunks';
import { generateRequestDataThunk } from 'store/generators/thunks';

import loansServices from '../services';
import { LoanNotification, Statistic } from '../types';

// cli-import

export const requestCreateLoan = generateRequestThunk({
  request: loansServices.createLoan,
  options: {
    onSuccess: (dispatch) => {
      dispatch(
        requestLoansActiveItems(undefined, { reset: true, pageLimit: LOANS_REQUEST_LIMIT_PAGE }),
      );
    },
  },
});

export const requestLoansActiveItems = generateInfinityListThunk<LoanItem, undefined>({
  request: loansServices.getActiveLoans,
  updateState: loansActions.updateLoansActiveItems,
  reducerPath: 'loans.loansItemsActive',
  options: { showErrorToast: false },
});

export const requestLoansHistoryItems = generateInfinityListThunk<LoanItem, undefined>({
  request: loansServices.getHistoryLoans,
  updateState: loansActions.updateLoansHistoryItems,
  reducerPath: 'loans.loansItemsHistory',
  options: { showErrorToast: false },
});
export const requestStatistic = generateRequestDataThunk<Statistic>({
  updateState: loansActions.updateStatistic,
  request: loansServices.getStatistic,
  options: { showErrorToast: false },
});
export const requestPaymentsHistory = generateRequestThunk({
  request: loansServices.getPaymentsHistory,
  options: { showErrorToast: false },
});
export const requestCollateralHistory = generateRequestThunk({
  request: loansServices.getCollateralHistory,
  options: { showErrorToast: false },
});

export const requestOutstandingBalanceDetails = generateRequestThunk({
  request: loansServices.getOutstandingBalanceDetails,
});
export const requestRepayLoan = generateRequestThunk({
  request: loansServices.repayLoan,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestWallets());
      dispatch(
        requestLoansActiveItems(undefined, { reset: true, pageLimit: LOANS_REQUEST_LIMIT_PAGE }),
      );
    },
  },
});
export const requestAddCollateral = generateRequestThunk({
  request: loansServices.addCollateral,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestWallets());
      dispatch(
        requestLoansActiveItems(undefined, { reset: true, pageLimit: LOANS_REQUEST_LIMIT_PAGE }),
      );
    },
  },
});

export const requestLoansNotifications = generateRequestDataThunk<LoanNotification[]>({
  updateState: loansActions.updateNotifications,
  request: loansServices.getNotifications,
  options: { showErrorToast: false },
});
export const requestLoanTemplates = generateRequestDataThunk<LoanTemplate[]>({
  updateState: loansActions.updateTemplate,
  request: loansServices.getTemplates,
  options: { showErrorToast: false },
});
export const requestReadNotification = generateRequestThunk({
  request: loansServices.readNotification,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestLoansNotifications());
    },
  },
});
// cli-thunk
