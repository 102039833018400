import { isToday, isYesterday } from 'date-fns';
import { groupBy } from 'lodash';

import { useCallback, useEffect, useMemo } from 'react';

import accountsDrawerTemplates from 'modules/accounts/constants/drawerTemplates';
import { requestGetAccountTransactions } from 'modules/accounts/store/thunks';
import { Transaction } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import { requestDigitalAccountTransactionsHistory } from 'modules/digitalAccount/store/thunks';
import { useDispatch } from 'store';

import useInfinityScrollForAccountTransactions from 'hooks/useInfinityScrollForAccountTransactions';

import { useTranslation } from 'libs/i18n';

import { formatDDMMYY } from 'utils/date';
import eventEmitter from 'utils/eventEmitter';

import { CurrencyCode } from 'types';

const useAccountsTransactionsHistory = ({
  currencyCode,
  isCryptoAccount,
  filterValue,
}: {
  isCryptoAccount: boolean;
  currencyCode?: CurrencyCode;
  filterValue: string;
}) => {
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const translate = useTranslation();

  const getDateLabel = useCallback(
    (item: Transaction) => {
      const date = new Date(item.dateCreated);
      if (isToday(date)) {
        return translate('DATE_TODAY');
      }
      if (isYesterday(date)) {
        return translate('DATE_YESTERDAY');
      }
      return formatDDMMYY(date);
    },
    [translate],
  );

  const loadMore = useCallback(
    async (pageNumber: number, pageSize: number) => {
      if (!isCryptoAccount) {
        const { data } = await dispatch(
          requestDigitalAccountTransactionsHistory({
            pageNumber,
            pageLimit: pageSize,
            currencyCode,
          }),
        );
        return data;
      } else {
        const { data } = await dispatch(
          requestGetAccountTransactions({
            pageNumber,
            pageSize,
            currencyCode,
          }),
        );
        return data;
      }
    },
    [isCryptoAccount, currencyCode, dispatch],
  );

  const { list, isLoading, onInfinityScroll, refreshList } =
    useInfinityScrollForAccountTransactions<Transaction>({
      loadMore,
      threshold: 500,
    });

  const filteredList = useMemo(() => {
    if (filterValue) {
      return list.filter((t) => t.description.toLowerCase().includes(filterValue.toLowerCase()));
    }
    return list;
  }, [filterValue, list]);

  const groupedByDate = useMemo(
    () => Object.entries(groupBy(filteredList, getDateLabel)),
    [getDateLabel, filteredList],
  );

  const onTransactionClick = useCallback(
    (uniqId: string) => {
      const transaction = list.find((i) => i.uniqId === uniqId);

      if (transaction) {
        drawer.open(
          accountsDrawerTemplates.transactionDetails({
            transaction,
          }),
        );
      }
    },
    [drawer, list],
  );

  useEffect(() => {
    const unsubscribe = eventEmitter.subscribe('refreshTransactions', refreshList);

    return () => {
      unsubscribe();
    };
  }, [refreshList]);

  return { groupedByDate, isLoading, onInfinityScroll, onTransactionClick };
};

export default useAccountsTransactionsHistory;
