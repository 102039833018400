import clsx from 'clsx';

import { FC, ReactNode, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';

import { Icon } from 'components/ui/Icon';

import classes from './DrawerHeader.module.scss';

interface DrawerHeaderProps {
  title: string;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  rightComponent?: ReactNode;
  className?: string;
}
export const DrawerHeader: FC<DrawerHeaderProps> = ({
  title,
  showCloseButton = true,
  showBackButton = false,
  rightComponent,
  className,
}) => {
  const drawer = useDrawer();

  const popDrawer = useCallback(() => {
    drawer.pop();
  }, [drawer]);

  return (
    <div className={clsx(classes.root, className)}>
      <div
        onClick={showBackButton ? popDrawer : undefined}
        className={clsx('row aifs gap-1', showBackButton && 'pointer')}
      >
        {showBackButton && (
          <Icon className={classes.backIcon} name="chevronLeftSquared" size={16} />
        )}
        <h3 className={classes.title}>{title}</h3>
      </div>
      <div className="row gap-2 aic flex-shrink-0">
        {rightComponent}
        {showCloseButton && (
          <Icon
            onClick={drawer.close}
            className={classes.closeButton}
            name="cross"
            color="black40"
            size={16}
          />
        )}
      </div>
    </div>
  );
};
