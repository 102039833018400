import clsx from 'clsx';

import { FC, Fragment, MouseEventHandler, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIssuersMeta } from 'modules/accounts/store/selectors';
import { Wallet } from 'modules/accounts/types';
import { IAPIMeta } from 'store/generators/types';

import { CurrencyIcon, Icon, LineDelimiter, Loader, SearchInput } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatCurrency, formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './AccountList.module.scss';

interface AccountListProps {
  wallets: Wallet[];
  walletsMeta: IAPIMeta;
  loadingWalletCurrencyCode: CurrencyCode | null;
  onWalletClick: MouseEventHandler<HTMLDivElement>;
  className?: string;
  isCryptoAccount: boolean;
}

export const AccountList: FC<AccountListProps> = ({
  wallets,
  walletsMeta,
  onWalletClick,
  loadingWalletCurrencyCode,
  className,
}) => {
  const translate = useTranslation();

  const zeroHidden = useFlag(false);

  const issuersMeta = useSelector(selectIssuersMeta);

  const [filterValue, setFilterValue] = useState('');

  const filteredWallets = useMemo(() => {
    let filtered = wallets;

    if (zeroHidden.state) {
      filtered = filtered.filter((w) => +formatCurrency(w.amount, w.currencyCode) > 0);
    }

    if (filterValue) {
      const filterValueUppercase = filterValue.toUpperCase();

      filtered = filtered.filter(
        (w) =>
          w.currencyCode.includes(filterValueUppercase) ||
          w.currencyLabel.toUpperCase().includes(filterValueUppercase),
      );
    }

    return filtered;
  }, [wallets, zeroHidden.state, filterValue]);

  return (
    <div className={className}>
      {(issuersMeta.loading && !issuersMeta.loaded) ||
      (walletsMeta.loading && !walletsMeta.loaded) ? (
        <Loader centered size="lg" />
      ) : (
        <>
          <SearchInput
            value={filterValue}
            onChange={setFilterValue}
            zeroHidden={zeroHidden.state}
            toggleHideZero={zeroHidden.toggle}
            className="mb-1"
          />
          {filteredWallets.length === 0 && filterValue ? (
            <span className="filledError mt-3">
              {translate('NO_RESULTS_FOR')} &quot;{filterValue}&quot;
            </span>
          ) : (
            filteredWallets.map((wallet, index) => (
              <Fragment key={wallet.currencyCode}>
                {index !== 0 && <LineDelimiter />}
                <div
                  onClick={
                    loadingWalletCurrencyCode === wallet.currencyCode ? undefined : onWalletClick
                  }
                  data-currency={wallet.currencyCode}
                  className={classes.wallet}
                >
                  <div className="row aic">
                    <CurrencyIcon className="mr-1" size={32} code={wallet.currencyCode} />
                    <span className={classes.mainLabel}>{wallet.currencyLabel}</span>
                  </div>
                  <div className="row aic">
                    <div className="column">
                      <span className={clsx(classes.mainLabel, classes.amountLabel, 'tar')}>
                        {formatCurrency(wallet.amount, wallet.currencyCode)}
                      </span>
                      {(!wallet.amount && !wallet.amountInDefaultCurrency) ||
                      wallet.amountInDefaultCurrency ? (
                        <span className={clsx(classes.subLabel, 'tar')}>
                          {formatCurrencyWithSymbol(
                            wallet.amountInDefaultCurrency,
                            wallet.defaultCurrencyCode,
                          )}
                        </span>
                      ) : null}
                    </div>
                    <div className={classes.button}>
                      {loadingWalletCurrencyCode === wallet.currencyCode ? (
                        <Loader />
                      ) : (
                        <Icon name="arrowRight" className={classes.arrowRight} size="sm" />
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            ))
          )}
        </>
      )}
    </div>
  );
};
