import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { templateTitleFullByType } from 'modules/loans/constants/config';
import { AddCollateralProps } from 'modules/loans/views/AddCollateral';
import { AddCollateralConfirmProps } from 'modules/loans/views/AddCollateralConfirm';
import { AddCollateralSuccessProps } from 'modules/loans/views/AddCollateralSuccess';
import { LiquidationRiskReducedProps } from 'modules/loans/views/LiquidationRiskReduced';
import { LoanAutoPaymentFailedProps } from 'modules/loans/views/LoanAutoPaymentFailed';
import { LoanGetSuccessProps } from 'modules/loans/views/LoanGetSuccess';
import { LoanMarginCallProps } from 'modules/loans/views/LoanMarginCall';
import { LoanRepaidProps } from 'modules/loans/views/LoanRepaid';
import { LoanRepaymentProps } from 'modules/loans/views/LoanRepayment';
import { TemplateDescriptionProps } from 'modules/loans/views/TemplateDescription';

import { getTranslation } from 'libs/i18n';

const templateDescription = (props: TemplateDescriptionProps): DrawerTemplateReturnType => ({
  key: 'loanTemplateDescription',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation(templateTitleFullByType[props.template.type]),
  },
});

const loanGetSuccess = (props: LoanGetSuccessProps): DrawerTemplateReturnType => ({
  key: 'loanGetSuccess',
  props,
  sideBarProps: {
    showBackButton: false,
    navigationHeaderLabel: getTranslation('LOAN_GET_SUCCESS_TITLE'),
    navigationHeaderProps: {
      hideBackButton: true,
    },
  },
});

const loanRepayment = (props: LoanRepaymentProps): DrawerTemplateReturnType => ({
  key: 'loanRepayment',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('LOANS_REPAYMENT'),
    showBackButton: false,
    swipeModal: true,
    swipeModalMaxHeight: true,
  },
});

const loanRepaid = (props: LoanRepaidProps): DrawerTemplateReturnType => ({
  key: 'loanRepaid',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('LOANS_REPAID_FULL'),
    showBackButton: false,
    swipeModal: true,
  },
});

const loanAddCollateral = (props: AddCollateralProps): DrawerTemplateReturnType => ({
  key: 'loanAddCollateral',
  props,
  sideBarProps: {
    swipeModal: true,
    showBackButton: false,
    navigationHeaderProps: {
      className: 'jcsb',
      infoIcon:
        props.loan.marginCallStatus === 'none'
          ? {
              title: getTranslation('LOANS_ADD_COLLATERAL_MANUAL_FAQ_TITLE'),
              description: getTranslation('LOANS_ADD_COLLATERAL_MANUAL_FAQ_DESC'),
            }
          : props.loan.marginCallStatus === 'approach'
          ? {
              title: getTranslation('LOANS_ADD_LOAN_COLLATERAL_REDUCE_RISK'),
              description: getTranslation('LOANS_COLLATERAL_APPROACHING_TO_MC_DESC'),
            }
          : undefined,
    },
    navigationHeaderLabel: getTranslation(
      props.loan.marginCallStatus === 'none'
        ? 'LOANS_ADD_LOAN_COLLATERAL'
        : 'LOANS_ADD_LOAN_COLLATERAL_REDUCE_RISK',
    ),
  },
});

const loanAddCollateralConfirm = (props: AddCollateralConfirmProps): DrawerTemplateReturnType => ({
  key: 'loanAddCollateralConfirm',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('CONFIRMATION'),
    showBackButton: false,
    swipeModal: true,
  },
});
const loanLiquidationRiskReduced = (
  props: LiquidationRiskReducedProps,
): DrawerTemplateReturnType => ({
  key: 'loanLiquidationRiskReduced',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation(
      props.loan.liquidationRiskPercent <= 25 ? 'LOANS_LOAN_IS_HEALTHY' : 'LOANS_COLLATERAL_ADDED',
    ),
    showBackButton: false,
    swipeModal: true,
  },
});

const loanAddCollateralSuccess = (props: AddCollateralSuccessProps): DrawerTemplateReturnType => ({
  key: 'loanAddCollateralSuccess',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('LOANS_COLLATERAL_ADDED'),
    navigationHeaderProps: { hideBackButton: true },
    showBackButton: false,
    swipeModal: true,
    swipeModalMaxHeight: true,
  },
});
const loanMarginCall = (props: LoanMarginCallProps): DrawerTemplateReturnType => ({
  key: 'loanMarginCall',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('LOANS_LOAN_UNDER_MC'),
    showBackButton: false,
    swipeModal: true,
    swipeModalMaxHeight: true,
  },
});
const loanAutoPaymentFailed = (props: LoanAutoPaymentFailedProps): DrawerTemplateReturnType => ({
  key: 'loanAutoPaymentFailed',
  props,
  sideBarProps: {
    showBackButton: false,
    navigationHeaderLabel: getTranslation('LOANS_LOAN_AUTO_PAYMENT_FAILED'),
    swipeModal: true,
    swipeModalMaxHeight: true,
  },
});

const loanDrawerTemplates = {
  templateDescription,
  loanGetSuccess,
  loanRepayment,
  loanRepaid,
  loanAddCollateral,
  loanAutoPaymentFailed,
  loanMarginCall,
  loanAddCollateralSuccess,
  loanLiquidationRiskReduced,
  loanAddCollateralConfirm,
};

export default loanDrawerTemplates;
