import clsx from 'clsx';

import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';

import { Size } from 'types';

import { ThemeColor, getThemeColor } from 'styles';

import classes from './Icon.module.scss';

const sizes: { [key in Size]: number } = {
  xl: 36,
  lg: 32,
  md: 24,
  sm: 16,
  xs: 12,
};

export interface IconProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  name: string;
  className?: string;
  size?: Size | number;
  color?: ThemeColor;
  forwardRef?: (ref: HTMLDivElement | null) => void;
}

export const Icon: FC<IconProps> = ({
  name,
  size = 'md',
  className,
  color,
  forwardRef,
  ...props
}) => {
  const sizePixels = typeof size === 'number' ? size : sizes[size];

  return (
    <div className={clsx(classes.root, className)} ref={forwardRef} {...props}>
      <svg width={sizePixels} height={sizePixels} color={color ? getThemeColor(color) : undefined}>
        <use href={`#${name}`} />
      </svg>
    </div>
  );
};
