import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  selectDigitalAccountExistWallets,
  selectExistingWallets,
} from 'modules/accounts/store/selectors';
import { Wallet } from 'modules/accounts/types';

// import { selectSidebarOpenedKey } from 'modules/app/store/selectors';
import { AnalyticEvents } from 'libs/analytic/events';
import analytic from 'libs/analytic/index';

const remapWalletsForAnalytic = (wallets: Wallet[]) =>
  wallets.map((w) => ({
    currencyCode: w.currencyCode,
    amount: w.amount,
    amountInDefaultCurrency: w.amountInDefaultCurrency,
    defaultCurrencyCode: w.defaultCurrencyCode,
  }));
const useAnalytic = () => {
  const { pathname } = useLocation();
  // const sidebarKey = useSelector(selectSidebarOpenedKey);

  const wallets = useSelector(selectExistingWallets);
  const digitalWallets = useSelector(selectDigitalAccountExistWallets);

  const walletsForAnalytic = useMemo(
    () => ({
      iban: remapWalletsForAnalytic(digitalWallets),
      trading: remapWalletsForAnalytic(wallets),
    }),
    [wallets, digitalWallets],
  );
  useEffect(() => {
    analytic.pageChange(pathname);
  }, [pathname]);

  // useEffect(() => {
  //   if (sidebarKey) {
  //     analytic.sendEvent(AnalyticEvents.sidebarOpen, { name: sidebarKey });
  //   }
  // }, [sidebarKey]);

  const walletsWithAmountsOnly = useMemo(
    () => ({
      iban: walletsForAnalytic.iban.map((i) => i.amount),
      trading: walletsForAnalytic.trading.map((i) => i.amount),
    }),
    [walletsForAnalytic],
  );

  useEffect(() => {
    if (walletsForAnalytic.iban.length || walletsForAnalytic.trading.length) {
      analytic.sendEvent(AnalyticEvents.accountsBalances, {
        wallets: walletsForAnalytic,
      });
    }

    // eslint-disable-next-line
  }, [JSON.stringify(walletsWithAmountsOnly)]);
};

export default useAnalytic;
