import { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectStakingTemplates } from 'modules/staking/store/selectors';

import { CurrencyIcon } from 'components/ui';

import { getTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './TemplateHeader.module.scss';

interface TemplateHeaderProps {
  currencyCode: CurrencyCode;
}

export const TemplateHeader = memo<TemplateHeaderProps>(({ currencyCode }) => {
  const templates = useSelector(selectStakingTemplates);
  const template = templates.find((t) => t.currencyCode === currencyCode);
  return template ? (
    <div className="column aic">
      <CurrencyIcon code={currencyCode} size={52} />
      <div className={classes.currencyTitle}>
        <span>{getCurrencyLabelByCode(currencyCode)}</span>
        <span>{currencyCode}</span>
      </div>
      <span className={classes.percentLabel}>
        {template.percentRPY}% {getTranslation('STAKING_RPY')}
      </span>
    </div>
  ) : null;
});
