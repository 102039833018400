import { FC, ReactElement } from 'react';

import { Image, ImageProps } from 'components/ui/Image';

import { CurrencyCode } from 'types';

import classes from './CurrencyIcon.module.scss';

export interface CurrencyIconProps extends Omit<ImageProps, 'name'> {
  code: CurrencyCode;
  size?: number;
  active?: boolean;
  adornment?: ReactElement;
}

export const CurrencyIcon: FC<CurrencyIconProps> = ({
  code,
  size = 18,
  active,
  adornment,
  ...props
}) => {
  return (
    <div className={classes.root} {...props}>
      <Image
        key={active ? '1' : '2'}
        name={code}
        path={active ? 'coins/active' : 'coins'}
        width={size}
        height={size}
      />
      {adornment}
    </div>
  );
};
