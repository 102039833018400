import { GetLoanFormValues } from 'modules/loans/views/LoanCalculator';
import { IApiInfinityListState, IApiRequestState } from 'store/generators/types';

import { CurrencyCode, FiatCurrencyCode } from 'types';

// cli-import

export type LoanCalculatorSavedValues = Omit<GetLoanFormValues, 'templateId'> & {
  templateId: number | null;
};

export interface LoansState {
  loansItemsActive: IApiInfinityListState<LoanItem>;
  loansItemsHistory: IApiInfinityListState<LoanItem>;
  statistic: IApiRequestState<Statistic | null>;
  notifications: IApiRequestState<LoanNotification[]>;
  templates: IApiRequestState<LoanTemplate[]>;
  loanCalculatorSavedValues: LoanCalculatorSavedValues;
  // cli-state-interface
}

export interface LoanItem {
  id: number;
  templateId: number;
  templateType: LoanTemplateType;

  loanAmount: number;
  loanCurrency: CurrencyCode;

  collateralAmount: number;
  collateralCurrency: CurrencyCode;

  termMonth: number;
  ltv: number;
  interestRate: number;

  nextPaymentAmount: number | null;
  nextPaymentDate: string | null;

  marginCallExchangeRate: number | null;
  marginCallStatus: 'none' | 'approach' | 'active';
  collateralMinDepositAmount: number | null;
  marginCallStartDate: string | null;

  isActive: boolean;

  autoManagementMC: boolean;
  excessCollateralRelease: boolean;
  fullRepaymentAllowed: boolean;
  partialRepaymentAllowed: boolean;

  restBalanceAmount: number | null;

  dateStart: string;
  dateEnd: string;
  exchangeRate: number | null;

  lastTransaction: {
    collateralAmount: number;
    walletAmount: number;
    transactionAmount: number;
    transactionType: 'autoMC' | 'excessRelease' | 'addedManually' | 'payoutBack';
  } | null;

  liquidationRiskPercent: number;
  autoPaymentFailedDepositAmount: number | null;

  leftDaysBeforeLiquidation: number | null;
}

export interface Statistic {
  totalAmount: number;
  defaultCurrencyCode: FiatCurrencyCode;
  nextPaymentDate: string | null;
  nextPaymentAmount: number | null;
  nextPaymentCurrency: CurrencyCode | null;
  loansNumber: number;
}

export interface LoanPaymentHistoryItem {
  paymentDate: string;
  paymentAmount: number | null;
  paymentCurrencyCode: CurrencyCode;
  outstandingBalance: number;
  paymentsLeft: number;

  paymentType: 'monthlyRepayment' | 'manualPayment' | 'loanIssued';

  isPaid: boolean;
}
export interface LoanCollateralHistoryItem {
  date: string;

  currencyCode: CurrencyCode;

  walletAmount: number;
  collateralAmount: number;
  transactionAmount: number;

  transactionType:
    | 'autoMC'
    | 'excessRelease'
    | 'addedManually'
    | 'payoutBack'
    | 'purchaseCollateralForMirrorTemplate';
}

export interface OutstandingBalanceDetails {
  loanBalanceAmount: number;
  interestRateBalanceAmount: number;
  fineForLateRepaymentAmount: number | null;
  earlyRepaymentFeeAmount: number | null;
  totalOutstandingBalance: number;
  currencyCode: CurrencyCode;
}

export enum LoanNotificationType {
  'marginCall' = 'MARGIN_CALL', // Auto replenish failed or auto replenish off
  'preMarginCall' = 'PRE_MARGIN_CALL',
  'autoMarginCallReplenishmentFailed' = 'AUTO_MARGIN_CALL_FAILED',
  'marginCallAvoidedByAutoReplenish' = 'AUTO_MARGIN_CALL_SUCCEEDED',
  'marginCallAvoidedByRateRaised' = 'MARGIN_CALL_AVOIDED_BY_RATE_RAISED',
  'autoPaymentFailed' = 'AUTO_PAYMENT_FAILED',
  'autoPaymentSucceeded' = 'AUTO_PAYMENT_SUCCEEDED',
  'releaseExcessCollateral' = 'RELEASE_EXCESS_COLLATERAL',
}

export interface LoanNotification {
  id: number;
  type: LoanNotificationType;
  loan: LoanItem;
}

export type LoanTemplateType = 'FLEXIBLE' | 'BULLET' | 'MIRROR' | 'ONLY_INTEREST' | 'QUICK';

export interface LoanTemplate {
  id: number;
  isQuick: boolean;

  autoMCEnabled: boolean;
  excessCollateralReleaseEnabled: boolean;

  earlyRepaymentPenaltyPercent: number;

  maxTermMonth: number;
  minTermMonth: number;

  originationFeePercent: number;

  loanRules: { [key in CurrencyCode]: { maxAmount: number; minAmount: number } };
  collateralRules: { [key in CurrencyCode]: { maxAmount: number; minAmount: number } };

  ltvRange: {
    [key: number]: {
      percent: number;
      percentWithAutoMC: number;
      percentWithExcessCollateral: number;
      percentWithAutoMCAndExcessCollateral: number;
    };
  };
  minLtv: number;
  maxLtv: number;

  type: LoanTemplateType;
}

// cli-type
