import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import useDrawer from 'modules/app/hooks/useDrawer';
import phonebookDrawerTemplates from 'modules/phonebook/constants/drawerTemplates';
import { requestCreateCounterparty, requestEditCounterparty } from 'modules/phonebook/store/thunks';
import { Counterparty } from 'modules/phonebook/types';
import { useDispatch } from 'store';

import { SubmitButton, TextInputField } from 'components/form';

import { getTranslation, useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';
import yup, { makeValidate } from 'libs/yup';

export interface AddEditCounterpartyProps {
  counterpartyForEdit?: Counterparty;
}

interface FormValues {
  name: string;
  email: string;
}

interface AddEditCounterpartyFormProps extends FormRenderProps<FormValues> {
  isEditMode: boolean;
}
const AddEditCounterpartyForm: FC<AddEditCounterpartyFormProps> = ({
  handleSubmit,
  isEditMode,
}) => {
  const translate = useTranslation();
  return (
    <form onSubmit={handleSubmit} className="column flex-1 gap-24 jcsb">
      <div className="column gap-2">
        <Field
          name="name"
          component={TextInputField}
          label={translate('PHONEBOOK_COUNTERPARTY_NAME')}
          placeholder={translate('PHONEBOOK_COUNTERPARTY_NAME_PLACEHOLDER')}
        />
        <Field
          name="email"
          component={TextInputField}
          label={translate('PHONEBOOK_COUNTERPARTY_EMAIL')}
          placeholder={translate('PHONEBOOK_COUNTERPARTY_EMAIL_PLACEHOLDER')}
          underComponent={
            <p className="font-s grey-300">{translate('PHONEBOOK_COUNTERPARTY_EMAIL_NOTE')}</p>
          }
        />
      </div>
      <SubmitButton fullWidth>{translate(isEditMode ? 'UPDATE' : 'ADD')}</SubmitButton>
    </form>
  );
};

const schema = yup.object().shape({
  name: yup
    .string()
    .max(80, getTranslation('VALIDATION_MAX', { max: 80 }))
    .requiredDefault(),
  email: yup.string().optional().email(getTranslation('VALIDATION_EMAIL')),
});
const validate = makeValidate(schema);
const AddEditCounterparty: FC<AddEditCounterpartyProps> = ({ counterpartyForEdit }) => {
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const translate = useTranslation();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const thunk = counterpartyForEdit
        ? requestEditCounterparty({ ...values, id: counterpartyForEdit.id })
        : requestCreateCounterparty(values);
      const { data: newCounterparty, success } = await dispatch(thunk);

      if (success && newCounterparty) {
        if (counterpartyForEdit) {
          drawer.pop();
        } else {
          drawer.replace(
            phonebookDrawerTemplates.counterparty({ counterpartyId: newCounterparty.id }),
          );
        }
        successToast(
          translate(
            counterpartyForEdit
              ? 'PHONEBOOK_COUNTERPARTY_EDITED'
              : 'PHONEBOOK_COUNTERPARTY_CREATED',
          ),
        );
      }
    },
    [translate, drawer, dispatch, counterpartyForEdit],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <AddEditCounterpartyForm {...formProps} isEditMode={!!counterpartyForEdit} />
    ),
    [counterpartyForEdit],
  );

  const initialValues: FormValues = useMemo(
    () =>
      counterpartyForEdit
        ? { name: counterpartyForEdit.name, email: counterpartyForEdit.email || '' }
        : {
            name: '',
            email: '',
          },
    [counterpartyForEdit],
  );
  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={renderForm}
      validate={validate}
    />
  );
};

export default AddEditCounterparty;
