import { createSlice } from '@reduxjs/toolkit';

import { generateDefaultReducer, generateRequestDataReducer } from 'store/generators/reducers';
import { initialArrayApiRequestState } from 'store/generators/utils';

// cli-import
import { AccountType, AccountsState, DigitalWallet, Issuer, Transaction, Wallet } from '../types';

const initialState: AccountsState = {
  wallets: initialArrayApiRequestState,
  issuers: initialArrayApiRequestState,

  activeAccountType: AccountType.crypto,
  digitalWallets: initialArrayApiRequestState,

  lastTransactions: initialArrayApiRequestState,
  // cli-state
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    resetState: (state) => {
      state = initialState;
      return state;
    },
    updateWallets: generateRequestDataReducer<AccountsState, Wallet[]>({
      reducerPath: 'wallets',
    }),
    updateIssuers: generateRequestDataReducer<AccountsState, Issuer[]>({
      reducerPath: 'issuers',
    }),
    updateAccountType: generateDefaultReducer<AccountsState, AccountType>({
      reducerPath: 'activeAccountType',
    }),
    updateDigitalWallets: generateRequestDataReducer<AccountsState, DigitalWallet[]>({
      reducerPath: 'digitalWallets',
    }),
    updateLastTransactions: generateRequestDataReducer<AccountsState, Transaction[]>({
      reducerPath: 'lastTransactions',
    }),
    // cli-reducer
  },
});

export default accountsSlice.reducer;
export const accountsActions = accountsSlice.actions;
