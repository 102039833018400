import { useMemo } from 'react';

import { PaymentOperationId } from 'modules/payment/types';
import useTransactionLimit from 'modules/payment/views/components/AmountForm/hooks/useTransactionLimit';

import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

const useLimits = ({
  currencyAmount,
  operationId,
}: {
  currencyAmount: CurrencyAmount;
  operationId: PaymentOperationId;
}) => {
  const translate = useTranslation();

  const transactionLimit = useTransactionLimit(operationId, currencyAmount.currency);

  const currencyAmountPlaceholder = useMemo(
    () =>
      transactionLimit.max
        ? translate('PAYMENT_LIMITS_LABEL', {
            minLabel: formatCurrencyWithSymbol(transactionLimit.min, currencyAmount.currency, {
              round: 'up',
            }),
            maxLabel: formatCurrencyWithSymbol(transactionLimit.max, currencyAmount.currency, {
              round: 'up',
            }),
          })
        : translate('PAYMENT_LIMITS_LABEL_MINIMUM', {
            minLabel: formatCurrencyWithSymbol(transactionLimit.min, currencyAmount.currency, {
              round: 'up',
            }),
          }),
    [translate, currencyAmount.currency, transactionLimit],
  );

  return { currencyAmountPlaceholder };
};

export default useLimits;
