import clsx from 'clsx';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectAppLanguage } from 'modules/app/store/selectors';
import SignIn from 'modules/auth/views/SignIn';
import SignUp from 'modules/auth/views/SignUp';
import { SignPageHeader } from 'modules/auth/views/components/SignPageHeader';
import { SignTerms } from 'modules/auth/views/components/SignTerms';
import { isReactNative, isReactNativeAndroid } from 'modules/reactNative/utils';

import routesByName from 'constants/routesByName';

import useFlag from 'hooks/useFlag';

import classes from './Sign.module.scss';

const Sign = () => {
  const { pathname } = useLocation();

  const appLanguage = useSelector(selectAppLanguage);

  const langPicker = useFlag(false);

  const isLogin = pathname.startsWith(routesByName.signIn);

  return (
    <div
      className={clsx(
        classes.container,
        isReactNative && classes.reactNative,
        isReactNativeAndroid && classes.reactNativeAndroid,
      )}
    >
      <SignPageHeader langPicker={langPicker} appLanguage={appLanguage} />

      <div id="SIGN_SLIDER" className={clsx(classes.slider, isLogin && classes.login)}>
        <div className={classes.wrapper}>
          <SignIn />
          <SignUp />
        </div>
      </div>
      <SignTerms appLanguageShortCode={appLanguage.shortCode} langPicker={langPicker} />
    </div>
  );
};

export default Sign;
