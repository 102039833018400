import { useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';

import { Icon, MenuList } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import { useTranslation } from 'libs/i18n';

import classes from './Invoices.module.scss';

export const InvoicesEmpty = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const menu = useMemo<MenuListItem[]>(
    () => [
      {
        label: translate('INVOICES_ADD_YOUR_CLIENT_DETAILS'),
        handler: () => {
          drawer.open(invoicingDrawerTemplates.savedClients({ variant: 'manage' }));
        },
        icon: 'casePlus',
      },
    ],
    [translate, drawer],
  );
  return (
    <div className="column flex-1 jcc gap-4">
      <div className="column gap-6 aic py-3">
        <Icon name="document2" size={72} />
        <div className={classes.textContent}>
          <h3>{translate('INVOICES_NO_INVOICES')}</h3>
          <p>{translate('INVOICES_NO_INVOICES_SUBTITLE')}</p>
        </div>
        <MenuList menu={menu} centered className="w100" />
      </div>
    </div>
  );
};
