import { FC, ReactElement } from 'react';

import { DotSeparatedViews } from 'components/ui/DotSeparatedViews';
import { Skeleton } from 'components/ui/Skeleton';

import classes from './DetailsDotSeparated.module.scss';

interface DetailsDotSeparatedProps {
  label?: string;
  value?: string;
  valueComponent?: ReactElement;
  loading?: boolean;
}
export const DetailsDotSeparated: FC<DetailsDotSeparatedProps> = ({
  label,
  valueComponent,
  value,
  loading,
}) => {
  return (
    <DotSeparatedViews
      leftComponent={
        loading ? (
          <Skeleton height={16} width={120} />
        ) : (
          <span className={classes.label}>{label}</span>
        )
      }
      rightComponent={
        loading ? (
          <Skeleton height={16} width={40} />
        ) : (
          <>
            {value && <span className={classes.value}>{value}</span>}
            {valueComponent}
          </>
        )
      }
    />
  );
};
