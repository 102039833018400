import { StakingItem, Template } from 'modules/staking/types';
import { transformList } from 'store/generators/transformers';

import {
  DepositTemplateDto,
  StakingDto,
  StakingResponseList,
  TemplatesResponse,
} from 'libs/swagger/nebeusApiTypes';

export const transformStakingTemplates = (response: TemplatesResponse): Template[] =>
  transformList<DepositTemplateDto, Template>(response.templates?.filter((t) => t.staking) || [], [
    ['id'],
    ['annualPercent', 'percentRPY'],
    // @ts-ignore
    ['rules', 'currencyCode', { custom: (rules) => rules[0].currencyCode }],
    // @ts-ignore
    ['rules', 'minAmount', { custom: (rules) => rules[0].minAmount }],
  ]).sort((a, b) => b.percentRPY - a.percentRPY);

export const transformStakingItems = (response: StakingResponseList): StakingItem[] =>
  transformList<StakingDto, StakingItem>(response.stakingAccounts || [], [
    ['id'],
    ['currentAmount', 'amount'],
    ['currency', 'currencyCode'],
    ['interestRate', 'percentRPY'],
    ['templateId'],
    ['amountInDefaultCurrency'],
    ['defaultUserCurrency', 'defaultCurrency'],
  ]);
