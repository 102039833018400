import { AppStore } from 'store';

// eslint-disable-next-line @typescript-eslint/no-unused-vars

export const selectTemplatesReducer = (state: AppStore) => state.cryptoRenting.templates;
export const selectTemplates = (state: AppStore) => state.cryptoRenting.templates.data;
export const selectStatisticReducer = (state: AppStore) => state.cryptoRenting.statistic;

export const selectActiveRentingItemsReducer = (state: AppStore) =>
  state.cryptoRenting.rentingItemsActive;
export const selectHistoryRentingItemsReducer = (state: AppStore) =>
  state.cryptoRenting.rentingItemsHistory;

export const selectRentingItems = (state: AppStore) => [
  ...state.cryptoRenting.rentingItemsActive.data,
  ...state.cryptoRenting.rentingItemsHistory.data,
];
