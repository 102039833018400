import clsx from 'clsx';

import { ChangeEventHandler, FC, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { AllowedCountry } from 'modules/app/types';

import appConfig from 'constants/appConfig';

import { Icon } from 'components/ui/Icon';
import { Loader } from 'components/ui/Loader';
import { NoResultLabel } from 'components/ui/NoResultLabel';
import { Select, SelectProps } from 'components/ui/Select';
import { TextInput, TextInputProps } from 'components/ui/TextInput';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';
import { errorToast } from 'libs/toast';

import classes from './AddressAutoComplete.module.scss';

// eslint-disable-next-line no-undef
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
// eslint-disable-next-line no-undef
type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export interface Address {
  address1: string;
  address2: string;
  postCode: string;
  city: string;
  country: AllowedCountry;
}

export interface AddressAutoCompleteProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  selectPortalNode?: Element;
  selectProps?: Partial<SelectProps<AutocompletePrediction>>;
  onChange: (address: Address) => void;
  floatingSelect?: boolean;
  allowedCountries: AllowedCountry[];
}

const renderItem = (prediction: AutocompletePrediction) => (
  <div className={classes.option}>
    <span>{prediction.description}</span>
    <Icon name="chevronRightSquaredThin" />
  </div>
);
const keyAccessor = (prediction: AutocompletePrediction) => prediction.place_id;

const MIN_LENGTH_TO_SEARCH = 3;

const findAddressComponent = (
  components: GeocoderAddressComponent[] | undefined,
  types: string[],
) => components?.find((c) => types.some((searchType) => c.types.includes(searchType)));

export const AddressAutoComplete: FC<AddressAutoCompleteProps> = ({
  selectPortalNode,
  selectProps,
  onChange,
  floatingSelect = true,
  allowedCountries,
}) => {
  const translate = useTranslation();

  const [query, setQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesAutocompleteService({
      apiKey: appConfig.googlePlacesApiKey,
      debounce: 3000,
    });

  const handleChangeQuery = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const inputValue = event.currentTarget.value;
      setQuery(inputValue);

      if (inputValue.length < MIN_LENGTH_TO_SEARCH) {
        return;
      }

      getPlacePredictions({ input: inputValue });
    },
    [getPlacePredictions],
  );

  const select = useFlag(false);

  const handlePick = useCallback(
    async (prediction: AutocompletePrediction) => {
      placesService?.getDetails({ placeId: prediction.place_id }, (details) => {
        if (details && details.address_components && details.address_components.length > 0) {
          const streetName = findAddressComponent(details.address_components, [
            'street_address',
            'route',
            'political',
          ])?.long_name;
          const buildNumber = findAddressComponent(details.address_components, [
            'street_number',
          ])?.long_name;

          const address1 = [streetName, buildNumber].filter((entity) => entity).join(', ');
          const address2 = findAddressComponent(details.address_components, [
            'subpremise',
          ])?.long_name;

          const city = findAddressComponent(details.address_components, ['locality'])?.long_name;
          const countryIso2 = findAddressComponent(details.address_components, [
            'country',
          ])?.short_name;
          const postCode = findAddressComponent(details.address_components, [
            'postal_code',
          ])?.long_name;

          const country = allowedCountries.find((c) => c.code === countryIso2);

          if (!country) {
            errorToast(translate('ERROR_COUNTRY_IS_NOT_ALLOWED_FOR_OPERATION'));
            return;
          }

          const address: Address = {
            address1,
            address2: address2 || '',
            city: city || '',
            country,
            postCode: postCode || '',
          };
          onChange(address);
        }
      });
    },
    [placesService, translate, allowedCountries, onChange],
  );

  const selectComponent = (
    <Select<AutocompletePrediction>
      anchorEl={anchorEl}
      isOpen={select.state}
      onClose={select.off}
      options={placePredictions}
      onPick={handlePick}
      renderItem={renderItem}
      keyAccessor={keyAccessor}
      noOptionsLabel={
        query.length < MIN_LENGTH_TO_SEARCH
          ? translate('TYPE_TO_SEARCH')
          : isPlacePredictionsLoading
          ? translate('LOADING_WITH_DOTS')
          : undefined
      }
      onMobileShowSwipeModal={false}
      {...selectProps}
    />
  );

  const finalSelectComponent = floatingSelect
    ? selectPortalNode
      ? createPortal(selectComponent, selectPortalNode)
      : selectComponent
    : null;

  return (
    <div className={clsx(classes.root, !floatingSelect && classes.fullPage)}>
      <TextInput
        onFocus={select.on}
        inputContainerRef={setAnchorEl}
        value={query}
        onChange={handleChangeQuery}
        startAdornment={<Icon name="search" className={classes.searchIcon} size={20} />}
        endAdornment={
          <Loader
            size={24}
            className={clsx(classes.loader, isPlacePredictionsLoading && classes.active)}
          />
        }
        placeholder={translate('SEARCH_ADDRESS')}
      />
      {finalSelectComponent}
      {!floatingSelect && (
        <div className={classes.fullPageOptions}>
          {placePredictions.length === 0 &&
          !isPlacePredictionsLoading &&
          query.length >= MIN_LENGTH_TO_SEARCH ? (
            <NoResultLabel findString={query} />
          ) : null}
          {placePredictions.map((p) => (
            <div key={p.place_id} onClick={() => handlePick(p)}>
              {renderItem(p)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
