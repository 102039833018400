import appConfig from 'constants/appConfig';

import AxiosInstance from 'libs/axios/AxiosInstance';
import extendAxiosInstance from 'libs/axios/extendAxiosInstance';

const SmartTraderAxiosInstance = extendAxiosInstance(AxiosInstance, {
  baseURL: appConfig.apiUrl + '/v1/smart-trader',
});

export default SmartTraderAxiosInstance;
