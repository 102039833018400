import { useEffect, useState } from 'react';

const fetchImageWithX = async ({
  xNumber,
  type,
  imageName,
  path = '',
}: {
  xNumber: 1 | 2 | 3;
  type: 'png' | 'jpg' | 'svg';
  imageName: string;
  path?: string;
}) => {
  try {
    const xString = xNumber > 1 ? `@${xNumber}x` : '';

    const response = await import(
      /* webpackMode: "eager" */
      '../../../assets/images/' + `${path}${path ? '/' : ''}${imageName}${xString}.${type}`
    );
    return response.default;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return '';
  }
};

const useFetchLargestImages = ({
  type,
  imageName,
  path,
}: {
  type: 'png' | 'jpg' | 'svg';
  imageName: string;
  path?: string;
}) => {
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const first = await fetchImageWithX({
        xNumber: 1,
        type,
        imageName,
        path,
      });
      if (type === 'svg') {
        setImages([first]);
        return;
      }
      const second = await fetchImageWithX({
        xNumber: 2,
        type,
        imageName,
        path,
      });
      const third = await fetchImageWithX({
        xNumber: 3,
        type,
        imageName,
        path,
      });
      setImages([first, second, third]);
    })();
    // eslint-disable-next-line
  }, [imageName]);

  return images;
};

export default useFetchLargestImages;
