import { useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { CreateInvoiceStep } from 'modules/invoicing/views/CreateInvoice/components/CreateInvoiceStep';
import InvoiceServiceItemsForm, {
  InvoiceServiceItemsFormValues,
} from 'modules/invoicing/views/CreateInvoice/components/InvoiceServiceItemsForm';

import { useTranslation } from 'libs/i18n';

export const InvoiceServiceItems = () => {
  const translate = useTranslation();

  const form = useForm<InvoiceServiceItemsFormValues>();
  const { values } = useFormState<InvoiceServiceItemsFormValues>();

  const addItem = useCallback(() => {
    form.change('invoiceServiceItems', [
      ...values.invoiceServiceItems,
      { amount: '', name: '', quantity: '' },
    ]);
  }, [form, values.invoiceServiceItems]);

  return (
    <CreateInvoiceStep
      label={translate('INVOICE_ITEMS')}
      labelEndAdornment={
        <span onClick={addItem} className="cyanBlue pointer label">
          {translate('ADD')}
        </span>
      }
      component={<InvoiceServiceItemsForm canRemove />}
    />
  );
};
