import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectIssuersByCurrencyCode } from 'modules/accounts/store/selectors';
import { exchangeActions } from 'modules/exchange/store';
import { selectExchangeRates } from 'modules/exchange/store/selectors';
import { requestExchangeRate } from 'modules/exchange/store/thunks';
import { useDispatch } from 'store';

import { canRequest } from 'utils/common';

import { CurrenciesDirection, CurrencyCode } from 'types';

const useFetchRate = () => {
  const dispatch = useDispatch();
  const rates = useSelector(selectExchangeRates);

  const issuers = useSelector(selectIssuersByCurrencyCode);

  const fetchRate = useCallback(
    async (
      from: CurrencyCode,
      to: CurrencyCode,
      options?: { force?: boolean },
    ): Promise<{ rate: number }> => {
      const direction = `${from}/${to}` as CurrenciesDirection;
      const rateReducer = rates[direction];

      if (!rateReducer || (rateReducer && (canRequest(rateReducer.meta) || options?.force))) {
        if (to === from) {
          dispatch(
            exchangeActions.updateRates({
              direction: direction,
              meta: { loading: false, loaded: true, error: undefined },
              data: {
                fromCurrency: from,
                toCurrency: to,
                rate: 1,
              },
            }),
          );
          return { rate: 1 };
        }

        const fromIssuerId = issuers[from]?.id;
        const toIssuerId = issuers[to]?.id;

        if (fromIssuerId && toIssuerId) {
          const { data } = await dispatch(
            requestExchangeRate({ fromCurrency: from, fromIssuerId, toCurrency: to, toIssuerId }),
          );

          return { rate: data?.rate || 0 };
        }
      }
      return { rate: 0 };
    },
    [rates, dispatch, issuers],
  );

  return { fetchRate };
};

export default useFetchRate;
