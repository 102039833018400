import { createSlice } from '@reduxjs/toolkit';

import { Transaction } from 'modules/accounts/types';
import { generateRequestDataReducer } from 'store/generators/reducers';
import { initialArrayApiRequestState } from 'store/generators/utils';

import { DigitalAccountState, DigitalSubscription, IbanAccountProvider } from '../types';
import { AllowedCountries } from '../types';

// cli-import

const initialState: DigitalAccountState = {
  subscriptions: initialArrayApiRequestState,
  lastTransactions: initialArrayApiRequestState,

  allowedCountries: initialArrayApiRequestState,
  allowedProviders: initialArrayApiRequestState,
  // cli-state
};

const digitalAccountSlice = createSlice({
  name: 'digitalAccount',
  initialState,
  reducers: {
    resetState: (state) => {
      state = initialState;
      return state;
    },
    updateSubscriptions: generateRequestDataReducer<DigitalAccountState, DigitalSubscription[]>({
      reducerPath: 'subscriptions',
    }),
    updateLastTransactions: generateRequestDataReducer<DigitalAccountState, Transaction[]>({
      reducerPath: 'lastTransactions',
    }),

    updateAllowedCountries: generateRequestDataReducer<DigitalAccountState, AllowedCountries>({
      reducerPath: 'allowedCountries',
    }),
    updateAllowedProviders: generateRequestDataReducer<DigitalAccountState, IbanAccountProvider[]>({
      reducerPath: 'allowedProviders',
    }),
    // cli-reducer
  },
});

export default digitalAccountSlice.reducer;
export const digitalAccountActions = digitalAccountSlice.actions;
