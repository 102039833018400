import clsx from 'clsx';

import { FC } from 'react';

import { Icon } from 'components/ui';

import classes from './FeedbackRate.module.scss';

interface FeedbackRateProps {
  value: number | null;
  onChange: (value: number) => void;
}
export const FeedbackRate: FC<FeedbackRateProps> = ({ value, onChange }) => {
  return (
    <div className={clsx(classes.stars, value && classes.locked)}>
      {new Array(5).fill(null).map((_, i) => (
        <Icon
          className={clsx(classes.icon, value && i < value ? classes.active : null)}
          onClick={() => {
            onChange(i + 1);
          }}
          key={i}
          name="starFilled"
          size="xl"
        />
      ))}
    </div>
  );
};
