import { FC } from 'react';

import { cryptoBankFeatures } from 'modules/cryptoBankCard/constants/config';
import DescriptionCards from 'modules/cryptoBankCard/views/DescriptionCards';

export interface BankCardsFeaturesExploreProps {}
const BankCardsFeaturesExplore: FC<BankCardsFeaturesExploreProps> = () => {
  return <DescriptionCards list={cryptoBankFeatures} />;
};

export default BankCardsFeaturesExplore;
