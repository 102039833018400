import clsx from 'clsx';

import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import useOrderCryptoVirtualBankCard from 'modules/cryptoBankCard/hooks/useOrderCryptoVirtualBankCard';
import {
  selectIsPhysicalCryptoCardExist,
  selectIsVirtualCryptoCardExist,
} from 'modules/cryptoBankCard/store/selectors';
import { requestSendPassCode, requestSendPinCode } from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard } from 'modules/cryptoBankCard/types';
import { useDispatch } from 'store';

import { Icon, Loader } from 'components/ui';
import { InfoIcon, InfoIconProps } from 'components/ui/InfoIcon';

import useFlag from 'hooks/useFlag';

import { TranslationKey, useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import { voidFunc } from 'types';

import classes from './BankCardMoreOptions.module.scss';

export interface BankCardMoreOptionsProps {
  bankCard: CryptoBankCard;
}
interface Option {
  icon: string;
  label: TranslationKey;
  handler: voidFunc;
  disabled?: boolean;
  loading?: boolean;
  infoIcon?: InfoIconProps;
}
const BankCardMoreOptions: FC<BankCardMoreOptionsProps> = ({ bankCard }) => {
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const translate = useTranslation();

  const userHasCryptoVirtualCard = useSelector(selectIsVirtualCryptoCardExist);
  const userHasCryptoPhysicalCard = useSelector(selectIsPhysicalCryptoCardExist);

  const pinCodeSending = useFlag(false);
  const passCodeSending = useFlag(false);

  const orderCryptoVirtualCard = useOrderCryptoVirtualBankCard();

  const list = useMemo<Option[]>(() => {
    let result: Option[];

    result = [
      {
        loading: passCodeSending.state,
        icon: 'key2',
        label: 'BANK_CARD_SEND_PASSCODE',
        infoIcon: {
          title: translate('BANK_CARD_PASS_CODE_DESC_TITLE'),
          description: translate('BANK_CARD_PASS_CODE_DESC_TEXT'),
        },
        disabled:
          bankCard.isBlocked ||
          (bankCard.type === 'crypto' && (!bankCard.isActivated || bankCard.isFrozen)),
        handler: async () => {
          passCodeSending.on();
          const { success } = await dispatch(requestSendPassCode({ id: bankCard.id }));
          if (success) {
            successToast(translate('BANK_CARD_SEND_PASSCODE_SUCCESS'));
            drawer.pop();
          }
          passCodeSending.off();
        },
      },
      {
        icon: 'percent',
        label: 'FEES_AND_CONDITIONS',
        handler: () => {
          drawer.open(cryptoBankCardDrawerTemplates.feeAndConditions());
        },
      },
      {
        icon: 'crossSm',
        label: 'BANK_CARD_TERMINATE',
        disabled: bankCard.isBlocked || !bankCard.isActivated,
        handler: () => {
          drawer.replace(
            cryptoBankCardDrawerTemplates.terminateCard({
              bankCard: bankCard,
            }),
          );
        },
      },
    ];

    if (!userHasCryptoVirtualCard) {
      result.unshift({
        icon: 'plusBold',
        label: 'BANK_CARD_GET_NEW_VIRTUAL',
        loading: orderCryptoVirtualCard.loading,
        handler: async () => {
          await orderCryptoVirtualCard.startProcess();
        },
      });
    }
    if (!userHasCryptoPhysicalCard) {
      result.unshift({
        icon: 'plusBold',
        label: 'BANK_CARD_GET_NEW_PHYSICAL',
        handler: () => {
          drawer.open(commonDrawerTemplates.bankCards({ type: 'crypto', getNew: true }));
        },
      });
    }

    if (!bankCard.isVirtual) {
      result.splice(
        userHasCryptoVirtualCard ? 0 : 1,
        0,
        {
          loading: pinCodeSending.state,
          icon: 'key2',
          label: 'BANK_CARD_SEND_PIN',
          infoIcon: {
            title: translate('BANK_CARD_PIN_CODE_DESC_TITLE'),
            description: translate('BANK_CARD_PIN_CODE_DESC_TEXT'),
          },
          disabled:
            bankCard.isBlocked ||
            (bankCard.type === 'crypto' && (!bankCard.isActivated || bankCard.isFrozen)),
          handler: async () => {
            pinCodeSending.on();
            const { success } = await dispatch(requestSendPinCode({ id: bankCard.id }));
            if (success) {
              successToast(translate('BANK_CARD_SEND_PIN_SUCCESS'));
              drawer.pop();
            }
            pinCodeSending.off();
          },
        },
        {
          icon: 'lockOutlined',
          label: 'BANK_CARD_CHANGE_PIN',
          disabled:
            bankCard.isBlocked ||
            (bankCard.type === 'crypto' && (!bankCard.isActivated || bankCard.isFrozen)),
          handler: async () => {
            drawer.replace(cryptoBankCardDrawerTemplates.changePinCode({ bankCard }));
          },
        },
      );
    }
    return result;
  }, [
    orderCryptoVirtualCard,
    drawer,
    userHasCryptoPhysicalCard,
    userHasCryptoVirtualCard,
    pinCodeSending,
    passCodeSending,
    dispatch,
    bankCard,
    translate,
  ]);

  return (
    <div className={classes.root}>
      {list.map((i) => (
        <div
          key={i.label}
          className={clsx(classes.button, (i.disabled || i.loading) && classes.disabled)}
          onClick={i.disabled ? undefined : i.handler}
        >
          <div className="row aic gap-1-5 jcsb flex-1">
            <div className="row aic gap-1-5">
              <Icon name={i.icon} size={24} />
              <span>{translate(i.label)}</span>
            </div>
            {i.infoIcon && <InfoIcon showInSidebar {...i.infoIcon} />}
          </div>
          {i.loading && <Loader size="sm" />}
        </div>
      ))}
    </div>
  );
};

export default BankCardMoreOptions;
