import { useCallback, useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { requestSubmitCustomerQuestionnaire } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import { Quiz, QuizProps } from 'components/common/Quiz';
import { DrawerHeader, FeedbackRate } from 'components/ui';

import { TranslationKey, receiveTranslation, useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

const questions: {
  question: TranslationKey;
  severalAnswersAllowed?: boolean;
  options?: { text: TranslationKey; customAnswerAllowed?: boolean }[];
  answerComponent?: QuizProps['questions'][number]['answerComponent'];
}[] = [
  {
    question: 'CUSTOMER_QUESTIONNAIRE_QUESTION_1',
    severalAnswersAllowed: true,
    options: [
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_1' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_2' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_3' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_4' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_5' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_6' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_7', customAnswerAllowed: true },
    ],
  },
  {
    question: 'CUSTOMER_QUESTIONNAIRE_QUESTION_2',
    severalAnswersAllowed: true,
    options: [
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_1' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_2' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_3' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_4' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_5' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_6' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_7', customAnswerAllowed: true },
    ],
  },
  {
    question: 'CUSTOMER_QUESTIONNAIRE_QUESTION_3',
    options: [
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_1' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_2' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_3' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_4' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_5' },
    ],
  },
  {
    question: 'CUSTOMER_QUESTIONNAIRE_QUESTION_4',
    options: [
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_1' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_2' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_3' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_4' },
      { text: 'CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_5' },
    ],
  },
  {
    question: 'CUSTOMER_QUESTIONNAIRE_QUESTION_5',
    answerComponent: ({ answer, questionId, onChange }) => (
      <FeedbackRate
        value={answer.customAnswer ? +answer.customAnswer : null}
        onChange={(newValue) => onChange({ questionId, customAnswer: newValue.toString() })}
      />
    ),
  },
];

const CustomerQuestionnaire = () => {
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const translate = useTranslation();

  const mappedQuestions = useMemo<QuizProps['questions']>(
    () =>
      questions.map((q) => ({
        questionId: q.question,
        questionText: translate(q.question),
        severalAnswersAllowed: q.severalAnswersAllowed,

        options: q.options
          ? q.options.map((o) => ({
              id: o.text,
              text: translate(o.text),
              customAnswerAllowed: o.customAnswerAllowed,
            }))
          : undefined,
        answerComponent: q.answerComponent,
      })),
    [translate],
  );

  const handleSubmit = useCallback<QuizProps['onSubmit']>(
    async (answers) => {
      const { success } = await dispatch(
        requestSubmitCustomerQuestionnaire({
          answers: answers.map((a) => {
            return {
              questionId: a.questionId,
              question: receiveTranslation(a.questionId as TranslationKey, 'ENG'),
              answers: a.answersIds.map((id) => receiveTranslation(id as TranslationKey, 'ENG')),
              customAnswer: a.customAnswer,
            };
          }),
        }),
      );

      if (success) {
        successToast(translate('QUESTIONNAIRE_FINISH_MESSAGE'));
      }

      drawer.pop();
    },
    [dispatch, translate, drawer],
  );
  const handleSkip = useCallback(async () => {
    await dispatch(requestSubmitCustomerQuestionnaire({ answers: [] }));
    drawer.pop();
  }, [drawer, dispatch]);

  return (
    <div className="column gap-3 flex-1 pt-2">
      <DrawerHeader
        title={translate('CUSTOMER_QUESTIONNAIRE_TITLE')}
        showCloseButton={false}
        showBackButton={false}
      />
      <Quiz onSkip={handleSkip} onSubmit={handleSubmit} questions={mappedQuestions} />
    </div>
  );
};

export default CustomerQuestionnaire;
