import { FieldRenderProps } from 'react-final-form';

import { AllowedCountry } from 'modules/app/types';

import { CountryPickerInput } from 'components/ui';
import { CountryPickerInputProps } from 'components/ui/CountryPickerInput';

import useField from 'hooks/useField';

interface CountryPickerInputFieldProps
  extends FieldRenderProps<AllowedCountry>,
    Omit<CountryPickerInputProps, 'value' | 'onChange'> {}

export const CountryPickerInputField = ({
  input: { value, onChange, ...input },
  meta,
  ...props
}: CountryPickerInputFieldProps) => {
  const { hasError, error, valid } = useField(meta);

  return (
    <CountryPickerInput
      value={value}
      onChange={onChange}
      error={hasError && error}
      valid={valid}
      {...input}
      {...props}
    />
  );
};
