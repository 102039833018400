import clsx from 'clsx';

import { FC, Fragment, ReactNode } from 'react';

import { CurrencyIcon } from 'components/ui/CurrencyIcon';
import { DotSeparatedViews } from 'components/ui/DotSeparatedViews';
import { Loader } from 'components/ui/Loader';

import { CurrencyCode, voidFunc } from 'types';

import classes from './SummaryCard.module.scss';

interface SummaryCardItemNative {
  id?: string;
  label?: string;
  value: ReactNode;
  subValue?: string;
  loading?: boolean;
  currencyCode?: CurrencyCode;
  valueClassName?: string;
  delimiter?: never;
}
export type SummaryCardItem =
  | SummaryCardItemNative
  | {
      delimiter: ReactNode;
      id: string;
      label?: never;
      value?: never;
      subValue?: never;
      loading?: never;
      currencyCode?: never;
      valueClassName?: never;
    };

export interface SummaryCardProps {
  config: SummaryCardItem[];
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  variant?: 'creamy' | 'outlined' | 'borderless';
  onClick?: voidFunc;
}

export const SummaryCard: FC<SummaryCardProps> = ({
  config,
  startAdornment,
  endAdornment,
  variant = 'creamy',
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(classes.root, onClick && 'pointer', classes[`variant-${variant}`])}
    >
      {startAdornment}
      <div className={classes.content}>
        {config.map((i) => (
          <Fragment key={i.id || i.label}>
            {i.delimiter || (
              <div className={classes.item}>
                <DotSeparatedViews
                  leftComponent={i.label ? <span className={classes.label}>{i.label}</span> : null}
                  rightComponent={
                    i.loading ? (
                      <Loader size={16} />
                    ) : (
                      <div className="column gap-1-5">
                        <div className="row aic gap-0-5">
                          {i.currencyCode && <CurrencyIcon size={16} code={i.currencyCode} />}
                          <span
                            className={clsx(
                              classes.value,
                              i.valueClassName,
                              !i.label && classes.noLabel,
                            )}
                          >
                            {i.value}
                          </span>
                        </div>
                        {i.subValue && <span className={classes.subValue}>{i.subValue}</span>}
                      </div>
                    )
                  }
                />
              </div>
            )}
          </Fragment>
        ))}
      </div>
      {endAdornment}
    </div>
  );
};
