import { FirebaseApp as IFirebaseApp } from '@firebase/app';
import { Messaging } from '@firebase/messaging';
import { RemoteConfig } from '@firebase/remote-config';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { fetchAndActivate, getAll, getRemoteConfig } from 'firebase/remote-config';

import { selectIsUserAuthed } from 'modules/auth/store/selectors';
import { selectReactNativeNotificationToken } from 'modules/reactNative/store/selectors';
import { isReactNative, isReactNativeAndroid } from 'modules/reactNative/utils';
import smartTraderServices from 'modules/smartTrader/services';
import userServices from 'modules/user/services';
import store from 'store';

import appConfig from 'constants/appConfig';

interface DefaultFBConfig {
  [key: string]: string | number | boolean;
}
export interface FirebaseConfig extends DefaultFBConfig {
  verificationRequired: boolean;
}

export const initialConfig: FirebaseConfig = {
  verificationRequired: false,
};

export const isNotificationApiSupported = () =>
  'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
class FirebaseApp {
  private app: IFirebaseApp | undefined;
  messaging: Messaging | undefined;
  remoteConfig: RemoteConfig | undefined;
  token: string | null;

  constructor() {
    this.remoteConfig = undefined;
    this.messaging = undefined;
    this.app = undefined;
    this.token = null;
  }

  init() {
    try {
      if (appConfig.firebaseConfig) {
        this.app = initializeApp(JSON.parse(appConfig.firebaseConfig));
        this.initNotifications();
        this.initConfig();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  initConfig() {
    try {
      const remoteConfig = getRemoteConfig(this.app);
      remoteConfig.settings.minimumFetchIntervalMillis = 60000; // 1 minute cache
      remoteConfig.defaultConfig = initialConfig;

      this.remoteConfig = remoteConfig;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error in firebase initConfig: ', e);
    }
  }

  async getRemoteConfig(): Promise<FirebaseConfig | null> {
    try {
      if (!this.remoteConfig) {
        return null;
      }
      await fetchAndActivate(this.remoteConfig);
      const config = getAll(this.remoteConfig);

      return Object.entries(config).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            value.asString() === 'true' || value.asString() === 'false'
              ? value.asBoolean()
              : value.asString(),
        }),
        {},
      ) as FirebaseConfig;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error in firebase getRemoteConfig: ', e);

      return null;
    }
  }

  initNotifications() {
    if (isReactNative) {
      this.provideFCMTokenToExternalServices();
      return;
    }
    if (this.app) {
      try {
        this.messaging = getMessaging(this.app);

        this.subscribeOnNotificationPermissionChanging();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Init notifications error: ', e);
      }
    }
  }

  subscribeOnNotificationPermissionChanging() {
    if (isReactNative) {
      return;
    }
    try {
      navigator.permissions
        ?.query({ name: 'notifications' })
        .then((permissionStatus) => {
          permissionStatus.onchange = async () => {
            if (permissionStatus.state === 'granted') {
              this.provideFCMTokenToExternalServices();
            } else {
              this.removeFCMTokenFromExternalServices();
            }
          };
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Error in subscribeOnNotificationPermissionChanging: ', e);
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error in subscribeOnNotificationPermissionChanging 2: ', e);
    }
  }

  async requestNotificationPermission(): Promise<boolean> {
    if (isNotificationApiSupported() && !isReactNative) {
      const result = await Notification.requestPermission();
      return result === 'granted';
    }
    return false;
  }

  async getFCMToken(): Promise<string | null> {
    if (isReactNative) {
      return null;
    }
    if (this.messaging && this.notificationsAllowed) {
      return getToken(this.messaging, {
        vapidKey: appConfig.firebaseCloudMessagingToken,
      });
    }
    return null;
  }

  get notificationsAllowed(): boolean {
    if (isNotificationApiSupported()) {
      return Notification.permission === 'granted' || isReactNative;
    }
    return false;
  }
  async provideFCMTokenToExternalServices() {
    try {
      const currentStore = store.getState();
      const authed = selectIsUserAuthed(currentStore);
      if (!authed) {
        return;
      }
      if (isReactNative) {
        const token = selectReactNativeNotificationToken(currentStore);
        if (token) {
          try {
            await smartTraderServices.attachFCMTokenMobile(token);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Cannot provide fcm token to smart trader');
          }
          try {
            await userServices.attachFCMToken({
              token,
              appVersion: `${appConfig.customEnvironment} ${appConfig.version} REACT_NATIVE`,
              platform: isReactNative ? (isReactNativeAndroid ? 'ANDROID' : 'IOS') : 'WEB',
              osVersion:
                window.navigator?.appVersion || window?.navigator?.userAgent || 'No version',
            });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Cannot provide fcm token to main backend');
          }
        }
        return;
      }
      const token = await this.getFCMToken();
      this.token = token;

      if (!token) {
        return;
      }
      try {
        await smartTraderServices.attachFCMToken(token);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Cannot provide fcm token to smart trader');
      }
      try {
        await userServices.attachFCMToken({
          token,
          appVersion: `${appConfig.customEnvironment} ${appConfig.version}`,
          platform: isReactNative ? (isReactNativeAndroid ? 'ANDROID' : 'IOS') : 'WEB',
          osVersion: window.navigator?.appVersion || window?.navigator?.userAgent || 'No version',
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Cannot provide fcm token to main backend');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Provide FCM token to external services error: ', e);
    }
  }
  async removeFCMTokenFromExternalServices() {
    const currentStore = store.getState();
    const authed = selectIsUserAuthed(currentStore);
    if (!authed) {
      return;
    }

    if (isReactNative) {
      try {
        await smartTraderServices.detachFCMTokenMobile();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Cannot remove fcm token from smart trader');
      }
    } else {
      try {
        await smartTraderServices.detachFCMToken();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Cannot remove fcm token from smart trader');
      }
    }
    try {
      if (this.token) {
        await userServices.detachFCMToken(this.token);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Cannot provide fcm token to main backend');
    }
  }
}

export default new FirebaseApp();
