import { FC, memo, useCallback } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import { StepHeader } from 'modules/auth/views/components/StepHeader';

import { SubmitButton, TextInputField } from 'components/form';

import useMobile from 'hooks/useMobile';

import { useTranslation } from 'libs/i18n';
import useSwiperInputFocus from 'libs/swiper/useSwiperInputFocus';
import useSwiperSlideSizeObserver from 'libs/swiper/useSwiperSlideSizeObserver';

import { validate } from './validate';

export interface EnterNameFormValues {
  firstName: string;
  lastName: string;
}

const initialValues: EnterNameFormValues = {
  firstName: '',
  lastName: '',
};

interface EnterNameFormProps extends FormRenderProps<EnterNameFormValues> {}
const EnterNameForm: FC<EnterNameFormProps> = ({ handleSubmit }) => {
  const translate = useTranslation();

  const { setNode } = useSwiperSlideSizeObserver();

  const isMobile = useMobile();

  const { inputRef } = useSwiperInputFocus();

  return (
    <form ref={setNode} className="authStepRoot" onSubmit={handleSubmit}>
      <div className="column gap-3">
        <StepHeader
          title={translate('SIGN_UP_ENTER_NAME_TITLE')}
          subtitle={translate('SIGN_UP_ENTER_NAME_SUBTITLE')}
        />
        <div className="column gap-2">
          <Field
            name="firstName"
            inputRef={inputRef}
            component={TextInputField}
            label={isMobile ? translate('FIRST_NAME') : null}
            placeholder={translate('FIRST_NAME')}
            autoComplete="given-name"
          />
          <Field
            name="lastName"
            component={TextInputField}
            label={isMobile ? translate('SURNAME') : null}
            placeholder={translate('SURNAME')}
            autoComplete="family-name"
          />
        </div>
      </div>
      <SubmitButton fullWidth>{translate('CONTINUE')}</SubmitButton>
    </form>
  );
};

interface EnterNameProps {
  onSubmit: (values: EnterNameFormValues) => void;
}

export const EnterName = memo<EnterNameProps>(({ onSubmit }) => {
  const handleSubmit = useCallback((values: EnterNameFormValues) => onSubmit(values), [onSubmit]);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={EnterNameForm}
      validate={validate}
    />
  );
});
