import { useFormState } from 'react-final-form';

import { CreateInvoiceStep } from 'modules/invoicing/views/CreateInvoice/components/CreateInvoiceStep';
import PaymentDetailsForm from 'modules/invoicing/views/CreateInvoice/components/PaymentDetailsForm';
import { CreateInvoiceFormValues } from 'modules/invoicing/views/CreateInvoice/index';

import { useTranslation } from 'libs/i18n';

export const PaymentDetails = () => {
  const translate = useTranslation();

  const { values } = useFormState<CreateInvoiceFormValues>();

  return (
    <CreateInvoiceStep
      label={translate('PAYMENT_DETAILS')}
      component={<PaymentDetailsForm invoiceCurrency={values.invoiceCurrency} />}
    />
  );
};
