import clsx from 'clsx';

import { FC, useRef } from 'react';

import { AuthBackButton } from 'modules/auth/views/components/AuthBackButton';
import { AuthLogo } from 'modules/auth/views/components/AuthLogo';
import { AllowedLanguage } from 'modules/user/constants/language';

import { LanguagePicker } from 'components/common';
import { Icon } from 'components/ui';

import { Flag } from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { openHelpCenter } from 'utils/thirdPartyLibs';

import classes from './SignPageHeader.module.scss';

interface SingPageHeaderProps {
  appLanguage: AllowedLanguage;
  langPicker: Flag;
}

const floatingSelectProps = {
  sizeStyle: { minWidth: '200px' },
};

export const SignPageHeader: FC<SingPageHeaderProps> = ({ appLanguage, langPicker }) => {
  const translate = useTranslation();

  const langPickerAnchor = useRef<HTMLDivElement | null>(null);

  return (
    <div className={classes.root}>
      <AuthBackButton className={classes.backButton} />
      <AuthLogo />
      <div className={classes.tools}>
        <span onClick={openHelpCenter} className="pointer font-semibold">
          {translate('HELP_CENTER')}
        </span>
        <div onClick={langPicker.toggle} ref={langPickerAnchor} className="row aic gap-1 pointer">
          <span className=" font-semibold">{appLanguage.shortCode}</span>
          <Icon
            className={clsx(classes.langPickerIcon, langPicker.state && classes.active)}
            name="chevronRightSquared"
            size="sm"
          />
        </div>
        <LanguagePicker
          anchorEl={langPickerAnchor.current}
          isOpen={langPicker.state}
          onClose={langPicker.off}
          floatingSelectProps={floatingSelectProps}
          placement="top-end"
        />
      </div>
    </div>
  );
};
