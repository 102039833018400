import { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import useRateToDefaultCurrency from 'modules/exchange/hooks/useRateToDefaultCurrency';
import { selectStakingItems, selectStakingTemplatesReducer } from 'modules/staking/store/selectors';
import { requestStakingTemplates, requestUnstake } from 'modules/staking/store/thunks';
import classes from 'modules/staking/views/StakingSetup/StakingSetup.module.scss';
import { OperationListItem } from 'modules/staking/views/components/OperationListItem';
import { TemplateHeader } from 'modules/staking/views/components/TemplateHeader';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { CurrencyAmountField, SubmitButton } from 'components/form';
import { LineDelimiter } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';
import { CurrencyPickerItem } from 'components/ui/CurrencyPicker';

import { getTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import { canRequest } from 'utils/common';
import {
  MINIMUM_AMOUNT_CRYPTO,
  formatCurrency,
  formatCurrencyWithLabel,
  formatCurrencyWithSymbol,
  getCurrencyLabelByCode,
} from 'utils/currency';

import { CurrencyCode } from 'types';

interface FormValues {
  currencyAmount: CurrencyAmount;
}

export interface UnstakeProps {
  currencyCode: CurrencyCode;
}
interface UnstakeFormProps extends FormRenderProps<FormValues> {
  currenciesList: CurrencyPickerItem[];
}

const UnstakeForm: FC<UnstakeFormProps> = ({ handleSubmit, values, currenciesList }) => {
  const { rate: rateToDefaultCurrency, defaultCurrency } = useRateToDefaultCurrency(
    values.currencyAmount.currency,
    { autoFetch: true },
  );

  const currentStakingWallet = useMemo(
    () =>
      currenciesList.find((i) => i.currencyCode === values.currencyAmount.currency) || {
        amount: 0,
        amountInDefaultCurrency: 0,
        currencyCode: values.currencyAmount.currency,
      },
    [currenciesList, values.currencyAmount.currency],
  );

  const defaultCurrencyCalculatorComponent = useMemo(() => {
    return rateToDefaultCurrency ? (
      <span className={classes.defaultCurrencyAmount}>
        {getTranslation('IN_CURRENCY')} {defaultCurrency}{' '}
        {formatCurrencyWithSymbol(
          rateToDefaultCurrency * +values.currencyAmount.amount,
          defaultCurrency,
        )}
      </span>
    ) : undefined;
  }, [values.currencyAmount.amount, defaultCurrency, rateToDefaultCurrency]);

  return (
    <form onSubmit={handleSubmit} className="column mt-4">
      <TemplateHeader currencyCode={values.currencyAmount.currency} />
      <LineDelimiter className="my-2" />
      <div className="column gap-0-5">
        <OperationListItem
          label={getTranslation('STAKING_STAKED_CURRENCY')}
          value={getCurrencyLabelByCode(values.currencyAmount.currency, { withBrackets: true })}
          valueCurrencyCode={values.currencyAmount.currency}
        />
        <OperationListItem
          label={getTranslation('STAKING_REWARD_PAYOUT')}
          value={getTranslation('STAKING_PAYOUT_INTERVAL')}
        />
        <OperationListItem
          label={getTranslation('STAKING_STAKED_BALANCE')}
          value={formatCurrencyWithLabel(
            currentStakingWallet.amount,
            values.currencyAmount.currency,
          )}
          valueCurrencyCode={values.currencyAmount.currency}
        />
      </div>
      <LineDelimiter className="my-2" />
      <Field
        name="currencyAmount"
        component={CurrencyAmountField}
        placeholder={getTranslation('STAKING_ENTER_UNSTAKE_AMOUNT')}
        label={getTranslation('STAKING_PLACEHOLDER_AMOUNT_TO_UNSTAKE')}
        showBalance
        showMaxAmountButton
        currenciesList={currenciesList}
        underComponent={defaultCurrencyCalculatorComponent}
      />
      <SubmitButton className="mt-2" fullWidth variant="lightGreen">
        {getTranslation('CONTINUE')}
      </SubmitButton>

      <AgreementText variant="renting-and-staking" className="mt-2" />
    </form>
  );
};

const Unstake: FC<UnstakeProps> = ({ currencyCode }) => {
  const sidebar = useSideBar();
  const dispatch = useDispatch();

  const templatesReducer = useSelector(selectStakingTemplatesReducer);
  const stakingItems = useSelector(selectStakingItems);

  const currenciesList = useMemo<CurrencyPickerItem[]>(
    () =>
      stakingItems.map((i) => ({
        amount: i.amount,
        currencyCode: i.currencyCode,
        amountInDefaultCurrency: i.amountInDefaultCurrency,
        defaultCurrencyCode: i.defaultCurrency,
      })),
    [stakingItems],
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      currencyAmount: { currency: currencyCode, amount: '' },
    }),
    [currencyCode],
  );

  useEffect(() => {
    if (canRequest(templatesReducer.meta)) {
      dispatch(requestStakingTemplates());
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const template = templatesReducer.data.find(
        (t) => t.currencyCode === values.currencyAmount.currency,
      );
      if (!template) {
        return;
      }
      sidebar.open(
        ...sidebarTemplates.stakingOperationConfirm({
          onConfirm: async () => {
            const { success } = await dispatch(
              requestUnstake({ amount: +values.currencyAmount.amount, templateId: template.id }),
            );
            if (success) {
              sidebar.replaceAll(
                ...sidebarTemplates.stakingSuccessOperation({
                  amount: +values.currencyAmount.amount,
                  currencyCode: values.currencyAmount.currency,
                  percentRPY: template?.percentRPY || 0,
                  title: getTranslation('STAKING_UNSTAKE_SUCCESS_TITLE'),
                  subtitle: getTranslation('STAKING_UNSTAKE_SUCCESS_SUBTITLE', {
                    currencyCode: values.currencyAmount.currency,
                  }),
                }),
              );
            }
          },
          currencyCode: values.currencyAmount.currency,
          checkList: [
            {
              label: getTranslation('STAKING_UNSTAKE_AMOUNT'),
              value: formatCurrencyWithLabel(
                values.currencyAmount.amount,
                values.currencyAmount.currency,
              ),
              valueCurrencyCode: values.currencyAmount.currency,
            },
          ],
        }),
      );
    },
    [templatesReducer.data, sidebar, dispatch],
  );

  const validate = useCallback(
    (values: FormValues) => {
      const currentStaking = stakingItems.find(
        (s) => s.currencyCode === values.currencyAmount.currency,
      );
      const schema = yup.object().shape({
        currencyAmount: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .positive()
            .required(getTranslation('VALIDATION_REQUIRED'))
            .min(
              MINIMUM_AMOUNT_CRYPTO,
              getTranslation('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(
                  MINIMUM_AMOUNT_CRYPTO,
                  values.currencyAmount.currency,
                ),
              }),
            )
            .max(
              +formatCurrency(currentStaking?.amount || 0, false),
              getTranslation('VALIDATION_INSUFFICIENT_FUNDS'),
            )
            .nullable(true),
        }),
      });
      return makeValidate(schema)(values);
    },
    [stakingItems],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      // @ts-ignore
      render={UnstakeForm}
      currenciesList={currenciesList}
      validate={validate}
    />
  );
};

export default Unstake;
