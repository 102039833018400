import { FC, useCallback, useMemo } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import useDrawer from 'modules/app/hooks/useDrawer';
import loanDrawerTemplates from 'modules/loans/constants/drawerTemplates';
import { LoanItem } from 'modules/loans/types';

import { Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { ThemeColor, getThemeColor } from 'styles';

import classes from './LiquidationRiskReduced.module.scss';

export interface LiquidationRiskReducedProps {
  loan: LoanItem;
}

type RiskLevel = 'average' | 'danger' | 'healthy';

const colorByRiskLevel: { [key in RiskLevel]: ThemeColor } = {
  average: 'yellow',
  danger: 'red',
  healthy: 'green',
};

const LiquidationRiskReduced: FC<LiquidationRiskReducedProps> = ({ loan }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const loanIsHealthy = loan.liquidationRiskPercent <= 25;

  const riskLevel = useMemo<RiskLevel>(() => {
    if (loan.liquidationRiskPercent >= 75) {
      return 'danger';
    }
    if (loan.liquidationRiskPercent >= 50) {
      return 'average';
    }
    return 'healthy';
  }, [loan.liquidationRiskPercent]);

  const handleAddCollateral = useCallback(() => {
    drawer.replace(loanDrawerTemplates.loanAddCollateral({ loan }));
  }, [drawer, loan]);

  return (
    <div className="column flex-1 gap-6">
      <p
        className="fons-s grey-400"
        dangerouslySetInnerHTML={{
          __html: translate(
            loanIsHealthy
              ? 'LOANS_ADD_COLLATERAL_HEALTHY_DESC'
              : 'LOANS_ADD_COLLATERAL_BELOW_MIN_DESC',
            {
              amountLabel: formatCurrencyWithSymbol(
                loan.collateralMinDepositAmount || 0,
                loan.collateralCurrency,
                { round: 'up' },
              ),
            },
          ),
        }}
      />
      <div className="column aic relative jcc flex-1">
        <CircularProgressbar
          className={classes.circle}
          value={loan.liquidationRiskPercent}
          strokeWidth={10}
          styles={buildStyles({
            pathColor: getThemeColor(colorByRiskLevel[riskLevel]),
            trailColor: getThemeColor('westar'),
          })}
        />
        <span className={classes.percentLabel}>{loan.liquidationRiskPercent.toString() + '%'}</span>
      </div>

      <div className="column gap-2">
        <Button
          fullWidth
          onClick={drawer.close}
          variant={loanIsHealthy ? 'greyishGreen' : 'lightGreen'}
        >
          {translate('CLOSE')}
        </Button>
        {!loanIsHealthy && (
          <Button fullWidth onClick={handleAddCollateral} variant="red">
            {translate('LOANS_ADD_COLLATERAL')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LiquidationRiskReduced;
