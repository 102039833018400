import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import useMaintenance from 'modules/app/hooks/useMaintenance';
import { selectMaintenanceData } from 'modules/app/store/selectors';
import { isReactNative } from 'modules/reactNative/utils';

import { Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { LINK_NEBEUS_PUBLIC_SITE } from 'utils/links';

import classes from './MaintenanceCheck.module.scss';

interface MaintenanceCheckProps {
  children: ReactElement;
}
const MaintenanceCheck: FC<MaintenanceCheckProps> = ({ children }) => {
  const translate = useTranslation();

  const maintenance = useSelector(selectMaintenanceData);

  useMaintenance();

  return maintenance.isActive ? (
    <div className={classes.root}>
      {!isReactNative && (
        <a href={LINK_NEBEUS_PUBLIC_SITE} className={classes.header}>
          <Image name="nebeusLogoWhite" type="svg" />
        </a>
      )}
      <div className={classes.body}>
        <Image name="nebeusLogoCircle3D" path="common" />
        <h1>{translate('MAINTENANCE_TITLE')}</h1>
        <p dangerouslySetInnerHTML={{ __html: translate('MAINTENANCE_SUBTITLE') }} />
      </div>
    </div>
  ) : (
    children
  );
};

export default MaintenanceCheck;
