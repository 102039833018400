import clsx from 'clsx';
import { random } from 'lodash';

import { FC, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectNotificationsReducer } from 'modules/user/store/selectors';
import {
  requestNotifications,
  requestReadAllNotifications,
  requestReadNotificationBatched,
} from 'modules/user/store/thunks';
import { Notification } from 'modules/user/types';
import { useDispatch } from 'store';

import { EntityView } from 'components/common';
import { ButtonGroup, DottedLine, DrawerHeader, Image, Loader, Skeleton } from 'components/ui';
import { ButtonGroupItem } from 'components/ui/ButtonGroup';

import useFlag from 'hooks/useFlag';
import useInfinityScrollSimplified from 'hooks/useInfinityScrollSimplified';

import { useTranslation } from 'libs/i18n';

import { formatDDMMYY, formatHHMM } from 'utils/date';

import classes from './Notifications.module.scss';
import generateTitleAndBodyForNotification from './generateTitleAndBodyForNotification';

const NotificationsListViewFallback = () => (
  <div className="column gap-3 px-2 flexScrollable">
    {new Array(20).fill(null).map((_, i) => (
      <Fragment key={i}>
        <div className="column gap-1-5">
          <div className="column gap-0-5">
            <Skeleton height={28} />
            <Skeleton height={[56, 84, 112][random(0, 2)]} />
          </div>
          <Skeleton height={16} width={117} />
        </div>
        <DottedLine />
      </Fragment>
    ))}
  </div>
);

const NoData = () => {
  const translate = useTranslation();

  return (
    <div className="column flex-1 jcc px-2">
      <Image name="cloud" path="common" className="w75 m-0-auto" />
      <span className="tac font-semibold">{translate('NOTIFICATIONS_NO_DATA')}</span>
    </div>
  );
};
interface NotificationsProps {}

const Notifications: FC<NotificationsProps> = () => {
  const translate = useTranslation();
  const dispatch = useDispatch();

  const isSystem = useFlag(true);

  const readAllLoading = useFlag(false);

  const buttons = useMemo<ButtonGroupItem[]>(
    () => [
      { id: '0', children: translate('NOTIFICATIONS_TYPE_SYSTEM'), onClick: isSystem.on },
      { id: '1', children: translate('NOTIFICATIONS_TYPE_ANNOUNCEMENT'), onClick: isSystem.off },
    ],
    [translate, isSystem],
  );

  const notificationsReducer = useSelector(selectNotificationsReducer);

  const fetchNotifications = useCallback(
    (reset?: boolean) => dispatch(requestNotifications(undefined, { reset, pageLimit: 20 })),
    [dispatch],
  );

  const { onInfinityScroll } = useInfinityScrollSimplified({
    loading: notificationsReducer.meta.loading,
    hasMore: notificationsReducer.meta.hasMore,
    loadMore: fetchNotifications,
  });

  const initialRequest = useCallback(() => fetchNotifications(true), [fetchNotifications]);

  useEffect(
    () => {
      initialRequest();
    },
    // eslint-disable-next-line
    [],
  );

  const readAllNotifications = useCallback(async () => {
    readAllLoading.on();
    await dispatch(requestReadAllNotifications());
    await initialRequest();
    readAllLoading.off();
  }, [readAllLoading, initialRequest, dispatch]);

  const readViewedNotificationsTimeoutId = useRef<number | null>(null);
  const notificationsIdsForRead = useRef<string[]>([]);

  const readViewedNotifications = useCallback(() => {
    dispatch(requestReadNotificationBatched(notificationsIdsForRead.current));
    notificationsIdsForRead.current = [];
    readViewedNotificationsTimeoutId.current = null;
  }, [dispatch]);

  const handleObserve = useCallback<IntersectionObserverCallback>(
    (entries) => {
      const intersectingNotificationsIds = entries
        .filter((e) => e.isIntersecting)
        .map((i) => i.target.id);

      if (intersectingNotificationsIds.length) {
        notificationsIdsForRead.current = [
          ...new Set([...notificationsIdsForRead.current, ...intersectingNotificationsIds]),
        ];

        if (!readViewedNotificationsTimeoutId.current) {
          readViewedNotificationsTimeoutId.current = window.setTimeout(
            readViewedNotifications,
            5000,
          );
        }
      }
    },
    [readViewedNotifications],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserve, {
      root: document.querySelector('.' + classes.notificationsList),
      rootMargin: '0px',
      threshold: 1.0,
    });
    const targets = Array.from(
      document.getElementsByClassName(`${classes.notification} ${classes.notRead}`),
    );
    targets.forEach((target) => {
      observer.observe(target);
    });

    return () => {
      targets.forEach((target) => {
        observer.unobserve(target);
      });
    };
    // eslint-disable-next-line
  }, [handleObserve, notificationsReducer.data]);

  const notificationsWithTexts = useMemo<(Notification & { title: string; body: string })[]>(
    () =>
      notificationsReducer.data
        .map((n) => {
          const notificationsTexts = generateTitleAndBodyForNotification(n, translate);
          if (!notificationsTexts) {
            return null;
          } else {
            return { ...n, ...notificationsTexts };
          }
        })
        .filter((i): i is Notification & { title: string; body: string } => !!i),
    [translate, notificationsReducer.data],
  );

  const notificationsListView = useMemo(
    () => (
      <div id="notificationsList" className={classes.notificationsList} onScroll={onInfinityScroll}>
        {notificationsWithTexts.map((n) => {
          return (
            <Fragment key={n.id}>
              <div
                key={n.id}
                className={clsx(classes.notification, !n.isRead && classes.notRead)}
                id={n.id}
              >
                <div className="row aifs gap-2 jcsb">
                  <span className={classes.title}>{n.title}</span>
                  <div className={classes.readStatus} />
                </div>
                <span className={classes.body}>{n.body}</span>
                <span className={classes.date}>
                  {formatDDMMYY(new Date(n.dateCreated))} • {formatHHMM(new Date(n.dateCreated))}
                </span>
              </div>
              <DottedLine />
            </Fragment>
          );
        })}
      </div>
    ),
    [notificationsWithTexts, onInfinityScroll],
  );

  return (
    <div className={classes.root}>
      <Loader overlap active={readAllLoading.state} />
      <div
        className={clsx(
          classes.loader,
          notificationsReducer.meta.loading && notificationsReducer.data.length && classes.active,
        )}
      >
        <Loader />
      </div>
      <div className={classes.fixedContent}>
        <DrawerHeader
          className="aic"
          title={translate('NOTIFICATIONS')}
          showCloseButton={false}
          showBackButton
          rightComponent={
            <span onClick={readAllNotifications} className="label cyanBlue pointer">
              {translate('NOTIFICATIONS_MARK_ALL_AS_READ')}
            </span>
          }
        />
        <ButtonGroup
          className={classes.buttons}
          activeButtonIndex={isSystem.state ? 0 : 1}
          buttons={buttons}
          variant="creamyGreenWithDots"
        />
      </div>
      <div className={clsx(classes.slider, isSystem.state && classes.system)}>
        <div className={classes.sliderWrapper}>
          <EntityView
            component={notificationsListView}
            request={fetchNotifications}
            reducer={notificationsReducer}
            loaderComponent={<NotificationsListViewFallback />}
            noDataComponent={<NoData />}
            errorCardClassName="px-2"
          />
          <div className={clsx(classes.beamer, !isSystem.state && classes.active)}>
            <iframe src="https://app.getbeamer.com/news?app_id=DrPXfVMI32638&language=en&api=true&filterByUrl=false" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
