import { FC, useCallback, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import usePaymentInit from 'modules/payment/hooks/usePaymentInit';
import { PaymentOperationId, PaymentOperationLimit } from 'modules/payment/types';
import { WalletBalanceCard } from 'modules/payment/views/components';
import { AmountForm } from 'modules/payment/views/components';
import { PaymentAmountFormValues } from 'modules/payment/views/components/AmountForm';
import useAmountFormValidation from 'modules/payment/views/components/AmountForm/hooks/useAmountFormValidation';

import { makeValidate } from 'libs/yup';

import { CurrencyCode } from 'types';

interface DepositApplePayProps {
  currencyCode: CurrencyCode;
}

interface FormValues extends PaymentAmountFormValues {}
interface DepositApplePayFormProps extends FormRenderProps<FormValues> {
  operationId: PaymentOperationId;
  operationLimit?: PaymentOperationLimit;
}

const DepositApplePayForm: FC<DepositApplePayFormProps> = ({ handleSubmit, operationId }) => {
  return (
    <form onSubmit={handleSubmit} className="mt-2">
      <AmountForm operationId={operationId} />
    </form>
  );
};

const DepositApplePay: FC<DepositApplePayProps> = ({ currencyCode }) => {
  usePaymentInit();

  const initialValues = useMemo<FormValues>(
    () => ({
      currencyAmount: { amount: '', currency: currencyCode },
      currencyAmountWithCommission: { amount: '', currency: currencyCode },
      commissionAmount: null,
    }),
    [currencyCode],
  );
  const handleSubmit = useCallback((values: FormValues) => {
    // eslint-disable-next-line no-console
    console.log(values);
  }, []);

  const { amountFormSchema } = useAmountFormValidation({
    currencyCode,
    operationId: PaymentOperationId.depositApplePay,
  });

  const validate = useMemo(() => makeValidate(amountFormSchema), [amountFormSchema]);

  return (
    <div className="mt-2">
      <WalletBalanceCard currencyCode={currencyCode} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        // @ts-ignore
        render={DepositApplePayForm}
        operationId={PaymentOperationId.depositApplePay}
      />
    </div>
  );
};

export default DepositApplePay;
