import { Notification } from 'modules/user/types';

import { TranslateFunc } from 'libs/i18n';

import { formatCurrencyWithLabel } from 'utils/currency';

import { CurrencyCode } from 'types';

const generateTitleAndBodyForNotification = (
  notification: Notification,
  translate: TranslateFunc,
): { title: string; body: string } | null => {
  switch (notification.type) {
    case 'MODULR_INCOME_TRANSACTION_WAS_FINISHED': {
      if ('amount' in notification.relatedEntity && 'currency' in notification.relatedEntity) {
        return {
          title: translate('DEPOSIT_FROM_SEPA_WIRE_TO_IBAN_WALLET_TITLE'),
          body: translate('DEPOSIT_FROM_SEPA_WIRE_TO_IBAN_WALLET_BODY', {
            amountLabel: formatCurrencyWithLabel(
              notification.relatedEntity.amount,
              notification.relatedEntity.currency as CurrencyCode,
            ),
          }),
        };
      }
      break;
    }
    case 'MODULR_OUTCOME_TRANSACTION_WAS_FINISHED': {
      if ('amount' in notification.relatedEntity && 'currency' in notification.relatedEntity) {
        return {
          title: translate('SEND_FROM_IBAN_WALLET_TO_SEPA_WIRE_TITLE'),
          body: translate('SEND_FROM_IBAN_WALLET_TO_SEPA_WIRE_BODY', {
            amountLabel: formatCurrencyWithLabel(
              notification.relatedEntity.amount,
              notification.relatedEntity.currency as CurrencyCode,
            ),
          }),
        };
      }
      break;
    }
    case 'BITGO_INCOME_TRANSACTION_WAS_FINISHED': {
      if ('amount' in notification.relatedEntity && 'currency' in notification.relatedEntity) {
        return {
          title: translate('DEPOSIT_FROM_EXTERNAL_WALLET_TO_CRYPTO_TRADING_TITLE'),
          body: translate('DEPOSIT_FROM_EXTERNAL_WALLET_TO_CRYPTO_TRADING_BODY', {
            amountLabel: formatCurrencyWithLabel(
              notification.relatedEntity.amount,
              notification.relatedEntity.currency as CurrencyCode,
            ),
          }),
        };
      }
      break;
    }
    case 'BITGO_OUTCOME_TRANSACTION_WAS_FINISHED': {
      if ('amount' in notification.relatedEntity && 'currencyCode' in notification.relatedEntity) {
        return {
          title: translate('SEND_FROM_TRADING_TO_EXTERNAL_CRYPTO_WALLET_TITLE'),
          body: translate('SEND_FROM_TRADING_TO_EXTERNAL_CRYPTO_WALLET_BODY', {
            amountLabel: formatCurrencyWithLabel(
              notification.relatedEntity.amount,
              notification.relatedEntity.currencyCode as CurrencyCode,
            ),
          }),
        };
      }
      break;
    }
    case 'CRYPTO_CARD_PURCHASE_BY_CARD_WAS_INITIATED': {
      if ('merchantName' in notification.relatedEntity) {
        return {
          title: translate('CRYPTO_CARD_PURCHASE_TITLE', {
            amountLabel: formatCurrencyWithLabel(
              notification.relatedEntity.fullPaymentAmount,
              notification.relatedEntity.paymentCurrencyCode as CurrencyCode,
            ),
          }),
          body: translate('CRYPTO_CARD_PURCHASE_BODY', {
            merchant: notification.relatedEntity.merchantName,
          }),
        };
      }
      break;
    }
    case 'CRYPTO_CARD_CASH_WITHDRAWAL_WAS_INITIATED': {
      if (
        'fullPaymentAmount' in notification.relatedEntity &&
        'paymentCurrencyCode' in notification.relatedEntity
      ) {
        return {
          title: translate('CRYPTO_CARD_ATM_WITHDRAWAL_TITLE'),
          body: translate('CRYPTO_CARD_ATM_WITHDRAWAL_BODY', {
            amountLabel: formatCurrencyWithLabel(
              notification.relatedEntity.fullPaymentAmount,
              notification.relatedEntity.paymentCurrencyCode as CurrencyCode,
            ),
          }),
        };
      }
      break;
    }
    case 'CRYPTO_CARD_WAS_ACTIVATED': {
      return {
        title: translate('CRYPTO_CARD_ACTIVATED_TITLE'),
        body: translate('CRYPTO_CARD_ACTIVATED_BODY'),
      };
    }
    case 'MARKETING_PROGRAM_MESSAGE_WAS_RECEIVED': {
      if ('name' in notification.relatedEntity) {
        switch (notification.relatedEntity.name) {
          case 'CRYPTO_CARD_CURVE_APP_ADVERTISEMENT': {
            return {
              title: translate('NOTIFICATION_MARKETING_CURVE_TITLE'),
              body: translate('NOTIFICATION_MARKETING_CURVE_BODY'),
            };
          }
          default: {
            return {
              title: notification.relatedEntity.messageTitle,
              body: notification.relatedEntity.messageBody,
            };
          }
        }
      }

      break;
    }
    case 'TRADING_TRANSACTION_WAS_FINISHED': {
      if (
        'type' in notification.relatedEntity &&
        'amountWithCommission' in notification.relatedEntity
      ) {
        const amountLabel = formatCurrencyWithLabel(
          notification.relatedEntity.amountWithCommission,
          notification.relatedEntity.currency as CurrencyCode,
        );
        switch (notification.relatedEntity.type) {
          case 'FROM_TRADING_TO_TRADING': {
            return {
              title: translate('SEND_TO_NEBEUS_USER_TITLE'),
              body: translate('SEND_TO_NEBEUS_USER_BODY', {
                amountLabel,
              }),
            };
          }
          case 'FROM_TRADING_TO_MONEY': {
            return {
              title: translate('SEND_FROM_TRADING_TO_MONEY_TITLE'),
              body: translate('SEND_FROM_TRADING_TO_MONEY_BODY', {
                amountLabel,
              }),
            };
          }
          case 'FROM_MONEY_TO_TRADING': {
            return {
              title: translate('SEND_FROM_MONEY_TO_TRADING_TITLE'),
              body: translate('SEND_FROM_MONEY_TO_TRADING_BODY', {
                amountLabel,
              }),
            };
          }
        }
      }

      break;
    }
    case 'WIRE_TRANSACTION_WAS_FINISHED': {
      if (
        'type' in notification.relatedEntity &&
        'currencyCode' in notification.relatedEntity &&
        'amount' in notification.relatedEntity
      ) {
        const amountLabel = formatCurrencyWithLabel(
          notification.relatedEntity.amount,
          notification.relatedEntity.currencyCode as CurrencyCode,
        );
        switch (notification.relatedEntity.type) {
          case 'TOP_UP_BY_ADMIN': {
            return {
              title: translate('DEPOSIT_FROM_SEPA_WIRE_TO_TRADING_TITLE'),
              body: translate(
                notification.relatedEntity.currencyCode === 'EUR'
                  ? 'DEPOSIT_FROM_SEPA_TO_TRADING_BODY'
                  : 'DEPOSIT_FROM_WIRE_TO_TRADING_BODY',
                { amountLabel },
              ),
            };
          }
          case 'WITHDRAWAL_BY_ADMIN': {
            return {
              title: translate('SEND_FROM_TRADING_TO_SEPA_WIRE_TITLE'),
              body: translate(
                notification.relatedEntity.currencyCode === 'EUR'
                  ? 'SEND_FROM_TRADING_TO_SEPA_BODY'
                  : 'SEND_FROM_TRADING_TO_WIRE_BODY',
                { amountLabel },
              ),
            };
          }
          case 'WITHDRAWAL_BY_USER': {
            return {
              title: translate('SEND_FROM_TRADING_TO_SEPA_WIRE_TITLE'),
              body: translate(
                notification.relatedEntity.currencyCode === 'EUR'
                  ? 'SEND_FROM_TRADING_TO_SEPA_BODY'
                  : 'SEND_FROM_TRADING_TO_WIRE_BODY',
                { amountLabel },
              ),
            };
          }
        }
      }
      break;
    }
  }

  // eslint-disable-next-line no-console
  console.log('Notification texts building error', notification);

  return null;
};

export default generateTitleAndBodyForNotification;
