import clsx from 'clsx';

import { FC } from 'react';

import { TemplateName } from 'modules/cryptoRenting/types';

import { Image } from 'components/ui';

import { Size } from 'types';

import classes from './TemplateImage.module.scss';

interface TemplateImageProps {
  templateName: TemplateName;
  size?: Size;
  className?: string;
}

export const TemplateImage: FC<TemplateImageProps> = ({ size = 'md', templateName, className }) => {
  return (
    <div className={clsx(classes.root, classes[size], classes[templateName], className)}>
      <Image
        className={classes.templateImage}
        name={templateName.toLowerCase()}
        path="cryptoRenting/templatesIcons"
      />
    </div>
  );
};
