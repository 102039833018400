import clsx from 'clsx';

import { AccountStatementProps } from 'modules/accounts/views/AccountStatement';
import { SideBarProps, SideBarStackItem, SideBarStackItemKey } from 'modules/app/types';
import { ConfirmOTPCodeProps } from 'modules/app/views/ConfirmOTPCode';
import { SelectCurrencyProps } from 'modules/app/views/SelectCurrency';
import { CancelSubscriptionProps } from 'modules/digitalAccount/views/CancelSubscription';
import { CancelSubscriptionSuccessProps } from 'modules/digitalAccount/views/CancelSubscriptionSuccess';
import { ChooseSubscriptionProps } from 'modules/digitalAccount/views/ChooseSubscription';
import { GetSubscriptionProps } from 'modules/digitalAccount/views/GetSubscription';
import { GetSubscriptionConfirmProps } from 'modules/digitalAccount/views/GetSubscriptionConfirm';
import { GetSubscriptionSuccessProps } from 'modules/digitalAccount/views/GetSubscriptionSuccess';
import { SubscriptionManagementProps } from 'modules/digitalAccount/views/SubscriptionManagement';
import { SubscriptionRequestedProps } from 'modules/digitalAccount/views/SubscriptionRequested';
import { SubscriptionVerificationPendingProps } from 'modules/digitalAccount/views/SubscriptionVerificationPending';
import { selectPaymentOperationsLimits } from 'modules/payment/store/selectors';
import { PaymentOperationId } from 'modules/payment/types';
import { AccountDetailsProps } from 'modules/payment/views/AccountDetails';
import { AddEditSavedCryptoWalletProps } from 'modules/payment/views/AddEditSavedCryptoWallet';
import { DepositByDetailsProps } from 'modules/payment/views/DepositByDetails';
import { SendSEPAProps } from 'modules/payment/views/SendSEPA';
import { SendToDAOrCAProps } from 'modules/payment/views/SendToDAOrCA';
import { SendWireProps } from 'modules/payment/views/SendWire';
import { isReactNative } from 'modules/reactNative/utils';
import { SmartTraderActivatedProps } from 'modules/smartTrader/views/SmartTraderActivated';
import { SmartTraderConfigureTradeProps } from 'modules/smartTrader/views/SmartTraderConfigureTrade';
import { SmartTraderExchangeProps } from 'modules/smartTrader/views/SmartTraderExchange';
import { SmartTraderExchangeSuccessProps } from 'modules/smartTrader/views/SmartTraderExchangeSuccess';
import { SmartTraderInsufficientFundsProps } from 'modules/smartTrader/views/SmartTraderInsufficientFunds';
import { SmartTraderPickAssetsProps } from 'modules/smartTrader/views/SmartTraderPickAssets';
import { SmartTraderSelectTradingPairProps } from 'modules/smartTrader/views/SmartTraderSelectTradingPair';
import { SmartTraderSummaryConfirmationProps } from 'modules/smartTrader/views/SmartTraderSummaryConfirmation';
import { SmartTraderWelcomeProps } from 'modules/smartTrader/views/SmartTraderWelcome';
import { StakingOperationConfirmProps } from 'modules/staking/views/StakingOperationConfirm';
import { StakingSetupProps } from 'modules/staking/views/StakingSetup';
import { StakingSuccessOperationProps } from 'modules/staking/views/StakingSuccessOperation';
import { UnstakeProps } from 'modules/staking/views/Unstake';
import { VerificationIDProps } from 'modules/user/views/Settings/components/Verification/components/VerificationID';
import store from 'store';

import { AnswerSidebarProps } from 'components/common/AnswerSidebar';
import { DescriptionListCarouselSidebarProps } from 'components/common/DescriptionListCarouselSidebar';
import { DynamicContentSidebarItemProps } from 'components/common/DynamicContentSidebarItem';
import { SelectSidebarProps } from 'components/common/SelectSidebar';

import { getTranslation } from 'libs/i18n';

import {
  MINIMUM_AMOUNT_FIAT,
  formatCurrencyWithSymbol,
  getCurrencyLabelByCode,
  isFiat,
} from 'utils/currency';

import { CryptoCurrencyCode, CurrencyCode, FiatCurrencyCode } from 'types';

import sidebarScreensClasses from './SidebarScreens.module.scss';

const paymentProcess = (
  {
    isDeposit = true,
    isDigitalAccount = false,
    currencyCode,
  }: {
    isDeposit?: boolean;
    isDigitalAccount?: boolean;
    currencyCode?: CurrencyCode;
  } = {
    isDeposit: true,
    isDigitalAccount: false,
  },
): [SideBarStackItemKey, any] =>
  currencyCode
    ? isDeposit && !isFiat(currencyCode)
      ? [
          'paymentDepositCrypto',
          {
            props: { currencyCode },
            sideBarProps: {
              swipeModal: true,
              navigationHeaderLabel:
                getTranslation('DEPOSIT') + ` ${getCurrencyLabelByCode(currencyCode)}`,
            },
          },
        ]
      : [
          'paymentPickOperation',
          {
            props: { isDeposit, isDigitalAccount, currencyCode },
            sideBarProps: {
              navigationHeaderLabel:
                getTranslation(isDeposit ? 'DEPOSIT' : 'SEND') +
                ` ${getCurrencyLabelByCode(currencyCode)}`,
            },
          },
        ]
    : [
        'paymentPickCurrency',
        {
          props: { isDeposit, isDigitalAccount },
          sideBarProps: {
            withoutBottomPadding: true,
            navigationHeaderLabel: getTranslation(
              isDeposit ? 'PAYMENT_DEPOSIT_FUNDS' : 'PAYMENT_SEND_FUNDS',
            ),
          },
        },
      ];

const paymentOperationApplePay = (
  currencyCode: CurrencyCode,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => {
  const limits = selectPaymentOperationsLimits(store.getState());

  const applePayLimits = limits.find(
    (l) => l.operationId === PaymentOperationId.depositApplePay && l.currencyCode === currencyCode,
  );

  return [
    'paymentDepositApplePay',
    {
      props: { currencyCode },
      sideBarProps: {
        navigationHeaderLabel: getTranslation('PAYMENT_APPLE_PAY'),
        navigationHeaderProps: {
          className: 'jcsb',
          infoIcon: {
            title: getTranslation('PAYMENT_APPLE_PAY'),
            description: getTranslation('PAYMENT_APPLE_PAY_FAQ_DESCRIPTION'),
            stepsConfig: {
              title: getTranslation('PAYMENT_APPLE_PAY_FAQ_STEPS_TITLE'),
              steps: [
                getTranslation('PAYMENT_APPLE_PAY_FAQ_STEPS_1'),
                getTranslation('PAYMENT_APPLE_PAY_FAQ_STEPS_2'),
                getTranslation('PAYMENT_APPLE_PAY_FAQ_STEPS_3', {
                  minAmount: formatCurrencyWithSymbol(
                    applePayLimits?.min || MINIMUM_AMOUNT_FIAT,
                    currencyCode,
                  ),
                  maxAmount: formatCurrencyWithSymbol(applePayLimits?.max || 10000, currencyCode),
                }),
              ],
            },
          },
        },
      },
    },
  ];
};

const paymentOperationSendExternalWallet = (
  currencyCode: CryptoCurrencyCode,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'paymentSendExternalWallet',
  {
    props: { currencyCode },
    sideBarProps: {
      navigationHeaderLabel: getTranslation('PAYMENT_TO_EXTERNAL_WALLET'),
      navigationHeaderProps: {
        className: 'capitalize jcsb',
        infoIcon: {
          title: getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_TITLE'),
          description: getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_DESCRIPTION'),
          stepsConfig: {
            title: getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEPS_TITLE'),
            steps: [
              getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEP_1'),
              getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEP_2'),
            ],
          },
        },
      },
    },
  },
];
const paymentOperationDepositSendBankCard = (
  currencyCode: CurrencyCode,
  { isDeposit }: { isDeposit: boolean },
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'paymentDepositSendBankCard',
  {
    props: {
      currencyCode,
      isDeposit,
      operationId: isDeposit
        ? PaymentOperationId.depositFromBankCardToTradingWallet
        : PaymentOperationId.sendFromTradingWalletToBankCard,
    },
    sideBarProps: {
      navigationHeaderLabel: getTranslation('PAYMENT_BANK_CARD'),
      navigationHeaderProps: {
        className: 'jcsb',
        infoIcon: {
          title: getTranslation(`PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_TITLE`),
          description: getTranslation(
            `PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_DESCRIPTION`,
          ),
          stepsConfig: {
            title: getTranslation(
              `PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_STEPS_TITLE`,
            ),
            // @ts-ignore
            steps: [
              getTranslation(`PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_STEP_1`),
              getTranslation(`PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_STEP_2`),
              getTranslation(`PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_STEP_3`),
              isDeposit ? getTranslation(`PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_4`) : null,
            ].filter((i) => i !== null),
          },
        },
      },
    },
  },
];
const paymentSendNebeusUser = (
  currencyCode: CurrencyCode,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'paymentSendNebeusUser',
  {
    props: {
      currencyCode,
    },
    sideBarProps: {
      navigationHeaderLabel: getTranslation('PAYMENT_NU_NAVIGATION_TITLE'),
      navigationHeaderProps: {
        className: 'jcsb',
        infoIcon: {
          title: getTranslation('PAYMENT_NU_FAQ_TITLE'),
          description: getTranslation(`PAYMENT_NU_FAQ_DESCRIPTION`),
          stepsConfig: {
            title: getTranslation('PAYMENT_NU_FAQ_STEPS_TITLE'),
            // @ts-ignore
            steps: [
              getTranslation('PAYMENT_NU_FAQ_STEP_1'),
              getTranslation('PAYMENT_NU_FAQ_STEP_2'),
              getTranslation('PAYMENT_NU_FAQ_STEP_3'),
            ],
          },
        },
      },
    },
  },
];
const paymentOperationDepositByDetails = (
  operationId: PaymentOperationId,
  currencyCode: CurrencyCode,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'paymentDepositByDetails',
  {
    props: {
      operationId,
      currencyCode,
    } as DepositByDetailsProps,
    sideBarProps: {
      navigationHeaderLabel: getTranslation(
        operationId === PaymentOperationId.depositFromWireToMoneyWallet && currencyCode === 'GBP'
          ? 'PAYMENT_BANK_TRANSFER'
          : (operationId === PaymentOperationId.depositFromSepaToTradingWallet &&
              currencyCode === 'EUR') ||
            operationId === PaymentOperationId.depositFromSepaToMoneyWallet
          ? 'PAYMENT_BANK_TRANSFER_SEPA'
          : 'PAYMENT_BANK_TRANSFER_WIRE',
      ),
      navigationHeaderProps: {
        className: 'jcsb',
        // @ts-ignore
        infoIcon:
          operationId === PaymentOperationId.depositFromWireToTradingWallet
            ? undefined
            : {
                title: getTranslation(
                  operationId === PaymentOperationId.depositFromWireToMoneyWallet
                    ? 'PAYMENT_DEPOSIT_WIRE_FAQ_TITLE'
                    : 'PAYMENT_DEPOSIT_SEPA_FAQ_TITLE',
                ),
                description:
                  operationId === PaymentOperationId.depositFromWireToMoneyWallet
                    ? ''
                    : getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_DESC'),
                stepsConfig: {
                  title: getTranslation(
                    operationId === PaymentOperationId.depositFromWireToMoneyWallet
                      ? 'PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_TITLE'
                      : 'PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_TITLE',
                  ),
                  steps: [
                    operationId === PaymentOperationId.depositFromSepaToMoneyWallet
                      ? undefined
                      : getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_1'),
                    getTranslation(
                      operationId === PaymentOperationId.depositFromWireToMoneyWallet
                        ? 'PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_1'
                        : 'PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_2',
                    ),
                    getTranslation(
                      operationId === PaymentOperationId.depositFromWireToMoneyWallet
                        ? 'PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_2'
                        : 'PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_3',
                    ),
                  ].filter((i) => !!i),
                },
              },
      },
    },
  },
];
const paymentOperationSendSEPA = (
  isDigitalAccount: boolean,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'paymentSendSEPA',
  {
    props: {
      isDigitalAccount,
    } as SendSEPAProps,
    sideBarProps: {
      navigationHeaderLabel: getTranslation('PAYMENT_BANK_TRANSFER_SEPA'),
      navigationHeaderProps: {
        className: 'jcsb',
        // @ts-ignore
        infoIcon: {
          title: getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_TITLE'),
          description: getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_DESC'),
          stepsConfig: {
            title: getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_TITLE'),
            steps: [
              getTranslation('PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_1'),
              getTranslation('PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_2'),
              getTranslation('PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_3'),
            ],
          },
        },
      },
    },
  },
];
const paymentOperationSendWire = (
  isDigitalAccount: boolean,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'paymentSendWire',
  {
    props: {
      isDigitalAccount,
    } as SendWireProps,
    sideBarProps: {
      navigationHeaderLabel: getTranslation(
        isDigitalAccount ? 'PAYMENT_BANK_TRANSFER' : 'PAYMENT_BANK_TRANSFER_WIRE',
      ),
      navigationHeaderProps: {
        className: 'jcsb',
        // @ts-ignore
        infoIcon: {
          title: getTranslation(
            isDigitalAccount ? 'PAYMENT_DEPOSIT_WIRE_FAQ_TITLE' : 'PAYMENT_SEND_WIRE_FAQ_TITLE',
          ),
          description: getTranslation(
            isDigitalAccount ? 'PAYMENT_SEND_WIRE_DA_FAQ_DESC' : 'PAYMENT_SEND_WIRE_FAQ_DESC',
          ),
          stepsConfig: {
            title: getTranslation(
              isDigitalAccount
                ? 'PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_TITLE'
                : 'PAYMENT_SEND_WIRE_FAQ_STEPS_TITLE',
            ),
            steps: [
              getTranslation(
                isDigitalAccount
                  ? 'PAYMENT_SEND_WIRE_DA_FAQ_STEPS_1'
                  : 'PAYMENT_SEND_WIRE_FAQ_STEPS_1',
              ),
              getTranslation(
                isDigitalAccount
                  ? 'PAYMENT_SEND_WIRE_DA_FAQ_STEPS_2'
                  : 'PAYMENT_SEND_WIRE_FAQ_STEPS_2',
              ),
              getTranslation('PAYMENT_SEND_WIRE_FAQ_STEPS_3'),
            ],
          },
        },
      },
    },
  },
];
const paymentOperationSendToDAOrCA = (
  isDigitalAccount: boolean,
  currencyCode: FiatCurrencyCode,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'paymentSendToDAOrCA',
  {
    props: {
      isDigitalAccount,
      currencyCode,
    } as SendToDAOrCAProps,
    sideBarProps: {
      navigationHeaderLabel: getTranslation(
        isDigitalAccount ? 'PAYMENT_NEBEUS_TO_CRYPTO_ACCOUNT' : 'PAYMENT_NEBEUS_TO_MONEY_ACCOUNT',
      ),
      navigationHeaderProps: {
        className: 'jcsb',
        // @ts-ignore
        infoIcon: {
          title: getTranslation(
            isDigitalAccount ? 'PAYMENT_SEND_TO_CA_FAQ_TITLE' : 'PAYMENT_SEND_TO_DA_FAQ_TITLE',
          ),
          stepsConfig: {
            title: getTranslation('PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_TITLE'),
            steps: [
              getTranslation('PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_1'),
              getTranslation('PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_2'),
              getTranslation('PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_3'),
            ],
          },
        },
      },
    },
  },
];

const accountDetails = (
  props: AccountDetailsProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'accountDetails',
  {
    props,
    sideBarProps: {
      navigationHeaderLabel: getTranslation('ACCOUNT_DETAILS'),
      navigationHeaderProps: { hideBackButton: true },
      swipeModal: true,
    },
  },
];
const accountStatement = (
  props: AccountStatementProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'accountStatement',
  {
    props,
    sideBarProps: {
      swipeModal: true,
    },
  },
];

const depositByDetailsOperationCodes = [
  PaymentOperationId.depositFromSepaToTradingWallet,
  PaymentOperationId.depositFromWireToTradingWallet,
  PaymentOperationId.depositFromSepaToMoneyWallet,
  PaymentOperationId.depositFromWireToMoneyWallet,
];

const paymentOperation = ({
  operationId,
  currencyCode,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isDigitalAccount = false,
}: {
  operationId: PaymentOperationId;
  currencyCode: CurrencyCode;
  isDigitalAccount?: boolean;
}): [SideBarStackItemKey, Partial<SideBarStackItem>] | null => {
  if (depositByDetailsOperationCodes.includes(operationId)) {
    return paymentOperationDepositByDetails(operationId, currencyCode);
  }

  switch (operationId) {
    case PaymentOperationId.depositApplePay: {
      return paymentOperationApplePay(currencyCode);
    }
    case PaymentOperationId.depositFromBankCardToTradingWallet: {
      return paymentOperationDepositSendBankCard(currencyCode, { isDeposit: true });
    }
    case PaymentOperationId.sendFromTradingWalletToBankCard: {
      return paymentOperationDepositSendBankCard(currencyCode, { isDeposit: false });
    }
    case PaymentOperationId.sendToNebeusUser: {
      return paymentSendNebeusUser(currencyCode);
    }
    case PaymentOperationId.sendCryptoToExternalWallet: {
      return isFiat(currencyCode)
        ? null
        : paymentOperationSendExternalWallet(currencyCode as CryptoCurrencyCode);
    }
    // @ts-ignore
    case PaymentOperationId.sendFromTradingToSepa: {
      if (currencyCode === 'EUR') {
        return paymentOperationSendSEPA(false);
      }
    }
    // @ts-ignore
    // eslint-disable-next-line no-fallthrough
    case PaymentOperationId.sendFromTradingToWire: {
      if (currencyCode === 'GBP') {
        return paymentOperationSendWire(false);
      }
    }
    // eslint-disable-next-line no-fallthrough
    case PaymentOperationId.sendFromMoneyToSepa: {
      return paymentOperationSendSEPA(true);
    }
    case PaymentOperationId.sendFromMoneyToWire: {
      return paymentOperationSendWire(true);
    }
    case PaymentOperationId.sendFromTradingToMoney: {
      return paymentOperationSendToDAOrCA(isDigitalAccount, currencyCode as FiatCurrencyCode);
    }
    case PaymentOperationId.sendFromMoneyToTrading: {
      return paymentOperationSendToDAOrCA(isDigitalAccount, currencyCode as FiatCurrencyCode);
    }

    default: {
      return null;
    }
  }
};

const confirmOTPCode = (
  props: ConfirmOTPCodeProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'confirmOTPCode',
  {
    sideBarProps: { navigationHeaderLabel: getTranslation('CONFIRMATION') },
    props,
  },
];

const dynamicContent = (
  props: DynamicContentSidebarItemProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'dynamicContent',
  { props, sideBarProps: { showBackButton: false, swipeModal: true } },
];

const stakingSetup = (
  props: StakingSetupProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'stakingSetup',
  { props, sideBarProps: { variant: 'white', swipeModal: true } },
];
const stakingOperationConfirm = (
  props: StakingOperationConfirmProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'stakingOperationConfirm',
  { props, sideBarProps: { variant: 'white', swipeModal: true } },
];
const stakingSuccessOperation = (
  props: StakingSuccessOperationProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'stakingSuccessOperation',
  {
    props,
    sideBarProps: {
      variant: 'white',
      contentClassName: sidebarScreensClasses['stakingSuccessSideBar-' + props.currencyCode],
    },
  },
];
const unstake = (props: UnstakeProps): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'unstake',
  {
    props,
    sideBarProps: {
      variant: 'white',
      swipeModal: true,
    },
  },
];

const selectCurrency = (
  props: SelectCurrencyProps,
  sideBarProps: Partial<SideBarProps> = {},
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'selectCurrency',
  { props, sideBarProps: { swipeModal: true, variant: 'creamy', ...sideBarProps } },
];

const descriptionListCarouselSidebar = (
  props: DescriptionListCarouselSidebarProps,
  sideBarProps: SideBarProps = {},
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'descriptionListCarouselSidebar',
  {
    props,
    sideBarProps: {
      navigationVariant: 'secondary',
      variant: 'white',
      ...sideBarProps,
    },
  },
];

const answer = (props: AnswerSidebarProps): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'answer',
  { props, sideBarProps: { swipeModal: true } },
];
const select = <T>(
  props: SelectSidebarProps<T>,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'select',
  { props, sideBarProps: { swipeModal: true } },
];

const digitalAccountChooseSubscription = (
  props?: ChooseSubscriptionProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'digitalAccountChooseSubscription',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.digitalAccountChooseSubscription,
      showBackButton: false,
      navigationVariant: 'secondary',
      secondaryNavigationProps: { variant: 'light' },
    },
  },
];
const digitalAccountGetSubscription = (
  props: GetSubscriptionProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'digitalAccountGetSubscription',
  {
    props,
    sideBarProps: {
      showBackButton: false,
      navigationVariant: 'secondary',
      secondaryNavigationProps: { variant: 'light' },
      contentClassName: sidebarScreensClasses['digitalAccountSubLayout-' + props.subscription.type],
    },
  },
];
const digitalAccountDowngradeSubscriptionToStandard = (): [
  SideBarStackItemKey,
  Partial<SideBarStackItem>,
] => [
  'downgradeSubscriptionToStandard',
  {
    sideBarProps: {
      contentClassName: sidebarScreensClasses.getBankCard,
      navigationVariant: 'secondary',
      showBackButton: true,
    },
  },
];
const digitalAccountGetSubscriptionConfirm = (
  props: GetSubscriptionConfirmProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'digitalAccountGetSubscriptionConfirm',
  {
    props,
    sideBarProps: {
      showBackButton: false,
      navigationVariant: 'secondary',
      secondaryNavigationProps: { variant: 'light' },
      contentClassName: sidebarScreensClasses['digitalAccountSubLayout-' + props.subscription.type],
    },
  },
];

const bankCardAdvertisement = (): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'bankCardAdvertisement',
  {
    sideBarProps: {
      contentClassName: clsx(
        sidebarScreensClasses.bankCardAdvertisement,
        isReactNative && 'reactNative',
      ),
      backButtonVariant: 'outlinedWhite',
    },
  },
];
const digitalAccountGetSubscriptionSuccess = (
  props: GetSubscriptionSuccessProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'digitalAccountGetSubscriptionSuccess',
  {
    props,
    sideBarProps: {
      // onHide: () => {
      //   const userHasBankCards = selectIsUserHasBankCard(store.getState());
      //
      //   const template = bankCardAdvertisement();
      //   if (!userHasBankCards) {
      //     store.dispatch(appActions.pushToSideBar({ key: template[0], ...template[1] }));
      //   }
      // },
      showBackButton: false,
      navigationVariant: 'secondary',
      secondaryNavigationProps: { variant: 'light' },
      contentClassName: sidebarScreensClasses['digitalAccountSubLayout-' + props.subscription.type],
    },
  },
];
const digitalAccountSubscriptionVerificationPending = (
  props: SubscriptionVerificationPendingProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'digitalAccountSubscriptionVerificationPending',
  {
    props,
    sideBarProps: {
      showBackButton: false,
      navigationVariant: 'secondary',
      secondaryNavigationProps: { variant: 'light' },
      contentClassName: sidebarScreensClasses['digitalAccountSubLayout-' + props.subscriptionType],
    },
  },
];
const digitalAccountSubscriptionRequested = (
  props: SubscriptionRequestedProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'digitalAccountSubscriptionRequested',
  {
    props,
    sideBarProps: {
      showBackButton: false,
      navigationVariant: 'secondary',
      secondaryNavigationProps: { variant: 'light' },
      contentClassName: sidebarScreensClasses['digitalAccountSubLayout-' + props.subscription.type],
    },
  },
];
const digitalAccountSubscriptionManagement = (
  props: SubscriptionManagementProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'digitalAccountSubscriptionManagement',
  {
    props,
    sideBarProps: { swipeModal: true, variant: 'creamy' },
  },
];
const digitalAccountCancelSubscription = (
  props: CancelSubscriptionProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'digitalAccountCancelSubscription',
  {
    props,
    sideBarProps: {
      showBackButton: false,
      navigationVariant: 'secondary',
      secondaryNavigationProps: { variant: 'light' },
      contentClassName: sidebarScreensClasses['digitalAccountSubLayout-' + props.subscription.type],
    },
  },
];
const digitalAccountCancelSubscriptionSuccess = (
  props: CancelSubscriptionSuccessProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'digitalAccountCancelSubscriptionSuccess',
  {
    props,
    sideBarProps: {
      showBackButton: false,
      navigationVariant: 'secondary',
      secondaryNavigationProps: { variant: 'light' },
      contentClassName: sidebarScreensClasses['digitalAccountSubLayout-' + props.subscription.type],
    },
  },
];
const subscriptions = (): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'subscriptions',
  {
    sideBarProps: {
      swipeModal: true,
      variant: 'creamy',
      navigationHeaderLabel: getTranslation('SUBSCRIPTIONS'),
      navigationHeaderProps: { hideBackButton: true },
    },
  },
];
const verification = (): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'verification',
  {
    sideBarProps: {
      swipeModal: true,
      variant: 'creamy',
      navigationHeaderLabel: getTranslation('VERIFICATION'),
      navigationHeaderProps: {
        hideBackButton: true,
      },
    },
  },
];
const verificationID = (
  props?: VerificationIDProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'verificationID',
  {
    props,
    sideBarProps: {
      disableClickOutside: props?.required,
      swipeModal: true,
      swipeModalBlocked: props?.required,
      navigationHeaderLabel: getTranslation('VERIFICATION_ID_VERIFICATION'),
      navigationHeaderProps: {
        hideBackButton: true,
      },
      showBackButton: !props?.required,
    },
  },
];

const smartTraderWelcome = (
  props: SmartTraderWelcomeProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'smartTraderWelcome',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.smartTrader,
      navigationVariant: 'secondary',
      secondaryNavigationProps: {
        variant: 'light',
        className: 'mb-2',
      },
      wrapperClassName: sidebarScreensClasses.smartTraderWrapper,
    },
  },
];
const smartTraderPickAssets = (
  props: SmartTraderPickAssetsProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'smartTraderPickAssets',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.smartTrader,
      navigationVariant: 'secondary',
      secondaryNavigationProps: {
        variant: 'light',
        className: 'mb-2',
      },
      wrapperClassName: sidebarScreensClasses.smartTraderWrapper,
    },
  },
];
const smartTraderInsufficientFunds = (
  props: SmartTraderInsufficientFundsProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'smartTraderInsufficientFunds',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.smartTrader,
      navigationVariant: 'secondary',
      secondaryNavigationProps: {
        variant: 'light',
        className: 'mb-2',
      },
      wrapperClassName: sidebarScreensClasses.smartTraderWrapper,
    },
  },
];
const smartTraderExchange = (
  props: SmartTraderExchangeProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'smartTraderExchange',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.smartTrader,
      navigationVariant: 'secondary',
      secondaryNavigationProps: {
        variant: 'light',
        className: 'mb-2',
      },
      wrapperClassName: sidebarScreensClasses.smartTraderWrapper,
    },
  },
];
const smartTraderExchangeSuccess = (
  props: SmartTraderExchangeSuccessProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'smartTraderExchangeSuccess',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.smartTrader,
      navigationVariant: 'secondary',
      secondaryNavigationProps: {
        variant: 'light',
        className: 'mb-2',
      },
      wrapperClassName: sidebarScreensClasses.smartTraderWrapper,
    },
  },
];
const smartTraderSelectTradingPair = (
  props: SmartTraderSelectTradingPairProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'smartTraderSelectTradingPair',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.smartTrader,
      navigationVariant: 'secondary',
      secondaryNavigationProps: {
        variant: 'light',
        className: 'mb-2',
      },
      wrapperClassName: sidebarScreensClasses.smartTraderWrapper,
    },
  },
];
const smartTraderConfigureTrade = (
  props: SmartTraderConfigureTradeProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'smartTraderConfigureTrade',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.smartTrader,
      navigationVariant: 'secondary',
      secondaryNavigationProps: {
        variant: 'light',
        className: 'mb-2',
      },
      wrapperClassName: sidebarScreensClasses.smartTraderWrapper,
    },
  },
];
const smartTraderSummaryConfirmation = (
  props: SmartTraderSummaryConfirmationProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'smartTraderSummaryConfirmation',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.smartTrader,
      navigationVariant: 'secondary',
      secondaryNavigationProps: {
        variant: 'light',
        className: 'mb-2',
      },
      wrapperClassName: sidebarScreensClasses.smartTraderWrapper,
    },
  },
];
const smartTraderActivated = (
  props: SmartTraderActivatedProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'smartTraderActivated',
  {
    props,
    sideBarProps: {
      contentClassName: sidebarScreensClasses.smartTrader,
      navigationVariant: 'secondary',
      secondaryNavigationProps: {
        variant: 'light',
        className: 'mb-2',
      },
      wrapperClassName: sidebarScreensClasses.smartTraderWrapper,
    },
  },
];

const paymentAddEditSavedCryptoWallet = (
  props?: AddEditSavedCryptoWalletProps,
): [SideBarStackItemKey, Partial<SideBarStackItem>] => [
  'paymentAddEditSavedCryptoWallet',
  { props, sideBarProps: { swipeModal: true } },
];

const sidebarTemplates = {
  paymentProcess,
  paymentOperation,
  accountDetails,
  accountStatement,

  stakingSetup,
  unstake,
  stakingOperationConfirm,
  stakingSuccessOperation,

  bankCardAdvertisement,

  confirmOTPCode,
  answer,
  select,
  selectCurrency,
  dynamicContent,
  descriptionListCarouselSidebar,

  digitalAccountChooseSubscription,
  digitalAccountGetSubscription,
  digitalAccountGetSubscriptionConfirm,
  digitalAccountGetSubscriptionSuccess,
  digitalAccountSubscriptionVerificationPending,
  digitalAccountSubscriptionRequested,
  digitalAccountSubscriptionManagement,
  digitalAccountCancelSubscription,
  digitalAccountCancelSubscriptionSuccess,
  digitalAccountDowngradeSubscriptionToStandard,

  subscriptions,
  verification,
  verificationID,

  smartTraderWelcome,
  smartTraderPickAssets,
  smartTraderInsufficientFunds,
  smartTraderExchange,
  smartTraderExchangeSuccess,
  smartTraderSelectTradingPair,
  smartTraderConfigureTrade,
  smartTraderSummaryConfirmation,
  smartTraderActivated,

  paymentAddEditSavedCryptoWallet,
};

export default sidebarTemplates;
