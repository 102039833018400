import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectCryptoWallets } from 'modules/accounts/store/selectors';
import useSideBar from 'modules/app/hooks/useSideBar';
import { selectSavedCryptoWallets } from 'modules/payment/store/selectors';
import { requestEditCryptoWallet, requestSaveCryptoWallet } from 'modules/payment/store/thunks';
import { SavedCryptoWallet } from 'modules/payment/types';
import { useDispatch } from 'store';

import { SimpleCurrencyPickerInputField, SubmitButton, TextInputField } from 'components/form';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';
import yup, { makeValidate } from 'libs/yup';

import { findByProperty } from 'utils/arrayUtils';

import { CryptoCurrencyCode, CurrencyCode } from 'types';

interface FormValues {
  walletAddress: string;
  currencyCode: CryptoCurrencyCode | null;
  walletName: string;
}

interface AddEditSavedCryptoWalletFormProps extends FormRenderProps<FormValues> {
  currenciesList: CurrencyCode[];
  editMode: boolean;
}

const AddEditSavedCryptoWalletForm: FC<AddEditSavedCryptoWalletFormProps> = ({
  handleSubmit,
  currenciesList,
  editMode,
}) => {
  const translate = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="column flex-1 jcsb gap-3">
      <div className="column gap-2">
        <span className="label">{translate('WALLET_DETAILS')}</span>
        <Field
          name="walletAddress"
          component={TextInputField}
          placeholder={translate('PAYMENT_WALLET_ADDRESS')}
        />
        <Field
          name="currencyCode"
          component={SimpleCurrencyPickerInputField}
          currenciesList={currenciesList}
          placeholder={translate('PAYMENT_WALLET_CURRENCY')}
        />
        <Field
          name="walletName"
          component={TextInputField}
          placeholder={translate('PAYMENT_WALLET_NAME')}
        />
      </div>
      <SubmitButton fullWidth>
        {translate(editMode ? 'SAVE' : 'PAYMENT_SAVED_WALLETS_ADD_WALLET')}
      </SubmitButton>
    </form>
  );
};

export interface AddEditSavedCryptoWalletProps {
  walletIdForEditing?: SavedCryptoWallet['id'];
}
const AddEditSavedCryptoWallet: FC<AddEditSavedCryptoWalletProps> = ({ walletIdForEditing }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const sidebar = useSideBar();

  const savedCryptoWallets = useSelector(selectSavedCryptoWallets);
  const cryptoWallets = useSelector(selectCryptoWallets);

  const cryptoWalletForEditing = useMemo(
    () => findByProperty(savedCryptoWallets, 'id', walletIdForEditing),
    [savedCryptoWallets, walletIdForEditing],
  );

  const editMode = !!cryptoWalletForEditing;

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!values.currencyCode) {
        return;
      }
      if (editMode) {
        const { success } = await dispatch(
          requestEditCryptoWallet({
            currency: values.currencyCode,
            walletId: values.walletAddress,
            label: values.walletName,
            id: cryptoWalletForEditing!.id,
          }),
        );

        if (success) {
          successToast(translate('PAYMENT_SAVED_WALLETS_SUCCESS_EDIT'));
          sidebar.pop();
        }
        return;
      }
      const { success } = await dispatch(
        requestSaveCryptoWallet({
          currency: values.currencyCode,
          walletId: values.walletAddress,
          label: values.walletName,
        }),
      );

      if (success) {
        successToast(translate('PAYMENT_SAVED_WALLETS_SUCCESS'));
        sidebar.pop();
      }
    },
    [editMode, cryptoWalletForEditing, dispatch, translate, sidebar],
  );

  const currenciesList = useMemo(() => cryptoWallets.map((w) => w.currencyCode), [cryptoWallets]);

  const validate = useCallback((values: FormValues) => {
    const schema = yup.object().shape({
      walletAddress: yup
        .string()
        // .test({
        //   name: 'isCryptoAddress',
        //   message: getTranslation('PAYMENT_WALLET_ADDRESS_INVALID', {
        //     currencyCode: values.currencyCode,
        //   }),
        //   test: (value: string | undefined) =>
        //     validateCryptoWalletAddress(value, values.currencyCode, ),
        // })
        .requiredDefault(),
      walletName: yup.string().requiredDefault(),
    });
    return makeValidate(schema)(values);
  }, []);

  const initialValues = useMemo<FormValues>(
    () =>
      cryptoWalletForEditing
        ? {
            walletAddress: cryptoWalletForEditing.address,
            currencyCode: cryptoWalletForEditing.currencyCode,
            walletName: cryptoWalletForEditing.label,
          }
        : {
            walletAddress: '',
            currencyCode: null,
            walletName: '',
          },
    [cryptoWalletForEditing],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      // @ts-ignore
      render={AddEditSavedCryptoWalletForm}
      currenciesList={currenciesList}
      validate={validate}
      editMode={!!walletIdForEditing}
    />
  );
};

export default AddEditSavedCryptoWallet;
