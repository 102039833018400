import clsx from 'clsx';

import { FC, ReactNode, memo, useCallback, useEffect } from 'react';

import useWithdrawLimits from 'modules/payment/hooks/useWithdrawLimits';
import { WithdrawalLimits } from 'modules/payment/services';
import { requestWithdrawalLimits } from 'modules/payment/store/thunks';
import { PaymentOperationId } from 'modules/payment/types';
import useTransactionLimit from 'modules/payment/views/components/AmountForm/hooks/useTransactionLimit';
import { useDispatch } from 'store';

import { CurrencyIcon, DetailsDotSeparated, InfoIcon, Skeleton } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './WithdrawalLimitsCard.module.scss';

const getLimitPercent = (currentLimit: WithdrawalLimits['daily' | 'monthly'] | null) => {
  if (!currentLimit) {
    return 0;
  }
  // If limit === null it means unlimited
  if (currentLimit.limit === null && currentLimit.used > 0) {
    return 1; // For unlimited card we need to show small filled progress line
  }
  if (currentLimit.limit) {
    const realPercent = Math.round((currentLimit.used * 100) / currentLimit.limit);

    if (currentLimit.used > 0 && realPercent === 0) {
      return 1;
    }
    return realPercent;
  }
  return 0;
};
interface LimitProps {
  desc: string;
  currentLimit: WithdrawalLimits['daily' | 'monthly'] | null;
  currencyCode: CurrencyCode;
}
const Limit = memo<LimitProps>(({ desc, currentLimit, currencyCode }) => {
  const translate = useTranslation();

  if (!currentLimit) {
    return (
      <div className="column gap-1">
        <div className="row jcsb gap-3 aic">
          <Skeleton width={125} height={16} />
          <Skeleton width={100} height={16} />
        </div>
        <Skeleton height={6} />
        <Skeleton className="asfe" width={80} height={16} />
      </div>
    );
  }

  const fullLimitLabel = currentLimit.limit
    ? formatCurrencyWithSymbol(currentLimit.limit || 0, currencyCode)
    : translate('NOT_LIMITED');

  const usedPercent = getLimitPercent(currentLimit);
  const leftLabel = currentLimit.limit
    ? translate('PAYMENT_LIMITS_LEFT', {
        value: formatCurrencyWithSymbol(currentLimit.limit - currentLimit.used, currencyCode),
      })
    : translate('NOT_LIMITED');

  return (
    <div className="column gap-1">
      <div className="row gap-3 jcsb aic">
        <span className={classes.desc}>{desc}</span>
        <span className={classes.values}>
          <b>{formatCurrencyWithSymbol(currentLimit.used, currencyCode)}</b> / {fullLimitLabel}
        </span>
      </div>

      <div className={classes.progressBar}>
        <div
          className={clsx(
            classes.progress,
            usedPercent > 50 && classes.warn,
            usedPercent > 75 && classes.danger,
          )}
          style={{ width: usedPercent.toString() + '%' }}
        />
      </div>
      <span className={classes.leftLabel}>{leftLabel}</span>
    </div>
  );
});

interface WithdrawalLimitCardProps {
  operationId: PaymentOperationId;
  currencyCode: CurrencyCode;
  className?: string;
  rightAdornment?: ReactNode;
}

export const WithdrawalLimitsCard: FC<WithdrawalLimitCardProps> = ({
  operationId,
  currencyCode,
  className,
  rightAdornment,
}) => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const withdrawalLimitsLoading = useFlag(false);

  const withdrawalLimits = useWithdrawLimits(currencyCode, operationId);

  const transactionLimit = useTransactionLimit(operationId, currencyCode);

  const fetchWithdrawalLimits = useCallback(async () => {
    withdrawalLimitsLoading.on();
    await dispatch(requestWithdrawalLimits({ operationCode: operationId, currencyCode }));
    withdrawalLimitsLoading.off();
  }, [dispatch, withdrawalLimitsLoading, operationId, currencyCode]);

  useEffect(() => {
    fetchWithdrawalLimits();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={clsx('outlinedCard column gap-3', className)}>
      <div className="row jcsb">
        <div className="row aic gap-1">
          <CurrencyIcon code={currencyCode} size={16} />
          <span className="label">{translate('PAYMENT_CURRENCY_LIMIT', { currencyCode })}</span>
        </div>
        <div className="row aic gap-2">
          {rightAdornment}
          <InfoIcon
            size="xs"
            title={translate('WITHDRAW_LIMITS')}
            description={translate('WITHDRAW_LIMITS_DESC')}
          />
        </div>
      </div>
      <div className="column gap-1-5">
        <DetailsDotSeparated
          label={translate('PAYMENT_MIN_WITHDRAWAL')}
          value={formatCurrencyWithSymbol(transactionLimit?.min || 0, currencyCode)}
        />
        {transactionLimit?.max ? (
          <DetailsDotSeparated
            label={translate('PAYMENT_TRANSACTION_WITHDRAWAL')}
            value={formatCurrencyWithSymbol(transactionLimit.max, currencyCode)}
          />
        ) : null}
      </div>
      <Limit
        currentLimit={withdrawalLimits?.daily || null}
        currencyCode={currencyCode}
        desc={translate('PAYMENT_DAILY_WITHDRAWAL')}
      />
      <Limit
        currentLimit={withdrawalLimits?.monthly || null}
        currencyCode={currencyCode}
        desc={translate('PAYMENT_MONTHLY_WITHDRAWAL')}
      />
    </div>
  );
};
