import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectIssuersByCurrencyCode } from 'modules/accounts/store/selectors';
import { exchangeActions } from 'modules/exchange/store';
import { requestExchangeRate } from 'modules/exchange/store/thunks';
import { ExchangeRateData } from 'modules/exchange/types';
import { AppStore, useDispatch } from 'store';
import { IApiRequestState } from 'store/generators/types';

import { canRequest } from 'utils/common';

import { CurrenciesDirection, CurrencyCode } from 'types';

interface Options {
  autoFetch: boolean;
}

export type UseRateReducer = (
  from: CurrencyCode,
  to: CurrencyCode,
  options?: Options,
) => IApiRequestState<ExchangeRateData | null> | undefined;

const useRateReducer: UseRateReducer = (from, to, options) => {
  const dispatch = useDispatch();

  const issuers = useSelector(selectIssuersByCurrencyCode);

  const selectRate = useCallback(
    (state: AppStore) => state.exchange.rates[`${from}/${to}`],
    [from, to],
  );

  const rateReducer = useSelector(selectRate);

  const [fromIssuerId, toIssuerId] = useMemo(
    () => [issuers[from]?.id, issuers[to]?.id],
    [issuers, from, to],
  );

  useEffect(() => {
    if (
      options?.autoFetch &&
      (!rateReducer || (rateReducer && canRequest(rateReducer.meta, 0.5)))
    ) {
      if (to === from) {
        dispatch(
          exchangeActions.updateRates({
            direction: (from + '/' + to) as CurrenciesDirection,
            meta: { loading: false, loaded: true, error: undefined },
            data: {
              fromCurrency: from,
              toCurrency: to,
              rate: 1,
            },
          }),
        );
        return;
      }

      if (fromIssuerId && toIssuerId) {
        dispatch(
          requestExchangeRate(
            { fromCurrency: from, fromIssuerId, toCurrency: to, toIssuerId },
            { withoutFailedToast: true },
          ),
        );
      }
    }
    // eslint-disable-next-line
  }, [from, to, fromIssuerId, toIssuerId]);

  return rateReducer;
};
export default useRateReducer;
