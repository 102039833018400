import { FC, useCallback } from 'react';

import {
  OperationListItem,
  OperationListItemProps,
} from 'modules/staking/views/components/OperationListItem';
import { TemplateHeader } from 'modules/staking/views/components/TemplateHeader';

import { AgreementText } from 'components/common';
import { Button, LineDelimiter } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { CurrencyCode } from 'types';

export interface StakingOperationConfirmProps {
  currencyCode: CurrencyCode;
  checkList: OperationListItemProps[];
  onConfirm: () => Promise<any>;
}
const StakingOperationConfirm: FC<StakingOperationConfirmProps> = ({
  currencyCode,
  checkList,
  onConfirm,
}) => {
  const translate = useTranslation();

  const loading = useFlag(false);

  const handleConfirm = useCallback(async () => {
    loading.on();
    await onConfirm();
    loading.off();
  }, [loading, onConfirm]);
  return (
    <div className="mt-4">
      <TemplateHeader currencyCode={currencyCode} />
      <LineDelimiter className="my-2" />
      <div className="column gap-0-5">
        {checkList.map((item) => (
          <OperationListItem key={item.label} {...item} />
        ))}
      </div>
      <LineDelimiter className="my-2" />
      <Button variant="lightGreen" fullWidth onClick={handleConfirm} loading={loading.state}>
        {translate('CONFIRM')}
      </Button>
      <AgreementText className="mt-2" variant="renting-and-staking" />
    </div>
  );
};

export default StakingOperationConfirm;
