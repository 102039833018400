import { EventHandler, UIEvent, useEffect } from 'react';

import { scrollViewSelector } from 'constants/viewConstants';

const useScroll = (
  handler: EventHandler<UIEvent<HTMLDivElement>>,
  scrollRef?: HTMLDivElement | null,
) => {
  useEffect(() => {
    const scrollNode: HTMLDivElement | null =
      scrollRef || document.querySelector(scrollViewSelector);

    // @ts-ignore
    scrollNode?.addEventListener('scroll', handler);

    return () => {
      // @ts-ignore
      scrollNode?.removeEventListener('scroll', handler);
    };
  }, [handler, scrollRef]);
};

export default useScroll;
