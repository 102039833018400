import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import clsx from 'clsx';

import {
  FC,
  ReactElement,
  TouchEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectActiveAccountType } from 'modules/accounts/store/selectors';
import { requestDigitalWallets, requestWallets } from 'modules/accounts/store/thunks';
import { AccountType } from 'modules/accounts/types';
import { requestBankCards, requestBankCardsOrders } from 'modules/cryptoBankCard/store/thunks';
import {
  requestDigitalAccountSubscriptions,
  requestLastTransactions,
} from 'modules/digitalAccount/store/thunks';
import { requestLoansNotifications } from 'modules/loans/store/thunks';
import { isReactNative } from 'modules/reactNative/utils';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';
import { scrollViewSelector } from 'constants/viewConstants';

import useFlag from 'hooks/useFlag';
import useTablet from 'hooks/useTablet';

import loaderIcon from 'assets/icons/loader.svg';

import { sleep } from 'utils/common';
import { isCurrencyCode } from 'utils/currency';
import eventEmitter from 'utils/eventEmitter';

import classes from './PullToRefresh.module.scss';

interface PullToRefreshProps {
  children: ReactElement;
  refreshData: TRefreshData;
}

const REFRESH_DISTANCE = 300;

type TRefreshData = {
  thunks: ThunkAction<any, any, any, AnyAction>[];
  additional?: () => MaybePromise<any>;
};
type RefreshByPathnameReturnType = TRefreshData | null;
const useRefreshByPathname = (): RefreshByPathnameReturnType => {
  const currentAccountType = useSelector(selectActiveAccountType);

  const { pathname } = useLocation();

  return useMemo<RefreshByPathnameReturnType>(() => {
    switch (pathname) {
      case routesByName.dashboard: {
        return {
          thunks:
            currentAccountType === AccountType.crypto
              ? [
                  requestWallets(),
                  requestLoansNotifications(),
                  requestBankCards(),
                  requestBankCardsOrders(),
                ]
              : [
                  requestDigitalWallets(),
                  requestDigitalAccountSubscriptions(),
                  requestLastTransactions(),
                ],
        };
      }
      case routesByName.accounts(AccountType.crypto): {
        return { thunks: [requestWallets()] };
      }
      case routesByName.accounts(AccountType.digital): {
        return { thunks: [requestDigitalWallets()] };
      }
    }
    if (pathname.includes('accounts')) {
      const paths = pathname.split('/');
      const currencyCodeMaybe = paths[paths.length - 1];
      if (isCurrencyCode(currencyCodeMaybe)) {
        const isDigitalAccount = paths[paths.length - 2] === AccountType.digital;

        return {
          thunks: isDigitalAccount ? [requestDigitalWallets()] : [requestWallets()],
          additional: () => {
            eventEmitter.emit('refreshTransactions');
          },
        };
      }
    }
    return null;
  }, [pathname, currentAccountType]);
};

const PullToRefreshComponent: FC<PullToRefreshProps> = ({ children, refreshData }) => {
  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement | null>(document.querySelector(scrollViewSelector));
  const [loaderRef, setLoaderRef] = useState<HTMLImageElement | null>(null);
  const loading = useFlag(false);

  const startPoint = useRef(0);
  const startContainerOffset = useRef(0);
  const pullDistance = useRef(0);

  const handleRefresh = useCallback(async () => {
    loading.on();
    await Promise.all(refreshData.thunks.map((thunk) => dispatch(thunk)));
    await refreshData.additional?.();
    loading.off();
  }, [loading, dispatch, refreshData]);

  const handleTouchStart = useCallback<TouchEventHandler<HTMLDivElement>>((event) => {
    const touch = event.targetTouches[0];
    if (!touch) {
      return;
    }
    const { screenY } = touch;
    startPoint.current = screenY;
    startContainerOffset.current = containerRef.current?.scrollTop || 0;
  }, []);

  const handleTouchEnd = useCallback<TouchEventHandler<HTMLDivElement>>(async () => {
    if (pullDistance.current > 0 && containerRef.current && loaderRef) {
      containerRef.current.style.transition = `all 0.4s ease`;
      loaderRef.style.transition = `all 0.4s ease`;

      if (pullDistance.current >= REFRESH_DISTANCE) {
        await handleRefresh();
      }

      containerRef.current.style.transform = `translateY(0px)`;
      loaderRef.style.opacity = '0';
      loaderRef.style.transform = 'rotate(0deg)';

      await sleep(400);
      containerRef.current.style.transition = `unset`;
      loaderRef.style.transition = `unset`;
    }
  }, [handleRefresh, loaderRef]);

  const handleTouchMove = useCallback<TouchEventHandler<HTMLDivElement>>(
    (event) => {
      const touch = event.targetTouches[0];
      if (!touch) {
        return;
      }
      const { screenY } = touch;
      const pullDistanceCalculated = Math.min(
        startPoint.current < screenY
          ? Math.max(Math.abs(screenY - startPoint.current) - startContainerOffset.current, 0)
          : 0,
        REFRESH_DISTANCE,
      );

      pullDistance.current = pullDistanceCalculated;

      if (pullDistanceCalculated > 0 && containerRef.current) {
        containerRef.current.style.transform = `translateY(${pullDistanceCalculated / 3}px)`;
        if (loaderRef) {
          loaderRef.style.transform = `rotate(${pullDistanceCalculated}deg)`;
          loaderRef.style.opacity = `${pullDistanceCalculated / REFRESH_DISTANCE}`;
        }
      }
    },
    [loaderRef],
  );

  useEffect(() => {
    containerRef.current = document.querySelector(scrollViewSelector);
  }, []);

  return (
    <div
      className={classes.root}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      <img
        ref={setLoaderRef}
        className={clsx(
          classes.loader,
          isReactNative && classes.reactNative,
          loading.state && classes.loading,
        )}
        src={loaderIcon}
        alt="Loading"
      />
      {children}
    </div>
  );
};

export const PullToRefresh: FC<{ children: ReactElement }> = ({ children }) => {
  const isTablet = useTablet();

  const refreshData = useRefreshByPathname();

  return isTablet && refreshData ? (
    <PullToRefreshComponent refreshData={refreshData}>{children}</PullToRefreshComponent>
  ) : (
    children
  );
};
