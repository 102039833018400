import clsx from 'clsx';

import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectUserProfile } from 'modules/user/store/selectors';

import placeholderImg from 'assets/images/common/avatarPlaceholder.svg';

import { voidFunc } from 'types';

import classes from './UserAvatar.module.scss';

interface UserAvatarProps {
  size?: number;
  className?: string;
  onClick?: voidFunc;
  active?: boolean;
}

export const UserAvatar: FC<UserAvatarProps> = ({ size = 40, className, onClick, active }) => {
  const fontSize = Math.round(size / 2.2);

  const profile = useSelector(selectUserProfile);

  return profile ? (
    <div
      onClick={onClick}
      className={clsx(classes.root, className, onClick && 'pointer', active && classes.active)}
      style={{ width: size, height: size }}
    >
      {profile.avatarUrl ? (
        <img src={profile.avatarUrl || placeholderImg} alt="Avatar" />
      ) : (
        <span style={{ fontSize, height: fontSize }}>
          {profile.firstName[0]}
          {profile.lastName[0]}
        </span>
      )}
    </div>
  ) : null;
};
