import { Swiper, SwiperSlide } from 'swiper/react';

import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { selectLoansNotificationsReducer } from 'modules/loans/store/selectors';
import { requestLoansNotifications } from 'modules/loans/store/thunks';
import { LoanNotificationCard } from 'modules/loans/views/components/LoanNotificationCard';
import { useDispatch } from 'store';

import { canRequest } from 'utils/common';

import classes from './LoanNotificationsCarousel.module.scss';

export const LoanNotificationsCarousel = () => {
  const dispatch = useDispatch();
  const notificationsReducer = useSelector(selectLoansNotificationsReducer);

  const needMoreSpace = useMediaQuery({ maxWidth: 900 });

  const slidesPerView = useMemo(() => {
    if (needMoreSpace) {
      return notificationsReducer.data.length > 1 ? 1.2 : 1;
    }
    if (notificationsReducer.data.length < 3) {
      return notificationsReducer.data.length;
    }
    return 2.2;
  }, [notificationsReducer.data.length, needMoreSpace]);

  useEffect(() => {
    if (canRequest(notificationsReducer.meta)) {
      dispatch(requestLoansNotifications());
    }
    // eslint-disable-next-line
  }, []);

  return notificationsReducer.data.length ? (
    <div className={classes.root}>
      <Swiper className="w100" spaceBetween={8} slidesPerView={slidesPerView}>
        {notificationsReducer.data.map((notification) => (
          <SwiperSlide key={notification.id}>
            <LoanNotificationCard notification={notification} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};
