import { accountsActions } from '../store';
import { requestRatesBatched } from 'modules/exchange/store/thunks';
import { requestCurrenciesNetworks } from 'modules/payment/store/thunks';
import { generateRequestDataThunk, generateRequestThunk } from 'store/generators/thunks';

import { ExchangeMultiPairRequest, TradingWalletRequest } from 'libs/swagger/nebeusApiTypes';

import { isFiat } from 'utils/currency';

import accountsServices from '../services';
import { DigitalWallet, Issuer, TradingWallet, Transaction, Wallet } from '../types';

// cli-import

export const requestWallets = generateRequestDataThunk<Wallet[]>({
  updateState: accountsActions.updateWallets,
  request: accountsServices.getWallets,
  options: { showErrorToast: false },
});

export const requestIssuers = generateRequestDataThunk<Issuer[]>({
  updateState: accountsActions.updateIssuers,
  request: accountsServices.getIssuers,
  options: {
    showErrorToast: false,
    onSuccess: (issuers, dispatch) => {
      const currencyCodes = issuers.map((i) => i.currencyCode);

      let pairs: ExchangeMultiPairRequest['pairs'] = [];

      currencyCodes.forEach((fromCurrency) => {
        currencyCodes.forEach((toCurrency) => {
          if (toCurrency !== fromCurrency) {
            pairs.push({ fromCurrency, toCurrency });
          }
        });
      });

      dispatch(requestRatesBatched({ pairs }));
    },
  },
});

export const requestCreateWallet = generateRequestThunk<TradingWalletRequest, TradingWallet>({
  request: accountsServices.createWallet,
  options: {
    onSuccess: async (dispatch, data) => {
      if (!isFiat(data.currencyCode)) {
        await dispatch(requestCurrenciesNetworks());
      }
      await dispatch(requestWallets());
    },
  },
});
export const requestGetAccountTransactions = generateRequestThunk({
  request: accountsServices.getAccountTransactions,
  options: { showErrorToast: false },
});

export const requestDigitalWallets = generateRequestDataThunk<DigitalWallet[]>({
  updateState: accountsActions.updateDigitalWallets,
  request: accountsServices.getDigitalWallets,
  options: { showErrorToast: false },
});
export const requestTransactionStatement = generateRequestThunk({
  request: accountsServices.getTransactionStatement,
});

export const requestAccountStatement = generateRequestThunk({
  request: accountsServices.getAccountStatement,
});
export const requestCreateDigitalWallet = generateRequestThunk({
  request: accountsServices.createDigitalWallet,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestDigitalWallets());
    },
  },
});

export const requestLastTransactions = generateRequestDataThunk<Transaction[]>({
  updateState: accountsActions.updateLastTransactions,
  request: async () => {
    const transactions = await accountsServices.getAccountTransactions({
      pageSize: 3,
      pageNumber: 0,
    });

    // Bug with two data source on BE
    if (transactions.length >= 3) {
      return transactions.filter((_, index) => index <= 2);
    } else {
      const additionalTransactions = await accountsServices.getAccountTransactions({
        pageSize: 3,
        pageNumber: 1,
      });

      return [...transactions, ...additionalTransactions].filter((_, index) => index <= 2);
    }
  },
  options: { showErrorToast: false },
});

export const requestGetTransactionsForRefund = generateRequestThunk({
  request: accountsServices.getTransactionsForRefund,
});
export const requestGetBankCardDepositTransactionById = generateRequestThunk({
  request: accountsServices.getBankCardDepositTransactionById,
});
// cli-thunk
