import { ChangeEventHandler, FC, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { TextInput } from 'components/ui';
import { TextInputProps } from 'components/ui/TextInput';

import useField from 'hooks/useField';

interface TextInputFieldProps extends FieldRenderProps<string>, TextInputProps {}

export const TextInputField: FC<TextInputFieldProps> = ({
  input: { value, onChange, ...input },
  meta,
  ...props
}) => {
  const { hasError, error, valid } = useField(meta);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(event.currentTarget.value);
    },
    [onChange],
  );

  return (
    <TextInput
      value={value}
      onChange={handleChange}
      error={hasError && error}
      valid={valid}
      {...input}
      {...props}
    />
  );
};
