import clsx from 'clsx';
import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { selectCryptoWallets } from 'modules/accounts/store/selectors';
import { TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import { templateDescriptionByType, templateTitleByType } from 'modules/loans/constants/config';
import loanDrawerTemplates from 'modules/loans/constants/drawerTemplates';
import { loansActions } from 'modules/loans/store';
import {
  selectLoanCalculatorSavedValues,
  selectTemplatesReducer,
} from 'modules/loans/store/selectors';
import { requestLoanTemplates } from 'modules/loans/store/thunks';
import { LoanTemplate } from 'modules/loans/types';
import { CheckList } from 'modules/loans/views/LoanCalculator/components/CheckList';
import { LoanConfigurator } from 'modules/loans/views/LoanCalculator/components/LoanConfigurator';
import { MarketingCard } from 'modules/loans/views/LoanCalculator/components/MarketingCard';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { SubmitButton } from 'components/form';
import { Button, InfoIcon, Loader, NavigationHeader, VerticalDelimiter } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import useStoreEntity from 'hooks/useStoreEntity';
import useTablet from 'hooks/useTablet';

import { useTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import {
  findWallet,
  formatCurrency,
  formatCurrencyWithSymbol,
  getCurrencyLabelByCode,
} from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './LoanCalculator.module.scss';

export interface GetLoanFormValues {
  loan: CurrencyAmount;
  collateral: CurrencyAmount;
  termMonth: number;
  ltv: number;
  excessCollateralRelease: boolean;
  autoManagementMC: boolean;
  templateId: number;
}

interface LoanCalculatorWithTemplateProps {
  template: LoanTemplate;
  variant: 'full' | 'short';
}

interface LoanCalculatorFormProps extends FormRenderProps<GetLoanFormValues> {
  cryptoWallets: TradingWallet[];
  template: LoanTemplate;
  variant: LoanCalculatorWithTemplateProps['variant'];
}

const LoanCalculatorForm: FC<LoanCalculatorFormProps> = ({
  cryptoWallets,
  handleSubmit,
  template,
  errors,
  values,
  variant,
}) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const drawer = useDrawer();
  const navigate = useNavigate();
  const isTablet = useTablet();

  const handleClickLearnMore = useCallback(() => {
    drawer.open(loanDrawerTemplates.templateDescription({ template }));
  }, [drawer, template]);

  const handleDepositCollateral = useCallback(() => {
    drawer.open(
      paymentDrawerTemplates.paymentProcess({ currencyCode: values.collateral.currency }),
    );
  }, [drawer, values.collateral.currency]);

  const isInsufficientFunds =
    get(errors, 'collateral.amount') === translate('VALIDATION_INSUFFICIENT_FUNDS');

  const handleClickGetLoanShortVersion = useCallback(() => {
    navigate(routesByName.loans('new', template.type));
  }, [navigate, template.type]);

  const submitButtons = (
    <div className="column gap-1-5">
      {isInsufficientFunds ? (
        <Button onClick={handleDepositCollateral} fullWidth>
          {translate('DEPOSIT_CURRENCY', {
            currencyLabel: getCurrencyLabelByCode(values.collateral.currency),
          })}
        </Button>
      ) : (
        <SubmitButton fullWidth>{translate('LOANS_GET_LOAN')}</SubmitButton>
      )}
      <Button
        className={classes.learnMoreButton}
        fullWidth
        onClick={handleClickLearnMore}
        variant="creamyBlack"
      >
        {translate('LEARN_MORE')}
      </Button>
    </div>
  );

  useEffect(() => {
    dispatch(loansActions.updateLoanCalculatorSavedValues(values));

    // eslint-disable-next-line
  }, [values]);

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      {variant === 'full' ? (
        <>
          <div className="column gap-4 flex-1">
            <CheckList template={template} variant={variant} />
            <div className="column gap-1">
              <MarketingCard variant="zeroRisk" />
              {template.earlyRepaymentPenaltyPercent ? (
                <MarketingCard variant="earlyRepaymentFee" />
              ) : null}
            </div>
            {isTablet && submitButtons}
          </div>
          <VerticalDelimiter className={classes.delimiter} />
        </>
      ) : null}
      <div className={classes.calculatorSide}>
        <LoanConfigurator cryptoWallets={cryptoWallets} template={template} variant={variant} />
        {variant === 'full' ? (
          isTablet ? null : (
            submitButtons
          )
        ) : (
          <>
            <CheckList template={template} variant={variant} />
            <Button fullWidth onClick={handleClickGetLoanShortVersion}>
              {translate('LOANS_GET_LOAN')}
            </Button>
          </>
        )}
      </div>
    </form>
  );
};

const LoanCalculatorWithTemplate: FC<LoanCalculatorWithTemplateProps> = ({ template, variant }) => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const drawer = useDrawer();

  const cryptoWallets = useSelector(selectCryptoWallets);

  const initialValuesFromStore = useSelector(selectLoanCalculatorSavedValues);

  const initialValues = useMemo<GetLoanFormValues>(() => {
    const loanCurrencies = Object.keys(template.loanRules);
    const collateralCurrencies = Object.keys(template.collateralRules);

    const loanCurrency = loanCurrencies.includes(initialValuesFromStore.loan.currency)
      ? initialValuesFromStore.loan.currency
      : (loanCurrencies[0] as CurrencyCode);

    const collateralCurrency = collateralCurrencies.includes(
      initialValuesFromStore.collateral.currency,
    )
      ? initialValuesFromStore.collateral.currency
      : (collateralCurrencies[0] as CurrencyCode);

    const result = {
      ...initialValuesFromStore,
      excessCollateralRelease: template.excessCollateralReleaseEnabled
        ? initialValuesFromStore.excessCollateralRelease
        : false,
      autoManagementMC: template.autoMCEnabled ? initialValuesFromStore.autoManagementMC : false,
      ltv:
        initialValuesFromStore.ltv > template.maxLtv || initialValuesFromStore.ltv < template.minLtv
          ? template.maxLtv
          : initialValuesFromStore.ltv,
      termMonth:
        initialValuesFromStore.termMonth > template.maxTermMonth ||
        initialValuesFromStore.termMonth < template.minTermMonth
          ? template.minTermMonth
          : initialValuesFromStore.termMonth,
      loan: {
        ...initialValuesFromStore.loan,
        currency: loanCurrency,
      },
      collateral: {
        ...initialValuesFromStore.collateral,
        currency: collateralCurrency,
      },
      templateId: template.id,
    };

    return result;
    // eslint-disable-next-line
  }, [template]);

  const validate = useCallback(
    async (values: GetLoanFormValues) => {
      const loanRule = template.loanRules[values.loan.currency];
      const collateralRule = template.collateralRules[values.collateral.currency];

      const minLoanAmount = loanRule.minAmount;
      const maxLoanAmount = loanRule.maxAmount;

      const walletAmount = +formatCurrency(
        findWallet(cryptoWallets, values.collateral.currency)?.amount || 0,
        values.collateral.currency,
        { round: 'down' },
      );

      const maxCollateralAmount = Math.min(walletAmount, collateralRule.maxAmount);

      const schema = yup.object().shape({
        collateral: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .min(
              collateralRule.minAmount,
              translate('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(
                  collateralRule.minAmount,
                  values.collateral.currency,
                ),
              }),
            )
            .max(
              maxCollateralAmount,
              maxCollateralAmount === walletAmount
                ? translate('VALIDATION_INSUFFICIENT_FUNDS')
                : translate('VALIDATION_MAX_AMOUNT', {
                    maxLabel: formatCurrencyWithSymbol(
                      collateralRule.maxAmount,
                      values.collateral.currency,
                    ),
                  }),
            )
            .requiredDefault(),
        }),
        loan: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .min(
              minLoanAmount,
              translate('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(minLoanAmount, values.loan.currency),
              }),
            )
            .max(
              maxLoanAmount,
              translate('VALIDATION_MAX_AMOUNT', {
                maxLabel: formatCurrencyWithSymbol(maxLoanAmount, values.loan.currency),
              }),
            )
            .requiredDefault(),
        }),
      });

      let errors = await makeValidate(schema)(values);

      if (
        template.type === 'FLEXIBLE' &&
        values.ltv === template.minLtv &&
        !values.autoManagementMC &&
        !values.excessCollateralRelease
      ) {
        errors = {
          ...errors,
          [FORM_ERROR]: translate('LOAN_MIN_LTV_WITH_NO_OPTIONS_VALIDATION'),
        };
      }

      return errors;
    },
    [template, translate, cryptoWallets],
  );

  const handleSubmit = useCallback(
    (values: GetLoanFormValues) => {
      navigate(routesByName.loanConfirmation, { state: values });
    },
    [navigate],
  );

  const renderForm = useCallback(
    (formValues: FormRenderProps<GetLoanFormValues>) => (
      <LoanCalculatorForm
        {...formValues}
        cryptoWallets={cryptoWallets}
        template={template}
        variant={variant}
      />
    ),
    [cryptoWallets, template, variant],
  );

  const handleClickInfoIcon = useCallback(() => {
    drawer.open(loanDrawerTemplates.templateDescription({ template }));
  }, [drawer, template]);

  return (
    <div className={clsx(classes.root, classes[`variant-${variant}`])}>
      {variant === 'full' && (
        <div className={classes.titles}>
          <div className="row aic gap-2">
            <NavigationHeader backButtonFilled={false}>
              {translate(templateTitleByType[template.type])}
            </NavigationHeader>
            <InfoIcon className={classes.infoIcon} onClick={handleClickInfoIcon} />
          </div>
          <p>{translate(templateDescriptionByType[template.type])}</p>
        </div>
      )}
      <Form
        initialValues={initialValues}
        validate={validate}
        render={renderForm}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

interface LoanCalculatorProps {
  templateType?: LoanTemplate['type'];
  variant?: 'short' | 'full';
}
const LoanCalculator: FC<LoanCalculatorProps> = ({
  templateType: templateTypeFromProps,
  variant = 'full',
}) => {
  const navigate = useNavigate();
  const { templateType: templateTypeFromParams } = useParams();

  const templateType = templateTypeFromParams || templateTypeFromProps;

  const { entityReducer: templatesReducer } = useStoreEntity(
    selectTemplatesReducer,
    requestLoanTemplates,
  );

  const template = useMemo(
    () => templatesReducer.data.find((t) => t.type === templateType?.toUpperCase()),
    [templatesReducer.data, templateType],
  );

  useEffect(() => {
    if (templatesReducer.meta.loaded && !template) {
      navigate(routesByName.loans('new'));
    }
    // eslint-disable-next-line
  }, [templatesReducer.meta.loaded]);

  return template ? (
    <LoanCalculatorWithTemplate variant={variant} template={template} />
  ) : (
    <Loader centered />
  );
};

export default LoanCalculator;
