import { MOBILE_MAX_WIDTH } from 'constants/responsive';

export const _isMobile = window.innerWidth < MOBILE_MAX_WIDTH;

export const isCurrentDeviceMobileOrTablet = !!window.navigator.userAgent.match(
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i,
);

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const getPlatformByUserAgent = (): 'WEB' | 'IOS' | 'ANDROID' | null => {
  try {
    if (/android/i.test(window.navigator.userAgent)) {
      return 'ANDROID';
    }

    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      return 'IOS';
    }

    return 'WEB';
  } catch {
    return null;
  }
};

export const DEVICE_PLATFORM = getPlatformByUserAgent();
