export type LangCode = 'ENG' | 'ESP';
export type FullLangCode = 'en_GB' | 'es_PE';
export interface AllowedLanguage {
  shortCode: 'EN' | 'ES';
  fullCode: FullLangCode;
  code: LangCode;
  name: string;
}

export const defaultLanguage: AllowedLanguage = {
  shortCode: 'EN',
  code: 'ENG',
  fullCode: 'en_GB',
  name: 'English',
};

export const allowedLanguages: AllowedLanguage[] = [
  defaultLanguage,
  { shortCode: 'ES', code: 'ESP', name: 'Spanish', fullCode: 'es_PE' },
  // { shortCode: 'FR', code: 'FRA', name: 'French' },
  // { shortCode: 'DE', code: 'DEU', name: 'Deutsch' },
];

const deviceLanguageCode = window.navigator.language;
const deviceLanguageCodeUpperCased = deviceLanguageCode.split('-')[0]?.toUpperCase();
export const deviceAllowedLanguage =
  allowedLanguages.find((l) => l.shortCode === deviceLanguageCodeUpperCased) || defaultLanguage;

// export const countryByLangCode: { [key in LangCode]?: string[] } = {
//   ESP: [
//     'ES',
//     'MX',
//     'CR',
//     'GT',
//     'NI',
//     'PA',
//     'DO',
//     'PR',
//     'AR',
//     'BO',
//     'CL',
//     'CO',
//     'EC',
//     'PY',
//     'PE',
//     'UY',
//     'VE',
//   ],
// };
