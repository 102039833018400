import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useBankCards from 'modules/app/hooks/useBankCards';
import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates, {
  DrawerTemplateReturnType,
} from 'modules/app/views/Sidebar/commonDrawerTemplates';
import useIbanAccountPageOpening from 'modules/digitalAccount/hooks/useIbanAccountPageOpening';
import { selectIsDigitalAccountAllowed } from 'modules/digitalAccount/store/selectors';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';

import routesByName from 'constants/routesByName';

import NebeusReferralProgramAdBanner from 'components/common/NebeusReferralProgramAdBanner';
import { Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './AppOperations.module.scss';

interface MenuGroup {
  groupName: string;
  items: {
    icon: string;
    label: string;
    drawerTemplate?: DrawerTemplateReturnType;
    handler?: voidFunc;
    route?: string;
  }[];
}
const AppOperations = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const drawer = useDrawer();

  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);
  const { onOpenIbanAccountPage } = useIbanAccountPageOpening();

  const { bankCardsViewAllowed } = useBankCards();

  const operationsGroups = useMemo<MenuGroup[]>(() => {
    const earningMenu: MenuGroup = {
      groupName: translate('EARNING'),
      items: [
        { icon: 'staking', label: translate('STAKING'), route: routesByName.staking('assets') },
        {
          icon: 'circlesUp',
          label: translate('RENTING'),
          route: routesByName.cryptoRenting('new'),
        },
      ],
    };
    const moneyMenu: MenuGroup = {
      groupName: translate('MONEY'),
      items: [
        {
          icon: 'document2',
          label: translate('INVOICES'),
          drawerTemplate: invoicingDrawerTemplates.invoices(),
        },
      ],
    };

    if (isDAAllowed) {
      moneyMenu.items.unshift({
        icon: 'bank2',
        label: translate('IBAN'),
        handler: onOpenIbanAccountPage,
      });
    }
    if (bankCardsViewAllowed) {
      moneyMenu.items.unshift({
        icon: 'card2',
        label: translate('CARD'),
        drawerTemplate: commonDrawerTemplates.bankCards(),
      });
    }

    const cryptoMenu: MenuGroup = {
      groupName: translate('CRYPTO'),
      items: [
        {
          icon: 'percent',
          label: translate('LOANS'),
          route: routesByName.loans('new'),
        },
        {
          icon: 'smartTrader',
          label: translate('SMART_TRADER'),
          route: routesByName.smartTrader,
        },
        {
          icon: 'transferArrowsSquared',
          label: translate('EXCHANGE'),
          drawerTemplate: exchangeDrawerTemplates.exchange(),
        },
        {
          icon: 'vault',
          label: translate('VAULT'),
          route: routesByName.vault,
        },
      ],
    };

    const result = [earningMenu, moneyMenu, cryptoMenu];

    return result;
  }, [bankCardsViewAllowed, onOpenIbanAccountPage, isDAAllowed, translate]);

  return (
    <div className="column gap-3">
      <NebeusReferralProgramAdBanner />
      <div className="column gap-3">
        {operationsGroups.map((group) => (
          <div key={group.groupName} className="column gap-2">
            <span className="label">{group.groupName}</span>
            <div className="row aic gap-3">
              {group.items.map((item) => (
                <div
                  key={item.icon}
                  onClick={() => {
                    if (item.route) {
                      drawer.close();
                      navigate(item.route);

                      return;
                    }
                    if (item.drawerTemplate) {
                      drawer.replace(item.drawerTemplate);
                      return;
                    }
                    if (item.handler) {
                      drawer.close();
                      item.handler();
                    }
                  }}
                  className={classes.button}
                >
                  <Icon name={item.icon} className={classes.icon} />
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppOperations;
