import { DigitalSubscriptionManagement } from './components/DigitalSubscriptionManagement';

const Subscriptions = () => {
  return (
    <div className="mt-2">
      <DigitalSubscriptionManagement />
    </div>
  );
};

export default Subscriptions;
