import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import { selectRNBioSettings } from 'modules/reactNative/store/selectors';
import { isReactNativeSupportBioVerification } from 'modules/reactNative/utils';
import {
  appVerificationService,
  getBioTypeLabelKey,
} from 'modules/reactNative/utils/appVerification';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import { selectUserProfile } from 'modules/user/store/selectors';
import { requestProfile, requestToggleSendOtpViaEmail } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import { MenuList, Switch } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

const Security = () => {
  const translate = useTranslation();
  const dispatch = useDispatch();

  const drawer = useDrawer();

  const userProfile = useSelector(selectUserProfile);

  const toggleTFA = useCallback(
    (turnOn: boolean) => {
      if (turnOn) {
        drawer.open(userDrawerTemplates.setupTFA());
      } else {
        drawer.open(
          userDrawerTemplates.checkTFACode({
            variant: 'disable',
          }),
        );
      }
    },
    [drawer],
  );

  const toggleOtpLoading = useFlag(false);
  const toggleOtp = useCallback(async () => {
    toggleOtpLoading.on();
    await dispatch(requestToggleSendOtpViaEmail(!userProfile?.otpByEmail));
    await dispatch(requestProfile());
    toggleOtpLoading.off();
  }, [toggleOtpLoading, userProfile, dispatch]);

  const rnBioSettings = useSelector(selectRNBioSettings);

  const menu = useMemo<MenuListItem[]>(() => {
    if (!userProfile) {
      return [];
    }
    const result: MenuListItem[] = [
      {
        key: 'tfa',
        label: translate('TFA_TITLE'),
        endAdornment: <Switch value={userProfile.twoFactorsAuthEnabled} onChange={toggleTFA} />,
      },
    ];
    if (userProfile.hasPhoneNumber) {
      result.push(
        {
          key: 'emailOtp',
          label: translate('RECEIVE_OTP_BY_EMAIL'),
          textEllipsis: false,
          endAdornment: (
            <Switch
              value={userProfile.otpByEmail}
              onChange={toggleOtp}
              loading={toggleOtpLoading.state}
            />
          ),
        },
        {
          key: 'phoneOtp',
          label: translate('RECEIVE_OTP_BY_PHONE'),
          textEllipsis: false,
          endAdornment: (
            <Switch
              value={!userProfile.otpByEmail}
              onChange={toggleOtp}
              loading={toggleOtpLoading.state}
            />
          ),
        },
      );
    }

    if (isReactNativeSupportBioVerification) {
      result.push({
        key: 'passCode',
        label: translate('PASSCODE'),
        endAdornment: (
          <Switch
            value={rnBioSettings.passcodeEnabled}
            onChange={() => {
              if (rnBioSettings.passcodeEnabled) {
                appVerificationService.checkBioVerification({ turnOff: true });
              } else {
                appVerificationService.setVerification();
              }
            }}
          />
        ),
      });

      if (rnBioSettings.bioEnabled || rnBioSettings.bioType) {
        result.push({
          key: 'bioVerification',
          label: translate(getBioTypeLabelKey(rnBioSettings.bioType)),
          endAdornment: (
            <Switch
              value={rnBioSettings.bioEnabled}
              onChange={() => {
                if (rnBioSettings.bioEnabled) {
                  appVerificationService.checkBioVerification({ turnOffBio: true });
                } else {
                  appVerificationService.setVerification({
                    onlyBio: rnBioSettings.passcodeEnabled,
                  });
                }
              }}
            />
          ),
        });
      }
    }
    return result;
  }, [rnBioSettings, toggleTFA, userProfile, toggleOtpLoading.state, toggleOtp, translate]);

  return <MenuList className="mt-1" menu={menu} />;
};

export default Security;
