import { FC, useCallback, useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';
import { requestDownloadInvoicePdf } from 'modules/invoicing/store/thunks';
import { Invoice } from 'modules/invoicing/types';
import { useDispatch } from 'store';

import { Button, Icon, MenuList } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import classes from './InvoiceCreated.module.scss';

export interface InvoiceCreatedProps {
  invoice: Invoice;
}
const InvoiceCreated: FC<InvoiceCreatedProps> = ({ invoice }) => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const menu = useMemo<MenuListItem[]>(() => {
    const result = [
      {
        label: translate('SAVE_AS_PDF'),
        handler: async () => {
          const { success } = await dispatch(requestDownloadInvoicePdf(invoice));
          if (success) {
            successToast(translate('SUCCESS!'));
          }
        },
        asyncHandler: true,
        icon: 'arrowDownSafe',
      },
      {
        label: translate('INVOICE_VIEW_INVOICE'),
        handler: () => {
          drawer.replaceAll(invoicingDrawerTemplates.invoice({ invoice }));
        },
        icon: 'document2',
      },
    ];

    // TODO: For future (mobile)
    // @ts-ignore
    // if (window.navigator.share) {
    //   result.push({
    //     label: translate('SHARE'),
    //     handler: () => {
    //       // TODO: Invoicing share
    //     },
    //     icon: 'doc',
    //   });
    // }

    return result;
  }, [drawer, dispatch, invoice, translate]);

  const handleFinish = useCallback(() => {
    drawer.replaceAll(invoicingDrawerTemplates.invoices());
  }, [drawer]);

  return (
    <div className="column flex-1 jcsb gap-3">
      <div className="column flex-1 jcc gap-4">
        <div className="column gap-6 aic">
          <Icon name="checkInCircle" size={72} />
          <div className={classes.textContent}>
            <h3>{translate('INVOICE_CREATED_TITLE')}</h3>
            <p>{translate('INVOICE_CREATED_SUBTITLE')}</p>
          </div>
          <MenuList menu={menu} centered className="w100" />
        </div>
      </div>
      <Button onClick={handleFinish}>{translate('INVOICE_BACK_TO_INVOICES')}</Button>
    </div>
  );
};

export default InvoiceCreated;
