import { createSelector } from '@reduxjs/toolkit';

import { selectDigitalAccountWallets } from 'modules/accounts/store/selectors';
import { IBAN_ACCOUNT_MAIN_PROVIDER } from 'modules/digitalAccount/types';
import { selectUserProfile } from 'modules/user/store/selectors';
import { AppStore } from 'store';

export const selectSubscriptions = (state: AppStore) => state.digitalAccount.subscriptions.data;

export const selectActiveDigitalSubscription = createSelector(selectSubscriptions, (subs) =>
  subs.find((s) => s.isActive),
);
export const selectFreeDigitalSubscription = (state: AppStore) =>
  state.digitalAccount.subscriptions.data.find((s) => s.pricePerMonth === 0);

export const selectSubscriptionReducer = (state: AppStore) => state.digitalAccount.subscriptions;
export const selectSubscriptionMeta = (state: AppStore) => state.digitalAccount.subscriptions.meta;

export const selectEndingDigitalSubscription = createSelector(selectSubscriptions, (subs) =>
  subs.find((s) => s.isEnding),
);
export const selectAllEndingDigitalSubscription = createSelector(selectSubscriptions, (subs) =>
  subs.filter((s) => s.isEnding),
);

export const selectIsIbanNumberCreated = createSelector(selectDigitalAccountWallets, (wallets) =>
  wallets.find((w) => 'iban' in w && w.iban?.length > 0),
);

export const selectUserDASubscriptionType = createSelector(
  selectActiveDigitalSubscription,
  selectEndingDigitalSubscription,
  selectIsIbanNumberCreated,
  (active, ending, ibanNumberCreated) => ibanNumberCreated && (active?.type || ending?.type),
);

export const selectLastTransactionsReducer = (state: AppStore) =>
  state.digitalAccount.lastTransactions;

export const selectAllowedCountries = (state: AppStore) =>
  state.digitalAccount.allowedCountries.data;

export const selectAllowedCountriesMeta = (state: AppStore) =>
  state.digitalAccount.allowedCountries.meta;

export const selectIsDigitalAccountAllowed = createSelector(
  selectUserProfile,
  selectUserDASubscriptionType,
  selectAllowedCountries,
  (userProfile, digitalAccountSubscription, allowedCountries) => {
    if (!userProfile) {
      return false;
    }
    if (digitalAccountSubscription) {
      return true;
    }
    if (userProfile.isBusinessAccount) {
      return false;
    }

    return !!allowedCountries.find((c) => c.iso2code === userProfile.countryCode);
  },
);

export const selectAllowedIbanAccountProviders = (state: AppStore) =>
  state.digitalAccount.allowedProviders.data;
export const selectAllowedIbanAccountProvidersMeta = (state: AppStore) =>
  state.digitalAccount.allowedProviders.meta;

export const selectCurrentIbanAccountProvider = createSelector(
  selectAllowedIbanAccountProviders,
  (providers) => providers.find((p) => p.id === IBAN_ACCOUNT_MAIN_PROVIDER) || providers[0] || null,
);
