import get from 'lodash/get';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectExchangeRates } from 'modules/exchange/store/selectors';

import { CurrencyCode } from 'types';

type UseRate = () => (from: CurrencyCode, to: CurrencyCode) => number;

const useGetRate: UseRate = () => {
  const rates = useSelector(selectExchangeRates);

  const getRate = useCallback(
    (from: CurrencyCode, to: CurrencyCode) => get(rates, `[${from}/${to}].data.rate`) || 0,
    [rates],
  );
  return getRate;
};
export default useGetRate;
