import { SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { initAuthedUser, requestSignIn, requestSignInConfirmOTP } from 'modules/auth/store/thunks';
import { IssuesWithTFA } from 'modules/auth/views/SignIn/steps/IssuesWithTFA';
import { LoginWithTFA } from 'modules/auth/views/SignIn/steps/LoginWithTFA';
import ResetTFA from 'modules/auth/views/SignIn/steps/ResetTFA';
import { SendOtpByPhoneSuggestion } from 'modules/auth/views/SignIn/steps/SendOtpByPhoneSuggestion';
import { SignWrapper } from 'modules/auth/views/components/SignWrapper';
import { EnterEmail } from 'modules/auth/views/steps/EnterEmail';
import { EnterOTP } from 'modules/auth/views/steps/EnterOTP';
import { isReactNativeSupportBioVerification } from 'modules/reactNative/utils';
import { appVerificationService } from 'modules/reactNative/utils/appVerification';
import { requestToggleSendOtpViaEmail } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import useFlag from 'hooks/useFlag';

import { ApiCodes, translateApiCode } from 'libs/axios/ApiCodes';
import { useTranslation } from 'libs/i18n';

import { clearClipboard, sleep } from 'utils/common';

export interface Values {
  email: string | null;
  hasTfa: boolean;
  codeSentByEmail: boolean;
  userHasPhoneNumber: boolean;
  phoneNumber: string | null;
}

const SignIn = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const [values, setValues] = useState<Values>({
    email: null,
    codeSentByEmail: true,
    hasTfa: false,
    userHasPhoneNumber: false,
    phoneNumber: null,
  });

  // const needSuggestSendOtpByPhone = values.codeSentByEmail && values.userHasPhoneNumber;
  const needSuggestSendOtpByPhone = false; // TODO: Temp decision

  const sendOtpViaPhoneSubmitted = useFlag(false);

  const onSendOtpByPhoneSubmit = useCallback(async () => {
    if (values.hasTfa) {
      sendOtpViaPhoneSubmitted.on();
      swiper?.slideNext();
    } else {
      await dispatch(requestToggleSendOtpViaEmail(false));
      await dispatch(initAuthedUser());
      if (isReactNativeSupportBioVerification) {
        appVerificationService.setVerification();
        await sleep(500);
      }
      navigate(routesByName.dashboard, { state: { from: 'auth' }, replace: true });
    }
  }, [values.hasTfa, navigate, sendOtpViaPhoneSubmitted, swiper, dispatch]);

  const onSendOtpByPhoneReject = useCallback(async () => {
    if (values.hasTfa) {
      swiper?.slideNext();
    } else {
      await dispatch(initAuthedUser());
      if (isReactNativeSupportBioVerification) {
        appVerificationService.setVerification();
        await sleep(500);
      }
      navigate(routesByName.dashboard, { state: { from: 'auth' }, replace: true });
    }
  }, [values.hasTfa, navigate, swiper, dispatch]);

  const loading = useFlag(false);

  const sendOtp = useCallback(
    async (email: string) => {
      const { success, data } = await dispatch(requestSignIn({ login: email }));

      if (data) {
        setValues((prev) => ({
          ...prev,
          codeSentByEmail: data.sentToEmail,
          userHasPhoneNumber: data.hasPhoneNumber,
        }));
      }
      return success;
    },
    [dispatch],
  );

  const handleSubmitEmail = useCallback(
    async (email: string) => {
      loading.on();
      const success = await sendOtp(email);

      loading.off();

      if (success) {
        setValues((prev) => ({ ...prev, email }));
        swiper?.slideNext();
      }
    },
    [sendOtp, loading, swiper],
  );

  const resendOtp = useCallback(() => sendOtp(values.email!), [values, sendOtp]);

  const onOTPSuccess = useCallback(
    async ({ hasTfa, phoneNumber }: { hasTfa: boolean; phoneNumber: string | null }) => {
      if (hasTfa || needSuggestSendOtpByPhone) {
        if (hasTfa) {
          await clearClipboard();
        }
        setValues((prev) => ({ ...prev, hasTfa, phoneNumber }));
        swiper?.slideNext();
      } else {
        if (isReactNativeSupportBioVerification) {
          appVerificationService.setVerification();
          await sleep(500);
        }

        const result = await dispatch(initAuthedUser());
        if (result) {
          if (result.user.hasPhoneNumber) {
            navigate(routesByName.dashboard, { state: { from: 'auth' }, replace: true });
          } else {
            navigate(routesByName.signUp, { replace: true });
          }
        }
      }
    },
    [dispatch, swiper, needSuggestSendOtpByPhone, navigate],
  );

  const handleSubmitOTP = useCallback(
    async ({ code }: { code: string }) => {
      if (values.email) {
        loading.on();

        const { success, data, error } = await dispatch(
          requestSignInConfirmOTP({
            login: values.email,
            otpCode: code,
          }),
        );

        if (success) {
          await onOTPSuccess({ hasTfa: !!data?.twoFactorAuth, phoneNumber: data?.phoneNumber });
          loading.off();
        } else {
          loading.off();
          if (error?.error?.code === ApiCodes.otpCodeIsInvalid) {
            return { code: translateApiCode(ApiCodes.otpCodeIsInvalid) };
          }
        }
      }
    },
    [loading, values, dispatch, onOTPSuccess],
  );

  return (
    <SignWrapper isLogin swiper={swiper} onSwiper={setSwiper} loading={loading.state}>
      <SwiperSlide>
        <EnterEmail onSubmit={handleSubmitEmail} isLogin />
      </SwiperSlide>
      <SwiperSlide>
        <EnterOTP
          onSubmit={handleSubmitOTP}
          resendOtp={resendOtp}
          title={translate('SIGN_IN_ENTER_OTP_TITLE')}
          subtitle={translate(
            values.codeSentByEmail
              ? 'SIGN_ENTER_OTP_SUBTITLE_EMAIL'
              : 'SIGN_ENTER_OTP_SUBTITLE_PHONE',
            {
              value: (values.codeSentByEmail ? values.email : values.phoneNumber) || '',
              codeLength: 6,
            },
          )}
        />
      </SwiperSlide>
      {needSuggestSendOtpByPhone ? (
        <SwiperSlide>
          <SendOtpByPhoneSuggestion
            onSubmit={onSendOtpByPhoneSubmit}
            onReject={onSendOtpByPhoneReject}
            phoneNumber={values.phoneNumber}
          />
        </SwiperSlide>
      ) : null}
      {values.email ? (
        <>
          <SwiperSlide>
            <LoginWithTFA email={values.email} sendOtpByPhone={sendOtpViaPhoneSubmitted.state} />
          </SwiperSlide>
          <SwiperSlide>
            <IssuesWithTFA />
          </SwiperSlide>
          <SwiperSlide>
            <ResetTFA
              email={values.email}
              onSuccess={() => {
                setValues((prev) => ({ ...prev, hasTfa: false }));
              }}
            />
          </SwiperSlide>
        </>
      ) : null}
    </SignWrapper>
  );
};

export default SignIn;
