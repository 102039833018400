import { sumBy } from 'lodash';

import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useWallets from 'modules/accounts/hooks/useWallets';
import { accountsActions } from 'modules/accounts/store';
import {
  selectActiveAccountType,
  selectDigitalAccountWalletsMeta,
  selectWalletsMeta,
} from 'modules/accounts/store/selectors';
import {
  requestCreateDigitalWallet,
  requestCreateWallet,
  requestDigitalWallets,
  requestWallets,
} from 'modules/accounts/store/thunks';
import { AccountType, DigitalWallet, TradingWallet } from 'modules/accounts/types';
import { AccountList } from 'modules/accounts/views/Accounts/components/AccountList';
import useBankCardDepositTransactionDetails from 'modules/accounts/views/Accounts/hooks/useBankCardDepositTransactionDetails';
import { AccountTypeChangeButton } from 'modules/accounts/views/components';
import useDrawer from 'modules/app/hooks/useDrawer';
import useSideBar from 'modules/app/hooks/useSideBar';
import { selectAllowedCurrenciesSorted } from 'modules/app/store/selectors';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import rentingDrawerTemplates from 'modules/cryptoRenting/constants/drawerTemplates';
import { selectTemplatesReducer } from 'modules/cryptoRenting/store/selectors';
import { requestTemplates } from 'modules/cryptoRenting/store/thunks';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import {
  selectIsDigitalAccountAllowed,
  selectUserDASubscriptionType,
} from 'modules/digitalAccount/store/selectors';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { PaymentOperationId } from 'modules/payment/types';
import { selectIsUserVerified, selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { AmountLabel, Icon, Image } from 'components/ui';

import useMobile from 'hooks/useMobile';
import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { CurrencyCode } from 'types';

import classes from './Accounts.module.scss';

const validAccountTypes = [AccountType.digital, AccountType.crypto];

const Accounts = () => {
  const dispatch = useDispatch();

  const translate = useTranslation();
  const navigate = useNavigate();
  const sidebar = useSideBar();
  const drawer = useDrawer();
  const params = useParams();

  useBankCardDepositTransactionDetails();

  const { accountType: accountTypeFromRoute } = params || {};

  const defaultCurrencyCode = useSelector(selectUserDefaultCurrencyCode);
  const isDigitalAccountAllowed = useSelector(selectIsDigitalAccountAllowed);
  const currentAccountType = useSelector(selectActiveAccountType);
  const allowedCurrencies = useSelector(selectAllowedCurrenciesSorted);
  const userHasSubscription = useSelector(selectUserDASubscriptionType);

  const isCryptoAccount = accountTypeFromRoute === AccountType.crypto;

  const currentAccountTypeValid = useMemo(
    () =>
      validAccountTypes.includes(accountTypeFromRoute as AccountType) &&
      (accountTypeFromRoute === AccountType.digital ? isDigitalAccountAllowed : true),
    [accountTypeFromRoute, isDigitalAccountAllowed],
  );

  useEffect(() => {
    if (!currentAccountTypeValid) {
      navigate(routesByName.accounts(AccountType.crypto), { replace: true });
    }

    // eslint-disable-next-line
  }, [currentAccountTypeValid]);

  useEffect(() => {
    if (!currentAccountTypeValid) {
      return;
    }
    dispatch(isCryptoAccount ? requestWallets() : requestDigitalWallets());

    // eslint-disable-next-line
  }, [isCryptoAccount]);

  useEffect(() => {
    if (currentAccountTypeValid && currentAccountType !== accountTypeFromRoute) {
      dispatch(accountsActions.updateAccountType(accountTypeFromRoute as AccountType));
    }

    // eslint-disable-next-line
  }, [accountTypeFromRoute, currentAccountTypeValid]);

  const { wallets } = useWallets({ isDigitalAccount: !isCryptoAccount });

  const walletsMeta = useSelector(
    isCryptoAccount ? selectWalletsMeta : selectDigitalAccountWalletsMeta,
  );

  const [loadingWalletCurrencyCode, setLoadingWalletCurrencyCode] = useState<CurrencyCode | null>(
    null,
  );

  const totalValue = useMemo(() => sumBy(wallets, 'amountInDefaultCurrency'), [wallets]);

  const operationButtons = useMemo(() => {
    const result = isCryptoAccount
      ? [
          {
            label: translate('DEPOSIT'),
            icon: 'arrowDown',
            handler: () => {
              drawer.open(paymentDrawerTemplates.paymentProcess());
            },
          },
          {
            label: translate('SEND'),
            icon: 'arrowUp',
            handler: () => {
              drawer.open(paymentDrawerTemplates.paymentProcess({ isDeposit: false }));
            },
          },
          {
            label: translate(
              isDigitalAccountAllowed ? 'ACCOUNTS_FUND_DA' : 'PAYMENT_SEND_TO_NEBEUS_USER',
            ),
            icon: 'arrowLeft',
            handler: () => {
              if (isDigitalAccountAllowed) {
                if (!userHasSubscription) {
                  drawer.open(ibanAccountDrawerTemplates.accountCreatingPrompt());
                  return;
                }
                const template = paymentDrawerTemplates.paymentOperation({
                  operationId: PaymentOperationId.sendFromTradingToMoney,
                  currencyCode: 'EUR',
                });
                if (template) {
                  drawer.open(template);
                }
              } else {
                sidebar.open(
                  ...sidebarTemplates.selectCurrency({
                    currencies: allowedCurrencies,
                    onPick: (code: CurrencyCode) => {
                      const routeTemplate = paymentDrawerTemplates.paymentOperation({
                        operationId: PaymentOperationId.sendToNebeusUser,
                        currencyCode: code,
                      });
                      if (routeTemplate) {
                        drawer.replaceAll(routeTemplate);
                      }
                    },
                  }),
                );
              }
            },
          },
          {
            label: translate('EXCHANGE'),
            icon: 'refresh',
            handler: () => {
              drawer.open(exchangeDrawerTemplates.exchange());
            },
          },

          // TODO: [WBE] No pdf, no all transactions history
          // {
          //   label: translate('MORE'),
          //   icon: 'dots',
          //   handler: () => {
          //     sidebar.open('accountOperationsMore', {
          //       sideBarProps: { swipeModal: true, variant: 'creamy' },
          //     });
          //   },
          // },
        ]
      : [
          {
            label: translate('DEPOSIT'),
            icon: 'arrowDown',
            handler: () => {
              sidebar.open(...sidebarTemplates.paymentProcess({ isDigitalAccount: true }));
            },
          },
          {
            label: translate('SEND'),
            icon: 'arrowUp',
            handler: () => {
              sidebar.open(
                ...sidebarTemplates.paymentProcess({ isDeposit: false, isDigitalAccount: true }),
              );
            },
          },
          {
            label: translate('ACCOUNTS_FUND_CA'),
            icon: 'arrowRight',
            handler: () => {
              const template = paymentDrawerTemplates.paymentOperation({
                operationId: PaymentOperationId.sendFromMoneyToTrading,
                currencyCode: 'EUR',
              });
              if (template) {
                drawer.open(template);
              }
            },
          },
        ];

    result.push({
      label: translate('ACCOUNTS_ACCOUNT_STATEMENT'),
      icon: 'doc',
      handler: () => {
        sidebar.open(...sidebarTemplates.accountStatement({ isDigitalAccount: !isCryptoAccount }));
      },
    });

    return result;
  }, [
    userHasSubscription,
    isDigitalAccountAllowed,
    isCryptoAccount,
    translate,
    sidebar,
    drawer,
    allowedCurrencies,
  ]);

  const userVerified = useSelector(selectIsUserVerified);

  const handleWalletClick = useCallback(
    async (event: SyntheticEvent<HTMLDivElement>) => {
      const currencyCode = event.currentTarget.dataset.currency as CurrencyCode;
      const wallet = wallets.find((w) => w.currencyCode === currencyCode) as
        | TradingWallet
        | DigitalWallet;

      if (wallet) {
        if (wallet.exist) {
          navigate(routesByName.accountPage(currencyCode, !isCryptoAccount));
          return;
        }

        if (!userVerified) {
          sidebar.open(...sidebarTemplates.verificationID());
          return;
        }
        setLoadingWalletCurrencyCode(currencyCode);

        if (isCryptoAccount && 'issuerId' in wallet) {
          const { success } = await dispatch(
            requestCreateWallet({
              issuerId: wallet.issuerId,
            }),
          );

          setLoadingWalletCurrencyCode(null);
          if (success) {
            navigate(routesByName.accountPage(currencyCode, false));
          }
        } else {
          const { success } = await dispatch(requestCreateDigitalWallet(currencyCode));
          setLoadingWalletCurrencyCode(null);
          if (success) {
            navigate(routesByName.accountPage(currencyCode, true));
          }
        }
      }
    },
    [sidebar, userVerified, isCryptoAccount, navigate, dispatch, wallets],
  );

  const handleAccountTypeChange = useCallback(
    (newAccountType: AccountType) => {
      navigate(routesByName.accounts(newAccountType), { replace: true });
    },
    [navigate],
  );

  const isMobile = useMobile();

  const {
    entityReducer: { data: rentingTemplates },
  } = useStoreEntity(selectTemplatesReducer, requestTemplates);

  const handleClickBanner = useCallback(() => {
    navigate(routesByName.cryptoRenting('new'));
    drawer.open(rentingDrawerTemplates.rentingSetup({ templateName: 'Baobab' }));
  }, [navigate, drawer]);

  return (
    <div className={classes.root}>
      {isDigitalAccountAllowed && (
        <AccountTypeChangeButton onChange={handleAccountTypeChange} className="mb-4" />
      )}
      <div className={classes.card}>
        <div className={classes.container}>
          <div className={classes.totalValueCard}>
            <span className={classes.totalValueLabel}>{translate('ACCOUNTS_TOTAL_VALUE')}</span>
            <AmountLabel
              className={classes.totalValue}
              size="xl"
              amount={totalValue}
              currencyCode={defaultCurrencyCode}
              showCurrencySymbol
            />
          </div>
          <div className={classes.operationButtons}>
            {operationButtons.map((ob) => (
              <div onClick={ob.handler} key={ob.label} className={classes.operationButton}>
                <Icon
                  size="sm"
                  color="creamyWhite"
                  name={ob.icon}
                  className={classes.operationButtonIcon}
                />
                <span>{ob.label}</span>
              </div>
            ))}
          </div>
        </div>
        {rentingTemplates.length && (
          <Image
            onClick={handleClickBanner}
            className={classes.banner}
            name={isMobile ? 'rentingBannerSm' : 'rentingBanner'}
            path="cryptoRenting"
          />
        )}

        <div className={classes.accountListWrapper}>
          <AccountList
            wallets={wallets}
            walletsMeta={walletsMeta}
            loadingWalletCurrencyCode={loadingWalletCurrencyCode}
            onWalletClick={handleWalletClick}
            isCryptoAccount={isCryptoAccount}
          />
        </div>
      </div>
    </div>
  );
};

export default Accounts;
