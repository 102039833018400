import clsx from 'clsx';

import { FC, useCallback, useState } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import { DeliveryPlan } from 'modules/cryptoBankCard/types';
import { ShippingDetailsFormValues } from 'modules/cryptoBankCard/views/OrderBankCardShippingDetails';

import { Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { voidFunc } from 'types';

import classes from './OrderBankCardDeliveryMethod.module.scss';

export interface OrderBankCardDeliveryMethodProps {
  shippingDetails: ShippingDetailsFormValues;
  plans: DeliveryPlan[];
}

interface PlanItemProps {
  plan: DeliveryPlan;
  onClick: voidFunc;
  active: boolean;
}

const PlanItem: FC<PlanItemProps> = ({ plan, onClick, active }) => {
  const translate = useTranslation();
  return (
    <div onClick={onClick} className={clsx(classes.plan, active && classes.active)}>
      <div className="column gap-0-5">
        <span>{plan.name}</span>
        <p>
          {translate('DATE_RANGE_IN_DAYS', { day1: plan.daysRange[0], day2: plan.daysRange[1] })}
        </p>
      </div>
      <div>
        <span>
          {plan.priceInEur ? formatCurrencyWithSymbol(plan.priceInEur, 'EUR') : translate('FREE')}
        </span>
      </div>
    </div>
  );
};
const OrderBankCardDeliveryMethod: FC<OrderBankCardDeliveryMethodProps> = ({
  shippingDetails,
  plans,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const [activePlanId, setActivePlanId] = useState<number | null>(null);

  const handleSubmit = useCallback(() => {
    drawer.replace(
      cryptoBankCardDrawerTemplates.orderBankCardConfirmation({
        isVirtual: false,
        shippingValues: shippingDetails,
        deliveryPlan: plans.find((p) => p.id === activePlanId)!,
      }),
    );
  }, [drawer, plans, shippingDetails, activePlanId]);

  return (
    <div className="column flex-1 jcsb gap-3 mt-1">
      <div className="column gap-2">
        {plans.map((p) => (
          <PlanItem
            key={p.id}
            plan={p}
            onClick={() => setActivePlanId(p.id)}
            active={activePlanId === p.id}
          />
        ))}
      </div>
      <Button variant="greyishGreen" fullWidth disabled={!activePlanId} onClick={handleSubmit}>
        {translate('CONTINUE')}
      </Button>
    </div>
  );
};

export default OrderBankCardDeliveryMethod;
