import clsx from 'clsx';
import { isEqual } from 'lodash';

import { FC, HTMLProps, MouseEventHandler, ReactElement, useCallback } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';

import { useTranslation } from 'libs/i18n';

import classes from './SelectSidebar.module.scss';

export interface SelectSidebarProps<T> extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  value?: T;
  onPick: (value: T) => void;
  options: T[];
  labelAccessor?: (value: T) => string;
  keyAccessor?: (value: T) => string;
  className?: string;
  renderItem?: (value: T, index: number, isActive: boolean) => ReactElement;
}

interface OptionProps {
  label: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Option: FC<OptionProps> = ({ label, onClick }) => {
  return (
    <div onClick={onClick} key={label} className={classes.option}>
      <span>{label}</span>
    </div>
  );
};

const defaultAccessor = (value: any) => value;

export const SelectSidebar = <T,>({
  value,
  onPick,
  options,
  labelAccessor = defaultAccessor,
  keyAccessor = defaultAccessor,
  className,
  renderItem: renderItemFromProps,
}: SelectSidebarProps<T>) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const renderItem = useCallback(
    (option: T, index: number) => {
      if (renderItemFromProps) {
        return (
          <div
            key={keyAccessor(option)}
            onClick={() => {
              onPick(option);
              sidebar.pop();
            }}
          >
            {renderItemFromProps(option, index, isEqual(value, option))}
          </div>
        );
      }
      return (
        <Option
          key={keyAccessor(option)}
          label={labelAccessor(option)}
          onClick={() => {
            onPick(option);
            sidebar.pop();
          }}
        />
      );
    },
    [sidebar, value, keyAccessor, labelAccessor, renderItemFromProps, onPick],
  );

  return (
    <div className={clsx(classes.options, className)}>
      {options.length ? (
        options.map(renderItem)
      ) : (
        <Option label={translate('VALIDATION_SELECT_NO_OPTIONS')} />
      )}
    </div>
  );
};
