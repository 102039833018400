import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import {
  selectIsDigitalAccountAllowed,
  selectSubscriptionReducer,
  selectUserDASubscriptionType,
} from 'modules/digitalAccount/store/selectors';

import routesByName from 'constants/routesByName';

const useIbanAccountPageOpening = () => {
  const drawer = useDrawer();
  const navigate = useNavigate();

  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);

  const digitalSubscriptionsReducer = useSelector(selectSubscriptionReducer);
  const userHasSubscription = useSelector(selectUserDASubscriptionType);

  const onOpenIbanAccountPage = useCallback(() => {
    if (isDAAllowed && digitalSubscriptionsReducer.meta.loaded && !userHasSubscription) {
      drawer.open(ibanAccountDrawerTemplates.createIban());
    }
    if (isDAAllowed && userHasSubscription) {
      navigate(routesByName.iban);
    }
  }, [navigate, drawer, isDAAllowed, digitalSubscriptionsReducer, userHasSubscription]);

  return { onOpenIbanAccountPage };
};
export default useIbanAccountPageOpening;
