import { cryptoRentingActions } from '../store';
import { RENTING_REQUEST_LIMIT_PAGE } from 'modules/cryptoRenting/constants/config';
import { generateInfinityListThunk, generateRequestDataThunk } from 'store/generators/thunks';
import { generateRequestThunk } from 'store/generators/thunks';

import cryptoRentingServices from '../services';
import { RentingItem, RentingSchedule, Statistic, Template } from '../types';

// cli-import

export const requestTemplates = generateRequestDataThunk<Template[]>({
  updateState: cryptoRentingActions.updateTemplates,
  request: cryptoRentingServices.getTemplates,
  options: { showErrorToast: false },
});
export const requestStatistic = generateRequestDataThunk<Statistic>({
  updateState: cryptoRentingActions.updateStatistic,
  request: cryptoRentingServices.getStatistic,
  options: { showErrorToast: false },
});

export const requestRentingActiveItems = generateInfinityListThunk<RentingItem, undefined>({
  request: cryptoRentingServices.getRentingActiveItems,
  updateState: cryptoRentingActions.updateRentingActiveItems,
  reducerPath: 'cryptoRenting.rentingItemsActive',
  options: { showErrorToast: false },
});

export const requestRentingHistoryItems = generateInfinityListThunk<RentingItem, undefined>({
  request: cryptoRentingServices.getRentingHistoryItems,
  updateState: cryptoRentingActions.updateRentingHistoryItems,
  reducerPath: 'cryptoRenting.rentingItemsHistory',
  options: { showErrorToast: false },
});
export const requestCreateRenting = generateRequestThunk({
  request: cryptoRentingServices.createRenting,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestStatistic());
      dispatch(
        requestRentingActiveItems(undefined, {
          reset: true,
          pageLimit: RENTING_REQUEST_LIMIT_PAGE,
        }),
      );
    },
  },
});

export const requestGetPayoutSchedule = generateRequestThunk<number, RentingSchedule>({
  request: cryptoRentingServices.getPayoutSchedule,
  options: { showErrorToast: false },
});

// cli-thunk
