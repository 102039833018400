import { FC, useEffect } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { CurrencyNetwork } from 'modules/payment/types';

import { Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

export interface SelectCryptoNetworkConfirmationProps {
  network: CurrencyNetwork;
  onConfirm: voidFunc;
}
const SelectCryptoNetworkConfirmation: FC<SelectCryptoNetworkConfirmationProps> = ({
  network,
  onConfirm,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  useEffect(() => {
    if (!onConfirm) {
      drawer.pop();
    }
    // eslint-disable-next-line
  }, [onConfirm]);

  return (
    <div className="column gap-4 flex-1 jcsb">
      <div className="column gap-1">
        <h3>{translate('PAYMENT_NETWORK_SELECTED', { networkName: network.networkName })}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: translate('PAYMENT_NETWORK_IMPORTANT_TEXT', {
              networkName: network.networkName,
            }),
          }}
        />
      </div>
      <div className="row gap-1 aic">
        <Button fullWidth onClick={() => drawer.pop()}>
          {translate('CANCEL')}
        </Button>
        <Button
          fullWidth
          onClick={() => {
            drawer.pop({ key: 'paymentSelectCryptoNetworkConfirmation' });
            onConfirm();
          }}
        >
          {translate('CONFIRM')}
        </Button>
      </div>
    </div>
  );
};

export default SelectCryptoNetworkConfirmation;
