import { createSlice } from '@reduxjs/toolkit';

import { generateRequestDataReducer } from 'store/generators/reducers';
import { initialArrayApiRequestState } from 'store/generators/utils';
import { initialApiRequestState } from 'store/generators/utils';

import { CurrencyCode } from 'types';

import { CryptoBankCard, CryptoBankCardFeeConfig, CryptoBankCardState } from '../types';
import { CryptoBankCardOrder } from '../types';
import { Config } from '../types';

// cli-import

const initialState: CryptoBankCardState = {
  allowedDebitCardPaymentCurrencyCodes: initialArrayApiRequestState,
  bankCardsOrders: initialArrayApiRequestState,
  config: initialApiRequestState,
  allowedCountries: initialArrayApiRequestState,
  bankCards: initialArrayApiRequestState,
  allowedLinkingCurrencies: initialArrayApiRequestState,
  feeConfig: initialApiRequestState,
  // cli-state
};

const cryptoBankCardSlice = createSlice({
  name: 'cryptoBankCard',
  initialState,
  reducers: {
    resetState: (state) => {
      state = initialState;
      return state;
    },
    updateAllowedDebitCardPaymentCurrencyCodes: generateRequestDataReducer<
      CryptoBankCardState,
      CurrencyCode[]
    >({
      reducerPath: 'allowedDebitCardPaymentCurrencyCodes',
    }),
    updateBankCardsOrders: generateRequestDataReducer<CryptoBankCardState, CryptoBankCardOrder[]>({
      reducerPath: 'bankCardsOrders',
    }),
    updateConfig: generateRequestDataReducer<CryptoBankCardState, Config>({
      reducerPath: 'config',
    }),
    updateAllowedCountries: generateRequestDataReducer<CryptoBankCardState, string[]>({
      reducerPath: 'allowedCountries',
    }),
    updateBankCards: generateRequestDataReducer<CryptoBankCardState, CryptoBankCard[]>({
      reducerPath: 'bankCards',
    }),
    updateAllowedLinkingCurrencies: generateRequestDataReducer<CryptoBankCardState, CurrencyCode[]>(
      {
        reducerPath: 'allowedLinkingCurrencies',
      },
    ),
    updateFeeConfig: generateRequestDataReducer<CryptoBankCardState, CryptoBankCardFeeConfig>({
      reducerPath: 'feeConfig',
    }),
    // cli-reducer
  },
});

export default cryptoBankCardSlice.reducer;
export const cryptoBankCardActions = cryptoBankCardSlice.actions;
