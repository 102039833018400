import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  selectDigitalAccountExistWallets,
  selectDigitalAccountWalletsMeta,
  selectExistingWallets,
  selectWalletsMeta,
} from 'modules/accounts/store/selectors';
import { requestDigitalWallets, requestWallets } from 'modules/accounts/store/thunks';
import { AccountType, DigitalWallet, TradingWallet } from 'modules/accounts/types';
import { AccountDetails } from 'modules/accounts/views/AccountPage/components/AccountDetails';
import TransactionHistory from 'modules/accounts/views/components/TransactionHistory';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { NavigationHeader } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { findWallet, getCurrencyLabelByCode, isFiat } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './AccountPage.module.scss';

interface AccountPageProps {
  isCryptoAccount: boolean;
  wallet: TradingWallet | DigitalWallet;
}

const AccountPage: FC<AccountPageProps> = ({ wallet }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const isCryptoAccount = params?.accountType === AccountType.crypto;

  useEffect(() => {
    dispatch(isCryptoAccount ? requestWallets() : requestDigitalWallets());

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <NavigationHeader className={classes.headerLabel}>
        {`${getCurrencyLabelByCode(wallet.currencyCode)} ${
          isFiat(wallet.currencyCode)
            ? translate(isCryptoAccount ? 'CRYPTO_ACCOUNT' : 'DIGITAL_ACCOUNT')
            : ''
        }`}
      </NavigationHeader>
      <AccountDetails wallet={wallet} />
      <TransactionHistory
        key={`${wallet.currencyCode}-${isCryptoAccount ? 'crypto' : 'iban'}`}
        currencyCode={wallet.currencyCode}
        isCryptoAccount={isCryptoAccount}
      />
    </div>
  );
};

const AccountPageWithRedirect = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { accountType, currencyCode } = params || {};

  const isCryptoAccount = accountType === AccountType.crypto;

  const wallets = useSelector(
    // @ts-ignore
    isCryptoAccount ? selectExistingWallets : selectDigitalAccountExistWallets,
  ) as TradingWallet[] | DigitalWallet[];

  const wallet = useMemo<TradingWallet | DigitalWallet | undefined>(
    // @ts-ignore
    () => findWallet(wallets, currencyCode as CurrencyCode),
    [currencyCode, wallets],
  );

  const meta = useSelector(isCryptoAccount ? selectWalletsMeta : selectDigitalAccountWalletsMeta);

  useEffect(() => {
    if (meta.loaded && !wallet) {
      navigate(routesByName.accounts((accountType as AccountType) || AccountType.crypto), {
        replace: true,
      });
    }
    // eslint-disable-next-line
  }, [wallet, meta.loaded]);

  return wallet ? <AccountPage isCryptoAccount={isCryptoAccount} wallet={wallet} /> : null;
};

export default AccountPageWithRedirect;
