import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectDigitalAccountExistWallets } from 'modules/accounts/store/selectors';
import { DigitalWallet } from 'modules/accounts/types';
import { requestSwitchDefaultIban } from 'modules/digitalAccount/store/thunks';
import { PaymentOperationId } from 'modules/payment/types';
import { PaymentDetailsCard } from 'modules/payment/views/components/PaymentDetailsCard';
import { useDispatch } from 'store';

import { AmountLabel, CheckBox, DottedLine, DrawerHeader } from 'components/ui';

import { useTranslation } from 'libs/i18n';

interface WalletCardProps {
  wallet: DigitalWallet;
  onPick: (wallet: DigitalWallet) => void;
}
const WalletCard: FC<WalletCardProps> = ({ wallet, onPick }) => {
  const translate = useTranslation();

  return (
    <PaymentDetailsCard
      startAdornment={
        <div className="column">
          <div className="row jcsb">
            <div className="column gap-1">
              <span className="label">
                {translate('IBAN_ACCOUNT_ASSETS', { currencyCode: wallet.currencyCode })}
              </span>
              <AmountLabel
                amount={wallet.amount}
                currencyCode={wallet.currencyCode}
                currencyIcon={{ size: 24 }}
              />
            </div>
            <CheckBox
              rounded
              checked={wallet.default}
              onChange={(newValue) => {
                if (newValue) {
                  onPick(wallet);
                }
              }}
            />
          </div>
          <DottedLine className="my-3" />
        </div>
      }
      currencyCode={wallet.currencyCode}
      operationId={
        wallet.currencyCode === 'GBP'
          ? PaymentOperationId.depositFromWireToMoneyWallet
          : PaymentOperationId.depositFromSepaToMoneyWallet
      }
    />
  );
};
const SwitchDefaultIban = () => {
  const translate = useTranslation();
  const dispatch = useDispatch();

  const digitalWallets = useSelector(selectDigitalAccountExistWallets);

  const handleSwitch = useCallback(
    async (wallet: DigitalWallet) => {
      await dispatch(requestSwitchDefaultIban({ currencyCode: wallet.currencyCode }));
    },
    [dispatch],
  );

  return (
    <div className="pt-2">
      <DrawerHeader title={translate('SWITCH_IBAN')} />
      <div className="mt-2 column gap-3">
        {digitalWallets.map((wallet) => (
          <WalletCard key={wallet.currencyCode} wallet={wallet} onPick={handleSwitch} />
        ))}
      </div>
    </div>
  );
};

export default SwitchDefaultIban;
