import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';

import currenciesWithAsyncAddressCreating from 'modules/accounts/constants/currenciesWithAsyncAddressCreating';
import { selectWallets } from 'modules/accounts/store/selectors';
import { requestCreateWallet } from 'modules/accounts/store/thunks';
import { TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import { getImportantLabelForSendCrypto } from 'modules/payment/constants/cryptoCurrenciesDetails';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import useDefaultCryptoNetwork from 'modules/payment/hooks/useDefaultCryptoNetwork';
import { CurrencyNetwork, PaymentOperationId } from 'modules/payment/types';
import { WalletBalanceCard } from 'modules/payment/views/components';
import useTransactionLimit from 'modules/payment/views/components/AmountForm/hooks/useTransactionLimit';
import { useDispatch } from 'store';

import {
  CopyButton,
  Icon,
  ImportantCard,
  InfoIcon,
  Loader,
  Skeleton,
  TextInput,
} from 'components/ui';
import { InfoIconProps } from 'components/ui/InfoIcon';

import useFlag from 'hooks/useFlag';

import { getTranslation, useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';
import { LINK_TERMS_OF_USE } from 'utils/links';

import { CryptoCurrencyCode } from 'types';

import classes from './DepositCrypto.module.scss';

export interface DepositCryptoProps {
  currencyCode: CryptoCurrencyCode;
  minimumDepositAmount?: number;
}

interface WalletAddressCardProps {
  value: string;
  label: string;
}

const WalletAddressCard: FC<WalletAddressCardProps> = ({ value, label }) => {
  return (
    <div className={classes.walletAddressCard}>
      <div className="column gap-0-5">
        <span className="label black">{label}</span>

        <span className={classes.walletAddress}>{value}</span>
      </div>
      <CopyButton className="flex-shrink-0" value={value} label={label} />
    </div>
  );
};

const DepositCrypto: FC<DepositCryptoProps> = ({
  currencyCode,
  minimumDepositAmount: minimumDepositAmountFromProps,
}) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const walletCreating = useFlag(false);

  const wallets = useSelector(selectWallets);

  const transactionLimit = useTransactionLimit(
    PaymentOperationId.depositCryptoFromExternalWallet,
    currencyCode,
  );

  const minimumDepositAmount = Math.max(minimumDepositAmountFromProps || 0, transactionLimit.min);

  const wallet = useMemo(
    () => wallets.find((w) => w.currencyCode === currencyCode) as TradingWallet,
    [wallets, currencyCode],
  );

  const { defaultNetwork, allowedNetworks } = useDefaultCryptoNetwork(currencyCode, {
    isDeposit: true,
  });

  const [currentNetworkId, setCurrentNetworkId] = useState<CurrencyNetwork['network'] | null>(
    defaultNetwork?.network || null,
  );

  const currentNetwork = useMemo(
    () => allowedNetworks.find((n) => n.network === currentNetworkId),
    [allowedNetworks, currentNetworkId],
  );

  const isDefaultNetworkExist = !!defaultNetwork;
  useEffect(() => {
    if (isDefaultNetworkExist && !currentNetwork) {
      setCurrentNetworkId(defaultNetwork!.network);
    }
    // eslint-disable-next-line
  }, [isDefaultNetworkExist]);

  const createWallet = useCallback(async () => {
    walletCreating.on();
    await dispatch(requestCreateWallet({ issuerId: wallet!.issuerId }));
    walletCreating.off();
  }, [dispatch, wallet, walletCreating]);

  useEffect(() => {
    if (wallet?.exist === false && !walletCreating.state) {
      createWallet();
    }
    // eslint-disable-next-line
  }, [JSON.stringify(wallet)]);

  const [walletParsedAddress, walletMetaParam, walletMetaParamType] = useMemo<
    [string, string | undefined, 'tag' | 'memo' | undefined]
  >(() => {
    if (!currentNetwork?.address) {
      return ['', undefined, undefined];
    }
    const hasMemo = currentNetwork.address.includes('?memoId=');
    const hasTag = currentNetwork.address.includes('?dt=');

    if (hasMemo || hasTag) {
      const separator = hasMemo ? '?memoId=' : '?dt=';
      const walletParts = currentNetwork.address.split(separator);

      return [walletParts[0], walletParts[1], hasMemo ? 'memo' : 'tag'];
    }

    return [currentNetwork.address, undefined, undefined];
  }, [currentNetwork?.address]);

  const handleNetworkFieldClick = useCallback(() => {
    drawer.open(
      paymentDrawerTemplates.selectCryptoNetwork({
        currencyCode,
        onSelect: (network: CurrencyNetwork) => {
          setCurrentNetworkId(network.network);
        },
        isDeposit: true,
      }),
    );
  }, [drawer, currencyCode]);

  const minDepositAmountInfoIconProps = useMemo<Partial<InfoIconProps>>(() => {
    if (minimumDepositAmountFromProps) {
      return {
        symbol: '!',
      };
    }
    return {
      title: translate('PAYMENT_DEPOSIT_CRYPTO_MIN_AMOUNT_INFO_TITLE'),
      description: translate('PAYMENT_DEPOSIT_CRYPTO_MIN_AMOUNT_INFO_DESC', {
        termLink: LINK_TERMS_OF_USE,
      }),
    };
  }, [minimumDepositAmountFromProps, translate]);

  const walletCreatingAsync =
    !walletCreating.state &&
    (!currentNetwork || !currentNetwork?.address) &&
    currenciesWithAsyncAddressCreating.includes(currencyCode);

  if (walletCreating.state || !wallet || !currentNetwork) {
    return (
      <div className="mt-1 column gap-3">
        <div className="column gap-1">
          <Skeleton height={24} />
          <Skeleton height={24} width={180} />
        </div>
        <div className="column gap-2">
          <Skeleton height={50} />
          <Skeleton height={70} />
        </div>
        <Skeleton className="asc" width={156} height={156} borderRadius={0} />

        <div className={classes.walletAddressCard}>
          <div className="column gap-1 jcsb flex-1">
            <Skeleton height={20} width={90} />
            <div className="column gap-0-5">
              <Skeleton height={14} />
              <Skeleton height={14} width={200} />
            </div>
          </div>
          <Skeleton height={32} width={32} borderRadius={16} />
        </div>

        <div className="outlinedCard column gap-0-5">
          <Skeleton height={24} width={90} />
          <div className="column gap-1">
            <Skeleton height={24} />
            <Skeleton height={24} />
            <Skeleton height={24} />
            <Skeleton height={24} width={80} />
          </div>
        </div>
      </div>
    );
  }

  return wallet && currentNetwork ? (
    <div className="mt-1 column gap-3">
      <p>{translate('PAYMENT_DEPOSIT_CRYPTO_QR_LABEL', { currencyCode })}</p>

      <div className="column gap-2">
        <WalletBalanceCard currencyCode={currencyCode} />
        {minimumDepositAmount && (
          <div className={classes.minimumDepositCard}>
            <InfoIcon {...minDepositAmountInfoIconProps} />
            <div className="column gap-0-5">
              <span className="label">{translate('PAYMENT_MINIMUM_DEPOSIT_AMOUNT')}</span>
              <span className={classes.balanceLabel}>
                {formatCurrencyWithSymbol(minimumDepositAmount, wallet.currencyCode)}
              </span>
            </div>
          </div>
        )}
      </div>
      {walletCreatingAsync ? (
        <ImportantCard
          text={translate('PAYMENT_CRYPTO_ADDRESS_ASYNC_CREATING_WARNING', {
            currencyCode,
          })}
        />
      ) : null}

      {!walletCreating.state && (!currentNetwork || !currentNetwork.address) ? null : (
        <div className={classes.qrContainer}>
          {walletCreating.state ? (
            <Loader />
          ) : currentNetwork?.address ? (
            <QRCode className="w100 h100" size={156} value={currentNetwork.address} />
          ) : null}
        </div>
      )}
      {walletParsedAddress ? (
        <>
          <div className={classes.walletAddressEntities}>
            <WalletAddressCard
              label={translate('PAYMENT_WALLET_ADDRESS')}
              value={walletParsedAddress}
            />
            {walletMetaParam && (
              <WalletAddressCard
                label={`${currencyCode} ${walletMetaParamType!.toUpperCase()}`}
                value={walletMetaParam}
              />
            )}
          </div>
          {allowedNetworks.length > 1 ? (
            <TextInput
              label={getTranslation('NETWORK')}
              onInputContainerClick={handleNetworkFieldClick}
              value={currentNetwork?.networkName}
              endAdornment={
                <Icon
                  className="mr-1-5 ml-1-5"
                  size="sm"
                  name="triangleDownLg"
                  color="kindaBlack"
                />
              }
              inputContainerClassName="pointer"
              disabled
            />
          ) : null}
          <ImportantCard
            text={getImportantLabelForSendCrypto(currencyCode, currentNetwork.networkName)}
          />
        </>
      ) : null}
    </div>
  ) : null;
};

export default DepositCrypto;
