import { AxiosRequestConfig } from 'axios';

// import authServicesMocked from 'modules/auth/services/mocked';
import appConfig from 'constants/appConfig';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  AccessWithRefreshTokenResponse,
  AuthenticatorSecrets,
  DisableTwoFactorAuthRequest,
  LoginConfirmationResponse,
  LoginRequest,
  LoginResponse,
  LoginVerifyRequest,
  LoginWithOtpRequest,
  OtpCodeOnboardingPhoneSendReq,
  OtpCodeOnboardingPhoneVerifyReq,
  ResetTwoFactorAuthRequest,
  TwoFactorAuthReset,
  UserConfirmRegistrationRequest,
  UserConfirmRegistrationResponse,
  UserRegistrationRequest,
  UserRegistrationResponse,
  VerifyTwoFactorAuthRequest,
  VerifyTwoFactorAuthResponse,
} from 'libs/swagger/nebeusApiTypes';

import eventEmitter from 'utils/eventEmitter';

// cli-import

const authServices = {
  getAccessToken: () => localStorage.getItem(appConfig.accessTokenKey),
  getRefreshToken: () => localStorage.getItem(appConfig.refreshTokenKey),
  getRefreshTokenPayload: (): { exp: number; iat: number; sub: string } | null => {
    const token = localStorage.getItem(appConfig.refreshTokenKey);
    if (!token) {
      return null;
    }
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  },
  setAccessToken: (token: string) => localStorage.setItem(appConfig.accessTokenKey, token),
  setTokens: (access: string, refresh: string) => {
    localStorage.setItem(appConfig.accessTokenKey, access);
    localStorage.setItem(appConfig.refreshTokenKey, refresh);
  },
  clearAuthTokens: () => {
    localStorage.removeItem(appConfig.accessTokenKey);
    localStorage.removeItem(appConfig.refreshTokenKey);
  },

  async refreshToken() {
    localStorage.removeItem(appConfig.accessTokenKey);
    const token = this.getRefreshToken();

    if (!token) {
      throw new Error('Refresh is impossible');
    }

    const { accessToken, refreshToken } = await AxiosInstance.post<
      AccessWithRefreshTokenResponse,
      AccessWithRefreshTokenResponse
    >('/v1/login/token/refresh', {
      refreshToken: token,
    });

    if (accessToken && refreshToken) {
      this.setTokens(accessToken, refreshToken);
      eventEmitter.emit('REFRESH_TOKEN_REFRESHED');
    }
  },

  signIn: (
    payload: LoginRequest,
  ): Promise<{ sentToEmail: boolean; sentToPhone: boolean; hasPhoneNumber: boolean }> =>
    AxiosInstance.post<LoginConfirmationResponse, LoginConfirmationResponse>(
      '/v1/login',
      payload,
    ).then((response) => ({
      sentToEmail: response.action === 'EMAIL_SENT',
      sentToPhone: response.action === 'PHONE_SENT',
      hasPhoneNumber: response.hasPhoneNumber,
    })),
  signInConfirm: (
    payload: LoginWithOtpRequest,
    config?: AxiosRequestConfig,
  ): Promise<LoginResponse> => AxiosInstance.post('/v1/login/otp', payload, config),
  signUp: (payload: UserRegistrationRequest): Promise<UserRegistrationResponse> =>
    AxiosInstance.post('/v1/register', payload),

  signUpConfirm: (
    payload: UserConfirmRegistrationRequest,
    config?: AxiosRequestConfig,
  ): Promise<UserRegistrationResponse> =>
    AxiosInstance.post<UserConfirmRegistrationResponse, UserRegistrationResponse>(
      '/v1/register/confirm',
      payload,
      config,
    ),

  getTFASetupKey: (): Promise<AuthenticatorSecrets> => AxiosInstance.post('/v1/two-factor-auth'),
  verifyTFA: (payload: VerifyTwoFactorAuthRequest): Promise<VerifyTwoFactorAuthResponse> =>
    AxiosInstance.post<VerifyTwoFactorAuthResponse, VerifyTwoFactorAuthResponse>(
      '/v1/two-factor-auth/verify',
      payload,
    ),

  signInTFA: (payload: LoginVerifyRequest): Promise<LoginResponse> =>
    AxiosInstance.post<LoginResponse, LoginResponse>('/v1/login/verify', payload),

  disableTFA: (payload: DisableTwoFactorAuthRequest) =>
    AxiosInstance.post('/v1/two-factor-auth/disable', payload),
  resetTFA: (payload: ResetTwoFactorAuthRequest): Promise<TwoFactorAuthReset> =>
    AxiosInstance.post('/v1/two-factor-auth/reset', payload),
  sendOtpToPhoneNumber: (
    payload: OtpCodeOnboardingPhoneSendReq,
    config?: AxiosRequestConfig,
  ): Promise<void> => AxiosInstance.post('/v1/otp-code/onboarding/phone/send', payload, config),
  verifyPhoneNumberByOtp: (payload: OtpCodeOnboardingPhoneVerifyReq): Promise<void> =>
    AxiosInstance.post('/v1/otp-code/onboarding/phone/verify', payload),
  // cli-service
};

// export default authServicesMocked;
export default authServices;
