import { FC, useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { TextInputField } from 'components/form';
import { Icon } from 'components/ui';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup from 'libs/yup';

import { parseFiatAmount } from 'utils/inputParsers';

interface InvoiceServiceItemsFormProps {
  canRemove?: boolean;
}

interface InvoiceServiceItemFormValue {
  name: string;
  amount: string;
  quantity: string;
}

export interface InvoiceServiceItemsFormValues {
  invoiceServiceItems: InvoiceServiceItemFormValue[];
}

export const invoiceServiceItemsValidationSchema = yup.object().shape({
  invoiceServiceItems: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .min(2, getTranslation('VALIDATION_MIN', { min: 2 }))
        .max(40, getTranslation('VALIDATION_MAX', { max: 40 }))
        .requiredDefault(),
      quantity: yup.string().integer().requiredDefault(),
      amount: yup
        .number()
        .requiredDefault()
        .typeError(getTranslation('VALIDATION_FIELD_MUST_BE_NUMBER')),
    }),
  ),
});

const InvoiceServiceItemsForm: FC<InvoiceServiceItemsFormProps> = ({ canRemove }) => {
  const translate = useTranslation();

  const form = useForm<InvoiceServiceItemsFormValues>();
  const { values } = useFormState<InvoiceServiceItemsFormValues>();

  const handleRemove = useCallback(
    (itemIndex: number) => {
      form.change(
        'invoiceServiceItems',
        values.invoiceServiceItems.filter((_, index) => index !== itemIndex),
      );
    },
    [values, form],
  );

  return (
    <div className="column gap-3">
      {values.invoiceServiceItems.map((_, index) => (
        <div key={index} className="column gap-2">
          <Field
            name={`invoiceServiceItems[${index}].name`}
            placeholder={translate('INVOICE_PRODUCT_OF_SERVICE_WITH_NUMBER', { count: index + 1 })}
            component={TextInputField}
            endAdornment={
              canRemove && values.invoiceServiceItems.length > 1 ? (
                <div
                  className="pointer px-1-5"
                  onClick={() => {
                    handleRemove(index);
                  }}
                >
                  <Icon color="black40" size={16} name="crossSafe" />
                </div>
              ) : undefined
            }
          />
          <div className="row gap-2">
            <Field
              name={`invoiceServiceItems[${index}].quantity`}
              placeholder={translate('QUANTITY_SHORT')}
              component={TextInputField}
              inputMode="numeric"
            />
            <Field
              name={`invoiceServiceItems[${index}].amount`}
              placeholder={translate('AMOUNT')}
              component={TextInputField}
              inputMode="decimal"
              parse={parseFiatAmount}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default InvoiceServiceItemsForm;
