import { useEffect, useRef } from 'react';

import { scrollViewSelector } from 'constants/viewConstants';

const useScrollRef = (selector: string = scrollViewSelector) => {
  const scrollRef = useRef<HTMLDivElement | null>(document.querySelector(selector));

  useEffect(() => {
    scrollRef.current = document.querySelector(selector);
  }, [selector]);

  return scrollRef;
};

export default useScrollRef;
