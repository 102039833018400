import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { SimpleCurrencyPickerInput } from 'components/ui';
import { SimpleCurrencyPickerInputProps } from 'components/ui/SimpleCurrencyPickerInput';

import useField from 'hooks/useField';

import { CurrencyCode } from 'types';

interface SimpleCurrencyPickerInputFieldProps
  extends FieldRenderProps<CurrencyCode>,
    Omit<SimpleCurrencyPickerInputProps, 'currencyCode' | 'onChange'> {}

export const SimpleCurrencyPickerInputField: FC<SimpleCurrencyPickerInputFieldProps> = ({
  input,
  meta,
  ...props
}) => {
  const { hasError, error, valid } = useField(meta);

  return (
    <SimpleCurrencyPickerInput
      error={hasError && error}
      valid={valid}
      currencyCode={input.value}
      {...input}
      {...props}
    />
  );
};
