import { receivePreparedHtml } from 'modules/loans/constants/contracts/utils';

import { template } from './template';

export const getPreContract = (payload: {
  loanAmountCurrency: string;
  interestAmountCurrency: string;
  term: string;
  monthlyPayment: string;
  totalAmountCurrency: string;
  ltvPercent: string;
  annualPercent: string;
}) => receivePreparedHtml(template, payload);
