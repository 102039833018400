import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectPaymentWithdrawLimits } from 'modules/payment/store/selectors';
import { PaymentOperationId } from 'modules/payment/types';

import { CurrencyCode } from 'types';

const useWithdrawLimits = (currencyCode: CurrencyCode, operationId: PaymentOperationId) => {
  const allWithdrawLimits = useSelector(selectPaymentWithdrawLimits);

  const withdrawalLimits = useMemo(
    () =>
      allWithdrawLimits.find(
        (l) => l.currencyCode === currencyCode && l.operationCode === operationId,
      ),
    [allWithdrawLimits, currencyCode, operationId],
  );

  return withdrawalLimits;
};

export default useWithdrawLimits;
