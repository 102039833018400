import { toast } from 'react-toastify';

import { getTranslation } from 'libs/i18n';

export const errorToast = (message: string = getTranslation('ERROR')) => {
  toast.error(message);
};
export const successToast = (message: string = getTranslation('SUCCESS')) => {
  toast.success(message);
};
