import clsx from 'clsx';

import { FC, useMemo } from 'react';

import useWallets from 'modules/accounts/hooks/useWallets';

import { AmountLabel, CurrencyIcon, Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { findWallet, formatCurrencyWithLabel, isFiat } from 'utils/currency';

import { CurrencyCode, voidFunc } from 'types';

import classes from './BalanceCard.module.scss';

interface BalanceCardProps {
  isDigitalWallet?: boolean;
  currencyCode: CurrencyCode;
  onClick?: voidFunc;
  disabled?: boolean;
}
export const BalanceCard: FC<BalanceCardProps> = ({
  isDigitalWallet,
  currencyCode,
  onClick,
  disabled,
}) => {
  const translate = useTranslation();

  const { wallets } = useWallets({ isDigitalAccount: isDigitalWallet });

  const wallet = useMemo(() => findWallet(wallets, currencyCode), [currencyCode, wallets]);

  return wallet ? (
    <div
      className={clsx(classes.root, disabled && 'disabled')}
      onClick={disabled ? undefined : onClick}
    >
      <div className="row gap-1-5">
        <CurrencyIcon code={currencyCode} size={32} />
        <div className="column gap-1">
          <span className="label">{translate('CURRENCY_ACCOUNT_BALANCE', { currencyCode })}</span>
          <div className={classes.balanceLabels}>
            <AmountLabel
              className="flex-shrink-0"
              amount={wallet.amount}
              currencyCode={wallet.currencyCode}
              showCurrencySymbol
            />
            {!isFiat(currencyCode) && (
              <>
                <span className={classes.amountInDefaultCurrency}>/</span>
                <span className={classes.amountInDefaultCurrency}>
                  {formatCurrencyWithLabel(
                    wallet.amountInDefaultCurrency,
                    wallet.defaultCurrencyCode,
                  )}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <Icon name="chevronRight" size={24} color="black40" />
    </div>
  ) : null;
};
