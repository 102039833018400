import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import {
  selectBankCardsOrders,
  selectIsCryptoBankCardAllowed,
} from 'modules/cryptoBankCard/store/selectors';

import BankCardFeaturesList from 'components/common/bankCards/BankCardFeaturesList';
import { Button, Icon, Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './BankCardsDescription.module.scss';

export interface BankCardsDescriptionProps {}
const BankCardsDescription: FC<BankCardsDescriptionProps> = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const cryptoBankCardsOrders = useSelector(selectBankCardsOrders);
  const cryptoBankCardAllowed = useSelector(selectIsCryptoBankCardAllowed);

  const handleContinue = useCallback(async () => {
    drawer.open(cryptoBankCardDrawerTemplates.orderBankCardChooseType());
  }, [drawer]);

  const handleExploreClick = useCallback(() => {
    drawer.open(
      commonDrawerTemplates.bankCardsFeaturesExplore(
        { cardType: 'crypto' },
        {
          navigationHeaderLabel: translate('CRYPTO_CARD_FEATURES'),
        },
      ),
    );
  }, [drawer, translate]);

  const [cryptoPhysicalCardOrder, cryptoVirtualCardOrder] = useMemo(
    () => [
      cryptoBankCardsOrders.find((o) => !o.isVirtual && o.preOrderedStatus !== 'failed'),
      cryptoBankCardsOrders.find((o) => o.isVirtual && o.preOrderedStatus !== 'failed'),
    ],
    [cryptoBankCardsOrders],
  );

  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);

  return !cryptoBankCardAllowed ? null : (
    <div className="column flex-1 gap-3 mt-1">
      <div className={classes.content}>
        <div className={classes.carouselCard}>
          <Swiper
            onSwiper={setSwiperInstance}
            className={classes.slider}
            spaceBetween={31}
            centeredSlides
            slidesPerView={1}
            initialSlide={0}
          >
            <SwiperSlide className={classes.slide} key="cryptoPlastic">
              <div className="column gap-6 aic">
                <Image className={classes.cardImage} name="cryptoBankCard" path="cryptoBankCard" />
                <h4>{translate('NEBEUS_CRYPTO_CARD')}</h4>
              </div>
            </SwiperSlide>

            {swiperInstance && swiperInstance.slides.length > 1 ? (
              <>
                <div className={classes.pagination}>
                  {new Array(swiperInstance.slides.length).fill(null).map((_, i) => (
                    <div key={i} className={clsx(classes.paginationDot)} />
                  ))}
                </div>
                <div className={classes.arrowsContainer}>
                  <Icon
                    className={clsx(classes.arrow)}
                    onClick={() => swiperInstance?.slidePrev()}
                    name="arrowLeftSafe"
                  />
                  <Icon
                    className={clsx(classes.arrow)}
                    onClick={() => swiperInstance?.slideNext()}
                    name="arrowRightSafe"
                  />
                </div>
              </>
            ) : null}
          </Swiper>
        </div>
        <div className={classes.outlinedCard}>
          <div className="row jcsb aic">
            <span className="label">{translate('BANK_CARD_FEATURES')}</span>
            <span onClick={handleExploreClick} className="label cyanBlue pointer">
              {translate('EXPLORE')}
            </span>
          </div>
          <BankCardFeaturesList />
        </div>
      </div>
      <Button
        disabled={!!cryptoPhysicalCardOrder && !!cryptoVirtualCardOrder}
        variant="greyishGreen"
        fullWidth
        onClick={handleContinue}
      >
        {translate(
          cryptoVirtualCardOrder && cryptoPhysicalCardOrder
            ? 'CRYPTO_BANK_CARD_ALREADY_EXIST'
            : 'CONTINUE',
        )}
      </Button>
    </div>
  );
};

export default BankCardsDescription;
