import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { selectSavedCryptoWalletsReducer } from 'modules/payment/store/selectors';
import { requestRemoveCryptoWallet, requestSavedCryptoWallets } from 'modules/payment/store/thunks';
import { SavedCryptoWallet } from 'modules/payment/types';
import { useDispatch } from 'store';

import { CurrencyIcon, Icon, MenuList, Select, Skeleton } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import useFlag from 'hooks/useFlag';
import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import classes from './CryptoWallets.module.scss';

enum WalletOptions {
  edit,
  remove,
}

interface Option {
  id: WalletOptions;
  label: string;
}

const labelAccessor = (value: Option) => value.label;

export const CryptoWallets = () => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const [optionsButtonNode, setOptionsButtonNode] = useState<HTMLDivElement | null>(null);

  const walletOptionsSelect = useFlag(false);
  const walletOptionId = useRef<SavedCryptoWallet['id'] | null>(null);

  const cryptoWallets = useSelector(selectSavedCryptoWalletsReducer);

  useStoreEntity(selectSavedCryptoWalletsReducer, requestSavedCryptoWallets);

  const walletOptions = useMemo<Option[]>(
    () => [
      { id: WalletOptions.edit, label: translate('EDIT') },
      { id: WalletOptions.remove, label: translate('REMOVE') },
    ],
    [translate],
  );

  const menu = useMemo<MenuListItem[]>(() => {
    const result = cryptoWallets.data.map(
      (w): MenuListItem => ({
        forwardRef: setOptionsButtonNode,
        handler: () => {
          walletOptionId.current = w.id;
          walletOptionsSelect.on();
        },
        label: w.address,
        description: w.label,
        endAdornment: <Icon name="dots" size="sm" color="black40" />,
        startAdornment: (
          <div className={classes.currencyIconContainer}>
            <CurrencyIcon size={16} code={w.currencyCode} />
          </div>
        ),
      }),
    );

    if (!cryptoWallets.data.length) {
      result.push({
        icon: 'addWalletCard',
        label: translate('PAYMENT_SAVED_WALLETS_ADD_WALLET'),
        arrowNext: true,
        handler: () => drawer.open(paymentDrawerTemplates.addEditSavedCryptoWallet()),
      });
    }

    return result;
  }, [walletOptionsSelect, translate, cryptoWallets.data, drawer]);

  const handlePickOption = useCallback(
    async (value: Option) => {
      if (!walletOptionId.current) {
        return;
      }
      switch (value.id) {
        case WalletOptions.edit: {
          drawer.open(
            paymentDrawerTemplates.addEditSavedCryptoWallet({
              walletIdForEditing: walletOptionId.current,
            }),
          );
          break;
        }
        case WalletOptions.remove: {
          const { success } = await dispatch(requestRemoveCryptoWallet(walletOptionId.current));
          if (success) {
            successToast(translate('PAYMENT_SAVED_WALLETS_SUCCESS_REMOVED'));
          }
          break;
        }
      }
    },
    [dispatch, drawer, translate],
  );

  return (
    <div className="column gap-1-5">
      <div className="row aic gap-3 jcsb">
        <span className="label">{translate('PROFILE_SAVED_CRYPTO_WALLETS_TITLE')}</span>
        {cryptoWallets.data.length ? (
          <span
            onClick={() => drawer.open(paymentDrawerTemplates.addEditSavedCryptoWallet())}
            className="label blue pointer"
          >
            {translate('PAYMENT_SAVED_WALLETS_ADD_WALLET')}
          </span>
        ) : null}
      </div>
      {cryptoWallets.meta.loading && !cryptoWallets.meta.loaded ? (
        <Skeleton height={80} borderRadius={12} />
      ) : (
        <>
          <MenuList menu={menu} />
          <Select<Option>
            isOpen={walletOptionsSelect.state}
            onClose={walletOptionsSelect.off}
            options={walletOptions}
            onPick={handlePickOption}
            anchorEl={optionsButtonNode}
            labelAccessor={labelAccessor}
            keyAccessor={labelAccessor}
          />
        </>
      )}
    </div>
  );
};
