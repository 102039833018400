import { addMonths } from 'date-fns';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { translationKeyTitleBySubType } from 'modules/digitalAccount/constants/config';
import {
  selectActiveDigitalSubscription,
  selectFreeDigitalSubscription,
} from 'modules/digitalAccount/store/selectors';
import { requestCreateSubscription } from 'modules/digitalAccount/store/thunks';
import { SubscriptionCheckCard } from 'modules/digitalAccount/views/components/SubscriptionCheckCard';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { Button, Icon, Mark } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatDDMMYY_HHMM } from 'utils/date';

import classes from './DowngradeSubscriptionToStandard.module.scss';

const DowngradeSubscriptionToStandard = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const sidebar = useSideBar();

  const currentSubscription = useSelector(selectActiveDigitalSubscription);

  const freeSubscription = useSelector(selectFreeDigitalSubscription);

  const loading = useFlag(false);
  const onPressDowngrade = useCallback(async () => {
    loading.on();
    const { success, data } = await dispatch(
      requestCreateSubscription({
        id: freeSubscription!.id,
      }),
    );
    loading.off();
    if (success) {
      sidebar.replaceAll(
        ...sidebarTemplates.digitalAccountGetSubscriptionSuccess({
          subscription: data,
          showWalletDetails: false,
        }),
      );
    }
  }, [dispatch, freeSubscription, loading, sidebar]);
  return (
    <div>
      <div>
        <div className={classes.titles}>
          <h4>{translate('DIGITAL_ACCOUNT_DOWNGRADE_TO_STD_TITLE')}</h4>
          <p>{translate('DIGITAL_ACCOUNT_DOWNGRADE_TO_STD_DESC')}</p>
        </div>
        {currentSubscription ? (
          <div className={classes.card}>
            <Mark
              className="flex gap-0-5"
              variant="white"
              startAdornment={<Icon name="cross" size={15} color="white" />}
            >
              {translate('DIGITAL_ACCOUNT_DOWNGRADE_TO_STD_TITLE')}
            </Mark>
            <div>
              <div className={classes.subTitles}>
                <span>
                  Nebeus {translate(translationKeyTitleBySubType[currentSubscription.type])}
                </span>
                <span>{translate('DIGITAL_ACCOUNT')}</span>
              </div>
              {currentSubscription.startDate && (
                <span className={classes.subDesc}>
                  {translate('DA_SUBSCRIPTION_CANCEL_DESC', {
                    endDate: formatDDMMYY_HHMM(
                      new Date(addMonths(new Date(currentSubscription.startDate), 1)),
                    ),
                  })}
                </span>
              )}
            </div>
            <SubscriptionCheckCard
              className="mt-2"
              variant="blurred"
              subscription={currentSubscription}
            />
          </div>
        ) : null}
      </div>
      <div className="column mt-4 gap-2">
        <Button
          loading={loading.state}
          fullWidth
          variant="outlinedWhite"
          onClick={onPressDowngrade}
        >
          {translate('DOWNGRADE_SUBSCRIPTION')}
        </Button>
        <Button fullWidth onClick={sidebar.pop} variant="outlinedWhite">
          {translate('BACK')}
        </Button>
        <AgreementText variant="modulR" light />
      </div>
    </div>
  );
};

export default DowngradeSubscriptionToStandard;
