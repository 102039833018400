export const parseNumbers = (value: string) => value.replace(/\D/g, '');

export const parseDots = (value: string): string => {
  let parsedValue = value.replace(',', '.');
  if (parsedValue.split('.')?.length === 3) {
    parsedValue = parsedValue.slice(0, -1);
  }
  if (parsedValue[0] === '.') {
    parsedValue = `0${parsedValue}`;
  }
  if (parsedValue[0] === '0' && parsedValue[1] && parsedValue[1] !== '.') {
    parsedValue = parsedValue.slice(0, -1);
  }
  return parsedValue;
};

export const parseAmount = (value: string, isFiat: boolean): string => {
  let parsedValue = parseDots(value);
  const accuracy = isFiat ? 2 : 6;

  parsedValue = parsedValue.replace(/[^0-9.]/g, '');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dec] = parsedValue.split('.');

  if (dec?.length > accuracy) {
    return Number(parsedValue).toFixed(accuracy);
  }
  return parsedValue;
};

export const parseFiatAmount = (value: string) => parseAmount(value, true);
export const parseFixEmptyString = (value: string) => value;

export const parsePhoneNumber = (value: string | undefined) => {
  if (!value) {
    return '+';
  }
  if (value.startsWith('+')) {
    return value;
  }
  return `+${value}`;
};

export const parseSortCode = (value: string) => {
  return value
    .replaceAll('-', '')
    .match(/.{1,2}/g)
    ?.join('-');
};
