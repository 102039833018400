import { createSlice } from '@reduxjs/toolkit';

import { generateRequestDataReducer } from 'store/generators/reducers';
import { initialArrayApiRequestState } from 'store/generators/utils';
import { initialApiRequestState } from 'store/generators/utils';

import { StakingItem, StakingState, Template } from '../types';
import { Statistic } from '../types';

// cli-import

const initialState: StakingState = {
  templates: initialArrayApiRequestState,
  stakingItems: initialArrayApiRequestState,
  statistic: initialApiRequestState,
  // cli-state
};

const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    updateTemplates: generateRequestDataReducer<StakingState, Template[]>({
      reducerPath: 'templates',
    }),
    updateStakingItems: generateRequestDataReducer<StakingState, StakingItem[]>({
      reducerPath: 'stakingItems',
    }),
    updateStatistic: generateRequestDataReducer<StakingState, Statistic>({
      reducerPath: 'statistic',
    }),
    // cli-reducer
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export default stakingSlice.reducer;
export const stakingActions = stakingSlice.actions;
