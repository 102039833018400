import { FC } from 'react';

import { InfoIcon } from 'components/ui';

import classes from './AnswerSidebar.module.scss';

export interface AnswerSidebarProps {
  title?: string;
  description?: string;
  stepsConfig?: {
    title?: string;
    steps: string[];
  };
}

const AnswerSidebar: FC<AnswerSidebarProps> = ({ title, description, stepsConfig }) => {
  return (
    <div className={classes.content}>
      <InfoIcon className="mb-2" />
      {title && <h3>{title}</h3>}
      {description && (
        <p
          className={title ? 'mt-1' : undefined}
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      )}
      {stepsConfig && (
        <div className="mt-3">
          {stepsConfig.title && <span className="label">{stepsConfig.title}</span>}
          <div className="mt-2 gap-2 column">
            {stepsConfig.steps.map((text, index) => (
              <div key={text} className="row gap-1-5">
                <span className={classes.stepNumber}>{index + 1}.</span>
                <p>{text}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AnswerSidebar;
