import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useHistoryRates from 'modules/exchange/hooks/useHistoryRates';
import { selectRatesHistory } from 'modules/exchange/store/selectors';
import { requestRatesHistory } from 'modules/exchange/store/thunks';
import { useDispatch } from 'store';

import { CurrenciesDirection, CurrencyCode } from 'types';

const useHistoryRateChart = (
  from: CurrencyCode,
  to: CurrencyCode,
  options: { fetchHistory?: boolean } = { fetchHistory: true },
) => {
  const dispatch = useDispatch();

  const histories = useSelector(selectRatesHistory);

  const history = useMemo(
    () => histories[(from + '/' + to) as CurrenciesDirection]?.rates,
    [from, to, histories],
  );

  useEffect(() => {
    if (options?.fetchHistory === false) {
      return;
    }
    if (!history?.length) {
      dispatch(requestRatesHistory({ from: [from], to: [to] }));
    }
    // eslint-disable-next-line
  }, [history?.length]);

  const meta = useHistoryRates(history || []);

  return { ...meta, history: history || [] };
};

export default useHistoryRateChart;
