import { createSelector } from '@reduxjs/toolkit';
import { maxBy } from 'lodash';

import { AppStore } from 'store';

export const selectStakingTemplatesReducer = (state: AppStore) => state.staking.templates;
export const selectStakingTemplates = (state: AppStore) => state.staking.templates.data;
export const selectStakingTemplatesMaxRPY = createSelector(
  selectStakingTemplates,
  (templates) => maxBy(templates, 'percentRPY')?.percentRPY || 7.5,
);
export const selectAllowedStakingCurrencies = createSelector(selectStakingTemplates, (templates) =>
  templates.map((t) => t.currencyCode),
);

export const selectStakingItemsReducer = (state: AppStore) => state.staking.stakingItems;
export const selectStakingItems = (state: AppStore) => state.staking.stakingItems.data;

export const selectStatisticReducer = (state: AppStore) => state.staking.statistic;
