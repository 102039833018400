import clsx from 'clsx';

import { useCallback, useMemo } from 'react';
import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import useSideBarIsOpen from 'modules/app/hooks/useSideBarIsOpen';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { isReactNative } from 'modules/reactNative/utils';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';

import { NAV_BAR_SHOWED_MIN_WIDTH, TABLET_MAX_WIDTH } from 'constants/responsive';
import routesByName from 'constants/routesByName';

import { UserAvatar } from 'components/common';
import { Icon, Image } from 'components/ui';

import classes from './Header.module.scss';

const BurgerButton = () => {
  const drawer = useDrawer();
  const getIsOpen = useSideBarIsOpen();

  const toggleNavBar = useCallback(() => {
    if (getIsOpen('navBar')) {
      drawer.pop();
    } else {
      drawer.open(commonDrawerTemplates.navigationBar());
    }
  }, [getIsOpen, drawer]);

  const isOpen = getIsOpen('navBar');
  return (
    <button onClick={toggleNavBar} className={clsx(classes.button, isOpen && classes.opened)} />
  );
};
const Header = () => {
  const drawer = useDrawer();
  const getIsOpen = useSideBarIsOpen();

  const isSettingsOpen = useMemo(() => getIsOpen('settings'), [getIsOpen]);

  const handleClickAvatar = useCallback(() => {
    drawer.open(userDrawerTemplates.settings());
  }, [drawer]);

  const handleClickNotifications = useCallback(() => {
    drawer.open(userDrawerTemplates.notifications());
  }, [drawer]);

  if (isReactNative) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className="row aic gap-2">
          <MediaQuery maxWidth={TABLET_MAX_WIDTH}>
            <BurgerButton />
          </MediaQuery>
          <NavLink to={routesByName.dashboard}>
            <Image className={classes.logo} name="nebeusLogoWhite" type="svg" />
          </NavLink>
        </div>
        <div className={classes.rightSide}>
          <Icon
            onClick={handleClickNotifications}
            className={classes.bell}
            name="bell"
            color="creamyWhite"
          />
          <UserAvatar active={isSettingsOpen} onClick={handleClickAvatar} />
          <MediaQuery maxWidth={NAV_BAR_SHOWED_MIN_WIDTH} minWidth={TABLET_MAX_WIDTH + 1}>
            <BurgerButton />
          </MediaQuery>
        </div>
      </div>
    </div>
  );
};

export default Header;
