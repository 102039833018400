import { Button, InfoIcon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { openContactSupport } from 'utils/thirdPartyLibs';

import classes from './BlockedCard.module.scss';

export const BlockedCard = () => {
  const translate = useTranslation();

  return (
    <div className={classes.root}>
      <div className="row gap-2">
        <InfoIcon symbol="!" className={classes.icon} />
        <p>{translate('BANK_CARD_BLOCKED_TEXT')}</p>
      </div>
      <Button onClick={openContactSupport} fullWidth>
        {translate('CONTACT_SUPPORT')}
      </Button>
    </div>
  );
};
