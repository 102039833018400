import { useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import vaultDrawerTemplates from 'modules/vault/constants/drawerTemplates';

import { Icon } from 'components/ui';

import useTablet from 'hooks/useTablet';

import { useTranslation } from 'libs/i18n';

import classes from './OperationButtons.module.scss';

export const OperationButtons = () => {
  const drawer = useDrawer();
  const translate = useTranslation();
  const isTablet = useTablet();

  const buttons = useMemo(
    () => [
      {
        icon: 'arrowUpSquared',
        label: translate(isTablet ? 'WITHDRAW' : 'VAULT_WITHDRAW'),
        deposit: false,
      },
      {
        icon: 'arrowDownSquared',
        label: translate(isTablet ? 'DEPOSIT' : 'VAULT_DEPOSIT'),
        deposit: true,
      },
    ],
    [isTablet, translate],
  );

  return (
    <div className={classes.root}>
      {buttons.map((b) => (
        <div
          onClick={() => {
            drawer.open(vaultDrawerTemplates.vaultDepositWithdraw({ deposit: b.deposit }));
          }}
          key={b.icon}
          className={classes.button}
        >
          <Icon className={classes.icon} name={b.icon} size={32} />
          <span className={classes.label}>{b.label}</span>
        </div>
      ))}
    </div>
  );
};
