import { format } from 'date-fns';

export const DATE_FORMAT_DD_MM_YYYY = 'dd.MM.yyyy';
export const DATE_FORMAT_DD_MM_YY_HH_MM_AMPM = 'dd.MM.yyyy, hh:mm aaa';
export const DATE_FORMAT_DD_MM_YY_HH_MM = 'dd.MM.yyyy, HH:mm';
export const DATE_FORMAT_HH_MM = 'HH:mm';
export const DATE_FORMAT_HH_MM_AMPM = 'hh:mm aaa';

export const formatDDMMYY = (date: Date | number) => format(date, DATE_FORMAT_DD_MM_YYYY);

export const formatDDMMYY_HHMM_AMPM = (date: Date | number) =>
  format(date, DATE_FORMAT_DD_MM_YY_HH_MM_AMPM);
export const formatDDMMYY_HHMM = (date: Date | number) => format(date, DATE_FORMAT_DD_MM_YY_HH_MM);

export const formatHHMM = (date: Date | number) => format(date, DATE_FORMAT_HH_MM);
export const formatHHMM_AMPM = (date: Date | number) => format(date, DATE_FORMAT_HH_MM_AMPM);
