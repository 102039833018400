import clsx from 'clsx';

import { FC, SyntheticEvent, useCallback } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import { SideBarProps } from 'modules/app/types';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';

import { Icon } from 'components/ui/Icon';
import { LineDelimiter } from 'components/ui/LineDelimiter';

import classes from './DescriptionList.module.scss';

export interface DescriptionListItem {
  icon: string;
  title: string;
  modalTitle?: string;
  buttonTitle?: string;
  description: string;
}
interface DescriptionListProps {
  className?: string;
  list: DescriptionListItem[];
  sideBarProps?: Partial<SideBarProps>;
}

export const DescriptionList: FC<DescriptionListProps> = ({ className, list, sideBarProps }) => {
  const sidebar = useSideBar();

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      sidebar.open(
        ...sidebarTemplates.descriptionListCarouselSidebar(
          {
            list,
            activeIndex: +(event.currentTarget.dataset.index || 0),
          },
          sideBarProps,
        ),
      );
    },
    [list, sideBarProps, sidebar],
  );

  return (
    <div className={clsx(className, classes.root)}>
      {list.map((i, index) => (
        <div key={i.icon}>
          {index !== 0 && <LineDelimiter className={classes.delimiter} />}
          <div
            onClick={handleClick}
            data-index={index}
            className="row aic jcsb py-1-5 gap-1 pointer"
          >
            <div className="row aic gap-1">
              <Icon name={i.icon} color="creamyWhite" />
              <span className={classes.title}>{i.title}</span>
            </div>
            <Icon name="chevronRight" size="sm" color="creamyWhite" />
          </div>
        </div>
      ))}
    </div>
  );
};
