import { random, times } from 'lodash';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import { selectAppLanguage } from 'modules/app/store/selectors';
import { requestGetQuestionnaire, requestSubmitQuestionnaire } from 'modules/user/store/thunks';
import { UserQuestionnaire } from 'modules/user/types';
import { useDispatch } from 'store';

import { Button, CheckBox, ProgressBar, Skeleton, TextInput } from 'components/ui';

import useEntity from 'hooks/useEntity';
import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import classes from './Questionnaire.module.scss';

const parseTextWithLocalization = (text: string, languageIndex: number) =>
  text.includes('/') ? text.split('/')[languageIndex] : text;

interface AnswerProps {
  selected: boolean;
  onChange: (payload: { id: string; answer: string | null }) => void;
  questionId: string;
  answer: string;
  languageIndex: number;
}
const Answer: FC<AnswerProps> = ({ selected, onChange, questionId, answer, languageIndex }) => {
  return (
    <div
      className={classes.answer}
      onClick={() => {
        onChange({ id: questionId, answer: selected ? null : answer });
      }}
    >
      <CheckBox rounded checked={selected} />
      <span>{parseTextWithLocalization(answer, languageIndex)}</span>
    </div>
  );
};

export interface QuestionnaireProps {
  id: UserQuestionnaire['id'];
}

const Questionnaire: FC<QuestionnaireProps> = ({ id }) => {
  const appLanguage = useSelector(selectAppLanguage);

  const dispatch = useDispatch();
  const drawer = useDrawer();
  const translate = useTranslation();

  const languageIndex = appLanguage.code === 'ENG' ? 0 : 1;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [answers, setAnswers] = useState<{
    [id: string]: string | null;
  }>({});

  const {
    entity: questionnaire,
    loading,
    error,
  } = useEntity(() => dispatch(requestGetQuestionnaire(id)), { fetchOnMount: true });

  useEffect(() => {
    if (error) {
      drawer.pop({ key: 'questionnaire' });
    }
    // eslint-disable-next-line
  }, [error]);

  const questions = useMemo(() => {
    if (!questionnaire) {
      return [];
    }
    return questionnaire.questions.filter((q) => {
      if (!q.dependsOn) {
        return true;
      }
      return answers[q.dependsOn.questionId] === q.dependsOn.hasValue;
    });
  }, [answers, questionnaire]);

  const currentQuestion = useMemo(
    () => questions[currentQuestionIndex],
    [questions, currentQuestionIndex],
  );

  const currentAnswer = useMemo(() => {
    const currentQuestionId = currentQuestion?.id;

    return answers[currentQuestionId];
  }, [answers, currentQuestion]);

  const updateAnswer = useCallback(({ id, answer }: { id: string; answer: string | null }) => {
    setAnswers((prev) => ({ ...prev, [id]: answer }));
  }, []);

  const [hasPrev, hasNext] = useMemo(() => {
    return [currentQuestionIndex !== 0, currentQuestionIndex !== questions.length - 1];
  }, [currentQuestionIndex, questions]);

  const handlePressBack = useCallback(() => {
    setCurrentQuestionIndex((prev) => (prev === 0 ? 0 : prev - 1));
  }, []);

  const handlePressNext = useCallback(() => {
    if (currentQuestionIndex < questions.length) {
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  }, [currentQuestionIndex, questions]);

  const submitting = useFlag(false);

  const handleSubmit = useCallback(async () => {
    if (!questionnaire) {
      return;
    }
    submitting.on();
    const { success } = await dispatch(
      requestSubmitQuestionnaire({
        id: questionnaire.id,
        payload: {
          answers: Object.entries(answers).map(([key, value]) => ({
            answer: value as string,
            questionId: key,
          })),
        },
      }),
    );

    if (success) {
      successToast(translate('SUCCESS!'));
    }

    drawer.pop({ key: 'questionnaire' });

    submitting.off();
  }, [answers, submitting, drawer, dispatch, translate, questionnaire]);

  if (loading) {
    return (
      <div className="column flex-1 jcsb gap-3">
        <div className="column gap-2">
          <Skeleton height={16} borderRadius={16} />
          <Skeleton width={200} height={32} />
          <div className="column gap-1-5">
            {times(7, (i) => (
              <div key={i} className="row gap-1">
                <Skeleton width={24} height={24} borderRadius={12} />
                <Skeleton width={random(80, 300, false)} height={24} />
              </div>
            ))}
          </div>
        </div>
        <div className="row gap-1-5">
          <Skeleton height={48} />
          <Skeleton height={48} />
        </div>
      </div>
    );
  }

  if (!questionnaire) {
    return null;
  }

  return (
    <div className="column flex-1 jcsb gap-3">
      <div className={classes.content}>
        <ProgressBar minValue={0} maxValue={questions.length - 1} value={currentQuestionIndex} />
        <div className={classes.question}>
          <h6>{parseTextWithLocalization(currentQuestion.question, languageIndex)}</h6>
          {currentQuestion.allowedAnswers ? (
            <div className={classes.answers}>
              {currentQuestion.allowedAnswers.map((a) => (
                <Answer
                  key={a}
                  selected={answers[currentQuestion.id] === a}
                  questionId={currentQuestion.id}
                  onChange={updateAnswer}
                  languageIndex={languageIndex}
                  answer={a}
                />
              ))}
            </div>
          ) : (
            <TextInput
              value={currentAnswer || ''}
              onChange={(e) =>
                updateAnswer({ id: currentQuestion.id, answer: e.currentTarget.value })
              }
              placeholder={parseTextWithLocalization(currentQuestion.question, languageIndex)}
            />
          )}
        </div>
      </div>
      <div className="row gap-1-5">
        {hasPrev && (
          <Button onClick={handlePressBack} fullWidth variant="creamyBlack">
            {translate('BACK')}
          </Button>
        )}
        {hasNext && (
          <Button
            fullWidth
            variant="creamyBlack"
            onClick={handlePressNext}
            disabled={!currentAnswer}
          >
            {translate('NEXT')}
          </Button>
        )}
        {!hasNext && (
          <Button
            loading={submitting.state}
            onClick={handleSubmit}
            disabled={!currentAnswer}
            fullWidth
          >
            {translate('SUBMIT')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Questionnaire;
