import clsx from 'clsx';

import { FC } from 'react';

import { CurrencyIcon } from 'components/ui';

import { CurrencyCode } from 'types';

import classes from './OperationListItem.module.scss';

export interface OperationListItemProps {
  label: string;
  labelCurrencyCode?: CurrencyCode;
  value: string;
  valueCurrencyCode?: CurrencyCode;
  subValue?: string;
  className?: string;
}

export const OperationListItem: FC<OperationListItemProps> = ({
  labelCurrencyCode,
  label,
  valueCurrencyCode,
  value,
  subValue,
  className,
}) => {
  return (
    <div className={clsx(className, classes.root)}>
      <div className="row ">
        {labelCurrencyCode && (
          <CurrencyIcon className={classes.leftCurrency} size={13} code={labelCurrencyCode} />
        )}
        <span className={classes.label}>{label}:</span>
      </div>
      <div className="tar">
        <div className="row aic">
          <span className={classes.value}>{value}</span>
          {valueCurrencyCode && (
            <CurrencyIcon className={classes.rightCurrency} size={13} code={valueCurrencyCode} />
          )}
        </div>
        <span className={classes.subValue}>{subValue}</span>
      </div>
    </div>
  );
};
