import { smartTraderActions } from '../store';
import { generateRequestThunk } from 'store/generators/thunks';
import { generateRequestDataThunk } from 'store/generators/thunks';

import { CurrenciesDirection } from 'types';

import smartTraderServices from '../services';
import { SmartTrade } from '../types';

// cli-import

export const requestAllowedDirections = generateRequestDataThunk<CurrenciesDirection[]>({
  updateState: smartTraderActions.updateAllowedDirections,
  request: smartTraderServices.getAvailableDirections,
  options: { showErrorToast: false },
});
export const requestTrades = generateRequestDataThunk<SmartTrade[]>({
  updateState: smartTraderActions.updateTrades,
  request: smartTraderServices.getTrades,
  options: { showErrorToast: false },
});
export const requestCreateTrade = generateRequestThunk({
  request: smartTraderServices.createTrade,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestTrades());
    },
  },
});
export const requestTransactionHistory = generateRequestThunk({
  request: smartTraderServices.getTransactionHistory,
  options: { showErrorToast: false },
});
export const requestCommitExchangeOperation = generateRequestThunk({
  request: smartTraderServices.commitExchangeOperation,
  options: { showErrorToast: false },
});

// cli-thunk
