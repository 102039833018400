import { useCallback, useEffect, useRef, useState } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import authServices from 'modules/auth/services';
import { logout } from 'modules/auth/store/externalThunks';
import { useDispatch } from 'store';

import { Button, Image } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';
import { errorToast } from 'libs/toast';

const SessionExpiredWarning = () => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const [seconds, setSeconds] = useState(60);

  const intervalId = useRef<number | null>(null);

  useEffect(() => {
    intervalId.current = window.setInterval(() => {
      setSeconds((prev) => Math.max(prev - 1, 0));
    }, 1000);

    return () => {
      if (intervalId.current) {
        window.clearInterval(intervalId.current);
      }
    };
  }, []);

  const proceedLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (seconds <= 0) {
      proceedLogout();
    }
    // eslint-disable-next-line
  }, [seconds]);

  const loading = useFlag(false);

  const handleContinue = useCallback(async () => {
    try {
      if (intervalId.current) {
        window.clearInterval(intervalId.current);
      }
      loading.on();
      await authServices.refreshToken();
      loading.off();
      drawer.pop({ key: 'sessionExpiredWarning' });
    } catch (e) {
      errorToast(translate('SESSION_EXPIRED_WARNING_UNABLE_TO_CONTINUE'));
      dispatch(logout());
    }
  }, [loading, drawer, translate, dispatch]);

  return (
    <div className="column gap-3 pt-2 column flex-1 jcsb">
      <div>
        <h3>{translate('SESSION_EXPIRED_WARNING_TITLE')}</h3>
        <p>
          {translate('SESSION_EXPIRED_WARNING_SUBTITLE', {
            seconds,
          })}
        </p>
      </div>

      <Image name="cloud" path="common" className="w75 m-0-auto" />

      <Button onClick={handleContinue} fullWidth loading={loading.state}>
        {translate('SESSION_EXPIRED_WARNING_CONTINUE_BUTTON')}
      </Button>
    </div>
  );
};

export default SessionExpiredWarning;
