import { ReactNativeState } from 'modules/reactNative/types';
import {
  isReactNativeSupportBioVerification,
  sendToReactNative,
} from 'modules/reactNative/utils/index';

import { TranslationKey } from 'libs/i18n';

import eventEmitter from 'utils/eventEmitter';

import { voidFunc } from 'types';

export const getBioTypeLabelKey = (bioType?: ReactNativeState['bioType']): TranslationKey => {
  switch (bioType) {
    case 'FaceID': {
      return 'APP_VERIFICATION_FACE_ID';
    }
    case 'TouchID': {
      return 'APP_VERIFICATION_TOUCH_ID';
    }
    case 'Biometrics': {
      return 'APP_VERIFICATION_BIOMETRY';
    }
    default: {
      return 'APP_VERIFICATION_BIOMETRY';
    }
  }
};

export const appVerificationService = {
  checkBioVerification: (payload?: {
    turnOff?: boolean;
    turnOffBio?: boolean;
  }): Promise<boolean> | boolean => {
    if (isReactNativeSupportBioVerification) {
      const idTimestamp = Date.now();
      sendToReactNative('CHECK_BIO_VERIFICATION', { ...payload, id: idTimestamp });

      return new Promise((resolve) => {
        let unsubscribe: voidFunc | null = null;

        const subFunc = (resultPayload: { id: number; success: boolean }) => {
          if (resultPayload.id === idTimestamp) {
            resolve(resultPayload.success);
            if (unsubscribe) {
              unsubscribe();
            }
          }
        };

        unsubscribe = eventEmitter.subscribe('CHECK_BIO_VERIFICATION_FINISH', subFunc);
      });
    }

    return true;
  },
  setVerification: (payload?: { onlyBio?: boolean }) => {
    if (isReactNativeSupportBioVerification) {
      sendToReactNative('SET_BIO_VERIFICATION', payload);
    }
  },
};
