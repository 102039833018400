import { useSelector } from 'react-redux';

import { selectDefaultDigitalWallet } from 'modules/accounts/store/selectors';
import { PaymentOperationId } from 'modules/payment/types';
import { PaymentDetailsCard } from 'modules/payment/views/components/PaymentDetailsCard';

import { CurrencyIcon, DottedLine, Skeleton } from 'components/ui';

import { useTranslation } from 'libs/i18n';

export const CurrentIbanDetails = () => {
  const translate = useTranslation();
  const defaultDigitalWallet = useSelector(selectDefaultDigitalWallet);

  if (!defaultDigitalWallet) {
    return <Skeleton height={236} />;
  }
  const mainWalletOperationId =
    defaultDigitalWallet.currencyCode === 'GBP'
      ? PaymentOperationId.depositFromWireToMoneyWallet
      : PaymentOperationId.depositFromSepaToMoneyWallet;

  return (
    <PaymentDetailsCard
      operationId={mainWalletOperationId}
      variant="short"
      currencyCode={defaultDigitalWallet.currencyCode}
    >
      <div className="mt-3 column gap-3">
        <DottedLine />
        <div className="row gap-1">
          <CurrencyIcon code={defaultDigitalWallet.currencyCode} size={16} />
          <span className="label">
            {translate('IBAN_ACCOUNT_CURRENCY', {
              currencyCode: defaultDigitalWallet.currencyCode,
            })}
          </span>
        </div>
      </div>
    </PaymentDetailsCard>
  );
};
