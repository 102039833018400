import { useEffect } from 'react';

import { isReactNative } from 'modules/reactNative/utils';

import useFlag from 'hooks/useFlag';

import { isNotificationApiSupported } from 'libs/firebase';

const useNotificationsPermissionStatus = () => {
  const allowed = useFlag(
    isNotificationApiSupported() ? Notification?.permission === 'granted' : false,
  );

  useEffect(() => {
    try {
      navigator.permissions
        .query({ name: 'notifications' })
        .then((notificationPerm) => {
          try {
            notificationPerm.onchange = function () {
              if (notificationPerm.state === 'granted') {
                allowed.on();
              } else {
                allowed.off();
              }
            };
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error in useNotificationsPermissionStatus 1 : ', e);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Error in useNotificationsPermissionStatus 2 : ', e);
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error in useNotificationsPermissionStatus 3: ', e);
    }
    // eslint-disable-next-line
  }, []);

  return isReactNative || allowed.state;
};

export default useNotificationsPermissionStatus;
