import { SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { selectAllowedCountries } from 'modules/app/store/selectors';
import { AllowedCountry } from 'modules/app/types';
import {
  initAuthedUser,
  requestSendOtpToPhoneNumber,
  requestSignUp,
  requestSignUpConfirm,
  requestVerifyPhoneNumberByOtp,
} from 'modules/auth/store/thunks';
import BankCardOffer from 'modules/auth/views/SignUp/steps/BankCardOffer';
// import BankCardOfferDeliveryAddress from 'modules/auth/views/SignUp/steps/BankCardOfferDeliveryAddress';
// import BankCardOfferPhysicalInstructions from 'modules/auth/views/SignUp/steps/BankCardOfferPhysicalInstructions';
// import BankCardOfferSelectType, {
//   CardType,
// } from 'modules/auth/views/SignUp/steps/BankCardOfferSelectType';
import BankCardOfferSuccess from 'modules/auth/views/SignUp/steps/BankCardOfferSuccess';
import { EnterName } from 'modules/auth/views/SignUp/steps/EnterName';
import { EnterPhoneNumber } from 'modules/auth/views/SignUp/steps/EnterPhoneNumber';
import {
  SelectAccountType,
  SignUpAccountType,
} from 'modules/auth/views/SignUp/steps/SelectAccountType';
import { SelectCountry } from 'modules/auth/views/SignUp/steps/SelectCountry';
import { SignWrapper } from 'modules/auth/views/components/SignWrapper';
import { EnterEmail } from 'modules/auth/views/steps/EnterEmail';
import { EnterOTP } from 'modules/auth/views/steps/EnterOTP';
import { requestOrderVirtualCardFromOnboarding } from 'modules/cryptoBankCard/store/thunks';
import { isReactNativeSupportBioVerification } from 'modules/reactNative/utils';
import { appVerificationService } from 'modules/reactNative/utils/appVerification';
import { selectUserExperiments, selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { PhoneNumberInputValue } from 'components/ui/PhoneNumberInput';

import useFlag from 'hooks/useFlag';

import { ApiCodes, translateApiCode } from 'libs/axios/ApiCodes';
import { useTranslation } from 'libs/i18n';
import { UserConfirmRegistrationRequest } from 'libs/swagger/nebeusApiTypes';
import { errorToast } from 'libs/toast';

import { sleep } from 'utils/common';
import { getAnalyticCookies } from 'utils/cookies';

import { CreateBusinessAccount } from './steps/CreateBusinessAccount';

export interface SignUpValues {
  country: AllowedCountry | null;
  email: string | null;
  phoneNumber: PhoneNumberInputValue | null;
  firstName: string | null;
  lastName: string | null;
}

type CurrentProcess =
  | 'businessAccount'
  | 'personalAccount'
  | 'phoneNumberVerification'
  | 'bankCardOffer'
  | 'bankCardOfferSuccess';

const initialProcess = 'personalAccount';
const initialValues = {
  country: null,
  email: null,
  lastName: null,
  firstName: null,
  phoneNumber: null,
};

const PHONE_VERIFICATION_OTP_LENGTH = 4;

const SignUp = () => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUserProfile);
  const userExperiments = useSelector(selectUserExperiments);
  const allowedCountries = useSelector(selectAllowedCountries);

  const userCountry = useMemo(
    () => (user ? allowedCountries.find((c) => c.code === user.countryCode) : null) || null,
    [user, allowedCountries],
  );

  const [currentProcess, setCurrentProcess] = useState<CurrentProcess>(initialProcess);

  useEffect(() => {
    if (user && !user.hasPhoneNumber) {
      changeCurrentProcess('phoneNumberVerification');
    }
    // eslint-disable-next-line
  }, [user?.hasPhoneNumber]);

  const [params] = useSearchParams();

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const resetSlider = useCallback(() => {
    swiper?.slideTo(0, 0);
  }, [swiper]);

  const currentProcessRef = useRef<CurrentProcess>(initialProcess);
  const changeCurrentProcess = useCallback(
    (process: CurrentProcess) => {
      currentProcessRef.current = process;
      setCurrentProcess(process);
      resetSlider();
    },
    [resetSlider],
  );

  const [values, setValues] = useState<SignUpValues>(initialValues);

  const loading = useFlag(false);

  const sendOtpToEmail = useCallback(
    async (email: string) => {
      const { success } = await dispatch(requestSignUp({ login: email }));
      return success;
    },
    [dispatch],
  );
  const sendOtpToPhoneNumber = useCallback(
    async (phoneNumber: string, captchaToken: string) => {
      const { success } = await dispatch(
        requestSendOtpToPhoneNumber(
          { phoneNumber },
          { axiosConfig: { headers: { 'X-Captcha-Token': captchaToken } } },
        ),
      );
      return success;
    },
    [dispatch],
  );

  const actualValues = useRef<SignUpValues>(initialValues);

  const handleStep = useCallback(
    (values: Partial<{ [K in keyof SignUpValues]: NonNullable<SignUpValues[K]> }>) => {
      setValues((prev) => ({ ...prev, ...values }));
      actualValues.current = { ...actualValues.current, ...values };
      swiper?.slideNext();
    },
    [swiper],
  );

  const handleSubmitEmail = useCallback(
    async (email: string) => {
      loading.on();

      const success = await sendOtpToEmail(email);

      loading.off();

      if (success) {
        handleStep({ email });
      }
    },
    [sendOtpToEmail, loading, handleStep],
  );

  const { executeRecaptcha } = useGoogleReCaptcha();

  const getCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      errorToast(translate('ERROR_SOMETHING_WRONG_TRY_LATER'));
      return null;
    }

    try {
      const captchaToken = await executeRecaptcha();
      return captchaToken;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Captcha error: ', e);
      errorToast(translate('ERROR_SOMETHING_WRONG_TRY_LATER'));
      return null;
    }
  }, [translate, executeRecaptcha]);

  const handleSubmitPhoneNumber = useCallback(
    async ({ phoneNumber }: { phoneNumber: PhoneNumberInputValue }) => {
      const captchaToken = await getCaptchaToken();
      if (captchaToken) {
        loading.on();

        const success = await sendOtpToPhoneNumber(
          phoneNumber.country.phoneCode + phoneNumber.number,
          captchaToken,
        );

        loading.off();

        if (success) {
          handleStep({ phoneNumber });
        }
      }
    },
    [loading, handleStep, getCaptchaToken, sendOtpToPhoneNumber],
  );

  const resendOtpToEmail = useCallback(
    () => sendOtpToEmail(values.email!),
    [values.email, sendOtpToEmail],
  );
  const resendOtpToPhoneNumber = useCallback(async () => {
    const captchaToken = await getCaptchaToken();
    if (captchaToken) {
      return sendOtpToPhoneNumber(
        values.phoneNumber!.country.phoneCode + values.phoneNumber!.number,
        captchaToken,
      );
    }
    return false;
  }, [getCaptchaToken, values.phoneNumber, sendOtpToPhoneNumber]);

  const handleSubmitEmailOTP = useCallback(
    async ({ code }: { code: string }) => {
      if (values.country && values.email && values.firstName && values.lastName) {
        loading.on();

        const analyticCookies = getAnalyticCookies();

        const campaignSource = {
          ...analyticCookies,
          referrer: document.referrer,
        };

        const payload: UserConfirmRegistrationRequest = {
          login: values.email,
          otpCode: code,
          countryIso2Code: values.country.code,
          firstName: values.firstName,
          lastName: values.lastName,
          campaignSource: JSON.stringify(campaignSource),
        };

        const refCode = params.get('refCode');
        if (refCode) {
          payload.refCode = refCode;
        }
        const { success, error } = await dispatch(requestSignUpConfirm(payload));
        if (success) {
          await dispatch(initAuthedUser());
          changeCurrentProcess('phoneNumberVerification');
          loading.off();
        } else {
          loading.off();
          if (error?.error?.code === ApiCodes.otpCodeIsInvalid) {
            return { code: translateApiCode(ApiCodes.otpCodeIsInvalid) };
          }
        }
      }
    },
    [loading, changeCurrentProcess, values, dispatch, params],
  );
  const handleSubmitPhoneOtp = useCallback(
    async ({ code }: { code: string }) => {
      if (!values.phoneNumber) {
        return;
      }
      loading.on();

      const { success, error } = await dispatch(
        requestVerifyPhoneNumberByOtp({
          phoneNumber: values.phoneNumber.country.phoneCode + values.phoneNumber.number,
          otpCode: code,
        }),
      );
      loading.off();
      if (success) {
        if (isReactNativeSupportBioVerification) {
          appVerificationService.setVerification();
          await sleep(500);
        }
        if (!userExperiments.showBankCardOfferOnOnboarding) {
          navigate(routesByName.dashboard, { state: { from: 'auth' }, replace: true });
        } else {
          changeCurrentProcess('bankCardOffer');
        }
      } else {
        if (error?.error?.code === ApiCodes.otpCodeIsInvalidOrExpired) {
          return { code: translateApiCode(ApiCodes.otpCodeIsInvalidOrExpired) };
        }
      }
    },
    [loading, navigate, userExperiments, values, dispatch, changeCurrentProcess],
  );

  const phoneNumberProcess = useMemo(
    () => [
      <EnterPhoneNumber
        key="phoneNumberVerification"
        onSubmit={handleSubmitPhoneNumber}
        country={values.country || userCountry}
        allowSwitchAuthType
      />,
      <EnterOTP
        key="phoneNumberVerificationOtp"
        onSubmit={handleSubmitPhoneOtp}
        resendOtp={resendOtpToPhoneNumber}
        title={translate('SIGN_UP_ENTER_PHONE_OTP_TITLE')}
        subtitle={translate('SIGN_ENTER_OTP_SUBTITLE_PHONE', {
          value: values.phoneNumber
            ? values.phoneNumber.country.phoneCode + values.phoneNumber.number
            : '',
          codeLength: PHONE_VERIFICATION_OTP_LENGTH,
        })}
        codeLength={PHONE_VERIFICATION_OTP_LENGTH}
      />,
    ],
    [
      handleSubmitPhoneNumber,
      handleSubmitPhoneOtp,
      resendOtpToPhoneNumber,
      translate,
      userCountry,
      values.country,
      values.phoneNumber,
    ],
  );

  const handleSelectAccountType = useCallback(
    (type: SignUpAccountType) => {
      if (type === 'business') {
        changeCurrentProcess('businessAccount');
      } else {
        changeCurrentProcess('personalAccount');
      }
      swiper?.slideNext();
    },
    [changeCurrentProcess, swiper],
  );

  // const goForward = useCallback(() => {
  //   swiper?.slideNext();
  // }, [swiper]);

  // const handleSelectBankCardType = useCallback(
  //   (cardType: CardType) => {
  //     if (cardType === 'virtual') {
  //       changeCurrentProcess('bankCardOfferSuccess');
  //     } else {
  //       goForward();
  //     }
  //   },
  //   [goForward, changeCurrentProcess],
  // );

  // const onPhysicalCardOrderedSuccessfully = useCallback(() => {
  //   changeCurrentProcess('bankCardOfferSuccess');
  // }, [changeCurrentProcess]);

  const handleSubmitBankCardOffer = useCallback(async () => {
    loading.on();
    const { success } = await dispatch(
      requestOrderVirtualCardFromOnboarding({
        paidOrder: true, // Card is free
        paymentCurrencyCode: 'EUR',
        requestSource: 'USER_ONBOARDING',
      }),
    );
    if (success) {
      changeCurrentProcess('bankCardOfferSuccess');
    }
    loading.off();
  }, [dispatch, loading, changeCurrentProcess]);

  const bankCardOfferProcess = useMemo(
    () => [
      <BankCardOffer key="bankCardOffer" onSubmit={handleSubmitBankCardOffer} />,
      // <BankCardOfferSelectType
      //   key="bankCardOfferSelectType"
      //   onFinish={handleSelectBankCardType}
      //   loading={loading}
      // />,
      // <BankCardOfferPhysicalInstructions
      //   key="bankCardOfferPhysicalInstructions"
      //   onSubmit={goForward}
      // />,
      // <BankCardOfferDeliveryAddress
      //   key="bankCardOfferDeliveryAddress"
      //   onFinish={onPhysicalCardOrderedSuccessfully}
      //   loading={loading}
      // />,
    ],
    [handleSubmitBankCardOffer],
  );

  const bankCardOfferSuccessProcess = useMemo(
    () => [<BankCardOfferSuccess key="bankCardOfferSuccess" />],
    [],
  );

  const personalAccountProcess = useMemo(
    () => [
      <SelectAccountType key="selectAccountType" onSubmit={handleSelectAccountType} />,
      <SelectCountry key="selectCountry" onSubmit={handleStep} />,
      <EnterName key="enterName" onSubmit={handleStep} />,
      <EnterEmail key="enterEmail" onSubmit={handleSubmitEmail} isLogin={false} />,
      <EnterOTP
        key="enterEmailOtp"
        onSubmit={handleSubmitEmailOTP}
        resendOtp={resendOtpToEmail}
        title={translate('SIGN_UP_ENTER_OTP_TITLE')}
        subtitle={translate('SIGN_ENTER_OTP_SUBTITLE_EMAIL', {
          value: values.email || '',
        })}
      />,
    ],
    [
      handleSelectAccountType,
      handleStep,
      handleSubmitEmail,
      handleSubmitEmailOTP,
      resendOtpToEmail,
      translate,
      values.email,
    ],
  );

  const businessAccountProcess = useMemo(
    () => [
      <SelectAccountType key="selectAccountType" onSubmit={handleSelectAccountType} />,
      <CreateBusinessAccount key="businessAccount" loading={loading} />,
    ],
    [handleSelectAccountType, loading],
  );

  const getCurrentProcessSlides = useCallback(
    (process: CurrentProcess) => {
      switch (process) {
        case 'personalAccount': {
          return personalAccountProcess;
        }
        case 'businessAccount': {
          return businessAccountProcess;
        }
        case 'phoneNumberVerification': {
          return phoneNumberProcess;
        }
        case 'bankCardOffer': {
          return bankCardOfferProcess;
        }
        case 'bankCardOfferSuccess': {
          return bankCardOfferSuccessProcess;
        }
      }
    },
    [
      personalAccountProcess,
      phoneNumberProcess,
      businessAccountProcess,
      bankCardOfferProcess,
      bankCardOfferSuccessProcess,
    ],
  );

  const currentProcessSlides = useMemo(
    () => getCurrentProcessSlides(currentProcess),
    [currentProcess, getCurrentProcessSlides],
  );

  return (
    <SignWrapper isLogin={false} loading={loading.state} swiper={swiper} onSwiper={setSwiper}>
      {currentProcessSlides.map((slide) => (
        <SwiperSlide key={slide.key}>{slide}</SwiperSlide>
      ))}
    </SignWrapper>
  );
};

export default SignUp;
