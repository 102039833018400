import { FC } from 'react';

import { Icon } from 'components/ui/Icon';

import { voidFunc } from 'types';

interface CarouselControlsProps {
  slideNext: voidFunc;
  slidePrev: voidFunc;
  canSlideNext: boolean;
  canSlidePrev: boolean;
}
export const CarouselControls: FC<CarouselControlsProps> = ({
  slideNext,
  slidePrev,
  canSlidePrev,
  canSlideNext,
}) => {
  return (
    <div className="row aic gap-3">
      <Icon
        className="pointer"
        name="chevronLeftSquared"
        onClick={slidePrev}
        size={16}
        color={canSlidePrev ? 'cyanBlue' : 'black40x'}
      />
      <Icon
        className="pointer"
        name="chevronRightSquared"
        onClick={slideNext}
        size={16}
        color={canSlideNext ? 'cyanBlue' : 'black40x'}
      />
    </div>
  );
};
