import { FC, useCallback } from 'react';

import { CurrencyAmountListItem } from 'components/ui/CurrencyAmountListItem';
import { Select, SelectProps } from 'components/ui/Select';

import useMobile from 'hooks/useMobile';

import { CurrencyCode, voidFunc } from 'types';

export interface CurrencyPickerItem {
  currencyCode: CurrencyCode;
  amount: number;
  amountInDefaultCurrency: number;
  defaultCurrencyCode: CurrencyCode;
}

export interface CurrencyPickerProps
  extends Omit<SelectProps<CurrencyPickerItem>, 'onPick' | 'options'> {
  selectedCurrency?: CurrencyCode;
  currenciesList: CurrencyPickerItem[];
  onPick: (code: CurrencyCode) => void;
  className?: string;
  position?: 'top' | 'bottom';
  isOpen: boolean;
  onClose: voidFunc;
  anchorEl: HTMLElement | null;
  title?: string;
}
const keyAccessor = (item: CurrencyPickerItem) => item.currencyCode;

export const CurrencyPicker: FC<CurrencyPickerProps> = ({
  selectedCurrency,
  currenciesList,
  onPick,
  isOpen,
  onClose,
  title,
  ...props
}) => {
  const isMobile = useMobile();

  const renderItem = useCallback(
    (currencyItem: CurrencyPickerItem) => (
      <CurrencyAmountListItem
        amount={currencyItem.amount}
        currencyCode={currencyItem.currencyCode}
        secondaryAmount={currencyItem.amountInDefaultCurrency}
        secondaryCurrencyCode={currencyItem.defaultCurrencyCode}
        active={currencyItem.currencyCode === selectedCurrency}
      />
    ),

    [selectedCurrency],
  );

  const handlePickCurrency = useCallback(
    (currencyItem: CurrencyPickerItem) => {
      onPick(currencyItem.currencyCode);
      onClose();
    },
    [onClose, onPick],
  );

  return (
    <Select
      isOpen={isOpen}
      onClose={onClose}
      options={currenciesList}
      onPick={handlePickCurrency}
      renderItem={renderItem}
      keyAccessor={keyAccessor}
      startAdornment={isMobile && title ? <h3 className="mb-3">{title}</h3> : undefined}
      {...props}
    />
  );
};
