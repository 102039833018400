import { FC, useCallback } from 'react';

import {
  requestBankCardExpenses,
  requestBankCardTransactions,
} from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard } from 'modules/cryptoBankCard/types';
import { CryptoBankCardTransaction as ICryptoBankCardTransaction } from 'modules/cryptoBankCard/types';
import CryptoBankCardTransaction from 'modules/cryptoBankCard/views/components/CryptoBankCardTransaction';
import { useDispatch } from 'store';

import { TransactionHistory } from 'components/common';
import { AmountLabel, Loader } from 'components/ui';

import useEntity from 'hooks/useEntity';

import { useTranslation } from 'libs/i18n';

import classes from './CryptoBankCardTransactions.module.scss';

export interface CryptoBankCardTransactionsProps {
  card: CryptoBankCard;
}
const CryptoBankCardTransactions: FC<CryptoBankCardTransactionsProps> = ({ card }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const expenses = useEntity(() => dispatch(requestBankCardExpenses(card.id)), {
    fetchOnMount: true,
  });

  const loadTransactions = useCallback(
    async (pageNumber: number, pageSize: number) => {
      const { success, data } = await dispatch(
        requestBankCardTransactions({ pageNumber, pageSize, id: card.id }),
      );

      if (success && data?.transactions) {
        const mappedTransactions = data.transactions.map((t) => ({
          ...t,
          id: t.id,
          date: t.dateCreated,
          label: t.description,
          amount: t.amountInMerchantCurrency,
          amountInDefaultCurrency: t.amountInLinkedWalletCurrency,
          defaultCurrencyCode: t.currencyCode,
        }));
        return mappedTransactions;
      }

      return null;
    },
    [card, dispatch],
  );

  const renderTransaction = useCallback(
    (t: ICryptoBankCardTransaction) => <CryptoBankCardTransaction key={t.id} transaction={t} />,
    [],
  );

  return (
    <div className={classes.root}>
      <div className={classes.expenses}>
        <span className="label">{translate('THIS_MONTH_EXPENSES')}:</span>
        {expenses.loading && <Loader size="xs" />}
        {expenses.entity && (
          <AmountLabel
            amount={expenses.entity.expenses.monthly}
            currencyCode={expenses.entity.defaultCurrency}
            showCurrencySymbol
          />
        )}
      </div>
      <TransactionHistory
        className={classes.transactions}
        pageSize={20}
        requestTransactions={loadTransactions}
        groupByDate
        renderItem={renderTransaction}
        sectionDateFormat="dd.MM.yyyy"
        sectionClassName="column gap-3"
        listClassName="column gap-2"
        wrapperClassName="column gap-4"
      />
    </div>
  );
};

export default CryptoBankCardTransactions;
