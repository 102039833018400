import { WithdrawalLimits } from 'modules/payment/services';
import { IApiRequestState } from 'store/generators/types';

import {
  CurrencyNetworkList,
  CurrencyNetwork as IApiCurrencyNetwork,
  OtpForBankOperationRequest,
} from 'libs/swagger/nebeusApiTypes';

import { CryptoCurrencyCode, CurrencyCode } from 'types';

// cli-import

export interface PaymentState {
  operationsLimits: IApiRequestState<PaymentOperationLimit[]>;
  savedCryptoWallets: IApiRequestState<SavedCryptoWallet[]>;
  savedBankCards: IApiRequestState<SavedBankCard[]>;
  withdrawLimits: WithdrawalLimits[];
  currenciesNetworks: IApiRequestState<CurrencyNetworks[]>;
  // cli-state-interface
}

export enum PaymentOperationId {
  'depositCryptoFromExternalWallet' = 'bitgoIncomeTransaction',
  'depositApplePay' = 'depositApplePay',
  'depositFromSepaToTradingWallet' = 'depositBTSEPA',
  'depositFromWireToTradingWallet' = 'depositBTWire',
  'depositFromSepaToMoneyWallet' = 'moneyIncomeTransaction',
  'depositFromWireToMoneyWallet' = 'moneyIncomeTransaction',
  'depositFromBankCardToMoneyWallet' = 'sendFromCardToMoney',
  'depositFromBankCardToTradingWallet' = 'sendFromCardToTrading',

  'sendCryptoToExternalWallet' = 'bitgoOutcomeTransaction',
  'sendFromTradingToSepa' = 'wireTransaction',
  'sendFromTradingToWire' = 'wireTransaction',
  'sendFromTradingWalletToBankCard' = 'sendFromTradingToCard',
  'sendFromTradingToMoney' = 'sendFromTradingToMoney',
  'sendToNebeusUser' = 'sendFromTradingToTrading',

  'sendFromMoneyToChaps' = 'sendChaps',
  'sendFromMoneyToSepaFaster' = 'sendFasterPayment',
  'sendFromMoneyWalletToBankCard' = 'sendFromMoneyToCard',
  'sendFromMoneyToTrading' = 'sendFromMoneyToTrading',
  'sendFromMoneyToSepaCredit' = 'sendSEPACreditTransfer',
  'sendFromMoneyToSepaInstantCredit' = 'sendSEPAInstantCreditTransfer',
  'sendFromMoneyToSepa' = 'sendSEPAInstantCreditTransfer',
  'sendFromMoneyToWire' = 'sendFasterPayment',
}

export interface PaymentOperationLimit {
  operationId: PaymentOperationId;
  currencyCode: CurrencyCode;
  min: number;
  max: number;
}

export interface SavedCryptoWallet {
  id: string;
  address: string;
  label: string;
  currencyCode: CryptoCurrencyCode;
}
export interface SavedBankCard {
  id: string;
  lastDigits: string;
  dateExp: string;
  issuerType: 'MC' | 'VISA';
}

export interface CurrencyNetwork extends IApiCurrencyNetwork {}

export interface CurrencyNetworks extends Omit<CurrencyNetworkList, 'networks'> {
  networks: CurrencyNetwork[];
}
export type CryptoNetworkId = CurrencyNetwork['network'];

export type OutcomeTransactionsTypes =
  | PaymentOperationId.sendCryptoToExternalWallet
  | PaymentOperationId.sendFromMoneyToSepa
  | PaymentOperationId.sendFromMoneyToWire
  | PaymentOperationId.sendFromTradingWalletToBankCard
  | PaymentOperationId.sendToNebeusUser
  | PaymentOperationId.sendFromTradingToSepa
  | PaymentOperationId.sendFromTradingToWire;

export const transactionTypesForOtpSending: {
  [key in OutcomeTransactionsTypes]: OtpForBankOperationRequest['relatedEntityType'];
} = {
  [PaymentOperationId.sendCryptoToExternalWallet]: 'BitgoOutcomeTransaction',
  [PaymentOperationId.sendFromMoneyToSepa]: 'FiatRepublicOutcomeTransaction',
  [PaymentOperationId.sendFromMoneyToWire]: 'FiatRepublicOutcomeTransaction',
  [PaymentOperationId.sendFromTradingWalletToBankCard]: 'CardTradingTransaction',

  [PaymentOperationId.sendToNebeusUser]: 'TradingTransaction',
  [PaymentOperationId.sendFromTradingToSepa]: 'WireTransaction',
  [PaymentOperationId.sendFromTradingToWire]: 'WireTransaction',
};
// cli-type
