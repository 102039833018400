import clsx from 'clsx';

import { FC, useCallback, useEffect, useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import {
  requestBankCardExpenses,
  requestBankCardTransactions,
  requestCardLimits,
} from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard } from 'modules/cryptoBankCard/types';
import CryptoBankCardTransaction from 'modules/cryptoBankCard/views/components/CryptoBankCardTransaction';
import { useDispatch } from 'store';

import { CurrencyIcon, Icon, Image, LineDelimiter, Loader, Skeleton } from 'components/ui';

import useEntity from 'hooks/useEntity';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY } from 'utils/date';

import classes from './CryptoBankCardPart.module.scss';

interface LimitCardProps {
  cardId: CryptoBankCard['id'];
}
const LimitsCard: FC<LimitCardProps> = ({ cardId }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const fetchLimits = useCallback(() => dispatch(requestCardLimits(cardId)), [cardId, dispatch]);

  const { entity: limits, loading } = useEntity(fetchLimits, { fetchOnMount: true });

  const usedLimitPercent = useMemo(() => {
    if (!limits) {
      return 0;
    }

    const percent = Math.round((limits.usedLimitMonthly * 100) / limits.limitMonthly);

    if (limits.usedLimitMonthly > 0 && percent === 0) {
      return 1;
    }
    return percent;
  }, [limits]);

  if (loading) {
    // Skeleton
    return (
      <div className={classes.limitCard}>
        <Skeleton height={16} width={90} />
        <LineDelimiter className="my-1" />
        <div className="row jcsb">
          <Skeleton height={16} width={130} />
          <Skeleton height={16} width={90} />
        </div>
        <LineDelimiter className="my-1" />
        <div className="column gap-1">
          <div className="row jcsb">
            <Skeleton height={16} width={130} />
            <Skeleton height={16} width={90} />
          </div>
          <Skeleton height={8} />
          <Skeleton height={16} width={110} />
        </div>
      </div>
    );
  }

  return limits ? (
    <div className={classes.limitCard}>
      <div className="row gap-0-5">
        <CurrencyIcon code={limits.currencyCode} size={16} />
        <span className={classes.headerText}>
          {translate('PAYMENT_CURRENCY_LIMIT', { currencyCode: limits.currencyCode })}
        </span>
      </div>

      <LineDelimiter className="my-1" />

      <div className="row jcsb gap-1">
        <span className="label">{translate('CRYPTO_BANK_CARD_MONTHLY_LIMIT_RESET_DATE')}</span>
        <span className={classes.infoRowValue}>
          {formatDDMMYY(new Date(limits.cutOffDateMonthly))}
        </span>
      </div>
      <LineDelimiter className="my-1" />

      <div className="column gap-1">
        <div className="row jcsb gap-1">
          <span className="label">{translate('CRYPTO_BANK_CARD_MONTHLY_LIMIT')}</span>
          <span className={classes.infoRowValue}>
            {formatCurrencyWithSymbol(limits.limitMonthly, limits.currencyCode)}
          </span>
        </div>
        <div className={classes.progressBar}>
          <div
            className={clsx(
              classes.progress,
              usedLimitPercent > 50 && classes.warn,
              usedLimitPercent > 75 && classes.danger,
            )}
            style={{ width: usedLimitPercent.toString() + '%' }}
          ></div>
        </div>
        <div className="row jcsb gap-1">
          <span className={classes.limits}>
            <span>{formatCurrencyWithSymbol(limits.usedLimitMonthly, limits.currencyCode)} / </span>
            <span>
              {limits.limitMonthly === null
                ? translate('NOT_LIMITED')
                : formatCurrencyWithSymbol(limits.limitMonthly || 0, limits.currencyCode)}
            </span>
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

const ConnectAccountCard: FC<{ card: CryptoBankCard }> = ({ card }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const handleClick = useCallback(() => {
    if (card.isBlocked || card.isFrozen) {
      return;
    }
    if (card.isVirtual) {
      drawer.open(cryptoBankCardDrawerTemplates.connectCryptoAccount({ card }));
    } else {
      drawer.open(
        cryptoBankCardDrawerTemplates.activateCard({ cardId: card.id, isVirtual: card.isVirtual }),
      );
    }
  }, [drawer, card]);

  return (
    <div
      className={clsx(classes.card, (card.isBlocked || card.isFrozen) && classes.disabled)}
      onClick={handleClick}
    >
      <div className="column gap-1 flex-1">
        <span className="label">{translate('CRYPTO_BANK_CARD_CONNECTED_BALANCE')}</span>
        <div className={clsx('row  gap-1-5 jcsb', classes.connectAccountCardRow)}>
          <span>{translate('CRYPTO_BANK_CARD_CONNECT_ACCOUNT')}</span>
          <Image name="cryptoCoinsFade" path="cryptoBankCard" />
        </div>
      </div>
      <Icon name="chevronRight" size={24} color="black40" />
    </div>
  );
};

const Transactions: FC<{ card: CryptoBankCard }> = ({ card }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const drawer = useDrawer();

  const lastTransactions = useEntity(
    () => dispatch(requestBankCardTransactions({ id: card.id, pageSize: 3, pageNumber: 0 })),
    { fetchOnMount: true },
  );

  const expenses = useEntity(() => dispatch(requestBankCardExpenses(card.id)));

  useEffect(() => {
    if (lastTransactions.entity?.transactions.length) {
      expenses.fetchEntity();
    }
    // eslint-disable-next-line
  }, [lastTransactions.entity?.transactions.length]);

  return lastTransactions.loading ? (
    <Loader centered />
  ) : lastTransactions.entity?.transactions.length ? (
    <div className={classes.transactionsCard}>
      <div className="row jcsb gap-2">
        <div className="column gap-1">
          <span className="label">{translate('THIS_MONTH_EXPENSES')}:</span>
          {expenses.loading ? (
            <Loader size="xs" />
          ) : expenses.entity ? (
            <span className={classes.expensesLabel}>
              {formatCurrencyWithSymbol(
                expenses.entity?.expenses.monthly || 0,
                expenses.entity?.defaultCurrency,
              )}
            </span>
          ) : null}
        </div>
        <div>
          <span
            className="label cyanBlue pointer"
            onClick={() => {
              drawer.open(cryptoBankCardDrawerTemplates.transactions({ card }));
            }}
          >
            {translate('ACCOUNT_TRANSACTIONS_SEE_ALL')}
          </span>
        </div>
      </div>
      <Icon name="dotsLine" size={2} />

      <div className={classes.transactionsList}>
        {lastTransactions.entity.transactions.map((t) => (
          <CryptoBankCardTransaction key={t.id} transaction={t} fullDate />
        ))}
      </div>
    </div>
  ) : (
    <div className={clsx(classes.transactionsCard, classes.row)}>
      <div className="column gap-1">
        <span className="label">{translate('TRANSACTIONS')}</span>
        <span className={classes.noTransactions}>{translate('NO_TRANSACTIONS_YES')}</span>
      </div>
      <Image name="clock" path="loans" className={classes.clockImg} />
    </div>
  );
};

interface CryptoBankCardPartProps {
  card: CryptoBankCard;
}

export const CryptoBankCardPart: FC<CryptoBankCardPartProps> = ({ card }) => (
  <div className={classes.root}>
    {!card.currencyCode && !card.isBlocked && <ConnectAccountCard card={card} />}
    <LimitsCard cardId={card.id} />
    <Transactions card={card} />
  </div>
);
