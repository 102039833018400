import { paymentActions } from '../store';
import { requestDigitalWallets, requestWallets } from 'modules/accounts/store/thunks';
import { generateRequestDataThunk } from 'store/generators/thunks';
import { generateRequestThunk } from 'store/generators/thunks';

import eventEmitter from 'utils/eventEmitter';

import paymentServices from '../services';
import {
  CurrencyNetworks,
  PaymentOperationLimit,
  SavedBankCard,
  SavedCryptoWallet,
} from '../types';

// cli-import

export const requestOperationsLimits = generateRequestDataThunk<PaymentOperationLimit[]>({
  updateState: paymentActions.updateOperationsLimits,
  request: paymentServices.getLimits,
  options: { showErrorToast: false },
});
export const requestSendToExternalWallet = generateRequestThunk({
  request: paymentServices.sendToExternalWallet,
  options: {
    showErrorToast: false,
    onSuccess: (dispatch) => {
      dispatch(requestWallets());
      eventEmitter.emit('refreshTransactions');
    },
  },
});
export const requestSendFromDigitalToCryptoAccount = generateRequestThunk({
  request: paymentServices.sendFromDigitalToCryptoAccount,
  options: {
    showErrorToast: false,
    onSuccess: (dispatch) => {
      dispatch(requestWallets());
      dispatch(requestDigitalWallets());
      eventEmitter.emit('refreshTransactions');
    },
  },
});
export const requestSendFromCryptoToDigitalAccount = generateRequestThunk({
  request: paymentServices.sendFromCryptoToDigitalAccount,
  options: {
    showErrorToast: false,
    onSuccess: (dispatch) => {
      dispatch(requestWallets());
      dispatch(requestDigitalWallets());
      eventEmitter.emit('refreshTransactions');
    },
  },
});

export const requestSavedCryptoWallets = generateRequestDataThunk<SavedCryptoWallet[]>({
  updateState: paymentActions.updateSavedCryptoWallets,
  request: paymentServices.getSavedCryptoWallets,
  options: { showErrorToast: false },
});
export const requestSaveCryptoWallet = generateRequestThunk({
  request: paymentServices.saveCryptoWallet,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestSavedCryptoWallets());
    },
  },
});
export const requestEditCryptoWallet = generateRequestThunk({
  request: paymentServices.editCryptoWallet,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestSavedCryptoWallets());
    },
  },
});
export const requestRemoveCryptoWallet = generateRequestThunk({
  request: paymentServices.removeCryptoWallet,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestSavedCryptoWallets());
    },
  },
});
export const requestSavedBankCards = generateRequestDataThunk<SavedBankCard[]>({
  updateState: paymentActions.updateSavedBankCards,
  request: paymentServices.getSavedBankCards,
  options: { showErrorToast: false },
});
export const requestCalculateOperation = generateRequestThunk({
  request: paymentServices.calculateOperation,
});
export const requestSendToNebeusUser = generateRequestThunk({
  request: paymentServices.sendToNebeusUser,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestWallets());
      eventEmitter.emit('refreshTransactions');
    },
  },
});

export const requestWithdrawalLimits = generateRequestThunk({
  request: paymentServices.getWithdrawalLimits,
  options: {
    showErrorToast: false,
    onSuccess: (dispatch, data) => {
      dispatch(paymentActions.updateWithdrawLimits([data]));
    },
  },
});
export const requestSendFromDigitalAccountToSEPAOrWire = generateRequestThunk({
  request: paymentServices.sendFromDigitalAccountToSEPAOrWire,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestDigitalWallets());
      eventEmitter.emit('refreshTransactions');
    },
  },
});
export const requestSendFromTradingAccountToSEPAOrWire = generateRequestThunk({
  request: paymentServices.sendFromTradingAccountToSEPAOrWire,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestWallets());
      eventEmitter.emit('refreshTransactions');
    },
  },
});

export const requestDepositFromBankCardToCryptoWallet = generateRequestThunk({
  request: paymentServices.depositFromBankCardToCryptoWallet,
  options: { showErrorToast: false },
});
export const requestSendFromTradingWalletToBankCard = generateRequestThunk({
  request: paymentServices.sendFromTradingWalletToBankCard,
  options: { showErrorToast: false },
});
export const requestCurrenciesNetworks = generateRequestDataThunk<CurrencyNetworks[]>({
  updateState: paymentActions.updateCurrenciesNetworks,
  request: paymentServices.getCurrenciesNetworks,
});
export const requestGenerateCryptoAddress = generateRequestThunk({
  request: paymentServices.generateCryptoAddress,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestCurrenciesNetworks());
    },
  },
});

export const requestRefundRejectedCryptoTransaction = generateRequestThunk({
  request: paymentServices.refundRejectedCryptoTransaction,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestWallets());
      eventEmitter.emit('refreshTransactions');
    },
  },
});
// cli-thunk
