import { FC, memo, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import { selectAllowedCountriesReducer } from 'modules/app/store/selectors';
import { requestAllowedCountries } from 'modules/app/store/thunks';
import { AllowedCountry } from 'modules/app/types';
import { CountryPickerMobile } from 'modules/auth/views/SignUp/components/CountryPickerMobile';
import { StepHeader } from 'modules/auth/views/components/StepHeader';
import { SwitchAuthType } from 'modules/auth/views/components/SwitchAuthType';

import { PhoneNumberInputField, SubmitButton } from 'components/form';
import { CountryOptionProps } from 'components/ui/CountryOption';
import { PhoneNumberInputValue } from 'components/ui/PhoneNumberInput';

import useMobile from 'hooks/useMobile';
import useStoreEntity from 'hooks/useStoreEntity';

import { getTranslation, useTranslation } from 'libs/i18n';
import useSwiperSlideSizeObserver from 'libs/swiper/useSwiperSlideSizeObserver';
import yup, { makeValidate } from 'libs/yup';

import { isPhoneNumberValid } from 'utils/validation';

interface EnterPhoneNumberProps {
  onSubmit: (values: FormValues) => void;
  country: AllowedCountry | null;
  allowSwitchAuthType?: boolean;
}

interface FormValues {
  phoneNumber: PhoneNumberInputValue;
}

export interface EnterPhoneNumberFormProps extends FormRenderProps<FormValues> {
  allowedCountries: AllowedCountry[];
  allowSwitchAuthType?: boolean;
}

const schema = yup.object().shape({
  phoneNumber: yup
    .object()
    .shape({
      country: yup
        .object()
        .required(getTranslation('VALIDATION_REQUIRED'))
        .typeError(getTranslation('VALIDATION_COUNTRY_INCORRECT')),
      number: yup.string().requiredDefault(),
    })
    .test(
      'isValidPhoneNumber',
      getTranslation('VALIDATION_PHONE_INVALID'),
      // @ts-ignore
      (phoneNumber: PhoneNumberInputValue) =>
        isPhoneNumberValid(phoneNumber.country.phoneCode + phoneNumber.number),
    ),
});

const validate = makeValidate(schema);

const countryOptionProps: Partial<CountryOptionProps> = {
  labelAccessor: (country: AllowedCountry) => `${country.name} (${country.phoneCode})`,
};

const EnterPhoneNumberForm: FC<EnterPhoneNumberFormProps> = ({
  handleSubmit,
  allowedCountries,
  form,
  values,
  allowSwitchAuthType,
}) => {
  const translate = useTranslation();
  const isMobile = useMobile();

  const { setNode } = useSwiperSlideSizeObserver();

  const handleChangeCountry = useCallback(
    (country: AllowedCountry) => {
      form.change('phoneNumber', { country, number: values.phoneNumber.number });
    },
    [form, values.phoneNumber.number],
  );

  const signSliderNode = useMemo(() => document.getElementById('SIGN_SLIDER'), []);

  return (
    <form ref={setNode} className="authStepRoot" onSubmit={handleSubmit}>
      <div className="column gap-3 flex-1">
        <StepHeader
          title={translate('SIGN_UP_ENTER_PHONE_NUMBER_TITLE')}
          subtitle={translate('SIGN_UP_ENTER_PHONE_NUMBER_SUBTITLE')}
        />
        <Field
          name="phoneNumber"
          component={PhoneNumberInputField}
          allowedCountries={allowedCountries}
          label={translate('PHONE_NUMBER')}
          placeholder={translate('PLACEHOLDER_PHONE_NUMBER')}
          selectPortalNode={signSliderNode}
          hideSelect={isMobile}
        />
        {isMobile ? (
          <CountryPickerMobile
            onChange={handleChangeCountry}
            value={values.phoneNumber.country}
            allowedCountries={allowedCountries}
            countryOptionProps={countryOptionProps}
            withFilter={false}
          />
        ) : null}
      </div>
      <div className="column gap-1-5 flex-shrink-0">
        <SubmitButton className="flex-shrink-0" fullWidth>
          {translate('CONTINUE')}
        </SubmitButton>
        <p className="font-s grey-300">
          This site is protected by reCAPTCHA and the Google{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">
            Terms of Service
          </a>{' '}
          apply.
        </p>
        {allowSwitchAuthType && (
          <SwitchAuthType currentAuthType="signUp" className="asfc hideOnMobile" />
        )}
      </div>
    </form>
  );
};

export const EnterPhoneNumber = memo<EnterPhoneNumberProps>(
  ({ onSubmit, country, allowSwitchAuthType }) => {
    const { entityReducer: allowedCountiesReducer } = useStoreEntity(
      selectAllowedCountriesReducer,
      requestAllowedCountries,
    );

    const initialValues = useMemo<FormValues>(
      () => ({
        phoneNumber: {
          country: country || allowedCountiesReducer.data[0],
          number: '',
        },
      }),
      [country, allowedCountiesReducer.data],
    );

    const renderForm = useCallback(
      (formProps: FormRenderProps<FormValues>) => (
        <EnterPhoneNumberForm
          allowedCountries={allowedCountiesReducer.data}
          allowSwitchAuthType={allowSwitchAuthType}
          {...formProps}
        />
      ),
      [allowedCountiesReducer.data, allowSwitchAuthType],
    );

    if (!initialValues.phoneNumber.country) {
      return null;
    }

    return (
      <Form<FormValues>
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={renderForm}
        validate={validate}
      />
    );
  },
);
