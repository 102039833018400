import clsx from 'clsx';

import { FC } from 'react';

import { AllowedCountry } from 'modules/app/types';

import { Icon } from 'components/ui/Icon';
import { Image } from 'components/ui/Image';

import { voidFunc } from 'types';

import classes from './CountryOptions.module.scss';

export interface CountryOptionProps {
  country: AllowedCountry;
  active: boolean;
  onClick?: voidFunc;
  labelAccessor?: (country: AllowedCountry) => string;
}
export const CountryOption: FC<CountryOptionProps> = ({
  country,
  active,
  onClick,
  labelAccessor,
}) => {
  return (
    <div onClick={onClick} className={clsx(classes.root, active && classes.active)}>
      <div className="row aic gap-2">
        <Image name={country.code.toLowerCase()} path="countryFlags" type="svg" />
        <span>{labelAccessor ? labelAccessor(country) : country.name}</span>
      </div>

      {active && <Icon className={classes.successCircle} name="check" size={10} />}
    </div>
  );
};
