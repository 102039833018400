import { MessageHandler } from '@sumsub/websdk';
import SumsubWebSdk from '@sumsub/websdk-react';
import { ErrorHandler } from '@sumsub/websdk/types/types';

import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useSideBar from 'modules/app/hooks/useSideBar';
import { selectIsUserVerified } from 'modules/user/store/selectors';
import { requestGetVerificationToken, requestProfile } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import { Button, Loader } from 'components/ui';

import useEntity from 'hooks/useEntity';

import analytic from 'libs/analytic';
import { AnalyticEvents } from 'libs/analytic/events';
import { useTranslation } from 'libs/i18n';

export interface VerificationIDProps {
  required?: boolean;
}
const VerificationID: FC<VerificationIDProps> = ({ required }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const sidebar = useSideBar();

  const userVerified = useSelector(selectIsUserVerified);

  const {
    fetchEntity: fetchSumsubToken,
    loading,
    entity: tokenResponse,
    error,
  } = useEntity(() => dispatch(requestGetVerificationToken()), { fetchOnMount: true });

  const handleSumsubMessage = useCallback<MessageHandler>(
    async (message, payload) => {
      if (message === 'idCheck.onApplicantStatusChanged') {
        analytic.sendEvent(AnalyticEvents.userVerificationStatusChanged, {
          sumsubPayload: payload,
        });
        const { data: profile } = await dispatch(requestProfile());
        if (!profile || !profile.verified) {
          // If user has been verified by Sumsub but webhook do not finished updating status in DB
          setTimeout(() => {
            dispatch(requestProfile());
          }, 15000);
        }
      }
    },
    [dispatch],
  );

  const handleError = useCallback<ErrorHandler>((error) => {
    analytic.sendEvent(AnalyticEvents.userVerificationSDKError, { error });
    // eslint-disable-next-line no-console
    console.log('Sumsub error: ', error);
  }, []);

  const expirationHandler = useCallback(async () => {
    const response = await fetchSumsubToken();
    return response?.token || '';
  }, [fetchSumsubToken]);

  if (userVerified) {
    return (
      <div className="column gap-2">
        <p>{translate('VERIFICATION_ALREADY_VERIFIED')}</p>
        <Button onClick={sidebar.close}>{translate('CLOSE')}</Button>
      </div>
    );
  }

  return (
    <div>
      {required && <p className="mb-2">{translate('VERIFICATION_SUBTITLE')}</p>}
      {loading ? (
        <Loader centered />
      ) : (
        tokenResponse?.token && (
          <SumsubWebSdk
            accessToken={tokenResponse.token}
            expirationHandler={expirationHandler}
            onMessage={handleSumsubMessage}
            onError={handleError}
            // testEnv={appConfig.isDev}
          />
        )
      )}
      {error ? (
        error.error?.code === 'Country not found' ? (
          <span className="errorLabel">{translate('VERIFICATION_COUNTRY_NOT_ALLOWED')}</span>
        ) : (
          <div>
            <span className="errorLabel">❌ {translate('ERROR')}</span>
            <Button onClick={fetchSumsubToken} className="mt-2" fullWidth>
              {translate('ERROR_TRY_AGAIN')}
            </Button>
          </div>
        )
      ) : null}
    </div>
  );
};
export default VerificationID;
