import { useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import useDrawer from 'modules/app/hooks/useDrawer';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';
import { selectsInvoicesSavedClientsReducer } from 'modules/invoicing/store/selectors';
import { requestSavedClients } from 'modules/invoicing/store/thunks';
import { InvoiceSavedClient } from 'modules/invoicing/types';
import { ClientDetailsForm } from 'modules/invoicing/views/CreateInvoice/components/ClientDetailsForm';
import { CreateInvoiceStep } from 'modules/invoicing/views/CreateInvoice/components/CreateInvoiceStep';
import SavedEntityCard from 'modules/invoicing/views/CreateInvoice/components/SavedEntityCard';
import { CreateInvoiceFormValues } from 'modules/invoicing/views/CreateInvoice/index';

import { CheckBoxField } from 'components/form';
import { Icon } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import classes from './ClientDetails.module.scss';

export const ClientDetails = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const { entityReducer: clientsReducer } = useStoreEntity(
    selectsInvoicesSavedClientsReducer,
    requestSavedClients,
  );

  const form = useForm<CreateInvoiceFormValues>();
  const formState = useFormState<CreateInvoiceFormValues>();

  const handleClickSavedClients = useCallback(() => {
    drawer.open(
      invoicingDrawerTemplates.savedClients({
        variant: 'pick',
        onPick: (client: InvoiceSavedClient) => {
          form.batch(() => {
            form.change('isClientSaved', true);
            form.change('clientName', client.name);
            form.change('clientAddress1', client.address);
            form.change('clientAddress2', client.address2);
            form.change('clientCity', client.city);
            form.change('clientCountry', client.country);
            form.change('clientPostCode', client.postCode);
            form.change('clientEmail', client.email);
          });
          drawer.pop();
        },
      }),
    );
  }, [form, drawer]);

  const removeSavedClient = useCallback(() => {
    form.change('isClientSaved', false);
    form.change('clientName', '');
    form.change('clientAddress1', '');
    form.change('clientAddress2', '');
    form.change('clientCity', '');
    form.change('clientCountry', '');
    form.change('clientPostCode', '');
    form.change('clientEmail', '');
  }, [form]);

  return (
    <CreateInvoiceStep
      label={translate('INVOICES_CLIENT_DETAILS')}
      labelEndAdornment={
        clientsReducer.data.length ? (
          <span onClick={handleClickSavedClients} className="label cyanBlue pointer">
            {translate(formState.values.isClientSaved ? 'CHANGE' : 'INVOICES_USE_SAVED_DETAILS')}
          </span>
        ) : undefined
      }
      component={
        formState.values.isClientSaved ? (
          <SavedEntityCard
            entity="client"
            endAdornment={
              <Icon onClick={removeSavedClient} className="pointer" name="crossSafe" size="sm" />
            }
            title={formState.values.clientName}
            description={[
              formState.values.clientCountry,
              formState.values.clientCity,
              formState.values.clientPostCode,
              formState.values.clientAddress1,
              formState.values.clientAddress2,
              formState.values.clientEmail,
            ]
              .filter((i) => !!i)
              .join(', ')}
          />
        ) : (
          <ClientDetailsForm />
        )
      }
      endAdornment={
        formState.values.isClientSaved ? undefined : (
          <div className="row aic gap-1-5">
            <Field name="saveClient" component={CheckBoxField} />
            <span className={classes.saveLabel}>
              {translate('INVOICES_SAVE_FOR_FUTURE_INVOICES')}
            </span>
          </div>
        )
      }
    />
  );
};
