import clsx from 'clsx';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import { LOANS_REQUEST_LIMIT_PAGE } from 'modules/loans/constants/config';
import loanDrawerTemplates from 'modules/loans/constants/drawerTemplates';
import { allowedNotificationTypes } from 'modules/loans/services/transformers';
import {
  selectActiveLoansItemsReducer,
  selectHistoryLoansItemsReducer,
  selectLoansNotificationsReducer,
} from 'modules/loans/store/selectors';
import {
  requestLoansActiveItems,
  requestLoansHistoryItems,
  requestLoansNotifications,
} from 'modules/loans/store/thunks';
import { LoanItem as ILoanItem, LoanNotification } from 'modules/loans/types';
import { LiquidationRiskCard } from 'modules/loans/views/components/LiquidationRiskCard';
import { LoanHeader } from 'modules/loans/views/components/LoanHeader';
import { LoanMovement } from 'modules/loans/views/components/LoanMovement';
import { LoanNotificationCard } from 'modules/loans/views/components/LoanNotificationCard';
import { isReactNative } from 'modules/reactNative/utils';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';
import { scrollViewSelector } from 'constants/viewConstants';

import { ErrorCard } from 'components/common';
import { AmountLabel, Button, DottedLine, Icon, Loader, Mark, SummaryCard } from 'components/ui';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import useInfinityScrollSimplified from 'hooks/useInfinityScrollSimplified';

import { TranslationKey, useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';
import { formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY } from 'utils/date';

import classes from './ActiveHistory.module.scss';

interface ActiveHistoryProps {
  active: boolean;
}
interface LoanItemProps {
  loan: ILoanItem;
  notification: LoanNotification | undefined;
}

interface LoanActiveHistoryItemProps {
  list: SummaryCardItem[];
  openPaymentsAndCollateral: () => void;
  loan: ILoanItem;
}
interface LoanActiveItemProps extends LoanActiveHistoryItemProps {
  onRepay: () => void;
  onAddCollateral: () => void;
  notification: LoanNotification | undefined;
}

const ActiveLoanItem: FC<LoanActiveItemProps> = ({
  list,
  loan,
  openPaymentsAndCollateral,
  onRepay,
  onAddCollateral,
  notification,
}) => {
  const translate = useTranslation();

  const hasOption = loan.autoManagementMC || loan.excessCollateralRelease;

  const hasOptionCard = hasOption || loan.lastTransaction;

  // @ts-ignore
  const enabledOptionsTranslationKeys: TranslationKey[] = [
    loan.autoManagementMC && 'LOANS_AUTO_MC',
    loan.excessCollateralRelease && 'LOANS_EXCESS_RELEASE',
  ].filter((k) => !!k);

  return (
    <>
      {hasOptionCard || loan.lastTransaction ? (
        <div className="outlinedCard p-2 column gap-2">
          {hasOption && (
            <div className="column gap-2">
              {enabledOptionsTranslationKeys.map((tk) => (
                <div key={tk} className="row aic gap-1">
                  <Icon name="check" className={classes.successCircle} size={10} />
                  <span className={classes.optionLabel}>{translate(tk)}</span>
                </div>
              ))}
            </div>
          )}
          {hasOption && loan.lastTransaction ? <DottedLine /> : null}
          {loan.lastTransaction && (
            <LoanMovement
              transactionAmount={loan.lastTransaction.transactionAmount}
              collateralAmount={loan.collateralAmount}
              currencyCode={loan.collateralCurrency}
              walletAmount={loan.lastTransaction.walletAmount}
              transactionType={loan.lastTransaction.transactionType}
            />
          )}
        </div>
      ) : null}

      <SummaryCard config={list} variant="borderless" />

      <div className="column gap-1-5">
        <LiquidationRiskCard
          className="outlinedCard flex-1 p-2"
          riskPercent={loan.liquidationRiskPercent}
          marginCallStatus={loan.marginCallStatus}
        />
        <div className="outlinedCard p-2 column flex-1 gap-1-5">
          <span className="label">{translate('LOANS_OUTSTANDING_BALANCE')}</span>
          <AmountLabel
            amount={loan.restBalanceAmount || 0}
            currencyCode={loan.loanCurrency}
            currencyIcon={{ size: 16 }}
            size="sm"
          />
        </div>
        <Button onClick={openPaymentsAndCollateral} fullWidth variant="creamyBlack">
          {translate('LOANS_PAYMENTS_COLLATERAL')}
        </Button>
        <div className="row gap-1-5">
          {loan.fullRepaymentAllowed || loan.partialRepaymentAllowed ? (
            <Button onClick={onRepay} fullWidth>
              {translate('LOANS_REPAY')}
            </Button>
          ) : null}
          <Button onClick={onAddCollateral} variant="creamyBlack" fullWidth>
            {translate('LOANS_ADD_COLLATERAL')}
          </Button>
        </div>
      </div>
      {notification && <LoanNotificationCard notification={notification} />}
    </>
  );
};
const HistoryLoanItem: FC<LoanActiveHistoryItemProps> = ({
  list,
  openPaymentsAndCollateral,
  loan,
}) => {
  const translate = useTranslation();

  return (
    <>
      <SummaryCard config={list} variant="borderless" />
      <Button
        onClick={openPaymentsAndCollateral}
        data-loanid={loan.id}
        fullWidth
        variant="creamyBlack"
      >
        {translate('LOANS_PAYMENTS_COLLATERAL')}
      </Button>
    </>
  );
};

const LoanItem: FC<LoanItemProps> = ({ loan, notification }) => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const drawer = useDrawer();

  const list = useMemo<SummaryCardItem[]>(() => {
    const result = [
      {
        label: translate('TERM'),
        value: translate('DATE_MONTH_PLURAL', { count: loan.termMonth }),
      },
      {
        label: translate('START_DATE'),
        value: formatDDMMYY(new Date(loan.dateStart)),
      },
      {
        label: translate('END_DATE'),
        value: formatDDMMYY(new Date(loan.dateEnd)),
      },
      {
        label: translate('LOANS_LTV'),
        value: loan.ltv.toString() + '%',
      },
      {
        label: translate('LOANS_INTEREST_RATE'),
        value: loan.interestRate.toString() + '%',
      },
      {
        label: translate('LOANS_COLLATERAL'),
        value: formatCurrencyWithSymbol(loan.collateralAmount, loan.collateralCurrency),
        currencyCode: loan.collateralCurrency,
      },
    ];

    if (loan.isActive) {
      result.push({
        label: translate('LOANS_LOAN_EXCHANGE_RATE'),
        value: formatCurrencyWithSymbol(loan.exchangeRate || 0, loan.loanCurrency),
      });

      if (loan.nextPaymentDate && loan.nextPaymentAmount) {
        result.push(
          {
            label: translate('NEXT_PAYMENT_DATE'),
            value: formatDDMMYY(new Date(loan.nextPaymentDate)),
          },
          {
            label: translate('NEXT_PAYMENT'),
            value: formatCurrencyWithSymbol(loan.nextPaymentAmount, loan.loanCurrency),
            currencyCode: loan.loanCurrency,
          },
        );
      }
      if (loan.marginCallExchangeRate) {
        result.push({
          label: translate('LOAN_MC_RATE'),
          value: formatCurrencyWithSymbol(loan.marginCallExchangeRate, loan.loanCurrency),
        });
      }
    }

    return result;
  }, [translate, loan]);

  const handleClickPaymentAndCollateral = useCallback(() => {
    navigate(routesByName.loansPaymentAndCollateral(loan.id.toString()));
  }, [navigate, loan.id]);

  const handleClickRepay = useCallback(() => {
    drawer.open(loanDrawerTemplates.loanRepayment({ loan }));
  }, [loan, drawer]);

  const handleClickAddCollateral = useCallback(() => {
    drawer.open(loanDrawerTemplates.loanAddCollateral({ loan }));
  }, [loan, drawer]);

  return (
    <div className={classes.loanItem}>
      <LoanHeader
        loan={loan}
        endAdornment={!loan.isActive && <Mark variant="gray">{translate('CLOSED')}</Mark>}
      />
      {loan.isActive ? (
        <ActiveLoanItem
          openPaymentsAndCollateral={handleClickPaymentAndCollateral}
          onRepay={handleClickRepay}
          onAddCollateral={handleClickAddCollateral}
          loan={loan}
          list={list}
          notification={notification}
        />
      ) : (
        <HistoryLoanItem
          openPaymentsAndCollateral={handleClickPaymentAndCollateral}
          loan={loan}
          list={list}
        />
      )}
    </div>
  );
};

export const ActiveHistory: FC<ActiveHistoryProps> = ({ active }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const navigate = useNavigate();

  const loansItemsReducer = useSelector(
    active ? selectActiveLoansItemsReducer : selectHistoryLoansItemsReducer,
  );

  const handleGetLoans = useCallback(() => {
    navigate(routesByName.loans('new'));
  }, [navigate]);

  const loadMore = useCallback(
    async (thunkOptions?: { reset?: boolean }) => {
      const fetchItems = active ? requestLoansActiveItems : requestLoansHistoryItems;
      await dispatch(
        fetchItems(undefined, { pageLimit: LOANS_REQUEST_LIMIT_PAGE, ...thunkOptions }),
      );
    },
    [dispatch, active],
  );

  const { onInfinityScroll } = useInfinityScrollSimplified({
    loading: loansItemsReducer.meta.loading,
    hasMore: loansItemsReducer.meta.hasMore,
    loadMore,
  });

  useEffect(() => {
    const scrollEl = document.querySelector<HTMLDivElement>(scrollViewSelector);

    if (scrollEl) {
      // @ts-ignore
      scrollEl.addEventListener('scroll', onInfinityScroll);
      return () => {
        // @ts-ignore
        scrollEl.removeEventListener('scroll', onInfinityScroll);
      };
    }
  }, [onInfinityScroll]);

  useEffect(() => {
    if (canRequest(loansItemsReducer.meta, 10)) {
      loadMore({ reset: true });
    }
    // eslint-disable-next-line
  }, []);

  const notificationsReducer = useSelector(selectLoansNotificationsReducer);
  useEffect(() => {
    if (active && canRequest(notificationsReducer.meta)) {
      dispatch(requestLoansNotifications());
    }
    // eslint-disable-next-line
  }, [active]);

  const getLoanNotificationById = useCallback(
    (loanId: number) => {
      const allNotifications = notificationsReducer.data.filter((n) => n.loan.id === loanId);
      allNotifications.sort(
        (a, b) =>
          allowedNotificationTypes.findIndex((type) => type === a.type) -
          allowedNotificationTypes.findIndex((type) => type === b.type),
      );

      return allNotifications[0];
    },
    [notificationsReducer.data],
  );

  const loansRenderedItems = useMemo(
    () =>
      loansItemsReducer.data.map((loan) => (
        <LoanItem key={loan.id} loan={loan} notification={getLoanNotificationById(loan.id)} />
      )),
    [loansItemsReducer.data, getLoanNotificationById],
  );

  return (
    <div className={clsx(classes.root, isReactNative && classes.reactNative)}>
      <div className={classes.loans}>{loansRenderedItems}</div>
      {loansItemsReducer.meta.loading && <Loader className="mt-4" centered />}
      {loansItemsReducer.meta.error && <ErrorCard retry={loadMore} />}

      {!loansItemsReducer.meta.hasMore && loansItemsReducer.data.length === 0 ? (
        <div className={classes.emptyCard}>
          <div className="column gap-0-5">
            <h4>{translate(active ? 'LOANS_NO_ACTIVE_ITEMS' : 'LOANS_NO_HISTORY_ITEMS')}</h4>
            <p>
              {translate(
                active ? 'LOANS_NO_ACTIVE_ITEMS_SUBTITLE' : 'LOANS_NO_HISTORY_ITEMS_SUBTITLE',
              )}
            </p>
          </div>
          <Button className={classes.emptyButton} fullWidth showShadow onClick={handleGetLoans}>
            {translate('LOANS_GET_LOAN')}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
