import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import ExchangeForm, { ExchangeSuccessCallbackPayload } from 'modules/exchange/views/ExchangeForm';
import { SmartTraderTitles } from 'modules/smartTrader/views/components/SmartTraderTitles';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';

import { ButtonProps } from 'components/ui/Button';

import { useTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

const toFieldProps = { pickerDisabled: true };
export interface SmartTraderExchangeProps {
  currencyCode: CurrencyCode;
}
const SmartTraderExchange: FC<SmartTraderExchangeProps> = ({ currencyCode }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const userDefaultCurrency = useSelector(selectUserDefaultCurrencyCode);

  const initialValues = useMemo(
    () => ({
      from: { currency: userDefaultCurrency, amount: '' },
      to: { currency: currencyCode, amount: '' },
    }),
    [currencyCode, userDefaultCurrency],
  );
  const submitButtonProps = useMemo<ButtonProps>(
    () => ({
      variant: 'gold',
      children: translate('BUY') + ' ' + getCurrencyLabelByCode(currencyCode),
    }),
    [translate, currencyCode],
  );

  const handleSuccess = useCallback(
    ({ response }: ExchangeSuccessCallbackPayload) => {
      sidebar.replace(
        ...sidebarTemplates.smartTraderExchangeSuccess({
          from: { amount: response.fromAmount.toString(), currency: response.fromCurrency },
          to: { amount: response.toAmount.toString(), currency: response.toCurrency },
          fee: {
            amount: response.commissionAmount.toString(),
            currency: response.commissionCurrencyCode,
          },
          date: response.operationDate,
          rate: response.exchangeRate,
        }),
      );
    },
    [sidebar],
  );
  return (
    <div className="column gap-3">
      <SmartTraderTitles
        currencyCode={currencyCode}
        title={translate('EXCHANGE')}
        subtitle={translate('SMART_TRADER_EXCHANGE_SUBTITLE')}
      />
      <ExchangeForm
        {...initialValues}
        onSuccess={handleSuccess}
        formContainerClassName="card p-3"
        isLight
        submitButtonProps={submitButtonProps}
        toFieldProps={toFieldProps}
      />
    </div>
  );
};

export default SmartTraderExchange;
