import { FC } from 'react';

import useOrderCryptoVirtualBankCard from 'modules/cryptoBankCard/hooks/useOrderCryptoVirtualBankCard';

import BankCardFeaturesList from 'components/common/bankCards/BankCardFeaturesList';
import { Button, Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './AdvertisementCard.module.scss';

interface AdvertisementCardProps {}
export const AdvertisementCard: FC<AdvertisementCardProps> = () => {
  const translate = useTranslation();

  const orderVirtualBankCard = useOrderCryptoVirtualBankCard();

  return (
    <div className={classes.root}>
      <div className="flexScrollable">
        <div className={classes.card}>
          <h3>{translate('CRYPTO_VIRTUAL_BANK_CARD_AD_TITLE')}</h3>
          <Icon className={classes.dots} name="dotsLine" size={2} />
          <BankCardFeaturesList className={classes.features} />
        </div>
      </div>
      <Button
        loading={orderVirtualBankCard.loading}
        onClick={orderVirtualBankCard.startProcess}
        variant="greyishGreen"
        fullWidth
      >
        {translate('BANK_CARD_GET')}
      </Button>
    </div>
  );
};
