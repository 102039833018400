import { FC, useCallback } from 'react';

import { requestTransactionHistory } from 'modules/smartTrader/store/thunks';
import { SmartTrade } from 'modules/smartTrader/types';
import { useDispatch } from 'store';

import { TransactionHistory } from 'components/common';
import { TransactionItem } from 'components/common/TransactionHistory';

import { TranslationKey, useTranslation } from 'libs/i18n';

interface TradeHistoryProps {
  trade: SmartTrade;
}

export const TradeHistory: FC<TradeHistoryProps> = ({ trade }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();

  const loadMore = useCallback(
    async (page: number, limit: number) => {
      const { success, data } = await dispatch(
        requestTransactionHistory({
          id: trade.id,
          offset: page * limit,
          limit,
        }),
      );

      if (success && data?.data.length) {
        return data.data.map(
          (hi) =>
            ({
              id: hi.id,
              date: hi.date,
              currencyCode: trade.primaryCurrency,
              amount: (hi.operationAmount || 0) * (hi.operationType === 'SELL_OPERATION' ? -1 : 1),
              label: translate(('SMART_TRADER_' + hi.operationType) as TranslationKey, {
                currencyCode: trade.primaryCurrency,
              }),
            } as TransactionItem),
        );
      }
      return null;
    },
    [dispatch, translate, trade],
  );

  return (
    <div className="column gap-3">
      <h3>{translate('HISTORY')}</h3>
      <TransactionHistory
        requestTransactions={loadMore}
        hideZeroAmount
        groupByDate
        pageSize={20}
        scrollInside
      />
    </div>
  );
};
