import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { MIN_DEPOSIT_AMOUNT_TO_CREATE_IBAN_ACCOUNT_IN_EUR } from 'modules/digitalAccount/constants/config';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';

import { Button, CheckBox, DrawerHeader, Image } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { voidFunc } from 'types';

import classes from './IbanAccountCreatingPrompt.module.scss';

interface AccountHolderCheckboxProps {
  value: boolean;
  onClick: voidFunc;
}
const AccountHolderCheckbox: FC<AccountHolderCheckboxProps> = ({ onClick, value }) => {
  const translate = useTranslation();

  return (
    <div className={classes.checkboxCard} onClick={onClick}>
      <div className="column gap-0-5">
        <span>{translate('IBAN_ACCOUNT_CREATING_PROMPT_ACCOUNT_HOLDER_TITLE')}</span>
        <p>{translate('IBAN_ACCOUNT_CREATING_PROMPT_ACCOUNT_HOLDER_SUBTITLE')}</p>
      </div>
      <CheckBox rounded checked={value} />
    </div>
  );
};

const IbanAccountCreatingPrompt = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const accountHolderConfirmation = useFlag(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleContinue = useCallback(() => {
    drawer.open(ibanAccountDrawerTemplates.accountCreatingDeposit());
  }, [drawer]);

  return (
    <div className="pt-2 column flex-1">
      <DrawerHeader showBackButton title={translate('IBAN_ACCOUNT_CREATING_PROMPT_TITLE')} />
      <div className="flexScrollable mt-1 gap-3 jcsb">
        <p
          className={classes.subtitle}
          dangerouslySetInnerHTML={{
            __html: translate('IBAN_ACCOUNT_CREATING_PROMPT_SUBTITLE', {
              amountLabel: formatCurrencyWithSymbol(
                MIN_DEPOSIT_AMOUNT_TO_CREATE_IBAN_ACCOUNT_IN_EUR,
                'EUR',
              ),
            }),
          }}
        />
        <Image className={classes.ibanLogo} name="ibanLogo" path="common" type="svg" />
        <AccountHolderCheckbox
          value={accountHolderConfirmation.state}
          onClick={accountHolderConfirmation.toggle}
        />
      </div>
      {/*<Button disabled={!accountHolderConfirmation.state} className="mt-3" onClick={handleContinue}>*/}
      {/*  {translate('CREATE')}*/}
      {/*</Button>*/}
      <Button disabled className="mt-3">
        {translate('TEMPORARILY_UNAVAILABLE')}
      </Button>
    </div>
  );
};
export default IbanAccountCreatingPrompt;
