import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { selectBankCardsReducer } from 'modules/cryptoBankCard/store/selectors';

import routesByName from 'constants/routesByName';

const ActivateCryptoCardByUrl = () => {
  const drawer = useDrawer();
  const bankCardsReducer = useSelector(selectBankCardsReducer);

  useEffect(() => {
    const bankCard = bankCardsReducer.data.find(
      (bankCard) => !bankCard.isVirtual && !bankCard.isActivated,
    );
    if (bankCardsReducer.meta.loaded && bankCard) {
      drawer.open(
        commonDrawerTemplates.bankCards({
          type: 'crypto',
          props: { initialBankCardId: bankCard.id },
        }),
      );
    }

    // eslint-disable-next-line
  }, [bankCardsReducer.meta.loaded, JSON.stringify(bankCardsReducer.data)]);

  return <Navigate to={routesByName.dashboard} />;
};

export default ActivateCryptoCardByUrl;
