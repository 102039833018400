import { useMemo } from 'react';

import { RatesHistoryItem } from 'modules/exchange/types';

const useHistoryRates = (history: RatesHistoryItem[]) =>
  useMemo(() => {
    const oldest = history[0] || 0;
    const newest = history[history.length - 1] || 0;
    const difference = newest.value - oldest.value;

    let changePercent = difference / (oldest.value / 100);
    changePercent = +changePercent?.toFixed(2);

    return {
      changePercent,
      changePercentLabel: isNaN(changePercent)
        ? ''
        : `${changePercent > 0 ? '+' : ''}${changePercent}%`,
      raised: changePercent > 0,
    };
  }, [history]);
export default useHistoryRates;
