import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import { selectIsUserVerified } from 'modules/user/store/selectors';

const useVerificationCheck = () => {
  const drawer = useDrawer();

  const userVerified = useSelector(selectIsUserVerified);

  const checkVerification = useCallback(() => {
    if (!userVerified) {
      drawer.open(userDrawerTemplates.verificationID());
    }

    return userVerified;
  }, [drawer, userVerified]);

  return checkVerification;
};

export default useVerificationCheck;
