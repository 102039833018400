import { useEffect } from 'react';

import accountsDrawerTemplates from 'modules/accounts/constants/drawerTemplates';
import { requestGetTransactionsForRefund } from 'modules/accounts/store/thunks';
import useDrawer from 'modules/app/hooks/useDrawer';
import { useDispatch } from 'store';

import useEntity from 'hooks/useEntity';

const useRefundTransactions = () => {
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const { entity: transactions } = useEntity(() => dispatch(requestGetTransactionsForRefund()), {
    fetchOnMount: true,
  });

  useEffect(() => {
    if (transactions?.length) {
      drawer.open(accountsDrawerTemplates.transactionDetails({ transaction: transactions[0] }));
    }
    // eslint-disable-next-line
  }, [transactions]);
};
export default useRefundTransactions;
