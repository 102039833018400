import { Placement } from '@floating-ui/core/src/types';
import {
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import clsx from 'clsx';
import { isEqual } from 'lodash';

import {
  FC,
  HTMLProps,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { SwipeModal, SwipeModalProps } from 'components/ui/SwipeModal';

import useMobile from 'hooks/useMobile';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './Select.module.scss';

export interface SelectProps<T> extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  isOpen: boolean;
  onClose: voidFunc;
  value?: T | null;
  onPick: (value: T) => void;
  options: T[];
  anchorEl: HTMLElement | null;
  labelAccessor?: (value: T) => string;
  keyAccessor?: (value: T) => string;
  className?: string;
  renderItem?: (value: T, index: number, isActive: boolean) => ReactElement;
  placement?: Placement;
  flipEnabled?: boolean;
  startAdornment?: ReactElement;
  onMobileShowSwipeModal?: boolean;
  maxHeightSwipeModal?: boolean;
  swipeModalVariant?: SwipeModalProps['variant'];
  sizeStyle?: FloatingSelectProps['sizeStyle'];
  optionsWrapperClassName?: string;
  variant?: 'creamy' | 'white';
  floatingSelectProps?: Partial<FloatingSelectProps>;
  noOptionsLabel?: string;
}

interface OptionProps {
  label: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Option: FC<OptionProps> = ({ label, onClick }) => {
  return (
    <div onClick={onClick} key={label} className={classes.option}>
      <span>{label}</span>
    </div>
  );
};

const defaultAccessor = (value: any) => value;

interface FloatingSelectProps {
  children: ReactElement | ReactElement[];
  isVisible: boolean;
  onClose: voidFunc;
  optionsLength: number;
  anchorEl: HTMLElement | null;
  className?: string;
  flipEnabled?: boolean;
  placement?: Placement;
  sizeStyle?: { width?: string; minWidth?: string };
  variant?: 'creamy' | 'white';
  offset?: number;
}
const FloatingSelect: FC<FloatingSelectProps> = ({
  isVisible,
  onClose,
  anchorEl,
  optionsLength,
  className,
  children,
  placement = 'bottom',
  sizeStyle,
  flipEnabled = true,
  variant = 'white',
  offset: offsetFromProps = 12,
}) => {
  const floatingMiddleware = useMemo(() => {
    const result = [
      offset({ mainAxis: offsetFromProps }),
      shift(),
      flipEnabled ? flip() : undefined,
      size({
        apply({ availableWidth, availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 12}px`,
            width: `${rects.reference.width}px`,
            ...sizeStyle,
          });
        },
      }),
    ];

    return result;
  }, [sizeStyle, offsetFromProps, flipEnabled]);
  const { x, y, reference, floating, strategy, context, update } = useFloating({
    open: isVisible,
    onOpenChange: onClose,
    placement,
    middleware: floatingMiddleware,
  });

  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    reference(anchorEl);
  }, [reference, anchorEl]);

  useEffect(() => {
    update();
    // eslint-disable-next-line
  }, [optionsLength, flipEnabled]);

  return isVisible ? (
    <div
      ref={floating}
      className={clsx(classes.root, classes[`variant-${variant}`], className)}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      {...getFloatingProps()}
    >
      {children}
    </div>
  ) : null;
};

export const Select = <T,>({
  value,
  isOpen,
  onPick,
  options,
  labelAccessor = defaultAccessor,
  keyAccessor = defaultAccessor,
  className,
  renderItem: renderItemFromProps,
  anchorEl,
  placement,
  flipEnabled,
  onClose,
  startAdornment,
  onMobileShowSwipeModal = true,
  maxHeightSwipeModal,
  swipeModalVariant,
  optionsWrapperClassName,
  variant = 'white',
  floatingSelectProps,
  noOptionsLabel,
  ...props
}: SelectProps<T>) => {
  const translate = useTranslation();
  const isMobile = useMobile();

  const renderItem = useCallback(
    (option: T, index: number) => {
      if (renderItemFromProps) {
        return (
          <div
            key={keyAccessor(option)}
            onClick={() => {
              onPick(option);
              onClose();
            }}
          >
            {renderItemFromProps(option, index, isEqual(value, option))}
          </div>
        );
      }
      return (
        <Option
          key={keyAccessor(option)}
          label={labelAccessor(option)}
          onClick={() => {
            onPick(option);
            onClose();
          }}
        />
      );
    },
    [value, onClose, keyAccessor, labelAccessor, renderItemFromProps, onPick],
  );

  const showAsSwipeModal = isMobile && onMobileShowSwipeModal;

  const Component = showAsSwipeModal ? SwipeModal : FloatingSelect;

  const otherProps = useMemo(
    () =>
      showAsSwipeModal
        ? { maxHeight: maxHeightSwipeModal, variant: swipeModalVariant }
        : {
            optionsLength: options.length,
            anchorEl,
            placement,
            flipEnabled,
            variant,
            ...floatingSelectProps,
          },
    [
      variant,
      showAsSwipeModal,
      maxHeightSwipeModal,
      swipeModalVariant,
      options.length,
      anchorEl,
      placement,
      flipEnabled,
      floatingSelectProps,
    ],
  );

  return (
    // @ts-ignore
    <Component
      isVisible={isOpen}
      onClose={onClose}
      className={className}
      index={999}
      {...otherProps}
      {...props}
    >
      <>
        {startAdornment}
        <div className={clsx(classes.options, optionsWrapperClassName)}>
          {options.length ? (
            options.map(renderItem)
          ) : (
            <Option label={noOptionsLabel || translate('VALIDATION_SELECT_NO_OPTIONS')} />
          )}
        </div>
      </>
    </Component>
  );
};
