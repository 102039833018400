import { VaultTransactionItem, VaultWallet } from 'modules/vault/types';
import { transformList } from 'store/generators/transformers';

import {
  VaultWalletDto,
  VaultWalletResponseList,
  VaultWalletTransactionDto,
  VaultWalletTransactionResponseList,
} from 'libs/swagger/nebeusApiTypes';

import { getCurrencyLabelByCode } from 'utils/currency';

export const transformVaultWallets = (wallets: VaultWalletResponseList['vaultWallets']) =>
  transformList<VaultWalletDto, VaultWallet>(wallets, [
    ['id', 'id', { convertToString: true }],
    ['amount'],
    ['amountInDefaultCurrency'],
    ['currencyCode'],
    ['defaultCurrencyCode'],
    // @ts-ignore
    ['currencyCode', 'currencyLabel', { custom: getCurrencyLabelByCode }],
    ['exist', 'exist', { custom: () => true }],
  ]);
export const transformVaultTransactions = (
  transactions: VaultWalletTransactionResponseList['vaultWalletTransactions'],
) =>
  transformList<VaultWalletTransactionDto, VaultTransactionItem>(transactions, [
    ['id'],
    ['amount'],
    ['currencyCode'],
    ['description', 'label'],
    ['date'],
  ]);
