import clsx from 'clsx';

import { FC, ReactNode } from 'react';

import { useTranslation } from 'libs/i18n';

import classes from './ImpornantCard.module.scss';

interface ImportantCardProps {
  title?: string;
  text: string;
  className?: string;
  children?: ReactNode;
  variant?: 'red' | 'fullRed';
}

export const ImportantCard: FC<ImportantCardProps> = ({
  title: titleFromProps,
  text,
  className,
  children,
  variant,
}) => {
  const translate = useTranslation();

  const title = titleFromProps || translate('IMPORTANT');

  return (
    <div
      className={clsx(
        classes.root,
        className,
        classes[`variant-${variant}`],
        'outlinedCard column gap-2',
      )}
    >
      <div className="column">
        <div className="row aic">
          <span className={classes.importantIcon}>!</span>
          <span className={classes.importantLabel}>{title}:</span>
        </div>
        <p className={classes.importantInfoLabel} dangerouslySetInnerHTML={{ __html: text }} />
      </div>

      {children}
    </div>
  );
};
