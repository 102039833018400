import { FC } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { ExchangeSuccessCard } from 'modules/exchange/views/components/ExchangeSuccessCard';

import { Button } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import { useTranslation } from 'libs/i18n';

export interface ExchangeSuccessProps {
  from: CurrencyAmount;
  to: CurrencyAmount;
  rate: number;
  fee: CurrencyAmount;
  date: string;
}

const ExchangeSuccess: FC<ExchangeSuccessProps> = (props) => {
  const translate = useTranslation();

  const drawer = useDrawer();
  return (
    <div className="column gap-3 mt-3 flex-1 jcsb">
      <ExchangeSuccessCard {...props} />
      <Button
        onClick={() => {
          drawer.pop();
        }}
        fullWidth
      >
        {translate('CLOSE')}
      </Button>
    </div>
  );
};

export default ExchangeSuccess;
