import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectRatesHistory } from 'modules/exchange/store/selectors';
import { requestRatesHistory } from 'modules/exchange/store/thunks';
import { useDispatch } from 'store';

import { isEntityStale } from 'utils/common';

import { CurrencyCode } from 'types';

const useFetchHistoryRatesBatched = (currencies: CurrencyCode[], toCurrency: CurrencyCode) => {
  const dispatch = useDispatch();

  const fetchHistory = useCallback(
    async (from: CurrencyCode[], to: CurrencyCode[]) => {
      await dispatch(requestRatesHistory({ from, to }));
    },
    [dispatch],
  );

  const histories = useSelector(selectRatesHistory);

  useEffect(() => {
    const currenciesWithoutHistoryData = currencies.filter(
      (code) =>
        !histories[`${code}/${toCurrency}`] ||
        isEntityStale(histories[`${code}/${toCurrency}`]?.lastLoadedTime || null, 15),
    );

    if (currenciesWithoutHistoryData.length && toCurrency) {
      fetchHistory(currenciesWithoutHistoryData, [toCurrency]);
    }
    // eslint-disable-next-line
  }, [currencies, toCurrency]);

  return { histories };
};

export default useFetchHistoryRatesBatched;
