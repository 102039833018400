import { FC, useCallback, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import { requestCreateWallet } from 'modules/accounts/store/thunks';
import useDrawer from 'modules/app/hooks/useDrawer';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import usePaymentInit from 'modules/payment/hooks/usePaymentInit';
import {
  requestDepositFromBankCardToCryptoWallet,
  requestSendFromTradingWalletToBankCard,
} from 'modules/payment/store/thunks';
import { PaymentOperationId, transactionTypesForOtpSending } from 'modules/payment/types';
import { isDepositByOperationId } from 'modules/payment/utils';
import getDepositResultDrawerTemplate from 'modules/payment/views/DepositSendBankCard/utils/getDepositResultDrawerTemplate';
import { AmountForm, BankCardForm, WalletBalanceCard } from 'modules/payment/views/components';
import { PaymentAmountFormValues } from 'modules/payment/views/components/AmountForm';
import useAmountFormValidation from 'modules/payment/views/components/AmountForm/hooks/useAmountFormValidation';
import {
  BankCardFormValues,
  getBankCardFormInitialValues,
  getBankCardValidationSchema,
} from 'modules/payment/views/components/BankCardForm';
import { WithdrawalLimitsCard } from 'modules/payment/views/components/WithdrawalLimitsCard';
import { selectSelectedRecipientForSendingFunds } from 'modules/phonebook/store/selectors';
import RecipientSelector from 'modules/phonebook/views/RecipientSelector';
import { selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { DottedLine } from 'components/ui';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { getTranslation, useTranslation } from 'libs/i18n';
import { makeValidate } from 'libs/yup';

import { formatCurrencyWithSymbol, isFiat } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';
import { openExternalLinkInCurrentWindow } from 'utils/links';

import { CurrencyCode } from 'types';

interface DepositSendBankCardProps {
  currencyCode: CurrencyCode;
  operationId:
    | PaymentOperationId.sendFromTradingWalletToBankCard
    | PaymentOperationId.depositFromBankCardToTradingWallet;
}

interface FormValues extends PaymentAmountFormValues, BankCardFormValues {}

interface DepositSendBankCardFormProps extends FormRenderProps<FormValues> {
  isDeposit: boolean;
  operationId:
    | PaymentOperationId.sendFromTradingWalletToBankCard
    | PaymentOperationId.depositFromBankCardToTradingWallet;
}

const DepositSendBankCardForm: FC<DepositSendBankCardFormProps> = ({
  handleSubmit,
  values,
  isDeposit,
  operationId,
}) => {
  const translate = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="column gap-3">
      {isDeposit ? (
        <div className="column gap-2">
          <span className="label">{translate('PAYMENT_ENTER_CARD_DETAILS')}</span>
          <BankCardForm isDeposit={isDeposit} />
        </div>
      ) : (
        <RecipientSelector paymentType="BANK_CARD">
          <BankCardForm isDeposit={isDeposit} />
        </RecipientSelector>
      )}

      {isDeposit ? (
        <DottedLine />
      ) : (
        <WithdrawalLimitsCard
          currencyCode={values.currencyAmount.currency}
          operationId={PaymentOperationId.sendFromTradingWalletToBankCard}
        />
      )}

      <AmountForm operationId={operationId} />
    </form>
  );
};

const DepositSendBankCard: FC<DepositSendBankCardProps> = ({ currencyCode, operationId }) => {
  const drawer = useDrawer();
  const translate = useTranslation();
  const dispatch = useDispatch();

  usePaymentInit();

  const isDeposit = isDepositByOperationId(operationId);
  const isCrypto = !isFiat(currencyCode);

  const userProfile = useSelector(selectUserProfile);

  const tradingWallets = useSelector(selectWallets);

  const initialValues = useMemo<FormValues>(
    () => ({
      currencyAmount: { amount: '', currency: currencyCode },
      currencyAmountWithCommission: { amount: '', currency: isCrypto ? 'EUR' : currencyCode },
      commissionAmount: null,
      ...getBankCardFormInitialValues({ isDeposit }),
    }),
    [currencyCode, isDeposit, isCrypto],
  );

  const phonebookRecipient = useSelector(selectSelectedRecipientForSendingFunds);

  const sendFunds = useCallback(
    async (values: FormValues, code: string) => {
      const fullNamePaths = values.fullName.trim().split(' ');
      const payload = {
        amount: +values.currencyAmount.amount,
        currencyCode: values.currencyAmount.currency,
        cardNumber: values.cardNumber.replaceAll(' ', ''),
        firstname: fullNamePaths[0],
        lastname: fullNamePaths[fullNamePaths.length - 1],
        otpCode: code,
        // @ts-ignore For analytic purpose
        analyticField_phonebookRecipient: phonebookRecipient,
      };
      const { success, error } = await dispatch(requestSendFromTradingWalletToBankCard(payload));

      const drawerTemplate = paymentDrawerTemplates.finishedOperation({
        currencyCode: values.currencyAmount.currency,
        amount: +values.currencyAmount.amount * -1,
        isDeposit: false,
        isSuccess: success,
        description: success
          ? translate('PAYMENT_SEND_FROM_TRADING_TO_BANK_CARD_SUCCESS')
          : error?.message || getTranslation('ERROR_SOMETHING_BROKE'),
        summaryBlocks: [
          [
            {
              label: translate('DESCRIPTION'),
              value: translate('PAYMENT_SEND_CURRENCY_BY_BANK_CARD', { currencyCode }),
            },
            { label: translate('DATE_&_TIME'), value: formatDDMMYY_HHMM(new Date()) },
            {
              label: translate('ACCOUNT_OPERATION_STATUS'),
              value: translate(
                success ? 'PAYMENT_OPERATION_COMPLETED_SUCCESSFULLY' : 'PAYMENT_OPERATION_FAILED',
              ),
            },
          ],
          [
            {
              label: translate('SENDER'),
              value: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
            },
            { label: translate('RECIPIENT'), value: values.fullName },
            { label: translate('RECIPIENTS_BANK_CARD_NUMBER'), value: values.cardNumber },
          ],
          [
            {
              label: translate('PAYMENT_RECIPIENT_WILL_GET'),
              value: formatCurrencyWithSymbol(
                values.currencyAmountWithCommission?.amount || values.currencyAmount.amount,
                currencyCode,
              ),
            },
            {
              label: translate('PAYMENT_NEBEUS_FEE'),
              value: formatCurrencyWithSymbol(values.commissionAmount || 0, currencyCode),
            },
            {
              label: translate('PAYMENT_TOTAL_WITHDRAWN'),
              value: formatCurrencyWithSymbol(
                values.currencyAmount.amount,
                values.currencyAmount.currency,
              ),
            },
          ],
        ],
      });

      if (success) {
        drawer.replaceAll(drawerTemplate);
      } else {
        drawer.replace(drawerTemplate);
      }
    },
    [phonebookRecipient, currencyCode, drawer, userProfile, dispatch, translate],
  );

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (isDeposit) {
        const wallet = tradingWallets.find(
          (w) => w.currencyCode === values.currencyAmount.currency,
        );

        if (wallet && !wallet.exist && 'issuerId' in wallet) {
          await dispatch(requestCreateWallet({ issuerId: wallet.issuerId }));
        }

        const [expMonth, expYearTwo] = values.expDate.split('/');

        const expYearFour = new Date().getFullYear().toString().slice(0, -2) + expYearTwo;

        const { data, success, error } = await dispatch(
          requestDepositFromBankCardToCryptoWallet({
            amount: +values.currencyAmount.amount,
            currencyCode: values.currencyAmount.currency,
            card: {
              cardNumber: values.cardNumber.replaceAll(' ', ''),
              cardPrintedName: values.fullName,
              cvv2: values.cvc!,
              expireMonth: expMonth,
              expireYear: expYearFour,
            },
          }),
        );

        const isSuccess = Boolean(success && data?.outputRedirectToUrl);

        if (isSuccess) {
          openExternalLinkInCurrentWindow(data!.outputRedirectToUrl);
        } else {
          const drawerTemplate = getDepositResultDrawerTemplate({
            amountWithCommission: +(
              values.currencyAmountWithCommission?.amount || values.currencyAmount.amount
            ),
            amount: +values.currencyAmount.amount,
            currencyCode: values.currencyAmount.currency,
            isSuccess,
            commissionAmount: values.commissionAmount || 0,
            userProfile: userProfile!,
            errorMessage: error?.message,
            bankCardFullName: values.fullName,
            bankCardNumber: values.cardNumber,
          });
          drawer.open(drawerTemplate);
        }
      } else {
        const summaryBlocks: SummaryCardItem[][] = [
          [
            {
              label: translate('SENDER'),
              value: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
            },
            { label: translate('RECIPIENT'), value: values.fullName },
            { label: translate('RECIPIENTS_BANK_CARD_NUMBER'), value: values.cardNumber }, // TODO: SAVED WALLET
          ],
          [
            {
              label: translate('PAYMENT_RECIPIENTS_GETS'),
              value: formatCurrencyWithSymbol(
                values.currencyAmountWithCommission?.amount || values.currencyAmount.amount,
                currencyCode,
              ),
            },
            {
              label: translate('PAYMENT_NEBEUS_FEE'),
              value: formatCurrencyWithSymbol(values.commissionAmount || 0, currencyCode),
            },
            {
              label: translate('PAYMENT_TOTAL_WITHDRAWN'),
              value: formatCurrencyWithSymbol(
                values.currencyAmount.amount,
                values.currencyAmount.currency,
              ),
            },
          ],
        ];
        drawer.open(
          paymentDrawerTemplates.confirmOTPCode({
            onConfirmed: (code: string) => sendFunds(values, code),
            summaryBlocks,
            summary: {
              currencyAmount: values.currencyAmount,
              currencyAmountWithCommission: values.currencyAmountWithCommission,
            },
            importantLabel: translate('PAYMENT_SEND_FIAT_IMPORTANT_NOTE'),
            otpByEmail: userProfile?.otpByEmail,
            transactionType:
              transactionTypesForOtpSending[PaymentOperationId.sendFromTradingWalletToBankCard],
          }),
        );
      }
    },
    [tradingWallets, userProfile, currencyCode, sendFunds, isDeposit, drawer, translate, dispatch],
  );

  const { amountFormSchema } = useAmountFormValidation({ operationId, currencyCode });
  const bankCardSchema = useMemo(() => getBankCardValidationSchema({ isDeposit }), [isDeposit]);

  const validate = useMemo(
    () => makeValidate(amountFormSchema.concat(bankCardSchema)),
    [amountFormSchema, bankCardSchema],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <DepositSendBankCardForm isDeposit={isDeposit} operationId={operationId} {...formProps} />
    ),
    [isDeposit, operationId],
  );
  return (
    <div className="mt-1 column gap-3">
      <WalletBalanceCard currencyCode={currencyCode} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        render={renderForm}
      />
    </div>
  );
};

export default DepositSendBankCard;
