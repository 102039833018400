import clsx from 'clsx';

import { ChangeEventHandler, FC, useCallback, useMemo, useState } from 'react';

import { CurrencyIcon } from 'components/ui/CurrencyIcon';
import { Icon } from 'components/ui/Icon';
import { SimpleCurrencyPicker } from 'components/ui/SimpleCurrencyPicker';
import { TextInput, TextInputProps } from 'components/ui/TextInput';

import useFlag from 'hooks/useFlag';

import { isFiat } from 'utils/currency';
import { parseAmount } from 'utils/inputParsers';

import { CurrencyCode, voidFunc } from 'types';

import classes from './SimpleCurrencyAmountInput.module.scss';

export interface CurrencyAmount {
  amount: string;
  currency: CurrencyCode;
}

export interface SimpleCurrencyAmountInputProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  value: CurrencyAmount;
  onChange: (value: Partial<CurrencyAmount>) => void;
  pickerDisabled?: boolean;
  currencyButtonLeft?: boolean;
  currenciesList?: CurrencyCode[];
}

interface CurrencyPickerButtonProps {
  onClick: voidFunc;
  currencyCode: CurrencyCode;
  isOpen: boolean;
  className?: string;
  disabled?: boolean;
  onlyArrow?: boolean;
}

const CurrencyPickerButton: FC<CurrencyPickerButtonProps> = ({
  onClick,
  className,
  currencyCode,
  isOpen,
  disabled,
  onlyArrow,
}) => {
  return (
    <div
      onClick={(e) => {
        if (disabled) {
          return;
        }
        e.stopPropagation();
        onClick();
      }}
      className={clsx(
        classes.currencyPickerButton,
        className,
        'row aic jcsb',
        !disabled && 'pointer',
      )}
    >
      {onlyArrow ? null : (
        <>
          <CurrencyIcon size={16} code={currencyCode} />
          <span>{currencyCode}</span>
        </>
      )}
      {disabled ? null : (
        <Icon
          className={clsx(classes.arrow, isOpen && classes.active)}
          size="sm"
          name="triangleDownLg"
          color="kindaBlack"
        />
      )}
    </div>
  );
};

export const SimpleCurrencyAmountInput: FC<SimpleCurrencyAmountInputProps> = ({
  value: { amount, currency },
  onChange,
  className,
  currenciesList,
  pickerDisabled = !currenciesList || currenciesList.length < 2,
  currencyButtonLeft,
  disabled: inputDisabled,
  ...props
}) => {
  const currencyPicker = useFlag(false);

  const currencyIsFiat = useMemo(() => isFiat(currency), [currency]);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const newInputValue = e.target.value;
      const parsedInputValue = parseAmount(newInputValue, currencyIsFiat);
      onChange({ currency, amount: parsedInputValue });
    },
    [onChange, currency, currencyIsFiat],
  );
  const handlePickCurrency = useCallback(
    (code: CurrencyCode) => {
      onChange({ currency: code, amount });
    },
    [onChange, amount],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleInputContainerClick = useCallback(() => {
    if (inputDisabled && !pickerDisabled) {
      currencyPicker.on();
    }
  }, [inputDisabled, pickerDisabled, currencyPicker]);

  return (
    <div className={clsx(classes.root, className)}>
      <TextInput
        inputContainerClassName={
          inputDisabled && !pickerDisabled ? classes.inputPointer : undefined
        }
        onInputContainerClick={handleInputContainerClick}
        inputContainerRef={setAnchorEl}
        focused={currencyPicker.state}
        borderOnFocus={currencyPicker.state ? 'green' : 'gray'}
        value={amount}
        inputMode="decimal"
        onChange={handleInputChange}
        startAdornment={
          currencyButtonLeft ? (
            <CurrencyIcon className="ml-1-5" size={24} code={currency} />
          ) : undefined
        }
        endAdornment={
          <CurrencyPickerButton
            onClick={currencyPicker.toggle}
            currencyCode={currency}
            isOpen={currencyPicker.state}
            className={classes.currencyPickerButtonAdornment}
            disabled={pickerDisabled}
            onlyArrow={currencyButtonLeft}
          />
        }
        disabled={inputDisabled}
        {...props}
      />

      {currenciesList ? (
        <SimpleCurrencyPicker
          anchorEl={anchorEl}
          currenciesList={currenciesList}
          selectedCurrency={currency}
          onPick={handlePickCurrency}
          isOpen={currencyPicker.state}
          onClose={currencyPicker.off}
        />
      ) : null}
    </div>
  );
};
