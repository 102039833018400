import clsx from 'clsx';

import { FC } from 'react';

import { selectBankCardFeeConfigReducer } from 'modules/cryptoBankCard/store/selectors';
import { requestFeeConfig } from 'modules/cryptoBankCard/store/thunks';

import { DetailsDotSeparated } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

interface CryptoBankCardFeesAndConditionsCardProps {
  className?: string;
}
export const CryptoBankCardFeesAndConditionsCard: FC<CryptoBankCardFeesAndConditionsCardProps> = ({
  className,
}) => {
  const translate = useTranslation();

  const {
    entityReducer: { data: config },
  } = useStoreEntity(selectBankCardFeeConfigReducer, requestFeeConfig);

  const loading = !config;

  return (
    <div className={clsx('column gap-3', className)}>
      <div className="column gap-1-5">
        <div className="row jcsb gap-3">
          <span className="label black">{translate('CRYPTO_CARD_FEE_OPTION_CRYPTO')}</span>
          <span className="label">{translate('FEE')}</span>
        </div>
        {config && config.monthlyTurnoverLimitAmount > 0 ? (
          <>
            <DetailsDotSeparated
              loading={loading}
              label={
                loading
                  ? undefined
                  : translate('CRYPTO_CARD_FEE_OPTION_CRYPTO_CONDITION_MORE', {
                      amountLabel: formatCurrencyWithSymbol(
                        config.monthlyTurnoverLimitAmount,
                        'EUR',
                      ),
                    })
              }
              value={
                loading
                  ? undefined
                  : formatCurrencyWithSymbol(config.feeOnlyCryptoTurnoverPassed, 'EUR')
              }
            />
            <DetailsDotSeparated
              loading={loading}
              label={
                loading
                  ? undefined
                  : translate('CRYPTO_CARD_FEE_OPTION_CRYPTO_CONDITION_LESS', {
                      amountLabel: formatCurrencyWithSymbol(
                        config.monthlyTurnoverLimitAmount,
                        'EUR',
                      ),
                    })
              }
              value={
                loading
                  ? undefined
                  : formatCurrencyWithSymbol(config.feeOnlyCryptoTurnoverNotPassed, 'EUR')
              }
            />
          </>
        ) : (
          <DetailsDotSeparated
            loading={loading}
            label={translate('ANY_EXPENSES')}
            value={loading ? undefined : formatCurrencyWithSymbol(config.feeOnlyCryptoUsed, 'EUR')}
          />
        )}
      </div>
      <div className="column gap-1-5">
        <div className="row jcsb gap-3">
          <span className="label black">{translate('CRYPTO_CARD_FEE_OPTION_FIAT')}</span>
          <span className="label">{translate('FEE')}</span>
        </div>
        <DetailsDotSeparated
          loading={loading}
          label={translate('ANY_EXPENSES')}
          value={loading ? undefined : formatCurrencyWithSymbol(config.feeOnlyFiatUsed, 'EUR')}
        />
      </div>
      <div className="column gap-1-5">
        <div className="row jcsb gap-3">
          <span className="label black">{translate('CRYPTO_CARD_FEE_OPTION_CRYPTO_AND_FIAT')}</span>
          <span className="label">{translate('FEE')}</span>
        </div>
        <DetailsDotSeparated
          loading={loading}
          label={translate('ANY_EXPENSES')}
          value={loading ? undefined : formatCurrencyWithSymbol(config.feeCryptoFiatUsed, 'EUR')}
        />
      </div>
    </div>
  );
};
