import clsx from 'clsx';

import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import {
  selectActiveDigitalSubscription,
  selectSubscriptionReducer,
} from 'modules/digitalAccount/store/selectors';
import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionCard } from 'modules/digitalAccount/views/components/SubscriptionCard';

import { Loader } from 'components/ui';

import { TranslationKey, useTranslation } from 'libs/i18n';

import classes from './ChooseSubscription.module.scss';

export interface ChooseSubscriptionProps {
  variant?: 'buyBankCard';
}

const whiteTitleKeys: TranslationKey[] = [
  'DIGITAL_ACCOUNT_GET_CARD_UPG_SUB',
  'DIGITAL_ACCOUNT_GET_CARD_BUY_SUB',
];
const ChooseSubscription: FC<ChooseSubscriptionProps> = ({ variant }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const subscriptionsReducer = useSelector(selectSubscriptionReducer);
  const activeSubscription = useSelector(selectActiveDigitalSubscription);

  const handleChoose = useCallback(
    (subscription: DigitalSubscription) => {
      if (subscription.type === 'standard' && activeSubscription) {
        sidebar.open(...sidebarTemplates.digitalAccountDowngradeSubscriptionToStandard());
      } else {
        sidebar.open(...sidebarTemplates.digitalAccountGetSubscription({ subscription }));
      }
    },
    [sidebar, activeSubscription],
  );

  const activeSub = useSelector(selectActiveDigitalSubscription);

  const titleKey = useMemo<TranslationKey>(() => {
    if (variant === 'buyBankCard') {
      if (activeSub?.type === 'standard') {
        return 'DIGITAL_ACCOUNT_GET_CARD_UPG_SUB';
      }
      if (activeSub) {
        return 'DIGITAL_ACCOUNT_GET_CARD_BUY_SUB';
      }
    }
    if (activeSub) {
      if (activeSub.type === 'whale') {
        return 'DOWNGRADE_YOUR_SUBSCRIPTION';
      }
      if (activeSub.type === 'standard') {
        return 'UPGRADE_YOUR_SUBSCRIPTION';
      }
    }

    return 'CHOOSE_YOUR_SUBSCRIPTION';
  }, [activeSub, variant]);

  return (
    <div className={classes.root}>
      <h5 className={clsx(classes.title, whiteTitleKeys.includes(titleKey) && classes.white)}>
        {translate(titleKey)}
      </h5>
      <div className="mt-6 column gap-3">
        {subscriptionsReducer.meta.loading && !subscriptionsReducer.meta.loaded ? (
          <Loader centered className="mt-3" />
        ) : (
          subscriptionsReducer.data.map((s) => (
            <SubscriptionCard
              key={s.id}
              subscription={s}
              onClick={handleChoose}
              variant="withPrice"
              disabled={s.type === activeSub?.type || s.type !== 'standard'}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ChooseSubscription;
