import cardValidator from 'card-validator';
import ibanLib from 'iban';

import { PaymentDetails, PaymentDetailsItem } from 'modules/phonebook/types';
import classes from 'modules/phonebook/views/Counterparty/Counterparty.module.scss';

import { CurrencyIcon, Icon, Image } from 'components/ui';

import { TranslationKey } from 'libs/i18n';

import { formatBankCardNumber } from 'utils/formatters';

import { CurrencyCode } from 'types';

export const configPaymentDetailsByType: {
  [key in PaymentDetails['type']]: {
    label: TranslationKey;
    addLabel: TranslationKey;
    editLabel: TranslationKey;
    addIconName: string;
    primaryDetailsField: PaymentDetailsItem['type'];
  };
} = {
  BANK_CARD: {
    label: 'BANK_CARD',
    addLabel: 'PHONEBOOK_ADD_CARD',
    editLabel: 'PHONEBOOK_EDIT_CARD',
    addIconName: 'bankCardPlus',
    primaryDetailsField: 'NUMBER',
  },
  SEPA: {
    label: 'SEPA_ACCOUNTS',
    addLabel: 'PHONEBOOK_ADD_SEPA',
    editLabel: 'PHONEBOOK_EDIT_SEPA',
    addIconName: 'paymentSEPA',
    primaryDetailsField: 'IBAN',
  },
  WIRE: {
    label: 'WIRE_ACCOUNTS',
    addLabel: 'PHONEBOOK_ADD_WIRE',
    editLabel: 'PHONEBOOK_EDIT_WIRE',
    addIconName: 'paymentWire',
    primaryDetailsField: 'NUMBER',
  },
  CRYPTO_WALLET: {
    label: 'EXTERNAL_CRYPTO_WALLETS',
    addLabel: 'PHONEBOOK_ADD_CRYPTO_WALLET',
    editLabel: 'PHONEBOOK_EDIT_CRYPTO_WALLET',
    addIconName: 'addWalletCard',
    primaryDetailsField: 'CRYPTO_WALLET_ADDRESS',
  },
};

export const getIconByPaymentDetails = (
  details: PaymentDetails,
  { size }: { size: 'sm' | 'lg' } = {
    size: 'lg',
  },
) => {
  switch (details.type) {
    case 'BANK_CARD': {
      const bankCardType = cardValidator.number(
        details.details.find((i) => i.type === 'NUMBER')?.value,
      ).card?.type;

      const bankCardTypeValid = bankCardType === 'visa' || bankCardType === 'mastercard';

      const imageName = bankCardType ? (size === 'sm' ? bankCardType + '2' : bankCardType) : null;

      return imageName && bankCardTypeValid ? (
        <Image name={imageName} path="payment" type="svg" />
      ) : (
        <Icon name="bankCard3" className={classes.bankCardIcon} />
      );
    }
    case 'SEPA': {
      return <CurrencyIcon code="EUR" size={size === 'sm' ? 16 : 32} />;
    }
    case 'WIRE': {
      return <CurrencyIcon code="GBP" size={size === 'sm' ? 16 : 32} />;
    }
    case 'CRYPTO_WALLET': {
      return (
        <CurrencyIcon
          code={details.details.find((i) => i.type === 'CURRENCY_CODE')!.value as CurrencyCode}
          size={size === 'sm' ? 16 : 32}
        />
      );
    }
  }
};

export const maskPaymentDetailsValue = (
  paymentDetailsType: PaymentDetails['type'],
  value: string | null,
): string => {
  if (!value) {
    return '';
  }
  switch (paymentDetailsType) {
    case 'BANK_CARD': {
      return `••• ${value.substring(value.length - 4)}`;
    }
    case 'SEPA': {
      return ibanLib.printFormat(value).substring(0, 4) + ' ••••';
    }
    case 'WIRE': {
      return value.substring(0, 4) + ' ••••';
    }
  }
  return value;
};

const fieldNamesByPaymentDetailsTypeAndFieldType: {
  [key in `${PaymentDetails['type']}/${PaymentDetailsItem['type']}`]?: string;
} = {
  'BANK_CARD/NUMBER': 'cardNumber',
  'BANK_CARD/NAME': 'fullName',
  'SEPA/NAME': 'accountName',
  'SEPA/IBAN': 'iban',
  'SEPA/REFERENCE': 'reference',
  'WIRE/NAME': 'accountName',
  'WIRE/NUMBER': 'accountNumber',
  'WIRE/REFERENCE': 'reference',
  'WIRE/SORT_CODE': 'sortCode',
  'CRYPTO_WALLET/CRYPTO_WALLET_ADDRESS': 'walletAddress',
  'CRYPTO_WALLET/CRYPTO_WALLET_ADDITIONAL': 'walletAddressAddition',
  'CRYPTO_WALLET/CRYPTO_WALLET_NETWORK_ID': 'walletNetwork',
  'CRYPTO_WALLET/CURRENCY_CODE': 'currencyCode',
};

export const remapPaymentDetailsToFormValues = (paymentDetails: PaymentDetails) => {
  return paymentDetails.details.reduce((acc, currentValue) => {
    let value = currentValue.value;
    if (value) {
      switch (currentValue.type) {
        case 'NUMBER': {
          if (paymentDetails.type === 'BANK_CARD') {
            value = formatBankCardNumber(value);
          }
        }
      }
    }

    return {
      ...acc,
      // @ts-ignore
      [fieldNamesByPaymentDetailsTypeAndFieldType[`${paymentDetails.type}/${currentValue.type}`]]:
        value,
    };
  }, {});
};
