import { FC } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import {
  translationKeySubDescSecondaryBySubType,
  translationKeyTitleBySubType,
} from 'modules/digitalAccount/constants/config';
import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionCheckCard } from 'modules/digitalAccount/views/components/SubscriptionCheckCard';
import { SubscriptionLayout } from 'modules/digitalAccount/views/components/SubscriptionLayout';

import { Button, Mark } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatDDMMYY_HHMM } from 'utils/date';

import classes from './CancelSubscriptionSuccess.module.scss';

export interface CancelSubscriptionSuccessProps {
  subscription: DigitalSubscription;
}

const CancelSubscriptionSuccess: FC<CancelSubscriptionSuccessProps> = ({ subscription }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  return (
    <SubscriptionLayout subscriptionType={subscription.type}>
      <div>
        <div className={classes.titles}>
          <h4>{translate('DA_SUBSCRIPTION_CANCELLED')}</h4>
          {subscription.endDate && (
            <p>
              {translate('DA_SUBSCRIPTION_CANCELLED_DESC', {
                endDate: formatDDMMYY_HHMM(new Date(subscription.endDate)),
              })}
            </p>
          )}
        </div>
        <div className={classes.blurredCard}>
          <Mark variant="white" className="row aic">
            {translate('CANCELLED')}
          </Mark>
          <div className={classes.subscriptionTitles}>
            <h5>
              Nebeus {translate(translationKeyTitleBySubType[subscription.type])}
              <br />
              {translate('DIGITAL_ACCOUNT')}
            </h5>
            <p>{translate(translationKeySubDescSecondaryBySubType[subscription.type])}</p>
          </div>
          <SubscriptionCheckCard
            variant="blurred"
            className={classes.checkCard}
            subscription={subscription}
          />
        </div>
      </div>
      <div className="column gap-1-5">
        <Button onClick={sidebar.pop} fullWidth variant="gold">
          {translate('BACK_TO_SUBSCRIPTIONS')}
        </Button>
      </div>
    </SubscriptionLayout>
  );
};

export default CancelSubscriptionSuccess;
