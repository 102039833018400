import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import loanDrawerTemplates from 'modules/loans/constants/drawerTemplates';
import { LoanItem } from 'modules/loans/types';
import LoanTypeAndId from 'modules/loans/views/components/LoanTypeAndId';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';

import { AmountLabel, Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode } from 'utils/currency';

import classes from './LoanMarginCall.module.scss';

export interface LoanMarginCallProps {
  loan: LoanItem;
}

const LoanMarginCall: FC<LoanMarginCallProps> = ({ loan }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const repay = useCallback(() => {
    drawer.open(loanDrawerTemplates.loanRepayment({ loan }));
  }, [drawer, loan]);

  const addCollateral = useCallback(() => {
    drawer.open(loanDrawerTemplates.loanAddCollateral({ loan }));
  }, [drawer, loan]);

  const depositFunds = useCallback(() => {
    drawer.open(
      paymentDrawerTemplates.paymentProcess({
        isDeposit: true,
        currencyCode: loan.collateralCurrency,
      }),
    );
  }, [drawer, loan]);

  return (
    <div className="column gap-3 flex-1 jcsb">
      <div className="flexScrollable gap-3">
        <p
          className="grey-400"
          dangerouslySetInnerHTML={{
            __html: translate(
              loan.autoManagementMC
                ? 'LOANS_LOAN_UNDER_MC_AUTO_MANAGEMENT_DESC'
                : 'LOANS_LOAN_UNDER_MC_DESC',
              {
                currencyLabel: getCurrencyLabelByCode(loan.collateralCurrency),
                currencyCode: loan.collateralCurrency,
                days: loan.leftDaysBeforeLiquidation || 0,
              },
            ),
          }}
        />

        <div className="outlinedCard column gap-3">
          <LoanTypeAndId id={loan.id} type={loan.templateType} />
          <span className="label">{translate('MINIMUM_DEPOSIT')}</span>
          <AmountLabel
            amount={loan.collateralMinDepositAmount!}
            currencyCode={loan.collateralCurrency}
            showCurrencySymbol
          />
        </div>
      </div>

      <div className="column gap-1-5">
        <Button
          fullWidth
          variant="creamyBlack"
          onClick={loan.autoManagementMC ? repay : addCollateral}
        >
          {translate(loan.autoManagementMC ? 'LOANS_REPAY_LOAN' : 'LOANS_ADD_COLLATERAL')}
        </Button>
        <Button fullWidth onClick={loan.autoManagementMC ? depositFunds : repay}>
          {translate(loan.autoManagementMC ? 'DEPOSIT' : 'LOANS_REPAY_LOAN')}
        </Button>
        <p
          className={classes.note}
          dangerouslySetInnerHTML={{ __html: translate('LOANS_ADD_COLLATERAL_NOTE') }}
        />
      </div>
    </div>
  );
};

export default LoanMarginCall;
