import { DescriptionCardsListItem } from 'modules/cryptoBankCard/views/DescriptionCards';
import { CryptoBankCardFeesAndConditionsCard } from 'modules/cryptoBankCard/views/components/CryptoBankCardFeesAndConditionsCard';

import { DottedLine } from 'components/ui';

const CryptoBankCardFeaturesEndAdornment = () => (
  <div className="column gap-3 mt-3">
    <DottedLine />
    <CryptoBankCardFeesAndConditionsCard />
  </div>
);

export const cryptoBankFeatures: DescriptionCardsListItem[] = [
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_1_TITLE',
    desc: 'CRYPTO_BANK_CARD_FEATURE_1_DESC',
    icon: 'chainCircles',
  },
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_2_TITLE',
    desc: 'CRYPTO_BANK_CARD_FEATURE_2_DESC',
    icon: 'arrowsUp',
  },
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_3_TITLE',
    desc: 'CRYPTO_BANK_CARD_FEATURE_3_DESC',
    icon: 'currenciesGroup',
  },
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_4_TITLE',
    desc: 'CRYPTO_BANK_CARD_FEATURE_4_DESC',
    icon: 'pieCircle',
  },
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_5_TITLE',
    desc: 'CRYPTO_BANK_CARD_FEATURE_5_DESC',
    icon: 'atm',
  },
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_6_TITLE',
    desc: 'CRYPTO_BANK_CARD_FEATURE_6_DESC',
    icon: 'atm',
  },
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_7_TITLE',
    desc: 'CRYPTO_BANK_CARD_FEATURE_7_DESC',
    icon: 'delivery',
  },
  {
    title: 'CRYPTO_CARD_FEE_SYSTEM_TITLE',
    desc: 'CRYPTO_CARD_FEE_SYSTEM_DESC',
    icon: 'percent',
    endAdornment: <CryptoBankCardFeaturesEndAdornment />,
  },
];
export const cryptoBankFeaturesShort: Omit<DescriptionCardsListItem, 'desc'>[] = [
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_1_TITLE',
    icon: 'chainCircles',
  },
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_2_TITLE',
    icon: 'arrowsUp',
  },
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_3_TITLE',
    icon: 'currenciesGroup',
  },
  {
    title: 'CRYPTO_BANK_CARD_FEATURE_4_TITLE',
    icon: 'pieCircle',
  },
  {
    title: 'CRYPTO_CARD_FEE_SYSTEM_TITLE',
    icon: 'percent',
  },
];
