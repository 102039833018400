import clsx from 'clsx';

import { FC, useState } from 'react';

import { CurrencyIcon } from 'components/ui/CurrencyIcon';
import { Icon } from 'components/ui/Icon';
import { SimpleCurrencyPicker } from 'components/ui/SimpleCurrencyPicker';
import { TextInput, TextInputProps } from 'components/ui/TextInput';

import useFlag from 'hooks/useFlag';

import { CurrencyCode } from 'types';

import classes from './SimpleCurrencyPickerInput.module.scss';

export interface SimpleCurrencyPickerInputProps extends Omit<TextInputProps, 'onChange'> {
  currencyCode: CurrencyCode;
  onChange: (code: CurrencyCode) => void;
  currenciesList: CurrencyCode[];
}

export const SimpleCurrencyPickerInput: FC<SimpleCurrencyPickerInputProps> = ({
  currencyCode,
  onChange,
  currenciesList,
  ...props
}) => {
  const pickerOpen = useFlag(false);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  return (
    <div className="relative">
      <TextInput
        onInputContainerClick={pickerOpen.toggle}
        inputContainerClassName={classes.inputContainerClassName}
        inputContainerRef={setAnchorEl}
        disabled
        startAdornment={<CurrencyIcon className="ml-1-5" size={24} code={currencyCode} />}
        endAdornment={
          <Icon
            className={clsx(classes.arrow, pickerOpen.state && classes.active)}
            size="sm"
            name="triangleDownLg"
            color="kindaBlack"
          />
        }
        inputMode="decimal"
        value={currencyCode}
        {...props}
      />
      <SimpleCurrencyPicker
        anchorEl={anchorEl}
        isOpen={pickerOpen.state}
        onClose={pickerOpen.off}
        currenciesList={currenciesList}
        selectedCurrency={currencyCode}
        onPick={onChange}
      />
    </div>
  );
};
