import { useSwiper } from 'swiper/react';

import { memo, useMemo } from 'react';

import { StepHeader } from 'modules/auth/views/components/StepHeader';

import { MenuList } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import { useTranslation } from 'libs/i18n';

import { openContactSupport } from 'utils/thirdPartyLibs';

export const IssuesWithTFA = memo(() => {
  const translate = useTranslation();

  const swiper = useSwiper();

  const menu = useMemo<MenuListItem[]>(
    () => [
      { label: translate('ENTER_BACKUP_CODE'), handler: () => swiper.slideNext(), icon: 'key2' },
      { label: translate('CONTACT_SUPPORT'), handler: openContactSupport, icon: 'chat' },
    ],
    [translate, swiper],
  );

  return (
    <div className="authStepRoot">
      <div className="column gap-3">
        <StepHeader
          title={translate('SIGN_WITH_TFA_HAVING_ISSUES')}
          subtitle={translate('SIGN_WITH_TFA_HAVING_ISSUES_SUBTITLE')}
        />
        <MenuList menu={menu} centered />
      </div>
    </div>
  );
});
