import { FC, useMemo } from 'react';

import { templateDescriptionByType } from 'modules/loans/constants/config';
import { LoanTemplate } from 'modules/loans/types';

import { CurrencyIcon } from 'components/ui';

import { TranslationKey, useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './TemplateDescription.module.scss';

export interface TemplateDescriptionProps {
  template: LoanTemplate;
}
const TemplateDescription: FC<TemplateDescriptionProps> = ({ template }) => {
  const translate = useTranslation();

  const collateralCurrencies = Object.keys(template.collateralRules) as CurrencyCode[];
  const loanCurrencies = Object.keys(template.loanRules) as CurrencyCode[];
  const loanRuleCurrency = loanCurrencies[0] || 'EUR';
  const loanRule = template.loanRules[loanRuleCurrency];

  const featuresList = useMemo<{ label: TranslationKey; value: string }[]>(
    () => [
      {
        label: 'LOAN_AMOUNT_RANGE',
        value: `${formatCurrencyWithSymbol(
          loanRule.minAmount,
          loanRuleCurrency,
        )}-${formatCurrencyWithSymbol(loanRule.maxAmount, loanRuleCurrency)}`,
      },
      {
        label: 'LOANS_COLLATERAL',
        value:
          collateralCurrencies.length > 2
            ? translate('TOKENS_COUNT', { count: collateralCurrencies.length })
            : collateralCurrencies.join(' & '),
      },
      {
        label: 'LOANS_INTEREST_RATE',
        value: `${
          template.minLtv === template.maxLtv
            ? ''
            : `${template.ltvRange[template.minLtv].percent}-`
        }${template.ltvRange[template.maxLtv].percent}%`,
      },
      {
        label: 'LOANS_INTEREST_LTV_FULL',
        value: `${template.minLtv === template.maxLtv ? '' : `${template.minLtv}-`}${
          template.maxLtv
        }%`,
      },
      {
        label: 'LOANS_LOAN_TERM',
        value: translate('DATE_MONTH_PLURAL_NO_BRACKETS', {
          count: `${
            template.minTermMonth === template.maxTermMonth ? '' : `${template.minTermMonth}-`
          }${template.maxTermMonth}`,
        }),
      },
      {
        label: 'LOAN_REPAYMENT',
        value: translate(
          template.type === 'BULLET' ? 'LOAN_REPAYMENT_ONCE' : 'LOAN_REPAYMENT_MONTHLY',
        ),
      },
      {
        label: 'LOANS_EARLY_REPAYMENT',
        value: template.earlyRepaymentPenaltyPercent
          ? `${template.earlyRepaymentPenaltyPercent}%`
          : translate('NOT_ALLOWED'),
      },
      {
        label: 'LOANS_LOAN_ORIGINATION_FEE',
        value: template.originationFeePercent.toString() + '%',
      },
    ],
    [loanRule, loanRuleCurrency, template, collateralCurrencies, translate],
  );

  return (
    <div className={classes.root}>
      <p className={classes.subtitle}>
        {translate(
          template.type === 'FLEXIBLE'
            ? 'LOANS_TEMPLATE_FLEXIBLE_DESC_LONG'
            : templateDescriptionByType[template.type],
        )}
      </p>
      <div className="column gap-2">
        {collateralCurrencies.length > 2 ? (
          <div className="outlinedCard mt-3 column gap-3">
            <span className="label">
              {translate('LOANS_TOKENS_AVAILABLE', {
                count: collateralCurrencies.length,
              })}
            </span>
            <div className={classes.currencies}>
              {collateralCurrencies.map((code) => (
                <div key={code} className={classes.currencyItem}>
                  <CurrencyIcon code={code} size={32} />
                  <span className="label">{code}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div className="outlinedCard  column gap-3">
          <span className="label">{translate('LOAN_FEATURES')}</span>
          <div className={classes.features}>
            {featuresList.map((feature) => (
              <div key={feature.label} className={classes.feature}>
                <span>{translate(feature.label)}</span>
                <span>{feature.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateDescription;
