import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectVaultWallets } from 'modules/vault/store/selectors';

import { AssetsCard } from 'components/ui';

import { useTranslation } from 'libs/i18n';

const colors = ['#FFBF00', '#2A2B2A'];
export const Assets = () => {
  const translate = useTranslation();

  const wallets = useSelector(selectVaultWallets);

  const defaultCurrency = wallets[0]?.defaultCurrencyCode || 'EUR';

  const assets = useMemo(
    () =>
      wallets.map((w) => ({
        amount: w.amountInDefaultCurrency,
        label: w.currencyLabel,
        currencyCode: w.defaultCurrencyCode,
      })),
    [wallets],
  );

  return (
    <AssetsCard
      label={translate('VAULT_TOTAL_VALUE')}
      currencyCode={defaultCurrency}
      assets={assets}
      colors={colors}
    />
  );
};
