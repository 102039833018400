import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { findLastIndex } from 'lodash';

import { deviceAllowedLanguage } from 'modules/user/constants/language';
import { generateDefaultReducer, generateRequestDataReducer } from 'store/generators/reducers';
import { initialArrayApiRequestState } from 'store/generators/utils';

import { initialConfig as initialFirebaseConfig } from 'libs/firebase';

import {
  AllowedCountry,
  AppState,
  BlogPost,
  Maintenance,
  OperationCommission,
  PopSideBarPayload,
  SideBarStackItem,
} from '../types';

// cli-import

const initialMaintenance: Maintenance = {
  dateFrom: null,
  dateTo: null,
  isActive: false,
};
const initialState: AppState = {
  inited: false,
  maintenance: initialMaintenance,
  allowedCountries: initialArrayApiRequestState,
  sideBarStack: [],
  blogPosts: {
    desktop: initialArrayApiRequestState,
    mobile: initialArrayApiRequestState,
  },
  operationsCommissions: initialArrayApiRequestState,

  firebaseConfig: initialFirebaseConfig,
  language: deviceAllowedLanguage,
  // cli-state
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateInited: generateDefaultReducer({ reducerPath: 'inited' }),

    updateFirebaseConfig: generateDefaultReducer({ reducerPath: 'firebaseConfig' }),

    updateAllowedCountries: generateRequestDataReducer<AppState, AllowedCountry[]>({
      reducerPath: 'allowedCountries',
    }),

    popSideBar: (
      state,
      { payload: { soft = true, key, id } = {} }: PayloadAction<PopSideBarPayload | undefined>,
    ) => {
      const target = key
        ? state.sideBarStack.find((i) => i.key === key && (id ? i.id === id : true))
        : state.sideBarStack[state.sideBarStack.length - 1];

      if (target) {
        if (soft) {
          target.active = false;
        } else {
          const removeTargetIndex = findLastIndex(
            state.sideBarStack,
            (i) => i.key === target.key && (id ? i.id === id : true),
          );
          state.sideBarStack = state.sideBarStack.filter((_, index) => index !== removeTargetIndex);
        }
      }
    },
    pushToSideBar: (state, action: PayloadAction<Omit<SideBarStackItem, 'active'>>) => {
      if (!action.payload.allowDuplicate) {
        const duplicate = state.sideBarStack.find((i) => i.key === action.payload.key);
        if (duplicate) {
          return;
        }
      }
      state.sideBarStack.push({ ...action.payload, active: true });
    },
    replaceSideBar: (state, action: PayloadAction<Omit<SideBarStackItem, 'active'>>) => {
      if (!action.payload.allowDuplicate) {
        const duplicate = state.sideBarStack.find((i) => i.key === action.payload.key);
        if (duplicate) {
          state.sideBarStack = state.sideBarStack.filter((i) => i.key !== action.payload.key);
        }
      }
      const target = state.sideBarStack[state.sideBarStack.length - 1];
      if (target) {
        target.active = false;
      }
      state.sideBarStack.push({ ...action.payload, active: true });
    },
    replaceAllSideBar: (state, action: PayloadAction<Omit<SideBarStackItem, 'active'>>) => {
      const target = state.sideBarStack[state.sideBarStack.length - 1];
      if (target) {
        state.sideBarStack = [target];
        target.active = false;
        state.sideBarStack.push({ ...action.payload, active: true });
      } else {
        state.sideBarStack = [{ ...action.payload, active: true }];
      }
    },

    closeSideBar: (state, action?: PayloadAction<{ instantly?: boolean } | undefined>) => {
      if (action?.payload?.instantly) {
        state.sideBarStack = [];
      } else {
        state.sideBarStack = [
          { ...state.sideBarStack[state.sideBarStack.length - 1], active: false },
        ];
      }
    },
    updateBlogpostDesktop: generateRequestDataReducer<AppState, BlogPost[]>({
      reducerPath: 'blogPosts.desktop',
    }),
    updateBlogpostMobile: generateRequestDataReducer<AppState, BlogPost[]>({
      reducerPath: 'blogPosts.mobile',
    }),
    updateOperationsCommissions: generateRequestDataReducer<AppState, OperationCommission[]>({
      reducerPath: 'operationsCommissions',
    }),
    updateAppLanguage: generateDefaultReducer({ reducerPath: 'language' }),

    updateMaintenance: generateDefaultReducer<AppState, Maintenance>({
      reducerPath: 'maintenance',
    }),
    // cli-reducer
    resetState: (state) => {
      state = {
        ...initialState,
        inited: true,
        firebaseConfig: state.firebaseConfig,
      };
      return state;
    },
  },
});

export default appSlice.reducer;
export const appActions = appSlice.actions;
