import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectDigitalAccountExistWallets,
  selectDigitalAccountWallets,
  selectExistingWallets,
  selectWallets,
} from 'modules/accounts/store/selectors';
import { Wallet } from 'modules/accounts/types';

const useWallets = (
  {
    isDigitalAccount,
    onlyExisted,
  }: {
    isDigitalAccount?: boolean;
    onlyExisted?: boolean;
  } = { onlyExisted: false, isDigitalAccount: false },
) => {
  const selector = useMemo(() => {
    if (isDigitalAccount) {
      if (onlyExisted) {
        return selectDigitalAccountExistWallets;
      }
      return selectDigitalAccountWallets;
    } else {
      if (onlyExisted) {
        return selectExistingWallets;
      }
      return selectWallets;
    }
  }, [isDigitalAccount, onlyExisted]);

  // @ts-ignore
  const wallets: Wallet[] = useSelector(selector);

  return { wallets };
};

export default useWallets;
