import reactNativeServices from 'modules/reactNative/services';
import { isReactNative, isReactNativeV2, sendToReactNative } from 'modules/reactNative/utils';

import appConfig from 'constants/appConfig';

import ApiError from 'libs/axios/ApiError';
import AxiosInstance from 'libs/axios/AxiosInstance';
import { getTranslation } from 'libs/i18n';

import { downloadFile } from 'utils/links';

export const downloadPdf = ({
  fileName,
  url,
  params,
  method = 'get',
  body,
}: {
  fileName: string;
  url: string;
  params?: object;
  method?: 'get' | 'post';
  body?: object;
}) => {
  const request = ({ returnHtml }: { returnHtml?: boolean }) => {
    const requestParams = returnHtml
      ? {
          responseType: undefined,
          params,
          headers: {
            responseType: 'html',
          },
        }
      : {
          responseType: 'blob',
          params,
        };

    return AxiosInstance[method]<Blob, Blob>(
      url,
      method === 'post' ? body : requestParams,
      // @ts-ignore
      method === 'post' ? requestParams : undefined,
    );
  };
  return isReactNative
    ? isReactNativeV2 // TODO: Remove this check after mobile v2 deploy
      ? reactNativeServices.downloadBlob({
          fileName,
          url:
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            appConfig.apiUrl + url,
        })
      : request({ returnHtml: true })
          .then((data) => {
            sendToReactNative('GENERATE_PDF', { html: data, fileName });
          })
          .catch(async (e: any) => {
            const errorText = await e.error.text();
            const error = JSON.parse(errorText);
            throw new ApiError(getTranslation(error.code) || error.message, error, 400);
          })
    : request({ returnHtml: false })
        .then((data) => {
          downloadFile(data, fileName);
        })
        .catch(async (e: any) => {
          const errorText = await e.error.text();
          const error = JSON.parse(errorText);
          throw new ApiError(getTranslation(error.code) || error.message, error, 400);
        });
};
