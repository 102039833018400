import { format, formatDistanceStrict } from 'date-fns';

import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import {
  selectDigitalAccountExistWallets,
  selectExistingWallets,
} from 'modules/accounts/store/selectors';
import { requestAccountStatement } from 'modules/accounts/store/thunks';
import useSideBar from 'modules/app/hooks/useSideBar';
import { useDispatch } from 'store';

import {
  DatePickerInputField,
  SimpleCurrencyPickerInputField,
  SubmitButton,
} from 'components/form';
import { InfoIcon } from 'components/ui';
import { DatePickerValue } from 'components/ui/DatePicker';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';
import yup, { makeValidate } from 'libs/yup';

import { CurrencyCode } from 'types';

export interface AccountStatementProps {
  isDigitalAccount: boolean;
  currencyCode?: CurrencyCode;
}
interface FormValues {
  dateRange: DatePickerValue;
  currencyCode: CurrencyCode | null;
}

interface AccountStatementFormProps extends FormRenderProps<FormValues> {
  currenciesList: CurrencyCode[];
}

const AccountStatementForm: FC<AccountStatementFormProps> = ({ currenciesList, handleSubmit }) => {
  const translate = useTranslation();

  return (
    <div className="column gap-2">
      <div className="row jcsb aic">
        <h4>{translate('ACCOUNT_STATEMENT')}</h4>
        <InfoIcon
          title={translate('ACCOUNT_STATEMENT')}
          description={translate('ACCOUNT_STATEMENT_DESC')}
          showInSidebar
        />
      </div>
      <form className="column gap-4" onSubmit={handleSubmit}>
        <div className="column gap-2">
          <Field
            name="dateRange"
            component={DatePickerInputField}
            variant="range"
            isFutureAllowed={false}
            inputProps={{
              label: translate('SELECT_DATE'),
            }}
          />

          <Field
            name="currencyCode"
            component={SimpleCurrencyPickerInputField}
            currenciesList={currenciesList}
            label={translate('SELECT_CURRENCY')}
          />
        </div>
        <SubmitButton fullWidth>{translate('ACCOUNT_STATEMENT_DOWNLOAD')}</SubmitButton>
      </form>
    </div>
  );
};

const AccountStatement: FC<AccountStatementProps> = ({ isDigitalAccount, currencyCode = null }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const sidebar = useSideBar();

  const digitalExistingWallets = useSelector(selectDigitalAccountExistWallets);
  const tradingExistingWallets = useSelector(selectExistingWallets);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const { success } = await dispatch(
        requestAccountStatement({
          fromDate: format(values.dateRange.startDate!, 'yyyy-MM-dd HH:mm:ss'),
          toDate:
            format(values.dateRange.endDate!, 'yyyy-MM-dd HH:mm:ss').substring(0, 10) + ' 23:59:59',
          isDigitalAccount,
          currencyCode: values.currencyCode!,
        }),
      );

      if (success) {
        successToast(translate('ACCOUNT_STATEMENT_DOWNLOADED'));
        sidebar.close();
      }
    },
    [isDigitalAccount, translate, sidebar, dispatch],
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      dateRange: { startDate: null, endDate: null },
      currencyCode,
    }),
    [currencyCode],
  );

  const currenciesList = useMemo(() => {
    if (isDigitalAccount) {
      return digitalExistingWallets.map((w) => w.currencyCode);
    }
    return tradingExistingWallets.map((w) => w.currencyCode);
  }, [isDigitalAccount, tradingExistingWallets, digitalExistingWallets]);

  const validate = useCallback(
    (values: FormValues) => {
      const schema = yup.object().shape({
        currencyCode: yup.string().requiredDefault(),
        dateRange: yup
          .object()
          .shape({
            startDate: yup.date().required(translate('VALIDATION_REQUIRED')),
            endDate: yup.date().required(translate('VALIDATION_REQUIRED')),
          })
          .test(
            'noLongerOneYear',
            translate('ERRORS_DATE_PERIOD_YEAR'),
            ({ startDate, endDate }) => {
              if (!startDate || !endDate) {
                return false;
              }
              try {
                return parseInt(formatDistanceStrict(startDate, endDate, { unit: 'day' })) <= 366;
              } catch (e) {
                return false;
              }
            },
          ),
      });

      return makeValidate(schema)(values);
    },
    [translate],
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      // @ts-ignore
      render={AccountStatementForm}
      currenciesList={currenciesList}
    />
  );
};

export default AccountStatement;
