import { FullLangCode, LangCode } from 'modules/user/constants/language';
import { Notification, Profile } from 'modules/user/types';
import { transformList } from 'store/generators/transformers';

import appConfig from 'constants/appConfig';

import { UserProfileDto } from 'libs/swagger/nebeusApiTypes';
import {
  NebeusNotificationDtoObject,
  PageDtoNebeusNotificationDtoObject,
} from 'libs/swagger/nebeusNotificationServiceApiTypes';

import { FiatCurrencyCode } from 'types';

const langCodeByCodeFromBE: { [key in FullLangCode]: LangCode } = {
  en_GB: 'ENG',
  es_PE: 'ESP',
};

const getPublicId = (id: string) => {
  const idSplit = id.split('-');
  const publicId = idSplit[idSplit.length - 1];

  return publicId;
};

export const transformProfileResponse = (response: UserProfileDto): Profile => ({
  id: response.userId,
  publicId: getPublicId(response.userId),
  firstName: response.firstName,
  lastName: response.lastName,
  defaultCurrency: response.defaultCurrencyCode as FiatCurrencyCode,
  language: langCodeByCodeFromBE[response.language as FullLangCode] || 'ENG',
  countryCode: response.countryCode,
  email: response.email,
  twoFactorsAuthEnabled: response.tfaEnabled,
  verified: response.verificationStatus === 'REVIEWED',
  verificationStatus: response.verificationStatus,
  otpByEmail: response.otpByEmail,
  avatarUrl: null, // [LP] Wait for BE implementing
  isBusinessAccount: response.businessAccount,
  hasPhoneNumber: response.hasPhoneNumber,
  signUpSource: response.campaignSource,
  blockedFeatures: response.blockedFeatures,
  newsToEmailAllowed: response.marketingMailingEnabled,
  isTestUser: appConfig.testUsersIds.includes(response.userId),
});

export const transformNotifications = (
  response: PageDtoNebeusNotificationDtoObject,
): Notification[] =>
  transformList<NebeusNotificationDtoObject, Notification>(response.content, [
    ['id'],
    ['createdAt', 'dateCreated'],
    ['read', 'isRead'],
    ['type'],
    ['relatedEntityType'],
    ['relatedEntity'],
  ]);
