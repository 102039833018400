import clsx from 'clsx';
import { FormApi } from 'final-form';
import { useSwiper, useSwiperSlide } from 'swiper/react';

import { FC, memo, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import authServices from 'modules/auth/services';
import { initAuthedUser, requestSignInTFA } from 'modules/auth/store/thunks';
import { StepHeader } from 'modules/auth/views/components/StepHeader';
import { isReactNativeSupportBioVerification } from 'modules/reactNative/utils';
import { appVerificationService } from 'modules/reactNative/utils/appVerification';
import { requestToggleSendOtpViaEmail } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { PinCodeField } from 'components/form';
import { Loader } from 'components/ui';

import useWindowFocus from 'hooks/useWindowFocus';

import { useTranslation } from 'libs/i18n';

import { clearClipboard, getStringFromClipboard, sleep } from 'utils/common';
import { parseFixEmptyString } from 'utils/inputParsers';

import classes from './LoginWithTFA.module.scss';

interface FormValues {
  code: string;
}

const initialValues: FormValues = {
  code: '',
};

interface LoginWithTFAFormProps extends FormRenderProps<FormValues> {
  email: string;
}
const LoginWithTFAForm: FC<LoginWithTFAFormProps> = ({
  handleSubmit,
  values,
  form: { change: changeForm },
  submitting,
}) => {
  const translate = useTranslation();

  const { isActive } = useSwiperSlide();
  const swiper = useSwiper();

  useEffect(() => {
    if (values.code.length === 6) {
      handleSubmit();
    }
    // eslint-disable-next-line
  }, [values.code]);

  const pasteCodeFromClipboard = useCallback(async () => {
    if (!isActive) {
      return;
    }
    const code = await getStringFromClipboard();
    if (code.length === 6 && !isNaN(Number(code))) {
      changeForm('code', code);
    }
  }, [isActive, changeForm]);

  useWindowFocus(pasteCodeFromClipboard);

  const handlePressIssues = useCallback(() => {
    swiper.slideNext();
  }, [swiper]);

  return (
    <form className={clsx(classes.form, 'authStepRoot')} onSubmit={handleSubmit}>
      <div className="column gap-3">
        {createPortal(
          <div className={clsx(classes.loader, submitting && classes.active)}>
            <Loader size="lg" />
          </div>,
          document.querySelector('#root')!,
        )}
        <StepHeader
          title={translate('SIGN_WITH_TFA_TITLE')}
          subtitle={translate('SIGN_WITH_TFA_SUBTITLE')}
        />
        <div className="column gap-2">
          <Field
            name="code"
            component={PinCodeField}
            onSubmit={handleSubmit}
            length={6}
            canIntercept={isActive}
            parse={parseFixEmptyString}
          />
          <div className="m-0-auto" onClick={handlePressIssues}>
            <span className={classes.secondaryButton}>
              {translate('SIGN_WITH_TFA_HAVING_ISSUES')}
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

interface LoginWithTFAProps {
  email: string;
  sendOtpByPhone: boolean;
}

export const LoginWithTFA = memo<LoginWithTFAProps>(({ email, sendOtpByPhone }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues, FormValues>) => {
      const { success, data } = await dispatch(
        requestSignInTFA({ verificationCode: values.code, login: email }),
      );
      if (!success) {
        await clearClipboard();
        form.reset();
        return;
      }
      if (data?.accessToken && data.refreshToken) {
        authServices.setTokens(data.accessToken, data.refreshToken);
        if (sendOtpByPhone) {
          await dispatch(requestToggleSendOtpViaEmail(false));
        }
        const successInited = await dispatch(initAuthedUser());
        if (successInited) {
          if (isReactNativeSupportBioVerification) {
            appVerificationService.setVerification();
            await sleep(500);
          }
          navigate(routesByName.dashboard, { state: { from: 'auth' }, replace: true });
        }
      }
    },
    [dispatch, sendOtpByPhone, navigate, email],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      // @ts-ignore
      render={LoginWithTFAForm}
      email={email}
    />
  );
});
