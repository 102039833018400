export const template = `
<html lang="en">
  <head>
    <style>
      body {
        margin: 0;
        padding: 24px 24px 0;
        color: #2a2b2a;
        font-family: SegoeUI, sans-serif;
        font-size: 16px;
        line-height: 24px;
      }
      p {
        margin: 0;
      }
      b {
        font-weight: 600;
      }
      h1,
      h2 {
        font-weight: 600;
      }

      h1 {
        font-size: 20px;
        line-height: 28px;
      }
      h2 {
        font-size: 16px;
        line-height: 24px;
        margin: 24px 0;
      }

      .table {
        margin: 24px 0;
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .link {
        text-decoration: underline;
      }
    </style>
    <title>
      Pre-Contractual loan information<br />(Standard European Consumer Loan Information)
    </title>
  </head>
  <body>
    <h1>Pre-Contractual loan information<br />(Standard European Consumer Loan Information)</h1>
    <h2>1.Identity and contact details of the lender</h2>
    <div class="table">
      <p><b>Lender:</b> Rintral Trading, S.L.</p>
      <p><b>Address:</b> Carrer de la Ciutat de Granada, 150, 08018 Barcelona, Spain</p>
      <p><b>N.I.F:</b> B-66096686</p>
      <p><b>E-mail address*:</b> info@nebeus.com</p>
      <p><b>Web address*:</b> www.nebeus.com</p>
    </div>

    <h2>2.Description of the main features of the loan product</h2>

    <div class="table">
      <p>
        <b>The type of loan:</b> Personal consumer loan granted for whatever consumer´s purposes and
        secured by means of a cryptocurrency used as collateral.
      </p>
      <p>
        <b>The total amount of loan:</b>(This means the amount, or the sum of all amounts made
        available to the consumer under the loan agreement) {loanAmountCurrency}
      </p>
      <p>
        <b>Conditions governing the disposition of funds:</b>(How and when you will obtain the
        money) Upon receiving the consumer's cryptocurrency that will be used as collateral to
        secure the loan, and once the loan agreement has been signed, the lender should immediately
        transfer the total amount of the loan into the consumer's Nebeus account in the relevant
        currency.
      </p>

      <p><b>The duration of the loan agreement:</b> {term} month(s)</p>
      <h2>Instalments and, where appropriate, the order in which instalments will be allocated:</h2>
      <p><b>You must pay the following:</b> {monthlyPayment} per month for {term} month(s)</p>
      <p>
        <b>Interest and expenses to be paid by the consumer as follows:</b> {interestAmountCurrency}
      </p>
      <p>
        <b
          >The total amount you will have to pay:<br />
          (amount of the borrowed capital plus interests and any expenses related to your loan)</b
        >
        {totalAmountCurrency}
      </p>

      <h2>If applicable,</h2>
      <p>
        <b>
          Collateral required: (This is a description of the security to be provided by you in
          relation to the loan agreement)</b
        >
        Cryptocurrency collateral deposit. The consumer will, at the point of signing the contract,
        grant a security deposit consisting of a certain amount of cryptocurrency collateral
        (Ethereum, Bitcoin or any other cryptocurrency that is accepted on Nebeus), that Rintral
        Trading SL will automatically take from the consumer's Nebeus, relevant currency account.
        The cryptocurrency collateral will be kept by Rintral Trading SL in order to guarantee the
        fulfillment of the consumer's payments obligations under the loan agreement.
      </p>
    </div>

    <h2>3. Costs of the loan</h2>

    <div class="table">
      <p>
        <b
          >The borrowing rate or, if applicable, the different borrowing rates that apply to the
          loan agreement:</b
        >
        {ltvPercent}%
      </p>
      <p>
        <b
          >Annual Percentage Rate (APR):(This is the total cost expressed as an annual percentage of
          the total amount of loan. The APR is there to help you compare different offers)</b
        >
        {annualPercent}%
      </p>
      <p>
        <b
          >Annual Percentage Rate (APR):(This is the total cost expressed as an annual percentage of
          the total amount of loan. The APR is there to help you compare different offers)</b
        >
        {annualPercent}%
      </p>
      <p>
        <b
          >In order to obtain the loan or to obtain it on the terms and conditions offered, the
          consumer must:</b
        >
        In order to obtain a loan from the lender, the consumer should have an open account with
        Nebeus, with a specific amount of cryptocurrency. The opening and use of a Nebeus account
        may be subject to additional fees, depending on the services that the consumer decides to
        use. (More information on the specific services and fees charged by Rintral Trading SL, can
        be found through the following link:
        <a
          rel="noopener noreferrer"
          class="link"
          target="_blank"
          href="https://support.nebeus.com/portal/en/kb/fees-and-limits"
          >Nebeus</a
        >
      </p>
      <br />
      <p>
        The lender, Rintral Trading SL, will charge a cost for Contemporaneous with the funding of
        the Loan.<br /><br />
        The Borrower shall pay to the Lender (Rintral Trading SL) an agreed-upon origination fee of
        2% of the Loan Principal Amount. The Lender (Rintral Trading SL) is authorized to deduct the
        fee from the Loan Principal Amount.
      </p>

      <h2>Related costs</h2>
      <h2>If applicable,</h2>

      <p>
        <b>Amount of costs for using a specific means of payment, and other costs:</b>
        The lender, Rintral Trading SL, may charge a cost for a specific means of transfer or
        payment, and the cost will depend on the payment that the consumer chooses to use. More
        information on the specific services and fees charged by Rintral Trading SL, can be found
        through the following link:
        <a
          rel="noopener noreferrer"
          class="link"
          target="_blank"
          href="https://support.nebeus.com/portal/en/kb/fees-and-limits"
          >Nebeus</a
        >
      </p>

      <p>
        <b>Costs in the case of late payments:</b>
        You will be charged 1 % for late payments. Missing payments will entail several
        consequences: late penalty fees will be charged, and in severe cases, Rintral Trading SL
        will be obliged to liquidate the consumer's cryptocurrency collateral to cover the costs of
        the loan.
      </p>
    </div>

    <h2>4. Other important legal aspects</h2>

    <div class="table">
      <p>
        <b>Right of withdrawal:</b>
        A consumer has the right to withdraw from the loan agreement at any point after obtaining
        the loan. However, a 2.5% early repayment fee will be charged by Rintral Trading SL. In case
        of exercise of the right of withdrawal, the consumer shall be obliged to return the total
        amount of the loaned funds, as well as the interest accrued between the date of disposal of
        the loan and the date of repayment of the funds.<br /><br />
        This right of withdrawal shall not be applicable to those loan agreements with a value of
        more than €75,000.
      </p>

      <p>
        <b>Early repayment:</b>
        If the consumer withdraws from the loan agreement, a 2.5% early repayment fee will be
        charged by Rintral Trading SL. The consumer has the right to repay the loan early at any
        time in full or in part.
      </p>
      <p>
        <b>Right to a copy of the loan agreement:</b>
        The consumer has the right to obtain a copy of the loan agreement free of charge. Upon
        issuing the loan, Rintral Trading SL will send a copy of the loan agreement to the
        consumer's Nebeus account email.
      </p>

      <h2>If applicable,</h2>

      <p>
        <b> The period during which the lender is bound by the pre-contractual information</b> These
        pre-contractual information terms will be valid from 25 Jun 2023 to 25 Jul 2023.
      </p>
    </div>

    <h2>5. Additional information in the case of distance marketing of financial services</h2>

    <div class="table">
      <h2>If applicable,</h2>
      <p>
        <b
          >The law taken by the lender as a basis for the establishment of relations with you before
          the conclusion of the loan agreement:</b
        >
        Spanish legislation, in particular the provisions of Law 16/2011 of 24 June on consumer loan
        agreements.
      </p>

      <h2>If applicable,</h2>

      <p>
        <b
          >Clause stipulating the governing law applicable to the loan agreement and/or the
          competent court:</b
        >
        Clause 33: Spanish Law shall apply to the loan agreement and the competent jurisdiction
        shall be the Courts corresponding to the domicile of the consumer.
      </p>
      <p>
        <b>Language regime:</b> Information and contractual terms, as well as communications among
        the parties, will be supplied in Spanish, unless the consumer expressly consents in writing
        to the use of English language.
      </p>

      <h2>(c) Concerning redress</h2>

      <p>
        <b>Existence of and access to out-of-court complaint and redress mechanism:</b> The lender
        has established a Customer Service in accordance with the provisions of the regulations in
        force. The claims related to legally recognized interests and rights may be addressed to
        this Customer Service through the following link:
        <a
          rel="noopener noreferrer"
          class="link"
          target="_blank"
          href="https://verify.nebeus.com/nebeus/form/HelpDesk/formperma/BBblV3UK0pmLHc2Qxz116dfqHNHCZQ7rt0u--HN_kU8"
          >Nebeus</a
        ><br /><br />
        In case of disagreement with the resolution of the Customer Service, the claimant will be
        able to address any of the consumer protection entities (either public or private) existing
        in Spain, or directly proceed with the recourse to justice.
      </p>
    </div>
  </body>
</html>
`;
