import { FC } from 'react';
import { useForm } from 'react-final-form';

import { Button } from 'components/ui';
import { ButtonProps } from 'components/ui/Button';

import { getTranslation } from 'libs/i18n';

interface SubmitButtonProps extends ButtonProps {
  behaviour?: 'default' | 'validateOnClick';
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  loading,
  children: childrenFromProps,
  behaviour = 'default',
  ...props
}) => {
  const { getState } = useForm();

  const children = childrenFromProps || getTranslation('CONFIRM');

  const { submitting, hasSubmitErrors, dirtySinceLastSubmit, hasValidationErrors } = getState();

  return (
    <Button
      type="submit"
      loading={submitting || loading}
      disabled={
        behaviour === 'validateOnClick'
          ? submitting
          : hasValidationErrors || (hasSubmitErrors && !dirtySinceLastSubmit) || submitting
      }
      {...props}
    >
      {children}
    </Button>
  );
};
