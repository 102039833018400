import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { selectBankCards } from 'modules/cryptoBankCard/store/selectors';
import { CryptoBankCardFeesAndConditionsCard } from 'modules/cryptoBankCard/views/components/CryptoBankCardFeesAndConditionsCard';

import { Button, Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './CryptoBankCardIntroducing.module.scss';

interface CryptoBankCardIntroducingProps {}
const CryptoBankCardIntroducing: FC<CryptoBankCardIntroducingProps> = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const bankCards = useSelector(selectBankCards);

  const userHasPhysAndVirtBankCard =
    !!bankCards.find((bc) => bc.isVirtual) && !!bankCards.find((bc) => !bc.isVirtual);

  const handleOrderCard = useCallback(() => {
    if (userHasPhysAndVirtBankCard) {
      drawer.pop();
    } else {
      drawer.open(commonDrawerTemplates.bankCards({ type: 'crypto', getNew: true }));
    }
  }, [userHasPhysAndVirtBankCard, drawer]);

  return (
    <div className={classes.root}>
      <div className="column gap-3">
        <p>{translate('CRYPTO_CARD_INTRO_SUBTITLE')}</p>
        <div className={classes.imageContainer}>
          <Image name="cryptoBankCard3D" path="cryptoBankCard" />
        </div>
        <p>{translate('CRYPTO_CARD_INTRO_DESC')}</p>

        <div className="column gap-1">
          <span className="font-title">
            <b>{translate('CRYPTO_CARD_INTRO_NEW_CONDITIONS_AND_FEES')}</b>
          </span>
          <p>{translate('CRYPTO_CARD_FEE_SYSTEM_DESC')}</p>
        </div>
        <div className="outlinedCard">
          <CryptoBankCardFeesAndConditionsCard />
        </div>
      </div>
      <Button onClick={handleOrderCard}>
        {translate(userHasPhysAndVirtBankCard ? 'GOT_IT_THANKS' : 'ORDER_CARD')}
      </Button>
    </div>
  );
};

export default CryptoBankCardIntroducing;
