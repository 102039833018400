import clsx from 'clsx';

import { memo, useMemo } from 'react';
import { useFormState } from 'react-final-form';

import { LoanTemplate } from 'modules/loans/types';
import { GetLoanFormValues } from 'modules/loans/views/LoanCalculator/index';

import { TranslationKey, useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import classes from './CheckList.module.scss';
import useLoanCheckList from './useLoanCheckList';

export interface CheckListProps {
  template: LoanTemplate;
  variant: 'short' | 'full';
}
export const CheckList = memo<CheckListProps>(({ template, variant }) => {
  const translate = useTranslation();

  const { values } = useFormState<GetLoanFormValues>();

  const {
    totalLoanCost,
    totalLoanRepayment,
    monthlyRepayment,
    loanExchangeRate,
    clearInterestPercent,
  } = useLoanCheckList(values, template);

  const list = useMemo<{ label?: TranslationKey; value?: string; isDelimiter?: boolean }[]>(() => {
    const result: { label?: TranslationKey; value?: string; isDelimiter?: boolean }[] = [
      { label: 'LOANS_INTEREST_RATE', value: clearInterestPercent.toString() + '%' },
      { label: 'LOANS_INTEREST_LTV_FULL', value: values.ltv.toString() + '%' },
      {
        label: 'LOANS_LOAN_TERM',
        value: translate('DATE_MONTH_PLURAL', { count: values.termMonth }),
      },
      {
        label: 'LOANS_COLLATERAL_AMOUNT_TYPE',
        value: formatCurrencyWithSymbol(
          template.type === 'MIRROR' ? +values.collateral.amount * 2 : values.collateral.amount,
          values.collateral.currency,
          {
            withZeros: true,
          },
        ),
      },
      {
        label: 'LOANS_LOAN_EXCHANGE_RATE',
        value: formatCurrencyWithSymbol(loanExchangeRate, values.loan.currency, {
          withZeros: true,
        }),
      },
      {
        label: 'LOANS_LOAN_ORIGINATION_FEE',
        value: template.originationFeePercent
          ? template.originationFeePercent.toString() + '%'
          : translate('FREE'),
      },
      {
        label: 'LOANS_EARLY_REPAYMENT_OPTION',
        value: template.earlyRepaymentPenaltyPercent
          ? template.earlyRepaymentPenaltyPercent.toString() + '%'
          : translate('NOT_ALLOWED'),
      },

      {
        label: 'LOANS_LOAN_AMOUNT',
        value: formatCurrencyWithSymbol(values.loan.amount, values.loan.currency),
      },
      {
        label: 'LOANS_TOTAL_COST',
        value: formatCurrencyWithSymbol(totalLoanCost, values.loan.currency),
      },
      {
        label:
          template.type === 'ONLY_INTEREST' || template.type === 'MIRROR'
            ? 'LOANS_MONTHLY_PAYMENT_ONLY_INTEREST'
            : template.type === 'BULLET'
            ? 'ONE_TIME_PAYMENT'
            : 'MONTHLY_PAYMENT',
        value: formatCurrencyWithSymbol(monthlyRepayment, values.loan.currency),
      },
      {
        label: 'LOANS_TOTAL_REPAYMENT',
        value: formatCurrencyWithSymbol(totalLoanRepayment, values.loan.currency),
      },
    ];

    if (template.type === 'MIRROR') {
      result.unshift({
        label: 'LOANS_COLLATERAL_MIRROR_YOU_GET',
        value: formatCurrencyWithSymbol(+values.collateral.amount * 2, values.collateral.currency, {
          withZeros: true,
        }),
      });
      result.unshift({
        label: 'LOANS_COLLATERAL_MIRROR_YOU_SEND',
        value: formatCurrencyWithSymbol(values.collateral.amount, values.collateral.currency, {
          withZeros: true,
        }),
      });
    }

    const currentLtv = template.ltvRange[values.ltv];

    if (values.excessCollateralRelease && currentLtv) {
      const excessPercent = values.autoManagementMC
        ? (currentLtv.percentWithAutoMCAndExcessCollateral - currentLtv.percent) / 2
        : currentLtv.percentWithExcessCollateral - currentLtv.percent;
      result.splice(6, 0, {
        label: 'LOANS_EXCESS_RELEASE',
        value: excessPercent.toString() + '%',
      });
    }
    if (values.autoManagementMC && currentLtv) {
      const autoMCPercent = values.excessCollateralRelease
        ? (currentLtv.percentWithAutoMCAndExcessCollateral - currentLtv.percent) / 2
        : currentLtv.percentWithAutoMC - currentLtv.percent;
      result.splice(6, 0, {
        label: 'LOANS_AUTO_MC',
        value: autoMCPercent.toString() + '%',
      });
    }

    return result;
  }, [
    totalLoanRepayment,
    clearInterestPercent,
    monthlyRepayment,
    loanExchangeRate,
    totalLoanCost,
    translate,
    values,
    template,
  ]);

  const shortList = [list[10], list[9], list[0]];

  const currentList = variant === 'short' ? shortList : list;
  return (
    <div className={clsx(classes.root, classes[`variant-${variant}`])}>
      <span className={classes.title}>{translate('TOTAL')}</span>
      <div className={classes.list}>
        {currentList.map((item) => (
          <div key={item.label} className={classes.listItem}>
            <span>{translate(item.label!)}</span>
            <span>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
});
