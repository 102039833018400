// import invoicingServicesMocked from 'modules/invoicing/services/mocked';
import { Invoice, InvoiceSavedClient } from 'modules/invoicing/types';
import { ListFilterReqPayload } from 'store/generators/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  DeleteBatchInvoicesRequest,
  InvoiceClientDto,
  InvoicePageResponse,
  InvoiceRequest,
} from 'libs/swagger/nebeusApiTypes';

import { downloadPdf } from 'utils/pdf';

// cli-import

export interface InvoicesResponse extends Omit<InvoicePageResponse, 'data'> {
  data: Invoice[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const invoicingServices = {
  getInvoices: ({ filter }: { filter: ListFilterReqPayload }): Promise<Invoice[]> =>
    AxiosInstance.get<InvoicesResponse, InvoicesResponse>('/v1/invoice', {
      params: { pageNumber: filter.pageNumber, pageSize: filter.pageLimit },
    }).then((res) => res.data),

  removeInvoice: (id: Invoice['id']) => AxiosInstance.delete(`/v1/invoice/${id}`),

  removeMultipleInvoices: (ids: Invoice['id'][]) =>
    AxiosInstance.delete('/v1/invoice/delete-batched', {
      data: { invoicesIds: ids } as DeleteBatchInvoicesRequest,
    }),

  createInvoice: (payload: InvoiceRequest): Promise<Invoice> =>
    AxiosInstance.post('/v1/invoice/create', payload),

  getInvoiceById: (id: Invoice['id']) => AxiosInstance.get(`/v1/invoice/${id}`),

  getSavedClients: (): Promise<InvoiceSavedClient[]> => AxiosInstance.get('/v1/invoice/client'),

  saveClient: (payload: InvoiceClientDto): Promise<InvoiceSavedClient> =>
    AxiosInstance.post('/v1/invoice/client', payload),

  editSavedClient: ({
    client,
    id,
  }: {
    client: InvoiceClientDto;
    id: InvoiceSavedClient['id'];
  }): Promise<InvoiceSavedClient> => AxiosInstance.put(`/v1/invoice/client/${id}`, client),

  removeSavedClient: (clientId: InvoiceSavedClient['id']): Promise<InvoiceSavedClient> =>
    AxiosInstance.delete(`/v1/invoice/client/${clientId}`),
  downloadInvoicePdf: (invoice: Invoice) =>
    downloadPdf({
      fileName: `Invoice_# ${invoice.invoiceDetails.number}`,
      url: `/v1/documents/invoicing/download-invoice-pdf`,
      method: 'post',
      body: { id: invoice.id },
    }),

  // cli-service
};

export default invoicingServices;
// export default invoicingServicesMocked;
