import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectSideBarStack } from 'modules/app/store/selectors';
import { SideBarStackItemKey } from 'modules/app/types';

const useSideBarIsOpen = () => {
  const sideBarStack = useSelector(selectSideBarStack);

  return useCallback(
    (key: SideBarStackItemKey) => sideBarStack.find((i) => i.key === key)?.active || false,
    [sideBarStack],
  );
};

export default useSideBarIsOpen;
