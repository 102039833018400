import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { logout } from 'modules/auth/store/externalThunks';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import phonebookDrawerTemplates from 'modules/phonebook/constants/drawerTemplates';
import { selectReactNativeNotificationToken } from 'modules/reactNative/store/selectors';
import { isReactNative } from 'modules/reactNative/utils';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import { allowedLanguages, defaultLanguage } from 'modules/user/constants/language';
import { userActions } from 'modules/user/store';
import {
  selectUserDefaultCurrencyCode,
  selectUserProfileReducer,
} from 'modules/user/store/selectors';
import {
  requestProfile,
  requestToggleSendNewsToEmail,
  requestUpdateDefaultCurrency,
} from 'modules/user/store/thunks';
import classes from 'modules/user/views/Settings/Settings.module.scss';
import { useDispatch } from 'store';

import { LanguagePicker, UserAvatar } from 'components/common';
import {
  CopyButton,
  CurrencyIcon,
  Icon,
  Image,
  Mark,
  MenuList,
  SimpleCurrencyPicker,
  Switch,
} from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import useFlag from 'hooks/useFlag';
import useStoreEntity from 'hooks/useStoreEntity';

import Firebase from 'libs/firebase';
import { useTranslation } from 'libs/i18n';

import { fiatCurrencies } from 'utils/currency';
import {
  LINK_PRIVACY_POLICY,
  LINK_REQUEST_BUSINESS_ACCOUNT,
  LINK_TERMS_OF_USE,
  openExternalLink,
} from 'utils/links';
import { openChatSupport, openHelpCenter } from 'utils/thirdPartyLibs';

import { CurrencyCode, FiatCurrencyCode } from 'types';

const Settings = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const defaultCurrency = useSelector(selectUserDefaultCurrencyCode);

  const drawer = useDrawer();

  const {
    entityReducer: { data: userProfile },
  } = useStoreEntity(selectUserProfileReducer, requestProfile);

  const referralAdMenu = useMemo<MenuListItem[]>(
    () => [
      {
        icon: 'present',
        label: translate('REFERRAL_LINK_MARKETING_AD_TITLE'),
        handler: () => {
          drawer.open(commonDrawerTemplates.referralLinksMarketingAd());
        },
        endAdornment: <Mark variant="greenOutlined">{translate('NEW')}</Mark>,
      },
    ],
    [drawer, translate],
  );

  const mainMenu = useMemo<MenuListItem[]>(() => {
    const result = [
      {
        icon: 'user',
        label: translate('PROFILE'),
        handler: () => {
          drawer.open(userDrawerTemplates.profile());
        },
      },
      {
        icon: 'book',
        label: translate('PHONEBOOK'),
        handler: () => {
          drawer.open(phonebookDrawerTemplates.counterparties());
        },
      },
      {
        icon: 'starArrow',
        label: translate('VERIFICATION'),
        handler: () => {
          drawer.open(userDrawerTemplates.verification());
        },
        endAdornment: (
          <Mark variant={userProfile?.verified ? 'greenOutlined' : 'grayOutlined'}>
            {translate(
              userProfile?.verified ? 'VERIFICATION_VERIFIED' : 'VERIFICATION_NOT_VERIFIED',
            )}
          </Mark>
        ),
      },
      {
        icon: 'bankCard',
        label: translate('PAYMENT_METHODS'),
        handler: () => {
          drawer.open(paymentDrawerTemplates.paymentMethods());
        },
      },
      {
        icon: 'lock',
        label: translate('SECURITY'),
        handler: () => {
          drawer.open(userDrawerTemplates.security());
        },
      },
      {
        icon: 'casePlus',
        label: translate('REQUEST_BUSINESS_ACCOUNT'),
        handler: () => {
          openExternalLink(LINK_REQUEST_BUSINESS_ACCOUNT);
        },
      },
    ];

    // if (digitalAccountsAllowed) {
    //   result.splice(4, 0, {
    //     icon: 'coins',
    //     label: translate('SUBSCRIPTIONS'),
    //     handler: () => {
    //       drawer.open(ibanAccountDrawerTemplates.subscriptions());
    //     },
    //   });
    // }

    return result;
  }, [drawer, userProfile, translate]);

  const emailNewsAllowedLoading = useFlag(false);
  const handleChangeNewsToEmail = useCallback(
    async (newValue: boolean) => {
      if (!userProfile) {
        return;
      }
      emailNewsAllowedLoading.on();
      dispatch(
        userActions.updateProfile({ data: { ...userProfile, newsToEmailAllowed: newValue } }),
      );

      await dispatch(requestToggleSendNewsToEmail(newValue));
      emailNewsAllowedLoading.off();
    },
    [emailNewsAllowedLoading, userProfile, dispatch],
  );
  const helpMenu = useMemo<MenuListItem[]>(
    () => [
      {
        icon: 'chat',
        label: translate('PROFILE_CHAT_SUPPORT'),
        handler: openChatSupport,
        endAdornment: <Mark>{translate('BETA')}</Mark>,
      },
      {
        icon: 'question',
        label: translate('HELP_CENTER'),
        handler: openHelpCenter,
      },
      {
        icon: 'document3',
        label: translate('TERMS_OF_USE'),
        handler: () => {
          openExternalLink(LINK_TERMS_OF_USE);
        },
        endAdornment: <Icon name="link" />,
      },
      {
        icon: 'privacy',
        label: translate('PROFILE_PRIVACY_POLICY'),
        handler: () => {
          openExternalLink(LINK_PRIVACY_POLICY);
        },
        endAdornment: <Icon name="link" />,
      },
      {
        icon: 'email',
        label: translate('PROFILE_EMAIL_NEWS_NOTIFICATION'),
        endAdornment: userProfile ? (
          <Switch
            onChange={handleChangeNewsToEmail}
            value={userProfile.newsToEmailAllowed}
            loading={emailNewsAllowedLoading.state}
          />
        ) : undefined,
      },
    ],
    [translate, emailNewsAllowedLoading.state, handleChangeNewsToEmail, userProfile],
  );

  const logoutMenu = useMemo<MenuListItem[]>(
    () => [
      {
        icon: 'logout',
        label: translate('PROFILE_LOGOUT'),
        handler: async () => {
          await dispatch(logout());
        },
        asyncHandler: true,
      },
    ],
    [dispatch, translate],
  );

  const defaultCurrencyMenuRef = useRef<HTMLDivElement>(null);
  const defaultCurrencyPicker = useFlag(false);

  const defaultCurrencyLoading = useFlag(false);
  const handleChangeDefaultCurrencyCode = useCallback(
    async (code: CurrencyCode) => {
      defaultCurrencyLoading.on();
      await dispatch(requestUpdateDefaultCurrency(code as FiatCurrencyCode));
      defaultCurrencyLoading.off();
    },
    [dispatch, defaultCurrencyLoading],
  );

  const defaultCurrencyMenu = useMemo<MenuListItem[]>(
    () => [
      {
        forwardRef: defaultCurrencyMenuRef,
        startAdornment: <CurrencyIcon size={24} code={defaultCurrency} />,
        label: translate('DEFAULT_CURRENCY'),
        handler: defaultCurrencyPicker.toggle,
        endAdornment: <Mark>{defaultCurrency}</Mark>,
        loading: defaultCurrencyLoading.state,
      },
    ],
    [defaultCurrencyPicker, defaultCurrencyLoading, defaultCurrency, translate],
  );

  // language
  const userLanguage = useMemo(
    () => allowedLanguages.find((l) => l.code === userProfile?.language) || defaultLanguage,
    [userProfile],
  );
  const langMenuRef = useRef<HTMLDivElement>(null);
  const langSelect = useFlag(false);

  const languageMenu = useMemo<MenuListItem[]>(
    () => [
      {
        forwardRef: langMenuRef,
        startAdornment: <Image className={classes.langImg} name={userLanguage.shortCode} />,
        label: translate('LANGUAGE'),
        handler: langSelect.toggle,
        endAdornment: <Mark>{userProfile?.language || ''}</Mark>,
      },
    ],
    [userLanguage, langSelect, translate, userProfile],
  );

  const defaultValuesMenu = useMemo(
    () => [...defaultCurrencyMenu, ...languageMenu],
    [defaultCurrencyMenu, languageMenu],
  );

  const closeAccountMenu = useMemo<MenuListItem[]>(
    () => [
      {
        icon: 'closeAccount',
        label: translate('PROFILE_CLOSE_ACCOUNT'),
        handler: () => {
          drawer.open(userDrawerTemplates.closeAccount());
        },
      },
    ],
    [drawer, translate],
  );

  const fcmRNToken = useSelector(selectReactNativeNotificationToken);

  const testUserMenu = useMemo<MenuListItem[]>(() => {
    return [
      {
        icon: 'settings',
        label: 'Notification token',
        endAdornment: (
          <CopyButton value={(isReactNative ? fcmRNToken : Firebase.token) || 'NO TOKEN'} />
        ),
      },
    ];
  }, [fcmRNToken]);

  return userProfile ? (
    <div className={classes.root}>
      <div className="asc column aic gap-2">
        <UserAvatar size={100} />
        <div className={classes.userDetails}>
          <h3>
            {userProfile.firstName} {userProfile.lastName}
          </h3>
          <span className={classes.email}>{userProfile.email}</span>
        </div>
      </div>

      <div className="column gap-3 mt-6">
        <MenuList menu={referralAdMenu} />
        <MenuList menu={mainMenu} />
        <MenuList menu={defaultValuesMenu} />
        <MenuList menu={helpMenu} />
        <MenuList menu={logoutMenu} />
        {userProfile.isTestUser && <MenuList menu={testUserMenu} />}

        {isReactNative && <MenuList menu={closeAccountMenu} />}
      </div>

      <SimpleCurrencyPicker
        className={classes.select}
        anchorEl={defaultCurrencyMenuRef.current}
        isOpen={defaultCurrencyPicker.state}
        onClose={defaultCurrencyPicker.off}
        onPick={handleChangeDefaultCurrencyCode}
        currenciesList={fiatCurrencies}
        selectedCurrency={defaultCurrency}
        position="top"
      />
      <LanguagePicker
        anchorEl={langMenuRef.current}
        isOpen={langSelect.state}
        onClose={langSelect.off}
      />
    </div>
  ) : null;
};

export default Settings;
