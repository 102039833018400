import clsx from 'clsx';
import { formatDistanceStrict, isFuture, isValid, parse } from 'date-fns';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import useDrawer from 'modules/app/hooks/useDrawer';
import { selectAllowedCountriesReducer as selectAppAllowedCountriesReducer } from 'modules/app/store/selectors';
import { requestAllowedCountries as requestAppAllowedCountries } from 'modules/app/store/thunks';
import { AllowedCountry } from 'modules/app/types';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import { selectAllowedCountriesReducer as selectCryptoCardAllowedCountriesReducer } from 'modules/cryptoBankCard/store/selectors';
import { requestAllowedCountries as requestCryptoCardAllowedCountries } from 'modules/cryptoBankCard/store/thunks';
import { UserExtendedProfile } from 'modules/user/services';
import { requestUserExtendedProfile } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import { AddressForm, SubmitButton, TextInputField } from 'components/form';
import { AutoComplete, Icon, Loader } from 'components/ui';
import { Address } from 'components/ui/AddressAutoComplete';
import { AutoCompleteProps } from 'components/ui/AutoComplete';

import useEntity from 'hooks/useEntity';
import useFlag from 'hooks/useFlag';
import useStoreEntity from 'hooks/useStoreEntity';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import { findByProperty } from 'utils/arrayUtils';
import { parsePhoneNumber } from 'utils/inputParsers';
import { isPhoneNumberValid } from 'utils/validation';

import classes from './OrderBankCardShippingDetails.module.scss';

export interface ShippingDetailsFormValues {
  firstName: string;
  lastName: string;
  birthday: string;

  phoneNumber: string;
  addressVariant: 'default' | 'custom';
  shippingAddress: Omit<Address, 'country'> & { country: AllowedCountry | null };
}
interface OrderBankCardShippingDetailsFormProps extends FormRenderProps<ShippingDetailsFormValues> {
  extendedProfile: UserExtendedProfile | null;
  extendedProfileCountry?: AllowedCountry;
  isVirtual?: boolean;
  extendedProfileLoading: boolean;
  allowedCountries: AllowedCountry[];
}

type AddressSelectOption = 'default' | 'custom';

const addressSelectOptions: AddressSelectOption[] = ['default', 'custom'];

export const OrderBankCardShippingDetailsForm: FC<OrderBankCardShippingDetailsFormProps> = ({
  handleSubmit,
  form,
  extendedProfile,
  values,
  isVirtual,
  allowedCountries,
  extendedProfileCountry,
  extendedProfileLoading,
}) => {
  const translate = useTranslation();

  const parseDateInput = useCallback(
    (currentValue: string, fieldName: string) => {
      // @ts-ignore
      const prevValue = values[fieldName];

      const goForward = prevValue.length < currentValue.length;

      const lastChar = currentValue[currentValue.length - 1];

      if (isNaN(+lastChar) && goForward) {
        return currentValue.slice(0, -1);
      }
      if (currentValue.length > 10) {
        return currentValue.substring(0, 10);
      }

      if (goForward) {
        if (currentValue.length === 2 || currentValue.length === 5) {
          return currentValue + '.';
        }
      } else {
        if (prevValue[prevValue.length - 1] === '.') {
          return currentValue.slice(0, -1);
        }
      }

      return currentValue;
    },
    [values],
  );

  const userDefaultAddress = useMemo(() => {
    if (extendedProfile && extendedProfileCountry) {
      return [
        extendedProfile.address,
        extendedProfile.city,
        extendedProfile.postCode,
        extendedProfileCountry.name,
      ].join(', ');
    }
  }, [extendedProfile, extendedProfileCountry]);
  const addressSelectProps = useMemo<AutoCompleteProps<AddressSelectOption>['selectProps']>(() => {
    return {
      renderItem: (value: AddressSelectOption) => (
        <div
          className={clsx(classes.addressOption, values.addressVariant === value && classes.active)}
        >
          {value === 'custom' ? (
            <>
              <Icon name="plusBold" />
              <span>{translate('ADD_NEW_ADDRESS')}</span>
            </>
          ) : (
            <span>{userDefaultAddress}</span>
          )}
        </div>
      ),
    };
  }, [userDefaultAddress, values.addressVariant, translate]);

  const isManualAddressMode = useFlag(false);

  const onAddressSelectChange = useCallback(
    (value: AddressSelectOption | null) => {
      if (value === 'custom') {
        form.batch(() => {
          form.change('addressVariant', 'custom');
          form.change('shippingAddress', {
            address1: '',
            address2: '',
            postCode: '',
            city: '',
            country: null,
          });
        });
      }
      if (value === 'default' && extendedProfile) {
        isManualAddressMode.off();
        form.change('addressVariant', 'default');
        form.change('shippingAddress', {
          address1: extendedProfile.address,
          address2: '',
          postCode: extendedProfile.postCode,
          city: extendedProfile.city,
          country: extendedProfileCountry ?? null,
        });
      }
    },
    [isManualAddressMode, form, extendedProfile, extendedProfileCountry],
  );

  const addressSelectLabelAccessor = useCallback(
    (value: AddressSelectOption | null): string =>
      value === 'default' ? userDefaultAddress || '' : translate('NEW_ADDRESS'),
    [userDefaultAddress, translate],
  );

  const [addressInputRef, setAddressInputRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (addressInputRef) {
      const height = values.addressVariant === 'custom' ? 48 : addressInputRef.scrollHeight;
      addressInputRef.style.height = `${height}px`;
    }
  }, [addressInputRef, values.addressVariant]);

  return (
    <form className="mt-3 column gap-6" onSubmit={handleSubmit}>
      <div className="column gap-2">
        <Field
          name="firstName"
          label={translate('FIRST_NAME')}
          component={TextInputField}
          autoComplete="given-name"
          disabled
        />
        <Field
          name="lastName"
          label={translate('LAST_NAME')}
          component={TextInputField}
          autoComplete="family-name"
          disabled
        />
        <Field
          name="birthday"
          label={translate('DATE_OF_BIRTH')}
          placeholder={translate('PLACEHOLDER_DATE_FORMAT_1')}
          component={TextInputField}
          autoComplete="bday"
          parse={parseDateInput}
        />
        <Field
          name="phoneNumber"
          label={translate('PHONE_NUMBER')}
          component={TextInputField}
          autoComplete="tel"
          type="tel"
          parse={parsePhoneNumber}
          disabled={extendedProfile?.phoneNumber}
        />
        {isVirtual || extendedProfileLoading ? null : (
          <>
            <AutoComplete<AddressSelectOption>
              value={values.addressVariant}
              onChange={onAddressSelectChange}
              label={translate('CRYPTO_BANK_CARD_SHIPPING_ADDRESS')}
              options={addressSelectOptions}
              selectProps={addressSelectProps}
              labelAccessor={addressSelectLabelAccessor}
              autocomplete={false}
              inputComponent="textarea"
              // @ts-ignore
              inputRef={setAddressInputRef}
              inputContainerClassName={classes.addressInputContainer}
            />
            {values.addressVariant === 'default' ? null : (
              <div className="outlinedCard p-1">
                <AddressForm
                  allowedCountries={allowedCountries}
                  fieldName="shippingAddress"
                  isManualMode={isManualAddressMode}
                />
              </div>
            )}
          </>
        )}
      </div>
      <SubmitButton behaviour="validateOnClick" variant="greyishGreen">
        {translate('CONTINUE')}
      </SubmitButton>
    </form>
  );
};

const virtualSchemaPart = {
  phoneNumber: yup
    .string()
    .test(
      'isValidPhoneNumber',
      getTranslation('VALIDATION_PHONE_INVALID'),
      (phoneNumber: string | undefined) => (phoneNumber ? isPhoneNumberValid(phoneNumber) : false),
    )
    .requiredDefault(),
  birthday: yup
    .string()
    .test('validDate', getTranslation('ERRORS_INVALID_DATE'), (value: string | undefined) => {
      if (!value) {
        return false;
      }
      try {
        return isValid(parse(value, 'dd.MM.yyyy', new Date()));
      } catch (e) {
        return false;
      }
    })
    .test('dateRange', getTranslation('ERRORS_INVALID_DATE'), (value: string | undefined) => {
      if (!value) {
        return false;
      }
      try {
        const date = parse(value, 'dd.MM.yyyy', new Date());
        const distance = parseInt(
          formatDistanceStrict(date, new Date(), {
            unit: 'year',
          }),
          10,
        );
        const over18 = distance >= 18 && distance < 120;

        return over18 && !isFuture(date);
      } catch (e) {
        return false;
      }
    })
    .requiredDefault(),
};

const virtualBankCardSchema = yup.object().shape(virtualSchemaPart);
const schema = yup.object().shape({
  firstName: yup.string().requiredDefault(),
  lastName: yup.string().requiredDefault(),
  shippingAddress: yup.object().shape({
    country: yup
      .object()
      .required(getTranslation('VALIDATION_REQUIRED'))
      .typeError(getTranslation('VALIDATION_REQUIRED')),
    city: yup.string().requiredDefault(),
    address1: yup.string().requiredDefault(),
    address2: yup.string().optional(),
    postCode: yup.string().requiredDefault(),
  }),
  ...virtualSchemaPart,
});

export interface OrderBankCardShippingDetailsProps {
  isVirtual?: boolean;
}
const OrderBankCardShippingDetails: FC<OrderBankCardShippingDetailsProps> = ({ isVirtual }) => {
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const {
    entityReducer: { data: allowedCryptoCardCountries },
  } = useStoreEntity(selectCryptoCardAllowedCountriesReducer, requestCryptoCardAllowedCountries);
  const {
    entityReducer: { data: allowedAppCountries },
  } = useStoreEntity(selectAppAllowedCountriesReducer, requestAppAllowedCountries);

  const allowedCountries = useMemo(
    () =>
      allowedAppCountries.filter((appAllowed) =>
        allowedCryptoCardCountries.includes(appAllowed.code),
      ),
    [allowedAppCountries, allowedCryptoCardCountries],
  );

  const { loading: extendedProfileLoading, entity: extendedProfile } = useEntity(
    () => dispatch(requestUserExtendedProfile()),
    { fetchOnMount: true },
  );

  const extendedProfileCountry = useMemo(() => {
    if (!extendedProfile) {
      return;
    }
    return findByProperty(allowedCountries, 'code', extendedProfile.countryIso2Code);
  }, [allowedCountries, extendedProfile]);

  const handleSubmit = useCallback(
    (values: ShippingDetailsFormValues) => {
      if (isVirtual) {
        drawer.open(
          cryptoBankCardDrawerTemplates.orderBankCardConfirmation({
            isVirtual: true,
            userData: { phoneNumber: values.phoneNumber, dateOfBirth: values.birthday },
          }),
        );
      } else {
        drawer.open(
          cryptoBankCardDrawerTemplates.orderCardCalculatingShippingCosts({
            shippingDetails: values,
          }),
        );
      }
    },
    [drawer, isVirtual],
  );

  const initialValues: ShippingDetailsFormValues = useMemo(
    () =>
      extendedProfile
        ? {
            firstName: extendedProfile.firstName,
            lastName: extendedProfile.lastName,
            birthday: extendedProfile.dateOfBirth ?? '',
            phoneNumber: extendedProfile.phoneNumber ?? '',
            addressVariant: 'default',
            shippingAddress: {
              country: extendedProfileCountry || null,
              city: extendedProfile.city,
              address1: extendedProfile.address,
              address2: '',
              postCode: extendedProfile.postCode,
            },
          }
        : {
            firstName: '',
            lastName: '',
            birthday: '',
            phoneNumber: '',
            addressVariant: 'custom',
            shippingAddress: {
              country: null,
              city: '',
              address1: '',
              address2: '',
              postCode: '',
            },
          },
    [extendedProfileCountry, extendedProfile],
  );

  const validate = useMemo(
    () => makeValidate(isVirtual ? virtualBankCardSchema : schema),
    [isVirtual],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<ShippingDetailsFormValues>) => (
      <OrderBankCardShippingDetailsForm
        extendedProfile={extendedProfile}
        extendedProfileLoading={extendedProfileLoading}
        extendedProfileCountry={extendedProfileCountry}
        isVirtual={isVirtual}
        allowedCountries={allowedCountries}
        {...formProps}
      />
    ),
    [extendedProfile, extendedProfileLoading, extendedProfileCountry, isVirtual, allowedCountries],
  );

  return (
    <div className="relative">
      <Loader overlap active={extendedProfileLoading} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        render={renderForm}
      />
    </div>
  );
};

export default OrderBankCardShippingDetails;
