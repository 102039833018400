import { AppStore } from 'store';

export const selectExchangeRates = (state: AppStore) => state.exchange.rates;
export const selectAllowedDirectionsForExchange = (state: AppStore) =>
  state.exchange.allowedDirections.data;
export const selectAllowedDirectionsReducer = (state: AppStore) => state.exchange.allowedDirections;
export const selectAllowedDirectionsMeta = (state: AppStore) =>
  state.exchange.allowedDirections.meta;
export const selectRatesHistory = (state: AppStore) => state.exchange.ratesHistory;
export const selectAggregatedRatesHistory = (state: AppStore) =>
  state.exchange.ratesAggregatedHistory;

export const selectFollowedCurrencies = (state: AppStore) => state.exchange.followedCurrencies.data;
export const selectFollowedCurrenciesReducer = (state: AppStore) =>
  state.exchange.followedCurrencies;
