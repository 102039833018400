import { times } from 'lodash';

import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectIssuersReducer } from 'modules/accounts/store/selectors';
import { requestIssuers } from 'modules/accounts/store/thunks';
import useDrawer from 'modules/app/hooks/useDrawer';
import useFetchHistoryRatesBatched from 'modules/exchange/hooks/useFetchHistoryRatesBatched';
import {
  selectAllowedDirectionsReducer,
  selectFollowedCurrenciesReducer,
} from 'modules/exchange/store/selectors';
import {
  requestAllowedDirections,
  requestFollowCurrency,
  requestFollowedCurrencies,
  requestUnfollowCurrency,
} from 'modules/exchange/store/thunks';
import CurrencyRateCard from 'modules/exchange/views/components/CurrencyRateCard';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { Icon, Skeleton, Switch } from 'components/ui';

import useMobile from 'hooks/useMobile';
import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { isFiat } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './FollowedCurrencies.module.scss';

const currenciesPriority: { [key in CurrencyCode]?: number } = {
  BTC: 1,
  ETH: 2,
  USDC: 3,
  USDT: 4,
  BUSD: 100,
};

const FollowedCurrencies = () => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const defaultCurrency = useSelector(selectUserDefaultCurrencyCode);
  const {
    entityReducer: { data: allowedDirections, meta: allowedDirectionsMeta },
  } = useStoreEntity(selectAllowedDirectionsReducer, requestAllowedDirections);
  const {
    entityReducer: { data: followedCurrencies, meta: followedCurrenciesMeta },
  } = useStoreEntity(selectFollowedCurrenciesReducer, requestFollowedCurrencies);
  const {
    entityReducer: { data: issuers, meta: issuersMeta },
  } = useStoreEntity(selectIssuersReducer, requestIssuers, { staleAllowed: true });

  const loading =
    (issuersMeta.loading && !issuersMeta.loaded) ||
    (allowedDirectionsMeta.loading && !allowedDirectionsMeta.loaded) ||
    (followedCurrenciesMeta.loading && !followedCurrenciesMeta.loaded);

  const currencies = useMemo(
    () =>
      issuers
        .filter(
          (i) =>
            !isFiat(i.currencyCode) &&
            allowedDirections.includes(`${i.currencyCode}/${defaultCurrency}`),
        )
        .map((i) => i.currencyCode)
        .sort((a, b) => {
          if (currenciesPriority[b] || currenciesPriority[a]) {
            return (currenciesPriority[a] || 10) - (currenciesPriority[b] || 10);
          }
          return 0;
        }),

    [allowedDirections, defaultCurrency, issuers],
  );
  const toggleFollowing = useCallback(
    async (currencyCode: CurrencyCode, follow: boolean) => {
      const thunk = follow ? requestFollowCurrency : requestUnfollowCurrency;
      await dispatch(thunk(currencyCode));
    },
    [dispatch],
  );

  useFetchHistoryRatesBatched(currencies, defaultCurrency);

  return (
    <div className={classes.root}>
      <div className="row jcsb aic gap-2">
        <h5>{translate('MANAGE_CURRENCIES')}</h5>
        {!isMobile && (
          <Icon
            onClick={() => {
              drawer.pop();
            }}
            className="pointer"
            name="cross"
            size={16}
            color="black40"
          />
        )}
      </div>
      <div className={classes.content}>
        {loading
          ? times(10).map((_, index) => <Skeleton key={index} height={48} />)
          : currencies.map((currencyCode) => (
              <CurrencyRateCard
                key={currencyCode}
                className={classes.currencyCard}
                from={currencyCode}
                to={defaultCurrency}
                ratesChartProps={{ height: 32 }}
                reverseFiatAndStable={false}
                fetchHistory={false}
                endAdornment={
                  <Switch
                    className={classes.switch}
                    circleClassName={classes.switchCircle}
                    value={followedCurrencies.includes(currencyCode)}
                    onChange={(newValue) => {
                      toggleFollowing(currencyCode, newValue);
                    }}
                  />
                }
              />
            ))}
      </div>
    </div>
  );
};

export default FollowedCurrencies;
