import { uniqBy } from 'lodash';

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIssuersMeta, selectWalletsMap } from 'modules/accounts/store/selectors';
import { TradingWallet } from 'modules/accounts/types';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import {
  selectAllowedDirectionsReducer,
  selectTradesMap,
} from 'modules/smartTrader/store/selectors';
import { requestAllowedDirections } from 'modules/smartTrader/store/thunks';
import { useDispatch } from 'store';

import { CurrencyIcon, Loader, SearchInput, Switch } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';
import { formatCurrencyWithSymbol, getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

const CurrencyCard = memo<{
  currencyCode: CurrencyCode;
  wallet: TradingWallet;
  onSwitch: (code: CurrencyCode) => void;
}>(({ currencyCode, wallet, onSwitch }) => {
  return (
    <div className="creamyCard p-2 row jcsb">
      <div className="row gap-1-5 aic">
        <CurrencyIcon size={24} code={currencyCode} />
        <div className="column gap-0-5">
          <span className="font-A600 font-s">{getCurrencyLabelByCode(currencyCode)}</span>
          <span className="label">{currencyCode}</span>
        </div>
      </div>
      <div className="row gap-2">
        <div className="column gap-0-5">
          <span className="font-A600 font-s">
            {formatCurrencyWithSymbol(wallet.amount, wallet.currencyCode)}
          </span>
          {(!wallet.amount && !wallet.amountInDefaultCurrency) || wallet.amountInDefaultCurrency ? (
            <span className="label tar">
              {formatCurrencyWithSymbol(wallet.amountInDefaultCurrency, wallet.defaultCurrencyCode)}
            </span>
          ) : null}
        </div>
        <Switch value={false} onChange={() => onSwitch(currencyCode)} />
      </div>
    </div>
  );
});

export const StartTradeAllowedList = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const sidebar = useSideBar();

  const allowedDirectionsReducer = useSelector(selectAllowedDirectionsReducer);
  const tradesMap = useSelector(selectTradesMap);
  const walletsMap = useSelector(selectWalletsMap);
  const issuersMeta = useSelector(selectIssuersMeta);

  const [filterValue, setFilterValue] = useState('');

  const ListEmptyComponent = useMemo(() => {
    return (
      <span className="errorLabel mt-3">
        {filterValue.length
          ? `${translate('NO_RESULTS_FOR')} "${filterValue}"`
          : translate('NO_CURRENCIES_FOUND')}
      </span>
    );
  }, [translate, filterValue]);

  useEffect(() => {
    if (canRequest(allowedDirectionsReducer.meta)) {
      dispatch(requestAllowedDirections());
    }
    // eslint-disable-next-line
  }, []);

  const allowedCurrencies = useMemo(() => {
    const allowedCurrenciesByDirections = allowedDirectionsReducer.data
      .map((i) => {
        const code = i.split('/')[0] as CurrencyCode;
        const label = getCurrencyLabelByCode(code);

        return { code, label };
      })
      // @ts-ignore
      .filter((i) => !tradesMap[i.code]);
    return uniqBy(allowedCurrenciesByDirections, (i) => i.code);
  }, [tradesMap, allowedDirectionsReducer.data]);

  const handleSwitch = useCallback(
    (code: CurrencyCode) => {
      sidebar.open(...sidebarTemplates.smartTraderWelcome({ currencyCode: code }));
    },
    [sidebar],
  );

  const renderItem = useCallback(
    (currencyCode: CurrencyCode) => {
      // @ts-ignore
      const wallet = walletsMap[currencyCode] as TradingWallet;

      return wallet ? (
        <CurrencyCard
          key={currencyCode}
          currencyCode={currencyCode}
          wallet={wallet}
          onSwitch={handleSwitch}
        />
      ) : null;
    },
    [handleSwitch, walletsMap],
  );

  const filteredCurrencyCodes = useMemo(() => {
    if (filterValue) {
      const filterValueUppercase = filterValue.toUpperCase();

      return allowedCurrencies
        .filter(
          (currency) =>
            currency.code.includes(filterValueUppercase) ||
            currency.label.toUpperCase().includes(filterValueUppercase),
        )
        .map((c) => c.code);
    }

    return allowedCurrencies.map((i) => i.code);
  }, [allowedCurrencies, filterValue]);

  return (allowedDirectionsReducer.meta.loading && !allowedDirectionsReducer.meta.loaded) ||
    (issuersMeta.loading && !issuersMeta.loaded) ? (
    <Loader className="mt-10" centered />
  ) : (
    <div>
      <SearchInput
        value={filterValue}
        onChange={setFilterValue}
        placeholder={translate('PLACEHOLDER_SEARCH_CURRENCIES')}
      />
      {filteredCurrencyCodes.length ? (
        <div className="mt-2 column gap-1">{filteredCurrencyCodes.map(renderItem)}</div>
      ) : null}
      {!filteredCurrencyCodes.length ? ListEmptyComponent : null}
    </div>
  );
};
