import { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Form, FormRenderProps } from 'react-final-form';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { BankCardForm, SEPADetailsForm, WireDetailsForm } from 'modules/payment/views/components';
import {
  BankCardFormValues,
  getBankCardFormInitialValues,
  getBankCardValidationSchema,
} from 'modules/payment/views/components/BankCardForm';
import {
  CryptoWalletForm,
  CryptoWalletFormValues,
  cryptoWalletFormInitialValues,
  getCryptoWalletFormValidationSchema,
} from 'modules/payment/views/components/CryptoWalletForm';
import {
  SEPADetailsFormValues,
  SEPADetailsInitialValues,
  sepaDetailsFormSchema,
} from 'modules/payment/views/components/SEPADetailsForm';
import {
  WireDetailsFormValues,
  wireDetailsInitialValues,
  wireDetailsValidationSchema,
} from 'modules/payment/views/components/WireDetailsForm';
import { PHONEBOOK_COUNTERPARTIES_PAYMENT_DETAILS_HEADER_CLASSNAME } from 'modules/phonebook/constants/drawerTemplates';
import { remapPaymentDetailsToFormValues } from 'modules/phonebook/constants/utils';
import {
  requestCreateCounterpartyPaymentDetails,
  requestDeleteCounterpartyPaymentDetails,
  requestEditCounterpartyPaymentDetails,
} from 'modules/phonebook/store/thunks';
import { Counterparty, PaymentDetails, PaymentDetailsItem } from 'modules/phonebook/types';
import { useDispatch } from 'store';

import { SubmitButton } from 'components/form';

import { TranslationKey, useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';
import { makeValidate } from 'libs/yup';

export interface AddEditPaymentDetailsProps {
  counterpartyId: Counterparty['id'];
  paymentDetails?: PaymentDetails;
  type: PaymentDetails['type'];
  title: string;
  checkHandlers?: boolean;
  onSuccess?: (paymentDetails: PaymentDetails) => void;
}

type FormValues =
  | ({ type: 'BANK_CARD' } & BankCardFormValues)
  | ({ type: 'SEPA' } & SEPADetailsFormValues)
  | ({ type: 'WIRE' } & WireDetailsFormValues)
  | ({ type: 'CRYPTO_WALLET' } & CryptoWalletFormValues);

const configByType: {
  [key in PaymentDetails['type']]: {
    initialValues: Omit<FormValues, 'type'>;
    validationSchema: any;
    formComponent: ReactElement;
    label: TranslationKey;
    createMessage: TranslationKey;
    updateMessage: TranslationKey;
    deleteMessage: TranslationKey;
    deleteAskTitle: TranslationKey;
    deleteAskSubtitle: TranslationKey;
  };
} = {
  BANK_CARD: {
    initialValues: getBankCardFormInitialValues({ isDeposit: false }),
    validationSchema: getBankCardValidationSchema({ isDeposit: false }),
    formComponent: <BankCardForm isDeposit={false} />,
    label: 'PAYMENT_ENTER_CARD_DETAILS',
    createMessage: 'PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_ADDED',
    updateMessage: 'PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_UPDATED',
    deleteMessage: 'PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_DELETED',
    deleteAskTitle: 'PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_DELETE_TITLE',
    deleteAskSubtitle: 'PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_DELETE_SUBTITLE',
  },
  SEPA: {
    initialValues: SEPADetailsInitialValues,
    validationSchema: sepaDetailsFormSchema,
    formComponent: <SEPADetailsForm />,
    label: 'ACCOUNT_DETAILS',
    createMessage: 'PHONEBOOK_PAYMENT_DETAILS_SEPA_ADDED',
    updateMessage: 'PHONEBOOK_PAYMENT_DETAILS_SEPA_UPDATED',
    deleteMessage: 'PHONEBOOK_PAYMENT_DETAILS_SEPA_DELETED',
    deleteAskTitle: 'PHONEBOOK_PAYMENT_DETAILS_SEPA_DELETE_TITLE',
    deleteAskSubtitle: 'PHONEBOOK_PAYMENT_DETAILS_SEPA_DELETE_SUBTITLE',
  },
  WIRE: {
    initialValues: wireDetailsInitialValues,
    validationSchema: wireDetailsValidationSchema,
    formComponent: <WireDetailsForm />,
    label: 'ACCOUNT_DETAILS',
    createMessage: 'PHONEBOOK_PAYMENT_DETAILS_WIRE_ADDED',
    updateMessage: 'PHONEBOOK_PAYMENT_DETAILS_WIRE_UPDATED',
    deleteMessage: 'PHONEBOOK_PAYMENT_DETAILS_WIRE_DELETED',
    deleteAskTitle: 'PHONEBOOK_PAYMENT_DETAILS_WIRE_DELETE_TITLE',
    deleteAskSubtitle: 'PHONEBOOK_PAYMENT_DETAILS_WIRE_DELETE_SUBTITLE',
  },
  CRYPTO_WALLET: {
    initialValues: cryptoWalletFormInitialValues,
    validationSchema: getCryptoWalletFormValidationSchema,
    formComponent: <CryptoWalletForm withCurrencyPicker />,
    label: 'WALLET_DETAILS',
    createMessage: 'PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_ADDED',
    updateMessage: 'PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_UPDATED',
    deleteMessage: 'PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_DELETED',
    deleteAskTitle: 'PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_DELETE_TITLE',
    deleteAskSubtitle: 'PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_DELETE_SUBTITLE',
  },
};

interface AddEditPaymentDetailsFormProps extends FormRenderProps<FormValues> {
  label: string;
  formComponent: ReactElement;
  isEditing: boolean;
}

const AddEditPaymentDetailsForm: FC<AddEditPaymentDetailsFormProps> = ({
  label,
  formComponent,
  isEditing,
  handleSubmit,
}) => {
  const translate = useTranslation();

  return (
    <form
      onSubmit={handleSubmit}
      className="column mt-1 flex-1 gap-10 jcsb"
      style={{ minHeight: '50vh' }}
    >
      <div className="column gap-2">
        <span className="label">{label}</span>
        {formComponent}
      </div>
      <SubmitButton>{translate(isEditing ? 'UPDATE' : 'ADD')}</SubmitButton>
    </form>
  );
};

const AddEditPaymentDetails: FC<AddEditPaymentDetailsProps> = ({
  counterpartyId,
  paymentDetails,
  type,
  checkHandlers,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const translate = useTranslation();
  const config = useMemo(() => configByType[type], [type]);

  const isEditing = !!paymentDetails;

  useEffect(() => {
    if (checkHandlers && !onSuccess) {
      drawer.pop({ key: 'phonebookAddEditPaymentDetails', soft: false });
    }
    // eslint-disable-next-line
  }, []);

  const paymentDetailsAsFormValues = useMemo(() => {
    if (!paymentDetails) {
      return null;
    }

    return remapPaymentDetailsToFormValues(paymentDetails);
  }, [paymentDetails]);

  const initialValues = useMemo(
    () => ({
      type,
      ...config.initialValues,
      ...(paymentDetailsAsFormValues || {}),
    }),
    [type, config.initialValues, paymentDetailsAsFormValues],
  );

  const validateWithValues = useCallback(
    (values: FormValues) => {
      const schema = config.validationSchema(values);
      return makeValidate(schema)(values);
    },
    [config],
  );

  const defaultValidate = useMemo(() => makeValidate(config.validationSchema), [config]);

  const validate = useMemo(
    () => (type === 'CRYPTO_WALLET' ? validateWithValues : defaultValidate),
    [type, validateWithValues, defaultValidate],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <AddEditPaymentDetailsForm
        {...formProps}
        label={translate(config.label)}
        formComponent={config.formComponent}
        isEditing={isEditing}
      />
    ),
    [translate, config, isEditing],
  );

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      let details: PaymentDetailsItem[] = [];

      switch (values.type) {
        case 'BANK_CARD': {
          details = [
            { type: 'NUMBER', value: values.cardNumber.replaceAll(' ', '') },
            { type: 'NAME', value: values.fullName },
          ];
          break;
        }
        case 'SEPA': {
          details = [
            { type: 'NAME', value: values.accountName },
            { type: 'IBAN', value: values.iban },
            { type: 'REFERENCE', value: values.reference },
          ];
          break;
        }
        case 'WIRE': {
          details = [
            { type: 'NAME', value: values.accountName },
            { type: 'NUMBER', value: values.accountNumber },
            { type: 'SORT_CODE', value: values.sortCode },
            { type: 'REFERENCE', value: values.reference },
          ];
          break;
        }
        case 'CRYPTO_WALLET': {
          details = [
            { type: 'CURRENCY_CODE', value: values.currencyCode! },
            { type: 'CRYPTO_WALLET_ADDRESS', value: values.walletAddress },
            { type: 'CRYPTO_WALLET_NETWORK_ID', value: values.walletNetwork || '' },
            { type: 'CRYPTO_WALLET_ADDITIONAL', value: values.walletAddressAddition },
          ];
          break;
        }
      }

      const payload: Omit<PaymentDetails, 'id'> = { type: values.type, details };

      const thunk = isEditing
        ? requestEditCounterpartyPaymentDetails({
            counterpartyId,
            paymentDetailsId: paymentDetails!.id,
            paymentDetails: payload,
          })
        : requestCreateCounterpartyPaymentDetails({ counterpartyId, paymentDetails: payload });

      const { success, data } = await dispatch(thunk);

      if (success && data) {
        successToast(translate(isEditing ? config.updateMessage : config.createMessage));
        onSuccess?.(data);
        drawer.pop();
      }
    },
    [paymentDetails, onSuccess, counterpartyId, dispatch, drawer, isEditing, config, translate],
  );

  const headerNode = document.querySelector(
    '.' + PHONEBOOK_COUNTERPARTIES_PAYMENT_DETAILS_HEADER_CLASSNAME,
  );

  const handleClickRemove = useCallback(() => {
    drawer.open(
      commonDrawerTemplates.confirmation({
        title: translate(config.deleteAskTitle),
        description: translate(config.deleteAskSubtitle),
        imageProps: { name: 'cloud', path: 'common' },
        buttons: [
          {
            children: translate('YES'),
            variant: 'creamyBlack',
            handleLoading: true,
            onClick: async () => {
              if (!paymentDetails) {
                return;
              }
              const { success } = await dispatch(
                requestDeleteCounterpartyPaymentDetails({
                  paymentDetailsId: paymentDetails.id,
                  counterpartyId,
                }),
              );
              if (success) {
                successToast(translate(config.deleteMessage));
                drawer.pop({ key: 'confirmation' });
                drawer.pop({ key: 'phonebookAddEditPaymentDetails', soft: false });
              }
            },
          },
          {
            children: translate('NO'),
            variant: 'creamyBlack',
            onClick: () => {
              drawer.pop();
            },
          },
        ],
      }),
    );
  }, [counterpartyId, paymentDetails, config, translate, dispatch, drawer]);

  return (
    <>
      {isEditing && headerNode
        ? createPortal(
            <span onClick={handleClickRemove} className="label cyanBlue pointer">
              {translate(config.deleteAskTitle)}
            </span>,
            headerNode,
          )
        : null}
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        render={renderForm}
      />
    </>
  );
};

export default AddEditPaymentDetails;
