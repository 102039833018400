import { FieldRenderProps } from 'react-final-form';

import { PhoneNumberInput } from 'components/ui';
import { PhoneNumberInputProps, PhoneNumberInputValue } from 'components/ui/PhoneNumberInput';

import useField from 'hooks/useField';

interface CountryPickerInputFieldProps
  extends FieldRenderProps<PhoneNumberInputValue>,
    Omit<PhoneNumberInputProps, 'value' | 'onChange'> {}

export const PhoneNumberInputField = ({
  input: { value, onChange, ...input },
  meta,
  ...props
}: CountryPickerInputFieldProps) => {
  const { hasError, error, valid } = useField(meta);

  return (
    <PhoneNumberInput
      value={value}
      onChange={onChange}
      error={hasError && (error.country || error.number || error)}
      valid={valid}
      {...input}
      {...props}
    />
  );
};
