import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { requestGetPayoutSchedule } from 'modules/cryptoRenting/store/thunks';
import { RentingSchedule } from 'modules/cryptoRenting/types';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import {
  AmountLabel,
  Button,
  CurrencyIcon,
  InfoIcon,
  Loader,
  NavigationHeader,
  Table,
} from 'components/ui';
import { TableRow } from 'components/ui/Table';

import useFlag from 'hooks/useFlag';
import useTablet from 'hooks/useTablet';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel } from 'utils/currency';
import { formatDDMMYY } from 'utils/date';

import classes from './RentingPayoutSchedule.module.scss';

interface ListItemsProps {
  headers: string[];
  rows: TableRow[];
}
const ListItems: FC<ListItemsProps> = ({ headers, rows }) => {
  return (
    <div className="column gap-1-5">
      {rows.map((item) => (
        <div key={item.key} className={classes.listItem}>
          {item.columns.map((column, index) => (
            <div className="row aic jcsb gap-3" key={index}>
              <span className="label ">{headers[index]}</span>
              <div className="row aic space-no-wrap ">{column}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const RentingPayoutSchedule = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isTablet = useTablet();

  const params = useParams();

  const scheduleLoading = useFlag(false);
  const [schedule, setSchedule] = useState<RentingSchedule | null>(null);

  const init = useCallback(async () => {
    scheduleLoading.on();
    const { success, data } = await dispatch(requestGetPayoutSchedule(+params!.rentingId!));
    if (success) {
      setSchedule(data);
    }
    scheduleLoading.off();
  }, [scheduleLoading, params, dispatch]);

  useEffect(() => {
    if (!params.rentingId) {
      navigate(routesByName.cryptoRenting('new'));
    } else {
      init();
    }
    // eslint-disable-next-line
  }, [params?.rentingId]);

  const tableHeaders = useMemo(
    () => [
      translate('DATE'),
      translate('RENTING_PAYOUT'),
      translate('RENTING_TOTAL_CUMULATIVE_PAYOUT'),
    ],
    [translate],
  );

  const tableRows = useMemo(
    () =>
      !schedule
        ? []
        : schedule.schedule.map((row) => ({
            key: row.date,
            columns: [
              <span key={row.date}>{formatDDMMYY(new Date(row.date))}</span>,
              <>
                <CurrencyIcon
                  className={classes.currencyIcon}
                  code={schedule.renting.withdrawCurrency}
                  size={16}
                />
                <span className="black ml-1">
                  {formatCurrencyWithLabel(row.payoutAmount, schedule.renting.withdrawCurrency)}
                </span>
              </>,
              <>
                <CurrencyIcon
                  className={classes.currencyIcon}
                  code={schedule.renting.withdrawCurrency}
                  size={16}
                />
                <span className="black ml-1">
                  {formatCurrencyWithLabel(row.totalPayout, schedule.renting.withdrawCurrency)}
                </span>
              </>,
            ],
          })),
    [schedule],
  );
  return (
    <div>
      <NavigationHeader>{translate('BACK')}</NavigationHeader>
      {scheduleLoading.state ? (
        <Loader centered className="mt-10" size="xl" />
      ) : !schedule ? (
        <Button className="mt-10 m-0-auto" onClick={init}>
          {translate('ERROR_TRY_AGAIN')}
        </Button>
      ) : (
        <div className="mt-3">
          <div className={classes.rentingInfoCard}>
            <CurrencyIcon size={52} code={schedule.renting.rentCurrency} />
            <div className="column ml-1 ">
              <span className="label">{translate('RENTING_RENT_AMOUNT')}</span>
              <AmountLabel
                className="mt-1"
                amount={schedule.renting.rentAmount}
                currencyCode={schedule.renting.rentCurrency}
                showCurrencySymbol
                size="lg"
              />
            </div>
          </div>
          <div className={classes.schedule}>
            <div className="row aic jcsb gap-3">
              <h4>{translate('RENTING_PAYOUT_SCHEDULE')}</h4>
              <InfoIcon
                title={translate('RENTING_TOTAL_EARNINGS_FAQ_TITLE')}
                description={translate('RENTING_TOTAL_EARNINGS_FAQ_DESCRIPTION')}
              />
            </div>
            <div className="mt-2">
              {isTablet ? (
                <ListItems rows={tableRows} headers={tableHeaders} />
              ) : (
                <Table headers={tableHeaders} rows={tableRows} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RentingPayoutSchedule;
