import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectDigitalAccountExistWalletsReducer } from 'modules/accounts/store/selectors';
import useDrawer from 'modules/app/hooks/useDrawer';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';

import { AmountLabel, ButtonsMenu, Skeleton } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { findByProperty } from 'utils/arrayUtils';

import classes from './Assets.module.scss';

export const Assets = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const walletsReducer = useSelector(selectDigitalAccountExistWalletsReducer);

  const defaultWallet = useMemo(
    () => findByProperty(walletsReducer.data, 'default', true),
    [walletsReducer.data],
  );

  const operationButtons = useMemo(
    () => [
      {
        label: translate('DEPOSIT'),
        icon: 'arrowDownSquared',
        handler: () => {
          drawer.open(
            paymentDrawerTemplates.paymentProcess({
              isDeposit: true,
              isDigitalAccount: true,
              currencyCode: 'EUR',
            }),
          );
        },
      },
      {
        label: translate('SEND'),
        icon: 'arrowUpSquared',
        handler: () => {
          drawer.open(
            paymentDrawerTemplates.paymentProcess({
              isDeposit: false,
              isDigitalAccount: true,
              currencyCode: 'EUR',
            }),
          );
        },
      },
    ],
    [drawer, translate],
  );

  const handleSwitchIban = useCallback(() => {
    drawer.open(ibanAccountDrawerTemplates.switchDefaultIban());
  }, [drawer]);

  const loading = !walletsReducer.meta.loaded && walletsReducer.meta.loading;

  if (loading) {
    return (
      <div className={classes.root}>
        <div className="column gap-1-5">
          <div className="row aic jcsb">
            <Skeleton width={100} height={16} />
            <Skeleton width={100} height={16} />
          </div>
          <Skeleton width={200} height={40} />
        </div>
        <Skeleton height={92} />
      </div>
    );
  }

  if (!defaultWallet) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className="column gap-1-5">
        <div className="row jcsb aic gap-2">
          <span className="label">
            {translate('IBAN_ASSETS', { currencyCode: defaultWallet.currencyCode })}
          </span>
          <span onClick={handleSwitchIban} className="label cyanBlue pointer">
            {translate('SWITCH_IBAN', { currencyCode: defaultWallet.currencyCode })}
          </span>
        </div>
        <AmountLabel
          amount={defaultWallet.amount}
          currencyCode={defaultWallet.currencyCode}
          showCurrencySymbol
          size="xl4"
        />
      </div>
      <ButtonsMenu buttons={operationButtons} />
    </div>
  );
};
