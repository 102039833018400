import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectUserProfile } from 'modules/user/store/selectors';

import { CopyButton, InfoIcon, Skeleton } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './TransferReferenceCode.module.scss';

interface TransferReferenceCodeProps {
  code?: string;
  loading?: boolean;
}
export const TransferReferenceCode: FC<TransferReferenceCodeProps> = ({
  code: codeFromProps,
  loading,
}) => {
  const translate = useTranslation();

  const user = useSelector(selectUserProfile);

  const finalCode = codeFromProps || user?.publicId;

  return (
    <div className="outlinedCard column gap-2">
      <div className="row gap-2">
        <div className="column gap-0-5 flex-1">
          <span className="label">{translate('PAYMENT_SEPA_REFERENCE_CODE')}</span>
          {loading ? (
            <Skeleton width={200} height={28} />
          ) : (
            <span className={classes.referenceCode}>{finalCode}</span>
          )}
        </div>
        {!loading && <CopyButton className="flex-shrink-0" value={finalCode} />}
      </div>
      <div className="row aic jscb gap-2">
        <p className={classes.importantLabel}>
          {translate('PAYMENT_SEPA_REFERENCE_CODE_IMPORTANT')}
        </p>
        <InfoIcon
          className="flex-shrink-0"
          title={translate('PAYMENT_SEPA_REFERENCE_CODE')}
          description={translate('PAYMENT_SEPA_REFERENCE_CODE_IMPORTANT_DESC')}
        />
      </div>
    </div>
  );
};
