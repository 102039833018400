import clsx from 'clsx';

import { FC } from 'react';

import { Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

interface ErrorCardProps {
  label?: string;
  className?: string;
  retry?: voidFunc;
}

export const ErrorCard: FC<ErrorCardProps> = ({ className, retry, label }) => {
  const translate = useTranslation();

  return (
    <div className={clsx(className, 'column gap-2')}>
      <span className="errorLabel">{label || translate('API_ERROR')} </span>
      {retry && <Button onClick={retry}>{translate('ERROR_TRY_AGAIN')}</Button>}
    </div>
  );
};
