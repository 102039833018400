import { times } from 'lodash';

import { FC, useCallback } from 'react';

import accountsDrawerTemplates from 'modules/accounts/constants/drawerTemplates';
import { selectLastTransactionsReducer as selectTradingLastTransactionsReducer } from 'modules/accounts/store/selectors';
import { requestLastTransactions as requestTradingLastTransactions } from 'modules/accounts/store/thunks';
import { AccountType, Transaction } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import { selectLastTransactionsReducer as selectIbanLastTransactionsReducer } from 'modules/digitalAccount/store/selectors';
import { requestLastTransactions as requestIbanLastTransactions } from 'modules/digitalAccount/store/thunks';

import { EntityView } from 'components/common';
import { Icon, Skeleton, TransactionRow } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

interface LastTransactionsComponentProps {
  transactions: Transaction[] | null;
  accountType: AccountType;
}

const LastTransactionsComponent: FC<LastTransactionsComponentProps> = ({
  transactions,
  accountType,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const handleSeeAll = useCallback(() => {
    drawer.open(
      accountsDrawerTemplates.transactionsHistory({
        isCryptoAccount: accountType === AccountType.crypto,
      }),
    );
  }, [accountType, drawer]);

  const onTransactionClick = useCallback(
    (uniqId: string) => {
      if (!transactions) {
        return;
      }
      const transaction = transactions.find((i) => i.uniqId === uniqId);

      if (transaction) {
        drawer.open(
          accountsDrawerTemplates.transactionDetails({
            transaction,
          }),
        );
      }
    },
    [drawer, transactions],
  );

  return transactions ? (
    <div className="column gap-3">
      <div className="row aic jcsb">
        <span className="label">{translate('ACCOUNT_TRANSACTIONS_LAST')}</span>
        {transactions.length ? (
          <span onClick={handleSeeAll} className="label cyanBlue pointer ">
            {translate('ACCOUNT_TRANSACTIONS_SEE_ALL')}
          </span>
        ) : null}
      </div>

      {transactions.length ? (
        <div className="column gap-4">
          {transactions.map((item) => (
            <TransactionRow
              key={item.uniqId}
              transaction={item}
              onClick={() => {
                onTransactionClick(item.uniqId);
              }}
            />
          ))}
        </div>
      ) : (
        <div className="row aic gap-2">
          <Icon name="timeThin" size={32} color="grey200" />
          <span className="font-sm font-semibold">
            {translate('ACCOUNT_TRANSACTIONS_HISTORY_EMPTY')}
          </span>
        </div>
      )}
    </div>
  ) : null;
};

interface LastTransactionsProps {
  accountType: AccountType;
}
export const LastTransactions: FC<LastTransactionsProps> = ({ accountType }) => {
  const selectLastTransactionsReducer =
    accountType === AccountType.digital
      ? selectIbanLastTransactionsReducer
      : selectTradingLastTransactionsReducer;

  const requestLastTransactions =
    accountType === AccountType.digital
      ? requestIbanLastTransactions
      : requestTradingLastTransactions;

  const { entityReducer: transactionsReducer, fetchEntity } = useStoreEntity(
    selectLastTransactionsReducer,
    requestLastTransactions,
  );

  const component = (
    <LastTransactionsComponent accountType={accountType} transactions={transactionsReducer.data} />
  );
  return (
    <EntityView
      request={fetchEntity}
      component={component}
      noDataComponent={component}
      reducer={transactionsReducer}
      loaderComponent={
        <div className="column gap-3">
          <div className="row jcsb aic gap-2">
            <Skeleton height={16} width={90} />
            <Skeleton height={16} width={60} />
          </div>
          <div className="column gap-3">
            {times(3).map((_, i) => (
              <Skeleton key={i} height={48} />
            ))}
          </div>
        </div>
      }
    />
  );
};
