import clsx from 'clsx';

import { FC, useEffect, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { TextInputField } from 'components/form';

import { useTranslation } from 'libs/i18n';

import { formatCurrency } from 'utils/currency';

import { CurrencyCode } from 'types';

import { FormValues } from '../../index';
import classes from './ConfigChangeCard.module.scss';

interface ConfigChangeCardProps {
  currencyCode: CurrencyCode;
  secondaryAmount: number;
}

const getPercent = (greater: number, less: number) => {
  const percent = Number(((greater / less) * 100 - 100).toFixed(2));

  return isNaN(percent) ? '' : percent.toString();
};

interface PercentAdornmentProps {
  symbol: string;
  className?: string;
}

const PercentAdornment: FC<PercentAdornmentProps> = ({ symbol, className, ...props }) => (
  <span className={clsx(classes.percentAdornment, className)} {...props}>
    {symbol}
  </span>
);

export const ConfigChangeCard: FC<ConfigChangeCardProps> = ({ currencyCode, secondaryAmount }) => {
  const translate = useTranslation();

  const { getFieldState, change } = useForm<FormValues>();
  const { values } = useFormState<FormValues>();

  useEffect(() => {
    if (getFieldState('takeProfitAmount')?.active) {
      const percent = getPercent(+values.takeProfitAmount, secondaryAmount);
      change('takeProfitPercent', +percent > 0 ? percent : '');
    }
    // eslint-disable-next-line
  }, [values.takeProfitAmount]);

  useEffect(() => {
    if (getFieldState('stopLossAmount')?.active) {
      const percent = getPercent(+values.stopLossAmount, secondaryAmount);
      change('stopLossPercent', Math.abs(+percent).toString());
    }
    // eslint-disable-next-line
  }, [values.stopLossAmount]);

  useEffect(() => {
    if (getFieldState('stopLossPercent')?.active) {
      const amount = formatCurrency(
        secondaryAmount - secondaryAmount * (+values.stopLossPercent / 100),
        currencyCode,
      );
      change('stopLossAmount', amount);
    }
    // eslint-disable-next-line
  }, [values.stopLossPercent]);
  useEffect(() => {
    if (getFieldState('takeProfitPercent')?.active) {
      const amount = formatCurrency(
        secondaryAmount + secondaryAmount * (+values.takeProfitPercent / 100),
        currencyCode,
      );
      change('takeProfitAmount', amount);
    }
    // eslint-disable-next-line
  }, [values.takeProfitPercent]);

  const TakeProfitAmountEndAdornment = useMemo(
    () =>
      values.takeProfitAmount ? (
        <span className={classes.endAdornment}>{currencyCode}</span>
      ) : undefined,
    [values.takeProfitAmount, currencyCode],
  );
  const StopLossAmountEndAdornment = useMemo(
    () =>
      values.takeProfitAmount ? (
        <span className={classes.endAdornment}>{currencyCode}</span>
      ) : undefined,
    [values.takeProfitAmount, currencyCode],
  );

  return (
    <div className="whiteCard column p-3 gap-3">
      <div className="row gap-1 aifs">
        <Field
          className={classes.amountField}
          name="takeProfitAmount"
          component={TextInputField}
          label={translate('SMART_TRADER_TAKE_PROFIT')}
          placeholder={currencyCode}
          keyboardType="numeric"
          endAdornment={TakeProfitAmountEndAdornment}
        />
        <Field
          className={classes.percentField}
          name="takeProfitPercent"
          component={TextInputField}
          keyboardType="numeric"
          label=" "
          startAdornment={<PercentAdornment className={classes.start} symbol="+" />}
          endAdornment={<PercentAdornment className={classes.end} symbol="%" />}
        />
      </div>
      <div className="row gap-1 aifs">
        <Field
          className={classes.amountField}
          name="stopLossAmount"
          component={TextInputField}
          label={translate('SMART_TRADER_STOP_LOSS')}
          placeholder={currencyCode}
          keyboardType="numeric"
          endAdornment={StopLossAmountEndAdornment}
        />
        <Field
          className={classes.percentField}
          name="stopLossPercent"
          component={TextInputField}
          keyboardType="numeric"
          label=" "
          startAdornment={<PercentAdornment className={classes.start} symbol="-" />}
          endAdornment={<PercentAdornment className={classes.end} symbol="%" />}
        />
      </div>
    </div>
  );
};
