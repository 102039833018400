import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectMaintenanceData } from 'modules/app/store/selectors';
import { requestMaintenance } from 'modules/app/store/thunks';
import { useDispatch } from 'store';

import eventEmitter, { eventEmitterEventNames } from 'utils/eventEmitter';

const useMaintenance = () => {
  const intervalId = useRef<number | null>(null);
  const dispatch = useDispatch();

  const maintenance = useSelector(selectMaintenanceData);

  const checkMaintenance = useCallback(() => dispatch(requestMaintenance()), [dispatch]);

  useEffect(() => {
    checkMaintenance();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (maintenance.isActive) {
      intervalId.current = window.setInterval(checkMaintenance, 60 * 1000);
    } else {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    }
    // eslint-disable-next-line
  }, [maintenance.isActive]);

  useEffect(() => {
    const unsubscribe = eventEmitter.subscribe(
      eventEmitterEventNames.internalServerError,
      checkMaintenance,
    );

    return () => {
      unsubscribe();
    };
  }, [checkMaintenance]);
};

export default useMaintenance;
