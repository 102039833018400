import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectBankCards as selectCryptoBankCards,
  selectIsCryptoBankCardAllowed,
} from 'modules/cryptoBankCard/store/selectors';
import { selectIsDigitalAccountAllowed } from 'modules/digitalAccount/store/selectors';

const useBankCards = () => {
  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);
  const isCryptoBankCardAllowed = useSelector(selectIsCryptoBankCardAllowed);
  const cryptoBankCards = useSelector(selectCryptoBankCards);

  const bankCardsViewAllowed = useMemo(
    () => isDAAllowed || isCryptoBankCardAllowed || cryptoBankCards.length,
    [isDAAllowed, isCryptoBankCardAllowed, cryptoBankCards.length],
  );

  return { bankCardsViewAllowed };
};

export default useBankCards;
