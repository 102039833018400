import clsx from 'clsx';
import { parseISO } from 'date-fns';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RENTING_REQUEST_LIMIT_PAGE } from 'modules/cryptoRenting/constants/config';
import {
  selectActiveRentingItemsReducer,
  selectHistoryRentingItemsReducer,
  selectTemplates,
} from 'modules/cryptoRenting/store/selectors';
import {
  requestRentingActiveItems,
  requestRentingHistoryItems,
} from 'modules/cryptoRenting/store/thunks';
import { RentingItem as IRentingItem, Template } from 'modules/cryptoRenting/types';
import { TemplateImage } from 'modules/cryptoRenting/views/CryptoRenting/components/TemplateImage';
import { isReactNative } from 'modules/reactNative/utils';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { ErrorCard } from 'components/common';
import { AmountLabel, Button, CurrencyIcon, InfoIcon, Loader, Mark } from 'components/ui';

import useInfinityScrollSimplified from 'hooks/useInfinityScrollSimplified';

import { useTranslation } from 'libs/i18n';

import { formatDDMMYY } from 'utils/date';

import classes from './ActiveHistory.module.scss';

interface ActiveHistoryProps {
  active: boolean;
}
interface RentingItemProps {
  renting: IRentingItem;
  templates: Template[];
}

const RentingItem: FC<RentingItemProps> = ({ renting, templates }) => {
  const translate = useTranslation();
  const navigate = useNavigate();

  const template = useMemo(
    () => templates.find((t) => t.id === renting.templateId),
    [templates, renting],
  );

  const infoItems = useMemo(() => {
    if (!template) {
      return [];
    }

    const startDate = parseISO(renting.dateStart);
    const endDate = parseISO(renting.dateEnd);

    return [
      {
        label: translate('START_DATE'),
        value: formatDDMMYY(startDate),
      },
      {
        label: translate('END_DATE'),
        value: formatDDMMYY(endDate),
      },
      {
        label: translate('RENTING_RENT_AMOUNT'),
        value: renting.rentAmount,
        currencyCode: renting.rentCurrency,
      },
      {
        label: translate('TERM'),
        value: translate('DATE_MONTH_PLURAL', { count: renting.termMonth }),
      },
      {
        label: translate('RENTING_MONTHLY_PAYOUT'),
        value: renting.monthRewardAmount,
        currencyCode: renting.withdrawCurrency,
        answerModal: {
          title: translate('RENTING_MONTHLY_PAYOUT_FAQ_TITLE'),
          description: translate('RENTING_MONTHLY_PAYOUT_FAQ_DESCRIPTION'),
        },
      },
      {
        label: translate('RENTING_LOCKUP_PERIOD'),
        value: translate('DATE_MONTH_PLURAL', { count: template.lockUpPeriod }),
        answerModal: {
          title: translate('RENTING_LOCKUP_PERIOD_FAQ_TITLE'),
          description: translate('RENTING_LOCKUP_PERIOD_FAQ_DESCRIPTION', {
            months: template.lockUpPeriod,
          }),
        },
      },
      {
        label: translate('RENTING_YEARLY_PERCENTAGE'),
        value: renting.percentRPY.toString() + '%',
      },
      {
        label: translate('RENTING_TOTAL_EARNINGS'),
        value: renting.totalPayout,
        currencyCode: renting.withdrawCurrency,
        answerModal: {
          title: translate('RENTING_TOTAL_EARNINGS_FAQ_TITLE'),
          description: translate('RENTING_TOTAL_EARNINGS_FAQ_DESCRIPTION'),
        },
      },
    ];
  }, [translate, renting, template]);

  const handleClickPayoutSchedule = useCallback(() => {
    navigate(routesByName.cryptoRentingPayoutSchedule(renting.id.toString()));
  }, [renting.id, navigate]);

  return template ? (
    <div className={classes.rentingItem}>
      <div className="row aic">
        <TemplateImage size="sm" templateName={template.name} />
        <div className="column ml-2">
          <h6>{template.name}</h6>
          <Mark variant={renting.active ? 'green' : 'gray'}>
            {translate(renting.active ? 'ACTIVE' : 'EXPIRED')}
          </Mark>
        </div>
      </div>
      <div className={classes.items}>
        {infoItems.map((item) => (
          <div key={item.label} className={classes.infoItem}>
            <div className={clsx(classes.labelContainer, 'row aic')}>
              <span className={clsx(classes.label, 'label')}>{item.label}</span>
              {item.answerModal && <InfoIcon size="xs" className="ml-1-5" {...item.answerModal} />}
            </div>
            <div className="row aic">
              {item.currencyCode ? (
                <div className={classes.currencyAmount}>
                  <CurrencyIcon
                    className={classes.currencyIcon}
                    size={16}
                    code={item.currencyCode}
                  />
                  <AmountLabel
                    showCurrencyCode
                    size="sm"
                    currencyCode={item.currencyCode}
                    amount={item.value}
                    font="main"
                  />
                </div>
              ) : (
                <span className={classes.value}>{item.value}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <Button onClick={handleClickPayoutSchedule} variant="lightGreen" fullWidth className="mt-3">
        {translate('RENTING_PAYOUT_SCHEDULE')}
      </Button>
    </div>
  ) : null;
};

export const ActiveHistory: FC<ActiveHistoryProps> = ({ active }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const navigate = useNavigate();

  const templates = useSelector(selectTemplates);
  const rentingItemsReducer = useSelector(
    active ? selectActiveRentingItemsReducer : selectHistoryRentingItemsReducer,
  );

  const handleStartRenting = useCallback(() => {
    navigate(routesByName.cryptoRenting('new'));
  }, [navigate]);

  const loadMore = useCallback(async () => {
    const fetchItems = active ? requestRentingActiveItems : requestRentingHistoryItems;
    await dispatch(fetchItems(undefined, { pageLimit: RENTING_REQUEST_LIMIT_PAGE }));
  }, [dispatch, active]);

  const { onInfinityScroll } = useInfinityScrollSimplified({
    loading: rentingItemsReducer.meta.loading,
    hasMore: rentingItemsReducer.meta.hasMore,
    loadMore,
  });

  useEffect(() => {
    const scrollEl = document.querySelector<HTMLDivElement>('.appBody');

    if (scrollEl) {
      // @ts-ignore
      scrollEl.addEventListener('scroll', onInfinityScroll);
      return () => {
        // @ts-ignore
        scrollEl.removeEventListener('scroll', onInfinityScroll);
      };
    }
  }, [onInfinityScroll]);

  useEffect(() => {
    if (rentingItemsReducer.meta.pageNumber === 0) {
      loadMore();
    }
    // eslint-disable-next-line
  }, [rentingItemsReducer.meta.pageNumber]);

  return (
    <div className={clsx(classes.root, isReactNative && classes.reactNative)}>
      <div className={classes.rentingItems}>
        {rentingItemsReducer.data.map((renting) => (
          <RentingItem key={renting.id} templates={templates} renting={renting} />
        ))}
      </div>
      {rentingItemsReducer.meta.loading && <Loader className="mt-4" centered />}
      {rentingItemsReducer.meta.error && <ErrorCard retry={loadMore} />}
      {!rentingItemsReducer.meta.hasMore && rentingItemsReducer.data.length === 0 ? (
        <div className={classes.noItems}>
          <p className={classes.noItemsText}>
            {translate(!active ? 'RENTING_NO_HISTORY' : 'RENTING_NO_ACTIVE_RENTINGS')}
          </p>
          <Button fullWidth className={classes.button} onClick={handleStartRenting}>
            {translate('RENTING_START_RENTING')}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
