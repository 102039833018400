import { useMemo } from 'react';

import { selectPaymentOperationsLimitsReducer } from 'modules/payment/store/selectors';
import { requestOperationsLimits } from 'modules/payment/store/thunks';
import { PaymentOperationId } from 'modules/payment/types';
import useCommissionRule from 'modules/payment/views/components/AmountForm/hooks/useCommissionRule';

import useStoreEntity from 'hooks/useStoreEntity';

import { MINIMUM_AMOUNT_CRYPTO, MINIMUM_AMOUNT_FIAT, isFiat } from 'utils/currency';

import { CurrencyCode } from 'types';

const useTransactionLimit = (operationId: PaymentOperationId, currencyCode: CurrencyCode) => {
  const {
    entityReducer: { data: allPaymentsLimits },
  } = useStoreEntity(selectPaymentOperationsLimitsReducer, requestOperationsLimits);

  const commissionRule = useCommissionRule(operationId, currencyCode);

  const operationLimit = useMemo(() => {
    const minDefaultAmount = isFiat(currencyCode) ? MINIMUM_AMOUNT_FIAT : MINIMUM_AMOUNT_CRYPTO;

    const result = allPaymentsLimits.find(
      (l) => l.operationId === operationId && l.currencyCode === currencyCode,
    );

    if (operationId === PaymentOperationId.sendCryptoToExternalWallet && commissionRule) {
      if (result) {
        return {
          ...result,
          min: Math.max(minDefaultAmount, result.min + (commissionRule.fixed || 0)),
        };
      }
      return {
        min: commissionRule.fixed || minDefaultAmount,
        max: null,
      };
    }

    if (result) {
      return { ...result, min: Math.max(minDefaultAmount, result.min) };
    }
    return {
      min: minDefaultAmount,
      max: null,
    };
  }, [commissionRule, currencyCode, operationId, allPaymentsLimits]);

  return operationLimit;
};
export default useTransactionLimit;
