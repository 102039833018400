import { FC, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { CreateInvoiceStep } from 'modules/invoicing/views/CreateInvoice/components/CreateInvoiceStep';
import SavedEntityCard from 'modules/invoicing/views/CreateInvoice/components/SavedEntityCard';
import useUserCountry from 'modules/user/hooks/useUserCountry';
import { selectExtendedUserProfileReducer } from 'modules/user/store/selectors';
import { requestUserExtendedProfile } from 'modules/user/store/thunks';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

export interface CurrentUserFormValues {
  issuerName: string;
  issuerCountry: string;
  issuerCity: string;
  issuerPostCode: string;
  issuerAddress: string;
}
interface CurrentUserCardProps {}
export const CurrentUserCard: FC<CurrentUserCardProps> = () => {
  const translate = useTranslation();

  const {
    entityReducer: { data: userProfile },
  } = useStoreEntity(selectExtendedUserProfileReducer, requestUserExtendedProfile);

  const form = useForm<CurrentUserFormValues>();
  const { values } = useFormState<CurrentUserFormValues>();

  const userCountry = useUserCountry();

  useEffect(() => {
    if (userProfile) {
      form.batch(() => {
        form.change('issuerName', `${userProfile.firstName} ${userProfile.lastName}`);
        form.change('issuerCity', userProfile.city);
        form.change('issuerPostCode', userProfile.postCode);
        form.change('issuerAddress', userProfile.address);
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(userProfile)]);

  useEffect(() => {
    if (userCountry) {
      form.change('issuerCountry', userCountry.name);
    }
    // eslint-disable-next-line
  }, [userCountry?.name]);

  const title = values.issuerName;

  const description =
    values.issuerCountry && values.issuerCity
      ? [values.issuerCountry, values.issuerCity, values.issuerPostCode, values.issuerAddress].join(
          ', ',
        )
      : null;

  return (
    <CreateInvoiceStep
      label={translate('INVOICE_USER_DETAILS')}
      component={<SavedEntityCard entity="user" title={title} description={description} />}
    />
  );
};
