import clsx from 'clsx';

import { FC } from 'react';

import classes from './VerticalDelimiter.module.scss';

interface VerticalDelimiterProps {
  className?: string;
}
export const VerticalDelimiter: FC<VerticalDelimiterProps> = ({ className }) => {
  return <div className={clsx(classes.root, className)} />;
};
