import clsx from 'clsx';

import { FC } from 'react';

import { voidFunc } from 'types';

import classes from './Avatar.module.scss';

interface AvatarProps {
  size?: number;
  className?: string;
  onClick?: voidFunc;
  active?: boolean;
  name: string;
}

export const Avatar: FC<AvatarProps> = ({ size = 40, className, onClick, active, name }) => {
  const fontSize = Math.round(size / 2.2);

  const [fName, sName] = name.split(' ');

  return (
    <div
      onClick={onClick}
      className={clsx(classes.root, className, onClick && 'pointer', active && classes.active)}
      style={{ width: size, height: size }}
    >
      <span style={{ fontSize, height: fontSize }}>
        {fName[0]}
        {sName?.[0] || ''}
      </span>
    </div>
  );
};
