import { addMonths } from 'date-fns';

import { FC, useCallback } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import {
  translationKeySubDescSecondaryBySubType,
  translationKeyTitleBySubType,
} from 'modules/digitalAccount/constants/config';
import { requestCancelSubscription } from 'modules/digitalAccount/store/thunks';
import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionCheckCard } from 'modules/digitalAccount/views/components/SubscriptionCheckCard';
import { SubscriptionLayout } from 'modules/digitalAccount/views/components/SubscriptionLayout';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { Button, Icon, Mark } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatDDMMYY_HHMM } from 'utils/date';

import classes from './CancelSubscription.module.scss';

export interface CancelSubscriptionProps {
  subscription: DigitalSubscription;
}

const CancelSubscription: FC<CancelSubscriptionProps> = ({ subscription }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();
  const dispatch = useDispatch();

  const loading = useFlag(false);

  const handleSubmit = useCallback(async () => {
    loading.on();
    const { success, data } = await dispatch(requestCancelSubscription({ id: subscription.id }));
    loading.off();

    if (success) {
      sidebar.replace(
        ...sidebarTemplates.digitalAccountCancelSubscriptionSuccess({ subscription: data }),
      );
    }
  }, [loading, subscription, sidebar, dispatch]);

  return (
    <SubscriptionLayout subscriptionType={subscription.type}>
      <div>
        <div className={classes.titles}>
          <h4>{translate('DA_SUBSCRIPTION_CANCEL_QUESTION')}</h4>
          {subscription.startDate ? (
            <p>
              {translate('DA_SUBSCRIPTION_CANCEL_DESC', {
                endDate: formatDDMMYY_HHMM(
                  new Date(addMonths(new Date(subscription.startDate), 1)),
                ),
              })}
            </p>
          ) : null}
        </div>
        <div className={classes.blurredCard}>
          <Mark variant="white" className="row aic">
            <Icon name="cross" size="xs" className={classes.crossIcon} />
            {translate('DA_SUBSCRIPTION_WILL_BE_CLOSED')}
          </Mark>
          <div className={classes.subscriptionTitles}>
            <h5>
              <span>Nebeus {translate(translationKeyTitleBySubType[subscription.type])}</span>
              <br />
              {translate('DIGITAL_ACCOUNT')}
            </h5>
            <p>{translate(translationKeySubDescSecondaryBySubType[subscription.type])}</p>
          </div>
          <SubscriptionCheckCard
            variant="blurred"
            className={classes.checkCard}
            subscription={subscription}
          />
        </div>
      </div>
      <div className="column gap-1-5">
        <Button loading={loading.state} onClick={handleSubmit} fullWidth variant="goldOutlined">
          {translate('CANCEL_SUBSCRIPTION')}
        </Button>
        <Button onClick={sidebar.pop} variant="goldOutlined" fullWidth>
          {translate('BACK')}
        </Button>
        <AgreementText variant="modulR" light />
      </div>
    </SubscriptionLayout>
  );
};

export default CancelSubscription;
