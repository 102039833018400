import {
  requestDigitalWallets,
  requestIssuers,
  requestWallets,
} from 'modules/accounts/store/thunks';
import { selectAppLanguage } from 'modules/app/store/selectors';
import { requestOperationsCommissions } from 'modules/app/store/thunks';
import authServices from 'modules/auth/services';
import { authActions } from 'modules/auth/store';
import { logout } from 'modules/auth/store/externalThunks';
import {
  requestAllowedCountries as requestCryptoBankCardAllowedCountries,
  requestBankCards as requestCryptoBankCards,
} from 'modules/cryptoBankCard/store/thunks';
import {
  selectAllowedCountriesMeta,
  selectAllowedIbanAccountProvidersMeta,
} from 'modules/digitalAccount/store/selectors';
import {
  requestAllowedCountries as requestAllowedCountriesForIbanCreating,
  requestAllowedProviders as requestAllowedProvidersForIbanAccount,
  requestDigitalAccountSubscriptions,
} from 'modules/digitalAccount/store/thunks';
import { defaultLanguage } from 'modules/user/constants/language';
import { selectUserExperiments } from 'modules/user/store/selectors';
import { requestProfile, requestUpdateUserLanguage } from 'modules/user/store/thunks';
import { Profile } from 'modules/user/types';
import store, { AppDispatch, AppStore } from 'store';
import { generateRequestThunk } from 'store/generators/thunks';

import analytic from 'libs/analytic';
import Firebase from 'libs/firebase';
import { getTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import { canRequest } from 'utils/common';
import { initZohoChatUser } from 'utils/thirdPartyLibs';

// cli-import

export const initAuthedUser =
  () =>
  async (dispatch: AppDispatch, getState: () => AppStore): Promise<{ user: Profile } | null> => {
    dispatch(authActions.updateAuthed(true));

    const { success, data: user } = await dispatch(requestProfile());
    if (!success) {
      await dispatch(logout());
      return null;
    }

    const store = getState();

    if (user) {
      initZohoChatUser(user);
      const userExperiments = selectUserExperiments();
      analytic.identifyUser(user, { userExperiments });
      Firebase.provideFCMTokenToExternalServices();
    }

    dispatch(requestIssuers());
    dispatch(requestWallets());
    dispatch(requestOperationsCommissions());
    dispatch(requestCryptoBankCards());
    dispatch(requestCryptoBankCardAllowedCountries());

    if (canRequest(selectAllowedCountriesMeta(store), 60)) {
      dispatch(requestAllowedCountriesForIbanCreating());
    }
    if (canRequest(selectAllowedIbanAccountProvidersMeta(store), 60)) {
      dispatch(requestAllowedProvidersForIbanAccount());
    }
    dispatch(requestDigitalAccountSubscriptions());
    dispatch(requestDigitalWallets());

    return { user };
  };

export const requestSignIn = generateRequestThunk({
  request: authServices.signIn,
  options: {
    onSuccess: async (dispatch, data) => {
      if (data.sentToEmail) {
        successToast(getTranslation('SIGN_IN_OTP_EMAIL_SENT'));
      } else if (data.sentToPhone) {
        successToast(getTranslation('SIGN_IN_OTP_PHONE_SENT'));
      }
    },
  },
});
export const requestSignInConfirmOTP = generateRequestThunk({
  request: authServices.signInConfirm,
  options: {
    onSuccess: async (dispatch, data) => {
      if (data.accessToken && data.refreshToken) {
        authServices.setTokens(data.accessToken, data.refreshToken);
      }
    },
  },
});
export const requestSignUp = generateRequestThunk({
  request: authServices.signUp,
  options: {
    onSuccess: (dispatch, data) => {
      if (data.action === 'EMAIL_SENT') {
        successToast(getTranslation('SIGN_UP_EMAIL_SENT'));
      }
    },
  },
});

export const requestSignUpConfirm = generateRequestThunk({
  request: authServices.signUpConfirm,
  options: {
    onSuccess: async (dispatch, data) => {
      if (data.accessToken && data.refreshToken) {
        authServices.setTokens(data.accessToken, data.refreshToken);

        const appLang = selectAppLanguage(store.getState());
        if (appLang.fullCode !== defaultLanguage.fullCode) {
          await dispatch(requestUpdateUserLanguage(appLang.fullCode));
        }
      }
    },
  },
});

export const requestVerifyTFA = generateRequestThunk({
  request: authServices.verifyTFA,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestProfile());
    },
  },
});
export const requestSignInTFA = generateRequestThunk({
  request: authServices.signInTFA,
});
export const requestTFASetupKey = generateRequestThunk({ request: authServices.getTFASetupKey });
export const requestDisableTFA = generateRequestThunk({
  request: authServices.disableTFA,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestProfile());
    },
  },
});
export const requestResetTFA = generateRequestThunk({
  request: authServices.resetTFA,
});
export const requestSendOtpToPhoneNumber = generateRequestThunk({
  request: authServices.sendOtpToPhoneNumber,
});
export const requestVerifyPhoneNumberByOtp = generateRequestThunk({
  request: authServices.verifyPhoneNumberByOtp,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestProfile());
    },
  },
});
// cli-thunk
