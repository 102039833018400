import { formatDistanceToNowStrict } from 'date-fns';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { selectIssuersReducer } from 'modules/accounts/store/selectors';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { selectStakingItemsReducer } from 'modules/staking/store/selectors';
import { requestSingleStatistic, requestStakingItems } from 'modules/staking/store/thunks';
import { StakingItem as IStakingItem, SingleStatistic } from 'modules/staking/types';
import { StakingHistory } from 'modules/staking/views/components/StakingHistory';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import {
  AmountLabel,
  Button,
  CurrencyIcon,
  LineDelimiter,
  Loader,
  NavigationHeader,
} from 'components/ui';

import useFlag from 'hooks/useFlag';
import useTablet from 'hooks/useTablet';

import { useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';
import { formatCurrencyWithLabel, getCurrencyLabelByCode, isFiat } from 'utils/currency';

import { CryptoCurrencyCode, CurrencyCode } from 'types';

import classes from './StakingItem.module.scss';

interface StatsProps {
  currencyCode: CurrencyCode;
  staking: IStakingItem;
}
const Stats: FC<StatsProps> = ({ currencyCode, staking }) => {
  const sidebar = useSideBar();
  const translate = useTranslation();
  const dispatch = useDispatch();

  const isTablet = useTablet();

  const loading = useFlag(false);
  const [statistic, setStatistic] = useState<SingleStatistic | null>(null);

  const handleStake = useCallback(() => {
    if (currencyCode) {
      sidebar.open(
        ...sidebarTemplates.stakingSetup({ currencyCode } as { currencyCode: CryptoCurrencyCode }),
      );
    }
  }, [sidebar, currencyCode]);
  const handleUnstake = useCallback(() => {
    if (currencyCode) {
      sidebar.open(
        ...sidebarTemplates.unstake({ currencyCode } as { currencyCode: CryptoCurrencyCode }),
      );
    }
  }, [sidebar, currencyCode]);

  const fetchStatistic = useCallback(async () => {
    loading.on();

    const { data, success } = await dispatch(requestSingleStatistic({ currencyCode }));

    if (success) {
      setStatistic(data);
    }
    loading.off();
  }, [dispatch, loading, currencyCode]);

  useEffect(() => {
    fetchStatistic();
    // eslint-disable-next-line
  }, []);

  const statConfig = useMemo(
    () => [
      {
        label: translate('STAKING_STAKED_CURRENCY'),
        value: getCurrencyLabelByCode(staking.currencyCode, { withBrackets: true }),
        currencyIconCode: staking.currencyCode,
      },
      {
        label: translate('STAKING_TOTAL_REWARDS'),
        value: formatCurrencyWithLabel(statistic?.totalRewardsAmount || 0, staking.currencyCode),
        currencyIconCode: staking.currencyCode,
      },
      {
        label: translate('STAKING_NEXT_PAYOUT', {
          days: statistic?.nextPayoutDate
            ? parseInt(
                formatDistanceToNowStrict(new Date(statistic.nextPayoutDate), {
                  unit: 'day',
                  addSuffix: false,
                }),
              )
            : '-',
        }),
        value: formatCurrencyWithLabel(statistic?.nextPayoutAmount || 0, staking.currencyCode),
        currencyIconCode: staking.currencyCode,
      },
      {
        label: translate('PAYOUT_INTERVAL'),
        value: translate('STAKING_PAYOUT_INTERVAL'),
      },
    ],
    [staking, translate, statistic],
  );

  const buttons = (
    <div className={classes.buttons}>
      <Button fullWidth={isTablet} onClick={handleStake} variant="darkGreenOutlined">
        {translate('STAKING_STAKE')}
      </Button>
      <Button fullWidth={isTablet} onClick={handleUnstake} variant="darkGreenOutlined">
        {translate('STAKING_UNSTAKE')}
      </Button>
    </div>
  );
  return (
    <div className="column">
      <div className="row aifs jcsb">
        <div className="flex-1 column">
          <span className="label">{translate('STAKING_TOTAL_STAKED_VALUE')}:</span>
          <AmountLabel
            className={classes.amountLabel}
            size="xl4"
            amount={staking.amount}
            currencyCode={currencyCode}
            showCurrencyCode
          />
          <span className={classes.percent}>
            {staking.percentRPY}% {translate('STAKING_RPY')}
          </span>
        </div>
        {!isTablet && buttons}
      </div>
      <LineDelimiter className={classes.delimiter} />
      <div className={classes.statDetails}>
        {statConfig.map((item) => (
          <div key={item.label} className={classes.statDetailsItem}>
            <span className={classes.label}>{item.label}:</span>
            <div className="row aic gap-0-5">
              <span className={classes.statValue}>{item.value}</span>
              {item.currencyIconCode && (
                <CurrencyIcon code={item.currencyIconCode} size={isTablet ? 13 : 20} />
              )}
            </div>
          </div>
        ))}
      </div>
      <LineDelimiter className={classes.delimiter} />

      {isTablet && buttons}
    </div>
  );
};

const StakingItem = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const issuersReducer = useSelector(selectIssuersReducer);
  const stakingItemsReducer = useSelector(selectStakingItemsReducer);

  const currencyCode = useMemo(
    () => issuersReducer.data.find((i) => i.currencyCode === params.code)?.currencyCode || null,
    [issuersReducer.data, params.code],
  );

  const staking = useMemo(
    () => stakingItemsReducer.data.find((i) => i.currencyCode === currencyCode),
    [currencyCode, stakingItemsReducer.data],
  );

  useEffect(() => {
    if (issuersReducer.meta.loaded && (!currencyCode || isFiat(currencyCode))) {
      navigate(routesByName.staking('assets'));
    }
    // eslint-disable-next-line
  }, [currencyCode, issuersReducer.meta.loaded]);

  useEffect(() => {
    if (issuersReducer.meta.loaded && stakingItemsReducer.meta.loaded && !staking) {
      navigate(routesByName.staking('assets'));
    }
    // eslint-disable-next-line
  }, [staking, stakingItemsReducer.meta.loaded, issuersReducer.meta.loaded]);

  useEffect(() => {
    if (canRequest(stakingItemsReducer.meta)) {
      dispatch(requestStakingItems());
    }
    // eslint-disable-next-line
  }, []);

  return currencyCode ? (
    <div>
      <NavigationHeader>
        <div className={classes.header}>
          <h3>{getCurrencyLabelByCode(currencyCode)}</h3> <span>{currencyCode}</span>
        </div>
      </NavigationHeader>
      {stakingItemsReducer.meta.loading || !staking ? (
        <Loader centered className="mt-5" />
      ) : (
        <div className={classes.card}>
          <Stats currencyCode={currencyCode} staking={staking} />
          <StakingHistory className={classes.history} currencyCode={currencyCode} />
        </div>
      )}
    </div>
  ) : (
    <Loader centered />
  );
};

export default StakingItem;
