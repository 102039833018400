import { AppStore } from 'store';

export const selectPaymentOperationsLimitsReducer = (state: AppStore) =>
  state.payment.operationsLimits;
export const selectPaymentOperationsLimits = (state: AppStore) =>
  state.payment.operationsLimits.data;
export const selectPaymentWithdrawLimits = (state: AppStore) => state.payment.withdrawLimits;

export const selectSavedCryptoWalletsReducer = (state: AppStore) =>
  state.payment.savedCryptoWallets;
export const selectSavedCryptoWallets = (state: AppStore) => state.payment.savedCryptoWallets.data;
export const selectSavedBankCardsReducer = (state: AppStore) => state.payment.savedBankCards;
export const selectCurrenciesNetworksReducer = (state: AppStore) =>
  state.payment.currenciesNetworks;
