import { FC } from 'react';

import { StepHeader } from 'modules/auth/views/components/StepHeader';
import { SwitchAuthType } from 'modules/auth/views/components/SwitchAuthType';

import { DescriptionCard } from 'components/ui';

import { TranslationKey, useTranslation } from 'libs/i18n';

interface SelectAccountTypeProps {
  onSubmit: (accountType: SignUpAccountType) => void;
}

export type SignUpAccountType = 'personal' | 'business';

const options: {
  icon: string;
  title: TranslationKey;
  value: SignUpAccountType;
}[] = [
  {
    icon: 'userThin',
    title: 'SING_UP_ACCOUNT_TYPE_PERSONAL_TITLE',
    value: 'personal',
  },
  {
    icon: 'casePlusThin',
    title: 'SING_UP_ACCOUNT_TYPE_BUSINESS_TITLE',
    value: 'business',
  },
];
export const SelectAccountType: FC<SelectAccountTypeProps> = ({ onSubmit }) => {
  const translate = useTranslation();

  return (
    <div className="authStepRoot aic">
      <div className="column gap-3">
        <StepHeader
          title={translate('SING_UP_ACCOUNT_TYPE_TITLE')}
          subtitle={translate('SING_UP_ACCOUNT_TYPE_SUBTITLE')}
        />
        <div className="column gap-2 w100">
          {options.map((option) => (
            <DescriptionCard
              key={option.icon}
              onClick={() => {
                onSubmit(option.value);
              }}
              icon={option.icon}
              title={translate(option.title)}
            />
          ))}
        </div>
      </div>
      <SwitchAuthType currentAuthType="signUp" className="hideOnMobile" />
    </div>
  );
};
