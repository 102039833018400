import clsx from 'clsx';

import { FC, useCallback, useEffect, useMemo } from 'react';

import { requestStakingHistory } from 'modules/staking/store/thunks';
import { StakingHistoryItem } from 'modules/staking/types';
import { useDispatch } from 'store';

import { CurrencyIcon, LineDelimiter, Loader } from 'components/ui';

import useInfinityScroll from 'hooks/useInfinityScroll';
import useScroll from 'hooks/useScroll';
import useTablet from 'hooks/useTablet';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY } from 'utils/date';
import eventEmitter from 'utils/eventEmitter';

import { CurrencyCode } from 'types';

import classes from './StakingHistory.module.scss';

interface StakingHistoryProps {
  currencyCode?: CurrencyCode;
  className?: string;
}

export const StakingHistory: FC<StakingHistoryProps> = ({ currencyCode, className }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();

  const isTablet = useTablet();

  const loadHistory = useCallback(
    async (page: number) => {
      const { success, data } = await dispatch(
        requestStakingHistory({
          pageNumber: page,
          pageSize: 10,
          currencyCode,
        }),
      );

      if (success) {
        return data;
      }
      return [];
    },
    [dispatch, currencyCode],
  );

  const { onInfinityScroll, isLoading, list, refreshList } = useInfinityScroll({
    loadMore: loadHistory,
    pageSize: 10,
  });

  useScroll(onInfinityScroll);

  const labelsByType = useMemo<{ [key in StakingHistoryItem['operationType']]?: string }>(
    () => ({
      UNSTAKE_ACCOUNT: translate('STAKING_UNSTAKE'),
      STAKE_ACCOUNT: translate('STAKING_STAKE'),
      STAKING_PAYOUT: translate('STAKING_REWARD_PAYOUT'),
    }),
    [translate],
  );

  useEffect(() => {
    const unsubscribe = eventEmitter.subscribe('refreshStakingHistory', refreshList);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={className}>
      <span className="label">{translate('STAKING_HISTORY')}:</span>
      <LineDelimiter className={classes.delimiter} />

      <div className="column gap-1">
        {list.map((i) => (
          <div key={i.transactionId} className={classes.listItem}>
            <div className="row aic gap-1">
              <CurrencyIcon size={isTablet ? 28 : 32} code={i.currencyCode} />
              <div className="column">
                <span className={classes.operationName}>{labelsByType[i.operationType]}</span>
                <span className={classes.date}>{formatDDMMYY(new Date(i.dateCreated))}</span>
              </div>
            </div>
            <div className="tar column">
              <span
                className={clsx(
                  classes.amount,
                  i.operationType !== 'UNSTAKE_ACCOUNT' && classes.up,
                )}
              >
                {i.operationType === 'UNSTAKE_ACCOUNT' ? '-' : '+'}
                {formatCurrencyWithLabel(i.amount, i.currencyCode)}
              </span>
              {i.amountInDefaultCurrency ? (
                <span className={classes.amountInDef}>
                  {formatCurrencyWithSymbol(i.amountInDefaultCurrency, i.defaultCurrency)}
                </span>
              ) : null}
            </div>
          </div>
        ))}
        {isLoading && <Loader centered />}
      </div>
    </div>
  );
};
