import { FC, useCallback, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import { TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import useRateCalculator from 'modules/exchange/hooks/useRateCalculator';
import {
  getAddressAdditionKeyByCurrency,
  getAddressAdditionLabelByCurrency,
  getImportantLabelForSendCrypto,
} from 'modules/payment/constants/cryptoCurrenciesDetails';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import useDefaultCryptoNetwork from 'modules/payment/hooks/useDefaultCryptoNetwork';
import usePaymentInit from 'modules/payment/hooks/usePaymentInit';
import { requestSendToExternalWallet } from 'modules/payment/store/thunks';
import {
  CurrencyNetwork,
  PaymentOperationId,
  transactionTypesForOtpSending,
} from 'modules/payment/types';
import { AmountForm, PaymentAmountFormValues } from 'modules/payment/views/components/AmountForm';
import useAmountFormValidation from 'modules/payment/views/components/AmountForm/hooks/useAmountFormValidation';
import { CommissionLabelProps } from 'modules/payment/views/components/CommissionLabel';
import {
  CryptoWalletForm,
  CryptoWalletFormValues,
  cryptoWalletFormInitialValues,
  getCryptoWalletFormValidationSchema,
} from 'modules/payment/views/components/CryptoWalletForm';
import { WithdrawalLimitsCard } from 'modules/payment/views/components/WithdrawalLimitsCard';
import { selectSelectedRecipientForSendingFunds } from 'modules/phonebook/store/selectors';
import RecipientSelector from 'modules/phonebook/views/RecipientSelector';
import { selectUserDefaultCurrencyCode, selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { ImportantCard } from 'components/ui';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { getTranslation, useTranslation } from 'libs/i18n';
import { CreateOutcomeTransactionRequest } from 'libs/swagger/nebeusApiTypes';
import { errorToast } from 'libs/toast';
import { makeValidate } from 'libs/yup';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';

import { CryptoCurrencyCode } from 'types';

import { WalletBalanceCard } from '../components';

interface SendExternalWalletProps {
  currencyCode: CryptoCurrencyCode;
}

interface FormValues extends PaymentAmountFormValues, CryptoWalletFormValues {
  savedWalletId: string | null;
}

const OPERATION_ID = PaymentOperationId.sendCryptoToExternalWallet;
interface SendExternalWalletFormProps extends FormRenderProps<FormValues> {
  currencyCode: CryptoCurrencyCode;
  allowedNetworks: CurrencyNetwork[];
}

const SendExternalWalletForm: FC<SendExternalWalletFormProps> = ({
  handleSubmit,
  values,
  currencyCode,
  allowedNetworks,
}) => {
  const currentNetwork = useMemo(
    () => allowedNetworks.find((n) => n.network === values.walletNetwork),
    [values.walletNetwork, allowedNetworks],
  );

  const amountFormAdditionalCalculateRequestPayload = useMemo(
    () => ({ network: values.walletNetwork! }),
    [values.walletNetwork],
  );

  const recalculateDeps = useMemo(() => [values.walletNetwork || ''], [values.walletNetwork]);
  const commissionLabelProps = useMemo<Partial<CommissionLabelProps>>(
    () => ({ cryptoNetwork: values.walletNetwork! }),
    [values.walletNetwork],
  );

  const recipientSelectorFilters = useMemo(() => ({ currencyCode }), [currencyCode]);

  return (
    <form onSubmit={handleSubmit} className="column gap-3">
      <WithdrawalLimitsCard
        currencyCode={values.currencyAmount.currency}
        operationId={OPERATION_ID}
      />
      <RecipientSelector paymentType="CRYPTO_WALLET" filters={recipientSelectorFilters}>
        <CryptoWalletForm />
      </RecipientSelector>
      <AmountForm
        operationId={PaymentOperationId.sendCryptoToExternalWallet}
        additionalCalculateRequestPayload={amountFormAdditionalCalculateRequestPayload}
        recalculateDeps={recalculateDeps}
        commissionLabelProps={commissionLabelProps}
      />
      {currentNetwork && (
        <ImportantCard
          text={getImportantLabelForSendCrypto(currencyCode, currentNetwork.networkName)}
        />
      )}
    </form>
  );
};

const SendExternalWallet: FC<SendExternalWalletProps> = ({ currencyCode }) => {
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const translate = useTranslation();

  usePaymentInit();

  const userProfile = useSelector(selectUserProfile);

  const { defaultNetwork, allowedNetworks } = useDefaultCryptoNetwork(currencyCode, {
    isDeposit: false,
  });

  const userDefaultCurrencyCode = useSelector(selectUserDefaultCurrencyCode);
  const convertToDefaultCurrency = useRateCalculator(currencyCode, userDefaultCurrencyCode);

  const wallets = useSelector(selectWallets);
  const wallet = useMemo(
    () => wallets.find((w) => w.currencyCode === currencyCode) as TradingWallet,
    [wallets, currencyCode],
  );

  const convertToDefaultCurrencyWithSymbol = useCallback(
    (amount: string | number) =>
      formatCurrencyWithSymbol(convertToDefaultCurrency(+amount), userDefaultCurrencyCode),
    [convertToDefaultCurrency, userDefaultCurrencyCode],
  );

  const walletAddressAdditionName = getAddressAdditionLabelByCurrency(currencyCode);

  const getCommonSummaryBlocks = useCallback(
    (values: FormValues) => {
      const result: SummaryCardItem[][] = [
        [
          {
            label: translate('SENDER'),
            value: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
          },
          { label: translate('PAYMENT_RECIPIENTS_WALLET'), value: values.walletAddress },
        ],
        [
          {
            label: translate('PAYMENT_RECIPIENTS_GETS'),
            value: formatCurrencyWithLabel(
              values.currencyAmountWithCommission?.amount || values.currencyAmount.amount,
              currencyCode,
            ),
            subValue: convertToDefaultCurrencyWithSymbol(
              +(values.currencyAmountWithCommission?.amount || 0) || values.currencyAmount.amount,
            ),
          },
          {
            label: translate('PAYMENT_NEBEUS_FEE'),
            value: formatCurrencyWithLabel(values.commissionAmount || 0, currencyCode),
            subValue: convertToDefaultCurrencyWithSymbol(values.commissionAmount || 0),
          },
          {
            label: translate('PAYMENT_TOTAL_WITHDRAWN'),
            value: formatCurrencyWithLabel(
              values.currencyAmount.amount,
              values.currencyAmount.currency,
            ),
            subValue: convertToDefaultCurrencyWithSymbol(+values.currencyAmount.amount),
          },
        ],
      ];

      if (walletAddressAdditionName) {
        result[0].splice(2, 0, {
          label: walletAddressAdditionName,
          value: values.walletAddressAddition,
        });
      }
      const network = allowedNetworks.find((n) => n.network === values.walletNetwork);

      if (network) {
        result[0].splice(2, 0, {
          value: network.networkName,
          label: getTranslation('NETWORK'),
        });
      }

      return result;
    },
    [
      allowedNetworks,
      convertToDefaultCurrencyWithSymbol,
      currencyCode,
      translate,
      userProfile,
      walletAddressAdditionName,
    ],
  );

  const phonebookRecipient = useSelector(selectSelectedRecipientForSendingFunds);

  const sendFunds = useCallback(
    async (values: FormValues, code: string) => {
      if (!values.walletNetwork) {
        return;
      }
      if (wallet?.coinSerial) {
        let address = values.walletAddress;

        if (values.walletAddressAddition) {
          const additionAddressKey = getAddressAdditionKeyByCurrency(
            values.currencyAmount.currency as CryptoCurrencyCode,
          );

          if (additionAddressKey) {
            address += `?${additionAddressKey}=${values.walletAddressAddition}`;
          }
        }

        const payload: CreateOutcomeTransactionRequest = {
          amount: +values.currencyAmount.amount,
          address,
          coinSerial: wallet.coinSerial,
          otpCode: code,
          network: values.walletNetwork,
        };

        // @ts-ignore For analytic purpose
        payload['analyticField_phonebookRecipient'] = phonebookRecipient;

        const { success, error } = await dispatch(requestSendToExternalWallet(payload));

        const drawerTemplatePayload = {
          navigationHeaderLabel: success ? translate('PAYMENT_OPERATION_INITIATED') : undefined,
          currencyCode: values.currencyAmount.currency,
          amount: +values.currencyAmount.amount * -1,
          isDeposit: false,
          isSuccess: success,
          description: success
            ? translate('PAYMENT_CRYPTO_WITHDRAW_INITIATED_SUBTITLE')
            : error?.message || getTranslation('ERROR_SOMETHING_BROKE'),
          summaryBlocks: [
            [
              {
                label: translate('DESCRIPTION'),
                value: translate('PAYMENT_SEND_TO_EXTERNAL_WALLET'),
              },
              { label: translate('DATE_&_TIME'), value: formatDDMMYY_HHMM(new Date()) },
              {
                label: translate('ACCOUNT_OPERATION_STATUS'),
                value: translate(
                  success ? 'PAYMENT_OPERATION_INITIATED' : 'PAYMENT_OPERATION_FAILED',
                ),
              },
            ],
            ...getCommonSummaryBlocks(values),
          ],
        };

        const drawerTemplate = paymentDrawerTemplates.finishedOperation(drawerTemplatePayload);

        if (success) {
          drawer.replaceAll(drawerTemplate);
        } else {
          drawer.replace(drawerTemplate);
        }
      } else {
        errorToast(
          `Wallet ${wallet?.currencyCode || ''} not found. Please, contact Nebeus support`,
        );
      }
    },
    [phonebookRecipient, getCommonSummaryBlocks, translate, dispatch, drawer, wallet],
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const network = allowedNetworks.find((n) => n.network === values.walletNetwork);
      if (!network) {
        return;
      }

      drawer.open(
        paymentDrawerTemplates.confirmOTPCode({
          summary: {
            currencyAmount: values.currencyAmount,
            currencyAmountWithCommission: values.currencyAmountWithCommission,
          },
          summaryBlocks: getCommonSummaryBlocks(values),
          onConfirmed: (code: string) => sendFunds(values, code),
          importantLabel: getImportantLabelForSendCrypto(currencyCode, network.networkName),
          transactionType:
            transactionTypesForOtpSending[PaymentOperationId.sendCryptoToExternalWallet],
        }),
      );
    },
    [allowedNetworks, getCommonSummaryBlocks, drawer, sendFunds, currencyCode],
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      ...cryptoWalletFormInitialValues,
      savedWalletId: null,
      currencyAmount: { amount: '', currency: currencyCode },
      currencyAmountWithCommission: { amount: '', currency: currencyCode },
      commissionAmount: null,
      walletNetwork: defaultNetwork?.network || null,
      currencyCode,
    }),
    [defaultNetwork, currencyCode],
  );

  const { amountFormSchema } = useAmountFormValidation({
    operationId: PaymentOperationId.sendCryptoToExternalWallet,
    currencyCode,
  });

  const validate = useCallback(
    (values: FormValues) => {
      const walletFormSchema = getCryptoWalletFormValidationSchema(values);
      return makeValidate(walletFormSchema.concat(amountFormSchema))(values);
    },
    [amountFormSchema],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <SendExternalWalletForm
        currencyCode={currencyCode}
        allowedNetworks={allowedNetworks}
        {...formProps}
      />
    ),
    [currencyCode, allowedNetworks],
  );

  return (
    <div className="mt-1 column gap-3">
      <WalletBalanceCard currencyCode={currencyCode} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={renderForm}
        validate={validate}
      />
    </div>
  );
};
export default SendExternalWallet;
