import { FC, MouseEventHandler, useCallback, useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';

import LocalStorageKeys from 'constants/localStorageKeys';

import { Icon, Image } from 'components/ui';

import usePersistedState from 'hooks/usePersistedState';

import { useTranslation } from 'libs/i18n';

import classes from './NebeusReferralProgramAdBanner.module.scss';

const ONE_DAY_IN_MS = 60 * 60 * 24 * 1000;

interface NebeusReferralProgramAdBannerProps {}
const NebeusReferralProgramAdBanner: FC<NebeusReferralProgramAdBannerProps> = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const [lastRejectedTime, setLastRejectedTime] = usePersistedState(
    LocalStorageKeys.referralLinksBannerCloseTime,
  );

  const handleClick = useCallback(() => {
    drawer.open(commonDrawerTemplates.referralLinksMarketingAd());
  }, [drawer]);

  const handleClose = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();
      setLastRejectedTime(Date.now().toString());
    },
    [setLastRejectedTime],
  );

  const canShow = useMemo(
    () => !lastRejectedTime || Date.now() - +lastRejectedTime > ONE_DAY_IN_MS,
    [lastRejectedTime],
  );

  return canShow ? (
    <div className={classes.root} onClick={handleClick}>
      <Image name="moneyBag" path="common" />

      <div className={classes.textContent}>
        <span className="label">{translate('REFERRAL_LINK_NEBEUS_PROGRAM')}</span>
        <h5>{translate('REFERRAL_LINK_MARKETING_AD_TITLE')}</h5>
      </div>
      <Icon onClick={handleClose} className={classes.crossIcon} name="cross" size={16} />
    </div>
  ) : null;
};

export default NebeusReferralProgramAdBanner;
