import clsx from 'clsx';

import { FC, ReactNode } from 'react';

import { Icon } from 'components/ui/Icon';

import { voidFunc } from 'types';

import classes from './DescriptionCard.module.scss';

interface DescriptionCardProps {
  title: string;
  icon: string;
  description?: string;
  endAdornment?: ReactNode;
  onClick?: voidFunc;
  className?: string;
}
export const DescriptionCard: FC<DescriptionCardProps> = ({
  title,
  icon,
  description,
  endAdornment,
  onClick,
  className,
}) => {
  return (
    <div className={clsx(classes.root, className, onClick && classes.clickable)} onClick={onClick}>
      <Icon name={icon} size={32} />
      <span className={classes.title}>{title}</span>
      {description ? (
        <p className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      {endAdornment}
    </div>
  );
};
