import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { getCurrencyCodeByNetwork } from 'modules/payment/constants/cryptoCurrenciesDetails';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import useAllowedCryptoNetworksByCurrencyCode from 'modules/payment/hooks/useAllowedCryptoNetworksByCurrencyCode';
import { requestGenerateCryptoAddress } from 'modules/payment/store/thunks';
import { CurrencyNetwork } from 'modules/payment/types';
import { getCommissionLabel } from 'modules/payment/utils/getCommissionLabel';
import { useDispatch } from 'store';

import { CurrencyIcon, LineDelimiter, Loader } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { CryptoCurrencyCode } from 'types';

import classes from './SelectCryptoNetwork.module.scss';

export interface SelectCryptoNetworkProps {
  currencyCode: CryptoCurrencyCode;
  onSelect: (network: CurrencyNetwork) => void;
  isDeposit: boolean;
}

const SelectCryptoNetwork: FC<SelectCryptoNetworkProps> = ({
  currencyCode,
  onSelect,
  isDeposit,
}) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const drawer = useDrawer();

  const { allowedNetworks } = useAllowedCryptoNetworksByCurrencyCode(currencyCode, { isDeposit });

  useEffect(() => {
    if (!onSelect) {
      drawer.pop();
    }
    // eslint-disable-next-line
  }, [onSelect]);

  const [generatingNetworkId, setGeneratingNetworkId] = useState<CurrencyNetwork['network'] | null>(
    null,
  );

  const handleSelect = useCallback<MouseEventHandler<HTMLDivElement>>(
    async (event) => {
      const network = allowedNetworks.find(
        (n) => n.network === event.currentTarget.dataset.network,
      );

      if (!network) {
        return;
      }
      if (isDeposit) {
        if (network.address) {
          onSelect(network);
          drawer.pop();
        } else {
          setGeneratingNetworkId(network.network);
          const { success } = await dispatch(
            requestGenerateCryptoAddress({ currencyCode, network: network.network }),
          );
          if (success) {
            onSelect(network);
            drawer.pop();
          }
          setGeneratingNetworkId(null);
        }
      } else {
        drawer.open(
          paymentDrawerTemplates.selectCryptoNetworkConfirmation({
            network,
            onConfirm: () => {
              drawer.pop({ key: 'paymentSelectCryptoNetwork' });
              onSelect(network);
            },
          }),
        );
      }
    },
    [drawer, isDeposit, onSelect, currencyCode, dispatch, allowedNetworks],
  );

  return (
    <div className="column gap-1-5">
      <p className={classes.notice}>{translate('PAYMENT_NETWORK_NOTICE')}</p>
      <LineDelimiter />
      <div className="column gap-0-5">
        {allowedNetworks.map((n) => {
          const networkCurrencyCode = getCurrencyCodeByNetwork(n.network);
          return (
            <div
              key={n.network}
              data-network={n.network}
              className={classes.item}
              onClick={handleSelect}
            >
              <div className="row gap-1-5 aic">
                {networkCurrencyCode && <CurrencyIcon code={networkCurrencyCode} size={44} />}

                <div className="column">
                  <span className={classes.boldLabel}>{n.networkName}</span>
                  {isDeposit ? null : (
                    <span className={classes.label}>
                      {translate('FEE')}:{' '}
                      {getCommissionLabel({
                        fixed: n.commission.fixedAmount,
                        minFixed: n.commission.minAmount,
                        percent: n.commission.percent,
                        currencyCode,
                      })}
                    </span>
                  )}
                </div>
              </div>
              {generatingNetworkId === n.network ? <Loader /> : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectCryptoNetwork;
