import { FC, useEffect } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';

import { Button, ButtonProps } from 'components/ui/Button';
import { Image, ImageProps } from 'components/ui/Image';

import classes from './Confirmation.module.scss';

export interface ConfirmationProps {
  title: string;
  description: string;
  imageProps?: ImageProps;
  buttons: ButtonProps[];
}

const Confirmation: FC<ConfirmationProps> = ({ description, imageProps, buttons }) => {
  const drawer = useDrawer();

  useEffect(() => {
    if (buttons.some((i) => !i.onClick)) {
      drawer.pop({ key: 'confirmation', soft: false });
    }
    // eslint-disable-next-line
  }, [buttons]);

  return (
    <div className={classes.root}>
      <p className={classes.desc}>{description}</p>
      {imageProps && <Image {...imageProps} />}
      <div className="column gap-1">
        {buttons.map((b, i) => (
          <Button key={i} {...b} />
        ))}
      </div>
    </div>
  );
};

export default Confirmation;
