// const handleClickOutside = (event: MouseEvent) => {
import { isReactNative } from 'modules/reactNative/utils';
//   const widget = document.getElementById('zohohc-asap-web-bodylayout');
//
//   if (window.ZohoHCAsap && widget && event.target && !widget.contains(event.target as Node)) {
//     window.ZohoHCAsap.Action('close');
//     document.removeEventListener('click', handleClickOutside);
//   }
// };
//
// const closeOnClickOutSide = () => {
//   // timeout for prevent instant handle click outside
//   setTimeout(() => {
//     document.addEventListener('click', handleClickOutside);
//   }, 1);
// };
import { Profile } from 'modules/user/types';

import analytic from 'libs/analytic';
import { AnalyticEvents } from 'libs/analytic/events';

export const openContactSupport = () => {
  try {
    if (window.ZohoHCAsap) {
      window.ZohoHCAsap.Actions.OpenTab('TICKETS');
      // closeOnClickOutSide();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ZOHO SUPPORT ERROR', e);
  }
};

export const openHelpCenter = () => {
  try {
    if (window.ZohoHCAsap) {
      window.ZohoHCAsap.Action('open');
      // closeOnClickOutSide();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ZOHO SUPPORT ERROR', e);
  }
};
export const openChatSupport = () => {
  try {
    if (window.ZohoHCAsap) {
      window.ZohoHCAsap.Action('open', { tab: 'ZOHOCHAT' });

      analytic.sendEvent(AnalyticEvents.tempLog, {
        module: 'Zoho chat',
        log: 'Chan opened',
      });

      // closeOnClickOutSide();
    } else {
      analytic.sendEvent(AnalyticEvents.tempLog, {
        module: 'Zoho chat',
        log: 'window.ZohoHCAsap is undefined',
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ZOHO SUPPORT ERROR', e);
    analytic.sendEvent(AnalyticEvents.tempLog, {
      module: 'Zoho chat',
      log: 'Zoho chat error: ',
      // @ts-ignore
      error: e?.message || '-',
    });
  }
};

export const initZohoChatUser = (user: Profile) => {
  try {
    if (window.$zoho) {
      window.$zoho.salesiq.visitor.name(`${user.firstName} ${user.lastName}`);
      window.$zoho.salesiq.visitor.email(user.email);
      window.$zoho.salesiq.visitor.id(user.id);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ZOHO CHAT INIT USER ERROR', e);
  }
};

export const resetZohoChat = () => {
  try {
    if (window.$zoho) {
      window.$zoho.salesiq.reset();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ZOHO CHAT RESET ERROR', e);
  }
};

export const manualLoadZohoScriptForReactNative = () => {
  if (isReactNative) {
    const script = document.createElement('script');
    script.src =
      'https://static.zohocdn.com/helpcenter/asapweb/zohohcasap-efc-sdk-v1.0.dc8090f028cfee03616d.js';
    script.type = 'text/javascript';
    script.async = true;
    document.head.appendChild(script);
  }
};

// export const checkZohoScript = () => {
//   // Function to attach error event listener to script elements
//   function attachScriptErrorListener(script: HTMLScriptElement) {
//     if (script.src.includes('zoho')) {
//       script.addEventListener('error', function (event) {
//         analytic.sendEvent(AnalyticEvents.tempLog, {
//           module: 'Zoho chat',
//           log: 'Script loading error',
//           src: script.src,
//           event: event,
//           message: event.message,
//         });
//       });
//       script.addEventListener('load', function (event) {
//         analytic.sendEvent(AnalyticEvents.tempLog, {
//           module: 'Zoho chat',
//           log: 'Script loaded',
//           src: script.src,
//           event: event,
//         });
//       });
//     }
//   }
//
//   // Attach error listeners to existing script elements
//   document.querySelectorAll('script').forEach(attachScriptErrorListener);
//
//   // Use MutationObserver to monitor addition of new script elements
//   const observer = new MutationObserver(function (mutationsList) {
//     for (let mutation of mutationsList) {
//       if (mutation.type === 'childList') {
//         mutation.addedNodes.forEach((node) => {
//           if (node.nodeName === 'SCRIPT') {
//             attachScriptErrorListener(node as HTMLScriptElement);
//           }
//         });
//       }
//     }
//   });
//
//   // Start observing the document body for added script elements
//   observer.observe(document.body, {
//     childList: true,
//     subtree: true,
//   });
// };
