import clsx from 'clsx';

import { FC } from 'react';

import { IBAN_ACCOUNT_PRICE_PER_MONTH } from 'modules/digitalAccount/constants/config';
import { DigitalSubscription } from 'modules/digitalAccount/types';

import { useTranslation } from 'libs/i18n';

import { formatCurrency, getCurrencySymbolByCode } from 'utils/currency';

import classes from './SubscriptionPriceCard.module.scss';

interface SubscriptionPriceCardProps {
  subscription: DigitalSubscription;
  className?: string;
  variant?: 'white' | 'blurred';
}

export const SubscriptionPriceCard: FC<SubscriptionPriceCardProps> = ({
  className,
  variant = 'white',
}) => {
  const translate = useTranslation();

  return (
    <div className={clsx(classes.root, className, classes['variant-' + variant])}>
      <span>
        {translate('PRICE_PER_MONTH', {
          currency: getCurrencySymbolByCode('EUR'),
          amount: formatCurrency(IBAN_ACCOUNT_PRICE_PER_MONTH, true),
        })}
      </span>
      <p dangerouslySetInnerHTML={{ __html: translate('IBAN_ACCOUNT_CREATING_TITLE') }} />
    </div>
  );
};
