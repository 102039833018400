import { digitalAccountActions } from '../store';
import { accountsActions } from 'modules/accounts/store';
import { requestDigitalWallets } from 'modules/accounts/store/thunks';
import { Transaction } from 'modules/accounts/types';
import { generateRequestDataThunk, generateRequestThunk } from 'store/generators/thunks';

import digitalAccountServices from '../services';
import { AllowedCountries, GetTransactionsHistoryParams, IbanAccountProvider } from '../types';
import { DigitalSubscription } from '../types';

// cli-import

export const requestDigitalAccountSubscriptions = generateRequestDataThunk<DigitalSubscription[]>({
  updateState: digitalAccountActions.updateSubscriptions,
  request: digitalAccountServices.getSubscriptions,
  options: { showErrorToast: false },
});
export const requestCreateSubscription = generateRequestThunk({
  request: digitalAccountServices.createSubscription,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestDigitalAccountSubscriptions());
      await dispatch(requestDigitalWallets());
    },
  },
});
export const requestCancelSubscription = generateRequestThunk({
  request: digitalAccountServices.cancelSubscription,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestDigitalAccountSubscriptions());
    },
  },
});
export const requestLastTransactions = generateRequestDataThunk<Transaction[]>({
  updateState: digitalAccountActions.updateLastTransactions,
  request: async () => {
    const transactions = await digitalAccountServices.getTransactionsHistory({
      pageLimit: 3,
      pageNumber: 0,
    });

    // Bug with two data source on BE
    if (transactions.length >= 3) {
      return transactions.filter((_, index) => index <= 2);
    } else {
      const additionalTransactions = await digitalAccountServices.getTransactionsHistory({
        pageLimit: 3,
        pageNumber: 1,
      });

      return [...transactions, ...additionalTransactions].filter((_, index) => index <= 2);
    }
  },
  options: { showErrorToast: false },
});
export const requestDigitalAccountTransactionsHistory = generateRequestThunk<
  GetTransactionsHistoryParams,
  Transaction[]
>({
  request: digitalAccountServices.getTransactionsHistory,
  options: { showErrorToast: false },
});

export const requestSwitchDefaultIban = generateRequestThunk({
  request: digitalAccountServices.switchDefaultIban,
  options: {
    onSuccess: (dispatch, data) => {
      dispatch(accountsActions.updateDigitalWallets({ data }));
    },
  },
});

export const requestIbanAccountCreatingReferenceCode = generateRequestThunk({
  request: digitalAccountServices.getIbanAccountCreatingReferenceCode,
});

export const requestCreatingIbanAccountStatus = generateRequestThunk({
  request: digitalAccountServices.getCreatingIbanAccountStatus,
});

export const requestAllowedCountries = generateRequestDataThunk<AllowedCountries>({
  updateState: digitalAccountActions.updateAllowedCountries,
  request: digitalAccountServices.getAllowedCountries,
});

export const requestAllowedProviders = generateRequestDataThunk<IbanAccountProvider[]>({
  updateState: digitalAccountActions.updateAllowedProviders,
  request: digitalAccountServices.getAllowedProviders,
});
// cli-thunk
