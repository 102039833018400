import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollViewSelector } from 'constants/viewConstants';

const useScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    document.querySelector(scrollViewSelector)?.scrollTo(0, 0);
  }, [pathname]);
};

export default useScrollToTop;
