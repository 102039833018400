import clsx from 'clsx';
import { format } from 'date-fns';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import { selectAggregatedRatesHistory } from 'modules/exchange/store/selectors';
import { requestRatesHistoryAggregated } from 'modules/exchange/store/thunks';
import { GetRatesHistoryAggregatedTimeUnit } from 'modules/exchange/types';
import { SmartTrade } from 'modules/smartTrader/types';
import { SmartTraderSummaryCard } from 'modules/smartTrader/views/components/SmartTraderSummaryCard';
import { useDispatch } from 'store';

import { Button, Loader } from 'components/ui';

import useFlag from 'hooks/useFlag';
import usePrevious from 'hooks/usePrevious';

import { getTranslation, useTranslation } from 'libs/i18n';

import { formatCurrency, formatCurrencyWithLabel } from 'utils/currency';

import { CurrenciesDirection } from 'types';

import { getThemeColor } from 'styles';

import classes from './MainCard.module.scss';

const timeunits: GetRatesHistoryAggregatedTimeUnit[] = ['1M', '1w', '1d', '12h', '4h', '1h'];

const formatsByTimeUnit: {
  [key in GetRatesHistoryAggregatedTimeUnit]: string;
} = {
  '1h': 'HH aaa',
  '4h': 'EEEEE',
  '12h': 'dd MMM',
  '1d': 'dd MMM',
  '1w': 'dd MMM',
  '1M': 'dd MMM',
};

export interface DynamicChartProps {
  trade: SmartTrade;
}

const tick = {
  fontFamily: 'SegoeUI',
  fontSize: 16,
  color: getThemeColor('black40'),
  fontWeight: 600,
};

const FIVE_MINUTE_IN_MS = 5 * 1000 * 60;

const DynamicChart: FC<DynamicChartProps> = ({ trade }) => {
  const dispatch = useDispatch();

  const [timeunit, setTimeUnit] = useState<GetRatesHistoryAggregatedTimeUnit>(
    timeunits[timeunits.length - 1],
  );

  const aggregatedRatesHistories = useSelector(selectAggregatedRatesHistory);

  const currentHistory = useMemo(
    () =>
      aggregatedRatesHistories[
        (trade.primaryCurrency + '/' + trade.secondaryCurrency) as CurrenciesDirection
      ]?.[timeunit],
    [timeunit, aggregatedRatesHistories, trade],
  );

  const prevHistory = usePrevious(currentHistory, { valueAlwaysTruly: true });

  const loading = useFlag(false);

  const timeunitsLoadDates = useRef<{ [key in GetRatesHistoryAggregatedTimeUnit]?: number }>({});

  useEffect(() => {
    if (
      !currentHistory?.length ||
      !timeunitsLoadDates.current[timeunit] ||
      (!!timeunitsLoadDates.current[timeunit] &&
        Date.now() - timeunitsLoadDates.current[timeunit]! > FIVE_MINUTE_IN_MS)
    ) {
      (async () => {
        loading.on();
        const { success } = await dispatch(
          requestRatesHistoryAggregated({
            from: trade.primaryCurrency,
            to: trade.secondaryCurrency,
            unit: timeunit,
          }),
        );

        timeunitsLoadDates.current = { ...timeunitsLoadDates.current, [timeunit]: Date.now() };

        if (!success) {
          setTimeUnit(timeunits[timeunits.length - 1]);
        }

        loading.off();
      })();
    }
    // eslint-disable-next-line
  }, [timeunit]);

  const TooltipContent = useCallback(
    (props: TooltipProps<string, string>) => {
      const rate = props.payload?.[0]?.value;
      const timestamp = props.label * 1000;
      return timestamp && rate ? (
        <div className={classes.tooltip}>
          <span>
            {getTranslation('DATE')}: {format(timestamp, 'dd.MM.yyyy, hh:mm')}
          </span>
          <span>
            {getTranslation('RATE')}: {formatCurrencyWithLabel(rate, trade.secondaryCurrency)}
          </span>
        </div>
      ) : null;
    },
    [trade.secondaryCurrency],
  );

  return (
    <div className="column gap-2">
      <div className="row gap-2 jcfe">
        {timeunits.map((unit) => (
          <div
            key={unit}
            onClick={() => setTimeUnit(unit)}
            className={clsx(classes.timeunitButton, unit === timeunit && classes.active)}
          >
            <span>{unit}</span>
          </div>
        ))}
      </div>
      <div className="relative">
        {currentHistory?.length || prevHistory?.length ? (
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart data={currentHistory || prevHistory} margin={{ right: 28 }}>
              <defs>
                <linearGradient id="areaColor" x1="0" y1="1" x2="0" y2="0">
                  <stop offset="0%" stopColor="#FEFCF9" stopOpacity={1} />
                  <stop offset="100%" stopColor="#EAE8E1" stopOpacity={1} />
                </linearGradient>
              </defs>
              <XAxis
                minTickGap={50}
                dataKey="timestamp"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(ts) => format(ts * 1000, formatsByTimeUnit[timeunit])}
                tick={tick}
              />
              <YAxis
                orientation="right"
                dataKey="value"
                domain={['dataMin', 'dataMax']}
                tickLine={false}
                axisLine={false}
                tickFormatter={(rate) => formatCurrency(rate, true, { withZeros: true })}
                tick={tick}
                tickCount={8}
              />
              <CartesianGrid horizontal vertical={false} stroke={getThemeColor('westar')} />
              <Tooltip content={TooltipContent} />
              <Area
                type="linear"
                dataKey="value"
                stroke="#ABA187"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#areaColor)"
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : null}
        {loading.state && (
          <div className={classes.loaderWrapper}>
            <Loader centered />
          </div>
        )}
      </div>
    </div>
  );
};

interface MainCardProps {
  trade: SmartTrade;
}
export const MainCard: FC<MainCardProps> = ({ trade }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const handleBuy = useCallback(() => {
    drawer.open(
      exchangeDrawerTemplates.exchange({
        from: { currency: trade.secondaryCurrency, amount: '' },
        to: { currency: trade.primaryCurrency, amount: '' },
      }),
    );
  }, [drawer, trade]);

  const handleSell = useCallback(() => {
    drawer.open(
      exchangeDrawerTemplates.exchange({
        to: { currency: trade.secondaryCurrency, amount: '' },
        from: { currency: trade.primaryCurrency, amount: '' },
      }),
    );
  }, [drawer, trade]);
  return (
    <div className="column">
      <DynamicChart trade={trade} />
      <div className="row gap-1 mt-3 mb-4">
        <Button onClick={handleBuy} variant="blackWhite" fullWidth>
          {translate('BUY')}
        </Button>
        <Button fullWidth onClick={handleSell}>
          {translate('SELL')}
        </Button>
      </div>
      <SmartTraderSummaryCard trade={trade} />
      {/*TODO: Wait for design*/}
      {/*<Button mt={4}>{translate('SMART_TRADER_CHANGE_SPREAD')}</Button>*/}
    </div>
  );
};
