import { FC } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';

import { AmountLabel, Button, CurrencyIcon } from 'components/ui';

import useTablet from 'hooks/useTablet';

import { getTranslation, useTranslation } from 'libs/i18n';

import { CurrencyCode } from 'types';

import classes from './StakingSuccessOperation.module.scss';

export interface StakingSuccessOperationProps {
  currencyCode: CurrencyCode;
  amount: number;
  percentRPY: number;
  title: string;
  subtitle: string;
}

const StakingSuccessOperation: FC<StakingSuccessOperationProps> = ({
  currencyCode,
  amount,
  percentRPY,
  title,
  subtitle,
}) => {
  const translate = useTranslation();
  const isTablet = useTablet();
  const sidebar = useSideBar();
  return (
    <div className="mt-4 flex-1 column jcsb">
      <div>
        <div className="column aic">
          <CurrencyIcon code={currencyCode} size={86} active />
          <AmountLabel
            size="lg"
            className="mt-1"
            amount={amount}
            currencyCode={currencyCode}
            showCurrencyLabel
          />
          <span className={classes.percent}>
            {percentRPY}% {getTranslation('STAKING_RPY')}
          </span>
        </div>
        <div className={classes.card}>
          <h3>{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: subtitle }} />
        </div>
      </div>
      {isTablet && (
        <Button className="mt-4" fullWidth variant="lightGreen" onClick={sidebar.close}>
          {translate('CLOSE')}
        </Button>
      )}
    </div>
  );
};

export default StakingSuccessOperation;
