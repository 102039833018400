import { FC } from 'react';

import { Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './BankCardOrder.module.scss';

interface BankCardOrderProps {
  isVirtual: boolean;
}

export const BankCardOrder: FC<BankCardOrderProps> = ({ isVirtual }) => {
  const translate = useTranslation();

  return (
    <div className="outlinedCard column gap-3">
      <span className="label">{translate('YOUR_ORDER')}</span>
      <div className="row aic gap-2 jcsb">
        <div className="column gap-0-5 flex-shrink-0">
          <span className={classes.title}>{translate('NEBEUS_CRYPTO_CARD')}</span>
          <span className={classes.label}>
            {translate(isVirtual ? 'BANK_CARD_VIRTUAL_WORD' : 'BANK_CARD_PHYSICAL_WORD')}
          </span>
        </div>
        <Image
          className={classes.img}
          name={isVirtual ? 'cryptoVirtualBankCard' : 'cryptoBankCard'}
          path="cryptoBankCard"
        />
      </div>
    </div>
  );
};
