import { FC, useCallback } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { SmartTraderTitles } from 'modules/smartTrader/views/components/SmartTraderTitles';

import { AgreementText } from 'components/common';
import { Button, Image } from 'components/ui';

import useNotificationsPermissionStatus from 'hooks/useNotificationsPermissionStatus';
import useVerificationCheck from 'hooks/useVerificationCheck';

import Firebase from 'libs/firebase';
import { useTranslation } from 'libs/i18n';
import { errorToast } from 'libs/toast';

import { CurrencyCode } from 'types';

import classes from './SmartTraderWelcome.module.scss';

export interface SmartTraderWelcomeProps {
  currencyCode: CurrencyCode;
}

const SmartTraderWelcome: FC<SmartTraderWelcomeProps> = ({ currencyCode }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const pushNotificationAllowed = useNotificationsPermissionStatus();

  const checkVerification = useVerificationCheck();

  const handleContinue = useCallback(async () => {
    if (!pushNotificationAllowed) {
      const allowed = await Firebase.requestNotificationPermission();
      if (!allowed) {
        errorToast(translate('ERROR_NOTIFICATION_DISALLOWED'));
        return;
      }
    }

    if (!checkVerification()) {
      return;
    }

    sidebar.replace(...sidebarTemplates.smartTraderPickAssets({ currencyCode }));
  }, [checkVerification, currencyCode, translate, pushNotificationAllowed, sidebar]);

  return (
    <>
      <div>
        <SmartTraderTitles
          currencyCode={currencyCode}
          title={translate('SMART_TRADER_WELCOME')}
          subtitle={translate('SMART_TRADER_DESCRIPTION')}
        />
        <span className={classes.subtitle}>{translate('SMART_TRADER_WELCOME_SUBTITLE')}</span>
      </div>
      <Image className="my-2" name="smartTraderChart" path="smartTrader" />

      <div className="column gap-1-5">
        <Button variant="gold" onClick={handleContinue} fullWidth>
          {translate(pushNotificationAllowed ? 'CONTINUE' : 'ALLOW_PUSH_NOTIFICATIONS')}
        </Button>
        <AgreementText light />
      </div>
    </>
  );
};

export default SmartTraderWelcome;
