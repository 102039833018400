import { FC, useCallback } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import useDrawer from 'modules/app/hooks/useDrawer';
import useSideBar from 'modules/app/hooks/useSideBar';
import { requestDisableTFA, requestVerifyTFA } from 'modules/auth/store/thunks';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import { useDispatch } from 'store';

import { SubmitButton, TextInputField } from 'components/form';
import { Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import { parseNumbers } from 'utils/inputParsers';

import { validate } from './validate';

export type CheckTFACodeProps =
  | {
      variant: 'setup';
      resetCode: string;
    }
  | { variant: 'disable'; resetCode?: never };

interface FormValues {
  code: string;
}
const initialValues: FormValues = {
  code: '',
};

interface CheckTFACodeFormProps extends FormRenderProps<FormValues> {}

const CheckTFACodeForm: FC<CheckTFACodeFormProps> = ({ handleSubmit }) => {
  const translate = useTranslation();
  const sideBar = useSideBar();
  return (
    <form onSubmit={handleSubmit} className="column gap-3">
      <Field
        name="code"
        component={TextInputField}
        label={translate('TFA_ENTER_CODE_PLACEHOLDER')}
        parse={parseNumbers}
        maxLength={6}
        autoComplete="one-time-code"
      />
      <div className="row mt34">
        <Button variant="darkGreenOutlined" className="mr-1-5" fullWidth onClick={sideBar.pop}>
          {translate('CANCEL')}
        </Button>
        <SubmitButton fullWidth>{translate('NEXT')}</SubmitButton>
      </div>
    </form>
  );
};
const CheckTFACode: FC<CheckTFACodeProps> = ({ variant, resetCode }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const drawer = useDrawer();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (variant === 'setup') {
        const { success } = await dispatch(requestVerifyTFA({ verificationCode: values.code }));
        if (success) {
          successToast(translate('TFA_SETUP_SUCCESS'));
          drawer.replace(userDrawerTemplates.emergencyResetTFACode({ resetCode }));
        }
      } else if (variant === 'disable') {
        const { success } = await dispatch(requestDisableTFA({ verificationCode: values.code }));
        if (success) {
          successToast(translate('TFA_DISABLE_SUCCESS'));
          drawer.pop();
        }
      }
    },
    [drawer, resetCode, translate, variant, dispatch],
  );

  return (
    <div className="column gap-3">
      <p className="darkGrey">
        {translate(
          variant === 'setup' ? 'TFA_SETUP_CONFIRM_CODE_SUBTITLE' : 'TFA_DISABLE_SUBTITLE',
        )}
      </p>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={CheckTFACodeForm}
        validate={validate}
        resetCode={resetCode}
      />
    </div>
  );
};

export default CheckTFACode;
