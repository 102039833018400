import { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import useDrawer from 'modules/app/hooks/useDrawer';
import { getAddressAdditionLabelByCurrency } from 'modules/payment/constants/cryptoCurrenciesDetails';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import useAllowedCryptoNetworksByCurrencyCode from 'modules/payment/hooks/useAllowedCryptoNetworksByCurrencyCode';
import useParseCryptoAddress from 'modules/payment/hooks/useParseCryptoAddress';
import { CryptoNetworkId, CurrencyNetwork } from 'modules/payment/types';
import { validateCryptoWalletAddress } from 'modules/payment/utils';
import reactNativeServices from 'modules/reactNative/services';
import { isReactNativeV2 } from 'modules/reactNative/utils';

import { SimpleCurrencyPickerInputField, TextInputField } from 'components/form';
import { Icon } from 'components/ui';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup from 'libs/yup';

import { cryptoCurrencies } from 'utils/currency';

import { CryptoCurrencyCode } from 'types';

import classes from './CryptoWalletForm.module.scss';

interface CryptoWalletFormProps {
  withCurrencyPicker?: boolean;
  networkSelectingAllowed?: boolean;
}

export interface CryptoWalletFormValues {
  walletAddress: string;
  walletAddressAddition: string;
  walletNetwork: CryptoNetworkId | null;
  currencyCode: CryptoCurrencyCode | null;
}

export const cryptoWalletFormInitialValues: CryptoWalletFormValues = {
  walletAddress: '',
  walletAddressAddition: '',
  walletNetwork: null,
  currencyCode: null,
};

export const getCryptoWalletFormValidationSchema = (values: CryptoWalletFormValues) =>
  yup.object().shape({
    currencyCode: yup.string().requiredDefault(),
    walletAddress: yup
      .string()
      .test({
        name: 'isCryptoAddress',
        message: getTranslation('PAYMENT_WALLET_ADDRESS_INVALID', {
          currencyCode: values.currencyCode || 'BTC',
        }),
        test: (value: string | undefined) =>
          value && values.walletNetwork && values.currencyCode
            ? validateCryptoWalletAddress(value, values.currencyCode, values.walletNetwork)
            : true,
      })
      .withoutSpaces()
      .requiredDefault(),
    walletAddressAddition:
      values.currencyCode && getAddressAdditionLabelByCurrency(values.currencyCode)
        ? yup.string().withoutSpaces().requiredDefault()
        : yup.string().optional(),
    walletNetwork: yup.string().required(getTranslation('VALIDATION_REQUIRED')),
  });

export const CryptoWalletForm: FC<CryptoWalletFormProps> = ({
  withCurrencyPicker,
  networkSelectingAllowed: networkSelectingAllowedFromProps = true,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const { change, batch } = useForm<CryptoWalletFormValues>();
  const { values } = useFormState<CryptoWalletFormValues>();

  const walletAddressAdditionName = useMemo(() => {
    if (values.currencyCode) {
      return getAddressAdditionLabelByCurrency(values.currencyCode);
    }
    return null;
  }, [values.currencyCode]);

  const { allowedNetworks } = useAllowedCryptoNetworksByCurrencyCode(values.currencyCode || 'BTC', {
    isDeposit: false,
  });

  const defaultNetwork = useMemo(
    () => allowedNetworks.find((n) => n.defaultNetwork),
    [allowedNetworks],
  );
  useEffect(() => {
    // Currency code need for valid network;
    if (defaultNetwork && values.currencyCode && !values.walletNetwork) {
      change('walletNetwork', defaultNetwork.network);
    }
    // eslint-disable-next-line
  }, [defaultNetwork, values.currencyCode, values.walletNetwork]);

  const parseCryptoAddress = useParseCryptoAddress();

  const openScanner = useCallback(async () => {
    const result = await reactNativeServices.scanQrCode();
    if (result) {
      const { address, metaParam } = parseCryptoAddress(result);
      if (!address) {
        return;
      }
      batch(() => {
        change('walletAddress', address);

        if (metaParam) {
          change('walletAddressAddition', metaParam);
        }
      });
    }
  }, [batch, change, parseCryptoAddress]);

  const endAdornment = useMemo(() => {
    return values.walletAddress ? null : isReactNativeV2 ? (
      <Icon onClick={openScanner} name="camera" size={16} className="mr-2" />
    ) : null;
  }, [values.walletAddress, openScanner]);

  const topRightComponent = useMemo(() => {
    if (values.walletAddress?.length && isReactNativeV2) {
      return (
        <div className="row gap-0-5" onClick={openScanner}>
          <span className={classes.scanLabel}>{translate('SCAN')}</span>
          <Icon name="camera" size={16} color="cyanBlue" />
        </div>
      );
    }
  }, [openScanner, values.walletAddress, translate]);

  const handleNetworkFieldClick = useCallback(() => {
    if (!values.currencyCode) {
      return;
    }
    drawer.open(
      paymentDrawerTemplates.selectCryptoNetwork({
        currencyCode: values.currencyCode,
        onSelect: (network: CurrencyNetwork) => {
          change('walletNetwork', network.network);
        },
        isDeposit: false,
      }),
    );
  }, [drawer, change, values.currencyCode]);

  const walletNetworkLabelAccessor = useCallback(
    (id: CryptoNetworkId) => allowedNetworks.find((n) => n.network === id)?.networkName || '',
    [allowedNetworks],
  );

  const networkPickerAllowed = networkSelectingAllowedFromProps && allowedNetworks.length > 1;

  return (
    <>
      {withCurrencyPicker && (
        <Field
          name="currencyCode"
          component={SimpleCurrencyPickerInputField}
          currenciesList={cryptoCurrencies}
          placeholder={translate('PAYMENT_WALLET_CURRENCY')}
        />
      )}
      {networkPickerAllowed ? (
        <Field
          name="walletNetwork"
          placeholder={getTranslation('SELECT_NETWORK')}
          component={TextInputField}
          onInputContainerClick={handleNetworkFieldClick}
          endAdornment={
            <Icon className="mr-1-5 ml-1-5" size="sm" name="triangleDownLg" color="kindaBlack" />
          }
          inputContainerClassName="pointer"
          valueParser={walletNetworkLabelAccessor}
          disabled
        />
      ) : null}
      <Field
        name="walletAddress"
        placeholder={getTranslation('PAYMENT_WALLET_ADDRESS')}
        component={TextInputField}
        endAdornment={endAdornment}
        topRightComponent={topRightComponent}
      />
      {walletAddressAdditionName ? (
        <Field
          name="walletAddressAddition"
          placeholder={walletAddressAdditionName}
          component={TextInputField}
        />
      ) : null}
    </>
  );
};
