import { accountsActions } from 'modules/accounts/store';
import { appActions } from 'modules/app/store';
import authServices from 'modules/auth/services';
import { authActions } from 'modules/auth/store/index';
import { cryptoBankCardActions } from 'modules/cryptoBankCard/store';
import { cryptoRentingActions } from 'modules/cryptoRenting/store';
import { digitalAccountActions } from 'modules/digitalAccount/store';
import { exchangeActions } from 'modules/exchange/store';
import { invoicingActions } from 'modules/invoicing/store';
import { loansActions } from 'modules/loans/store';
import { paymentActions } from 'modules/payment/store';
import { phonebookActions } from 'modules/phonebook/store';
import { isReactNativeSupportBioVerification, sendToReactNative } from 'modules/reactNative/utils';
import { smartTraderActions } from 'modules/smartTrader/store';
import { stakingActions } from 'modules/staking/store';
import { userActions } from 'modules/user/store';
import { vaultActions } from 'modules/vault/store';
import { AppDispatch } from 'store';

import analytic from 'libs/analytic';
import Firebase from 'libs/firebase';

import { resetZohoChat } from 'utils/thirdPartyLibs';

export const logout = () => async (dispatch: AppDispatch) => {
  await Firebase.removeFCMTokenFromExternalServices();
  if (isReactNativeSupportBioVerification) {
    sendToReactNative('FORCE_RESET_BIO_VERIFICATION');
  }
  dispatch(authActions.resetState());
  analytic.resetUser();
  dispatch(appActions.resetState());
  dispatch(userActions.resetState());
  dispatch(accountsActions.resetState());
  dispatch(cryptoRentingActions.resetState());
  dispatch(paymentActions.resetState());
  dispatch(stakingActions.resetState());
  dispatch(digitalAccountActions.resetState());
  dispatch(smartTraderActions.resetState());
  dispatch(loansActions.resetState());
  dispatch(vaultActions.resetState());
  dispatch(cryptoBankCardActions.resetState());
  dispatch(invoicingActions.resetState());
  dispatch(exchangeActions.resetState());
  dispatch(phonebookActions.resetState());

  authServices.clearAuthTokens();

  resetZohoChat();
};

// File created for avoiding circular dependency in axios instance
