import clsx from 'clsx';

import { FC, Fragment, useMemo } from 'react';

import { isReactNative } from 'modules/reactNative/utils';

import analytic from 'libs/analytic';
import { AnalyticEvents } from 'libs/analytic/events';
import { TranslationKey, useTranslation } from 'libs/i18n';

import {
  LINK_RENTING_AND_STAKING_AGREEMENT,
  LINK_TERMS_OF_BUSINESS,
  LINK_TERMS_OF_USE,
  LINK_TERM_OF_USE_CRYPTO_BANK_CARD,
  openExternalLink,
} from 'utils/links';

import classes from './AgreementText.module.scss';

interface AgreementTextProps {
  variant?:
    | 'signIn'
    | 'signUp'
    | 'modulR'
    | 'proceeding'
    | 'renting-and-staking'
    | 'cryptoBankCard';
  className?: string;
  light?: boolean;
}

export const AgreementText: FC<AgreementTextProps> = ({
  variant = 'proceeding',
  className,
  light,
}) => {
  const translate = useTranslation();

  const firstPart = useMemo<TranslationKey>(() => {
    switch (variant) {
      case 'signUp': {
        return 'SIGN_AGREEMENT_P1';
      }
      case 'signIn': {
        return 'SIGN_IN_AGREEMENT_P1';
      }

      default: {
        return 'PROCEEDING_AGREEMENT_P1';
      }
    }
  }, [variant]);

  const secondPart = useMemo<{ tKey: TranslationKey; url: string }[]>(() => {
    switch (variant) {
      case 'modulR': {
        return [
          { tKey: 'TERMS_OF_USE', url: LINK_TERMS_OF_USE },
          { tKey: 'TERMS_OF_BUSINESS', url: LINK_TERMS_OF_BUSINESS },
        ];
      }
      case 'renting-and-staking': {
        return [{ tKey: 'TERMS_OF_USE', url: LINK_RENTING_AND_STAKING_AGREEMENT }];
      }
      case 'cryptoBankCard': {
        return [
          { tKey: 'TERMS_OF_USE', url: LINK_TERMS_OF_USE },
          { tKey: 'FEE_AND_LIMITS', url: LINK_TERM_OF_USE_CRYPTO_BANK_CARD },
        ];
      }
      default: {
        return [{ tKey: 'TERMS_OF_USE', url: LINK_TERMS_OF_USE }];
      }
    }
  }, [variant]);

  return (
    <span className={clsx(classes.root, className, light && classes.light)}>
      {translate(firstPart)}
      {secondPart.map((part, index) => (
        <Fragment key={part.tKey}>
          {index !== 0 ? ` ${translate('AND')} ` : null}
          <a
            href={isReactNative ? undefined : part.url}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.url}
            onClick={() => {
              analytic.sendEvent(AnalyticEvents.pressedAgreementText, {
                text: translate(part.tKey),
                translationKey: part.tKey,
                componentVariant: variant,
              });
              if (isReactNative) {
                openExternalLink(part.url);
              }
            }}
          >
            {translate(part.tKey)}
          </a>
        </Fragment>
      ))}
    </span>
  );
};
