import { uniq } from 'lodash';

import { FC, FormEventHandler, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import classes from 'modules/exchange/views/ExchangePage/ExchangePage.module.scss';
import CurrencyRateCard from 'modules/exchange/views/components/CurrencyRateCard';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';

import { Icon, NoResultLabel, TextInput } from 'components/ui';

import usePersistedState from 'hooks/usePersistedState';
import useVerificationCheck from 'hooks/useVerificationCheck';

import { useTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode, voidFunc } from 'types';

interface CurrenciesCardProps {
  list: CurrencyCode[];
}

const ratesChartProps = { height: 40 };

interface StarIconProps {
  active: boolean;
  onClick: voidFunc;
}
const StarIcon: FC<StarIconProps> = ({ active, onClick }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      className={classes.startIconWrapper}
    >
      <Icon
        name={active ? 'starFilled' : 'star'}
        size={16}
        color={active ? 'green' : 'creamy200'}
      />
    </div>
  );
};
export const CurrenciesCard: FC<CurrenciesCardProps> = ({ list }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const [watchList, setWatchList] = usePersistedState<CurrencyCode[]>('watchList');

  const currencies = useMemo(
    () => list.map((code) => ({ code, label: getCurrencyLabelByCode(code) })),
    [list],
  );

  const defaultCurrency = useSelector(selectUserDefaultCurrencyCode);

  const [filterValue, setFilterValue] = useState('');

  const checkVerification = useVerificationCheck();

  const filteredCurrencyCodes = useMemo(() => {
    if (filterValue) {
      const filterValueUppercase = filterValue.toUpperCase();

      return currencies
        .filter(
          (c) =>
            c.code.includes(filterValueUppercase) ||
            c.label.toUpperCase().includes(filterValueUppercase),
        )
        .map((c) => c.code);
    }

    return currencies.map((i) => i.code);
  }, [currencies, filterValue]);

  const handleClickCard = useCallback(
    (currencyCode: CurrencyCode) => {
      if (checkVerification()) {
        drawer.open(
          exchangeDrawerTemplates.exchange({
            from: { amount: '', currency: currencyCode },
            to: { amount: '', currency: defaultCurrency },
          }),
        );
      }
    },
    [drawer, defaultCurrency, checkVerification],
  );

  const toggleWatchListCurrency = useCallback(
    (currency: CurrencyCode) => {
      const active = watchList?.includes(currency);

      if (active) {
        setWatchList(watchList?.filter((c) => c !== currency) || []);
      } else {
        setWatchList(uniq([...(watchList || []), currency]));
      }
    },
    [watchList, setWatchList],
  );

  const handleChange = useCallback<FormEventHandler<HTMLInputElement>>((e) => {
    setFilterValue(e.currentTarget.value);
  }, []);

  return (
    <div className="column gap-3">
      <TextInput
        startAdornment={<Icon name="search" size="sm" className="pl-1-5" color="darkGrey" />}
        value={filterValue}
        onChange={handleChange}
        placeholder={translate('PLACEHOLDER_SEARCH_CURRENCIES')}
      />
      <div className={classes.currencies}>
        {filteredCurrencyCodes.map((currency) => (
          <div
            key={currency}
            onClick={() => {
              handleClickCard(currency);
            }}
          >
            <CurrencyRateCard
              variant="bare"
              from={currency}
              to={defaultCurrency}
              ratesChartProps={ratesChartProps}
              reverseFiatAndStable={false}
              chartClassName={classes.chart}
              endAdornment={
                <StarIcon
                  active={!!watchList?.includes(currency)}
                  onClick={() => {
                    toggleWatchListCurrency(currency);
                  }}
                />
              }
            />
          </div>
        ))}
        {filterValue.length && !filteredCurrencyCodes.length ? (
          <NoResultLabel findString={filterValue} />
        ) : null}
      </div>
    </div>
  );
};
