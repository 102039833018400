import clsx from 'clsx';

import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectTradesReducer } from 'modules/smartTrader/store/selectors';
import { requestTrades } from 'modules/smartTrader/store/thunks';
import { SmartTrade } from 'modules/smartTrader/types';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { CurrencyIcon, Icon, Loader } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';
import { formatCurrencyWithSymbol, getCurrencyLabelByCode } from 'utils/currency';

import { voidFunc } from 'types';

import classes from './TradesList.module.scss';

const TradeItemCard = memo<{ trade: SmartTrade; onClick: voidFunc }>(({ trade, onClick }) => {
  return (
    <div className="creamyCard aic row jcsb pointer" onClick={onClick}>
      <div className="row gap-1-5 aic">
        <CurrencyIcon size={24} code={trade.primaryCurrency} />
        <div className="column gap-0-5">
          <span className={classes.primaryLabel}>
            {getCurrencyLabelByCode(trade.primaryCurrency)}
          </span>
          <span className="label">{trade.primaryCurrency}</span>
        </div>
      </div>
      <div className="row gap-2 aic">
        <div className="column gap-0-5">
          <span className={clsx(classes.primaryLabel, 'tar')}>
            {formatCurrencyWithSymbol(trade.primaryAmount, trade.primaryCurrency)}
          </span>
          <span className="label tar">
            {formatCurrencyWithSymbol(trade.secondaryAmount, trade.secondaryCurrency)}
          </span>
        </div>
        <div className={classes.arrowContainer}>
          <Icon name="arrowRight" color="white" size={14} />
        </div>
      </div>
    </div>
  );
});

export const TradesList = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const navigate = useNavigate();

  const tradesReducer = useSelector(selectTradesReducer);

  useEffect(() => {
    if (canRequest(tradesReducer.meta)) {
      dispatch(requestTrades());
    }
    // eslint-disable-next-line
  }, []);

  return tradesReducer.meta.loading && !tradesReducer.meta.loaded ? (
    <Loader centered />
  ) : tradesReducer.data.length ? (
    <div className="column gap-2">
      <span className="label">{translate('SMART_TRADER')}</span>
      <div className="column gap-1">
        {tradesReducer.data.map((item) => (
          <TradeItemCard
            key={item.id}
            trade={item}
            onClick={() => {
              navigate(routesByName.smartTradeDashboard(item.primaryCurrency));
            }}
          />
        ))}
      </div>
    </div>
  ) : null;
};
