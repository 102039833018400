import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { BankCardItem } from 'modules/app/views/TradingAccountDashboard/components/BankCards/components/BankCardItem';
import { useBankCardOrderBuilder } from 'modules/app/views/TradingAccountDashboard/components/BankCards/useBankCardOrderBuilder';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import { requestReadBankCardOrder } from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard, CryptoBankCardOrder } from 'modules/cryptoBankCard/types';
import { useDispatch } from 'store';

import { Icon, Skeleton } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

interface CardOrderProps {
  order: CryptoBankCardOrder;
}
const CardOrder: FC<CardOrderProps> = ({ order }) => {
  const dispatch = useDispatch();

  const { title, subtitle, activateButton, depositButton } = useBankCardOrderBuilder({
    order,
  });

  const hideOrder = useCallback(
    async (id: CryptoBankCardOrder['id']) => {
      await dispatch(requestReadBankCardOrder(id));
    },
    [dispatch],
  );

  const endAdornment =
    activateButton || depositButton || order.preOrderedStatus === 'failed' ? (
      <div className="row aic gap-1">
        {activateButton}
        {depositButton}
        {order.preOrderedStatus === 'failed' && (
          <Icon
            className="pointer"
            onClick={() => hideOrder(order.id)}
            name="cross"
            size={16}
            color="grey200"
          />
        )}
      </div>
    ) : null;

  return (
    <BankCardItem
      isCardVirtual={order.isVirtual}
      title={title}
      valueComponent={subtitle}
      endAdornment={endAdornment}
    />
  );
};

interface BankCardLogicReducerProps {
  card?: CryptoBankCard & {
    amount: number | null;
  };
  cardOrder?: CryptoBankCardOrder;
  showAdvertisement?: boolean;
}
export const BankCardLogicReducer: FC<BankCardLogicReducerProps> = ({
  card,
  cardOrder,
  showAdvertisement,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  if (card) {
    return (
      <BankCardItem
        isCardVirtual={card.isVirtual}
        cardLastDigits={card.lastDigits}
        valueComponent={
          card.currencyCode ? (
            card.amount === null ? (
              <Skeleton height={28} width={100} />
            ) : (
              formatCurrencyWithSymbol(card.amount || 0, card.currencyCode, {
                withZeros: true,
              })
            )
          ) : (
            '-'
          )
        }
        onClick={() => {
          drawer.open(
            commonDrawerTemplates.bankCards({
              type: card.type,
              props: { initialBankCardId: card.id },
            }),
          );
        }}
      />
    );
  }

  if (cardOrder) {
    return <CardOrder order={cardOrder} />;
  }

  if (showAdvertisement) {
    return (
      <BankCardItem
        valueComponent={translate('CRYPTO_BANK_CARD_AD_CARD_TITLE')}
        onClick={() => drawer.open(cryptoBankCardDrawerTemplates.orderBankCardChooseType())}
      />
    );
  }
  return null;
};
