import { FC, useCallback } from 'react';
import { Field, useForm } from 'react-final-form';

import { AllowedCountry } from 'modules/app/types';

import { CountryPickerInputField } from 'components/form/CountryPickerInputField';
import { TextInputField } from 'components/form/TextInputField';
import { AddressAutoComplete, Button } from 'components/ui';
import { Address } from 'components/ui/AddressAutoComplete';

import { Flag } from 'hooks/useFlag';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup from 'libs/yup';

export const addressFormInitialValues = {
  address1: '',
  address2: '',
  city: '',
  postCode: '',
  country: null,
};

// Field should only contain alphanumerics (at least one), hyphens (-), apostrophes ('), commas (,), dots (.), solidus (/) and spaces.
// eslint-disable-next-line no-useless-escape
const addressRegExp = new RegExp(/^[a-zA-Z0-9\-',.\/ ]+$/);

export const addressFormValidationSchema = yup.object().shape({
  country: yup
    .object()
    .required(getTranslation('VALIDATION_REQUIRED'))
    .typeError(getTranslation('VALIDATION_REQUIRED')),
  city: yup
    .string()
    .matches(addressRegExp, getTranslation('VALIDATION_IBAN_OUTCOME_ADDRESS'))
    .requiredDefault(),
  address1: yup
    .string()
    .matches(addressRegExp, getTranslation('VALIDATION_IBAN_OUTCOME_ADDRESS'))
    .requiredDefault(),
  address2: yup
    .string()
    .test({
      name: 'isValidAddressString',
      message: getTranslation('VALIDATION_IBAN_OUTCOME_ADDRESS'),
      test: (value: string | undefined) => !value || addressRegExp.test(value),
    })
    .optional(),
  postCode: yup.string().requiredDefault(),
});

interface AddressFormProps {
  fieldName: string;
  allowedCountries: AllowedCountry[];
  isManualMode: Flag;
}
export const AddressForm: FC<AddressFormProps> = ({
  fieldName,
  allowedCountries,
  isManualMode,
}) => {
  const translate = useTranslation();

  const form = useForm();

  const onAutoCompleteChange = useCallback(
    (address: Address) => {
      form.change(fieldName, address);
      isManualMode.on();
    },
    [form, fieldName, isManualMode],
  );

  return isManualMode.state ? (
    <div className="column gap-2">
      <Field
        name={`${fieldName}.address1`}
        placeholder={translate('CRYPTO_BANK_CARD_SHIPPING_ADDRESS_1')}
        component={TextInputField}
        autoComplete="address-line1"
      />
      <Field
        name={`${fieldName}.address2`}
        placeholder={translate('CRYPTO_BANK_CARD_SHIPPING_ADDRESS_2')}
        component={TextInputField}
        autoComplete="address-line2"
      />
      <div className="row gap-1-5">
        <Field
          name={`${fieldName}.city`}
          placeholder={translate('CITY')}
          component={TextInputField}
        />
        <Field
          name={`${fieldName}.postCode`}
          placeholder={translate('CRYPTO_BANK_CARD_SHIPPING_POST_CODE')}
          component={TextInputField}
          autoComplete="postal-code"
        />
      </div>
      <Field
        name={`${fieldName}.country`}
        component={CountryPickerInputField}
        allowedCountries={allowedCountries}
        placeholder={translate('COUNTRY')}
        autoComplete="country-name"
      />
    </div>
  ) : (
    <div className="column gap-1-5">
      <AddressAutoComplete onChange={onAutoCompleteChange} allowedCountries={allowedCountries} />
      <span className="m-0-auto">{translate('OR')}</span>
      <Button variant="creamyBlack" onClick={isManualMode.on}>
        {translate('ADDRESS_ENTER_MANUALLY')}
      </Button>
    </div>
  );
};
