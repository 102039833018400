import { FC, useCallback } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import {
  ExchangeSuccessCard,
  ExchangeSuccessCardProps,
} from 'modules/exchange/views/components/ExchangeSuccessCard';
import { SmartTraderTitles } from 'modules/smartTrader/views/components/SmartTraderTitles';

import { AgreementText } from 'components/common';
import { Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';

export interface SmartTraderExchangeSuccessProps extends ExchangeSuccessCardProps {}
const SmartTraderExchangeSuccess: FC<SmartTraderExchangeSuccessProps> = (props) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const handlePressSetup = useCallback(() => {
    sidebar.replace(
      ...sidebarTemplates.smartTraderSelectTradingPair({ currencyCode: props.to.currency }),
    );
  }, [sidebar, props.to.currency]);

  return (
    <>
      <div className="column gap-3">
        <SmartTraderTitles
          currencyCode={props.to.currency}
          title={translate('SUCCESS!')}
          subtitle={translate('SMART_TRADER_EXCHANGE_SUCCESS_SUBTITLE')}
        />
        <ExchangeSuccessCard isLight {...props} />
      </div>
      <div className="column gap-1-5">
        <Button onClick={handlePressSetup} variant="gold">
          {translate('SMART_TRADER_SETUP')}
        </Button>
        <AgreementText light />
      </div>
    </>
  );
};

export default SmartTraderExchangeSuccess;
