import { TranslationKey, getTranslation } from 'libs/i18n';

export enum ApiCodes {
  userExist = 'Sdk user exists',
  userNotFound = 'User not found',
  confirmationCodeInvalid = 'exception.confirmation_code.invalid',
  invalidLoginOrPass = 'exception.invalid_login_or_pass',
  internalServerError = 'internal server error',
  tfaVerificationFailed = 'two factor auth verification failed',
  otpCodeIsInvalid = 'Otp code is invalid',
  userNotVerified = 'user.notReviewed',
  failedToSendEmail = 'Failed to send email',
  walletNotFound = 'Wallet not found',
  userInactive = 'validator.user.inactive',
  userExist2 = 'exception.parameter.user_exists',
  otpCodeIsInvalidOrExpired = 'otp_code.validation.failure',
  notEnoughPermissions = 'not_enough_permissions',
}

const translationKeyByApiCode: { [key in ApiCodes]: TranslationKey } = {
  [ApiCodes.userExist]: 'ERROR_EMAIL_REGISTER_IMPOSSIBLE',
  [ApiCodes.userNotFound]: 'USER_NOT_FOUND',
  [ApiCodes.confirmationCodeInvalid]: 'ERROR_OTP_INVALID',
  [ApiCodes.invalidLoginOrPass]: 'ERROR_INVALID_CREDENTIALS',
  [ApiCodes.internalServerError]: 'ERROR_SOMETHING_WRONG_TRY_LATER_2',
  [ApiCodes.tfaVerificationFailed]: 'ERROR_TFA_CODE_INVALID',
  [ApiCodes.otpCodeIsInvalid]: 'ERROR_OTP_INVALID',
  [ApiCodes.userNotVerified]: 'ERROR_VERIFY_ACCOUNT',
  [ApiCodes.failedToSendEmail]: 'ERROR_CANT_SEND_OTP_TO_EMAIL',
  [ApiCodes.walletNotFound]: 'ERROR_WALLET_NOT_FOUND',
  [ApiCodes.userInactive]: 'ERROR_SOMETHING_WRONG_WITH_ACCOUNT',
  [ApiCodes.userExist2]: 'ERROR_EMAIL_REGISTER_IMPOSSIBLE',
  [ApiCodes.otpCodeIsInvalidOrExpired]: 'ERROR_OTP_CODE_VALIDATION_FAILURE',
  [ApiCodes.notEnoughPermissions]: 'ERROR_BLOCKED_USER',
};

export const translateApiCode = (code: ApiCodes) =>
  translationKeyByApiCode[code] ? getTranslation(translationKeyByApiCode[code]) : null;
