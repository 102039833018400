import { useEffect, useRef } from 'react';

const useLastPresentValue = <T>(value: T) => {
  const internalValue = useRef(value);

  useEffect(() => {
    if (value) {
      internalValue.current = value;
    }
  }, [value]);

  return internalValue.current;
};

export default useLastPresentValue;
