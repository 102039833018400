import { FC } from 'react';

import { useTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './SmartTraderTitles.module.scss';

export interface SmartTraderTitlesProps {
  title: string;
  subtitle?: string;
  currencyCode?: CurrencyCode;
}

export const SmartTraderTitles: FC<SmartTraderTitlesProps> = ({
  title,
  subtitle,
  currencyCode,
}) => {
  const translation = useTranslation();

  return (
    <div className={classes.root}>
      <span className={classes.topTitle}>
        <span>{currencyCode ? getCurrencyLabelByCode(currencyCode) : 'Nebeus'}</span>
        {translation('SMART_TRADER')}
      </span>
      <h5>{title}</h5>
      {subtitle && <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
    </div>
  );
};
