import clsx from 'clsx';

import { FC, useMemo, useState } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';

import { AgreementText } from 'components/common';
import { Button, Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

interface BlockProps {
  step: number;
  title: string;
  text: string;
  className?: string;
  active: boolean;
  toggle: voidFunc;
}
const Block: FC<BlockProps> = ({ step, text, title, className, active, toggle }) => {
  const translate = useTranslation();

  return (
    <div className={clsx(className, 'outlinedCard column gap-2')}>
      <div className="column gap-1">
        <div className="row gap-1">
          <h3 className="font-md">
            {step}. {title}
          </h3>
        </div>
        <p className="font-sm" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      <Button onClick={toggle} fullWidth variant={active ? 'greenWhite' : 'creamyBlack'}>
        <span>{translate(active ? 'CONFIRMED' : 'CONFIRM')}</span>

        {active && <Icon name="check" size={16} className="ml-1" />}
      </Button>
    </div>
  );
};

const CloseAccountCheckList = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const [activeSteps, setActiveSteps] = useState([false, false, false]);

  const blocks = useMemo(
    () => [
      {
        title: translate('CLOSE_ACCOUNT_CHECK_LIST_BLOCK_TITLE'),
        text: translate('CLOSE_ACCOUNT_CHECK_LIST_BLOCK_1_TEXT'),
      },
      {
        title: translate('CLOSE_ACCOUNT_CHECK_LIST_BLOCK_TITLE'),
        text: translate('CLOSE_ACCOUNT_CHECK_LIST_BLOCK_2_TEXT'),
      },
      {
        title: translate('CLOSE_ACCOUNT_CHECK_LIST_BLOCK_TITLE2'),
        text: translate('CLOSE_ACCOUNT_CHECK_LIST_BLOCK_3_TEXT'),
      },
    ],
    [translate],
  );
  return (
    <div className="column gap-3">
      <p className="darkGrey ">{translate('CLOSE_ACCOUNT_CHECK_LIST_SUBTITLE')}</p>

      <div className="column gap-2">
        {blocks.map((block, i) => (
          <Block
            key={i}
            step={i + 1}
            active={activeSteps[i]}
            toggle={() => {
              setActiveSteps((prev) => prev.map((v, index) => (i === index ? !v : v)));
            }}
            {...block}
          />
        ))}
      </div>

      <div className="column gap-1-5">
        <Button
          onClick={() => {
            drawer.replace(userDrawerTemplates.closeAccountFinal());
          }}
          disabled={activeSteps.includes(false)}
          fullWidth
          variant="darkGreyOutlined"
        >
          {translate('CONTINUE')}
        </Button>
        <AgreementText />
      </div>
    </div>
  );
};

export default CloseAccountCheckList;
