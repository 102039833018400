import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { StepHeader } from 'modules/auth/views/components/StepHeader';

import routesByName from 'constants/routesByName';

import BankCardFeaturesList from 'components/common/bankCards/BankCardFeaturesList';
import { Button, Image } from 'components/ui';

import analytic from 'libs/analytic';
import { AnalyticEvents } from 'libs/analytic/events';
import { useTranslation } from 'libs/i18n';
import useSwiperSlideSizeObserver from 'libs/swiper/useSwiperSlideSizeObserver';

import { voidFunc } from 'types';

import classes from './BankCardOffer.module.scss';

interface BankCardOfferProps {
  onSubmit: voidFunc;
}

const BankCardOffer: FC<BankCardOfferProps> = ({ onSubmit }) => {
  const translate = useTranslation();
  const navigate = useNavigate();

  const handleReject = useCallback(() => {
    analytic.sendEvent(AnalyticEvents.onboardingCardOfferReject);
    navigate(routesByName.dashboard, { state: { from: 'auth' }, replace: true });
  }, [navigate]);

  const handleSubmit = useCallback(() => {
    analytic.sendEvent(AnalyticEvents.onboardingCardOfferAcceptedAndMovedOn);
    onSubmit();
  }, [onSubmit]);

  const { setNode } = useSwiperSlideSizeObserver();

  return (
    <div ref={setNode} className="authStepRoot">
      <StepHeader title={translate('ONBOARDING_CARD_OFFER_TITLE')} />
      <Image className={classes.cardImg} name="cryptoBankCardGradient" path="cryptoBankCard" />
      <div className="column gap-2">
        <Button onClick={handleSubmit}>{translate('ONBOARDING_CARD_OFFER_SUBMIT_BUTTON')}</Button>
        <BankCardFeaturesList className="outlinedCard" />
        <Button onClick={handleReject} variant="creamyBlack">
          {translate('ONBOARDING_CARD_OFFER_REJECT_BUTTON')}
        </Button>
      </div>
    </div>
  );
};

export default BankCardOffer;
