import clsx from 'clsx';

import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectActiveAccountType } from 'modules/accounts/store/selectors';
import useBankCards from 'modules/app/hooks/useBankCards';
import useDrawer from 'modules/app/hooks/useDrawer';
import { selectSideBarStack } from 'modules/app/store/selectors';
import { SideBarStackItemKey } from 'modules/app/types';
import useShowRNBottomBarInSidebar from 'modules/app/views/AuthedApp/components/ReactNativeBottomBar/useShowRNBottomBarInSidebar';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import useIbanAccountPageOpening from 'modules/digitalAccount/hooks/useIbanAccountPageOpening';
import { selectIsDigitalAccountAllowed } from 'modules/digitalAccount/store/selectors';
import { isReactNative } from 'modules/reactNative/utils';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';

import routesByName from 'constants/routesByName';

import { Icon, Image } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { TranslationKey, useTranslation } from 'libs/i18n';

import eventEmitter from 'utils/eventEmitter';

import { voidFunc } from 'types';

import classes from './ReactNativeBottomBar.module.scss';

const ReactNativeBottomBar = () => {
  const translate = useTranslation();

  const drawer = useDrawer();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activeAccountType = useSelector(selectActiveAccountType);
  const sidebarStack = useSelector(selectSideBarStack);

  const sidebarOpenedKey = useMemo(() => {
    const lastDrawer = sidebarStack[sidebarStack.length - 1];
    if (lastDrawer?.active) {
      return lastDrawer.key;
    }
  }, [sidebarStack]);

  const sidebarOpened = useMemo(
    () => sidebarStack.filter((i) => i.active).length > 0,
    [sidebarStack],
  );

  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);

  const { onOpenIbanAccountPage } = useIbanAccountPageOpening();
  const { bankCardsViewAllowed } = useBankCards();

  const config = useMemo<
    {
      iconName: string;
      label?: TranslationKey;
      route?: string;
      handler?: voidFunc;
      mainButton?: boolean;
      sidebarKey?: SideBarStackItemKey;
    }[]
  >(
    () => [
      {
        iconName: 'dashboard',
        label: 'DASHBOARD',
        route: routesByName.dashboard,
      },
      {
        iconName: 'walletCircles',
        label: 'WALLETS',
        route: routesByName.accounts(activeAccountType),
      },
      {
        mainButton: true,
        iconName: 'nebeusIconLight',
        handler: () => {
          drawer.open(commonDrawerTemplates.appOperations());
        },
      },
      isDAAllowed
        ? {
            iconName: 'bank2',
            label: 'IBAN',
            route: routesByName.iban,
            handler: () => {
              drawer.close();
              onOpenIbanAccountPage();
            },
          }
        : {
            iconName: 'percent',
            label: 'LOANS',
            route: routesByName.loans(),
          },
      bankCardsViewAllowed
        ? {
            iconName: 'card2',
            label: 'CARDS',
            handler: () => {
              drawer.replaceAll(commonDrawerTemplates.bankCards());
            },
            sidebarKey: 'bankCards',
          }
        : {
            iconName: 'settings',
            label: 'SETTINGS',
            handler: () => {
              drawer.replaceAll(userDrawerTemplates.settings());
            },
            sidebarKey: 'settings',
          },
    ],
    [activeAccountType, bankCardsViewAllowed, drawer, onOpenIbanAccountPage, isDAAllowed],
  );

  const showBottomBarInsideSidebar = useShowRNBottomBarInSidebar();

  const activeIndex = useMemo(() => {
    const activeSidebarIndex = sidebarOpenedKey
      ? config.findIndex((i) => i.sidebarKey === sidebarOpenedKey)
      : -1;
    if (activeSidebarIndex >= 0) {
      return activeSidebarIndex;
    }
    // @ts-ignore
    return config.findIndex((i) => i.route && pathname.includes(i.route));
  }, [pathname, config, sidebarOpenedKey]);

  const keyboardOpen = useFlag(false);

  useEffect(() => {
    const showSubRemove = eventEmitter.subscribe('RNKeyboardShow', keyboardOpen.on);
    const hideSubRemove = eventEmitter.subscribe('RNKeyboardHide', keyboardOpen.off);

    return () => {
      showSubRemove();
      hideSubRemove();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={clsx(
          classes.root,
          sidebarOpened && !showBottomBarInsideSidebar && classes.hide,
          showBottomBarInsideSidebar && classes.showBottomBarInsideSidebar,
          keyboardOpen.state && classes.momentHide,
        )}
      >
        {config.map((i, index) => (
          <div
            key={i.iconName}
            onClick={
              i.handler ||
              (() => {
                drawer.close();
                // @ts-ignore
                navigate(i.route);
              })
            }
            className={clsx(classes.navButton, {
              [classes.active]: index === activeIndex || i.mainButton,
            })}
          >
            {i.mainButton ? (
              <Image name={i.iconName} path="common" type="svg" />
            ) : (
              <Icon name={i.iconName} size="lg" />
            )}
            {i.label ? <span>{translate(i.label)}</span> : null}
          </div>
        ))}
      </div>
    </>
  );
};

const ReactNativeBottomBarWrapper = () => {
  return isReactNative ? <ReactNativeBottomBar /> : null;
};
export default ReactNativeBottomBarWrapper;
