import clsx from 'clsx';

import { FC, ReactNode, useEffect, useState } from 'react';

import useFlag from 'hooks/useFlag';

import classes from './Smooth.module.scss';

interface SmoothProps {
  children: ReactNode;
  className?: string;
  isVisible: boolean;
  animation?: 'height' | 'opacity' | 'width';
  animationValueCorrector?: number;
}

export const Smooth: FC<SmoothProps> = ({
  isVisible,
  children,
  className,
  animation = 'height',
  animationValueCorrector = 0,
}) => {
  const [ref, setRootRef] = useState<HTMLDivElement | null>(null);
  const [animationValue, setAnimationValue] = useState(0);

  const visible = useFlag(isVisible);

  useEffect(() => {
    switch (animation) {
      case 'height': {
        if (isVisible) {
          if (ref) {
            setAnimationValue(ref.scrollHeight + animationValueCorrector);
          }
        } else {
          setAnimationValue(0);
        }
        break;
      }
      case 'width': {
        if (isVisible) {
          if (ref) {
            setAnimationValue(ref.scrollWidth + animationValueCorrector);
          }
        } else {
          setAnimationValue(0);
        }
        break;
      }

      case 'opacity': {
        if (isVisible) {
          setTimeout(() => {
            setAnimationValue(1);
          }, 10);
        } else {
          setAnimationValue(0);
        }
        break;
      }
    }

    // eslint-disable-next-line
  }, [ref, isVisible]);

  useEffect(() => {
    if (isVisible) {
      visible.on();
    } else {
      setTimeout(visible.off, 400);
    }
    // eslint-disable-next-line
  }, [isVisible]);

  return visible.state ? (
    <div
      ref={setRootRef}
      className={clsx(classes.root, className)}
      style={{
        [animation]: animationValue,
      }}
    >
      {children}
    </div>
  ) : null;
};
