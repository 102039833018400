import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import {
  selectAllowedCountriesReducer,
  selectBankCardsOrdersReducer,
  selectIsCryptoBankCardAllowed,
} from 'modules/cryptoBankCard/store/selectors';

import routesByName from 'constants/routesByName';

import { Loader } from 'components/ui';

import { getTranslation } from 'libs/i18n';
import { errorToast } from 'libs/toast';

const OpenCryptoBankCardOrderProcessByUrl = () => {
  const drawer = useDrawer();

  const allowedCountiesForCryptoBankCardReducer = useSelector(selectAllowedCountriesReducer);
  const isCryptoBankCardAllowed = useSelector(selectIsCryptoBankCardAllowed);
  const bankCardsOrdersReducer = useSelector(selectBankCardsOrdersReducer);

  const stringifiedOrders = JSON.stringify(bankCardsOrdersReducer.data);
  useEffect(
    () => {
      if (
        isCryptoBankCardAllowed &&
        bankCardsOrdersReducer.meta.loaded &&
        allowedCountiesForCryptoBankCardReducer.meta.loaded
      ) {
        const notFailedPhysicalCardOrder = bankCardsOrdersReducer.data.find(
          (b) => !b.isVirtual && b.preOrderedStatus !== 'failed',
        );

        if (notFailedPhysicalCardOrder) {
          errorToast(getTranslation('CRYPTO_BANK_CARD_ALREADY_ORDERED'));
          return;
        }
        drawer.open(
          commonDrawerTemplates.bankCards({
            type: 'crypto',
          }),
        );
      }
    },
    // eslint-disable-next-line
    [
      isCryptoBankCardAllowed,
      bankCardsOrdersReducer.meta.loaded,
      allowedCountiesForCryptoBankCardReducer.meta.loaded,
      stringifiedOrders,
    ],
  );

  return bankCardsOrdersReducer.meta.loaded &&
    allowedCountiesForCryptoBankCardReducer.meta.loaded ? (
    <Navigate to={routesByName.dashboard} />
  ) : (
    <div className="row mt-5 aic jcc">
      <Loader />
    </div>
  );
};

export default OpenCryptoBankCardOrderProcessByUrl;
