import { FieldMetaState } from 'react-final-form';

const useField = <T>(meta: FieldMetaState<T>) => {
  const error = meta.error || meta.submitError;
  const hasError =
    (meta.dirty && !meta.active && meta.error) ||
    (meta.submitError && !meta.dirtySinceLastSubmit) ||
    (meta.submitFailed && meta.error);

  const valid = meta.modified && meta.valid;

  return { hasError, error, valid };
};

export default useField;
