import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectIssuers, selectWallets } from 'modules/accounts/store/selectors';
import { requestCreateWallet } from 'modules/accounts/store/thunks';
import useDrawer from 'modules/app/hooks/useDrawer';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import {
  selectAllowedLinkingCurrenciesReducer,
  selectBankCardsOrders,
} from 'modules/cryptoBankCard/store/selectors';
import {
  requestActivateBankCard,
  requestAllowedLinkingCurrencies,
  requestLinkTradingWalletToBankCard,
  requestReadBankCardOrder,
} from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard } from 'modules/cryptoBankCard/types';
import { useDispatch } from 'store';

import { SimpleCurrencyPickerInputField, SubmitButton, TextInputField } from 'components/form';

import useStoreEntity from 'hooks/useStoreEntity';

import { getTranslation, useTranslation } from 'libs/i18n';
import { errorToast, successToast } from 'libs/toast';
import yup, { makeValidate } from 'libs/yup';

import { findByProperty } from 'utils/arrayUtils';
import { findWallet } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './CryptoBankCardActivate.module.scss';

interface CryptoBankCardActivateFormProps extends FormRenderProps<FormValues> {
  allowedCurrenciesList: CurrencyCode[];
  isVirtual?: boolean;
}
const CryptoBankCardActivateForm: FC<CryptoBankCardActivateFormProps> = ({
  handleSubmit,
  allowedCurrenciesList,
  isVirtual,
}) => {
  const translate = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="column gap-3">
      <div className="column gap-2">
        {!isVirtual && (
          <Field
            name="cvcCode"
            label={translate('CVC_CODE')}
            placeholder={translate('PLACEHOLDER_CVC_CODE')}
            autoComplete="cc-csc"
            maxLength={3}
            component={TextInputField}
          />
        )}
        <Field
          name="currencyCode"
          label={translate('CRYPTO_BANK_CARD_CONNECTED_BALANCE')}
          component={SimpleCurrencyPickerInputField}
          currenciesList={allowedCurrenciesList}
        />
      </div>
      <SubmitButton variant="greyishGreen">{translate('ACTIVATE_CARD')}</SubmitButton>
    </form>
  );
};
interface FormValues {
  cvcCode: string;
  currencyCode: CurrencyCode | null;
}

const initialValues: FormValues = {
  cvcCode: '',
  currencyCode: null,
};
export interface CryptoBankCardActivateProps {
  cardId: CryptoBankCard['id'];
  isVirtual?: boolean;
}

const CryptoBankCardActivate: FC<CryptoBankCardActivateProps> = ({ cardId, isVirtual }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const wallets = useSelector(selectWallets);
  const issuers = useSelector(selectIssuers);

  const bankCardOrders = useSelector(selectBankCardsOrders);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const walletForConnect = findWallet(wallets, values.currencyCode!);

      if (!walletForConnect) {
        errorToast(translate('ERROR_WALLET_NOT_FOUND'));
        return;
      }

      if (!walletForConnect.exist) {
        const issuer = findByProperty(issuers, 'currencyCode', values.currencyCode!);

        if (!issuer) {
          errorToast(translate('ERROR_CANT_CREATE_WALLET_ISSUER_NOT_WOUND'));
          return;
        }
        const { success: successWalletCreated } = await dispatch(
          requestCreateWallet({ issuerId: issuer.id }),
        );

        if (!successWalletCreated) {
          // Error toast will be showed from request error
          return;
        }
      }
      if (isVirtual) {
        const { success: successConnected } = await dispatch(
          requestLinkTradingWalletToBankCard({
            bankCardId: cardId,
            currencyCode: values.currencyCode!,
          }),
        );
        if (successConnected) {
          const notActivatedBankCardOrder = bankCardOrders.find(
            (o) => o.isVirtual && o.preOrderedStatus === 'success' && !o.read,
          );
          if (notActivatedBankCardOrder) {
            await dispatch(requestReadBankCardOrder(notActivatedBankCardOrder.id));
          }
          successToast(translate('SUCCESS'));
          drawer.pop();
        } else {
          return { cvcCode: translate('ERROR_CANT_ACTIVATE_CRYPTO_CARD') };
        }
      } else {
        const { success: successActivated } = await dispatch(
          requestActivateBankCard({
            id: cardId,
            cvc: values.cvcCode,
            linkedCurrencyCode: values.currencyCode!,
          }),
        );
        if (successActivated) {
          const notActivatedBankCardOrder = bankCardOrders.find(
            (o) => !o.isVirtual && o.preOrderedStatus === 'success' && !o.read,
          );
          if (notActivatedBankCardOrder) {
            await dispatch(requestReadBankCardOrder(notActivatedBankCardOrder.id));
          }

          drawer.replace(cryptoBankCardDrawerTemplates.activateCardSuccess());
        } else {
          return { cvcCode: translate('ERROR_CANT_ACTIVATE_CRYPTO_CARD') };
        }
      }
    },
    [bankCardOrders, isVirtual, wallets, drawer, issuers, dispatch, translate, cardId],
  );

  const {
    entityReducer: { data: allowedCurrencies },
  } = useStoreEntity(selectAllowedLinkingCurrenciesReducer, requestAllowedLinkingCurrencies);

  const allowedWallets = useMemo(
    () => wallets.filter((w) => allowedCurrencies.includes(w.currencyCode)),
    [wallets, allowedCurrencies],
  );

  const allowedCurrenciesList = useMemo(
    () => allowedWallets.map((i) => i.currencyCode),
    [allowedWallets],
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        currencyCode: yup.string().requiredDefault(),
        cvcCode: isVirtual
          ? yup.string().optional()
          : yup
              .string()
              .length(3, getTranslation('VALIDATION_LENGTH', { length: 3 }))
              .test(
                'onlyNumbers',
                getTranslation('VALIDATION_FIELD_ONLY_NUMBERS'),
                (value?: string) => !!value && !Number.isNaN(Number(value)),
              )
              .requiredDefault(),
      }),
    [isVirtual],
  );

  const validate = useMemo(() => makeValidate(schema), [schema]);

  return (
    <div className={classes.root}>
      {!isVirtual && (
        <p className={classes.description}>
          {translate('CRYPTO_BANK_CARD_ACTIVATE_CARD_SUBTITLE')}
        </p>
      )}

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        // @ts-ignore
        render={CryptoBankCardActivateForm}
        validate={validate}
        allowedCurrenciesList={allowedCurrenciesList}
        isVirtual={isVirtual}
      />
    </div>
  );
};

export default CryptoBankCardActivate;
