import { AxiosRequestConfig } from 'axios';

import {
  transformExchangeAllowedDirections,
  transformHistoryRatesResponse,
} from 'modules/exchange/services/transformers';
import {
  ExchangeResponse,
  GetExchangeRateReqParams,
  GetRatesHistoryAggregatedApiResponse,
  GetRatesHistoryAggregatedReqParams,
  GetRatesHistoryApiResponse,
  GetRatesHistoryParams,
  RatesHistory,
} from 'modules/exchange/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  CalculateExchangeRequest,
  CalculateExchangeResponse,
  ExchangeMultiPairRequest,
  ExchangeMultiPairResponse,
  ExchangePairResponse,
  ExchangePairsResponse,
  ExchangeRequest,
} from 'libs/swagger/nebeusApiTypes';

import { CurrenciesDirection, CurrencyCode } from 'types';

// cli-import

const exchangeServices = {
  getRate: (params: GetExchangeRateReqParams): Promise<ExchangePairResponse> =>
    AxiosInstance.post('/v1/exchange/rate', {}, { params }),
  getRateBatched: (body: ExchangeMultiPairRequest): Promise<ExchangeMultiPairResponse> =>
    AxiosInstance.post('/v1/exchange/rate/batch', body),
  calculateExchange: (
    payload: CalculateExchangeRequest,
    axiosConfig?: AxiosRequestConfig,
  ): Promise<CalculateExchangeResponse> =>
    AxiosInstance.post('/v1/exchange/calculate', payload, { signal: axiosConfig?.signal }),
  calculateExchangeReversed: (
    payload: CalculateExchangeRequest,
    axiosConfig?: AxiosRequestConfig,
  ): Promise<CalculateExchangeResponse> =>
    AxiosInstance.post('/v1/exchange/calculate/reversed', payload, { signal: axiosConfig?.signal }),
  exchange: async (payload: ExchangeRequest): Promise<ExchangeResponse> =>
    AxiosInstance.post<ExchangeResponse, ExchangeResponse>('/v1/exchange/execute', payload),
  getRatesHistory: ({ from, to }: GetRatesHistoryParams): Promise<RatesHistory> =>
    AxiosInstance.get<GetRatesHistoryApiResponse, GetRatesHistoryApiResponse>(
      `/v1/rates/history/multiple?from=${from.join(',')}&to=${to.join(',')}`,
      {
        params: { timeunit: '1d' },
      },
    ).then(transformHistoryRatesResponse),
  getRatesHistoryAggregated: (
    payload: GetRatesHistoryAggregatedReqParams,
  ): Promise<GetRatesHistoryAggregatedApiResponse> =>
    AxiosInstance.get<GetRatesHistoryAggregatedApiResponse, GetRatesHistoryAggregatedApiResponse>(
      '/v1/rates/history/aggregation',
      { params: payload },
    ),
  getAllowedDirections: (): Promise<CurrenciesDirection[]> =>
    AxiosInstance.get<ExchangePairsResponse, ExchangePairsResponse>('/v1/exchange/pair').then(
      transformExchangeAllowedDirections,
    ),
  getFollowedCurrencies: (): Promise<CurrencyCode[]> =>
    AxiosInstance.get('/v1/exchange/followed-currency'),
  followCurrency: (currencyCode: CurrencyCode): Promise<CurrencyCode[]> =>
    AxiosInstance.post('/v1/exchange/followed-currency/follow', {
      currencyCode,
    }),
  unfollowCurrency: (currencyCode: CurrencyCode): Promise<CurrencyCode[]> =>
    AxiosInstance.post('/v1/exchange/followed-currency/unfollow', {
      currencyCode,
    }),
  // cli-service
};

export default exchangeServices;
