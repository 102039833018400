import { receivePreparedHtml } from 'modules/loans/constants/contracts/utils';

import { template } from './template';

export const getInterestOnlyAgreement = (payload: {
  userName: string;
  userAddress: string;
  userEmail: string;
  totalRepaymentAmount: string;
  loanAmount: string;
  loanCurrency: string;
  originationFee: string;
  interestRate: string;
  termMonth: string;
  endDate: string;
  monthlyPayment: string;
  collateralAmount: string;
  collateralCurrency: string;
  earlyRepaymentFeePercent: string;
}) => receivePreparedHtml(template, payload);
