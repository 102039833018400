import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// cli-import
import { ReactNativeState } from '../types';

const initialState: ReactNativeState = {
  isRN: false,
  isiOS: false,
  isAndroid: false,
  insets: { top: 0, bottom: 0, left: 0, right: 0 },
  notificationToken: undefined,
  passcodeEnabled: false,
  bioEnabled: false,
  bioType: undefined,
  // cli-state
};

const reactNativeSlice = createSlice({
  name: 'reactNative',
  initialState,
  reducers: {
    updateState: (
      state: ReactNativeState,
      { payload }: PayloadAction<Partial<ReactNativeState>>,
    ) => {
      state = { ...state, ...payload };
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
    // cli-reducer
  },
});

export default reactNativeSlice.reducer;
export const reactNativeActions = reactNativeSlice.actions;
