import clsx from 'clsx';

import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import useOrderCryptoVirtualBankCard from 'modules/cryptoBankCard/hooks/useOrderCryptoVirtualBankCard';
import {
  selectBankCardsOrdersReducer,
  selectIsPhysicalCryptoCardExist,
  selectIsVirtualCryptoCardExist,
} from 'modules/cryptoBankCard/store/selectors';
import { requestBankCardsOrders } from 'modules/cryptoBankCard/store/thunks';

import { Button } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './OrderBankCardChooseType.module.scss';

interface OptionProps {
  option: { label: string; sublabel: string; id: string };
  onClick: voidFunc;
  active: boolean;
  disabled?: boolean;
}

const Option: FC<OptionProps> = ({ option, onClick, active, disabled }) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={clsx(classes.option, active && classes.active, disabled && 'disabled')}
    >
      <div className="column gap-0-5">
        <span>{option.label}</span>
        <p className="label">{option.sublabel}</p>
      </div>
    </div>
  );
};
const OrderBankCardChooseType = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const userHasCryptoVirtualCard = useSelector(selectIsVirtualCryptoCardExist);
  const userHasCryptoPhysicalCard = useSelector(selectIsPhysicalCryptoCardExist);

  const [activeOption, setActiveOption] = useState<string | null>(null);

  const {
    entityReducer: { data: cryptoBankCardsOrders },
  } = useStoreEntity(selectBankCardsOrdersReducer, requestBankCardsOrders);

  const [cryptoPhysicalCardOrderExist, cryptoVirtualCardOrderExist] = useMemo(
    () => [
      !!cryptoBankCardsOrders.find((o) => !o.isVirtual && o.preOrderedStatus !== 'failed'),
      !!cryptoBankCardsOrders.find((o) => o.isVirtual && o.preOrderedStatus !== 'failed'),
    ],
    [cryptoBankCardsOrders],
  );

  const options = useMemo(
    () => [
      {
        label: translate('BANK_CARD_VIRTUAL_WORD'),
        sublabel: translate('BANK_CARD_ISSUE_RIGHT_NOW'),
        id: 'virtual',
        disabled: cryptoVirtualCardOrderExist || userHasCryptoVirtualCard,
      },
      {
        label: translate('BANK_CARD_PHYSICAL_WORD'),
        sublabel: translate('BANK_CARD_ISSUE_DEPENDS_ON_COUNTRY'),
        id: 'physical',
        disabled: cryptoPhysicalCardOrderExist || userHasCryptoPhysicalCard,
      },
    ],
    [
      translate,
      userHasCryptoVirtualCard,
      cryptoVirtualCardOrderExist,
      userHasCryptoPhysicalCard,
      cryptoPhysicalCardOrderExist,
    ],
  );

  const orderVirtualCard = useOrderCryptoVirtualBankCard();

  const handleSubmit = useCallback(() => {
    if (activeOption === 'virtual') {
      orderVirtualCard.startProcess();
    } else {
      drawer.open(cryptoBankCardDrawerTemplates.orderCardShippingDetails());
    }
  }, [activeOption, drawer, orderVirtualCard]);

  return (
    <div className="column flex-1 jcsb gap-3 mt-1">
      <div className="column gap-2">
        {options.map((o) => (
          <Option
            key={o.id}
            option={o}
            onClick={() => setActiveOption(o.id)}
            active={activeOption === o.id}
            disabled={o.disabled}
          />
        ))}
      </div>
      <Button
        fullWidth
        disabled={!activeOption}
        loading={orderVirtualCard.loading}
        onClick={handleSubmit}
      >
        {translate('CONTINUE')}
      </Button>
    </div>
  );
};

export default OrderBankCardChooseType;
