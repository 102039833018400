import clsx from 'clsx';

import { FC, useCallback, useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';

import { Button, Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol, getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './CreateBankCardSuccess.module.scss';

export interface CreateBankCardSuccessProps {
  isVirtual: boolean;
  userHasFundsToPay?: boolean;
  paymentWalletCurrencyCode?: CurrencyCode;
  needDepositForOrderProcessing?: boolean;
  minAmountToProcessOrderInEur?: number;
  paymentAmount?: number;
}
const CreateBankCardSuccess: FC<CreateBankCardSuccessProps> = ({
  isVirtual,
  userHasFundsToPay,
  paymentAmount,
  paymentWalletCurrencyCode,
  needDepositForOrderProcessing,
  minAmountToProcessOrderInEur,
}) => {
  const drawer = useDrawer();

  const translate = useTranslation();

  const title = useMemo(() => {
    if (!userHasFundsToPay || needDepositForOrderProcessing) {
      return 'CRYPTO_BANK_CARD_ORDERED_NOT_PAID_SUCCESS_TITLE';
    }
    return isVirtual
      ? 'CRYPTO_VIRTUAL_BANK_CARD_ORDERED_SUCCESS_TITLE'
      : 'CRYPTO_BANK_CARD_ORDERED_SUCCESS_TITLE';
  }, [userHasFundsToPay, isVirtual, needDepositForOrderProcessing]);

  const subtitle = useMemo(() => {
    if (!userHasFundsToPay && needDepositForOrderProcessing && minAmountToProcessOrderInEur) {
      let result = translate('CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TEXT', {
        paymentLabel: formatCurrencyWithSymbol(
          paymentAmount || 0,
          paymentWalletCurrencyCode || 'EUR',
        ),
        currencyLabel: getCurrencyLabelByCode(paymentWalletCurrencyCode || 'EUR').toLowerCase(),
      });

      result +=
        ' ' +
        translate('CRYPTO_BANK_CARD_ISSUANCE_RULE_REMEMBER', {
          minAmountLabel: formatCurrencyWithSymbol(minAmountToProcessOrderInEur, 'EUR'),
        });

      return result;
    }

    if (!userHasFundsToPay) {
      return translate('CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TEXT', {
        paymentLabel: formatCurrencyWithSymbol(
          paymentAmount || 0,
          paymentWalletCurrencyCode || 'EUR',
        ),
        currencyLabel: getCurrencyLabelByCode(paymentWalletCurrencyCode || 'EUR').toLowerCase(),
      });
    }
    if (needDepositForOrderProcessing && minAmountToProcessOrderInEur) {
      return translate('CRYPTO_BANK_CARD_NEED_MIN_DEPOSIT_ORDERED_SUCCESS_TEXT', {
        minAmountInEur: minAmountToProcessOrderInEur,
      });
    }
    return translate('CRYPTO_BANK_CARD_ORDERED_SUCCESS_TEXT');
  }, [
    paymentAmount,
    translate,
    userHasFundsToPay,
    needDepositForOrderProcessing,
    minAmountToProcessOrderInEur,
    paymentWalletCurrencyCode,
  ]);

  const handleDepositFunds = useCallback(() => {
    drawer.replaceAll(
      paymentDrawerTemplates.paymentProcess({
        isDeposit: true,
        isDigitalAccount: false,
        currencyCode: paymentWalletCurrencyCode,
      }),
    );
  }, [drawer, paymentWalletCurrencyCode]);

  return (
    <div className="column flex-1 gap-3">
      <div className={clsx('column flex-1 jcc tac', classes.content)}>
        <Image
          className={classes.cardImg}
          name={!isVirtual ? 'cryptoBankCard' : 'cryptoVirtualBankCard'}
          path="cryptoBankCard"
        />
        <h3
          className="mt-6"
          dangerouslySetInnerHTML={{
            __html: translate(title),
          }}
        />
        <p>{subtitle}</p>
      </div>
      <div className="row gap-1-5">
        {!userHasFundsToPay ? (
          <Button fullWidth onClick={handleDepositFunds} variant="greyishGreen">
            {translate('PAYMENT_DEPOSIT_FUNDS')}
          </Button>
        ) : (
          <Button onClick={drawer.close} fullWidth variant="greyishGreen">
            {translate('CLOSE')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateBankCardSuccess;
