import { CurrenciesCard } from 'modules/exchange/views/ExchangePage/components/CurrenciesCard';

import { Icon } from 'components/ui';

import usePersistedState from 'hooks/usePersistedState';

import { useTranslation } from 'libs/i18n';

import { CurrencyCode } from 'types';

export const WatchList = () => {
  const translate = useTranslation();

  const [watchList] = usePersistedState<CurrencyCode[]>('watchList');

  return (
    <div className="px-2">
      {!watchList || !watchList?.length ? (
        <div className="column gap-6 pt-10">
          <Icon name="questionLg" size={72} />
          <div className="column gap-2 tac">
            <h2 className="font-xl2">{translate('WATCH_LIST_EMPTY_TITLE')}</h2>
            <p className="darkGrey">{translate('WATCH_LIST_EMPTY_SUBTITLE')}</p>
          </div>
        </div>
      ) : (
        <CurrenciesCard list={watchList || []} />
      )}
    </div>
  );
};
