import clsx from 'clsx';

import { FC, useCallback, useEffect, useMemo } from 'react';

import { selectExtendedUserProfileReducer } from 'modules/user/store/selectors';
import { requestUserExtendedProfile } from 'modules/user/store/thunks';

import LocalStorageKeys from 'constants/localStorageKeys';

import useFlag from 'hooks/useFlag';
import usePersistedState from 'hooks/usePersistedState';
import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './HappyBirthDay.module.scss';

const confettiElements = new Array(Math.round(200)).fill('').reduce(
  (r) =>
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    r +
    `<i class="${classes.confettiIcon}" style="--rotation: ${
      -50 + Math.random() * 100
    }deg; --color: hsl(${
      Math.random() * 360
    },100%,70%); --rnd: ${Math.random()}"><span></span></i>`,
  '',
);

interface HappyBirthDayViewProps {
  onFinish: voidFunc;
}
const HappyBirthDayView: FC<HappyBirthDayViewProps> = ({ onFinish }) => {
  const translate = useTranslation();

  const finished = useFlag(false);
  useEffect(() => {
    setTimeout(() => {
      finished.on();
      setTimeout(onFinish, 1000);
    }, 15000);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={clsx(classes.root, finished.state && classes.finished)}>
      <div className={classes.text}>
        {translate('HAPPY_BIRTHDAY')
          .split(' ')
          .map((part) => (
            <span key={part}>{part} </span>
          ))}
      </div>
      <div className={classes.confettis} dangerouslySetInnerHTML={{ __html: confettiElements }} />
      <div className={classes.confettis} dangerouslySetInnerHTML={{ __html: confettiElements }} />
    </div>
  );
};

const HappyBirthDay = () => {
  const {
    entityReducer: { data: user },
  } = useStoreEntity(selectExtendedUserProfileReducer, requestUserExtendedProfile);

  const [lastBirthdayYearCelebrated, setLastBirthdayYearCelebrated] = usePersistedState(
    LocalStorageKeys.lastBirthdayYearCelebrated,
  );

  const todayIsBirthday = useMemo(() => {
    if (!user || !user.dateOfBirth) {
      return;
    }

    const [birthdayDay, birthdayMonth] = user.dateOfBirth.split('.');

    const today = new Date();
    const [todayDay, todayMonth] = [today.getDate(), today.getMonth() + 1];

    return todayDay === +birthdayDay && todayMonth === +birthdayMonth;
  }, [user]);

  const alreadyCelebrated = useMemo(() => {
    return lastBirthdayYearCelebrated === new Date().getFullYear();
  }, [lastBirthdayYearCelebrated]);

  const needToShowHB = todayIsBirthday && !alreadyCelebrated;

  const onCelebrated = useCallback(() => {
    setLastBirthdayYearCelebrated(new Date().getFullYear());
  }, [setLastBirthdayYearCelebrated]);

  return needToShowHB ? <HappyBirthDayView onFinish={onCelebrated} /> : null;
};

export default HappyBirthDay;
