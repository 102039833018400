import clsx from 'clsx';

import { FC, useMemo } from 'react';

import RateApp from 'modules/app/views/RateApp';

import { AmountLabel, CurrencyIcon, SummaryCard } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY_HHMM_AMPM } from 'utils/date';

import classes from './ExchangeSuccessCard.module.scss';

export interface ExchangeSuccessCardProps {
  from: CurrencyAmount;
  to: CurrencyAmount;
  fee: CurrencyAmount;
  rate: number;
  date: string;
  isLight?: boolean;
}
export const ExchangeSuccessCard: FC<ExchangeSuccessCardProps> = ({
  from,
  to,
  fee,
  date,
  rate,
  isLight,
}) => {
  const translate = useTranslation();

  const summary = useMemo<SummaryCardItem[]>(
    () => [
      { label: translate('CHARGE_ACCOUNT'), value: from.currency },
      {
        label: translate('CHARGE_AMOUNT'),
        value: formatCurrencyWithSymbol(from.amount, from.currency),
      },
      {
        label: translate('FEE'),
        value: formatCurrencyWithSymbol(fee.amount, fee.currency),
      },
      {
        label: translate('EXCHANGE_RATE'),
        value: formatCurrencyWithSymbol(rate, to.currency),
      },
      {
        label: translate('ACCOUNT_OPERATION_STATUS'),
        value: translate('EXCHANGE_OPERATION_SUCCESSFULLY'),
      },
    ],
    [translate, fee, rate, to, from],
  );

  return (
    <div className="column gap-3">
      <div className={classes.card}>
        <CurrencyIcon active code={to.currency} size={72} />
        <span className={classes.boldLabel}>
          {translate('BUY')} {to.currency}
        </span>
        <AmountLabel
          amount={to.amount}
          currencyCode={to.currency}
          size="xl2"
          showCurrencyCode
          showPlusOrMinus
        />

        <span className="label">{formatDDMMYY_HHMM_AMPM(new Date(date))}</span>
      </div>
      <RateApp />

      <div className="column gap-3">
        <span className={clsx('label', isLight && 'white')}>
          {translate('ACCOUNTS_OPERATION_DETAILS')}
        </span>
        <SummaryCard config={summary} variant={isLight ? undefined : 'borderless'} />
      </div>
    </div>
  );
};
