import clsx from 'clsx';

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from 'components/ui/Icon';

import { voidFunc } from 'types';

import classes from './ButtonsMenu.module.scss';

interface Button {
  label: string;
  icon: string;
  route?: string;
  handler?: voidFunc;
}
interface ButtonsMenuProps {
  buttons: Button[];
  className?: string;
}
export const ButtonsMenu: FC<ButtonsMenuProps> = ({ buttons, className }) => {
  const navigate = useNavigate();

  return (
    <div className={clsx(classes.operationButtons, className)}>
      {buttons.map((button) => (
        <div
          key={button.label}
          onClick={() => {
            if (button.handler) {
              button.handler();
              return;
            }
            if (button.route) {
              navigate(button.route);
            }
          }}
          className={classes.buttonContainer}
        >
          <Icon className={classes.icon} name={button.icon} />
          <span>{button.label}</span>
        </div>
      ))}
    </div>
  );
};
