import clsx from 'clsx';

import { FC, useCallback } from 'react';

import { Icon } from 'components/ui/Icon';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import { setStringToClipboard } from 'utils/common';

import { Size } from 'types';

import classes from './CopyButton.module.scss';

interface CopyButtonProps {
  className?: string;
  value?: string;
  label?: string;
  disabled?: boolean;
  size?: Size;
}

export const CopyButton: FC<CopyButtonProps> = ({ className, value, label, disabled, size }) => {
  const translate = useTranslation();
  const handleCopy = useCallback(async () => {
    if (value && !disabled) {
      await setStringToClipboard(value);
      successToast(label ? `${label} ` + translate('COPIED').toLowerCase() : translate('COPIED'));
    }
  }, [disabled, translate, value, label]);

  return (
    <div
      onClick={handleCopy}
      className={clsx(
        classes.root,
        className,
        disabled && classes.disabled,
        size && classes['size-' + size],
      )}
    >
      <Icon name="copy" color="green" size={size} />
    </div>
  );
};
