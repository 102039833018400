import { times } from 'lodash';

import { ChangeEventHandler, FC, useCallback, useState } from 'react';

import useAccountsTransactionsHistory from 'modules/accounts/hooks/useAccountsTransactionsHistory';

import { DrawerHeader, Icon, Skeleton, TextInput, TransactionRow } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { CurrencyCode } from 'types';

import classes from './AccountsTransactionsHistoryDrawerView.module.scss';

export interface AccountsTransactionsHistoryDrawerViewProps {
  isCryptoAccount: boolean;
  currencyCode?: CurrencyCode;
}
const AccountsTransactionsHistoryDrawerView: FC<AccountsTransactionsHistoryDrawerViewProps> = ({
  currencyCode,
  isCryptoAccount,
}) => {
  const translate = useTranslation();

  const [filter, setFilter] = useState('');

  const handleFilter = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setFilter(e.currentTarget.value);
  }, []);

  const { onInfinityScroll, groupedByDate, isLoading, onTransactionClick } =
    useAccountsTransactionsHistory({
      currencyCode,
      isCryptoAccount,
      filterValue: filter,
    });

  return (
    <div className="column flex-1 pt-2 gap-3">
      <DrawerHeader title={translate('ACCOUNT_TRANSACTIONS_HISTORY')} />
      <TextInput
        value={filter}
        onChange={handleFilter}
        placeholder={translate('SEARCH')}
        startAdornment={
          <div className="pl-1-5">
            <Icon name="search" size={16} color="darkGrey" />
          </div>
        }
      />

      {isLoading && groupedByDate.length === 0 ? (
        <div className={classes.transactions}>
          {times(5).map((_, index) => (
            <div key={index} className="column gap-2">
              <Skeleton width={100} height={16} />
              <div className="column gap-3">
                {times(3).map((__, trIndex) => (
                  <Skeleton key={`${index}${trIndex}`} height={48} />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : null}

      {groupedByDate.length ? (
        <div className={classes.transactions} onScroll={onInfinityScroll}>
          {groupedByDate.map(([date, transactions]) => (
            <div key={date} className="column gap-2">
              <span className="label">{date}</span>
              <div className="column gap-3">
                {transactions.map((t) => (
                  <TransactionRow
                    key={t.uniqId}
                    onClick={() => {
                      onTransactionClick(t.uniqId);
                    }}
                    transaction={t}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default AccountsTransactionsHistoryDrawerView;
