import { sumBy, times } from 'lodash';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  selectDigitalAccountWallets,
  selectWalletsReducer,
} from 'modules/accounts/store/selectors';
import { AccountType } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { selectStatisticReducer as selectRentingStatisticReducer } from 'modules/cryptoRenting/store/selectors';
import { requestStatistic as requestRentingStatistic } from 'modules/cryptoRenting/store/thunks';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import { selectStatisticReducer as selectLoanStatisticReducer } from 'modules/loans/store/selectors';
import { requestStatistic as requestLoanStatistic } from 'modules/loans/store/thunks';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { isReactNative } from 'modules/reactNative/utils';
import { selectStatisticReducer as selectStakingStatisticReducer } from 'modules/staking/store/selectors';
import { requestStatistic as requestStakingStatistic } from 'modules/staking/store/thunks';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';
import { selectVaultWalletsReducer } from 'modules/vault/store/selectors';
import { requestVaultWallets } from 'modules/vault/store/thunks';
import { VaultWallet } from 'modules/vault/types';

import routesByName from 'constants/routesByName';

import { AmountLabel, CurrencyIcon, Icon, Skeleton } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import classes from './Assets.module.scss';

export const Assets = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const drawer = useDrawer();

  const userDefaultCurrency = useSelector(selectUserDefaultCurrencyCode);

  const { data: wallets, meta: walletsMeta } = useSelector(selectWalletsReducer);
  const walletsAmount = useMemo(() => sumBy(wallets, 'amountInDefaultCurrency'), [wallets]);

  const ibanWallets = useSelector(selectDigitalAccountWallets);
  const ibanWalletsAmount = useMemo(
    () => sumBy(ibanWallets, 'amountInDefaultCurrency'),
    [ibanWallets],
  );

  const {
    entityReducer: { data: rentingStatistic },
  } = useStoreEntity(selectRentingStatisticReducer, requestRentingStatistic);

  const {
    entityReducer: { data: stakingStatistic },
  } = useStoreEntity(selectStakingStatisticReducer, requestStakingStatistic);

  const earningsAmount =
    (rentingStatistic?.totalAssets || 0) + (stakingStatistic?.totalStakedAmount || 0);

  const {
    entityReducer: { data: loanStatistic },
  } = useStoreEntity(selectLoanStatisticReducer, requestLoanStatistic);
  const loansCollateralAmount = loanStatistic?.totalAmount || 0;

  const { entityReducer: vaultWalletsReducer } = useStoreEntity<VaultWallet[]>(
    selectVaultWalletsReducer,
    requestVaultWallets,
  );

  const vaultsAmount = useMemo(
    () => sumBy(vaultWalletsReducer.data, 'amountInDefaultCurrency'),
    [vaultWalletsReducer.data],
  );

  // TODO:[WBE] Wait BE for endpoint for all statistic
  const assets = useMemo(
    () => [
      {
        label: translate('WALLETS'),
        amount: walletsAmount,
        currencyCode: userDefaultCurrency,
      },
      {
        label: translate('IBAN'),
        amount: ibanWalletsAmount,
        currencyCode: userDefaultCurrency,
      },
      {
        label: translate('EARNING'),
        amount: earningsAmount,
        currencyCode: userDefaultCurrency,
      },
      {
        label: translate('VAULTS'),
        amount: vaultsAmount,
        currencyCode: userDefaultCurrency,
      },
      {
        label: translate('LOAN_COLLATERAL'),
        amount: loansCollateralAmount,
        currencyCode: userDefaultCurrency,
      },
    ],
    [
      translate,
      vaultsAmount,
      walletsAmount,
      earningsAmount,
      loansCollateralAmount,
      userDefaultCurrency,
      ibanWalletsAmount,
    ],
  );

  const operationButtons = useMemo(
    () => [
      {
        label: translate('DEPOSIT'),
        icon: 'arrowDownSquared',
        handler: () => drawer.open(paymentDrawerTemplates.paymentProcess()),
      },
      {
        label: translate('SEND'),
        icon: 'arrowUpSquared',
        handler: () => drawer.open(paymentDrawerTemplates.paymentProcess({ isDeposit: false })),
      },
      {
        label: translate('EXCHANGE'),
        icon: 'transferArrowsSquared',
        handler: () => {
          if (isReactNative) {
            navigate(routesByName.exchange);
          } else {
            drawer.open(exchangeDrawerTemplates.exchange());
          }
        },
      },
      {
        label: translate('MORE'),
        icon: 'dotsSquared',
        handler: () => {
          drawer.open(commonDrawerTemplates.appOperations());
        },
      },
    ],
    [drawer, navigate, translate],
  );

  const totalAmount = useMemo(() => sumBy(assets, 'amount'), [assets]);

  return (
    <div className="column gap-3">
      <div className="column gap-1-5">
        <span className="label">{translate('TOTAL_ASSETS_TITLE')}</span>
        <AmountLabel
          className={classes.totalAmountLabel}
          amount={totalAmount}
          currencyCode={userDefaultCurrency}
          size="xl4"
          showCurrencySymbol
          useCounter
        />
      </div>
      <div className={classes.buttons}>
        {operationButtons.map((b) => (
          <div key={b.label} className={classes.button} onClick={b.handler}>
            <div className={classes.circle}>
              <Icon name={b.icon} />
            </div>
            <span className={classes.label}>{b.label}</span>
          </div>
        ))}
      </div>
      <div className={classes.accountsWrapper}>
        <div className={classes.accounts}>
          {walletsMeta.loading && !walletsMeta.loaded
            ? times(5, (i) => (
                <div key={i}>
                  <div className={classes.slide}>
                    <Skeleton width={32} height={32} borderRadius={16} />
                    <div className="column gap-0-5">
                      <Skeleton width={100} height={16} />
                      <Skeleton width={70} height={18} />
                    </div>
                  </div>
                </div>
              ))
            : wallets.map((w) => (
                <div
                  key={w.currencyCode}
                  className={classes.slide}
                  onClick={() => navigate(routesByName.accountPage(w.currencyCode))}
                >
                  <CurrencyIcon size={32} code={w.currencyCode} />
                  <div className="column gap-0-5">
                    <span className={classes.label}>
                      {translate('CURRENCY_ACCOUNT', { currencyCode: w.currencyCode })}
                    </span>
                    <span className={classes.value}>
                      {formatCurrencyWithSymbol(w.amount, w.currencyCode, { withZeros: true })}
                    </span>
                  </div>
                </div>
              ))}
          <div
            className={classes.slide}
            onClick={() => navigate(routesByName.accounts(AccountType.crypto))}
          >
            <Icon className="asfs" size={32} name="plusSquared" />
            <span className={classes.value}>{translate('ACCOUNTS_OPEN_ACCOUNT')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
