import { Invoice } from 'modules/invoicing/types';

export const calculateInvoiceAmount = (invoice: Invoice) => {
  const subtotal = invoice.invoiceServiceItems.reduce((prev, current) => {
    return prev + +current.amount * +current.quantity;
  }, 0);

  const total = subtotal - invoice.invoiceDiscount.amount || 0;

  return { total, subtotal };
};
