import { AccountsTransactionsHistoryDrawerViewProps } from 'modules/accounts/views/AccountsTransactionsHistoryDrawerView';
import { TransactionDetailsProps } from 'modules/accounts/views/TransactionDetails';
import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';

const transactionsHistory = (
  props: AccountsTransactionsHistoryDrawerViewProps,
): DrawerTemplateReturnType => ({
  key: 'accountsTransactionsHistory',
  sideBarProps: {
    showBackButton: false,
    withoutBottomPadding: true,
  },
  props,
});
const transactionDetails = (props: TransactionDetailsProps): DrawerTemplateReturnType => ({
  key: 'transactionDetails',
  sideBarProps: {
    showBackButton: false,
    swipeModal: true,
  },
  props,
});

const accountsDrawerTemplates = {
  transactionsHistory,
  transactionDetails,
};

export default accountsDrawerTemplates;
