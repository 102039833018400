import clsx from 'clsx';
import { random } from 'lodash';

import { ChangeEventHandler, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import phonebookDrawerTemplates, {
  PHONEBOOK_COUNTERPARTIES_HEADER_CLASSNAME,
} from 'modules/phonebook/constants/drawerTemplates';
import { selectCounterpartiesLibrary } from 'modules/phonebook/store/selectors';
import {
  requestDeleteCounterparties,
  requestGetCounterpartyList,
} from 'modules/phonebook/store/thunks';
import { Counterparty } from 'modules/phonebook/types';
import { useDispatch } from 'store';

import { Smooth } from 'components/common';
import { Button, CheckBox, Icon, Loader, NoResultLabel, Skeleton, TextInput } from 'components/ui';

import useAbortController from 'hooks/useAbortController';
import useDebounceFunc from 'hooks/useDebounceFunc';
import useFirstRender from 'hooks/useFirstRender';
import useFlag from 'hooks/useFlag';
import useInfinityScroll from 'hooks/useInfinityScroll';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import eventEmitter, { eventEmitterEventNames } from 'utils/eventEmitter';

import classes from './Counterparties.module.scss';

const SkeletonList = memo(() => (
  <>
    {new Array(30).fill(null).map((_, i) => (
      <div key={i} className="row aic gap-2">
        <Skeleton height={40} width={40} borderRadius={20} />
        <div className="column gap-1">
          <Skeleton height={20} width={random(80, 140)} />
          <Skeleton height={20} width={random(80, 250)} />
        </div>
      </div>
    ))}
  </>
));
export interface CounterpartiesProps {
  onPick?: (counterparty: Counterparty) => Promise<void>;
  withPick?: boolean;
  isNebeusUser?: boolean;
}

const Counterparties: FC<CounterpartiesProps> = ({
  onPick,
  withPick,
  isNebeusUser: isNebeusUserFilter,
}) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const counterpartiesLibrary = useSelector(selectCounterpartiesLibrary);

  useEffect(() => {
    if (withPick && !onPick) {
      drawer.pop({ key: 'phonebookCounterparties', soft: false });
    }
    // eslint-disable-next-line
  }, []);

  const [searchQuery, setSearchQuery] = useState('');

  const abortController = useAbortController();

  const loadMore = useCallback(
    async (pageNumber: number, pageSize: number, reqPayload?: any) => {
      abortController.abort();

      const signal = abortController.generate();

      const { data, success } = await dispatch(
        requestGetCounterpartyList(
          { searchQuery, pageNumber, pageSize, ...reqPayload },
          { axiosConfig: { signal } },
        ),
      );

      if (success && data) {
        return data.list.map((i) => i.id);
      }
      return [];
    },
    [dispatch, searchQuery, abortController],
  );

  const { onInfinityScroll, isLoading, list, refreshList, page } = useInfinityScroll({
    loadMore,
    pageSize: 20,
  });

  const counterpartiesList = useMemo(
    () => list.map((id) => counterpartiesLibrary[id]).filter((i) => !!i),
    [list, counterpartiesLibrary],
  );

  const debouncing = useFlag(false);

  useEffect(() => {
    if (!isLoading) {
      debouncing.off();
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const debouncedRefreshList = useDebounceFunc(refreshList);

  const handleSearchQueryChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const value = event.currentTarget.value;
    setSearchQuery(value);
  }, []);

  const firstRender = useFirstRender();

  useEffect(() => {
    if (firstRender) {
      return;
    }
    debouncing.on();
    debouncedRefreshList();
    // if (searchQuery === '') {
    //   refreshList();
    // } else {
    //   debouncing.on();
    //   debouncedRefreshList();
    // }

    // eslint-disable-next-line
  }, [searchQuery]);

  const handlePressAddCounterparty = useCallback(() => {
    drawer.open(phonebookDrawerTemplates.addEditCounterparty());
  }, [drawer]);

  const selectMode = useFlag(false);
  const [selectedIds, setSelectedIds] = useState<Counterparty['id'][]>([]);

  const [loadingCounterpartyId, setLoadingCounterpartyId] = useState<Counterparty['id'] | null>(
    null,
  );

  const handleClickCounterparty = useCallback(
    async (counterparty: Counterparty) => {
      if (selectMode.state) {
        if (!selectedIds.includes(counterparty.id)) {
          setSelectedIds((prev) => [...prev, counterparty.id]);
        } else {
          setSelectedIds((prev) => prev.filter((i) => i !== counterparty.id));
        }
      } else {
        if (onPick) {
          setLoadingCounterpartyId(counterparty.id);
          await onPick(counterparty);
          setLoadingCounterpartyId(null);

          drawer.pop();
        } else {
          drawer.open(phonebookDrawerTemplates.counterparty({ counterpartyId: counterparty.id }));
        }
      }
    },
    [drawer, onPick, selectedIds, selectMode.state],
  );

  const drawerHeader = document.querySelector('.' + PHONEBOOK_COUNTERPARTIES_HEADER_CLASSNAME);

  const handleSelectAll = useCallback(() => {
    setSelectedIds(counterpartiesList.map((i) => i.id));
  }, [counterpartiesList]);

  const handleClearSelected = useCallback(() => {
    setSelectedIds([]);
  }, []);

  const isAllListSelected = useMemo(
    () => counterpartiesList.length === selectedIds.length,
    [counterpartiesList, selectedIds],
  );

  const handleCancelSelecting = useCallback(() => {
    selectMode.off();
    setSelectedIds([]);
  }, [selectMode]);

  const handlePressMassDelete = useCallback(() => {
    drawer.open(
      commonDrawerTemplates.confirmation({
        title: translate('PHONEBOOK_COUNTERPARTY_MASS_DELETE'),
        description: translate('PHONEBOOK_COUNTERPARTY_MASS_DELETE_SUBTITLE'),
        imageProps: { name: 'cloud', path: 'common' },
        buttons: [
          {
            children: translate('YES'),
            variant: 'creamyBlack',
            handleLoading: true,
            onClick: async () => {
              const { success } = await dispatch(requestDeleteCounterparties(selectedIds));
              if (success) {
                selectMode.off();
                setSelectedIds([]);
                refreshList();
                successToast(
                  translate('PHONEBOOK_COUNTERPARTY_MASS_DELETED', { count: selectedIds.length }),
                );
                drawer.pop({ key: 'confirmation' });
              }
            },
          },
          {
            children: translate('NO'),
            variant: 'creamyBlack',
            onClick: () => {
              drawer.pop();
            },
          },
        ],
      }),
    );
  }, [dispatch, selectMode, drawer, translate, selectedIds, refreshList]);

  const isPhonebookEmpty = useMemo(
    () => !isLoading && !debouncing.state && counterpartiesList.length === 0 && !searchQuery.length,
    [isLoading, debouncing.state, counterpartiesList.length, searchQuery.length],
  );
  const noResult = useMemo(
    () => !isLoading && counterpartiesList.length === 0 && searchQuery.length,
    [isLoading, counterpartiesList.length, searchQuery.length],
  );
  const showSkeletonLoading = useMemo(
    () => (debouncing.state || isLoading) && counterpartiesList.length === 0,
    [isLoading, debouncing.state, counterpartiesList.length],
  );

  const refreshListOnlyForFirstPage = useCallback(() => {
    if (page === 0) {
      refreshList();
    }
  }, [refreshList, page]);

  useEffect(() => {
    const unsub = eventEmitter.subscribe(
      eventEmitterEventNames.phonebookCounterpartyCreated,
      refreshListOnlyForFirstPage,
    );

    return () => {
      unsub();
    };
  }, [refreshListOnlyForFirstPage]);

  return (
    <div className="flex-1 column gap-3 jcsb">
      {drawerHeader && !isPhonebookEmpty
        ? createPortal(
            <div className="row gap-2 aic">
              {!selectMode.state ? (
                <span className="label cyanBlue pointer" onClick={selectMode.on}>
                  {translate('SELECT')}
                </span>
              ) : (
                <>
                  {isAllListSelected ? (
                    <span className="label cyanBlue pointer" onClick={handleClearSelected}>
                      {translate('CANCEL_SELECTION')}
                    </span>
                  ) : (
                    <span className="label cyanBlue pointer" onClick={handleSelectAll}>
                      {translate('SELECT_ALL')}
                    </span>
                  )}

                  <span className="label cyanBlue pointer" onClick={handleCancelSelecting}>
                    {translate('CANCEL')}
                  </span>
                </>
              )}
            </div>,
            drawerHeader,
          )
        : null}
      {isPhonebookEmpty ? (
        <div className="column flex-1 jcc gap-3">
          <Icon name="book" size={72} color="grey400" />
          <p className="tac grey-400 font-semibold">{translate('PHONEBOOK_EMPTY')}</p>
        </div>
      ) : (
        <div className="column flex-1 gap-3">
          <TextInput
            startAdornment={<Icon className={classes.searchIcon} name="search" size={16} />}
            value={searchQuery}
            onChange={handleSearchQueryChange}
            placeholder={translate('PHONEBOOK_SEARCH_COUNTERPARTY_PLACEHOLDER')}
            withClearing
            endAdornment={
              <div className={clsx(classes.searchLoader, debouncing.state && classes.active)}>
                <Loader size={20} />
              </div>
            }
          />

          {showSkeletonLoading ? (
            <div className="gap-3 flexScrollable">
              <SkeletonList />
            </div>
          ) : noResult ? (
            <NoResultLabel findString={searchQuery} />
          ) : (
            <div onScroll={onInfinityScroll} className={classes.list}>
              {counterpartiesList.map((c) => {
                const [fName, lName] = c.name.split(' ');
                const checked = selectedIds.includes(c.id);
                const disabled = isNebeusUserFilter && !c.isNebeusUser;
                return (
                  <div
                    key={c.id}
                    className={clsx(classes.counterparty, disabled ? 'disabled' : null)}
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      handleClickCounterparty(c);
                    }}
                  >
                    <Loader overlap active={loadingCounterpartyId === c.id} />
                    <Smooth
                      isVisible={selectMode.state}
                      animation="width"
                      animationValueCorrector={16}
                    >
                      <CheckBox checked={checked} />
                    </Smooth>
                    <div className={classes.avatar}>
                      <span>
                        {fName[0]}
                        {lName?.[0]}
                      </span>
                    </div>
                    <div className={classes.name}>
                      <span>{c.name}</span>
                      {c.email && <span>{c.email}</span>}
                    </div>
                    {c.isNebeusUser && (
                      <Icon className={classes.nebeusIcon} name="nebeus" color="green" />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      {selectMode.state ? (
        <div className="column gap-1">
          <Button onClick={handlePressMassDelete} disabled={!selectedIds.length}>
            {translate('PHONEBOOK_COUNTERPARTY_MASS_DELETE')}
          </Button>
          <Button variant="creamyBlack" onClick={handleCancelSelecting}>
            {translate('CANCEL')}
          </Button>
        </div>
      ) : (
        <Button onClick={handlePressAddCounterparty}>
          {translate('PHONEBOOK_ADD_COUNTERPARTY')}
        </Button>
      )}
    </div>
  );
};

export default Counterparties;
