import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { requestCreateDigitalWallet } from 'modules/accounts/store/thunks';
import useDrawer from 'modules/app/hooks/useDrawer';
import { selectCurrentIbanAccountProvider } from 'modules/digitalAccount/store/selectors';
import { IbanAccountProviderId } from 'modules/digitalAccount/types';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { Button, DrawerHeader, Icon, Image } from 'components/ui';

import useFlag from 'hooks/useFlag';
import useVerificationCheck from 'hooks/useVerificationCheck';

import { useTranslation } from 'libs/i18n';
import { errorToast, successToast } from 'libs/toast';

import classes from './IbanAccountCreate.module.scss';

const IbanAccountCreate = () => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const ibanAccountProvider = useSelector(selectCurrentIbanAccountProvider);

  const checkVerification = useVerificationCheck();

  const benefitsList = useMemo(
    () =>
      ibanAccountProvider?.id === IbanAccountProviderId.quicko
        ? [
            {
              label: translate('IBAN_ACCOUNT_BENEFIT_1_TITLE'),
              description: translate('IBAN_ACCOUNT_BENEFIT_1_DESCRIPTION'),
              icon: 'refresh-square',
            },
            {
              label: translate('IBAN_ACCOUNT_BENEFIT_2_TITLE'),
              description: translate('IBAN_ACCOUNT_BENEFIT_2_DESCRIPTION'),
              icon: 'transfer-arrows',
            },
            {
              label: translate('IBAN_ACCOUNT_BENEFIT_3_TITLE'),
              description: translate('IBAN_ACCOUNT_BENEFIT_3_DESCRIPTION'),
              icon: 'people',
            },
            {
              label: translate('IBAN_ACCOUNT_BENEFIT_4_TITLE'),
              description: translate('IBAN_ACCOUNT_BENEFIT_4_DESCRIPTION'),
              icon: 'bank2',
            },
            {
              label: translate('IBAN_ACCOUNT_BENEFIT_5_TITLE'),
              description: translate('IBAN_ACCOUNT_BENEFIT_5_DESCRIPTION'),
              icon: 'free',
            },
          ]
        : [
            {
              label: translate('IBAN_ACCOUNT_BENEFIT_1_TITLE_PROVIDER_2'),
              description: translate('IBAN_ACCOUNT_BENEFIT_1_DESCRIPTION_PROVIDER_2'),
              icon: 'refresh-square',
            },
            {
              label: translate('IBAN_ACCOUNT_BENEFIT_2_TITLE_PROVIDER_2'),
              description: translate('IBAN_ACCOUNT_BENEFIT_2_DESCRIPTION_PROVIDER_2'),
              icon: 'transfer-arrows',
            },
            {
              label: translate('IBAN_ACCOUNT_BENEFIT_3_TITLE_PROVIDER_2'),
              description: translate('IBAN_ACCOUNT_BENEFIT_3_DESCRIPTION_PROVIDER_2'),
              icon: 'free',
            },
            {
              label: translate('IBAN_ACCOUNT_BENEFIT_4_TITLE_PROVIDER_2'),
              description: translate('IBAN_ACCOUNT_BENEFIT_4_DESCRIPTION_PROVIDER_2'),
              icon: 'bank2',
            },
          ],
    [ibanAccountProvider, translate],
  );

  const loading = useFlag(false);

  const handleSubmit = useCallback(async () => {
    if (!checkVerification()) {
      return;
    }

    loading.on();
    const { success } = await dispatch(requestCreateDigitalWallet('EUR'));

    loading.off();

    if (success) {
      drawer.close();
      successToast(translate('IBAN_ACCOUNT_CREATING_SUCCESS'));
    }
  }, [drawer, dispatch, checkVerification, translate, loading]);

  useEffect(() => {
    if (!ibanAccountProvider) {
      errorToast(translate('ERROR_SOMETHING_WRONG_TRY_LATER'));
      drawer.close();
    }

    // eslint-disable-next-line
  }, []);

  return !ibanAccountProvider ? null : (
    <div className={classes.root}>
      <Image className={classes.bg} name="createIbanBgDark" path="digitalAccount" />
      <div className={classes.body}>
        <DrawerHeader
          title={translate(
            ibanAccountProvider.id === IbanAccountProviderId.quicko
              ? 'IBAN_ACCOUNT_CREATING_TITLE'
              : 'IBAN_ACCOUNT_CREATING_TITLE_PROVIDER_2',
          )}
          showBackButton
          showCloseButton={false}
          className={classes.header}
        />

        <div className={classes.content}>
          <div className="column gap-3">
            <p className={classes.subtitle}>{translate('IBAN_ACCOUNT_CREATING_SUBTITLE')}</p>
            <div className={classes.benefits}>
              {benefitsList.map((b) => (
                <div key={b.icon} className={classes.benefit}>
                  <Icon name={b.icon} size={24} />
                  <div className="column gap-1">
                    <span>{b.label}</span>
                    <p>{b.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="column gap-1-5">
            {ibanAccountProvider.pricePerMonth && (
              <p className="white font-xs">
                {translate('IBAN_ACCOUNT_CREATING_WARNING', {
                  price: ibanAccountProvider.pricePerMonth,
                })}
              </p>
            )}

            <Button
              className={classes.button}
              variant="brightGreen"
              loading={loading.state}
              onClick={handleSubmit}
            >
              {translate('CONTINUE')}
            </Button>
            <AgreementText variant="modulR" light />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IbanAccountCreate;
