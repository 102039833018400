import { CurrencyNetwork } from 'modules/payment/types';

import { getTranslation } from 'libs/i18n';

// import { getCurrencyLabelByCode } from 'utils/currency';
import { CryptoCurrencyCode, CurrencyCode } from 'types';

// export enum cryptoNetworks {
//   ERC20 = 'Ethereum ERC-20',
//   AVAXChain = 'Avalanche C-Chain',
// }
//
// const networkByCurrencyCode: { [key in CryptoCurrencyCode]?: string } = {
//   USDC: cryptoNetworks.ERC20,
//   USDT: cryptoNetworks.ERC20,
//   AVAX: cryptoNetworks.AVAXChain,
//   LINK: cryptoNetworks.ERC20,
//   AAVE: cryptoNetworks.ERC20,
//   CHZ: cryptoNetworks.ERC20,
//   GRT: cryptoNetworks.ERC20,
//   UNI: cryptoNetworks.ERC20,
//   SAND: cryptoNetworks.ERC20,
//   FTM: cryptoNetworks.ERC20,
//   APE: cryptoNetworks.ERC20,
//   '1INCH': cryptoNetworks.ERC20,
//   MATIC: cryptoNetworks.ERC20,
//   BUSD: cryptoNetworks.ERC20,
// };
//
// export const getCurrencyNetwork = (code: CryptoCurrencyCode) =>
//   networkByCurrencyCode[code] || getCurrencyLabelByCode(code);

const currencyCodeByNetwork: { [key in CurrencyNetwork['network']]?: CryptoCurrencyCode } = {
  ALGORAND: 'ALGO',
  AVALANCHE_C_CHAIN: 'AVAX',
  AVAX: 'AVAX',
  AVAX_NETWORK: 'AVAX',
  BITCOIN: 'BTC',
  BITCOIN_CASH: 'BCH',
  CELO: 'CELO',
  DASH: 'DASH',
  EOS: 'EOS',
  ERC_20: 'ETH',
  ETH: 'ETH',
  ETH_NETWORK: 'ETH',
  LITECOIN: 'LTC',
  NANOBYTE_TOKEN: 'NBT',
  POLYGON: 'MATIC',
  POLYGON_NETWORK: 'MATIC',
  RIPPLE: 'XRP',
  SOLANA: 'SOL',
  STELLAR_LUMENS: 'XLM',
  TEZOS: 'XTZ',
  TRX: 'TRX',
  TRX_NETWORK: 'TRX',
};

export const getCurrencyCodeByNetwork = (
  network: CurrencyNetwork['network'],
): CryptoCurrencyCode | null => currencyCodeByNetwork[network] || null;

const additionLabelByCurrencyCode: { [key in CurrencyCode]?: string } = {
  XRP: 'XRP TAG',
  XLM: 'XLM MEMO',
  EOS: 'EOS MEMO',
};
const additionKeyByCurrencyCode: { [key in CurrencyCode]?: string } = {
  XRP: 'dt',
  XLM: 'memoId',
  EOS: 'memoId',
};

export const getAddressAdditionLabelByCurrency = (code: CryptoCurrencyCode) =>
  additionLabelByCurrencyCode[code] || null;
export const getAddressAdditionKeyByCurrency = (code: CryptoCurrencyCode) =>
  additionKeyByCurrencyCode[code] || null;

export const getImportantLabelForSendCrypto = (
  currencyCode: CryptoCurrencyCode,
  network: CurrencyNetwork['networkName'],
) => {
  let result = getTranslation(
    additionLabelByCurrencyCode[currencyCode]
      ? 'PAYMENT_SEND_CURRENCY_IMPORTANT_ADDITIONAL_LABEL'
      : 'PAYMENT_SEND_CURRENCY_IMPORTANT_LABEL',
    {
      currencyCode,
      additional: additionLabelByCurrencyCode[currencyCode] || '',
      network,
    },
  );

  if (currencyCode === 'BUSD') {
    result += '<br/><br/>' + getTranslation('PAYMENT_SEND_BUSD_IMPORTANT_LABEL');
  }

  return result;
};
