import clsx from 'clsx';
import { Swiper } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import signClasses from 'modules/auth/views/Sign/Sign.module.scss';
import { AuthBackButton } from 'modules/auth/views/components/AuthBackButton';

import routesByName from 'constants/routesByName';

import { Loader } from 'components/ui';

import useMobile from 'hooks/useMobile';

import eventEmitter from 'utils/eventEmitter';

import classes from './SignWrapper.module.scss';

interface SignWrapperProps {
  children: ReactNode;
  loading: boolean;
  swiper: SwiperClass | null;
  onSwiper: (swiper: SwiperClass) => void;
  isLogin: boolean;
  onSlideChange?: (swiper: SwiperClass) => void;
}
export const SignWrapper: FC<SignWrapperProps> = ({
  isLogin,
  children,
  loading,
  swiper,
  onSwiper,
  onSlideChange,
}) => {
  const navigate = useNavigate();

  const isMobile = useMobile();

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = useCallback(
    (swiperInstance: SwiperClass) => {
      onSlideChange?.(swiperInstance);
      setActiveSlideIndex(swiperInstance.activeIndex);
    },
    [onSlideChange],
  );

  const handleBack = useCallback(() => {
    if (activeSlideIndex > 0) {
      swiper?.slidePrev();
    } else {
      navigate(routesByName.welcome, { replace: true });
    }
  }, [activeSlideIndex, navigate, swiper]);

  const { pathname } = useLocation();

  const isActive = pathname === (isLogin ? routesByName.signIn : routesByName.signUp);

  useEffect(() => {
    if (!isActive) {
      return;
    }
    const unsubscribe = eventEmitter.subscribe('SIGN_SLIDER_GO_BACK', handleBack);

    return () => {
      unsubscribe();
    };
  }, [isActive, handleBack]);

  const sliderNode = document.querySelector(`.${signClasses.slider}`);

  return (
    <>
      {sliderNode
        ? createPortal(
            <>
              <div className={clsx(classes.fullScreenLoader, loading && classes.active)}>
                <Loader size="xl" />
              </div>
            </>,
            sliderNode,
          )
        : null}

      <div className={classes.sliderWrapper}>
        <Swiper
          onSlideChange={handleSlideChange}
          onSwiper={onSwiper}
          className={classes.slider}
          slidesPerView={1}
          allowTouchMove={false}
          spaceBetween={32}
          autoHeight={!isMobile}
          preventClicks={false}
          preventClicksPropagation={false}
        >
          <div className={classes.backButtonWrapper}>
            <AuthBackButton
              className={clsx(classes.backButton, activeSlideIndex > 0 && classes.visible)}
            />
          </div>
          {children}
        </Swiper>
      </div>
    </>
  );
};
