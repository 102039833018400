import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import {
  requestTerminateBankCard as requestCloseCryptoBankCard,
  requestBankCards as requestCryptoBankCards,
} from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard } from 'modules/cryptoBankCard/types';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { Button, Image } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatDDMMYY } from 'utils/date';

import classes from './TerminateBankCard.module.scss';

export interface TerminateBankCardProps {
  bankCard: CryptoBankCard;
  finalConfirmation?: boolean;
}

const TerminateBankCard: FC<TerminateBankCardProps> = ({ finalConfirmation = false, bankCard }) => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const loading = useFlag(false);

  const handleConfirm = useCallback(() => {
    drawer.replace(
      commonDrawerTemplates.bankCardTerminate({
        finalConfirmation: true,
        bankCard,
      }),
    );
  }, [bankCard, drawer]);

  const handleFinalConfirm = useCallback(async () => {
    loading.on();
    const { success } = await dispatch(requestCloseCryptoBankCard(bankCard.id));
    if (success) {
      drawer.close();
      dispatch(requestCryptoBankCards());
    }
  }, [dispatch, loading, bankCard, drawer]);

  return (
    <div className={classes.root}>
      <div className="column gap-1">
        <p className="darkGrey">{translate('BANK_CARD_TERMINATE_SUBTITLE')}</p>
        {bankCard.nextPaymentDate && (
          <p className="darkGrey">
            {translate('BANK_CARD_TERMINATE_WARNING', {
              nextPaymentDate: formatDDMMYY(new Date(bankCard.nextPaymentDate)),
            })}
          </p>
        )}
      </div>
      <Image
        className={classes.cardImg}
        name={bankCard.isVirtual ? 'cryptoVirtualBankCard' : 'cryptoBankCard'}
        path="cryptoBankCard"
      />

      <div className="column gap-1-5">
        <div className="row aic gap-1-5">
          {finalConfirmation ? (
            <Button
              loading={loading.state}
              fullWidth
              variant="darkGreyOutlined"
              onClick={handleFinalConfirm}
            >
              {translate('CONFIRM')}
            </Button>
          ) : (
            <>
              <Button fullWidth variant="darkGreyOutlined" onClick={handleConfirm}>
                {translate('YES')}
              </Button>
              <Button
                fullWidth
                variant="darkGreyOutlined"
                onClick={() => {
                  drawer.pop();
                }}
              >
                {translate('NO')}
              </Button>
            </>
          )}
        </div>
        <AgreementText />
      </div>
    </div>
  );
};

export default TerminateBankCard;
