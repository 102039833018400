import { FC, useMemo } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import { LoanItem } from 'modules/loans/types';
import { LoanHeader } from 'modules/loans/views/components/LoanHeader';

import { Button, SummaryCard } from 'components/ui';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel } from 'utils/currency';

import classes from './LoanGetSuccess.module.scss';

export interface LoanGetSuccessProps {
  loan: LoanItem;
}

const LoanGetSuccess: FC<LoanGetSuccessProps> = ({ loan }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const summary = useMemo<SummaryCardItem[]>(
    () => [
      {
        label: translate('LOANS_LOAN_EXCHANGE_RATE'),
        value: formatCurrencyWithLabel(loan.exchangeRate || 0, loan.loanCurrency),
        currencyCode: loan.loanCurrency,
      },
      {
        label: translate('LOANS_LOAN_TERM'),
        value: translate('DATE_MONTH_PLURAL', { count: loan.termMonth }),
      },
      {
        label: translate('LOANS_INTEREST_RATE'),
        value: `${loan.interestRate}%`,
      },
      {
        label: translate('LOANS_INTEREST_LTV_FULL'),
        value: `${loan.ltv}%`,
      },
    ],
    [translate, loan],
  );

  return (
    <div className={classes.root}>
      <div className="flexScrollable gap-3">
        <p className={classes.subtitle}>{translate('LOAN_GET_SUCCESS_SUBTITLE')}</p>
        <div className="outlinedCard column gap-3">
          <LoanHeader loan={loan} />
          <SummaryCard variant="borderless" config={summary} />
        </div>
      </div>
      <Button fullWidth onClick={sidebar.close}>
        {translate('CLOSE')}
      </Button>
    </div>
  );
};

export default LoanGetSuccess;
