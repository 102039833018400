import { FC, ReactNode } from 'react';

import { LoanItem } from 'modules/loans/types';
import LoanTypeAndId from 'modules/loans/views/components/LoanTypeAndId';

import { AmountLabel } from 'components/ui';

import { CurrencyCode } from 'types';

interface LoanHeaderProps {
  loan: LoanItem;
  amount?: number;
  currencyCode?: CurrencyCode;
  endAdornment?: ReactNode;
}
export const LoanHeader: FC<LoanHeaderProps> = ({
  loan,
  endAdornment,
  amount = loan.loanAmount,
  currencyCode = loan.loanCurrency,
}) => {
  return (
    <div className="column gap-1-5">
      <LoanTypeAndId type={loan.templateType} id={loan.id} />
      <div className="row aic jcsb gap-2">
        <AmountLabel size="xl" amount={amount} currencyCode={currencyCode} showCurrencySymbol />
        {endAdornment}
      </div>
    </div>
  );
};
