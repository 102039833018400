import { AppStore } from 'store';

export const selectReactNativeInsets = (state: AppStore) => state.reactNative.insets;
export const selectReactNativeNotificationToken = (state: AppStore) =>
  state.reactNative.notificationToken;

export const selectRNBioSettings = (state: AppStore) => ({
  bioType: state.reactNative.bioType,
  bioEnabled: state.reactNative.bioEnabled,
  passcodeEnabled: state.reactNative.passcodeEnabled,
});
