import clsx from 'clsx';

import React, { memo } from 'react';

import { voidFunc } from 'types';

import classes from './FlipCard.module.scss';

export enum FlipSide {
  FRONT,
  BACK,
}

interface FlipCardProps {
  side: FlipSide;
  front: React.ReactElement;
  back: React.ReactElement;
  showSideIndicator?: boolean;
  className?: string;
  containerClassName?: string;
  onClick: voidFunc;
}

export const FlipCard = memo<FlipCardProps>(
  ({ side, front, back, className, containerClassName, onClick }) => {
    return (
      <div className={clsx(classes.root, className)} onClick={onClick}>
        <div className={clsx(classes.sideContainer, containerClassName)}>
          <div
            className={clsx(classes.side, classes.front, side === FlipSide.FRONT && classes.active)}
          >
            {front}
          </div>
          <div
            className={clsx(classes.side, classes.back, side === FlipSide.BACK && classes.active)}
          >
            {back}
          </div>
        </div>
      </div>
    );
  },
);
