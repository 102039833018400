import { useEffect, useRef } from 'react';

const usePrevious = <T>(value: T, options?: { valueAlwaysTruly?: true }) => {
  const ref = useRef<T>(value);
  useEffect(() => {
    if (options?.valueAlwaysTruly && !value) {
      return;
    }
    ref.current = value;
    // eslint-disable-next-line
  }, [value]);
  return ref.current;
};

export default usePrevious;
