import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Switch } from 'components/ui';
import { SwitchProps } from 'components/ui/Switch';

interface SliderFieldProps extends FieldRenderProps<boolean>, SwitchProps {}

export const SwitchField: FC<SliderFieldProps> = ({ input, ...props }) => {
  return <Switch {...input} {...props} />;
};
