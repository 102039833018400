import clsx from 'clsx';

import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import { Wallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import ConfirmOTPCodeCard from 'modules/app/views/ConfirmOTPCodeCard';
import useRatesConverterToDefaultCurrency from 'modules/exchange/hooks/useRatesConverterToDefaultCurrency';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { selectVaultWallets } from 'modules/vault/store/selectors';
import { requestDeposit, requestWithdraw } from 'modules/vault/store/thunks';
import { AppStore, useDispatch } from 'store';

import { CurrencyAmountField, SubmitButton } from 'components/form';
import { Icon, Loader, SummaryCard } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import useFlag from 'hooks/useFlag';

import { getTranslation, useTranslation } from 'libs/i18n';
import {
  OtpForBankOperationRequest,
  VaultWalletDepositRequest,
  VaultWalletWithdrawRequest,
} from 'libs/swagger/nebeusApiTypes';
import yup, { makeValidate } from 'libs/yup';

import {
  MINIMUM_AMOUNT_CRYPTO,
  findWallet,
  formatCurrencyWithLabel,
  formatCurrencyWithSymbol,
} from 'utils/currency';

import classes from './VaultDepositWithdraw.module.scss';

export interface VaultDepositWithdrawProps {
  deposit: boolean;
}

interface FormValues {
  currencyAmount: CurrencyAmount;
}

interface VaultDepositWithdrawFormProps extends FormRenderProps<FormValues> {
  wallets: Wallet[];
  otpModeEnabled: boolean;
}

interface VaultSummaryCardProps {
  currencyAmount: CurrencyAmount;
  summaryBlocks: SummaryCardItem[][];
}
const VaultSummaryCard: FC<VaultSummaryCardProps> = ({ currencyAmount, summaryBlocks }) => {
  const translate = useTranslation();

  const summaryRef = useRef<HTMLDivElement>(null);
  const summaryExpanded = useFlag(false);

  return (
    <div className="column gap-2">
      <span className="label">{translate('AMOUNT')}</span>
      <div className="outlinedCard column">
        <div className="row gap-3 jcsb aic">
          <span className={classes.summaryTitle}>
            <b>
              {formatCurrencyWithSymbol(currencyAmount.amount, currencyAmount.currency, {
                withZeros: true,
              })}
            </b>
          </span>
          <Icon
            onClick={summaryExpanded.toggle}
            className={clsx(classes.chevron, summaryExpanded.state && classes.expanded)}
            name="chevronRightSquaredThin"
          />
        </div>

        <div
          ref={summaryRef}
          className={classes.summary}
          style={{ height: summaryExpanded.state ? summaryRef.current?.scrollHeight : 0 }}
        >
          <div className={classes.inner}>
            {summaryBlocks.map((block, i) => (
              <SummaryCard key={i} config={block} variant="borderless" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const VaultDepositWithdrawForm: FC<VaultDepositWithdrawFormProps> = ({ handleSubmit, wallets }) => {
  const translate = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="column gap-3 flex-1 jcsb">
      <Field
        name="currencyAmount"
        component={CurrencyAmountField}
        label={getTranslation('AMOUNT')}
        currenciesList={wallets}
        showBalance
        showMaxAmountButton
        currencyPickerTitle={translate('SELECT_ACCOUNT')}
      />
      <SubmitButton className="mt-3" fullWidth />
    </form>
  );
};

const selectMainCryptoWallets = (state: AppStore) =>
  selectWallets(state).filter((w) => w.currencyCode === 'BTC' || w.currencyCode === 'ETH');

const initialValues: FormValues = {
  currencyAmount: {
    amount: '',
    currency: 'BTC',
  },
};
const VaultDepositWithdraw: FC<VaultDepositWithdrawProps> = ({ deposit }) => {
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const translate = useTranslation();

  const otpCode = useRef('');
  const withdrawAmountInDefaultCurrency = useRef<number | null>(null);
  const loading = useFlag(false);
  const otpMode = useFlag(false);

  const [formValues, setFormValues] = useState<FormValues | null>(null);

  const mainCryptoWallets = useSelector(selectMainCryptoWallets);
  const vaultWallets = useSelector(selectVaultWallets);

  const wallets: Wallet[] = deposit ? mainCryptoWallets : vaultWallets;

  const { convertToDefaultCurrency, defaultCurrencyCode } = useRatesConverterToDefaultCurrency();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!deposit && !otpCode.current) {
        withdrawAmountInDefaultCurrency.current = await convertToDefaultCurrency(
          values.currencyAmount.currency,
          +values.currencyAmount.amount,
        );
        setFormValues(values);
        otpMode.on();

        return;
      }
      const thunk = deposit ? requestDeposit : requestWithdraw;

      const payload: VaultWalletWithdrawRequest | VaultWalletDepositRequest = {
        currencyCode: values.currencyAmount.currency,
        amount: +values.currencyAmount.amount,
        otpCode: undefined,
      };

      if (!deposit) {
        payload['otpCode'] = otpCode.current;
      }

      const { success } = await dispatch(thunk(payload));

      const amountInDefaultCurrency = await convertToDefaultCurrency(
        values.currencyAmount.currency,
        +values.currencyAmount.amount,
      );

      if (success) {
        drawer.replace(
          paymentDrawerTemplates.finishedOperation({
            currencyCode: values.currencyAmount.currency,
            amount: +values.currencyAmount.amount * (deposit ? 1 : -1),
            title: translate(
              deposit ? 'VAULT_OPERATION_DEPOSITED' : 'VAULT_OPERATION_WITHDRAW_DONE',
              { currencyCode: values.currencyAmount.currency },
            ),
            description: deposit
              ? formatCurrencyWithSymbol(amountInDefaultCurrency, defaultCurrencyCode)
              : translate('VAULT_OPERATION_WITHDRAW_DONE_TEXT'),
            isDeposit: deposit,
            isSuccess: true,

            summaryBlocks: [
              [
                {
                  label: translate('CHARGE_ACCOUNT'),
                  value: translate('CURRENCY_ACCOUNT', {
                    currencyCode: values.currencyAmount.currency,
                  }),
                },
                {
                  label: translate('CHARGE_AMOUNT'),
                  value: formatCurrencyWithLabel(
                    +values.currencyAmount.amount * -1,
                    values.currencyAmount.currency,
                  ),
                },
                {
                  label: translate('ACCOUNT_OPERATION_STATUS'),
                  value: translate('PAYMENT_OPERATION_COMPLETED_SUCCESSFULLY'),
                },
              ],
            ],
          }),
        );
      }
    },
    [convertToDefaultCurrency, defaultCurrencyCode, translate, otpMode, dispatch, drawer, deposit],
  );

  const validate = useCallback(
    (values: FormValues) => {
      const wallet = findWallet(wallets, values.currencyAmount.currency);

      const schema = yup.object().shape({
        currencyAmount: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .min(
              MINIMUM_AMOUNT_CRYPTO,
              getTranslation('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(
                  MINIMUM_AMOUNT_CRYPTO,
                  values.currencyAmount.currency,
                ),
              }),
            )
            .max(wallet?.amount || 0, getTranslation('VALIDATION_INSUFFICIENT_FUNDS'))
            .requiredDefault(),
        }),
      });

      return makeValidate(schema)(values);
    },
    [wallets],
  );

  const handleCodeConfirmed = useCallback(
    async (code: string) => {
      otpCode.current = code;
      if (formValues) {
        await handleSubmit(formValues);
      }
    },
    [handleSubmit, formValues],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <VaultDepositWithdrawForm {...formProps} wallets={wallets} otpModeEnabled={otpMode.state} />
    ),
    [otpMode.state, wallets],
  );

  const withdrawSummary = useMemo<VaultSummaryCardProps['summaryBlocks']>(() => {
    if (!formValues) {
      return [];
    }

    return [
      [
        {
          label: translate('PAYMENT_FROM'),
          value: translate('NEBEUS_COLD_STORAGE'),
        },
        {
          label: translate('PAYMENT_TO'),
          value: translate('NEBEUS_ACCOUNT', { currencyCode: formValues.currencyAmount.currency }),
        },
      ],
      [
        {
          label: translate('PAYMENT_YOU_WILL_GET'),
          value: formatCurrencyWithSymbol(
            formValues.currencyAmount.amount,
            formValues.currencyAmount.currency,
          ),
          subValue: formatCurrencyWithSymbol(
            withdrawAmountInDefaultCurrency.current || 0,
            defaultCurrencyCode,
          ),
        },
        {
          label: translate('PAYMENT_NEBEUS_FEE'),
          value: formatCurrencyWithSymbol(0, formValues.currencyAmount.currency),
          subValue: formatCurrencyWithSymbol(0, defaultCurrencyCode),
        },
        {
          label: translate('PAYMENT_TOTAL_WITHDRAWN'),
          value: formatCurrencyWithSymbol(
            formValues.currencyAmount.amount,
            formValues.currencyAmount.currency,
          ),
          subValue: formatCurrencyWithSymbol(
            withdrawAmountInDefaultCurrency.current || 0,
            defaultCurrencyCode,
          ),
        },
      ],
    ];
  }, [formValues, translate, defaultCurrencyCode]);

  const sendOtpReqPayload = useMemo<OtpForBankOperationRequest | null>(
    () =>
      formValues
        ? {
            currencyCode: formValues.currencyAmount.currency,
            amount: +formValues.currencyAmount.amount,
            relatedEntityType: 'VaultWalletTransaction',
          }
        : null,
    [formValues],
  );
  return (
    <div className="relative column flex-1 jcsb gap-3 mt-1">
      <Loader overlap active={loading.state} />
      <div className="column gap-3 flex-1">
        <p
          className={classes.text}
          dangerouslySetInnerHTML={{
            __html: translate(deposit ? 'VAULT_DEPOSIT_TEXT' : 'VAULT_WITHDRAW_TEXT'),
          }}
        />
        {otpMode.state && formValues ? (
          <div className="column gap-3">
            <VaultSummaryCard
              currencyAmount={formValues.currencyAmount}
              summaryBlocks={withdrawSummary}
            />
            {sendOtpReqPayload && (
              <ConfirmOTPCodeCard
                sendOtpReqPayload={sendOtpReqPayload}
                loading={loading}
                onConfirmed={handleCodeConfirmed}
              />
            )}
          </div>
        ) : (
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={renderForm}
            validate={validate}
          />
        )}
      </div>
    </div>
  );
};

export default VaultDepositWithdraw;
