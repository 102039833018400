import clsx from 'clsx';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthLogo } from 'modules/auth/views/components/AuthLogo';
import { isReactNative } from 'modules/reactNative/utils';

import routesByName from 'constants/routesByName';

import { Button, Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './Welcome.module.scss';

const Welcome = () => {
  const translate = useTranslation();
  const navigate = useNavigate();

  const handleSignUp = useCallback(() => {
    navigate(routesByName.signUp);
  }, [navigate]);

  const handleSignIn = useCallback(() => {
    navigate(routesByName.signIn);
  }, [navigate]);

  return (
    <div className={clsx(classes.root, isReactNative && classes.reactNative)}>
      <div className="column gap-3 aic">
        <AuthLogo />
        <Image name="earth3D" path="common" />
        <h1>{translate('WELCOME_TITLE')}</h1>
      </div>
      <div className="column gap-1 w100">
        <Button onClick={handleSignUp} fullWidth>
          {translate('SIGN_SIGN_UP')}
        </Button>
        <Button onClick={handleSignIn} fullWidth variant="creamyBlack">
          {translate('SIGN_SIGN_IN')}
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
