import {
  transformStakingItems,
  transformStakingTemplates,
} from 'modules/staking/services/transformers';
import {
  SingleStatistic,
  StakingHistoryItem,
  StakingItem,
  Statistic,
  Template,
} from 'modules/staking/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  GetStakingSingleStatisticUsingQueryParams,
  GetStakingTransactionsUsingQueryParams,
  StakingFullStatisticResponse,
  StakingRequest,
  StakingResponseList,
  StakingSingleStatisticResponse,
  StakingTransactionResponseList,
  TemplatesResponse,
} from 'libs/swagger/nebeusApiTypes';

// cli-import

const stakingServices = {
  getTemplates: (): Promise<Template[]> =>
    AxiosInstance.get<TemplatesResponse, TemplatesResponse>('/v1/deposit/template/search').then(
      transformStakingTemplates,
    ),
  getStakingItems: (): Promise<StakingItem[]> =>
    AxiosInstance.get<StakingResponseList, StakingResponseList>('/v1/staking').then(
      transformStakingItems,
    ),
  stake: (payload: StakingRequest) => AxiosInstance.post('/v1/staking/stake', payload),
  unstake: (payload: StakingRequest) => AxiosInstance.post('/v1/staking/unstake', payload),

  getSingleStatistic: (
    params: GetStakingSingleStatisticUsingQueryParams,
  ): Promise<SingleStatistic> =>
    AxiosInstance.get<StakingSingleStatisticResponse, StakingSingleStatisticResponse>(
      '/v1/staking/statistic',
      { params },
    ).then((response) => response.stakingSingleStatistic),
  getStatistic: (): Promise<Statistic> =>
    AxiosInstance.get<StakingFullStatisticResponse, StakingFullStatisticResponse>(
      '/v1/staking/statistic/all',
    ).then((response) => response.stakingFullStatistic),
  getHistory: (params: GetStakingTransactionsUsingQueryParams): Promise<StakingHistoryItem[]> => {
    return AxiosInstance.get<StakingTransactionResponseList, StakingTransactionResponseList>(
      '/v1/staking/transaction/history',
      { params },
    ).then((response) => response.stakingTransactions);
  },
  // cli-service
};

export default stakingServices;
