import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { Profile } from 'modules/user/types';

import { getTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';

import { CurrencyCode } from 'types';

const getDepositResultDrawerTemplate = ({
  isSuccess,
  amount,
  amountWithCommission,
  commissionAmount,
  currencyCode,
  userProfile,
  errorMessage,
  bankCardFullName,
  bankCardNumber,
}: {
  isSuccess: boolean;
  amount: number;
  amountWithCommission?: number;
  commissionAmount?: number;
  currencyCode: CurrencyCode;
  userProfile: Profile;
  errorMessage?: string;
  bankCardFullName: string;
  bankCardNumber: string;
}) => {
  return paymentDrawerTemplates.finishedOperation({
    currencyCode,
    amount,
    isDeposit: true,
    isSuccess,
    navigationHeaderLabel: isSuccess ? getTranslation('PAYMENT_OPERATION_INITIATED') : undefined,
    description: isSuccess
      ? getTranslation('PAYMENT_DEPOSIT_BANK_CARD_IMPORTANT_INFO')
      : errorMessage || getTranslation('ERROR_SOMETHING_BROKE'),
    summaryBlocks: [
      [
        {
          label: getTranslation('DESCRIPTION'),
          value: getTranslation('PAYMENT_DEPOSIT_CURRENCY_BY_BANK_CARD', { currencyCode }),
        },
        { label: getTranslation('DATE_&_TIME'), value: formatDDMMYY_HHMM(new Date()) },
        {
          label: getTranslation('ACCOUNT_OPERATION_STATUS'),
          value: getTranslation(
            isSuccess ? 'PAYMENT_OPERATION_INITIATED' : 'PAYMENT_OPERATION_FAILED',
          ),
        },
      ],
      [
        {
          label: getTranslation('RECIPIENT'),
          value: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
        },
        { label: getTranslation('SENDER'), value: bankCardFullName },
        { label: getTranslation('SENDER_BANK_CARD_NUMBER'), value: bankCardNumber },
      ],
      [
        {
          label: getTranslation('PAYMENT_YOU_WILL_GET'),
          value: formatCurrencyWithSymbol(amountWithCommission || amount, currencyCode),
        },
        {
          label: getTranslation('PAYMENT_NEBEUS_FEE'),
          value: formatCurrencyWithSymbol(commissionAmount || 0, currencyCode),
        },
        {
          label: getTranslation('PAYMENT_DEPOSIT_AMOUNT'),
          value: formatCurrencyWithSymbol(amount, currencyCode),
        },
      ],
    ],
  });
};

export default getDepositResultDrawerTemplate;
