import { createSlice } from '@reduxjs/toolkit';

import { generateRequestDataReducer } from 'store/generators/reducers';
import { initialApiInfinityListState, initialArrayApiRequestState } from 'store/generators/utils';
import { initialApiRequestState } from 'store/generators/utils';

import { CryptoRentingState, RentingItem, Statistic, Template } from '../types';

// cli-import

const initialState: CryptoRentingState = {
  templates: initialArrayApiRequestState,
  statistic: initialApiRequestState,

  rentingItemsActive: initialApiInfinityListState,
  rentingItemsHistory: initialApiInfinityListState,
  // cli-state
};

const cryptoRentingSlice = createSlice({
  name: 'cryptoRenting',
  initialState,
  reducers: {
    resetState: (state) => {
      state = initialState;
      return state;
    },
    updateTemplates: generateRequestDataReducer<CryptoRentingState, Template[]>({
      reducerPath: 'templates',
    }),
    updateStatistic: generateRequestDataReducer<CryptoRentingState, Statistic>({
      reducerPath: 'statistic',
    }),
    updateRentingActiveItems: generateRequestDataReducer<CryptoRentingState, RentingItem[]>({
      reducerPath: 'rentingItemsActive',
    }),
    updateRentingHistoryItems: generateRequestDataReducer<CryptoRentingState, RentingItem[]>({
      reducerPath: 'rentingItemsHistory',
    }),

    // cli-reducer
  },
});

export default cryptoRentingSlice.reducer;
export const cryptoRentingActions = cryptoRentingSlice.actions;
