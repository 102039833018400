import { FC, useCallback } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import useDrawer from 'modules/app/hooks/useDrawer';
import { requestChangePinCode } from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard } from 'modules/cryptoBankCard/types';
import { useDispatch } from 'store';

import { SubmitButton, TextInputField } from 'components/form';

import { getTranslation, useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';
import yup, { makeValidate } from 'libs/yup';

import classes from './CryptoBankCardChangePinCode.module.scss';

interface FormValues {
  oldPin: string;
  newPin: string;
}

const initialValues: FormValues = {
  oldPin: '',
  newPin: '',
};

interface CryptoBankCardChangePinCodeFormProps extends FormRenderProps<FormValues> {}
const CryptoBankCardChangePinCodeForm: FC<CryptoBankCardChangePinCodeFormProps> = ({
  handleSubmit,
}) => {
  const translate = useTranslation();

  return (
    <form className="column gap-3" onSubmit={handleSubmit}>
      <div className="column gap-2">
        <Field
          name="oldPin"
          component={TextInputField}
          label={translate('BANK_CARD_OLD_PIN')}
          maxLength={4}
          inputMode="numeric"
        />
        <Field
          name="newPin"
          component={TextInputField}
          label={translate('BANK_CARD_NEW_PIN')}
          maxLength={4}
          inputMode="numeric"
        />
      </div>
      <SubmitButton />
    </form>
  );
};
export interface CryptoBankCardChangePinCodeProps {
  bankCard: CryptoBankCard;
}

const CryptoBankCardChangePinCode: FC<CryptoBankCardChangePinCodeProps> = ({ bankCard }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const drawer = useDrawer();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const { success } = await dispatch(
        requestChangePinCode({ id: bankCard.id, newPin: values.newPin, oldPin: values.oldPin }),
      );
      if (success) {
        successToast(translate('BANK_CARD_PIN_CHANGE_SUCCESS'));
        drawer.pop();
      }
    },
    [dispatch, drawer, bankCard, translate],
  );

  const validate = useCallback((values: FormValues) => {
    const schema = yup.object().shape({
      oldPin: yup
        .string()
        .length(4, getTranslation('VALIDATION_DIGITS_COUNT', { count: 4 }))
        .requiredDefault()
        .integer(),

      newPin: yup
        .string()
        .length(4, getTranslation('VALIDATION_DIGITS_COUNT', { count: 4 }))
        .test(
          'match',
          getTranslation('VALIDATION_NEW_PIN_SAME_AS_OLD'),
          (newPin: string | undefined) => newPin !== values.oldPin,
        )
        .requiredDefault()
        .integer(),
    });
    return makeValidate(schema)(values);
  }, []);
  return (
    <div className={classes.root}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={CryptoBankCardChangePinCodeForm}
        validate={validate}
      />
    </div>
  );
};

export default CryptoBankCardChangePinCode;
