import { Field } from 'react-final-form';

import { TextInputField } from 'components/form';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup from 'libs/yup';

import classes from './InvoiceNotesForm.module.scss';

export const invoiceNotesValidationSchema = yup.object().shape(
  {
    invoiceNotes: yup.string().when('invoiceNotes', (val?: string) => {
      if (val && val.length > 0) {
        return yup
          .string()
          .min(4, getTranslation('VALIDATION_MIN', { min: 4 }))
          .max(200, getTranslation('VALIDATION_MAX', { max: 200 }));
      }
      return yup.string().optional();
    }),
  },
  [['invoiceNotes', 'invoiceNotes']],
);
export interface InvoiceNotesFormValues {
  invoiceNotes: string;
}
const InvoiceNotesForm = () => {
  const translate = useTranslation();

  return (
    <Field
      name="invoiceNotes"
      placeholder={translate('INVOICE_NOTES_PLACEHOLDER')}
      component={TextInputField}
      inputComponent="textarea"
      inputContainerClassName={classes.inputContainer}
    />
  );
};

export default InvoiceNotesForm;
