import authServices from 'modules/auth/services';
import { isReactNative, sendToReactNative } from 'modules/reactNative/utils';

import ApiError from 'libs/axios/ApiError';

import eventEmitter from 'utils/eventEmitter';

import { voidFunc } from 'types';

const reactNativeServices = {
  downloadBlob: (payload: { fileName: string; url: string }) => {
    const id = Date.now().toString();

    sendToReactNative('DOWNLOAD_BLOB_FILE', {
      ...payload,
      accessToken: authServices.getAccessToken(),
      id,
    });

    return new Promise((resolve) => {
      let unsubscribe: voidFunc | null = null;

      const subFunc = (resultPayload: { errorMessage: string | null; id: string }) => {
        if (resultPayload.id === id) {
          if (resultPayload.errorMessage) {
            throw new ApiError(
              resultPayload.errorMessage,
              {
                code: '',
                message: resultPayload.errorMessage,
                mdcId: '',
                status: 'fail',
              },
              400,
            );
          } else {
            resolve(true);
          }
          if (unsubscribe) {
            unsubscribe();
          }
        }
      };

      unsubscribe = eventEmitter.subscribe('DOWNLOAD_BLOB_FILE_FINISH', subFunc);
    });
  },
  scanBankCard: async (): Promise<{
    cardNumber: string;
    expDate: string;
    cardHolderName?: string;
  } | null> => {
    if (!isReactNative) {
      return null;
    }
    const id = Date.now().toString();

    sendToReactNative('SCAN_BANK_CARD', { id });
    return new Promise((resolve) => {
      let unsubscribe: voidFunc | null = null;

      const subFunc = (resultPayload: {
        card: {
          cardNumber: string;
          expDate: string;
          cardHolderName?: string;
        };
        id: string;
      }) => {
        if (resultPayload.id === id) {
          resolve(resultPayload.card);
          if (unsubscribe) {
            unsubscribe();
          }
        }
      };

      unsubscribe = eventEmitter.subscribe('SCAN_BANK_CARD_FINISH', subFunc);
    });
  },
  scanQrCode: async (): Promise<string | null> => {
    if (!isReactNative) {
      return null;
    }
    const id = Date.now().toString();

    sendToReactNative('SCAN_QR_CODE', { id });
    return new Promise((resolve) => {
      let unsubscribe: voidFunc | null = null;

      const subFunc = (resultPayload: { value: string | null; id: string }) => {
        if (resultPayload.id === id) {
          resolve(resultPayload.value);
          if (unsubscribe) {
            unsubscribe();
          }
        }
      };

      unsubscribe = eventEmitter.subscribe('SCAN_QR_CODE_FINISH', subFunc);
    });
  },
};

export default reactNativeServices;
