import { memo } from 'react';

import { Invoice } from 'modules/invoicing/types';
import { calculateInvoiceAmount } from 'modules/invoicing/utils';

import { CheckBox, SummaryCard } from 'components/ui';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { voidFunc } from 'types';

interface InvoiceCardProps {
  invoice: Invoice;
  onClick: voidFunc;
  editMode: boolean;
  pickedForDelete: boolean;
}
const InvoiceCard = memo<InvoiceCardProps>(({ invoice, onClick, pickedForDelete, editMode }) => {
  const translate = useTranslation();

  const config: SummaryCardItem[] = [
    {
      id: '1',
      label: translate('INVOICE_DATE'),
      value: invoice.invoiceDetails.fromDate,
    },
    {
      id: '2',
      label: translate('DUE_DATE'),
      value: invoice.invoiceDetails.toDate,
    },
    { id: '3', label: translate('INVOICE_COMPANY_OR_CLIENT_NAME'), value: invoice.client.name },
    {
      id: '4',
      label: translate('AMOUNT'),
      value: formatCurrencyWithSymbol(
        calculateInvoiceAmount(invoice).total,
        invoice.invoiceDetails.currencyCode,
      ),
    },
  ];
  return (
    <SummaryCard
      onClick={onClick}
      startAdornment={
        <div className="row aic jcsb gap-2">
          <h6 className="font-md">
            {translate('INVOICE')} #{invoice.invoiceDetails.number}
          </h6>
          {editMode && <CheckBox checked={pickedForDelete} />}
        </div>
      }
      config={config}
    />
  );
});

export default InvoiceCard;
