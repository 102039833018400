import { useMemo } from 'react';

import useRate from 'modules/exchange/hooks/useRate';
import { LoanTemplate } from 'modules/loans/types';
import { GetLoanFormValues } from 'modules/loans/views/LoanCalculator/';

const useLoanCheckList = (values: GetLoanFormValues, template: LoanTemplate) => {
  // Interest percent without options
  const clearInterestPercent = useMemo(() => {
    const ltv = template.ltvRange[+values.ltv];
    if (!ltv) {
      return 0;
    }

    return ltv.percent;
  }, [template.ltvRange, values.ltv]);

  const interestPercent = useMemo(() => {
    const ltv = template.ltvRange[+values.ltv];
    if (!ltv) {
      return 0;
    }

    if (values.excessCollateralRelease && values.autoManagementMC) {
      return ltv.percentWithAutoMCAndExcessCollateral;
    }
    if (values.autoManagementMC) {
      return ltv.percentWithAutoMC;
    }
    if (values.excessCollateralRelease) {
      return ltv.percentWithExcessCollateral;
    }

    return ltv.percent;
  }, [template.ltvRange, values.autoManagementMC, values.excessCollateralRelease, values.ltv]);

  const rate = useRate(values.loan.currency, values.collateral.currency);
  const loanExchangeRate = 1 / rate;

  const monthlyRepayment = useMemo(() => {
    if (interestPercent <= 0) {
      return +values.loan.amount / values.termMonth;
    }

    switch (template.type) {
      case 'FLEXIBLE': {
        const percentMonthly = interestPercent / (100 * 12);
        const pow = Math.pow(1 + percentMonthly, values.termMonth);
        const percent = (percentMonthly * pow) / (pow - 1);

        return +values.loan.amount * percent;
      }
      case 'ONLY_INTEREST': {
        return (+values.loan.amount * (interestPercent / 100)) / values.termMonth;
      }
      case 'MIRROR': {
        return (+values.loan.amount * (interestPercent / 100)) / values.termMonth;
      }
      case 'BULLET': {
        return +values.loan.amount * (interestPercent / 100) + +values.loan.amount;
      }
    }
    return 0;
  }, [interestPercent, template, values.termMonth, values.loan.amount]);

  const totalLoanRepayment = useMemo(() => {
    switch (template.type) {
      case 'FLEXIBLE': {
        return monthlyRepayment * values.termMonth;
      }
      case 'ONLY_INTEREST': {
        return monthlyRepayment * values.termMonth + +values.loan.amount;
      }
      case 'MIRROR': {
        return monthlyRepayment * values.termMonth + +values.loan.amount;
      }
      case 'BULLET': {
        return monthlyRepayment;
      }
    }
    return monthlyRepayment * values.termMonth;
  }, [values.termMonth, values.loan.amount, monthlyRepayment, template]);

  const totalLoanCost = totalLoanRepayment - Number(values.loan.amount);

  return {
    totalLoanCost,
    totalLoanRepayment,
    monthlyRepayment,
    loanExchangeRate,
    interestPercent,
    clearInterestPercent,
  };
};

export default useLoanCheckList;
