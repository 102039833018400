import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectAppLanguage } from 'modules/app/store/selectors';

import { Image } from 'components/ui';

import { LINK_NEBEUS_PUBLIC_SITE } from 'utils/links';

import classes from './AuthLogo.module.scss';

interface AuthLogoProps {}
export const AuthLogo: FC<AuthLogoProps> = () => {
  const appLanguage = useSelector(selectAppLanguage);

  return (
    <a
      className={classes.root}
      href={`${LINK_NEBEUS_PUBLIC_SITE}/${appLanguage.shortCode.toLocaleLowerCase()}`}
    >
      <Image name="nebeusLogo" type="svg" />
    </a>
  );
};
