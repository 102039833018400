import { PaymentOperationLimit, SavedCryptoWallet } from 'modules/payment/types';
import { transformList } from 'store/generators/transformers';

import {
  OperationLimitDto,
  OperationPerTransactionLimitResponseList,
  SavedWalletDto,
  SavedWalletsResponse,
} from 'libs/swagger/nebeusApiTypes';

export const transformSavedCryptoWallets = (response: SavedWalletsResponse) =>
  transformList<SavedWalletDto, SavedCryptoWallet>(response?.wallets || [], [
    ['id'],
    ['walletId', 'address'],
    ['walletName', 'label'],
    ['currency', 'currencyCode'],
  ]);
export const transformOperationLimits = (response: OperationPerTransactionLimitResponseList) =>
  transformList<OperationLimitDto, PaymentOperationLimit>(response.perTransactionLimitsInfo, [
    ['currencyCode', 'currencyCode'],
    ['maxAmount', 'max'],
    ['minAmount', 'min'],
    ['operationCode', 'operationId'],
  ]);
