import clsx from 'clsx';

import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import rentingCommonClasses from 'modules/cryptoRenting/constants/RentingCommon.module.scss';
import { RentingSetupProps } from 'modules/cryptoRenting/views/RentingSetup';
import { isReactNative } from 'modules/reactNative/utils';

const rentingSetup = (props: RentingSetupProps): DrawerTemplateReturnType => ({
  key: 'rentingSetup',
  props,
  sideBarProps: {
    swipeModal: true,
    contentClassName: clsx(
      rentingCommonClasses.rentingSideBar,
      rentingCommonClasses['templateBg-' + props.templateName],
      rentingCommonClasses.mask,
      isReactNative && rentingCommonClasses.maskNoBlur,
    ),
  },
});

const rentingHighestRewardsAd = (): DrawerTemplateReturnType => ({
  key: 'rentingHighestRewardsAd',
  sideBarProps: {
    showBackButton: false,
    swipeModal: true,
    swipeModalMaxHeight: true,
  },
});

const rentingDrawerTemplates = {
  rentingSetup,
  rentingHighestRewardsAd,
};

export default rentingDrawerTemplates;
