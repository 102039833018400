import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';

import { UserAvatar } from 'components/common';
import { Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './ReactNativeHeader.module.scss';

interface ReactNativeHeaderProps {}
export const ReactNativeHeader: FC<ReactNativeHeaderProps> = () => {
  const drawer = useDrawer();
  const translate = useTranslation();

  const handleClickAvatar = useCallback(() => {
    drawer.open(userDrawerTemplates.settings());
  }, [drawer]);
  const handleClickBell = useCallback(() => {
    drawer.open(userDrawerTemplates.notifications());
  }, [drawer]);

  return (
    <div className="row aic jcsb gap-2">
      <UserAvatar onClick={handleClickAvatar} size={48} />
      <div className="row aic gap-2">
        <div
          className={classes.earnButton}
          onClick={() => {
            drawer.open(commonDrawerTemplates.referralLinksMarketingAd());
          }}
        >
          <Icon name="present" size={16} />
          <span>{translate('REFERRAL_LINK_MARKETING_AD_SHORT_TITLE')}</span>
        </div>
        <Icon onClick={handleClickBell} className={classes.bell} name="bell" />
      </div>
    </div>
  );
};
