import clsx from 'clsx';

import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';

import { FixedField } from 'components/ui/FixedField';
import { TextInput, TextInputProps } from 'components/ui/TextInput';

import classes from './SliderInput.module.scss';

export interface SliderInputProps extends Omit<TextInputProps, 'onChange' | 'value'> {
  suffix?: string;
  step?: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  value: number;
}

export const SliderInput: FC<SliderInputProps> = ({
  value,
  suffix,
  min = 1,
  max = 100,
  step = 1,
  onChange,
  className,
  ...props
}) => {
  const handleSlide = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(+event.currentTarget.value);
    },
    [onChange],
  );

  const disabled = min === max;

  const endAdornment = useMemo(() => {
    const percentage = disabled ? 0 : ((value - min) / (max - min)) * 100;
    return (
      <input
        className={clsx(classes.slider, disabled && classes.disabled)}
        type="range"
        onChange={handleSlide}
        min={min}
        max={max}
        step={step}
        value={value}
        style={{
          background: `linear-gradient(90deg, #343434 ${percentage}%, #dfdbd1 ${percentage}%)`,
        }}
      />
    );
  }, [value, disabled, min, max, step, handleSlide]);

  const inputValue = suffix ? `${value}${suffix}` : value;

  if (min === max) {
    return <FixedField label={props.label || ''} value={inputValue.toString()} />;
  }

  return (
    <TextInput
      {...props}
      borderOnFocus={false}
      className={clsx('relative', className)}
      inputContainerClassName={clsx(classes.inputContainer, disabled && classes.disabled)}
      value={inputValue}
      disabled
      endAdornment={endAdornment}
    />
  );
};
