import cardValidator from 'card-validator';

import { FC, useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';

import useParseCardNumber from 'modules/payment/views/components/BankCardForm/hooks/useParseCardNumber';
import useParseDateMonth from 'modules/payment/views/components/BankCardForm/hooks/useParseDateMonth';

import { TextInputField } from 'components/form';
import { Image } from 'components/ui';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup from 'libs/yup';

import { parseNumbers } from 'utils/inputParsers';

export interface BankCardFormValues {
  cardNumber: string;
  expDate: string;
  cvc?: string;
  fullName: string;
}

export const getBankCardFormInitialValues = ({
  isDeposit,
}: {
  isDeposit: boolean;
}): BankCardFormValues => ({
  cardNumber: '',
  expDate: '',
  fullName: '',
  cvc: isDeposit ? '' : undefined,
});

interface BankCardFormProps {
  className?: string;
  isDeposit: boolean;
}

export const getBankCardValidationSchema = ({ isDeposit }: { isDeposit: boolean }) => {
  const mainShape = {
    cardNumber: yup
      .string()
      .test({
        name: 'validCardNumber',
        message: getTranslation('VALIDATION_BANK_CARD_NUMBER_INVALID'),
        test: (value: string | undefined) =>
          value ? cardValidator.number(value.replace(' ', '')).isValid : false,
      })
      .length(19, getTranslation('VALIDATION_DIGITS_COUNT', { count: 16 }))
      .required(getTranslation('VALIDATION_REQUIRED')),

    fullName: yup
      .string()
      .test({
        name: 'validFullName',
        message: getTranslation('VALIDATION_FIELD_INVALID'),
        test: (value: string | undefined) =>
          value ? cardValidator.cardholderName(value).isValid : false,
      })
      .test({
        name: 'hasTwoWords',
        message: getTranslation('VALIDATION_NAME_TWO_WORDS'),
        test: (value) => {
          if (value) {
            const [first, last] = value.split(' ');
            return !!first && !!last;
          }
          return false;
        },
      })
      .required(getTranslation('VALIDATION_REQUIRED')),
  };

  if (isDeposit) {
    // @ts-ignore
    mainShape['cvc'] = yup
      .string()
      .length(3, getTranslation('VALIDATION_DIGITS_COUNT', { count: 3 }))
      .required(getTranslation('VALIDATION_REQUIRED'));

    // @ts-ignore
    mainShape['expDate'] = yup
      .string()
      .test({
        name: 'validExpDate',
        message: getTranslation('VALIDATION_BANK_CARD_EXP_DATE_INVALID'),
        test: (value: string | undefined) => {
          return value ? cardValidator.expirationDate(value).isValid : false;
        },
      })
      .length(5, getTranslation('VALIDATION_DIGITS_COUNT', { count: 4 }))
      .requiredDefault();
  }
  return yup.object().shape(mainShape);
};

type AllowedBankCardType = 'visa' | 'mastercard';

const validBankCardTypes: AllowedBankCardType[] = ['visa', 'mastercard'];

export const BankCardForm: FC<BankCardFormProps> = ({ isDeposit }) => {
  const translate = useTranslation();

  const { values } = useFormState<BankCardFormValues>();

  const parseDateDateMonth = useParseDateMonth();
  const parseCardNumber = useParseCardNumber();

  const bankCardType = useMemo<AllowedBankCardType | null>(() => {
    const type = cardValidator.number(values.cardNumber).card?.type;
    if (type && validBankCardTypes.includes(type as AllowedBankCardType)) {
      return type as AllowedBankCardType;
    }
    return null;
  }, [values.cardNumber]);

  // const { change } = useForm<BankCardFormValues>();
  //
  // const openScanner = useCallback(async () => {
  //   const result = await reactNativeServices.scanBankCard();
  //   if (result) {
  //     change('cardNumber', result.cardNumber);
  //     change('expDate', result.expDate);
  //     if (result.cardHolderName) {
  //       change('fullName', result.cardHolderName);
  //     }
  //   }
  // }, [change]);

  // const cardNumberEndAdornment = useMemo(() => {
  //   return bankCardType ? (
  //     <Image name={bankCardType} path="payment" type="svg" className="mr-2" />
  //   ) : isReactNativeV2 ? (
  //     <Icon onClick={openScanner} name="camera" size={16} className="mr-2" />
  //   ) : null;
  // }, [openScanner, bankCardType]);

  const cardNumberEndAdornment = useMemo(() => {
    return bankCardType ? (
      <Image name={bankCardType} path="payment" type="svg" className="mr-2" />
    ) : null;
  }, [bankCardType]);

  // const topRightComponent = useMemo(() => {
  //   if (bankCardType && isReactNativeV2) {
  //     return (
  //       <div className="row gap-0-5" onClick={openScanner}>
  //         <span className={classes.scanLabel}>{translate('SCAN')}</span>
  //         <Icon name="camera" size={16} color="cyanBlue" />
  //       </div>
  //     );
  //   }
  // }, [openScanner, bankCardType, translate]);

  return (
    <>
      <Field
        name="cardNumber"
        component={TextInputField}
        placeholder={translate('PAYMENT_BANK_CARD_NUMBER')}
        autoComplete="cc-number"
        maxLength={19}
        parse={parseCardNumber}
        endAdornment={cardNumberEndAdornment}
        // topRightComponent={topRightComponent}
      />

      {isDeposit && (
        <div className="row gap-2">
          <Field
            name="expDate"
            component={TextInputField}
            placeholder={translate('PAYMENT_BANK_CARD_EXP_DATE')}
            maxLength={5}
            parse={parseDateDateMonth}
            autoComplete="cc-exp"
          />
          <Field
            name="cvc"
            component={TextInputField}
            placeholder={translate('PAYMENT_BANK_CARD_CVC')}
            maxLength={3}
            parse={parseNumbers}
            autoComplete="cc-csc"
          />
        </div>
      )}
      <Field
        name="fullName"
        component={TextInputField}
        placeholder={translate('PAYMENT_BANK_CARD_FULL_NAME')}
        autoComplete="cc-name"
      />
    </>
  );
};
