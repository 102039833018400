import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import { selectReferralLinksReducer, selectUserProfile } from 'modules/user/store/selectors';
import { requestGenerateReferralLink, requestReferralLinks } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import { Button, DrawerHeader, Icon, Image } from 'components/ui';

import useFlag from 'hooks/useFlag';
import useStoreEntity from 'hooks/useStoreEntity';

import { TranslationKey, useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import { setStringToClipboard } from 'utils/common';

import classes from './ReferralLinksMarketingAd.module.scss';

const steps: { icon: string; text: TranslationKey }[] = [
  { icon: 'chainCircles', text: 'REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_1' },
  { icon: 'userCheck', text: 'REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_2' },
  { icon: 'nebeusCard', text: 'REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_3' },
  { icon: 'circlesUp', text: 'REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_4' },
];
const ReferralLinksMarketingAd = () => {
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const translate = useTranslation();
  const userProfile = useSelector(selectUserProfile);

  const loading = useFlag(false);

  const { entityReducer: refLinksReducer } = useStoreEntity(
    selectReferralLinksReducer,
    requestReferralLinks,
  );

  const getRefLink = useCallback(async () => {
    if (!refLinksReducer.meta.loaded) {
      return null;
    }
    if (refLinksReducer.data.length) {
      return refLinksReducer.data[0];
    } else {
      loading.on();

      const { data: refLink } = await dispatch(requestGenerateReferralLink());
      loading.off();

      return refLink;
    }
  }, [dispatch, loading, refLinksReducer]);

  const handleCopyRefLink = useCallback(async () => {
    const refLink = await getRefLink();

    if (refLink) {
      const success = await setStringToClipboard(refLink);
      if (success) {
        successToast(translate('REFERRAL_LINK_COPIED'));
      }
    }
  }, [translate, getRefLink]);

  const shareLink = useCallback(async () => {
    const refLink = await getRefLink();

    if (!refLink) {
      return;
    }
    try {
      await window.navigator.share({
        url: refLink,
        title: translate('REFERRAL_LINK_INVITE_TEXT'),
        text: translate('REFERRAL_LINK_INVITE_TEXT'),
      });
      successToast(translate('REFERRAL_LINK_INVITE_SENT'));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Cant share ref link: ', e);
    }
  }, [translate, getRefLink]);

  const handleClickVerifyIdentity = useCallback(() => {
    drawer.open(userDrawerTemplates.verificationID());
  }, [drawer]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className="column gap-1">
          <DrawerHeader title={translate('REFERRAL_LINK_MARKETING_AD_TITLE')} />
          <p>{translate('REFERRAL_LINK_MARKETING_AD_SUBTITLE')}</p>
        </div>
        <div className={classes.imgCard}>
          <Image name="moneyBag" path="common" />
        </div>
        <div className="outlinedCard column gap-3">
          <span className="label">{translate('REFERRAL_LINK_MARKETING_AD_CONDITION_TITLE')}</span>

          <div className="column gap-2">
            {steps.map((step, index) => (
              <div key={index} className="row aic gap-1-5">
                <Icon name={step.icon} size={32} />
                <span className={classes.stepTitle}>{translate(step.text)}</span>
              </div>
            ))}
          </div>
          <p className="font-sm">
            {translate('REFERRAL_LINK_MARKETING_AD_TEXT')}
            <br />
            <br />*{translate('REFERRAL_LINK_MAX_USING_CONDITION')}
          </p>
        </div>
      </div>
      {userProfile?.verified ? (
        <div className="row aic gap-1">
          <Button
            loading={loading.state || refLinksReducer.meta.loading}
            fullWidth
            onClick={handleCopyRefLink}
          >
            {translate('REFERRAL_LINK_MARKETING_AD_COPY_BUTTON')}
          </Button>
          {'share' in window.navigator ? (
            <Button
              variant="darkGreyOutlined"
              loading={loading.state || refLinksReducer.meta.loading}
              onClick={shareLink}
            >
              <Icon name="share" size={16} />
            </Button>
          ) : null}
        </div>
      ) : (
        <Button onClick={handleClickVerifyIdentity}>
          {translate('VERIFICATION_VERIFY_IDENTITY')}
        </Button>
      )}
    </div>
  );
};

export default ReferralLinksMarketingAd;
