import { FC, useCallback } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { SmartTraderTitles } from 'modules/smartTrader/views/components/SmartTraderTitles';

import { AgreementText } from 'components/common';
import { Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

export interface SmartTraderInsufficientFundsProps {
  currencyCode: CurrencyCode;
}
const SmartTraderInsufficientFunds: FC<SmartTraderInsufficientFundsProps> = ({ currencyCode }) => {
  const translate = useTranslation();

  const sidebar = useSideBar();

  const currencyLabel = getCurrencyLabelByCode(currencyCode);

  const handleDeposit = useCallback(() => {
    sidebar.open(
      ...sidebarTemplates.paymentProcess({
        isDeposit: true,
        currencyCode,
        isDigitalAccount: false,
      }),
    );
  }, [sidebar, currencyCode]);

  const handleExchange = useCallback(() => {
    sidebar.open(...sidebarTemplates.smartTraderExchange({ currencyCode }));
  }, [currencyCode, sidebar]);

  return (
    <>
      <SmartTraderTitles
        currencyCode={currencyCode}
        title={translate('VALIDATION_INSUFFICIENT_FUNDS', {
          currencyLabel,
        })}
        subtitle={translate('SMART_TRADER_INSUFFICIENT_FUNDS', {
          currencyLabel,
        })}
      />
      <div className="column gap-1-5">
        <Button variant="gold" onClick={handleDeposit}>
          {translate('DEPOSIT_CURRENCY', { currencyLabel })}
        </Button>
        <Button variant="gold" onClick={handleExchange}>
          {translate('EXCHANGE') + ' ' + currencyLabel}
        </Button>
        <AgreementText light />
      </div>
    </>
  );
};

export default SmartTraderInsufficientFunds;
