import { selectUserProfile } from 'modules/user/store/selectors';
import store, { persistor } from 'store';

import appConfig from 'constants/appConfig';

import analytic from 'libs/analytic';
import { AnalyticEvents } from 'libs/analytic/events';
import UtilitiesAxiosInstance from 'libs/axios/UtilitiesAxiosInstance';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const onSentryError = async (error: Error, componentStack: string, eventId: string) => {
  if (appConfig.isDev) {
    return;
  }
  let sentToSlack = false;
  try {
    if (error?.message === "Cannot read properties of undefined (reading 'meta')") {
      persistor.purge();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error onSentryError 0: ', e);
  }
  try {
    const user = selectUserProfile(store.getState());

    const { success } = await UtilitiesAxiosInstance.post<any, { success: boolean }>(
      '/front-end/catch-frontend-error',
      {
        message: error?.message || 'No message',
        userEmail: user?.email || 'No user',
        userID: user?.id || 'No user',
        appVersion: appConfig.version,
        environment: appConfig.customEnvironment,
      },
    );
    sentToSlack = success;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error onSentryError 1: ', e);
  }
  try {
    analytic.sendEvent(AnalyticEvents.appErrorCaptured, { sentToSlack });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error onSentryError 2: ', e);
  }
};
