import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { VaultDepositWithdrawProps } from 'modules/vault/views/VaultDepositWithdraw';

import { getTranslation } from 'libs/i18n';

const vaultDepositWithdraw = (props: VaultDepositWithdrawProps): DrawerTemplateReturnType => ({
  key: 'vaultDepositWithdraw',
  props,
  sideBarProps: {
    variant: 'white',
    navigationHeaderLabel: getTranslation(props.deposit ? 'VAULT_DEPOSIT' : 'VAULT_WITHDRAW'),
  },
});

const vaultDrawerTemplates = { vaultDepositWithdraw };

export default vaultDrawerTemplates;
