import clsx from 'clsx';

import { FC, ReactElement } from 'react';

import { IApiInfinityListState, IApiRequestState } from 'store/generators/types';

import { ErrorCard } from 'components/common/ErrorCard';
import { Loader } from 'components/ui';

import { voidFunc } from 'types';

import classes from './EntityView.module.scss';

interface EntityViewProps {
  reducer: IApiRequestState<unknown> | IApiInfinityListState<unknown>;
  request: voidFunc;
  component: ReactElement;
  noDataComponent?: ReactElement;
  loaderClassName?: string;
  loaderComponent?: ReactElement;
  errorCardClassName?: string;
}

export const EntityView: FC<EntityViewProps> = ({
  reducer,
  request,
  component,
  noDataComponent = null,
  errorCardClassName,
  loaderClassName,
  loaderComponent = (
    <div className={clsx(loaderClassName, classes.loader, 'row aic')}>
      <Loader centered />
    </div>
  ),
}) => {
  return reducer.meta.loading && !reducer.meta.loaded ? (
    loaderComponent
  ) : reducer.meta.error ? (
    <ErrorCard className={errorCardClassName} retry={request} label={reducer.meta.error} />
  ) : reducer.data && (Array.isArray(reducer.data) ? reducer.data.length > 0 : true) ? (
    component
  ) : (
    noDataComponent
  );
};
