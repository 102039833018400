import { FC, useCallback } from 'react';

import { requestConfirmDelivery } from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard } from 'modules/cryptoBankCard/types';
import { useDispatch } from 'store';

import { Button, ImportantCard } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

interface DeliveryConfirmationProps {
  bankCard: CryptoBankCard;
}

export const DeliveryConfirmation: FC<DeliveryConfirmationProps> = ({ bankCard }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const loading = useFlag(false);

  const handleClickConfirm = useCallback(async () => {
    loading.on();

    await dispatch(requestConfirmDelivery({ cardId: bankCard.id }));

    successToast(translate('SUCCESS'));

    loading.off();
  }, [loading, translate, dispatch, bankCard]);

  return (
    <ImportantCard text={translate('CRYPTO_BANK_CARD_DELIVERY_CONFIRMATION')}>
      <Button loading={loading.state} onClick={handleClickConfirm}>
        {translate('CRYPTO_BANK_CARD_DELIVERY_CONFIRMATION_CONFIRM_BUTTON')}
      </Button>
    </ImportantCard>
  );
};
