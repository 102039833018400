import { useCallback, useState } from 'react';

import { voidFunc } from 'types';

export interface Flag {
  state: boolean;
  on: voidFunc;
  off: voidFunc;
  toggle: voidFunc;
}
const useFlag = (initialValue: boolean): Flag => {
  const [state, setState] = useState<boolean>(initialValue);

  const on = useCallback(() => {
    setState(true);
  }, []);
  const off = useCallback(() => {
    setState(false);
  }, []);
  const toggle = useCallback(() => {
    setState((prev) => !prev);
  }, []);

  return { state, on, off, toggle };
};

export default useFlag;
