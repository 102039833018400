import clsx from 'clsx';

import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { selectActiveAccountType } from 'modules/accounts/store/selectors';
import useBankCards from 'modules/app/hooks/useBankCards';
import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import useIbanAccountPageOpening from 'modules/digitalAccount/hooks/useIbanAccountPageOpening';
import { selectIsDigitalAccountAllowed } from 'modules/digitalAccount/store/selectors';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';

import routesByName from 'constants/routesByName';

import { Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './NavMenu.module.scss';

interface NavMenuProps {
  className?: string;
}

type NavButton = {
  label: string;
  icon: string;
} & ({ route: string } | { handler: voidFunc; activeRoute?: string });

const NavButtonChildren: FC<{ iconName: string; label: string }> = ({ iconName, label }) => (
  <>
    <Icon name={iconName} size={32} color="black" />
    <span>{label}</span>
  </>
);
const NavMenu: FC<NavMenuProps> = ({ className }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);
  const activeAccountType = useSelector(selectActiveAccountType);

  const { bankCardsViewAllowed } = useBankCards();

  const { onOpenIbanAccountPage } = useIbanAccountPageOpening();

  const { pathname } = useLocation();

  const navButtons = useMemo<NavButton[]>(() => {
    const buttons: NavButton[] = [
      {
        label: translate('DASHBOARD'),
        route: routesByName.dashboard,
        icon: 'dashboard',
      },

      {
        label: translate('WALLET'),
        route: routesByName.accounts(activeAccountType),
        icon: 'walletCircles',
      },

      {
        label: translate('STAKING'),
        route: routesByName.staking(),
        icon: 'staking',
      },
      {
        label: translate('RENTING'),
        route: routesByName.cryptoRenting(),
        icon: 'circlesUp',
      },
      {
        label: translate('LOANS'),
        route: routesByName.loans('new'),
        icon: 'percent',
      },
      {
        label: translate('VAULT'),
        route: routesByName.vault,
        icon: 'vault',
      },
      {
        label: translate('SMART_TRADER'),
        route: routesByName.smartTrader,
        icon: 'smartTrader',
      },
      {
        icon: 'document2',
        label: translate('INVOICES'),
        handler: () => drawer.replace(invoicingDrawerTemplates.invoices()),
      },
      {
        icon: 'presentThin',
        label: translate('REFERRAL_LINK_MARKETING_AD_SHORT_TITLE'),
        handler: () => drawer.replace(commonDrawerTemplates.referralLinksMarketingAd()),
      },
    ];

    if (isDAAllowed) {
      buttons.splice(1, 0, {
        label: translate('IBAN'),
        handler: () => {
          onOpenIbanAccountPage();
        },
        icon: 'bank2',
        activeRoute: routesByName.iban,
      });
    }

    if (bankCardsViewAllowed) {
      buttons.splice(isDAAllowed ? 3 : 2, 0, {
        icon: 'card2',
        label: translate('CARD'),
        handler: () => {
          drawer.replace(commonDrawerTemplates.bankCards());
        },
      });
    }

    return buttons;
  }, [
    bankCardsViewAllowed,
    onOpenIbanAccountPage,
    drawer,
    activeAccountType,
    isDAAllowed,
    translate,
  ]);

  return (
    <div className={clsx(classes.root, className)}>
      {navButtons.map((button) =>
        'route' in button ? (
          <NavLink
            onClick={drawer.close}
            end={false}
            className={({ isActive }) => clsx(classes.navButton, { [classes.active]: isActive })}
            key={button.label}
            to={button.route}
          >
            <NavButtonChildren label={button.label} iconName={button.icon} />
          </NavLink>
        ) : (
          <div
            key={button.label}
            onClick={button.handler}
            className={clsx(classes.navButton, button.activeRoute === pathname && classes.active)}
          >
            <NavButtonChildren label={button.label} iconName={button.icon} />
          </div>
        ),
      )}
    </div>
  );
};

export default NavMenu;
