import { FC, useCallback } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import useParseCardNumber from 'modules/payment/views/components/BankCardForm/hooks/useParseCardNumber';
import useParseDateMonth from 'modules/payment/views/components/BankCardForm/hooks/useParseDateMonth';

import { SubmitButton, TextInputField } from 'components/form';

import { useTranslation } from 'libs/i18n';

import { parseNumbers } from 'utils/inputParsers';

interface FormValues {
  cardNumber: string;
  expDate: string;
  cvc: string;
  fullName: string;
}

const initialValues: FormValues = {
  cardNumber: '',
  expDate: '',
  cvc: '',
  fullName: '',
};

interface AddSavedBankCardFormProps extends FormRenderProps<FormValues> {}
const AddSavedBankCardForm: FC<AddSavedBankCardFormProps> = ({ handleSubmit }) => {
  const translate = useTranslation();
  const parseDateDateMonth = useParseDateMonth();
  const parseCardNumber = useParseCardNumber();
  return (
    <form className="mt-2" onSubmit={handleSubmit}>
      <Field
        name="cardNumber"
        component={TextInputField}
        label={translate('PAYMENT_BANK_CARD_NUMBER')}
        autoComplete="cc-number"
        maxLength={19}
        parse={parseCardNumber}
        variant="linen"
      />
      <div className="mt-2 row gap-2">
        <Field
          name="expDate"
          component={TextInputField}
          label={translate('PAYMENT_BANK_CARD_EXP_DATE')}
          placeholder={translate('PAYMENT_BANK_CARD_EXP_DATE_PLACEHOLDER')}
          maxLength={5}
          parse={parseDateDateMonth}
          autoComplete="cc-exp"
          variant="linen"
        />
        <Field
          name="cvc"
          component={TextInputField}
          label={translate('PAYMENT_BANK_CARD_CVC')}
          maxLength={3}
          parse={parseNumbers}
          autoComplete="cc-csc"
          variant="linen"
        />
      </div>
      <Field
        name="fullName"
        component={TextInputField}
        label={translate('PAYMENT_BANK_CARD_FULL_NAME')}
        className="mt-2"
        autoComplete="cc-name"
        variant="linen"
      />
      <SubmitButton fullWidth className="mt-2">
        {translate('PAYMENT_SAVE_BANK_CARD')}
      </SubmitButton>
    </form>
  );
};

const AddSavedBankCard = () => {
  const translate = useTranslation();

  const handleSubmit = useCallback((values: FormValues) => {
    // eslint-disable-next-line no-console
    console.log(values); // TODO:[WBE] Saved bank cards
  }, []);

  return (
    <>
      <h4>{translate('PAYMENT_ADD_BANK_CARD')}</h4>
      <Form onSubmit={handleSubmit} initialValues={initialValues} render={AddSavedBankCardForm} />
    </>
  );
};

export default AddSavedBankCard;
