import clsx from 'clsx';

import { FC, useMemo } from 'react';

import useWallets from 'modules/accounts/hooks/useWallets';

import { CurrencyIcon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './WalletBalanceCard.module.scss';

interface WalletBalanceCardProps {
  className?: string;
  currencyCode: CurrencyCode;
  isDigitalAccount?: boolean;
}

export const WalletBalanceCard: FC<WalletBalanceCardProps> = ({
  className,
  currencyCode,
  isDigitalAccount = false,
}) => {
  const translate = useTranslation();

  const { wallets } = useWallets({ isDigitalAccount });

  const wallet = useMemo(
    () => wallets.find((w) => w.currencyCode === currencyCode),
    [wallets, currencyCode],
  );

  return wallet ? (
    <div className={clsx(classes.root, className)}>
      <div className="row aic">
        <CurrencyIcon className="mr-1" code={currencyCode} size={24} />
        <span className="label">
          {currencyCode} {translate('BALANCE')}
        </span>
      </div>
      <span className={classes.value}>{formatCurrencyWithSymbol(wallet.amount, currencyCode)}</span>
    </div>
  ) : null;
};
