import { CreateInvoiceStep } from 'modules/invoicing/views/CreateInvoice/components/CreateInvoiceStep';
import InvoiceNotesForm from 'modules/invoicing/views/CreateInvoice/components/InvoiceNotesForm';

import { useTranslation } from 'libs/i18n';

export const InvoiceNotes = () => {
  const translate = useTranslation();

  return <CreateInvoiceStep label={translate('INVOICE_NOTES')} component={<InvoiceNotesForm />} />;
};
