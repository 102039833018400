import { createSlice } from '@reduxjs/toolkit';

import { generateRequestDataReducer } from 'store/generators/reducers';
import { initialApiInfinityListState } from 'store/generators/utils';
import { initialArrayApiRequestState } from 'store/generators/utils';

import { Invoice, InvoiceSavedClient, InvoicingState } from '../types';

// cli-import

const initialState: InvoicingState = {
  invoices: initialApiInfinityListState,
  savedClients: initialArrayApiRequestState,
  // cli-state
};

const invoicingSlice = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {
    updateInvoices: generateRequestDataReducer<InvoicingState, Invoice[]>({
      reducerPath: 'invoices',
    }),
    updateSavedClients: generateRequestDataReducer<InvoicingState, InvoiceSavedClient[]>({
      reducerPath: 'savedClients',
    }),
    // cli-reducer
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export default invoicingSlice.reducer;
export const invoicingActions = invoicingSlice.actions;
