import clsx from 'clsx';

import { FC, useCallback, useState } from 'react';

import useAccountsTransactionsHistory from 'modules/accounts/hooks/useAccountsTransactionsHistory';
import { isReactNative } from 'modules/reactNative/utils';

import {
  Image,
  LineDelimiter,
  Loader,
  NoResultLabel,
  SearchInput,
  TransactionRow,
} from 'components/ui';

import useScrollRef from 'hooks/useScrollRef';

import { useTranslation } from 'libs/i18n';

import { CurrencyCode } from 'types';

import classes from './TransactionHistory.module.scss';

export interface TransactionHistoryProps {
  currencyCode?: CurrencyCode;
  isCryptoAccount?: boolean;
  transactionsContainerClassName?: string;
}

const TransactionHistory: FC<TransactionHistoryProps> = ({
  currencyCode,
  isCryptoAccount = true,
  transactionsContainerClassName,
}) => {
  const translate = useTranslation();

  const [filterValue, setFilterValue] = useState('');

  const mainScrollRef = useScrollRef();

  const scrollToEndMainView = useCallback(() => {
    mainScrollRef.current?.scrollTo({ left: 0, top: window.innerHeight, behavior: 'smooth' });
  }, [mainScrollRef]);

  const { groupedByDate, onInfinityScroll, isLoading, onTransactionClick } =
    useAccountsTransactionsHistory({
      currencyCode,
      isCryptoAccount,
      filterValue,
    });

  return (
    <section
      className={clsx(
        groupedByDate.length > 10 && classes.hasItems,
        classes.rootCard,
        'mt-2 relative',
      )}
    >
      <SearchInput
        value={filterValue}
        onChange={setFilterValue}
        placeholder={translate('PLACEHOLDER_HISTORY')}
        searchIconPosition="end"
      />
      <div
        onScroll={onInfinityScroll}
        onTouchStart={scrollToEndMainView}
        className={clsx(
          classes.transactionsContainer,
          transactionsContainerClassName,
          isReactNative && classes.reactNative,
          'mt-2 column',
        )}
      >
        {groupedByDate.map(([dateLabel, dateList], index) => (
          <div key={dateLabel}>
            {index !== 0 ? <LineDelimiter className="my-3" /> : null}

            <span className={clsx(classes.boldLabel, classes.sectionLabel, 'mb-1')}>
              {dateLabel}
            </span>
            <div className="column gap-2">
              {dateList.map((transaction) => (
                <TransactionRow
                  key={transaction.uniqId}
                  transaction={transaction}
                  onClick={() => {
                    onTransactionClick(transaction.uniqId);
                  }}
                  className={classes.transaction}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {isLoading && (
        <Loader className={groupedByDate.length ? classes.loader : undefined} centered />
      )}
      {filterValue && !groupedByDate.length ? <NoResultLabel findString={filterValue} /> : null}
      {!filterValue && !groupedByDate.length && !isLoading ? (
        <div className="column gap-3 aic">
          <Image className="m-0-auto" name="clock" path="loans" />
          <h5>{translate('ACCOUNT_TRANSACTIONS_HISTORY_EMPTY')}</h5>
        </div>
      ) : null}
    </section>
  );
};

export default TransactionHistory;
