import { MessagePayload, onMessage } from 'firebase/messaging';
import { noop } from 'lodash';

import { useCallback, useEffect } from 'react';

import { PushNotificationData } from 'modules/app/types';
import { isReactNative } from 'modules/reactNative/utils';

import Firebase from 'libs/firebase';
import usePushNotificationClickHandler from 'libs/firebase/pushNotifications/usePushNotificationClickHandler';

import nebeusLogo from 'assets/icons/nebeusCircleLogo.svg';

import { voidFunc } from 'types';

const useWebNotificationMessageHandlerFunc = () => {
  const pushNotificationClickHandler = usePushNotificationClickHandler();

  const handleFocusedWindowMessage = useCallback(
    (payload: Omit<MessagePayload, 'data'> & { data: PushNotificationData | undefined }) => {
      if (!payload.data || !payload.notification || !payload.notification.title) {
        return;
      }
      const title = payload.notification.title || payload.data.title;
      const body = payload.notification.body || payload.data.body;

      const notification = new Notification(title, {
        body,
        icon: nebeusLogo,
        vibrate: [200, 100, 200],
        requireInteraction: true,
        silent: false,
      });

      const handler = () => pushNotificationClickHandler(payload.data!);

      notification.onclick = () => {
        handler();
        notification.close();
      };
    },
    [pushNotificationClickHandler],
  );

  const handleBackgroundMessage = useCallback(
    (event: MessageEvent) => {
      if (event.data.event === 'webNotificationClick') {
        pushNotificationClickHandler(event.data.data as PushNotificationData);
      }
    },
    [pushNotificationClickHandler],
  );

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', handleBackgroundMessage);

      return () => {
        navigator.serviceWorker.removeEventListener('message', handleBackgroundMessage);
      };
    }
  }, [handleBackgroundMessage]);

  useEffect(() => {
    let unsubscribe: voidFunc | undefined;
    if (Firebase.messaging) {
      try {
        // @ts-ignore
        unsubscribe = onMessage(Firebase.messaging, handleFocusedWindowMessage);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Firebase messaging error subscribe onMessage: ', e);
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('CANT INIT NOTIFICATION MESSAGE HANDLER');
    }

    return () => {
      unsubscribe?.();
    };
  }, [handleFocusedWindowMessage]);
};

const useWebNotificationMessageHandler = isReactNative
  ? noop
  : useWebNotificationMessageHandlerFunc;
export default useWebNotificationMessageHandler;
