import { Transaction } from 'modules/accounts/types';
import { IApiRequestState } from 'store/generators/types';

import { CountryResp } from 'libs/swagger/nebeusApiTypes';

import { CurrencyCode } from 'types';

// cli-import

export interface DigitalAccountState {
  subscriptions: IApiRequestState<DigitalSubscription[]>;
  lastTransactions: IApiRequestState<Transaction[]>;

  allowedCountries: IApiRequestState<AllowedCountries>;
  allowedProviders: IApiRequestState<IbanAccountProvider[]>;
  // cli-state-interface
}

export type DigitalSubscriptionType = 'standard' | 'hodler' | 'whale';

export interface DigitalSubscription {
  id: number;
  pricePerMonth: number;
  payCurrencyCode: CurrencyCode;
  type: DigitalSubscriptionType;
  isActive: boolean;
  isDepositPending: boolean;
  isVerificationPending: boolean;
  isEnding: boolean;
  endDate: string | null;
  startDate: string | null;
}

export interface GetTransactionsHistoryParams {
  pageLimit: number;
  pageNumber: number;
  currencyCode?: CurrencyCode;
}

export type AllowedCountries = CountryResp[];

export enum IbanAccountProviderId {
  fiatRepublic = '1',
  quicko = '2',
}

export const IBAN_ACCOUNT_MAIN_PROVIDER = IbanAccountProviderId.quicko;

export interface IbanAccountProvider {
  id: IbanAccountProviderId;
  bic: string;
  pricePerMonth: number;
}
// cli-type
