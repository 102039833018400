import { AxiosResponse } from 'axios';

import { AllowedCountry, BlogPost, BlogPostApiItem, OperationCommission } from 'modules/app/types';
import { transformList } from 'store/generators/transformers';

import { OperationCommissionResponseList } from 'libs/swagger/nebeusApiTypes';

interface AllowedCountryResponse {
  Alpha3Code: 'AND';
  Code: 'AD';
  Id: 1;
  Name: 'Andorra, Principality of';
  PhoneCode: '+376';
  SystemActive: true;
  TimeZone: 'Europe/Andorra';
}
export interface AllowedCountriesResponse {
  Result: AllowedCountryResponse[];
  Status: 'success' | 'failure';
}

export const transformAllowedCountries = (response: AllowedCountriesResponse): AllowedCountry[] =>
  transformList<AllowedCountryResponse, AllowedCountry>(response.Result, [
    ['Name', null, { uncapitalize: true }],
    ['Code', null, { uncapitalize: true }],
    ['Id', null, { uncapitalize: true }],
    ['Alpha3Code', 'fullCode'],
    ['PhoneCode', 'phoneCode'],
  ]).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

export const transformBlogPosts = (response: AxiosResponse<BlogPostApiItem[]>): BlogPost[] =>
  transformList(response.data, [
    ['id'],
    [
      'translations[0].linkUrl',
      'url',
      {
        // @ts-ignore
        custom: (url?: string) => {
          if (!url) {
            return undefined;
          }
          if (url.startsWith('https://account.nebeus.com')) {
            return url.replace('https://account.nebeus.com', 'https://app.nebeus.com');
          }
          return url;
        },
      },
    ],
    ['translations[0].images[0]', 'imageUrl'],
  ]);

export const transformOperationCommissions = (
  response: OperationCommissionResponseList,
): OperationCommission[] =>
  transformList(response.operationCommissions, [
    ['commissionPercent', 'percent'],
    ['fixedCommissionAmount', 'fixed'],
    ['minFixedCommissionAmount', 'minFixed'],
    ['operationCode', 'operationId'],
    ['currencyCode', 'currencyCode'],
    ['network', 'cryptoNetwork'],
  ]);
