import clsx from 'clsx';

import { FC } from 'react';

import classes from './Skeleton.module.scss';

interface SkeletonProps {
  width?: number | string;
  height: number;
  className?: string;
  borderRadius?: number | null;
}
export const Skeleton: FC<SkeletonProps> = ({
  className,
  width = '100%',
  height,
  borderRadius = 4,
}) => {
  return (
    <div
      className={clsx(classes.root, className)}
      style={{
        width,
        height,
        minHeight: height,
        borderRadius: borderRadius === null ? undefined : borderRadius,
      }}
    />
  );
};
