import useSessionExpiredWarning from 'modules/app/views/SessionExpiredWarning/useSessionExpiredWarning';
import useReactNativeEvents from 'modules/reactNative/hooks/useReactNativeEvents';
import useVerificationRequired from 'modules/user/hooks/useVerificationRequired';

import useReactNativeState from 'hooks/useReactNativeState';

import useAnalytic from 'libs/analytic/useAnalytic';
import useWebNotificationMessageHandler from 'libs/firebase/pushNotifications/useWebNotificationMessageHandler';
import useFirebaseConfig from 'libs/firebase/useFirebaseConfig';

const AppControllers = () => {
  useWebNotificationMessageHandler();
  useAnalytic();
  useSessionExpiredWarning();
  useReactNativeState();
  useReactNativeEvents();
  useFirebaseConfig();
  useVerificationRequired();
  return null;
};
export default AppControllers;
