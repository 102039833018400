import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { logout } from 'modules/auth/store/externalThunks';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { Button, Image } from 'components/ui';

import analytic from 'libs/analytic';
import { AnalyticEvents } from 'libs/analytic/events';
import { useTranslation } from 'libs/i18n';

import { sleep } from 'utils/common';

export interface CloseAccountProps {
  finalConfirmation?: boolean;
}

const CloseAccount: FC<CloseAccountProps> = ({ finalConfirmation }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const drawer = useDrawer();

  const handleCloseAccount = useCallback(async () => {
    analytic.sendEvent(AnalyticEvents.closeUserAccount);
    drawer.close();
    await sleep(300);
    await dispatch(logout());
  }, [drawer, dispatch]);

  return (
    <div className="column gap-4">
      <p className="darkGrey">
        {translate(finalConfirmation ? 'CLOSE_ACCOUNT_FINAL_SUBTITLE' : 'CLOSE_ACCOUNT_SUBTITLE')}
      </p>
      <Image name="cloud" path="common" className="m-0-auto" />

      <div className="column gap-1-5">
        {!finalConfirmation ? (
          <div className="row gap-1-5">
            <Button
              onClick={() => {
                drawer.open(userDrawerTemplates.closeAccountCheckList());
              }}
              fullWidth
              variant="darkGreyOutlined"
            >
              {translate('YES')}
            </Button>
            <Button onClick={() => drawer.pop()} fullWidth variant="darkGreyOutlined">
              {translate('NO')}
            </Button>
          </div>
        ) : (
          <div className="column gap-1-5">
            <Button onClick={handleCloseAccount} fullWidth variant="darkGreyOutlined">
              {translate('CONFIRM')}
            </Button>
          </div>
        )}
        <AgreementText />
      </div>
    </div>
  );
};

export default CloseAccount;
