import clsx from 'clsx';

import { FC } from 'react';

import classes from './DottedLine.module.scss';

interface DottedLineProps {
  className?: string;
}

export const DottedLine: FC<DottedLineProps> = ({ className }) => {
  return (
    <div className={clsx(classes.root, className)}>
      <span className={classes.dots}>
        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
      </span>
    </div>
  );
};
