import { SideBarProps, SideBarStackItem } from 'modules/app/types';
import { ConfirmationProps } from 'modules/app/views/Confirmation';
import { MenuListSidebarViewProps } from 'modules/app/views/MenuListSidebarView';
import sidebarScreensClasses from 'modules/app/views/Sidebar/SidebarScreens.module.scss';
import {
  selectBankCards as selectCryptoBankCards,
  selectIsCryptoBankCardAllowed,
} from 'modules/cryptoBankCard/store/selectors';
import { BankCardMoreOptionsProps } from 'modules/cryptoBankCard/views/BankCardMoreOptions';
import { BankCardsProps } from 'modules/cryptoBankCard/views/BankCards';
import { DescriptionCardsProps } from 'modules/cryptoBankCard/views/DescriptionCards';
import { TerminateBankCardProps } from 'modules/cryptoBankCard/views/TerminateBankCard';
import { isReactNative } from 'modules/reactNative/utils';
import store from 'store';

import { AnswerSidebarProps } from 'components/common/AnswerSidebar';
import { BankCardsDescriptionProps } from 'components/common/BankCardsDescription';
import { BankCardsFeaturesExploreProps } from 'components/common/BankCardsFeaturesExplore';
import { DynamicContentSidebarItemProps } from 'components/common/DynamicContentSidebarItem';

import { getTranslation } from 'libs/i18n';

export type DrawerTemplateReturnType = Omit<SideBarStackItem, 'active'>;

const bankCards = (params?: {
  type: 'crypto';
  props?: BankCardsProps;
  getNew?: boolean;
}): DrawerTemplateReturnType => {
  const currentStore = store.getState();
  const hasCryptoBankCards = selectCryptoBankCards(currentStore).length > 0;
  const cryptoBankCardAllowed = selectIsCryptoBankCardAllowed(currentStore);

  const bankCardsTemplate: DrawerTemplateReturnType = {
    key: 'bankCards',
    props: params?.props,
    sideBarProps: { showBackButton: isReactNative, backButtonClassName: 'mb-0' },
    id: params?.props?.initialBankCardId?.toString(),
    allowDuplicate: false,
  };

  const descriptionTemplateProps: BankCardsDescriptionProps = {
    type: params?.type || 'crypto',
  };
  const descriptionTemplate: DrawerTemplateReturnType = {
    key: 'bankCardsDescription',
    props: descriptionTemplateProps,
    sideBarProps: {
      navigationHeaderLabel: getTranslation('BANK_CARDS_DESCRIPTION'),
    },
  };

  if (!params) {
    if (hasCryptoBankCards) {
      return bankCardsTemplate;
    } else {
      return descriptionTemplate;
    }
  }

  const { getNew } = params;

  if (!cryptoBankCardAllowed && !hasCryptoBankCards) {
    if (getNew) {
      return descriptionTemplate;
    }
    return bankCardsTemplate;
  } else {
    if (!hasCryptoBankCards || getNew) {
      return descriptionTemplate;
    }

    return bankCardsTemplate;
  }
};

const descriptionCards = (
  props: DescriptionCardsProps,
  sideBarProps?: Partial<SideBarProps>,
): DrawerTemplateReturnType => ({
  key: 'descriptionCards',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('BANK_CARD_FEATURES'),
    ...sideBarProps,
  },
});
const bankCardMoreOptions = (props: BankCardMoreOptionsProps): DrawerTemplateReturnType => ({
  key: 'bankCardMoreOptions',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderProps: {
      hideBackButton: true,
    },
    navigationHeaderLabel: getTranslation('MORE'),
  },
});
const menuList = (props: MenuListSidebarViewProps): DrawerTemplateReturnType => ({
  key: 'menuList',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('ACTIONS'),
    navigationHeaderProps: {
      backButtonFilled: false,
    },
  },
});
const confirmation = (props: ConfirmationProps): DrawerTemplateReturnType => ({
  key: 'confirmation',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: props.title,
  },
});
const appOperations = (): DrawerTemplateReturnType => ({
  key: 'appOperations',
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('NEBEUS_PRODUCTS'),
    navigationHeaderProps: {
      hideBackButton: true,
    },
  },
});
const navigationBar = (): DrawerTemplateReturnType => ({
  key: 'navBar',
  props: { className: sidebarScreensClasses.navBar },
  sideBarProps: { showBackButton: false },
});

const bankCardTerminate = (props: TerminateBankCardProps): DrawerTemplateReturnType => ({
  key: 'bankCardTerminate',
  props,
  sideBarProps: {
    showBackButton: false,
    swipeModal: true,
    navigationHeaderLabel: getTranslation(
      props.finalConfirmation ? 'BANK_CARD_TERMINATE_FINAL_TITLE' : 'BANK_CARD_TERMINATE_TITLE',
    ),
  },
  allowDuplicate: true,
  id: props.finalConfirmation ? 'final' : 'notFinal',
});

const referralLinksMarketingAd = (): DrawerTemplateReturnType => ({
  key: 'referralLinksMarketingAd',
  sideBarProps: {
    showBackButton: false,
    swipeModal: true,
    swipeModalMaxHeight: true,
  },
});

const bankCardsFeaturesExplore = (
  props: BankCardsFeaturesExploreProps,
  sideBarProps?: Partial<SideBarProps>,
): DrawerTemplateReturnType => ({
  key: 'bankCardsFeaturesExplore',
  props,
  sideBarProps,
});

const sessionExpiredWarning = (): DrawerTemplateReturnType => ({
  key: 'sessionExpiredWarning',
  sideBarProps: {
    showBackButton: false,
    disableClickOutside: true,
  },
});

const answer = (props: AnswerSidebarProps): DrawerTemplateReturnType => ({
  key: 'answer',
  props,
  sideBarProps: { swipeModal: true },
});
const dynamicContent = (
  props: DynamicContentSidebarItemProps,
  drawerProps?: Partial<SideBarProps>,
): DrawerTemplateReturnType => ({
  key: 'dynamicContent',
  props,
  sideBarProps: {
    showBackButton: false,
    swipeModal: true,
    navigationHeaderLabel: props.title,
    ...drawerProps,
  },
});
const commonDrawerTemplates = {
  bankCards,
  bankCardsFeaturesExplore,
  descriptionCards,
  bankCardMoreOptions,
  menuList,
  confirmation,
  appOperations,
  navigationBar,
  bankCardTerminate,
  referralLinksMarketingAd,
  sessionExpiredWarning,
  answer,
  dynamicContent,
};

export default commonDrawerTemplates;
