import clsx from 'clsx';

import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { requestCollateralHistory, requestPaymentsHistory } from 'modules/loans/store/thunks';
import { LoanCollateralHistoryItem, LoanPaymentHistoryItem } from 'modules/loans/types';
import { LoanMovement } from 'modules/loans/views/components/LoanMovement';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import {
  ButtonGroup,
  CurrencyIcon,
  DottedLine,
  Icon,
  Loader,
  Mark,
  NavigationHeader,
  SummaryCard,
  Table,
} from 'components/ui';
import { ButtonGroupItem } from 'components/ui/ButtonGroup';
import { TableRow } from 'components/ui/Table';

import useEntity, { EntityController } from 'hooks/useEntity';
import useFlag from 'hooks/useFlag';
import useTablet from 'hooks/useTablet';

import { TranslationKey, useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';

import classes from './PaymentsAndCollateral.module.scss';

const translationKeysByType: {
  [key in LoanPaymentHistoryItem['paymentType']]: TranslationKey;
} = {
  monthlyRepayment: 'LOANS_MONTHLY_REPAYMENT',
  loanIssued: 'LOANS_ISSUED',
  manualPayment: 'LOANS_MANUAL_PAYMENT',
};

interface ListItemsProps {
  headers: string[];
  rows: TableRow[];
}
const ListItems = memo<ListItemsProps>(({ headers, rows }) => {
  return (
    <div className="column gap-3">
      {rows.map((item) => (
        <div key={item.key} className="outlinedCard column gap-3">
          <div className="column">
            <span className="label">{headers[2]}</span>
            <span className={classes.outstandingValue}>{item.columns[2]}</span>
          </div>
          <SummaryCard
            variant="borderless"
            config={item.columns
              .map((i, index) => ({ label: headers[index], value: i }))
              .filter((_, index) => index !== 5 && index !== 2)}
          />
        </div>
      ))}
    </div>
  );
});

interface PaymentsProps {
  entityController: EntityController<LoanPaymentHistoryItem[]>;
}
const Payments: FC<PaymentsProps> = ({ entityController }) => {
  const translate = useTranslation();

  const isTablet = useTablet();

  useEffect(() => {
    if (!entityController.entity) {
      entityController.fetchEntity();
    }
    // eslint-disable-next-line
  }, []);

  const headers = useMemo(
    () =>
      (
        [
          'DATE_&_TIME',
          'LOAN_PAYMENTS',
          'LOANS_OUTSTANDING_BALANCE',
          'LOANS_PAYMENTS_LEFT',
          'TRANSACTION',
        ] as TranslationKey[]
      ).map((k) => translate(k)),
    [translate],
  );

  const sortByDateDesc = useFlag(true);

  const handleHeaderClick = useCallback(
    (index: number) => {
      if (index === 0) {
        sortByDateDesc.toggle();
      }
    },
    [sortByDateDesc],
  );

  const entities = useMemo(() => {
    if (!entityController.entity) {
      return [];
    }
    if (sortByDateDesc.state) {
      return entityController.entity;
    } else {
      const entityCopy = [...entityController.entity];
      entityCopy.sort(
        (a, b) => new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime(),
      );

      return entityCopy;
    }
  }, [entityController.entity, sortByDateDesc.state]);

  const rows = useMemo<TableRow[]>(
    () =>
      entities.map((payment) => ({
        key: payment.paymentDate,
        columns: [
          <span key={payment.paymentDate}>{formatDDMMYY_HHMM(new Date(payment.paymentDate))}</span>,
          <>
            {payment.paymentAmount === null ? null : (
              <CurrencyIcon
                className={classes.currencyIcon}
                code={payment.paymentCurrencyCode}
                size={16}
              />
            )}
            <span className="black ml-1">
              {payment.paymentAmount
                ? formatCurrencyWithSymbol(payment.paymentAmount, payment.paymentCurrencyCode)
                : '-'}
            </span>
          </>,
          <>
            {!isTablet && (
              <CurrencyIcon
                className={clsx(classes.currencyIcon, 'mr-0-5')}
                code={payment.paymentCurrencyCode}
                size={16}
              />
            )}
            <span className="black">
              {formatCurrencyWithLabel(payment.outstandingBalance, payment.paymentCurrencyCode)}
            </span>
          </>,
          <>
            <span className="black ">{payment.paymentsLeft}</span>
          </>,
          <>
            <span className="black ">{translate(translationKeysByType[payment.paymentType])}</span>
          </>,
          <>{payment.isPaid && <Mark variant="gray">{translate('PAID')}</Mark>}</>,
        ],
      })),
    [isTablet, entities, translate],
  );
  return entityController.loading ? (
    <Loader centered />
  ) : isTablet ? (
    <ListItems rows={rows} headers={headers} />
  ) : (
    <div className={classes.tableWrapper}>
      <Table onHeaderClick={handleHeaderClick} headers={headers} rows={rows} />
    </div>
  );
};

interface CollateralListItemsProps {
  items: TableRow[];
}
const CollateralListItems: FC<CollateralListItemsProps> = ({ items }) => {
  return (
    <div className="column gap-3">
      {items.map(({ key, columns: [date, movement, transaction] }) => (
        <div key={key} className="outlinedCard column gap-3">
          <div className="row aic jcsb gap-1">
            {transaction}
            {date}
          </div>
          <DottedLine />
          {movement}
        </div>
      ))}
    </div>
  );
};
interface CollateralProps {
  entityController: EntityController<LoanCollateralHistoryItem[]>;
}
const translationKeysByCollateralType: {
  [key in LoanCollateralHistoryItem['transactionType']]: TranslationKey;
} = {
  autoMC: 'LOANS_AUTO_MC_SHORT',
  excessRelease: 'LOANS_EXCESS_RELEASE',
  addedManually: 'LOANS_COLLATERAL_ADDED_MANUALLY',
  payoutBack: 'LOANS_COLLATERAL_PAYOUT_BACK',
  purchaseCollateralForMirrorTemplate: 'LOANS_COLLATERAL_DOUBLED',
};

const Collateral: FC<CollateralProps> = ({ entityController }) => {
  const translate = useTranslation();

  useEffect(() => {
    if (!entityController.entity) {
      entityController.fetchEntity();
    }
    // eslint-disable-next-line
  }, []);

  const headers = useMemo(
    () => (['DATE', 'LOAN_MOVEMENT', 'TRANSACTION'] as TranslationKey[]).map((k) => translate(k)),
    [translate],
  );

  const isTablet = useTablet();

  const sortByDateDesc = useFlag(true);

  const handleHeaderClick = useCallback(
    (index: number) => {
      // DATE
      if (index === 0) {
        sortByDateDesc.toggle();
      }
    },
    [sortByDateDesc],
  );

  const entities = useMemo(() => {
    if (!entityController.entity) {
      return [];
    }
    if (sortByDateDesc.state) {
      return entityController.entity;
    } else {
      const entityCopy = [...entityController.entity];
      entityCopy.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

      return entityCopy;
    }
  }, [entityController.entity, sortByDateDesc.state]);

  const rows = useMemo<TableRow[]>(
    () =>
      entities.map((collateral) => ({
        key: collateral.date,
        columns: [
          <span className={classes.date} key={collateral.date}>
            {formatDDMMYY_HHMM(new Date(collateral.date))}
          </span>,
          <>
            <LoanMovement
              transactionAmount={collateral.transactionAmount}
              collateralAmount={collateral.collateralAmount}
              currencyCode={collateral.currencyCode}
              walletAmount={collateral.walletAmount}
              transactionType={collateral.transactionType}
            />
          </>,
          <>
            <div className="row aic gap-1">
              <Icon name="check" className={classes.successCircle} size={10} />
              <span className={classes.optionLabel}>
                {translate(translationKeysByCollateralType[collateral.transactionType])}
              </span>
            </div>
          </>,
        ],
      })),
    [entities, translate],
  );

  return entityController.loading ? (
    <Loader centered />
  ) : isTablet ? (
    <CollateralListItems items={rows} />
  ) : (
    <div className={classes.tableWrapper}>
      <Table onHeaderClick={handleHeaderClick} headers={headers} rows={rows} />
    </div>
  );
};

const PaymentsAndCollateral = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const paymentsTab = useFlag(true);

  const { loanId } = useParams();

  useEffect(() => {
    if (!loanId) {
      navigate(routesByName.loans('new'), { replace: true });
    }
    // eslint-disable-next-line
  }, [loanId]);

  const fetchPayments = useCallback(() => {
    return dispatch(requestPaymentsHistory(+loanId!));
  }, [loanId, dispatch]);

  const fetchCollateral = useCallback(() => {
    return dispatch(requestCollateralHistory(+loanId!));
  }, [loanId, dispatch]);

  const paymentsEntity = useEntity<LoanPaymentHistoryItem[]>(loanId ? fetchPayments : undefined);
  const collateralEntity = useEntity<LoanCollateralHistoryItem[]>(
    loanId ? fetchCollateral : undefined,
  );

  const buttons = useMemo<ButtonGroupItem[]>(
    () => [
      { id: '1', children: translate('LOAN_PAYMENTS'), onClick: paymentsTab.on },
      { id: '2', children: translate('LOANS_COLLATERAL'), onClick: paymentsTab.off },
    ],
    [paymentsTab, translate],
  );

  return loanId ? (
    <div className={classes.root}>
      <NavigationHeader
      // TODO: [WBE] Statement
      // className="jcsb"
      // endAdornment={
      //   <Button className={classes.statementButton} size="sm" variant="transparentBlack">
      //     <Icon name="document3" />
      //     <span>{translate('LOANS_DOWNLOAD_STATEMENT')}</span>
      //   </Button>
      // }
      >{`${translate('LOAN')} #${loanId}`}</NavigationHeader>
      <ButtonGroup
        buttons={buttons}
        activeButtonIndex={paymentsTab.state ? 0 : 1}
        variant="creamyGreen"
        className={classes.buttons}
      />
      <div className="column gap-3">
        {paymentsTab.state ? (
          <Payments entityController={paymentsEntity} />
        ) : (
          <Collateral entityController={collateralEntity} />
        )}
      </div>
    </div>
  ) : null;
};

export default PaymentsAndCollateral;
