import { createSlice } from '@reduxjs/toolkit';

import { generateRequestDataReducer } from 'store/generators/reducers';
import { initialArrayApiRequestState } from 'store/generators/utils';

import { CurrenciesDirection } from 'types';

import { SmartTrade } from '../types';
import { SmartTraderState } from '../types';

// cli-import

const initialState: SmartTraderState = {
  allowedDirections: initialArrayApiRequestState,
  trades: initialArrayApiRequestState,
  // cli-state
};

const smartTraderSlice = createSlice({
  name: 'smartTrader',
  initialState,
  reducers: {
    resetState: (state) => {
      state = initialState;
      return state;
    },
    updateAllowedDirections: generateRequestDataReducer<SmartTraderState, CurrenciesDirection[]>({
      reducerPath: 'allowedDirections',
    }),
    updateTrades: generateRequestDataReducer<SmartTraderState, SmartTrade[]>({
      reducerPath: 'trades',
    }),
    // cli-reducer
  },
});

export default smartTraderSlice.reducer;
export const smartTraderActions = smartTraderSlice.actions;
