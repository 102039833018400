import clsx from 'clsx';

import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { selectTradesReducer } from 'modules/smartTrader/store/selectors';
import { requestTrades } from 'modules/smartTrader/store/thunks';
import { SmartTrade } from 'modules/smartTrader/types';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { Loader, NavigationHeader } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';

import classes from './SmartTraderDashboard.module.scss';
import { MainCard } from './components/MainCard';
import { TotalCard } from './components/TotalCard';
import { TradeHistory } from './components/TradeHistory';

const Dashboard: FC<{ trade: SmartTrade }> = ({ trade }) => {
  return (
    <div className="column gap-2">
      <TotalCard trade={trade} />
      <MainCard trade={trade} />
      <TradeHistory trade={trade} />
    </div>
  );
};

const SmartTradeDashboard = () => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const tradesReducer = useSelector(selectTradesReducer);

  const trade = useMemo(
    () => tradesReducer.data.find((t) => t.primaryCurrency === params.currencyCode),
    [tradesReducer.data, params],
  );

  useEffect(() => {
    if (canRequest(tradesReducer.meta)) {
      dispatch(requestTrades());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tradesReducer.meta.loaded && !trade) {
      navigate(routesByName.smartTrader);
    }
    // eslint-disable-next-line
  }, [tradesReducer.meta.loaded]);

  return (
    <div className="column">
      <NavigationHeader className="mb-4">
        <span className={clsx(classes.header, classes.green)}>Nebeus </span>
        <span className={classes.header}>{translate('SMART_TRADER')}</span>
      </NavigationHeader>
      {tradesReducer.meta.loading && !tradesReducer.meta.loaded && (
        <Loader centered className="mt-10" />
      )}
      {!tradesReducer.meta.loading && trade ? <Dashboard trade={trade} /> : null}
    </div>
  );
};

export default SmartTradeDashboard;
