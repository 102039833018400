import { createSelector } from '@reduxjs/toolkit';

import { UserExperiments } from 'modules/user/types';
// import { Profile } from 'modules/user/types';
import { AppStore } from 'store';

export const selectUserProfile = (state: AppStore) =>
  // state.user.profile.data
  //   ? ({ ...state.user.profile.data, verified: true, countryCode: 'UA' } as Profile)
  //   : null;
  state.user.profile.data;

export const selectUserProfileReducer = (state: AppStore) => state.user.profile;

export const selectUserExperiments = () =>
  ({
    showBankCardOfferOnOnboarding: true,
  } as UserExperiments);

export const selectUserDefaultCurrencyCode = (state: AppStore) =>
  state.user.profile.data?.defaultCurrency || 'EUR';

export const selectUserCountryCode = createSelector(selectUserProfile, (user) => user?.countryCode);

export const selectIsUserVerified = createSelector(selectUserProfile, (user) => user?.verified);
export const selectExtendedUserProfileReducer = (state: AppStore) => state.user.extendedProfile;

export const selectReferralLinksReducer = (state: AppStore) => state.user.referralLinks;

export const selectNotificationsReducer = (state: AppStore) => state.user.notifications;
