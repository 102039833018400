import clsx from 'clsx';
import { addMonths } from 'date-fns';

import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectWallets } from 'modules/accounts/store/selectors';
import { requestCreateWallet } from 'modules/accounts/store/thunks';
import { TradingWallet } from 'modules/accounts/types';
import useSideBar from 'modules/app/hooks/useSideBar';
import rentingCommonClasses from 'modules/cryptoRenting/constants/RentingCommon.module.scss';
import { requestCreateRenting } from 'modules/cryptoRenting/store/thunks';
import { TemplateHeader } from 'modules/cryptoRenting/views/CryptoRenting/components/TemplateHeader';
import RentingSetupPayoutCalculations from 'modules/cryptoRenting/views/RentingSetupPayoutCalculations';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';
import { AppStore, useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { Button, CurrencyIcon } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { findWallet, formatCurrencyWithLabel, getCurrencyLabelByCode } from 'utils/currency';
import { formatDDMMYY } from 'utils/date';

import { CurrencyCode } from 'types';

import classes from './RentingSetupConfirm.module.scss';

interface RentingSetupConfirmProps {
  templateId: number;
  rent: { amount: string; currency: CurrencyCode };
  term: number;
  withdrawCurrency: CurrencyCode;
}

const RentingSetupConfirm: FC<RentingSetupConfirmProps> = ({
  term,
  templateId,
  rent,
  withdrawCurrency,
}) => {
  const sidebar = useSideBar();
  const translate = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectTemplate = useCallback(
    (state: AppStore) => state.cryptoRenting.templates.data.find((t) => t.id === templateId),
    [templateId],
  );

  const template = useSelector(selectTemplate);

  const defaultCurrency = useSelector(selectUserDefaultCurrencyCode);

  const setup = useMemo(
    () =>
      template
        ? [
            {
              label: translate('RENTING_RENT_AMOUNT'),
              value: formatCurrencyWithLabel(rent.amount, rent.currency),
              currencyCode: rent.currency,
            },
            {
              label: translate('RENTING_RENT_CURRENCY'),
              value: getCurrencyLabelByCode(rent.currency) + ` (${rent.currency})`,
              currencyCode: rent.currency,
            },
            {
              label: translate('RENTING_REWARD_PAYOUT'),
              value: translate('RENTING_MONTHLY_PAYOUT'),
            },
            {
              label: translate('START_DATE'),
              value: formatDDMMYY(Date.now()),
            },
            {
              label: translate('END_DATE'),
              value: formatDDMMYY(addMonths(Date.now(), term)),
            },
            {
              label: translate('TERM'),
              value: translate('DATE_MONTH_PLURAL', { count: term }),
            },
            {
              label: translate('RENTING_LOCKUP_PERIOD'),
              value: translate('DATE_MONTH_PLURAL', { count: template.minTermMonth }),
            },
          ]
        : [],
    [template, term, rent, translate],
  );

  const loading = useFlag(false);
  // @ts-ignore // TODO: Fix types for trading wallet
  const tradingWallets: TradingWallet[] = useSelector(selectWallets);

  const handleSubmit = useCallback(async () => {
    loading.on();
    const rentingRewardsWallet = findWallet<TradingWallet>(tradingWallets, withdrawCurrency);
    if (rentingRewardsWallet && !rentingRewardsWallet.exist) {
      const { success: rentingRewardsWalletCreated } = await dispatch(
        requestCreateWallet({ issuerId: rentingRewardsWallet.issuerId }),
      );
      if (!rentingRewardsWalletCreated) {
        loading.off();
        return;
      }
    }
    const { success } = await dispatch(
      requestCreateRenting({
        amount: +rent.amount,
        currency: rent.currency,
        templateId: template!.id,
        termMonth: term,
        payoutCurrency: withdrawCurrency,
      }),
    );
    loading.off();

    if (success) {
      navigate(routesByName.cryptoRenting('active'));
      sidebar.replace('rentingSetupSuccess', {
        sideBarProps: {
          swipeModal: true,
          closeOnClickOutside: true,
          showBackButton: false,
          contentClassName: clsx(
            rentingCommonClasses.rentingSideBar,
            rentingCommonClasses['templateBg-' + template!.name],
          ),
        },
        props: {
          templateId: template!.id,
          rent,
        },
      });
    }
  }, [
    tradingWallets,
    navigate,
    term,
    withdrawCurrency,
    loading,
    dispatch,
    sidebar,
    template,
    rent,
  ]);

  return template ? (
    <div className="mt-2">
      <div className={classes.blurredCard}>
        <TemplateHeader templateName={template.name} />
        <div className="mt-3">
          <span className="label black">{translate('RENTING_TERMS')}</span>
          <div className={clsx(classes.table, 'mt-1 outlinedCard p-1-5')}>
            {setup.map((item) => (
              <div key={item.label} className="row aic jcsb">
                <span className={classes.label}>{item.label}:</span>
                <div className="row aic">
                  <span className={classes.value}>{item.value}</span>
                  {item.currencyCode && (
                    <CurrencyIcon
                      className={classes.currencyIcon}
                      size={16}
                      code={item.currencyCode}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-2">
          <span className="label black mb-1">{translate('RENTING_YOUR_REWARDS')}</span>
          <RentingSetupPayoutCalculations
            rentAmount={+rent.amount}
            rentCurrency={rent.currency}
            percent={template.percentRPY}
            withdrawCurrency={withdrawCurrency}
            defaultCurrency={defaultCurrency}
            termMonth={term}
          />
        </div>
        <Button
          loading={loading.state}
          onClick={handleSubmit}
          showShadow
          fullWidth
          className="mt-3"
        >
          {translate('CONFIRM')}
        </Button>
      </div>
    </div>
  ) : null;
};

export default RentingSetupConfirm;
