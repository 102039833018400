import { useCallback, useEffect, useState } from 'react';

import useFlag from 'hooks/useFlag';

import ApiError from 'libs/axios/ApiError';

export interface EntityController<E> {
  fetchEntity: () => Promise<E | null>;
  loading: boolean;
  entity: E | null;
  error: ApiError | null;
}
interface Options {
  fetchOnMount?: boolean;
}
const useEntity = <Entity>(
  fetch?: () => Promise<{
    data: Entity | null;
    success: boolean;
    error: ApiError | null;
  }>,
  options?: Options,
): EntityController<Entity> => {
  const loading = useFlag(false);

  const [entity, setEntity] = useState<Entity | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const fetchEntity = useCallback(async () => {
    if (!fetch) {
      return null;
    }
    loading.on();
    const { success, data, error } = await fetch();
    if (success && data) {
      setEntity(data);
    } else {
      setError(error);
    }
    loading.off();
    return data;
  }, [loading, fetch]);

  useEffect(() => {
    if (options?.fetchOnMount) {
      fetchEntity();
    }
    // eslint-disable-next-line
  }, []);

  return { fetchEntity, loading: loading.state, entity, error };
};

export default useEntity;
