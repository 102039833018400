import clsx from 'clsx';

import { FC } from 'react';

import classes from './StepHeader.module.scss';

interface StepHeaderProps {
  title: string;
  subtitle?: string;
  className?: string;
}
export const StepHeader: FC<StepHeaderProps> = ({ title, subtitle, className }) => {
  return (
    <div className={clsx(classes.root, className)}>
      <h2>{title}</h2>
      {subtitle ? <p dangerouslySetInnerHTML={{ __html: subtitle }} /> : null}
    </div>
  );
};
