import { getTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

const schema = yup.object().shape({
  country: yup
    .object()
    .required(getTranslation('VALIDATION_REQUIRED'))
    .typeError(getTranslation('VALIDATION_COUNTRY_INCORRECT')),
});

export const validate = makeValidate(schema);
