import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import { selectUserProfile } from 'modules/user/store/selectors';

import { Mark, MenuList } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import { useTranslation } from 'libs/i18n';

const Verification = () => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const user = useSelector(selectUserProfile);

  const menu = useMemo<MenuListItem[]>(
    () =>
      user
        ? [
            {
              icon: 'userVerification',
              label: translate('VERIFICATION_ID_VERIFICATION'),
              arrowNext: !user.verified,
              endAdornment: (
                <Mark variant={user.verified ? 'greenOutlined' : 'grayOutlined'}>
                  {translate(user.verified ? 'VERIFICATION_VERIFIED' : 'VERIFICATION_NOT_VERIFIED')}
                </Mark>
              ),
              handler: !user.verified
                ? () => {
                    drawer.open(userDrawerTemplates.verificationID());
                  }
                : undefined,
            },
          ]
        : [],
    [translate, drawer, user],
  );

  return <MenuList menu={menu} />;
};

export default Verification;
