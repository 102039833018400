import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import authServices from 'modules/auth/services';

import appConfig from 'constants/appConfig';

const UtilitiesAxiosInstance = axios.create({
  baseURL: appConfig.apiUrl + '/v1/utilities',
});

UtilitiesAxiosInstance.interceptors.request.use((config) => {
  const authToken = authServices.getAccessToken();

  if (authToken) {
    config.headers!.Authorization = `Bearer ${authToken}`;
  }

  config.headers!['X-Correlation-ID'] = uuidv4();
  config.headers!['Content-Type'] = 'application/json';

  return config;
});

UtilitiesAxiosInstance.interceptors.response.use((response) => {
  return response.data;
});

export default UtilitiesAxiosInstance;
