import { FC, useMemo } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { CurrencyAmountInput } from 'components/ui';
import { CurrencyAmount, CurrencyAmountInputProps } from 'components/ui/CurrencyAmountInput';

import useField from 'hooks/useField';

interface CurrencyAmountFieldProps
  extends FieldRenderProps<CurrencyAmount>,
    Omit<CurrencyAmountInputProps, 'value' | 'onChange'> {
  showErrorIfExist?: boolean;
  showErrorOnlyIfHasAmountValue?: boolean;
}

export const CurrencyAmountField: FC<CurrencyAmountFieldProps> = ({
  input,
  meta,
  showErrorIfExist,
  showErrorOnlyIfHasAmountValue = true,
  ...props
}) => {
  const { hasError, error, valid } = useField(meta);

  const errorField = useMemo(() => {
    const errorText = error?.amount || error?.currency || error;
    if (showErrorOnlyIfHasAmountValue && !input.value.amount?.length) {
      return null;
    }

    if (hasError || showErrorIfExist) {
      return errorText;
    }
    return null;
  }, [input.value.amount, error, hasError, showErrorOnlyIfHasAmountValue, showErrorIfExist]);

  return <CurrencyAmountInput error={errorField} valid={valid} {...input} {...props} />;
};
