import { IAPIMeta, IApiInfinityListState, IApiRequestState } from './types';

export const initialApiRequestMeta: IAPIMeta = {
  loaded: false,
  loading: false,
  lastLoadedTime: null,
};
export const initialApiRequestState: IApiRequestState<any> = {
  data: null,
  meta: initialApiRequestMeta,
};
export const initialArrayApiRequestState: IApiRequestState<any[]> = {
  data: [],
  meta: initialApiRequestMeta,
};

export const initialApiInfinityListState: IApiInfinityListState<any> = {
  data: [],
  meta: {
    loading: false,
    loaded: false,
    pageNumber: 0,
    hasMore: true,
    lastLoadedTime: null,
  },
};
