import { createSlice } from '@reduxjs/toolkit';

import { generateDefaultReducer } from 'store/generators/reducers';

// cli-import
import { AuthState } from '../types';

const initialState: AuthState = {
  authed: false,
  // cli-state
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuthed: generateDefaultReducer({ reducerPath: 'authed' }),
    resetState: (state) => {
      state = initialState;
      return state;
    },
    // cli-reducer
  },
});

export default authSlice.reducer;
export const authActions = authSlice.actions;
