import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountType } from 'modules/accounts/types';

import routesByName from 'constants/routesByName';

const cryptoRentingTabRoutes = [
  routesByName.cryptoRenting('new'),
  routesByName.cryptoRenting('history'),
  routesByName.cryptoRenting('active'),
];
const loanRoutes = [
  routesByName.loans('new'),
  routesByName.loans('history'),
  routesByName.loans('active'),
  routesByName.loanConfirmation,
];

const stakingTabRoutes = [routesByName.staking('assets'), routesByName.staking('overview')];

export const getValidRoute = (currentRoute: string) => {
  // accounts
  if (currentRoute.startsWith(routesByName.accounts())) {
    if (
      !currentRoute.startsWith(routesByName.accounts(AccountType.crypto)) &&
      !currentRoute.startsWith(routesByName.accounts(AccountType.digital))
    ) {
      return routesByName.accounts(AccountType.crypto);
    }
    return null;
  }
  // crypto-renting
  if (currentRoute.startsWith(routesByName.cryptoRenting())) {
    const isValid =
      (currentRoute.startsWith(routesByName.cryptoRentingPayoutSchedule('')) &&
        currentRoute.length > routesByName.cryptoRentingPayoutSchedule('').length) ||
      cryptoRentingTabRoutes.some((route) => currentRoute.endsWith(route));
    if (!isValid) {
      return routesByName.cryptoRenting('new');
    }
    return null;
  }
  // loans
  if (currentRoute.startsWith(routesByName.loans())) {
    const isValid =
      (currentRoute.startsWith(routesByName.loansPaymentAndCollateral('')) &&
        currentRoute.length > routesByName.loansPaymentAndCollateral('').length) ||
      loanRoutes.some((route) => currentRoute.endsWith(route)) ||
      currentRoute.startsWith(routesByName.loans('new') + '/');
    if (!isValid) {
      return routesByName.loans('new');
    }
    return null;
  }
  // staking
  if (currentRoute.startsWith(routesByName.staking())) {
    const isValid = stakingTabRoutes.some((route) => currentRoute.startsWith(route));
    if (!isValid) {
      return routesByName.staking('assets');
    }
    return null;
  }
  return null;
};
const useInvalidRouteRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const validRoute = getValidRoute(pathname);

    if (validRoute) {
      navigate(validRoute, { replace: true });
    }
    // eslint-disable-next-line
  }, [pathname]);
};

export default useInvalidRouteRedirect;
