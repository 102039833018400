import { useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import { MIN_DEPOSIT_AMOUNT_TO_CREATE_IBAN_ACCOUNT_IN_EUR } from 'modules/digitalAccount/constants/config';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import { requestIbanAccountCreatingReferenceCode } from 'modules/digitalAccount/store/thunks';
import { PaymentOperationId } from 'modules/payment/types';
import { TransferReferenceCode } from 'modules/payment/views/components';
import { PaymentDetailsCard } from 'modules/payment/views/components/PaymentDetailsCard';
import { useDispatch } from 'store';

import { Button, DrawerHeader } from 'components/ui';

import useEntity from 'hooks/useEntity';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import classes from './IbanAccountCreatingDeposit.module.scss';

const IbanAccountCreatingDeposit = () => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const handleContinue = useCallback(() => {
    drawer.open(ibanAccountDrawerTemplates.accountCreatingStatus());
  }, [drawer]);

  const { entity: referenceCode, loading: referenceCodeLoading } = useEntity(
    () => dispatch(requestIbanAccountCreatingReferenceCode()),
    { fetchOnMount: true },
  );
  return (
    <div className="pt-2 column flex-1 gap-1">
      <DrawerHeader showBackButton title={translate('IBAN_ACCOUNT_CREATING_DEPOSIT_TITLE')} />
      <div className="flexScrollable scrollOverDrawer gap-3">
        <p
          className={classes.subtitle}
          dangerouslySetInnerHTML={{
            __html: translate('IBAN_ACCOUNT_CREATING_DEPOSIT_SUBTITLE', {
              amountLabel: formatCurrencyWithSymbol(
                MIN_DEPOSIT_AMOUNT_TO_CREATE_IBAN_ACCOUNT_IN_EUR,
                'EUR',
              ),
            }),
          }}
        />

        <div className="column gap-2">
          <PaymentDetailsCard
            operationId={PaymentOperationId.depositFromSepaToTradingWallet}
            currencyCode="EUR"
          />
          <TransferReferenceCode loading={referenceCodeLoading} code={referenceCode || '-'} />
          <p className={classes.importantLabel}>
            {translate('PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_2')}
          </p>
        </div>
      </div>
      <Button className="mt-3" onClick={handleContinue}>
        {translate('IBAN_ACCOUNT_CREATING_DEPOSIT_SENT_BUTTON')}
      </Button>
    </div>
  );
};
export default IbanAccountCreatingDeposit;
