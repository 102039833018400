import {
  transformBankCardTransactionResponse,
  transformCreateWalletResponse,
  transformDigitalWallets,
  transformIssuers,
  transformTradingTransactions,
  transformWallets,
} from 'modules/accounts/services/transformers';
import {
  DigitalWallet,
  GetIssuersApiResponse,
  Issuer,
  TradingWallet,
  Transaction,
} from 'modules/accounts/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  BaseCardTransactionHistoryResult,
  GetTradingWalletTransactionsUsingQueryParams,
  ModulrWalletResponseList,
  TradingWallet as TradingWalletAPI,
  TradingWalletRequest,
  TradingWalletResponse,
  TradingWalletTransactionResponseList,
} from 'libs/swagger/nebeusApiTypes';

// import { sleep } from 'utils/common';
import { downloadPdf } from 'utils/pdf';

import { CurrencyCode } from 'types';

// cli-import

const accountsServices = {
  getWallets: (): Promise<TradingWallet[]> =>
    AxiosInstance.get<TradingWalletResponse[], TradingWalletResponse[]>('/v1/wallet/trading').then(
      transformWallets,
    ),
  // getWallets: async (): Promise<TradingWallet[]> => {
  //   await sleep(200);
  //   return [
  //     {
  //       currencyCode: 'EUR',
  //       amount: 27,
  //       amountInDefaultCurrency: 30,
  //       currencyLabel: 'Euro',
  //       defaultCurrencyCode: 'EUR',
  //       issuerId: '2',
  //       coinSerial: '2',
  //     },
  //     {
  //       currencyCode: 'BTC',
  //       amount: 100,
  //       amountInDefaultCurrency: 20,
  //       currencyLabel: 'Bitcoin',
  //       defaultCurrencyCode: 'EUR',
  //       issuerId: '3',
  //       coinSerial: '3',
  //     },
  //     {
  //       currencyCode: 'ETH',
  //       amount: 0.03,
  //       amountInDefaultCurrency: 20,
  //       currencyLabel: 'Ethereum',
  //       defaultCurrencyCode: 'EUR',
  //       issuerId: '3',
  //       coinSerial: '3',
  //     },
  //     {
  //       currencyCode: 'USDC',
  //       amount: 60,
  //       amountInDefaultCurrency: 0.3,
  //       currencyLabel: 'USDC',
  //       defaultCurrencyCode: 'EUR',
  //       issuerId: '4',
  //       coinSerial: '4',
  //     },
  //     {
  //       currencyCode: 'USDT',
  //       amount: 1300,
  //       amountInDefaultCurrency: 0.3,
  //       currencyLabel: 'USDT',
  //       defaultCurrencyCode: 'EUR',
  //       issuerId: '4',
  //       coinSerial: '4',
  //     },
  //   ];
  //   // return [];
  // },
  getIssuers: (): Promise<Issuer[]> =>
    AxiosInstance.get<GetIssuersApiResponse, GetIssuersApiResponse>('/v1/issuer').then(
      transformIssuers,
    ),
  createWallet: (payload: TradingWalletRequest): Promise<TradingWallet> =>
    AxiosInstance.post<TradingWalletAPI, TradingWalletAPI>('/v1/wallet/trading/init', payload).then(
      transformCreateWalletResponse,
    ),
  getAccountTransactions: (
    payload: GetTradingWalletTransactionsUsingQueryParams,
  ): Promise<Transaction[]> =>
    AxiosInstance.get<TradingWalletTransactionResponseList, TradingWalletTransactionResponseList>(
      '/v1/transaction/trading',
      { params: payload },
    ).then((response) => transformTradingTransactions(response, payload.currencyCode)),
  // getAccountTransactions: async (
  //   payload: GetTradingWalletTransactionsUsingQueryParams,
  // ): Promise<Transaction[]> => {
  //   await sleep(300);
  //
  //   const tr = new Array(payload.pageSize).fill(null).map((_, index) => ({
  //     currencyCode: (payload.currencyCode || 'BTC') as CurrencyCode,
  //     hash: '0x01efc06036193bfd10ff7175155c120742766e5bf2c21fa7d2895ee9de5d3c2d',
  //     fromAddress: '0x782fa4b7341d3cd06ec5d38687eda7882aad7c7a',
  //     toAddress: '0x782fa4b7341d3cd06ec5d38687eda7882aad7c7a',
  //     network: 'ERC-20',
  //     amount: 10,
  //     amountInDefaultCurrency: 10,
  //     dateCreated: new Date().toString(),
  //     defaultCurrency: 'EUR',
  //     id: ((index + 1) * payload.pageSize * (payload.pageNumber + 1)).toString(),
  //     status: [
  //       'CREATED',
  //       'PROCESSED',
  //       'REJECTED',
  //       'FAILED',
  //       'PENDING',
  //       'MONITORING_APPROVED',
  //       'MONITORING_REJECT',
  //       'REDEEEM',
  //     ][index],
  //     description: 'Outcome transaction',
  //     type: PaymentOperationId.sendCryptoToExternalWallet,
  //   }));
  //
  //   return transformTradingTransactions({ tradingTransactions: tr });
  // },
  getDigitalWallets: (): Promise<DigitalWallet[]> =>
    AxiosInstance.get<ModulrWalletResponseList, ModulrWalletResponseList, DigitalWallet[]>(
      '/v1/modulr/wallet',
    ).then(transformDigitalWallets),

  // getDigitalWallets: async (): Promise<DigitalWallet[]> => {
  //   await sleep(300);
  //
  //   return [
  //     // {
  //     //   currencyCode: 'GBP',
  //     //   exist: true,
  //     //   isDigitalWallet: true,
  //     //   ownerName: 'Andrey',
  //     //   sortCode: '23874532',
  //     //   accountNumber: '3242',
  //     //   amount: 100,
  //     //   amountInDefaultCurrency: 100,
  //     //   defaultCurrencyCode: 'EUR',
  //     //   currencyLabel: 'British Pound',
  //     //   default: false,
  //     // },
  //     {
  //       currencyCode: 'EUR',
  //       exist: true,
  //       isDigitalWallet: true,
  //       ownerName: 'Andrey',
  //       iban: '23874532',
  //       bic: '3242',
  //
  //       amount: 100,
  //       amountInDefaultCurrency: 100,
  //       defaultCurrencyCode: 'EUR',
  //       currencyLabel: 'Euro',
  //       default: true,
  //     },
  //   ];
  // },
  getAccountStatement: (payload: {
    currencyCode: CurrencyCode;
    fromDate: string;
    toDate: string;
    isDigitalAccount: boolean;
  }) =>
    downloadPdf({
      fileName: `Account_statement_${payload.isDigitalAccount ? 'Money' : 'Crypto'}_${
        payload.currencyCode
      }`,
      url: `/v1/transaction/${payload.isDigitalAccount ? 'modulr' : 'trading'}/statement?from=${
        payload.fromDate
      }&to=${payload.toDate}&currency=${payload.currencyCode}&currencyCode=${payload.currencyCode}`,
    }),

  getTransactionStatement: ({
    transactionId,
    isDigitalAccount,
    currencyCode,
  }: {
    transactionId: string;
    isDigitalAccount: boolean;
    currencyCode: CurrencyCode;
  }) =>
    downloadPdf({
      fileName: 'Transaction_receipt_' + transactionId,
      url: `/v1/transaction/${
        isDigitalAccount ? 'modulr' : 'trading'
      }/${transactionId}/receipt?currencyCode=${currencyCode}`,
    }),

  createDigitalWallet: (currencyCode: CurrencyCode) =>
    AxiosInstance.post(currencyCode === 'GBP' ? '/v1/modulr/wallet/gbp' : '/v1/modulr/customer'),

  getTransactionsForRefund: (): Promise<Transaction[]> =>
    AxiosInstance.get<TradingWalletTransactionResponseList, TradingWalletTransactionResponseList>(
      '/v1/transaction/crypto/income/rejected',
    ).then(transformTradingTransactions),

  getBankCardDepositTransactionById: (id: Transaction['id']): Promise<Transaction> =>
    AxiosInstance.get<BaseCardTransactionHistoryResult, BaseCardTransactionHistoryResult>(
      `/v1/card/transaction/card-trading/${id}`,
    ).then((response) => transformBankCardTransactionResponse([response])[0]),

  // getTransactionsForRefund: async (): Promise<Transaction[]> => {
  //   await sleep(500);
  //   return [
  //     {
  //       id: '0',
  //       uniqId: '0',
  //       requestIdentifier: '0',
  //       description: 'Deposit crypto',
  //       operationId: 'bitgoIncomeTransaction',
  //       amount: 2.3254354353543,
  //       amountInDefaultCurrency: 23432.325432342,
  //       currencyCode: 'ETH',
  //       dateCreated: new Date().toString(),
  //       defaultCurrencyCode: 'EUR',
  //       status: 'rejected',
  //       isIncome: true,
  //       additionalInfo: {
  //         network: 'ERC_20',
  //       },
  //     },
  //   ];
  // },

  // cli-service
};

export default accountsServices;
