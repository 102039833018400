import { CreateInvoiceStep } from 'modules/invoicing/views/CreateInvoice/components/CreateInvoiceStep';
import { InvoiceDetailsForm } from 'modules/invoicing/views/CreateInvoice/components/InvoiceDetailsForm';

import { useTranslation } from 'libs/i18n';

export const InvoiceDetails = () => {
  const translate = useTranslation();

  return (
    <CreateInvoiceStep label={translate('INVOICE_DETAILS')} component={<InvoiceDetailsForm />} />
  );
};
