import { FC, MouseEventHandler, useCallback } from 'react';

import LocalStorageKeys from 'constants/localStorageKeys';

import { Icon, Image } from 'components/ui';

import usePersistedState from 'hooks/usePersistedState';

import { useTranslation } from 'libs/i18n';

import { isCurrentDeviceMobileOrTablet } from 'utils/device';
import { LINK_CURVE_MOBILE_APP, LINK_CURVE_WEBSITE } from 'utils/links';

import classes from './CurveAppAdCard.module.scss';

interface CurveAppAdCardProps {}
const CurveAppAdCard: FC<CurveAppAdCardProps> = () => {
  const translate = useTranslation();

  const [curveAppAdRejectedTime, setCurveAppAdRejectedTime] = usePersistedState(
    LocalStorageKeys.curveAppAdRejectedTime,
  );

  const handleClose = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      setCurveAppAdRejectedTime(Date.now());
    },
    [setCurveAppAdRejectedTime],
  );

  return !curveAppAdRejectedTime ? (
    <a
      className={classes.root}
      href={isCurrentDeviceMobileOrTablet ? LINK_CURVE_MOBILE_APP : LINK_CURVE_WEBSITE}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image className={classes.bg} name="contactlessInCircle" path="cryptoBankCard" type="svg" />
      <span>{translate('BANK_CARDS_CURVE_APP_AD_TITLE')}</span>
      <Icon onClick={handleClose} className={classes.crossIcon} name="crossSquared" size={16} />
    </a>
  ) : null;
};

export default CurveAppAdCard;
