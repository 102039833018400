import clsx from 'clsx';

import { FC, useMemo } from 'react';

import useRate from 'modules/exchange/hooks/useRate';

import { AmountLabel, CurrencyIcon, Icon } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel } from 'utils/currency';

import classes from './ConfigCard.module.scss';

interface ConfigCardProps {
  primary: CurrencyAmount;
  secondary: CurrencyAmount;
  stopLossAmount: number;
  takeProfitAmount: number;
}

export const ConfigCard: FC<ConfigCardProps> = ({
  primary,
  secondary,
  stopLossAmount,
  takeProfitAmount,
}) => {
  const translate = useTranslation();

  const [profitAmount, lossAmount] = useMemo(
    () => [
      Math.abs(Math.max(takeProfitAmount - +secondary.amount, 0)),
      stopLossAmount && stopLossAmount < +secondary.amount ? +secondary.amount - stopLossAmount : 0,
    ],
    [takeProfitAmount, stopLossAmount, secondary.amount],
  );

  const rate = useRate(primary.currency, secondary.currency);

  const [profitAmountInPrimaryCurrency, lossAmountInPrimaryCurrency] = useMemo(
    () =>
      rate ? [Math.abs(profitAmount * (1 / rate)), Math.abs(lossAmount * (1 / rate))] : [0, 0],
    [rate, profitAmount, lossAmount],
  );

  return (
    <div className="whiteCard p-3">
      <div>
        <span className={classes.label}>{translate('SMART_TRADER_YOUR_FUNDS')}</span>
        <div className="row mt-0-5 gap-1-5 aifs">
          <CurrencyIcon size={32} code={primary.currency} />
          <div>
            <AmountLabel
              size="xl2"
              amount={primary.amount}
              currencyCode={primary.currency}
              showCurrencyLabel
            />
            <span className={classes.secondaryLabel}>
              {formatCurrencyWithLabel(secondary.amount, secondary.currency)}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-2 gap-1-5">
        <div>
          <span className={classes.label}>{translate('SMART_TRADER_YOUR_PROFIT')}</span>
          <div className="row mt-1 jcsb gap-1-5">
            <div className="row gap-0-5">
              <Icon name="arrowUpSafe" color="green" />
              <span className={clsx(classes.value, classes.profit)}>
                {profitAmount
                  ? formatCurrencyWithLabel(profitAmount, secondary.currency)
                  : '— ' + secondary.currency}
              </span>
            </div>
            <span className={classes.valueInPrimary}>
              {profitAmountInPrimaryCurrency
                ? formatCurrencyWithLabel(profitAmountInPrimaryCurrency, primary.currency)
                : '— ' + primary.currency}
            </span>
          </div>
        </div>
        <div className="mt-3">
          <span className={classes.label}>{translate('SMART_TRADER_YOUR_LOSS')}</span>
          <div className="row mt-0-5 jcsb">
            <div className="row gap-0-5">
              <Icon name="arrowDownSafe" color="red" />
              <span className={classes.value}>
                {lossAmount
                  ? formatCurrencyWithLabel(lossAmount, secondary.currency)
                  : '— ' + secondary.currency}
              </span>
            </div>
            <span className={classes.valueInPrimary}>
              {lossAmountInPrimaryCurrency
                ? formatCurrencyWithLabel(lossAmountInPrimaryCurrency, primary.currency)
                : '— ' + primary.currency}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
