import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Icon } from 'components/ui';
import { DescriptionListItem } from 'components/ui/DescriptionList';

import classes from './DescriptionListCarouselSidebar.module.scss';

export interface DescriptionListCarouselSidebarProps {
  activeIndex?: number;
  list: DescriptionListItem[];
}

const DescriptionListCarouselSidebar: FC<DescriptionListCarouselSidebarProps> = ({
  activeIndex = 0,
  list,
}) => {
  const [slideIndex, setSlideIndex] = useState(activeIndex);
  const buttonsTitles = useMemo(
    () => list.map((item) => item.buttonTitle || item.modalTitle || item.title),
    [list],
  );

  const [mainSlider, setMainSlider] = useState<SwiperRef['swiper'] | null>(null);
  const [navSlider, setNavSlider] = useState<SwiperRef['swiper'] | null>(null);

  const syncSliders = useCallback(() => {
    if (mainSlider && mainSlider.activeIndex !== slideIndex) {
      mainSlider.slideTo(slideIndex);
    }
    if (navSlider && navSlider.activeIndex !== slideIndex) {
      navSlider.slideTo(slideIndex);
    }
  }, [navSlider, mainSlider, slideIndex]);

  useEffect(() => {
    syncSliders();
    //eslint-disable-next-line
  }, [slideIndex]);

  useEffect(() => {
    if (typeof activeIndex === 'number' && mainSlider && navSlider) {
      syncSliders();
    }
    //eslint-disable-next-line
  }, [activeIndex, navSlider, mainSlider]);

  const handleSlideChange = useCallback((slider: SwiperClass) => {
    setSlideIndex(slider.activeIndex);
  }, []);
  return (
    <div className={classes.root}>
      <div>
        <div className={classes.line} />
        <Swiper onSlideChange={handleSlideChange} onSwiper={setMainSlider} className="mt-4">
          {list.map((i) => (
            <SwiperSlide className="px-3 grab h100" key={i.icon}>
              <div className={classes.iconContainer}>
                <Icon name={i.icon} size={28} />
              </div>
              <h5 className="mt-3">{i.modalTitle || i.title}</h5>
              <p className={classes.text} dangerouslySetInnerHTML={{ __html: i.description }} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="mt-4">
        <Swiper
          onSlideChange={handleSlideChange}
          onSwiper={setNavSlider}
          className="px-3"
          slidesPerView="auto"
          spaceBetween={8}
        >
          {buttonsTitles.map((title, index) => (
            <SwiperSlide className={classes.navSliderItem} key={title}>
              <Button
                className={classes.button}
                onClick={() => setSlideIndex(index)}
                variant={index === slideIndex ? 'greyishGreen' : 'outlinedBlack'}
              >
                {title}
              </Button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
export default DescriptionListCarouselSidebar;
