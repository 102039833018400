import clsx from 'clsx';

import { FC, useCallback, useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import loanDrawerTemplates from 'modules/loans/constants/drawerTemplates';
import { requestReadNotification } from 'modules/loans/store/thunks';
import { LoanNotification, LoanNotificationType } from 'modules/loans/types';
import classes from 'modules/loans/views/components/LoanNotificationCard/LoanNotificationCard.module.scss';
import { useDispatch } from 'store';

import { Icon, Image, Loader } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode } from 'utils/currency';

interface LoanNotificationCardProps {
  className?: string;
  notification: LoanNotification;
}

const dangerousNotificationTypes = [
  LoanNotificationType.autoPaymentFailed,
  LoanNotificationType.marginCall,
  LoanNotificationType.preMarginCall,
  LoanNotificationType.autoMarginCallReplenishmentFailed,
];

export const LoanNotificationCard: FC<LoanNotificationCardProps> = ({
  className,
  notification,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const dangerous = useMemo(
    () => dangerousNotificationTypes.includes(notification.type),
    [notification.type],
  );

  const [title, subtitle] = useMemo<[string | undefined, string | undefined]>(() => {
    switch (notification.type) {
      case LoanNotificationType.marginCall: {
        return [
          translate('LOANS_LOAN_UNDER_MC'),
          translate('LOANS_LOAN_UNDER_MC_SUBTITLE', {
            days: notification.loan.leftDaysBeforeLiquidation || 0,
          }),
        ];
      }
      case LoanNotificationType.preMarginCall: {
        return [translate('LOANS_LOAN_PRE_MC'), translate('LOANS_LOAN_PRE_MC_SUBTITLE')];
      }
      case LoanNotificationType.autoPaymentFailed: {
        return [
          translate('LOANS_LOAN_AUTO_PAYMENT_FAILED'),
          translate('LOANS_LOAN_AUTO_PAYMENT_FAILED_SUBTITLE', {
            currencyLabel: getCurrencyLabelByCode(notification.loan.loanCurrency),
          }),
        ];
      }
      case LoanNotificationType.autoPaymentSucceeded: {
        return [
          translate('LOANS_LOAN_AUTO_PAYMENT_SUCCEEDED'),
          translate('LOANS_LOAN_AUTO_PAYMENT_SUCCEEDED_SUBTITLE'),
        ];
      }
      case LoanNotificationType.marginCallAvoidedByRateRaised: {
        return [
          translate('LOANS_LOAN_MC_AVOIDED_TITLE'),
          translate('LOANS_LOAN_MC_AVOIDED_SUBTITLE'),
        ];
      }
      case LoanNotificationType.marginCallAvoidedByAutoReplenish: {
        return [
          translate('LOANS_LOAN_MC_AVOIDED_TITLE'),
          translate('LOANS_LOAN_MC_AVOIDED_SUBTITLE'),
        ];
      }
      case LoanNotificationType.autoMarginCallReplenishmentFailed: {
        return [
          translate('LOANS_LOAN_AUTO_MC_FAILED_TITLE'),
          translate('LOANS_LOAN_UNDER_MC_SUBTITLE', {
            days: notification.loan.leftDaysBeforeLiquidation || 0,
          }),
        ];
      }
      case LoanNotificationType.releaseExcessCollateral: {
        return [translate('LOANS_LOAN_EXCESS_COLLATERAL_RELEASE_TITLE'), undefined];
      }

      default: {
        return [undefined, undefined];
      }
    }
  }, [translate, notification]);

  const clickHandler = useMemo(() => {
    switch (notification.type) {
      case LoanNotificationType.marginCall: {
        return () => drawer.open(loanDrawerTemplates.loanMarginCall({ loan: notification.loan }));
      }
      case LoanNotificationType.preMarginCall: {
        return () =>
          drawer.open(loanDrawerTemplates.loanAddCollateral({ loan: notification.loan }));
      }
      case LoanNotificationType.autoMarginCallReplenishmentFailed: {
        return () =>
          drawer.open(loanDrawerTemplates.loanAddCollateral({ loan: notification.loan }));
      }
      case LoanNotificationType.autoPaymentFailed: {
        return () =>
          drawer.open(loanDrawerTemplates.loanAutoPaymentFailed({ loan: notification.loan }));
      }
      case LoanNotificationType.marginCallAvoidedByAutoReplenish: {
        return () =>
          drawer.open(
            commonDrawerTemplates.dynamicContent({
              title: translate('LOANS_MC_AVOIDED'),
              text: translate('LOANS_MC_AVOIDED_AUTO_DESC'),
              buttons: [
                { label: translate('CLOSE'), onClick: drawer.close, variant: 'greyishGreen' },
              ],
              iconProps: {
                name: 'checkInCircle',
                size: 72,
              },
            }),
          );
      }
      case LoanNotificationType.marginCallAvoidedByRateRaised: {
        return () =>
          drawer.open(
            commonDrawerTemplates.dynamicContent({
              title: translate('LOANS_MC_AVOIDED'),
              text: translate('LOANS_MC_AVOIDED_DESC'),
              buttons: [
                { label: translate('CLOSE'), onClick: drawer.close, variant: 'greyishGreen' },
              ],
              iconProps: {
                name: 'checkInCircle',
                size: 72,
              },
            }),
          );
      }
    }
  }, [translate, drawer, notification]);

  const readLoading = useFlag(false);
  const handleClick = useCallback(async () => {
    readLoading.on();
    if (clickHandler) {
      clickHandler();
    }
    if (!dangerous) {
      await dispatch(requestReadNotification({ loanId: notification.loan.id }));
    }
    readLoading.off();
  }, [clickHandler, readLoading, dangerous, dispatch, notification]);

  return title ? (
    <div
      className={clsx(classes.root, dangerous && classes.dangerous, className)}
      onClick={handleClick}
    >
      <Loader centered overlap active={readLoading.state} />
      <div className="column flex-1 flex-shrink-1 hidden">
        <div className="row gap-1">
          <div className="row aic gap-1">
            <Image
              className={classes.icon}
              name={dangerous ? 'circleDanger' : 'circleSuccess'}
              path="common"
              type="svg"
            />
            <span className={classes.title}>{title}</span>
          </div>
          <span className={classes.loanId}>#{notification.loan.id}</span>
        </div>
        {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
      </div>
      <Icon
        className={classes.endAdornment}
        name={clickHandler ? 'chevronRightSquaredThin' : 'crossSafe'}
        size={24}
      />
    </div>
  ) : null;
};
