import { RentingItem, RentingSchedule, Statistic, Template } from 'modules/cryptoRenting/types';
import { ListFilterReqPayload } from 'store/generators/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  RentingPayoutScheduleResponse,
  RentingRequest,
  RentingResponseList,
  RentingStatisticResponse,
  TemplatesResponse,
} from 'libs/swagger/nebeusApiTypes';

import {
  transformPayoutSchedule,
  transformRentingItems,
  transformRentingTemplates,
  transformStatistic,
} from './transformers';

// cli-import

const cryptoRentingServices = {
  getTemplates: (): Promise<Template[]> =>
    AxiosInstance.get<TemplatesResponse, TemplatesResponse>('/v1/deposit/template/search').then(
      transformRentingTemplates,
    ),
  getStatistic: (): Promise<Statistic> =>
    AxiosInstance.get<RentingStatisticResponse, RentingStatisticResponse>(
      '/v1/renting/statistic',
    ).then(transformStatistic),
  getRentingActiveItems: ({ filter }: { filter: ListFilterReqPayload }): Promise<RentingItem[]> =>
    AxiosInstance.get<RentingResponseList, RentingResponseList>('/v1/renting', {
      params: { status: 'ACTIVE', page: filter.pageNumber, size: filter.pageLimit },
    }).then((response) => transformRentingItems(response.rentingAccounts)),

  getRentingHistoryItems: ({ filter }: { filter: ListFilterReqPayload }): Promise<RentingItem[]> =>
    AxiosInstance.get<RentingResponseList, RentingResponseList>('/v1/renting', {
      params: { status: 'COMPLETED', page: filter.pageNumber, size: filter.pageLimit },
    }).then((response) => transformRentingItems(response.rentingAccounts)),

  createRenting: (payload: RentingRequest) => AxiosInstance.post('/v1/renting/open', payload),

  getPayoutSchedule: (id: number): Promise<RentingSchedule> =>
    AxiosInstance.get<RentingPayoutScheduleResponse, RentingPayoutScheduleResponse>(
      `/v1/renting/${id}/payout-schedule`,
    ).then(transformPayoutSchedule),

  // cli-service
};

export default cryptoRentingServices;
