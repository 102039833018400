import { vaultActions } from '../store';
import { requestWallets } from 'modules/accounts/store/thunks';
import { VaultTransactionItem, VaultWallet } from 'modules/vault/types';
import { generateRequestDataThunk, generateRequestThunk } from 'store/generators/thunks';

import { GetWalletsTransactionsUsingQueryParams } from 'libs/swagger/nebeusApiTypes';

import eventEmitter from 'utils/eventEmitter';

import vaultServices from '../services';

// cli-import

export const requestVaultWallets = generateRequestDataThunk<VaultWallet[]>({
  updateState: vaultActions.updateVaultWallets,
  request: vaultServices.getWallets,
  options: { showErrorToast: false },
});
export const requestVaultTransactions = generateRequestThunk<
  GetWalletsTransactionsUsingQueryParams,
  VaultTransactionItem[]
>({
  request: vaultServices.getTransactions,
  options: { showErrorToast: false },
});
export const requestDeposit = generateRequestThunk({
  request: vaultServices.deposit,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestVaultWallets());
      dispatch(requestWallets());
      eventEmitter.emit('refreshVaultHistory');
    },
  },
});
export const requestWithdraw = generateRequestThunk({
  request: vaultServices.withdraw,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestVaultWallets());
      dispatch(requestWallets());
      eventEmitter.emit('refreshVaultHistory');
    },
  },
});
// cli-thunk
