import { useMemo } from 'react';

import useWallets from 'modules/accounts/hooks/useWallets';
import useWithdrawLimits from 'modules/payment/hooks/useWithdrawLimits';
import { PaymentOperationId } from 'modules/payment/types';
import { isDepositByOperationId } from 'modules/payment/utils';
import useCommissionRule from 'modules/payment/views/components/AmountForm/hooks/useCommissionRule';
import useTransactionLimit from 'modules/payment/views/components/AmountForm/hooks/useTransactionLimit';

import { getTranslation } from 'libs/i18n';
import yup from 'libs/yup';

import {
  MINIMUM_AMOUNT_CRYPTO,
  MINIMUM_AMOUNT_FIAT,
  formatCurrency,
  formatCurrencyWithSymbol,
  isFiat,
} from 'utils/currency';

import { CurrencyCode } from 'types';

const useAmountFormValidation = ({
  operationId,
  currencyCode,
  isDigitalAccount = false,
}: {
  operationId: PaymentOperationId;
  currencyCode: CurrencyCode;
  isDigitalAccount?: boolean;
}) => {
  const isDeposit = isDepositByOperationId(operationId);

  const { wallets } = useWallets({ isDigitalAccount });

  const wallet = useMemo(
    () => wallets.find((w) => w.currencyCode === currencyCode),
    [wallets, currencyCode],
  );

  const transactionLimit = useTransactionLimit(operationId, currencyCode);
  const commissionRule = useCommissionRule(operationId, currencyCode);

  const operationHasCommission = useMemo(
    () =>
      !commissionRule
        ? false
        : Boolean(commissionRule.percent || commissionRule.fixed || commissionRule.minFixed),
    [commissionRule],
  );

  const withdrawalLimits = useWithdrawLimits(currencyCode, operationId);

  const amountFormSchema = useMemo(() => {
    const minAmount = +formatCurrency(transactionLimit.min, currencyCode, { round: 'up' });
    const maxAmount = transactionLimit.max
      ? +formatCurrency(transactionLimit.max, currencyCode, { round: 'up' })
      : null;

    const mainShape = {
      currencyAmount: yup.object().shape({
        amount: yup
          .number()
          .transform((value) => (isNaN(value) ? 0 : value))
          .min(
            minAmount,
            getTranslation('VALIDATION_MIN_AMOUNT', {
              minLabel: formatCurrencyWithSymbol(minAmount, currencyCode),
            }),
          )
          .test({
            name: 'limitMaxAmount',
            message: getTranslation('VALIDATION_MAX_AMOUNT', {
              maxLabel: formatCurrencyWithSymbol(maxAmount || 0, currencyCode),
            }),
            test: (value: number | undefined) => (!maxAmount || !value ? true : value <= maxAmount),
          })
          .test({
            name: 'withdrawLimitMaxAmount',
            message: getTranslation('VALIDATION_WITHDRAW_LIMITS_REACHED', {
              maxLabel: formatCurrencyWithSymbol(maxAmount || 0, currencyCode),
            }),
            test: (value: number | undefined) => {
              if (isDeposit || !withdrawalLimits || !value) {
                return true;
              }

              if (withdrawalLimits.daily.limit !== null) {
                const restDailyLimit = withdrawalLimits.daily.limit - withdrawalLimits.daily.used;
                if (value > restDailyLimit) {
                  return false;
                }
              }
              if (withdrawalLimits.monthly.limit !== null) {
                const restMonthlyLimit =
                  withdrawalLimits.monthly.limit - withdrawalLimits.monthly.used;

                if (value > restMonthlyLimit) {
                  return false;
                }
              }

              return true;
            },
          })
          .test({
            name: 'walletMaxAmount',
            message: getTranslation('VALIDATION_INSUFFICIENT_FUNDS'),
            test: (value: number | undefined) => {
              return isDeposit || !value
                ? true
                : value <= +formatCurrency(wallet!.amount, currencyCode, { round: 'down' });
            },
          })
          .requiredDefault(),
      }),
    };

    if (operationHasCommission) {
      // @ts-ignore
      mainShape['currencyAmountWithCommission'] = yup.object().shape({
        amount: yup
          .number()
          .transform((value) => (isNaN(value) ? 0 : value))
          .min(
            isFiat(currencyCode) ? MINIMUM_AMOUNT_FIAT : MINIMUM_AMOUNT_CRYPTO,
            getTranslation('VALIDATION_MIN_AMOUNT', {
              minLabel: formatCurrencyWithSymbol(
                isFiat(currencyCode) ? MINIMUM_AMOUNT_FIAT : MINIMUM_AMOUNT_CRYPTO,
                currencyCode,
              ),
            }),
          )
          .requiredDefault(),
      });
    }

    return yup.object().shape(mainShape);
  }, [isDeposit, withdrawalLimits, operationHasCommission, wallet, currencyCode, transactionLimit]);

  return { amountFormSchema };
};

export default useAmountFormValidation;
