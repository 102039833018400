import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import clsx from 'clsx';
import { FORM_ERROR } from 'final-form';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import useSideBar from 'modules/app/hooks/useSideBar';
import { requestSendOtpCode, requestVerifyOtpCode } from 'modules/app/store/thunks';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { selectUserProfile } from 'modules/user/store/selectors';
import { Profile } from 'modules/user/types';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { PinCodeField, SubmitButton } from 'components/form';

import { Flag } from 'hooks/useFlag';
import useWindowFocus from 'hooks/useWindowFocus';

import { getTranslation, useTranslation } from 'libs/i18n';
import { OtpForBankOperationRequest } from 'libs/swagger/nebeusApiTypes';
import yup, { makeValidate } from 'libs/yup';

import { getStringFromClipboard } from 'utils/common';
import { parseFixEmptyString } from 'utils/inputParsers';

import classes from './ConfirmOTPCodeCard.module.scss';

interface FormValues {
  code: string;
}

const initialValues: FormValues = {
  code: '',
};

interface ConfirmOTPCodeCardFormProps extends FormRenderProps<FormValues> {
  user: Profile | null;
  className?: string;
  sendOtp: () => Promise<void>;
}

const OTP_CODE_LENGTH = 6;

const ConfirmOTPCodeForm: FC<ConfirmOTPCodeCardFormProps> = ({
  handleSubmit,
  dirtySinceLastSubmit,
  submitError,
  values,
  form: { change: changeForm },
  user,
  className,
  sendOtp: sendOtpFromProps,
}) => {
  const translate = useTranslation();

  const [seconds, setSeconds] = useState(0);

  const sendOtp = useCallback(async () => {
    await sendOtpFromProps();
    setSeconds(59);
  }, [sendOtpFromProps]);

  useEffect(() => {
    sendOtp();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (seconds > 0 && !intervalId) {
      intervalId = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [seconds]);

  const pasteCodeFromClipboard = useCallback(async () => {
    const code = await getStringFromClipboard();
    if (code.length === OTP_CODE_LENGTH && !isNaN(Number(code))) {
      changeForm('code', code);
    }
  }, [changeForm]);

  useWindowFocus(pasteCodeFromClipboard);

  const hasError = useMemo(
    () => submitError && !dirtySinceLastSubmit,
    [submitError, dirtySinceLastSubmit],
  );
  const customDisableSubmitButton = useMemo(
    () => values.code.length !== OTP_CODE_LENGTH || (submitError && !dirtySinceLastSubmit),
    [values.code, submitError, dirtySinceLastSubmit],
  );

  return (
    <form onSubmit={handleSubmit} className="column gap-3" translate="no">
      <div className={clsx(classes.creamyCard, className)}>
        <span className={classes.formHeader}>{translate('CONFIRM_OTP_AUTHENTICATE')}</span>
        <p>
          {translate(
            user!.otpByEmail ? 'CONFIRM_OTP_INSTRUCTIONS_EMAIL' : 'CONFIRM_OTP_INSTRUCTIONS_SMS',
          )}
        </p>
        <div className="mt-2">
          <Field
            name="code"
            component={PinCodeField}
            onSubmit={handleSubmit}
            length={6}
            parse={parseFixEmptyString}
            variant="white"
          />
          <span className={classes.codeStateLabel}>
            {hasError ? (
              <span className={classes.error}>{submitError}. </span>
            ) : (
              <span>{getTranslation('CONFIRM_OTP_CODE_SENT')}. </span>
            )}
            {seconds === 0 ? (
              <span onClick={sendOtp} className={classes.resendLabel}>
                {getTranslation('CONFIRM_OTP_RESEND_CODE')}
              </span>
            ) : (
              <span>
                {getTranslation('CONFIRM_OTP_RESEND_IN')}{' '}
                <span className={classes.blue}>
                  0:{seconds < 10 ? '0' : ''}
                  {seconds}
                </span>
              </span>
            )}
          </span>
        </div>
      </div>
      <div className="column gap-2">
        <SubmitButton disabled={customDisableSubmitButton} fullWidth>
          {getTranslation('CONFIRM')}
        </SubmitButton>
        <AgreementText />
      </div>
    </form>
  );
};

interface ConfirmOTPCardProps {
  loading: Flag;
  onConfirmed: (code: string) => MaybePromise<boolean | void>;
  className?: string;
  manualConfirm?: boolean;
  sendOtpReqPayload: OtpForBankOperationRequest;
}

const ConfirmOTPCodeCard: FC<ConfirmOTPCardProps> = ({
  loading,
  onConfirmed,
  className,
  manualConfirm = true,
  sendOtpReqPayload,
}) => {
  const dispatch = useDispatch();
  const sidebar = useSideBar();

  const user = useSelector(selectUserProfile);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      loading.on();
      if (manualConfirm) {
        const codeValid = await onConfirmed(values.code);

        if (codeValid === false) {
          loading.off();
          return { [FORM_ERROR]: getTranslation('CONFIRM_OTP_INCORRECT_CODE') };
        }
      } else {
        const { success, data } = await dispatch(requestVerifyOtpCode(values.code));

        if (success && data?.otpCodeStatus === 'CODE_VERIFIED') {
          await onConfirmed(values.code);
        } else {
          loading.off();

          return { [FORM_ERROR]: getTranslation('CONFIRM_OTP_INCORRECT_CODE') };
        }
      }

      loading.off();
    },
    [manualConfirm, loading, dispatch, onConfirmed],
  );

  const validate = useMemo(
    () =>
      makeValidate(
        yup.object().shape({
          code: yup
            .string()
            .length(
              OTP_CODE_LENGTH,
              getTranslation('VALIDATION_DIGITS_COUNT', { count: OTP_CODE_LENGTH }),
            )
            .requiredDefault(),
        }),
      ),
    [],
  );
  const sendOtp = useCallback(async () => {
    loading.on();
    const { error } = await dispatch(requestSendOtpCode(sendOtpReqPayload));
    if (error) {
      sidebar.open(
        ...sidebarTemplates.dynamicContent({
          title: getTranslation('ERROR_SEEMS_PROBLEM'),
          text: error?.message || getTranslation('ERROR_SOMETHING_BROKE'),
          imgProps: { name: 'technicalError' },
          buttons: [
            { label: getTranslation('CLOSE'), variant: 'lightGreen', onClick: sidebar.close },
            {
              label: getTranslation('PAYMENT_BACK_TO_PROCESS'),
              variant: 'greyishGreen',
              onClick: sidebar.pop,
            },
          ],
        }),
      );
      loading.off();
      return;
    }
    loading.off();
  }, [sidebar, sendOtpReqPayload, loading, dispatch]);

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <ConfirmOTPCodeForm sendOtp={sendOtp} className={className} user={user} {...formProps} />
    ),
    [sendOtp, user, className],
  );

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={renderForm}
    />
  );
};

export default ConfirmOTPCodeCard;
