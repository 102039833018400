import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { CreateInvoiceProps } from 'modules/invoicing/views/CreateInvoice';
import { CreateInvoiceConfirmationProps } from 'modules/invoicing/views/CreateInvoiceConfirmation';
import { EditInvoiceStepProps } from 'modules/invoicing/views/EditInvoiceStep';
import { InvoiceCreatedProps } from 'modules/invoicing/views/InvoiceCreated';
import { InvoiceFullProps } from 'modules/invoicing/views/InvoiceFull';
import { SavedClientsProps } from 'modules/invoicing/views/SavedClients';

import { getTranslation } from 'libs/i18n';

const invoices = (): DrawerTemplateReturnType => ({
  key: 'invoices',
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
      className: 'jcsb invoicesHeader',
    },
    navigationHeaderLabel: getTranslation('INVOICES'),
  },
  props: {
    headerSelector: '.invoicesHeader',
  },
});
const createInvoice = (props?: CreateInvoiceProps): DrawerTemplateReturnType => ({
  key: 'createInvoice',
  props,
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
    },
    navigationHeaderLabel: getTranslation('INVOICES_CREATE'),
  },
});
const createInvoiceConfirmation = (
  props: CreateInvoiceConfirmationProps,
): DrawerTemplateReturnType => ({
  key: 'createInvoiceConfirmation',
  props,
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
    },
    navigationHeaderLabel: getTranslation('CONFIRMATION'),
  },
});
const savedClients = (props: SavedClientsProps): DrawerTemplateReturnType => ({
  key: 'invoicesSavedClients',
  props,
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
    },
    navigationHeaderLabel: getTranslation(
      props.variant === 'pick' ? 'INVOICES_USE_SAVED_DETAILS' : 'INVOICES_CLIENT_DETAILS',
    ),
    swipeModal: props.variant === 'pick',
    swipeModalMaxHeight: props.variant === 'pick',
  },
});
const editInvoiceStep = (props: EditInvoiceStepProps): DrawerTemplateReturnType => ({
  key: 'editInvoiceStep',
  props,
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
    },
    navigationHeaderLabel: props.stepName,
    swipeModal: true,
  },
});
const invoiceCreated = (props: InvoiceCreatedProps): DrawerTemplateReturnType => ({
  key: 'invoiceCreated',
  props,
  sideBarProps: {
    showBackButton: false,
  },
});

export const INVOICE_VIEW_HEADER_CLASSNAME = 'invoiceHeader';
const invoice = (props: InvoiceFullProps): DrawerTemplateReturnType => ({
  key: 'invoice',
  props: { ...props },
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
      className: `jcsb ${INVOICE_VIEW_HEADER_CLASSNAME}`,
    },
    navigationHeaderLabel: getTranslation('INVOICE') + ` #${props.invoice.invoiceDetails.number}`,
  },
});

const invoicingDrawerTemplates = {
  invoices,
  createInvoice,
  savedClients,
  createInvoiceConfirmation,
  editInvoiceStep,
  invoiceCreated,
  invoice,
};

export default invoicingDrawerTemplates;
