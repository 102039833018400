import { useMemo } from 'react';

import { selectCurrenciesNetworksReducer } from 'modules/payment/store/selectors';
import { requestCurrenciesNetworks } from 'modules/payment/store/thunks';

import useStoreEntity from 'hooks/useStoreEntity';

import { findByProperty } from 'utils/arrayUtils';

import { CryptoCurrencyCode } from 'types';

export interface GetAllowedCryptoNetworksOptions {
  isDeposit?: boolean;
}
const useAllowedCryptoNetworksByCurrencyCode = (
  currencyCode: CryptoCurrencyCode,
  options?: GetAllowedCryptoNetworksOptions,
) => {
  const { entityReducer: currenciesNetworks } = useStoreEntity(
    selectCurrenciesNetworksReducer,
    requestCurrenciesNetworks,
  );

  const allowedNetworks = useMemo(() => {
    let result =
      findByProperty(currenciesNetworks.data, 'currencyCode', currencyCode)?.networks || [];
    if (options) {
      if (options.isDeposit) {
        result = result.filter((n) => n.availableForIncomeTransactions);
      } else if (options.isDeposit === false) {
        result = result.filter((n) => n.availableForOutcomeTransactions);
      }
    }
    return result;
  }, [options, currenciesNetworks.data, currencyCode]);

  return { allowedNetworks };
};

export default useAllowedCryptoNetworksByCurrencyCode;
