import { random } from 'lodash';

interface Subscriber {
  id: string;
  eventName: string;
  func: (payload?: any) => void;
}

export enum eventEmitterEventNames {
  phonebookCounterpartyCreated = 'phonebookCounterpartyCreated',
  phonebookCounterpartyPaymentDetailsUpdated = 'phonebookCounterpartyPaymentDetailsUpdated',
  internalServerError = 'internalServerError',
}

class EventEmitter {
  private subscribers: Subscriber[] = [];

  emit(eventName: string, payload?: any) {
    const subscribers = this.subscribers.filter((s) => s.eventName === eventName);

    if (subscribers.length) {
      subscribers.forEach((subscriber) => {
        subscriber.func(payload);
      });
    }
  }

  subscribe(eventName: string, func: (payload?: any) => void) {
    const id = random(0, 9999999).toString();
    this.subscribers.push({ id, eventName, func });

    return () => {
      const removeIndex = this.subscribers.findIndex((s) => s.id === id);
      this.subscribers.splice(removeIndex, 1);
    };
  }
}

const eventEmitter = new EventEmitter();

// @ts-ignore
window.internalEventEmitter = eventEmitter;

export default eventEmitter;
