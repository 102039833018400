import axios from 'axios';

import appConfig from 'constants/appConfig';

const MaintenanceAxiosInstance = axios.create({
  baseURL: appConfig.maintenanceApiUrl,
});

MaintenanceAxiosInstance.interceptors.response.use((response) => {
  return response.data;
});

export default MaintenanceAxiosInstance;
