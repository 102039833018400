import { useEffect } from 'react';

import { voidFunc } from 'types';

const useInterval = (func: voidFunc, ms: number) => {
  useEffect(() => {
    const intervalId = setInterval(func, ms);

    return () => {
      clearInterval(intervalId);
    };
  }, [func, ms]);
};

export default useInterval;
