import { maxBy } from 'lodash';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import { selectTemplatesReducer } from 'modules/cryptoRenting/store/selectors';
import { requestTemplates } from 'modules/cryptoRenting/store/thunks';
import { TemplateName } from 'modules/cryptoRenting/types';

import useStoreEntity from 'hooks/useStoreEntity';

import { transformArrayToMap } from 'utils/arrayUtils';
import { isFiat } from 'utils/currency';

import { CurrencyCode } from 'types';

interface PotentialWalletForRenting {
  currencyCode: CurrencyCode;
  templateName: TemplateName;
  percent: number;
}
const useRentingPromoWallets = () => {
  const {
    entityReducer: { data: rentingTemplates },
  } = useStoreEntity(selectTemplatesReducer, requestTemplates);

  const tradingWallets = useSelector(selectWallets);

  const potentialWalletsForRenting = useMemo<PotentialWalletForRenting[]>(() => {
    const result: PotentialWalletForRenting[] = [];
    tradingWallets
      .filter((w) => w.amount > 0 && !isFiat(w.currencyCode))
      .forEach((w) => {
        const matchedTemplates = rentingTemplates.filter((t) =>
          t.rules.some((r) => r.currencyCode === w.currencyCode && w.amount >= r.minAmount),
        );
        if (matchedTemplates.length) {
          const bestTemplate = maxBy(matchedTemplates, (t) => t.percentRPY);

          if (bestTemplate) {
            result.push({
              currencyCode: w.currencyCode,
              templateName: bestTemplate.name,
              percent: bestTemplate.percentRPY,
            });
          }
        }
      });

    return result;
  }, [rentingTemplates, tradingWallets]);

  const potentialWalletsForRentingMap = useMemo<{
    [key in CurrencyCode]?: PotentialWalletForRenting;
  }>(
    () => transformArrayToMap(potentialWalletsForRenting, 'currencyCode'),
    [potentialWalletsForRenting],
  );

  return { potentialWalletsForRenting, potentialWalletsForRentingMap };
};

export default useRentingPromoWallets;
