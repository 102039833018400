import { FC } from 'react';

import usePaymentsDetails from 'modules/payment/hooks/usePaymentsDetails';
import { PaymentOperationId } from 'modules/payment/types';

import { DetailsCard } from 'components/ui';
import { DetailsCardProps } from 'components/ui/DetailsCard';

import { FiatCurrencyCode } from 'types';

export type DepositByDetailsOperationId =
  | PaymentOperationId.depositFromSepaToMoneyWallet
  | PaymentOperationId.depositFromSepaToTradingWallet
  | PaymentOperationId.depositFromWireToTradingWallet
  | PaymentOperationId.depositFromWireToMoneyWallet;

interface PaymentDetailsCardProps extends Partial<DetailsCardProps> {
  operationId: DepositByDetailsOperationId;
  className?: string;
  variant?: 'short' | 'full';
  currencyCode: FiatCurrencyCode;
}
export const PaymentDetailsCard: FC<PaymentDetailsCardProps> = ({
  variant = 'full',
  operationId,
  currencyCode,
  ...props
}) => {
  const { detailsConfig } = usePaymentsDetails({ operationId, variant, currencyCode });
  return detailsConfig ? <DetailsCard details={detailsConfig} {...props} /> : null;
};
