import clsx from 'clsx';

import { FC } from 'react';

import { cryptoBankFeaturesShort } from 'modules/cryptoBankCard/constants/config';

import { Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './BankCardFeaturesList.module.scss';

interface BankCardFeaturesListProps {
  className?: string;
}
const BankCardFeaturesList: FC<BankCardFeaturesListProps> = ({ className }) => {
  const translate = useTranslation();

  return (
    <div className={clsx('column gap-3', className)}>
      {cryptoBankFeaturesShort.map((i) => (
        <div className={classes.feature} key={i.title}>
          <Icon className={classes.icon} name={i.icon} size={32} />
          <span>{translate(i.title)}</span>
        </div>
      ))}
    </div>
  );
};

export default BankCardFeaturesList;
