import clsx from 'clsx';

import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import rentingClasses from 'modules/cryptoRenting/constants/RentingCommon.module.scss';
import { selectTemplates } from 'modules/cryptoRenting/store/selectors';
import { TemplateName } from 'modules/cryptoRenting/types';
import { TemplateImage } from 'modules/cryptoRenting/views/CryptoRenting/components/TemplateImage';

import { useTranslation } from 'libs/i18n';

import classes from './TemplateHeader.module.scss';

interface TemplateHeaderProps {
  templateName: TemplateName;
}
export const TemplateHeader: FC<TemplateHeaderProps> = ({ templateName }) => {
  const translate = useTranslation();
  const templates = useSelector(selectTemplates);

  const template = useMemo(
    () => templates.find((t) => t.name === templateName),
    [templates, templateName],
  );
  return template ? (
    <div className="row aife jcsb">
      <div className="column">
        <span className={clsx(classes.name, rentingClasses[`templateLabel${templateName}`])}>
          {templateName}
        </span>
        <span className={classes.percent}>
          {template.percentRPY}% {translate('RENTING_RPY')}
        </span>
      </div>
      <TemplateImage templateName={templateName} size="md" />
    </div>
  ) : null;
};
