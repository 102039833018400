import clsx from 'clsx';

import { FC, ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import {
  selectStakingItemsReducer,
  selectStakingTemplatesReducer,
} from 'modules/staking/store/selectors';
import { requestStakingItems, requestStakingTemplates } from 'modules/staking/store/thunks';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import {
  CurrencyIcon,
  Icon,
  LineDelimiter,
  Loader,
  NoResultLabel,
  SearchInput,
} from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';
import { formatCurrency, formatCurrencyWithSymbol, getCurrencyLabelByCode } from 'utils/currency';

import { CryptoCurrencyCode, CurrencyCode, FiatCurrencyCode } from 'types';

import classes from './Assets.module.scss';

interface CardListItem {
  currencyCode: CurrencyCode;
  currencyLabel: string;
  percentRPY: number;

  assets?: { amount: number; amountInDefaultCurrency: number; defaultCurrency: FiatCurrencyCode };
}
interface CardListProps {
  list: CardListItem[];
  className?: string;
  label: string;
  endAdornment?: ReactElement;
  listPreComponent?: ReactElement;
  onClick: (code: CryptoCurrencyCode) => void;
}
const CardList: FC<CardListProps> = ({
  className,
  list,
  label,
  endAdornment,
  onClick,
  listPreComponent,
}) => {
  const translate = useTranslation();

  const rpyLabel = translate('STAKING_RPY');

  const handleClick = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      const code = e.currentTarget.dataset.code as CryptoCurrencyCode;
      if (code) {
        onClick(code);
      }
    },
    [onClick],
  );

  return (
    <div className={clsx(className, classes.cardList)}>
      <div className={clsx(classes.titleRow, endAdornment && classes.withAdornment)}>
        <span className={classes.title}>{label}</span>
        {endAdornment}
      </div>
      <LineDelimiter className={classes.lineDelimiter} />
      <div className={classes.cardListContainer}>
        {listPreComponent}
        {list.map((i) => (
          <div
            onClick={handleClick}
            data-code={i.currencyCode}
            key={i.currencyCode}
            className={clsx(classes.cardListItem, 'row  jcsb aic')}
          >
            <div className="row aic">
              <CurrencyIcon className="mr-1-5" code={i.currencyCode} size={32} />
              <div className="column">
                <div className={classes.cardListItemTitles}>
                  <span>{i.currencyLabel}</span>
                  <span>{i.currencyCode}</span>
                </div>
                <span className={classes.percent}>
                  {i.percentRPY}% {rpyLabel}
                </span>
              </div>
            </div>
            <div className="row aic">
              {i.assets && (
                <div className={classes.assetsTitles}>
                  <span>{formatCurrency(i.assets.amount, false)}</span>
                  {i.assets.amountInDefaultCurrency ? (
                    <span>
                      {formatCurrencyWithSymbol(
                        i.assets.amountInDefaultCurrency,
                        i.assets.defaultCurrency,
                      )}
                    </span>
                  ) : null}
                </div>
              )}
              <div className={classes.arrowContainer}>
                <Icon name="arrowRight" size="sm" color="greyishGreen" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const Assets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translate = useTranslation();
  const sidebar = useSideBar();

  const templatesReducer = useSelector(selectStakingTemplatesReducer);
  const stakingItemsReducer = useSelector(selectStakingItemsReducer);

  const [filterInput, setFilterInput] = useState('');

  useEffect(() => {
    if (canRequest(templatesReducer.meta)) {
      dispatch(requestStakingTemplates());
    }
    if (canRequest(stakingItemsReducer.meta)) {
      dispatch(requestStakingItems());
    }
    // eslint-disable-next-line
  }, []);

  const allTemplates = useMemo<CardListItem[]>(
    () =>
      templatesReducer.data.map((t) => ({
        currencyCode: t.currencyCode,
        currencyLabel: getCurrencyLabelByCode(t.currencyCode),
        percentRPY: t.percentRPY,
      })),
    [templatesReducer.data],
  );

  const filteredTemplates = useMemo(() => {
    if (!filterInput.length) {
      return allTemplates;
    }
    const filterUpperCased = filterInput.toUpperCase();

    return allTemplates.filter(
      (t) =>
        t.currencyCode.includes(filterUpperCased) ||
        t.currencyLabel.toUpperCase().includes(filterUpperCased),
    );
  }, [filterInput, allTemplates]);

  const stakingItemsList = useMemo<CardListItem[]>(
    () =>
      stakingItemsReducer.data
        .map((s) => ({
          currencyCode: s.currencyCode,
          currencyLabel: getCurrencyLabelByCode(s.currencyCode),
          percentRPY: s.percentRPY,
          assets: {
            amount: s.amount,
            amountInDefaultCurrency: s.amountInDefaultCurrency,
            defaultCurrency: s.defaultCurrency,
          },
        }))
        .sort((a, b) => b.assets.amount - a.assets.amount),
    [stakingItemsReducer],
  );

  const handleClickTemplate = useCallback(
    (currencyCode: CryptoCurrencyCode) => {
      sidebar.open(...sidebarTemplates.stakingSetup({ currencyCode }));
    },
    [sidebar],
  );

  const handleClickStakingItem = useCallback(
    (currencyCode: CryptoCurrencyCode) => {
      navigate(routesByName.stakingItem(currencyCode));
    },
    [navigate],
  );
  return (
    <>
      {stakingItemsReducer.meta.loading && !stakingItemsReducer.meta.loaded ? (
        <Loader centered className="mb-10" />
      ) : stakingItemsReducer.data.length ? (
        <CardList
          onClick={handleClickStakingItem}
          label={translate('STAKED_ASSETS')}
          list={stakingItemsList}
        />
      ) : null}
      <div>
        {templatesReducer.meta.loading && !templatesReducer.meta.loaded ? (
          <Loader centered />
        ) : templatesReducer.data.length ? (
          <CardList
            onClick={handleClickTemplate}
            label={translate('ALL_CURRENCIES')}
            endAdornment={
              <SearchInput
                className={classes.searchInput}
                placeholder={translate('PLACEHOLDER_SEARCH_CURRENCIES')}
                value={filterInput}
                onChange={setFilterInput}
                searchIconPosition="end"
              />
            }
            list={filteredTemplates}
            listPreComponent={
              filteredTemplates.length === 0 && filterInput.length > 0 ? (
                <NoResultLabel findString={filterInput} />
              ) : undefined
            }
          />
        ) : null}
      </div>
    </>
  );
};
