import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import {
  selectBankCardsOrdersReducer,
  selectBankCardsReducer as selectCryptoBankCardsReducer,
  selectIsCryptoBankCardAllowed,
} from 'modules/cryptoBankCard/store/selectors';
import { requestBankCardsOrders } from 'modules/cryptoBankCard/store/thunks';

import { DottedLine } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import classes from './BankCards.module.scss';
import { BankCardLogicReducer } from './components/BankCardLogicReducer';

interface BankCardsProps {}
const BankCards: FC<BankCardsProps> = () => {
  const translate = useTranslation();

  const { entityReducer: bankCardOrdersReducer } = useStoreEntity(
    selectBankCardsOrdersReducer,
    requestBankCardsOrders,
  );

  const isCryptoCardAllowed = useSelector(selectIsCryptoBankCardAllowed);

  const cryptoCardsReducer = useSelector(selectCryptoBankCardsReducer);

  const tradingWallets = useSelector(selectWallets);

  const cryptoCardsWithBalance = useMemo(
    () =>
      cryptoCardsReducer.data.map((card) => ({
        ...card,
        amount: tradingWallets.find((w) => w.currencyCode === card.currencyCode)?.amount ?? null,
      })),
    [tradingWallets, cryptoCardsReducer.data],
  );

  const [cryptoPhysicalCard, cryptoVirtualCard] = useMemo(
    () => [
      cryptoCardsWithBalance.find((c) => !c.isVirtual && c.isActivated),
      cryptoCardsWithBalance.find((c) => c.isVirtual && c.isActivated),
    ],
    [cryptoCardsWithBalance],
  );

  const [cryptoPhysicalBankCardOrder, cryptoVirtualBankCardOrder] = useMemo(
    () => [
      bankCardOrdersReducer.data.find((o) => !o.isVirtual && !o.read),
      bankCardOrdersReducer.data.find((o) => o.isVirtual && !o.read),
    ],
    [bankCardOrdersReducer.data],
  );

  if (!isCryptoCardAllowed) {
    return null;
  }

  return (
    <div className={classes.root}>
      <DottedLine />
      <div className="column gap-2">
        <span className="label">{translate('CARDS')}</span>
        <div className="column gap-2 aifs">
          <BankCardLogicReducer
            card={cryptoPhysicalCard}
            cardOrder={cryptoPhysicalBankCardOrder}
            showAdvertisement
          />
          <BankCardLogicReducer
            card={cryptoVirtualCard}
            cardOrder={cryptoVirtualBankCardOrder}
            showAdvertisement={false}
          />
        </div>
      </div>
      <DottedLine />
    </div>
  );
};

export default BankCards;
