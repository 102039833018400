import { sumBy } from 'lodash';

import { useMemo } from 'react';
import MediaQuery from 'react-responsive';

import { selectVaultWalletsReducer } from 'modules/vault/store/selectors';
import { requestVaultWallets } from 'modules/vault/store/thunks';
import { VaultWallet } from 'modules/vault/types';

import { NAV_BAR_SHOWED_MIN_WIDTH } from 'constants/responsive';

import { EntityView } from 'components/common';
import { InfoIcon, Loader, NavigationHeader } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import classes from './Vault.module.scss';
import { Assets } from './components/Assets';
import { DescriptionCard } from './components/DescriptionCard';
import { History } from './components/History';
import { OperationButtons } from './components/OperationButtons';
import { VaultIntroduction } from './components/VaultIntroduction';

const Vault = () => {
  const translate = useTranslation();

  const { entityReducer: walletsReducer, fetchEntity: fetchWallets } = useStoreEntity<
    VaultWallet[]
  >(selectVaultWalletsReducer, requestVaultWallets);

  const userHasFundsInVault = useMemo(
    () => sumBy(walletsReducer.data, 'amountInDefaultCurrency') > 0,
    [walletsReducer.data],
  );

  return (
    <div className={classes.root}>
      <MediaQuery maxWidth={NAV_BAR_SHOWED_MIN_WIDTH}>
        <div className="row aic jcsb gap-3">
          <NavigationHeader backButtonFilled={false}>
            {translate('VAULT_COLD_STORAGE')}
          </NavigationHeader>
          <InfoIcon
            title={translate('VAULT_INTRO_TITLE')}
            description={translate('VAULT_INTRO_TEXT_P1') + translate('VAULT_INTRO_TEXT_P2')}
          />
        </div>
      </MediaQuery>

      <EntityView
        loaderClassName={classes.assetsLoader}
        reducer={walletsReducer}
        request={fetchWallets}
        component={<Assets />}
      />
      <OperationButtons />

      <DescriptionCard />

      {walletsReducer.meta.loading && !walletsReducer.meta.loaded ? (
        <Loader centered className="mt-3" />
      ) : userHasFundsInVault ? (
        <History />
      ) : (
        <VaultIntroduction />
      )}
    </div>
  );
};

export default Vault;
