import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { requestCreateDigitalWallet } from 'modules/accounts/store/thunks';
import useSideBar from 'modules/app/hooks/useSideBar';
import { SideBarProps } from 'modules/app/types';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { IBAN_ACCOUNT_PRICE_PER_MONTH } from 'modules/digitalAccount/constants/config';
import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionLayout } from 'modules/digitalAccount/views/components/SubscriptionLayout';
import { SubscriptionPriceCard } from 'modules/digitalAccount/views/components/SubscriptionPriceCard';
import { SubscriptionProcessTitle } from 'modules/digitalAccount/views/components/SubscriptionProcessTitle';
import { selectIsUserVerified, selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { Button, DescriptionList } from 'components/ui';
import { DescriptionListItem } from 'components/ui/DescriptionList';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import classes from './GetSubscription.module.scss';

export interface GetSubscriptionProps {
  subscription: DigitalSubscription;
}

const sideBarProps: SideBarProps = {
  contentClassName: classes.descriptionListBg,
  variant: 'transparent',
  secondaryNavigationProps: { variant: 'light' },
};

const GetSubscription: FC<GetSubscriptionProps> = ({ subscription }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();
  const dispatch = useDispatch();

  const benefitsList: DescriptionListItem[] = useMemo(
    () => [
      {
        title: translate('IBAN_ACCOUNT_BENEFIT_1_TITLE'),
        description: translate('IBAN_ACCOUNT_BENEFIT_1_DESCRIPTION'),
        icon: 'refresh-square',
      },
      {
        title: translate('IBAN_ACCOUNT_BENEFIT_2_TITLE'),
        description: translate('IBAN_ACCOUNT_BENEFIT_2_DESCRIPTION'),
        icon: 'transfer-arrows',
      },
      {
        title: translate('IBAN_ACCOUNT_BENEFIT_3_TITLE'),
        description: translate('IBAN_ACCOUNT_BENEFIT_3_DESCRIPTION'),
        icon: 'people',
      },
      {
        title: translate('IBAN_ACCOUNT_BENEFIT_4_TITLE'),
        description: translate('IBAN_ACCOUNT_BENEFIT_4_DESCRIPTION'),
        icon: 'people',
      },
      {
        title: translate('IBAN_ACCOUNT_BENEFIT_5_TITLE'),
        description: translate('IBAN_ACCOUNT_BENEFIT_5_DESCRIPTION'),
        icon: 'people',
      },
    ],
    [translate],
  );

  const user = useSelector(selectUserProfile);
  const userVerified = useSelector(selectIsUserVerified);

  const loading = useFlag(false);

  const handleSubmit = useCallback(async () => {
    if (!userVerified) {
      sidebar.open(...sidebarTemplates.verificationID());
      return;
    }
    if (!user) {
      return;
    }
    if (subscription.type === 'standard') {
      // return;
      // loading.on();
      //
      // analytic.sendEvent(AnalyticEvents.userRequestModulRAccount);
      //
      // const { success } = await dispatch(
      //   requestCreateMoneyAccount({ email: user.email, id: user.id }),
      // );
      //
      // loading.off();
      //
      // if (success) {
      //   sidebar.replaceAll(
      //     ...sidebarTemplates.digitalAccountSubscriptionRequested({
      //       subscription,
      //     }),
      //   );
      // }

      loading.on();
      // For standard we open modulr account and backend create standard subs
      const { success } = await dispatch(requestCreateDigitalWallet('EUR'));

      // const { success: subSuccess, data: subscriptions } = await dispatch(
      //   requestDigitalAccountSubscriptions(),
      // );
      loading.off();

      if (success) {
        sidebar.replaceAll(
          ...sidebarTemplates.digitalAccountSubscriptionRequested({
            subscription,
          }),
        );
      }

      // const userHasDigitalWallet = digitalWallets.find((w) => w.currencyCode === 'EUR' && w.exist);

      // if (success && subSuccess && subscriptions?.length) {
      //   analytic.sendEvent(AnalyticEvents.userSuccessfullyRequestIbanAccount);
      //
      //   sidebar.replaceAll(
      //     ...sidebarTemplates.digitalAccountGetSubscriptionSuccess({
      //       subscription: subscriptions.find((s) => s.type === 'standard')!,
      //       showWalletDetails: !userHasDigitalWallet,
      //     }),
      //   );
      // }
    } else {
      sidebar.open(...sidebarTemplates.digitalAccountGetSubscriptionConfirm({ subscription }));
    }
  }, [user, dispatch, userVerified, sidebar, loading, subscription]);

  return (
    <SubscriptionLayout subscriptionType={subscription.type}>
      <div>
        <SubscriptionProcessTitle subscriptionType={subscription.type} />
        <SubscriptionPriceCard subscription={subscription} />
        <DescriptionList className="mt-3" list={benefitsList} sideBarProps={sideBarProps} />
      </div>
      <div className="column gap-1-5">
        <p className="white font-xs">
          {translate('IBAN_ACCOUNT_CREATING_WARNING', { price: IBAN_ACCOUNT_PRICE_PER_MONTH })}
        </p>
        <Button loading={loading.state} onClick={handleSubmit} fullWidth variant="gold" showShadow>
          {translate('CONTINUE')}
        </Button>
        <AgreementText variant="modulR" light />
      </div>
    </SubscriptionLayout>
  );
};

export default GetSubscription;
