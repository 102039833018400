import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import clsx from 'clsx';

import {
  MutableRefObject,
  ReactElement,
  ReactNode,
  RefObject,
  SyntheticEvent,
  memo,
  useState,
} from 'react';

import { Icon, IconProps } from 'components/ui/Icon';
import { Loader } from 'components/ui/Loader';
import { Skeleton } from 'components/ui/Skeleton';

import classes from './MenuList.module.scss';

export interface MenuListItem {
  forwardRef?: ((ref: HTMLDivElement) => void) | RefObject<HTMLDivElement>;
  label: string;
  className?: string;
  underLabelComponent?: ReactNode;
  disabled?: boolean;
  textEllipsis?: boolean;
  handler?: (event: SyntheticEvent<HTMLDivElement>) => MaybePromise<void>;
  asyncHandler?: boolean;
  icon?: string;
  iconProps?: Partial<IconProps>;
  description?: string;
  endAdornment?: ReactElement;
  startAdornment?: ReactElement;
  arrowNext?: boolean;
  loading?: boolean;
  key?: string;
}
interface MenuListProps {
  className?: string;
  variant?: 'outlined' | 'creamy';
  menu: MenuListItem[];
  forwardRef?: MutableRefObject<HTMLDivElement | null>;
  centered?: boolean;
  loading?: boolean;
}

export const MenuList = memo<MenuListProps>(
  ({ forwardRef, className, loading, menu, variant = 'creamy', centered }) => {
    const [loadingLabel, setLoadingLabel] = useState<string | null>(null);

    if (loading) {
      return (
        <div className={clsx(classes.root, className, classes[`variant-${variant}`])}>
          {new Array(menu.length).fill(null).map((_, i) => (
            <Skeleton key={i} height={96} borderRadius={null} className={classes.menuItemLoading} />
          ))}
        </div>
      );
    }
    return (
      <div
        ref={forwardRef}
        className={clsx(classes.root, className, classes[`variant-${variant}`])}
      >
        {menu.map(
          ({
            key,
            label,
            handler,
            asyncHandler,
            icon,
            iconProps,
            loading,
            description,
            startAdornment,
            endAdornment,
            arrowNext,
            disabled,
            forwardRef: listItemForwardRef,
            textEllipsis = true,
            underLabelComponent,
            className: listItemClassName,
          }) => (
            <div
              ref={listItemForwardRef}
              onClick={
                disabled
                  ? undefined
                  : asyncHandler
                  ? async (e) => {
                      if (!handler) {
                        return;
                      }
                      setLoadingLabel(label);
                      await handler(e);
                      setLoadingLabel(null);
                    }
                  : handler
              }
              key={key || label + (description || '')}
              className={clsx(
                classes.menuItem,
                handler && 'pointer',
                disabled && classes.disabled,
                listItemClassName,
              )}
            >
              <div className={clsx('px-3', description ? 'py-2' : 'py-3')}>
                <div className={clsx('row aic gap-1-5', centered ? 'jcc' : 'jcsb')}>
                  <div className="row aic gap-1-5 flex-shrink-1 hidden">
                    {startAdornment}
                    {icon && <Icon size="md" name={icon} {...iconProps} />}
                    <div className="column flex-shrink-1 hidden">
                      {description && <span className={classes.description}>{description}</span>}
                      <span className={clsx(classes.label, textEllipsis && classes.ellipsis)}>
                        {label}
                      </span>
                      {underLabelComponent}
                    </div>
                  </div>
                  {loading || loadingLabel === label ? (
                    <Loader size="xs" />
                  ) : (
                    <div className="row aic gap-1-5">
                      {endAdornment}
                      {arrowNext && <Icon className={classes.arrow} name="chevronRightSafe" />}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    );
  },
);
