import { invoicingActions } from 'modules/invoicing/store/index';
import { Invoice, InvoiceSavedClient } from 'modules/invoicing/types';
import { INVOICES_LIST_PAGE_LIMIT } from 'modules/invoicing/views/Invoices';
import { generateInfinityListThunk } from 'store/generators/thunks';
import { generateRequestDataThunk } from 'store/generators/thunks';
import { generateRequestThunk } from 'store/generators/thunks';

import invoicingServices from '../services';

// cli-import

export const requestInvoices = generateInfinityListThunk<Invoice, undefined>({
  request: invoicingServices.getInvoices,
  updateState: invoicingActions.updateInvoices,
  reducerPath: 'invoicing.invoices',
  options: { showErrorToast: false },
});
export const requestSavedClients = generateRequestDataThunk<InvoiceSavedClient[]>({
  updateState: invoicingActions.updateSavedClients,
  request: invoicingServices.getSavedClients,
});
export const requestCreateInvoice = generateRequestThunk({
  request: invoicingServices.createInvoice,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestInvoices(undefined, { reset: true, pageLimit: INVOICES_LIST_PAGE_LIMIT }));
    },
  },
});
export const requestGetInvoiceById = generateRequestThunk({
  request: invoicingServices.getInvoiceById,
});
export const requestSaveClient = generateRequestThunk({
  request: invoicingServices.saveClient,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestSavedClients());
    },
  },
});
export const requestEditSavedClient = generateRequestThunk({
  request: invoicingServices.editSavedClient,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestSavedClients());
    },
  },
});
export const requestRemoveSavedClient = generateRequestThunk({
  request: invoicingServices.removeSavedClient,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestSavedClients());
    },
  },
});
export const requestRemoveInvoice = generateRequestThunk({
  request: invoicingServices.removeInvoice,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestInvoices(undefined, { reset: true, pageLimit: INVOICES_LIST_PAGE_LIMIT }));
    },
  },
});

export const requestRemoveMultipleInvoices = generateRequestThunk({
  request: invoicingServices.removeMultipleInvoices,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestInvoices(undefined, { reset: true, pageLimit: INVOICES_LIST_PAGE_LIMIT }));
    },
  },
});
export const requestDownloadInvoicePdf = generateRequestThunk({
  request: invoicingServices.downloadInvoicePdf,
});
// cli-thunk
