import { createSlice } from '@reduxjs/toolkit';

import { generateDefaultReducer, generateRequestDataReducer } from 'store/generators/reducers';
import { initialApiInfinityListState } from 'store/generators/utils';
import { initialApiRequestState } from 'store/generators/utils';
import { initialArrayApiRequestState } from 'store/generators/utils';

import { LoanCalculatorSavedValues, LoanNotification, LoanTemplate, Statistic } from '../types';
// cli-import
import { LoanItem, LoansState } from '../types';

const initialState: LoansState = {
  loansItemsActive: initialApiInfinityListState,
  loansItemsHistory: initialApiInfinityListState,
  statistic: initialApiRequestState,
  notifications: initialArrayApiRequestState,
  templates: initialArrayApiRequestState,
  loanCalculatorSavedValues: {
    loan: {
      currency: 'EUR' as const,
      amount: '',
    },
    collateral: {
      currency: 'BTC' as const,
      amount: '',
    },
    termMonth: 1,
    ltv: 50,
    excessCollateralRelease: false,
    autoManagementMC: false,
    templateId: null,
  },
  // cli-state
};

const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    updateLoansActiveItems: generateRequestDataReducer<LoansState, LoanItem[]>({
      reducerPath: 'loansItemsActive',
    }),
    updateLoansHistoryItems: generateRequestDataReducer<LoansState, LoanItem[]>({
      reducerPath: 'loansItemsHistory',
    }),
    updateStatistic: generateRequestDataReducer<LoansState, Statistic>({
      reducerPath: 'statistic',
    }),
    updateNotifications: generateRequestDataReducer<LoansState, LoanNotification[]>({
      reducerPath: 'notifications',
    }),
    updateTemplate: generateRequestDataReducer<LoansState, LoanTemplate[]>({
      reducerPath: 'templates',
    }),
    updateLoanCalculatorSavedValues: generateDefaultReducer<LoansState, LoanCalculatorSavedValues>({
      reducerPath: 'loanCalculatorSavedValues',
    }),
    // cli-reducer
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export default loansSlice.reducer;
export const loansActions = loansSlice.actions;
