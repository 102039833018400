import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { CheckBox } from 'components/ui';

interface CheckBoxFieldProps extends FieldRenderProps<boolean> {}

export const CheckBoxField: FC<CheckBoxFieldProps> = ({ input: { value, onChange }, ...props }) => {
  return <CheckBox checked={value} onChange={onChange} {...props} />;
};
