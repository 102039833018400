import clsx from 'clsx';

import { FC, ReactNode } from 'react';

import { DottedLine } from 'components/ui/DottedLine';

import classes from './DotSeparatedViews.module.scss';

interface DotSeparatedViewsProps {
  leftComponent: ReactNode;
  rightComponent: ReactNode;
  className?: string;
}

export const DotSeparatedViews: FC<DotSeparatedViewsProps> = ({
  className,
  rightComponent,
  leftComponent,
}) => {
  return (
    <div className={clsx(classes.root, className)}>
      {leftComponent}
      {leftComponent ? (
        <div className={classes.dotsWrapper}>
          <DottedLine className={classes.dots} />
        </div>
      ) : null}

      {rightComponent}
    </div>
  );
};
