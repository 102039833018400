import clsx from 'clsx';

import { FC } from 'react';

import useHistoryRateChart from 'modules/exchange/hooks/useHistoryRateChart';
import useRate from 'modules/exchange/hooks/useRate';

import { CurrencyIcon, RateChart } from 'components/ui';

import {
  fiatCurrencies,
  formatCurrencyWithSymbol,
  getCurrencyLabelByCode,
  stableCoinsCodes,
} from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './CurrencyChartCard.module.scss';

export interface CurrencyChartCardProps {
  from: CurrencyCode;
  to: CurrencyCode;
  variant?: 'white' | 'blurred';
}

const alwaysToCurrency = [...fiatCurrencies, ...stableCoinsCodes];

export const CurrencyChartCard: FC<CurrencyChartCardProps> = ({
  from: fromFromProps,
  to: toFromProps,
  variant = 'white',
}) => {
  const reverse = alwaysToCurrency.includes(fromFromProps);
  const from = reverse ? toFromProps : fromFromProps;
  const to = reverse ? fromFromProps : toFromProps;

  const rate = useRate(from, to, { autoFetch: true });
  const { history, raised, changePercentLabel } = useHistoryRateChart(from, to);

  return (
    <div className={clsx(classes.root, classes['variant-' + variant])}>
      <div className="row gap-2">
        <CurrencyIcon size={32} code={from} />
        <div className="flex-1">
          <RateChart rates={history || []} raised={raised} />
        </div>
      </div>
      <div className="row jcsb mt-1">
        <span className={classes.boldLabel}>{getCurrencyLabelByCode(from)}</span>
        <span className={clsx(classes.boldLabel, 'tar')}>{formatCurrencyWithSymbol(rate, to)}</span>
      </div>
      <div className="row jcsb">
        <span className={classes.label}>{from}</span>
        <span className={clsx(classes.percentLabel, raised && classes.raised, 'tar')}>
          {changePercentLabel}
        </span>
      </div>
    </div>
  );
};
