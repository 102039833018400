import { Field } from 'react-final-form';

import { SimpleCurrencyPickerInputField } from 'components/form';

import { cryptoCurrencies } from 'utils/currency';

export const ChooseCryptoAccount = () => {
  return (
    <Field
      name="payment.currencyCode"
      component={SimpleCurrencyPickerInputField}
      currenciesList={cryptoCurrencies}
      variant="white"
    />
  );
};
