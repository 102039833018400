import { FC, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';
import { selectsInvoicesReducer } from 'modules/invoicing/store/selectors';
import { requestInvoices, requestRemoveMultipleInvoices } from 'modules/invoicing/store/thunks';
import { Invoice } from 'modules/invoicing/types';
import { InvoicesEmpty } from 'modules/invoicing/views/Invoices/components/InvoicesEmpty';
import InvoicesList from 'modules/invoicing/views/Invoices/components/InvoicesList';
import { useDispatch } from 'store';

import { EntityView } from 'components/common';
import { Button, Icon } from 'components/ui';

import useAsyncPortalTarget from 'hooks/useAsyncPortalTarget';
import useFlag from 'hooks/useFlag';
import useInfinityScrollSimplified from 'hooks/useInfinityScrollSimplified';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import { canRequest } from 'utils/common';

export const INVOICES_LIST_PAGE_LIMIT = 10;
interface InvoicesProps {
  headerSelector: string;
}
const Invoices: FC<InvoicesProps> = ({ headerSelector }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const drawer = useDrawer();

  const editMode = useFlag(false);

  const invoicesReducer = useSelector(selectsInvoicesReducer);

  const firstFetch = useCallback(async () => {
    if (canRequest(invoicesReducer.meta)) {
      await dispatch(
        requestInvoices(undefined, { pageLimit: INVOICES_LIST_PAGE_LIMIT, reset: true }),
      );
    }
  }, [dispatch, invoicesReducer]);

  const loadMore = useCallback(async () => {
    await dispatch(requestInvoices(undefined, { pageLimit: INVOICES_LIST_PAGE_LIMIT }));
  }, [dispatch]);

  const { onInfinityScroll } = useInfinityScrollSimplified({
    loadMore,
    loading: invoicesReducer.meta.loading,
    hasMore: invoicesReducer.meta.hasMore,
  });

  useEffect(() => {
    firstFetch();
    // eslint-disable-next-line
  }, []);

  const openActions = useCallback(() => {
    drawer.open(
      commonDrawerTemplates.menuList({
        menu: [
          {
            icon: 'case2',
            label: translate('INVOICES_CLIENT_DETAILS'),
            handler: () => {
              drawer.replace(invoicingDrawerTemplates.savedClients({ variant: 'manage' }));
            },
          },
          {
            icon: 'list',
            disabled: !invoicesReducer.data.length,
            label: translate(editMode.state ? 'INVOICES_MANAGE_MODE_OFF' : 'INVOICES_MANAGE'),
            handler: () => {
              editMode.toggle();
              if (editMode.state) {
                setPickedForDeleteInvoices([]);
              }
              drawer.pop();
            },
          },
        ],
      }),
    );
  }, [drawer, invoicesReducer.data.length, editMode, translate]);

  const portalTarget = useAsyncPortalTarget(headerSelector);

  const [pickedForDeleteInvoices, setPickedForDeleteInvoices] = useState<Invoice['id'][]>([]);

  const deleting = useFlag(false);

  const deletePickerInvoices = useCallback(async () => {
    deleting.on();
    const { success } = await dispatch(requestRemoveMultipleInvoices(pickedForDeleteInvoices));
    if (success) {
      successToast(translate('INVOICE_DELETED', { count: pickedForDeleteInvoices.length }));
      editMode.off();
      setPickedForDeleteInvoices([]);
    }
    deleting.off();
  }, [translate, editMode, deleting, dispatch, pickedForDeleteInvoices]);

  const togglePickedForDeleteInvoice = useCallback((id: Invoice['id'], addToList: boolean) => {
    setPickedForDeleteInvoices((prev) =>
      addToList ? [...prev, id] : prev.filter((i) => i !== id),
    );
  }, []);

  return (
    <div className="column gap-2 flex-1 jcsb relative">
      {portalTarget &&
        createPortal(
          <Icon onClick={openActions} className="pointer" name="dotsSquared" color="black40" />,
          portalTarget,
        )}
      <EntityView
        reducer={invoicesReducer}
        request={firstFetch}
        component={
          <InvoicesList
            onScroll={onInfinityScroll}
            list={invoicesReducer.data}
            loading={invoicesReducer.meta.loading}
            editMode={editMode.state}
            togglePickedForDeleteInvoice={togglePickedForDeleteInvoice}
            pickedForDeleteInvoices={pickedForDeleteInvoices}
          />
        }
        noDataComponent={<InvoicesEmpty />}
      />
      {editMode.state ? (
        <Button
          disabled={!pickedForDeleteInvoices.length}
          fullWidth
          variant="greyishGreen"
          onClick={deletePickerInvoices}
          loading={deleting.state}
        >
          {translate('INVOICE_DELETE_MULTIPLE', { count: pickedForDeleteInvoices.length })}
        </Button>
      ) : (
        <Button
          fullWidth
          variant="greyishGreen"
          onClick={() => {
            drawer.open(invoicingDrawerTemplates.createInvoice());
          }}
        >
          {translate('INVOICES_CREATE')}
        </Button>
      )}
    </div>
  );
};

export default Invoices;
