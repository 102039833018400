import clsx from 'clsx';

import { ChangeEventHandler, FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import {
  selectAllowedCurrencies,
  selectAllowedCurrenciesSorted,
} from 'modules/app/store/selectors';
import rentingDrawerTemplates from 'modules/cryptoRenting/constants/drawerTemplates';
import useRentingPromoWallets from 'modules/cryptoRenting/hooks/useRentingPromoWallets';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';

import { CurrencyIcon, Icon, Mark, NoResultLabel, TextInput } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode, isFiat } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './PickCurrency.module.scss';

export interface PickCurrencyProps {
  isDeposit?: boolean;
  isDigitalAccount?: boolean;
}

const PickCurrency: FC<PickCurrencyProps> = ({ isDeposit = true }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const isCrypto = useFlag(false);
  const showSearchInput = useFlag(false);

  const [filter, setFilter] = useState('');

  const allowedCurrenciesInitial: CurrencyCode[] = useSelector(selectAllowedCurrenciesSorted);

  const allowedCurrencies = useMemo(
    () =>
      allowedCurrenciesInitial
        .filter((c) => c !== 'NBT')
        .filter((c) => (isCrypto.state ? !isFiat(c) : isFiat(c))),

    [isCrypto.state, allowedCurrenciesInitial],
  );
  const allowedCurrenciesWithLabel: { code: CurrencyCode; label: string }[] = useMemo(
    () => allowedCurrencies.map((c) => ({ code: c, label: getCurrencyLabelByCode(c) })),
    [allowedCurrencies],
  );

  const buttons = useMemo(
    () => [
      {
        id: '1',
        children: translate('PAYMENT_CASH'),
        onClick: isCrypto.off,
        active: !isCrypto.state,
      },
      {
        id: '2',
        children: translate('PAYMENT_CRYPTO'),
        onClick: isCrypto.on,
        active: isCrypto.state,
      },
    ],
    [translate, isCrypto],
  );

  const { potentialWalletsForRentingMap } = useRentingPromoWallets();

  const filteredCurrencies = useMemo(() => {
    if (!filter) {
      return allowedCurrenciesWithLabel;
    }
    const filterUppercase = filter.toUpperCase();

    return allowedCurrenciesWithLabel.filter(
      (w) => w.code.includes(filterUppercase) || w.label.toUpperCase().includes(filterUppercase),
    );
  }, [allowedCurrenciesWithLabel, filter]);

  const handlePickCurrency = useCallback(
    async (e: SyntheticEvent<HTMLDivElement>) => {
      const code = e.currentTarget.dataset.code as CurrencyCode;
      if (code) {
        drawer.open(
          paymentDrawerTemplates.paymentProcess({
            isDeposit,
            currencyCode: code,
          }),
        );
      }
    },
    [drawer, isDeposit],
  );

  const handleFilterInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setFilter(e.target.value);
  }, []);

  return (
    <div className="mt-1 flex-1 column gap-3 ">
      <div className="row gap-3 jcsb">
        <div className="row gap-0-5">
          {buttons.map((b) => (
            <div
              key={b.id}
              onClick={b.onClick}
              className={clsx(classes.pickerButton, b.active && classes.active)}
            >
              <span>{b.children}</span>
            </div>
          ))}
        </div>
        <Icon
          onClick={showSearchInput.toggle}
          className={classes.showSearchInputButton}
          name={showSearchInput.state ? 'cross' : 'search'}
          size={16}
        />
      </div>
      <div className="column gap-3 flex-1">
        {showSearchInput.state && (
          <TextInput
            value={filter}
            onChange={handleFilterInputChange}
            startAdornment={
              <Icon className={classes.textInputStartAdornment} name="search" size={16} />
            }
            placeholder={translate('SEARCH')}
          />
        )}
        <div className={classes.currenciesList}>
          {filteredCurrencies.map((currency) => {
            const potentialWalletForRenting = potentialWalletsForRentingMap[currency.code];

            return (
              <div
                onClick={handlePickCurrency}
                data-code={currency.code}
                key={currency.code}
                className={classes.item}
              >
                <div className="row aic gap-2">
                  <CurrencyIcon size={40} code={currency.code} />
                  <div className="column gap-0-5">
                    <div className="row gap-2 aic">
                      <span className={classes.label}>{currency.label}</span>
                      {isDeposit && potentialWalletForRenting ? (
                        <Mark
                          onClick={(e) => {
                            e.stopPropagation();
                            drawer.open(
                              rentingDrawerTemplates.rentingSetup({
                                templateName: potentialWalletForRenting.templateName,
                                rentCurrency: potentialWalletForRenting.currencyCode,
                              }),
                            );
                          }}
                          rounded
                          className={clsx(
                            classes.rentingPromoMark,
                            classes[`variant-${potentialWalletForRenting.templateName}`],
                          )}
                        >
                          {translate('RENTING_EARN_PERCENT', {
                            percent: potentialWalletForRenting.percent,
                          })}
                        </Mark>
                      ) : null}
                    </div>
                    <span className={classes.sublabel}>{currency.code}</span>
                  </div>
                </div>
                {/*<div className="column gap-0-5">*/}
                {/*  <span className={clsx(classes.label, 'tar')}>*/}
                {/*    {formatCurrencyWithSymbol(w.amount, w.currencyCode)}*/}
                {/*  </span>*/}
                {/*  <span className={clsx(classes.sublabel, 'tar')}>*/}
                {/*    {formatCurrencyWithSymbol(w.amountInDefaultCurrency, w.defaultCurrencyCode)}*/}
                {/*  </span>*/}
                {/*</div>*/}
              </div>
            );
          })}
          {!filteredCurrencies.length && <NoResultLabel findString={filter} />}
        </div>
      </div>
    </div>
  );
};

export default PickCurrency;
