import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectCryptoWallets } from 'modules/accounts/store/selectors';
import { TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import loanDrawerTemplates from 'modules/loans/constants/drawerTemplates';
import { requestAddCollateral } from 'modules/loans/store/thunks';
import { LoanItem } from 'modules/loans/types';
import { LiquidationRiskCard } from 'modules/loans/views/components/LiquidationRiskCard';
import LoanTypeAndId from 'modules/loans/views/components/LoanTypeAndId';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { useDispatch } from 'store';

import { CurrencyAmountField, SubmitButton } from 'components/form';
import { AmountLabel, Button, DottedLine, Icon } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import {
  MINIMUM_AMOUNT_CRYPTO,
  findWallet,
  formatCurrency,
  formatCurrencyWithSymbol,
  getCurrencyLabelByCode,
} from 'utils/currency';

import classes from './AddCollateral.module.scss';

export interface AddCollateralProps {
  loan: LoanItem;
}

interface FormValues {
  collateral: CurrencyAmount;
}

interface AddCollateralFormProps extends FormRenderProps<FormValues> {
  wallets: TradingWallet[];
  loan: LoanItem;
}

const AddCollateralForm: FC<AddCollateralFormProps> = ({ handleSubmit, wallets, loan, errors }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const insufficientFunds = useMemo(
    () => errors?.collateral?.amount === getTranslation('VALIDATION_INSUFFICIENT_FUNDS'),
    [errors],
  );

  const handleDeposit = useCallback(() => {
    drawer.open(
      paymentDrawerTemplates.paymentProcess({
        isDeposit: true,
        currencyCode: loan.collateralCurrency,
      }),
    );
  }, [drawer, loan.collateralCurrency]);
  return (
    <form onSubmit={handleSubmit} className="column gap-3 flex-1 jcsb">
      <Field
        name="collateral"
        label={translate('PLACEHOLDER_ENTER_AMOUNT')}
        component={CurrencyAmountField}
        showBalance
        maxAmountButtonLabel={translate('LOANS_REPAY_FULL_AMOUNT')}
        currenciesList={wallets}
        pickerDisabled
        showErrorIfExist
      />
      <div className="column gap-2">
        {insufficientFunds ? (
          <Button
            onClick={handleDeposit}
            fullWidth
            variant={loan.marginCallStatus === 'none' ? 'greyishGreen' : 'red'}
          >
            {translate('DEPOSIT_CURRENCY', {
              currencyLabel: getCurrencyLabelByCode(loan.collateralCurrency),
            })}
          </Button>
        ) : (
          <SubmitButton
            fullWidth
            variant={loan.marginCallStatus === 'none' ? 'greyishGreen' : 'red'}
          >
            {translate('LOANS_ADD_COLLATERAL')}
          </SubmitButton>
        )}
        <p className={classes.note}>{translate('LOANS_ADD_COLLATERAL_NOTE')}</p>
      </div>
    </form>
  );
};

const AddCollateral: FC<AddCollateralProps> = ({ loan }) => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const wallets = useSelector(selectCryptoWallets);

  const wallet = useMemo(
    () => findWallet(wallets, loan.collateralCurrency),
    [wallets, loan.collateralCurrency],
  );

  const [isManualRepay] = useMemo(() => [loan.marginCallStatus === 'none'], [loan]);

  const initialValues = useMemo<FormValues>(
    () => ({
      collateral: {
        currency: loan.collateralCurrency,
        amount: loan.collateralMinDepositAmount
          ? formatCurrency(loan.collateralMinDepositAmount, loan.collateralCurrency, {
              round: 'up',
              withZeros: true,
            })
          : '',
      },
    }),
    [loan],
  );

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (isManualRepay) {
        drawer.open(
          loanDrawerTemplates.loanAddCollateralConfirm({ loan, amount: +values.collateral.amount }),
        );
      } else {
        const { success, data } = await dispatch(
          requestAddCollateral({ amount: +values.collateral.amount, loanId: loan.id }),
        );
        if (success) {
          drawer.replaceAll(loanDrawerTemplates.loanLiquidationRiskReduced({ loan: data }));
        }
      }
    },
    [loan, drawer, isManualRepay, dispatch],
  );

  const schema = useMemo(() => {
    const minAmount = MINIMUM_AMOUNT_CRYPTO;

    return yup.object().shape({
      collateral: yup.object().shape({
        amount: yup
          .number()
          .transform((value) => (isNaN(value) ? 0 : value))
          .requiredDefault()
          .min(
            minAmount,
            getTranslation('VALIDATION_MIN_AMOUNT', {
              minLabel: formatCurrencyWithSymbol(minAmount, loan.collateralCurrency),
            }),
          )
          .max(
            +formatCurrency(wallet?.amount || 0, false, { round: 'down' }),
            getTranslation('VALIDATION_INSUFFICIENT_FUNDS'),
          ),
      }),
    });
  }, [loan, wallet]);

  const validate = useMemo(() => {
    return makeValidate(schema);
  }, [schema]);

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <AddCollateralForm {...formProps} wallets={wallets} loan={loan} />
    ),
    [wallets, loan],
  );

  return (
    <div className="column gap-2 mt-1 flex-1">
      <div className="outlinedCard column gap-3">
        <LoanTypeAndId type={loan.templateType} id={loan.id} />
        {!isManualRepay && <DottedLine />}
        <div className="column gap-1">
          {!isManualRepay && (
            <span className="font-s grey-400">{translate('LOANS_MIN_DEPOSIT_FOR_SAVE_LOAN')}</span>
          )}
          <AmountLabel
            size="lg"
            amount={isManualRepay ? loan.collateralAmount : loan.collateralMinDepositAmount!}
            currencyCode={loan.collateralCurrency}
            showCurrencyCode
          />
        </div>
      </div>

      <div className="outlinedCard">
        {isManualRepay ? (
          <LiquidationRiskCard
            riskPercent={loan.liquidationRiskPercent}
            marginCallStatus={loan.marginCallStatus}
          />
        ) : (
          <div className="column gap-3">
            <span
              className={classes.collateralHelpCalcLabel}
              dangerouslySetInnerHTML={{
                __html: translate('LOANS_ADD_COLLATERAL_HELP_CALCULATION', {
                  percent: 25,
                  amountLabel: formatCurrencyWithSymbol(
                    loan.collateralMinDepositAmount!,
                    loan.collateralCurrency,
                  ),
                }),
              }}
            />
            <DottedLine />
            <div className="row aic jcsb">
              <LiquidationRiskCard
                className="flex-1"
                variant="vertical"
                riskPercent={loan.liquidationRiskPercent}
                marginCallStatus={loan.marginCallStatus}
              />
              <div className="column gap-1 flex-1">
                <span className="label font-s green tac">
                  +
                  {formatCurrencyWithSymbol(
                    loan.collateralMinDepositAmount!,
                    loan.collateralCurrency,
                  )}
                </span>
                <Icon name="arrowRightSquaredSafe" color="green" />
              </div>
              <LiquidationRiskCard
                className="flex-1"
                variant="vertical"
                riskPercent={25}
                marginCallStatus="none"
              />
            </div>
          </div>
        )}
      </div>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={renderForm}
        validate={validate}
      />
    </div>
  );
};

export default AddCollateral;
