import clsx from 'clsx';
import get from 'lodash/get';

import { FC, ReactNode, useCallback, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import useWallets from 'modules/accounts/hooks/useWallets';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { selectUserDASubscriptionType } from 'modules/digitalAccount/store/selectors';
import useRateToDefaultCurrency from 'modules/exchange/hooks/useRateToDefaultCurrency';
import { PaymentOperationId } from 'modules/payment/types';
import {
  getBlockedOperationIdByPaymentOperationId,
  isDepositByOperationId,
  isDigitalAccountByOperationId,
} from 'modules/payment/utils';
import useCalculating from 'modules/payment/views/components/AmountForm/hooks/useCalculating';
import useLimits from 'modules/payment/views/components/AmountForm/hooks/useLimits';
import {
  CommissionLabel,
  CommissionLabelProps,
} from 'modules/payment/views/components/CommissionLabel';
import { selectUserProfile } from 'modules/user/store/selectors';

import { AgreementText } from 'components/common';
import { CurrencyAmountField, SubmitButton } from 'components/form';
import { Button } from 'components/ui';
import { CurrencyAmount, CurrencyAmountInputProps } from 'components/ui/CurrencyAmountInput';

import { useTranslation } from 'libs/i18n';
import { OperationCommissionRequest } from 'libs/swagger/nebeusApiTypes';

import { formatCurrencyWithSymbol, getCurrencyLabelByCode, isFiat } from 'utils/currency';

import classes from './AmountForm.module.scss';

export interface PaymentAmountFormValues {
  currencyAmount: CurrencyAmount;
  currencyAmountWithCommission?: CurrencyAmount;
  commissionAmount: number | null;
}
interface AmountFormProps {
  className?: string;
  operationId: PaymentOperationId;
  currencyAmountFieldProps?: Partial<CurrencyAmountInputProps>;
  needConfirmation?: boolean;
  additionalCalculateRequestPayload?: Partial<OperationCommissionRequest>;
  recalculateDeps?: string[];
  commissionLabelProps?: Partial<CommissionLabelProps>;
  beforeButtonComponent?: ReactNode;
}

export const AmountForm: FC<AmountFormProps> = ({
  className,
  operationId,
  currencyAmountFieldProps = {},
  needConfirmation = true,
  additionalCalculateRequestPayload,
  recalculateDeps,
  commissionLabelProps,
  beforeButtonComponent,
}) => {
  const translate = useTranslation();
  const sidebar = useSideBar();
  const user = useSelector(selectUserProfile);

  const digitalSubscriptionType = useSelector(selectUserDASubscriptionType);

  const form = useForm<PaymentAmountFormValues>();
  const {
    values: { currencyAmount, currencyAmountWithCommission, commissionAmount },
    errors,
  } = useFormState<PaymentAmountFormValues>();

  const isDeposit = isDepositByOperationId(operationId);
  const isDigitalAccount = isDigitalAccountByOperationId(operationId);

  const { wallets } = useWallets({ isDigitalAccount });

  const { operationHasCommission, loadingField } = useCalculating({
    operationId,
    currencyAmount,
    currencyAmountWithCommission,
    form,
    additionalCalculateRequestPayload,
    recalculateDeps,
  });

  const { currencyAmountPlaceholder } = useLimits({
    currencyAmount,
    operationId,
  });

  const showIncreaseLimits = useMemo(
    () =>
      (operationId === PaymentOperationId.sendFromTradingToMoney ||
        operationId === PaymentOperationId.sendFromMoneyToTrading) &&
      get(errors, 'currencyAmount.amount') === translate('VALIDATION_WITHDRAW_LIMITS_REACHED') &&
      digitalSubscriptionType !== 'whale',

    [operationId, digitalSubscriptionType, errors, translate],
  );

  const handleClickIncreaseLimits = useCallback(() => {
    sidebar.open(...sidebarTemplates.digitalAccountChooseSubscription());
  }, [sidebar]);

  const { rate: rateToDefaultCurrency, defaultCurrency } = useRateToDefaultCurrency(
    currencyAmount.currency,
    {
      autoFetch: true,
    },
  );

  const operationBlockedForUser = useMemo(() => {
    const blockedOperationId = getBlockedOperationIdByPaymentOperationId(operationId);

    return blockedOperationId && user?.blockedFeatures.includes(blockedOperationId);
  }, [user, operationId]);

  const warningMessage = useMemo(() => {
    let result = '';

    switch (operationId) {
      case PaymentOperationId.depositFromBankCardToTradingWallet: {
        result += translate('PAYMENT_DEPOSIT_BANK_CARD_IMPORTANT_INFO');
        break;
      }
      case PaymentOperationId.sendFromTradingWalletToBankCard: {
        result += translate('PAYMENT_SEND_BANK_CARD_IMPORTANT_INFO');
        break;
      }
    }

    if (!isDeposit) {
      const withdrawalCurrencyCode =
        currencyAmountWithCommission?.currency || currencyAmount.currency;

      result +=
        ' ' +
        translate(
          isFiat(withdrawalCurrencyCode)
            ? 'PAYMENT_WITHDRAWAL_LARGE_AMOUNT_FIAT_WARNING'
            : 'PAYMENT_WITHDRAWAL_LARGE_AMOUNT_CRYPTO_WARNING',
        );
    }
    return result;
  }, [currencyAmount, currencyAmountWithCommission, translate, isDeposit, operationId]);

  return (
    <div translate="no" className={clsx(classes.root, className)}>
      <div className="column gap-2">
        <Field
          name="currencyAmount"
          // @ts-ignore
          component={CurrencyAmountField}
          pickerDisabled
          label={translate('PAYMENT_AMOUNT_FORM_AMOUNT')}
          placeholder={currencyAmountPlaceholder}
          className={classes.input}
          loading={loadingField === 'from'}
          showMaxAmountButton={!isDeposit}
          currenciesList={wallets}
          showBalance
          underComponent={
            <span className={classes.underFieldLabel}>
              {getCurrencyLabelByCode(defaultCurrency)}:{' '}
              {formatCurrencyWithSymbol(
                +currencyAmount.amount * rateToDefaultCurrency,
                defaultCurrency,
              )}
            </span>
          }
          {...currencyAmountFieldProps}
        />
        {operationHasCommission && (
          <Field
            name="currencyAmountWithCommission"
            component={CurrencyAmountField}
            pickerDisabled
            label={translate(isDeposit ? 'PAYMENT_YOU_WILL_GET' : 'PAYMENT_RECIPIENT_WILL_GET')}
            className={classes.input}
            loading={loadingField === 'to'}
            underComponent={
              <CommissionLabel
                className={classes.commissionLabel}
                operationId={operationId}
                currencyCode={currencyAmount.currency}
                commissionAmount={commissionAmount}
                {...commissionLabelProps}
              />
            }
          />
        )}
      </div>

      {beforeButtonComponent}

      <div className="column gap-1-5 mt-1">
        {showIncreaseLimits ? (
          <Button onClick={handleClickIncreaseLimits} fullWidth showShadow>
            {translate('DA_SUBSCRIPTION_INCREASE_LIMITS')}
          </Button>
        ) : operationBlockedForUser ? null : (
          <SubmitButton loading={!!loadingField} fullWidth showShadow>
            {translate(!isDeposit && needConfirmation ? 'GET_CONFIRMATION_CODE' : 'CONFIRM')}
          </SubmitButton>
        )}
        <AgreementText />
        {warningMessage && <p className="grey-300 font-xs">{warningMessage}</p>}
      </div>
    </div>
  );
};
