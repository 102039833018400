import mixpanel from 'mixpanel-browser';

import googleAnalytic from 'react-ga';

import {
  isReactNative,
  isReactNativeAndroid,
  isReactNativeIOS,
  isReactNativeSupportBioVerification,
} from 'modules/reactNative/utils';
import { Profile, UserExperiments } from 'modules/user/types';

import appConfig from 'constants/appConfig';

import { AnalyticEvents } from 'libs/analytic/events';

import { getAnalyticCookies } from 'utils/cookies';

interface User {
  id: string;
}

const analyticDisabled = appConfig.env === 'development';
// const analyticDisabled = false;

class AnalyticSingleton {
  private user: User | null = null;
  private userExperiments: UserExperiments | null = null;

  public inited: boolean = false;
  private lastMarketingCampaign: object | null = null;

  constructor() {
    this.user = null;
    this.userExperiments = null;
    this.inited = false;
    this.lastMarketingCampaign = null;
  }
  init() {
    try {
      if (analyticDisabled) {
        return;
      }
      if (appConfig.googleAnalyticKey) {
        googleAnalytic.initialize(appConfig.googleAnalyticKey, { debug: appConfig.isDev });
      }
      if (appConfig.mixpanelToken) {
        mixpanel.init(appConfig.mixpanelToken, { debug: appConfig.isDev, ignore_dnt: true });

        this.lastMarketingCampaign = getAnalyticCookies();
      }

      this.inited = true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Analytic error, init: ', e);
    }
  }
  identifyUser(userProfile: Profile, { userExperiments }: { userExperiments: UserExperiments }) {
    try {
      if (!this.inited) {
        return;
      }
      let signUpCampaign = null;

      if (userProfile.signUpSource) {
        try {
          signUpCampaign = JSON.parse(userProfile.signUpSource);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('Error, cant parse user signUpSource: ', e);
        }
      }

      const user = { id: userProfile.id, signUpCampaign };
      this.user = user;
      this.userExperiments = userExperiments;

      if (appConfig.googleAnalyticKey) {
        googleAnalytic.set(user);
      }
      mixpanel.identify(user.id);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Analytic error, identifyUser: ', e);
    }
  }
  resetUser() {
    if (!this.inited) {
      return;
    }
    this.user = null;
    this.userExperiments = null;
  }
  sendEvent(eventName: AnalyticEvents, properties?: object, options?: { onlyMixpanel?: boolean }) {
    try {
      if (!this.inited) {
        return;
      }
      // @ts-ignore
      let user = properties?.user || {};
      const identifierUser = this.user ? { ...this.user, experiments: this.userExperiments } : null;
      if (identifierUser) {
        user = {
          ...user,
          ...identifierUser,
          experiments: { ...user.experiments, ...identifierUser.experiments },
        };
      }
      const fullProperties = {
        ...properties,
        user,
        device: {
          isReactNative,
          isReactNativeSupportBioVerification,
          isReactNativeAndroid,
          isReactNativeIOS,
        },
        environment: appConfig.customEnvironment,
        frontendVersion: appConfig.version,
        lastMarketingCampaign: this.lastMarketingCampaign,
      };

      mixpanel.track(eventName, fullProperties);
      if (options?.onlyMixpanel) {
        return;
      }
      googleAnalytic.event({
        category: 'App',
        action: eventName,
        label: JSON.stringify(fullProperties),
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Analytic error, sendEvent: ', e);
    }
  }

  pageChange(pathname: string) {
    try {
      if (!this.inited) {
        return;
      }
      this.sendEvent(AnalyticEvents.pageChanged, { pathname });
      googleAnalytic.pageview(pathname);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Analytic error, pageChange: ', e);
    }
  }
}

const analytic = new AnalyticSingleton();
export default analytic;
