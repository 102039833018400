import { FC, useCallback, useEffect } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import { requestDeliveryPlans } from 'modules/cryptoBankCard/store/thunks';
import { ShippingDetailsFormValues } from 'modules/cryptoBankCard/views/OrderBankCardShippingDetails';
import { useDispatch } from 'store';

import { Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { sleep } from 'utils/common';

import classes from './OrderBankCardCalculatingShippingCosts.module.scss';

export interface OrderBankCardCalculatingShippingCostsProps {
  shippingDetails: ShippingDetailsFormValues;
}

const MIN_VIEW_DISPLAYING_MS = 2000;

const OrderBankCardCalculatingShippingCosts: FC<OrderBankCardCalculatingShippingCostsProps> = ({
  shippingDetails,
}) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const drawer = useDrawer();

  const fetchDeliveryPlans = useCallback(async () => {
    const startTime = Date.now();
    const { success, data } = await dispatch(
      requestDeliveryPlans({ countryIso2Code: shippingDetails.shippingAddress.country!.code }),
    );

    const endTime = Date.now();
    const timeDistance = endTime - startTime;
    const additionalDisplayViewTime = MIN_VIEW_DISPLAYING_MS - timeDistance;

    if (additionalDisplayViewTime > 0) {
      await sleep(additionalDisplayViewTime);
    }

    if (success && data?.length) {
      if (data.length > 1) {
        drawer.replace(
          cryptoBankCardDrawerTemplates.orderBankCardDeliveryMethod({
            shippingDetails,
            plans: data,
          }),
        );
      } else {
        drawer.replace(
          cryptoBankCardDrawerTemplates.orderBankCardConfirmation({
            isVirtual: false,
            shippingValues: shippingDetails,
            deliveryPlan: data[0],
          }),
        );
      }
    } else {
      drawer.pop();
    }
  }, [shippingDetails, dispatch, drawer]);

  useEffect(() => {
    fetchDeliveryPlans();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex-1 jcc aic">
      <div className="column aic gap-3">
        <Image className={classes.img} name="nebeusRocket" path="common" />
        <h3 className="tac">{translate('CRYPTO_BANK_CARD_SHIPPING_CALCULATING_COSTS')}</h3>
      </div>
    </div>
  );
};

export default OrderBankCardCalculatingShippingCosts;
