import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectAllowedCountriesReducer } from 'modules/app/store/selectors';
import { requestAllowedCountries } from 'modules/app/store/thunks';
import { AllowedCountry } from 'modules/app/types';
import { selectUserProfile } from 'modules/user/store/selectors';

import useStoreEntity from 'hooks/useStoreEntity';

import { findByProperty } from 'utils/arrayUtils';

const useUserCountry = () => {
  const userProfile = useSelector(selectUserProfile);

  const {
    entityReducer: { data: allowedCountries },
  } = useStoreEntity(selectAllowedCountriesReducer, requestAllowedCountries);

  return useMemo<AllowedCountry | null>(
    () => findByProperty(allowedCountries, 'code', userProfile?.countryCode) || null,
    [allowedCountries, userProfile],
  );
};

export default useUserCountry;
