import { useCallback, useEffect } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import { requestGetChallenges } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

const useUserChallenges = () => {
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const checkChallenges = useCallback(async () => {
    const { data: challenges } = await dispatch(requestGetChallenges());

    if (!challenges || !challenges.length) {
      return;
    }

    const questionnaireChallenge = challenges?.find((c) => c.type === 'SUBMIT_QUESTIONNAIRE');
    if (questionnaireChallenge) {
      drawer.open(
        userDrawerTemplates.questionnaire({ id: questionnaireChallenge.relatedEntityId }),
      );
    }
  }, [drawer, dispatch]);

  useEffect(() => {
    checkChallenges();

    // eslint-disable-next-line
  }, []);
};

export default useUserChallenges;
