import { useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import { selectExtendedUserProfileReducer } from 'modules/user/store/selectors';
import { requestUserExtendedProfile } from 'modules/user/store/thunks';

import useStoreEntity from 'hooks/useStoreEntity';

const useOrderCryptoVirtualBankCard = () => {
  const drawer = useDrawer();

  const {
    entityReducer: { data: userExtendedProfile, meta: userExtendedProfileMeta },
  } = useStoreEntity(selectExtendedUserProfileReducer, requestUserExtendedProfile, {
    staleAllowed: true,
  });

  const startProcess = useCallback(() => {
    if (userExtendedProfile && userExtendedProfile.dateOfBirth && userExtendedProfile.phoneNumber) {
      drawer.open(
        cryptoBankCardDrawerTemplates.orderBankCardConfirmation({
          isVirtual: true,
          userData: {
            phoneNumber: userExtendedProfile.phoneNumber,
            dateOfBirth: userExtendedProfile.dateOfBirth,
          },
        }),
      );
    } else {
      drawer.open(cryptoBankCardDrawerTemplates.orderCardShippingDetails({ isVirtual: true }));
    }
  }, [drawer, userExtendedProfile]);

  return { startProcess, loading: userExtendedProfileMeta.loading };
};

export default useOrderCryptoVirtualBankCard;
