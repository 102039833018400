import clsx from 'clsx';

import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { TradingWallet } from 'modules/accounts/types';
import useFetchRate from 'modules/exchange/hooks/useFetchRate';
import useRateReducer from 'modules/exchange/hooks/useRateReducer';
import { LoanTemplate } from 'modules/loans/types';
import { GetLoanFormValues } from 'modules/loans/views/LoanCalculator';

import { Smooth } from 'components/common';
import {
  CurrencyAmountField,
  SimpleCurrencyAmountField,
  SliderField,
  SwitchField,
} from 'components/form';
import { CurrencyAmountInput, FixedField, Image, ImportantCard, InfoIcon } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import useFirstRender from 'hooks/useFirstRender';

import { TranslationKey, useTranslation } from 'libs/i18n';

import { formatCurrency } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './LoanConfigurator.module.scss';

interface LoanConfiguratorProps {
  cryptoWallets: TradingWallet[];
  template: LoanTemplate;
  variant: 'short' | 'full';
}

const options: {
  fieldName: string;
  labelKey: TranslationKey;
  descriptionKey: TranslationKey;
  isRequiredForLowLtv?: boolean;
}[] = [
  {
    fieldName: 'excessCollateralRelease',
    labelKey: 'LOANS_EXCESS_RELEASE',
    descriptionKey: 'LOANS_EXCESS_RELEASE_DESC',
    isRequiredForLowLtv: true,
  },
  {
    fieldName: 'autoManagementMC',
    labelKey: 'LOANS_AUTO_MC',
    descriptionKey: 'LOANS_AUTO_MC_DESC',
    isRequiredForLowLtv: true,
  },
];

const checkActiveFields = ['loan', 'collateral'];

const calculateLoan = (collateralAmount: number, ltv: number, rate: number) =>
  (collateralAmount * ((ltv || 1) / 100)) / rate;
const calculateCollateral = (loanAmount: number, ltv: number, rate: number) =>
  (loanAmount * rate) / ((ltv || 1) / 100);

export const LoanConfigurator: FC<LoanConfiguratorProps> = ({
  cryptoWallets,
  template,
  variant,
}) => {
  const translate = useTranslation();

  const firstRender = useFirstRender();

  const { change, getFieldState } = useForm<GetLoanFormValues>();
  const { values, active: activeField } = useFormState<GetLoanFormValues>();

  const lastActiveField = useRef<'loan' | 'collateral' | null>(null);
  const rateReducer = useRateReducer(values.loan.currency, values.collateral.currency);

  const rate = useMemo(() => rateReducer?.data?.rate || 0, [rateReducer?.data?.rate]);

  const loanRule = template.loanRules[values.loan.currency];

  const mirrorInputRef = useRef<HTMLInputElement | null>(null);
  const initialMirrorCollateralAmount = +values.collateral.amount * 2;
  const [mirrorCollateralAmount, setMirrorCollateralAmount] = useState(
    initialMirrorCollateralAmount === 0 ? '' : initialMirrorCollateralAmount.toString(),
  );
  const mirrorCollateralValue = useMemo(
    () => ({
      currency: values.collateral.currency,
      amount: mirrorCollateralAmount,
    }),
    [mirrorCollateralAmount, values.collateral.currency],
  );

  const [loanIcon, collateralIcon, ltvIcon] = useMemo(
    () => [
      {
        title: translate('LOAN_FIELD_TITLE'),
        description: translate('LOAN_FIELD_DESC', {
          minAmount: loanRule.minAmount,
          maxAmount: loanRule.maxAmount,
        }),
      },
      {
        title: translate('COLLATERAL_FIELD_TITLE'),
        description: translate('COLLATERAL_FIELD_DESC'),
      },
      {
        title: translate('LTV_FIELD_TITLE'),
        description: translate('LTV_FIELD_DESC'),
      },
    ],
    [loanRule, translate],
  );

  const { fetchRate } = useFetchRate();
  const loadRate = useCallback(
    () => fetchRate(values.loan.currency, values.collateral.currency),
    [fetchRate, values.loan.currency, values.collateral.currency],
  );

  // last touched field control
  useEffect(() => {
    if (activeField && checkActiveFields.includes(activeField)) {
      // @ts-ignore
      lastActiveField.current = activeField;
    }
  }, [activeField]);

  // calculating collateral by loan
  const calculateAndChangeCollateral = useCallback(async () => {
    let currentRate = rate;
    if (!rate) {
      const { rate: fetchedRate } = await loadRate();
      currentRate = fetchedRate;
    }
    const multiplier = template.type === 'MIRROR' ? 0.5 : 1;
    const collateralAmount =
      calculateCollateral(+values.loan.amount, values.ltv, currentRate) * multiplier;

    change('collateral', {
      amount: collateralAmount
        ? formatCurrency(collateralAmount, values.collateral.currency, {
            round: 'up',
            withZeros: true,
          })
        : '',
      currency: values.collateral.currency,
    });
  }, [
    loadRate,
    change,
    template.type,
    values.ltv,
    values.collateral.currency,
    values.loan.amount,
    rate,
  ]);

  useEffect(() => {
    if (getFieldState('loan')?.active) {
      calculateAndChangeCollateral();
    }
    // eslint-disable-next-line
  }, [values.loan.amount]);

  // calculating loan by collateral
  const calculateAndChangeLoan = useCallback(async () => {
    let currentRate = rate;
    if (!rate) {
      const { rate: fetchedRate } = await loadRate();
      currentRate = fetchedRate;
    }
    const loanMultiplier =
      template.type === 'MIRROR' ? 1 + template.originationFeePercent / 100 : 1;
    const collateralMultiplier = template.type === 'MIRROR' ? 2 : 1;
    const collateralAmount = +values.collateral.amount * collateralMultiplier;

    const loanAmount = calculateLoan(collateralAmount, values.ltv, currentRate) * loanMultiplier;

    change('loan', {
      amount: loanAmount
        ? formatCurrency(loanAmount, values.loan.currency, {
            round: 'down',
            withZeros: true,
          })
        : '',
      currency: values.loan.currency,
    });
  }, [
    template.originationFeePercent,
    loadRate,
    template.type,
    change,
    rate,
    values.collateral.amount,
    values.ltv,
    values.loan.currency,
  ]);

  useEffect(() => {
    if (getFieldState('collateral')?.active || mirrorInputRef.current === document.activeElement) {
      calculateAndChangeLoan();
    }
    // eslint-disable-next-line
  }, [values.collateral.amount]);

  // calculating not last touched field by ltv
  useEffect(() => {
    if (firstRender) {
      return;
    }
    if (lastActiveField.current === 'collateral') {
      calculateAndChangeLoan();
    } else {
      calculateAndChangeCollateral();
    }

    // eslint-disable-next-line
  }, [values.ltv]);

  // calculating collateral on loan currency changing
  useEffect(() => {
    if (!firstRender) {
      calculateAndChangeLoan();
    }

    // eslint-disable-next-line
  }, [values.loan.currency]);

  // calculating loan on collateral currency changing
  useEffect(() => {
    if (!firstRender) {
      calculateAndChangeCollateral();
    }

    // eslint-disable-next-line
  }, [values.collateral.currency]);

  useEffect(() => {
    if (lastActiveField.current === 'collateral' || template.type === 'MIRROR') {
      calculateAndChangeLoan();
    } else {
      calculateAndChangeCollateral();
    }
    // eslint-disable-next-line
  }, [values.templateId]);

  useEffect(() => {
    const ltvs = Object.keys(template.ltvRange);
    if (!ltvs.includes(values.ltv.toString())) {
      change('ltv', +ltvs[0]);
    }

    // eslint-disable-next-line
  }, [template.type, values.ltv]);

  useEffect(() => {
    if (values.termMonth > template.maxTermMonth || values.termMonth < template.minTermMonth) {
      change('termMonth', template.minTermMonth);
    }

    // eslint-disable-next-line
  }, [template.type, values.termMonth]);

  const collateralCurrenciesList = useMemo(
    () => cryptoWallets.filter((i) => !!template.collateralRules[i.currencyCode]),
    [cryptoWallets, template],
  );

  useEffect(() => {
    if (!collateralCurrenciesList.find((w) => w.currencyCode === values.collateral.currency)) {
      change('collateral', {
        amount: values.collateral.amount,
        currency: collateralCurrenciesList[0].currencyCode,
      });
    }
    // eslint-disable-next-line
  }, [collateralCurrenciesList, values.collateral.currency]);

  const loanCurrencies = useMemo(
    () => Object.keys(template.loanRules) as CurrencyCode[],
    [template],
  );

  useEffect(() => {
    if (!loanCurrencies.includes(values.loan.currency)) {
      change('loan', {
        amount: values.loan.amount,
        currency: loanCurrencies[0],
      });
    }
    // eslint-disable-next-line
  }, [loanCurrencies, values.loan.currency]);

  useEffect(() => {
    if (values.excessCollateralRelease && !template.excessCollateralReleaseEnabled) {
      change('excessCollateralRelease', false);
    }
    if (values.autoManagementMC && !template.autoMCEnabled) {
      change('autoManagementMC', false);
    }
    // eslint-disable-next-line
  }, [
    template.excessCollateralReleaseEnabled,
    template.autoMCEnabled,
    values.excessCollateralRelease,
    values.autoManagementMC,
  ]);

  const needSelectOneLowLtvOfOptions =
    template.type === 'FLEXIBLE' &&
    values.ltv === template.minLtv &&
    !values.autoManagementMC &&
    !values.excessCollateralRelease;

  const handleClickLtv = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      change('ltv', +e.currentTarget.dataset.ltv!);
    },
    [change],
  );

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          (template.excessCollateralReleaseEnabled &&
            option.fieldName === 'excessCollateralRelease') ||
          (template.autoMCEnabled && option.fieldName === 'autoManagementMC'),
      ),
    [template],
  );

  const handleChangeMirrorCollateral = useCallback(
    (value: Partial<CurrencyAmount>) => {
      if (typeof value.amount !== 'undefined') {
        setMirrorCollateralAmount(value.amount);
        change('collateral', {
          ...values.collateral,
          amount: formatCurrency(+value.amount / 2, values.collateral.currency, {
            withZeros: true,
            round: 'down',
          }),
        });
      }
    },
    [change, values.collateral],
  );

  useEffect(() => {
    if (template.type === 'MIRROR' && getFieldState('collateral')?.active) {
      setMirrorCollateralAmount(
        formatCurrency(+values.collateral.amount * 2, values.collateral.currency),
      );
    }
    // eslint-disable-next-line
  }, [template.type, values.collateral.amount]);

  return (
    <div className={classes.root}>
      <div className="column gap-2">
        {template.type === 'MIRROR' ? null : (
          <Field
            name="loan"
            component={SimpleCurrencyAmountField}
            currenciesList={loanCurrencies}
            label={translate('LOAN')}
            placeholder={translate('PLACEHOLDER_LIMITS_LABEL', {
              minLabel: loanRule.minAmount || 2,
              maxLabel: loanRule.maxAmount || 3,
            })}
            icon={loanIcon}
            loading={rateReducer?.meta.loading}
            showErrorOnlyIfHasAmountValue
          />
        )}
        {template.type === 'MIRROR' && (
          <CurrencyAmountInput
            inputRef={mirrorInputRef}
            onChange={handleChangeMirrorCollateral}
            pickerDisabled
            label={translate('LOANS_COLLATERAL_MIRROR_YOU_GET')}
            value={mirrorCollateralValue}
          />
        )}
        <Field
          name="collateral"
          component={CurrencyAmountField}
          label={translate(
            template.type === 'MIRROR' ? 'LOANS_COLLATERAL_MIRROR_YOU_SEND' : 'LOANS_COLLATERAL',
          )}
          showBalance
          currenciesList={collateralCurrenciesList}
          icon={template.type === 'MIRROR' ? undefined : collateralIcon}
          loading={rateReducer?.meta.loading}
          showErrorOnlyIfHasAmountValue
        />

        <Field
          name="termMonth"
          component={SliderField}
          label={translate('LOANS_LOAN_TERM')}
          suffix={' ' + translate('PLACEHOLDER_SUFFIX_MONTHS')}
          min={template.minTermMonth}
          max={template.maxTermMonth}
        />
        <div className="column">
          {template.minLtv === template.maxLtv ? (
            <FixedField
              infoIcon={ltvIcon}
              label={`${translate('LOANS_INTEREST_LTV_FULL')} (${translate('LOANS_LTV')})`}
              value={template.minLtv.toString() + '%'}
            />
          ) : (
            <div className="column gap-1">
              <div className="row gap-1">
                <span className="label">
                  {translate('LOANS_INTEREST_LTV_FULL')} ({translate('LOANS_LTV')})
                </span>
                <InfoIcon size="xs" title={ltvIcon.title} description={ltvIcon.description} />
              </div>
              <div className="row gap-1">
                {Object.keys(template.ltvRange).map((ltv) => (
                  <div
                    key={ltv}
                    onClick={handleClickLtv}
                    data-ltv={ltv}
                    className={clsx(classes.ltvOption, +ltv === values.ltv && classes.active)}
                  >
                    <span>{ltv}%</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {variant === 'full' && (
            <Smooth isVisible={needSelectOneLowLtvOfOptions}>
              <ImportantCard
                className="mt-3"
                text={translate('LOAN_MIN_LTV_WITH_NO_OPTIONS_VALIDATION')}
              />
            </Smooth>
          )}
        </div>
      </div>

      {filteredOptions.length && variant === 'full' ? (
        <div className="column gap-1-5">
          {filteredOptions.map((option) => (
            <div
              className={clsx(
                classes.option,
                needSelectOneLowLtvOfOptions && option.isRequiredForLowLtv ? classes.error : null,
              )}
              key={option.fieldName}
            >
              <div className="row aic gap-1-5">
                <div className={classes.optionImgContainer}>
                  <Image className={classes.optionImg} name={option.fieldName} path="loans" />
                </div>
                <div className="row aic gap-1">
                  <span className={classes.optionLabel}>{translate(option.labelKey)}</span>
                  <InfoIcon
                    className="ml-1 flex-shrink-0"
                    size="xs"
                    title={translate(option.labelKey)}
                    description={translate(option.descriptionKey)}
                  />
                </div>
              </div>
              <Field name={option.fieldName} component={SwitchField} className="flex-shrink-0" />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
