import clsx from 'clsx';
import ibanLib from 'iban';

import { useCallback, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectDigitalAccountExistWallets } from 'modules/accounts/store/selectors';
import { DigitalWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import { selectUserDASubscriptionType } from 'modules/digitalAccount/store/selectors';
import { PaymentDetailsFormValues } from 'modules/invoicing/views/CreateInvoice/components/PaymentDetailsForm/index';

import { CurrencyIcon, Icon, Select } from 'components/ui';

import useAnchor from 'hooks/useAnchor';
import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { findWallet } from 'utils/currency';

import classes from './ChooseMoneyAccount.module.scss';

const renderItem = (wallet: DigitalWallet) => (
  <div className="row gap-1">
    <CurrencyIcon code={wallet.currencyCode} size={40} />
    <div className="column gap-0-5">
      <span className={classes.currencyLabel}>{wallet.currencyLabel}</span>
      <span className={classes.ibanNumber}>
        {wallet.currencyCode === 'EUR' ? ibanLib.printFormat(wallet.iban) : wallet.accountNumber}
      </span>
    </div>
  </div>
);
const keyAccessor = (wallet: DigitalWallet) => wallet.currencyCode;
export const ChooseMoneyAccount = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const moneyAccountWallets = useSelector(selectDigitalAccountExistWallets);
  const isUserHasDigitalSubscription = useSelector(selectUserDASubscriptionType);

  const form = useForm<PaymentDetailsFormValues>();
  const { values } = useFormState<PaymentDetailsFormValues>();

  const currentWallet = useMemo<DigitalWallet | undefined>(
    () =>
      values.payment.currencyCode
        ? findWallet<DigitalWallet>(moneyAccountWallets, values.payment.currencyCode)
        : undefined,
    [moneyAccountWallets, values.payment.currencyCode],
  );

  const picker = useFlag(false);
  const [anchor, setAnchor] = useAnchor();

  const onPick = useCallback(
    (wallet: DigitalWallet) => {
      // @ts-ignore
      form.change('payment.currencyCode', wallet.currencyCode);
    },
    [form],
  );

  const handleClickGetIbanAccount = useCallback(() => {
    drawer.open(ibanAccountDrawerTemplates.accountCreatingPrompt());
  }, [drawer]);

  if (!isUserHasDigitalSubscription) {
    return (
      <div className="row aic gap-1">
        <span className={classes.description}>{translate('INVOICE_GET_MONEY_ACCOUNT_DESC')}</span>
        <div onClick={handleClickGetIbanAccount} className={classes.button}>
          <span>{translate('GET')}</span>
          <Icon name="arrowRight" />
        </div>
      </div>
    );
  }

  return currentWallet ? (
    <div>
      <div ref={setAnchor} className={classes.root} onClick={picker.on}>
        <div className="row gap-1">
          <CurrencyIcon
            className={classes.currencyIcon}
            code={currentWallet.currencyCode}
            size={24}
          />

          <div className="column gap-0-5">
            <span className={classes.currencyLabel}>{currentWallet.currencyLabel}</span>
            <span className={classes.ibanNumber}>
              {currentWallet.currencyCode === 'EUR'
                ? ibanLib.printFormat(currentWallet.iban)
                : currentWallet.accountNumber}
            </span>
          </div>
        </div>
        <Icon
          className={clsx(classes.arrow, picker.state && classes.active)}
          name="triangleDownLg"
          size={16}
        />
      </div>
      <div>
        <Select<DigitalWallet>
          onClose={picker.off}
          isOpen={picker.state}
          anchorEl={anchor}
          onPick={onPick}
          options={moneyAccountWallets}
          renderItem={renderItem}
          keyAccessor={keyAccessor}
          optionsWrapperClassName="gap-3"
        />
      </div>
    </div>
  ) : null;
};
