import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import useSideBar from 'modules/app/hooks/useSideBar';
import { selectFirebaseConfig } from 'modules/app/store/selectors';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { selectIsUserAuthed } from 'modules/auth/store/selectors';
import { selectUserProfileReducer } from 'modules/user/store/selectors';

const useVerificationRequired = () => {
  const sidebar = useSideBar();
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const userAuthed = useSelector(selectIsUserAuthed);
  const userReducer = useSelector(selectUserProfileReducer);
  const firebaseConfig = useSelector(selectFirebaseConfig);

  const [needOpenVerification, setNeedOpenVerification] = useState(false);

  useEffect(() => {
    if (
      firebaseConfig.verificationRequired &&
      userAuthed &&
      userReducer.meta.loaded &&
      !userReducer.data?.verified
    ) {
      sidebar.open(...sidebarTemplates.verificationID({ required: true }));
    }
    // eslint-disable-next-line
  }, [
    userAuthed,
    userReducer.data?.verified,
    firebaseConfig.verificationRequired,
    userReducer.meta.loaded,
  ]);

  useEffect(() => {
    if (
      !firebaseConfig.verificationRequired &&
      location.state?.from === 'auth' &&
      userAuthed &&
      userReducer.meta.loaded &&
      !userReducer.data?.verified
    ) {
      sidebar.open(...sidebarTemplates.verificationID());
    }
    // eslint-disable-next-line
  }, [userAuthed, userReducer.meta.loaded, userReducer.data?.verified, location.state]);

  useEffect(() => {
    if (
      needOpenVerification &&
      userReducer.meta.loaded &&
      !userReducer.data?.verified &&
      !firebaseConfig.verificationRequired
    ) {
      sidebar.open(...sidebarTemplates.verificationID());
      setNeedOpenVerification(false);
    }
    // eslint-disable-next-line
  }, [needOpenVerification, JSON.stringify(userReducer), firebaseConfig.verificationRequired]);

  useEffect(() => {
    if (params.get('verify_source') === 'email') {
      params.delete('verify_source');
      setParams(params);
      setNeedOpenVerification(true);
    }
    // eslint-disable-next-line
  }, [location.search]);
};

export default useVerificationRequired;
