import { useMemo } from 'react';

import useAllowedCryptoNetworksByCurrencyCode, {
  GetAllowedCryptoNetworksOptions,
} from 'modules/payment/hooks/useAllowedCryptoNetworksByCurrencyCode';

import { findByProperty } from 'utils/arrayUtils';

import { CryptoCurrencyCode } from 'types';

const useDefaultCryptoNetwork = (
  currencyCode: CryptoCurrencyCode,
  options?: GetAllowedCryptoNetworksOptions,
) => {
  const { allowedNetworks } = useAllowedCryptoNetworksByCurrencyCode(currencyCode, options);

  const defaultNetwork = useMemo(
    () => findByProperty(allowedNetworks, 'defaultNetwork', true),
    [allowedNetworks],
  );

  return {
    defaultNetwork,
    allowedNetworks,
  };
};

export default useDefaultCryptoNetwork;
