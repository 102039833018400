import {
  transformVaultTransactions,
  transformVaultWallets,
} from 'modules/vault/services/transformers';
import { VaultTransactionItem, VaultWallet } from 'modules/vault/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  GetWalletsTransactionsUsingQueryParams,
  VaultWalletDepositRequest,
  VaultWalletResponseList,
  VaultWalletTransactionResponseList,
  VaultWalletWithdrawRequest,
} from 'libs/swagger/nebeusApiTypes';

// cli-import

const vaultServices = {
  getWallets: async (): Promise<VaultWallet[]> =>
    AxiosInstance.get<VaultWalletResponseList, VaultWalletResponseList>('/v1/wallet/vault').then(
      (response) => transformVaultWallets(response.vaultWallets),
    ),
  getTransactions: async (
    payload: GetWalletsTransactionsUsingQueryParams,
  ): Promise<VaultTransactionItem[]> =>
    AxiosInstance.get<VaultWalletTransactionResponseList, VaultWalletTransactionResponseList>(
      '/v1/wallet/vault/transaction',
      { params: payload },
    ).then((response) => transformVaultTransactions(response.vaultWalletTransactions)),
  deposit: (payload: VaultWalletDepositRequest) =>
    AxiosInstance.post('/v1/wallet/vault/funds/deposit', payload),
  withdraw: (payload: VaultWalletWithdrawRequest) =>
    AxiosInstance.post('/v1/wallet/vault/funds/withdraw', payload),
  // cli-service
};

export default vaultServices;
