import { FC, memo, useCallback } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import { StepHeader } from 'modules/auth/views/components/StepHeader';
import { SwitchAuthType } from 'modules/auth/views/components/SwitchAuthType';

import routesByName from 'constants/routesByName';

import { SubmitButton, TextInputField } from 'components/form';

import useMobile from 'hooks/useMobile';

import { useTranslation } from 'libs/i18n';
import useSwiperInputFocus from 'libs/swiper/useSwiperInputFocus';
import useSwiperSlideSizeObserver from 'libs/swiper/useSwiperSlideSizeObserver';

import { validate } from './validate';

interface FormValues {
  email: string;
}

const initialValues: FormValues = {
  email: '',
};

interface EnterEmailFormProps extends FormRenderProps<FormValues> {
  isLogin: boolean;
}
const EnterEmailForm: FC<EnterEmailFormProps> = ({ handleSubmit, isLogin }) => {
  const translate = useTranslation();

  const { pathname } = useLocation();
  const canFocusInput = isLogin
    ? pathname === routesByName.signIn
    : pathname === routesByName.signUp;

  const isMobile = useMobile();

  const { setNode } = useSwiperSlideSizeObserver();

  const { inputRef } = useSwiperInputFocus({ additionalCondition: canFocusInput });

  return (
    <form ref={setNode} className="authStepRoot" onSubmit={handleSubmit}>
      <div className="column gap-3">
        <StepHeader
          title={translate('SIGN_ENTER_EMAIL_TITLE')}
          subtitle={
            isLogin
              ? translate('SIGN_ENTER_EMAIL_SUBTITLE')
              : `${translate('SIGN_ENTER_EMAIL_SUBTITLE')}<br/><br/> ${translate(
                  'SIGN_UP_EMAIL_NOTE',
                )}`
          }
        />

        <Field
          name="email"
          inputRef={inputRef}
          component={TextInputField}
          label={isMobile ? translate('PLACEHOLDER_EMAIL') : undefined}
          placeholder={translate('PLACEHOLDER_EMAIL')}
          autoComplete="email"
          type="email"
          inputMode="email"
        />
      </div>

      <div className="column gap-3 aic">
        <SubmitButton fullWidth>{translate('CONTINUE')}</SubmitButton>
        {isLogin && <SwitchAuthType className="hideOnMobile" currentAuthType="login" />}
      </div>
    </form>
  );
};

interface EnterEmailProps {
  onSubmit: (email: string) => void;
  isLogin: boolean;
}

export const EnterEmail = memo<EnterEmailProps>(({ onSubmit, isLogin }) => {
  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values.email.toLowerCase());
    },
    [onSubmit],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => <EnterEmailForm {...formProps} isLogin={isLogin} />,
    [isLogin],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={renderForm}
      validate={validate}
    />
  );
});
