import clsx from 'clsx';

import { FC, useCallback } from 'react';

import { CurrencyIcon } from 'components/ui/CurrencyIcon';
import { Select, SelectProps } from 'components/ui/Select';

import { getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode, voidFunc } from 'types';

import classes from './SimpleCurrencyPicker.module.scss';

export interface SimpleCurrencyPickerProps
  extends Omit<SelectProps<CurrencyCode>, 'value' | 'options'> {
  selectedCurrency?: CurrencyCode;
  currenciesList: CurrencyCode[];
  onPick: (code: CurrencyCode) => void;
  className?: string;
  position?: 'top' | 'bottom';
  isOpen: boolean;
  onClose: voidFunc;
  anchorEl: HTMLElement | null;
}

export const SimpleCurrencyPicker: FC<SimpleCurrencyPickerProps> = ({
  selectedCurrency,
  currenciesList,
  ...props
}) => {
  const renderItem = useCallback((code: CurrencyCode, index: number, active: boolean) => {
    return (
      <div className={clsx(classes.currencyItem, active && classes.active)}>
        <CurrencyIcon size={24} className={classes.currencyIcon} code={code} />
        <div className={classes.textBlock}>
          <span>{getCurrencyLabelByCode(code)}</span>
        </div>
      </div>
    );
  }, []);

  return (
    <Select<CurrencyCode>
      renderItem={renderItem}
      options={currenciesList}
      value={selectedCurrency}
      {...props}
    />
  );
};
