import { useCallback, useEffect } from 'react';

import { appActions } from 'modules/app/store';
import { useDispatch } from 'store';

import Firebase from 'libs/firebase';

const useFirebaseConfig = () => {
  const dispatch = useDispatch();

  const initConfig = useCallback(async () => {
    const config = await Firebase.getRemoteConfig();

    if (config) {
      dispatch(appActions.updateFirebaseConfig(config));
    }
  }, [dispatch]);

  useEffect(() => {
    initConfig();
    // eslint-disable-next-line
  }, []);
};

export default useFirebaseConfig;
