import { isEqual } from 'lodash';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import { TradingWallet } from 'modules/accounts/types';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import useRateReducer from 'modules/exchange/hooks/useRateReducer';
import { selectAllowedDirections, selectTrades } from 'modules/smartTrader/store/selectors';
import { SmartTraderTitles } from 'modules/smartTrader/views/components/SmartTraderTitles';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';

import { AgreementText } from 'components/common';
import { CurrencyAmountField, SimpleCurrencyAmountField, SubmitButton } from 'components/form';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import { useTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import {
  MINIMUM_AMOUNT_CRYPTO,
  MINIMUM_AMOUNT_FIAT,
  formatCurrency,
  formatCurrencyWithSymbol,
  isFiat,
} from 'utils/currency';

import { CurrenciesDirection, CurrencyCode } from 'types';

interface SmartTraderSelectTradingPairFormProps extends FormRenderProps<FormValues> {
  wallets: TradingWallet[];
  allowedDirections: CurrenciesDirection[];
}
const SmartTraderSelectTradingPairForm: FC<SmartTraderSelectTradingPairFormProps> = ({
  wallets,
  values,
  allowedDirections,
  form,
  handleSubmit,
}) => {
  const translate = useTranslation();

  const allowedDirectionsMap = useMemo(
    () =>
      allowedDirections.map((d) => {
        const [from, to] = d.split('/');
        return { from, to } as { from: CurrencyCode; to: CurrencyCode };
      }),
    [allowedDirections],
  );

  const allowedSecondaryCurrencies = useMemo<CurrencyCode[]>(
    () => allowedDirectionsMap.filter((d) => d.from === values.primary.currency).map((d) => d.to),
    [allowedDirectionsMap, values.primary.currency],
  );

  const primaryCurrenciesList = useMemo(
    () => wallets.filter((w) => allowedDirectionsMap.find((d) => d.from === w.currencyCode)),
    [wallets, allowedDirectionsMap],
  );

  useEffect(() => {
    if (!allowedSecondaryCurrencies.includes(values.secondary.currency)) {
      form.change('secondary', {
        currency: allowedSecondaryCurrencies[0] || 'EUR',
        amount: values.secondary.amount,
      });
    }
    // eslint-disable-next-line
  }, [allowedSecondaryCurrencies]);

  const rateReducer = useRateReducer(values.primary.currency, values.secondary.currency, {
    autoFetch: true,
  });

  useEffect(() => {
    const rate = rateReducer?.data?.rate;
    if (rate && !form.getFieldState('secondary')?.active) {
      form.change('secondary', {
        ...values.secondary,
        amount:
          values.primary.amount === ''
            ? ''
            : formatCurrency(+values.primary.amount * rate, values.secondary.currency),
      });
    }
    // eslint-disable-next-line
  }, [
    values.primary.amount,
    values.primary.currency,
    values.secondary.currency,
    rateReducer?.data?.rate,
  ]);

  useEffect(() => {
    const rate = rateReducer?.data?.rate;
    if (rate && form.getFieldState('secondary')?.active) {
      form.change('primary', {
        ...values.primary,
        amount: formatCurrency(+values.secondary.amount * (1 / rate), values.primary.currency),
      });
    }
    // eslint-disable-next-line
  }, [values.secondary.amount]);
  return (
    <form onSubmit={handleSubmit} className="column flex-1 jcsb">
      <div>
        <SmartTraderTitles
          title={translate('SMART_TRADER_SELECT_PAIR_TITLE')}
          subtitle={translate('SMART_TRADER_SELECT_PAIR_SUBTITLE')}
        />
        <div className="whiteCard mt-3 p-3 gap-3">
          <Field
            name="primary"
            component={CurrencyAmountField}
            label={translate('SELECT_ASSET')}
            placeholder={translate('ENTER_AMOUNT')}
            showMaxAmountButton
            showBalance
            currenciesList={primaryCurrenciesList}
            loading={rateReducer?.meta.loading}
          />
          <Field
            name="secondary"
            component={SimpleCurrencyAmountField}
            label={translate('SELECT_PAIR')}
            placeholder={translate('ENTER_AMOUNT')}
            currenciesList={allowedSecondaryCurrencies}
            loading={rateReducer?.meta.loading}
            isEqual={isEqual}
          />
        </div>
      </div>
      <div className="column gap-1-5">
        <SubmitButton variant="gold">{translate('CONTINUE')}</SubmitButton>
        <AgreementText light />
      </div>
    </form>
  );
};

interface FormValues {
  primary: CurrencyAmount;
  secondary: CurrencyAmount;
}
export interface SmartTraderSelectTradingPairProps {
  currencyCode: CurrencyCode;
}
const SmartTraderSelectTradingPair: FC<SmartTraderSelectTradingPairProps> = ({ currencyCode }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const wallets = useSelector(selectWallets);
  const allowedDirections = useSelector(selectAllowedDirections);
  const trades = useSelector(selectTrades);

  const allowedWallets = useMemo(
    () => wallets.filter((w) => !trades.find((t) => t.primaryCurrency === w.currencyCode)),
    [trades, wallets],
  );

  const userDefaultCurrency = useSelector(selectUserDefaultCurrencyCode);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      sidebar.open(...sidebarTemplates.smartTraderConfigureTrade(values));
    },
    [sidebar],
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      primary: { currency: currencyCode, amount: '' },
      secondary: { currency: userDefaultCurrency, amount: '' },
    }),
    [currencyCode, userDefaultCurrency],
  );

  const getSchema = useCallback(
    (values: FormValues) => {
      const wallet = wallets.find((w) => w.currencyCode === values.primary.currency);

      const secondaryMinAmount = isFiat(values.secondary.currency)
        ? MINIMUM_AMOUNT_FIAT
        : MINIMUM_AMOUNT_CRYPTO;
      return yup.object().shape({
        primary: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .min(
              MINIMUM_AMOUNT_CRYPTO,
              translate('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(MINIMUM_AMOUNT_CRYPTO, values.primary.currency),
              }),
            )
            .max(wallet?.amount ?? 0, translate('VALIDATION_INSUFFICIENT_FUNDS'))
            .requiredDefault(),
        }),
        secondary: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .min(
              secondaryMinAmount,
              translate('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(secondaryMinAmount, values.secondary.currency),
              }),
            )
            .requiredDefault(),
        }),
      });
    },
    [translate, wallets],
  );

  const validate = useCallback(
    (values: FormValues) => {
      const schema = getSchema(values);
      return makeValidate(schema)(values);
    },
    [getSchema],
  );
  return (
    <Form
      // @ts-ignore
      render={SmartTraderSelectTradingPairForm}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      wallets={allowedWallets}
      allowedDirections={allowedDirections}
    />
  );
};

export default SmartTraderSelectTradingPair;
