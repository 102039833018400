import clsx from 'clsx';

import { FC, ReactElement, ReactNode } from 'react';

import { CopyButton } from 'components/ui/CopyButton';
import { Loader } from 'components/ui/Loader';

import classes from './DetailsCard.module.scss';

export interface DetailsItem {
  label: string;
  value: string;
  copyValue?: string;
  canCopy?: boolean;
  endAdornment?: ReactNode;
  loading?: boolean;
}

export interface DetailsCardProps {
  details: DetailsItem[];
  className?: string;
  children?: ReactElement;
  startAdornment?: ReactElement;
}
export const DetailsCard: FC<DetailsCardProps> = ({
  className,
  details,
  children,
  startAdornment,
}) => {
  return (
    <div className={clsx(className, 'outlinedCard')}>
      {startAdornment}
      <div className="column gap-3">
        {details.map((d) => (
          <div className="row aic jcsb gap-2" key={d.label}>
            <div className="column flex-shrink-1 gap-0-5">
              <span className="label">{d.label}</span>
              <span className={classes.value}>{d.value}</span>
            </div>
            <div className="flex-shrink-0">
              {d.loading ? (
                <Loader />
              ) : (
                d.endAdornment ||
                (d.canCopy === false ? null : (
                  <CopyButton
                    className="flex-shrink-0"
                    value={d.copyValue || d.value}
                    label={d.label}
                  />
                ))
              )}
            </div>
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};
