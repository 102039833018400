import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { CryptoBankCardActivateProps } from 'modules/cryptoBankCard/views/CryptoBankCardActivate';
import { CryptoBankCardChangePinCodeProps } from 'modules/cryptoBankCard/views/CryptoBankCardChangePinCode';
import { CryptoBankCardConnectAccountProps } from 'modules/cryptoBankCard/views/CryptoBankCardConnectAccount';
import { CryptoBankCardTransactionsProps } from 'modules/cryptoBankCard/views/CryptoBankCardTransactions';
import { GoogleWalletOptionAdProps } from 'modules/cryptoBankCard/views/GoogleWalletOptionAd';
import { OrderBankCardCalculatingShippingCostsProps } from 'modules/cryptoBankCard/views/OrderBankCardCalculatingShippingCosts';
import { OrderBankCardConfirmationProps } from 'modules/cryptoBankCard/views/OrderBankCardConfirmation';
import { OrderBankCardDeliveryMethodProps } from 'modules/cryptoBankCard/views/OrderBankCardDeliveryMethod';
import { OrderBankCardShippingDetailsProps } from 'modules/cryptoBankCard/views/OrderBankCardShippingDetails';
import { TerminateBankCardProps } from 'modules/cryptoBankCard/views/TerminateBankCard';

import { CreateBankCardSuccessProps } from 'components/common/bankCards/CreateBankCardSuccess';

import { getTranslation } from 'libs/i18n';

const orderCardShippingDetails = (
  props?: OrderBankCardShippingDetailsProps,
): DrawerTemplateReturnType => ({
  key: 'orderCryptoBankCardShippingDetails',
  props,
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
    },
    navigationHeaderLabel: getTranslation(
      props?.isVirtual
        ? 'CRYPTO_VIRTUAL_BANK_CARD_USER_DETAILS'
        : 'CRYPTO_BANK_CARD_SHIPPING_DETAILS',
    ),
  },
});

const orderBankCardConfirmation = (
  props: OrderBankCardConfirmationProps,
): DrawerTemplateReturnType => ({
  key: 'orderCryptoBankCardConfirmation',
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
    },
    navigationHeaderLabel: getTranslation('CONFIRMATION'),
  },
  props,
});
const orderBankCardSuccess = (props: CreateBankCardSuccessProps): DrawerTemplateReturnType => ({
  key: 'createBankCardSuccess',
  props,
  sideBarProps: {
    showBackButton: false,
  },
});

const activateCard = (props: CryptoBankCardActivateProps): DrawerTemplateReturnType => ({
  key: 'cryptoBankCardActivate',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('CRYPTO_BANK_CARD_ACTIVATE_CARD_TITLE'),
    navigationHeaderProps: { hideBackButton: true },
    swipeModal: true,
  },
});
const activateCardSuccess = (): DrawerTemplateReturnType => ({
  key: 'cryptoBankCardActivateSuccess',
  sideBarProps: {
    swipeModal: true,
    showBackButton: false,
  },
});
const connectCryptoAccount = (
  props: CryptoBankCardConnectAccountProps,
): DrawerTemplateReturnType => ({
  key: 'cryptoBankCardConnectAccount',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation(
      props.card.currencyCode
        ? 'CRYPTO_BANK_CARD_SWITCH_CONNECT_ACCOUNT'
        : 'CRYPTO_BANK_CARD_CONNECT_ACCOUNT',
    ),
    navigationHeaderProps: { hideBackButton: true },
    swipeModalMaxHeight: true,
    withoutBottomPadding: true,
  },
});
const transactions = (props: CryptoBankCardTransactionsProps): DrawerTemplateReturnType => ({
  key: 'cryptoBankCardTransactions',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('TRANSACTIONS'),
    navigationHeaderProps: { hideBackButton: true },
    contentClassName: 'pb-0',
  },
});
const changePinCode = (props: CryptoBankCardChangePinCodeProps): DrawerTemplateReturnType => ({
  key: 'cryptoBankCardChangePinCode',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('BANK_CARD_CHANGE_PIN'),
    navigationHeaderProps: { hideBackButton: true },
  },
});

const orderCardCalculatingShippingCosts = (
  props: OrderBankCardCalculatingShippingCostsProps,
): DrawerTemplateReturnType => ({
  key: 'orderBankCardCalculatingShippingCosts',
  sideBarProps: {
    showBackButton: false,
  },
  props,
});

const orderBankCardDeliveryMethod = (
  props: OrderBankCardDeliveryMethodProps,
): DrawerTemplateReturnType => ({
  key: 'orderBankCardDeliveryMethod',
  sideBarProps: {
    navigationHeaderLabel: getTranslation('CRYPTO_BANK_CARD_SHIPPING_CHOOSE_METHOD'),
  },
  props,
});
const orderBankCardChooseType = (): DrawerTemplateReturnType => ({
  key: 'orderBankCardChooseType',
  sideBarProps: {
    navigationHeaderLabel: getTranslation('CRYPTO_BANK_CARD_CHOOSE_TYPE'),
  },
});
const introducing = (): DrawerTemplateReturnType => ({
  key: 'cryptoBankCardIntroducing',
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
    },
    navigationHeaderLabel: getTranslation('CRYPTO_CARD_INTRO_TITLE'),
    swipeModal: true,
  },
});
const feeAndConditions = (): DrawerTemplateReturnType => ({
  key: 'cryptoCardFeeAndConditions',
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
    },
    navigationHeaderLabel: getTranslation('CRYPTO_CARD_FEES_AND_CONDITIONS'),
    swipeModal: true,
  },
});

const googleWalletOptionAd = (props: GoogleWalletOptionAdProps): DrawerTemplateReturnType => ({
  key: 'cryptoCardGoogleWalletOptionAd',
  props,
  sideBarProps: {
    navigationHeaderProps: {
      backButtonFilled: false,
    },
    navigationHeaderLabel: getTranslation(
      props.cardExist
        ? 'CARD_WITH_GOOGLE_WALLET_CARD_EXIST_AD_TITLE'
        : 'CARD_WITH_GOOGLE_WALLET_CARD_ABSENT_AD_TITLE',
    ),
    swipeModal: true,
    swipeModalMaxHeight: true,
  },
});

const terminateCard = (props: TerminateBankCardProps): DrawerTemplateReturnType => ({
  key: 'bankCardTerminate',
  props,
  sideBarProps: {
    showBackButton: false,
    swipeModal: true,
    navigationHeaderLabel: getTranslation(
      props.finalConfirmation ? 'BANK_CARD_TERMINATE_FINAL_TITLE' : 'BANK_CARD_TERMINATE_TITLE',
    ),
  },
  allowDuplicate: true,
  id: props.finalConfirmation ? 'final' : 'notFinal',
});

const cryptoBankCardDrawerTemplates = {
  orderCardShippingDetails,
  orderBankCardConfirmation,
  orderBankCardSuccess,
  activateCard,
  activateCardSuccess,
  connectCryptoAccount,
  transactions,
  changePinCode,
  orderCardCalculatingShippingCosts,
  orderBankCardDeliveryMethod,
  orderBankCardChooseType,
  introducing,
  feeAndConditions,
  googleWalletOptionAd,
  terminateCard,
};

export default cryptoBankCardDrawerTemplates;
