import { useTranslation } from 'libs/i18n';

import classes from './DescriptionCard.module.scss';

export const DescriptionCard = () => {
  const translate = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.blurredCard}>
        <h2 dangerouslySetInnerHTML={{ __html: translate('VAULT_INSURED_COLD_STORAGE_VAULT') }} />
        <div className={classes.desc}>
          <p>&#9679; {translate('VAULT_BENEFIT_1')}</p>
          <p>&#9679; {translate('VAULT_BENEFIT_2')}</p>
        </div>
      </div>
      <h2
        className={classes.mobileTitle}
        dangerouslySetInnerHTML={{ __html: translate('VAULT_INSURED_COLD_STORAGE_VAULT') }}
      />
    </div>
  );
};
