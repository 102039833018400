import clsx from 'clsx';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Navigate, Route, useNavigate } from 'react-router-dom';

import AccountPage from 'modules/accounts/views/AccountPage';
import Accounts from 'modules/accounts/views/Accounts';
import AccountsTransactionsHistory from 'modules/accounts/views/AccountsTransactionsHistory';
import useDataRefresher from 'modules/app/hooks/useDataRefresher';
import HappyBirthDay from 'modules/app/views/AuthedApp/components/HappyBirthDay';
import useAdBanners from 'modules/app/views/AuthedApp/hooks/useAdBanners';
import useInvalidRouteRedirect from 'modules/app/views/AuthedApp/hooks/useInvalidRouteRedirect';
import useScrollToTop from 'modules/app/views/AuthedApp/hooks/useScrollToTop';
import MaintenanceScheduleCard from 'modules/app/views/MaintenanceScheduleCard';
import SideBar from 'modules/app/views/Sidebar';
import TradingAccountDashboard from 'modules/app/views/TradingAccountDashboard';
import { selectIsUserAuthed } from 'modules/auth/store/selectors';
import ActivateCryptoCardByUrl from 'modules/cryptoBankCard/views/ActivateCryptoCardByUrl';
import OpenCryptoBankCardOrderProcessByUrl from 'modules/cryptoBankCard/views/OpenCryptoBankCardOrderProcessByUrl';
import CryptoRenting from 'modules/cryptoRenting/views/CryptoRenting';
import RentingPayoutSchedule from 'modules/cryptoRenting/views/RentingPayoutSchedule';
import IbanDashboard from 'modules/digitalAccount/views/IbanDashboard';
import ExchangePage from 'modules/exchange/views/ExchangePage';
import LoanCalculator from 'modules/loans/views/LoanCalculator';
import LoanConfirmation from 'modules/loans/views/LoanConfirmation';
import Loans from 'modules/loans/views/Loans';
import PaymentsAndCollateral from 'modules/loans/views/PaymentsAndCollateral';
import { isReactNative } from 'modules/reactNative/utils';
import SmartTradeDashboard from 'modules/smartTrader/views/SmartTradeDashboard';
import { SmartTrader } from 'modules/smartTrader/views/SmartTrader';
import Staking from 'modules/staking/views/Staking';
import StakingItem from 'modules/staking/views/StakingItem';
import useCustomerQuestionnaire from 'modules/user/hooks/useCustomerQuestionnaire';
import useUserChallenges from 'modules/user/hooks/useUserChallenges';
import { selectUserProfile } from 'modules/user/store/selectors';
import Vault from 'modules/vault/views/Vault';

import { NAV_BAR_SHOWED_MIN_WIDTH } from 'constants/responsive';
import routesByName from 'constants/routesByName';

import { PullToRefresh } from 'components/common';

import SentryRoutes from 'libs/sentry/SentryRoutes';

import Header from './components/Header';
import NavMenu from './components/NavMenu';
import ReactNativeBottomBar from './components/ReactNativeBottomBar';
import useRefundTransactions from './hooks/useRefundTransactions';

const AuthedAppControllers = () => {
  useInvalidRouteRedirect();
  useDataRefresher();
  useScrollToTop();
  useAdBanners();
  useRefundTransactions();
  useUserChallenges();
  // Will be returned in Jan
  // useCustomerQuestionnaire();

  return (
    <>
      <HappyBirthDay />
    </>
  );
};

const AuthedApp = () => {
  const navigate = useNavigate();

  const authed = useSelector(selectIsUserAuthed);
  const user = useSelector(selectUserProfile);

  useEffect(() => {
    if (!authed) {
      navigate(routesByName.signIn, { replace: true });
    } else {
      if (!user?.hasPhoneNumber) {
        navigate(routesByName.signUp, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [authed, user?.hasPhoneNumber]);

  return authed ? (
    <>
      <SideBar />
      <Header />
      <PullToRefresh>
        <div className={clsx('appBody', isReactNative && 'reactNative')}>
          <div className="container row gap-4">
            <MediaQuery minWidth={NAV_BAR_SHOWED_MIN_WIDTH + 1}>
              <NavMenu />
            </MediaQuery>
            <div className={clsx('appContent', isReactNative && 'reactNative')}>
              <MaintenanceScheduleCard />
              <SentryRoutes>
                <Route path={routesByName.dashboard} element={<TradingAccountDashboard />} />
                <Route path={routesByName.iban} element={<IbanDashboard />} />
                <Route path={routesByName.cryptoRenting()}>
                  <Route path=":tab" element={<CryptoRenting />} />
                  <Route
                    path={routesByName.cryptoRentingPayoutSchedule()}
                    element={<RentingPayoutSchedule />}
                  />
                </Route>
                <Route path={routesByName.loans()}>
                  <Route path=":tab" element={<Loans />} />
                  <Route path=":tab/:templateType" element={<LoanCalculator />} />
                  <Route
                    path={routesByName.loansPaymentAndCollateral()}
                    element={<PaymentsAndCollateral />}
                  />
                  <Route path={routesByName.loanConfirmation} element={<LoanConfirmation />} />
                </Route>
                <Route path={routesByName.staking()}>
                  <Route path=":tab" element={<Staking />} />
                  <Route path="assets/:code" element={<StakingItem />} />
                </Route>
                <Route path={routesByName.accounts()}>
                  <Route path=":accountType" element={<Accounts />} />
                  <Route path=":accountType/:currencyCode" element={<AccountPage />} />
                  <Route path="crypto/history" element={<AccountsTransactionsHistory />} />
                </Route>
                <Route path={routesByName.exchange} element={<ExchangePage />} />
                <Route path={routesByName.smartTrader}>
                  <Route index element={<SmartTrader />} />
                  <Route
                    path={routesByName.smartTradeDashboard()}
                    element={<SmartTradeDashboard />}
                  />
                </Route>

                <Route path={routesByName.vault} element={<Vault />} />
                <Route
                  path={routesByName.orderCryptoBankCard}
                  element={<OpenCryptoBankCardOrderProcessByUrl />}
                />
                <Route
                  path={routesByName.activateCryptoCard}
                  element={<ActivateCryptoCardByUrl />}
                />
                <Route path="/*" element={<Navigate replace to={routesByName.dashboard} />} />
              </SentryRoutes>
            </div>
          </div>
        </div>
      </PullToRefresh>
      <ReactNativeBottomBar />
      <AuthedAppControllers />
    </>
  ) : null;
};

export default AuthedApp;
