import clsx from 'clsx';
import { ResponsiveContainerProps } from 'recharts';

import { FC, ReactNode } from 'react';

import useHistoryRateChart from 'modules/exchange/hooks/useHistoryRateChart';
import useRate from 'modules/exchange/hooks/useRate';
import classes from 'modules/exchange/views/components/CurrencyRateCard/CurrencyRateCard.module.scss';

import { CurrencyIcon, RateChart, Skeleton } from 'components/ui';

import {
  fiatCurrencies,
  formatCurrencyWithSymbol,
  getCurrencyLabelByCode,
  stableCoinsCodes,
} from 'utils/currency';

import { CurrencyCode, voidFunc } from 'types';

export interface CurrencyRateCardProps {
  from: CurrencyCode;
  to: CurrencyCode;
  className?: string;
  ratesChartProps?: Omit<ResponsiveContainerProps, 'children'>;
  endAdornment?: ReactNode;
  reverseFiatAndStable?: boolean;
  chartClassName?: string;
  fetchHistory?: boolean;
  onClick?: voidFunc;
  variant?: 'bare';
  isLight?: boolean;
  rate?: number;
  rateLoading?: boolean;
}

const alwaysToCurrency = [...fiatCurrencies, ...stableCoinsCodes];

const CurrencyRateCard: FC<CurrencyRateCardProps> = ({
  from: fromFromProps,
  to: toFromProps,
  rate: rateFromProps,
  rateLoading,
  className,
  ratesChartProps,
  endAdornment,
  reverseFiatAndStable = true,
  chartClassName,
  fetchHistory,
  onClick,
  variant,
  isLight,
}) => {
  const reverse = reverseFiatAndStable && alwaysToCurrency.includes(fromFromProps);
  const from = reverse ? toFromProps : fromFromProps;
  const to = reverse ? fromFromProps : toFromProps;
  const rateFromStore = useRate(from, to);

  const { history, raised, changePercentLabel, changePercent } = useHistoryRateChart(from, to, {
    fetchHistory,
  });

  const rate = rateFromProps || rateFromStore;

  return (
    <div
      onClick={onClick}
      className={clsx(
        classes.root,
        className,
        isLight && classes.light,
        classes[`variant-${variant}`],
      )}
    >
      <div className="row aic">
        <CurrencyIcon className={classes.currencyIcon} size={40} code={from} />
        <div>
          <h5>{getCurrencyLabelByCode(from)}</h5>
          <span>{from}</span>
        </div>
      </div>
      <div className={clsx(classes.chart, chartClassName)}>
        <RateChart
          rates={history}
          raised={raised}
          height={variant === 'bare' ? 32 : 68}
          {...ratesChartProps}
        />
      </div>
      <div className="row aic">
        <div className="tar column gap-0-5 aife">
          {rateLoading || !rate ? (
            <Skeleton height={24} width={80} />
          ) : (
            <h5>{formatCurrencyWithSymbol(rate, to)}</h5>
          )}
          {changePercentLabel ? (
            <span className={changePercent > 0 ? classes.raised : classes.down}>
              {changePercentLabel}
            </span>
          ) : (
            <Skeleton height={16} width={50} />
          )}
        </div>
        {endAdornment}
      </div>
    </div>
  );
};

export default CurrencyRateCard;
