import clsx from 'clsx';

import { FC, HTMLProps, ReactNode } from 'react';

import classes from './Mark.module.scss';

interface MarkProps extends HTMLProps<HTMLSpanElement> {
  children: ReactNode;
  className?: string;
  variant?:
    | 'green'
    | 'greenOutlined'
    | 'redOutlined'
    | 'gray'
    | 'grayOutlined'
    | 'blurredGreen'
    | 'blurredBlack'
    | 'warn'
    | 'kindaBlack'
    | 'gold'
    | 'white';

  rounded?: boolean;
  startAdornment?: ReactNode;
}
export const Mark: FC<MarkProps> = ({
  children,
  className,
  variant = 'grayOutlined',
  rounded,
  startAdornment,
  ...props
}) => (
  <span
    className={clsx(
      classes.root,
      className,
      classes['variant-' + variant],
      rounded && 'max-border-radius',
    )}
    {...props}
  >
    {startAdornment}
    {children}
  </span>
);
