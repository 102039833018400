import { createSlice } from '@reduxjs/toolkit';

import { generateRequestDataReducer } from 'store/generators/reducers';
import { initialArrayApiRequestState } from 'store/generators/utils';

import { VaultState, VaultWallet } from '../types';

// cli-import

const initialState: VaultState = {
  vaultWallets: initialArrayApiRequestState,
  // cli-state
};

const vaultSlice = createSlice({
  name: 'vault',
  initialState,
  reducers: {
    resetState: (state) => {
      state = initialState;
      return state;
    },
    updateVaultWallets: generateRequestDataReducer<VaultState, VaultWallet[]>({
      reducerPath: 'vaultWallets',
    }),
    // cli-reducer
  },
});

export default vaultSlice.reducer;
export const vaultActions = vaultSlice.actions;
