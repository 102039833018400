import { DetailedHTMLProps, FC, ImgHTMLAttributes, useMemo } from 'react';

import useFetchLargestImages from './useFetchLargestImages';

export interface ImageProps
  extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  type?: 'png' | 'jpg' | 'svg';
  name: string;
  path?: string;
}

export const Image: FC<ImageProps> = ({ type = 'png', name, path, ...props }) => {
  const [image, image2x, image3x] = useFetchLargestImages({
    type,
    imageName: name,
    path,
  });

  const srcSet = useMemo(
    () => (image && image2x && image3x ? `${image3x} 3x, ${image2x} 2x, ${image} 1x` : ''),
    [image, image2x, image3x],
  );

  // eslint-disable-next-line
  return image ? <img src={image} srcSet={srcSet || undefined} alt={name} {...props} /> : null;
};
