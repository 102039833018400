import clsx from 'clsx';

import { FC } from 'react';

import classes from './LineDelimiter.module.scss';

interface LineDelimiterProps {
  className?: string;
}

export const LineDelimiter: FC<LineDelimiterProps> = ({ className }) => (
  <div className={clsx(classes.root, className)} />
);
