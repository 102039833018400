import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import ExchangeForm, {
  ExchangeFormValues,
  ExchangeSuccessCallbackPayload,
} from 'modules/exchange/views/ExchangeForm';

export interface ExchangeProps extends Partial<ExchangeFormValues> {}

const Exchange: FC<ExchangeProps> = ({
  from = { amount: '', currency: 'BTC' },
  to = { amount: '', currency: 'EUR' },
}) => {
  const drawer = useDrawer();

  const onSuccess = useCallback(
    ({ response }: ExchangeSuccessCallbackPayload) => {
      drawer.replace(
        exchangeDrawerTemplates.exchangeSuccess({
          from: { amount: response.fromAmount.toString(), currency: response.fromCurrency },
          to: { amount: response.toAmount.toString(), currency: response.toCurrency },
          rate: response.exchangeRate,
          fee: {
            amount: response.commissionAmount.toString(),
            currency: response.commissionCurrencyCode,
          },
          date: response.operationDate,
        }),
      );
    },
    [drawer],
  );

  return (
    <div className="mt-1">
      <ExchangeForm from={from} to={to} onSuccess={onSuccess} />
    </div>
  );
};

export default Exchange;
