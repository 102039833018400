import { StartTradeAllowedList } from 'modules/smartTrader/views/SmartTrader/components/StartTradeAllowedList';
import { TradesList } from 'modules/smartTrader/views/SmartTrader/components/TradesList';

export const SmartTrader = () => {
  return (
    <div className="px-2 pb-2 column gap-2">
      <TradesList />
      <StartTradeAllowedList />
    </div>
  );
};
