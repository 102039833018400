import { useCallback } from 'react';

const useParseCryptoAddress = (): ((addressInput: string) => {
  address: string;
  metaParam?: string;
  metaParamType?: 'memo' | 'tag';
}) => {
  return useCallback((addressInput: string) => {
    if (!addressInput) {
      return { address: '', metaParam: undefined, metaParamType: undefined };
    }
    const hasMemo = addressInput.includes('?memoId=');
    const hasTag = addressInput.includes('?dt=');

    if (hasMemo || hasTag) {
      const separator = hasMemo ? '?memoId=' : '?dt=';
      const walletParts = addressInput.split(separator);

      return {
        address: walletParts[0],
        metaParam: walletParts[1],
        metaParamType: hasMemo ? 'memo' : 'tag',
      };
    }

    return { address: addressInput, metaParam: undefined, metaParamType: undefined };
  }, []);
};

export default useParseCryptoAddress;
