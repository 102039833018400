import { FormApi } from 'final-form';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { requestCalculateOperation } from 'modules/payment/store/thunks';
import { PaymentOperationId } from 'modules/payment/types';
import useCommissionRule from 'modules/payment/views/components/AmountForm/hooks/useCommissionRule';
import { PaymentAmountFormValues } from 'modules/payment/views/components/AmountForm/index';
import { useDispatch } from 'store';

import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import useDebounceFunc from 'hooks/useDebounceFunc';

import { OperationCommissionRequest } from 'libs/swagger/nebeusApiTypes';

import { formatCurrency } from 'utils/currency';

const useCalculating = ({
  operationId,
  currencyAmount,
  currencyAmountWithCommission,
  form,
  additionalCalculateRequestPayload,
  recalculateDeps = [],
}: {
  operationId: PaymentOperationId;
  currencyAmount: CurrencyAmount;
  currencyAmountWithCommission?: CurrencyAmount;
  form: FormApi<PaymentAmountFormValues>;
  additionalCalculateRequestPayload?: Partial<OperationCommissionRequest>;
  recalculateDeps?: string[];
}) => {
  const dispatch = useDispatch();

  const commissionRule = useCommissionRule(operationId, currencyAmount.currency);

  const operationHasCommission = useMemo(
    () =>
      !commissionRule
        ? false
        : Boolean(commissionRule.percent || commissionRule.fixed || commissionRule.minFixed),
    [commissionRule],
  );

  useEffect(() => {
    if (operationHasCommission) {
      form.change('currencyAmountWithCommission', {
        amount: '',
        currency: currencyAmount.currency,
      });
    }
    // eslint-disable-next-line
  }, [operationHasCommission]);

  const abortController = useRef<AbortController | undefined>();

  const [loadingField, setLoadingField] = useState<'from' | 'to' | null>(null);

  const calculate = useCallback(
    async (payload: OperationCommissionRequest) => {
      if (abortController.current) {
        abortController.current.abort();
      }

      const controller = new AbortController();
      abortController.current = controller;

      setLoadingField(payload.plusCommissionToAmount ? 'from' : 'to');

      const { success, data } = await dispatch(
        requestCalculateOperation(payload, { axiosConfig: { signal: controller.signal } }),
      );

      if (success && data) {
        form.batch(() => {
          form.change(
            payload.plusCommissionToAmount ? 'currencyAmount' : 'currencyAmountWithCommission',
            {
              amount: formatCurrency(data.amountWithCommission, payload.currencyCode),
              currency: payload.currencyCode,
            },
          );
          form.change('commissionAmount', data.commissionAmount);
        });
      }
      setLoadingField(null);
    },
    [dispatch, form],
  );

  const debouncedCalculate = useDebounceFunc<OperationCommissionRequest>(calculate);

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }
    if (operationHasCommission && !form.getFieldState('currencyAmountWithCommission')?.active) {
      setLoadingField('to');

      debouncedCalculate({
        ...additionalCalculateRequestPayload,
        amount: +currencyAmount.amount,
        currencyCode: currencyAmount.currency,
        plusCommissionToAmount: false,
        // @ts-ignore
        operationCode: operationId,
      });
    }
    // eslint-disable-next-line
  }, [currencyAmount.amount]);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }
    if (operationHasCommission && !form.getFieldState('currencyAmountWithCommission')?.active) {
      calculate({
        ...additionalCalculateRequestPayload,
        amount: +currencyAmount.amount,
        currencyCode: currencyAmount.currency,
        plusCommissionToAmount: false,
        // @ts-ignore
        operationCode: operationId,
      });
    }
    // eslint-disable-next-line
  }, recalculateDeps);

  useEffect(() => {
    if (!currencyAmountWithCommission) {
      return;
    }
    if (form.getFieldState('currencyAmountWithCommission')?.active && commissionRule) {
      setLoadingField('from');
      debouncedCalculate({
        ...additionalCalculateRequestPayload,
        amount: +currencyAmountWithCommission.amount,
        currencyCode: currencyAmountWithCommission.currency || currencyAmount.currency,
        plusCommissionToAmount: true,
        // @ts-ignore
        operationCode: operationId,
      });
    }
    // eslint-disable-next-line
  }, [currencyAmountWithCommission?.amount]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return { operationHasCommission, loadingField };
};

export default useCalculating;
