import { groupBy, random } from 'lodash';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import phonebookDrawerTemplates from 'modules/phonebook/constants/drawerTemplates';
import {
  configPaymentDetailsByType,
  getIconByPaymentDetails,
  maskPaymentDetailsValue,
} from 'modules/phonebook/constants/utils';
import {
  selectCounterpartiesLibrary,
  selectPaymentDetailsLibrary,
} from 'modules/phonebook/store/selectors';
import {
  requestDeleteCounterparty,
  requestGetCounterpartyPaymentsDetails,
} from 'modules/phonebook/store/thunks';
import { Counterparty as ICounterparty, PaymentDetails } from 'modules/phonebook/types';
import { useDispatch } from 'store';

import { Button, DrawerHeader, Icon, Mark, MenuList, Skeleton } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import useEntity from 'hooks/useEntity';
import useLastPresentValue from 'hooks/useLastPresentValue';

import { TranslationKey, useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import classes from './Counterparty.module.scss';

export interface CounterpartyProps {
  counterpartyId: ICounterparty['id'];
}

const Skeletons = () => (
  <div className={classes.skeletons}>
    {new Array(random(1, 3, false)).fill(null).map((_, index) => (
      <Skeleton key={index} height={92} borderRadius={4} />
    ))}
  </div>
);

const Counterparty: FC<CounterpartyProps> = ({ counterpartyId }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const counterpartiesLibrary = useSelector(selectCounterpartiesLibrary);
  const paymentDetailsLibrary = useSelector(selectPaymentDetailsLibrary);

  const counterpartyFromLibrary = useMemo(
    () => counterpartiesLibrary[counterpartyId],
    [counterpartyId, counterpartiesLibrary],
  );

  const counterparty = useLastPresentValue(counterpartyFromLibrary);

  const [fName, lName] = counterparty.name.toUpperCase().split(' ');

  const editCounterparty = useCallback(() => {
    drawer.open(
      phonebookDrawerTemplates.addEditCounterparty({ counterpartyForEdit: counterparty }),
    );
  }, [drawer, counterparty]);

  const { loading: paymentDetailsLoading, fetchEntity: fetchPaymentDetails } = useEntity(() =>
    dispatch(requestGetCounterpartyPaymentsDetails(counterparty.id)),
  );

  const paymentDetails = useMemo(
    () => paymentDetailsLibrary[counterpartyId],
    [paymentDetailsLibrary, counterpartyId],
  );

  useEffect(() => {
    if (!paymentDetails) {
      fetchPaymentDetails();
    }

    // eslint-disable-next-line
  }, []);

  const paymentDetailsGroupedByType = useMemo<{
    [key in PaymentDetails['type']]: PaymentDetails[];
  }>(
    () => ({
      BANK_CARD: [],
      SEPA: [],
      WIRE: [],
      CRYPTO_WALLET: [],
      ...groupBy(paymentDetails, 'type'),
    }),
    [paymentDetails],
  );

  const getMenuItemByPaymentDetails = useCallback(
    (paymentDetails: PaymentDetails): MenuListItem => {
      const config = configPaymentDetailsByType[paymentDetails.type];

      const primaryFieldName = config.primaryDetailsField;
      const primaryValue = paymentDetails.details.find((i) => i.type === primaryFieldName)!.value;
      const parsedPrimaryValue = maskPaymentDetailsValue(paymentDetails.type, primaryValue);

      const secondaryValues = paymentDetails.details.filter(
        (i) => i.type !== primaryFieldName && !!i.value,
      );
      const secondaryValuesLabel = secondaryValues.map((i) => i.value).join(', ');
      const underComponent = <span className="label">{secondaryValuesLabel}</span>;

      const handler = () => {
        drawer.open(
          phonebookDrawerTemplates.addEditPaymentDetails({
            type: paymentDetails.type,
            counterpartyId: counterparty.id,
            paymentDetails,
            title: translate(config.editLabel),
          }),
        );
      };

      return {
        key: paymentDetails.id.toString(),
        label: parsedPrimaryValue,
        underLabelComponent: underComponent,
        startAdornment: getIconByPaymentDetails(paymentDetails),
        handler,
      };
    },
    [drawer, counterparty, translate],
  );

  const getAddMenuItemByPaymentDetailsType = useCallback(
    (type: PaymentDetails['type']): MenuListItem => {
      const config = configPaymentDetailsByType[type];

      const handler = () => {
        drawer.open(
          phonebookDrawerTemplates.addEditPaymentDetails({
            type,
            counterpartyId: counterparty.id,
            title: translate(config.addLabel),
          }),
        );
      };
      const endAdornment = <Icon name="plusBold" color="grey200" />;

      return {
        label: translate(config.addLabel),
        icon: config.addIconName,
        handler,
        endAdornment,
      };
    },
    [translate, drawer, counterparty],
  );

  const detailsList = useMemo<{ label: TranslationKey; details: MenuListItem[] }[]>(
    () =>
      Object.entries(paymentDetailsGroupedByType).map(([type, details]) => ({
        label: configPaymentDetailsByType[type as PaymentDetails['type']].label,
        details: details
          .map(getMenuItemByPaymentDetails)
          .concat(getAddMenuItemByPaymentDetailsType(type as PaymentDetails['type'])),
      })),
    [paymentDetailsGroupedByType, getMenuItemByPaymentDetails, getAddMenuItemByPaymentDetailsType],
  );

  const handleClickDelete = useCallback(() => {
    drawer.open(
      commonDrawerTemplates.confirmation({
        title: translate('PHONEBOOK_DELETE_COUNTERPARTY_TITLE'),
        description: translate('PHONEBOOK_DELETE_COUNTERPARTY_SUBTITLE'),
        imageProps: { name: 'cloud', path: 'common' },
        buttons: [
          {
            children: translate('YES'),
            variant: 'creamyBlack',
            handleLoading: true,
            onClick: async () => {
              const { success } = await dispatch(requestDeleteCounterparty(counterparty.id));
              if (success) {
                successToast(
                  translate('PHONEBOOK_COUNTERPARTY_DELETED', { name: counterparty.name }),
                );
                drawer.pop({ key: 'confirmation' });
                drawer.pop({ key: 'phonebookCounterparty', soft: false });
              }
            },
          },
          {
            children: translate('NO'),
            variant: 'creamyBlack',
            onClick: () => {
              drawer.pop();
            },
          },
        ],
      }),
    );
  }, [dispatch, drawer, translate, counterparty]);

  return (
    <div className="column gap-3 flex-1 pt-3">
      <DrawerHeader title={counterparty.name} showCloseButton={false} showBackButton />
      <div className={classes.body}>
        <div className={classes.counterpartyInfo}>
          <div className={classes.avatar}>
            <div className={classes.edit} onClick={editCounterparty}>
              <Icon name="pencil" size={12} />
            </div>
            <span>
              {fName[0]}
              {lName?.[0]}
            </span>
          </div>
          <div className={classes.name}>
            <span>{counterparty.name}</span>
            <span>{counterparty.email}</span>
          </div>
          {counterparty.isNebeusUser && (
            <Mark variant="greenOutlined">{translate('NEBEUS_USER')}</Mark>
          )}
        </div>
        <div className={classes.details}>
          {detailsList.map((detailsItem) => (
            <div key={detailsItem.label} className="column gap-1-5">
              <span className="label">{translate(detailsItem.label)}</span>
              <div className="column gap-1">
                {paymentDetailsLoading && <Skeletons />}
                <MenuList menu={detailsItem.details} />
              </div>
            </div>
          ))}
          <Button onClick={handleClickDelete}>{translate('PHONEBOOK_DELETE_COUNTERPARTY')}</Button>
        </div>
      </div>
    </div>
  );
};

export default Counterparty;
