import { FC, useEffect } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';

import { MenuList, MenuListItem } from 'components/ui/MenuList';

export interface MenuListSidebarViewProps {
  menu: MenuListItem[];
}
const MenuListSidebarView: FC<MenuListSidebarViewProps> = ({ menu }) => {
  const drawer = useDrawer();

  useEffect(() => {
    const handlerDoesntExist = !!menu.find((i) => !i.handler);
    if (handlerDoesntExist) {
      drawer.pop({ soft: false });
    }
    // eslint-disable-next-line
  }, []);
  return <MenuList className="mt-1" menu={menu} />;
};

export default MenuListSidebarView;
