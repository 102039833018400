import clsx from 'clsx';

import { FC } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import RateApp from 'modules/app/views/RateApp';

import { AmountLabel, Button, CurrencyIcon, Image, SummaryCard } from 'components/ui';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { useTranslation } from 'libs/i18n';

import { formatDDMMYY_HHMM } from 'utils/date';

import { CurrencyCode } from 'types';

import classes from './FinishedOperation.module.scss';

export interface FinishedOperationProps {
  currencyCode: CurrencyCode;
  amount: number;
  title?: string;
  description: string;
  isDeposit: boolean;
  isSuccess: boolean;
  navigationHeaderLabel?: string;

  summaryBlocks: SummaryCardItem[][];
}
const FinishedOperation: FC<FinishedOperationProps> = ({
  currencyCode,
  amount,
  description,
  isSuccess,
  isDeposit,
  summaryBlocks,
  title: titleFromProps,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const title =
    titleFromProps ||
    translate(isDeposit ? 'DEPOSIT_CURRENCY' : 'SEND_CURRENCY', {
      currencyLabel: currencyCode,
    });

  return (
    <div className="mt-1 column gap-3 flex-1 jcsb">
      <div className="column flex-1 gap-3">
        <div className="creamyCard column aic gap-3">
          <CurrencyIcon
            code={currencyCode}
            size={72}
            adornment={
              <Image
                className={classes.operationStatusCurrencyIcon}
                name={isSuccess ? 'circleSuccess' : 'circleFailed'}
                type="svg"
                path="common"
              />
            }
          />
          <div className={classes.cardInfo}>
            <h5>{title}</h5>
            <AmountLabel
              amount={amount}
              currencyCode={currencyCode}
              showPlusOrMinus
              showCurrencySymbol
              className={clsx(!isSuccess && classes.amountLabelFailed)}
            />
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <span className="label">{formatDDMMYY_HHMM(new Date())}</span>
        </div>

        {isSuccess && <RateApp />}

        <div className="column gap-3 flex-1">
          <div className="row gap-3 jcsb">
            <span className="label">{translate('ACCOUNTS_OPERATION_DETAILS')}</span>
            {/*<span className="label cyanBlue pointer">{translate('ACCOUNTS_GET_A_RECEIPT')}</span>*/}{' '}
            {/*// TODO: GET A RECEIPT*/}
          </div>
          <div className={classes.summary}>
            {summaryBlocks.map((block, index) => (
              <SummaryCard key={index} variant="borderless" config={block} />
            ))}
          </div>
        </div>
      </div>
      <div className="row gap-2">
        <Button
          fullWidth
          variant={isSuccess ? 'greyishGreen' : 'darkGreyOutlined'}
          onClick={isSuccess ? () => drawer.pop() : drawer.close}
        >
          {translate('CLOSE')}
        </Button>
        {!isSuccess && (
          <Button fullWidth onClick={() => drawer.pop()}>
            {translate('ERROR_TRY_AGAIN')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FinishedOperation;
