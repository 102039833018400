import clsx from 'clsx';

import { FC, ReactElement, ReactNode } from 'react';

import { voidFunc } from 'types';

import classes from './ButtonGroup.module.scss';

export interface ButtonGroupItem {
  children: ReactElement | ReactNode;
  onClick: voidFunc;
  id: string;
  disabled?: boolean;
}
interface ButtonGroupProps {
  buttons: ButtonGroupItem[];
  className?: string;
  activeButtonIndex: number;
  activeButtonClassName?: string;
  buttonClassName?: string;
  sliderClassName?: string;
  separated?: boolean;
  variant?: 'creamyGreenWithDots' | 'darkGreen' | 'creamyGreen';
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
  className,
  separated,
  buttons,
  activeButtonIndex,
  activeButtonClassName,
  buttonClassName,
  sliderClassName,
  variant = 'darkGreen',
}) => {
  return (
    <div
      className={clsx(
        classes.root,
        classes['variant-' + variant],
        separated && classes.separated,
        className,
      )}
    >
      <div
        className={clsx(classes.slider, sliderClassName)}
        style={{
          width: separated
            ? `calc((100% - 16px - ${(buttons.length - 1) * 8}px) / ${buttons.length})`
            : `${100 / buttons.length}%`,
          transform: separated
            ? `translateX(calc(${activeButtonIndex * 100}% + ${8 * activeButtonIndex - 1}px))`
            : `translateX(${activeButtonIndex * 100}%)`,
        }}
      />

      {buttons.map((b, index) => (
        <div
          onClick={b.disabled ? undefined : b.onClick}
          key={b.id}
          className={clsx(
            classes.button,
            buttonClassName,
            activeButtonIndex === index && classes.active,
            activeButtonIndex === index && activeButtonClassName,
            b.disabled && classes.disabled,
          )}
        >
          {b.children}
          <div className={clsx(classes.dot, activeButtonIndex === index && classes.active)} />
        </div>
      ))}
    </div>
  );
};
