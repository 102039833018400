import clsx from 'clsx';

import { memo, useMemo } from 'react';

import { CryptoNetworkId, PaymentOperationId } from 'modules/payment/types';
import { getCommissionLabel } from 'modules/payment/utils/getCommissionLabel';
import useCommissionRule from 'modules/payment/views/components/AmountForm/hooks/useCommissionRule';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './CommissionLabel.module.scss';

export interface CommissionLabelProps {
  operationId: PaymentOperationId;
  currencyCode: CurrencyCode;
  commissionAmount?: number | null;
  className?: string;
  feeFreeClassName?: string;
  cryptoNetwork?: CryptoNetworkId;
}
export const CommissionLabel = memo<CommissionLabelProps>(
  ({ operationId, currencyCode, commissionAmount, feeFreeClassName, className, cryptoNetwork }) => {
    const translate = useTranslation();

    const rule = useCommissionRule(operationId, currencyCode, cryptoNetwork);

    const feeFree = rule && !rule.fixed && !rule.minFixed && !rule.percent;

    const label = useMemo(() => {
      if (!rule) {
        return '';
      }
      if (!rule.fixed && !rule.minFixed && !rule.percent) {
        return translate('FREE');
      }
      let result = translate('FEE') + ': ';

      const showCalculatedCommissionAmount =
        !(rule.fixed && !rule.minFixed && !rule.percent) && !!commissionAmount;

      if (showCalculatedCommissionAmount) {
        result += `${formatCurrencyWithSymbol(commissionAmount, rule.currencyCode)} (`;
      }

      result += getCommissionLabel({
        minFixed: rule.minFixed,
        fixed: rule.fixed,
        percent: rule.percent,
        currencyCode: rule.currencyCode,
      });

      if (showCalculatedCommissionAmount) {
        result += ')';
      }

      return result;
    }, [commissionAmount, rule, translate]);

    return rule ? (
      <span className={clsx(classes.root, feeFree && feeFreeClassName, className)}>{label}</span>
    ) : null;
  },
);
