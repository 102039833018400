import clsx from 'clsx';

import { FC } from 'react';

import { Icon } from 'components/ui';

import eventEmitter from 'utils/eventEmitter';

import classes from './AuthBackButton.module.scss';

const onBack = () => {
  eventEmitter.emit('SIGN_SLIDER_GO_BACK');
};

interface AuthBackButtonProps {
  className?: string;
}

export const AuthBackButton: FC<AuthBackButtonProps> = ({ className }) => {
  return (
    <div onClick={onBack} className={clsx(classes.root, className)}>
      <Icon size={32} name="chevronRightSquaredThin" />
    </div>
  );
};
