import { createSelector } from '@reduxjs/toolkit';

import { selectIssuers } from 'modules/accounts/store/selectors';
import { AppStore } from 'store';

import { isFiat } from 'utils/currency';

export const selectIsAppInited = (state: AppStore) => state.app.inited;

export const selectAllowedCountriesReducer = (state: AppStore) => state.app.allowedCountries;
export const selectAllowedCountries = (state: AppStore) => state.app.allowedCountries.data;
export const selectAllowedCountriesMeta = (state: AppStore) => state.app.allowedCountries.meta;

export const selectSideBarStack = (state: AppStore) => state.app.sideBarStack;
export const selectSideBarStackKeys = (state: AppStore) => state.app.sideBarStack.map((i) => i.key);
export const selectSidebarOpenedKey = (state: AppStore) =>
  state.app.sideBarStack[state.app.sideBarStack.length - 1]?.key;

export const selectAllowedCurrencies = createSelector(selectIssuers, (issuers) =>
  issuers.map((i) => i.currencyCode),
);
export const selectAllowedCurrenciesSorted = createSelector(
  selectAllowedCurrencies,
  (currencies) => [
    ...currencies.filter((c) => isFiat(c)).sort(),
    ...currencies.filter((c) => !isFiat(c)).sort(),
  ],
);
export const selectCryptoCurrencies = createSelector(selectAllowedCurrencies, (currencies) =>
  currencies.filter((c) => !isFiat(c)),
);

export const selectBlogPostsDesktopReducer = (state: AppStore) => state.app.blogPosts.desktop;
export const selectBlogPostsMobileReducer = (state: AppStore) => state.app.blogPosts.mobile;

export const selectOperationCommissions = (state: AppStore) => state.app.operationsCommissions.data;
export const selectOperationCommissionsMeta = (state: AppStore) =>
  state.app.operationsCommissions.meta;

export const selectFirebaseConfig = (state: AppStore) => state.app.firebaseConfig;

export const selectAppLanguage = (state: AppStore) => state.app.language;
export const selectMaintenanceData = (state: AppStore) => state.app.maintenance;
