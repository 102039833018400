import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { AddEditCounterpartyProps } from 'modules/phonebook/views/AddEditCounterparty';
import { AddEditPaymentDetailsProps } from 'modules/phonebook/views/AddEditPaymentDetails';
import { CounterpartiesProps } from 'modules/phonebook/views/Counterparties';
import { CounterpartyProps } from 'modules/phonebook/views/Counterparty';

import { getTranslation } from 'libs/i18n';

export const PHONEBOOK_COUNTERPARTIES_HEADER_CLASSNAME = 'phonebookCounterpartiesHeader';
export const PHONEBOOK_COUNTERPARTIES_PAYMENT_DETAILS_HEADER_CLASSNAME =
  'phonebookCounterpartiesPaymentDetailsHeader';

const counterparties = (props?: CounterpartiesProps): DrawerTemplateReturnType => ({
  key: 'phonebookCounterparties',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('PHONEBOOK'),
    navigationHeaderProps: {
      className: `jcsb ${PHONEBOOK_COUNTERPARTIES_HEADER_CLASSNAME}`,
    },
  },
});
const addEditCounterparty = (props?: AddEditCounterpartyProps): DrawerTemplateReturnType => ({
  key: 'phonebookAddEditCounterparty',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation(
      props?.counterpartyForEdit ? 'PHONEBOOK_EDIT_COUNTERPARTY' : 'PHONEBOOK_ADD_COUNTERPARTY',
    ),
    swipeModal: true,
  },
});
const addEditPaymentDetails = (props: AddEditPaymentDetailsProps): DrawerTemplateReturnType => ({
  key: 'phonebookAddEditPaymentDetails',
  props,
  sideBarProps: {
    navigationHeaderLabel: props.title,
    navigationHeaderProps: {
      className: 'jcsb ' + PHONEBOOK_COUNTERPARTIES_PAYMENT_DETAILS_HEADER_CLASSNAME,
    },
    swipeModal: true,
  },
});
const counterparty = (props: CounterpartyProps): DrawerTemplateReturnType => ({
  key: 'phonebookCounterparty',
  props,
  sideBarProps: {
    showBackButton: false,
    withoutBottomPadding: true,
  },
});

const phonebookDrawerTemplates = {
  counterparties,
  addEditCounterparty,
  counterparty,
  addEditPaymentDetails,
};

export default phonebookDrawerTemplates;
