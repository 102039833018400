import { FC } from 'react';

import { InfoIcon, InfoIconProps } from 'components/ui/InfoIcon';

import { useTranslation } from 'libs/i18n';

import classes from './FixedField.module.scss';

interface FixedFieldProps {
  label: string;
  value: string;

  infoIcon?: InfoIconProps;
}
export const FixedField: FC<FixedFieldProps> = ({ label, value, infoIcon }) => {
  const translate = useTranslation();

  return (
    <div className={classes.root}>
      <div className="row gap-2 jcsb">
        <div className="row aic gap-1">
          <span className="label">{label}</span>
          {infoIcon && <InfoIcon size="xs" {...infoIcon} />}
        </div>
        <span className="label">{translate('FIXED')}</span>
      </div>
      <div className={classes.inputContainer}>
        <span>{value}</span>
      </div>
    </div>
  );
};
