import get from 'lodash/get';

type Replacer<Entity extends object, ResultEntity extends object> = [
  keyof Entity | string,
  (keyof ResultEntity | null)?,
  {
    convertToString?: boolean;
    convertToNumber?: boolean;
    uncapitalize?: boolean;
    custom?: (entityValue: Entity[keyof Entity], entity: Entity) => any;
  }?,
];

export const transformList = <Entity extends object, ResultEntity extends object>(
  list: Entity[],
  replacers: Array<Replacer<Entity, ResultEntity>>,
): ResultEntity[] =>
  // @ts-expect-error
  list.map<Entity>((entity) =>
    // @ts-expect-error
    replacers.reduce<Partial<ResultEntity>>((acc, replacer) => {
      const [from, to, options] = replacer;

      let newValue: any = get(entity, from);
      // @ts-expect-error
      let newKey: string = to || from;

      if (options) {
        if (options.convertToString) {
          newValue = `${newValue}`;
        }
        if (options.convertToNumber) {
          newValue = +newValue;
        }
        if (options.uncapitalize) {
          newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
        }
        if (options.custom) {
          newValue = options.custom(newValue, entity);
        }
      }

      return { ...acc, [newKey]: newValue };
    }, {}),
  );
