import { FieldRenderProps } from 'react-final-form';

import { AutoComplete, AutoCompleteProps } from 'components/ui/AutoComplete';

import useField from 'hooks/useField';

interface AutoCompleteFieldProps<T>
  extends FieldRenderProps<T>,
    Omit<AutoCompleteProps<T>, 'value' | 'onChange'> {}

export const AutoCompleteField = <T,>({
  input: { value, onChange, ...input },
  meta,
  ...props
}: AutoCompleteFieldProps<T>) => {
  const { hasError, error, valid } = useField(meta);

  return (
    <AutoComplete
      value={value}
      onChange={onChange}
      error={hasError && error}
      valid={valid}
      {...input}
      {...props}
    />
  );
};
