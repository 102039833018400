import useDrawer from 'modules/app/hooks/useDrawer';
import RateApp from 'modules/app/views/RateApp';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';

import { Button, Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './CryptoBankCardActivateSuccess.module.scss';

const CryptoBankCardActivateSuccess = () => {
  const translate = useTranslation();
  const drawer = useDrawer();

  return (
    <div className={classes.root}>
      <Icon name="checkInCircle" size={72} />
      <div className={classes.textContent}>
        <h3>{translate('CRYPTO_BANK_CARD_ACTIVATED_TITLE')}</h3>
        <p>{translate('CRYPTO_BANK_CARD_ACTIVATED_SUBTITLE')}</p>
      </div>
      <RateApp />
      <Button
        variant="greyishGreen"
        fullWidth
        className="mt-3"
        onClick={() => {
          drawer.replaceAll(commonDrawerTemplates.bankCards({ type: 'crypto' }));
        }}
      >
        {translate('GO_TO_CARD_SECTION')}
      </Button>
    </div>
  );
};

export default CryptoBankCardActivateSuccess;
