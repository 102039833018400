import clsx from 'clsx';

import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DigitalWallet, TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import { selectAllowedDirectionsReducer } from 'modules/exchange/store/selectors';
import { requestAllowedDirections } from 'modules/exchange/store/thunks';
import { PaymentOperationId } from 'modules/payment/types';
import { PaymentDetailsCard } from 'modules/payment/views/components/PaymentDetailsCard';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';

import { Button, CurrencyIcon, Icon } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode, FiatCurrencyCode } from 'types';

import classes from './AccountDetails.module.scss';

interface AccountDetailsProps {
  wallet: TradingWallet | DigitalWallet;
}

export const AccountDetails: FC<AccountDetailsProps> = ({ wallet }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();
  const drawer = useDrawer();

  const defaultCurrency = useSelector(selectUserDefaultCurrencyCode);
  const {
    entityReducer: { data: allowedExchangeDirections },
  } = useStoreEntity(selectAllowedDirectionsReducer, requestAllowedDirections);

  const allowedExchangePairCurrency = useMemo<CurrencyCode | null>(() => {
    if (defaultCurrency === wallet.currencyCode) {
      return 'BTC' as CurrencyCode;
    }
    const pairs = allowedExchangeDirections.filter((d) => d.startsWith(wallet.currencyCode));

    const canBeExchangedToDefaultCurrency = !!pairs.find((p) => p.endsWith(defaultCurrency));

    if (canBeExchangedToDefaultCurrency) {
      return defaultCurrency;
    }

    if (pairs.length) {
      const otherExchangeCurrency = pairs[0].split('/')[1] as CurrencyCode;
      return otherExchangeCurrency;
    }

    return null;
  }, [wallet.currencyCode, allowedExchangeDirections, defaultCurrency]);

  const isDigitalAccount = 'isDigitalWallet' in wallet;

  const operationButtons = useMemo(() => {
    const result = [
      {
        icon: 'arrowUp',
        label: translate('SEND'),
        handler: () => {
          sidebar.open(
            ...sidebarTemplates.paymentProcess({
              isDeposit: false,
              currencyCode: wallet.currencyCode,
              isDigitalAccount,
            }),
          );
        },
      },
      {
        icon: 'arrowDown',
        label: translate('DEPOSIT'),
        disabled: wallet.currencyCode === 'NBT',
        handler: () => {
          sidebar.open(
            ...sidebarTemplates.paymentProcess({
              currencyCode: wallet.currencyCode,
              isDigitalAccount,
            }),
          );
        },
      },
    ];

    if (!isDigitalAccount) {
      if (allowedExchangePairCurrency) {
        result.push({
          icon: 'refresh2',
          label: translate('EXCHANGE'),
          handler: () => {
            drawer.open(
              exchangeDrawerTemplates.exchange({
                from: { amount: '', currency: wallet.currencyCode },
                to: {
                  amount: '',
                  currency: allowedExchangePairCurrency,
                },
              }),
            );
          },
        });
      }
    }

    result.push({
      icon: 'doc',
      label: translate('ACCOUNT_STATEMENT'),
      handler: () => {
        sidebar.open(
          ...sidebarTemplates.accountStatement({
            currencyCode: wallet.currencyCode,
            isDigitalAccount,
          }),
        );
      },
    });

    return result;
  }, [allowedExchangePairCurrency, isDigitalAccount, translate, wallet, drawer, sidebar]);

  const mainWalletOperationId =
    wallet.currencyCode === 'GBP'
      ? PaymentOperationId.depositFromWireToMoneyWallet
      : PaymentOperationId.depositFromSepaToMoneyWallet;

  const openAccountDetails = useCallback(() => {
    sidebar.open(
      ...sidebarTemplates.accountDetails({
        operationId: mainWalletOperationId,
        currencyCode: wallet.currencyCode as FiatCurrencyCode,
      }),
    );
  }, [sidebar, wallet.currencyCode, mainWalletOperationId]);

  return (
    <div className="column mt-4 gap-2">
      <div className="outlinedCard row aic">
        <CurrencyIcon className="mr-1-5" size={44} code={wallet.currencyCode} />
        <div className="column">
          <span className={classes.primaryLabel}>
            {formatCurrencyWithLabel(wallet.amount, wallet.currencyCode)}
          </span>
          {wallet.amountInDefaultCurrency ? (
            <span className={classes.secondaryLabel}>
              {formatCurrencyWithSymbol(wallet.amountInDefaultCurrency, wallet.defaultCurrencyCode)}
            </span>
          ) : null}
        </div>
      </div>
      {isDigitalAccount && (
        <PaymentDetailsCard
          variant="short"
          operationId={mainWalletOperationId}
          currencyCode={wallet.currencyCode}
        >
          <Button onClick={openAccountDetails} fullWidth className="mt-3" variant="lightGreen">
            {translate('ACCOUNT_DETAILS')}
          </Button>
        </PaymentDetailsCard>
      )}
      <div className="row gap-2">
        {operationButtons.map((ob) => (
          <div
            onClick={ob.disabled ? undefined : ob.handler}
            key={ob.label}
            className={clsx(classes.operationButton, ob.disabled && classes.disabled)}
          >
            <Icon size={20} className={classes.icon} name={ob.icon} />
            <span>{ob.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
