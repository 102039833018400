interface FailureResponse {
  code: string;
  mdcId?: string;
  message?: string;
  status: 'fail';
}

class ApiError extends Error {
  error: FailureResponse;
  statusCode;

  constructor(message: string, error: FailureResponse, statusCode: number) {
    super();

    this.message = message;
    this.error = error;
    this.statusCode = statusCode;
  }
}

export default ApiError;
