import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import clsx from 'clsx';

import { FC, useMemo, useRef } from 'react';

import ConfirmOTPCard from 'modules/app/views/ConfirmOTPCodeCard';

import { Icon, ImportantCard, Loader, SummaryCard } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';
import { OtpForBankOperationRequest } from 'libs/swagger/nebeusApiTypes';

import { formatCurrencyWithSymbol } from 'utils/currency';

import classes from './ConfirmOTPCode.module.scss';

export interface ConfirmOTPCodeProps {
  onConfirmed: (code: string) => MaybePromise<boolean | void>;
  summaryBlocks: SummaryCardItem[][];
  importantLabel?: string;
  otpByEmail?: boolean;
  summary: { currencyAmount: CurrencyAmount; currencyAmountWithCommission?: CurrencyAmount };
  transactionType: OtpForBankOperationRequest['relatedEntityType'];
}

const ConfirmOTPCode: FC<ConfirmOTPCodeProps> = ({
  summary,
  summaryBlocks,
  onConfirmed,
  importantLabel,
  transactionType,
}) => {
  const translate = useTranslation();

  const summaryRef = useRef<HTMLDivElement>(null);

  const loading = useFlag(false);
  const summaryExpanded = useFlag(false);

  const recipientWillGetCurrencyAmount = summary.currencyAmountWithCommission?.amount
    ? summary.currencyAmountWithCommission
    : summary.currencyAmount;

  const sendOtpReqPayload = useMemo<OtpForBankOperationRequest>(
    () => ({
      amount: +summary.currencyAmount.amount,
      currencyCode: summary.currencyAmount.currency,
      relatedEntityType: transactionType,
    }),
    [summary, transactionType],
  );
  return (
    <div className="mt-1 relative column gap-3">
      <Loader overlap active={loading.state} />
      <div className="column gap-2">
        <span className="label">{translate('PAYMENT_RECIPIENT_WILL_GET')}</span>
        <div className="outlinedCard column">
          <div className="row gap-3 jcsb aic">
            <span className={classes.summaryTitle}>
              <b>
                {formatCurrencyWithSymbol(
                  recipientWillGetCurrencyAmount.amount,
                  recipientWillGetCurrencyAmount.currency,
                  { withZeros: true },
                )}
              </b>{' '}
              <span>
                {translate('PAYMENT_FOR', {
                  value: formatCurrencyWithSymbol(
                    summary.currencyAmount.amount,
                    summary.currencyAmount.currency,
                    { withZeros: true },
                  ),
                })}
              </span>
            </span>
            <Icon
              onClick={summaryExpanded.toggle}
              className={clsx(classes.chevron, summaryExpanded.state && classes.expanded)}
              name="chevronRightSquaredThin"
            />
          </div>
          <span className={classes.feesLabel}>{translate('ALL_FEES_INCLUDED')}</span>

          <div
            ref={summaryRef}
            className={classes.summary}
            style={{ height: summaryExpanded.state ? summaryRef.current?.scrollHeight : 0 }}
          >
            <div className={classes.inner}>
              {summaryBlocks.map((block, i) => (
                <SummaryCard key={i} config={block} variant="borderless" />
              ))}
            </div>
          </div>
        </div>
      </div>

      <ConfirmOTPCard
        sendOtpReqPayload={sendOtpReqPayload}
        loading={loading}
        onConfirmed={onConfirmed}
      />

      {importantLabel && <ImportantCard text={importantLabel} />}
    </div>
  );
};

export default ConfirmOTPCode;
