import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import authServices from 'modules/auth/services';

import appConfig from 'constants/appConfig';
import routesByName from 'constants/routesByName';

import { Button, CopyButton, MenuList } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import { getStringFromClipboard } from 'utils/common';

import classes from './AppPublicConfig.module.scss';

const AppPublicConfig = () => {
  const navigate = useNavigate();

  const pasteAccessTokenFromClipboard = useCallback(async () => {
    const token = await getStringFromClipboard();
    if (token) {
      authServices.setAccessToken(token);
      navigate(routesByName.dashboard);
    } else {
      window.alert('No token found in clipboard');
    }
  }, [navigate]);

  const menu: MenuListItem[] = [
    {
      description: 'Environment',
      label: appConfig.customEnvironment || 'NO ENV',
      endAdornment: <CopyButton value={appConfig.version} label="App version" />,
    },
    {
      description: 'App version',
      label: appConfig.version || 'NONE',
      endAdornment: <CopyButton value={appConfig.version} label="App version" />,
    },
    {
      description: 'Commit hash',
      label: appConfig.lastCommitHash || 'NONE',
      endAdornment: <CopyButton value={appConfig.lastCommitHash} label="Commit hash" />,
    },
    {
      label: 'Access token',
      endAdornment: <Button onClick={pasteAccessTokenFromClipboard}>Paste</Button>,
    },
  ];
  return (
    <div className={classes.root}>
      <h2 className="tac mb-2">⚙️ App config</h2>
      <MenuList menu={menu} />
    </div>
  );
};

export default AppPublicConfig;
