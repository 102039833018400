import IbanValidator from 'iban';

import { FC } from 'react';
import { Field } from 'react-final-form';

import { TextInputField } from 'components/form';

import { getTranslation } from 'libs/i18n';
import yup from 'libs/yup';

import { formatRemoveSpaces } from 'utils/formatters';

export const sepaDetailsFormSchema = yup.object().shape({
  accountName: yup
    .string()
    .requiredDefault()
    .matches(
      /^[aA-zZ\s]+$/,
      getTranslation('VALIDATION_ONLY_LETTERS', {
        fieldName: getTranslation('PAYMENT_ACCOUNT_NAME'),
      }),
    ),
  reference: yup
    .string()
    .optional()
    .matches(
      /^[a-zA-Z0-9 ]*$/,
      getTranslation('VALIDATION_ONLY_LETTERS_AND_NUMBERS', {
        fieldName: getTranslation('PAYMENT_REFERENCE'),
      }),
    )
    .min(8, getTranslation('VALIDATION_MIN', { min: 8 }))
    .max(140, getTranslation('VALIDATION_MAX', { max: 139 }))
    .requiredDefault(),
  iban: yup
    .string()
    .test({
      test: (value: string | undefined) => (value ? IbanValidator.isValid(value) : false),
      message: getTranslation('VALIDATION_INVALID_IBAN'),
    })
    .requiredDefault(),
});

export interface SEPADetailsFormValues {
  accountName: string;
  iban: string;
  reference: string;
}

export const SEPADetailsInitialValues = {
  accountName: '',
  iban: '',
  reference: '',
};
interface SEPADetailsFormProps {}
export const SEPADetailsForm: FC<SEPADetailsFormProps> = () => {
  return (
    <>
      <Field
        name="accountName"
        component={TextInputField}
        placeholder={getTranslation('PAYMENT_ACCOUNT_NAME')}
      />
      <Field
        name="iban"
        component={TextInputField}
        placeholder={getTranslation('PAYMENT_IBAN')}
        parse={formatRemoveSpaces}
      />
      <Field
        name="reference"
        component={TextInputField}
        placeholder={getTranslation('PAYMENT_REFERENCE')}
      />
    </>
  );
};
