import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';

import { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import useDrawer from 'modules/app/hooks/useDrawer';
import { CreateInvoiceFormValues } from 'modules/invoicing/views/CreateInvoice';
import {
  ClientDetailsForm,
  clientDetailsValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/ClientDetailsForm';
import {
  InvoiceDetailsForm,
  invoiceDetailsValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/InvoiceDetailsForm';
import InvoiceDiscountForm, {
  invoiceDiscountValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/InvoiceDiscountForm';
import InvoiceNotesForm, {
  invoiceNotesValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/InvoiceNotesForm';
import InvoiceServiceItemsForm, {
  invoiceServiceItemsValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/InvoiceServiceItemsForm';
import PaymentDetailsForm, {
  invoicePaymentValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/PaymentDetailsForm';
import { InvoiceStepConfigItem } from 'modules/invoicing/views/CreateInvoiceConfirmation';

import { SubmitButton } from 'components/form';

import { useTranslation } from 'libs/i18n';
import { makeValidate } from 'libs/yup';

import classes from './EditInvoiceStep.module.scss';

export interface EditInvoiceStepProps {
  stepName: string;
  stepId: InvoiceStepConfigItem['id'];
  values: Partial<CreateInvoiceFormValues>;
  onFinish: (values: CreateInvoiceFormValues) => MaybePromise<void>;
  topAdornment?: ReactElement;
}

const EditInvoiceStep: FC<EditInvoiceStepProps> = ({
  stepId,
  values: initialValues,
  topAdornment,
  onFinish,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const handleSubmit = useCallback(
    async (values: CreateInvoiceFormValues) => {
      await onFinish(values);
    },
    [onFinish],
  );

  useEffect(() => {
    if (!onFinish) {
      drawer.pop();
    }
    // eslint-disable-next-line
  }, []);

  const { validationSchema, formComponent: editStepFormComponent } = useMemo(() => {
    switch (stepId) {
      case 'client': {
        return {
          validationSchema: clientDetailsValidationSchema,
          formComponent: <ClientDetailsForm />,
        };
      }
      case 'details': {
        return {
          validationSchema: invoiceDetailsValidationSchema,
          formComponent: <InvoiceDetailsForm />,
        };
      }
      case 'items': {
        if (!initialValues.invoiceServiceItems) {
          return { validationSchema: null, formComponent: null };
        }
        return {
          validationSchema: invoiceServiceItemsValidationSchema,
          formComponent: <InvoiceServiceItemsForm />,
        };
      }
      case 'discount': {
        return {
          validationSchema: invoiceDiscountValidationSchema,
          formComponent: <InvoiceDiscountForm />,
        };
      }
      case 'notes': {
        return {
          validationSchema: invoiceNotesValidationSchema,
          formComponent: <InvoiceNotesForm />,
        };
      }
      case 'paymentDetails': {
        if (!initialValues.invoiceCurrency) {
          return { validationSchema: null, formComponent: null };
        }
        return {
          validationSchema: invoicePaymentValidationSchema,
          formComponent: <PaymentDetailsForm invoiceCurrency={initialValues.invoiceCurrency} />,
        };
      }
    }
    return { validationSchema: null, formComponent: null };
  }, [stepId, initialValues]);

  const formComponent = useMemo(() => {
    return ({ handleSubmit }: FormRenderProps<CreateInvoiceFormValues>) => {
      return (
        <form className="column gap-3" onSubmit={handleSubmit}>
          {editStepFormComponent}
          <SubmitButton fullWidth>{translate('SAVE_CHANGES')}</SubmitButton>
        </form>
      );
    };
  }, [translate, editStepFormComponent]);

  const validate = useMemo(
    () => (validationSchema ? makeValidate(validationSchema) : () => ({})),
    [validationSchema],
  );

  return validationSchema && formComponent ? (
    <div className={classes.root}>
      {topAdornment}
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={formComponent}
        validate={validate}
      />
    </div>
  ) : null;
};

export default EditInvoiceStep;
