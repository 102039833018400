import clsx from 'clsx';

import { FC } from 'react';

import useRate from 'modules/exchange/hooks/useRate';
import { SmartTrade } from 'modules/smartTrader/types';

import { Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel } from 'utils/currency';

import classes from './SmartTraderSummaryCard.module.scss';

export interface SmartTraderSummaryCardProps {
  trade: Pick<
    SmartTrade,
    | 'primaryCurrency'
    | 'secondaryCurrency'
    | 'primaryAmount'
    | 'secondaryAmount'
    | 'takeProfitAmount'
    | 'stopLossAmount'
  >;
}

export const SmartTraderSummaryCard: FC<SmartTraderSummaryCardProps> = ({ trade }) => {
  const translate = useTranslation();

  const rate = useRate(trade.primaryCurrency, trade.secondaryCurrency);

  const targetProfitAmount = trade.takeProfitAmount - trade.secondaryAmount;
  const targetProfitAmountInPrimaryCurrency = targetProfitAmount * (1 / rate);

  const takeProfitInPrimaryCurrency = trade.takeProfitAmount * (1 / rate);

  const maxLossAmount = trade.secondaryAmount - trade.stopLossAmount;
  const maxLossAmountInPrimaryCurrency = maxLossAmount * (1 / rate);
  const stopLossAmountInPrimaryCurrency = trade.stopLossAmount * (1 / rate);

  const takeProfitPercent = +((trade.takeProfitAmount / trade.secondaryAmount) * 100 - 100).toFixed(
    2,
  );

  const stopLossPercent = +Math.abs(
    (trade.stopLossAmount / trade.secondaryAmount) * 100 - 100,
  ).toFixed(2);

  return (
    <div className="creamyCard gap-4">
      <div className="row gap-1 aifs">
        <Icon name="arrowUpSafe" color="green" />
        <div className="column gap-2 flex-1">
          <div className="row flex-1 jcsb">
            <div className="column gap-0-5">
              <span className={classes.profitOrLossLabel}>
                {translate('SMART_TRADER_TARGET_PROFIT')}
              </span>
              <span className={classes.amountInSecondary}>
                {formatCurrencyWithLabel(targetProfitAmount, trade.secondaryCurrency)}
              </span>
            </div>
            <div className="column gap-0-5 tar">
              <span className={classes.profitOrLossLabel}>+{takeProfitPercent}%</span>
              <span className={classes.value}>
                {formatCurrencyWithLabel(
                  targetProfitAmountInPrimaryCurrency,
                  trade.primaryCurrency,
                )}
              </span>
            </div>
          </div>
          <div className="column">
            <span className="label">{translate('SMART_TRADER_TAKE_PROFIT')}</span>
            <div className="row jcsb mt-0-5">
              <span className={classes.value}>
                {formatCurrencyWithLabel(trade.takeProfitAmount, trade.secondaryCurrency)}
              </span>
              <span className={classes.value}>
                {formatCurrencyWithLabel(takeProfitInPrimaryCurrency, trade.primaryCurrency)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row gap-1 aifs mt-4">
        <Icon name="arrowDownSafe" color="red" />
        <div className="column gap-2 flex-1">
          <div className="row flex-1 jcsb">
            <div className="column gap-0-5">
              <span className={clsx(classes.profitOrLossLabel, classes.loss)}>
                {translate('SMART_TRADER_MAX_LOSS')}
              </span>
              <span className={classes.amountInSecondary}>
                {formatCurrencyWithLabel(maxLossAmount, trade.secondaryCurrency)}
              </span>
            </div>
            <div className="column gap-0-5 tar">
              <span className={clsx(classes.profitOrLossLabel, classes.loss)}>
                -{stopLossPercent}%
              </span>
              <span className={classes.value}>
                {formatCurrencyWithLabel(maxLossAmountInPrimaryCurrency, trade.primaryCurrency)}
              </span>
            </div>
          </div>
          <div className="column">
            <span className="label">{translate('SMART_TRADER_STOP_LOSS')}</span>
            <div className="row jcsb mt-0-5">
              <span className={classes.value}>
                {formatCurrencyWithLabel(trade.stopLossAmount, trade.secondaryCurrency)}
              </span>
              <span className={classes.value}>
                {formatCurrencyWithLabel(stopLossAmountInPrimaryCurrency, trade.primaryCurrency)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
