import clsx from 'clsx';

import { FC, SyntheticEvent, useCallback } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';

import { Tooltip } from 'components/ui';

import useAnchor from 'hooks/useAnchor';
import useTablet from 'hooks/useTablet';

import { Size, voidFunc } from 'types';

import classes from './InfoIcon.module.scss';

export interface InfoIconProps extends Partial<AnswerLayoutProps> {
  title?: string;
  symbol?: string;
  description?: string;
  className?: string;
  size?: Size;
  showInSidebar?: boolean;
  onClick?: voidFunc;
}

interface AnswerLayoutProps {
  title?: string;
  description?: string;
  stepsConfig?: {
    title?: string;
    steps: string[];
  };
}

const AnswerLayout: FC<AnswerLayoutProps> = ({ title, description, stepsConfig }) => {
  return (
    <div className={classes.content}>
      {title && <h5 className={classes.title}>{title}</h5>}
      {description && (
        <p
          className={title ? 'mt-1' : undefined}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {stepsConfig && (
        <div className="mt-3">
          {stepsConfig.title && <span className="label">{stepsConfig.title}</span>}
          <div className="mt-2 gap-2 column">
            {stepsConfig.steps.map((text, index) => (
              <div key={text} className="row gap-1-5">
                <span className={classes.stepNumber}>{index + 1}.</span>
                <p>{text}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const InfoIcon: FC<InfoIconProps> = ({
  className,
  title,
  description,
  stepsConfig,
  size = 'md',
  symbol = '?',
  showInSidebar = true,
  onClick,
}) => {
  const [infoIconRef, setInfoIconRef] = useAnchor();

  const sidebar = useSideBar();

  const isTablet = useTablet();

  const showTooltip = !showInSidebar && !isTablet && (title || description);

  const handleClick = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      onClick?.();
      e.stopPropagation();
      if (!showTooltip && (title || description)) {
        sidebar.open(...sidebarTemplates.answer({ title, description, stepsConfig }));
      }
    },
    [onClick, sidebar, showTooltip, title, description, stepsConfig],
  );

  return (
    <>
      <div
        onClick={handleClick}
        ref={setInfoIconRef}
        className={clsx(
          classes.root,
          className,
          classes['size-' + size],
          (showTooltip || (showInSidebar && title) || onClick) && classes.canBeClicked,
        )}
      >
        <span>{symbol}</span>
      </div>
      {showTooltip ? (
        <Tooltip anchorEl={infoIconRef}>
          <AnswerLayout title={title} description={description} stepsConfig={stepsConfig} />
        </Tooltip>
      ) : null}
    </>
  );
};
