import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { PinCodeInput } from 'components/ui';
import { PinCodeInputProps } from 'components/ui/PinCodeInput';

interface PinCodeFieldProps extends FieldRenderProps<string>, PinCodeInputProps {}

// USE WITH parse={parseFixEmptyString}
export const PinCodeField: FC<PinCodeFieldProps> = ({ input, meta, ...props }) => {
  const showError = meta.submitError && !meta.dirtySinceLastSubmit;

  return <PinCodeInput {...input} {...props} error={showError ? meta.submitError : undefined} />;
};
