import { createSlice } from '@reduxjs/toolkit';

import {
  generateDefaultReducer,
  generateRequestDataReducer,
  generateRequestDirectionReducer,
} from 'store/generators/reducers';
import { initialArrayApiRequestState } from 'store/generators/utils';

import { CurrenciesDirection, CurrencyCode } from 'types';

import {
  ExchangeRateData,
  ExchangeState,
  GetRatesHistoryAggregatedApiResponse,
  Rates,
} from '../types';
import { RatesHistory } from '../types';

// cli-import

const initialState: ExchangeState = {
  rates: {},
  ratesHistory: {},
  ratesAggregatedHistory: {},
  allowedDirections: initialArrayApiRequestState,
  followedCurrencies: initialArrayApiRequestState,
  // cli-state
};

const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {
    updateRates: generateRequestDirectionReducer<ExchangeState, ExchangeRateData>({
      reducerPath: 'rates',
    }),
    updateRatesBatched: generateDefaultReducer<ExchangeState, Rates>({
      reducerPath: 'rates',
      withPrev: true,
    }),

    updateRatesHistory: generateDefaultReducer<ExchangeState, RatesHistory>({
      reducerPath: 'ratesHistory',
      withPrev: true,
    }),
    updateAggregatedRatesHistory: (
      state: ExchangeState,
      { payload }: { payload: GetRatesHistoryAggregatedApiResponse },
    ) => {
      const direction = `${payload.from}/${payload.to}` as CurrenciesDirection;
      state.ratesAggregatedHistory[direction] = {
        ...state.ratesAggregatedHistory[direction],
        [payload.unit]: payload.rates,
      };
    },

    updateAllowedDirections: generateRequestDataReducer<ExchangeState, CurrenciesDirection[]>({
      reducerPath: 'allowedDirections',
    }),
    updateFollowedCurrencies: generateRequestDataReducer<ExchangeState, CurrencyCode[]>({
      reducerPath: 'followedCurrencies',
    }),
    resetState: (state) => {
      state = initialState;
      return state;
    },
    // cli-reducer
  },
});

export default exchangeSlice.reducer;
export const exchangeActions = exchangeSlice.actions;
