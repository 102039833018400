import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ActiveHistory } from 'modules/loans/views/Loans/tabs/ActiveHistory';
import { New } from 'modules/loans/views/Loans/tabs/New';

import routesByName from 'constants/routesByName';
import { scrollViewSelector } from 'constants/viewConstants';

import { ButtonGroup } from 'components/ui';
import { ButtonGroupItem } from 'components/ui/ButtonGroup';

import useMobile from 'hooks/useMobile';

import { useTranslation } from 'libs/i18n';

import classes from './Loans.module.scss';

const buttonsIndexByTab = {
  new: 0,
  active: 1,
  history: 2,
};

type Tab = 'new' | 'active' | 'history';

interface TabProps {
  active: boolean;
}

const componentsByTab: { [key in Tab]: FC<TabProps> } = {
  new: New,
  history: ActiveHistory,
  active: ActiveHistory,
};

const Loans = () => {
  const navigate = useNavigate();

  const translate = useTranslation();

  const { tab = 'new' } = useParams();

  const isMobile = useMobile();

  const scrollDocumentToTopOnMobile = useCallback(() => {
    if (isMobile) {
      document.querySelector(scrollViewSelector)?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isMobile]);

  const buttons = useMemo<ButtonGroupItem[]>(
    () => [
      {
        id: '1',
        children: translate('NEW'),
        onClick: () => {
          scrollDocumentToTopOnMobile();
          navigate(routesByName.loans('new'));
        },
      },
      {
        id: '2',
        children: translate('ACTIVE'),
        onClick: () => {
          scrollDocumentToTopOnMobile();

          navigate(routesByName.loans('active'));
        },
      },
      {
        id: '3',
        children: translate('HISTORY'),
        onClick: () => {
          scrollDocumentToTopOnMobile();

          navigate(routesByName.loans('history'));
        },
      },
    ],
    [scrollDocumentToTopOnMobile, translate, navigate],
  );

  const TabComponent = useMemo(() => componentsByTab[tab as Tab], [tab]);

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>{translate('LOANS_TITLE')}</h2>
      <div className={classes.buttonsContainer}>
        <ButtonGroup
          className={classes.buttonGroup}
          activeButtonIndex={buttonsIndexByTab[tab as Tab]}
          buttons={buttons}
          variant="creamyGreen"
        />
      </div>
      {TabComponent && <TabComponent key={tab} active={tab === 'active'} />}
    </div>
  );
};

export default Loans;
