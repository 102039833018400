export enum AnalyticEvents {
  pageChanged = 'Open page',
  // sidebarOpen = 'Open sidebar',

  appNetworkError400 = 'Network error(400)',
  appNetworkError500 = 'Network error(500)',
  appNetworkErrorNoStatus = 'Network error(No status)',
  appErrorCaptured = 'App caught error',
  rateApp = 'App: rating',

  loanCreate = 'Loan: create',
  loadAddCollateral = 'Loan: add collateral',
  loanManualPayment = 'Loan: manual repayment',

  rentingCreate = 'Renting: create',

  stakingStake = 'Staking: stake',
  stakingUnstake = 'Staking: unstake',

  exchangeProceed = 'Exchange: exchange funds',

  vaultDeposit = 'Vault: deposit',
  vaultWithdraw = 'Vault: withdraw',

  bankCardCreate = 'Bank card: create',
  bankCardFreeze = 'Bank card: freeze',
  bankCardUnfreeze = 'Bank card: unfreeze',
  bankCardTerminate = 'Bank card: terminate',

  digitalSubscriptionPurchase = 'Digital subscription: purchase',
  digitalSubscriptionCancel = 'Digital subscription: cancel',

  accountsCreateTradingWallet = 'Accounts: create trading wallet',
  accountsCreateDigitalWallet = 'Accounts: create money(modulR,digital) wallet',
  accountsBalances = 'Accounts: balance monitoring',

  authSignInOtpSent = 'Auth: login OTP sent to email',
  authSignInWithOtp = 'Auth: login with OTP',
  authSignInWithTfa = 'Auth: login with TFA',
  authEmergencyResetTFA = 'Auth: emergency reset TFA',
  authSignUp = 'Auth: register with otp',
  authSignUpStepChanged = 'Auth: sign up step changed',

  paymentSendToExternalCryptoWallet = 'Payment: send to external crypto wallet',
  paymentSendFromDigitalToCrypto = 'Payment: send from money(modulR, digital) wallet to trading',
  paymentSendFromCryptoToDigital = 'Payment: send from trading wallet to money(modulR, digital)',
  paymentSendToNebeusUser = 'Payment: send to Nebeus user',
  paymentSendFromDigitalToSepaOrWire = 'Payment: send from money(modulR, digital) wallet to SEPA or Wire(GBP)',
  paymentSendFromTradingToSepaOrWire = 'Payment: send from trading wallet to SEPA or Wire(GBP)',
  paymentDepositFromBankCardToTradingWallet = 'Payment: deposit from bank card to trading wallet',
  paymentSendFromTradingWalletToBankCard = 'Payment: send from trading wallet to bank card',

  smartTraderCreate = 'Smart trader: create trade',

  userChangeDefaultCurrencyOrLanguage = 'User: change default currency or language',
  userSetTFA = 'User: enable TFA',
  userDisableTFA = 'User: disable TFA',
  closeUserAccount = 'User: close account request',
  userSetOtpCodeByEmail = 'User: set otp code by email',
  userUnsetOtpCodeByEmail = 'User: set otp code by phone',

  userSuccessfullyRequestIbanAccount = 'User: request iban account (Standard subscription) successfully',
  userVerificationStatusChanged = 'User: verification status changed',
  userVerificationSDKError = 'User: verification SDK error',

  cryptoBankCardOrder = 'Crypto bank card: order',
  cryptoBankCardOrderVirtual = 'Crypto bank card: order virtual',

  cryptoBankCardOrderVirtualFromOnboarding = 'Crypto bank card: order virtual from onboarding',
  cryptoBankCardOrderPhysicalFromOnboarding = 'Crypto bank card: order physical from onboarding',

  cryptoBankCardReadOrderNotification = 'Crypto bank card: read order notification',
  cryptoBankCardActivate = 'Crypto bank card: activate',
  cryptoBankCardClose = 'Crypto bank card: close bank card',
  cryptoBankCardFreeze = 'Crypto bank card: freeze',
  cryptoBankCardUnfreeze = 'Crypto bank card: unfreeze',
  cryptoBankCardLink = 'Crypto bank card: link wallet',

  pressedAgreementText = 'Press: Agreement Text',

  invoicingCreateInvoice = 'Invoicing: Create invoice',

  onboardingCardOfferReject = 'Onboarding: card offer reject',
  onboardingCardOfferAcceptedAndMovedOn = 'Onboarding: card offer accepted (moved on)',
  onboardingPhoneOtpSent = 'Onboarding: phone otp sent',
  onboardingPhoneOtpVerify = 'Onboarding: phone otp verify',

  tempLog = 'Temporary log',
}
