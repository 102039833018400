import axios, { AxiosResponse } from 'axios';

import {
  AllowedCountriesResponse,
  transformAllowedCountries,
  transformBlogPosts,
  transformOperationCommissions,
} from 'modules/app/services/transformers';
import {
  AllowedCountry,
  BlogPost,
  BlogPostApiItem,
  BlogPostsCategory,
  Maintenance,
  OperationCommission,
} from 'modules/app/types';

import appConfig from 'constants/appConfig';

import AxiosInstance from 'libs/axios/AxiosInstance';
import MaintenanceAxiosInstance from 'libs/axios/MaintenanceAxiosInstance';
import {
  OperationCommissionResponseList,
  OtpCodeResponse,
  OtpForBankOperationRequest,
} from 'libs/swagger/nebeusApiTypes';

// cli-import

const appServices = {
  getAllowedCountries: (): Promise<AllowedCountry[]> =>
    AxiosInstance.get<AllowedCountriesResponse, AllowedCountriesResponse>(
      '/v1/allowed_countries',
    ).then(transformAllowedCountries),
  sendOtpCode: (payload: OtpForBankOperationRequest) =>
    AxiosInstance.post('/v1/otp-code/send', payload),
  verifyOtpCode: (code: string): Promise<OtpCodeResponse> =>
    AxiosInstance.get('/v1/otp-code/verify', { params: { otpCode: code } }),
  getBlogPosts: (category: BlogPostsCategory): Promise<BlogPost[]> =>
    axios
      .get<AxiosResponse<BlogPostApiItem[]>, AxiosResponse<BlogPostApiItem[]>>(
        'https://api.getbeamer.com/v0/posts?published=true&archived=false&expired=false&category=' +
          category,
        {
          headers: { 'beamer-api-key': appConfig.beamerApiKey },
        },
      )
      .then(transformBlogPosts),
  getOperationsCommissions: (): Promise<OperationCommission[]> =>
    AxiosInstance.get<OperationCommissionResponseList, OperationCommissionResponseList>(
      '/v1/operation/commission',
    ).then(transformOperationCommissions),

  getMaintenance: (): Promise<Maintenance> => MaintenanceAxiosInstance.get('/maintenance'),
  // cli-service
};

export default appServices;
