import { getTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

const schema = yup.object().shape({
  code: yup
    .string()
    .required(getTranslation('VALIDATION_REQUIRED'))
    .length(6, getTranslation('VALIDATION_LENGTH', { length: 6 })),
});
export const validate = makeValidate(schema);
