import { FC } from 'react';

import classes from './ProgressBar.module.scss';

interface ProgressBarProps {
  minValue: number;
  maxValue: number;
  value: number;
  percent?: number;
}
export const ProgressBar: FC<ProgressBarProps> = ({
  minValue,
  maxValue,
  value,
  percent: percentFromProps,
}) => {
  const percent = percentFromProps || (value = ((value - minValue) / (maxValue - minValue)) * 100);

  return (
    <div className={classes.root}>
      <div className={classes.progress} style={{ width: `${percent}%` }} />
    </div>
  );
};
