import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { SliderInput } from 'components/ui';
import { SliderInputProps } from 'components/ui/SliderInput';

import useField from 'hooks/useField';

interface SliderFieldProps
  extends FieldRenderProps<number>,
    Omit<SliderInputProps, 'value' | 'onChange'> {}

export const SliderField: FC<SliderFieldProps> = ({ input, meta, ...props }) => {
  const { hasError, error, valid } = useField(meta);

  return <SliderInput error={hasError && error} valid={valid} {...input} {...props} />;
};
