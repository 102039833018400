import clsx from 'clsx';

import { FC } from 'react';

import { useTranslation } from 'libs/i18n';

interface NoResultLabelProps {
  findString: string;
  className?: string;
}
export const NoResultLabel: FC<NoResultLabelProps> = ({ findString, className }) => {
  const translate = useTranslation();
  return (
    <span className={clsx(className, 'filledError')}>
      {translate('NO_RESULTS_FOR')} &quot;{findString}&quot;
    </span>
  );
};
