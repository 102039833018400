// import loansServicesMocked from 'modules/loans/services/mocked';
import {
  transformCollateralHistory,
  transformLoans,
  transformNotifications,
  transformOutstandingBalance,
  transformPaymentHistory,
  transformStatistic,
  transformTemplates,
} from 'modules/loans/services/transformers';
import {
  LoanCollateralHistoryItem,
  LoanItem,
  LoanNotification,
  LoanPaymentHistoryItem,
  LoanTemplate,
  OutstandingBalanceDetails,
  Statistic,
} from 'modules/loans/types';
import { ListFilterReqPayload } from 'store/generators/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  LoanCollateralPaymentResponseList,
  LoanNotificationResponseList,
  LoanOutstandingBalanceResponse,
  LoanPaymentResponseList,
  LoanRequest,
  LoanResponse,
  LoanResponseList,
  LoanStatisticResponse,
  LoanTemplateResponseList,
} from 'libs/swagger/nebeusApiTypes';

// cli-import

const loansServices = {
  createLoan: (payload: LoanRequest): Promise<LoanItem> =>
    AxiosInstance.post<LoanResponse, LoanResponse>('/v1/loan/init', payload).then(
      (response) => transformLoans([response.loanInfo])[0],
    ),
  getActiveLoans: ({ filter }: { filter: ListFilterReqPayload }): Promise<LoanItem[]> =>
    AxiosInstance.get<LoanResponseList, LoanResponseList>('/v1/loan/active', {
      params: filter,
    }).then((response) => transformLoans(response.loans)),
  getHistoryLoans: ({ filter }: { filter: ListFilterReqPayload }): Promise<LoanItem[]> =>
    AxiosInstance.get<LoanResponseList, LoanResponseList>('/v1/loan/completed', {
      params: filter,
    }).then((response) => transformLoans(response.loans)),

  getStatistic: (): Promise<Statistic> =>
    AxiosInstance.get<LoanStatisticResponse, LoanStatisticResponse>('/v1/loan/statistic').then(
      transformStatistic,
    ),
  getPaymentsHistory: (loanId: number): Promise<LoanPaymentHistoryItem[]> =>
    AxiosInstance.get<LoanPaymentResponseList, LoanPaymentResponseList>(
      `/v1/loan/${loanId}/payment`,
    ).then(transformPaymentHistory),

  getCollateralHistory: (loanId: number): Promise<LoanCollateralHistoryItem[]> =>
    AxiosInstance.get<LoanCollateralPaymentResponseList, LoanCollateralPaymentResponseList>(
      `/v1/loan/${loanId}/collateral/payment`,
    ).then(transformCollateralHistory),

  getLoanById: (loanId: number): Promise<LoanItem> =>
    AxiosInstance.get('/v1/loans/loan', { params: { loanId } }),

  getOutstandingBalanceDetails: async (loanId: number): Promise<OutstandingBalanceDetails> =>
    AxiosInstance.get<LoanOutstandingBalanceResponse, LoanOutstandingBalanceResponse>(
      `/v1/loan/${loanId}/outstanding-balance-details`,
    ).then(transformOutstandingBalance),

  repayLoan: async ({
    loanId,
    amount,
    isFull,
  }: {
    loanId: number;
    amount: number;
    isFull: boolean;
  }) =>
    AxiosInstance.post(`/v1/loan/repayment/${loanId}/repay`, {
      amountToRepay: amount,
      fullRepayment: isFull,
    }),
  addCollateral: async ({
    amount,
    loanId,
  }: {
    amount: number;
    loanId: number;
  }): Promise<LoanItem> =>
    AxiosInstance.post<LoanResponse, LoanResponse>(`/v1/loan/collateral/${loanId}/add`, {
      collateralToAdd: amount,
    }).then((response) => transformLoans([response.loanInfo])[0]),

  getNotifications: (): Promise<LoanNotification[]> =>
    AxiosInstance.get<LoanNotificationResponseList, LoanNotificationResponseList>(
      '/v1/loan/notification',
    ).then(transformNotifications),

  readNotification: ({ loanId }: { loanId: number }) =>
    AxiosInstance.get(`/v1/loan/${loanId}/notification/read`),

  getTemplates: (): Promise<LoanTemplate[]> =>
    AxiosInstance.get<LoanTemplateResponseList, LoanTemplateResponseList>('/v1/loan/template').then(
      transformTemplates,
    ),
  // cli-service
};

// export default loansServicesMocked;
export default loansServices;
