import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserExtendedProfile } from 'modules/user/services';
import { generateRequestDataReducer } from 'store/generators/reducers';
import {
  initialApiInfinityListState,
  initialApiRequestState,
  initialArrayApiRequestState,
} from 'store/generators/utils';

import { Notification } from '../types';
// cli-import
import { Profile, UserState } from '../types';

const initialState: UserState = {
  profile: initialApiRequestState,
  extendedProfile: initialApiRequestState,
  referralLinks: initialArrayApiRequestState,
  notifications: initialApiInfinityListState,
  // cli-state
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateProfile: generateRequestDataReducer<UserState, Profile>({
      reducerPath: 'profile',
    }),
    updateExtendedProfile: generateRequestDataReducer<UserState, UserExtendedProfile>({
      reducerPath: 'extendedProfile',
    }),
    updateReferralLinks: generateRequestDataReducer<UserState, string[]>({
      reducerPath: 'referralLinks',
    }),

    updateNotifications: generateRequestDataReducer<UserState, Notification[]>({
      reducerPath: 'notifications',
    }),
    updateNotificationsReadStatus: (
      state: UserState,
      action: PayloadAction<Notification['id'][]>,
    ) => {
      state.notifications.data
        .filter((n) => action.payload.includes(n.id))
        .forEach((n) => (n.isRead = true));
    },
    // cli-reducer
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export default userSlice.reducer;
export const userActions = userSlice.actions;
