import { FC, ReactElement } from 'react';

import { Icon, Skeleton } from 'components/ui';

import classes from './SavedEntityCard.module.scss';

interface SavedEntityCardProps {
  entity: 'user' | 'client';
  title: string | null;
  description: string | null;
  endAdornment?: ReactElement;
}

const iconNameByEntity = {
  user: 'userFilled',
  client: 'caseFilled',
};

const SavedEntityCard: FC<SavedEntityCardProps> = ({
  entity,
  title,
  description,
  endAdornment,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <Icon name={iconNameByEntity[entity]} />
      </div>
      <div className={classes.textDetails}>
        {title ? <span>{title}</span> : <Skeleton height={24} />}
        {description ? <p>{description}</p> : <Skeleton height={32} />}
      </div>
      {endAdornment}
    </div>
  );
};

export default SavedEntityCard;
