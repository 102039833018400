import { isValid, parse } from 'date-fns';
import { setIn } from 'final-form';
import * as yup from 'yup';

import { getTranslation } from 'libs/i18n';

yup.addMethod(yup.string, 'requiredDefault', function () {
  return this.required(getTranslation('VALIDATION_REQUIRED'));
});
yup.addMethod(yup.string, 'dateDDMMYYYY', function () {
  return this.test(
    'validDate',
    getTranslation('ERRORS_INVALID_DATE'),
    (value: string | undefined) => {
      if (!value) {
        return false;
      }
      if (value.length !== 10) {
        return false;
      }
      try {
        return isValid(parse(value, 'dd.MM.yyyy', new Date()));
      } catch (e) {
        return false;
      }
    },
  );
});
yup.addMethod(yup.string, 'integer', function () {
  return this.matches(/^\d+$/, getTranslation('VALIDATION_FIELD_ONLY_NUMBERS'));
});
yup.addMethod(yup.string, 'withoutSpaces', function () {
  return this.test('noSpaces', getTranslation('VALIDATION_NO_SPACES'), (value) => {
    return Boolean(value && !/\s/g.test(value));
  });
});
yup.addMethod(yup.number, 'requiredDefault', function () {
  return this.required(getTranslation('VALIDATION_REQUIRED'));
});

// @ts-ignore
const setInError = (errors, innerError) => {
  return setIn(errors, innerError.path, innerError.message);
};

const emptyObj = Object.create(null);

export const makeValidate = (schema: any) => {
  return async function validate(values: object) {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err) {
      // @ts-ignore
      return err.inner.reduce(setInError, emptyObj);
    }
  };
};

export default yup;
