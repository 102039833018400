import { useCallback, useEffect, useRef } from 'react';

const useScrollDrag = (el: HTMLElement | null) => {
  const isPressed = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!el) {
        return;
      }
      isPressed.current = true;
      startX.current = e.pageX - el.offsetLeft;
      scrollLeft.current = el.scrollLeft;
    },
    [el],
  );

  const handleMouseUp = useCallback(() => {
    isPressed.current = false;
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isPressed.current || !el) {
        return;
      }
      e.preventDefault();
      const x = e.pageX - el.offsetLeft;
      const walk = x - startX.current;
      el.scrollLeft = scrollLeft.current - walk;
    },
    [el],
  );

  useEffect(() => {
    if (!el) {
      return;
    }
    el.addEventListener('mousedown', handleMouseDown);

    return () => {
      el.removeEventListener('mousedown', handleMouseDown);
    };
  }, [el, handleMouseDown]);

  useEffect(() => {
    if (!el) {
      return;
    }
    el.addEventListener('mouseup', handleMouseUp);
    el.addEventListener('mouseleave', handleMouseUp);

    return () => {
      el.removeEventListener('mouseup', handleMouseUp);
      el.removeEventListener('mouseleave', handleMouseUp);
    };
  }, [el, handleMouseUp]);

  useEffect(() => {
    if (!el) {
      return;
    }
    el.addEventListener('mousemove', handleMouseMove);

    return () => {
      el.removeEventListener('mousemove', handleMouseMove);
    };
  }, [el, handleMouseMove]);
};

export default useScrollDrag;
