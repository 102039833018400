import { FC, ReactNode } from 'react';

import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionCard } from 'modules/digitalAccount/views/components/SubscriptionCard';
import { SubscriptionCheckCard } from 'modules/digitalAccount/views/components/SubscriptionCheckCard';

import { Mark } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './SubscriptionStateCard.module.scss';

interface SubscriptionStateCardProps {
  onClick?: voidFunc;
  subscription: DigitalSubscription;
  children?: ReactNode;
}

export const SubscriptionStateCard: FC<SubscriptionStateCardProps> = ({
  subscription,
  onClick,
  children,
}) => {
  const translate = useTranslation();
  return (
    <SubscriptionCard
      onClick={onClick}
      subscription={subscription}
      control={
        <Mark variant="blurredBlack">
          {translate(
            subscription.isVerificationPending
              ? 'DA_SUBSCRIPTION_VERIFICATION_PENDING_SHORT'
              : subscription.isDepositPending
              ? 'PAYMENT_PENDING'
              : 'ACTIVE',
          )}
        </Mark>
      }
    >
      <SubscriptionCheckCard
        className={classes.checkCard}
        subscription={subscription}
        paymentCurrency={subscription.payCurrencyCode}
      />
      {children}
    </SubscriptionCard>
  );
};
