import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import { selectOperationCommissionsMeta } from 'modules/app/store/selectors';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import {
  selectIsDigitalAccountAllowed,
  selectUserDASubscriptionType,
} from 'modules/digitalAccount/store/selectors';
import {
  BANK_CARD_OPERATION_BLOCKED_COUNTRIES_FOR_DEPOSIT,
  BANK_CARD_OPERATION_BLOCKED_COUNTRIES_FOR_WITHDRAW,
} from 'modules/payment/constants';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import useDefaultCryptoNetwork from 'modules/payment/hooks/useDefaultCryptoNetwork';
import usePaymentInit from 'modules/payment/hooks/usePaymentInit';
import { PaymentOperationId } from 'modules/payment/types';
import { getBlockedOperationIdByPaymentOperationId } from 'modules/payment/utils';
import { Operation, paymentOperations } from 'modules/payment/views/PickOperation/config';
import { CommissionLabel } from 'modules/payment/views/components/CommissionLabel';
import { selectUserCountryCode, selectUserProfile } from 'modules/user/store/selectors';

import { ErrorCard } from 'components/common';
import { MenuList } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import { useTranslation } from 'libs/i18n';

import { isFiat } from 'utils/currency';

import { CryptoCurrencyCode, CurrencyCode } from 'types';

import classes from './PickOperation.module.scss';

export interface PickOperationProps {
  currencyCode: CurrencyCode;
  isDeposit: boolean;
  isDigitalAccount: boolean;
}

const operationsIdsMustBeExecutedOnlyInIBANAccount = [
  PaymentOperationId.depositFromWireToTradingWallet,
  PaymentOperationId.depositFromSepaToTradingWallet,
  PaymentOperationId.sendFromTradingToSepa,
  PaymentOperationId.sendFromTradingToWire,
  PaymentOperationId.sendFromMoneyToTrading,
  PaymentOperationId.depositFromSepaToMoneyWallet,
  PaymentOperationId.sendFromMoneyToSepa,
];

const PickOperation: FC<PickOperationProps> = ({ currencyCode, isDeposit }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const user = useSelector(selectUserProfile);

  usePaymentInit();

  const { defaultNetwork } = useDefaultCryptoNetwork(currencyCode as CryptoCurrencyCode);

  const commissionsMeta = useSelector(selectOperationCommissionsMeta);

  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);
  const isUserHasDigitalAccount = useSelector(selectUserDASubscriptionType);
  const userCountry = useSelector(selectUserCountryCode);

  const handlePickOperation = useCallback(
    (operationId: PaymentOperationId) => {
      if (
        operationsIdsMustBeExecutedOnlyInIBANAccount.includes(operationId) &&
        isDAAllowed &&
        !isUserHasDigitalAccount
      ) {
        drawer.open(ibanAccountDrawerTemplates.accountCreatingPrompt());
        return;
      }

      const paymentOperationTemplate = paymentDrawerTemplates.paymentOperation({
        operationId,
        currencyCode,
      });
      if (paymentOperationTemplate) {
        drawer.open(paymentOperationTemplate);
      }
    },
    [isUserHasDigitalAccount, isDAAllowed, drawer, currencyCode],
  );

  const operations = useMemo<{
    tradingOperations: Operation[];
    ibanOperations: Operation[];
  }>(() => {
    const tradingOperations = [];
    const ibanOperations = [];

    if (isDeposit) {
      if (userCountry && !BANK_CARD_OPERATION_BLOCKED_COUNTRIES_FOR_DEPOSIT.includes(userCountry)) {
        tradingOperations.unshift(paymentOperations.deposit.bankCard);
      }
      if (isDAAllowed && currencyCode === 'EUR') {
        tradingOperations.push(paymentOperations.deposit.fromIBANAccount);
        ibanOperations.push(paymentOperations.deposit.SEPA_DA);
      }
    } else {
      tradingOperations.push(paymentOperations.send.nebeusUser);

      if (isFiat(currencyCode)) {
        if (
          userCountry &&
          !BANK_CARD_OPERATION_BLOCKED_COUNTRIES_FOR_WITHDRAW.includes(userCountry) &&
          currencyCode !== 'USD'
        ) {
          tradingOperations.unshift(paymentOperations.send.bankCard);
        }

        if (currencyCode === 'EUR') {
          if (isDAAllowed) {
            ibanOperations.push(paymentOperations.send.SEPA_DA);
          }

          if (isUserHasDigitalAccount) {
            tradingOperations.push(paymentOperations.send.toDigitalAccount);
            ibanOperations.push(paymentOperations.send.toCryptoAccount);
          } else {
            // TODO: Remove hardcode
            if (userCountry === 'GB') {
              tradingOperations.unshift(paymentOperations.send.SEPA);
            }
          }
        }
      } else {
        if (currencyCode !== 'NBT' && currencyCode !== 'CELO') {
          tradingOperations.push(paymentOperations.send.externalWallet);
        }
      }
    }

    return { tradingOperations, ibanOperations };
  }, [isDAAllowed, userCountry, isUserHasDigitalAccount, currencyCode, isDeposit]);

  const getAllowedOperations = useCallback(
    (ops: Operation[]) => {
      return ops
        .filter((o) => {
          const blockedFeatureId = getBlockedOperationIdByPaymentOperationId(o.operationId);
          if (!blockedFeatureId || !user) {
            return true;
          }
          return !user.blockedFeatures.includes(blockedFeatureId);
        })
        .map((o) => ({
          key: o.nameKey,
          label: translate(o.nameKey),
          handler: () => {
            handlePickOperation(o.operationId);
          },
          className: classes.menuListItem,
          icon: o.iconName,
          iconProps: { size: 32 },
          underLabelComponent: (
            <CommissionLabel
              className={classes.commissionLabel}
              feeFreeClassName={classes.free}
              currencyCode={currencyCode}
              operationId={o.operationId}
              cryptoNetwork={
                o.operationId === PaymentOperationId.sendCryptoToExternalWallet && defaultNetwork
                  ? defaultNetwork.network
                  : undefined
              }
            />
          ),
        }));
    },
    [currencyCode, defaultNetwork, handlePickOperation, translate, user],
  );

  const allowedTradingOperations = useMemo<MenuListItem[]>(
    () => getAllowedOperations(operations.tradingOperations),
    [operations, getAllowedOperations],
  );

  const allowedIbanOperations = useMemo<MenuListItem[]>(
    () => getAllowedOperations(operations.ibanOperations),
    [operations, getAllowedOperations],
  );

  return (
    <div className="mt-1 column gap-4">
      {isDAAllowed && allowedIbanOperations.length ? (
        <div className="column gap-1-5">
          <h6>{translate('DIGITAL_ACCOUNT')}</h6>
          <MenuList
            menu={allowedIbanOperations}
            loading={!commissionsMeta.loaded && commissionsMeta.loading}
          />
        </div>
      ) : null}
      {allowedTradingOperations.length ? (
        <div className="column gap-1-5">
          <h6>{translate('CRYPTO_ACCOUNT')}</h6>
          <MenuList
            menu={allowedTradingOperations}
            loading={!commissionsMeta.loaded && commissionsMeta.loading}
          />
        </div>
      ) : null}
      {allowedIbanOperations.length || allowedTradingOperations.length ? null : (
        <ErrorCard label={translate('USER_FEATURE_BLOCKED')} />
      )}
    </div>
  );
};

export default PickOperation;
