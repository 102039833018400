import clsx from 'clsx';

import { FC, useMemo } from 'react';

import { getDaysToNextNMonths } from 'modules/cryptoRenting/utils';
import useRate from 'modules/exchange/hooks/useRate';

import { CurrencyIcon } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './RentingSetupPayoutCalculations.module.scss';

interface ListItemProps {
  label: string;
  currencyCode?: CurrencyCode;
  value: string;
  valueInBrackets?: string;
  className?: string;
}
const ListItem: FC<ListItemProps> = ({
  label,
  currencyCode,
  valueInBrackets,
  value,
  className,
}) => {
  return (
    <div className={clsx('column gap-1', className)}>
      <span className={classes.shortInfoLabel}>{label}:</span>
      <div className={classes.shortInfoRow}>
        {currencyCode && <CurrencyIcon code={currencyCode} size={16} />}
        <span>{value}</span>
        {valueInBrackets && <span>({valueInBrackets})</span>}
      </div>
    </div>
  );
};
interface SetupCalculationProps {
  rentAmount: number;
  percent: number;
  withdrawCurrency: CurrencyCode;
  defaultCurrency: CurrencyCode;
  rentCurrency: CurrencyCode;
  termMonth: number;
}

const RentingSetupPayoutCalculations: FC<SetupCalculationProps> = ({
  withdrawCurrency,
  defaultCurrency,
  rentAmount,
  rentCurrency,
  percent,
  termMonth,
}) => {
  const translate = useTranslation();

  const rentToWithdrawRate = useRate(rentCurrency, withdrawCurrency, { autoFetch: true });
  const rentToDefaultRate = useRate(rentCurrency, defaultCurrency, { autoFetch: true });
  const daysInNextTermMonths = useMemo(() => getDaysToNextNMonths(termMonth), [termMonth]);
  const daysInNextMonth = useMemo(() => getDaysToNextNMonths(1), []);

  const [
    totalRewardsInWithdrawCurrency,
    monthRewardsInWithdrawCurrency,
    totalRewardsInDefaultCurrency,
    monthRewardsInDefaultCurrency,
    rentAmountInDefaultCurrency,
  ] = useMemo(() => {
    const rewardPerDay = (rentAmount * percent) / 365 / 100;

    const month = rewardPerDay * daysInNextMonth;
    const total = rewardPerDay * daysInNextTermMonths;
    return [
      total * rentToWithdrawRate,
      month * rentToWithdrawRate,
      total * rentToDefaultRate,
      month * rentToDefaultRate,
      rentAmount * rentToDefaultRate,
    ];
  }, [
    daysInNextMonth,
    daysInNextTermMonths,
    rentAmount,
    percent,
    rentToWithdrawRate,
    rentToDefaultRate,
  ]);

  return (
    <div className="outlinedCard p-1-5 mt-1-5 column gap-1-5">
      <div className="row gap-5">
        <ListItem className="row" label={translate('RENTING_RPY')} value={`${percent}%`} />
        <ListItem
          className="row"
          label={translate('RENTING_TERM')}
          value={translate('DATE_MONTH_PLURAL', { count: termMonth })}
        />
      </div>
      <ListItem
        label={translate('RENTING_RENT_AMOUNT')}
        value={formatCurrencyWithLabel(rentAmount, rentCurrency)}
        currencyCode={rentCurrency}
        valueInBrackets={formatCurrencyWithLabel(rentAmountInDefaultCurrency, defaultCurrency)}
      />
      <ListItem
        label={translate('RENTING_REWARDS_PER_MONTH')}
        value={formatCurrencyWithLabel(monthRewardsInWithdrawCurrency, withdrawCurrency)}
        valueInBrackets={formatCurrencyWithLabel(monthRewardsInDefaultCurrency, defaultCurrency)}
        currencyCode={withdrawCurrency}
      />
      <ListItem
        label={translate('RENTING_TOTAL_REWARDS')}
        value={formatCurrencyWithLabel(totalRewardsInWithdrawCurrency, withdrawCurrency)}
        valueInBrackets={formatCurrencyWithLabel(totalRewardsInDefaultCurrency, defaultCurrency)}
        currencyCode={withdrawCurrency}
      />
    </div>
  );
};

export default RentingSetupPayoutCalculations;
