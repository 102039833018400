import throttle from 'lodash/throttle';

import { UIEvent, useCallback, useEffect, useRef, useState } from 'react';

import useFlag from 'hooks/useFlag';

// This hook implemented with refetching list several times if it gets empty array of transactions
const useInfinityScrollForAccountTransactions = <Entity>({
  loadMore,
  pageSize = 20,
  threshold = window.innerHeight / 2,
}: {
  loadMore: (page: number, pageSize: number) => Promise<Entity[] | null>;
  pageSize?: number;
  threshold?: number;
}) => {
  const emptyArrayCount = useRef(0);

  const loading = useFlag(true);
  const hasMore = useFlag(true);
  const [page, setPage] = useState(0);
  const [entities, setEntities] = useState<Entity[]>([]);

  const fetchEntities = useCallback(
    async (pageNumber: number) => {
      if (!hasMore.state && pageNumber !== 0) {
        return;
      }
      loading.on();
      const newEntities = await loadMore(pageNumber, pageSize);
      if (!newEntities) {
        loading.off();
        hasMore.off();
        return;
      }
      if (newEntities.length > 0) {
        setEntities((prev) => (pageNumber === 0 ? newEntities : [...prev, ...newEntities]));
        loading.off();
      }
      if (newEntities.length === 0) {
        if (emptyArrayCount.current === 3) {
          hasMore.off();
          loading.off();
        } else {
          emptyArrayCount.current += 1;
          setPage(pageNumber + 1);
        }
      }
    },
    [loadMore, pageSize, hasMore, loading],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInfinityScrollThrottled = useCallback(
    throttle(
      (height, offset, top) => {
        const currentThreshold = height - offset - top;

        if (currentThreshold < threshold && !loading.state) {
          setPage((prev) => prev + 1);
        }
      },
      500,
      { leading: false, trailing: true },
    ),
    [threshold, loading.state],
  );
  const onInfinityScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      onInfinityScrollThrottled(
        event.currentTarget.scrollHeight,
        event.currentTarget.offsetHeight,
        event.currentTarget.scrollTop,
      );
    },
    [onInfinityScrollThrottled],
  );

  useEffect(() => {
    fetchEntities(page);

    // eslint-disable-next-line
  }, [page]);

  const refreshList = useCallback(() => {
    fetchEntities(0);
  }, [fetchEntities]);

  return {
    isLoading: loading.state,
    hasMore: hasMore.state,
    list: entities,
    onInfinityScroll,
    refreshList,
  };
};

export default useInfinityScrollForAccountTransactions;
