import clsx from 'clsx';

import { FC } from 'react';

import { Icon } from 'components/ui/Icon';

import classes from './CheckBox.module.scss';

export interface CheckBoxProps {
  checked: boolean;
  onChange?: (newValue: boolean) => void;
  rounded?: boolean;
}
export const CheckBox: FC<CheckBoxProps> = ({ checked, onChange, rounded }) => {
  return (
    <div
      onClick={() => {
        if (!onChange) {
          return;
        }
        onChange(!checked);
      }}
      className={clsx(classes.root, checked && classes.checked, rounded && classes.rounded)}
    >
      <Icon className={classes.icon} name="checkSquared" size={16} />
    </div>
  );
};
