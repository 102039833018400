const analyticCookiesNames = [
  'utm_content',
  'utm_term',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_id',
  'click_id',
  's_id',
  'public_referrer',
];

export const getCookie = (name: string): string | null => {
  const re = new RegExp(name + '=([^;]+)');
  const value = re.exec(document.cookie);
  return value ? value[1] : null;
};

export const getAnalyticCookies = () => {
  return analyticCookiesNames.reduce((acc, name) => {
    const value = getCookie(name);
    if (value) {
      return { ...acc, [name]: value };
    }
    return acc;
  }, {});
};
