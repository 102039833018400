import { FC, memo, useCallback, useEffect } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import { selectAllowedCountriesReducer } from 'modules/app/store/selectors';
import { requestAllowedCountries } from 'modules/app/store/thunks';
import { AllowedCountry } from 'modules/app/types';
import { CountryPickerMobile } from 'modules/auth/views/SignUp/components/CountryPickerMobile';
import { SignUpValues } from 'modules/auth/views/SignUp/index';
import { StepHeader } from 'modules/auth/views/components/StepHeader';

import { SubmitButton } from 'components/form';
import { CountryPickerInputField } from 'components/form/CountryPickerInputField';

import useMobile from 'hooks/useMobile';
import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';
import useSwiperSlideSizeObserver from 'libs/swiper/useSwiperSlideSizeObserver';

import { validate } from './validate';

interface FormValues {
  country: AllowedCountry | null;
}

const initialValues: FormValues = {
  country: null,
};

interface SelectCountryFormProps extends FormRenderProps<FormValues> {
  allowedCountries: AllowedCountry[];
}

const countryPickerSelectProps = {
  floatingSelectProps: { placement: 'bottom', flipEnabled: false, offset: 4, variant: 'creamy' },
};

const SelectCountryForm: FC<SelectCountryFormProps> = ({
  handleSubmit,
  allowedCountries,
  form,
  values,
}) => {
  const translate = useTranslation();
  const isMobile = useMobile();

  const { setNode } = useSwiperSlideSizeObserver();

  const handleChangeCountry = useCallback(
    (country: AllowedCountry) => {
      // For trigger useEffect with submit form
      if (values.country?.id === country.id) {
        form.submit();
      } else {
        form.change('country', country);
      }
    },
    [values.country, form],
  );

  useEffect(() => {
    if (values.country && isMobile) {
      setTimeout(form.submit, 1);
    }
    // eslint-disable-next-line
  }, [values.country]);

  return (
    <form ref={setNode} className="authStepRoot" onSubmit={handleSubmit}>
      <div className="column gap-3 flex-1">
        <StepHeader
          title={translate('SIGN_UP_SELECT_COUNTRY_TITLE')}
          subtitle={translate('SIGN_UP_SELECT_COUNTRY_SUBTITLE')}
        />
        {isMobile ? (
          <CountryPickerMobile
            onChange={handleChangeCountry}
            value={values.country}
            allowedCountries={allowedCountries}
          />
        ) : (
          <Field
            name="country"
            component={CountryPickerInputField}
            allowedCountries={allowedCountries}
            label={translate('COUNTRY')}
            placeholder={translate('PLACEHOLDER_WHERE_DO_YOU_LIVE')}
            portalSelector="#SIGN_SLIDER"
            selectProps={countryPickerSelectProps}
            autoComplete="country-name"
          />
        )}
      </div>
      {!isMobile && <SubmitButton fullWidth>{translate('CONTINUE')}</SubmitButton>}
    </form>
  );
};

interface SelectCountryProps {
  onSubmit: ({ country }: { country: NonNullable<SignUpValues['country']> }) => void;
}
export const SelectCountry = memo<SelectCountryProps>(({ onSubmit }) => {
  const { entityReducer: allowedCountiesReducer } = useStoreEntity(
    selectAllowedCountriesReducer,
    requestAllowedCountries,
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit({ country: values.country! });
    },
    [onSubmit],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <SelectCountryForm allowedCountries={allowedCountiesReducer.data} {...formProps} />
    ),
    [allowedCountiesReducer.data],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={renderForm}
      validate={validate}
    />
  );
});
