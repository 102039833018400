import clsx from 'clsx';

import {
  ButtonHTMLAttributes,
  ChangeEventHandler,
  DetailedHTMLProps,
  FC,
  useCallback,
} from 'react';

import { Icon } from 'components/ui/Icon';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './SearchInput.module.scss';

interface SearchInputProps
  extends Omit<
    DetailedHTMLProps<ButtonHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'type' | 'onChange'
  > {
  className?: string;
  onChange: (value: string) => void;
  value: string;
  zeroHidden?: boolean;
  toggleHideZero?: voidFunc;
  searchIconPosition?: 'start' | 'end';
}

export const SearchInput: FC<SearchInputProps> = ({
  className,
  onChange,
  toggleHideZero,
  zeroHidden,
  searchIconPosition = 'start',
  ...props
}) => {
  const translate = useTranslation();

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <div className={clsx(classes.root, className, classes['icon_' + searchIconPosition])}>
      <Icon name="search" className={clsx(classes.icon, classes[searchIconPosition])} />
      <input onChange={handleChange} type="text" {...props} />
      {toggleHideZero && (
        <button onClick={toggleHideZero} className={classes.hideButton}>
          {typeof zeroHidden === 'boolean'
            ? translate(
                zeroHidden ? 'PLACEHOLDER_SHOW_ZERO_BALANCES' : 'PLACEHOLDER_HIDE_ZERO_BALANCES',
              )
            : null}
        </button>
      )}
    </div>
  );
};
