import clsx from 'clsx';

import { ReactElement, ReactNode, memo, useCallback } from 'react';

import {
  translationKeyDescBySubType,
  translationKeyTitleBySubType,
} from 'modules/digitalAccount/constants/config';
import { DigitalSubscription, DigitalSubscriptionType } from 'modules/digitalAccount/types';

import { Icon, Image, Mark } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrency, getCurrencySymbolByCode } from 'utils/currency';

import classes from './SubscriptionCard.module.scss';

interface SubscriptionCardProps {
  subscription: DigitalSubscription;
  className?: string;
  variant?: 'default' | 'withPrice';
  onClick?: (subscription: DigitalSubscription) => void;
  children?: ReactNode;
  control?: ReactElement;
  disabled?: boolean;
}

const imageNameBySubType: { [key in DigitalSubscriptionType]: string } = {
  standard: 'nebeusBank',
  hodler: 'safe',
  whale: 'whale',
};

export const SubscriptionCard = memo<SubscriptionCardProps>(
  ({ subscription, className, variant = 'default', onClick, control, disabled, children }) => {
    const translate = useTranslation();

    const handleClick = useCallback(() => {
      onClick?.(subscription);
    }, [onClick, subscription]);
    return (
      <div
        onClick={disabled ? undefined : handleClick}
        className={clsx(
          classes.root,
          className,
          disabled && classes.disabled,
          classes['type-' + subscription.type],
          classes['variant-' + variant],
        )}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.header}>
            <h5>
              <span>Nebeus {translate(translationKeyTitleBySubType[subscription.type])}</span>
              <br />
              {translate('DIGITAL_ACCOUNT')}
            </h5>
            <div className={classes.control}>
              {control ||
                (variant === 'default' ? (
                  <Icon name="arrowRight" size="sm" color="white" />
                ) : (
                  <Mark
                    className={classes.mark}
                    variant={subscription.pricePerMonth > 0 ? 'gold' : 'white'}
                  >
                    {subscription.pricePerMonth > 0
                      ? translate('PRICE_PER_MONTH_SHORT', {
                          currency: getCurrencySymbolByCode('EUR'),
                          amount: formatCurrency(subscription.pricePerMonth, 'EUR'),
                        })
                      : translate('FREE').toUpperCase()}
                  </Mark>
                ))}
            </div>
          </div>
          {children || (
            <p
              className={classes.desc}
              dangerouslySetInnerHTML={{
                __html: translate(translationKeyDescBySubType[subscription.type]),
              }}
            />
          )}
        </div>

        <Image
          className={classes.subImage}
          name={imageNameBySubType[subscription.type]}
          path="digitalAccount"
        />
      </div>
    );
  },
);
