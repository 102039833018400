import clsx from 'clsx';

import { ChangeEventHandler, FC, useCallback, useMemo, useState } from 'react';

import { CurrencyIcon } from 'components/ui/CurrencyIcon';
import { CurrencyPicker, CurrencyPickerItem } from 'components/ui/CurrencyPicker';
import { Icon } from 'components/ui/Icon';
import { TextInput, TextInputProps } from 'components/ui/TextInput';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatCurrency, isFiat } from 'utils/currency';
import { parseAmount } from 'utils/inputParsers';

import { CurrencyCode, voidFunc } from 'types';

import classes from './CurrencyAmountInput.module.scss';

export interface CurrencyAmount {
  amount: string;
  currency: CurrencyCode;
}

export interface CurrencyAmountInputProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  value: CurrencyAmount;
  onChange: (value: Partial<CurrencyAmount>) => void;
  showBalance?: boolean;
  showMaxAmountButton?: boolean;
  maxAmountButtonLabel?: string;
  currencyPickerTitle?: string;
  onPressMaxAmountButton?: voidFunc;
  pickerDisabled?: boolean;
  currencyButtonLeft?: boolean;
  currenciesList?: CurrencyPickerItem[];
  pickerHidden?: boolean;
}

interface CurrencyPickerButtonProps {
  onClick: voidFunc;
  currencyCode: CurrencyCode;
  isOpen: boolean;
  className?: string;
  disabled?: boolean;
  onlyArrow?: boolean;
}

const CurrencyPickerButton: FC<CurrencyPickerButtonProps> = ({
  onClick,
  className,
  currencyCode,
  isOpen,
  disabled,
  onlyArrow,
}) => {
  return (
    <div
      onClick={(e) => {
        if (disabled) {
          return;
        }
        e.stopPropagation();
        onClick();
      }}
      className={clsx(
        classes.currencyPickerButton,
        className,
        'row aic jcsb',
        !disabled && 'pointer',
      )}
    >
      {onlyArrow ? null : (
        <>
          <CurrencyIcon size={16} code={currencyCode} />
          <span>{currencyCode}</span>
        </>
      )}
      {disabled ? null : (
        <Icon
          className={clsx(classes.arrow, isOpen && classes.active)}
          size="sm"
          name="triangleDownLg"
          color="kindaBlack"
        />
      )}
    </div>
  );
};

export const CurrencyAmountInput: FC<CurrencyAmountInputProps> = ({
  value: { amount, currency },
  onChange,
  className,
  showBalance,
  showMaxAmountButton,
  onPressMaxAmountButton,
  maxAmountButtonLabel,
  currenciesList,
  pickerDisabled = !currenciesList || currenciesList.length < 2,
  currencyButtonLeft,
  disabled: inputDisabled,
  pickerHidden,
  currencyPickerTitle,
  ...props
}) => {
  const translate = useTranslation();

  const wallet = useMemo(
    () => (currenciesList ? currenciesList.find((i) => i.currencyCode === currency) : null),
    [currenciesList, currency],
  );

  const currencyPicker = useFlag(false);

  const currencyIsFiat = useMemo(() => isFiat(currency), [currency]);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const newInputValue = e.target.value;
      const parsedInputValue = parseAmount(newInputValue, currencyIsFiat);
      onChange({ currency, amount: parsedInputValue });
    },
    [onChange, currency, currencyIsFiat],
  );
  const handlePickCurrency = useCallback(
    (code: CurrencyCode) => {
      onChange({ currency: code, amount });
    },
    [onChange, amount],
  );

  const handleMaxAmount = useCallback(() => {
    if (wallet) {
      onChange({
        currency,
        amount: formatCurrency(wallet.amount, wallet.currencyCode, {
          withZeros: true,
          round: 'down',
        }),
      });
    }
  }, [wallet, currency, onChange]);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleInputContainerClick = useCallback(() => {
    if (inputDisabled && !pickerDisabled) {
      currencyPicker.on();
    }
  }, [inputDisabled, pickerDisabled, currencyPicker]);

  return (
    <div className={clsx(classes.root, className)}>
      <TextInput
        inputContainerClassName={
          inputDisabled && !pickerDisabled ? classes.inputPointer : undefined
        }
        onInputContainerClick={handleInputContainerClick}
        inputContainerRef={setAnchorEl}
        focused={currencyPicker.state}
        inputMode="decimal"
        borderOnFocus={currencyPicker.state ? 'green' : 'gray'}
        value={amount}
        onChange={handleInputChange}
        topRightComponent={
          showBalance && wallet ? (
            <p className={classes.balance}>
              {currency} {translate('BALANCE')}:
              <span>
                {formatCurrency(wallet.amount, currency, { round: 'down', withZeros: true })}
              </span>
            </p>
          ) : undefined
        }
        startAdornment={
          currencyButtonLeft ? (
            <CurrencyIcon className="ml-1-5" size={24} code={currency} />
          ) : undefined
        }
        endAdornment={
          !pickerHidden ? (
            <CurrencyPickerButton
              onClick={currencyPicker.toggle}
              currencyCode={currency}
              isOpen={currencyPicker.state}
              className={classes.currencyPickerButtonAdornment}
              disabled={pickerDisabled}
              onlyArrow={currencyButtonLeft}
            />
          ) : undefined
        }
        underComponentRight={
          showMaxAmountButton && wallet ? (
            <span
              onClick={onPressMaxAmountButton || handleMaxAmount}
              className={classes.maxAmountButton}
            >
              {maxAmountButtonLabel || translate('ENTER_MAX_AMOUNT_BUTTON')}
            </span>
          ) : undefined
        }
        disabled={inputDisabled}
        {...props}
      />

      {currenciesList ? (
        <CurrencyPicker
          anchorEl={anchorEl}
          currenciesList={currenciesList}
          selectedCurrency={currency}
          onPick={handlePickCurrency}
          isOpen={currencyPicker.state}
          onClose={currencyPicker.off}
          title={currencyPickerTitle}
        />
      ) : null}
    </div>
  );
};
