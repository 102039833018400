export const LINK_TERMS_OF_USE = 'https://support.nebeus.com/portal/en/kb/articles/terms-of-use';
export const LINK_TERMS_OF_BUSINESS =
  'https://support.nebeus.com/portal/en/kb/articles/mfsel-introduced-client-schedule-introduced-client-terms-of-business-4-7-2023';
export const LINK_TERM_OF_USE_CRYPTO_BANK_CARD =
  'https://support.nebeus.com/portal/en/kb/articles/nebeus-mastercard-fees-and-limits-4-9-2023-1';
export const LINK_REQUEST_BUSINESS_ACCOUNT =
  'https://in.sumsub.com/idensic/l/#/uni_ngmCdPUK94Ak3jJz';

export const LINK_PRIVACY_POLICY =
  'https://support.nebeus.com/portal/en/kb/articles/privacy-policy';

export const LINK_RENTING_AND_STAKING_AGREEMENT =
  'https://support.nebeus.com/portal/en/kb/articles/crypto-renting-and-staking-agreement';

export const LINK_SUPPORT = 'https://support.nebeus.com/portal/en/newticket';

export const LINK_DIGITAL_ACCOUNTS_LIMITS_AND_FEES =
  'https://support.nebeus.com/portal/en/kb/articles/money-accounts-subscriptions';

export const LINK_NEBEUS_PUBLIC_SITE = 'https://nebeus.com';

export const LINK_CURVE_WEBSITE = 'https://curve.com';
export const LINK_CURVE_MOBILE_APP = 'https://link.curve.com';

export const LINK_ANDROID_APP =
  'https://play.google.com/store/apps/details?id=com.nebeus.android&hl=en&gl=US';

export const LINK_NEBEUS_TRUSTPILOT_RATE = 'https://www.trustpilot.com/evaluate/www.nebeus.com';
export const LINK_IOS_APP_REVIEW =
  'itms-apps://itunes.apple.com/app/id1568251064?action=write-review';

export const openExternalLink = (url: string) => {
  window.open(url, '_blank', 'noopener noreferrer');
};

export const downloadFile = (blob: Blob, fileName: string) => {
  try {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Download file error: ', e);
  }
};
export const openExternalLinkInCurrentWindow = (url: string) => {
  window.open(url, '_self');
};
