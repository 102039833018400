import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Assets } from 'modules/staking/views/Staking/components/Assets';
import { Overview } from 'modules/staking/views/Staking/components/Overview';

import routesByName from 'constants/routesByName';

import { ButtonGroup, NavigationHeader } from 'components/ui';
import { ButtonGroupItem } from 'components/ui/ButtonGroup';

import { useTranslation } from 'libs/i18n';

import classes from './Staking.module.scss';

const Staking = () => {
  const translate = useTranslation();
  const navigate = useNavigate();

  const { tab = 'assets' } = useParams();

  const buttons = useMemo<ButtonGroupItem[]>(
    () => [
      {
        id: '1',
        children: translate('ASSETS'),
        onClick: () => {
          navigate(routesByName.staking('assets'));
        },
      },
      {
        id: '2',
        children: translate('OVERVIEW'),
        onClick: () => {
          navigate(routesByName.staking('overview'));
        },
      },
    ],
    [navigate, translate],
  );
  return (
    <>
      <NavigationHeader hideBackButton className={classes.header}>
        {translate('STAKING')}
      </NavigationHeader>
      <div className={classes.content}>
        <div className={classes.buttonsContainer}>
          <ButtonGroup
            className={classes.buttons}
            buttons={buttons}
            activeButtonIndex={tab === 'overview' ? 1 : 0}
            separated
          />
        </div>
        {tab === 'assets' ? <Assets /> : <Overview />}
      </div>
    </>
  );
};

export default Staking;
