import clsx from 'clsx';
import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  isFuture,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from 'components/ui/Button';
import { Icon } from 'components/ui/Icon';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './DatePicker.module.scss';

interface DateInputProps {
  value: Date | null;
  className?: string;
}
const DateInput: FC<DateInputProps> = ({ value, className }) => {
  const translate = useTranslation();

  return (
    <div className={clsx(classes.dateInput, value && classes.hasValue, className)}>
      <Icon name="calendar" size={16} />
      <span>{value ? format(value, 'PP') : translate('SELECT_DATE')}</span>
    </div>
  );
};

export type DatePickerValue = { startDate: Date | null; endDate: Date | null };
export interface DatePickerProps {
  variant?: 'single' | 'range';
  value: DatePickerValue;
  onChange: (dates: DatePickerValue) => void;
  onCancel: voidFunc;
  isFutureAllowed?: boolean;
}
const daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

export const DatePicker: React.FC<DatePickerProps> = ({
  variant = 'single',
  value,
  onChange,
  onCancel,
  isFutureAllowed = true,
}) => {
  const translate = useTranslation();

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(value.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(value.endDate);
  const [currentMonth, setCurrentMonth] = useState<Date>(value.startDate || new Date());

  const handleApply = useCallback(() => {
    if (variant === 'single') {
      if (selectedStartDate) {
        onChange({ startDate: selectedStartDate, endDate: null });
      }
    } else {
      if (selectedStartDate && selectedEndDate) {
        onChange({ startDate: selectedStartDate, endDate: selectedEndDate });
      }
    }
  }, [variant, onChange, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    setSelectedStartDate(value.startDate);
    setSelectedEndDate(value.endDate);
  }, [value]);

  const handlePrevMonth = useCallback(() => {
    setCurrentMonth((prevMonth) => subMonths(prevMonth, 1));
  }, []);

  const handleNextMonth = useCallback(() => {
    setCurrentMonth((prevMonth) => addMonths(prevMonth, 1));
  }, []);

  const handleDateClick = useCallback(
    (day: Date) => {
      if (variant === 'single') {
        setSelectedStartDate(day);
        setSelectedEndDate(null);
      } else if (variant === 'range') {
        if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
          setSelectedStartDate(day);
          setSelectedEndDate(null);
        } else if (isBefore(day, selectedStartDate)) {
          setSelectedEndDate(selectedStartDate);
          setSelectedStartDate(day);
        } else {
          setSelectedEndDate(day);
        }
      }
    },
    [variant, selectedStartDate, selectedEndDate],
  );

  const renderHeader = useCallback(() => {
    return (
      <div className={classes.header}>
        <Icon className="pointer" onClick={handlePrevMonth} name="chevronLeftSquared" size={16} />
        <span className={classes.headerDate}>{format(currentMonth, 'MMMM yyyy')}</span>
        <Icon className="pointer" onClick={handleNextMonth} name="chevronRightSquared" size={16} />
      </div>
    );
  }, [currentMonth, handlePrevMonth, handleNextMonth]);

  const renderDaysOfWeek = useCallback(() => {
    return (
      <div className="row aic gap-0-5 jcsb">
        {daysOfWeek.map((day, index) => (
          <div key={index} className={classes.cell}>
            <span>{day}</span>
          </div>
        ))}
      </div>
    );
  }, []);

  const renderCells = useCallback(() => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);

    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    const days = eachDayOfInterval({ start: startDate, end: endDate });

    return (
      <div className={classes.days}>
        {days.map((day, index) => {
          const isToday = isSameDay(day, new Date());
          const isFutureDay = isFuture(day);
          const isSelectedStart = selectedStartDate && isSameDay(day, selectedStartDate);
          const isSelectedEnd = selectedEndDate && isSameDay(day, selectedEndDate);
          const isInRange =
            selectedStartDate &&
            selectedEndDate &&
            isWithinInterval(day, { start: selectedStartDate, end: selectedEndDate });
          const isInCurrentMonth = isSameMonth(day, currentMonth);

          const disabled = !isInCurrentMonth || (!isFutureAllowed && isFutureDay);

          return (
            <div
              key={index}
              className={clsx(
                classes.cell,
                classes.clickable,
                disabled && classes.disabled,
                isSelectedEnd && classes.selectedEnd,
                isSelectedStart && classes.selectedStart,
                (isSelectedEnd || isSelectedStart) && classes.selected,
                isInRange && classes.inRange,
                isToday && classes.today,
              )}
              onClick={disabled ? undefined : () => handleDateClick(day)}
            >
              <span>{format(day, 'd')}</span>
            </div>
          );
        })}
      </div>
    );
  }, [isFutureAllowed, currentMonth, selectedStartDate, selectedEndDate, handleDateClick]);

  const isInvalid = useMemo(
    () => !selectedStartDate || (variant === 'range' && !selectedEndDate),
    [selectedStartDate, selectedEndDate, variant],
  );

  const renderFooter = useCallback(() => {
    return (
      <div className={classes.footer}>
        <Button onClick={onCancel} className="flex-1" variant="greenOutlined">
          {translate('CANCEL')}
        </Button>
        <Button onClick={handleApply} disabled={isInvalid} className="flex-1" variant="green">
          {translate('APPLY')}
        </Button>
      </div>
    );
  }, [isInvalid, handleApply, onCancel, translate]);

  const handleClickToday = useCallback(() => {
    setSelectedStartDate(new Date());
    setCurrentMonth(new Date());
  }, []);

  const renderSelectedDate = useCallback(() => {
    return (
      <div className="row aic gap-1-5">
        <DateInput className="flex-1" value={selectedStartDate} />
        {variant === 'single' ? (
          <Button onClick={handleClickToday} size="sm" variant="greenOutlined">
            {translate('DATE_TODAY')}
          </Button>
        ) : (
          <>
            <span>-</span>
            <DateInput className="flex-1" value={selectedEndDate} />
          </>
        )}
      </div>
    );
  }, [handleClickToday, translate, variant, selectedStartDate, selectedEndDate]);

  return (
    <div className={clsx(classes.root, classes['variant-' + variant])}>
      <div className={classes.body}>
        {renderHeader()}
        {renderSelectedDate()}
        <div className="column gap-0-5">
          {renderDaysOfWeek()}
          {renderCells()}
        </div>
      </div>
      {renderFooter()}
    </div>
  );
};
