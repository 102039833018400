import { FC } from 'react';
import { Field } from 'react-final-form';

import { TextInputField } from 'components/form';

import { getTranslation } from 'libs/i18n';
import yup from 'libs/yup';

import { parseSortCode } from 'utils/inputParsers';

export const wireDetailsValidationSchema = yup.object().shape({
  accountName: yup
    .string()
    .requiredDefault()
    .matches(
      /^[aA-zZ\s]+$/,
      getTranslation('VALIDATION_ONLY_LETTERS', {
        fieldName: getTranslation('PAYMENT_ACCOUNT_NAME'),
      }),
    ),
  accountNumber: yup.string().requiredDefault(),
  reference: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 ]*$/,
      getTranslation('VALIDATION_ONLY_LETTERS_AND_NUMBERS', {
        fieldName: getTranslation('PAYMENT_REFERENCE'),
      }),
    )
    .min(8, getTranslation('VALIDATION_MIN', { min: 8 }))
    .max(18, getTranslation('VALIDATION_MAX', { max: 17 }))
    .requiredDefault(),
  sortCode: yup
    .string()
    .requiredDefault()
    .length(8, getTranslation('VALIDATION_DIGITS_COUNT', { count: 6 })),
});

export interface WireDetailsFormValues {
  accountName: string;
  accountNumber: string;
  sortCode: string;
  reference: string;
}

export const wireDetailsInitialValues: WireDetailsFormValues = {
  accountName: '',
  accountNumber: '',
  sortCode: '',
  reference: '',
};

interface WireDetailsFormProps {}
export const WireDetailsForm: FC<WireDetailsFormProps> = () => {
  return (
    <>
      <Field
        name="accountName"
        component={TextInputField}
        placeholder={getTranslation('PAYMENT_ACCOUNT_NAME')}
      />
      <Field
        name="accountNumber"
        component={TextInputField}
        placeholder={getTranslation('PAYMENT_ACCOUNT_NUMBER')}
      />
      <Field
        name="sortCode"
        component={TextInputField}
        placeholder={getTranslation('PAYMENT_SORT_CODE')}
        maxLength={8}
        parse={parseSortCode}
      />
      <Field
        name="reference"
        component={TextInputField}
        placeholder={getTranslation('PAYMENT_REFERENCE')}
      />
    </>
  );
};
