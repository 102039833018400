import { FC } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import { DigitalSubscriptionType } from 'modules/digitalAccount/types';
import { SubscriptionLayout } from 'modules/digitalAccount/views/components/SubscriptionLayout';
import { SubscriptionProcessTitle } from 'modules/digitalAccount/views/components/SubscriptionProcessTitle';

import { Button, Icon, Mark } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import classes from './SubscriptionVerificationPending.module.scss';

export interface SubscriptionVerificationPendingProps {
  subscriptionType: DigitalSubscriptionType;
}
const SubscriptionVerificationPending: FC<SubscriptionVerificationPendingProps> = ({
  subscriptionType,
}) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  return (
    <SubscriptionLayout subscriptionType={subscriptionType}>
      <div>
        <SubscriptionProcessTitle subscriptionType={subscriptionType} />
        <div className={classes.blurredCard}>
          <div className={classes.content}>
            <Mark variant="gold">{translate('VERIFICATION_PENDING')}</Mark>
            <div className="mt-2">
              <div className="row gap-1-5">
                <Icon size={10} name="check" className={classes.checkIcon} />
                <div className="column">
                  <span className={classes.title}>
                    {translate('DA_SUBSCRIPTION_VERIFICATION_PENDING_TITLE')}
                  </span>
                  <p className={classes.description}>
                    {translate('DA_SUBSCRIPTION_VERIFICATION_PENDING_DESC')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.infoCard}>
            <p>{translate('DA_SUBSCRIPTION_VERIFICATION_PENDING_IMPORTANT')}</p>
          </div>
        </div>
      </div>
      <Button onClick={sidebar.close} fullWidth variant="gold" showShadow>
        {translate('BACK_TO_DASHBOARD')}
      </Button>
    </SubscriptionLayout>
  );
};
export default SubscriptionVerificationPending;
