import { useCallback, useRef } from 'react';

const useParseDateMonth = () => {
  const prevValue = useRef('');

  return useCallback((value: string) => {
    let result = value.replace(/[^0-9/]/g, '');

    if (prevValue.current.length === 3 && result.length === 2) {
      result = result.slice(0, -1);
    }

    if (result.length === 2 && prevValue.current.length !== 3) {
      result += '/';
    }

    if (result.length >= 4 && !result.includes('/')) {
      return `${result.slice(0, 2)}/${result.slice(2, 4)}`;
    }

    prevValue.current = result;

    return result;
  }, []);
};

export default useParseDateMonth;
