import clsx from 'clsx';

import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  MutableRefObject,
  ReactElement,
  SyntheticEvent,
  useCallback,
} from 'react';

import { Icon } from 'components/ui/Icon';
import { InfoIcon, InfoIconProps } from 'components/ui/InfoIcon';
import { Loader } from 'components/ui/Loader';

import classes from './TextInput.module.scss';

export interface TextInputProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'type' | 'size'
  > {
  size?: 'md' | 'lg' | 'md-plus';
  endAdornment?: ReactElement;
  startAdornment?: ReactElement;
  underComponent?: ReactElement;
  underComponentRight?: ReactElement;
  topRightComponent?: ReactElement;
  error?: string;
  borderOnFocus?: 'darkGrey' | 'green' | 'gray' | false;
  borderOnError?: boolean;
  valid?: boolean;
  bgOnValid?: boolean;
  errorClassName?: string;
  label?: string;
  variant?: 'creamy' | 'white' | 'creamyWhite' | 'linen' | 'whiteOutlined';
  forwardRef?: MutableRefObject<HTMLDivElement | null>;
  inputContainerRef?: (ref: HTMLInputElement | null) => void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  focused?: boolean;
  hideErrorLabel?: boolean;
  loading?: boolean;
  inputContainerClassName?: string;
  onInputContainerClick?: (event: SyntheticEvent<HTMLDivElement>) => void;
  icon?: InfoIconProps;
  inputComponent?: 'input' | 'textarea';
  valueParser?: (value: any) => string;
  withClearing?: boolean;
}

export const TextInput: FC<TextInputProps> = ({
  className,
  size = 'md',
  endAdornment,
  error,
  borderOnFocus = 'gray',
  borderOnError = true,
  bgOnValid,
  valid,
  errorClassName,
  onClick,
  label,
  variant = 'creamy',
  forwardRef,
  startAdornment,
  underComponent,
  underComponentRight,
  topRightComponent,
  inputContainerRef,
  inputRef,
  focused,
  hideErrorLabel,
  loading,
  disabled,
  inputContainerClassName,
  onInputContainerClick,
  icon,
  value,
  valueParser,
  inputComponent: InputComponent = 'input',
  withClearing,
  onChange,
  ...props
}) => {
  const inputValue = valueParser ? valueParser(value) : value;

  const resetValue = useCallback(() => {
    if (onChange) {
      // @ts-ignore
      onChange({ target: { value: '' }, currentTarget: { value: '' } });
    }
  }, [onChange]);

  return (
    <div
      translate="no"
      className={clsx(classes.root, className)}
      onClick={onClick}
      ref={forwardRef}
    >
      {label || topRightComponent ? (
        <div className={clsx('row aic jcsb mb-1', classes.topAdornment)}>
          <div className="row aic">
            <span className="label">{label}</span>
            {icon && <InfoIcon className="ml-1 flex-shrink-0" size="xs" {...icon} />}
          </div>
          {topRightComponent}
        </div>
      ) : null}
      <div
        onClick={onInputContainerClick}
        ref={inputContainerRef}
        className={clsx(
          classes.inputContainer,
          InputComponent === 'textarea' && classes.textArea,
          inputContainerClassName,
          classes[size],
          classes[variant],
          focused && classes.focused,
          borderOnFocus && classes[`focusBorder-${borderOnFocus}`],
          borderOnError && error && classes.errorBorder,
          valid && bgOnValid && classes.valid,
          typeof inputValue === 'string' && inputValue.length && classes.hasValue,
        )}
      >
        {startAdornment}
        <InputComponent
          // @ts-ignore
          ref={inputRef}
          className={clsx(
            classes.input,
            startAdornment && classes.withStartAdornment,
            (loading || disabled) && classes.disabled,
          )}
          placeholder={label}
          disabled={loading || disabled}
          autoCapitalize="off"
          autoCorrect="off"
          value={inputValue}
          // @ts-ignore
          onChange={onChange}
          {...props}
        />
        <div className={clsx(classes.loaderMask, loading && classes.active)}>
          <Loader size="sm" />
        </div>

        {withClearing && (
          <Icon
            onClick={resetValue}
            name="cross"
            size={16}
            color="black40"
            className={classes.resetIcon}
          />
        )}
        {endAdornment}
      </div>
      {underComponent || underComponentRight || (error && !hideErrorLabel) ? (
        <div className={clsx(classes.underRow, 'row jcsb gap-1 mt-1')}>
          {error ? (
            <span className={clsx(classes.error, errorClassName)}>{error}</span>
          ) : (
            underComponent
          )}
          {underComponentRight}
        </div>
      ) : null}
    </div>
  );
};
