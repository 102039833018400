import { memo } from 'react';

import { NavigationHeader } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { StartTradeAllowedList } from './components/StartTradeAllowedList';
import { TradesList } from './components/TradesList';

export const SmartTrader = memo(() => {
  const translate = useTranslation();

  return (
    <div>
      <NavigationHeader hideBackButton>{translate('SMART_TRADER')}</NavigationHeader>
      <div className="column gap-3 mt-3">
        <TradesList />
        <StartTradeAllowedList />
      </div>
    </div>
  );
});
