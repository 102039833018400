import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectDigitalAccountExistWallets } from 'modules/accounts/store/selectors';
import useSideBar from 'modules/app/hooks/useSideBar';
import {
  translationKeySubDescSecondaryBySubType,
  translationKeyTitleBySubType,
} from 'modules/digitalAccount/constants/config';
import { selectSubscriptions } from 'modules/digitalAccount/store/selectors';
import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionCheckCard } from 'modules/digitalAccount/views/components/SubscriptionCheckCard';
import { SubscriptionLayout } from 'modules/digitalAccount/views/components/SubscriptionLayout';
import { SubscriptionProcessTitle } from 'modules/digitalAccount/views/components/SubscriptionProcessTitle';
import useRate from 'modules/exchange/hooks/useRate';
import { PaymentOperationId } from 'modules/payment/types';
import { PaymentDetailsCard } from 'modules/payment/views/components/PaymentDetailsCard';

import { Button, Icon, Mark } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrency, getCurrencySymbolByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './GetSubscriptionSuccess.module.scss';

export interface GetSubscriptionSuccessProps {
  subscription: DigitalSubscription;
  showWalletDetails?: boolean;
}

const GetSubscriptionSuccess: FC<GetSubscriptionSuccessProps> = ({
  subscription,
  showWalletDetails,
}) => {
  const translate = useTranslation();
  const sidebar = useSideBar();

  const subscriptions = useSelector(selectSubscriptions);
  const existingDigitalWallets = useSelector(selectDigitalAccountExistWallets);

  const wallet = useMemo(
    () =>
      existingDigitalWallets.find(
        (w) => w.currencyCode === subscription.payCurrencyCode || w.exist,
      ),
    [subscription, existingDigitalWallets],
  );

  const rateToGBP = useRate('EUR', 'GBP');

  const pricesByCurrency = useMemo<{ [key in CurrencyCode]?: number }>(
    () => ({
      EUR: subscription.pricePerMonth,
      GBP: subscription.pricePerMonth * (rateToGBP || 1),
    }),
    [rateToGBP, subscription],
  );

  const isDowngradedToStandard = useMemo(
    () =>
      subscription.type === 'standard' &&
      subscriptions.find((s) => s.type !== 'standard' && s.isEnding),
    [subscription.type, subscriptions],
  );
  return (
    <SubscriptionLayout subscriptionType={subscription.type}>
      <div>
        <SubscriptionProcessTitle
          subscriptionType={subscription.type}
          additional={
            isDowngradedToStandard
              ? undefined
              : translate(subscription.pricePerMonth > 0 ? 'PURCHASED' : 'OPENED')
          }
        />
        <p className={classes.subtitle}>
          {translate(
            subscription.type === 'standard' && !wallet
              ? 'DA_SUBSCRIPTION_STD_OPEN_DESC'
              : translationKeySubDescSecondaryBySubType[subscription.type],
          )}
        </p>
        <div className="mt-6">
          <div className={classes.blurredCard}>
            <div className={classes.content}>
              <Mark variant="gold">
                {translate(subscription.isDepositPending ? 'PAYMENT_PENDING' : 'ACTIVE')}
              </Mark>
              <div className="mt-2">
                <div className="row gap-1-5">
                  <Icon size={10} name="check" className={classes.checkIcon} />
                  <div className="column">
                    <span className={classes.priceLabel}>
                      {subscription.pricePerMonth > 0
                        ? translate('PRICE_PER_MONTH', {
                            currency: getCurrencySymbolByCode(subscription.payCurrencyCode),
                            amount: formatCurrency(
                              pricesByCurrency[subscription.payCurrencyCode],
                              true,
                              { withZeros: true },
                            ),
                          })
                        : translate('FREE_LIFETIME_PLAN')}
                    </span>
                    <p className={classes.description}>
                      {translate(
                        subscription.isDepositPending
                          ? 'DA_SUBSCRIPTION_GET_SUCCESS_DESC_DEPOSIT_NEED'
                          : 'DA_SUBSCRIPTION_GET_SUCCESS_DESC',
                        { subName: translate(translationKeyTitleBySubType[subscription.type]) },
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.infoCard}>
              {showWalletDetails && wallet ? (
                <PaymentDetailsCard
                  currencyCode={wallet.currencyCode}
                  className={classes.paymentCard}
                  operationId={
                    wallet.currencyCode === 'GBP'
                      ? PaymentOperationId.depositFromWireToMoneyWallet
                      : PaymentOperationId.depositFromSepaToMoneyWallet
                  }
                />
              ) : (
                <SubscriptionCheckCard
                  className="p-0"
                  subscription={subscription}
                  paymentCurrency={subscription.payCurrencyCode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Button onClick={sidebar.close} fullWidth variant="gold" showShadow>
        {translate('BACK_TO_DASHBOARD')}
      </Button>
    </SubscriptionLayout>
  );
};

export default GetSubscriptionSuccess;
