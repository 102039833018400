import { useSelector } from 'react-redux';

import useRate from 'modules/exchange/hooks/useRate';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';

import { CurrencyCode, FiatCurrencyCode } from 'types';

interface Options {
  autoFetch: boolean;
}
type RateToDefaultCurrency = (
  from: CurrencyCode,
  options?: Options,
) => { rate: number; defaultCurrency: FiatCurrencyCode };

const useRateToDefaultCurrency: RateToDefaultCurrency = (from, options) => {
  const defaultCurrency = useSelector(selectUserDefaultCurrencyCode);

  const rate = useRate(from, defaultCurrency, options);

  return { rate, defaultCurrency };
};
export default useRateToDefaultCurrency;
