import clsx from 'clsx';

import { FC, useCallback } from 'react';

import classes from './Switch.module.scss';

export interface SwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  loading?: boolean;
  circleClassName?: string;
}

export const Switch: FC<SwitchProps> = ({
  value,
  onChange,
  className,
  circleClassName,
  loading,
}) => {
  const handleChange = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);
  return (
    <div
      onClick={loading ? undefined : handleChange}
      className={clsx(classes.root, className, value && classes.active, loading && classes.loading)}
    >
      <div className={clsx(classes.circle, circleClassName, value && classes.active)} />
    </div>
  );
};
