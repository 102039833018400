import { useEffect, useState } from 'react';

const useAsyncPortalTarget = (selector: string) => {
  const [portalTarget, setPortalTarget] = useState<Element | null>(
    document.querySelector(selector) || null,
  );

  useEffect(() => {
    setTimeout(() => {
      const portalTargetEl = document.querySelector(selector);

      if (portalTargetEl) {
        setPortalTarget(portalTargetEl);
      }
    }, 100);
  }, [selector]);

  return portalTarget;
};

export default useAsyncPortalTarget;
