import { useNavigate } from 'react-router-dom';

import appConfig from 'constants/appConfig';
import routesByName from 'constants/routesByName';

import classes from './MocksButton.module.scss';

const MocksButton = () => {
  const navigate = useNavigate();

  if (!appConfig.mocksAllowed) {
    return null;
  }
  return (
    <div
      onClick={() => {
        navigate(routesByName.mocksConfigurator);
      }}
      className={classes.root}
    >
      ⚙️
    </div>
  );
};

export default MocksButton;
