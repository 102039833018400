import { PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import set from 'lodash/set';

import { DirectionData, IAPIMeta, IApiRequestState } from 'store/generators/types';

import { CurrenciesDirection } from 'types';

type GenerateDefaultReducer = <State extends object, Entity = undefined>(options: {
  reducerPath: keyof State;
  withPrev?: boolean;
  uniqByKey?: string;
}) => (state: State, action: PayloadAction<Entity>) => void;

export const generateDefaultReducer: GenerateDefaultReducer =
  ({ reducerPath, withPrev, uniqByKey }) =>
  (state, { payload }) => {
    if (withPrev) {
      const prev = get(state, reducerPath);
      if (Array.isArray(prev)) {
        if (uniqByKey) {
          const prevState = prev.filter(
            // @ts-ignore
            (pi) => !payload.find((i) => i[uniqByKey] === pi[uniqByKey]),
          );
          // @ts-ignore
          set(state, reducerPath, [...prevState, ...payload]);
        } else {
          // @ts-ignore
          set(state, reducerPath, [...prev, ...payload]);
        }
      } else {
        set(state, reducerPath, { ...prev, ...payload });
      }
      return;
    }
    set(state, reducerPath, payload);
  };

type GenerateRequestReducer = <State, Entity = undefined>(options: {
  reducerPath: keyof State | string;
  withPrev?: boolean;
}) => (state: State, action: PayloadAction<{ data?: Entity; meta?: Partial<IAPIMeta> }>) => void;

export const generateRequestDataReducer: GenerateRequestReducer =
  ({ reducerPath, withPrev }) =>
  (state, { payload }) => {
    const pathState: IApiRequestState<any> = get(state, reducerPath);

    if (pathState) {
      const { data, meta } = payload;
      if (data) {
        if (withPrev) {
          if (Array.isArray(pathState.data)) {
            pathState.data = [...pathState.data, data];
          } else {
            pathState.data = { ...pathState.data, data };
          }
        } else {
          pathState.data = data;
        }
      }
      if (meta) {
        pathState.meta = { ...pathState.meta, ...meta };
      }
    }
  };

type GenerateRequestDirectionReducer = <
  State extends object,
  Entity extends DirectionData,
>(options: {
  reducerPath: string;
}) => (
  state: State,
  action: PayloadAction<{
    direction: CurrenciesDirection;
    data?: Entity;
    meta?: Partial<IAPIMeta>;
  }>,
) => void;

export const generateRequestDirectionReducer: GenerateRequestDirectionReducer =
  ({ reducerPath }) =>
  (state, { payload: { direction, ...payload } }) => {
    const path = `${reducerPath}[${direction}]`;
    const pathState: IApiRequestState<unknown> | undefined = get(state, path);

    if (pathState) {
      const { data, meta } = payload;
      if (data) {
        pathState.data = data;
      }
      if (meta) {
        pathState.meta = { ...pathState.meta, ...meta };
      }
    } else {
      set(state, path, payload);
    }
  };
