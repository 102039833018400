import clsx from 'clsx';
import { formatDistanceToNowStrict } from 'date-fns';

import { FC, useMemo } from 'react';

import { Statistic } from 'modules/cryptoRenting/types';

import { AmountLabel, CurrencyIcon, LineDelimiter } from 'components/ui';

import useTablet from 'hooks/useTablet';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';

import classes from './AssetsOverview.module.scss';

interface AssetsOverviewProps {
  statistic: Statistic;
}
export const AssetsOverview: FC<AssetsOverviewProps> = ({ statistic }) => {
  const translate = useTranslation();
  const isTablet = useTablet();

  const nextRewardInDays = useMemo(
    () =>
      statistic?.nextRewardDate
        ? parseInt(
            formatDistanceToNowStrict(new Date(statistic.nextRewardDate), {
              unit: 'day',
              addSuffix: false,
            }),
          )
        : '',
    [statistic],
  );

  return (
    <div className={classes.root}>
      <div className={classes.totalAssets}>
        <span className="label">{translate('RENTING_TOTAL_ASSETS_LABEL')}:</span>
        <AmountLabel
          className={classes.totalAssetsAmount}
          size="xl5"
          amount={statistic.totalAssets}
          currencyCode={statistic.defaultCurrencyCode}
          showCurrencySymbol
        />
      </div>
      {isTablet && <LineDelimiter className="my-2" />}
      <div className={classes.overviewDetails}>
        <div className={classes.item}>
          <span className={classes.label}>{translate('RENTING_REWARDS_30DAYS')}:</span>
          <span className={classes.rewardLabel}>
            {formatCurrencyWithSymbol(statistic.assetsPer30Days, statistic.defaultCurrencyCode)}
          </span>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>{translate('RENTING_REWARDS_60DAYS')}:</span>
          <span className={classes.rewardLabel}>
            {formatCurrencyWithSymbol(statistic.assetsPer60Days, statistic.defaultCurrencyCode)}
          </span>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>
            {translate('RENTING_NEXT_REWARDS', {
              days: nextRewardInDays,
            })}
            :
          </span>
          <div className={classes.nextRewardContainer}>
            <span className={clsx(classes.rewardLabel, classes.nextReward)}>
              {formatCurrencyWithLabel(statistic.nextRewardAssets, statistic.nextRewardCurrency)}
            </span>
            <CurrencyIcon size={20} code={statistic.nextRewardCurrency} />
          </div>
        </div>
      </div>
    </div>
  );
};
