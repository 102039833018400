import clsx from 'clsx';
import { format, isToday, isYesterday } from 'date-fns';
import { groupBy } from 'lodash';

import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { CurrencyIcon, Loader } from 'components/ui';

import { Flag } from 'hooks/useFlag';
import useInfinityScroll from 'hooks/useInfinityScroll';
import useScroll from 'hooks/useScroll';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY, formatHHMM } from 'utils/date';

import { CurrencyCode } from 'types';

import classes from './TransactionHistory.module.scss';

export interface TransactionItem {
  id: string | number;
  currencyCode: CurrencyCode;
  date: string;
  label: string;
  amount: number;

  amountInDefaultCurrency?: number;
  defaultCurrencyCode?: CurrencyCode;
}
interface TransactionsProps<T> {
  hideZeroAmount?: boolean;
  className?: string;
  groupByDate?: boolean;
  requestTransactions: (page: number, pageSize: number) => Promise<T[] | null>;
  scrollInside?: boolean;
  pageSize?: number;
  refresh?: Flag;
  renderItem?: (transaction: T) => ReactNode;
  sectionDateFormat?: string;
  sectionClassName?: string;
  listClassName?: string;
  wrapperClassName?: string;
}

export const TransactionHistory = <T extends TransactionItem>({
  requestTransactions,
  className,
  groupByDate,
  hideZeroAmount,
  scrollInside,
  pageSize,
  refresh,
  renderItem,
  sectionDateFormat = 'LLLL, dd',
  sectionClassName,
  wrapperClassName,
  listClassName,
}: TransactionsProps<T>) => {
  const translate = useTranslation();

  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);

  const { onInfinityScroll, isLoading, list, hasMore, refreshList } = useInfinityScroll({
    loadMore: requestTransactions,
    pageSize,
  });

  useEffect(() => {
    if (refresh?.state) {
      refreshList();
      refresh.off();
    }
    // eslint-disable-next-line
  }, [refresh?.state]);

  useScroll(onInfinityScroll, rootRef);

  const getDateLabel = useCallback(
    (item: T) => {
      const date = new Date(item.date);
      if (isToday(date)) {
        return translate('DATE_TODAY');
      }
      if (isYesterday(date)) {
        return translate('DATE_YESTERDAY');
      }
      return format(date, sectionDateFormat);
    },
    [translate, sectionDateFormat],
  );

  const groupedByDate = useMemo<[string, T[]][]>(
    () => (groupByDate ? Object.entries(groupBy(list, getDateLabel)) : []),
    [getDateLabel, groupByDate, list],
  );

  const renderList = useCallback(
    (list: T[]) =>
      list.map(
        (i) =>
          renderItem?.(i) || (
            <div key={i.id} className={classes.listItem}>
              <div className="row aic gap-1">
                <CurrencyIcon size={44} code={i.currencyCode} />
                <div className="column">
                  <span className={classes.operationName}>{i.label}</span>
                  <span className={classes.date}>
                    {groupByDate ? formatHHMM(new Date(i.date)) : formatDDMMYY(new Date(i.date))}
                  </span>
                </div>
              </div>
              {hideZeroAmount && i.amount === 0 ? null : (
                <div className="tar column">
                  <span className={clsx(classes.amount, i.amount > 0 && classes.up)}>
                    {i.amount > 0 ? '+' : ''}
                    {formatCurrencyWithLabel(i.amount, i.currencyCode)}
                  </span>
                  {i.amountInDefaultCurrency && i.defaultCurrencyCode ? (
                    <span className={classes.amountInDef}>
                      {formatCurrencyWithSymbol(i.amountInDefaultCurrency, i.defaultCurrencyCode)}
                    </span>
                  ) : null}
                </div>
              )}
            </div>
          ),
      ),
    [groupByDate, hideZeroAmount, renderItem],
  );

  return (
    <div ref={setRootRef} className={clsx(className, scrollInside && classes.scrollInside)}>
      <div className={`column gap-1 relative ${wrapperClassName} ${hasMore ? 'pb-5' : ''}`}>
        {groupByDate
          ? groupedByDate.map(([dateLabel, groupedList]) => (
              <div key={dateLabel} className={sectionClassName}>
                <h6>{dateLabel}</h6>
                <div className={clsx(classes.list, listClassName)}>{renderList(groupedList)}</div>
              </div>
            ))
          : renderList(list)}
        {isLoading && (
          <div className={classes.loader}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
