import clsx from 'clsx';

import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import { TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import loanDrawerTemplates from 'modules/loans/constants/drawerTemplates';
import { requestOutstandingBalanceDetails, requestRepayLoan } from 'modules/loans/store/thunks';
import { LoanItem, OutstandingBalanceDetails } from 'modules/loans/types';
import { MarketingCard } from 'modules/loans/views/LoanCalculator/components/MarketingCard';
import { LoanHeader } from 'modules/loans/views/components/LoanHeader';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { useDispatch } from 'store';

import { Smooth } from 'components/common';
import { CurrencyAmountField, SubmitButton } from 'components/form';
import { AmountLabel, Button, Icon, InfoIcon, Loader, SummaryCard } from 'components/ui';
import { CurrencyAmount } from 'components/ui/SimpleCurrencyAmountInput';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import useEntity, { EntityController } from 'hooks/useEntity';
import useFlag, { Flag } from 'hooks/useFlag';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import {
  FormatCurrencyOptions,
  MINIMUM_AMOUNT_CRYPTO,
  MINIMUM_AMOUNT_FIAT,
  findWallet,
  formatCurrency,
  formatCurrencyWithSymbol,
  isFiat,
} from 'utils/currency';

import classes from './LoanRepayment.module.scss';

export interface LoanRepaymentProps {
  loan: LoanItem;
}

const currencyIcon = {
  size: 24,
};

interface FormValues {
  currencyAmount: CurrencyAmount;
}

interface RepaymentFormComponentProps extends FormRenderProps<FormValues> {
  wallets: TradingWallet[];
  restBalanceAmountForNow: number | null;
  outstandingBalanceDetailsEntityController: EntityController<OutstandingBalanceDetails>;
  loan: LoanItem;
  expanded: Flag;
}
const RepaymentFormComponent: FC<RepaymentFormComponentProps> = ({
  handleSubmit,
  wallets,
  form: { change },
  errors,
  values,
  restBalanceAmountForNow,
  outstandingBalanceDetailsEntityController,
  loan,
  expanded,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const insufficientFunds = useMemo(
    () => errors?.currencyAmount?.amount === getTranslation('VALIDATION_INSUFFICIENT_FUNDS'),
    [errors],
  );

  const handleDepositFunds = useCallback(() => {
    drawer.open(
      paymentDrawerTemplates.paymentProcess({
        isDeposit: true,
        currencyCode: values.currencyAmount.currency,
      }),
    );
  }, [drawer, values.currencyAmount.currency]);

  const handlePressFullAmount = useCallback(() => {
    if (restBalanceAmountForNow) {
      change('currencyAmount', {
        ...values.currencyAmount,
        amount: formatCurrency(restBalanceAmountForNow, values.currencyAmount.currency, {
          round: 'up',
        }),
      });
    }
  }, [change, values, restBalanceAmountForNow]);

  const showEarlyRepaymentFee =
    !!outstandingBalanceDetailsEntityController.entity?.earlyRepaymentFeeAmount;

  const outstandingConfig = useMemo<SummaryCardItem[] | null>(() => {
    if (!outstandingBalanceDetailsEntityController.entity) {
      return null;
    }

    const result: SummaryCardItem[] = [
      {
        label: translate('LOANS_OUTSTANDING_BALANCE'),
        value: formatCurrencyWithSymbol(
          outstandingBalanceDetailsEntityController.entity.loanBalanceAmount,
          outstandingBalanceDetailsEntityController.entity.currencyCode,
        ),
      },
      {
        label: translate('LOANS_OUTSTANDING_INTEREST_RATE_PAYMENT'),
        value: formatCurrencyWithSymbol(
          outstandingBalanceDetailsEntityController.entity.interestRateBalanceAmount,
          outstandingBalanceDetailsEntityController.entity.currencyCode,
        ),
      },
      {
        label: translate('LOANS_OUTSTANDING_FINES_FOR_LATE'),
        value: formatCurrencyWithSymbol(
          outstandingBalanceDetailsEntityController.entity.fineForLateRepaymentAmount || 0,
          outstandingBalanceDetailsEntityController.entity.currencyCode,
        ),
      },
      {
        label: translate('LOANS_EARLY_REPAYMENT_FEE'),
        value: formatCurrencyWithSymbol(
          outstandingBalanceDetailsEntityController.entity.earlyRepaymentFeeAmount || 0,
          outstandingBalanceDetailsEntityController.entity.currencyCode,
        ),
      },
    ];

    return result;
  }, [translate, outstandingBalanceDetailsEntityController.entity]);

  return (
    <form onSubmit={handleSubmit} className="mt-1 column flex-1 gap-3 jcsb ">
      <div className="column gap-3 flexScrollable">
        <div className="outlinedCard">
          <LoanHeader loan={loan} />
        </div>
        <div className="column gap-1-5">
          <div className="row gap-3 jcsb aic">
            <span className="label">{translate('LOANS_REPAYMENT_AMOUNT')}</span>
            <InfoIcon size="xs" description={translate('LOANS_OUTSTANDING_BALANCE_INFO')} />
          </div>
          <div className="outlinedCard">
            {outstandingBalanceDetailsEntityController.loading && <Loader centered size="xs" />}
            {outstandingBalanceDetailsEntityController.entity && (
              <>
                <div className="column gap-1">
                  <div className="row aic jcsb">
                    <AmountLabel
                      size="md"
                      amount={
                        outstandingBalanceDetailsEntityController.entity.totalOutstandingBalance
                      }
                      currencyCode={loan.loanCurrency}
                      showCurrencySymbol
                      currencyIcon={currencyIcon}
                      formatOptions={formatOptionsRoundUp}
                    />
                    <Icon
                      onClick={expanded.toggle}
                      className={clsx(classes.slideButton, expanded.state && classes.expanded)}
                      name="chevronDown"
                    />
                  </div>
                  <span className={classes.feeLabel}>{translate('ALL_FEES_INCLUDED')}</span>
                </div>
                <Smooth isVisible={expanded.state}>
                  <div className="pt-3">
                    <SummaryCard variant="borderless" config={outstandingConfig!} />
                  </div>
                </Smooth>
              </>
            )}
          </div>
        </div>
        {showEarlyRepaymentFee && <MarketingCard variant="earlyRepaymentFee" />}
        <Field
          name="currencyAmount"
          component={CurrencyAmountField}
          showBalance
          showMaxAmountButton
          maxAmountButtonLabel={translate('LOANS_REPAY_FULL_AMOUNT')}
          label={translate('PLACEHOLDER_ENTER_AMOUNT')}
          currenciesList={wallets}
          onPressMaxAmountButton={handlePressFullAmount}
          showErrorIfExist
          pickerDisabled
        />
      </div>
      {insufficientFunds ? (
        <Button fullWidth onClick={handleDepositFunds}>
          {translate('PAYMENT_DEPOSIT_FUNDS')}
        </Button>
      ) : (
        <SubmitButton fullWidth>{translate('LOANS_REPAY_LOAN')}</SubmitButton>
      )}
    </form>
  );
};

const formatOptionsRoundUp: FormatCurrencyOptions = {
  round: 'up',
};

const LoanRepayment: FC<LoanRepaymentProps> = ({ loan }) => {
  const dispatch = useDispatch();

  const expanded = useFlag(false);

  const fetchLoanOutstandingBalanceDetails = useCallback(async () => {
    const data = await dispatch(requestOutstandingBalanceDetails(loan.id));

    if (!data.success) {
      expanded.off();
    }
    return data;
  }, [loan.id, expanded, dispatch]);

  const outstandingBalanceDetailsEntityController = useEntity<OutstandingBalanceDetails>(
    fetchLoanOutstandingBalanceDetails,
    { fetchOnMount: true },
  );

  const restBalanceAmountForNow =
    outstandingBalanceDetailsEntityController.entity?.totalOutstandingBalance || null;

  const drawer = useDrawer();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const payload = {
        loanId: loan.id,
        amount: +values.currencyAmount.amount,
        isFull:
          +values.currencyAmount.amount ===
          +formatCurrency(restBalanceAmountForNow!, loan.loanCurrency, { round: 'up' }),
      };
      const { success } = await dispatch(requestRepayLoan(payload));

      if (success) {
        drawer.replaceAll(
          loanDrawerTemplates.loanRepaid({
            amount: payload.amount,
            isFull: payload.isFull,
            loan,
          }),
        );
      }
    },

    [drawer, dispatch, restBalanceAmountForNow, loan],
  );

  const wallets = useSelector(selectWallets);

  const initialValues = useMemo<FormValues>(
    () => ({
      currencyAmount: {
        currency: loan.loanCurrency,
        amount: '',
      },
    }),
    [loan],
  );

  const wallet = useMemo(
    () => findWallet(wallets, loan.loanCurrency),
    [wallets, loan.loanCurrency],
  );

  const schema = useMemo(() => {
    const minAmount = isFiat(loan.loanCurrency) ? MINIMUM_AMOUNT_FIAT : MINIMUM_AMOUNT_CRYPTO;

    return yup.object().shape({
      currencyAmount: yup.object().shape({
        amount: yup
          .number()
          .transform((value) => (isNaN(value) ? 0 : value))
          .requiredDefault()
          .min(
            minAmount,
            getTranslation('VALIDATION_MIN_AMOUNT', {
              minLabel: formatCurrencyWithSymbol(minAmount, loan.loanCurrency),
            }),
          )
          .test(
            'partialRepayment',
            getTranslation('LOANS_REPAYMENT_PARTIAL_IS_NOT_ALLOWED'),
            (amount?: number) =>
              !(
                !loan.partialRepaymentAllowed &&
                !!restBalanceAmountForNow &&
                amount !==
                  +formatCurrency(restBalanceAmountForNow, loan.loanCurrency, { round: 'up' })
              ),
          )
          .test(
            'fullRepayment',
            getTranslation('LOANS_REPAYMENT_FULL_IS_NOT_ALLOWED'),
            (amount?: number) =>
              !(
                !loan.fullRepaymentAllowed &&
                !!restBalanceAmountForNow &&
                amount ===
                  +formatCurrency(restBalanceAmountForNow, loan.loanCurrency, { round: 'up' })
              ),
          )
          .test('90%_case', getTranslation('LOANS_REPAY_LESS_90_PERCENT'), (amount?: number) =>
            amount
              ? (amount /
                  +formatCurrency(restBalanceAmountForNow!, loan.loanCurrency, {
                    round: 'up',
                  })) *
                  100 <
                  90 ||
                amount ===
                  +formatCurrency(restBalanceAmountForNow!, loan.loanCurrency, {
                    round: 'up',
                  })
              : true,
          )
          .max(
            +formatCurrency(wallet?.amount || 0, false, { round: 'down' }),
            getTranslation('VALIDATION_INSUFFICIENT_FUNDS'),
          ),
      }),
    });
  }, [loan, restBalanceAmountForNow, wallet]);

  const validate = useMemo(() => {
    return makeValidate(schema);
  }, [schema]);

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <RepaymentFormComponent
        {...formProps}
        wallets={wallets}
        restBalanceAmountForNow={restBalanceAmountForNow}
        loan={loan}
        outstandingBalanceDetailsEntityController={outstandingBalanceDetailsEntityController}
        expanded={expanded}
      />
    ),
    [expanded, wallets, outstandingBalanceDetailsEntityController, loan, restBalanceAmountForNow],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={renderForm}
      validate={validate}
    />
  );
};

export default LoanRepayment;
