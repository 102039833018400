import { createSelector } from '@reduxjs/toolkit';

import { AppStore } from 'store';

export const selectAllowedDirectionsReducer = (state: AppStore) =>
  state.smartTrader.allowedDirections;
export const selectAllowedDirections = (state: AppStore) =>
  state.smartTrader.allowedDirections.data;

export const selectTradesReducer = (state: AppStore) => state.smartTrader.trades;
export const selectTrades = (state: AppStore) => state.smartTrader.trades.data;
export const selectTradesMap = createSelector(selectTrades, (trades) =>
  trades.reduce((acc, trade) => ({ ...acc, [trade.primaryCurrency]: trade }), {}),
);
