export const colors = {
  black40: '#a1a1a1',
  black40x: '#d9d9d9',
  kindaBlack: '#343434',
  darkGrey: '#6a6d70',
  westar: '#dfdbd1',
  linen: '#f2e8df',
  //creamy: '#f9f3eb',
  //creamy200: '#f1e9e0',
  creamyHover: '#f6ecdf',
  creamy80: '#faf5ef',
  creamyWhite: '#fefcf9',
  //white: '#ffffff',
  shadowGreen: '#096333',
  //darkGreen: '#0c8444',
  greyishGreen: '#1ca55c',
  //green: '#4dae51',
  green15: '#ddf2e7',
  gold: '#fec009',
  peach: '#fde096',
  pastelYellow: '#c3ed8d',
  //red: '#ec4e4e',
  brickRed: '#fa8c75',
  cyanBlue: '#1fa6fc',
  //yellow: '#ffcd19',
  //green300: '#3b824b',

  // Redesign

  red: '#e15554',
  red100: '#fae4e1',
  red200: '#f5c5c2',
  red300: '#f0a9a7',
  red400: '#eb8d8b',
  red500: '#e46866',
  red600: '#cf5150',

  amber: '#ffbf00',
  amber100: '#fef4da',
  amber200: '#fee9af',
  amber300: '#ffde7d',
  amber400: '#ffd353',
  amber500: '#ffc61c',
  amber600: '#eab004',

  yellow: '#e7e247',
  yellow100: '#fbf8e0',
  yellow200: '#f7f2c0',
  yellow300: '#f3efa0',
  yellow400: '#efeb82',
  yellow500: '#eae55b',
  yellow600: '#d4d044',

  green: '#1ca55c',
  green100: '#def0e3',
  green200: '#b7e1c6',
  green300: '#8dd1ab',
  green400: '#67c290',
  green500: '#35af6d',
  green600: '#1d9957',

  blue: '#4d9de0',
  blue100: '#e8f0f6',
  blue200: '#c8ddef',
  blue300: '#a6cded',
  blue400: '#88bde8',
  blue500: '#61a8e3',
  blue600: '#61a8e3',

  cyan: '#35a19e',
  cyan100: '#e5f1ee',
  cyan200: '#bfdfda',
  cyan300: '#9acfcc',
  cyan400: '#78bfbc',
  cyan500: '#4baba8',
  cyan600: '#349592',

  creamy: '#f9f3eb',
  creamy100: '#f2e8df',
  creamy200: '#dfdbd1',

  white: '#fefcf9',
  grey100: '#e9e7e4',
  grey200: '#c0bfb9',
  grey300: '#949492',
  grey400: '#71716f',
  grey500: '#424241',

  black: '#2a2b2a',
  black100: '#242424',
  black200: '#151515',
};
export type ThemeColor = keyof typeof colors;
export const getThemeColor = (color: ThemeColor) => colors[color];
