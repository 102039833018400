import clsx from 'clsx';

import { FC, ReactNode } from 'react';

import classes from './Table.module.scss';

export interface TableRow {
  key: string;
  columns: ReactNode[];
}
interface TableProps {
  headers: string[];
  rows: TableRow[];
  className?: string;
  onHeaderClick?: (index: number) => void;
}

export const Table: FC<TableProps> = ({ className, headers, rows, onHeaderClick }) => {
  return (
    <table className={clsx(classes.root, className)}>
      <thead>
        <tr className="label">
          {headers.map((header, headerIndex) => (
            <th
              key={header}
              onClick={
                onHeaderClick
                  ? () => {
                      onHeaderClick(headerIndex);
                    }
                  : undefined
              }
              className={onHeaderClick ? 'pointer' : undefined}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            {row.columns.map((column, index) => (
              <td key={index}>{column}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
