import { receivePreparedHtml } from 'modules/loans/constants/contracts/utils';

import { formatDDMMYY } from 'utils/date';

import { template } from './template';

export const getAgreement = (payload: {
  interestRate: string;
  userName: string;
  userAddress: string;
  loanAmount: string;
  loanCurrency: string;
  userEmail: string;
}) => receivePreparedHtml(template, { ...payload, agreementDate: formatDDMMYY(new Date()) });
