import { Line, LineChart, ResponsiveContainer, ResponsiveContainerProps, YAxis } from 'recharts';

import { FC, useMemo } from 'react';

interface RateChartProps extends Omit<ResponsiveContainerProps, 'children'> {
  rates: { value: number }[];
  raised: boolean;
}
export const RateChart: FC<RateChartProps> = ({ rates, raised, ...props }) => {
  const ratesIsEqual = useMemo(() => rates.every((i) => i.value === rates[0].value), [rates]);

  // Fix for rates with equal data. See: https://stackoverflow.com/questions/73043945/why-does-a-svg-line-disappear-when-i-apply-a-svg-lineargradient/73043947#73043947
  if (ratesIsEqual) {
    return (
      <div
        style={{
          borderRadius: 4,
          height: 2,
          width: '100%',
          background: 'linear-gradient(90deg, rgba(77,174,81,0) 0%, rgba(77,174,81,1) 100%)',
        }}
      ></div>
    );
  }

  return (
    <ResponsiveContainer {...props}>
      <LineChart
        margin={{ top: 1, left: 1, right: 1, bottom: 1 }}
        data={rates}
        style={{ cursor: 'pointer' }}
      >
        <defs>
          <linearGradient
            id={`grad-${raised ? 'raised' : 'down'}`}
            x1="0%"
            y1="0%"
            x2="50%"
            y2="0%"
          >
            <stop offset="0%" stopColor={raised ? '#4DAE51' : '#EC4E4E'} stopOpacity={0} />
            <stop offset="100%" stopColor={raised ? '#4DAE51' : '#EC4E4E'} stopOpacity={1} />
          </linearGradient>
        </defs>
        <YAxis hide type="number" domain={['dataMin', 'dataMax']} />
        <Line
          type="linear"
          dataKey="value"
          stroke={`url(#grad-${raised ? 'raised' : 'down'})`}
          strokeWidth={2}
          legendType="none"
          dot={false}
          strokeLinecap="round"
          strokeLinejoin="round"
          fillOpacity={1}
          animationDuration={1000}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
