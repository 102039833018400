import clsx from 'clsx';

import { FC } from 'react';
import { Link } from 'react-router-dom';

import routesByName from 'constants/routesByName';

import { useTranslation } from 'libs/i18n';

import classes from './SwitchAuthType.module.scss';

interface SwitchAuthTypeProps {
  currentAuthType: 'login' | 'signUp';
  className?: string;
}
export const SwitchAuthType: FC<SwitchAuthTypeProps> = ({ currentAuthType, className }) => {
  const translate = useTranslation();
  return (
    <div className={clsx(classes.root, className)}>
      <span>
        {translate(
          currentAuthType === 'signUp' ? 'SIGN_ALREADY_HAVE_ACCOUNT' : 'SIGN_DONT_HAVE_ACCOUNT',
        )}{' '}
        <Link to={currentAuthType === 'signUp' ? routesByName.signIn : routesByName.signUp}>
          {translate(currentAuthType === 'signUp' ? 'SIGN_SIGN_IN' : 'SIGN_SIGN_UP')}
        </Link>
      </span>
    </div>
  );
};
