import { AxiosRequestConfig } from 'axios';

// import paymentServicesMocked from 'modules/payment/services/mocked';
import {
  transformOperationLimits,
  transformSavedCryptoWallets,
} from 'modules/payment/services/transformers';
import {
  CurrencyNetworks,
  PaymentOperationId,
  PaymentOperationLimit,
  SavedBankCard,
  SavedCryptoWallet,
} from 'modules/payment/types';

import ApiError from 'libs/axios/ApiError';
import AxiosInstance from 'libs/axios/AxiosInstance';
import { getTranslation } from 'libs/i18n';
import {
  CalculatedCommission,
  CalculatedCommissionResponse,
  CreateOutcomeTransactionRequest,
  CurrencyNetworkList,
  EditWalletRequest,
  FromCardResp,
  FromCardToTradingRequest,
  FromTradingToCardRequest,
  GetPerPeriodLimitUsingQueryParams,
  MoneyOutcomeTransactionRequest,
  OperationCommissionRequest,
  OperationPerPeriodLimitResponse,
  OperationPerTransactionLimitResponseList,
  RefundTransactionRequest,
  SaveWalletRequest,
  SavedWalletsResponse,
  ToCardResp,
  TradingTransferRequest,
  TradingWalletAddressRequest,
  TransferRequest,
  WireTransactionRequest,
} from 'libs/swagger/nebeusApiTypes';

import { sleep } from 'utils/common';

import { CurrencyCode } from 'types';

// cli-import

export interface WithdrawalLimits {
  daily: {
    limit: number | null;
    used: number;
  };
  monthly: {
    limit: number | null;
    used: number;
  };
  currencyCode: CurrencyCode;
  operationCode: PaymentOperationId;
  id: string;
}
export interface WithdrawalLimitsApiResponse
  extends Omit<OperationPerPeriodLimitResponse, 'perPeriodLimitInfo'> {
  perPeriodLimitInfo: WithdrawalLimits;
}

const paymentServices = {
  getLimits: (): Promise<PaymentOperationLimit[]> =>
    AxiosInstance.get<
      OperationPerTransactionLimitResponseList,
      OperationPerTransactionLimitResponseList
    >('/v1/operation/limit/per-transaction').then(transformOperationLimits),
  sendToExternalWallet: (payload: CreateOutcomeTransactionRequest) =>
    AxiosInstance.post('/v1/transaction/crypto/outcome-transaction', payload),
  sendFromDigitalToCryptoAccount: (payload: TransferRequest) =>
    AxiosInstance.post('/v1/transaction/trading/from-money-to-trading', payload),
  sendFromCryptoToDigitalAccount: (payload: TransferRequest) =>
    AxiosInstance.post('/v1/transaction/trading/from-trading-to-money', payload),
  getSavedCryptoWallets: (): Promise<SavedCryptoWallet[]> =>
    AxiosInstance.get<SavedWalletsResponse, SavedWalletsResponse>('/v1/crypto/saved-wallet').then(
      transformSavedCryptoWallets,
    ),
  saveCryptoWallet: (payload: SaveWalletRequest) =>
    AxiosInstance.post('/v1/crypto/saved-wallet', payload),
  editCryptoWallet: (payload: EditWalletRequest) =>
    AxiosInstance.put('/v1/crypto/saved-wallet', payload),
  removeCryptoWallet: (id: string) => AxiosInstance.delete('/v1/crypto/saved-wallet/' + id),
  getSavedBankCards: async (): Promise<SavedBankCard[]> => {
    await sleep(100);
    return [];
  },
  // AxiosInstance.get('/v1/saved-bank-cards'),
  calculateOperation: (
    payload: OperationCommissionRequest,
    config?: AxiosRequestConfig,
  ): Promise<CalculatedCommission> =>
    AxiosInstance.post<CalculatedCommissionResponse, CalculatedCommissionResponse>(
      '/v1/operation/commission/calculate',
      payload,
      config,
    ).then((data) => data.calculation),
  sendToNebeusUser: (payload: TradingTransferRequest) =>
    AxiosInstance.post('/v1/transaction/trading/from-trading-to-trading', payload),
  getWithdrawalLimits: (payload: GetPerPeriodLimitUsingQueryParams): Promise<WithdrawalLimits> =>
    AxiosInstance.get<WithdrawalLimitsApiResponse, WithdrawalLimitsApiResponse>(
      '/v1/operation/limit/per-period',
      { params: payload },
    ).then((response: WithdrawalLimitsApiResponse) => ({
      ...response.perPeriodLimitInfo,
      id: `${response.perPeriodLimitInfo.operationCode}/${response.perPeriodLimitInfo.currencyCode}`,
    })),
  sendFromDigitalAccountToSEPAOrWire: (payload: MoneyOutcomeTransactionRequest) =>
    AxiosInstance.post('/v1/transaction/iban/outcome', payload),
  sendFromTradingAccountToSEPAOrWire: (payload: WireTransactionRequest) =>
    AxiosInstance.post('/v1/transaction/wire/withdrawal/init', payload),
  depositFromBankCardToCryptoWallet: (payload: FromCardToTradingRequest): Promise<FromCardResp> =>
    AxiosInstance.post('/v1/card/transaction/from-card-to-trading', payload, {
      headers: {
        'x-screen-width': window.screen.width,
        'x-screen-height': window.screen.height,
        'x-timezone-offset': new Date().getTimezoneOffset(),
      },
    }),
  sendFromTradingWalletToBankCard: (payload: FromTradingToCardRequest): Promise<ToCardResp> =>
    AxiosInstance.post<ToCardResp, ToCardResp>(
      '/v1/card/transaction/from-trading-to-card',
      payload,
    ).then((response) => {
      if (response?.status?.includes('FAILED')) {
        throw new ApiError(
          getTranslation('ERROR_BANK_CARD_SEND'),
          {
            status: 'fail',
            code: 'ERROR_BANK_CARD_SEND',
            message: getTranslation('ERROR_BANK_CARD_SEND'),
          },
          400,
        );
      }

      return response;
    }),
  getCurrenciesNetworks: (): Promise<CurrencyNetworks[]> =>
    AxiosInstance.get<CurrencyNetworkList[], CurrencyNetworkList[]>(
      '/v1/transaction/crypto/currency-networks',
    ),
  // getCurrenciesNetworks: async (): Promise<CurrencyNetworks[]> => {
  //   await sleep(300);
  //
  //   return [
  //     {
  //       currencyCode: 'USDC',
  //       networks: [
  //         {
  //           network: 'BITCOIN',
  //           networkName: 'Bitcoin',
  //           commission: { minAmount: 5, fixedAmount: 2, percent: 2 },
  //           default: false,
  //         },
  //         {
  //           network: 'ETH_NETWORK',
  //           networkName: 'Ethereum',
  //           commission: { minAmount: 10, fixedAmount: 4, percent: 4 },
  //           default: true,
  //         },
  //       ],
  //     },
  //     {
  //       currencyCode: 'CHZ',
  //       networks: [
  //         {
  //           network: 'BITCOIN',
  //           networkName: 'Bitcoin',
  //           commission: { minAmount: 5, fixedAmount: 2, percent: 2 },
  //           default: false,
  //         },
  //         {
  //           network: 'ETH_NETWORK',
  //           networkName: 'Ethereum',
  //           commission: { minAmount: 10, fixedAmount: 4, percent: 4 },
  //           default: true,
  //         },
  //       ],
  //     },
  //     {
  //       currencyCode: 'USDT',
  //       networks: [
  //         {
  //           network: 'ERC_20',
  //           networkName: 'ERC-20',
  //           commission: { minAmount: 10, fixedAmount: 4, percent: 4 },
  //           default: true,
  //         },
  //         {
  //           network: 'BITCOIN',
  //           networkName: 'Bitcoin',
  //           commission: { minAmount: 20, fixedAmount: 6, percent: 4 },
  //           default: false,
  //         },
  //       ],
  //     },
  //     // {
  //     //   currencyCode: 'BTC',
  //     //   networks: [
  //     //     {
  //     //       network: 'BITCOIN',
  //     //       networkName: 'Bitcoin',
  //     //       commission: { minAmount: 10, fixedAmount: 4, percent: 4 },
  //     //       default: true,
  //     //     },
  //     //   ],
  //     // },
  //   ];
  // },

  generateCryptoAddress: (payload: TradingWalletAddressRequest) =>
    AxiosInstance.post('/v1/wallet/trading/address', payload),

  refundRejectedCryptoTransaction: (payload: RefundTransactionRequest): Promise<void> =>
    AxiosInstance.post('/v1/transaction/crypto/income/refund', payload),
  // cli-service
};

export default paymentServices;
// export default paymentServicesMocked;
