import { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import useDrawer from 'modules/app/hooks/useDrawer';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import useRateToDefaultCurrency from 'modules/exchange/hooks/useRateToDefaultCurrency';
import {
  selectAllowedStakingCurrencies,
  selectStakingItems,
  selectStakingTemplatesReducer,
} from 'modules/staking/store/selectors';
import { requestStake, requestStakingTemplates } from 'modules/staking/store/thunks';
import { StakingItem, Template } from 'modules/staking/types';
import { OperationListItem } from 'modules/staking/views/components/OperationListItem';
import { TemplateHeader } from 'modules/staking/views/components/TemplateHeader';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { CurrencyAmountField, SubmitButton } from 'components/form';
import { Button, LineDelimiter, Loader } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';
import { CurrencyPickerItem } from 'components/ui/CurrencyPicker';

import { getTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import { canRequest } from 'utils/common';
import {
  MINIMUM_AMOUNT_CRYPTO,
  findWallet,
  formatCurrency,
  formatCurrencyWithLabel,
  formatCurrencyWithSymbol,
  getCurrencyLabelByCode,
} from 'utils/currency';

import { CryptoCurrencyCode } from 'types';

import classes from './StakingSetup.module.scss';

export interface StakingSetupProps {
  currencyCode: CryptoCurrencyCode;
}

interface FormValues {
  currencyAmount: CurrencyAmount;
}
interface StakingSetupFormProps extends FormRenderProps<FormValues> {
  templates: Template[];
  stakingItems: StakingItem[];
  currenciesList: CurrencyPickerItem[];
}

const StakingSetupForm: FC<StakingSetupFormProps> = ({
  handleSubmit,
  values,
  templates,
  stakingItems,
  currenciesList,
  errors,
}) => {
  const sidebar = useSideBar();
  const drawer = useDrawer();

  const template = useMemo(
    () => templates.find((t) => t.currencyCode === values.currencyAmount.currency),
    [templates, values.currencyAmount.currency],
  );
  const stakingItem = useMemo(
    () =>
      stakingItems.find((i) => i.currencyCode === values.currencyAmount.currency) || {
        amount: 0,
        amountInDefaultCurrency: 0,
      },
    [stakingItems, values.currencyAmount.currency],
  );

  const { rate: rateToDefaultCurrency, defaultCurrency } = useRateToDefaultCurrency(
    values.currencyAmount.currency,
    { autoFetch: true },
  );

  const { stakingAmount, rewardsWeek, rewardsYear, rewardsYearInDefCurr, rewardsWeekInDefCurr } =
    useMemo(() => {
      const amount = stakingItem.amount + +values.currencyAmount.amount;

      const day = (+amount * (template?.percentRPY || 0)) / 365 / 100;

      const week = day * 7;
      const year = day * 365;

      return {
        stakingAmount: amount,
        rewardsWeek: week,
        rewardsYear: year,
        rewardsWeekInDefCurr: week * rateToDefaultCurrency,
        rewardsYearInDefCurr: year * rateToDefaultCurrency,
      };
    }, [rateToDefaultCurrency, template?.percentRPY, stakingItem, values.currencyAmount.amount]);

  const defaultCurrencyCalculatorComponent = useMemo(() => {
    return rateToDefaultCurrency ? (
      <span className={classes.defaultCurrencyAmount}>
        {getTranslation('IN_CURRENCY')} {defaultCurrency}{' '}
        {formatCurrencyWithSymbol(
          rateToDefaultCurrency * +values.currencyAmount.amount,
          defaultCurrency,
        )}
      </span>
    ) : undefined;
  }, [values.currencyAmount.amount, defaultCurrency, rateToDefaultCurrency]);

  const insufficientFunds = useMemo(
    () => errors?.currencyAmount?.amount === getTranslation('VALIDATION_INSUFFICIENT_FUNDS'),
    [errors],
  );

  const handleBuy = useCallback(() => {
    drawer.open(
      exchangeDrawerTemplates.exchange({
        from: { currency: defaultCurrency, amount: '' },
        to: { currency: values.currencyAmount.currency, amount: '' },
      }),
    );
  }, [drawer, defaultCurrency, values.currencyAmount.currency]);

  const handleDeposit = useCallback(() => {
    sidebar.open(
      ...sidebarTemplates.paymentProcess({
        isDeposit: true,
        currencyCode: values.currencyAmount.currency,
      }),
    );
  }, [sidebar, values.currencyAmount.currency]);

  return template ? (
    <form onSubmit={handleSubmit} className="column mt-4">
      <TemplateHeader currencyCode={values.currencyAmount.currency} />
      <LineDelimiter className="my-2" />
      <div className="column gap-0-5">
        <OperationListItem
          label={getTranslation('STAKING_STAKED_CURRENCY')}
          value={getCurrencyLabelByCode(values.currencyAmount.currency, { withBrackets: true })}
          valueCurrencyCode={values.currencyAmount.currency}
        />
        <OperationListItem
          label={getTranslation('STAKING_REWARD_PAYOUT')}
          value={getTranslation('STAKING_PAYOUT_INTERVAL')}
        />
        <OperationListItem
          label={getTranslation('STAKING_STAKED_BALANCE')}
          value={formatCurrencyWithLabel(stakingAmount, values.currencyAmount.currency)}
          valueCurrencyCode={values.currencyAmount.currency}
        />
      </div>
      <LineDelimiter className="my-2" />
      <OperationListItem
        label={getTranslation('STAKING_REWARDS_PER_WEEK')}
        value={formatCurrencyWithLabel(rewardsWeek, values.currencyAmount.currency)}
        subValue={
          rateToDefaultCurrency
            ? formatCurrencyWithSymbol(rewardsWeekInDefCurr, defaultCurrency)
            : undefined
        }
        labelCurrencyCode={values.currencyAmount.currency}
      />
      <OperationListItem
        className="mt-2"
        label={getTranslation('STAKING_REWARDS_PER_YEAR')}
        labelCurrencyCode={values.currencyAmount.currency}
        value={formatCurrencyWithLabel(rewardsYear, values.currencyAmount.currency)}
        subValue={
          rateToDefaultCurrency
            ? formatCurrencyWithSymbol(rewardsYearInDefCurr, defaultCurrency)
            : undefined
        }
      />
      <LineDelimiter className="my-2" />
      <Field
        name="currencyAmount"
        component={CurrencyAmountField}
        label={getTranslation('PLACEHOLDER_ENTER_AMOUNT')}
        showBalance
        showMaxAmountButton
        currenciesList={currenciesList}
        underComponent={defaultCurrencyCalculatorComponent}
      />
      <div className="mt-2 row gap-1-5">
        {insufficientFunds ? (
          <>
            <Button onClick={handleBuy} fullWidth variant="lightGreen">
              {getTranslation('BUY')}
            </Button>
            <Button onClick={handleDeposit} fullWidth variant="lightGreen">
              {getTranslation('DEPOSIT')}
            </Button>
          </>
        ) : (
          <SubmitButton fullWidth variant="lightGreen">
            {getTranslation('CONTINUE')}
          </SubmitButton>
        )}
      </div>

      <AgreementText variant="renting-and-staking" className="mt-2" />
    </form>
  ) : (
    <Loader centered className="mt-5" />
  );
};
const StakingSetup: FC<StakingSetupProps> = ({ currencyCode }) => {
  const sidebar = useSideBar();
  const dispatch = useDispatch();

  const templates = useSelector(selectStakingTemplatesReducer);
  const stakingCurrencies = useSelector(selectAllowedStakingCurrencies);
  const stakingItems = useSelector(selectStakingItems);
  const wallets = useSelector(selectWallets);

  const currenciesList = useMemo(
    () => wallets.filter((w) => stakingCurrencies.includes(w.currencyCode)),
    [wallets, stakingCurrencies],
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      currencyAmount: { currency: currencyCode, amount: '' },
    }),
    [currencyCode],
  );

  useEffect(() => {
    if (canRequest(templates.meta)) {
      dispatch(requestStakingTemplates());
    }
    // eslint-disable-next-line
  }, []);
  const handleSubmit = useCallback(
    (values: FormValues) => {
      const template = templates.data.find(
        (t) => t.currencyCode === values.currencyAmount.currency,
      );
      if (!template) {
        return;
      }
      sidebar.open(
        ...sidebarTemplates.stakingOperationConfirm({
          onConfirm: async () => {
            const { success } = await dispatch(
              requestStake({ amount: +values.currencyAmount.amount, templateId: template.id }),
            );
            if (success) {
              sidebar.replaceAll(
                ...sidebarTemplates.stakingSuccessOperation({
                  amount: +values.currencyAmount.amount,
                  currencyCode: values.currencyAmount.currency,
                  percentRPY: template!.percentRPY,
                  title: getTranslation('STAKING_SUCCESS_TITLE'),
                  subtitle: getTranslation('STAKING_SUCCESS_SUBTITLE', {
                    currencyCode: values.currencyAmount.currency,
                  }),
                }),
              );
            }
          },
          currencyCode: values.currencyAmount.currency,
          checkList: [
            {
              label: getTranslation('STAKING_STAKED_CURRENCY'),
              value: getCurrencyLabelByCode(values.currencyAmount.currency, { withBrackets: true }),
              valueCurrencyCode: values.currencyAmount.currency,
            },
            {
              label: getTranslation('STAKING_STAKED_AMOUNT'),
              value: formatCurrencyWithLabel(
                values.currencyAmount.amount,
                values.currencyAmount.currency,
              ),
              valueCurrencyCode: values.currencyAmount.currency,
            },
            {
              label: getTranslation('STAKING_REWARD_PAYOUT'),
              value: getTranslation('STAKING_PAYOUT_INTERVAL'),
            },
          ],
        }),
      );
    },
    [templates.data, sidebar, dispatch],
  );

  const validate = useCallback(
    (values: FormValues) => {
      const wallet = findWallet(wallets, values.currencyAmount.currency);
      const template = templates.data.find(
        (t) => t.currencyCode === values.currencyAmount.currency,
      );

      const schema = yup.object().shape({
        currencyAmount: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .positive()
            .required(getTranslation('VALIDATION_REQUIRED'))
            .min(
              template?.minAmount || MINIMUM_AMOUNT_CRYPTO,
              getTranslation('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(
                  template?.minAmount || MINIMUM_AMOUNT_CRYPTO,
                  values.currencyAmount.currency,
                ),
              }),
            )
            .max(
              +formatCurrency(wallet?.amount || 0, false),
              getTranslation('VALIDATION_INSUFFICIENT_FUNDS'),
            )
            .nullable(true),
        }),
      });
      return makeValidate(schema)(values);
    },
    [templates, wallets],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      // @ts-ignore
      render={StakingSetupForm}
      templates={templates.data}
      stakingItems={stakingItems}
      currenciesList={currenciesList}
      validate={validate}
    />
  );
};

export default StakingSetup;
