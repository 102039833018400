import clsx from 'clsx';

import { memo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';

import { Icon } from 'components/ui';

import { TranslationKey, useTranslation } from 'libs/i18n';

import classes from './MarketingCard.module.scss';

const cards: {
  icon: string;
  title: TranslationKey;
  desc: TranslationKey;
  drawerTitle?: TranslationKey;
  drawerDescription?: TranslationKey;
  variant: MarketingCardProps['variant'];
}[] = [
  {
    variant: 'zeroRisk',
    icon: 'watch',
    title: 'LOANS_ZERO_RISK_CARD_TITLE',
    desc: 'LOANS_ZERO_RISK_LEARN_ABOUT',
    drawerTitle: 'LOANS_ZERO_RISK_TITLE',
    drawerDescription: 'LOANS_ZERO_RISK_DESCRIPTION',
  },
  {
    variant: 'earlyRepaymentFee',

    icon: 'percent2',
    title: 'LOANS_EARLY_REPAYMENT_FEE',
    desc: 'LOANS_EARLY_REPAYMENT_FEE_DESC',
  },
];

interface MarketingCardProps {
  variant: 'zeroRisk' | 'earlyRepaymentFee';
}

export const MarketingCard = memo<MarketingCardProps>(({ variant }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const card = cards.find((c) => c.variant === variant);

  if (!card) {
    return null;
  }

  return (
    <div
      className={clsx(classes.card, card.drawerTitle && 'pointer')}
      onClick={() => {
        if (!card.drawerTitle || !card.drawerDescription) {
          return;
        }
        drawer.open(
          commonDrawerTemplates.answer({
            title: translate(card.drawerTitle),
            description: translate(card.drawerDescription),
          }),
        );
      }}
    >
      <div className="row aoc gap-1">
        <Icon name={card.icon} />
        <h4>{translate(card.title)}</h4>
      </div>
      <p>{translate(card.desc)}</p>
    </div>
  );
});
