import { useCallback, useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { CryptoBankCardOrder } from 'modules/cryptoBankCard/types';
import useRate from 'modules/exchange/hooks/useRate';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';

import { Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol, getCurrencyLabelByCode, isFiat } from 'utils/currency';

import { CryptoCurrencyCode } from 'types';

export const useBankCardOrderBuilder = ({ order }: { order: CryptoBankCardOrder }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const rateToEur = useRate(order.paymentCurrency, 'EUR', { autoFetch: true });
  const paymentAmount = order.paymentAmountInEur / rateToEur || 0;

  const title = useMemo(() => {
    switch (order.preOrderedStatus) {
      case 'pending': {
        if (order.pendingReason === 'accountVerification') {
          return undefined;
        }
        if (order.paid) {
          return translate('CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_TITLE');
        }
        return translate('CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TITLE');
      }
      case 'failed': {
        return translate('CRYPTO_BANK_CARD_PRE_ORDER_CARD_FAILED_TITLE');
      }
      case 'success': {
        if (order.isVirtual) {
          return translate('CRYPTO_BANK_CARD_PRE_ORDER_CARD_VIRTUAL_TITLE');
        }
        return translate('CRYPTO_BANK_CARD_PRE_ORDER_CARD_TITLE');
      }
    }
  }, [translate, order]);

  const iconName = useMemo(() => {
    switch (order.preOrderedStatus) {
      case 'failed': {
        return 'crossInCircle';
      }
      case 'pending': {
        return 'clockInCircle';
      }
    }
  }, [order.preOrderedStatus]);

  const subtitle = useMemo(() => {
    switch (order.preOrderedStatus) {
      case 'pending': {
        if (order.pendingReason === 'accountVerification') {
          return translate('CRYPTO_BANK_CARD_ORDER_CARD_ACCOUNT_VERIFICATION_SUBTITLE');
        }
        if (order.paid && order.pendingReason === 'minDeposit' && order.orderMinDeposit) {
          return translate('CRYPTO_BANK_CARD_ORDER_CARD_MIN_AMOUNT_RULE', {
            minAmountInEur: order.orderMinDeposit,
          });
        }
        if (order.paid || !order.paymentCurrency) {
          return null;
        }
        let result = translate(
          isFiat(order.paymentCurrency)
            ? 'CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TEXT_BY_CARD'
            : 'CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TEXT',
          {
            paymentLabel: formatCurrencyWithSymbol(paymentAmount, order.paymentCurrency || 'EUR'),
            currencyLabel: getCurrencyLabelByCode(order.paymentCurrency).toLowerCase(),
          },
        );

        if (order.pendingReason === 'minDeposit' && order.orderMinDeposit) {
          result += ` ${translate('CRYPTO_BANK_CARD_ISSUANCE_RULE_REMEMBER', {
            minAmountLabel: formatCurrencyWithSymbol(order.orderMinDeposit, 'EUR'),
          })}`;
        }
        return result;
      }
      case 'failed': {
        return null;
      }
      case 'success': {
        if (order.isVirtual) {
          return translate('CRYPTO_BANK_CARD_PRE_ORDER_CARD_VIRTUAL_TEXT');
        }
        return translate('CRYPTO_BANK_CARD_PRE_ORDER_CARD_TEXT');
      }
    }
  }, [translate, paymentAmount, order]);

  const handleDeposit = useCallback(() => {
    if (!order.paymentCurrency) {
      return;
    }
    if (isFiat(order.paymentCurrency)) {
      drawer.open(
        paymentDrawerTemplates.depositOrSendByBankCard({
          isDeposit: true,
          currencyCode: order.paymentCurrency,
        }),
      );
    } else {
      drawer.open(
        paymentDrawerTemplates.depositCrypto({
          currencyCode: order.paymentCurrency as CryptoCurrencyCode,
          minimumDepositAmount: paymentAmount,
        }),
      );
    }
  }, [drawer, paymentAmount, order]);

  const depositButton =
    order.preOrderedStatus === 'pending' && !order.paid && order.paymentCurrency ? (
      <Button onClick={handleDeposit} variant="greyishGreen" size="xs">
        {translate(
          isFiat(order.paymentCurrency) ? 'CRYPTO_BANK_CARD_ORDER_PAY_BY_CARD' : 'DEPOSIT_CURRENCY',
          { currencyLabel: getCurrencyLabelByCode(order.paymentCurrency) },
        )}
      </Button>
    ) : null;

  const activateButton =
    order.preOrderedStatus === 'success' ? (
      <Button
        onClick={() => {
          drawer.open(
            commonDrawerTemplates.bankCards({
              type: 'crypto',
              props: { initialBankCardId: order.id },
            }),
          );
        }}
        variant="greyishGreen"
        size="xs"
        fullWidth
      >
        {translate(order.isVirtual ? 'CRYPTO_BANK_CARD_CONNECT' : 'ACTIVATE')}
      </Button>
    ) : null;

  return { title, subtitle, iconName, depositButton, activateButton };
};
