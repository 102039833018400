import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { selectSubscriptions } from 'modules/digitalAccount/store/selectors';
import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionStateCard } from 'modules/digitalAccount/views/components/SubscriptionStateCard';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { PaymentOperationId } from 'modules/payment/types';

import { MenuList } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import { useTranslation } from 'libs/i18n';

import { FiatCurrencyCode } from 'types';

export interface SubscriptionManagementProps {
  subscription: DigitalSubscription;
}
const SubscriptionManagement: FC<SubscriptionManagementProps> = ({ subscription }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();
  const drawer = useDrawer();

  const subscriptions = useSelector(selectSubscriptions);

  const options = useMemo<MenuListItem[]>(() => {
    const result: MenuListItem[] = [
      {
        label: translate('PAYMENT_DEPOSIT_FUNDS'),
        icon: 'transfer-arrows',
        handler: () => {
          drawer.open(
            paymentDrawerTemplates.accountDetails({
              operationId:
                subscription.payCurrencyCode === 'GBP'
                  ? PaymentOperationId.depositFromWireToMoneyWallet
                  : PaymentOperationId.depositFromSepaToMoneyWallet,
              currencyCode: (subscription.payCurrencyCode as FiatCurrencyCode) || 'EUR',
            }),
          );
        },
      },
    ];

    if (subscription.type !== 'whale') {
      result.splice(0, 0, {
        label: translate('UPGRADE'),
        icon: 'arrowUpSafe',
        handler: () => {
          if (subscription.type === 'standard') {
            sidebar.replace(...sidebarTemplates.digitalAccountChooseSubscription());
          } else {
            sidebar.replace(
              ...sidebarTemplates.digitalAccountGetSubscription({
                subscription: subscriptions.find((s) => s.type === 'whale')!,
              }),
            );
          }
        },
      });
    }
    if (subscription.type !== 'standard') {
      result.splice(subscription.type !== 'whale' ? 1 : 0, 0, {
        label: translate('DOWNGRADE'),
        icon: 'arrowDownSafe',
        handler: () => {
          if (subscription.type === 'whale') {
            sidebar.replace(...sidebarTemplates.digitalAccountChooseSubscription());
          } else {
            sidebar.replace(...sidebarTemplates.digitalAccountDowngradeSubscriptionToStandard());
          }
        },
      });

      result.push({
        label: translate('CANCEL_SUBSCRIPTION'),
        icon: 'crossSafe',
        handler: () => {
          sidebar.open(...sidebarTemplates.digitalAccountCancelSubscription({ subscription }));
        },
      });
    }

    return result;
  }, [subscriptions, drawer, subscription, sidebar, translate]);
  return (
    <div className="mt-1 column gap-4">
      <SubscriptionStateCard subscription={subscription} />
      <MenuList menu={options} />
    </div>
  );
};

export default SubscriptionManagement;
