import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { CurrencyIcon, Icon } from 'components/ui';

import { CurrencyCode } from 'types';

import classes from './CurrenciesCarousel.module.scss';

interface CurrenciesCarouselProps {
  currencies: CurrencyCode[];
  selectedCurrency?: CurrencyCode;
  onPick?: (code: CurrencyCode) => void;
  className?: string;
  variant?: 'creamy' | 'white';
  onSwiper?: (swiper: SwiperClass) => void;
  onSlideChange?: (swiper: SwiperClass) => void;
}

export const CurrenciesCarousel: FC<CurrenciesCarouselProps> = ({
  currencies,
  selectedCurrency,
  className,
  onPick,
  variant = 'white',
  onSwiper,
  onSlideChange,
}) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      const currency = event.currentTarget.dataset.code as CurrencyCode;
      if (currency && onPick) {
        onPick(currency);
      }
    },
    [onPick],
  );

  useEffect(() => {
    if (selectedCurrency && swiper) {
      swiper.slideTo(
        currencies.findIndex((c) => c === selectedCurrency),
        1000,
      );
    }
    // eslint-disable-next-line
  }, [selectedCurrency]);

  const handleSwiper = useCallback(
    (swiperInstance: SwiperClass) => {
      setSwiper(swiperInstance);
      onSwiper?.(swiperInstance);
    },
    [onSwiper],
  );

  return (
    <Swiper
      onSwiper={handleSwiper}
      spaceBetween={4}
      slidesPerView="auto"
      className={clsx(classes.root, classes['variant-' + variant], className)}
      onSlideChange={onSlideChange}
    >
      {currencies.map((currencyCode) => (
        <SwiperSlide key={currencyCode} className={classes.slide}>
          <div
            onClick={handleClick}
            data-code={currencyCode}
            className={clsx(
              classes.currencyCard,
              currencyCode === selectedCurrency && classes.active,
            )}
          >
            <CurrencyIcon size={18} code={currencyCode} />
            <span className={classes.label}>{currencyCode}</span>
            {currencyCode === selectedCurrency && (
              <Icon color="creamyWhite" className={classes.activeIcon} name="check" size="xs" />
            )}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
