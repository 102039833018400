import throttle from 'lodash/throttle';

import { UIEventHandler, useCallback } from 'react';

const useInfinityScrollSimplified = ({
  loadMore,
  threshold = window.innerHeight / 2,
  loading,
  hasMore,
}: {
  loadMore: () => Promise<void>;
  threshold?: number;
  loading: boolean;
  hasMore: boolean;
}) => {
  const fetchEntities = useCallback(async () => {
    if (!hasMore || loading) {
      return;
    }
    await loadMore();
  }, [loadMore, hasMore, loading]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInfinityScrollThrottled = useCallback(
    throttle(
      async (height, offset, top) => {
        const currentThreshold = height - offset - top;
        if (currentThreshold < threshold) {
          await fetchEntities();
        }
      },
      500,
      { leading: false, trailing: true },
    ),
    [threshold, fetchEntities],
  );
  const onInfinityScroll = useCallback<UIEventHandler<HTMLDivElement>>(
    (event) => {
      onInfinityScrollThrottled(
        // @ts-ignore
        event.target.scrollHeight,
        // @ts-ignore
        event.target.offsetHeight,
        // @ts-ignore
        event.target.scrollTop,
      );
    },
    [onInfinityScrollThrottled],
  );

  return { onInfinityScroll };
};

export default useInfinityScrollSimplified;
