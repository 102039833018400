import throttle from 'lodash/throttle';

import { UIEvent, useCallback, useEffect, useState } from 'react';

import useFlag from 'hooks/useFlag';

const useInfinityScroll = <Entity>({
  loadMore,
  pageSize = 10,
  threshold = window.innerHeight / 2,
}: {
  loadMore: (page: number, pageSize: number) => Promise<Entity[] | null>;
  pageSize?: number;
  threshold?: number;
}) => {
  const loading = useFlag(false);
  const hasMore = useFlag(true);
  const [page, setPage] = useState(0);
  const [entities, setEntities] = useState<Entity[]>([]);

  const fetchEntities = useCallback(
    async ({ pageNumber }: { pageNumber: number }) => {
      if (!hasMore.state && pageNumber !== 0) {
        return;
      }
      loading.on();
      const newEntities = await loadMore(pageNumber, pageSize);
      if (newEntities) {
        if (newEntities.length < pageSize) {
          hasMore.off();
        } else {
          hasMore.on();
        }
        setEntities((prev) => (pageNumber === 0 ? newEntities : [...prev, ...newEntities]));
      }
      loading.off();
    },
    [loadMore, pageSize, hasMore, loading],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInfinityScrollThrottled = useCallback(
    throttle(
      (height, offset, top) => {
        const currentThreshold = height - offset - top;

        if (currentThreshold < threshold && !loading.state) {
          setPage((prev) => prev + 1);
        }
      },
      500,
      { leading: false, trailing: true },
    ),
    [threshold, loading.state],
  );
  const onInfinityScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      onInfinityScrollThrottled(
        event.currentTarget.scrollHeight,
        event.currentTarget.offsetHeight,
        event.currentTarget.scrollTop,
      );
    },
    [onInfinityScrollThrottled],
  );

  useEffect(() => {
    fetchEntities({ pageNumber: page });

    // eslint-disable-next-line
  }, [page]);

  const refreshList = useCallback(() => {
    setEntities([]);
    if (page === 0) {
      fetchEntities({ pageNumber: 0 });
    } else {
      setPage(0);
    }
  }, [fetchEntities, page]);

  return {
    isLoading: loading.state,
    hasMore: hasMore.state,
    list: entities,
    onInfinityScroll,
    refreshList,
    page,
  };
};

export default useInfinityScroll;
