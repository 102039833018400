export default {
  // auth
  WELCOME_TITLE: 'Send and receive money effortlessly all over the world',
  SIGN_IN_ENTER_OTP_TITLE: 'Enter login code',
  SIGN_UP_ENTER_OTP_TITLE: 'Verify your email with a code',
  SIGN_ENTER_OTP_SUBTITLE_EMAIL:
    'Please enter the 6-digit confirmation code that was sent to your email<b> %{value}</b>.',
  SIGN_ENTER_OTP_SUBTITLE_PHONE:
    'Please enter the %{codeLength}-digit confirmation code that was sent to your phone<b> %{value}</b>.',
  SIGN_UP_ENTER_PHONE_OTP_TITLE: 'Verify your phone number with a code',
  SIGN_UP_SELECT_COUNTRY_TITLE: 'What’s your country of residence?',
  SIGN_UP_SELECT_COUNTRY_SUBTITLE:
    'This information will later be used for your identity verification.',
  SIGN_UP_ENTER_PHONE_NUMBER_TITLE: 'Enter your phone number',
  SIGN_UP_ENTER_PHONE_NUMBER_SUBTITLE: 'We will send you a verification code.',
  SIGN_UP_SELECT_COUNTRY: 'Select country',
  SIGN_UP_COUNTRY_SEARCH: 'Search for your country of residence',

  SIGN_ENTER_EMAIL_TITLE: 'What’s your email?',
  SIGN_ENTER_EMAIL_SUBTITLE: 'Enter your email to receive a temporary login code.',
  SIGN_SEND_OTP_BY_PHONE_TITLE: 'Security improvement',
  SIGN_SEND_OTP_BY_PHONE_SUBTITLE: 'Would you like to receive the OTP codes by <b>phone</b>?',
  SIGN_SEND_OTP_BY_PHONE_NUMBER_ATTACHMENT:
    "OTP's will be sent to your phone number: <b>%{phoneNumber}</b>",
  SIGN_WITH_TFA_TITLE: 'Log in with 2FA',
  SIGN_WITH_TFA_SUBTITLE: 'Please enter the 2FA code from your authenticator app.',
  SIGN_WITH_TFA_HAVING_ISSUES: 'Having issues with 2FA?',
  SIGN_WITH_TFA_HAVING_ISSUES_SUBTITLE: 'Choose how to recover your 2FA login.',
  SIGN_UP_ENTER_NAME_TITLE: 'What’s your name?',
  SIGN_UP_ENTER_NAME_SUBTITLE:
    'This information will later be used for your identity verification.',
  SIGN_SIGN_UP: 'Open Account',
  SIGN_SIGN_IN: 'Log in',
  SIGN_OPEN_BUSINESS_ACCOUNT_P1: 'Open',
  SIGN_OPEN_BUSINESS_ACCOUNT_P2: 'a Business Account',
  SIGN_ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  SIGN_DONT_HAVE_ACCOUNT: "Don't have an account?",
  SIGN_UP_EMAIL_SENT: 'Email with confirmation code sent',
  SIGN_UP_CONFIRM_TITLE: 'Confirm your email',
  SIGN_UP_SUCCESS: 'You have successfully registered',
  SIGN_UP_EMAIL_NOTE:
    'Please use an email that is registered with your social networks and various services.',
  BACK_TO_LOGIN: 'Back to Log in',
  BACK_TO_SIGN_UP: 'Back to Sign up',
  SIGN_IN_OTP_EMAIL_SENT: 'One time password sent to your email',
  SIGN_IN_OTP_PHONE_SENT: 'One time password sent to your phone',
  SIGN_IN_OTP_CONFIRM: 'Enter one time password',

  SING_UP_ACCOUNT_TYPE_TITLE: 'What kind of account would you like to open?',
  SING_UP_ACCOUNT_TYPE_SUBTITLE: 'You can add another account later, too.',
  SING_UP_ACCOUNT_TYPE_PERSONAL_TITLE: 'Personal account',
  SING_UP_ACCOUNT_TYPE_BUSINESS_TITLE: 'Business account',

  // tfa
  TFA_TITLE: 'Two-Factor Authentication',
  TFA_DISABLE_TITLE: 'Disable Two-Factor Authentication',
  TFA_DISABLE_SUBTITLE:
    'Please enter the Google Authenticator 6-digit code to disable Two-Factor Authentication',
  TFA_DISABLE_SUCCESS: '2FA Disabled',
  TFA_RESET: 'Emergency reset',
  TFA_RESET_TITLE: 'Enter emergency backup code',
  TFA_RESET_SUBTITLE:
    'Please copy and paste your emergency backup code that was generated when setting up 2FA.',
  TFA_EMERGENCY_RESET_CODE_TITLE: 'Emergency backup code',
  TFA_EMERGENCY_RESET_CODE_SUBTITLE: 'Save this emergency backup code and store it somewhere safe.',
  TFA_EMERGENCY_RESET_CODE_PLACEHOLDER: 'Emergency backup code',
  TFA_EMERGENCY_RESET_SUCCESS: '2FA Reset complete',
  TFA_SETUP_TITLE: 'Use an authenticator app',
  TFA_SETUP_SUBTITLE:
    'Download the free <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US" rel="noopener noreferrer" target="_blank">Google Authenticator app</a>, add a new account and paste this key or scan the QR code to set up your account.',
  TFA_SETUP_KEY: 'Setup key',
  TFA_SETUP_ALTERNATIVE: 'or scan QR code',
  TFA_SETUP_CONFIRM_CODE_SUBTITLE:
    'Please enter the Google Authenticator 6-digit code to finish setting up your account.',
  TFA_ENTER_CODE_PLACEHOLDER: 'Enter Authenticator code',
  TFA_SETUP_SUCCESS: '2FA Setup Complete',

  // terms
  SIGN_AGREEMENT_P1: 'By opening a Nebeus account you agree to our ',
  SIGN_IN_AGREEMENT_P1: 'By signing in you agree to our ',
  PROCEEDING_AGREEMENT_P1: 'By proceeding you agree to our ',
  TERMS_OF_USE: 'Terms of Use',
  TERMS_OF_BUSINESS: 'Terms of Business',
  FEE_AND_LIMITS: 'Fees and Limits',
  FEES_AND_CONDITIONS: 'Fees & Conditions',
  TERMS_OF_MODULR_BUSINESS: 'Modulr Terms of Business',

  // placeholders
  PLACEHOLDER_EMAIL: 'Email',
  PLACEHOLDER_TFA_CODE: '6-digit code',
  PLACEHOLDER_COUNTRY: 'Country',
  PLACEHOLDER_PHONE_NUMBER: 'Enter phone number',
  PLACEHOLDER_WHERE_DO_YOU_LIVE: 'Where do you live',
  PLACEHOLDER_HIDE_ZERO_BALANCES: 'Hide zero balances',
  PLACEHOLDER_SHOW_ZERO_BALANCES: 'Show zero balances',
  PLACEHOLDER_HISTORY: 'History',
  PLACEHOLDER_ENTER_AMOUNT: 'Enter amount',
  PLACEHOLDER_SUFFIX_MONTHS: 'month(s)',
  PLACEHOLDER_SEARCH_CURRENCIES: 'Search currencies',
  PLACEHOLDER_LIMITS_LABEL: 'Between %{minLabel} and %{maxLabel}',
  PLACEHOLDER_YOUR_COUNTRY: 'Your country',
  PLACEHOLDER_OTP_CODE: 'Temporary login code',
  PLACEHOLDER_DATE_FORMAT_1: 'DD.MM.YYYY',
  PLACEHOLDER_CVC_CODE: 'Enter the cvc code',
  PLACEHOLDER_ADDRESS_STREET_AND_NUMBER: 'Street and number',
  PLACEHOLDER_ADDRESS_MORE_DETAILS: 'More address details (optional)',

  // dates
  DATE_TODAY: 'Today',
  DATE_YESTERDAY: 'Yesterday',

  // common
  ACCEPT: 'Accept',
  DENY: 'Deny',
  DENY_QUESTION: 'Deny?',
  NO_QUESTION: 'No?',
  ARE_YOU_SURE: 'Are you sure?',
  SUBMIT: 'Submit',
  CONFIRM: 'Confirm',
  CONFIRM_AND_FINISH: 'Confirm & Finish',
  CONFIRMED: 'Confirmed',
  CONFIRMATION: 'Confirmation',
  CONTINUE: 'Continue',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  CLOSE: 'Close',
  BACK: 'Back',
  SUCCESS: 'Success',
  'SUCCESS!': 'Success!',
  COPIED: 'Copied',
  NEXT: 'Next',
  CANCEL: 'Cancel',
  APPLY: 'Apply',
  CANCELLED: 'Cancelled',
  SAVE: 'Save',
  MORE: 'More',
  DASHBOARD: 'Dashboard',
  ACCOUNTS: 'Accounts',
  ACCOUNT: 'Account', // Account like wallet. F.e. business account. - !NOT USER ACCOUNT!
  CURRENCY_ACCOUNT: '%{currencyCode} Account', // Account like wallet. F.e. BTC account. - !NOT USER ACCOUNT!
  EXCHANGE_RATE: 'Exchange rate',
  RATE: 'Rate',
  DEFAULT_CURRENCY: 'Default currency',
  LANGUAGE: 'Language',
  LEARN_MORE: 'Learn more',
  NEW: 'New',
  ACTIVE: 'Active',
  CLOSED: 'Closed', // f.e. Loan closed
  EXPIRED: 'Expired',
  TERM: 'Term', // f.e (Term: 1 month)
  IN_CURRENCY: 'In', // f.e (In EUR, in USD etc)
  SEARCH: 'Search', // using with icon magnifying glass
  LONG_PRESS_TO_CONFIRM: 'Long press to confirm',
  FEE: 'Fee', // commission
  FREE: 'Free',
  IMPORTANT: 'Important',
  GET_CONFIRMATION_CODE: 'Get Confirmation Code', // f.e. in submit withdraw funds forms
  ASSETS: 'Assets',
  OVERVIEW: 'Overview',
  ALL_CURRENCIES: 'All currencies',
  BUY: 'Buy',
  SELL: 'SELL',
  HISTORY: 'History', // Context:  Transaction history
  AMOUNT: 'Amount',
  YES: 'Yes',
  NO: 'No',
  NEXT_PAYMENT_AMOUNT: 'Next payment amount',
  NEXT_PAYMENT_DATE: 'Next payment date',
  NEXT_PAYMENT: 'Next payment',
  TRANSACTION: 'Transaction',
  TRANSACTIONS: 'Transactions',
  NO_TRANSACTIONS_YES: 'No transactions yet',
  PAID: 'Paid',
  MINIMUM_DEPOSIT: 'Minimum deposit',
  DEPOSIT_CURRENCY: 'Deposit %{currencyLabel}', // F.e. Deposit Bitcoin
  SEND_CURRENCY: 'Send %{currencyLabel}', // F.e. Send Bitcoin
  NEBEUS_WALLET: 'Nebeus %{currency} wallet', // F.e. Nebeus BTC wallet
  NEBEUS_ACCOUNT: 'Nebeus %{currencyCode} account', // F.e. Nebeus BTC account
  PENDING: 'Pending',
  'PENDING...': 'Pending...',
  PAYOUT_INTERVAL: 'Payout interval',
  CHOOSE_YOUR_SUBSCRIPTION: 'Choose your subscription',
  UPGRADE_YOUR_SUBSCRIPTION: 'Upgrade your subscription',
  DOWNGRADE_YOUR_SUBSCRIPTION: 'Downgrade your subscription',
  PRICE_PER_MONTH_SHORT: '%{currency}%{amount}/mo.',
  PRICE_PER_MONTH: '%{currency}%{amount}/month',
  FREE_LIFETIME_PLAN: 'Free lifetime plan',
  LIFETIME: 'Lifetime',
  SUBSCRIPTIONS: 'Subscriptions',
  SUBSCRIPTION: 'Subscription',
  MONTHLY_PAYMENT: 'Monthly payment',
  ONE_TIME_PAYMENT: 'One time payment',
  PAYMENT_ACCOUNT: 'Payment account',
  SUBSCRIPTION_MONTHLY_AND_CANCEL_ANYTIME: 'Monthly, cancel anytime',
  PURCHASED: 'Purchased',
  OPENED: 'Opened', // F.e account opened,
  PAYMENT_PENDING: 'Payment pending',
  BACK_TO_DASHBOARD: 'Back to dashboard',
  BACK_TO_SUBSCRIPTIONS: 'Back to subscriptions',
  ACTIVE_UNTIL: 'Active until',
  PURCHASE_NEW_PLAN: 'Purchase new plan',
  VERIFICATION_PENDING: 'Verification pending',
  CANCEL_SUBSCRIPTION: 'Cancel subscription',
  UPGRADE: 'Upgrade',
  DOWNGRADE: 'Downgrade',
  GO_TO_CARD: 'Go to card', // Bank card
  GO_TO_CARD_SECTION: 'Go to card section', // Bank card
  YOUR_TOTAL_VALUE: 'Your total value',
  BANK_NAME: 'Bank name',
  ACCOUNT_DETAILS: 'Account details',
  GET_MORE_FROM_NEBEUS: 'Get more from Nebeus',
  CARDS: 'Cards',
  DOWNGRADE_SUBSCRIPTION: 'Downgrade Subscription',
  COMPLETED: 'Completed',
  ENTER_AMOUNT: 'Enter amount',
  SELECT_PAIR: 'Select pair',
  SELECT_ASSET: 'Select asset',
  SELECT_CURRENCY: 'Select currency',
  SUMMARY: 'Summary',
  ALLOW_PUSH_NOTIFICATIONS: 'Allow push notifications',
  FROM_ADDRESS: 'From address',
  TO_ADDRESS: 'To address',
  NETWORK: 'Network',
  NETWORK_DETAILS: 'Network details',
  SELECT_NETWORK: 'Select Network',
  TRANSACTION_HASH: 'Transaction hash',
  TRANSACTION_ID: 'Transaction ID',
  'DATE_&_TIME': 'Date & Time',
  OPERATION_AMOUNT: 'Operation Amount',
  DESCRIPTION: 'Description',
  DOWNLOAD_PDF: 'Download PDF',
  RECIPIENT: 'Recipient',
  RECIPIENT_EMAIL: "Recipient's email",
  RECIPIENT_DETAILS: 'Recipient details',
  RECIPIENTS_NAME: "Recipient's name",
  RECIPIENT_ADDRESS: 'Recipient address',
  COST: 'Cost',
  SENDER: 'Sender',
  SENDER_EMAIL: "Sender's email",
  SENDER_BANK_CARD_NUMBER: "Sender's bank card number",
  RECIPIENTS_IBAN: 'Recipient’s IBAN',
  RECIPIENTS_ACCOUNT_NUMBER: 'Recipient’s account number',
  RECIPIENTS_BANK_CARD_NUMBER: 'Recipient’s Bank Card Number',
  SELECT_A_QUESTION: 'Select a question',
  QUESTION: 'Question',
  WRITE_AN_ANSWER: 'Write an answer',
  ANSWER: 'Answer',
  ENTER_THE_FIELDS: 'Enter the fields',
  CONTACT_SUPPORT: 'Contact support',
  ENTER_BACKUP_CODE: 'Enter backup code',
  REQUEST_BUSINESS_ACCOUNT: 'Request business account',
  NOT_LIMITED: 'Not limited',
  TEMPORARILY_UNAVAILABLE: 'Temporarily unavailable',
  ACKNOWLEDGE_AND_CLOSE: 'Acknowledge and Close',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  DATE_OF_BIRTH: 'Date of Birth',
  PHONE_NUMBER: 'Phone number',
  CITY: 'City',
  POSTCODE: 'Postcode',
  SURNAME: 'Surname',
  EDIT: 'Edit',
  SELECT: 'Select',
  SELECT_ALL: 'Select all',
  CANCEL_SELECTION: 'Cancel selection',
  REMOVE: 'Remove',
  FULL_NAME: 'Full name',
  EMAIL: 'Email',
  COUNTRY: 'Country',
  ADDRESS: 'Address',
  DATE_FROM: 'Date from',
  DATE_TO: 'Date to',
  PASSCODE: 'Passcode',
  DELIVERY: 'Delivery',
  TOTAL_AMOUNT: 'Total amount',
  TOTAL_AMOUNT_IN_CURRENCY: 'Total amount in %{currencyCode}',
  SELECT_ACCOUNT_FOR_PAYMENT: 'Select account for payment',
  EXPLORE: 'Explore',
  CARD_ISSUE: 'Card issue',
  ORDER_NOW: 'Order now',
  SCAN: 'Scan',
  AND: 'and', // Terms of Use and Fees and Limits
  ACTIVATION_REQUIRED: 'Activation required',
  ACCOUNT_CONNECTION_REQUIRED: 'Account connection required', // Account like wallet
  ACTIVATE: 'Activate',
  ACTIVATE_CARD: 'Activate card',
  CVC_CODE: 'CVC code',
  HIDE_ZERO_BALANCE_ACCOUNTS: 'Hide zero balance accounts',
  SHOW_ZERO_BALANCE_ACCOUNTS: 'Show zero balance accounts',
  AVAILABLE_ACCOUNTS: 'available accounts',
  THIS_MONTH_EXPENSES: "This month's expenses",
  DATE_RANGE_IN_DAYS: '%{day1}-%{day2} days',
  DUE_DATE: 'Due date',
  CHANGE: 'Change',
  QUANTITY: 'Quantity',
  QUANTITY_SHORT: 'Qty',
  ADD: 'Add',
  UPDATE: 'Update',
  DISCOUNT: 'Discount',
  TOTAL: 'Total',
  GET: 'Get',
  REASON: 'Reason',
  SAVE_CHANGES: 'Save changes',
  SAVE_AS_PDF: 'Save as pdf',
  SHARE: 'Share',
  OPTIONAL_WITH_STAR: '*Optional',
  OPTIONAL: 'Optional',
  ACTIONS: 'Actions',
  HAPPY_BIRTHDAY: 'Happy birthday!',
  ADD_NEW_ADDRESS: 'Add new address',
  NEW_ADDRESS: 'New address',
  CURRENCIES: 'Currencies',
  HELP_CENTER: 'Help Center',
  EMAIL_CONFIRMATION: 'E-mail confirmation',
  SMS_CONFIRMATION: 'SMS confirmation',
  ALL_FEES_INCLUDED: 'All fees included',
  WALLET_DETAILS: 'Wallet details',
  SELECT_ACCOUNT: 'Select account',
  CHARGE_ACCOUNT: 'Charge account',
  CHARGE_AMOUNT: 'Amount of charge',
  ORDER_CARD: 'Order a card',
  ANY_EXPENSES: 'Any expenses',
  GOT_IT_THANKS: 'Got it, thanks',
  YOUR_ORDER: 'Your order',
  ACTION_REQUIRED: 'Action required',
  BETA: 'Beta',
  SEARCH_ADDRESS: 'Search address',
  OR: 'or', // F.e. Search address or Enter address manually
  TYPE_TO_SEARCH: 'Type to search',
  LOADING_WITH_DOTS: 'Loading...',
  IMPORTANT_NEWS: 'Important news!',
  CREATE: 'Create',
  TOKENS_COUNT: '%{count} tokens',
  NOT_ALLOWED: 'Not allowed',
  FIXED: 'Fixed',
  ID: 'ID',
  NEBEUS_USER: 'Nebeus user',
  BANK_CARD: 'Bank card',
  SEPA_ACCOUNTS: 'IBAN (SEPA) Accounts',
  WIRE_ACCOUNTS: 'GBP (WIRE) accounts',
  EXTERNAL_CRYPTO_WALLETS: 'External crypto wallets',
  ENTER_DETAILS: 'Enter details',
  SELECT_DATE: 'Select date',
  QUESTIONNAIRE: 'Questionnaire',
  SKIP: 'Skip',
  OTHER: 'Other',
  PROMO: 'Promo',
  START_NOW: 'Start now',

  // validation
  VALIDATION_LENGTH: 'Please, enter %{length} symbols',
  VALIDATION_REQUIRED: 'Field is required',
  VALIDATION_NO_SPACES: 'Field must be without spaces',
  VALIDATION_FIELD_INVALID: 'Field is invalid',
  VALIDATION_NAME_TWO_WORDS: 'Full name must consist of at least two words',
  VALIDATION_MIN: 'Field must be at least %{min} characters',
  VALIDATION_MAX: 'Field must contain a maximum of %{max} characters',
  VALIDATION_CONTAIN_NUMBER: 'Field must contain number',
  VALIDATION_EMAIL: 'Email is not valid',
  VALIDATION_ONLY_LETTERS: '%{fieldName} can only contain letters',
  VALIDATION_ONLY_LETTERS_AND_NUMBERS: '%{fieldName} can only contain letters and numbers',
  VALIDATION_SELECT_NO_OPTIONS: 'No options',
  VALIDATION_COUNTRY_INCORRECT: 'Invalid country value',
  VALIDATION_INSUFFICIENT_FUNDS: 'Insufficient funds',
  VALIDATION_MIN_AMOUNT: 'Amount must be greater than or equal to %{minLabel}',
  VALIDATION_MAX_AMOUNT: 'Amount must be less than or equal to %{maxLabel}',
  VALIDATION_WITHDRAW_LIMITS_REACHED: 'Withdraw limits reached',
  VALIDATION_BANK_CARD_NUMBER_INVALID: 'Invalid card number',
  VALIDATION_BANK_CARD_EXP_DATE_INVALID: 'Expiration date is invalid',
  VALIDATION_DIGITS_COUNT: 'Field must contain %{count} digits',
  VALIDATION_INVALID_IBAN: 'IBAN is invalid',
  VALIDATION_PHONE_INVALID: 'Phone number is invalid',
  VALIDATION_CVC_CODE_INCORRECT: 'Incorrect CVC code',
  VALIDATION_FIELD_ONLY_NUMBERS: 'Field must contain only numbers',
  VALIDATION_FIELD_MUST_BE_NUMBER: 'Field must be a valid number',
  VALIDATION_NEW_PIN_SAME_AS_OLD: 'New PIN code cannot be the same as the old',
  VALIDATION_GREATER_THAN_ZERO: 'Field must be greater than zero',
  VALIDATION_DATE_FUTURE: 'Date must not be in the future',
  VALIDATION_IBAN_OUTCOME_ADDRESS:
    "Field should only contain alphanumerics (at least one), hyphens (-), apostrophes ('), commas (,), dots (.), solidus (/) and spaces.",

  // layout
  HEADER_OPERATIONS: 'Operations',
  NEBEUS_PRODUCTS: 'Nebeus products',
  TOTAL_ASSETS_TITLE: 'Total assets',

  WALLETS: 'Wallets',
  WALLET: 'Wallet',
  IBAN: 'IBAN',
  EARNING: 'Earning',
  VAULTS: 'Vaults',
  LOAN_COLLATERAL: 'Loan Collateral',
  MONEY: 'Money',
  CRYPTO: 'Crypto',
  FIAT: 'Fiat',

  // payment
  SEND: 'Send',
  DEPOSIT: 'Deposit',
  WITHDRAW: 'Withdraw',
  PAYMENT_METHOD: 'Payment method',
  PAYMENT_METHODS: 'Payment methods',
  PAYMENT_METHOD_NEBEUS_IBAN_ACCOUNT: 'to Nebeus IBAN account',
  PAYMENT_METHOD_NEBEUS_CRYPTO_ACCOUNT: 'to Nebeus crypto account',
  PAYMENT_METHOD_NEBEUS_CASH_ACCOUNT: 'to Nebeus cash account',
  PAYMENT_OR_ENTER_DETAILS: 'Or enter details',
  PAYMENT_WITHDRAWAL_LARGE_AMOUNT_CRYPTO_WARNING:
    'Withdrawals of large amounts of cryptocurrency may take up to 48 hours to process.',
  PAYMENT_WITHDRAWAL_LARGE_AMOUNT_FIAT_WARNING:
    'Withdrawals of large amounts of fiat currency may take up to 48 hours to process.',
  // exchange
  EXCHANGE: 'Exchange',
  EXCHANGE_FROM: 'From',
  EXCHANGE_TO: 'To',
  ENTER_MAX_AMOUNT_BUTTON: 'Enter max amount',
  BALANCE: 'Balance',
  EXCHANGE_OPERATION_SUCCESSFULLY: 'Completed successfully',

  // user
  PROFILE: 'Profile',
  PROFILE_PERSONAL_DATA: 'Personal data',
  SETTINGS: 'Settings',
  PROFILE_LOGOUT: 'Log Out',
  SECURITY: 'Security',
  NOTIFICATIONS: 'Notifications',
  PUSH_NOTIFICATIONS: 'Push notifications',

  PROFILE_CHAT_SUPPORT: 'Chat Support',
  PROFILE_PRIVACY_POLICY: 'Privacy Policy',
  PROFILE_CLOSE_ACCOUNT: 'Close account',
  PROFILE_EMAIL_NEWS_NOTIFICATION: 'Receive news by email',

  PROFILE_SAVED_BANK_CARDS_TITLE: 'Bank cards',
  PROFILE_SAVED_CRYPTO_WALLETS_TITLE: 'Digital wallets',
  PROFILE_SAVED_CRYPTO_WALLETS_EDIT_TITLE: 'Edit your digital wallet',
  PROFILE_SAVED_CRYPTO_WALLETS_EDIT_SUBTITLE:
    'Update your digital wallet address here to save it to your Nebeus account.',
  PROFILE_SAVED_CRYPTO_WALLETS_ADD_TITLE: 'Add a digital wallet',
  PROFILE_SAVED_CRYPTO_WALLETS_ADD_SUBTITLE:
    'Enter your digital wallet address here to save it to your Nebeus account.',

  REFERRAL_LINKS: 'Referral links',
  REFERRAL_LINK: 'Referral link',
  REFERRAL_LINK_NEBEUS_PROGRAM: 'Nebeus referral program',
  REFERRAL_LINK_GENERATE: 'Generate referral link',
  REFERRAL_LINK_MARKETING_AD_TITLE: 'Invite Friend, Earn €20!',
  REFERRAL_LINK_MARKETING_AD_SHORT_TITLE: 'Earn €20!',
  REFERRAL_LINK_MARKETING_AD_SUBTITLE: 'Invite friend to join Nebeus using your referral link.',
  REFERRAL_LINK_MARKETING_AD_CONDITION_TITLE: 'Your referral must',
  REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_1: 'Sign up with your link',
  REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_2: 'Verify identity',
  REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_3: 'Order the Nebeus Card',
  REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_4: 'Spend at least €5 without ATM withdrawals',
  REFERRAL_LINK_MARKETING_AD_TEXT:
    "When your friend completes these required conditions, you'll earn a fantastic €20 reward! Share the love, reap the rewards",
  REFERRAL_LINK_MARKETING_AD_COPY_BUTTON: 'Copy Referral Link',
  REFERRAL_LINK_COPIED: 'Referral link copied',
  REFERRAL_LINK_INVITE_SENT: 'Invite sent',
  REFERRAL_LINK_INVITE_TEXT:
    'Join me and a thriving community of users who love Nebeus! Sign up with my link',
  REFERRAL_LINK_MAX_USING_CONDITION: 'A maximum of 5 users can use the same referral code.',

  // verification
  VERIFICATION: 'Verification',
  VERIFICATION_ID_VERIFICATION: 'ID Verification',
  VERIFICATION_TOKEN_EXPIRED: 'Your verification token expired. Please, contact our support',
  VERIFICATION_SUBTITLE: 'You need to verify your identity before using Nebeus',
  VERIFICATION_ALREADY_VERIFIED: 'You are already verified',
  VERIFICATION_VERIFIED: 'Verified',
  VERIFICATION_NOT_VERIFIED: 'Not verified',
  VERIFICATION_VERIFY: 'Verify',
  VERIFICATION_VERIFY_IDENTITY: 'Verify identity',
  VERIFICATION_COUNTRY_NOT_ALLOWED: 'Sorry, the service is no longer available in your country',

  // accounts
  CRYPTO_ACCOUNT: 'Crypto Account',
  ACCOUNTS_TOTAL_VALUE: 'Total value',
  ACCOUNTS_FUND_DA: 'Fund IBAN Account',
  ACCOUNTS_FUND_CA: 'Fund Crypto Account',
  ACCOUNTS_ACCOUNT_STATEMENT: 'Account Statement',
  ACCOUNTS_OPEN_ACCOUNT: 'Open account', // Create wallet
  ACCOUNT_STATEMENTS: 'Statements',
  ACCOUNT_STATEMENT_DOWNLOAD: 'Download account statement (.pdf)',
  ACCOUNT_STATEMENT: 'Account statement',
  ACCOUNT_STATEMENT_DESC:
    'Download an account statement showing all movements of funds for a specific currency within a selected timeframe. You can download the statement in PDF format.',
  ACCOUNT_STATEMENT_DOWNLOADED: 'Statement was successfully downloaded',

  ACCOUNT_TRANSACTIONS_HISTORY: 'Transactions history',
  ACCOUNT_TRANSACTIONS_HISTORY_EMPTY: 'You have no transactions yet',
  ACCOUNT_TRANSACTIONS_SEE_ALL: 'See all',
  ACCOUNT_TRANSACTIONS_LAST: 'Last Transactions',
  ACCOUNTS_WALLET_NOT_FOUND: 'Wallet for %{currencyCode} not found',
  ACCOUNT_TRANSACTION_STATUS_PROCESSING: 'Processing',
  ACCOUNT_TRANSACTION_STATUS_WAIT_FOR_APPROVE: 'Waiting for Approval',
  ACCOUNT_TRANSACTION_STATUS_WAIT_FOR_BLOCK_CONFIRM: 'Awaiting Block Confirmations',
  ACCOUNT_TRANSACTION_STATUS_COMPLETED: 'Completed',
  ACCOUNT_TRANSACTION_STATUS_FAIL: 'Unsuccessful',
  ACCOUNT_TRANSACTION_STATUS_REJECTED: 'Rejected',
  ACCOUNT_TRANSACTION_STATUS_REFUNDED: 'Refunded',
  ACCOUNT_TRANSACTION_STATUS_CANCELLED: 'Cancelled',
  ACCOUNTS_OPERATION_DETAILS: 'Operation details',
  ACCOUNTS_GET_A_RECEIPT: 'Get a receipt',
  ACCOUNT_OPERATION_STATUS: 'Operation status',

  // crypto-renting
  CRYPTO_RENTING: 'Crypto Renting',
  RENTING: 'Renting',
  RENTING_TOTAL_ASSETS_LABEL: 'Total Value in Crypto-Renting',
  RENTING_REWARDS_30DAYS: '30-day rewards',
  RENTING_REWARDS_60DAYS: '60-day rewards',
  RENTING_NEXT_REWARDS: 'Reward (in %{days} days)',
  RENTING_FAQ_TITLE: 'What is Crypto-Renting?',
  RENTING_APP_OPERATIONS_DESC: 'Earn up to %{rpy}% RPY',
  RENTING_FAQ_SUBTITLE: 'Discover how to earn rewards<br/>on your Crypto. ',
  RENTING_FAQ_DESCRIPTION:
    'Crypto-Renting allows you to earn rewards on your crypto by renting your assets to Nebeus. You can earn rewards in the same deposited cryptocurrency or a completely different currency of your choice.<br/>' +
    '<br/>' +
    'Nebeus has four different Crypto-Renting options, each with its unique lock-up periods and minimum deposit requirements.<br/>' +
    '<br/>' +
    '<b>RPY = Rewards-Per-Year.</b> <br/>' +
    '<br/>' +
    '<b>RPY</b> means the total amount paid by Nebeus for the rental of crypto assets, expressed as an annual percentage of the value of the crypto assets. ',
  RENTING_FAQ_DESCRIPTION_ALTERNATIVE:
    'Crypto-Renting allows you to <b>earn rewards on your crypto by renting your assets to Nebeus.</b> You can earn rewards in the same deposited cryptocurrency or a completely different currency of your choice.<br/>' +
    '<br/>' +
    'Nebeus has four different Crypto-Renting options, each with its unique lock-up periods and minimum deposit requirements.',
  RENTING_TAB_NEW: 'New',
  RENTING_TAB_ACTIVE: 'Active',
  RENTING_TAB_HISTORY: 'History',
  RENTING_RPY: 'RPY',
  RENTING_EARN_PERCENT: 'Earn %{percent}%', // Earn 12%
  RENTING_TERM: 'Term',
  RENTING_AVAILABLE_CURRENCIES: '%{count} available currencies',
  RENTING_WITHDRAW_CURRENCIES: 'Payout available in %{count} currencies',
  RENTING_RENT_AMOUNT: 'Rent amount',
  RENTING_RENT_CURRENCY: 'Rent currency',
  RENTING_MONTHLY_PAYOUT: 'Monthly payout',
  RENTING_LOCKUP_PERIOD: 'Lock-up period',
  RENTING_YEARLY_PERCENTAGE: 'Yearly percentage',
  RENTING_TOTAL_EARNINGS: 'Total earned',
  RENTING_PAYOUT_SCHEDULE: 'Payout schedule',
  RENTING_REWARD_PAYOUT: 'Reward payout',
  RENTING_MINIMUM_DEPOSIT: 'Minimum deposit',
  RENTING_PAYOUT_MONTHLY: 'Monthly',
  RENTING_SELECT_REWARDS_CURRENCY: 'Select rewards currency',
  RENTING_REWARDS_PER_MONTH: 'Rewards per month',
  RENTING_TOTAL_REWARDS: 'Total rewards',
  RENTING_MONTHLY_PAYOUT_FAQ_TITLE: 'Monthly payouts in Euros or Stablecoins',
  RENTING_MONTHLY_PAYOUT_FAQ_DESCRIPTION:
    'All crypto renting payouts are made in Euros or Stablecoins, and your earnings will be sent to your Nebeus account at the next payout date.',
  RENTING_LOCKUP_PERIOD_FAQ_TITLE: 'What is a lock-up period?',
  RENTING_LOCKUP_PERIOD_FAQ_DESCRIPTION:
    "Your funds will be locked-up for %{months} month(s). At the end of the lock-up period, you'll be able to withdraw your funds at any time for free.",
  RENTING_LOCKUP_PERIOD_FAQ_DESCRIPTION_ALTERNATIVE:
    'A lock-up period is a duration during which your deposited crypto assets will be locked. You will not be able to withdraw your assets during the lock-up period. <br/>' +
    '<br/>' +
    "Once the lock-up period ends, your Crypto-Renting program will continue to be active and generate monthly rewards for your selected duration. After the end of the lock-up period, you'll be able to withdraw your funds at any time.",
  RENTING_TOTAL_EARNINGS_FAQ_TITLE: 'How are earnings calculated?',
  RENTING_TOTAL_EARNINGS_FAQ_DESCRIPTION:
    'All earnings are calculated based on the current exchange rates at the date and time of paying out crypto renting earnings.',
  RENTING_MINIMUM_DEPOSIT_FAQ_TITLE: 'What is a minimum deposit?',
  RENTING_MINIMUM_DEPOSIT_FAQ_DESCRIPTION:
    'This is the minimum that you need to deposit into a Crypto-Renting program to be able to start renting. Minimum deposits vary depending on the Crypto-Renting option that you select.',
  RENTING_REWARD_PAYOUT_FAQ_TITLE: 'How are rewards paid out?',
  RENTING_REWARD_PAYOUT_FAQ_DESCRIPTION:
    '<b>RPY = Rewards-Per-Year.</b><br/>' +
    '<br/>' +
    'RPY means the total amount paid by Nebeus for the rental of crypto assets, expressed as an annual percentage of the value of the crypto assets. <br/>' +
    '<br/>' +
    'Rewards are paid monthly: every 30 days from the day of your crypto-renting program.<br/>' +
    '<br/>' +
    '<b>Reward payout = crypto-renting start day + 30 days.</b>',
  RENTING_WITHDRAW_CURRENCY_FAQ_TITLE: 'What currency can I earn rewards in?',
  RENTING_WITHDRAW_CURRENCY_FAQ_TITLE_ALTERNATIVE:
    'Choose what Crypto you want to Earn Rewards in!',
  RENTING_WITHDRAW_CURRENCY_FAQ_DESCRIPTION:
    'You can earn rewards in the same deposited cryptocurrency or a completely different currency of your choice.<br/>' +
    '<br/>' +
    'For example, you can deposit USDT and choose to earn rewards also in USDT, or you can choose to earn rewards in BTC, ETH, or any other available currency. ',
  RENTING_FAQ_RPY_TITLE: 'RPY = Rewards-Per-Year.',
  RENTING_FAQ_RPY_DESCRIPTION:
    'Nebeus has four crypto-renting options. <br/>' +
    '<br/>' +
    'Two focus on cryptos, such as Bitcoin, Ethereum, Avalanche, and Ripple, allowing you to earn up to %{cryptoMaxPercent}% RPY. <br/>' +
    '<br/>' +
    'The other two focus on renting stablecoins such as USDT and USDC, allowing you to earn up to %{stableCoinsMaxPercent}% RPY.  ',
  RENTING_FAQ_HOW_NEBEUS_USED_ASSETS_TITLE: 'How does Nebeus use Rented Assets?',
  RENTING_FAQ_HOW_NEBEUS_USED_ASSETS_DESCRIPTION:
    'Nebeus uses crypto-rented assets towards liquidity for loans and internal rehypothecation for product development. <br/>' +
    '<br/>' +
    'Nebeus has never reinvested crypto-rented assets, and Nebeus always maintains the necessary level of liquidity to meet all obligations.',

  RENTING_NEW_COINS_AD_TITLE: '🚀 New Coins! Earn Up to 10.5% in just a few taps!',
  RENTING_NEW_COINS_AD_SUBTITLE:
    'We have added four new coins to our renting program! 🎉 You can now earn up to 10.5% RPY with <b>1INCH, MATIC, EOS, and FTM</b>. Start earning now!',

  RENTING_TERMS: 'Terms',
  RENTING_YOUR_REWARDS: 'Your rewards',
  RENTING_SETUP_SUCCESS: 'Renting Started',
  RENTING_SETUP_SUCCESS_TEXT:
    'Congratulations! You’ve just started renting your crypto. You can view the details of your crypto-renting program in your active renting list.',
  RENTING_PAYOUT: 'Payout',
  RENTING_TOTAL_CUMULATIVE_PAYOUT: 'Total cumulative payout',
  RENTING_START_RENTING: 'Start renting',
  RENTING_NO_HISTORY:
    "You don't have crypto renting history. But that can all change, and you can start making history if you open a crypto renting program now.",
  RENTING_NO_ACTIVE_RENTINGS:
    "You don't have any active renting programs. But you can open one now and start earning a passive income on your digital assets.",

  // payment
  PAYMENT_CASH: 'Cash',
  PAYMENT_CRYPTO: 'Crypto',
  PAYMENT_DEPOSIT_FUNDS: 'Deposit Funds',
  PAYMENT_SEND_FUNDS: 'Send Funds',
  PAYMENT_BANK_CARD: 'Bank card',
  PAYMENT_APPLE_PAY: 'Apple Pay',
  PAYMENT_MINIMUM_DEPOSIT_AMOUNT: 'Minimum deposit amount',
  PAYMENT_DEPOSIT_CRYPTO_MIN_AMOUNT_INFO_TITLE: 'Minimum deposit requirement',
  PAYMENT_DEPOSIT_CRYPTO_MIN_AMOUNT_INFO_DESC:
    'Please be advised that our system is unable to process any top-up amounts that fall below the minimum threshold. In the event that a payment is made below this minimum amount, we regret to inform you that it will not be processed, refunded, or credited to your account. By proceeding with a top-up, you acknowledge and agree <a href="%{termLink}" rel="noopener noreferrer" target="_blank">to this policy</>.',
  PAYMENT_APPLE_PAY_FAQ_DESCRIPTION:
    'Using Apple Pay, you can instantly deposit FIAT to your Nebeus account. Apple Pay saves you the hassle of needing to wait up to 24 hours, like in the case of a bank transfer.',
  PAYMENT_APPLE_PAY_FAQ_STEPS_TITLE: 'Information about Apple Pay deposits:',
  PAYMENT_APPLE_PAY_FAQ_STEPS_1:
    'You can deposit funds to your Nebeus account using any bank card that you have saved in your Apple Pay wallet.',
  PAYMENT_APPLE_PAY_FAQ_STEPS_2:
    'Your deposited funds will be processed and will appear in your Nebeus account within a maximum of 15 minutes.',
  PAYMENT_APPLE_PAY_FAQ_STEPS_3:
    'The limits for depositing funds using Apple Pay are between %{minAmount} and %{maxAmount} per transaction.',
  PAYMENT_TO_EXTERNAL_WALLET: 'To External Wallet',
  PAYMENT_SEND_TO_EXTERNAL_WALLET: 'Send to External Wallet',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_TITLE: 'About Sending to External Wallets',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_DESCRIPTION:
    'You can send and withdraw crypto to any external wallet, and it will be sent directly through the native cryptocurrency blockchain. ',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEPS_TITLE: 'Information about sending to external wallets:',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEP_1:
    'We recommend that you scan the QR code or copy/paste the wallet address to which you want to send your crypto, as this will help avoid errors. Please note that we are not responsible for crypto mistakenly sent to the wrong wallet address.',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEP_2:
    'Always be careful when sending crypto to external wallets. Due to the nature of blockchain technology, once you make a transaction, it is impossible to cancel or reverse the transaction. ',
  PAYMENT_DEPOSIT_BANK_CARD_TO_TRADING:
    'Your transfer is being processed. <br/>' +
    '<br/>' +
    'Please note that it can take up to 72 hours for the funds to appear in your Nebeus account.',
  PAYMENT_SEND_FROM_TRADING_TO_BANK_CARD_SUCCESS:
    'Your transfer is being processed. <br/>' +
    '<br/>' +
    'Please note that this process can sometimes be delayed if your bank does not work with direct payment technology.',
  // NU - Nebeus User
  PAYMENT_NU_NAVIGATION_TITLE: 'To Nebeus User',
  PAYMENT_NU_FAQ_TITLE: 'Sending to Nebeus Users',
  PAYMENT_NU_FAQ_DESCRIPTION:
    "You can send crypto to any Nebeus user instantly and for free. All you have to know is the email that is associated with the Nebeus user's account.",
  PAYMENT_NU_FAQ_STEPS_TITLE: 'Information about sending to a Nebeus user:',
  PAYMENT_NU_FAQ_STEP_1: "It's totally free.",
  PAYMENT_NU_FAQ_STEP_2: 'There are no limits to how much you can send.',
  PAYMENT_NU_FAQ_STEP_3:
    'All transfers are done within the Nebeus platform, so they are secure and instant.',
  PAYMENT_NU_EMAIL_LABEL: 'Recipient’s Nebeus Email',

  PAYMENT_DIRECT_BANK_TRANSFER: 'Direct Bank Transfer',
  PAYMENT_NEBEUS_TO_MONEY_ACCOUNT: 'To IBAN Account',
  PAYMENT_NEBEUS_FROM_MONEY_ACCOUNT: 'From IBAN Account',
  PAYMENT_NEBEUS_TO_CRYPTO_ACCOUNT: 'To Crypto Account',
  PAYMENT_NEBEUS_SEND_TO_MONEY_ACCOUNT: 'Send to IBAN Account',
  PAYMENT_NEBEUS_SEND_TO_CRYPTO_ACCOUNT: 'Send to Crypto Account',
  PAYMENT_BANK_TRANSFER: 'Bank Transfer',
  PAYMENT_BANK_TRANSFER_SEPA: 'Bank Transfer (SEPA)',
  PAYMENT_BANK_TRANSFER_WIRE: 'Bank Transfer (Wire)',
  PAYMENT_SEND_TO_NEBEUS_USER: 'Send to Nebeus User',
  PAYMENT_SEND_TO_NEBEUS_USER_SUCCESS:
    "You've successfully sent %{amount} %{currency} to the Nebeus user with the email address: <b>%{email}</b>",
  PAYMENT_TO_NEBEUS_USER: 'To Nebeus User',
  PAYMENT_WALLET_BALANCE: 'Your %{currencyCode} balance',
  PAYMENT_CRYPTO_ADDRESS_ASYNC_CREATING_WARNING:
    'Your %{currencyCode} wallet address is generating. Usually, it takes up to 30 minutes. Now you can enjoy all the benefits of the wallet, except top-up to the wallet address. ',
  PAYMENT_DEPOSIT_CRYPTO_QR_LABEL:
    'Scan this QR code or share your wallet address to receive %{currencyCode}.',
  PAYMENT_ENTER_CARD_DETAILS: 'Enter card details',
  PAYMENT_WALLET_ADDRESS: 'Wallet address',
  PAYMENT_WALLET_CURRENCY: 'Wallet currency',
  PAYMENT_WALLET_NAME: 'Wallet name',
  PAYMENT_WALLET_ADDRESS_INVALID: 'Invalid %{currencyCode} wallet address',
  PAYMENT_DEPOSIT_CRYPTO_IMPORTANT_LABEL:
    'Only send %{currencyCode} to this wallet address. Sending any other currency may result in permanent loss.',
  PAYMENT_AMOUNT_FORM_AMOUNT: 'Amount',
  PAYMENT_YOU_WILL_GET: 'You will get',
  PAYMENT_RECIPIENT_WILL_GET: 'Recipient will get',
  PAYMENT_FOR: 'for %{value}', // Example: for €105.00
  PAYMENT_LIMITS_LABEL: 'Between %{minLabel} and %{maxLabel}',
  PAYMENT_LIMITS_LABEL_MINIMUM: 'Minimum %{minLabel}',
  PAYMENT_BANK_CARD_NUMBER: 'Card number',
  PAYMENT_BANK_CARD_EXP_DATE: 'EXP date',
  PAYMENT_BANK_CARD_EXP_DATE_PLACEHOLDER: 'EXP date --/--',
  PAYMENT_BANK_CARD_CVC: 'CVC',
  PAYMENT_BANK_CARD_FULL_NAME: 'Full name',
  PAYMENT_SAVE_BANK_CARD: 'Save Bank Card',
  PAYMENT_SEND_WIRE_FAQ_TITLE: 'About Wire Bank Transfers',
  PAYMENT_SEND_WIRE_FAQ_DESC:
    "Wire Bank Transfers are the standard and cheapest way of transferring money internationally. Wire Bank Transfers are slower than sending money to a Bank Card (the transfer can take up to 48 hours), but they are less expensive, so if you don't urgently need to receive your funds, using a Wire Bank Transfer is a great option.",
  PAYMENT_SEND_WIRE_FAQ_STEPS_TITLE: 'Important information on Wire Bank Transfers:',
  PAYMENT_SEND_WIRE_DA_FAQ_STEPS_1:
    'Bank Transfers can take up to 2 working days to arrive and be processed by your bank.',
  PAYMENT_SEND_WIRE_DA_FAQ_STEPS_2:
    'Nebeus does not have a limit for the amount of funds that you can send through a Bank Transfer.',
  PAYMENT_SEND_WIRE_FAQ_STEPS_1:
    'Wire Bank Transfers can take up to 2 working days to arrive and be processed by your bank.',
  PAYMENT_SEND_WIRE_FAQ_STEPS_2:
    'Nebeus does not have a limit for the amount of funds that you can send through a Wire Bank Transfers.',
  PAYMENT_SEND_WIRE_FAQ_STEPS_3:
    "If you are sending a large sum, the process can take more than 2 working days, and Nebeus's customer support team will contact you to assist you through the process.",
  PAYMENT_SEND_WIRE_DA_FAQ_DESC: 'Bank Transfers are the standard way of transferring money.',
  PAYMENT_DEPOSIT_WIRE_SEPA_IMPORTANT_NOTICE:
    'Only Payments from Accounts in Your Name Are Accepted.',
  PAYMENT_DEPOSIT_WIRE_FAQ_TITLE: 'About Bank Transfers',
  PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_TITLE: 'Important information on bank transfers:',
  PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_2:
    'Nebeus does not have a limit for the amount of funds that you can deposit through a bank transfer.',
  PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_1:
    'Depending on your bank, bank transfers can take up to 24 hours to arrive and be processed.',
  PAYMENT_DEPOSIT_SEPA_FAQ_TITLE: 'About SEPA Bank Transfers',
  PAYMENT_DEPOSIT_SEPA_DIGITAL_DESC:
    'Use these account details to deposit Euros to your Nebeus IBAN Account',
  PAYMENT_DEPOSIT_WIRE_DIGITAL_DESC:
    'Use these account details to deposit Great British Pounds to your Nebeus IBAN Account',
  PAYMENT_DEPOSIT_SEPA_CRYPTO_DESC:
    'Use these bank details to make a SEPA transfer. Please note, that only Euros are accepted for SEPA transfers.',
  PAYMENT_DEPOSIT_SEPA_FAQ_DESC:
    'SEPA (or the Single Euro Payments Area) is a Euro (EUR) bank transfer that works within the European Union and several other member-states that are part of the European Free Trade Association.',
  PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_TITLE: 'Important information on SEPA transfers',
  PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_1:
    'When making a bank transfer to Nebeus, the name on your bank account must match your name on Nebeus.',
  PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_2:
    'Depending on your bank, SEPA transfers can take up to 24 hours to arrive and be processed.',
  PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_3:
    'Nebeus does not have a limit for the amount of funds that you can deposit through a SEPA transfer.',
  PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_1:
    'SEPA transfers can take up to 2 working days to arrive and be processed by your bank.',
  PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_2:
    'Nebeus does not have a limit for the amount of funds that you can send through a SEPA transfer.',
  PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_3:
    "If you are sending a large sum, the process can take more than 2 working days, and Nebeus's customer support team will contact you to assist you through the process.",
  PAYMENT_BENEFICIARY: 'Beneficiary',
  PAYMENT_BENEFICIARY_ACCOUNT: 'Beneficiary account',
  PAYMENT_BENEFICIARY_BANK: 'Beneficiary bank',
  PAYMENT_BENEFICIARY_BANK_SWIFT: 'Beneficiary bank SWIFT',
  PAYMENT_INTERMEDIARY_BANK: 'Intermediary bank',
  PAYMENT_INTERMEDIARY_BANK_SWIFT: 'Intermediary bank SWIFT',
  PAYMENT_DETAILS: 'Payment details',

  PAYMENT_RECEIVER_OR_BENEFICIARY_NAME: 'Receiver / beneficiary name',
  PAYMENT_IBAN: 'IBAN',
  PAYMENT_REFERENCE: 'Reference',
  PAYMENT_RECIPIENT_TYPE: 'Recipient type',
  PAYMENT_SWIFT_OR_BIC: 'SWIFT/BIC',
  PAYMENT_SORT_CODE: 'Sort code',
  PAYMENT_ACCOUNT_NUMBER: 'Account number',
  PAYMENT_ACCOUNT_NAME: 'Account name',
  PAYMENT_BIC: 'BIC',
  PAYMENT_REFERENCE_CODE_INCLUDE_MESSAGE:
    'Please include the following payment details when initiating your transfer',
  PAYMENT_REFERENCE_CODE_INCLUDE_MESSAGE_DESC:
    'When initiating your transfer, it’s important to include the provided transfer payment details. Not including the transfer reference code may lead to processing delays. Your careful attention to this detail will contribute to a seamless and expedited transfer process.',
  PAYMENT_SEPA_REFERENCE_CODE: 'Transfer reference code',
  PAYMENT_SEPA_REFERENCE_CODE_IMPORTANT:
    'Indicate this transfer reference code when making your transfer',
  PAYMENT_SEPA_REFERENCE_CODE_IMPORTANT_DESC:
    'Please make sure to indicate this transfer reference code when making your transfer. If you do not indicate the transfer reference code, your transfer may take longer to process.',

  PAYMENT_DEPOSIT_BANK_CARD_IMPORTANT_INFO:
    'It can take up to 15 minutes for Nebeus to process your transaction and to reflect the funds in your account',
  PAYMENT_SEND_BANK_CARD_IMPORTANT_INFO:
    'Sending funds to a bank card usually takes a maximum of 15 minutes to process by your bank.',

  PAYMENT_BANK_CARD_DEPOSIT_FAQ_TITLE: 'About Bank Card Deposits',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_DESCRIPTION:
    'Bank card deposits are a great and quick way to deposit FIAT into your Nebeus Crypto account. They save you the hassle of waiting for a bank transfer to be executed.',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEPS_TITLE: 'Information about bank card deposits: ',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_1:
    'You can deposit funds to your Nebeus Crypto Account using any bank card. ',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_2:
    'Your deposited funds will be processed and will appear in your Nebeus Crypto Account within a maximum of 15 minutes. ',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_3:
    'The limits for depositing funds with bank cards are between %{minLabel} and %{maxLabel} per transaction.',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_4:
    'For Anti-Money Laundering reasons, we only allow two successful deposit transactions every 24 hours.',

  PAYMENT_BANK_CARD_SEND_FAQ_TITLE: 'About Sending to Bank Cards',
  PAYMENT_BANK_CARD_SEND_FAQ_DESCRIPTION:
    'Sending funds to bank cards is a great way to send and withdraw funds from your Nebeus account very quickly. ',
  PAYMENT_BANK_CARD_SEND_FAQ_STEPS_TITLE: 'Information about sending to bank cards:',
  PAYMENT_BANK_CARD_SEND_FAQ_STEP_1: 'You can send funds to any MasterCard or Visa.',
  PAYMENT_BANK_CARD_SEND_FAQ_STEP_2:
    'Sent funds will arrive at your card within a maximum of 15 minutes. Please note that this process can sometimes be delayed if your bank does not work with direct payment technology.',
  PAYMENT_BANK_CARD_SEND_FAQ_STEP_3:
    'The limits for sending funds to a bank card are between %{minLabel} and %{maxLabel} per transaction.',
  PAYMENT_SAVED_BANK_CARDS: 'Saved bank cards',
  PAYMENT_SAVED_WALLETS: 'Saved wallets',
  PAYMENT_SAVED_WALLETS_SUCCESS: 'Digital wallet added',
  PAYMENT_SAVED_WALLETS_SUCCESS_REMOVED: 'Digital wallet removed',
  PAYMENT_SAVED_WALLETS_SUCCESS_EDIT: 'Digital wallet edited',
  PAYMENT_SAVED_BANK_CARDS_ADD_CARD: 'Add card',
  PAYMENT_SAVED_WALLETS_ADD_WALLET: 'Add wallet',
  PAYMENT_SEND_EXTERNAL_WALLET_LABEL: 'Label',

  PAYMENT_RECIPIENTS_WALLET: 'Recipient’s wallet',
  PAYMENT_RECIPIENTS_STH: 'Recipient’s %{label}',
  PAYMENT_RECIPIENTS_GETS: 'Recipient gets',
  PAYMENT_NEBEUS_FEE: 'Nebeus fee',
  PAYMENT_TOTAL_WITHDRAWN: 'Total withdrawn',
  PAYMENT_DEPOSIT_AMOUNT: 'Deposit amount',
  PAYMENT_CRYPTO_WITHDRAW_INITIATED: 'Withdrawal initiated',
  PAYMENT_CRYPTO_WITHDRAW_INITIATED_SUBTITLE:
    'Track the progress of your withdrawal on your accounts page.',
  PAYMENT_RECIPIENT_TYPE_PERSON: 'Person',
  PAYMENT_RECIPIENT_TYPE_BUSINESS: 'Business',

  PAYMENT_SEND_CURRENCY_IMPORTANT_LABEL:
    'Only send %{currencyCode} to this address using the %{network} network. Sending any other currency or using a different network may result in permanent loss.',

  PAYMENT_SEND_CURRENCY_IMPORTANT_ADDITIONAL_LABEL:
    'Only send %{currencyCode} to this address using the %{network} network, and make sure to add the %{additional} to your transaction. Sending any other currency or using a different network or not adding the %{additional} may result in permanent loss.',
  PAYMENT_SEND_BUSD_IMPORTANT_LABEL:
    'Do not attempt to send funds using the BSC, BEP2, or BEP20 networks. Nebeus is not connected to any Binance blockchains so sending funds using a Binance network will result in permanent loss.',
  PAYMENT_BACK_TO_PROCESS: 'Back to process',
  PAYMENT_SEND_TO_DA_FAQ_TITLE: 'Funding your IBAN Account',
  PAYMENT_SEND_TO_CA_FAQ_TITLE: 'Funding your Crypto Account',
  PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_TITLE: 'Transfers money between your Money and Crypto Accounts.',
  PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_1:
    'Receive crypto into your Crypto Account from any wallet, exchange it into fiat, and instantly send the funds to your IBAN Account.',
  PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_2:
    'Vice Versa, send funds from your IBAN Account to your Crypto Account to buy crypto instantly.',
  PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_3:
    'Transfers are limited depending on your current account subscription. If you want to increase your limits, please upgrade your account.',
  PAYMENT_FEE_MIN: 'Min',
  PAYMENT_ADD_BANK_CARD: 'Add Bank Card',
  PAYMENT_FROM: 'From',
  PAYMENT_TO: 'To',
  PAYMENT_SEND_TO_SEPA_SUCCESS_MESSAGE:
    "You've successfully sent %{currencyAmountLabel} to %{accountName}<br/>" +
    '<br/>' +
    "Please note that SEPA transfers can take up to 2 working days to arrive and be processed by your receipt's bank.",
  PAYMENT_SEND_TO_WIRE_SUCCESS_MESSAGE:
    "You've successfully sent %{currencyAmountLabel} to %{accountName}<br/>" +
    '<br/>' +
    "Please note that wire transfers can take up to 2 working days to arrive and be processed by your receipt's bank.",
  PAYMENT_SEND_TO_CRYPTO_ACCOUNT:
    'Your funds have successfully been sent to your Nebeus crypto account.<br/>' +
    '<br/>' +
    'Please note that the transfer is instant but can sometimes take up to 10 minutes to process. ',
  PAYMENT_SEND_TO_DIGITAL_ACCOUNT:
    'Your funds have successfully been sent to your Nebeus IBAN Account.<br/>' +
    '<br/>' +
    'Please note that the transfer is instant but can sometimes take up to 10 minutes to process. ',
  PAYMENT_LIMIT_DAILY_WITHDRAWAL: 'Daily withdrawal',
  PAYMENT_LIMIT_MONTHLY_WITHDRAWAL: 'Monthly withdrawal',
  PAYMENT_CURRENCY_LIMIT: '%{currencyCode} Limits',
  PAYMENT_MIN_WITHDRAWAL: 'Min. Withdrawal',
  PAYMENT_TRANSACTION_WITHDRAWAL: 'Transaction Withdrawal',
  PAYMENT_DAILY_WITHDRAWAL: 'Daily Withdrawal',
  PAYMENT_MONTHLY_WITHDRAWAL: 'Monthly Withdrawal',
  WITHDRAW_LIMITS: 'Withdrawal Limits',
  WITHDRAW_LIMITS_DESC:
    'Nebeus has withdrawal limits for each cryptocurrency and FIAT currency for financial compliance, fraud prevention, and anti-money-laundering reasons.<br/><br/>The withdrawal limits are calculated separately for each cryptocurrency and FIAT currency.',
  PAYMENT_DEPOSIT_USD_WIRE_NOTE:
    'Deposit only from an account with the same holder name as your Nebeus account.',
  PAYMENT_NETWORK_NOTICE:
    'Ensure the network you select matches the network of your withdrawal address.',
  PAYMENT_NETWORK_SELECTED: '%{networkName} network selected',
  PAYMENT_NETWORK_IMPORTANT_TEXT:
    'Please ensure the network you selected matches the network of your withdrawal address. Sending funds using the wrong network may result in permanent loss.<br/><br/>Confirm sending funds using the %{networkName} network?',
  PAYMENT_SEND_FIAT_IMPORTANT_NOTE:
    "<b>Double-Check Beneficiary Details:</b> Please verify the recipient's account details carefully. Incorrect information can lead to delays or misdirected funds. Once a transfer is initiated, it cannot be reversed. <b>Beware of Fraud and Scams:</b> Be cautious of unsolicited requests for money transfers, even if they appear to be from known contacts. Always confirm the legitimacy of the request through independent means.",
  PAYMENT_OPERATION_COMPLETED_SUCCESSFULLY: 'Completed successfully',
  PAYMENT_OPERATION_COMPLETED: 'Operation completed',
  PAYMENT_OPERATION_INITIATED: 'Operation initiated',
  PAYMENT_OPERATION_FAILED: 'Operation failed',
  PAYMENT_DEPOSIT_CURRENCY_BY_BANK_CARD: 'Deposit %{currencyCode} by bank card',
  PAYMENT_SEND_CURRENCY_BY_BANK_CARD: 'Send %{currencyCode} to bank card',
  PAYMENT_SEND_BY_SEPA: 'Send by SEPA',
  PAYMENT_SEND_BY_WIRE: 'Send by Wire transfer',
  PAYMENT_LIMITS_LEFT: '%{value} left', // Example: 5,000 € left

  PAYMENT_TRANSACTION_MANDATORY_WITHDRAWAL:
    "We've identified a transaction that doesn't align with our policies. Please withdraw %{currencyAmountLabel} to an <b>external</b> wallet immediately.",
  PAYMENT_TRANSACTION_MANDATORY_WITHDRAWAL_SUBMIT_BUTTON: 'Withdraw funds now',

  // date
  DATE_MONTH_PLURAL_NO_BRACKETS: '%{count} months',
  DATE_MONTH_PLURAL: '%{count} month(s)',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  DATE: 'Date',

  // app
  CONFIRM_OTP_AUTHENTICATE: 'Authenticate',
  CONFIRM_OTP_INSTRUCTIONS_SMS:
    'Please enter the 6 digit confirmation code that we sent to your phone number that is linked to your Nebeus account.',
  CONFIRM_OTP_INSTRUCTIONS_EMAIL:
    'Please enter the 6 digit confirmation code that we sent to your email that is linked to your Nebeus account.',
  CONFIRM_OTP_INPUT_LABEL: 'Enter confirmation code',
  CONFIRM_OTP_RESEND_CODE: 'Resend code',
  CONFIRM_OTP_RESEND: 'Resend',
  CONFIRM_OTP_RESEND_IN: 'Resend in', // f.e. Resend in 00:59 (seconds)
  CONFIRM_OTP_RESEND_IN_SECONDS: ' Resend in %{seconds} seconds',
  CONFIRM_OTP_INCORRECT_CODE: 'Incorrect Code',
  CONFIRM_OTP_CODE_SENT: 'Code sent',
  NEWS: 'News',
  CRYPTO_NEWS: 'Crypto News',
  NEWS_VIEW_MORE: 'View more',
  NEWS_ITEM_TIME_AGO: '%{time} ago',
  CONFIRM_OTP_DIDNT_RECEIVE: 'Didn’t receive the code?',

  // EXCHANGE
  EXCHANGE_SUCCESS_TEXT:
    'Your exchange is confirmed, and your %{currencyCode} is already in your Nebeus account.',

  // STAKING
  STAKING: 'Staking',
  STAKED_ASSETS: 'Staked assets',
  STAKING_RPY: 'RPY', // Rewards per year
  STAKING_OVERVIEW_TITLE: 'Stake and get up to %{maxRPY}% RPY',
  STAKING_OVERVIEW_TEXT: 'Rewards are paid out at 30 days intervals.<br/>Unstake at any time.',
  STAKING_START: 'Start staking',
  STAKING_STAKED_CURRENCY: 'Staked currency',
  STAKING_STAKED_AMOUNT: 'Staked amount',
  STAKING_REWARD_PAYOUT: 'Reward payout',
  STAKING_STAKED_BALANCE: 'Staked balance',
  STAKING_PAYOUT_INTERVAL: 'Monthly',
  STAKING_REWARDS_PER_WEEK: 'Rewards per week',
  STAKING_REWARDS_PER_YEAR: 'Rewards per year',
  STAKING_APP_OPERATIONS_DESC: 'Flexible staking at up to %{rpy}% RPY',
  STAKING_SUCCESS_TITLE: 'Staking Successful',
  STAKING_SUCCESS_SUBTITLE:
    'Your %{currencyCode} staking is confirmed.<br/>' +
    'Please note that it can take up to 10 minutes your staked %{currencyCode} to appear in your Nebeus staking balance. ',
  STAKING_TOTAL_STAKED_VALUE: 'Total staked value',
  STAKING_STAKE: 'Stake',
  STAKING_UNSTAKE: 'Unstake',
  STAKING_PLACEHOLDER_AMOUNT_TO_UNSTAKE: 'Enter unstake amount',
  STAKING_ENTER_UNSTAKE_AMOUNT: 'Amount to unstake',
  STAKING_UNSTAKE_AMOUNT: 'Unstake amount',
  STAKING_UNSTAKE_SUCCESS_TITLE: 'Unstaking Successful',
  STAKING_UNSTAKE_SUCCESS_SUBTITLE:
    'Your %{currencyCode} unstaking is confirmed.<br/>Please note that it can take up to 10 minutes your unstaked %{currencyCode} to appear in your main Nebeus account. ',
  STAKING_STAKED_CURRENCIES: 'Staked currencies',
  STAKING_HISTORY: 'Staking history',
  STAKING_TOTAL_REWARDS: 'Total rewards',
  STAKING_NEXT_PAYOUT: 'Next payout (in %{days} days)',

  // errors
  ERROR: 'Error',
  ERROR_EMAIL_NOT_FOUND: 'Email not found',
  ERROR_TRY_AGAIN: 'Try again',
  NO_RESULTS_FOR: 'No results for', // [USING DESCRIPTION] No results for "Bitcoin",
  NO_CURRENCIES_FOUND: 'No currencies found.',
  API_ERROR: 'Oops, something seems to have gone wrong. Please try again.',
  API_ERROR_LIMIT: 'Request limit has been reached. Try again in a few minutes',
  ERROR_SEEMS_PROBLEM: 'Seems like a problem',
  ERROR_SOMETHING_BROKE: 'Something broke',
  ERROR_BOUNDARY_TEXT: 'Oops, something went wrong',
  ERROR_BOUNDARY_TRANSLATION_ERROR_TEXT:
    'This error may occur due to the use of a translator. Please disable the translator on the website to continue using Nebeus.',
  ERROR_NOTIFICATION_DISALLOWED: 'Please, allow to send you notifications to continue',
  ERROR_BANK_CARD_SEND:
    'There are some problems with your bank card. Try again or contact Nebeus support',
  ERRORS_INVALID_DATE: 'Date is invalid',
  ERRORS_DATE_PERIOD_YEAR: 'The period cannot be longer than a year',
  ERRORS_DATE_FUTURE: 'Future date is not allowed',
  ERROR_COUNTRY_IS_NOT_ALLOWED_FOR_OPERATION: 'This country is not allowed for operation',

  // loans
  CRYPTO_BACKED_LOANS: 'Crypto-Backed Loans',
  LOANS_APP_OPERATIONS_DESC: 'Up to %{ltv}% LTV and from 0% interest.',
  LOAN_MIN_LTV_WITH_NO_OPTIONS_VALIDATION:
    "To set up a loan with the lowest LTV, you'll need to include loan automation feature",

  // vault
  VAULT: 'Vault',
  NEBEUS_COLD_STORAGE: 'Nebeus Cold Storage',
  VAULT_COLD_STORAGE: 'Cold Storage Vaults',
  VAULT_INSURED: 'Insured Cold Storage Vault',
  VAULT_INSURED_DESC: 'Cold storage with a $250M insurance.',
  VAULT_APP_OPERATIONS_DESC: 'Store & Insure your Crypto',
  VAULT_INSURED_COLD_STORAGE_VAULT: 'Insured Cold<br/>Storage Vault',
  VAULT_BENEFIT_1: '$250M Pool Insurance for your Bitcoin & Ethereum',
  VAULT_BENEFIT_2: 'Safely store your crypto in BitGo’s insured cold storage',
  VAULT_TOTAL_VALUE: 'Total value in cold storage',
  VAULT_WITHDRAW: 'Withdraw from vault',
  VAULT_WITHDRAW_TEXT:
    'When you request to withdraw your funds, BitGo performs an identity verification and generates a transaction that needs to be signed offline by Multi-Sig technology. After Bitgo and Nebeus co-sign, the funds are withdrawn.<br/><br/>Because of this rigorous security process, the withdrawal can take up to 48 hours to be completed. ',
  VAULT_DEPOSIT: 'Deposit into vault',
  VAULT_DEPOSIT_TEXT:
    "Your funds will be sent to Bitgo and stored in a cold storage device placed in a bank-grade Class III secured vault, covered by a $250M insurance policy by Lloyd's of London.<br/><br/>Deposits and withdrawals are protected by identity verification and multi-Sig technology.",
  VAULT_INTRO_TITLE: 'What is a Nebeus Vault?',
  VAULT_INTRO_TEXT_P1:
    "The Nebeus Vault is a cold storage device held in custody by Bitgo, the largest crypto custodian in the world, and insured by a $250M insurance policy by Lloyd's of London.<br/><br/>" +
    'After you deposit funds into the Nebeus vault, they are transferred to BitGo. BitGo then stores your funds in a cold storage device and physically places it in a bank-grade Class III secured vault.',
  VAULT_INTRO_TEXT_P2:
    'Deposits and withdrawals are protected by identity verification and multi-Sig technology.<br/><br/>' +
    'The insurance protects your funds against third-party hacks, copying, loss, theft of private keys, insider theft, and default.',
  VAULT_OPERATION_DEPOSITED: 'Deposited to Vault',
  VAULT_OPERATION_WITHDRAW_DONE: 'Withdraw %{currencyCode}',
  VAULT_OPERATION_WITHDRAW_DONE_TEXT:
    'You will receive an email notification as soon as your funds arrive in your Nebeus account. The process can take up to 48 hours.',

  // DIGITAL ACCOUNT
  DIGITAL_ACCOUNT: 'IBAN Account',
  CARD: 'Card',
  DIGITAL_ACCOUNT_REQUESTED: 'requested',
  DA_SUBSCRIPTION_REQUESTED_TITLE: 'Your application has been accepted',
  DA_SUBSCRIPTION_REQUESTED_DESC: 'We need some time to create a IBAN Account for you.',
  DIGITAL_ACCOUNT_CANCEL_SUBSCRIPTION_BANK_CARD_WILL_BE_CLOSED:
    'If you cancel your subscription, all your active Nebeus VISA cards will automatically be closed. Are you sure you want to proceed?',
  DIGITAL_ACCOUNT_DOWNGRADE_TO_STD_TITLE: 'Downgrade subscription?',
  DIGITAL_ACCOUNT_DOWNGRADE_TO_STD_DESC:
    'If you downgrade your subscription to a Nebeus Standard Account, all your active VISA cards will be closed. Are you sure you want to proceed?',
  DIGITAL_ACCOUNT_GET_CARD_BUY_SUB:
    'To get your Nebeus card, please buy a Hodler or Whale Account subscription.',
  DIGITAL_ACCOUNT_GET_CARD_UPG_SUB:
    'To get your Nebeus card, please upgrade your IBAN Account to a Hodler or Whale account.',

  BANK_CARD_VIRTUAL: 'Virtual card',
  DA_BENEFIT_DIGITAL_ACCOUNT_DESC:
    'Your personal digital IBAN Account for everyday use. </br>' +
    'An IBAN Account for Euros and EUR transfers.</br>' +
    'A Sort Code Account for British Pounds and GBP transfers.</br>' +
    'Provided by Nebeus in partnership with Modulr Finance, authorized by the Central Bank of Ireland.',
  DA_BENEFIT_PAYMENTS: 'Bank Transfers & Payments',
  DA_BENEFIT_PAYMENTS_DESC:
    'Make and receive unlimited bank transfers:</br>' +
    '</br>' +
    'Receive funds from third parties such as friends, family, or your employer.</br>' +
    'Make transfers to pay your bills and invoices.</br>' +
    'Send money to friends and third parties.</br>',
  DA_BENEFIT_VIRTUAL_CARD: 'Nebeus Virtual Card',
  DA_BENEFIT_VIRTUAL_CARD_DESC:
    'Start using Nebeus like you use your bank with the Nebeus VISA debit card.</br></br>' +
    'Spend directly from your Nebeus Euro IBAN or GBP Sort Code account without needing to withdraw your crypto gains to another bank.</br></br>' +
    '<b>The Nebeus Virtual Card is only available on Hodler and Whale IBAN Accounts.<b/>',
  DA_BENEFIT_PHYSICAL_CARD: 'Nebeus Physical Card',
  DA_BENEFIT_OTHER: 'Other Perks',
  DA_BENEFIT_COMING_SOON: 'Coming soon.',
  DA_PROVIDER_MIGRATION_DESC:
    "We're transitioning providers to enhance our services and we need your approval to transfer your funds to the new vIBAN account at no cost. If you choose to deny this transfer, we'll reach out promptly to discuss returning any remaining funds. Check more details in your email",
  DA_PROVIDER_MIGRATION_SUCCESS: 'Transfer has been accepted',

  IBAN_CARD_MIGRATION_TITLE: 'Get your Nebeus Virtual card for free',
  IBAN_CARD_MIGRATION_DESC:
    'Get a free Nebeus Virtual Card connected to your trading account, making it easy for you to buy with both fiat and crypto. Would you like to accept the new card along with its <a href="%{termLink}" rel="noopener noreferrer" target="_blank">terms and conditions</a>?',

  GET_BANK_VIRTUAL_CARD: 'Get your free virtual card',
  BANK_CARD_FREEZE: 'Freeze',
  BANK_CARD_FROZEN: 'Card frozen',
  BANK_CARD_BLOCKED: 'Card blocked',
  BANK_CARD_UNFREEZE: 'Unfreeze',
  BANK_CARD_HIDE_DETAILS: 'Hide Details',
  BANK_CARD_SHOW_DETAILS: 'Show Details',
  BANK_CARD_CURRENCY_ACCOUNT: 'Card currency account',
  BANK_CARD_CURRENCY: 'Card currency',
  BANK_CARD_KBA: 'VISA Security Questions',
  BANK_CARD_TERMINATE: 'Terminate Card',
  BANK_CARD_SEND_PIN: 'Send PIN',
  BANK_CARD_CHANGE_PIN: 'Change PIN',
  BANK_CARD_SEND_PASSCODE: 'Send passcode',
  BANK_CARD_SEND_PIN_SUCCESS: 'PIN code sent successfully',
  BANK_CARD_SEND_PASSCODE_SUCCESS: 'Passcode sent successfully to your email',
  BANK_CARD_PASS_CODE_DESC_TITLE: 'Mastercard passcode',
  BANK_CARD_PASS_CODE_DESC_TEXT:
    'The Mastercard passcode is a 6-digit code used for online purchases. Please do not share this code. It will be requested by new payment merchants',
  BANK_CARD_PIN_CODE_DESC_TITLE: 'PIN code',
  BANK_CARD_PIN_CODE_DESC_TEXT:
    'PIN code is a 4 digit code. It used at ATMs and point-of-sale terminals. Your card is contactless, but you have to do the first transaction either a balance inquiry in atm or payment with POS terminal through inserting the card - this activates it.',
  BANK_CARD_VIRTUAL_WORD: 'Virtual',
  BANK_CARD_PHYSICAL_WORD: 'Physical',
  BANK_CARD_ISSUE_RIGHT_NOW: 'Right now',
  BANK_CARD_ISSUE_DEPENDS_ON_COUNTRY: 'Depends on delivery country',
  BANK_CARD_CVC_LONG: 'CVC2/CVV2-code',
  BANK_CARD_BLOCKED_TEXT:
    'Your card was blocked by Nebeus due to suspicious activity. Please contact support.',
  BANK_CARD_BLOCKED_BY_PAYMENT_FAILED:
    'Auto payment failed. Please, add funds to your IBAN Account.',
  BANK_CARD_GET_NEW: 'Get New Card',
  BANK_CARD_GET_NEW_VIRTUAL: 'Get New Virtual Card',
  BANK_CARD_GET_NEW_PHYSICAL: 'Get New Card',
  BANK_CARD_ADVERTISEMENT_TITLE: 'Your Nebeus<br/><span>Virtual Card</span>',
  BANK_CARD_ADVERTISEMENT_DESC: 'Spend directly from your Nebeus IBAN Account',
  BANK_CARD_ADVERTISEMENT_DESC_ALT:
    'Spend directly from your Nebeus IBAN Account without needing to send the money to your traditional bank account',
  BANK_CARD_GET: 'Get Card',
  BANK_CARD_BENEFIT_1_TITLE: 'Connected to your Nebeus IBAN',
  BANK_CARD_BENEFIT_1_BUTTON_TITLE: 'Connected to IBAN Account',
  BANK_CARD_BENEFIT_1_MODAL_TITLE: 'Connected to your Nebeus IBAN Account',
  BANK_CARD_BENEFIT_1_DESC:
    'Like any other bank, your Nebeus VISA card is connected to your Euro IBAN account or British Pound Sort Code account.<br/><br/>Spend directly from Nebeus without needing to withdraw your crypto gains to another bank.',
  BANK_CARD_BENEFIT_2_TITLE: 'Euros or British Pounds',
  BANK_CARD_BENEFIT_2_DESC:
    'Choose whether you want your card to be a EUR card or a GBP card.<br/>' +
    '<br/>' +
    'Your card will be connected to either your Euro IBAN account or your British Pound Sort Code account, depending on your chosen currency. ',
  BANK_CARD_BENEFIT_3_TITLE: 'Spend in any FIAT currency',
  BANK_CARD_BENEFIT_3_MODAL_TITLE: 'Spend in any Currency Globally',
  BANK_CARD_BENEFIT_3_DESC:
    "Spend in any FIAT currency globally and get VISA's international conversion rates.",
  BANK_CARD_BENEFIT_4_TITLE: 'Unlimited Virtual Cards',
  BANK_CARD_BENEFIT_4_BUTTON_TITLE: 'Nebeus Virtual Card',
  BANK_CARD_BENEFIT_4_DESC:
    'Your first virtual card is <b>free</b>, but you can get an unlimited number of virtual cards for a monthly fee that you can cancel at any time.<br/>' +
    '<br/>' +
    'Each additional card will be charged at <br/>' +
    '€%{priceAmount}/month',
  IBAN_BANK_CARD_AD_CARD_TITLE: 'Get a Visa card with IBAN account',
  IBAN_BANK_CARD_CREATE_SUCCESS_TITLE: 'Success!<br/>Nebeus Visa Virtual Card is ready',
  IBAN_BANK_CARD_CREATE_SUCCESS_SUBTITLE:
    'Congratulations! You can start using your new card right now',

  BANK_CARD_KBA_DESCRIPTION:
    'VISA Security Questions are an additional security layer by VISA, also known as Knowledge-Based Authentication, used to verify that you are making the transactions. When making a transaction, you can copy and paste them when asked for them by VISA.',
  FIRST_PET_NAME: 'First pet name',
  MATERNAL_GRANDMOTHER_MAIDEN_NAME: 'Maternal grandmother maiden name',
  FAVOURITE_CHILDHOOD_FRIEND: 'Favorite childhood friend',
  FIRST_CAR: 'First car',
  CITY_PARENTS_MET: 'City parents met',

  BANK_CARD_TERMINATE_TITLE: 'Terminate Card?',
  BANK_CARD_TERMINATE_FINAL_TITLE: 'Final confirmation.',
  BANK_CARD_TERMINATE_SUBTITLE: 'Are you sure you want to terminate your Nebeus card?',
  BANK_CARD_TERMINATE_WARNING:
    'The card will remain active until the end of the current billing period on %{nextPaymentDate}. During this time, you can continue to use the card as usual. After the billing period ends, a final fee will be withdrawn, and the card will be permanently closed. ',
  BANK_CARD_CLOSING_INFO:
    'This card will remain active till %{nextPaymentDate}, when the final fee will be withdrawn, and the card will be permanently closed.',
  BANK_CARD_PICK_CURRENCY: 'Choose Card Currency',
  BANK_CARD_PICK_CURRENCY_DESC:
    'Please choose the currency and account to which you want to connect your card. You will not be able to change this in the future.',
  BANK_CARD_UNLIMITED_DESC:
    'You first virtual card was free, but you can get an unlimited number of virtual cards for %{currency}%{amount}/month that you can cancel any time.',

  DA_SUBSCRIPTION_INCREASE_LIMITS: 'Increase limits',
  DA_SUBSCRIPTION_STANDARD: 'Standard',
  DA_SUBSCRIPTION_STANDARD_DESC:
    'Euro IBAN or GBP Sort Code account for everyday use, investment, and HODLing.',
  DA_SUBSCRIPTION_HODLER: 'Hodler',
  DA_SUBSCRIPTION_HODLER_DESC:
    'Higher transfer limits between your accounts. The best option for HODLers.',
  DA_SUBSCRIPTION_WHALE: 'Whale',
  DA_SUBSCRIPTION_WHALE_DESC:
    'Unlimited transfers, unlimited trading, unlimited everything: <br/>' +
    'the best option for crypto whales.',
  DA_BENEFITS_MONEY_ACCOUNT_TITLE: 'IBAN Account',
  DA_BENEFITS_MONEY_ACCOUNT_DESC:
    'Your personal Euro IBAN or GBP Sort Code account for everyday use, crypto investment, and HODLing.<br/><br/>' +
    'Receive third-party payments, pay your bills, make bank transfers, and instantly send funds to your crypto account to invest in crypto.<br/><br/>' +
    'Use your IBAN Account just like any other bank account.',
  DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO: 'Transfers Between Money & Crypto',
  DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO_MODAL_TITLE:
    'Transfers Between your Money and Crypto Accounts',
  DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO_DESC_P1: `Transfers between your Money and Crypto Accounts have limits depending on your Nebeus IBAN Account subscription. The higher the subscription, the higher the limits.<br/><br/>To see a complete list of limits, read `,
  DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO_DESC_P2: 'this article on our help center.',
  DA_BENEFITS_BANK_TRANSFERS: 'Bank Transfers & Payments',
  DA_BENEFITS_BANK_TRANSFERS_DESC:
    'Make and receive unlimited SEPA instant & GBP Faster Payments transfers:<br/><br/>' +
    '● Receive funds from third parties such as friends, and family.<br/>' +
    '● Receive your salary on Nebeus and use Nebeus like your other bank.<br/>' +
    '● Make transfers to pay your bills and invoices.<br/>' +
    '● Send money to friends and third parties.',
  DA_BENEFITS_OTHER_PERKS: 'Other Perks',
  DA_BENEFITS_COMING_SOON: 'Coming soon.',
  DA_OPEN_STANDARD_ACCOUNT: 'Open Nebeus Standard Account',
  DA_SELECT_ACCOUNT_FOR_SUB_PAYMENT: 'Select IBAN Account for payment',
  DA_SUBSCRIPTION_GET_SUCCESS_DESC_DEPOSIT_NEED:
    'To activate the subscription, please deposit money to your Nebeus IBAN Account. Nebeus automatically debit the subscription payment from your account.',
  DA_SUBSCRIPTION_GET_SUCCESS_DESC:
    'Your Nebeus %{subName} IBAN Account subscription is now active. Deposit money to your Nebeus IBAN Account to start enjoying all the benefits!',
  DA_SUBSCRIPTION_STD_OPEN_DESC:
    'Your Nebeus Standard IBAN Account is confirmed. You can manage your plan in your settings, and you can cancel at any time.',
  DA_SUBSCRIPTION_STANDARD_GET_SUCCESS:
    'Your personal IBAN account for everyday use, investment in crypto and HODLing.',
  DA_SUBSCRIPTION_HODLER_GET_SUCCESS:
    'Your Nebeus IBAN Account with higher limits. The best option for crypto hodlers.',
  DA_SUBSCRIPTION_WHALE_GET_SUCCESS:
    'Enjoy unlimited transfers between your crypto & IBAN account for unlimited trading.',
  DA_SUBSCRIPTION_VERIFICATION_PENDING_SHORT: 'Verif. pending',
  DA_SUBSCRIPTION_VERIFICATION_PENDING_TITLE: 'Your identity documents are being checked',
  DA_SUBSCRIPTION_VERIFICATION_PENDING_DESC:
    'We are verifying that the documents you submitted when you opened your Nebeus Account comply with our IBAN Account requirements. ',
  DA_SUBSCRIPTION_VERIFICATION_PENDING_IMPORTANT:
    'The process may take up to 24 hours. Our support team will contact you if some documents are missing or need to be updated.',
  DA_SUBSCRIPTION_DOWNGRADE_TO_STD_IMPORTANT:
    'You are trying to downgrade your subscription. Since the card is only available for Hodler and Whale subscriptions, you must close the card first. Please click on the button to close the card.',

  DA_SUBSCRIPTION_CANCEL_QUESTION: 'Cancel subscription?',
  DA_SUBSCRIPTION_CANCEL_DESC:
    'If you cancel now, your subscription will remain active until the end of your paid month: %{endDate}.',
  DA_SUBSCRIPTION_WILL_BE_CLOSED: 'Current subscription will be closed',

  DA_SUBSCRIPTION_CANCELLED: 'Subscription Canceled',
  DA_SUBSCRIPTION_CANCELLED_DESC:
    'You have successfully canceled your subscription. It will remain active until the end of your paid month: %{endDate}.',

  // iban

  IBAN_CARD_BALANCE: 'IBAN card balance',
  IBAN_ASSETS: '%{currencyCode} IBAN assets',
  IBAN_ACCOUNT_ASSETS: '%{currencyCode} IBAN account assets',
  SWITCH_IBAN: 'Switch IBAN',
  IBAN_ACCOUNT_CURRENCY: '%{currencyCode} IBAN account',

  // loans
  LOAN: 'Loan',
  LOANS: 'Loans',
  LOANS_QUICK: 'Quick loan',
  LOANS_TITLE: 'Get your own crypto loan',
  LOANS_FLEX: 'Flexible loan',
  LOANS_QUICK_SHORT: 'Quick loan',
  LOANS_FLEX_SHORT: 'Flexible loan',
  LOANS_COLLATERAL_FIELD_LABEL: 'I need a loan in',
  LOANS_COLLATERAL: 'Collateral',
  LOANS_COLLATERAL_MIRROR_YOU_SEND: 'You send',
  LOANS_COLLATERAL_MIRROR_YOU_GET: 'You get',
  LOAN_PAYMENTS: 'Payments',
  LOANS_TOTAL_REPAYMENT: 'Total Loan Repayment',
  LOAN_REPAYMENT: 'Repayment',
  LOAN_REPAYMENT_MONTHLY: 'Monthly',
  LOAN_REPAYMENT_ONCE: 'Once',
  LOANS_INTEREST_RATE: 'Interest Rate',
  LOANS_LTV: 'LTV',
  LOANS_INTEREST_LTV_FULL: 'Loan-To-Value',
  LOANS_LOAN_TERM: 'Loan Term',
  LOANS_COLLATERAL_AMOUNT_TYPE: 'Collateral Amount & Type',
  LOANS_LOAN_EXCHANGE_RATE: 'Loan Exchange Rate',
  LOANS_LOAN_ORIGINATION_FEE: 'Loan Origination Fee',
  LOANS_EARLY_REPAYMENT_OPTION: 'Early Repayment Option',
  LOANS_LOAN_AMOUNT: 'Loan Amount',
  LOANS_TOTAL_COST: 'Total cost of loan (total interest)',
  LOANS_GET_LOAN: 'Get a loan',
  LOANS_TOKENS_AVAILABLE: '%{count} tokens available',
  LOAN_FEATURES: 'Loan features',
  LOANS_REPAYMENT_AMOUNT: 'Repayment amount',

  LOANS_TEMPLATE_QUICK_TITLE: 'Quick',
  LOANS_TEMPLATE_FLEXIBLE_TITLE: 'Flexible',
  LOANS_TEMPLATE_BULLET_TITLE: 'Bullet',
  LOANS_TEMPLATE_ONLY_INTEREST_TITLE: 'Interest only',
  LOANS_TEMPLATE_MIRROR_TITLE: 'Mirror',

  LOANS_TEMPLATE_QUICK_TITLE_FULL: 'Quick loan',
  LOANS_TEMPLATE_FLEXIBLE_TITLE_FULL: 'Flexible loan',
  LOANS_TEMPLATE_BULLET_TITLE_FULL: 'Bullet loan',
  LOANS_TEMPLATE_ONLY_INTEREST_TITLE_FULL: 'Interest only loan',
  LOANS_TEMPLATE_MIRROR_TITLE_FULL: 'Mirror loan',

  LOANS_MONTHLY_PAYMENT_ONLY_INTEREST: 'Monthly payment (Only interest)',

  LOAN_AMOUNT_RANGE: 'Loan amount range',

  LOANS_TEMPLATE_FLEXIBLE_DESC_LONG:
    'Nebeus Flexible Loans are loans that you can customize to your individual needs. You can take out as much as 250,000 in FIAT currency or Stablecoins for as long as 36 months. You can choose your LTV, bringing it up to be as high as 80%, and your loan interest rate will automatically be adjusted depending on the LTV you select.',
  LOANS_TEMPLATE_FLEXIBLE_DESC:
    'Pay monthly both the interest and loan amount, with the option to repay early',
  LOANS_TEMPLATE_BULLET_DESC:
    'Pay once at the end of the term to cover the original amount you borrowed plus the interest',
  LOANS_TEMPLATE_ONLY_INTEREST_DESC:
    'Pay monthly only the interest. The amount you borrowed is settled in your final payment',
  LOANS_TEMPLATE_MIRROR_DESC:
    'We match your deposited BTC, doubling your position by adding the new BTC as collateral too',

  LOANS_ZERO_RISK_CARD_TITLE: '3 Days to Save your Loan',
  LOANS_ZERO_RISK_LEARN_ABOUT: 'Learn about our Zero Risk Loans',
  LOANS_FAQ_TEXT:
    '<b>Nebeus Crypto-Backed Loans</b><br/>' +
    "Don't sell your crypto. Use it as collateral towards a cryptocurrency-backed loan that you can use for your daily expenses or more ambitious long-term needs.<br/><br/>" +
    '<b>Quick Loans</b><br/>' +
    'Nebeus Quick Loans are fast cash loans at 0% interest and 50% LTV for 3 months. The only fee that we charge is a 2.5% loan origination fee when issuing your loan. You can then repay your loan at any time and for free.<br/><br/>' +
    '<b>Flexible Loans</b><br/>' +
    'Nebeus Flexible Loans are loans that you can customize to your individual needs. You can take out as much as 250,000 in FIAT currency or Stablecoins for as long as 36 months. You can choose your LTV, bringing it up to be as high as 80%, and your loan interest rate will automatically be adjusted depending on the LTV you select. ',

  LOANS_ZERO_RISK_TITLE: 'Zero Risk Loans',
  LOANS_ZERO_RISK_DESCRIPTION:
    'Nebeus gives you 3 Days to save your loan & recover your crypto in the event of a Margin Call.<br/><br/>' +
    '<b>If you have activated the Auto Margin Call Management</b><br/>All you have to do is add crypto to your main' +
    ' Nebeus account (BTC or ETH, depending on what collateral you are using). Nebeus will automatically replenish your' +
    ' loan collateral from your main Nebeus account within ten days of a margin call. Your crypto will be saved, and your' +
    ' loan will continue as usual.<br/><br/><b>If you have not activated the Auto Margin Call Management</b><br/>' +
    'You will have the following two options:<br/> Repay your loan within 3 days of the margin call. Once you do,' +
    ' Nebeus will return the total amount of your crypto collateral to your Nebeus account.<br/>' +
    'Add collateral to your loan. Once you do, your crypto will be saved, and your loan will continue as usual.<br/><br/>' +
    '<b>What else?</b><br/>If you did not add crypto to your account and did not repay your loan during the three-day period following a Margin Call, but in the meantime, crypto prices have gone back up, Nebeus will automatically monitor this volatility and will cancel the margin call. Your crypto will be saved, and your loan will go on as if nothing happened.',

  LOANS_EXCESS_RELEASE: 'Excess Collateral Release',
  LOANS_EXCESS_RELEASE_DESC:
    'Nebeus will monitor market volatility and automatically release part of your collateral used for your loan if crypto prices increase by more than 10%. This is an excellent option if you are taking out a longer-term loan.',

  LOANS_AUTO_MC: 'Automatic Margin Call Management',
  LOANS_AUTO_MC_DESC:
    'Nebeus will monitor market volatility and automatically replenish loan collateral from your main Nebeus account if and when approaching a margin call. When turning this option on, make sure to always have additional funds in your main Nebeus account, in the currency you are using as collateral towards your loan.<br/>' +
    '<br/>' +
    'Additionally, you will have 3 days to save your crypto in case of a Margin Call. Nebeus will automatically replenish your loan collateral from your main Nebeus account within 3 days after a margin call. Your crypto will be saved, and your loan will continue as usual.<br/>' +
    '<br/>' +
    'This option gives you peace of mind and protection against volatility.',
  LOANS_ONLY_ZERO_PAYMENT: 'Zero payments',
  LOANS_ONLY_ZERO_PAYMENT_DESC:
    'Interest will accrue monthly, and the borrower will be required to pay the total balance in the form of a large lump sum of both principal and accrued interest at maturity',
  LOANS_ONLY_INTEREST_REPAYMENT: 'Interest Only Repayment',
  LOANS_ONLY_INTEREST_REPAYMENT_DESC:
    '<b>Interest Only Repayment</b><br/>Only pay the loan interest for your loan each month, and repay the loan principal at the end of your loan term or when you want to close your loan.<br/>' +
    '<br/>' +
    '💡 This option is great if you want to get a loan to diversify your investment portfolio by investing your loan into another asset.',
  LOAN_FIELD_TITLE: 'How much can you borrow?',
  LOAN_FIELD_DESC:
    'For Nebeus Loans ou can borrow between %{minAmount} and %{maxAmount} in FIAT currency or Stablecoins for as long as 36 months.',
  COLLATERAL_FIELD_TITLE: 'What is Collateral?',
  COLLATERAL_FIELD_DESC:
    'Collateral is the cryptocurrency that you use to get your loan. After getting your loan and during your loan term, your crypto collateral is held by Nebeus as a security deposit for issuing your loan. <br/>' +
    '<br/>' +
    'Additionally, your collateral will be covered by a $100M insurance policy by Lloyd’s of London throughout your entire loan period.',
  LTV_FIELD_TITLE: 'What is an LTV ratio?',
  LTV_FIELD_DESC:
    'An LTV ratio determines the loan size you can get in relation to the amount of crypto collateral you deposit. So if you are depositing 1000€ worth of crypto and choose an 80% LTV, you will get an 800€ loan. The higher the LTV, the higher the loan amount that you will get. ',
  LOAN_AGREEMENT: 'Loan Agreement',
  LOAN_AGREEMENT_IMPORTANT_TEXT:
    'By accepting you agree to the Pre-Contractual information & Loan Agreement. ',
  LOAN_AGREEMENT_DESC:
    'After accepting, we will send you your copy of the Loan Agreement to your email.',
  LOAN_AGREEMENT_PRE_CONTRACT_INFO: 'Pre-Contractual information',
  LOAN_GET_SUCCESS_TITLE: 'You just got a loan!',
  LOAN_GET_SUCCESS_SUBTITLE:
    'You have just confirmed your Nebeus crypto-backed loan, and your funds are already in your Nebeus account. ',
  LOAN_ID: 'Loan ID',
  LOAN_TYPE: 'Loan type',
  LOANS_PAYMENTS_COLLATERAL: 'Payments & Collateral',
  LOAN_MC_RATE: 'Margin Call Exchange Rate',
  LOANS_REPAY: 'Repay Loan',
  LOANS_ADD_COLLATERAL: 'Add Collateral',
  LOANS_OUTSTANDING_BALANCE: 'Outstanding balance',
  LOANS_LIQUIDATION_RISK: 'Liquidation risk',
  LOANS_LIQUIDATION_RISK_DANGER: 'Dangerous',
  LOANS_LIQUIDATION_RISK_HEALTHY: 'Healthy',
  LOANS_LIQUIDATION_RISK_AVERAGE: 'Average',
  LOANS_AUTO_MC_SHORT: 'Auto Margin Call Management',
  LOANS_MARKETING_AUTO_MC_DESC:
    'Get your loan and let Nebeus automatically<br/> monitor crypto volatility',
  LOANS_MARKETING_FIAT_AND_STABLECOINS_TITLE:
    '<span>Loans</span> in FIAT <br/>' + 'and Stablecoins',
  LOANS_MARKETING_FIAT_AND_STABLECOINS_DESC: 'Up to 250,000 in any currency',
  LOANS_MARKETING_SAVE_LOAN_TITLE: '3 Days to Save<br/>your Loan',
  LOANS_MARKETING_SAVE_LOAN_DESC:
    'Zero risk loans: recover your Crypto in the<br/>event of a Margin Call',
  LOANS_MARKETING_FLEX_LOANS_TITLE:
    '<span>Flexible Loans:</span><br/>' + 'Up to 80% LTV & <br/>' + 'as long as 36 months',
  LOANS_MARKETING_QUICK_LOANS_TITLE:
    '<span>Quick Loans:</span><br/>' + '0% interest at 50% LTV <br/>' + 'for 3 months',
  LOANS_MARKETING_EXCESS_RELEASE_DESC:
    'Get your collateral back if crypto prices rise by more than 10%',
  LOANS_TOTAL_COLLATERAL: 'Total loans collateral',
  LOANS_NUMBER: 'Number of loans',
  LOANS_NO_ACTIVE_ITEMS: 'Uh oh, no active loans',
  LOANS_NO_ACTIVE_ITEMS_SUBTITLE:
    "You don't have any active loans, but you can get one by following a few simple steps.",
  LOANS_NO_HISTORY_ITEMS: 'No History for the moment',
  LOANS_NO_HISTORY_ITEMS_SUBTITLE:
    'But that can all change, and you can start making history if you get a loan now.',
  LOANS_DOWNLOAD_STATEMENT: 'Download Loan Statement',
  LOANS_PAYMENTS_LEFT: 'Payments left',
  LOANS_MONTHLY_REPAYMENT: 'Monthly repayment',
  LOANS_ISSUED: 'Loan issued',
  LOANS_MANUAL_PAYMENT: 'Manual payment',
  LOANS_COLLATERAL_DOUBLED: 'Collateral doubled',
  LOAN_MOVEMENT: 'Movement',
  LOANS_COLLATERAL_ADDED: 'Collateral added',
  LOANS_COLLATERAL_ADDED_MANUALLY: 'Collateral added manually',
  LOANS_COLLATERAL_INITIAL_PAYMENT: 'Initial payment',
  LOANS_COLLATERAL_LIQUIDATED_BY_MC: 'Margin Call Liquidation',
  LOANS_COLLATERAL_PAYOUT_BACK: 'Payout back',
  LOANS_REPAYMENT: 'Loan Repayment',
  LOANS_EARLY_REPAYMENT: 'Early repayment',
  LOANS_EARLY_REPAYMENT_FEE: 'Early repayment fee',
  LOANS_EARLY_REPAYMENT_FEE_DESC: '2.5% fee if you repay your loan before the end of its term',
  LOANS_OUTSTANDING_INTEREST_RATE_PAYMENT: 'Outstanding Interest Rate Payment',
  LOANS_OUTSTANDING_FINES_FOR_LATE: 'Outstanding fines for late repayment',
  LOANS_OTHER_OUTSTANDING_LATE_PAYMENTS: 'Other outstanding late payments',
  LOANS_REPAY_FULL_AMOUNT: 'Repay full amount',
  LOANS_REPAID_PART: 'Repayment Received',
  LOANS_REPAID_PART_DESC:
    "You've just repaid part of the outstanding balance of your loan, and we've successfully received the payment.  ",
  LOANS_REPAID: 'Repaid',
  LOANS_REPAID_FULL: 'Loan repaid',
  LOANS_REPAID_FULL_DESC:
    "You've just repaid the full & outstanding balance of your loan. Your loan is now closed, and your collateral will be returned to your Nebeus account.",
  LOANS_TOTAL_OUTSTANDING_BALANCE: 'Total Outstanding Balance',
  LOANS_REPAY_LOAN: 'Repay Loan',
  LOANS_REPAY_LESS_90_PERCENT: 'Please repay less than 90% of the amount or the full amount.',
  LOANS_REPAYMENT_PARTIAL_IS_NOT_ALLOWED: 'Partial repayment is not allowed',
  LOANS_REPAYMENT_FULL_IS_NOT_ALLOWED: 'Full repayment is not allowed',
  LOANS_OUTSTANDING_BALANCE_INFO:
    '<b>Total Outstanding Balance</b><br/>The total outstanding balance includes the outstanding loan repayment, the remaining interest rate payment, and any late repayment fines that might have occurred.',
  LOANS_ADD_LOAN_COLLATERAL_REDUCE_RISK: 'Reduce Liquidation Risk',
  LOANS_ADD_LOAN_COLLATERAL: 'Add Loan Collateral',
  LOANS_CURRENT_COLLATERAL: 'Current collateral',
  LOANS_CURRENT_LIQ_RISK: 'Current liquidation risk',
  LOANS_COLLATERAL_APPROACHING_TO_MC_DESC:
    "Markets are currently very volatile, and your loan is approaching a margin call. Please add the recommended minimum as collateral to lower the liquidation risk of your loan. If you don't do this, your loan might be liquidated if crypto prices continue to fall.",
  LOANS_ADD_COLLATERAL_NOTE:
    'Note: Liquidation risk is based on current market data, and may change depending on market conditions.',
  LOANS_ADD_COLLATERAL_HELP_CALCULATION:
    "By adding <span>%{amountLabel}</span>, you will reduce your loan's liquidation risk to <span>%{percent}%</span>",

  LOANS_ADD_COLLATERAL_MANUAL_FAQ_TITLE: 'Adding Loan Collateral',
  LOANS_ADD_COLLATERAL_MANUAL_FAQ_DESC:
    'By manually adding loan collateral, you reduce the risk of your loan entering a margin call when crypto markets are volatile.',
  LOANS_LOAN_IS_HEALTHY: 'Your loan is healthy!',
  LOANS_ADD_COLLATERAL_HEALTHY_DESC:
    "You've just lowered the liquidation risk of your loan, and your loan is now healthy.",
  LOANS_ADD_COLLATERAL_BELOW_MIN_DESC:
    "You've just successfully added collateral to your loan, but the added amount is below the recommended minimum.<br/> <br/>We recommend adding an additional " +
    '<span>%{amountLabel}</span> to lower the liquidation risk.',
  LOANS_ADD_COLLATERAL_CONFIRM_DESC:
    "You are about to add loan collateral to your loan. You will not be able to withdraw your loan collateral until the end of your loan's term or until you manually close your loan.",
  LOANS_ADD_COLLATERAL_FUNDS_FLOW: 'Funds flow',
  LOANS_RISK_REDUCED: 'Risk reduced',
  LOANS_COLLATERAL_ADDED_TITLE: 'Loan collateral <span>added!</span>',
  LOANS_COLLATERAL_ADDED_DESC:
    'You just added loan collateral to your loan and reduced the risk of your loan entering a margin call when crypto markets are volatile.',

  LOANS_MIN_DEPOSIT_FOR_SAVE_LOAN: 'Minimum deposit to save your loan',

  LOANS_LOAN_UNDER_MC: 'Your loan is at a Margin Call',
  LOANS_LOAN_UNDER_MC_SUBTITLE: 'You have %{days} days to save your loan.',
  LOANS_LOAN_PRE_MC: 'Your loan is close to Margin Call',
  LOANS_LOAN_PRE_MC_SUBTITLE: 'Reduce your loan liquidation risk',
  LOANS_LOAN_AUTO_PAYMENT_FAILED: 'Automated Payment Failed',
  LOANS_LOAN_AUTO_PAYMENT_FAILED_DESC:
    "Automated loan repayment has failed because you don't have enough funds in your account. Please deposit the required amount so that Nebeus can automatically charge the repayment from your account.",
  LOANS_LOAN_AUTO_PAYMENT_FAILED_SUBTITLE:
    'Deposit funds to your %{currencyLabel} account or manually repay your loan.',
  LOANS_LOAN_AUTO_PAYMENT_SUCCEEDED: 'Automated Payment Succeeded',
  LOANS_LOAN_AUTO_PAYMENT_SUCCEEDED_SUBTITLE: 'Loan repayment charged.',

  LOANS_LOAN_MC_AVOIDED_TITLE: 'Your loan has been saved',
  LOANS_LOAN_MC_AVOIDED_SUBTITLE: 'A Margin Call has been avoided.',
  LOANS_LOAN_AUTO_MC_FAILED_TITLE: 'Auto margin call management failed',
  LOANS_LOAN_EXCESS_COLLATERAL_RELEASE_TITLE: 'Excess collateral has been released',

  LOANS_LOAN_UNDER_MC_DESC:
    'Your loan is at a Margin Call. You have %{days} days to repay the total amount of your loan or add collateral. <br/>' +
    '<br/>' +
    "If you don't do this, your crypto might be liquidated if crypto prices do not increase within 3 days of entering the margin call. After liquidation, excess collateral will be returned to your Nebeus account.",
  LOANS_LOAN_UNDER_MC_AUTO_MANAGEMENT_DESC:
    "Your loan is at a Margin Call, and you don't have enough funds in your Nebeus %{currencyLabel} account. You have %{days} days to save your loan by depositing the recommended minimum into your %{currencyCode} account so that Nebeus can automatically replenish your loan collateral. Your crypto will be saved, and your loan will continue as usual.<br/>" +
    '<br/>' +
    'If you wish, you can also repay the totality of your loan, and your crypto will be saved.<br/>' +
    '<br/>' +
    "If you don't do this, your crypto might be liquidated if crypto prices do not increase within 3 days of entering the margin call. ",
  LOANS_MC_AVOIDED: 'Margin Call Avoided',
  LOANS_MC_AVOIDED_AUTO_DESC:
    'Nebeus has automatically replenished your loan collateral. Your loan and your crypto have been saved!',
  LOANS_MC_AVOIDED_DESC:
    'Nebeus has monitored crypto market volatility, and prices have gone back up. Your margin call has automatically been canceled, and your loan and crypto have been saved!',
  LOANS_TEMPLATE_IS_MISSING: 'Template is missing. Please, contact our support to fix it',
  LOANS_PICK_TEMPLATE: 'Pick your loan type',

  // Smart trader
  SMART_TRADER: 'Smart Trader',
  SMART_TRADER_WELCOME: 'Welcome to Nebeus Smart Trader',
  SMART_TRADER_DESCRIPTION:
    'Nebeus Smart Trader lets you trade crypto and define your <b>take profit and stop loss spread</b> so that Nebeus informs you of price fluctuations allowing you to trade without monitoring the market every minute.',
  SMART_TRADER_WELCOME_SUBTITLE:
    "Nebeus will inform you when it's time to buy or sell but will not automatically buy or sell for you.",
  SMART_TRADER_PICK_ASSETS_TITLE:
    'Would you like to buy %{currencyLabel} or use your existing assets?',
  SMART_TRADER_PICK_ASSETS_SUBTITLE:
    'Use your existing crypto to activate smart trader, or buy %{currencyLabel} to start?',
  SMART_TRADER_USE_EXISTING_ASSETS: 'Use Existing Assets',
  SMART_TRADER_SELECT_PAIR_TITLE: 'Select Your Trading Pair',
  SMART_TRADER_SELECT_PAIR_SUBTITLE:
    'Select your trading pair and the value that you want to trade.',
  SMART_TRADER_INSUFFICIENT_FUNDS:
    'It looks like you don’t have any %{currencyLabel} in your Nebeus account. Please deposit %{currencyLabel} to start using Nebeus Smart Trader.',
  SMART_TRADER_CONFIGURE_TITLE: 'Configure your trade',
  SMART_TRADER_CONFIGURE_SUBTITLE:
    'Set your take profit and stop loss targets so Nebeus can monitor market volatility and inform you of changes.',
  SMART_TRADER_YOUR_FUNDS: 'Your funds',
  SMART_TRADER_YOUR_PROFIT: 'Your profit',
  SMART_TRADER_YOUR_LOSS: 'Your loss',
  SMART_TRADER_TAKE_PROFIT: 'Take profit',
  SMART_TRADER_STOP_LOSS: 'Stop loss',
  SMART_TRADER_CURRENT_VALUE_IN: 'Current value in %{currencyCode}',
  SMART_TRADER_MAX_LOSS: 'Max loss',
  SMART_TRADER_TARGET_PROFIT: 'Target profit',
  SMART_TRADER_ACTIVATED_TITLE: 'Smart Trader Activated',
  SMART_TRADER_ACTIVATED_SUBTITLE:
    'You have successfully chosen your trading levels. Nebeus Smart Trader is now activated and is monitoring market changes. <br/><br/>Check your dashboard to stay up to date, and Nebeus will also send you notifications about changes.',
  SMART_TRADER_CHANGE_SPREAD: 'Change Take Profit / Stop Loss',
  SMART_TRADER_TRADE_CREATED: 'Smart trader started',
  SMART_TRADER_BUY_OPERATION: '%{currencyCode} bought',
  SMART_TRADER_SELL_OPERATION: '%{currencyCode} sold',
  SMART_TRADER_STOP_LOSS_TRIGGERED: 'Max loss reached',
  SMART_TRADER_TAKE_PROFIT_TRIGGERED: 'Target profit reached',
  SMART_TRADER_STOP_LOSS_TAKE_PROFIT_CHANGED: 'Spread levels changed',
  SMART_TRADER_EXCHANGE_SUBTITLE:
    'Instantly exchange crypto that you want to use for Nebeus Smart Trader.',
  SMART_TRADER_EXCHANGE_SUCCESS_SUBTITLE:
    'Your exchange is confirmed, and your crypto is ready to be used with Nebeus Smart Trader.',
  SMART_TRADER_SETUP: 'Set up Nebeus Smart Trader',

  SESSION_EXPIRED_WARNING_TITLE: 'Session expired',
  SESSION_EXPIRED_WARNING_SUBTITLE:
    'Your session is ending. For security purposes, you will be logged out after %{seconds} seconds. Do you want to continue the session?',
  SESSION_EXPIRED_WARNING_CONTINUE_BUTTON: 'Continue session',
  SESSION_EXPIRED_WARNING_UNABLE_TO_CONTINUE: 'Unable to continue session',

  RECEIVE_OTP_BY_EMAIL: 'Receive confirmation codes by email',
  RECEIVE_OTP_BY_PHONE: 'Receive confirmation codes by SMS',

  CLOSE_ACCOUNT_FINAL_TITLE: 'Final confirmation...',
  CLOSE_ACCOUNT_FINAL_SUBTITLE: 'Close your Nebeus account?',
  CLOSE_ACCOUNT_TITLE: 'We’re sad to see you go...',
  CLOSE_ACCOUNT_SUBTITLE: 'Are you sure you want to close your Nebeus account?',
  CLOSE_ACCOUNT_CHECK_LIST_TITLE: 'Before closing your account...',
  CLOSE_ACCOUNT_CHECK_LIST_SUBTITLE: 'Please make sure to confirm all of the below:',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_TITLE: 'Confirm that you have:',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_TITLE2: 'When you close your account:',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_1_TEXT:
    '&#x2022; No active crypto-backed loans;<br/>' +
    '&#x2022; No active crypto-renting programs;<br/>' +
    '&#x2022; No crypto in staking;<br/>' +
    '&#x2022; No crypto in any Nebeus vaults.',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_2_TEXT:
    '&#x2022; Withdrawn all crypto to an external wallet;<br/>' +
    '&#x2022; Withdrwarn all your fiat to your bank account or bank card.',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_3_TEXT:
    '&#x2022; All active subscription (if any) will be canceled and closed;<br/>' +
    '&#x2022; If any crypto or fiat funds remain on Nebeus, they might be lost.',

  WATCH_LIST: 'Watchlist',
  WATCH_LIST_EMPTY_TITLE: 'Your watch list is empty',
  WATCH_LIST_EMPTY_SUBTITLE: 'Start by adding your favorite coins here.',
  EXCHANGE_MARKETS: 'Markets',

  TRADE_DIRECTION_INVALID: 'Trade pair is not allowed',
  USER_NOT_FOUND: 'User not found',
  TRADE_WITH_THIS_CURRENCY_ALREADY_EXIST: 'Trade with this currency already exist',
  TRADE_CREATED: 'Trade created',
  STOP_LOSS_OR_TAKE_PROFIT_AMOUNT_MISMATCH:
    'Incorrect stop loss or take profit amount. Please, check values',
  TRADE_NOT_FOUND: 'Trade not found',

  // nebeus cards
  BANK_CARDS_DESCRIPTION: 'Get a Nebeus card',
  BANK_CARDS_CURVE_APP_AD_TITLE: 'Use your Nebeus Card contactless via the Curve app',

  // CRYPTO BANK CARD
  CRYPTO_BANK_CARD_SHIPPING_DETAILS: 'Shipping details',
  CRYPTO_VIRTUAL_BANK_CARD_USER_DETAILS: 'User additional info',
  CRYPTO_BANK_CARD_SHIPPING_COUNTRY: 'Shipping Country',
  CRYPTO_BANK_CARD_SHIPPING_COUNTRY_PLACEHOLDER: 'Search for shipping country',
  CRYPTO_BANK_CARD_SHIPPING_CITY: 'Shipping City',
  CRYPTO_BANK_CARD_SHIPPING_ADDRESS: 'Shipping Address',
  CRYPTO_BANK_CARD_SHIPPING_ADDRESS_1: 'Street name and house number',
  CRYPTO_BANK_CARD_SHIPPING_ADDRESS_2: 'Apt, floor, suite (optional)',
  CRYPTO_BANK_CARD_SHIPPING_POST_CODE: 'Postcode',
  CRYPTO_BANK_CARD_SHIPPING_CHOOSE_METHOD: 'Choose shipping method',
  CRYPTO_BANK_CARD_CHOOSE_TYPE: 'Choose Nebeus Card type',
  CRYPTO_BANK_CARD_AD_CARD_TITLE: 'Order Nebeus Card now',
  CRYPTO_BANK_CARD_AD_CARD_TITLE_WITH_COUNTRY: 'Order Nebeus Card now in <span>%{country}</span>',
  CRYPTO_BANK_CARD_AD_CARD_FEATURE_1: 'Fiat & Crypto',
  CRYPTO_BANK_CARD_AD_CARD_FEATURE_2: 'Instant Top-Up with Stablecoins',
  CRYPTO_BANK_CARD_AD_CARD_FEATURE_3: 'Flexible Spending Limits & more',
  CRYPTO_BANK_CARD_ORDERED_SUCCESS_TITLE: 'Nebeus card has been successfully ordered',
  CRYPTO_BANK_CARD_ORDERED_NOT_PAID_SUCCESS_TITLE: 'Nebeus Card Order Received',
  CRYPTO_VIRTUAL_BANK_CARD_ORDERED_SUCCESS_TITLE:
    'Nebeus virtual card has been successfully ordered',

  CRYPTO_BANK_CARD_NEED_MIN_DEPOSIT_ORDERED_SUCCESS_TEXT:
    'Great news! Your Nebeus card order is in. To finalize the process, ensure a minimum balance of %{minAmountInEur} euros in any of your accounts. After card confirmation, use those funds freely. Check your dashboard for the order status.',
  CRYPTO_BANK_CARD_NOT_PAYED_ORDERED_SUCCESS_TEXT:
    'Your order will be processed as soon as you deposit funds into your %{currencyLabel} wallet.%{additionalConditionText} Order status will be displayed in the dashboard.',
  CRYPTO_BANK_CARD_ORDERED_SUCCESS_TEXT:
    'Your order is being processed. Order status will be displayed in the dashboard.',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_TITLE: 'Your Nebeus card was ordered',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_VIRTUAL_TITLE: 'Your Nebeus Virtual card was created',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_TEXT:
    'Has the Nebeus Card already arrived? Activate it and start using!',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_VIRTUAL_TEXT: 'Connect your wallet and start using!',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_TITLE: 'Your Nebeus Card Awaiting Confirmation',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TITLE: 'Your Nebeus card is awaiting payment',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TEXT:
    'Please deposit %{paymentLabel} to your %{currencyLabel} wallet as soon as possible to cover DHL delivery cost.',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TEXT_BY_CARD:
    'Please deposit %{paymentLabel} to your %{currencyLabel} wallet as soon as possible or pay now by card to cover DHL delivery cost.',
  CRYPTO_BANK_CARD_ORDER_CARD_MIN_AMOUNT_RULE:
    'Ready to make things happen? To proceed with card issuance, make sure you have a minimum of %{minAmountInEur} euros in any of your accounts. After confirmation, freely use these funds without restrictions.',
  CRYPTO_BANK_CARD_ORDER_CARD_ACCOUNT_VERIFICATION_SUBTITLE:
    'Verify your identity to get your Nebeus card',

  CRYPTO_BANK_CARD_ORDER_PAY_BY_CARD: 'Pay by card',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_FAILED_TITLE: 'Sorry, your card issue has been rejected',
  BANK_CARD_FEATURES: 'Card features',
  CRYPTO_BANK_CARD_FEATURE_1_TITLE: 'Link Fiat or Crypto accounts',
  CRYPTO_BANK_CARD_FEATURE_1_DESC:
    'You can only use one fiat or crypto account for your debit card. You can change the linked account at any time depending on your needs and capabilities',
  CRYPTO_BANK_CARD_FEATURE_2_TITLE: 'Instant Top-up with Stablecoins',
  CRYPTO_BANK_CARD_FEATURE_2_DESC:
    'Enjoy the convenience of instantly topping up your card using stablecoins like USDT (Tether) and USDC (USD Coin).',
  CRYPTO_BANK_CARD_FEATURE_3_TITLE: 'Nominated Card Currency',
  CRYPTO_BANK_CARD_FEATURE_3_DESC:
    'Choose your nominated card currency from a variety of options, including EUR, USD, and GBP, to suit your preferences and needs.',
  CRYPTO_BANK_CARD_FEATURE_4_TITLE: 'Flexible Spending Limits',
  CRYPTO_BANK_CARD_FEATURE_4_DESC:
    'Benefit from flexible daily and monthly spend limits of 5,000 EUR, USD, or GBP and 35,000 EUR, USD, or GBP, respectively, giving you control over your spending.',
  CRYPTO_BANK_CARD_FEATURE_5_TITLE: 'ATM Withdrawals',
  CRYPTO_BANK_CARD_FEATURE_5_TITLE_SHORT: 'ATM Withdrawals (Free up to €200/monthly)',
  CRYPTO_BANK_CARD_FEATURE_5_DESC:
    'Easily access your funds with a monthly ATM withdrawal limit of 2,000 EUR, USD, or GBP.',
  CRYPTO_BANK_CARD_FEATURE_6_TITLE: 'Free ATM Withdrawals',
  CRYPTO_BANK_CARD_FEATURE_6_DESC:
    'Enjoy the convenience of the first 200 EUR, USD, or GBP in ATM withdrawals being free of charge.',
  CRYPTO_BANK_CARD_FEATURE_7_TITLE: 'Delivery Options',
  CRYPTO_BANK_CARD_FEATURE_7_DESC:
    'Choose between standard delivery times and expedited delivery for an extra charge to receive your card promptly.<br/><br/>The Nebeus Mastercard is designed to empower you with the utmost flexibility and convenience in managing your cryptocurrencies. Whether you are making online purchases, withdrawing cash, or switching between crypto assets, our Mastercard ensures a seamless experience for your financial needs.',
  CRYPTO_BANK_CARD_CONNECTED_BALANCE: 'Connected account balance',
  CRYPTO_BANK_CARD_CONNECT_ACCOUNT: 'Connect account',
  CRYPTO_BANK_CARD_CONNECT: 'Connect',
  CRYPTO_BANK_CARD_SWITCH_CONNECT_ACCOUNT: 'Switch connected account',
  CRYPTO_BANK_CARD_ACTIVATE_CARD: 'Activate your card first',
  CRYPTO_BANK_CARD_ACTIVATE_CARD_TITLE: 'Activate Nebeus Card',
  CRYPTO_BANK_CARD_ACTIVATE_CARD_SUBTITLE:
    'Enter the cvc code on the back of the card to activate it and start using it fully',
  CRYPTO_BANK_CARD_ACTIVATED_TITLE: 'Your card has been successfully activated',
  CRYPTO_BANK_CARD_ACTIVATED_SUBTITLE:
    "Activate your new contactless card with your first transaction, either at an ATM for a balance inquiry or by inserting it into a POS terminal for payment. After that, it's ready for contactless use!",
  CRYPTO_BANK_CARD_CONNECT_ACCOUNT_SUBTITLE:
    'You can only use one cash or crypto account for your debit card. You can change the linked account at any time depending on your needs and capabilities',
  CRYPTO_BANK_CARD_CONNECT_ACCOUNT_CURRENCY: 'Connect %{currencyCode} account',
  CRYPTO_BANK_CARD_CONNECTED: 'Card currency account switched to %{currencyCode}',
  CRYPTO_BANK_CARD_CURRENT_CARD_CURRENCY_ACCOUNT: 'Current card currency account',
  CRYPTO_BANK_CARD_ALREADY_EXIST: 'Crypto card ordered',
  CRYPTO_BANK_CARD_ALREADY_ORDERED:
    'Crypto card has already been ordered. You cannot order more than one physical card',
  CRYPTO_BANK_CARD_SHIPPING_CALCULATING_COSTS: 'Calculating your shipping costs...',
  BANK_CARD_OLD_PIN: 'Old PIN',
  BANK_CARD_NEW_PIN: 'New PIN',
  BANK_CARD_PIN_CHANGE_SUCCESS: 'PIN code has been successfully changed',
  CRYPTO_VIRTUAL_BANK_CARD_AD_TITLE: 'Get Nebeus Virtual Mastercard',
  CRYPTO_BANK_CARD_ISSUANCE_RULE_REMEMBER:
    'Remember that for card issuance, %{minAmountLabel} is needed in any of your accounts for operational purposes. After ordering, freely use the funds.',
  CRYPTO_BANK_CARD_ISSUANCE_RULE:
    'For card issuance you need to have at least %{minAmount} euros in any of your accounts. After confirmation, these funds are available for your use without restrictions',

  CRYPTO_BANK_CARD_MONTHLY_LIMIT: 'Monthly limit',
  CRYPTO_BANK_CARD_MONTHLY_LIMIT_RESET_DATE: 'Limits reset date',

  // APP VERIFICATION
  APP_VERIFICATION_FACE_ID: 'Face ID',
  APP_VERIFICATION_TOUCH_ID: 'Touch ID',
  APP_VERIFICATION_BIOMETRY: 'Biometry',

  // API CODES
  ERROR_TFA_CODE_INVALID: 'Code is invalid',
  ERROR_OTP_INVALID: 'Provided otp code is invalid',
  ERROR_INVALID_CREDENTIALS: 'Invalid credentials',
  ERROR_VERIFY_ACCOUNT: 'Please, verify your account',
  ERROR_SOMETHING_WRONG_TRY_LATER: 'Something went wrong. Try again later.',
  ERROR_SOMETHING_WRONG_TRY_LATER_2: 'Oops, something seems to have gone wrong. Please try again.',
  ERROR_WALLET_NOT_FOUND: 'Wallet not found',
  ERROR_SOMETHING_WRONG_WITH_ACCOUNT:
    'Something wrong with your account. Please, contact Nebeus support team.',
  ERROR_EMAIL_REGISTER_IMPOSSIBLE: 'Registration with this email address is not possible',
  ERROR_CANT_SEND_OTP_TO_EMAIL: 'Failed to send email to your email address',
  ERROR_OTP_CODE_VALIDATION_FAILURE: 'OTP code is invalid or has been expired',
  ERROR_CANT_CREATE_WALLET_ISSUER_NOT_WOUND:
    'Failed to create wallet. Issuer not found. Please, contact Nebeus support team or try later',
  ERROR_CANT_ACTIVATE_CRYPTO_CARD: 'Failed to activate card. Probably, CVC code is incorrect.',
  ERROR_BLOCKED_USER: 'Your account has limitations. Contact Nebeus support for details',

  NEBEUS_VIRTUAL_IBAN_CARD: 'Nebeus Virtual IBAN Card',
  NEBEUS_CRYPTO_CARD: 'Nebeus Card',
  NEBEUS_CRYPTO_VIRTUAL_CARD: 'Nebeus Virtual Card',
  CURRENCY_ACCOUNT_BALANCE: '%{currencyCode} account balance',
  IBAN_CARD_FEATURES: 'Virtual IBAN card features',
  CRYPTO_CARD_FEATURES: 'Nebeus Card features',

  USER_FEATURE_BLOCKED: 'This feature is temporarily unavailable. Please contact support',

  // Invoices
  INVOICES: 'Invoices',
  INVOICE: 'Invoice',
  INVOICES_NO_INVOICES: 'You have no invoices yet',
  INVOICES_NO_INVOICES_SUBTITLE:
    'You can start creating an invoice or begin by entering details about your clients',
  INVOICES_ADD_YOUR_CLIENT_DETAILS: 'Add your clients details',
  INVOICES_CREATE: 'Create an invoice',
  INVOICE_DATE: 'Invoice date',
  INVOICE_COMPANY_OR_CLIENT_NAME: 'Company/Client name',
  INVOICE_USER_DETAILS: 'Your / your company details',
  INVOICES_CLIENT_DETAILS: 'Client details',
  INVOICES_SAVE_FOR_FUTURE_INVOICES: 'Save for future invoices',
  INVOICES_USE_SAVED_DETAILS: 'Use Saved details',
  INVOICE_DETAILS: 'Invoice details',
  INVOICE_NUMBER: 'Invoice number',
  INVOICE_DUE_DATE: 'Due date',
  INVOICE_CURRENCY: 'Invoice currency',
  INVOICE_REFERENCE: 'PO / Reference*',
  INVOICE_ITEMS: 'Invoice items',
  INVOICE_PRODUCT_OF_SERVICE_WITH_NUMBER: 'Product or service %{count}',
  INVOICE_DISCOUNT: 'Invoice discount',
  INVOICE_NOTES: 'Invoice notes',
  INVOICE_NOTES_PLACEHOLDER: 'Any terms or other sales related information*',
  INVOICE_SUB_TOTAL: 'Sub total',
  NEBEUS_CRYPTO_ACCOUNT: 'Nebeus crypto account',
  NEBEUS_IBAN_ACCOUNT: 'Nebeus IBAN account',
  NEBEUS_CASH_ACCOUNT: 'Nebeus cash account',
  // INVOICE_GET_MONEY_ACCOUNT_DESC: 'Get yourself an IBAN account bundled with our branded VISA card',
  INVOICE_GET_MONEY_ACCOUNT_DESC: 'Get yourself an IBAN account', // TODO: IBAN CARD ISSUE

  INVOICE_CREATED_TITLE: 'Your invoice created',
  INVOICE_CREATED_SUBTITLE:
    'The invoice created. Now, you can save it on your device and share it with someone else',
  INVOICE_VIEW_INVOICE: 'View invoice',
  INVOICE_REMOVE: 'Remove invoice',
  INVOICE_REMOVED: 'Invoice #%{invoiceId} removed',
  INVOICE_USE_AS_TEMPLATE: 'Use as template',
  INVOICE_BACK_TO_INVOICES: 'Back to invoices',
  INVOICE_DISCOUNT_MUST_BE_LESS_THAT_TOTAL: 'Discount must be less than the total amount',
  INVOICES_NO_CLIENTS: 'You have no details about your clients',
  INVOICE_CLIENT_ADDED: 'Client details added',
  INVOICE_CLIENT_REMOVED: 'Details removed',
  INVOICE_REMOVE_DETAILS: 'Remove details',
  INVOICE_VALIDATION_DUE_DATE_BEFORE_INVOICE_DATE: 'Due date cant be earlier than invoice date',
  INVOICES_MANAGE: 'Manage invoices',
  INVOICES_MANAGE_MODE_OFF: 'Cancel managing',
  INVOICE_DELETE_MULTIPLE: 'Delete %{count} invoice(s)',
  INVOICE_DELETED: '%{count} invoice(s) deleted',

  FOLLOWED_CURRENCIES_FOLLOW: 'Follow needed currencies',
  FOLLOWED_CURRENCIES_MANAGE: 'Manage',
  MANAGE_CURRENCIES: 'Manage currencies',

  NEBEUS_ALL_RIGHT_RESERVED: '© %{currentYear} Nebeus. All Rights Reserved.',

  CRYPTO_CARD_INTRO_TITLE: 'Enhanced Nebeus Card',
  CRYPTO_CARD_INTRO_SUBTITLE:
    'The Nebeus Card now supports fiat! Explore the updated terms and fees.',
  CRYPTO_CARD_INTRO_DESC:
    'The Nebeus Card is your all-in-one debit card, now with added fiat! Whether you prefer virtual or physical, our card lets you seamlessly make transactions – payments, deposits, and more – using funds from your Nebeus fiat trading accounts and cryptocurrency wallets.',

  CRYPTO_CARD_INTRO_NEW_CONDITIONS_AND_FEES: 'New conditions & fees',
  CRYPTO_CARD_FEE_SYSTEM_TITLE: 'Flexible fee system',
  CRYPTO_CARD_FEES_AND_CONDITION_SYSTEM_TITLE: 'Fees & conditions of the Nebeus Card',
  CRYPTO_CARD_FEE_SYSTEM_DESC:
    'Our monthly commission system is flexible, adjusting to your spending in fiat and cryptocurrencies. Spending with your fiat account incurs a monthly fee.',

  CRYPTO_CARD_FEE_OPTION_CRYPTO: "If you're paying with crypto",
  CRYPTO_CARD_FEE_OPTION_CRYPTO_CONDITION_MORE: 'Spends more than %{amountLabel}',
  CRYPTO_CARD_FEE_OPTION_CRYPTO_CONDITION_LESS: 'Spends less than %{amountLabel}',
  CRYPTO_CARD_FEE_OPTION_FIAT: "If you're paying with fiat",
  CRYPTO_CARD_FEE_OPTION_CRYPTO_AND_FIAT: "If you're paying with crypto & Fiat",
  CRYPTO_CARD_FEES_AND_CONDITIONS: 'Fees & conditions of the Nebeus Card',
  CRYPTO_CARD_COUNTRY_IS_NOT_ALLOWED: 'This country is not allowed for crypto card order',

  // transactions
  TRANSACTION_CRYPTO_TRANSFER: 'Crypto transfer',
  TRANSACTION_REVERSAL_PAYMENT: 'Reversal',
  TRANSACTION_UNIVERSAL_CARD: 'Nebeus Card',
  TRANSACTION_UNIVERSAL_CARD_CASH_WITHDRAWAL: 'Nebeus Card cash withdrawal',
  TRANSACTION_INCOME: 'Income payment',
  TRANSACTION_APPLE_PAY: 'Apple pay',
  TRANSACTION_SEPA: 'SEPA transfer',
  TRANSACTION_WIRE: 'Wire transfer',
  TRANSACTION_LOAN: 'Loan',
  TRANSACTION_MONEY_SUBSCRIPTION: 'IBAN Subscription',
  TRANSACTION_MONEY_VIRTUAL_CARD: 'IBAN Virtual card',
  TRANSACTION_MONEY_INCOME: 'IBAN Income',
  TRANSACTION_REF_REWARD: 'Referral program reward',
  TRANSACTION_RENTING: 'Renting',
  TRANSACTION_BANK_CARD_WITHDRAWAL: 'Bank card withdrawal from crypto account',
  TRANSACTION_BANK_CARD_DEPOSIT: 'Bank card deposit to crypto account',
  TRANSACTION_BETWEEN_ACCOUNTS: 'Between accounts',
  TRANSACTION_TO_NEBEUS_USER: 'To Nebeus user',
  TRANSACTION_FROM_NEBEUS_USER: 'From Nebeus user',
  TRANSACTION_STAKING: 'Staking',
  TRANSACTION_VAULT: 'Vault',
  TRANSACTION_WIDGET: 'Crypto widget',
  TRANSACTION_EXCHANGE: 'Exchange',
  TRANSACTION_IBAN_MONTHLY_FEE: 'IBAN Account monthly fee',
  TRANSACTION_REJECT_REASON: 'Reject reason',
  TRANSACTION_REJECT_REASON_FORBIDDEN_WALLET:
    'Funds cannot be transferred to the specified wallet. Please try another wallet',

  UNLOCKED_SEPA_WIRE_PAYMENT_FOR_EU_USERS_TITLE: 'New payment methods are available!',
  UNLOCKED_SEPA_WIRE_PAYMENT_FOR_EU_USERS_DESCRIPTION:
    'Big news for our EU clients! We’ve added new payment methods for you.<br/><br/>Now, you can also use SEPA and Wire transfer for deposit and withdraw EUR and GBP',

  ONBOARDING_CARD_OFFER_TITLE: 'Order a Nebeus Card for Free connected to your crypto and fiat',
  ONBOARDING_CARD_OFFER_SUBMIT_BUTTON: 'Order a Nebeus Card for Free',
  ONBOARDING_CARD_OFFER_REJECT_BUTTON: 'Maybe later',
  ONBOARDING_CARD_OFFER_TYPE_TITLE: 'What type of card do you want to order?',
  BANK_CARD_OFFER_ONBOARDING_SUCCESS_TITLE: 'The request for the card order has been accepted',
  BANK_CARD_OFFER_ONBOARDING_SUCCESS_DESCRIPTION:
    'Your order is currently being processed. We will keep you updated on the status of your order as it progresses.',
  ONBOARDING_CARD_OFFER_INSTRUCTIONS_TITLE: 'Order your card',
  ONBOARDING_CARD_OFFER_INSTRUCTIONS_SUBTITLE: 'Here’s what you need to do next',
  ONBOARDING_CARD_OFFER_INSTRUCTIONS_TITLE_STEP_1: 'Enter your delivery address',
  ONBOARDING_CARD_OFFER_INSTRUCTIONS_TITLE_STEP_2: 'Verify your identity',
  ONBOARDING_CARD_OFFER_DELIVERY_TITLE: 'What’s your delivery address?',
  ONBOARDING_CARD_OFFER_ADDRESS_ENTER_MANUALLY: 'Enter address manually',

  RATE_APP_TITLE: 'Enhance your experience by rating Nebeus',
  RATE_APP_SUCCESS: 'Thank you for your rating!',
  RATE_APP_REQUEST_TITLE: 'Enjoying Nebeus?',
  RATE_APP_REQUEST_SUBTITLE_TRUSTPILOT: 'Please leave a review on Trustpilot',
  RATE_APP_REQUEST_SUBTITLE_APP_STORE: 'Please leave a review on App Store',
  RATE_APP_REQUEST_SUBTITLE_GOOGLE_PLAY: 'Please leave a review on Google Play',
  RATE_APP_TRUSTPILOT_BUTTON: 'Leave a review on Trustpilot',
  RATE_APP_AGREE: 'Leave a review',
  RATE_APP_LATER: 'Maybe later',
  RATE_APP_NEVER: "Don't ask again",

  // Address
  ADDRESS_CITY: 'City',
  ADDRESS_ADDRESS_1: 'Street name and house number',
  ADDRESS_ADDRESS_2: 'Apt, floor, suite (optional)',
  ADDRESS_POST_CODE: 'Postcode',
  ADDRESS_COUNTRY: 'Country',
  ADDRESS_ENTER_MANUALLY: 'Enter address manually',

  // notifications
  NOTIFICATIONS_MARK_ALL_AS_READ: 'Mark all as read',
  NOTIFICATIONS_TYPE_SYSTEM: 'System',
  NOTIFICATIONS_TYPE_ANNOUNCEMENT: 'Announcement',
  NOTIFICATIONS_NO_DATA: "Looks like you don't have any notifications yet",

  DEPOSIT_FROM_EXTERNAL_WALLET_TO_CRYPTO_TRADING_TITLE: 'Crypto boost!💰',
  DEPOSIT_FROM_EXTERNAL_WALLET_TO_CRYPTO_TRADING_BODY:
    'Hooray! You just received %{amountLabel} into your Wallet',
  DEPOSIT_FROM_SEPA_WIRE_TO_IBAN_WALLET_TITLE: 'Funds in! 🏦',
  DEPOSIT_FROM_SEPA_WIRE_TO_IBAN_WALLET_BODY:
    'Your IBAN has been boosted with a deposit of %{amountLabel}',
  SEND_FROM_IBAN_WALLET_TO_SEPA_WIRE_TITLE: 'Money Sent 💸',
  SEND_FROM_IBAN_WALLET_TO_SEPA_WIRE_BODY:
    'Your transfer of %{amountLabel} has gone through smoothly.',
  SEND_FROM_TRADING_TO_EXTERNAL_CRYPTO_WALLET_TITLE: 'On the way! 💸',
  SEND_FROM_TRADING_TO_EXTERNAL_CRYPTO_WALLET_BODY:
    'You transferred %{amountLabel} to an external crypto wallet.',
  CRYPTO_CARD_PURCHASE_TITLE: '%{amountLabel} spent with your Nebeus Card 💳',
  CRYPTO_CARD_PURCHASE_BODY: 'Just purchased at %{merchant}',
  CRYPTO_CARD_ATM_WITHDRAWAL_TITLE: 'Cash in Hand 🏧 ',
  CRYPTO_CARD_ATM_WITHDRAWAL_BODY:
    "You've withdrawn %{amountLabel} in cash from an ATM with your Nebeus Card.",
  CRYPTO_CARD_ACTIVATED_TITLE: 'Your Crypto Card is now active! ✅ ',
  CRYPTO_CARD_ACTIVATED_BODY: 'Ready to use for seamless transactions.',
  NOTIFICATION_MARKETING_CURVE_TITLE: 'Now go contactless!',
  NOTIFICATION_MARKETING_CURVE_BODY:
    'Use your Nebeus card through the Curve app for contactless & convenient payments',

  SEND_TO_NEBEUS_USER_TITLE: 'Transfer sent 💸',
  SEND_TO_NEBEUS_USER_BODY:
    'Shared wealth! Your transfer of %{amountLabel} to Nebeus user has been completed',
  SEND_FROM_TRADING_TO_MONEY_TITLE: 'Funds have been transferred 💸',
  SEND_FROM_TRADING_TO_MONEY_BODY:
    '%{amountLabel} has been successfully transferred to IBAN account from trading account',
  SEND_FROM_MONEY_TO_TRADING_TITLE: 'Funds have been transferred 💸',
  SEND_FROM_MONEY_TO_TRADING_BODY:
    '%{amountLabel} has been successfully transferred to trading account from iban account',

  SEND_FROM_TRADING_TO_SEPA_WIRE_TITLE: 'Transfer Complete 🌟',
  SEND_FROM_TRADING_TO_SEPA_BODY:
    'Your SEPA transfer of %{amountLabel} from your wallet is successful',
  SEND_FROM_TRADING_TO_WIRE_BODY:
    'Your Wire transfer of %{amountLabel} from your wallet is successful',
  DEPOSIT_FROM_SEPA_WIRE_TO_TRADING_TITLE: 'Your balance just got a boost! 🎉',
  DEPOSIT_FROM_SEPA_TO_TRADING_BODY:
    '%{amountLabel} SEPA deposit has landed! Your wallet is now topped up.',
  DEPOSIT_FROM_WIRE_TO_TRADING_BODY:
    '%{amountLabel} Wire deposit has landed! Your wallet is now topped up.',

  CARD_WITH_GOOGLE_WALLET_CARD_EXIST_AD_TITLE: 'Upgrade to Contactless!',
  CARD_WITH_GOOGLE_WALLET_CARD_EXIST_AD_DESC:
    'Upgrade your Nebeus Virtual Card for a contactless experience with Google Pay. Simply close your existing card and purchase a new one for free. Add it manually to your Wallet for instant access wherever you go.',
  CARD_WITH_GOOGLE_WALLET_CARD_ABSENT_AD_TITLE: 'Google Pay Now Available!',
  CARD_WITH_GOOGLE_WALLET_CARD_ABSENT_AD_DESC:
    'Get a Nebeus Virtual Card compatible with Google Pay! Add it manually to your Wallet and enjoy seamless crypto payments on the go!',

  IBAN_ACCOUNT_CREATING_PROMPT_TITLE: 'Would you like to create an IBAN?',
  IBAN_ACCOUNT_CREATING_PROMPT_SUBTITLE:
    'You need to fund your Nebeus account with <b>a minimum of %{amountLabel}</b> from your SEPA account',
  IBAN_ACCOUNT_CREATING_PROMPT_ACCOUNT_HOLDER_TITLE: 'Yes, I’m the account holder',
  IBAN_ACCOUNT_CREATING_PROMPT_ACCOUNT_HOLDER_SUBTITLE:
    'I confirm that I am the holder of the SEPA account from which I will be funding the account.',

  IBAN_ACCOUNT_CREATING_DEPOSIT_TITLE: 'How to pay from your bank account',
  IBAN_ACCOUNT_CREATING_DEPOSIT_SUBTITLE:
    'Transfer <b>a minimum of %{amountLabel}</b> to your Nebeus account',
  IBAN_ACCOUNT_CREATING_DEPOSIT_SENT_BUTTON: 'Funds have been sent',
  IBAN_ACCOUNT_CREATING_STATUS_TITLE: 'IBAN creating status',
  IBAN_CREATING_PENDING_PROVIDER:
    'Your payment has been received, data is being processed, please wait...',
  IBAN_CREATING_APPROVED_BY_PROVIDER: 'Your application has been confirmed. Creating IBAN...',
  IBAN_CREATING_REJECTED_BY_MIN_AMOUNT:
    'Your payment has been received, but the amount is less than %{amountLabel}. Please try again with more than %{amountLabel}',
  IBAN_CREATING_REJECTED_BY_PROVIDER:
    'There are problems with your application. Please contact support',
  USER_VERIFICATION_QUESTIONNAIRE: 'Verification questionnaire',

  // Phonebook
  PHONEBOOK: 'Phonebook',
  PHONEBOOK_ADD_COUNTERPARTY: 'Add new recipient',
  PHONEBOOK_SEARCH_COUNTERPARTY_PLACEHOLDER: 'Name, email, iban etc',
  PHONEBOOK_EMPTY: 'You have no recipients yet',
  PHONEBOOK_EDIT_COUNTERPARTY: 'Edit recipient info',
  PHONEBOOK_COUNTERPARTY_NAME: 'Name',
  PHONEBOOK_COUNTERPARTY_NAME_PLACEHOLDER: 'Add recipient’s name',
  PHONEBOOK_COUNTERPARTY_EMAIL: 'Recipient e-mail (Optional)',
  PHONEBOOK_COUNTERPARTY_EMAIL_PLACEHOLDER: 'Add recipient’s email',
  PHONEBOOK_COUNTERPARTY_EMAIL_NOTE:
    'Fill in this field if you’re sure the recipient is currently a Nebeus user',
  PHONEBOOK_COUNTERPARTY_EDITED: 'Recipient info edited',
  PHONEBOOK_COUNTERPARTY_CREATED: 'Recipient added',
  PHONEBOOK_COUNTERPARTY_DELETED: '%{name} profile deleted',
  PHONEBOOK_DELETE_COUNTERPARTY: 'Delete recipient profile',
  PHONEBOOK_ADD_CARD: 'Add card',
  PHONEBOOK_EDIT_CARD: 'Edit card details',
  PHONEBOOK_ADD_SEPA: 'Add IBAN account',
  PHONEBOOK_EDIT_SEPA: 'Edit IBAN details',
  PHONEBOOK_ADD_WIRE: 'Add GBP account',
  PHONEBOOK_EDIT_WIRE: 'Edit GBP account',
  PHONEBOOK_ADD_CRYPTO_WALLET: 'Add wallet',
  PHONEBOOK_EDIT_CRYPTO_WALLET: 'Edit wallet details',
  PHONEBOOK_DELETE_COUNTERPARTY_TITLE: 'Delete profile',
  PHONEBOOK_DELETE_COUNTERPARTY_SUBTITLE: 'This profile will be deleted. Are you sure?',
  PHONEBOOK_COUNTERPARTY_MASS_DELETE: 'Delete recipients',
  PHONEBOOK_COUNTERPARTY_MASS_DELETE_SUBTITLE: 'Selected profiles will be deleted. Are you sure?',
  PHONEBOOK_COUNTERPARTY_MASS_DELETED: '%{count} recipient(s) deleted',
  PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_ADDED: 'Card added',
  PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_UPDATED: 'Card has been updated',
  PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_DELETED: 'Card has been removed',
  PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_DELETE_TITLE: 'Remove card',
  PHONEBOOK_PAYMENT_DETAILS_BANK_CARD_DELETE_SUBTITLE:
    'Card details will be removed. Are you sure?',
  PHONEBOOK_PAYMENT_DETAILS_SEPA_ADDED: 'IBAN account added',
  PHONEBOOK_PAYMENT_DETAILS_SEPA_UPDATED: 'IBAN account has been updated',
  PHONEBOOK_PAYMENT_DETAILS_SEPA_DELETED: 'IBAN has been removed',
  PHONEBOOK_PAYMENT_DETAILS_SEPA_DELETE_TITLE: 'Remove IBAN',
  PHONEBOOK_PAYMENT_DETAILS_SEPA_DELETE_SUBTITLE: 'IBAN details will be removed. Are you sure?',
  PHONEBOOK_PAYMENT_DETAILS_WIRE_ADDED: 'GBP account added',
  PHONEBOOK_PAYMENT_DETAILS_WIRE_UPDATED: 'GBP account has been updated',
  PHONEBOOK_PAYMENT_DETAILS_WIRE_DELETED: 'GBP account has been removed',
  PHONEBOOK_PAYMENT_DETAILS_WIRE_DELETE_TITLE: 'Remove GBP account',
  PHONEBOOK_PAYMENT_DETAILS_WIRE_DELETE_SUBTITLE: 'GBP account will be removed. Are you sure?',
  PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_ADDED: 'External crypto wallet added',
  PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_UPDATED: 'Wallet has been updated',
  PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_DELETED: 'Wallet has been removed',
  PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_DELETE_TITLE: 'Remove wallet',
  PHONEBOOK_PAYMENT_DETAILS_CRYPTO_WALLET_DELETE_SUBTITLE: 'Wallet will be removed. Are you sure?',
  PHONEBOOK_SAVED_RECIPIENTS: 'Saved recipients',
  PHONEBOOK_OPEN_TO_SEE_ALL: 'Open phonebook to see all',
  PHONEBOOK_PICK_RECIPIENT: 'Pick a recipient',

  MAINTENANCE_SCHEDULED_TITLE: 'Scheduled maintenance',
  MAINTENANCE_SCHEDULED_SUBTITLE:
    'Nebeus will be unavailable from %{dateFrom} to %{dateTo}. Thank you for your understanding.',
  MAINTENANCE_TITLE: 'Application under maintenance',
  MAINTENANCE_SUBTITLE:
    'Our application is currently undergoing scheduled maintenance.<br/>We should be back shortly. Thank you for your patience.',

  CUSTOMER_QUESTIONNAIRE_QUESTION_1: 'Which feature do you find most valuable?',
  CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_1: 'Money transfers',
  CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_2: 'Exchange services',
  CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_3: 'Savings accounts (Renting & Staking)',
  CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_4: 'Loan services',
  CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_5: 'Nebeus Card',
  CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_6: 'Invoicing',
  CUSTOMER_QUESTIONNAIRE_QUESTION_1_ANSWER_7: 'Other',

  CUSTOMER_QUESTIONNAIRE_QUESTION_2: 'Which additional feature would you most like to see?',
  CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_1: 'More cryptocurrency options',
  CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_2: 'Financial analytics',
  CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_3: 'Improved customer support',
  CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_4: 'Rewards/cashback',
  CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_5: 'Lower fees or costs',
  CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_6: 'New products',
  CUSTOMER_QUESTIONNAIRE_QUESTION_2_ANSWER_7: 'Other',

  CUSTOMER_QUESTIONNAIRE_QUESTION_3:
    'How do you feel about the fees for transactions within Nebeus?',
  CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_1: 'Very reasonable',
  CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_2: 'Reasonable',
  CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_3: 'Neutral',
  CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_4: 'High',
  CUSTOMER_QUESTIONNAIRE_QUESTION_3_ANSWER_5: 'Very high',

  CUSTOMER_QUESTIONNAIRE_QUESTION_4:
    'How responsive and helpful are you in finding customer support at Nebeus?',
  CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_1: 'Very responsive and helpful',
  CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_2: 'Responsive but not always helpful',
  CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_3: 'Neutral',
  CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_4: 'Slow but helpful',
  CUSTOMER_QUESTIONNAIRE_QUESTION_4_ANSWER_5: 'Very slow and unhelpful',

  CUSTOMER_QUESTIONNAIRE_QUESTION_5: 'How would you rate your overall experience with Nebeus?',

  CUSTOMER_QUESTIONNAIRE_TITLE: 'Share your feedback',
  QUESTIONNAIRE_FINISH_MESSAGE: 'Thank you for your feedback',

  BANK_CARD_OPERATIONS_BLOCKED_AD_TITLE: '💡Tip: Use Your Virtual Card',
  BANK_CARD_OPERATIONS_BLOCKED_AD_SUBTITLE:
    '📢Hey there! Easily use your Nebeus virtual card for seamless in-store and online payments. Start spending your money instantly without the need to withdraw it first! 🚀',

  RENTING_HIGHEST_REWARDS_AD_TITLE: 'Our Highest Rewards Ever! 🚀',
  RENTING_HIGHEST_REWARDS_AD_SUBTITLE:
    'Earn up to <b>13%</b> in USDT, USDC, MATIC, 1INCH, EOS & FTM<br/><br/>Tap to discover how you can maximize your earnings today!',

  CRYPTO_BANK_CARD_DELIVERY_CONFIRMATION:
    'Your card is active and ready to use for online purchases! Once you receive your physical card, click the button below and the card can be used for offline payments.',
  CRYPTO_BANK_CARD_DELIVERY_CONFIRMATION_CONFIRM_BUTTON: 'Card has been received',

  EXCHANGE_LARGE_AMOUNT_WARNING_TITLE: 'Additional verification',
  EXCHANGE_LARGE_AMOUNT_WARNING_TEXT:
    'The exchange amount is quite large. We care about our customers and therefore ask you to additionally confirm this operation. Are you sure you want to exchange %{fromAmountLabel} to %{toCurrency}?',

  IBAN_ACCOUNT_CREATING_WARNING:
    'We will withdraw %{price} euro monthly fee during the payable billing cycle',

  IBAN_ACCOUNT_CREATING_TITLE: 'New Crypto-Friendly IBAN',
  IBAN_ACCOUNT_CREATING_TITLE_PROVIDER_2: 'Virtual Crypto-Friendly IBAN',
  IBAN_ACCOUNT_CREATING_SUBTITLE: 'Check the Features:',

  IBAN_ACCOUNT_BENEFIT_1_TITLE: 'Third-Party Payments',
  IBAN_ACCOUNT_BENEFIT_1_DESCRIPTION:
    'Receive and send funds to friends, clients, or business partners.',
  IBAN_ACCOUNT_BENEFIT_2_TITLE: 'Effortless Crypto Withdrawals',
  IBAN_ACCOUNT_BENEFIT_2_DESCRIPTION:
    'Move your crypto gains into your IBAN, integrating them into traditional banking without any restrictions.',
  IBAN_ACCOUNT_BENEFIT_3_TITLE: 'Dedicated Account Details',
  IBAN_ACCOUNT_BENEFIT_3_DESCRIPTION:
    'Enjoy more security and control, with your personal account details.',
  IBAN_ACCOUNT_BENEFIT_4_TITLE: 'Free SEPA Transfers',
  IBAN_ACCOUNT_BENEFIT_4_DESCRIPTION: 'No fees on incoming SEPA payments.',
  IBAN_ACCOUNT_BENEFIT_5_TITLE: 'Euro IBAN',
  IBAN_ACCOUNT_BENEFIT_5_DESCRIPTION: 'Send payments conveniently in EUR.',
  IBAN_ACCOUNT_CREATING_SUCCESS:
    'Success! Your application has been accepted and will be reviewed shortly',

  IBAN_ACCOUNT_BENEFIT_1_TITLE_PROVIDER_2: 'Payments from Your Own Accounts',
  IBAN_ACCOUNT_BENEFIT_1_DESCRIPTION_PROVIDER_2:
    'Receive and send funds directly from your personal accounts.',
  IBAN_ACCOUNT_BENEFIT_2_TITLE_PROVIDER_2: 'Move your crypto gains into your IBAN',
  IBAN_ACCOUNT_BENEFIT_2_DESCRIPTION_PROVIDER_2:
    'Send crypto-related transactions into traditional banking without any restrictions.',
  IBAN_ACCOUNT_BENEFIT_3_TITLE_PROVIDER_2: 'Free SEPA Transfers',
  IBAN_ACCOUNT_BENEFIT_3_DESCRIPTION_PROVIDER_2: 'No fees on incoming SEPA payments.',
  IBAN_ACCOUNT_BENEFIT_4_TITLE_PROVIDER_2: 'Free Euro IBAN',
  IBAN_ACCOUNT_BENEFIT_4_DESCRIPTION_PROVIDER_2: 'Send payments conveniently in EUR.',
};
