import { useCallback, useRef } from 'react';

const useDebounceFunc = <TArgs>(func: (...args: TArgs[]) => any, timeout = 1000) => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const debounced = useCallback(
    (...args: TArgs[]) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(() => {
        func(...args);
      }, timeout);
    },
    [func, timeout],
  );

  return debounced;
};

export default useDebounceFunc;
