import { CreateInvoiceStep } from 'modules/invoicing/views/CreateInvoice/components/CreateInvoiceStep';
import InvoiceDiscountForm from 'modules/invoicing/views/CreateInvoice/components/InvoiceDiscountForm';

import { useTranslation } from 'libs/i18n';

export const InvoiceDiscount = () => {
  const translate = useTranslation();

  return (
    <CreateInvoiceStep label={translate('INVOICE_DISCOUNT')} component={<InvoiceDiscountForm />} />
  );
};
