import { FC, useLayoutEffect } from 'react';

import { Flag } from 'hooks/useFlag';

import { LINK_REQUEST_BUSINESS_ACCOUNT } from 'utils/links';

interface CreateBusinessAccountProps {
  loading: Flag;
}
export const CreateBusinessAccount: FC<CreateBusinessAccountProps> = ({ loading }) => {
  useLayoutEffect(() => {
    loading.on();
    // eslint-disable-next-line
  }, []);

  return (
    <iframe
      className="authStepRoot"
      onLoad={loading.off}
      style={{ width: '100%', height: '60vh', border: 'none', outline: 'none' }}
      src={LINK_REQUEST_BUSINESS_ACCOUNT}
    ></iframe>
  );
};
