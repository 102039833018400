import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FC, MouseEventHandler, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import {
  selectSavedBankCardsReducer,
  selectSavedCryptoWalletsReducer,
} from 'modules/payment/store/selectors';
import { requestSavedBankCards, requestSavedCryptoWallets } from 'modules/payment/store/thunks';
import { SavedBankCard, SavedCryptoWallet } from 'modules/payment/types';
import { useDispatch } from 'store';
import { IApiRequestState } from 'store/generators/types';

import { CurrencyIcon, Icon, Image, Loader } from 'components/ui';

import { getTranslation, useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';

import { CurrencyCode, voidFunc } from 'types';
import { genericMemo } from 'types/custom';

import classes from './SavedWalletsCarousel.module.scss';

interface SavedWalletsCarouselProps<T> {
  className?: string;
  isBankCards: boolean;
  carouselClassName?: string;
  onPick: (wallet: T | null) => void;
  currencyCode?: CurrencyCode;
  activeWalletId: string | null;
}

interface AddCardButtonProps {
  onClick: voidFunc;
  isBankCards: boolean;
}
const AddCardButton: FC<AddCardButtonProps> = ({ onClick, isBankCards }) => (
  <div onClick={onClick} className={classes.addCardButton}>
    <Icon name="plus" size={16} className={classes.plusIcon} />
    <span className={classes.addCardLabel}>
      {getTranslation(
        isBankCards ? 'PAYMENT_SAVED_BANK_CARDS_ADD_CARD' : 'PAYMENT_SAVED_WALLETS_ADD_WALLET',
      )}
    </span>
  </div>
);

interface WalletCardProps {
  wallet: SavedCryptoWallet | SavedBankCard;
  onClick: MouseEventHandler<HTMLDivElement>;
  active: boolean;
}
const WalletCard: FC<WalletCardProps> = ({ wallet, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(classes.walletCard, active && classes.active)}
      data-id={wallet.id}
    >
      <Image className={classes.activeIcon} name="circleSuccess" path="common" type="svg" />
      {'address' in wallet && (
        <CurrencyIcon className="flex-shrink-0" size={24} code={wallet.currencyCode} />
      )}
      <div className={classes.walletData}>
        {'label' in wallet ? (
          <span className={classes.cardLabel}>{wallet.label}</span>
        ) : (
          <div className={classes.placeholders}>
            <div />
            <div />
          </div>
        )}
        {'address' in wallet ? (
          <span className={classes.walletAddress}>{wallet.address}</span>
        ) : (
          <>
            <span>{wallet.dateExp}</span>
            <span>{wallet.lastDigits}</span>
          </>
        )}
      </div>
    </div>
  );
};

function SavedWalletsCarouselLight<T extends SavedCryptoWallet | SavedBankCard>({
  className,
  isBankCards,
  carouselClassName,
  onPick,
  currencyCode,
  activeWalletId,
}: SavedWalletsCarouselProps<T>) {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const drawer = useDrawer();

  // @ts-ignore
  const walletsReducer: IApiRequestState<T[]> = useSelector(
    // @ts-ignore
    isBankCards ? selectSavedBankCardsReducer : selectSavedCryptoWalletsReducer,
  );

  useEffect(() => {
    if (canRequest(walletsReducer.meta)) {
      // @ts-ignore
      dispatch(isBankCards ? requestSavedBankCards() : requestSavedCryptoWallets());
    }
    // eslint-disable-next-line
  }, []);

  const handleAdd = useCallback(() => {
    drawer.open(paymentDrawerTemplates.addEditSavedCryptoWallet());
  }, [drawer]);

  const handleWalletClick = useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      const walletId = event.currentTarget.dataset.id;

      if (walletId) {
        if (activeWalletId === walletId) {
          onPick(null);
          return;
        }
        const wallet = walletsReducer.data.find((w) => w.id === walletId);
        if (wallet) {
          onPick(wallet);
        }
      }
    },
    [activeWalletId, onPick, walletsReducer.data],
  );

  const wallets = useMemo(
    () =>
      !isBankCards && currencyCode
        ? // @ts-ignore
          walletsReducer.data.filter((w: T) => w.currencyCode === currencyCode)
        : walletsReducer.data,
    [walletsReducer.data, isBankCards, currencyCode],
  );

  return (
    <div className={clsx(classes.root, className)}>
      <span className="label">
        {translate(isBankCards ? 'PAYMENT_SAVED_BANK_CARDS' : 'PAYMENT_SAVED_WALLETS')}
      </span>
      <Swiper className={clsx(carouselClassName, 'pt-2')} spaceBetween={8} slidesPerView="auto">
        <SwiperSlide className={classes.slide}>
          <AddCardButton onClick={handleAdd} isBankCards={isBankCards} />
        </SwiperSlide>

        {wallets.map((wallet: SavedCryptoWallet | SavedBankCard) => (
          <SwiperSlide className={classes.slide} key={wallet.id}>
            <WalletCard
              onClick={handleWalletClick}
              wallet={wallet}
              active={activeWalletId === wallet.id}
            />
          </SwiperSlide>
        ))}
        {walletsReducer.meta.loading && (
          <SwiperSlide className={classes.slide}>
            <div className={classes.loaderWrapper}>
              <Loader />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
}

export const SavedWalletsCarousel = genericMemo(SavedWalletsCarouselLight);
