import { FC, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import loanDrawerTemplates from 'modules/loans/constants/drawerTemplates';
import { requestAddCollateral } from 'modules/loans/store/thunks';
import { LoanItem } from 'modules/loans/types';
import classes from 'modules/loans/views/AddCollateralConfirm/AddCollateralConfirm.module.scss';
import LoanTypeAndId from 'modules/loans/views/components/LoanTypeAndId';
import { useDispatch } from 'store';

import { Button, CurrencyIcon, DottedLine, Icon } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

export interface AddCollateralConfirmProps {
  loan: LoanItem;
  amount: number;
}

const AddCollateralConfirm: FC<AddCollateralConfirmProps> = ({ loan, amount }) => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const loading = useFlag(false);
  const handleConfirm = useCallback(async () => {
    loading.on();
    const { success, data } = await dispatch(requestAddCollateral({ loanId: loan.id, amount }));
    if (success) {
      drawer.replaceAll(loanDrawerTemplates.loanAddCollateralSuccess({ loan: data, amount }));
    }
  }, [dispatch, loading, drawer, loan, amount]);

  return (
    <div className="column gap-3 flex-1 jcsb">
      <div className="column gap-3">
        <p className="grey-400">{translate('LOANS_ADD_COLLATERAL_CONFIRM_DESC')}</p>
        <div className="outlinedCard column gap-3">
          <span className="label">{translate('LOANS_ADD_COLLATERAL_FUNDS_FLOW')}</span>

          <div className="column gap-2">
            <DottedLine />
            <div className="row aic jcsb">
              <div className="column">
                <span className="label">
                  {translate('NEBEUS_WALLET', { currency: loan.collateralCurrency })}
                </span>
                <div className="row aic gap-0-5 mt-0-5">
                  <CurrencyIcon code={loan.collateralCurrency} size={16} />
                  <span className={classes.amount}>
                    {formatCurrencyWithSymbol(amount * -1, loan.collateralCurrency)}
                  </span>
                </div>
              </div>
              <Icon className="mx-2" name="arrowRight" size="sm" color="black40" />
              <div className="column">
                <span className="label">{translate('LOAN_COLLATERAL')}</span>
                <div className="row aic gap-0-5 mt-0-5">
                  <CurrencyIcon code={loan.collateralCurrency} size={24} />
                  <span className={classes.amount}>
                    +{formatCurrencyWithSymbol(amount, loan.collateralCurrency)}
                  </span>
                </div>
              </div>
            </div>
            <DottedLine />
          </div>
          <LoanTypeAndId type={loan.templateType} id={loan.id} />
        </div>
      </div>
      <div className="column gap-2">
        <Button loading={loading.state} onClick={handleConfirm} fullWidth>
          {translate('CONFIRM')}
        </Button>
        <p className={classes.note}>{translate('LOANS_ADD_COLLATERAL_NOTE')}</p>
      </div>
    </div>
  );
};

export default AddCollateralConfirm;
