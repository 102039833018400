import { FC, useCallback, useMemo } from 'react';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import useRate from 'modules/exchange/hooks/useRate';
import { requestCreateTrade } from 'modules/smartTrader/store/thunks';
import {
  SmartTraderSummaryCard,
  SmartTraderSummaryCardProps,
} from 'modules/smartTrader/views/components/SmartTraderSummaryCard';
import { SmartTraderTitles } from 'modules/smartTrader/views/components/SmartTraderTitles';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { Button } from 'components/ui';
import { CurrencyAmount } from 'components/ui/CurrencyAmountInput';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel } from 'utils/currency';

import classes from './SmartTraderSummaryConfirmation.module.scss';

export interface SmartTraderSummaryConfirmationProps {
  primary: CurrencyAmount;
  secondary: CurrencyAmount;
  takeProfitAmount: string;
  stopLossAmount: string;
}
const SmartTraderSummaryConfirmation: FC<SmartTraderSummaryConfirmationProps> = ({
  primary,
  secondary,
  takeProfitAmount,
  stopLossAmount,
}) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const sidebar = useSideBar();

  const rate = useRate(primary.currency, secondary.currency);

  const checkList = useMemo(
    () => [
      {
        label: translate('SMART_TRADER_YOUR_FUNDS'),
        value: formatCurrencyWithLabel(primary.amount, primary.currency),
      },
      {
        label: `${primary.currency}/${secondary.currency} ` + translate('EXCHANGE_RATE'),
        value: formatCurrencyWithLabel(rate, secondary.currency),
      },
      {
        label: translate('SMART_TRADER_CURRENT_VALUE_IN', {
          currencyCode: secondary.currency,
        }),
        value: formatCurrencyWithLabel(secondary.amount, secondary.currency),
      },
    ],
    [translate, primary, secondary, rate],
  );

  const trade = useMemo<SmartTraderSummaryCardProps['trade']>(
    () => ({
      primaryCurrency: primary.currency,
      secondaryCurrency: secondary.currency,
      primaryAmount: +primary.amount,
      secondaryAmount: +secondary.amount,
      takeProfitAmount: +takeProfitAmount,
      stopLossAmount: +stopLossAmount,
    }),
    [primary, secondary, takeProfitAmount, stopLossAmount],
  );

  const loading = useFlag(false);
  const handleConfirm = useCallback(async () => {
    loading.on();
    const { success } = await dispatch(
      requestCreateTrade({
        primaryAmount: +primary.amount,
        primaryCurrency: primary.currency,
        secondaryAmount: +secondary.amount,
        secondaryCurrency: secondary.currency,
        stopLossAmount: +stopLossAmount,
        takeProfitAmount: +takeProfitAmount,
      }),
    );
    loading.off();

    if (success) {
      sidebar.replaceAll(
        ...sidebarTemplates.smartTraderActivated({ currencyCode: primary.currency }),
      );
    }
  }, [sidebar, dispatch, loading, primary, secondary, stopLossAmount, takeProfitAmount]);

  return (
    <>
      <div className="column gap-3">
        <SmartTraderTitles title={translate('SUMMARY')} />
        <div className="whiteCard p-3">
          <div className="column gap-0-5 mb-3">
            {checkList.map(({ label, value }) => (
              <div className="row jcsb" key={label}>
                <span className={classes.label}>{label}</span>
                <span className={classes.value}>{value}</span>
              </div>
            ))}
          </div>
          <SmartTraderSummaryCard trade={trade} />
        </div>
      </div>
      <div className="column gap-1-5">
        <Button loading={loading.state} variant="gold" onClick={handleConfirm}>
          {translate('CONFIRM')}
        </Button>
        <AgreementText light />
      </div>
    </>
  );
};
export default SmartTraderSummaryConfirmation;
