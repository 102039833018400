import clsx from 'clsx';

import { memo, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import {
  requestFreezeBankCard as requestFreezeCryptoBankCard,
  requestUnfreezeBankCard as requestUnfreezeCryptoBankCard,
} from 'modules/cryptoBankCard/store/thunks';
import { CryptoBankCard } from 'modules/cryptoBankCard/types';
import { appVerificationService } from 'modules/reactNative/utils/appVerification';
import { useDispatch } from 'store';

import { Icon, Loader } from 'components/ui';

import useFlag, { Flag } from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import classes from './OperationButtons.module.scss';

interface OperationButtonsProps {
  bankCard: CryptoBankCard;
  frontSide: Flag;
}

export const OperationButtons = memo<OperationButtonsProps>(({ frontSide, bankCard }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const freezeToggling = useFlag(false);

  const handleToggleFreeze = useCallback(async () => {
    if (bankCard.isBlocked) {
      return;
    }
    freezeToggling.on();
    const thunk = bankCard.isFrozen ? requestUnfreezeCryptoBankCard : requestFreezeCryptoBankCard;
    if (!bankCard.isFrozen) {
      frontSide.on();
    }

    await dispatch(thunk(bankCard.id));
    freezeToggling.off();
  }, [dispatch, frontSide, freezeToggling, bankCard]);

  const toggleDetails = useCallback(async () => {
    if ((bankCard.isFrozen || bankCard.isBlocked) && frontSide.state) {
      return;
    }
    if (frontSide.state) {
      const success = await appVerificationService.checkBioVerification();
      if (success) {
        frontSide.toggle();
      }
    } else {
      frontSide.toggle();
    }
  }, [bankCard, frontSide]);

  const buttons = [
    {
      icon: bankCard.isFrozen ? 'sun' : 'freeze',
      label: translate(bankCard.isFrozen ? 'BANK_CARD_UNFREEZE' : 'BANK_CARD_FREEZE'),
      onClick: handleToggleFreeze,
      disabled: bankCard.isBlocked || (bankCard.type === 'crypto' && !bankCard.isActivated),
      loading: freezeToggling.state,
    },
    {
      icon: frontSide.state ? 'bankCardDetails' : 'bankCardHideDetails',
      label: translate(frontSide.state ? 'BANK_CARD_SHOW_DETAILS' : 'BANK_CARD_HIDE_DETAILS'),
      onClick: toggleDetails,
      disabled:
        bankCard.isFrozen ||
        bankCard.isBlocked ||
        (bankCard.type === 'crypto' && !bankCard.isActivated),
    },
    {
      icon: 'more',
      label: translate('MORE'),
      onClick: () => {
        drawer.open(
          commonDrawerTemplates.bankCardMoreOptions({
            bankCard,
          }),
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      {buttons.map((b) => (
        <div
          onClick={b.disabled ? undefined : b.onClick}
          className={clsx(classes.button, b.disabled && classes.disabled)}
          key={b.icon}
        >
          <Icon name={b.icon} size={32} />
          <span className={classes.label}>{b.label}</span>
          <Loader overlap active={b.loading} />
        </div>
      ))}
    </div>
  );
});
