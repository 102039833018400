import { useCallback, useEffect } from 'react';

import { reactNativeActions } from 'modules/reactNative/store';
import { ReactNativeState } from 'modules/reactNative/types';
import { useDispatch } from 'store';

import { updateStyleVariable } from 'utils/common';
import eventEmitter from 'utils/eventEmitter';

const useReactNativeState = () => {
  const dispatch = useDispatch();

  const updateStore = useCallback(
    (state: Partial<ReactNativeState>) => {
      dispatch(reactNativeActions.updateState(state));

      if (state.insets) {
        updateStyleVariable('--reactNativeInsetTop', `${state.insets.top}px`);
        updateStyleVariable('--reactNativeInsetBottom', `${state.insets.bottom}px`);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const unsubscribe = eventEmitter.subscribe('RNUpdateState', updateStore);

    return () => {
      unsubscribe();
    };
  }, [updateStore]);
};

export default useReactNativeState;
