import clsx from 'clsx';

import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectIsDigitalAccountAllowed } from 'modules/digitalAccount/store/selectors';
import { Invoice } from 'modules/invoicing/types';
import { ChooseCashAccount } from 'modules/invoicing/views/CreateInvoice/components/PaymentDetailsForm/components/ChooseCashAccount';
import { ChooseCryptoAccount } from 'modules/invoicing/views/CreateInvoice/components/PaymentDetailsForm/components/ChooseCryptoAccount';
import { ChooseMoneyAccount } from 'modules/invoicing/views/CreateInvoice/components/PaymentDetailsForm/components/ChooseMoneyAccount';

import useFlag from 'hooks/useFlag';

import { TranslationKey, useTranslation } from 'libs/i18n';
import yup from 'libs/yup';

import { isFiat } from 'utils/currency';
import eventEmitter from 'utils/eventEmitter';

import { CurrencyCode } from 'types';

import classes from './PaymentDetailsForm.module.scss';

export type PaymentType = Invoice['payment']['type'];

export interface PaymentDetailsFormValues {
  payment: {
    type: PaymentType | null;
    currencyCode: CurrencyCode | null;
  };
}
interface PaymentDetailsFormProps {
  invoiceCurrency: CurrencyCode | null;
}

const translationKeyByPaymentType: { [key in PaymentType]: TranslationKey } = {
  nebeusCryptoAccount: 'NEBEUS_CRYPTO_ACCOUNT',
  nebeusIbanAccount: 'NEBEUS_IBAN_ACCOUNT',
  nebeusSepaOrWireTransfer: 'NEBEUS_CASH_ACCOUNT',
};

interface PaymentMethod {
  type: Invoice['payment']['type'];
  initialCurrencyCode: CurrencyCode;
  activeAdornment?: ReactNode;
}
interface PaymentMethodProps {
  active: boolean;
  method: PaymentMethod;
  onChange: (method: PaymentMethod) => void;
  showError: boolean;
}

const PaymentMethodComponent: FC<PaymentMethodProps> = ({
  onChange,
  active,
  method,
  showError,
}) => {
  const translate = useTranslation();

  return (
    <div
      onClick={() => {
        onChange(method);
      }}
      className={clsx(
        classes.paymentMethod,
        active ? classes.active : undefined,
        showError && classes.error,
      )}
    >
      <div className="row aic gap-1-5">
        <div className={classes.radioButton}>
          <div />
        </div>
        <span className={classes.label}>{translate(translationKeyByPaymentType[method.type])}</span>
      </div>
      {active ? method.activeAdornment : null}
    </div>
  );
};

export const invoicePaymentValidationSchema = yup.object().shape({
  payment: yup.object().shape({
    type: yup.string().required(),
    currencyCode: yup.string().required(),
  }),
});

const PaymentDetailsForm: FC<PaymentDetailsFormProps> = ({ invoiceCurrency }) => {
  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);

  const form = useForm<PaymentDetailsFormValues>();
  const { values } = useFormState<PaymentDetailsFormValues>();

  const paymentMethods = useMemo<PaymentMethod[]>(() => {
    const isCurrencyFiat = !invoiceCurrency || isFiat(invoiceCurrency);

    return [
      {
        type: isDAAllowed ? 'nebeusIbanAccount' : 'nebeusSepaOrWireTransfer',
        disabled: !invoiceCurrency || !isCurrencyFiat,
        activeAdornment: isDAAllowed ? <ChooseMoneyAccount /> : <ChooseCashAccount />,
        initialCurrencyCode: 'EUR',
      },
      {
        type: 'nebeusCryptoAccount',
        disabled: !invoiceCurrency || isCurrencyFiat,
        activeAdornment: <ChooseCryptoAccount />,
        initialCurrencyCode: 'BTC',
      },
    ];
  }, [invoiceCurrency, isDAAllowed]);

  const onChangePaymentMethod = useCallback(
    (method: PaymentMethod) => {
      if (values.payment.type === method.type) {
        return;
      }
      form.batch(() => {
        // @ts-ignore
        form.change('payment.type', method.type);
        // @ts-ignore
        form.change('payment.currencyCode', method.initialCurrencyCode);
      });
    },
    [values.payment.type, form],
  );

  const showError = useFlag(false);

  const onValidationError = useCallback(() => {
    showError.on();
    setTimeout(showError.off, 2000);
  }, [showError]);

  useEffect(() => {
    const unsub = eventEmitter.subscribe('invoiceFormPaymentValidationError', onValidationError);

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.root}>
      {paymentMethods.map((m) => (
        <PaymentMethodComponent
          key={m.type}
          method={m}
          onChange={onChangePaymentMethod}
          active={m.type === values.payment.type}
          showError={showError.state}
        />
      ))}
    </div>
  );
};

export default PaymentDetailsForm;
