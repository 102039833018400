import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useRatesConverter from 'modules/exchange/hooks/useRatesConverter';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';

import { CurrencyCode } from 'types';

const useRatesConverterToDefaultCurrency = () => {
  const defaultCurrencyCode = useSelector(selectUserDefaultCurrencyCode);

  const convert = useRatesConverter();

  const convertToDefaultCurrency = useCallback(
    async (from: CurrencyCode, amount: number) => convert(from, defaultCurrencyCode, amount),
    [convert, defaultCurrencyCode],
  );

  return { convertToDefaultCurrency, defaultCurrencyCode };
};
export default useRatesConverterToDefaultCurrency;
