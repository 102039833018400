import { FC, useMemo } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { DatePickerInput } from 'components/ui';
import { DatePickerValue } from 'components/ui/DatePicker';
import { DatePickerInputProps } from 'components/ui/DatePickerInput';

interface DatePickerInputFieldProps
  extends FieldRenderProps<DatePickerValue>,
    Omit<DatePickerInputProps, 'value' | 'onChange'> {}

export const DatePickerInputField: FC<DatePickerInputFieldProps> = ({
  input,
  meta,
  inputProps,
  ...props
}) => {
  const hasValue = !!input.value.startDate;

  const error = useMemo(
    () => (hasValue ? meta.error?.startDate || meta.error?.endDate || meta.error : null),
    [hasValue, meta.error],
  );

  return <DatePickerInput inputProps={{ ...inputProps, error }} {...input} {...meta} {...props} />;
};
