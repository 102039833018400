// @ts-ignore
export const isReactNative: boolean = !!window.isWebviewUsingRN;
// export const isReactNative: boolean = true;
export const isReactNativeIOS: boolean = !!window.isWebviewUsingRNiOS;
export const isReactNativeAndroid: boolean = !!window.isWebviewUsingRNAndroid;
export const isReactNativeV2: boolean = !!window.isReactNativeV2;

const isReactNativeLegacyBioImplemented: boolean = !!window.isReactNativeLegacyBioImplemented;

export const isReactNativeSupportBioVerification =
  isReactNativeV2 || isReactNativeLegacyBioImplemented;

export const sendToReactNative = (actionType: string, props?: object) => {
  if (!window.ReactNativeWebView) {
    return;
  }
  try {
    window.ReactNativeWebView.postMessage(JSON.stringify({ ACTION_TYPE: actionType, props }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Cant send to react native: ', e);
  }
};

export const debugReactNative = (body: any) => {
  sendToReactNative('DEBUG', { body });
};

if (isReactNative) {
  try {
    document.getElementById('root')?.classList.add('reactNative');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
