import { selectAllowedCountriesReducer } from 'modules/app/store/selectors';
import { requestAllowedCountries } from 'modules/app/store/thunks';

import { Smooth } from 'components/common';
import { AddressForm } from 'components/form';
import { Icon } from 'components/ui';

import useFlag from 'hooks/useFlag';
import useStoreEntity from 'hooks/useStoreEntity';

import { getTranslation } from 'libs/i18n';

export const RecipientAddress = () => {
  const {
    entityReducer: { data: allowedCountries },
  } = useStoreEntity(selectAllowedCountriesReducer, requestAllowedCountries);

  const isManualMode = useFlag(false);

  return (
    <div className="column gap-2">
      <div className="row aic gap-3 jcsb">
        <span className="label">{getTranslation('RECIPIENT_ADDRESS')}</span>
        <Smooth isVisible={isManualMode.state} animation="opacity">
          <Icon
            onClick={isManualMode.off}
            size={16}
            name="search"
            color="blue"
            className="pointer"
          />
        </Smooth>
      </div>
      <AddressForm
        fieldName="recipientAddress"
        allowedCountries={allowedCountries}
        isManualMode={isManualMode}
      />
    </div>
  );
};
