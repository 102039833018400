import clsx from 'clsx';

import { FC, useCallback, useMemo } from 'react';

import { requestTransactionStatement } from 'modules/accounts/store/thunks';
import { Transaction, TransactionRejectReasons } from 'modules/accounts/types';
import { RefundRejectedCryptoTransaction } from 'modules/accounts/views/components/RefundRejectedCryptoTransaction';
import { transactionLabelByStatus } from 'modules/accounts/views/components/TransactionStatusTag';
import { useDispatch } from 'store';

import {
  AmountLabel,
  CurrencyIcon,
  DrawerHeader,
  Icon,
  ImportantCard,
  SummaryCard,
} from 'components/ui';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import useFlag from 'hooks/useFlag';

import { TranslationKey, useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import { formatDDMMYY_HHMM } from 'utils/date';

import classes from './TransactionDetails.module.scss';

export interface TransactionDetailsProps {
  transaction: Transaction;
}

const rejectReasonTranslationKeys: { [key in TransactionRejectReasons]: TranslationKey } = {
  [TransactionRejectReasons.SANCTIONS]: 'TRANSACTION_REJECT_REASON_FORBIDDEN_WALLET',
};

const TransactionDetails: FC<TransactionDetailsProps> = ({ transaction }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();

  const statementLoading = useFlag(false);

  const downloadStatement = useCallback(async () => {
    statementLoading.on();
    const { success } = await dispatch(
      requestTransactionStatement({
        transactionId: transaction.requestIdentifier,
        isDigitalAccount: false,
        currencyCode: transaction.currencyCode,
      }),
    );
    if (success) {
      successToast(translate('SUCCESS'));
    }
    statementLoading.off();
  }, [
    dispatch,
    translate,
    transaction.currencyCode,
    statementLoading,
    transaction.requestIdentifier,
  ]);

  const summaryConfig = useMemo<SummaryCardItem[]>(() => {
    const result = [
      { label: translate('TRANSACTION_ID'), value: transaction.id },

      {
        label: translate('ACCOUNT_OPERATION_STATUS'),
        value: translate(transactionLabelByStatus[transaction.status]),
      },
    ];

    if (transaction.additionalInfo) {
      if ('network' in transaction.additionalInfo) {
        [
          { label: translate('FROM_ADDRESS'), value: transaction.additionalInfo.fromAddress },
          { label: translate('TO_ADDRESS'), value: transaction.additionalInfo.toAddress },
          { label: translate('NETWORK'), value: transaction.additionalInfo.network },
          { label: translate('TRANSACTION_HASH'), value: transaction.additionalInfo.hash },
        ].forEach((i) => {
          if (i.value) {
            result.push(i as { label: string; value: string });
          }
        });
      }
      if ('reference' in transaction.additionalInfo && transaction.additionalInfo.reference) {
        result.push({
          label: translate('PAYMENT_REFERENCE'),
          value: transaction.additionalInfo.reference,
        });
      }
      if (
        'counterpartyEmail' in transaction.additionalInfo &&
        transaction.additionalInfo.counterpartyEmail
      ) {
        result.push({
          label: translate(transaction.isIncome ? 'SENDER_EMAIL' : 'RECIPIENT_EMAIL'),
          value: transaction.additionalInfo.counterpartyEmail,
        });
      }
    }

    return result;
  }, [transaction, translate]);

  const needRefundRejectedCryptoTransaction =
    transaction.operationId === 'bitgoIncomeTransaction' && transaction.status === 'rejected';

  return (
    <div className="column pt-2 gap-3">
      <DrawerHeader
        title={`${transaction.description}, ${formatDDMMYY_HHMM(
          new Date(transaction.dateCreated),
        )}`}
      />
      <div className="creamyCard column aic gap-3">
        <CurrencyIcon
          size={72}
          code={transaction.currencyCode}
          className="m-0-auto relative"
          adornment={
            transaction.amount === 0 ? undefined : (
              <Icon
                size={18}
                className={clsx(classes.mark, transaction.isIncome && classes.plus)}
                name={transaction.isIncome ? 'plus' : 'minus'}
              />
            )
          }
        />
        <span className={classes.label}>{transaction.description}</span>
        <AmountLabel
          amount={transaction.amount}
          currencyCode={transaction.currencyCode}
          showCurrencySymbol
          size="xl4"
          showPlusOrMinus
          className="tac"
        />

        <span className="label">{formatDDMMYY_HHMM(new Date(transaction.dateCreated))}</span>
      </div>
      {transaction.additionalInfo &&
      'rejectReason' in transaction.additionalInfo &&
      typeof transaction.additionalInfo.rejectReason === 'number' ? (
        <ImportantCard
          variant="fullRed"
          text={translate(
            rejectReasonTranslationKeys[
              transaction.additionalInfo.rejectReason as TransactionRejectReasons
            ],
          )}
        />
      ) : null}
      <div className="column gap-3">
        <div className="row aic jcsb gap-2">
          <span className="label">{translate('ACCOUNTS_OPERATION_DETAILS')}</span>
          {transaction.requestIdentifier && transaction.status === 'completed' ? (
            <span className="label cyanBlue pointer" onClick={downloadStatement}>
              {translate('ACCOUNTS_GET_A_RECEIPT')}
            </span>
          ) : null}
        </div>
        <SummaryCard variant="borderless" config={summaryConfig} />
      </div>
      {needRefundRejectedCryptoTransaction && (
        <RefundRejectedCryptoTransaction transaction={transaction} />
      )}
    </div>
  );
};

export default TransactionDetails;
