import { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import { IconProps } from 'react-toastify/dist/components';
import { ToastContainerProps } from 'react-toastify/dist/types';

import { Icon } from 'components/ui/Icon';

import useMobile from 'hooks/useMobile';
import useTablet from 'hooks/useTablet';

const ToastIcon: FC<IconProps> = ({ type }) => (
  <Icon
    className={`Toastify__toast-icon Toastify__toast-icon-${
      type === 'success' ? 'success' : 'error'
    }`}
    name={type === 'success' ? 'check' : 'cross'}
    size="sm"
    color="white"
  />
);
interface ToastProps extends ToastContainerProps {}
export const Toast: FC<ToastProps> = (props) => {
  const isTablet = useTablet();
  const isMobile = useMobile();

  return (
    <ToastContainer
      position={isTablet ? 'top-center' : 'bottom-left'}
      autoClose={5000}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={false}
      icon={ToastIcon}
      hideProgressBar={isMobile}
      {...props}
    />
  );
};
