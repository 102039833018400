import { Field } from 'react-final-form';

import { TextInputField } from 'components/form';

import { getTranslation, useTranslation } from 'libs/i18n';
import { InvoiceServiceItem } from 'libs/swagger/nebeusApiTypes';
import yup from 'libs/yup';

import { parseFiatAmount } from 'utils/inputParsers';

export interface InvoiceDiscountFormValues {
  discountAmount: string;
  discountReason: string;
}

export const invoiceDiscountValidationSchema = yup.object().shape(
  {
    discountAmount: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === '' ? undefined : value;
      })
      .typeError(getTranslation('VALIDATION_FIELD_MUST_BE_NUMBER'))
      .test(
        'lessThatTotalAmount',
        getTranslation('INVOICE_DISCOUNT_MUST_BE_LESS_THAT_TOTAL'),
        (value: number | string | undefined, ctx) => {
          if (!value) {
            return true;
          }
          return (
            +value <
            ctx.parent.invoiceServiceItems.reduce(
              (prev: number, curr: InvoiceServiceItem) =>
                prev + (curr.amount || 0) * (curr.quantity || 0),
              0,
            )
          );
        },
      )
      .optional(),
    discountReason: yup.string().when('discountReason', (val?: string) => {
      if (val && val.length > 0) {
        return yup
          .string()
          .min(4, getTranslation('VALIDATION_MIN', { min: 4 }))
          .max(60, getTranslation('VALIDATION_MAX', { max: 60 }));
      }
      return yup.string().optional();
    }),
  },
  [['discountReason', 'discountReason']],
);

const InvoiceDiscountForm = () => {
  const translate = useTranslation();

  return (
    <div className="column gap-2">
      <Field
        name="discountAmount"
        component={TextInputField}
        placeholder={translate('AMOUNT') + '*'}
        inputMode="decimal"
        parse={parseFiatAmount}
      />
      <Field
        name="discountReason"
        component={TextInputField}
        placeholder={translate('REASON') + '*'}
      />
    </div>
  );
};

export default InvoiceDiscountForm;
