import get from 'lodash/get';

import useRateReducer from 'modules/exchange/hooks/useRateReducer';

import { CurrencyCode } from 'types';

interface Options {
  autoFetch: boolean;
}
type UseRate = (from: CurrencyCode, to: CurrencyCode, options?: Options) => number;

const useRate: UseRate = (from, to, options) => {
  const rate = useRateReducer(from, to, options);

  return get(rate, 'data.rate') || 0;
};
export default useRate;
