import clsx from 'clsx';

import { memo } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import { LoanItem } from 'modules/loans/types';

import { TranslationKey, useTranslation } from 'libs/i18n';

import { ThemeColor, getThemeColor } from 'styles';

import classes from './LiquidationRiskCard.module.scss';

interface LiquidationRiskCardProps {
  riskPercent: number;
  marginCallStatus: LoanItem['marginCallStatus'];
  className?: string;
  variant?: 'vertical';
}

type MCStatus = LoanItem['marginCallStatus'];

export const LiquidationRiskCard = memo<LiquidationRiskCardProps>(
  ({ riskPercent, marginCallStatus, className, variant }) => {
    const translate = useTranslation();

    const translateKeyByStatus: { [key in MCStatus]: TranslationKey } = {
      approach: 'LOANS_LIQUIDATION_RISK_AVERAGE',
      active: 'LOANS_LIQUIDATION_RISK_DANGER',
      none: 'LOANS_LIQUIDATION_RISK_HEALTHY',
    };
    const colorByStatus: { [key in MCStatus]: ThemeColor } = {
      approach: 'yellow',
      active: 'red',
      none: 'green',
    };

    return (
      <div className={clsx(className, classes[`variant-${variant}`], classes.root)}>
        <CircularProgressbarWithChildren
          className={classes.circle}
          value={riskPercent}
          strokeWidth={8}
          styles={buildStyles({
            pathColor: getThemeColor(colorByStatus[marginCallStatus]),
            trailColor: getThemeColor('westar'),
          })}
        >
          <span className={classes.percentLabel}>{Math.round(riskPercent)}%</span>
        </CircularProgressbarWithChildren>
        <div className={classes.texts}>
          <span className="label">{translate('LOANS_LIQUIDATION_RISK')}</span>
          <span className={clsx(classes.liqRiskLabel, classes[marginCallStatus])}>
            {translate(translateKeyByStatus[marginCallStatus])}
          </span>
        </div>
      </div>
    );
  },
);
