// import clsx from 'clsx';
import {
  // Swiper,
  SwiperProps, // SwiperSlide
} from 'swiper/react';

import {
  FC, // useCallback, useMemo
} from 'react';

// import { useSelector } from 'react-redux';
//
// import useSideBar from 'modules/app/hooks/useSideBar';
// import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
// import { selectSubscriptionReducer } from 'modules/digitalAccount/store/selectors';
// import { requestDigitalAccountSubscriptions } from 'modules/digitalAccount/store/thunks';
// import { DigitalSubscription } from 'modules/digitalAccount/types';
// import { SubscriptionCard } from 'modules/digitalAccount/views/components/SubscriptionCard';
// import { useDispatch } from 'store';
//
// import { EntityView } from 'components/common';
//
// import useTablet from 'hooks/useTablet';
//
// import { useTranslation } from 'libs/i18n';
//
// import { findByProperty } from 'utils/arrayUtils';
//
// import classes from './SubscriptionsCarousel.module.scss';

interface SubscriptionsCarouselProps {
  className?: string;
  swiperProps?: Partial<SwiperProps>;
  sliderClassName?: string;
  labelClassName?: string;
  isSmallSpace?: boolean;
}

// interface SubscriptionCarouselComponentProps extends SubscriptionsCarouselProps {
//   subscriptions: DigitalSubscription[];
// }
//
// const SubscriptionCarouselComponent: FC<SubscriptionCarouselComponentProps> = ({
//   className,
//   subscriptions,
//   swiperProps,
//   sliderClassName,
//   labelClassName,
//   isSmallSpace,
// }) => {
//   const translate = useTranslation();
//   const sidebar = useSideBar();
//
//   const [hodler, whale] = useMemo(
//     () => [
//       findByProperty(subscriptions, 'type', 'hodler'),
//       findByProperty(subscriptions, 'type', 'whale'),
//     ],
//     [subscriptions],
//   );
//
//   const handleClickHodler = useCallback(() => {
//     if (hodler) {
//       sidebar.open(...sidebarTemplates.digitalAccountGetSubscription({ subscription: hodler }));
//     }
//   }, [hodler, sidebar]);
//
//   const handleClickWhale = useCallback(() => {
//     if (whale) {
//       sidebar.open(...sidebarTemplates.digitalAccountGetSubscription({ subscription: whale }));
//     }
//   }, [whale, sidebar]);
//
//   const userHasMaxSub = whale?.isActive;
//
//   const isTablet = useTablet();
//
//   const slidewPerView = useMemo(() => {
//     if (hodler?.isActive) {
//       return 1;
//     }
//     if (isSmallSpace) {
//       return 1.08;
//     }
//     if (isTablet) {
//       return 1.2;
//     }
//     return 2;
//   }, [isTablet, hodler, isSmallSpace]);
//
//   return !userHasMaxSub ? (
//     <div className={clsx(classes.root, className)}>
//       <span className={clsx(labelClassName, 'label')}>{translate('GET_MORE_FROM_NEBEUS')}</span>
//       <Swiper
//         className={clsx('mt-2 w100', sliderClassName)}
//         spaceBetween={12}
//         slidesPerView={slidewPerView}
//         {...swiperProps}
//       >
//         {hodler?.isActive === false && (
//           <SwiperSlide>
//             <SubscriptionCard
//               className="flex-1"
//               onClick={handleClickHodler}
//               subscription={hodler}
//             />
//           </SwiperSlide>
//         )}
//         {whale && (
//           <SwiperSlide>
//             <SubscriptionCard
//               className={clsx(hodler?.isActive === true && classes.wideDesc, 'flex-1')}
//               onClick={handleClickWhale}
//               subscription={whale}
//             />
//           </SwiperSlide>
//         )}
//       </Swiper>
//     </div>
//   ) : null;
// };

// const SubscriptionsCarousel: FC<SubscriptionsCarouselProps> = ({ ...props }) => {
//   const dispatch = useDispatch();
//
//   const subscriptionsReducer = useSelector(selectSubscriptionReducer);
//
//   const requestSubscriptions = useCallback(
//     () => dispatch(requestDigitalAccountSubscriptions()),
//     [dispatch],
//   );
//
//   return (
//     <EntityView
//       request={requestSubscriptions}
//       reducer={subscriptionsReducer}
//       component={
//         <SubscriptionCarouselComponent subscriptions={subscriptionsReducer.data} {...props} />
//       }
//     />
//   );
// };

// TODO: Return when will be available
const SubscriptionsCarouselTemp: FC<SubscriptionsCarouselProps> = () => null;
export default SubscriptionsCarouselTemp;
