import { createSelector } from '@reduxjs/toolkit';

import { selectUserProfile } from 'modules/user/store/selectors';
import { AppStore } from 'store';

export const selectAllowedDebitCardPaymentCurrencyCodesReducer = (state: AppStore) =>
  state.cryptoBankCard.allowedDebitCardPaymentCurrencyCodes;
export const selectBankCardsOrdersReducer = (state: AppStore) =>
  state.cryptoBankCard.bankCardsOrders;
export const selectBankCardsOrders = (state: AppStore) => state.cryptoBankCard.bankCardsOrders.data;

export const selectBankCardConfigReducer = (state: AppStore) => state.cryptoBankCard.config;
export const selectBankCardFeeConfigReducer = (state: AppStore) => state.cryptoBankCard.feeConfig;

export const selectAllowedCountriesReducer = (state: AppStore) =>
  state.cryptoBankCard.allowedCountries;
export const selectAllowedCountries = (state: AppStore) =>
  state.cryptoBankCard.allowedCountries.data;

export const selectIsCryptoBankCardAllowed = createSelector(
  selectUserProfile,
  selectAllowedCountries,
  (user, allowedCountries) =>
    user && !user.isBusinessAccount && allowedCountries.includes(user.countryCode),
);
export const selectBankCards = (state: AppStore) => state.cryptoBankCard.bankCards.data;
export const selectBankCardsReducer = (state: AppStore) => state.cryptoBankCard.bankCards;

export const selectAllowedLinkingCurrenciesReducer = (state: AppStore) =>
  state.cryptoBankCard.allowedLinkingCurrencies;

export const selectIsVirtualCryptoCardExist = createSelector(
  selectBankCards,
  (bankCards) => !!bankCards.find((bc) => bc.isVirtual),
);
export const selectIsPhysicalCryptoCardExist = createSelector(
  selectBankCards,
  (bankCards) => !!bankCards.find((bc) => !bc.isVirtual),
);
