import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { StepHeader } from 'modules/auth/views/components/StepHeader';

import routesByName from 'constants/routesByName';

import { Button, Icon } from 'components/ui';

import { useTranslation } from 'libs/i18n';
import useSwiperSlideSizeObserver from 'libs/swiper/useSwiperSlideSizeObserver';

interface BankCardOfferProps {}

const BankCardOfferSuccess: FC<BankCardOfferProps> = () => {
  const translate = useTranslation();
  const navigate = useNavigate();

  const handleFinish = useCallback(() => {
    navigate(routesByName.dashboard, { state: { from: 'auth' }, replace: true });
  }, [navigate]);

  const { setNode } = useSwiperSlideSizeObserver();

  return (
    <div ref={setNode} className="authStepRoot">
      {/*For justify content*/}
      <div className="showOnlyOnMobile" />
      <div className="column gap-5">
        <Icon name="checkInCircle" size={72} className="m-0-auto" />
        <StepHeader
          className="tac"
          title={translate('BANK_CARD_OFFER_ONBOARDING_SUCCESS_TITLE')}
          subtitle={translate('BANK_CARD_OFFER_ONBOARDING_SUCCESS_DESCRIPTION')}
        />
      </div>
      <Button onClick={handleFinish}>{translate('NEXT')}</Button>
    </div>
  );
};

export default BankCardOfferSuccess;
