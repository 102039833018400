import { IbanAccountProviderId } from 'modules/digitalAccount/types';
import { IApiRequestState } from 'store/generators/types';

import {
  CardTransactionAdditionalInfo,
  Issuer as IssuerApi,
  OperationPerTransactionLimitDto,
  TransactionAdditionalInfo,
} from 'libs/swagger/nebeusApiTypes';

import { CurrencyCode, FiatCurrencyCode } from 'types';

// cli-import

export interface AccountsState {
  wallets: IApiRequestState<TradingWallet[]>;
  issuers: IApiRequestState<Issuer[]>;
  activeAccountType: AccountType;
  digitalWallets: IApiRequestState<DigitalWallet[]>;
  lastTransactions: IApiRequestState<Transaction[]>;
  // cli-state-interface
}

export interface TradingWallet extends Wallet {
  address?: string;
}

export interface EURDigitalWallet extends Omit<Wallet, 'currencyCode'> {
  currencyCode: 'EUR';
  isDigitalWallet: true;
  ownerName: string;
  bankName: string;

  iban: string;
  bic: string;
  default: boolean;
}
export interface GBPDigitalWallet extends Omit<Wallet, 'currencyCode'> {
  currencyCode: 'GBP';
  isDigitalWallet: true;
  ownerName: string;
  bankName: string;

  accountNumber: string;
  sortCode: string;
  default: boolean;
}

export type DigitalWallet = (GBPDigitalWallet | EURDigitalWallet) & {
  providerId: IbanAccountProviderId;
};

export type Wallet = {
  amount: number;
  currencyCode: CurrencyCode;
  amountInDefaultCurrency: number;
  defaultCurrencyCode: FiatCurrencyCode;

  currencyLabel: string;

  exist?: boolean;

  issuerId: string;
  coinSerial: string | null;
};

export interface GetIssuersApiResponse {
  issuers: Required<IssuerApi[]>;
}
export interface Issuer {
  id: string;
  active: boolean;
  currencyCode: CurrencyCode;
  currencySymbol: string;
  currencyLabel: string;
}

export enum AccountType {
  digital = 'digital',
  crypto = 'crypto',
}

export enum TransactionRejectReasons {
  SANCTIONS,
}

export interface CryptoTransactionDetails
  extends Pick<TransactionAdditionalInfo, 'fromAddress' | 'hash' | 'network' | 'toAddress'> {
  rejectReason: TransactionRejectReasons;
}

export interface SendToNebeusUserTransactionDetails
  extends Pick<TransactionAdditionalInfo, 'counterpartyEmail' | 'reference'> {}
export interface IbanTransfersAdditionalInfo extends Pick<TransactionAdditionalInfo, 'reference'> {}

export interface Transaction {
  id: string;
  uniqId: string;
  requestIdentifier: string; // Alternative for id
  description: string;
  operationId: TransactionType | null;
  amount: number;
  amountInDefaultCurrency: number;
  currencyCode: CurrencyCode;
  dateCreated: string;
  defaultCurrencyCode: CurrencyCode;
  status:
    | 'completed'
    | 'pending'
    | 'failed'
    | 'rejected'
    | 'cancelled'
    | 'waitingForBlockConfirmation'
    | 'waitingForApproval'
    | 'created'
    | 'refunded';
  isIncome: boolean;

  additionalInfo:
    | CryptoTransactionDetails
    | CardTransactionAdditionalInfo
    | SendToNebeusUserTransactionDetails
    | IbanTransfersAdditionalInfo
    | null;
}

export type TransactionType = OperationPerTransactionLimitDto['operationCode'];
// cli-type
