import { userActions } from '../store';
import { requestDigitalWallets, requestWallets } from 'modules/accounts/store/thunks';
import { appActions } from 'modules/app/store';
import { selectIsDigitalAccountAllowed } from 'modules/digitalAccount/store/selectors';
import { allowedLanguages } from 'modules/user/constants/language';
import { selectIsUserVerified } from 'modules/user/store/selectors';
import store from 'store';
import {
  generateInfinityListThunk,
  generateRequestDataThunk,
  generateRequestThunk,
} from 'store/generators/thunks';

import userServices from '../services';
import { Profile } from '../types';

// cli-import

export const requestProfile = generateRequestDataThunk<Profile>({
  updateState: userActions.updateProfile,
  request: userServices.getUserProfile,
  options: {
    showErrorToast: false,
    onSuccess: (data, dispatch) => {
      dispatch(
        appActions.updateAppLanguage(allowedLanguages.find((l) => l.code === data.language)),
      );
    },
  },
});
export const requestGetVerificationToken = generateRequestThunk({
  request: userServices.getVerificationToken,
  options: { showErrorToast: false },
});
export const requestToggleSendOtpViaEmail = generateRequestThunk({
  request: userServices.toggleSendOtpViaEmail,
});

export const requestUpdateDefaultCurrency = generateRequestThunk({
  request: userServices.updateDefaultCurrency,
  options: {
    onSuccess: async (dispatch, data) => {
      dispatch(userActions.updateProfile({ data }));
      dispatch(requestWallets());
      const state = store.getState();
      if (selectIsUserVerified(state) && selectIsDigitalAccountAllowed(state)) {
        dispatch(requestDigitalWallets());
      }
    },
  },
});
export const requestUpdateUserLanguage = generateRequestThunk({
  request: userServices.updateLanguage,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestProfile());
    },
  },
});

export const requestUserExtendedProfile = generateRequestDataThunk({
  request: userServices.userProfileExtended,
  updateState: userActions.updateExtendedProfile,
});

export const requestReferralLinks = generateRequestDataThunk<string[]>({
  updateState: userActions.updateReferralLinks,
  request: userServices.getReferralLinks,
});
export const requestGenerateReferralLink = generateRequestThunk({
  request: userServices.generateReferralLink,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestReferralLinks());
    },
  },
});
export const requestNotifications = generateInfinityListThunk({
  updateState: userActions.updateNotifications,
  request: userServices.getNotifications,
  reducerPath: 'user.notifications',
});

export const requestReadNotification = generateRequestThunk({
  request: userServices.readNotification,
});
export const requestReadNotificationBatched = generateRequestThunk({
  request: userServices.readNotificationBatched,
  options: {
    onSuccess: (dispatch, data, reqPayload) => {
      dispatch(userActions.updateNotificationsReadStatus(reqPayload));
    },
  },
});
export const requestReadAllNotifications = generateRequestThunk({
  request: userServices.readAllNotifications,
});

export const requestToggleSendNewsToEmail = generateRequestThunk({
  request: userServices.toggleSendNewsToEmail,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestProfile());
    },
  },
});
export const requestGetChallenges = generateRequestThunk({
  request: userServices.getChallenges,
});
export const requestGetQuestionnaire = generateRequestThunk({
  request: userServices.getQuestionnaire,
});
export const requestSubmitQuestionnaire = generateRequestThunk({
  request: userServices.submitQuestionnaire,
});
export const requestSubmitCustomerQuestionnaire = generateRequestThunk({
  request: userServices.submitCustomerQuestionnaire,
});
// cli-thunk
