import clsx from 'clsx';

import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { appActions } from 'modules/app/store';
import { selectAppLanguage } from 'modules/app/store/selectors';
import { selectIsUserAuthed } from 'modules/auth/store/selectors';
import { AllowedLanguage, allowedLanguages } from 'modules/user/constants/language';
import { requestUpdateUserLanguage } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import { Image, Select } from 'components/ui';
import { SelectProps } from 'components/ui/Select';

import classes from './LanguagePicker.module.scss';

const languageKeyAccessor = (lang: AllowedLanguage) => lang.shortCode;
const renderLanguageItem = (lang: AllowedLanguage, index: number, active: boolean) => (
  <div className={clsx(classes.languageItem, active && classes.active)}>
    <Image className={classes.langImg} name={lang.shortCode} />
    <span>{lang.name}</span>
  </div>
);

export interface LanguagePickerProps
  extends Omit<SelectProps<AllowedLanguage>, 'options' | 'onPick' | 'value'> {}
export const LanguagePicker: FC<LanguagePickerProps> = ({ ...props }) => {
  const dispatch = useDispatch();

  const isUserAuthed = useSelector(selectIsUserAuthed);
  const appLanguage = useSelector(selectAppLanguage);

  const handleChangeLanguage = useCallback(
    async (lang: AllowedLanguage) => {
      if (isUserAuthed) {
        await dispatch(requestUpdateUserLanguage(lang.fullCode));
      } else {
        dispatch(appActions.updateAppLanguage(allowedLanguages.find((l) => l.code === lang.code)));
      }
    },
    [dispatch, isUserAuthed],
  );
  return (
    <Select<AllowedLanguage>
      onPick={handleChangeLanguage}
      options={allowedLanguages}
      renderItem={renderLanguageItem}
      keyAccessor={languageKeyAccessor}
      value={appLanguage}
      {...props}
    />
  );
};
