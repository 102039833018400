import { useEffect, useMemo } from 'react';

import { requestDigitalWallets } from 'modules/accounts/store/thunks';
import useDrawer from 'modules/app/hooks/useDrawer';
import {
  requestCreatingIbanAccountStatus,
  requestDigitalAccountSubscriptions,
} from 'modules/digitalAccount/store/thunks';
import { useDispatch } from 'store';

import { Button, DrawerHeader, Icon, Loader, Mark, MenuList } from 'components/ui';

import useEntity from 'hooks/useEntity';

import { TranslationKey, useTranslation } from 'libs/i18n';
import { ReferenceStatusResp } from 'libs/swagger/nebeusApiTypes';

import { formatCurrencyWithSymbol } from 'utils/currency';
import { openChatSupport } from 'utils/thirdPartyLibs';

const statusDescriptions: { [key in ReferenceStatusResp['status']]: TranslationKey } = {
  NONE: 'PENDING...',
  PENDING_PROVIDER: 'IBAN_CREATING_PENDING_PROVIDER',
  APPROVED_BY_PROVIDER: 'IBAN_CREATING_APPROVED_BY_PROVIDER',
  FINISHED: 'SUCCESS',
  REJECTED_BY_MIN_AMOUNT: 'IBAN_CREATING_REJECTED_BY_MIN_AMOUNT',
  REJECTED_BY_PROVIDER: 'IBAN_CREATING_REJECTED_BY_PROVIDER',
};

const IbanAccountCreatingStatus = () => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const { entity: statusResponse, fetchEntity: fetchStatus } = useEntity(
    () => dispatch(requestCreatingIbanAccountStatus()),
    {
      fetchOnMount: true,
    },
  );

  const status = statusResponse?.status || 'NONE';

  useEffect(() => {
    if (status === 'FINISHED') {
      dispatch(requestDigitalAccountSubscriptions());
      dispatch(requestDigitalWallets());
    }
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    let id: number | null;
    if (status !== 'FINISHED') {
      id = window.setInterval(fetchStatus, 30 * 1000);
    }

    return () => {
      if (id) {
        clearInterval(id);
      }
    };

    // eslint-disable-next-line
  }, [status]);

  const chatSupportMenu = useMemo(
    () => [
      {
        icon: 'chat',
        label: translate('PROFILE_CHAT_SUPPORT'),
        handler: openChatSupport,
        endAdornment: <Mark>{translate('BETA')}</Mark>,
      },
    ],
    [translate],
  );

  const statusRendered = useMemo(() => {
    if (status === 'FINISHED') {
      return (
        <div className="column aic gap-3 tac">
          <Icon size={72} name="checkInCircle" color="green" />
          <h3>{translate('SUCCESS')}</h3>
        </div>
      );
    }
    return (
      <div className="column aic gap-3 tac">
        {status.startsWith('REJECT') ? (
          <Icon size={72} name="crossInCircle" />
        ) : (
          <Loader size={72} centered variant="thin" />
        )}
        <h3>
          {translate(statusDescriptions[status], {
            amountLabel: formatCurrencyWithSymbol(5, 'EUR'),
          })}
        </h3>
      </div>
    );
  }, [status, translate]);

  return (
    <div className="pt-2 column flex-1 gap-3">
      <DrawerHeader showBackButton title={translate('IBAN_ACCOUNT_CREATING_STATUS_TITLE')} />
      <div className="flexScrollable gap-3 jcc">{statusRendered}</div>
      <div className="column gap-3">
        <MenuList menu={chatSupportMenu} />
        <div className="column gap-1-5">
          {status === 'REJECTED_BY_MIN_AMOUNT' && (
            <Button
              onClick={() => {
                drawer.pop();
              }}
            >
              {translate('ERROR_TRY_AGAIN')}
            </Button>
          )}
          <Button variant="creamyBlack" onClick={drawer.close}>
            {translate('CLOSE')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default IbanAccountCreatingStatus;
