import { FC, ReactNode } from 'react';

import { DescriptionCard } from 'components/ui';

import { TranslationKey, useTranslation } from 'libs/i18n';

export interface DescriptionCardsListItem {
  icon: string;
  title: TranslationKey;
  desc: TranslationKey;
  descParams?: { [key: string]: string | number };
  endAdornment?: ReactNode;
}
export interface DescriptionCardsProps {
  list: DescriptionCardsListItem[];
}
const DescriptionCards: FC<DescriptionCardsProps> = ({ list }) => {
  const translate = useTranslation();
  return (
    <div className="column gap-2 mt-1">
      {list.map((i) => (
        <DescriptionCard
          key={i.icon}
          title={translate(i.title)}
          description={translate(i.desc, i.descParams)}
          icon={i.icon}
          endAdornment={i.endAdornment}
        />
      ))}
    </div>
  );
};

export default DescriptionCards;
