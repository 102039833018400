import clsx from 'clsx';
import { isEqual, times } from 'lodash';

import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import phonebookDrawerTemplates from 'modules/phonebook/constants/drawerTemplates';
import {
  configPaymentDetailsByType,
  getIconByPaymentDetails,
  maskPaymentDetailsValue,
  remapPaymentDetailsToFormValues,
} from 'modules/phonebook/constants/utils';
import { phonebookActions } from 'modules/phonebook/store';
import {
  selectCounterpartiesLibrary,
  selectPaymentDetailsLibrary,
  selectSelectedRecipientForSendingFunds,
} from 'modules/phonebook/store/selectors';
import {
  requestGetCounterpartyList,
  requestGetCounterpartyPaymentsDetails,
  requestGetPaymentDetailsList,
} from 'modules/phonebook/store/thunks';
import { Counterparty, CounterpartyPaymentDetails, PaymentDetails } from 'modules/phonebook/types';
import { useDispatch } from 'store';

import { Avatar, Button, Icon, Skeleton, TextInput } from 'components/ui';

import useEntity from 'hooks/useEntity';
import useFlag from 'hooks/useFlag';
import useScrollDrag from 'hooks/useScrollDrag';

import { useTranslation } from 'libs/i18n';
import { GetPaymentDetailsListUsingQueryParams } from 'libs/swagger/nebeusApiTypes';

import eventEmitter, { eventEmitterEventNames } from 'utils/eventEmitter';

import { CurrencyCode, voidFunc } from 'types';

import classes from './RecipientSelector.module.scss';

interface RecipientSelectorProps {
  isNebeusUserForm?: boolean;
  paymentType?: PaymentDetails['type'];
  children: ReactNode;
  filters?: Partial<GetPaymentDetailsListUsingQueryParams>;
}

interface PaymentDetailsCardProps {
  paymentDetails: PaymentDetails;
  onClick?: voidFunc;
  active?: boolean;
  disabled?: boolean;
}

const PaymentDetailsCard: FC<PaymentDetailsCardProps> = ({
  paymentDetails,
  onClick,
  active,
  disabled,
}) => {
  const primaryValue = useMemo(() => {
    const primaryValueKey = configPaymentDetailsByType[paymentDetails.type].primaryDetailsField;
    let result = paymentDetails.details.find((i) => i.type === primaryValueKey)?.value || '-';
    result = maskPaymentDetailsValue(paymentDetails.type, result);
    return result;
  }, [paymentDetails]);

  const icon = useMemo(
    () => getIconByPaymentDetails(paymentDetails, { size: 'sm' }),
    [paymentDetails],
  );

  return (
    <div
      className={clsx(classes.paymentDetailsCard, active && classes.active, disabled && 'disabled')}
      onClick={disabled ? undefined : onClick}
    >
      <Icon className={classes.activeIcon} name="checkSquared" size={10} />
      <div className={classes.detailsIcon}>{icon}</div>
      <span>{primaryValue}</span>
    </div>
  );
};

interface CounterpartyCardProps {
  active: boolean;
  onClick: voidFunc;
  name: string;
}
const CounterpartyCard: FC<CounterpartyCardProps> = ({ active, onClick, name }) => {
  return (
    <div onClick={onClick} className={classes.counterpartyCard}>
      <div className="relative">
        {active && <Icon className={classes.check} name="checkSquared" size={10} />}
        <Avatar name={name} />
      </div>
      <span className={classes.name}>{name}</span>
    </div>
  );
};

interface PhonebookCarouselProps {
  activePaymentDetailsId: PaymentDetails['id'] | null;
  activeCounterpartyId: Counterparty['id'] | null;
  onCounterpartyPick: (counterparty: Counterparty) => void;
  list: CounterpartyPaymentDetails[];
  updateSelectedPaymentDetails: (details: PaymentDetails) => void;
  paymentType?: PaymentDetails['type'];
  isNebeusUserForm?: boolean;
  cryptoCurrencyCode?: CurrencyCode;
}
const PhonebookCarousel: FC<PhonebookCarouselProps> = ({
  activePaymentDetailsId,
  activeCounterpartyId,
  list,
  onCounterpartyPick,
  updateSelectedPaymentDetails,
  paymentType,
  isNebeusUserForm,
  cryptoCurrencyCode,
}) => {
  const drawer = useDrawer();
  const translate = useTranslation();

  const counterpartyScrollRef = useRef<HTMLDivElement | null>(null);
  const paymentDetailsScrollRef = useRef<HTMLDivElement | null>(null);

  useScrollDrag(counterpartyScrollRef.current);
  useScrollDrag(paymentDetailsScrollRef.current);

  const currentCounterparty = useMemo(() => {
    let result = list.find((i) => i.counterparty.id === activeCounterpartyId);

    if (!result) {
      return;
    }

    if (paymentType === 'CRYPTO_WALLET' && cryptoCurrencyCode) {
      result = {
        ...result,
        paymentDetails: [...result.paymentDetails].sort((a) => {
          const aCode = a.details.find((d) => d.type === 'CURRENCY_CODE')?.value;
          if (aCode === cryptoCurrencyCode) {
            return -1;
          }
          return 0;
        }),
      };
    }
    return result;
  }, [list, paymentType, cryptoCurrencyCode, activeCounterpartyId]);

  const handleCounterpartyPick = useCallback(
    async (counterparty: Counterparty) => {
      await onCounterpartyPick(counterparty);
      paymentDetailsScrollRef.current?.scroll({ left: 0, behavior: 'smooth' });
      counterpartyScrollRef.current?.scroll({ left: 0, behavior: 'smooth' });
    },
    [onCounterpartyPick],
  );

  const handleSearchClick = useCallback(() => {
    drawer.open(
      phonebookDrawerTemplates.counterparties({
        onPick: handleCounterpartyPick,
        withPick: true,
        isNebeusUser: isNebeusUserForm,
      }),
    );
  }, [drawer, handleCounterpartyPick, isNebeusUserForm]);

  const paymentDetailsConfig = paymentType ? configPaymentDetailsByType[paymentType] : null;

  const handleClickAddPaymentDetails = useCallback(() => {
    if (!currentCounterparty || !paymentDetailsConfig || !paymentType) {
      return;
    }
    drawer.open(
      phonebookDrawerTemplates.addEditPaymentDetails({
        counterpartyId: currentCounterparty.counterparty.id,
        type: paymentType,
        title: translate(paymentDetailsConfig.addLabel),
        onSuccess: (paymentDetails: PaymentDetails) => {
          paymentDetailsScrollRef.current?.scroll({
            left: paymentType === 'CRYPTO_WALLET' ? 0 : 9999,
            behavior: 'smooth',
          });
          if (
            paymentType === 'CRYPTO_WALLET' &&
            cryptoCurrencyCode !==
              paymentDetails.details.find((d) => d.type === 'CURRENCY_CODE')?.value
          ) {
            return;
          }
          updateSelectedPaymentDetails(paymentDetails);
        },
        checkHandlers: true,
      }),
    );
  }, [
    cryptoCurrencyCode,
    paymentType,
    paymentDetailsConfig,
    updateSelectedPaymentDetails,
    translate,
    drawer,
    currentCounterparty,
  ]);

  return (
    <div className="column gap-3">
      <TextInput
        inputContainerClassName="pointer"
        startAdornment={<Icon name="search" className={classes.searchIcon} />}
        value=""
        placeholder={translate('SEARCH')}
        disabled
        onInputContainerClick={handleSearchClick}
      />
      <div ref={counterpartyScrollRef} className={classes.carousel}>
        {list.map((i) => (
          <CounterpartyCard
            key={i.counterparty.id}
            active={activeCounterpartyId === i.counterparty.id}
            onClick={() => {
              onCounterpartyPick(i.counterparty);
              paymentDetailsScrollRef.current?.scrollTo({ left: 0, behavior: 'smooth' });
            }}
            name={i.counterparty.name}
          />
        ))}
      </div>
      {currentCounterparty && !isNebeusUserForm ? (
        <div
          ref={paymentDetailsScrollRef}
          className={clsx(classes.carousel, classes.paymentDetailsCarousel)}
        >
          {currentCounterparty.paymentDetails.map((i) => {
            const disabled =
              paymentType === 'CRYPTO_WALLET' &&
              i.details.find((d) => d.type === 'CURRENCY_CODE')?.value !== cryptoCurrencyCode;

            return (
              <PaymentDetailsCard
                key={i.id}
                active={activePaymentDetailsId === i.id}
                onClick={() => {
                  updateSelectedPaymentDetails(i);
                }}
                paymentDetails={i}
                disabled={disabled}
              />
            );
          })}
          {paymentDetailsConfig && (
            <div
              onClick={handleClickAddPaymentDetails}
              className={clsx(classes.paymentDetailsCard, classes.wide)}
            >
              <Icon name={paymentDetailsConfig.addIconName} size={16} />
              <span>{translate(paymentDetailsConfig.addLabel)}</span>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

const RecipientSelector: FC<RecipientSelectorProps> = ({
  paymentType,
  children,
  filters,
  isNebeusUserForm,
}) => {
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const translate = useTranslation();

  const counterpartiesLibrary = useSelector(selectCounterpartiesLibrary);
  const paymentDetailsLibrary = useSelector(selectPaymentDetailsLibrary);

  const initialCounterpartiesWithDetailsLoading = useFlag(true);
  const [initialCounterpartiesWithDetails, setInitialCounterpartiesWithPaymentDetails] = useState<
    CounterpartyPaymentDetails[]
  >([]);

  const fetchCounterpartiesWithPaymentDetails = useCallback(async () => {
    initialCounterpartiesWithDetailsLoading.on();
    if (isNebeusUserForm) {
      const { data } = await dispatch(
        requestGetCounterpartyList({
          pageSize: 10,
          pageNumber: 0,
          isNebeusUser: true,
          ...filters,
        }),
      );
      if (data) {
        setInitialCounterpartiesWithPaymentDetails(
          data.list.map((i) => ({ counterparty: i, paymentDetails: [] })),
        );
      }
    } else {
      const { data } = await dispatch(
        requestGetPaymentDetailsList({
          pageSize: 10,
          pageNumber: 0,
          type: paymentType,
          sortBy: 'LAST_USED',
          searchQuery: '',
          ...filters,
        }),
      );
      if (data) {
        setInitialCounterpartiesWithPaymentDetails(data.list);
      }
    }
    initialCounterpartiesWithDetailsLoading.off();
  }, [dispatch, filters, isNebeusUserForm, paymentType, initialCounterpartiesWithDetailsLoading]);

  useEffect(() => {
    fetchCounterpartiesWithPaymentDetails();
    // eslint-disable-next-line
  }, []);

  const { fetchEntity: fetchAllCounterparties, loading: allCounterpartiesLoading } = useEntity(() =>
    dispatch(requestGetCounterpartyList({ pageSize: 10, pageNumber: 0 })),
  );

  const replaceCounterpartyWithDetailsByAllCounterparties = useCallback(async () => {
    const allCounterparties = await fetchAllCounterparties();
    if (allCounterparties?.list.length) {
      setCounterpartiesIds(allCounterparties.list.map((i) => i.id));
    }
  }, [fetchAllCounterparties]);

  // Show all counterparties if there are no counterparties with details
  useEffect(() => {
    if (
      initialCounterpartiesWithDetails.length === 0 &&
      !initialCounterpartiesWithDetailsLoading.state &&
      !isNebeusUserForm
    ) {
      replaceCounterpartyWithDetailsByAllCounterparties();
    }
    // eslint-disable-next-line
  }, [initialCounterpartiesWithDetails, initialCounterpartiesWithDetailsLoading.state]);

  useEffect(() => {
    const unsub = eventEmitter.subscribe(
      eventEmitterEventNames.phonebookCounterpartyCreated,
      fetchCounterpartiesWithPaymentDetails,
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [fetchCounterpartiesWithPaymentDetails]);

  useEffect(() => {
    if (isNebeusUserForm) {
      return;
    }
    const unsub = eventEmitter.subscribe(
      eventEmitterEventNames.phonebookCounterpartyPaymentDetailsUpdated,
      fetchCounterpartiesWithPaymentDetails,
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [fetchCounterpartiesWithPaymentDetails]);

  const [counterpartiesIds, setCounterpartiesIds] = useState<Counterparty['id'][]>([]);

  useEffect(() => {
    if (initialCounterpartiesWithDetails.length) {
      setCounterpartiesIds(initialCounterpartiesWithDetails.map((i) => i.counterparty.id));
    }
  }, [initialCounterpartiesWithDetails]);

  const counterpartiesWithDetails = useMemo<CounterpartyPaymentDetails[]>(
    () =>
      counterpartiesIds
        .map((cId) => ({
          counterparty: counterpartiesLibrary[cId],
          paymentDetails: Object.values(paymentDetailsLibrary[cId] || {}).filter(
            (i) => i.type === paymentType,
          ),
        }))
        .filter((i) => !!i.counterparty),
    [paymentDetailsLibrary, counterpartiesIds, paymentType, counterpartiesLibrary],
  );

  const selectedRecipient = useSelector(selectSelectedRecipientForSendingFunds);

  const { paymentDetailsId: activePaymentDetailsId, counterpartyId: activeCounterpartyId } =
    selectedRecipient;

  const setActiveCounterpartyId = useCallback(
    (id: Counterparty['id'] | null) => {
      dispatch(
        phonebookActions.updateSelectedRecipientForSendingFunds({
          counterpartyId: id,
          paymentDetailsId: null,
        }),
      );
    },
    [dispatch],
  );

  const setActivePaymentDetailsId = useCallback(
    (id: PaymentDetails['id'] | null) => {
      dispatch(
        phonebookActions.updateSelectedRecipientForSendingFunds({
          paymentDetailsId: id,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(
        phonebookActions.updateSelectedRecipientForSendingFunds({
          paymentDetailsId: null,
          counterpartyId: null,
        }),
      );
    };

    // eslint-disable-next-line
  }, []);

  const form = useForm<{ [key: string]: any }>();
  const { values, initialValues } = useFormState<{ [key: string]: any }>();

  const updateSelectedPaymentDetails = useCallback(
    (paymentDetails: PaymentDetails) => {
      const formValues = remapPaymentDetailsToFormValues(paymentDetails);

      if (paymentDetails.id === activePaymentDetailsId) {
        setActivePaymentDetailsId(null);

        form.batch(() => {
          Object.entries(formValues).forEach(([name]) => form.change(name, initialValues[name]));
        });
      } else {
        const formValues = remapPaymentDetailsToFormValues(paymentDetails);

        form.batch(() => {
          Object.entries(formValues).forEach(([name, value]) => form.change(name, value));
        });

        // timeout for setting form values and active payment details id in one render;
        setTimeout(() => {
          setActivePaymentDetailsId(paymentDetails.id);
        }, 1);
      }
    },
    [setActivePaymentDetailsId, activePaymentDetailsId, form, initialValues],
  );

  const [currentObserverValues, staticValues] = useMemo(() => {
    let staticFormValues: object | null = null;
    let paymentDetails = null;

    const activeCounterpartyWithDetails = counterpartiesWithDetails.find(
      (c) => c.counterparty.id === activeCounterpartyId,
    );
    if (!activeCounterpartyWithDetails) {
      return [null, null];
    }
    if (!isNebeusUserForm) {
      paymentDetails = activeCounterpartyWithDetails.paymentDetails.find(
        (pd) => pd.id === activePaymentDetailsId,
      );

      if (!paymentDetails) {
        return [null, null];
      }
      staticFormValues = remapPaymentDetailsToFormValues(paymentDetails);
    } else {
      staticFormValues = { email: activeCounterpartyWithDetails.counterparty.email };
    }

    if (!staticFormValues) {
      return [null, null];
    }

    const observerFormValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (key in staticFormValues!) {
        return { ...acc, [key]: value };
      }
      return acc;
    }, {});

    return [observerFormValues, staticFormValues];
  }, [
    isNebeusUserForm,
    activeCounterpartyId,
    activePaymentDetailsId,
    values,
    counterpartiesWithDetails,
  ]);

  const clearForm = useCallback(() => {
    if (staticValues) {
      form.batch(() => {
        Object.entries(staticValues).forEach(([name]) => form.change(name, initialValues[name]));
      });
    }
    setActivePaymentDetailsId(null);
  }, [form, initialValues, setActivePaymentDetailsId, staticValues]);

  const onCounterpartyPick = useCallback(
    async (counterparty: Counterparty) => {
      if (!counterpartiesIds.includes(counterparty.id)) {
        const { data: paymentDetails = [], success } = await dispatch(
          requestGetCounterpartyPaymentsDetails(counterparty.id),
        );
        if (!paymentDetails || !success) {
          return;
        }
        setCounterpartiesIds((prev) => [counterparty.id, ...prev]);
      }

      if (isNebeusUserForm) {
        if (activeCounterpartyId === counterparty.id) {
          clearForm();
          setActiveCounterpartyId(null);
        } else {
          const newCounterparty = counterpartiesLibrary[counterparty.id];
          if (!newCounterparty) {
            return;
          }
          setActiveCounterpartyId(counterparty.id);
          form.change('email', newCounterparty.email);
        }
      } else {
        if (activeCounterpartyId !== counterparty.id) {
          clearForm();
          setActiveCounterpartyId(counterparty.id);
        }
      }
    },
    [
      counterpartiesLibrary,
      activeCounterpartyId,
      setActiveCounterpartyId,
      counterpartiesIds,
      dispatch,
      isNebeusUserForm,
      form,
      clearForm,
    ],
  );

  useEffect(() => {
    if (isNebeusUserForm) {
      if (currentObserverValues && staticValues && !isEqual(staticValues, currentObserverValues)) {
        setActiveCounterpartyId(null);
      }
    } else {
      if (
        activePaymentDetailsId &&
        currentObserverValues &&
        staticValues &&
        !isEqual(staticValues, currentObserverValues)
      ) {
        setActivePaymentDetailsId(null);
      }
    }
    // eslint-disable-next-line
  }, [currentObserverValues]);

  const handleClickAddCounterparty = useCallback(() => {
    drawer.open(phonebookDrawerTemplates.addEditCounterparty());
  }, [drawer]);

  if (initialCounterpartiesWithDetailsLoading.state || allCounterpartiesLoading) {
    return (
      <div className="outlinedCard column gap-3">
        <Skeleton height={32} width={110} />
        <Skeleton height={48} />
        <div className="row gap-1-5 hidden">
          {times(10, (i) => (
            <div key={i} className="column gap-1-5 aic">
              <Skeleton width={40} height={40} borderRadius={20} />
              <Skeleton height={16} width={75} />
            </div>
          ))}
        </div>
        <div className="column gap-2">
          <Skeleton width={110} height={16} />
          <Skeleton height={48} />
          <Skeleton height={48} />
        </div>
      </div>
    );
  }

  return (
    <div className="outlinedCard column gap-3">
      <h3>
        {translate(counterpartiesWithDetails.length ? 'PHONEBOOK_PICK_RECIPIENT' : 'ENTER_DETAILS')}
      </h3>
      {counterpartiesWithDetails.length ? (
        <div className="column gap-3">
          <PhonebookCarousel
            list={counterpartiesWithDetails}
            activePaymentDetailsId={activePaymentDetailsId}
            activeCounterpartyId={activeCounterpartyId}
            updateSelectedPaymentDetails={updateSelectedPaymentDetails}
            onCounterpartyPick={onCounterpartyPick}
            paymentType={paymentType}
            isNebeusUserForm={isNebeusUserForm}
            cryptoCurrencyCode={filters?.currencyCode}
          />
          <div className="column gap-2">
            <span className="label">{translate('PAYMENT_OR_ENTER_DETAILS')}</span>
            {children}
          </div>
        </div>
      ) : (
        <div className="column gap-2">
          {children}
          <span className="label">{translate('OR')}</span>
          <Button onClick={handleClickAddCounterparty} variant="darkGreenOutlined">
            {translate('PHONEBOOK_ADD_COUNTERPARTY')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default RecipientSelector;
