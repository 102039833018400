import { useCallback } from 'react';

import useRate from 'modules/exchange/hooks/useRate';

import { CurrencyCode } from 'types';

type UseRateCalculator = (from: CurrencyCode, to: CurrencyCode) => (amount: number) => number;

const useRateCalculator: UseRateCalculator = (from, to) => {
  const rate = useRate(from, to, { autoFetch: true });

  return useCallback(
    (amount) => {
      return amount * rate;
    },
    [rate],
  );
};
export default useRateCalculator;
