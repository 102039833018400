import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { SmartTraderTitles } from 'modules/smartTrader/views/components/SmartTraderTitles';

import { AgreementText } from 'components/common';
import { Button } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

export interface SmartTraderPickAssetsProps {
  currencyCode: CurrencyCode;
}
const SmartTraderPickAssets: FC<SmartTraderPickAssetsProps> = ({ currencyCode }) => {
  const translate = useTranslation();

  const sidebar = useSideBar();

  const wallets = useSelector(selectWallets);
  const wallet = useMemo(
    () => wallets.find((w) => w.currencyCode === currencyCode),
    [wallets, currencyCode],
  );

  const currencyLabel = getCurrencyLabelByCode(currencyCode);

  const handleDeposit = useCallback(() => {
    sidebar.open(
      ...sidebarTemplates.paymentProcess({
        isDeposit: true,
        isDigitalAccount: false,
        currencyCode,
      }),
    );
  }, [sidebar, currencyCode]);

  const handleExchange = useCallback(() => {
    sidebar.open(...sidebarTemplates.smartTraderExchange({ currencyCode }));
  }, [currencyCode, sidebar]);

  const handleUseExistingAssets = useCallback(() => {
    if (!wallet || wallet.amount === 0) {
      sidebar.open(...sidebarTemplates.smartTraderInsufficientFunds({ currencyCode }));
      return;
    }
    sidebar.open(...sidebarTemplates.smartTraderSelectTradingPair({ currencyCode }));
  }, [sidebar, currencyCode, wallet]);

  return (
    <>
      <SmartTraderTitles
        currencyCode={currencyCode}
        title={translate('SMART_TRADER_PICK_ASSETS_TITLE', {
          currencyLabel,
        })}
        subtitle={translate('SMART_TRADER_PICK_ASSETS_SUBTITLE', {
          currencyLabel,
        })}
      />
      <div className="column gap-1-5">
        {/*// TODO: WBE*/}
        {/*<Button variant="gold">{translate('BUY') + ' ' + currencyLabel}</Button>*/}
        <Button variant="gold" onClick={handleExchange}>
          {translate('EXCHANGE')}
        </Button>
        <Button variant="goldOutlined" onClick={handleUseExistingAssets}>
          {translate('SMART_TRADER_USE_EXISTING_ASSETS')}
        </Button>
        <Button variant="goldOutlined" onClick={handleDeposit}>
          {translate('DEPOSIT_CURRENCY', { currencyLabel })}
        </Button>
        <AgreementText light />
      </div>
    </>
  );
};

export default SmartTraderPickAssets;
