import { useCallback, useState } from 'react';

const useAnchor = <T = HTMLDivElement>(): [T | null, (ref: T | null) => void] => {
  const [anchor, setAnchor] = useState<T | null>(null);

  const handleSetAnchor = useCallback((ref: T | null) => {
    if (ref) {
      setAnchor(ref);
    }
  }, []);

  return [anchor, handleSetAnchor];
};

export default useAnchor;
