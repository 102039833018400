import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';

import routesByName from 'constants/routesByName';

import { MenuList } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import { useTranslation } from 'libs/i18n';

const AccountOperationsMore = () => {
  const translate = useTranslation();
  const sidebar = useSideBar();
  const navigate = useNavigate();

  const menu = useMemo<MenuListItem[]>(
    () => [
      {
        icon: 'time',
        label: translate('ACCOUNT_TRANSACTIONS_HISTORY'),
        handler: () => {
          sidebar.pop();
          navigate(routesByName.accountTransactionsHistory);
        },
      },
      {
        icon: 'doc',
        label: translate('ACCOUNTS_ACCOUNT_STATEMENT'),
        handler: () => {
          sidebar.replace(
            ...sidebarTemplates.accountStatement({
              isDigitalAccount: false,
            }),
          );
        },
      },
    ],
    [translate, navigate, sidebar],
  );
  return <MenuList menu={menu} />;
};

export default AccountOperationsMore;
