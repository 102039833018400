import { useEffect } from 'react';

import { voidFunc } from 'types';

const useWindowFocus = (handler: voidFunc) => {
  useEffect(() => {
    window.addEventListener('focus', handler);

    return () => {
      window.removeEventListener('focus', handler);
    };
  }, [handler]);
};

export default useWindowFocus;
