import { MouseEventHandler } from 'react';

import { IAPIMeta } from 'store/generators/types';

export const sleep = (ms: number) =>
  new Promise((res) => {
    setTimeout(res, ms);
  });

export const stopPropagation: MouseEventHandler<HTMLDivElement> = (e) => {
  e.stopPropagation();
};

export const isEntityStale = (lastLoadedTime: number | null, distanceMinutes = 1) =>
  !lastLoadedTime || (lastLoadedTime && Date.now() - lastLoadedTime > distanceMinutes * 1000 * 60);
// const ONE_MINUTE_IN_MS = 1000 * 60;
export const canRequest = (meta: IAPIMeta, distanceMinutes?: number) =>
  isEntityStale(meta.lastLoadedTime, distanceMinutes);

export const getStringFromClipboard = async () => {
  try {
    const value = (await window.navigator.clipboard?.readText()) || '';
    return value;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Clipboard read text is not allowed');
    return '';
  }
};
export const setStringToClipboard = async (value: string): Promise<boolean> => {
  try {
    await window.navigator.clipboard?.writeText(value);
    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Clipboard write text is not allowed');
    return false;
  }
};

export const clearClipboard = async () => {
  try {
    await window.navigator.clipboard.writeText('');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Clipboard clear text error');
  }
};

export const isMacOS = window.navigator?.appVersion?.includes('Mac OS');

export const isInteger = (value: number) =>
  typeof value === 'number' && isFinite(value) && Math.floor(value) === value;

export const updateStyleVariable = (variableName: string, value: string) =>
  document.documentElement.style.setProperty(variableName, value);
