import clsx from 'clsx';

import { FC, ReactNode, useMemo } from 'react';

import { Invoice } from 'modules/invoicing/types';

import { AmountLabel, DetailsDotSeparated } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './SummaryAmount.module.scss';

interface SummaryAmountProps {
  className?: string;
  startAdornment?: ReactNode;
  discountAmount: number;
  serviceItems: Invoice['invoiceServiceItems'];
  currencyCode?: CurrencyCode;
}
export const SummaryAmount: FC<SummaryAmountProps> = ({
  className,
  startAdornment,
  serviceItems,
  discountAmount,
  currencyCode,
}) => {
  const translate = useTranslation();

  const subtotal = useMemo(
    () =>
      serviceItems.reduce((prev, current) => {
        return prev + +current.amount * +current.quantity;
      }, 0),
    [serviceItems],
  );

  const total = subtotal - discountAmount;

  return (
    <div className={clsx('column gap-3', className)}>
      {startAdornment}
      <div className="column gap-1-5">
        <DetailsDotSeparated
          label={translate('INVOICE_SUB_TOTAL')}
          value={currencyCode ? formatCurrencyWithSymbol(subtotal, currencyCode) : '-'}
        />
        {serviceItems.length ? (
          <div className={classes.subItems}>
            {serviceItems.map((item, index) => (
              <DetailsDotSeparated
                key={`${item.name}${index}`}
                label={item.name || '-'}
                value={
                  currencyCode
                    ? formatCurrencyWithSymbol(item.amount * item.quantity, currencyCode)
                    : '-'
                }
              />
            ))}
          </div>
        ) : null}
        <DetailsDotSeparated
          label={translate('DISCOUNT')}
          value={currencyCode ? formatCurrencyWithSymbol(discountAmount, currencyCode) : '-'}
        />
      </div>
      <div className="row gap-2 jcsb">
        <span className={classes.boldLabel}>{translate('TOTAL')}</span>
        {currencyCode ? (
          <AmountLabel amount={total} currencyCode={currencyCode} showCurrencySymbol />
        ) : (
          <span className={classes.boldLabel}>-</span>
        )}
      </div>
    </div>
  );
};
