import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';

import { FC, useCallback, useMemo, useState } from 'react';

import { Button, CheckBox, ProgressBar, TextInput } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import classes from './Quiz.module.scss';

interface AnswerItemProps {
  questionId: string;
  severalAnswersAllowed?: boolean;
  onChange: (payload: { questionId: string; answerId?: string; customAnswer?: string }) => void;
  option: Option;
  answer: Answer;
}
const AnswerItem: FC<AnswerItemProps> = ({
  onChange,
  questionId,
  option,
  answer,
  severalAnswersAllowed,
}) => {
  const selected = answer.answersIds.includes(option.id);

  return (
    <div className="column gap-3">
      <div
        className={classes.answer}
        onClick={() => {
          onChange({ questionId, answerId: option.id });
        }}
      >
        <CheckBox rounded={!severalAnswersAllowed} checked={selected} />
        <span>{option.text}</span>
      </div>
      {selected && option.customAnswerAllowed && (
        <TextInput
          placeholder={option.text}
          value={answer.customAnswer || ''}
          onChange={(e) => onChange({ questionId, customAnswer: e.currentTarget.value })}
        />
      )}
    </div>
  );
};

interface Option {
  id: string;
  text: string;
  customAnswerAllowed?: boolean;
}
interface Answer {
  answersIds: string[];
  customAnswer?: string;
}

interface QuizItem {
  questionId: string;
  questionText: string;
  severalAnswersAllowed?: boolean;
  options?: Option[];
  answerComponent?: FC<{
    answer: Answer;
    questionId: string;
    onChange: (payload: { questionId: string; customAnswer?: string }) => void;
  }>;
}
export interface QuizProps {
  questions: QuizItem[];
  onSubmit: (
    answers: { questionId: string; answersIds: string[]; customAnswer?: string }[],
  ) => MaybePromise<void>;
  onSkip?: () => MaybePromise<void>;
}
export const Quiz: FC<QuizProps> = ({ questions, onSubmit, onSkip }) => {
  const translate = useTranslation();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [answers, setAnswers] = useState<{
    [questionId: string]: Answer;
  }>(questions.reduce((acc, value) => ({ ...acc, [value.questionId]: { answersIds: [] } }), {}));

  const currentQuestion = useMemo(
    () => questions[currentQuestionIndex],
    [questions, currentQuestionIndex],
  );

  const currentAnswer = useMemo(
    () => answers[currentQuestion.questionId],
    [answers, currentQuestion],
  );

  const updateAnswer = useCallback(
    ({
      questionId,
      answerId,
      customAnswer,
    }: {
      questionId: string;
      answerId?: string;
      customAnswer?: string;
    }) => {
      setAnswers((prev) => {
        const currentAnswer = prev[questionId];

        const newAnswer = {
          ...currentAnswer,
        };
        if (answerId) {
          const question = questions.find((q) => q.questionId === questionId)!;

          if (question.severalAnswersAllowed) {
            newAnswer.answersIds = currentAnswer.answersIds.includes(answerId)
              ? currentAnswer.answersIds.filter((id) => id !== answerId)
              : [...currentAnswer.answersIds, answerId];
          } else {
            newAnswer.answersIds = [answerId];
          }
        }

        if (customAnswer !== undefined) {
          newAnswer.customAnswer = customAnswer;
        }

        return {
          ...prev,
          [questionId]: newAnswer,
        };
      });
    },
    [questions],
  );

  const [hasPrev, hasNext] = useMemo(() => {
    return [currentQuestionIndex !== 0, currentQuestionIndex !== questions.length - 1];
  }, [currentQuestionIndex, questions]);

  const handlePressBack = useCallback(() => {
    setCurrentQuestionIndex((prev) => (prev === 0 ? 0 : prev - 1));
  }, []);

  const handlePressNext = useCallback(() => {
    if (currentQuestionIndex < questions.length) {
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  }, [currentQuestionIndex, questions]);

  const submitting = useFlag(false);

  const handleSubmit = useCallback(async () => {
    submitting.on();
    await onSubmit(
      Object.entries(answers).map(([questionId, { answersIds, customAnswer }]) => ({
        questionId,
        answersIds,
        customAnswer,
      })),
    );
    submitting.off();
  }, [submitting, answers, onSubmit]);
  const skipping = useFlag(false);

  const handleSkip = useCallback(async () => {
    if (!onSkip) {
      return;
    }
    skipping.on();
    await onSkip();
    skipping.off();
  }, [skipping, onSkip]);

  return (
    <div className="column flex-1 jcsb gap-3">
      <div className={classes.content}>
        <ProgressBar minValue={0} maxValue={questions.length - 1} value={currentQuestionIndex} />
        <div className={classes.question}>
          <h6>{currentQuestion.questionText}</h6>
          {currentQuestion.options ? (
            <div className={classes.answers}>
              {currentQuestion.options.map((option) => (
                <AnswerItem
                  key={option.id}
                  questionId={currentQuestion.questionId}
                  onChange={updateAnswer}
                  option={option}
                  answer={answers[currentQuestion.questionId]}
                  severalAnswersAllowed={currentQuestion.severalAnswersAllowed}
                />
              ))}
            </div>
          ) : currentQuestion.answerComponent ? (
            <currentQuestion.answerComponent
              answer={answers[currentQuestion.questionId]}
              onChange={updateAnswer}
              questionId={currentQuestion.questionId}
            />
          ) : null}
        </div>
      </div>
      <div className="column gap-1-5">
        <div className="row gap-1-5">
          {hasPrev && (
            <Button onClick={handlePressBack} fullWidth variant="creamyBlack">
              {translate('BACK')}
            </Button>
          )}
          {hasNext && (
            <Button
              fullWidth
              variant="creamyBlack"
              onClick={handlePressNext}
              disabled={!currentAnswer}
            >
              {translate('NEXT')}
            </Button>
          )}
          {!hasNext && (
            <Button
              loading={submitting.state}
              onClick={handleSubmit}
              disabled={!currentAnswer}
              fullWidth
            >
              {translate('SUBMIT')}
            </Button>
          )}
        </div>
        {onSkip && currentQuestionIndex === 0 ? (
          <Button variant="darkGreyOutlined" loading={skipping.state} onClick={handleSkip}>
            {translate('SKIP')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
