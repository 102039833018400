import { getTranslation } from 'libs/i18n';

import { getCurrencySymbolByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

export const getCommissionLabel = ({
  fixed,
  minFixed,
  percent,
  currencyCode,
}: {
  fixed: number | null;
  minFixed: number | null;
  percent: number | null;
  currencyCode: CurrencyCode;
}) => {
  let result = '';
  if (percent) {
    result += `${percent}%`;
  }
  if (minFixed) {
    result += ` (${getTranslation('PAYMENT_FEE_MIN').toLowerCase()}
        ${minFixed} ${getCurrencySymbolByCode(currencyCode)} )`;
  }
  if (fixed) {
    if (percent) {
      result += ' + ';
    }
    result += `${fixed} ${getCurrencySymbolByCode(currencyCode)}`;
  }

  return result;
};
