import clsx from 'clsx';

import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  selectStakingItemsReducer,
  selectStatisticReducer as selectStakingStatisticReducer,
  selectStakingTemplatesMaxRPY,
} from 'modules/staking/store/selectors';
import {
  requestStakingItems,
  requestStatistic as requestStakingStatistic,
} from 'modules/staking/store/thunks';
import { Statistic } from 'modules/staking/types';
import { StakingHistory } from 'modules/staking/views/components/StakingHistory';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { EntityView } from 'components/common';
import { AmountLabel, Button, CurrencyIcon, Image, LineDelimiter } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { canRequest } from 'utils/common';
import { formatCurrencyWithSymbol } from 'utils/currency';

import classes from './Overview.module.scss';

interface StatsAndHistoryProps {
  statistic: Statistic;
}

interface StatsProps {
  statistic: Statistic;
}

const Stats: FC<StatsProps> = ({ statistic }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();

  const stakingItemsReducer = useSelector(selectStakingItemsReducer);

  useEffect(() => {
    if (canRequest(stakingItemsReducer.meta)) {
      dispatch(requestStakingItems());
    }

    // eslint-disable-next-line
  }, []);

  const stakedCurrencies = useMemo(
    () => stakingItemsReducer.data.map((i) => i.currencyCode),
    [stakingItemsReducer.data],
  );

  return (
    <div className={classes.stats}>
      <div className="column">
        <span className="label">{translate('STAKING_TOTAL_STAKED_VALUE')}:</span>
        <AmountLabel
          size="xl4"
          className="mt-1"
          amount={statistic.totalStakedAmount}
          currencyCode={statistic.defaultCurrencyCode}
          showCurrencySymbol
        />
      </div>
      <LineDelimiter className={classes.statDelimiter} />
      <div className={classes.statDetails}>
        <div className={classes.item}>
          <span className={classes.label}>{translate('STAKING_STAKED_CURRENCIES')}</span>
          <div className="mt-1 row">
            {stakedCurrencies.map((code) => (
              <CurrencyIcon key={code} code={code} className={classes.currencyIcon} />
            ))}
          </div>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>{translate('RENTING_REWARDS_30DAYS')}</span>
          <span className={classes.rewardsLabel}>
            {formatCurrencyWithSymbol(statistic.monthRewardsAmount, statistic.defaultCurrencyCode)}
          </span>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>{translate('RENTING_REWARDS_60DAYS')}</span>
          <span className={classes.rewardsLabel}>
            {formatCurrencyWithSymbol(
              statistic.twoMonthRewardsAmount,
              statistic.defaultCurrencyCode,
            )}
          </span>
        </div>
      </div>
      <LineDelimiter className={classes.statDelimiter} />
    </div>
  );
};
const StatsAndHistory: FC<StatsAndHistoryProps> = ({ statistic }) => {
  return (
    <div className={classes.card}>
      <Stats statistic={statistic} />
      <StakingHistory className={classes.history} />
    </div>
  );
};

const StartStaking = () => {
  const translate = useTranslation();
  const navigate = useNavigate();

  const maxRPY = useSelector(selectStakingTemplatesMaxRPY);

  return (
    <div className={clsx(classes.startStaking)}>
      <div className={classes.imgContainer}>
        <Image className="w100" name="giftTether" />
      </div>
      <div className={classes.content}>
        <h3>{translate('STAKING_OVERVIEW_TITLE', { maxRPY })}</h3>
        <p dangerouslySetInnerHTML={{ __html: translate('STAKING_OVERVIEW_TEXT') }} />
        <LineDelimiter className={classes.delimiter} />
        <Button
          className={classes.button}
          fullWidth
          onClick={() => {
            navigate(routesByName.staking('assets'));
          }}
        >
          {translate('STAKING_START')}
        </Button>
      </div>
    </div>
  );
};

export const Overview = () => {
  const { entityReducer, fetchEntity } = useStoreEntity(
    selectStakingStatisticReducer,
    requestStakingStatistic,
  );

  return (
    <EntityView
      reducer={entityReducer}
      request={fetchEntity}
      component={
        (entityReducer.data?.totalStakedAmount || 0) > 0 ? (
          <StatsAndHistory statistic={entityReducer.data!} />
        ) : (
          <StartStaking />
        )
      }
    />
  );
};
