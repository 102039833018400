import { Counterparty } from 'modules/phonebook/types';

import { CounterpartiesResponse, CounterpartyResponse } from 'libs/swagger/nebeusApiTypes';

export const transformCounterparty = ({
  name,
  email,
  id,
  nebeusUser,
}: CounterpartyResponse): Counterparty => ({
  name,
  email,
  id,
  isNebeusUser: nebeusUser,
});

export const transformCounterparties = (
  response: CounterpartiesResponse,
): {
  list: Counterparty[];
  pageNumber: number;
  pageSize: number;
} => ({
  ...response,
  list: response.list.map(transformCounterparty),
});
