import clsx from 'clsx';

import { FC, ReactNode } from 'react';

import { Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

import classes from './BankCardItem.module.scss';

interface BankCardItemProps {
  title?: string;
  valueComponent: ReactNode;
  onClick?: voidFunc;
  cardLastDigits?: string;
  isCardVirtual?: boolean;
  endAdornment?: ReactNode;
}
export const BankCardItem: FC<BankCardItemProps> = ({
  title,
  valueComponent,
  cardLastDigits,
  onClick,
  endAdornment,
  isCardVirtual,
}) => {
  const translate = useTranslation();
  return (
    <div className={classes.root} translate="no">
      <div className={clsx('row gap-2 aic', onClick && 'pointer')} onClick={onClick}>
        <div className="relative">
          <Image name="cryptoBankCardMC" path="cryptoBankCard" type="svg" />
          <span className={classes.cardNumber}>{cardLastDigits}</span>
        </div>
        <div className="column gap-1 aifs">
          <span className={classes.label}>
            {translate(isCardVirtual ? 'NEBEUS_CRYPTO_VIRTUAL_CARD' : 'NEBEUS_CRYPTO_CARD')}
          </span>
          <span className={classes.value}>
            {title ? title + '. ' : ''}
            {valueComponent}
          </span>
        </div>
      </div>
      {endAdornment}
    </div>
  );
};
