import clsx from 'clsx';

import { FC } from 'react';

import useRate from 'modules/exchange/hooks/useRate';
import { SmartTrade } from 'modules/smartTrader/types';

import { AmountLabel, CurrencyIcon } from 'components/ui';

import { formatCurrencyWithLabel } from 'utils/currency';

import classes from './TotalCard.module.scss';

interface TotalCardProps {
  trade: SmartTrade;
}

export const TotalCard: FC<TotalCardProps> = ({ trade }) => {
  const rate = useRate(trade.primaryCurrency, trade.secondaryCurrency, {
    autoFetch: true,
  });

  const currentSecondaryAmount = trade.primaryAmount * rate;
  const currentDifferenceAmount = currentSecondaryAmount - trade.secondaryAmount;
  const currentDifferencePercent = Number(
    ((currentDifferenceAmount / trade.secondaryAmount) * 100).toFixed(2),
  );

  const isRaised = currentDifferenceAmount > 0;

  return (
    <div className="row aic gap-1-5">
      <CurrencyIcon size={44} code={trade.secondaryCurrency} />
      <div className="column">
        <AmountLabel
          size="xl"
          amount={currentSecondaryAmount}
          currencyCode={trade.secondaryCurrency}
          showCurrencyCode
        />
        <span className={clsx(classes.changeLabel, !isRaised && classes.minus)}>
          {isRaised ? '+' : '-'}
          {formatCurrencyWithLabel(Math.abs(currentDifferenceAmount), trade.secondaryCurrency)} (
          {isRaised ? '+ ' : '-'}
          {Math.abs(currentDifferencePercent)}%)
        </span>
      </div>
    </div>
  );
};
