import routesByName from 'constants/routesByName';

const unauthedRoutes = [
  routesByName.signIn,
  routesByName.signUp,
  routesByName.welcome,
  routesByName.emergencyResetTFA,
];

export default unauthedRoutes;
