import clsx from 'clsx';
import get from 'lodash/get';

import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef } from 'react';

import useKeyboardButton from 'hooks/useKeyboardButton';

import classes from './PinCodeInput.module.scss';

export interface PinCodeInputProps {
  length: number;
  value: string;
  onChange: (value: string) => void;
  canIntercept: boolean;
  variant?: 'creamy' | 'white';
  error?: string;
}

export const PinCodeInput: FC<PinCodeInputProps> = ({
  value,
  onChange,
  length,
  canIntercept = true,
  variant = 'creamy',
  error,
}) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const inputs = useMemo(() => new Array(length).fill(null), [length]);

  const getInputNode = useCallback(
    (index: number) =>
      get(rootRef.current, `childNodes[${Math.min(index, length - 1)}].childNodes[0]`) as
        | HTMLInputElement
        | undefined,
    [length],
  );

  const handleInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const index = event.target.dataset.index as string;

      if (event.target.value.length === length && !isNaN(+event.target.value.length)) {
        onChange(event.target.value);
        return;
      }

      let newValue = value.split('');
      const oldValue = newValue[+index];

      const inputNumbers = event.target.value.split('');
      newValue[+index] = oldValue
        ? inputNumbers.filter((n) => n !== oldValue)[0] || oldValue
        : inputNumbers[0];

      onChange(newValue.join(''));
    },
    [value, length, onChange],
  );

  const handleKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (!canIntercept) {
        return;
      }
      if (event.key === 'Backspace') {
        onChange(value.slice(0, -1));
      }
    },
    [onChange, canIntercept, value],
  );

  useKeyboardButton({ handler: handleKeyboard });

  useEffect(() => {
    if (canIntercept) {
      const firstNode = getInputNode(0);
      // Issue with swiper resolved by timeout
      setTimeout(() => {
        firstNode?.focus();
      }, 100);
    }
    // eslint-disable-next-line
  }, [canIntercept]);

  useEffect(() => {
    if (canIntercept) {
      const nextTarget = getInputNode(value.length);

      if (nextTarget) {
        nextTarget.focus();
      }
    }
    // eslint-disable-next-line
  }, [value.length]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        onChange('');
      }, 2000);
    }
    // eslint-disable-next-line
  }, [error]);

  return (
    <div
      className={clsx(classes.root, error && classes.error, classes[`variant-${variant}`])}
      ref={rootRef}
    >
      {inputs.map((_, index) => (
        <div className={clsx(classes.inputContainer, value[index] && classes.filled)} key={index}>
          <input
            type="number"
            maxLength={1}
            onChange={handleInput}
            data-index={index}
            value={value[index] || ''}
            inputMode="numeric"
          />
        </div>
      ))}
    </div>
  );
};
