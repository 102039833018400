import WAValidator from 'wallet-validator-js';

import { CurrencyNetwork, PaymentOperationId } from 'modules/payment/types';
import { UserBlockedFeature } from 'modules/user/types';

import { CryptoCurrencyCode } from 'types';

const depositOperations = [
  PaymentOperationId.depositFromWireToTradingWallet,
  PaymentOperationId.depositFromSepaToTradingWallet,
  PaymentOperationId.depositFromBankCardToTradingWallet,
  PaymentOperationId.depositApplePay,
  PaymentOperationId.depositCryptoFromExternalWallet,
  PaymentOperationId.depositFromSepaToTradingWallet,
  PaymentOperationId.depositFromSepaToMoneyWallet,
  PaymentOperationId.depositFromWireToMoneyWallet,
  PaymentOperationId.depositFromBankCardToMoneyWallet,
];
const digitalOperations = [
  PaymentOperationId.depositFromSepaToMoneyWallet,
  PaymentOperationId.depositFromWireToMoneyWallet,
  PaymentOperationId.depositFromBankCardToMoneyWallet,
  PaymentOperationId.sendFromMoneyToChaps,
  PaymentOperationId.sendFromMoneyToSepaFaster,
  PaymentOperationId.sendFromMoneyWalletToBankCard,
  PaymentOperationId.sendFromMoneyToTrading,
  PaymentOperationId.sendFromMoneyToSepaCredit,
  PaymentOperationId.sendFromMoneyToSepaInstantCredit,
  PaymentOperationId.sendFromMoneyToSepa,
  PaymentOperationId.sendFromMoneyToWire,
];

export const isDepositByOperationId = (id: PaymentOperationId): boolean =>
  depositOperations.includes(id);

export const isDigitalAccountByOperationId = (id: PaymentOperationId): boolean =>
  digitalOperations.includes(id);

export const validateCryptoWalletAddress = (
  value: string,
  currencyCode: CryptoCurrencyCode,
  network: CurrencyNetwork['network'],
) => {
  // Lib can validate only ERC-20 and BTC;
  if (network !== 'ERC_20' && currencyCode !== 'BTC') {
    return true;
  }
  if (!WAValidator.findCurrency(currencyCode)) {
    return true;
  }
  try {
    return WAValidator.validate(value, currencyCode);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Wallet address validation error: ', e);
    return true;
  }
};

const blockedOperationIdByPaymentOperationId: { [key in PaymentOperationId]?: UserBlockedFeature } =
  {
    [PaymentOperationId.sendToNebeusUser]: 'sendCryptoToNebeusUser',
    [PaymentOperationId.sendCryptoToExternalWallet]: 'sendCryptoToExternalWallet',
    [PaymentOperationId.sendFromTradingToMoney]: 'sendFromTradingAccountToMoneyAccount',
    [PaymentOperationId.sendFromMoneyToWire]: 'sendFromMoneyAccountGBPByWireTransfer',
    [PaymentOperationId.sendFromTradingWalletToBankCard]: 'sendFromTradingAccountToBankCard',
    [PaymentOperationId.sendFromMoneyToSepa]: 'sendFromMoneyAccountEURBySepaTransfer',
    [PaymentOperationId.sendFromMoneyToTrading]: 'sendFromMoneyAccountToTradingAccount',
    [PaymentOperationId.sendFromTradingToSepa]: 'sendFromTradingAccountEURBySepaTransfer',
    [PaymentOperationId.sendFromTradingToWire]: 'sendFromTradingAccountGBPByWireTransfer',
  };

export const getBlockedOperationIdByPaymentOperationId = (paymentOperationId: PaymentOperationId) =>
  blockedOperationIdByPaymentOperationId[paymentOperationId];
