import { Field } from 'react-final-form';

import { SimpleCurrencyPickerInputField } from 'components/form';

import { FiatCurrencyCode } from 'types';

const currenciesList: FiatCurrencyCode[] = ['EUR', 'GBP'];
export const ChooseCashAccount = () => {
  return (
    <Field
      name="payment.currencyCode"
      component={SimpleCurrencyPickerInputField}
      currenciesList={currenciesList}
      variant="white"
    />
  );
};
