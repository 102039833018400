import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import { selectSideBarStack } from 'modules/app/store/selectors';
import { PushNotificationData } from 'modules/app/types';
import { getValidRoute } from 'modules/app/views/AuthedApp/hooks/useInvalidRouteRedirect';
import { logout } from 'modules/auth/store/externalThunks';
import { selectIsUserAuthed } from 'modules/auth/store/selectors';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import usePushNotificationClickHandler from 'libs/firebase/pushNotifications/usePushNotificationClickHandler';

import eventEmitter from 'utils/eventEmitter';

const useReactNativeEvents = () => {
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const drawerStack = useSelector(selectSideBarStack);
  const location = useLocation();
  const navigate = useNavigate();

  const lastDrawerItem = drawerStack[drawerStack.length - 1];
  const userAuthed = useSelector(selectIsUserAuthed);

  const onUserLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    const logoutSubRemove = eventEmitter.subscribe('USER_LOGOUT', onUserLogout);

    return () => {
      logoutSubRemove();
    };
    // eslint-disable-next-line
  }, []);

  const handleAndroidBackButtonPress = useCallback(() => {
    if (lastDrawerItem) {
      if (
        !lastDrawerItem.sideBarProps?.swipeModalBlocked &&
        !lastDrawerItem.sideBarProps?.disableClickOutside
      ) {
        drawer.pop();
        return true;
      }
      return true;
    }
    if (location.key === 'default') {
      navigate(userAuthed ? routesByName.dashboard : routesByName.signIn);
    } else {
      navigate(-1);
    }
    return true;
  }, [navigate, userAuthed, location, lastDrawerItem, drawer]);

  useEffect(() => {
    const unsub = eventEmitter.subscribe(
      'ANDROID_BACK_BUTTON_PRESSED',
      handleAndroidBackButtonPress,
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [handleAndroidBackButtonPress]);

  const handleLiveDeepUrl = useCallback(
    (payload: { url: string }) => {
      const route = payload.url.split('.com')[1];

      const validRoute = getValidRoute(route);

      navigate(validRoute || route);
    },
    [navigate],
  );

  useEffect(() => {
    const unsub = eventEmitter.subscribe('HANDLE_LIVE_DEEP_URL', handleLiveDeepUrl);

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [handleLiveDeepUrl]);

  const pushNotificationClickHandler = usePushNotificationClickHandler();

  const handlePushNotification = useCallback(
    async (notification: {
      foreground: boolean;
      userInteraction: boolean;
      message: string | object;
      data: PushNotificationData;
      subText?: string | undefined;
      badge: number;
      alert: object;
      sound: string;
      id: string;
      action?: string | undefined;
    }) => {
      return pushNotificationClickHandler(notification.data);
    },
    [pushNotificationClickHandler],
  );

  useEffect(() => {
    const unsub = eventEmitter.subscribe('PUSH_NOTIFICATION_PRESS', handlePushNotification);

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [handlePushNotification]);
};

export default useReactNativeEvents;
