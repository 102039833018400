import { useCallback } from 'react';

import useFetchRate from 'modules/exchange/hooks/useFetchRate';
import useGetRate from 'modules/exchange/hooks/useGetRate';

import { CurrencyCode } from 'types';

const useRatesConverter = () => {
  const getRate = useGetRate();
  const { fetchRate } = useFetchRate();

  const convert = useCallback(
    async (from: CurrencyCode, to: CurrencyCode, amount: number) => {
      const rate = getRate(from, to);

      if (rate) {
        return amount * rate;
      }
      const { rate: fetchedRate } = await fetchRate(from, to, { force: true });

      return amount * fetchedRate;
    },
    [fetchRate, getRate],
  );

  return convert;
};
export default useRatesConverter;
