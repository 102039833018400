import clsx from 'clsx';

import { FC, UIEventHandler, useCallback } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';
import { Invoice } from 'modules/invoicing/types';

import { Loader } from 'components/ui';

import classes from './InvoicesList.module.scss';
import InvoiceCard from './components/InvoiceCard';

interface InvoicesListProps {
  onScroll: UIEventHandler<HTMLDivElement>;
  list: Invoice[];
  loading: boolean;
  editMode: boolean;
  togglePickedForDeleteInvoice: (id: Invoice['id'], newValue: boolean) => void;
  pickedForDeleteInvoices: Invoice['id'][];
}
const InvoicesList: FC<InvoicesListProps> = ({
  onScroll,
  list,
  loading,
  editMode,
  togglePickedForDeleteInvoice,
  pickedForDeleteInvoices,
}) => {
  const drawer = useDrawer();

  const onInvoiceClick = useCallback(
    (invoice: Invoice) => {
      if (editMode) {
        togglePickedForDeleteInvoice(invoice.id, !pickedForDeleteInvoices.includes(invoice.id));
      } else {
        drawer.open(
          invoicingDrawerTemplates.invoice({
            invoice,
          }),
        );
      }
    },
    [drawer, togglePickedForDeleteInvoice, pickedForDeleteInvoices, editMode],
  );
  return (
    <>
      <div className={classes.list} onScroll={onScroll}>
        {list.map((invoice) => (
          <InvoiceCard
            key={invoice.id}
            invoice={invoice}
            onClick={() => {
              onInvoiceClick(invoice);
            }}
            editMode={editMode}
            pickedForDelete={pickedForDeleteInvoices.includes(invoice.id)}
          />
        ))}
      </div>

      <div className={clsx(classes.loader, loading && classes.active)}>
        <Loader />
      </div>
    </>
  );
};

export default InvoicesList;
